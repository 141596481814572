import { Row, Col } from "antd";
import styled from "styled-components";


export const ChannelProductPageWrapper = styled(Row)`
    width: 95%;
    background-color: #313841;
    height: 90vh;
`;

export const EbppCol = styled(Col)`
   
    background-color : #36404A;
`;