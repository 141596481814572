import React, { Component } from "react";
import {
  Layout,
  Menu,
  Card,
  Dropdown,
  Icon,
  Button,
  Row,
  Col,
  Typography,
  Avatar,
  Timeline,
  Divider,
  Input,
} from "antd";

import "./platform-content-page.scss";
import HeaderLandingPage from "../components/landing-page-header/HeaderLandingPage";
import HeroImg2 from "../../media/hero-img-2.png";
import HeroImg from "../../media/hero-img.png";
import AssignmentImg from "../../media/assignment.png";

import GeepasLogo from "../../media/GEEPAS-logo2.jpeg";
import NestoLogo from "../../media/nesto-logo.png";
import KryptonLogo from "../../media/krypton-logo.png";
import OlsenmarkLogo from "../../media/olsenmark-logo.png";
import RoyalfordLogo from "../../media/royalford-logo.png";
import YounglifeLogo from "../../media/younglife-logo.jpg";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;

export default class PlatformContentPage extends Component {
  render() {
    return (
      <div className="platform-content-page-container">
        <Layout className="layout">
          <HeaderLandingPage />
          <Content>
            <div style={{ background: "#fff", minHeight: 1000 }}>
              <Row className="hero-section" gutter={[25, 25]}>
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Col span={18} style={{ textAlign: "center" }}>
                      <Title level={2} className="font-weight-400">
                        Unify your content creation, workflow and operations in
                        a single Content Hub
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Col span={18} style={{ textAlign: "center" }}>
                      <Title level={4} className="text-grey font-weight-300">
                        Bring your team together when you organize, plan and
                        produce content in one platform.
                      </Title>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row type="flex" justify="center" gutter={[16, 16]}>
                    <Col>
                      <Button type="primary" size="large">
                        Start free trial
                      </Button>
                    </Col>
                    <Col>
                      <Button size="large" className="demo-btn">
                        Book a demo
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row type="flex" justify="center" className="hero-img-section">
                <Col>
                  <img src={HeroImg2} className="hero-img-2" />
                </Col>
              </Row>

              <Row className="brand-section">
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Col style={{ marginTop: "30px" }}>
                      <Title level={3} className="font-weight-300">
                        Over 1,000 teams manage their product operations using
                        Omnycomm
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" align="middle" gutter={[16, 16]}>
                    <Col span={4}>
                      <img src={GeepasLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={NestoLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={KryptonLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={OlsenmarkLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={RoyalfordLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={YounglifeLogo} className="brand-logo" />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                type="flex"
                align="middle"
                className="img-right-content-section"
                justify=""
              >
                <Col lg={6} sm={24}>
                  <Row gutter={[16, 16]} style={{}}>
                    <Col span={24}>
                      <Title className="" level={3}>
                        Organize content in one platform
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Text className="text-color-1 font-weight-300">
                        From content scattered across multiple sources, easily
                        organize content into shared projects and folders to
                        create a single source of truth for content.
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col lg={18} sm={24}>
                  <img src={HeroImg} className="img-right" />
                </Col>
              </Row>

              <Row
                type="flex"
                align="middle"
                className="img-left-content-section"
                justify=""
              >
                <Col lg={18} sm={24}>
                  <img src={HeroImg} className="img-left" />
                </Col>
                <Col lg={6} sm={24}>
                  <Row gutter={[16, 16]} style={{}}>
                    <Col span={24}>
                      <Title className="" level={3}>
                        Everyone on your team can find content
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Text className="text-color-1 font-weight-300">
                        Spend less time looking for content and more time
                        working on it with powerful capabilities to search,
                        filter or sort content.
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                className="review-section"
                type="flex"
                justify="center"
                align="middle"
              >
                <Col span={3}>
                  <Avatar
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                    size={78}
                  />
                </Col>
                <Col span={21} style={{ marginBottom: "30px" }}>
                  <Text className="text-color-1 font-weight-300">
                    “Omnycomm is a game changer. Our product processes used to
                    involve an ineffective mix of spreadsheets, documents, and a
                    slew of file storage solutions. With Omnycomm, we've solved
                    all of these process problems and more, and we can now start
                    the sales process earlier. To top it off, our clients love
                    it too.”
                  </Text>
                </Col>
                <Col span={3}></Col>
                <Col span={21}>
                  <Text strong className="display-block text-color-3">
                    John Doe
                  </Text>
                  <Text className="text-color-1">
                    Partner & Executive Director, VMLY&R
                  </Text>
                </Col>
              </Row>

              <Row className="left-list-section">
                <Col span={24}>
                  <Row type="flex" justify="center" gutter={[8, 8]}>
                    <Col span={14} className="text-center">
                      <Title level={3} className="font-weight-300">
                        Your team always know where to work on content with a
                        Content Hub
                      </Title>
                    </Col>
                    <Col span={14} className="text-center">
                      <Title level={3} className="font-weight-300 text-grey">
                        Finally, a single platform to organize and manage
                        content processes at scale.
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" align="middle">
                    <Col span={8}>
                      <Row gutter={[64, 64]}>
                        <Col span={24}>
                          <Row type="flex" align="middle">
                            <Col span={2}>
                              <Icon type="check-circle" />
                            </Col>
                            <Col span={22}>
                              <Title className="margin-zero" level={4}>
                                Organize content in projects and folders
                              </Title>
                            </Col>

                            <Col span={24} offset={2}>
                              <Text className="text-color-grey font-weight-300">
                                Creating a logical structure for team
                                collaboration
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row type="flex" align="middle">
                            <Col span={2}>
                              <Icon type="check-circle" />
                            </Col>
                            <Col span={22}>
                              <Title className="margin-zero" level={4}>
                                View by content status
                              </Title>
                            </Col>

                            <Col span={24} offset={2}>
                              <Text className="text-color-grey font-weight-300">
                                To identify bottlenecks in workflow
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row type="flex" align="middle">
                            <Col span={2}>
                              <Icon type="check-circle" />
                            </Col>
                            <Col span={22}>
                              <Title className="margin-zero" level={4}>
                                Perform tasks in bulk
                              </Title>
                            </Col>

                            <Col span={24} offset={2}>
                              <Text className="text-color-grey font-weight-300">
                                Saving time spent managing content creation
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row type="flex" align="middle">
                            <Col span={2}>
                              <Icon type="check-circle" />
                            </Col>
                            <Col span={22}>
                              <Title className="margin-zero" level={4}>
                                Search, filter and sort content
                              </Title>
                            </Col>

                            <Col span={24} offset={2}>
                              <Text className="text-color-grey font-weight-300">
                                To quickly find what you need
                              </Text>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row type="flex" align="middle">
                            <Col span={2}>
                              <Icon type="check-circle" />
                            </Col>
                            <Col span={22}>
                              <Title className="margin-zero" level={4}>
                                Filter to personal assignments
                              </Title>
                            </Col>

                            <Col span={24} offset={2}>
                              <Text className="text-color-grey font-weight-300">
                                Help contributors focus only on what they need
                                to
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={16}>
                      <img src={AssignmentImg} className="assignment-img" />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="book-demo-section" type="flex" justify="center">
                <Col span={20}>
                  <Row
                    class="demo-container"
                    type="flex"
                    justify="space-between"
                    align="middle"
                    style={{
                      padding: "40px",
                      border: "1px solid #e5e5e5",
                      borderRadius: "5px",
                      backgroundColor: "white",
                    }}
                  >
                    <Col>
                      <Row>
                        <Col span={24}>
                          <Text strong className="text-color-2">
                            Ready to Get Started?
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Start your free 30 day trial now</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Button type="primary" size="large">
                        Book a Demo
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="feature-section" gutter={[32, 32]}>
                <Col span={24}>
                  <Row type="flex" justify="center" gutter={[8, 8]}>
                    <Col span={14} className="text-center">
                      <Title level={3} className="font-weight-300">
                        Manage your complete content operations in one platform
                      </Title>
                    </Col>
                    <Col span={14} className="text-center">
                      <Title level={3} className="font-weight-300 text-grey">
                        Explore more features GatherContent has to offer.
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Col span={22}>
                      <Row gutter={[14, 14]}>
                        <Col span={6}>
                          <Row className="feature-card">
                            <Col span={24} className="text-center">
                              <Title className="margin-zero" level={4}>
                                Perform tasks in bulk
                              </Title>
                            </Col>
                            <Col span={24} className="text-center">
                              <Text className="text-color-grey font-weight-300">
                                Saving time spent managing content creation
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <Row className="feature-card">
                            <Col span={24} className="text-center">
                              <Title className="margin-zero" level={4}>
                                Perform tasks in bulk
                              </Title>
                            </Col>
                            <Col span={24} className="text-center">
                              <Text className="text-color-grey font-weight-300">
                                Saving time spent managing content creation
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <Row className="feature-card">
                            <Col span={24} className="text-center">
                              <Title className="margin-zero" level={4}>
                                Perform tasks in bulk
                              </Title>
                            </Col>
                            <Col span={24} className="text-center">
                              <Text className="text-color-grey font-weight-300">
                                Saving time spent managing content creation
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <Row className="feature-card">
                            <Col span={24} className="text-center">
                              <Title className="margin-zero" level={4}>
                                Perform tasks in bulk
                              </Title>
                            </Col>
                            <Col span={24} className="text-center">
                              <Text className="text-color-grey font-weight-300">
                                Saving time spent managing content creation
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="info-footer-container">
                <Col span={24}>
                  <Row className="info-footer">
                    <Col span={6}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            PLATFORM FEATURES
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Content Hub </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content project Management</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Planning</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Templates </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Style Guides</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Management</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            PRODUCT
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Pricing</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Customers</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Demo</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Integrations </Text>
                        </Col>
                        <Col span={24}>
                          <Text>What's New</Text>
                        </Col>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            LEARNING
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Resources</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Blog</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Events</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            COMPANY
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>About Us</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Security and Backups</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Terms of Serrvice</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Privacy Policy </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Terms of Website Use</Text>
                        </Col>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            HELP
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Help Center</Text>
                        </Col>
                        <Col span={24}>
                          <Text>API Documentation</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Email Us</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Message Us</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={4} className="">
                            SIGN UP TO OUR WEEKLY NEWS LETTER
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Input size="large" placeholder="First Name" />
                        </Col>
                        <Col span={24}>
                          <Input size="large" placeholder="Email Address" />
                        </Col>
                        <Col span={24}>
                          <Button type="primary" size="large" block>
                            Subscribe
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Text>
                            We email useful things for people interested in
                            learning more about product management. No spam.
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Content>
          <Footer style={{ textAlign: "center", backgroundColor: "#f1f1f1" }}>
            © OmnyComm 2020, Jebel Ali, UAE. VAT No.: GB140105279. Company No.:
            SC400199{" "}
          </Footer>
        </Layout>
      </div>
    );
  }
}
