/**
 * @description This is the initialState of productDetails reducer
 * @property {object} productDetails - All required product details.
 */
const initialState = {
  productDetails: {
    base_product_name: "",
    seller_sku: "",
    brand_name: "",
    category: "",
    sub_category: "",
    manufacturer: "",
    manufacturer_part_number: "",
    base_dimensions: {
      export_carton_quantity_l: "",
      export_carton_quantity_l_metric: "",
      export_carton_quantity_b: "",
      export_carton_quantity_b_metric: "",
      export_carton_quantity_h: "",
      export_carton_quantity_h_metric: "",
      export_carton_crm_l: "",
      export_carton_crm_l_metric: "",
      export_carton_crm_b: "",
      export_carton_crm_b_metric: "",
      export_carton_crm_h: "",
      export_carton_crm_h_metric: "",
      product_dimension_l: "",
      product_dimension_l_metric: "",
      product_dimension_b: "",
      product_dimension_b_metric: "",
      product_dimension_h: "",
      product_dimension_h_metric: "",
      giftbox_l: "",
      giftbox_l_metric: "",
      giftbox_b: "",
      giftbox_b_metric: "",
      giftbox_h: "",
      giftbox_h_metric: "",
    },
    product_pk: "",
    product_name: "",
    barcode_string: "",
    holding_threshold: "",
    atp_threshold: "",
    color: "",
    color_map: "",
    standard_price: "",
    quantity: "",
    product_id_type: "",
    material_type: "",
    pfl_product_name: "",
    pfl_product_features: [],
    factory_code: "",
    factory_notes: "",
    product_id: "",
    status: "",
    product_pk: null,
    base_product_pk: null,
    weight: "",
  },
};

/**
 * @description This is redux reducer for editing product details
 * changes/updates state  for different cases like fetch product details,set image only etc..
 * @typedef StateType - state type of productDetails reducer, default state is initial state
 * @param {object} state
 * @param {object} action
 * @returns {StateType} returns updated state
 */
export default function productDetails(state = initialState, action) {
  switch (action.type) {
    case "FETCH_PRODUCT_DETAILS":
      return {
        ...state,
        productDetails: {
          ...action.payload,
          ["status"]: "",
        },
      };

    case "SET_IMAGES_ONLY":
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          images: action.payload.images,
        },
      };

    case "HANDEL_EDIT_PRODUCT_DETAILS":
      return {
        ...state,
        productDetails: { ...action.payload, ["status"]: "" },
      };

    case "HANDEL_EDIT_PRODUCT_DETAILS_SPECIAL_FEATURES":
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          ["dynamic_form_attributes"]: action.payload,
          ["status"]: "",
        },
      };

    case "SEND_FEED_BACK_CREATE_VARIANT":
      return {
        ...state,
        productDetails: { ...state.productDetails, ["status"]: action.payload },
      };

    case "FEEDBACK_SAVE_BASE_PRODUCT_DETAILS":
      return {
        ...state,
        productDetails: { ...state.productDetails, ["status"]: action.payload },
      };

    case "SEND_FEED_BACK_SAVE_BASE_PRODUCT":
      return {
        ...state,
        productDetails: { ...state.productDetails, ["status"]: action.payload },
      };

    case "SEND_PRODUCT_DESCRIPTION":
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          ["product_description"]: action.payload,
        },
      };

    case "SET_EMPTY":
      return initialState;

    case "SEND_PRODUCT_PK":
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          ["product_pk"]: action.payload,
        },
      };

    case "FETCH_BASE_PRODUCT_DETAILS":
      let supercategory_uuid =
        action.payload.super_category == "None"
          ? undefined
          : action.payload.super_category_uuid;
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          ...action.payload,
          ["super_category_uuid"]: supercategory_uuid,
          ["status"]: "",
        },
      };

    case "SET_EMPTY_PRODUCT_DETAILS":
      return {
        productDetails: {
          base_product_name: "",
          seller_sku: "",
          brand_name: "",
          category: "",
          sub_category: "",
          manufacturer: "",
          manufacturer_part_number: "",
          base_dimensions: {
            export_carton_quantity_l: "",
            export_carton_quantity_l_metric: "",
            export_carton_quantity_b: "",
            export_carton_quantity_b_metric: "",
            export_carton_quantity_h: "",
            export_carton_quantity_h_metric: "",
            export_carton_crm_l: "",
            export_carton_crm_l_metric: "",
            export_carton_crm_b: "",
            export_carton_crm_b_metric: "",
            export_carton_crm_h: "",
            export_carton_crm_h_metric: "",
            product_dimension_l: "",
            product_dimension_l_metric: "",
            product_dimension_b: "",
            product_dimension_b_metric: "",
            product_dimension_h: "",
            product_dimension_h_metric: "",
            giftbox_l: "",
            giftbox_l_metric: "",
            giftbox_b: "",
            giftbox_b_metric: "",
            giftbox_h: "",
            giftbox_h_metric: "",
          },
          product_pk: "",
          product_name: "",
          barcode_string: "",
          color: "",
          color_map: "",
          standard_price: "",
          quantity: "",
          product_id_type: "",
          material_type: "",
          pfl_product_name: "",
          pfl_product_features: [],
          factory_notes: "",
          product_id: "",
          status: "",
        },
      };

    default:
      return state;
  }
}
