import React, { Component } from "react";
import {
    Row,
    Col,
    Dropdown,
    Modal,
    Upload,
    message,
    Icon,
    Spin,
    Collapse,
    Breadcrumb,
    Button,
    Typography,
    Select,
    Tooltip,
} from "antd";
import "./deals-admin-banners.scss";
import { connect } from "react-redux";
// import { addcategory, fetchDealsHubCategoryData, handleUpdate } from "../../actions/dealsHubAdmin";
import axios from "axios";
import {
    FilterBtn,
    ProductDetailsTitle
} from "../export/styled-components-library";
import ListTableDealshubproducts from "./ListTableDealshubproducts";
import { fetchProductsByTags } from "../../actions/dealsHubProducts";
import DealshubAdminProductsFilterModal from "./DealshubAdminProductsFilterModal";
import isDev from "../../server";
import styled from "styled-components"

import fileTemplate from "./Upload dealshub products SKU.xlsx"
import fileTemplate1 from "./Bulk Upload Price Dealshub.xlsx"
import fileTemplate2 from "./Bulk Upload Stock Dealshub.xlsx"
import fileTemplate3 from "./Bulk Upload Status Dealshub.xlsx"
import fileTemplate4 from "./Bulk Upload Product Status Dealshub.xlsx"
import fileTemplateB2bPrice from './Bulk Upload Price Dealshub B2B.xlsx'
import fileTemplateB2bMoq from './Bulk Upload MOQ Dealshub B2B.xlsx'

const serverUrl = isDev();
const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

const UploadBtn = styled(Upload)`
  && {
    .ant-upload {
      height: 0px !important;
      width: 100% !important;
    }

    span {
      width: 100% !important;
    }
  }`

class DealsHubAdminProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverUrl: serverUrl,
            list: null,
            tags: [],
            _isSpinning: false,
            fileList: [],
            uploadModal: false,
            uploadType: "search",
        };
    }

    componentDidMount = () => {
        if (window.sessionStorage.getItem(`${this.props.locationUuid}-ProdSearch`)) {
            const tags = JSON.parse(window.sessionStorage.getItem(`${this.props.locationUuid}-ProdSearch`))
            this.handleChangeSearchTags(tags)
        }
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.locationUuid !== this.props.locationUuid) {
            let tags = []
            if (window.sessionStorage.getItem(`${this.props.locationUuid}-ProdSearch`)) {
                tags = JSON.parse(window.sessionStorage.getItem(`${this.props.locationUuid}-ProdSearch`))
            }
            this.handleChangeSearchTags(tags)
        }
    }

    handleSelectFile = file => {
        this.setState({ fileList: [file], uploadModal: false });
        this.props.fetchProductsByTags(null, null, file, this.props.locationUuid)
    }

    handleUnselectFile = file => {
        this.setState({ fileList: [] });
    }

    handleChangeSearchTags = (tags) => {
        this.setState({ tags, page: 1 }, () => {
            this.props.fetchProductsByTags(tags, this.props.dealsHubProduct.filters, null, this.props.locationUuid)
        });
    }

    getList = data => {
        this.setState({ list: data });
    };

    handleDownload = () => {
        this.setState({ _isSpinning: true });
        axios.post(`${serverUrl}/bulk-download-dealshub-product/`,
            {
                locationGroupUuid: this.props.locationUuid
            }
            , {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`

                }
            })
            .then(response => {
                this.setState({ _isSpinning: false });
                if (response.data.status === 200) {
                    if (response.data.approved === true) {
                        message.success("Bulk Download approved.")
                    }
                }
            });
    }


    handelPublish = () => {
        this.setState({ _isSpinning: true });
        axios
            .post(`${serverUrl}/dealshub/publish-dealshub-products/`,
                {
                    product_uuid_list: this.state.list,
                }
                , {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`

                    }
                })
            .then(response => {
                this.setState({ _isSpinning: false });
            });
    };

    handelUnpublish = () => {
        this.setState({ _isSpinning: true });

        axios
            .post(`${serverUrl}/dealshub/unpublish-dealshub-products/`, {
                product_uuid_list: this.state.list,
            }
                , {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`

                    }
                })
            .then(response => {

                this.setState({ _isSpinning: false });
            });
    };

    uploadFilePrices = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("locationGroupUuid", this.props.locationUuid);
        this.setState({ loading: true })
        axios
            .post(`${serverUrl}/bulk-update-dealshub-product-price/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")} `
                }
            })
            .then(res => {
                if (res.data.status === 200) {
                    message.success("Success!")
                    this.setState({ uploadModal: false })
                    window.location.reload();
                } else if (res.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Internal Server Error!");
                }
                this.setState({ loading: false, fileList: [] });
            })
            .catch(err => {
                this.setState({ loading: false });
                message.error("Internal Server Error!");
            });
    }

    uploadFileStock = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("locationGroupUuid", this.props.locationUuid);
        this.setState({ loading: true });
        axios
            .post(`${serverUrl}/bulk-update-dealshub-product-stock/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")} `
                }
            })
            .then(res => {
                this.setState({ loadingStock: false });
                if (res.data.status === 200) {
                    message.success("Success!")
                    this.setState({ uploadModal: false })
                    window.location.reload();
                } else if (res.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Internal Server Error!");
                }
                this.setState({ loading: false, fileList: [] });
            })
            .catch(err => {
                this.setState({ loading: false });
                message.error("Internal Server Error!");
            });
    }

    uploadFileStatus = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("locationGroupUuid", this.props.locationUuid);
        this.setState({ loading: true });
        axios
            .post(`${serverUrl}/bulk-update-dealshub-product-publish-status/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")} `
                }
            })
            .then(res => {
                this.setState({ loadingStock: false });
                if (res.data.status === 200) {
                    message.success("Success!")
                    this.setState({ uploadModal: false })
                    window.location.reload();
                } else if (res.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Internal Server Error!");
                }
                this.setState({ loading: false, fileList: [] });
            })
            .catch(err => {
                this.setState({ loading: false });
                message.error("Internal Server Error!");
            });
    }

    uploadFileBoolStatus = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("locationGroupUuid", this.props.locationUuid);
        this.setState({ loading: true });
        axios
            .post(`${serverUrl}/bulk-update-dealshub-product-status/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")} `
                }
            })
            .then(res => {
                this.setState({ loadingStock: false });
                if (res.data.status === 200) {
                    message.success("Success!")
                    this.setState({ uploadModal: false })
                    window.location.reload();
                } else if (res.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Internal Server Error!");
                }
                this.setState({ loading: false, fileList: [] });
            })
            .catch(err => {
                this.setState({ loading: false });
                message.error("Internal Server Error!");
            });
    }

    uploadFileCohortPrice = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("locationGroupUuid", this.props.locationUuid);
        this.setState({ loading: true });
        axios
            .post(`${serverUrl}/bulk-update-b2b-dealshub-product-price/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")} `
                }
            })
            .then(res => {
                this.setState({ loadingStock: false });
                if (res.data.status === 200) {
                    message.success("Success!")
                    this.setState({ uploadModal: false })
                    window.location.reload();
                } else if (res.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Internal Server Error!");
                }
                this.setState({ loading: false, fileList: [] });
            })
            .catch(err => {
                this.setState({ loading: false });
                message.error("Internal Server Error!");
            });
    }

    uploadFileCohortMOQ = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("locationGroupUuid", this.props.locationUuid);
        this.setState({ loading: true });
        axios
            .post(`${serverUrl}/bulk-update-b2b-dealshub-product-moq/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")} `
                }
            })
            .then(res => {
                this.setState({ loadingStock: false });
                if (res.data.status === 200) {
                    message.success("Success!")
                    this.setState({ uploadModal: false })
                    window.location.reload();
                } else if (res.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Internal Server Error!");
                }
                this.setState({ loading: false, fileList: [] });
            })
            .catch(err => {
                this.setState({ loading: false });
                message.error("Internal Server Error!");
            });
    }

    handleSelectFilePrices = (file) => {
        this.uploadFilePrices(file);
    }

    handleSelectFileStock = (file) => {
        this.uploadFileStock(file);
    }

    handleSelectFileStatus = (file) => {
        this.uploadFileStatus(file);
    }

    handleSelectFileBoolStatus = (file) => {
        this.uploadFileBoolStatus(file);
    }

    handleSelectFileCohortPrice = (file) => {
        this.uploadFileCohortPrice(file);
    }

    handleSelectFileCohortMOQ = (file) => {
        this.uploadFileCohortMOQ(file);
    }

    handleOkUploadModal = () => {
        this.setState({
            uploadModal: false
        })
    }

    render() {

        //.log(this.props, "gfgfgfggf")

        if (!this.state._isSpinning) {
            return (
                <React.Fragment>
                    <div className="products-container">
                        <div className="breadcrumb-row-parent">
                            <Row
                                type="flex"
                                align="middle"
                                style={{ backgroundColor: "#313841" }}
                            >
                                <Col span={12}>
                                    <ProductDetailsTitle level={4}>Ecommerce Products</ProductDetailsTitle>
                                </Col>
                                <Col span={12}>
                                    <Row
                                        className="breadcrumb-row"
                                        type="flex"
                                        align="middle"
                                        justify="end"
                                        style={{ padding: "20px 0", backgroundColor: "#313841" }}
                                    >
                                        <Breadcrumb separator="">
                                            <Breadcrumb.Item>eCommerce</Breadcrumb.Item>
                                            <Breadcrumb.Separator>
                                                <Icon type="right" className="breadcrumb-icon" />
                                            </Breadcrumb.Separator>
                                            <Breadcrumb.Item href="">Wigme</Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Row className="table-container-parent">
                            <Row gutter={[12, 0]} type="flex" align="middle" style={{ marginBottom: "20px" }}>
                                {/* <Col lg={16} sm={12}>
                  <FilterBtn>Filter</FilterBtn>
                </Col> */}
                                <Col lg={3} md={3} sm={12}>
                                    <DealshubAdminProductsFilterModal tags={this.state.tags}
                                        locationUuid={this.props.locationUuid} />

                                </Col>

                                <Col lg={3} md={3} sm={6}>
                                    {/* <Row>
                    <Col span={8}>
                      <Tooltip title="Download template">
                        <Button onClick={() => {
                          window.open(fileTemplate);
                        }}
                          type="primary"
                          style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", width: "100%" }}
                          ghost
                        >
                          <Icon type="download" />
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col span={16}>
                      <UploadBtn style={{ width: "100%" }} beforeUpload={this.handleSelectFile} onRemove={this.handleUnselectFile} fileList={this.state.fileList}>
                        <Button loading={this.state.loading}
                          disabled={this.state.fileList.length !== 0 || this.state.published}
                          style={{ width: "100%", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}
                          type="primary"> Upload </Button>
                      </UploadBtn>
                    </Col>
                  </Row> */}
                                    <Button style={{ width: "100%", background: "blue", color: "white", border: "none" }}
                                        icon="upload" onClick={() => this.setState({ uploadModal: true })}>
                                        Upload
                                    </Button>
                                </Col>
                                <Col span={3}>
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        onClick={this.handleDownload}
                                    >
                                        Download
                                    </Button>
                                </Col>
                                {/*TODO:*/}
                                <Col lg={5} md={8} sm={24}>
                                    <Select
                                        dropdownClassName="search-chips"
                                        dropdownMatchSelectWidth="false"
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder={[<Icon type="search" />, " Search here..."]}
                                        onChange={(tags) => {
                                            window.sessionStorage.setItem(`${this.props.locationUuid}-ProdSearch`, JSON.stringify(tags))
                                            this.handleChangeSearchTags(tags)
                                        }}
                                        value={this.state.tags}
                                    />
                                </Col>

                                <Col lg={4} md={4} sm={24}>
                                    <Row
                                        style={{ border: "1px solid transparent", borderRadius: "4px" }}>
                                        <Col sm={12}
                                            style={{
                                                background: "#59caaa36",
                                                padding: "4px",
                                                border: "1px solid #59CAAA",
                                                borderRight: "none",
                                                borderTopLeftRadius: "4px",
                                                borderBottomLeftRadius: "4px"
                                            }}>
                                            <Row type="flex" justify="center">
                                                {this.props.dealsHubProduct && this.props.dealsHubProduct.products.active_products != undefined ?
                                                    <Tooltip
                                                        title={`Active Products : ${this.props.dealsHubProduct.products.active_products}`}>
                                                        <Text style={{
                                                            color: "#59CAAA",
                                                            cursor: "pointer"
                                                        }}>{this.props.dealsHubProduct.products.active_products}</Text>
                                                    </Tooltip>
                                                    :
                                                    <Icon style={{ padding: "4px", color: "#59CAAA" }} type="loading" />
                                                }

                                            </Row>
                                        </Col>

                                        <Col sm={12}
                                            style={{
                                                padding: "4px",
                                                background: "#f1556c36",
                                                border: "1px solid #F18090",
                                                borderLeft: "none",
                                                borderTopRightRadius: "4px",
                                                borderBottomRightRadius: "4px"
                                            }}>
                                            <Row type="flex" justify="center">
                                                {this.props.dealsHubProduct && this.props.dealsHubProduct.products.inactive_products != undefined ?

                                                    <Tooltip
                                                        title={`Inactive Products : ${this.props.dealsHubProduct.products.inactive_products}`}>
                                                        <Text style={{
                                                            color: "#F18090",
                                                            cursor: "pointer"
                                                        }}>{this.props.dealsHubProduct.products.inactive_products}</Text>
                                                    </Tooltip>
                                                    :
                                                    <Icon style={{ padding: "4px", color: "#F18090" }} type="loading" />
                                                }

                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={3} sm={12}>
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        icon="vertical-align-top"
                                        onClick={this.handelPublish}
                                    >
                                        Go Live
                                    </Button>
                                </Col>

                                <Col lg={3} sm={12}>
                                    <Button
                                        type="danger"
                                        icon="vertical-align-bottom"
                                        style={{ width: "100%" }}
                                        onClick={this.handelUnpublish}
                                    >
                                        Deactivate
                                    </Button>
                                </Col>


                            </Row>

                            <ListTableDealshubproducts
                                sendList={this.getList}
                                channelName={this.props.channelName}
                                tags={this.state.tags}
                                locationUuid={this.props.locationUuid}
                                b2b={this.props.b2b}
                                siteInfo={this.props.siteInfo}
                            />
                        </Row>
                    </div>

                    <Modal
                        title="Upload"
                        visible={this.state.uploadModal}
                        onOk={this.handleOkUploadModal}
                        onCancel={() => this.setState({ uploadModal: false })}
                        closable={false}
                        destroyOnClose={true}
                        width="30%"
                        style={{ display: "flex", justifyContent: "center" }}
                    >

                        <Row gutter={[12, 18]}>
                            <Col span={14} style={{ width: "100%" }}>
                                <Text>Choose the upload type:</Text>
                                <Select
                                    style={{ width: "100%", display: "inline" }}
                                    onSelect={(e) => this.setState({
                                        uploadType: e
                                    })}
                                    value={this.state.uploadType}
                                >
                                    <Option value="search">Search Products</Option>
                                    <Option value="price">Upload price</Option>
                                    <Option value="stock">Upload stock</Option>
                                    <Option value="status">Upload publish status</Option>
                                    {this.props.b2b === 'b2b' &&
                                        <Option value='cohort based price'>Upload cohort based price</Option>}
                                    {this.props.b2b === 'b2b' &&
                                        <Option value='cohort based MOQ'>Upload cohort based MOQ</Option>}
                                    <Option value="bool_status">Upload Status</Option>
                                </Select>
                            </Col>

                            {this.state.uploadType == "search" &&
                                <Col span={12}>
                                    <UploadBtn style={{ width: "100%" }} beforeUpload={this.handleSelectFile}
                                        onRemove={this.handleUnselectFile} fileList={this.state.fileList}>
                                        <Button loading={this.state.loading}
                                            disabled={this.state.fileList.length !== 0 || this.state.published}
                                            type="primary"> Upload </Button>
                                    </UploadBtn>
                                </Col>
                            }

                            {this.state.uploadType == "price" &&
                                <Col span={12}>
                                    <UploadBtn style={{ width: "100%", marginLeft: "5px" }}
                                        beforeUpload={this.handleSelectFilePrices}>
                                        <Button loading={this.state.loading}
                                            disabled={this.state.fileList.length !== 0 || this.state.published}
                                            type="primary"> Upload </Button>
                                    </UploadBtn>
                                </Col>
                            }

                            {this.state.uploadType == "stock" &&
                                <Col span={12}>
                                    <UploadBtn style={{ width: "100%", marginLeft: "5px" }}
                                        beforeUpload={this.handleSelectFileStock}>
                                        <Button loading={this.state.loading}
                                            disabled={this.state.fileList.length !== 0 || this.state.published}
                                            type="primary"> Upload </Button>
                                    </UploadBtn>
                                </Col>
                            }

                            {this.state.uploadType == "status" &&
                                <Col span={12}>
                                    <UploadBtn style={{ width: "100%", marginLeft: "5px" }}
                                        beforeUpload={this.handleSelectFileStatus}>
                                        <Button loading={this.state.loading}
                                            disabled={this.state.fileList.length !== 0 || this.state.published}
                                            type="primary"> Upload </Button>
                                    </UploadBtn>
                                </Col>
                            }

                            {this.state.uploadType == "bool_status" &&
                                <Col span={12}>
                                    <UploadBtn style={{ width: "100%", marginLeft: "5px" }}
                                        beforeUpload={this.handleSelectFileBoolStatus}>
                                        <Button loading={this.state.loading}
                                            disabled={this.state.fileList.length !== 0 || this.state.published}
                                            type="primary"> Upload </Button>
                                    </UploadBtn>
                                </Col>
                            }


                            {(this.state.uploadType == "cohort based price") &&
                                <Col span={12}>
                                    <UploadBtn style={{ width: "100%", marginLeft: "5px" }}
                                        beforeUpload={this.handleSelectFileCohortPrice}>
                                        <Button loading={this.state.loading}
                                            disabled={this.state.fileList.length !== 0 || this.state.published}
                                            type="primary"> Upload </Button>
                                    </UploadBtn>
                                </Col>
                            }

                            {this.state.uploadType == "cohort based MOQ" &&
                                <Col span={12}>
                                    <UploadBtn style={{ width: "100%", marginLeft: "5px" }}
                                        beforeUpload={this.handleSelectFileCohortMOQ}>
                                        <Button loading={this.state.loading}
                                            disabled={this.state.fileList.length !== 0 || this.state.published}
                                            type="primary"> Upload </Button>
                                    </UploadBtn>
                                </Col>
                            }


                            {this.state.uploadType == "search" &&
                                <Col span={18} style={{ width: "100%" }}>
                                    <Row>
                                        <Text>
                                            Download <Text
                                                onClick={() => {
                                                    window.open(fileTemplate);
                                                }}
                                                style={{ color: "#fff", cursor: "pointer" }}
                                            >this template</Text> for searching products.
                                        </Text>
                                    </Row>


                                </Col>
                            }

                            {(this.state.uploadType === 'cohort based price') &&
                                <Col span={18} style={{ width: "100%" }}><Row>
                                    <Text>
                                        Download <Text
                                            onClick={() => {
                                                window.open(fileTemplateB2bPrice);
                                            }}
                                            style={{ color: "#fff", cursor: "pointer" }}
                                        >this template</Text> for Cohort based now price and promotional price.
                                    </Text>
                                </Row>
                                </Col>}

                            {this.state.uploadType === 'cohort based MOQ' &&
                                <Col span={18} style={{ width: "100%" }}> <Row>
                                    <Text>
                                        Download <Text
                                            onClick={() => {
                                                window.open(fileTemplateB2bMoq);
                                            }}
                                            style={{ color: "#fff", cursor: "pointer" }}
                                        >this template</Text> for Cohort based MOQ.
                                    </Text>
                                </Row></Col>}

                            {this.state.uploadType == "price" &&
                                <Col span={18} style={{ width: "100%" }}>
                                    <Text>Download <Text
                                        onClick={() => {
                                            window.open(fileTemplate1);
                                        }}
                                        style={{ color: "#fff", cursor: "pointer" }}>this template</Text> for price
                                        upload</Text>
                                </Col>
                            }

                            {this.state.uploadType == "stock" &&
                                <Col span={18} style={{ width: "100%" }}>
                                    <Text>Download <Text
                                        onClick={() => {
                                            window.open(fileTemplate2);
                                        }}
                                        style={{ color: "#fff", cursor: "pointer" }}>this template</Text> for stock
                                        upload</Text>
                                </Col>
                            }

                            {this.state.uploadType == "status" &&
                                <Col span={18} style={{ width: "100%" }}>
                                    <Text>Download <Text
                                        onClick={() => {
                                            window.open(fileTemplate3);
                                        }}
                                        style={{ color: "#fff", cursor: "pointer" }}>this template</Text> for publish status
                                        upload</Text>
                                </Col>
                            }

                            {this.state.uploadType == "bool_status" &&
                                <Col span={18} style={{ width: "100%" }}>
                                    <Text>Download <Text
                                        onClick={() => {
                                            window.open(fileTemplate4);
                                        }}
                                        style={{ color: "#fff", cursor: "pointer" }}>this template</Text> for status
                                        upload</Text>
                                </Col>
                            }

                            {/* <Col span={18}>
                <Text>Note:</Text>
                <Input.TextArea style={{ background: "#3C4853" }} onChange={(e) => this.setState({ reportModalNote: e.target.value })} value={this.state.reportModalNote} />
              </Col> */}
                        </Row>

                    </Modal>
                </React.Fragment>
            );
        } else {
            return <Spin />;
        }
    }
}

const mapStateToProps = ({ dealsHubProducts }) => {
    return {
        ...dealsHubProducts
    };
};

export default connect(mapStateToProps, { fetchProductsByTags })(DealsHubAdminProducts);
