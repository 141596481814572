export const STORE = "STORE"
export const CLEAR = "CLEAR"

export function storePermissionAction(misc) {
    return {
        type: STORE,
        payload: misc
    }
}

export function clearPermissionAction() {
    return {
        type: CLEAR
    }
}