import React, { useEffect, useState } from "react";
import {
    Button,
    Row,
    Col,
    Typography,
    Icon,
    Empty,
    message,
    Spin,
    Tooltip,
} from "antd";
import isDev from "../../server";
import { Link } from "react-router-dom";
import placeHolderImage from "media/placeholder3.png";

import "./brand-page.scss";
import Axios from "axios";
const { Text, Paragraph } = Typography;

const serverUrl = isDev();

const BrandsPage = (props) => {
    const [brandList, setBrandList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [areBrandsAvailable, setAreBrandsAvailable] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    useEffect(() => {
        fetchBrandList();
    }, []);

    const fetchBrandList = (currentPage = 0, noLoading = false) => {
        setLoading(!noLoading);
        Axios.post(
            `${serverUrl}/fetch-nesto-brands/`,
            {
                page: currentPage + 1,
                is_pagination: true,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`,
                },
            }
        ).then((res) => {
            if (res.data.status === 200) {
                setBrandList((brandList) =>
                    brandList.concat(res.data.brand_list)
                );
                setAreBrandsAvailable(res.data.is_available);
                setCurrentPage(currentPage + 1);
                setLoading(false);
                setLoadMoreLoading(false);
            } else {
                message.error("Something went wrong!");
            }
        });
    };

    const handleLoadMore = () => {
        setLoadMoreLoading(true);
        fetchBrandList(currentPage, true);
    };

    return loading === false ? (
        <div className="brand-page-container">
            <Row>
                <Col span={21}>
                    <Text
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "20px",
                        }}
                    >
                        Manage Brands
                    </Text>
                </Col>
                <Col span={3}>
                    <Link to="/create-brand">
                        <Button
                            style={{
                                backgroundColor: "#1ABC9C",
                                borderColor: "#1ABC9C",
                            }}
                        >
                            <Text style={{ color: "white" }}>
                                {" "}
                                <Icon type="plus"></Icon>Add New Brand
                            </Text>
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row
                style={{
                    backgroundColor: "#36404A",
                    padding: "10px",
                    marginTop: "10px",
                }}
            >
                <Col span={6}>
                    <Text>BRAND NAME</Text>
                </Col>
                <Col span={11}>
                    <Text>DESCRIPTION</Text>
                </Col>
                <Col offset={1} span={4}>
                    <Text>ADDED ON</Text>
                </Col>
            </Row>
            {brandList.map((brand, index) => {
                return (
                    <BrandListItem
                        key={index}
                        brand={brand}
                        setBrandDetails={props.setBrandDetails}
                    />
                );
            })}
            {brandList.length === 0 && (
                <Empty style={{ marginTop: "20px" }}></Empty>
            )}
            {areBrandsAvailable ? (
                <div
                    className="center-child"
                    style={{
                        width: "100%",
                        padding: 20,
                    }}
                >
                    <Button
                        loading={loadMoreLoading}
                        type="primary"
                        onClick={() => {
                            handleLoadMore();
                        }}
                    >
                        Load More...
                    </Button>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </div>
    ) : (
        <Spin
            style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            tip="Fetching Brand list"
        />
    );
};

const BrandListItem = (props) => {
    return (
        <Link to={`/edit-brand-details/${props.brand.pk}`}>
            <Row
                style={{
                    backgroundColor: "#36404A",
                    padding: "10px",
                    marginTop: "10px",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ borderRadius: 8 }}>
                        <img
                            src={
                                props.brand.logo === ""
                                    ? placeHolderImage
                                    : props.brand.logo
                            }
                            alt="logo"
                            style={{
                                width: "44px",
                                height: "44px",
                                borderRadius: 8,
                            }}
                        />
                    </div>
                    <Text
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "16px",
                            paddingLeft: 15,
                        }}
                    >
                        {props.brand.name}
                    </Text>
                </Col>
                <Col span={11}>
                    <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ color: "white", marginBottom: 0 }}>
                        {props.brand.about_brand}
                    </Paragraph>
                </Col>
                <Col offset={1} span={4}>
                    <Text style={{ color: "white" }}>
                        {props.brand.created_date}
                    </Text>
                </Col>
                <Col
                    span={2}
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <div>
                        <Tooltip title="Edit">
                            <Icon
                                className="hover-icon"
                                type="edit"
                                style={{ padding: 5, fontSize: 16 }}
                            />
                        </Tooltip>
                        {/* <Tooltip title="Delete">
                            <Icon
                                className="hover-icon"
                                type="delete"
                                style={{ padding: 5, fontSize: 16 }}
                            />
                        </Tooltip> */}
                    </div>
                </Col>
            </Row>
        </Link>
    );
};

export default BrandsPage;
