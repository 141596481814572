import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Collapse,
  Typography,
  Radio,
  message,
  Upload,
  Modal,
  Select,
  Input,
  Card,
  Icon,
  Spin
} from "antd";
import { connect } from "react-redux";
import {
  AddNavbarCategory,
  FetchDealshubAdminCategory,
  handleUpdate,
  ChangeHeadingCategory,
  HandleImageUpdateLink
} from "../../actions/DealshubAdminNavbarCategories";
import "./deals-hub-admin-category.scss";
import styled from "styled-components";
import axios from "axios";

import isDev from "../../server";
const serverUrl = isDev();

const { Title } = Typography;
const { Panel } = Collapse;
const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const SaveBtn = styled(Button)`
  margin-right: 10px;
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG/GIF file!");
        }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class DealsHubAdminCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serverUrl: serverUrl,
      spinnerLoading: false,
      headerCategoryList: null,
      uploadingInProgress: false,
      isDeleteOn: false,
      previewVisible: false,
      modalFileUid: "",
      modalFileHttpLink: "",
      modalHeadingId: "",
      _isMounted: false
    };
  }

  componentDidMount = () => {
    this.props.FetchDealshubAdminCategory();

    axios
      .post(`${this.state.serverUrl}/dealshub/fetch-heading-category-list/`, {
        websiteGroupName: localStorage.getItem("websiteGroupName")
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(response => {
        this.setState({
          headerCategoryList: response.data.categoryList
        });
      });
  };

  saveCategory = event => {
    this.setState({
      spinnerLoading: true
    });
    let tempUuid = event.target.getAttribute("categoryid");
    let uuid = tempUuid.substr(0, tempUuid.indexOf("$"));
    let dataModel = {};

    for (let index in this.props.DealshubAdminNavbarCategories) {
      let current = this.props.DealshubAdminNavbarCategories[index];
      if (current.uuid === uuid) {
        dataModel["uuid"] = uuid;
        dataModel["headingName"] = current.headingName;
        dataModel["categoryList"] = current.categoryList;
      }
    }



    axios
      .post(`${this.state.serverUrl}/dealshub/save-heading-data/`, {
        websiteGroupName: localStorage.getItem("websiteGroupName"),
        dataObj: dataModel
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(response => {
        this.setState({
          spinnerLoading: false
        });
      });
  };

  addCategory = () => {
    this.props.AddNavbarCategory(
      this.props.DealshubAdminNavbarCategories.length
    );
  };

  changeCategoryName = event => {
    let tempUuid = event.target.getAttribute("data-categoryid");
    let uuid = tempUuid.substr(0, tempUuid.indexOf("$"));
    this.props.handleUpdate({ uuid, value: event.target.value });
  };

  handleChange = (file, uuid) => {
    if (!this.state.uploadingInProgress && !this.state.isDeleteOn) {
      this.setState({ uploadingInProgress: true });

      let currentImage = file.file;
      let formData = new FormData();
      formData.append("image", currentImage.originFileObj);
      formData.append("uuid", uuid);

      if (currentImage.originFileObj !== undefined) {
        axios
          .post(
            `${this.state.serverUrl}/dealshub/upload-image-heading/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${localStorage.getItem("token")}`
              }
            }
          )
          .then(response => {
            let temp = this.props.DealshubAdminNavbarCategories;

            for (let index = 0; index < temp.length; ++index) {
              if (temp[index]["uuid"] === uuid) {
                temp[index]["imageList"].push(response.data.dataObj);
              }
            }

            temp[temp.length - 1]["uid"] = response.data.uuid;



            // set imageList to new values

            this.setState({
              fileList: [...[], ...temp],
              uploadingInProgress: false
            });
          });
      }
    }
  };

  deleteCategory = event => {
    this.setState({
      spinnerLoading: true
    });

    let tempUuid = event.target.getAttribute("categoryid");
    let uuid = tempUuid.substr(0, tempUuid.indexOf("$"));
    let requiredIndex = 0;
    for (let index in this.props.DealshubAdminNavbarCategories) {
      if (this.props.DealshubAdminNavbarCategories[index].uuid === uuid) {
        requiredIndex = index;
        break;
      }
    }

    axios
      .post(`${this.state.serverUrl}/dealshub/delete-heading/`, {
        uuid: uuid
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(response => {
        this.setState({
          spinnerLoading: false
        });
      });

    var tempState = this.props.DealshubAdminNavbarCategories.splice(
      requiredIndex,
      1
    );

    this.props.handleUpdate(this.props.DealshubAdminNavbarCategories);
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  handlePreview = async (file, uuid) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      modalFileUid: file.uid,
      modalFileHttpLink: file.httpLink,
      modalHeadingId: uuid
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  removeFile = (file, headerUuid) => {
    this.setState({
      isDeleteOn: true
    });

    axios
      .post(`${this.state.serverUrl}/dealshub/delete-image-heading/`, {
        uuid: file.uid
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(response => {

        for (let index in this.props.DealshubAdminNavbarCategories) {
          if (
            this.props.DealshubAdminNavbarCategories[index].uuid === headerUuid
          ) {
            let requiredObj = this.props.DealshubAdminNavbarCategories[index][
              "imageList"
            ];
            for (let index2 = 0; index2 < requiredObj.length; ++index2) {
              if (requiredObj[index2]["uid"] == file.uid) {
                requiredObj.splice(index2, 1);
              }
            }
            break;
          }
        }

        this.setState({
          isDeleteOn: false
        });
      });
  };

  handleCategoriesListingChange = (value, uuid) => {

    this.setState({
      spinnerLoading: true
    });
    let dataModel = {};

    for (let index in this.props.DealshubAdminNavbarCategories) {
      let current = this.props.DealshubAdminNavbarCategories[index];
      if (current.uuid === uuid) {
        dataModel["uuid"] = uuid;
        dataModel["headingName"] = current.headingName;
        dataModel["categoryList"] = value;
      }
    }

    this.props.ChangeHeadingCategory({
      originalData: this.props.DealshubAdminNavbarCategories,
      uuid: uuid,
      newCategoryList: value
    });



    axios
      .post(`${this.state.serverUrl}/dealshub/save-heading-data/`, {
        websiteGroupName: localStorage.getItem("websiteGroupName"),
        dataObj: dataModel
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(response => {
        this.setState({
          spinnerLoading: false
        });
      });
  };

  updateModalData = headingUuid => {
    axios
      .post(`${this.state.serverUrl}/dealshub/update-image-heading-link/`, {
        websiteGroupName: localStorage.getItem("websiteGroupName"),
        uuid: this.state.modalFileUid,
        httpLink: this.state.modalFileHttpLink
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(response => {
        this.props.HandleImageUpdateLink({
          headingUuid: headingUuid,
          fileUuid: this.state.modalFileUid,
          link: this.state.modalFileHttpLink
        });
        this.setState({ previewVisible: false });
      });
  };

  changeModalFileHttpLink = event => {
    this.setState({ modalFileHttpLink: event.target.value });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (
      this.props.DealshubAdminNavbarCategories &&
      this.state.headerCategoryList
    ) {
      const categoryListTemplate = [];
      const { previewVisible, previewImage } = this.state;
      const uploadButton = (
        <div>
          <Icon type="camera" />
          <div className="ant-upload-text">
            You can drag and drop images or upload images
          </div>
        </div>
      );

      this.state.headerCategoryList.map(categoryTemp =>
        categoryListTemplate.push(
          <Option value={categoryTemp.uuid} key={categoryTemp.uuid}>
            {categoryTemp.name}
          </Option>
        )
      );



      return (
        <Row className="dealshubadmincontainer">
          <Row
            className="buttons"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <Col>
              <Title style={{ marginBottom: "0px", color: "white" }} level={4}>
                DealsHub Navbar Admin
              </Title>
            </Col>
            <Col>
              <Button type="primary" onClick={this.addCategory}>
                Add Heading
              </Button>
            </Col>
          </Row>
          <Col span={24} className="admin-category-container">
            <Spin spinning={this.state.spinnerLoading}>
              {this.props.DealshubAdminNavbarCategories.map(temp => (
                <Row key={temp.uuid} gutter={[16, 8]}>
                  <Col xs={24}>
                    <div className="collapse-div">
                      <Collapse expandIconPosition="left">
                        <Panel header={temp.headingName} key={temp.uuid}>
                          <div className="content-container">
                            <div className="deals-hub-category-banner-container">
                              <Row>

                                <Col
                                  span={24}
                                  className="category-search-bar-container"
                                >
                                  <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                      <Text className="category-label">
                                        Category name
                                     </Text>
                                      <Input
                                        data-categoryid={
                                          temp.uuid + "$category-name"
                                        }
                                        defaultValue={temp.headingName}
                                        onChange={this.changeCategoryName}
                                      />
                                    </Col>
                                    <Col span={24} className="">
                                      <SaveBtn
                                        type="primary"
                                        categoryid={temp.uuid + "$save"}
                                        onClick={this.saveCategory}
                                      >
                                        Save
                                      </SaveBtn>
                                      <Button
                                        type="danger"
                                        categoryid={temp.uuid + "$delete"}
                                        onClick={this.deleteCategory}
                                      >
                                        Delete
                                      </Button>
                                    </Col>
                                    <Col span={24}>
                                      <Text className="category-label">
                                        Select Category
                                      </Text>
                                      {this.state.headerCategoryList ? (
                                        <Select
                                          labelInValue
                                          mode="tags"
                                          style={{ width: "100%" }}
                                          placeholder="Categories"
                                          onChange={value =>
                                            this.handleCategoriesListingChange(
                                              value,
                                              temp.uuid
                                            )
                                          }
                                          value={temp.categoryList}
                                          onRemove={this.handleCategoriesRemoveFile}
                                        >
                                          {categoryListTemplate}
                                        </Select>
                                      ) : (
                                          <React.Fragment></React.Fragment>
                                        )}
                                    </Col>

                                  </Row>



                                </Col>
                              </Row>

                              <div style={{ marginBottom: ".5em" }}>
                                <Text>
                                  Maximum 2 Images Allowed
                                </Text>
                                <br />
                                <Text>
                                  Resolution: 300px * 116px
                                </Text>
                                <br />
                                <Text >
                                  File Size Range: 5KB - 100KB
                                 </Text>
                              </div>
                              <Upload
                                listType="picture-card"
                                fileList={temp.imageList}
                                onPreview={file =>
                                  this.handlePreview(file, temp.uuid)
                                }
                                onChange={file =>
                                  this.handleChange(file, temp.uuid)
                                }
                                onRemove={file =>
                                  this.removeFile(file, temp.uuid)
                                }
                                customRequest={this.dummyRequest}
                              >
                                {temp.imageList.length >= 2
                                  ? null
                                  : uploadButton}
                              </Upload>
                              <Modal
                                visible={previewVisible}
                                footer={null}
                                onCancel={this.handleCancel}
                              >
                                <img
                                  alt="example"
                                  style={{ width: "100%" }}
                                  src={previewImage}
                                />
                                <div className="img-url-wrapper">
                                  <p>Enter URL:</p>
                                  <Input
                                    value={this.state.modalFileHttpLink}
                                    onChange={this.changeModalFileHttpLink}
                                  />
                                  <div className="modal-submit-btn">
                                    <Button
                                      onClick={() => {
                                        this.updateModalData(temp.uuid);
                                      }}
                                      type="primary"
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </Col>
                </Row>
              ))}
            </Spin>
          </Col>
        </Row>
      );
    } else {
      return <Spin></Spin>;
    }
  }
}

const mapStateToProps = ({ DealshubAdminNavbarCategories }) => {
  return {
    DealshubAdminNavbarCategories
  };
};

export default connect(mapStateToProps, {
  AddNavbarCategory,
  FetchDealshubAdminCategory,
  handleUpdate,
  ChangeHeadingCategory,
  HandleImageUpdateLink
})(DealsHubAdminCategory);
