import React, { useEffect, useState } from "react";
import { Row, Col, Menu, Dropdown, Icon, Input, Upload, Spin, message, Modal } from "antd";
import axios from "axios";

import "./image-manager.scss";
import isDev from "../../../../server";

const serverUrl = isDev();

const ImageManager = (props) => {
    const [fileList, setFileList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [typeSelected, setTypeSelected] = useState("FRONT IMAGES");
    const [isDelete, setIsDelete] = useState(false);
    const [deleteImageModal, setDeleteImageModal] = useState(false);
    const [deleteFile, setDeleteFile] = useState();

    const onClick = (item) => {
        let str = item.item.props.children[0];
        setTypeSelected(str);
    };

    useEffect(() => {
        if(JSON.stringify(props.images) !== '{}') {
            let tempArr = [props.images[`${typeSelected.split(" ").join("_").toLowerCase()}`]], temp=[];
            for(let i=0; i<tempArr[0].length; i++) {
                temp = [
                    ...temp,
                    {
                        uid: i,
                        hdurl: tempArr[0][i].original_image || tempArr[0][i].mid_image,
                        url: tempArr[0][i].thumbnail_image || tempArr[0][i].mid_image,
                        pk: tempArr[0][i].pk,
                        status: "done"

                    }
                ]
            }
            setFileList(temp);
        }
        
    }, [props.images, typeSelected]);

    const handlePreview = (file) => {
        window.open(file.hdurl, "_blank");
    }

    const handleUpload = ({file, files}) => {
        if(!isDelete){
            setIsUploading(true);

            let imageData = new FormData(), noOfImages = fileList - files;
            imageData.append("image_0", file);
            imageData.append('product_uuid', props.product_pk);
            imageData.append("image_count",1);
            let splitStr = typeSelected.split(" "), imageType;
            if(splitStr.length === 3) {
                imageType = "product_content";
            } else {
                imageType = splitStr[0].toLowerCase();
            }
            imageData.append("image_type", imageType);

            axios.post(`${serverUrl}/add-nesto-product-images/`, imageData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")}`,
                },
            }).then(res => {
                props.handleChange(true);
                setIsUploading(false);
            })
        }
    }

    const handleRemove = (file) => {
        setDeleteImageModal(true);
        setDeleteFile(file);
    }

    const onOkDeleteModal = () => {
        setIsDelete(true);
        axios.post(`${serverUrl}/remove-nesto-product-image/`,
        {
            image_pk: deleteFile.pk,
            product_uuid: props.product_pk
        }, {
            headers: {
                Authorization: `JWT ${localStorage.getItem(
                    "token"
                )}`,
            },
        }).then(res => {
            props.handleChange(true);
            //console.log(res);
            setDeleteImageModal(false);
            setIsDelete(false);
        })
    }

    const menu = (
        <Menu onClick={onClick}>
            {props.images &&
                Object.keys(props.images).map((key, index) => (
                    <Menu.Item key={index}>
                        {key.split("_").join(" ").toUpperCase()} (
                        {props.images[key] && props.images[key].length})
                    </Menu.Item>
                ))}
        </Menu>
    );
    return (
        <React.Fragment>
            <Row gutter={[0, 0]}>
                <Col span={24} style={{ margin:'10px 0px'}}>
                    <Dropdown overlay={menu} trigger={["click"]} >
                        <a
                            style={{ color: "white" , backgroundColor:'#313841', padding:'10px 0px 10px 16px', borderRadius:'5px'}}
                            className="ant-dropdown-link"
                            href="#"
                        >
                            {typeSelected} <Icon type="down" style={{paddingLeft:'200px'}} />
                        </a>
                    </Dropdown>
                </Col>
                <Col span={24}>
                    <Upload
                        disabled={props.createProduct}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader image-list"
                        showUploadList={{showRemoveIcon: props.canDeleteImage}}
                        beforeUpload={() => false}
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleUpload}
                        onRemove={handleRemove}
                        multiple={true}
                        id="aditional-button"
                        style={{ height: "150px" }}
                    >
                        <div>
                            {
                                <Icon type={isUploading ? "loading" : "plus"} />
                            }{" "}
                            {/* {isUploading && <Spin />} */}
                            <div className="ant-upload-text" style={{color: props.createProduct && "red"}}>
                                {props.createProduct ? (
                                    "You cannot add images while creating a new product"
                                ) : (
                                    "You can drag and drop images or upload images"
                                )}
                            </div>
                        </div>
                    </Upload>
                </Col>
            </Row>
            <Modal
                className="image-list"
                title="Delete Image"
                onOk={onOkDeleteModal}
                visible={deleteImageModal} 
                onCancel={() => setDeleteImageModal(false)}
                okText="Yes"
                confirmLoading={isDelete}
                width="fit-content"
            >
                <p>Are you sure you wanted to delete the image?</p>
            </Modal>
        </React.Fragment>
    );
};

export default ImageManager;
