import React from "react"
import { Card, Typography } from "antd"
import './playground.scss'
import { locationUuidConst } from '../../constants/locationUuid'

const { Text } = Typography


const AddressCard = ({ id, onClick, address, selected, locationGroupUuid }) => {

    return (
      <Card
        className="address-card"
        key={id}
        onClick={() => onClick(selected == id ? null : id)}
        style={{ border: selected == id ? "4px solid #1abc9c" : "" }}
      >
        <Text
          style={{ fontWeight: "bold", color: selected == id ? "#1abc9c" : "" }}
        >
          {address.firstName}
        </Text>
        <br />
        <Text style={{ color: selected == id ? "#1abc9c" : "" }}>
          {address.line1 || "-"}
        </Text>
        <br />
        <Text style={{ color: selected == id ? "#1abc9c" : "" }}>
          {address.line2 || "-"}
        </Text>
        <br />
        <Text style={{ color: selected == id ? "#1abc9c" : "" }}>
          {address.line3 || "-"}
        </Text>
        <br />
        <Text style={{ color: selected == id ? "#1abc9c" : "" }}>
          {address.line4 || "-"}
        </Text>
        <br />
        {
            !(locationGroupUuid === locationUuidConst.Geepas_uganda || locationGroupUuid === locationUuidConst.QA_geepas_uganda) ? (address.emirates != "" && (
          <Text style={{ color: selected == id ? "#1abc9c" : "" }}>
            {Array.of(
              locationUuidConst.WIGme_BAH,
              locationUuidConst.WIGme_KWT,
              locationUuidConst.QA_wigme_BAH,
              locationUuidConst.QA_wigme_KWT
            ).includes(locationGroupUuid)
              ? "Governorate"
              : "Emirates"}{" "}
            - {address.emirates || "-"}
          </Text>
        )): (<Text style={{ color: selected == id ? "#1abc9c" : "" }}>
            Region{" "} - {address.emirates || "-"}
        </Text>)}
      </Card>
    );
}

export default AddressCard