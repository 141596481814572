import React, { Component } from "react";
import {
  TableContainer,
  TableHead,
  TableHeadText,
  WhSelect,
  PriceSelect,
  WhOption,
  PriceOption,
  TableRow,
  TableRowText,
  TableRowCollapse
} from "../listtable/styled-components-library";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Ellipsis } from "react-spinners-css";
import {
  fetchAll,
  addToExportList,
  removeFromExportList,
  fetchAllMore
} from "../../../actions/exports";

import {
  Row,
  Col,
  Avatar,
  Collapse,
  Checkbox,
  Tag,
  Icon,
  Button,
  Tooltip,
  message,
  Typography
} from "antd";

import "./listtable.scss";
import ProductListCrudItems from "../../dealshubadmin/ProductListCrudItems";

import isDev from "../../../server";
const serverUrl = isDev(); 

// Imports From Styled Components

const { Panel } = Collapse;
const {Text} = Typography;

class ListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      pk: null,
      selectTopButton: false,
      all_products_for_checked: {},
      selected: 0,
      selectAllBackend: false,
      selectAllBackendVisible: false,
      matchingDrawer : false,
      selectedProductsForMatching : [],
      matchedLoader : false,
      feed_submission_id : ''
    };
  }

  componentDidUpdate(prevProps , prevState) {
    if(prevState.matchingDrawer != this.state.matchingDrawer)
    {
      this.props.updateMatchingDrawer(this.state.matchingDrawer)
    }
  }

  getSelectedCount = () => {
    setTimeout(() => {
      let arr = Object.keys(this.state.all_products_for_checked);
      let count = 0;
      arr.map(e => {
        if (this.state.all_products_for_checked[e]) count++;
      });
      this.setState({ selected: count });
    }, 5);
  };

  handleSelectOnPage = e => {
    this.setState({ selectAllBackendVisible: !this.state.selectTopButton });

    // UI Turn On Off  button
    this.setState({ selectTopButton: !this.state.selectTopButton });


    // handle to backend(redux)
    this.props.channelwiseState.products.products.map(e => {
      if (
        this.state.all_products_for_checked[e.product_pk] == undefined ||
        this.state.all_products_for_checked[e.product_pk] == false
      ) {
        if (this.state.selectTopButton == false)
          this.props.addToExportList(e.product_pk);
      } else if (this.state.selectTopButton == true)
        this.props.removeFromExportList(e.product_pk);
    });

    // handle selected elements in frontend
    this.props.channelwiseState.products.products.map(e => {
      this.setState(prevState => ({
        all_products_for_checked: {
          ...prevState.all_products_for_checked,
          [e.product_pk]: prevState.selectTopButton
        }
      }), () => this.props.checkedUpdate(this.state.all_products_for_checked));
    });

    this.setState({ selectAllBackend: false });
    this.props.selectOnBackend(false);

    this.getSelectedCount();

    this.setState({ checkedAllOnPage: !this.state.checkedAllOnPage });
  };

  handelSelectAllBackend = e => {
    this.setState({ selectAllBackend: !this.state.selectAllBackend });
    this.props.selectOnBackend(!this.state.selectAllBackend);
  };

  //force means we force the value of check that is true or false
  handelCheck = (e, force = null) => {

    if (force != null) {
      if (force)
        this.props.addToExportList(e);
      else
        this.props.removeFromExportList(e);

      this.setState({
        all_products_for_checked: {
          ...this.state.all_products_for_checked,
          [e]: force
        }
      }, () => {this.props.checkedUpdate(this.state.all_products_for_checked);});

      this.setState({ selectAllBackend: false });
      this.props.selectOnBackend(false);

      this.getSelectedCount();

      return;
    }

    if (
      this.state.all_products_for_checked[e] == undefined ||
      this.state.all_products_for_checked[e] == false
    )
      this.props.addToExportList(e);
    else this.props.removeFromExportList(e);


    this.setState({
      all_products_for_checked: {
        ...this.state.all_products_for_checked,
        [e]: this.state.all_products_for_checked
          ? !this.state.all_products_for_checked[e]
          : true
      }
    }, () => {this.props.checkedUpdate(this.state.all_products_for_checked);this.updateSelectedProductsForMatching()});

    this.setState({ selectAllBackend: false });
    this.props.selectOnBackend(false);

    this.getSelectedCount();

  };

  handleLoadMore = () => {
    this.setState({ selectTopButton: false , loadMoreLoading : true });
    this.props.fetchAllMore(
      this.props.channelName,
      this.props.channelwiseState.filters,
      this.props.channelwiseState.tags,
      () => {
        this.setState({
          loadMoreLoading : false
        })
      }
    );
  };

  RedirectToEdit = pk => {
    this.setState({ redirect: true, pk });
    // setTimeout(() => window.location.reload(), 10);
  };

  updateSelectedProductsForMatching = (callback = null) => {
    let origProducts = this.props.channelwiseState.products.products;
    let selectedProductsForMatching = [];
    for(let i = 0;i<origProducts.length;i++)
    {
      if( this.state.all_products_for_checked[origProducts[i].product_pk])
        selectedProductsForMatching.push(origProducts[i]);
    }
    this.setState({
      selectedProductsForMatching,
    },() => {if(callback)callback()})
  }

  matching = () => {

    let channelName = this.props.channelName.replace(" ","-").toLowerCase(); 

      this.setState({
        matchedLoader: true,
      });

      let selectedProductsForMatching_copy = this.state.selectedProductsForMatching.filter((e) => 
        e.matched_products == undefined
      );

      console.log(selectedProductsForMatching_copy , "Hey");
  
      let product_pk_list = selectedProductsForMatching_copy.map((e) =>
        parseInt(e.product_pk)
      );
  
      let data = {
        product_pk_list,
      };
  
      //.log(data);
  
      fetch(`${serverUrl}/mws/get-matching-products-${channelName}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          let selectedProductsForMatching = this.state.selectedProductsForMatching;
          let pkwise_matched = {};
  
          if (data.status == 200) {
            let matched_products = data.matched_products_list;
            for (let i = 0; i < matched_products.length; i++) {
              pkwise_matched[matched_products[i].product_pk] =
                matched_products[i];
            }
  
            for (let i = 0; i < selectedProductsForMatching.length; i++) {
              if(pkwise_matched[selectedProductsForMatching[i].product_pk])
              {
                selectedProductsForMatching[i].matched_products =
                pkwise_matched[selectedProductsForMatching[i].product_pk];
                selectedProductsForMatching[i].is_matched = pkwise_matched[selectedProductsForMatching[i].product_pk].status == "Success";
              }
            }
  
            this.setState({
              selectedProductsForMatching,
            });
          }
          else if(data.status === 405)
          {
            message.info("You are not authorised!")
          }
          else
        {
          message.error("Something went wrong!");
        }
          this.setState({
            matchedLoader: false,
          });
        });
    };

  matchingFlowStart = () => {
    this.updateSelectedProductsForMatching(this.matching);
    this.setState({
      matchingDrawer : true
    })
  }

  matchingFlowEnd = () => {
    this.setState({
      matchingDrawer : false
    })
  }

  publishMatchingProducts = () => {
    let channelName = this.props.channelName.replace(" ","-").toLowerCase();

    console.log(this.state.selectedProductsForMatching);

    let selectedProductsForMatching_Copy = this.state.selectedProductsForMatching
    
    // .filter((e) =>
    //   e.is_matched
    // );

    let product_pk_list = selectedProductsForMatching_Copy.map((e) =>
      parseInt(e.product_pk)
    );

    let data = {
      product_pk_list,
    };

    
    this.setState({
      loadingPublishing: true,
    });

    fetch(`${serverUrl}/mws/push-products-${channelName}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {

        this.setState({
          loadingPublishing: false,
        });
    
        
        if (data.status == 200) {
          this.setState({
            feed_submission_id: data.feed_submission_id,
          });

          message.success(
            "Successfully published please check reports in Reports Page for feed product id = " +
            data.feed_submission_id
          );
        }
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
        else {
          this.setState({
            timerOn: false,
          });
          message.error("Something went Wrong!");
        }
       
      });
  }

  collapseHeader2 = e => (
    <TableRow type="flex" align="middle" style = {{height : "100px"}} key = {e.product_pk}>
      <Col span={4}>
        <TableRowText>{e.brand_name || "NA"}</TableRowText>
      </Col>
      <Col span={4}>
        <Row>
          <TableRowText style={{ paddingRight: "15px" }} ellipsis={{ rows: 1, expandable: false }}>{e.category || "NA"}</TableRowText>

          <TableRowText style={{ paddingRight: "15px" }} ellipsis={{ rows: 1, expandable: false }}>{e.sub_category || "NA"}</TableRowText>
        </Row>
      </Col>

      <Col span={4}>
        <ProductListCrudItems
          value={e.now_price}
          name="now_price"
          type="float"
          url="update-channel-product-stock-and-price/"
          editOn={() => this.handelCheck(e.product_pk, true)}
          request_data={
            {
              "product_pk": e.product_pk,
              "channel_name": this.props.channelName,
            }
          }
        />
        <br />
        <ProductListCrudItems
          value={e.was_price}
          line_through
          name="was_price"
          type="float"
          url="update-channel-product-stock-and-price/"
          editOn={() => this.handelCheck(e.product_pk, true)}
          request_data={
            {
              "product_pk": e.product_pk,
              "channel_name": this.props.channelName,
            }
          }
        />
      </Col>

      <Col span={4}>
        <ProductListCrudItems
          value={e.stock}
          name="stock"
          type="int"
          url="update-channel-product-stock-and-price/"
          editOn={() => this.handelCheck(e.product_pk, true)}
          request_data={
            {
              "product_pk": e.product_pk,
              "channel_name": this.props.channelName,
            }
          }
        />
      </Col>

      <Col span={4}>
        {
          e.status == "New" &&
          (
            <Tag color="blue" style={{ marginBottom: "10px" }}>
            New
          </Tag>
          )
        }
        {e.status == "Active" && (
          <Tag color="green" style={{ marginBottom: "10px" }}>
            Active
          </Tag>
        )}
        {e.status == "Inactive" && (
          <Tag color="red" style={{ marginBottom: "10px" }}>
            Inactive
          </Tag>
        )}
        {
          e.status == "Listed" && (
            <Tag color="orange" style={{ marginBottom: "10px" }}>
              Listed
            </Tag>
          )}
      </Col>
      <Col span={2}>
   
          <Tooltip title="Edit product">
            <Icon
              onClick={() => this.RedirectToEdit(e.product_pk)}
              type="form"
              style={{ fontSize: "1.3em", color: "grey", paddingLeft: "10px" }}
            />
          </Tooltip>

          {/* <Icon type="laptop" style={{ fontSize: "1.3em", color: "grey" }} /> */}
   
      </Col>
    </TableRow>
  )

  redirectToAmazon = (link) => {
    if(link != "")
    window.open(link , '_blank');
  }

  collapseHeader1 = e => (
    <TableRow type="flex" align="middle" style = {{height : "100px"}} key = {e.product_pk}> 
      <Col span={3}>
        <Checkbox
          checked={
            this.state.all_products_for_checked[e.product_pk] ||
            this.state.selectAllBackend
          }
          onChange={() => this.handelCheck(e.product_pk)}
        ></Checkbox>
      </Col>
      <Col span={8}>
        <Row gutter={[8, 8]} style={{ width: "80%" }}>
          <Col span={24}>
            <Avatar shape="square" size={68} src={e.main_image} />
          </Col>
        </Row>
      </Col>

      <Col span={13}>
        <Row>
          <TableRowText style={{ color: "white" }} className="text-ellipsis">
            {e.product_name}
          </TableRowText>
          <TableRowText>{e.seller_sku}</TableRowText>
        </Row>
      </Col>
    </TableRow>
  );

  collapseHeader_matchingData = e => (
    <TableRow type="flex" align="middle" style = {{height : "100px"}}>
      <Col span = {3}></Col>
    <Col span={8}>
                <TableRowText
                  className="header-text"
                  style={{
                    marginBottom: "0px",
                    paddingRight: "20px",
                  }}
                  ellipsis={{ rows: 2, expandable: false }}
                  onClick = {() => this.redirectToAmazon((e.matched_products && e.matched_products.amazon_url)||"")}
                >
                   {(e.matched_products &&
                        e.matched_products
                        .matched_product_title)
                    }
                    {
                      !e.matched_products && this.state.matchedLoader ?   <Ellipsis color="#1ABC9C" size={40} /> :
                        !(e.matched_products &&
                          e.matched_products
                            .matched_product_title) && 'No matching found'
                    }                
                </TableRowText>                
    </Col>
    <Col span={4}>
        <TableRowText
          className="row-text"
          style={{ marginBottom: "0px" }}
          ellipsis={{ rows: 1, expandable: false }}
        >
          {e.is_matched == undefined && this.state.matchedLoader ? <Ellipsis color="#1ABC9C" size={40} /> :
            (e.is_matched ?
            <Tag color="green">Exsiting</Tag> : 
            <Tag color="orange">New</Tag>)}
        </TableRowText>                          
    </Col>

    <Col span={5}>
    <TableRowText
       className="header-text"
       style={{
         marginBottom: "0px",
         paddingRight: "20px",
         color : e.is_matched ? "green" : "red"
       }} 
       ellipsis={{ rows: 2, expandable: false }}
     >
        { this.state.matchedLoader && e.is_matched == undefined ?  <Ellipsis color="#1ABC9C" size={40} />  : (e.is_matched ? `You can publish it on ${this.props.channelName}` : `New products can not be published on ${this.props.channelName}`)}
    </TableRowText>
    </Col>
  </TableRow>
  )

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={`/edit-channel-product/${this.props.channelName
            .split(" ")
            .join("-")
            .toLowerCase()}/${this.state.pk}`}
        ></Redirect>
      );
    }
    console.log(this.state.selectedProductsForMatching);

    return (
      <React.Fragment>
        <TableContainer type="flex" align="middle" className="table-container">


          <TableHead type="flex" align="middle" className="table-header">
            {this.state.selected > 0 && (
              <Col span={24} style={{ marginBottom: "12px" }}>
                <TableHeadText>
                  Selected{" "}
                  {this.state.selectAllBackend
                    ? this.props.total_products
                    : this.state.selected}
                </TableHeadText>
                {this.state.selectAllBackendVisible && (
                  <Button
                    style={{ marginLeft: "12px" }}
                    size="small"
                    type={this.state.selectAllBackend ? "primary" : ""}
                    onClick={this.handelSelectAllBackend}
                  >
                    {" "}
                    Select All Products{" "}
                  </Button>
                )}
              </Col>
            )}

            <Col span = {9}>
                  <Row gutter = {[8,8]}>
                    <Col span = {3}>
                    <Checkbox
                        checked={
                          this.state.selectTopButton || this.state.selectAllBackend
                        }
                        onChange={this.handleSelectOnPage}
                      ></Checkbox>
                    </Col>
                    <Col span = {8}>
                        <TableHeadText>Images</TableHeadText>
                    </Col>
                    <Col span = {13}>
                        <TableHeadText>Product ID</TableHeadText>
                    </Col>
                  </Row>
            </Col>
            {
              this.state.matchingDrawer ? 
              <Col span = {15} style = {{overflow : "hidden"}}>
              <Row gutter = {[8,8]} className = "slide-in-left" type = "flex" align = "middle">
                      <Col span = {3}>
                    
                         <Icon style = {{
                            paddingLeft : "10px",
                            color : "white",
                            cursor : "pointer"
                          }} type="right-square" onClick = {this.matchingFlowEnd}/>
                   
                      </Col>
                      <Col span = {8}>
                          <TableHeadText>Matched</TableHeadText>
                      </Col>
                      <Col span = {4}>
                          <TableHeadText>New/Existing</TableHeadText>
                     
                     </Col>
                     <Col span = {3}>
                          <TableHeadText>Status</TableHeadText>
                     </Col>

                    <Tooltip title = "Publish Matched Products">
                     <Col span = {5}>
                          <Button onClick = {this.publishMatchingProducts} icon = "amazon" disabled = {this.state.matchedLoaders}
                          style = {!this.state.matchedLoader ? {color : "white" , background : "#F3A847" , border : "none" , width : "100%"} : {width : "100%"}} loading = {this.state.loadingPublishing}>Publish</Button>
                     </Col>
                     </Tooltip>
                    </Row>    
              </Col>
              : 
              <Col span = {15}>
              <Row gutter = {[8,8]}>
                      <Col span = {4}>
                      <TableHeadText>Brand</TableHeadText>
                      </Col>
                      <Col span = {4}>
                          <TableHeadText>Domain</TableHeadText>
                     
                      </Col>
                      <Col span = {4}>
                          <TableHeadText>Price</TableHeadText>
                     
                     </Col>
                     <Col span = {4}>
                          <TableHeadText>Stock</TableHeadText>
                     
                     </Col>
                     <Col span = {4}>
                          <TableHeadText>Ch. Status</TableHeadText>
                     
                     </Col>
                     <Col span = {2}>
                         <TableRowText style = {{color : "white"}} ellipsis = {{row : "1" , expandable : false}}>Actions</TableRowText>
                     
                     </Col>
                     <Col span = {2}>
                       {
                          (this.props.channelName === "Amazon UK" || this.props.channelName === "Amazon UAE") && 
                            <Tooltip title = "Match Selected Products">
                                <Icon style = {{
                                  paddingLeft : "10px",
                                  color : "white",
                                  cursor : "pointer"
                                }} type="left-square" onClick = {this.matchingFlowStart} />
                          </Tooltip>
                       }
                     </Col>
                    </Row>    
              </Col>
            }
          </TableHead>

          {
            this.state.matchingDrawer ?
            <div className="products-table-content" >
            <Row style = {{overflow : "hidden"}}>
              <Col span = {9}>
              {this.state.selectedProductsForMatching &&
              this.state.selectedProductsForMatching.map(e => {
                return (
                  <TableRowCollapse key={e.product_pk}>
                    <Panel
                      key="1"
                      showArrow={false}
                      extra={this.collapseHeader1(e)}
                    >
                    </Panel>
                  </TableRowCollapse>
                );
              })}
              </Col>
              <Col span = {15} style = {{height : "100%" , borderLeft : "5px solid #1ABC9C"}} className = "slide-in-left">
              {this.state.selectedProductsForMatching &&
              this.state.selectedProductsForMatching.map((e,index) => {
                return (
                  <TableRowCollapse key={e.product_pk} style = {index == this.state.selectedProductsForMatching.length -1 ? {marginBottom : "0px"}: {}}>
                    <Panel
                      key="1"
                      showArrow={false}
                      extra={this.collapseHeader_matchingData(e)}
                    >
                    </Panel>
                  </TableRowCollapse>
                );
              })}
              </Col>
            </Row>
          </div>
            :
            <div className="products-table-content">

            <Row>
              <Col span = {9}>
              {this.props.channelwiseState.products.products &&
              this.props.channelwiseState.products.products.map(e => {
                return (
                  <TableRowCollapse key={e.product_pk}>
                    <Panel
                      key="1"
                      showArrow={false}
                      extra={this.collapseHeader1(e)}
                    >
                    </Panel>
                  </TableRowCollapse>
                );
              })}
              </Col>
              <Col span = {15} style = {{height : "100%"}}>
              {this.props.channelwiseState.products.products &&
              this.props.channelwiseState.products.products.map(e => {
                return (
                  <TableRowCollapse key={e.product_pk}>
                    <Panel
                      key="1"
                      showArrow={false}
                      extra={this.collapseHeader2(e)}
                    >
                    </Panel>
                  </TableRowCollapse>
                );
              })}
              </Col>
            </Row>

           

            {this.props.channelwiseState.products.is_available &&
              this.props.channelwiseState.products.is_available ? (
                <div className="center-child" style={{ width: "100%" }}>
                  <Button type="primary" loading = {this.state.loadMoreLoading} onClick={this.handleLoadMore}>
                    Load more...
                  </Button>
                </div>
              ) : (
                <div />
              )}
          </div>
          }

         

        </TableContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ channelwise }) => {
  return {
    ...channelwise
  };
};

export default connect(mapStateToProps, {
  fetchAll,
  addToExportList,
  removeFromExportList,
  fetchAllMore
})(ListTable);
