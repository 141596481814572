import axios from "axios";

import isDev from "../server";
const serverUrl = isDev();

export const AddNavbarCategory = dealshubAdminNavbarCategoriesLength => dispatch => {
  let newCategory = {
    uuid: dealshubAdminNavbarCategoriesLength + 1,
    headingName: `Default Navbar Category ${dealshubAdminNavbarCategoriesLength +
      1}`,
    categoryList: [],
    imageList: []
  };
  axios
    .post(`${serverUrl}/dealshub/create-heading-data/`, {
      websiteGroupName: localStorage.getItem("websiteGroupName"),
      headingName: newCategory.headingName
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
    .then(response => {

      newCategory["uuid"] = response.data.uuid;
      dispatch({
        type: "ADD_NAVBAR_CATEGORY",
        payload: newCategory
      });
    });
};

export const handleUpdate = payload => {
  return { type: "HANDLE_UPDATE", payload };
};

export const ChangeHeadingCategory = payload => dispatch => {
  for (let index in payload.originalData) {
    if (payload.originalData[index]["uuid"] === payload.uuid) {
      payload.originalData[index]["categoryList"] = payload.newCategoryList;
      break;
    }
  }
  return { type: "CHANGE_HEADING_CATEGORY", payload };
};

export const HandleImageUpdateLink = payload => {
  return { type: "HANDLE_IMAGE_UPDATE_LINK", payload };
};

export const FetchDealshubAdminCategory = () => dispatch => {
  axios
    .post(`${serverUrl}/dealshub/fetch-heading-data-admin/`, {
      websiteGroupName: localStorage.getItem("websiteGroupName")
    })
    .then(response => {
      dispatch({
        type: "FETCH_DEALS_HUB_CATEGORY_DATA",
        payload: response.data.headingList
      });
    });
};
