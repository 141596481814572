import React, { useMemo } from "react";
import { Col, Spin, Progress, Row } from "antd";
import styles from "./AnalyticsCard.module.scss";

const currencyFormater = new Intl.NumberFormat("en-AE", {
  minimumSignificantDigits: 3,
});

export default function AnalyticsCards({
  target,
  data,
  currency,
  isToday,
  isMonthly,
  isDate,
  isFiltered,
}) {
  const percentageAmount = useMemo(() => {
    if (isToday) {
      try {
        return parseInt(
          (parseFloat(data.sales) / parseFloat(target.today_sales)) * 100
        );
      } catch (err) {
        console.warn("Sales Analytics Data cannot be parsed");
        return 0;
      }
    } else if (isMonthly) {
      try {
        return parseInt(
          (parseFloat(data.sales) / parseFloat(target.monthly_sales)) * 100
        );
      } catch (err) {
        console.warn("Sales Analytics Data cannot be parsed");
        return 0;
      }
    } else return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const percentageOrders = useMemo(() => {
    if (isToday) {
      try {
        return parseInt(
          (parseFloat(data.orders) / parseFloat(target.today_orders)) * 100
        );
      } catch (err) {
        console.warn("Orders Analytics Data cannot be parsed");
        return 0;
      }
    } else if (isMonthly) {
      try {
        return parseInt(
          (parseFloat(data.orders) / parseFloat(target.monthly_orders)) * 100
        );
      } catch (err) {
        console.warn("Orders Analytics Data cannot be parsed");
        return 0;
      }
    } else return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // console.log('data: ', data);
  if (!data) return null;
  return (
    <>
      <Row align="middle" justify="space-between" gutter={[8, 10]}>
        <Card
          title="Total Sales"
          amount={data.sales}
          currency={currency}
          orders={data.orders}
          isProgressAmount={() => {
            if (isFiltered) return <></>;
            if (isDate) return <></>;
            return (
              <Progress
                type="line"
                showInfo={false}
                percent={percentageAmount}
              />
            );
          }}
          isProgressOrder={() => {
            if (isFiltered) return <></>;
            if (isDate) return <></>;
            return (
              <Progress
                type="line"
                showInfo={false}
                percent={percentageOrders}
              />
            );
          }}
        />
        <Card
          title="Delivered"
          amount={
            isToday
              ? data.today_done_delivery_amount
              : isFiltered
              ? data.filtered_done_delivery_amount
              : isDate
              ? data.user_done_delivery_amount
              : data.monthly_done_delivery_amount
          }
          currency={currency}
          orders={data.delivered}
        />
        <Card
          title="Pending"
          amount={
            isToday
              ? data.today_pending_amount
              : isFiltered
              ? data.filtered_pending_amount
              : isDate
              ? data.user_pending_amount
              : data.monthly_pending_amount
          }
          currency={currency}
          orders={data.pending}
        />
        <Card
          title="Dispatched"
          amount={
            isToday
              ? data.today_dispatched_amount
              : isFiltered
              ? data.filtered_dispatched_amount
              : isDate
              ? data.user_dispatched_amount
              : data.monthly_dispatched_amount
          }
          currency={currency}
          orders={data.dispatched}
        />
        <Card
          title="Cancelled"
          amount={
            isToday
              ? data.today_cancelled_amount
              : isFiltered
              ? data.filtered_cancelled_amount
              : isDate
              ? data.user_cancelled_amount
              : data.monthly_cancelled_amount
          }
          currency={currency}
          orders={data.cancelled}
        />
        <Card
          title="Returned"
          amount={
            isToday
              ? data.today_returned_amount
              : isFiltered
              ? data.filtered_returned_amount
              : isDate
              ? data.user_returned_amount
              : data.monthly_returned_amount
          }
          currency={currency}
          orders={data.returned}
        />
        <Card
          title="Net Sales"
          amount={data.net_sales_amount}
          currency={currency}
          orders={data.net_sales}
        />
        <Card
          title="Average Order Value"
          amount={data.avg_value}
          currency={currency}
          orders={undefined}
        />
      </Row>
    </>
  );
}

const Card = (props) => {
  if (!props) {
    return <Spin spinning={true} />;
  }

  const { title, amount, currency, orders, isProgressAmount, isProgressOrder } =
    props;

  return (
    <Col span={6}>
      <div className={styles.card}>
        <Row>
          <p className={styles.title}>{title}</p>
        </Row>
        <Row gutter={[8, 2]}>
          <Col span={12}>
            <p className={styles.amountTitle}>Amount</p>
            <p className={styles.amount}>
              {currencyFormater.format(amount)} <span>{currency}</span>
            </p>
            {(isProgressAmount && isProgressAmount()) ?? <></>}
          </Col>
          {orders !== undefined ? (
            <Col span={12}>
              <p className={styles.ordersTitle}>Orders</p>
              <p className={styles.orders}>{orders}</p>
              {(isProgressOrder && isProgressOrder()) ?? <></>}
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </div>
    </Col>
  );
};
