import React, { Component } from "react";
import { Row, Col, Button, Icon, Select, message } from "antd";
import InputField from "../../datainputs/InputField";
import "../modals.scss";
import { connect } from "react-redux";
import {
  addToExportListOnDB,
  fetchExportList
} from "../../../../actions/exports";

const { Option } = Select;

class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new: true,
      existing: false,
      title: "",
      existing: ""
    };
  }

  makeNewSelected = () => {
    this.setState({
      new: true,
      existing: false
    });
  };

  makeExistingSelected = () => {
    this.setState({
      new: false,
      existing: true
    });
  };

  handelChange = e => {

    this.setState({
      title: e.target.value
    });
  };

  addToExport = () => {

    let export_option = this.state.new ? "New" : "Existing";
    let export_title = this.state.title;
    let channel_name = this.props.channelname;
    let listToExport = this.props.channelwiseState.listToExport;
    let export_title_pk = "";
    if (export_option == "Existing") {
      let obj = JSON.parse(this.state.existing);
      export_title = obj.title;
      export_title_pk = obj.pk;
    }
    this.props.addToExportListOnDB({
      export_option,
      export_title,
      channel_name,
      listToExport,
      export_title_pk,
      filters: this.props.channelwiseState.filters,
      tags: this.props.channelwiseState.tags,
      select_all: this.props.select_all
    });
    this.props.fetchExportList();
    this.props.closeModal();

  };

  onChange = e => {
    this.setState({ existing: e });
  };

  /* handled in actions
  errorFlag = 1;

  error = status => {
    switch (status) {
      case 200:
        message.success("Successfully Added to Export Page!");
        break;
      case 500:
        message.error("Internal Server error!");
        break;
      default:
        message.warning("Something Went Wrong!");
        break;
    }
  };
  
  componentWillReceiveProps = props => {
    if (props.channelwiseState.status !== "" && this.errorFlag) {
      this.error(props.channelwiseState.status);
      this.errorFlag = 0;
    }
  };
  */

  render() {

    return (
      <React.Fragment>
        <Row gutter={[24, 24]}>
          <Col sm={24}>
            <Button.Group size="default">
              <Button
                onClick={this.makeNewSelected}
                style={
                  this.state.new
                    ? {}
                    : { background: "white", color: "#6A7279" }
                }
                type="primary"
              >
                <Icon type="left" />
                New
              </Button>
              <Button
                style={
                  this.state.existing
                    ? {}
                    : { background: "white", color: "#6A7279" }
                }
                onClick={this.makeExistingSelected}
                type="primary"
              >
                Existing
                <Icon type="right" />
              </Button>
            </Button.Group>
          </Col>
          {this.state.new && (
            <Col sm={24}>
              <InputField
                impo
                label={{ type: "secondary" }}
                input={{ size: "large", value: this.state.title }}
                onChange={this.handelChange}
                labelText="Title"
              />
            </Col>
          )}
          {this.state.existing && this.props.exportlist && (
            <Col sm={24}>
              <Select
                showSearch
                style={{
                  width: "100%",
                  border: "1px solid #45525f",
                  borderRadius: "4px"
                }}
                placeholder="Select a Bundle"
                size="large"
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.props.exportlist.map(e => {
                  if (e.channel_name == this.props.channelname)
                    return <Option value={JSON.stringify(e)}>{e.title}</Option>;
                })}
              </Select>
            </Col>
          )}
          <Col sm={24}>
            <Row gutter={[12, 12]} type="flex" justify="end">
              <Col>
                <Button type="primary" onClick={this.addToExport}>
                  Add To Export Folder
                </Button>
              </Col>
              {/* <Col>
                            <Button>Download</Button>
                        </Col> */}
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ channelwise }) => {
  return {
    ...channelwise
  };
};

export default connect(mapStateToProps, {
  addToExportListOnDB,
  fetchExportList
})(Export);
