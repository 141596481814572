import { OPEN_RPN, CLOSE_RPN } from '../actions/refreshNotificationAction'

/**
 * intiialState js object holds the false status so that the notification
 * is not displayed by default
 */
const initialState = {
    status: false,
    payload: undefined
}
/**
 * refreshNotification reducer is used for the refreshNotification actions
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function refreshNotification(state = initialState, action) {
    switch (action.type) {
        case OPEN_RPN:
            return {
                status: true,
                payload: action.payload
            };
        case CLOSE_RPN:
            return {
                status: false,
                payload: action.payload
            };
        default: return state
    }
}