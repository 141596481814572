import { message } from "antd";
import axios from "axios";

import isDev from "../server";
const serverUrl = isDev();

let pageindex = 1;

export const fetchAll = (channelname, filters, tags, file = null , option = null) => dispatch => {

  pageindex = 1;
  let formData = new FormData();
  formData.append("page", pageindex);
  if (tags)
    formData.append("tags", JSON.stringify(tags));
  if (filters)
    formData.append("filter_parameters", JSON.stringify(filters))

  if (channelname)
    formData.append("channel_name", channelname);

  if(option)
    formData.append("option" , option);


  if (file)
    formData.append("import_file", file);

  axios
    .post(`${serverUrl}/fetch-channel-product-list/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
    .then(response => {

      if(response.data.status == 200)
      dispatch({
        type: "FETCH_ALL_CHANNEL_PRODUCT",
        payload: response.data
      });
      else if(response.data.status === 405)
      {
        message.error("Wrong Template");
      }
      else if(response.data.status === 406)
      {
        message.error("No Sheet 1");
      }
      else if(response.data.status === 407)
      {
        message.error("Unsupported file format");
      }    
      else {
        message.error("Internal Server Error");
      }
    });
};

export const fetchAllMore = (channelname, filters, tags , callback = null) => dispatch => {

  pageindex++;

  let formData = new FormData();
  formData.append("page", pageindex);
  if (tags)
    formData.append("tags", JSON.stringify(tags));
  if (filters)
    formData.append("filter_parameters", JSON.stringify(filters))

  if (channelname)
    formData.append("channel_name", channelname);

  axios
  .post(`${serverUrl}/fetch-channel-product-list/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `JWT ${localStorage.getItem("token")}`
    }
  })
  .then(response => {
    if(callback)callback();
    if(response.data.status == 200)
    dispatch({
      type: "FETCH_ALL_MORE_CHANNEL_PRODUCT",
      payload: response.data
    });
    else
    message.error("Something went wrong!");
  });

};

export const addFilters = filters => {
  return { type: "ADD_FILTERS_TO_CHANNEL_WISE_VIEW", payload: filters };
};

export const addTags = tags => {
  return { type: "ADD_TAGS_TO_CHANNEL_WISE_VIEW", payload: tags };
};

export const fetchExportList = () => dispatch => {
  const data = {};
  fetch(`${serverUrl}/fetch-export-list/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {

      dispatch({
        type: "FETCH_EXPORT_LIST",
        payload: data
      });
    });
};

export const fetchExportListPage = () => dispatch => {
  const data = {};
  fetch(`${serverUrl}/fetch-export-list/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {

      dispatch({
        type: "FETCH_EXPORT_LIST_PAGE",
        payload: data
      });
    });
};

export const addToExportListOnDB = inp => dispatch => {
  const data = {
    export_option: inp.export_option,
    export_title_pk: inp.export_title_pk,
    export_title: inp.export_title,
    channel_name: inp.channel_name,
    products: inp.listToExport,
    select_all: inp.select_all,
    filter_parameters: inp.filters,
    tags: inp.tags
  };



  dispatch({ type: "LOADING_ON" });

  fetch(`${serverUrl}/add-to-export/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {
      // dispatch({
      //   type: "SEND_FEEDBACK_FOR_EXPORT_ADD_ON_DB",
      //   payload: data.status
      // });
      dispatch({ type: "RESET_EXPORT_FLOW" })

      switch (data.status) {
        case 200:
          message.success("Successfully Added to Export Page!");
          break;
        case 500:
          message.error("Internal Server error!");
          break;
        default:
          message.warning("Something Went Wrong!");
          break;
      }

      dispatch({ type: "LOADING_OFF" });


    });

};

export const addToExportList = payload => {
  return { type: "ADD_TO_EXPORT_LIST", payload };
};

export const removeFromExportList = payload => {
  return { type: "REMOVE_FROM_EXPORT_LIST", payload };
};
