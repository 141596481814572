import React , {Component} from "react";
import RightWrapper from "my-components/ui-components/RightWrapper";
import TitleWithBreadCrumb from "views/components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {Row , Col , message , Tooltip , Affix , Button} from "antd";
import SimpleReactValidator from "simple-react-validator";
import BaseForm from "views/Edit-Base-Product-Page/BaseForm"
import ImageManagerForFactories from "views/components/datainputs/image-manager/ImageManagerForFatories"
import VarientsInfoAttributes from "views/Edit-Base-Product-Page/vitali-info/VarientsInfoAttributes";
import VarientsInfoPFL from "views/Edit-Base-Product-Page/vitali-info/VarientsInfoPFL"
import FormHeader from "views/components/datainputs/FormHeader";
import EditorBox from "views/components/EditorBox";
import isDev from "server";
import axios from "axios";
const serverUrl = isDev();

class CreateFactoryProduct extends Component{
    constructor(props){
        super(props);
        this.validator = new SimpleReactValidator({
          messages: {
            required: "Field is required",
            integer: "Must be number",
            default: "Validation has failed!" // will override all messages
          }
        });
        this.state = {
          brandList : null,
          categories : null,
          imagesdata : null,
          loading : false,
          category : null,
          sub_category : null,
          factoryProductDetails : {
              product_description: "",
              brand_name: "",
              manufacturer: "",
              manufacturer_part_number: "",
              dimensions: {
                export_carton_quantity_l: "",
                export_carton_quantity_l_metric: "",
                export_carton_quantity_b: "",
                export_carton_quantity_b_metric: "",
                export_carton_quantity_h: "",
                export_carton_quantity_h_metric: "",
                export_carton_crm_l: "",
                export_carton_crm_l_metric: "",
                export_carton_crm_b: "",
                export_carton_crm_b_metric: "",
                export_carton_crm_h: "",
                export_carton_crm_h_metric: "",
                product_dimension_l: "",
                product_dimension_l_metric: "",
                product_dimension_b: "",
                product_dimension_b_metric: "",
                product_dimension_h: "",
                product_dimension_h_metric: "",
                giftbox_l: "",
                giftbox_l_metric: "",
                giftbox_b: "",
                giftbox_b_metric: "",
                giftbox_h: "",
                giftbox_h_metric: ""
              },
              base_product_name: "",
              color: "",
              color_map: "",
              features : [],
              factory_notes: "",
          } 
        }
    }

    fetchFactoryProductDetails = () => {
      let data = {
          factory_product_uuid : this.props.factory_product_uuid
      };
      fetch(`${serverUrl}/sourcing/fetch-factory-product/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(data => {
  
          if (data.status !== 200)
            message.error("Internal Server Error!");
          else {
            this.setState({
              category : data.category_uuid,
              sub_category : data.sub_category_uuid,
              imagesdata : data.images,
                factoryProductDetails : {
                    product_description : data.product_description,
                    brand_name : data.brand,
                    manufacturer: data.manufacturer,
                    manufacturer_part_number: data.manufacturer_part_number,
                    dimensions: data.dimensions,
                    base_product_name: data.product_name,
                    color: data.color,
                    color_map: data.color_map,
                    features : data.features,
                    factory_notes: data.factory_notes,
                    quantity : data.moq,
                    material_type : data.material_type
                  }
            })
          }
  
        });
    }

    saveTheDetails = () => {

      let category_uuid = (this.state.categories[this.state.category_index] && this.state.categories[this.state.category_index]["category_uuid"]) || this.state.category;
      let sub_category_uuid = (this.state.categories[this.state.category_index] && this.state.categories[this.state.category_index]["sub_category_list"]
      [this.state.sub_category_index]["sub_category_uuid"]) || this.state.sub_category;

      let data = {
          ...this.state.factoryProductDetails,
          category_uuid,
          sub_category_uuid,
          product_name : this.state.factoryProductDetails.base_product_name,
          moq : this.state.factoryProductDetails.quantity,
          factory_product_uuid : this.props.factory_product_uuid || ""
      };
      let url = "create-factory-product";
      if(this.props.editMode)url = "save-factory-product";
      fetch(`${serverUrl}/sourcing/${url}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(data => {
  
          if (data.status === 500)
            message.error("Internal Server Error!");
            else if(data.status === 403)
            {
              message.info("You are not authorised!")
            }
          else {
            message.success("Successfully saved!")
            if(!this.props.editMode)
              this.saveImages(data.uuid);
          }
  
        });
    }

    saveImages = (uuid) => {

      if(!this.state.imagesdata)
      {
        window.location.assign(`/edit-factory-product/${uuid}`)
        return;
      }

      if(this.state.imagesdata == 0)
      {
        window.location.assign(`/edit-factory-product/${uuid}`)
        return;
      }

      let formData = new FormData();
      let imagedata = this.state.imagesdata;
      imagedata.map((e, index) => {
        formData.append("image_" + index, e.originFileObj);
      });
      formData.append("images_count", imagedata.length);
      formData.append("factory_product_uuid" , uuid)
      axios
        .post(`${serverUrl}/sourcing/upload-factory-product-images/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        })
        .then(res => {
          window.location.assign(`/edit-factory-product/${uuid}`)
          this.setState({
            loading: false
          })
        })
        .catch(err => {
         
        });
    }

    handelSave = () => {
      if (!this.validator.allValid()) {
        message.info("Fill Required Details!");
      } else {
        if (this.state.imagesdata) this.setState({ loading: true });
        this.saveTheDetails();  
      }
    }

    setlist = data => {
      this.setState({ 
        factoryProductDetails : {
          ...this.state.factoryProductDetails ,
          features : data
        }
       });
    };

    getEditorText = value => {
      this.setState({
        factoryProductDetails : {
          ...this.state.factoryProductDetails,
          product_description : value
        }
      });
    };

    updateImageList = dict => {
      console.log(dict);
      this.setState({ imagesdata: dict });
    };

    fetchCategories = () => {
      let data = {
  
      };
      fetch(`${serverUrl}/fetch-all-categories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(data => {
  
          if (data.status !== 200)
            message.error("Internal Server Error!");
          else {
            this.setState({
              categories: data.category_list
            })
          }
  
        });
    }



    componentDidMount = () => {
      this.fetchCategories();
      console.log(this.props , "Hey Buddy");
      if(this.props.editMode)
      {
        this.fetchFactoryProductDetails();
      }
      fetch(`${serverUrl}/fetch-brands/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
        .then(res => res.json())
        .then(data => {
          this.setState({ brandList: data.brand_list });

        });
    }

    getCategory = (e) => {
      console.log(e , "dadjs")
      this.setState({
          category_index : e
      })
    }
  
    getSubCategory = (e) => {
      this.setState({
          sub_category_index : e
      })
    }

    getInfo = (e) => {
      console.log(e);
      this.setState({
        factoryProductDetails :{
          ...this.state.factoryProductDetails,
          ...e
        }
      })
    }

    fetchImagesOnly = () => {
      let data = {
        factory_product_uuid : this.props.factory_product_uuid
    };
    fetch(`${serverUrl}/sourcing/fetch-factory-product/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status !== 200)
          message.error("Internal Server Error!");
        else {
          this.setState({
            imagesdata : data.images,
          })
        }

      });
    }

  
    render(){
      console.log(this.state);

      const initCat = (this.state.categories && this.state.category && this.state.categories.filter(
        (item) => item.category_uuid == this.state.category)[0]) || null;
  
      const initSubCat = (initCat && initCat.sub_category_list.filter(item => item.sub_category_uuid == this.state.sub_category)[0]) || null;
  
        let pageName = this.props.editMode ? "Edit Factory Product" : "Create Factory Product"
  
        return (
            <React.Fragment>
                <RightWrapper 
                    Upper = { <TitleWithBreadCrumb
                        title={`${pageName}`}
                        breadcrumb={[ "OmnyComm" ,"Factroy Products", pageName]}
                      />
                    }
                    innerwrapperTransparent
                >

                    <React.Fragment>
                          <Row style  ={{padding : "20px"}} gutter = {[40,40]}>
                            <Col className="gutter-row" lg={12} sm={24}>
                                <BaseForm
                                  brandList={this.state.brandList || []}
                                  validator={this.validator}
                                  productDetailsFromParent={this.state.factoryProductDetails}
                                  getInfo = {this.getInfo}
                                  categories={this.state.categories || []}
                                  getCategory={this.getCategory}
                                  getSubCategory={this.getSubCategory}
                                  initiCategory={initCat}
                                  initiSubCategory={initSubCat}
                                  nosellerSKU
                                  brand_not_impo
                                  disabled = {this.props.omnyCommUser}
                                  manufacturer_part_number_impo
                                  ECQ_impo
                                  ECC_impo
                                  GB_impo
                                />
                              
                            </Col>
                            <Col className="gutter-row" lg={12} sm={24}>  
                              <Row gutter={[40, 40]}>
                                <Col span={24}>
                                  <Row gutter={[24, 24]} className="gutter-box">
                                    <Col>

                                        <ImageManagerForFactories
                                          updateImageList={this.updateImageList}
                                          noCategories
                                          loading={this.state.loading}
                                          images = {this.state.imagesdata && this.state.imagesdata}
                                          
                                          fetchImagesOnly = {this.fetchImagesOnly}

                                          editMode = {this.props.editMode}
                                          disabled = {this.props.omnyCommUser}
                                          uuid = {this.props.factory_product_uuid}
                                       />
                                     
                                    </Col>
                                  </Row>
                                </Col>

                                <Col span={24}>
                                  <Row gutter={[24, 24]} className="gutter-box">
                                    <Col>
                                      
                                        <VarientsInfoAttributes
                                          validator={this.validator}
                                          productDetailsFromParent={this.state.factoryProductDetails}
                                          getInfo = {this.getInfo}
                                          disabled = {this.props.omnyCommUser}
                                          color_impo
                                          material_type_impo
                                          moq_impo
                                        />
                                      
                                    </Col>
                                  </Row>
                                </Col>


                                <Col span={24}>
                                  <Row gutter={[24, 24]} className="gutter-box">
                                    <Col>
                                  
                                        <VarientsInfoPFL
                                          initialdata={(this.state.factoryProductDetails && this.state.factoryProductDetails.features) || []}
                                          sendList={this.setlist}
                                          disabled = {this.props.omnyCommUser}
                                          validator={this.validator}
                                          name = "Features: Variant"
                                        />
                                    
                                    </Col>
                                  </Row>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                  <Row gutter={[24, 24]} className="gutter-box">
                                    <Col>
                                      <Row
                                        gutter={[24, 24]}
                                        style={{ margin: "0px", minHeight: "230px" }}
                                      >
                                        <Col style={{ padding: "0px" }} sm={24}>
                                          <FormHeader header="Product Description" />
                                        </Col>
                                        <Col sm={24} className="editorBox-container">
                                          <EditorBox
                                           disabled = {this.props.omnyCommUser}
                                            value={this.state.factoryProductDetails.product_description}
                                            getText={this.getEditorText}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>

                            <Col className="gutter-row save-btn-edit-product-page" sm={24}>
                              {
                                !this.props.omnyCommUser &&
                                <Tooltip placement="top" title="Save">
                                <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
                                  <Button
                                    type="danger"
                                    shape="circle"
                                    icon="save"
                                    size="large"
                                    onClick={() => this.handelSave()}
                                  ></Button>
                                </Affix>
                              </Tooltip>
                              }
                             
                            </Col>
                          </Row>
                    </React.Fragment>

                </RightWrapper>
            </React.Fragment>
        )
    }
}

export default CreateFactoryProduct