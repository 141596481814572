import React from "react";
import { Row, Col } from "antd";
import { propTypes } from "react-json-viewer";

export default function RightWrapper({ wrapperProps, Upper, InnerWrapper, innerwrapperTransparent , children }) {
    return (
        <Row style={{
            width: innerwrapperTransparent ? "99%" : "95%",
        }}
            {...wrapperProps}
        >
            {Upper &&
                <Col span={24}>
                    <Row style = {{padding : "0px 20px"}}>
                        {Upper}
                    </Row>
                </Col>
            }

            {
                InnerWrapper &&
                <Col span={24}>
                    <Row style={{padding : "0px 5px 5px 5px" ,backgroundColor: innerwrapperTransparent ? "transparent" : "#36404a", height: Upper ? "85vh" : "95vh", overflowY: "scroll", overflowX: "hidden" }}>
                        {InnerWrapper}
                    </Row>
                </Col>
            }

            {
                children &&
                <Col span={24}>
                    <Row style={{padding : "0px 5px 5px 5px", backgroundColor: innerwrapperTransparent ? "transparent" : "#36404a", height: Upper ? "85vh" : "95vh", overflowY: "scroll", overflowX: "hidden" }}>
                        {children}
                    </Row>
                </Col>
            }


        </Row>
    )
}