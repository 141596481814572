import React from 'react';
import {
    Row,
    Col,
    Typography,
    Checkbox
} from "antd";
import "./orders-account-manager.scss";

const { Text } = Typography;

const OrdersAccountManagerTableHeaderTemplate = () => {
    return (
        <Row className="table-head-row" type="flex" align="middle">
            <Col span={1}>
                {/* <Checkbox /> */}
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Order Info</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={4}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Products</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Variant</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Channel</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Buyer</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Payment</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={1}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Qty</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Price </Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Currency</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Shipment</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={2}>
                <Row>
                    <Col>
                        <Text className="table-head-text">Tracking</Text>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}

export default OrdersAccountManagerTableHeaderTemplate;