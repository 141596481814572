import React, { Component } from "react";
import { connect } from "react-redux";
import PlaceholderImage from "../../../../media/placeholder3.png";
import { Ellipsis } from "react-spinners-css";
import SearchChips from "../searchbar-chips/SearchChips";
import axios from "axios";
import ContentHealthTable from "./components/ContentHealthTable"
import {
  Row,
  Col,
  Avatar,
  Typography,
  Breadcrumb,
  Select,
  Button,
  Collapse,
  Checkbox,
  Tag,
  Icon,
  Modal,
  Skeleton,
  Tooltip,
  Affix,
  message
} from "antd";
import "./products.scss";
import styled from "styled-components";
import ProductsMobileView from "../../products-mobile-view/ProductsMobileView";
import { Link, Redirect } from "react-router-dom";
import {
  fetchProductList,
  fetchProductListMore,
  fetchProductListfromTags,
  addTags,
  setEmpty
} from "../../../../actions";
import ProductListFilterModal from "../product-list-filter-modal/ProductListFilterModal";
import ChooseChannelProduct from "../ChooseChannelProduct";
import NestoUploadModal from "./components/NestoUploadModal";
import isDev from "../../../../server";
import ProductPageExportModal from "../product-page-export-modal/ProductPageExportModal";

import ProductPageExportChannelModal from "../product-page-export-modal/ProductPageExportChannelModal";
import CreateAndUpdateProductModal from "../create-and-update-product-modal/CreateAndUpdateProductModal"
import ProductPageExportNesto from "../product-page-export-modal/ProductPageExporNesto";

const serverUrl = isDev();

// Imports From Styled Components
const { Title } = Typography;
const { Text } = Typography;

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
//  Styled Components
const ProductDetailsTitle = styled(Title)`
  color: #dee2e6 !important;
  margin: 0 !important;
`;

const TableContainer = styled(Row)``;
const TableHead = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #3f4a56;
  margin-top: 20px;
  border-bottom: 1px solid #495561;
  border-top: 1px solid #495561;
`;
const TableRow = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #313841;

  /* border-bottom: 1px solid red; */
`;
const TableRowText = styled(Text)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TableHeadText = styled(Text)`
  color: #dee2e6;
`;
const WhSelect = styled(Select)`
  && {
    color: #dee2e6;
    .ant-select-arrow {
      color: #1abc9c;
    }
    .ant-select-selection {
      background-color: #36404a;
    }
  }
`;
const WhOption = styled(Option)``;
const PriceSelect = styled(Select)`
  && {
    color: #dee2e6;
    .ant-select-arrow {
      color: #1abc9c;
    }
    .ant-select-selection {
      background-color: #36404a;
    }
  }
`;
const PriceOption = styled(Option)``;
const TableRowCollapse = styled(Collapse)`
  width: 100%;
  border: none;
  margin-bottom: 10px;
`;
const BaseProductContent = styled(Row)`
  padding: 12px;
  width: 100%;
  border-top: 1px solid #495561;
`;
const BaseProductContent1 = styled(Row)`
  padding: 12px;
  width: 100%;
  background-color: #313841;
`;
const ProductVariantContainer = styled(Row)`
  width: 100%;
`;

const ProductVariantRow = styled(Row)`
  width: 100%;
  padding: 5px 2px;
`;
const ChannelRow = styled(Row)`
  width: 100%;
  padding: 12px 2px;
  background-color: #3f4a56;
  border-top: 0.5px solid #495561;
  border-bottom: 0.5px solid #495561;
`;

class Products extends Component {
  state = {
    Modalvisible: false,
    visible: false,
    channelSelected: "Amazon Uk",
    redirectToChannelPage: false,
    product_pk: "",
    selectedWarehouse: "1070",
    selectedPrice: "OD_EA",
    activeIndex: null,
    refreshData: {},
    refreshLoaders: {},
    pageRefreshLoader: false,
    loadingInLoadMore: false,
    GR_LOADER: false,
  };



  componentDidMount = () => {
    this.props.drawerOff();
    this.props.dispatch(fetchProductList(this.props.filters, this.props.tags, this.props.isNestoUser));
  };

  componentWillReceiveProps = props => {
    this.setState({ loadingInLoadMore: false });
    this.props.getFilters(props.filters);
  };

  setNewChannelModalVisible(Modalvisible) {
    this.setState({ Modalvisible });
  }

  showModal = pk => {
    this.setState({
      visible: true,
      product_pk: pk
    });
  };

  handleOk = e => {
    this.props.changeChannelPage(this.state.channelSelected);
    this.setState({
      visible: false,
      redirectToChannelPage: true
    });
  };

  handelChannelSelect = e => {
    this.setState({ channelSelected: e });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  handleSelect = value => {
    this.setState({ selectedWarehouse: value });
  };

  handleChangeSearchTags = tags => {
    this.props.dispatch(setEmpty());
    this.props.dispatch(fetchProductList(this.props.filters, tags, this.props.isNestoUser));
    this.props.dispatch(addTags(tags));
  };

  handelSelectPrices = value => {
    this.setState({ selectedPrice: value });
  };

  renderWarehouse = warehouses_information => {
    if (this.state.selectedWarehouse === "1070") {
      let selectedWhObj = warehouses_information.find(
        element => element.company_code === this.state.selectedWarehouse
      );
      return selectedWhObj.qty;
    } else if (this.state.selectedWarehouse === "1000") {
      let selectedWhObj = warehouses_information.find(
        element => element.company_code === this.state.selectedWarehouse
      );
      return selectedWhObj.qty;
    }
  };

  handleLoadMore = () => {
    this.setState({ loadingInLoadMore: true });
    console.log(this.props);

    //code for content health filters
    let filters_new = {
      ...this.props.filters_content_health,
      ...this.props.filters,
    }

    this.props.dispatch(
      fetchProductListMore(filters_new, this.props.tags, this.props.isNestoUser)
    );
  };


  handelGenerateReports = () => {

    message.info("Please wait while server process your request!");
    this.setState({ GR_LOADER: true })
    let data = {
      filter_parameters: this.props.filters
    };
    fetch(`${serverUrl}/generate-reports/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ GR_LOADER: false });
        if (data.status === 500)
          message.error("Internal Server Error!");
          else if(data.status === 405)
          {
            message.info("You are not authorised!")
          }
        else {
          window.open(data.file_path_1, '_blank');
          window.open(data.file_path_2, '_blank');
        }

      });
  }

  handleBorder = index => {
    this.setState({ activeIndex: index });
  };

  handleRefreshAll = () => {
    this.setState({
      pageRefreshLoader: true
    });
    let product_pk_list = [];

    for (let i in this.props.productData.products) {
      for (let j in this.props.productData.products[i].products) {

        product_pk_list.push(
          this.props.productData.products[i].products[j].product_pk
        );
        this.setState(prevState => ({
          refreshLoaders: {
            ...prevState.refreshLoaders,
            [this.props.productData.products[i].products[j].product_pk]: true
          }
        }));
      }
    }

    let data = {
      product_pk_list,
      warehouse_code: this.state.selectedWarehouse
    };
    fetch(`${serverUrl}/sap/fetch-price-and-stock/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status === 500)
        message.error("Internal Server Error!");
        else if(data.status === 405)
        {
          message.info("You are not authorised!")
        }

        if (data.warehouses_information) {
          for (let i in data.warehouses_information) {
            this.setState(prevState => ({
              refreshData: {
                ...prevState.refreshData,
                [data.warehouses_information[i].product_pk]: {
                  ...prevState.refreshData[
                  data.warehouses_information[i].product_pk
                  ],
                  [data.warehouses_information[i].company_code]: {
                    prices: data.warehouses_information[i].prices,
                    qty: data.warehouses_information[i].total_holding,
                    atp :data.warehouses_information[i].total_atp
                  }
                }
              }
            }));
          }
        }

        this.setState({
          pageRefreshLoader: false
        });

        for (let i in this.props.productData.products) {
          for (let j in this.props.productData.products[i].products) {
            this.setState(prevState => ({
              refreshLoaders: {
                ...prevState.refreshLoaders,
                [this.props.productData.products[i].products[j]
                  .product_pk]: false
              }
            }));
          }
        }

        // if (data.warehouses_dict) {
        //   this.setState({
        //     refreshData: {
        //       ...this.state.refreshData,
        //       [pk]: {
        //         ...this.state.refreshData[pk],
        //         [data.warehouses_dict.company_code]: {
        //           prices: data.warehouses_dict.prices,
        //           qty: data.warehouses_dict.qty
        //         }
        //       }
        //     }
        //   });
        // }
        // this.setState({
        //   refreshLoaders: { ...this.state.refreshLoaders, [pk]: false }
        // });
      });
  };
  // this.state.selectedWarehouse
  handleRefreshWarehousePrice = pk => {
    this.setState({
      refreshLoaders: { ...this.state.refreshLoaders, [pk]: true }
    });
    let data = {
      product_pk_list: [pk],
      warehouse_code: this.state.selectedWarehouse
    };
    fetch(`${serverUrl}/sap/fetch-price-and-stock/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status === 500)
        message.error("Internal Server Error!");
        else if(data.status === 405)
        {
          message.info("You are not authorised!")
        }

        if (data.warehouses_information) {
          this.setState({
            refreshData: {
              ...this.state.refreshData,
              [pk]: {
                ...this.state.refreshData[pk],
                [data.warehouses_information[0].company_code]: {
                  prices: data.warehouses_information[0].prices,
                  qty: data.warehouses_information[0].total_holding,
                  atp : data.warehouses_information[0].total_atp
                }
              }
            }
          });
        }
        this.setState({
          refreshLoaders: { ...this.state.refreshLoaders, [pk]: false }
        });
      });
  };

  collapseHeader = product => (
    <TableRow type="flex" align="middle">
      <Col span={2}>
        <Row gutter={[8, 8]} style={{ width: "80%" }}>
          {product && product.products && product.products.length === 1 ? (
            product.base_main_images && product.base_main_images[0] ? (
              <Avatar
                shape="square"
                size={68}
                src={product.base_main_images[0].thumbnail_url}
                style={{ marginLeft: "5px" }}
              />
            ) : (
                <Avatar
                  shape="square"
                  size={68}
                  src={PlaceholderImage}
                  style={{ marginLeft: "5px" }}
                />
              )
          ) : (
              <React.Fragment>
                <Col span={12}>
                  {product &&
                    product.products &&
                    product.products[0].main_images[0] ? (
                      <Avatar
                        shape="square"
                        src={product.products[0].main_images[0].thumbnail_url}
                      />
                    ) : (
                      <Avatar shape="square" src={PlaceholderImage} />
                    )}
                </Col>
                <Col span={12}>
                  {product &&
                    product.products &&
                    product.products[1].main_images[0] ? (
                      <Avatar
                        shape="square"
                        src={product.products[1].main_images[0].thumbnail_url}
                      />
                    ) : (
                      <Avatar shape="square" src={PlaceholderImage} />
                    )}
                </Col>
                <Col span={12}>
                  {product.base_main_images && product.base_main_images[2] ? (
                    <Avatar
                      shape="square"
                      src={product.base_main_images[2].thumbnail_url}
                    />
                  ) : (
                      <Avatar shape="square" src={PlaceholderImage} />
                    )}
                </Col>
                <Col span={12}>
                  <Avatar
                    shape="square"
                    style={{ background: "white", color: "#8a8a8a" }}
                  >
                    +
                  {product.products.length > 3
                      ? product.products.length - 3
                      : 0}
                  </Avatar>
                </Col>
              </React.Fragment>
            )}
        </Row>
      </Col>

      <Col span={5} className="right-space">
        <Row>
          <TableRowText
            title={product.product_name}
            style={{ color: "#dee2e6" }}
          >
           {product.product_name} 
          </TableRowText>

          <TableRowText>{product.seller_sku}</TableRowText>
        </Row>
      </Col>
      <Col span={2}>
        <TableRowText></TableRowText>
      </Col>
      <Col span={3}>
        <Row style = {{paddingRight : "30px"}}>
          <TableRowText>{product.category}</TableRowText>
          <TableRowText>{product.subtitle}</TableRowText>
        </Row>
      </Col>
      <Col span={4} style={{ paddingLeft: "10px" }}>
        {/* <TableRowText>
          {product.products[0].warehouses_information.length > 0
            ? this.renderWarehouse(product.products[0].warehouses_information)
            : "NA"}
        </TableRowText> */}

        <TableRowText>{/* refresh button */}</TableRowText>
      </Col>
      <Col span={3}>
        <TableRowText></TableRowText>
      </Col>
      <Col span={3}>
        <Tag color="orange" style={{ marginBottom: "10px" }}>
          Total Channels :{" "}
          {product.products.reduce(
            (acc, obj) => acc + obj.channels_of_prod,
            0
          )}
        </Tag>
        <Tag color="red">
          Inactive:{" "}
          {product.products.reduce((acc, obj) => acc + obj.inactive_channels, 0)}
        </Tag>
      </Col>
      <Col span={2}>
        <Row style={{ paddingLeft: "28px" }}>
          <Link to={`/edit-base-product/${product.base_product_pk}`}>
            <Tooltip title="Edit base product">
              <Icon
                Icon
                className="hover-icon"
                type="form"
                style={{
                  fontSize: "1.3em",
                  color: "grey",
                  marginRight: "10px"
                }}
              />
            </Tooltip>
          </Link>
          <Link to={`/add-new-variant/${product.base_product_pk}`}>
            <Tooltip title="Add new variant">
              <Icon
                className="hover-icon"
                type="file-add"
                style={{ fontSize: "1.3em", color: "grey" }}
              />
            </Tooltip>
          </Link>
        </Row>
      </Col>
    </TableRow>
  );

  render() {
    if (this.state.redirectToChannelPage) {
      return (
        <Redirect
          to={`/edit-channel-product/${this.state.channelSelected
            .split(" ")
            .join("-")
            .toLowerCase()}/${this.state.product_pk}`}
        />
      );
    }

    return (
      <React.Fragment style={{overflow:"scroll"}}>

        <div className="products-container" style={{ maxHeight:"100vh",overflow: "scroll" }}>
          <div className="breadcrumb-row-parent">
            <Row
              type="flex"
              align="middle"
              style={{ backgroundColor: "#313841" }}
              className="hide-on-small-breadcrumb"
            >
              <Col span={12}>
                <ProductDetailsTitle level={4}>Products</ProductDetailsTitle>
              </Col>
              <Col span={12}>
                <Row
                  className="breadcrumb-row"
                  type="flex"
                  align="middle"
                  justify="end"
                  style={{ padding: "25px 0", backgroundColor: "#313841" }}
                >
                  <Breadcrumb separator="">
                    <Breadcrumb.Item>OmnyComm</Breadcrumb.Item>
                    <Breadcrumb.Separator>
                      <Icon type="right" className="breadcrumb-icon" />
                    </Breadcrumb.Separator>
                    <Breadcrumb.Item>Products</Breadcrumb.Item>
                  </Breadcrumb>
                </Row>
              </Col>
            </Row>
          </div>

        <div style = {{maxHeight : "90vh" , overflow : "scroll"}}>
          {
            !this.props.isNestoUser && <ContentHealthTable />
          }

          <div className="table-container-parent">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="products-top-nav"
            >
              <Col lg={24} sm={24}>
                <Row type="flex" gutter={[12, 12]} align="middle">
                  <Col lg={3} sm={8}>
                    {this.props.productData ? (
                      <Button style={{ width: "100%" }} type="primary" ghost>
                        Results:{" "}
                        {this.props.productData &&
                          this.props.productData.total_products}
                      </Button>
                    ) : (
                        <Button type="primary" loading ghost></Button>
                      )}
                  </Col>
                  <Col lg={2} sm={8}>
                    <ProductListFilterModal isNestoUser={this.props.isNestoUser} />
                  </Col>
                  {
                    !this.props.isNestoUser ? (
                      <Col lg={2} sm={8}>
                        <ProductPageExportModal />
                      </Col>
                    ) : (
                      <>
                        <Col lg={2} sm={8}>
                          <ProductPageExportNesto filters={this.props.filters} tags={this.props.tags}/>
                        </Col>
                        <Col lg={3} sm={8}>
                          <NestoUploadModal filters={this.props.filters}/>
                        </Col>
                      </>
                    )
                  }
                  {
                    !this.props.isNestoUser && (
                      <>
                        <Col lg={2} sm={8}>
                          <ProductPageExportChannelModal />
                        </Col>
                        <Col lg={2} sm={8}>
                          <CreateAndUpdateProductModal />
                        </Col>
                      </>
                    )
                  }
                  <Col lg={this.props.isNestoUser ? 10 : 9} sm={24}>

                    <Select
                      dropdownClassName="search-chips"
                      dropdownMatchSelectWidth="false"
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder={[<Icon type="search" />, " Search here..."]}
                      onChange={this.handleChangeSearchTags}
                      value={this.props.tags}
                    ></Select>
                  </Col>
                  <Col lg={4} sm={8}>
                    <Link to="/create-new-product">
                      <Button
                        type="danger"
                        style={{ width: "100%" }}
                        icon="plus-circle"
                      >
                        Add New Product
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>

            <TableContainer
              type="flex"
              align="middle"
              className="table-container"
            >
              <TableHead
                type="flex"
                align="middle"
                className="products-table-heading"
              >
                <Col span={2}>
                  <TableHeadText>{!this.props.isNestoUser ? "Images" : "Product ID"}</TableHeadText>
                </Col>
                <Col span={this.props.isNestoUser ? 7 : 5} className="right-space">
                  <TableHeadText>{this.props.isNestoUser ? "" : "Product ID"}</TableHeadText>
                </Col>
                <Col span={this.props.isNestoUser ? 3 : 2}>
                  <TableHeadText>Brand</TableHeadText>
                </Col>
                <Col span={this.props.isNestoUser ? 4 : 3}>
                  <TableHeadText>{this.props.isNestoUser ? "Category" : "Domain"}</TableHeadText>
                </Col>
                <Col span={this.props.isNestoUser ? 0 : 4} style={{ position: "relative" }}>
                  {
                    this.props.isNestoUser ? (
                      <TableHeadText></TableHeadText>
                    ) : (
                      <>
                        <WhSelect
                          value={this.state.selectedWarehouse}
                          style={{ width: 120 }}
                          onSelect={this.handleSelect}
                        >
                          <WhOption value="1070">Geepas-UAE Jafza</WhOption>
                          <WhOption value="1000"> Geepas-UAE Techno Park</WhOption>
                          <WhOption value="6000">Abraj-UAE</WhOption>
                          <WhOption value="5550">Baby Plus-UAE</WhOption>
                          <WhOption value="5600">Bag House-UAE</WhOption>
                          <WhOption value="7000">Clarkford-UAE</WhOption>
                          <WhOption value="5110">Crystal Promo-UAE</WhOption>
                          <WhOption value="5100">Crystal-UAE</WhOption>
                          <WhOption value="3050">Delcasa-UAE</WhOption>
                          <WhOption value="2100">Epsilon-UAE</WhOption>
                          <WhOption value="5700">Leather Plus-UAE</WhOption>
                          <WhOption value="1100">Olsenmark-UAE</WhOption>
                          <WhOption value="3000">Royalford-UAE</WhOption>
                          <WhOption value="5000">Young Life-UAE</WhOption>
                        </WhSelect>
                        <Button
                          size="small"
                          shape="circle"
                          type="primary"
                          style={{ position: "absolute", top: "3px", left: "-30px" }}
                          onClick={() => this.handleRefreshAll()}
                          disabled={this.state.pageRefreshLoader}
                        >
                          {this.state.pageRefreshLoader && <Icon type="sync" spin />}
                          {!this.state.pageRefreshLoader && <Icon type="sync" />}
                        </Button>
                      </>
                    )
                  }
                </Col>

                <Col span={this.props.isNestoUser ? 0 : 3}>
                  {
                    this.props.isNestoUser ? (
                      <TableHeadText></TableHeadText>
                    ) : (
                      this.props.productData &&
                      (this.props.productData.price_type ?
                        <PriceSelect
                          value={this.state.selectedPrice}
                          style={{ width: 120 }}
                          onSelect={this.handelSelectPrices}
                        >
                          <PriceOption value="EX_EA">EX_EA</PriceOption>
                          <PriceOption value="IC_EA">IC_EA</PriceOption>
                          <PriceOption value="OD_EA">OD_EA</PriceOption>
                          <PriceOption value="RET_EA">RET_EA</PriceOption>
                        </PriceSelect> :
                        <PriceSelect
                          defaultValue={this.state.selectedPrice}
                          style={{ width: 120 }}
                          onSelect={this.handelSelectPrices}
                        >
                          <PriceOption value="OD_EA">OD_EA</PriceOption>
                        </PriceSelect>)
                    )
                  }
                </Col>

                <Col span={this.props.isNestoUser ? 5 : 3}>
                  <TableHeadText>{this.props.isNestoUser ? "Status": "Ch. Status"}</TableHeadText>
                </Col>
                <Col span={2} style={{ paddingLeft: "20px" }}>
                  <TableHeadText>Actions</TableHeadText>
                </Col>
              </TableHead>


              <div className="products-table-content">


                {this.props.productData && this.props.productData.products ? (
                  this.props.isNestoUser ? (
                    this.props.productData.products.map((product, index) => {
                      return (
                        <TableRowCollapse
                          key={index}
                          className="base-product-container"
                        >
                          <BaseProductContent1
                                  type="flex"
                                  align="middle"
                                  key={index}
                                  onClick={() =>
                                    this.handleBorder(index)
                                  }
                                  className={
                                    this.state.activeIndex === index &&
                                    "border-is-active"
                                  }
                                >
                                  <ProductVariantContainer>
                                    <ProductVariantRow type="flex" align="middle">
                                      <Col span={2}>

                                        {product.front_images[0] ? (
                                          <Avatar
                                            shape="square"
                                            size={68}
                                            src={
                                              product.front_images[0].thumbnail_image
                                            }
                                          />
                                        ) : (
                                            <Avatar
                                              shape="square"
                                              size={68}
                                              src={PlaceholderImage}
                                            />
                                          )}
                                      </Col>
                                      <Col span={7} className="right-space">
                                       
                                          <Row>
                                          <Link
                                          to={`/product-details/${product.product_uuid}`}
                                        >
                                            <TableRowText
                                              style={{ color: "#dee2e6" }}
                                            >
                                             {product.product_name_ecommerce}
                                            </TableRowText>
                                            </Link>
                                            <TableRowText>
                                              {product.article_number} {product.article_number && product.barcode && "|"} {product.barcode}
                                            </TableRowText>
                                          </Row>
                                      
                                      </Col>
                                      <Col span={3}>
                                        <TableRowText>
                                          {product.brand}
                                        </TableRowText>
                                      </Col>
                                      <Col span={4}>
                                        <Row style = {{paddingRight : "30px"}}>
                                          <TableRowText>
                                            {product.super_category}
                                          </TableRowText>

                                          <TableRowText>
                                            {product.category}
                                          </TableRowText>

                                          <TableRowText>
                                            {product.sub_category}
                                          </TableRowText>
                                        </Row>
                                      </Col> 
                                      {/* <Col span={3} style={{ paddingLeft: "10px" }}>
                                        <TableRowText>
                                        
                                        </TableRowText>
                                      </Col>
                                      <Col span={3}>
                                        <TableRowText>
                                          
                                        </TableRowText>
                                        </Col> */}
                                      <Col span={5}>
                                        <Col>
                                          <Tag
                                            color={product.product_status === "rich" ? 'blue' :
                                              product.product_status === "ecommerce" ? "green" : "red"}
                                          >
                                            {product.product_status ? (product.product_status === "rich" ? "Rich Product" :
                                            product.product_status === "ecommerce" ? "E-commerce Ready" : "Not E-commerce Ready") : "Not E-commerce Ready"}
                                          </Tag>
                                        </Col>
                                        <Col style={{display: !(product.is_online || product.is_verified) && "none", 
                                          marginTop: (product.is_online || product.is_verified) && 10}}>
                                        {
                                          product.is_online && (
                                            <Tag
                                              color="geekblue"
                                            >
                                              Online
                                            </Tag>
                                          )
                                        }
                                         {
                                          product.is_verified && (
                                            <Tag
                                              color="purple"
                                            >
                                              Verified
                                            </Tag>
                                          )
                                        }
                                        </Col>
                                      </Col>
                                      <Col span={2}>
                                        <Row
                                          style={{ paddingLeft: "20px" }}
                                          gutter={[8, 8]}
                                        >
                                          <Col span={24}>
                                            <Link
                                              to={`/edit-product/${product.product_uuid}`}
                                            >
                                              <Tooltip title="Edit Product">
                                                <Icon
                                                  Icon
                                                  className="hover-icon"
                                                  type="edit"
                                                  style={{
                                                    fontSize: "1.3em",
                                                    color: "grey",
                                                    marginLeft: "10px"
                                                  }}
                                                />
                                              </Tooltip>
                                            </Link>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </ProductVariantRow>
                                  </ProductVariantContainer>
                                </BaseProductContent1>
                        </TableRowCollapse>
                      )
                    })
                  ) : (
                  this.props.productData.products.map((product, index) => {
                    return (
                      <TableRowCollapse
                        key={index}
                        className="base-product-container"
                      >
                        {product.products.length !== 1 ? (
                          <Panel
                            key="1"
                            showArrow={false}
                            extra={this.collapseHeader(product)}
                          >
                            {product.products.map((variant, index) => {
                              return (
                                <BaseProductContent
                                  type="flex"
                                  align="middle"
                                  key={index}
                                >
                                  <ProductVariantContainer>
                                    <ProductVariantRow type="flex" align="middle">
                                      <Col span={2}>
                                        {variant.main_images[0] ? (
                                          <Avatar
                                            shape="square"
                                            size={68}
                                            src={
                                              variant.main_images[0].thumbnail_url
                                            }
                                          />
                                        ) : (
                                            <Avatar
                                              shape="square"
                                              size={68}
                                              src={PlaceholderImage}
                                            />
                                          )}
                                      </Col>
                                      <Col span={5} className="right-space">
                                      
                                          <Row>
                                            <Link
                                              to={`/product-details/${variant.product_pk}`}
                                            >
                                              <TableRowText
                                                style={{ color: "#dee2e6" }}
                                              >
                                                {variant.product_name}
                                              </TableRowText>
                                            </Link>
                                            <TableRowText>
                                              {product.seller_sku} {product.seller_sku && variant.product_id && "|"} {variant.product_id}
                                            </TableRowText>
                                          </Row>
                                       
                                      </Col>
                                      <Col span={2}>
                                        <TableRowText>
                                          {product.brand_name}
                                        </TableRowText>
                                      </Col>
                                      <Col span={3}>
                                        <Row style = {{paddingRight : "30px"}}> 
                                          <TableRowText>
                                            {product.category}
                                          </TableRowText>

                                          <TableRowText>
                                            {product.sub_category}
                                          </TableRowText>
                                        </Row>
                                      </Col>
                                      <Col
                                        span={4}
                                        style={{ paddingLeft: "10px" }}
                                      >
                                        {/* <TableRowText>
                                          {variant.warehouses_information.length >
                                          0
                                            ? this.renderWarehouse(
                                                variant.warehouses_information
                                              )
                                            : "NA"}
                                          </TableRowText> */}
                                          
                                            <TableRowText>
                                          {/* refresh button for varient */}{" "}
                                          {this.state.refreshData[
                                            variant.product_pk
                                          ] &&
                                            this.state.refreshData[
                                            variant.product_pk
                                            ][this.state.selectedWarehouse] &&
                                           "ATP : " + this.state.refreshData[
                                              variant.product_pk
                                            ][this.state.selectedWarehouse].atp}
                                             
                                          <Button
                                            style={{ marginLeft: "5px" }}
                                            type="primary"
                                            shape="circle"
                                            size="small"
                                            disabled={
                                              this.state.refreshLoaders[
                                              variant.product_pk
                                              ]
                                            }
                                            onClick={() =>
                                              this.handleRefreshWarehousePrice(
                                                variant.product_pk
                                              )
                                            }
                                          >
                                            {!this.state.refreshLoaders[
                                              variant.product_pk
                                            ] && <Icon type="sync" />}
                                            {this.state.refreshLoaders[
                                              variant.product_pk
                                            ] && <Icon type="sync" spin />}
                                          </Button>
                                        </TableRowText>
                                        <TableHeadText>
                                        {this.state.refreshData[
                                            variant.product_pk
                                          ] &&
                                            this.state.refreshData[
                                            variant.product_pk
                                            ][this.state.selectedWarehouse] &&
                                           "Holding : " +  this.state.refreshData[
                                              variant.product_pk
                                            ][this.state.selectedWarehouse].qty}
                                        </TableHeadText>
                                      </Col>
                                      <Col span={3}>
                                        <TableRowText>
                                              {this.state.refreshData[
                                                variant.product_pk
                                              ] &&
                                                this.state.refreshData[
                                                variant.product_pk
                                                ][this.state.selectedWarehouse] &&
                                                this.state.refreshData[
                                                  variant.product_pk
                                                ][this.state.selectedWarehouse]
                                                  .prices[this.state.selectedPrice]}{
                                              this.state.refreshData[variant.product_pk] &&
                                                this.state.refreshData[variant.product_pk][this.state.selectedWarehouse] &&
                                                " AED"}
                                        </TableRowText>
                                      </Col>
                                      <Col span={3}>
                                        <Tag
                                          color="orange"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          Total Channels:{variant.channels_of_prod}
                                        </Tag>
                                        <Tag color="red">
                                          Inactive: {variant.inactive_channels}
                                        </Tag>
                                      </Col>
                                      <Col span={2}>
                                        <Row style={{ paddingLeft: "20px" }}>
                                          <Link
                                            to={`/edit-product/${variant.product_pk}`}
                                          >
                                            <Tooltip title="Edit variant">
                                              <Icon
                                                Icon
                                                className="hover-icon"
                                                type="edit"
                                                style={{
                                                  fontSize: "1.3em",
                                                  color: "grey",
                                                  marginRight: "10px"
                                                }}
                                              />
                                            </Tooltip>
                                          </Link>
                                          <Tooltip title="Select channel">
                                            <Icon
                                              Icon
                                              className="hover-icon"
                                              onClick={() =>
                                                this.showModal(variant.product_pk)
                                              }
                                              type="laptop"
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey"
                                              }}
                                            />
                                          </Tooltip>
                                        </Row>
                                      </Col>
                                    </ProductVariantRow>
                                  </ProductVariantContainer>
                                </BaseProductContent>
                              );
                            })}
                          </Panel>
                        ) : (
                            product.products.map((variant, key) => {
                              return (
                                <BaseProductContent1
                                  type="flex"
                                  align="middle"
                                  key={key}
                                  onClick={() =>
                                    this.handleBorder(index)
                                  }
                                  className={
                                    this.state.activeIndex === index &&
                                    "border-is-active"
                                  }
                                >
                                  <ProductVariantContainer>
                                    <ProductVariantRow type="flex" align="middle">
                                      <Col span={2}>

                                        {variant.main_images[0] ? (
                                          <Avatar
                                            shape="square"
                                            size={68}
                                            src={
                                              variant.main_images[0].thumbnail_url
                                            }
                                          />
                                        ) : (
                                            <Avatar
                                              shape="square"
                                              size={68}
                                              src={PlaceholderImage}
                                            />
                                          )}
                                      </Col>
                                      <Col span={5} className="right-space">
                                       
                                          <Row>
                                          <Link
                                          to={`/product-details/${variant.product_pk}`}
                                        >
                                            <TableRowText
                                              style={{ color: "#dee2e6" }}
                                            >
                                             {variant.product_name}
                                            </TableRowText>
                                            </Link>
                                            <TableRowText>
                                              {product.seller_sku} {product.seller_sku && variant.product_id && "|"} {variant.product_id}
                                            </TableRowText>
                                          </Row>
                                      
                                      </Col>
                                      <Col span={2}>
                                        <TableRowText>
                                          {product.brand_name}
                                        </TableRowText>
                                      </Col>
                                      <Col span={3}>
                                        <Row style = {{paddingRight : "30px"}}>
                                          <TableRowText>
                                            {product.category}
                                          </TableRowText>

                                          <TableRowText>
                                            {product.sub_category}
                                          </TableRowText>
                                        </Row>
                                      </Col>
                                      <Col span={4} style={{ paddingLeft: "10px" }}>
                                        {/* <TableRowText>
                                      {variant.warehouses_information.length > 0
                                        ? this.renderWarehouse(
                                            variant.warehouses_information
                                          )
                                        : "NA"}
                                    </TableRowText> */}
                                        <TableRowText style = {{
                                          position : "realtive"
                                        }}>
                                          {/* refresh button unknown */}

                                          {this.state.refreshData[
                                            variant.product_pk
                                          ] &&
                                            this.state.refreshData[
                                            variant.product_pk
                                            ][this.state.selectedWarehouse] &&
                                            "Holding : " + this.state.refreshData[
                                              variant.product_pk
                                            ][this.state.selectedWarehouse].qty +
                                            " Unit(s)"}


                                          

                                          <Button
                                            style={{
                                              marginLeft: "5px",
                                              backgroundColor: "#3F4A56",
                                              color: "#1ABC9C",
                                              border: "none",
                                              position : "absolute",
                                              top : "0px",
                                              left : "-22px"
                                            }}
                                            type=""
                                            shape="circle"
                                            size="small"
                                            disabled={
                                              this.state.refreshLoaders[
                                              variant.product_pk
                                              ]
                                            }
                                            onClick={() =>
                                              this.handleRefreshWarehousePrice(
                                                variant.product_pk
                                              )
                                            }
                                          >
                                            {!this.state.refreshLoaders[
                                              variant.product_pk
                                            ] && <Icon type="sync" />}
                                            {this.state.refreshLoaders[
                                              variant.product_pk
                                            ] && <Icon type="sync" spin />}
                                          </Button>
                                        </TableRowText>
                                        <TableRowText>
                                              {this.state.refreshData[
                                                variant.product_pk
                                              ] &&
                                                this.state.refreshData[
                                                variant.product_pk
                                                ][this.state.selectedWarehouse] &&
                                              "ATP : " + this.state.refreshData[
                                                  variant.product_pk
                                                ][this.state.selectedWarehouse].atp +
                                                " Unit(s)"}
                                        </TableRowText>
                                      </Col>
                                      <Col span={3}>
                                        <TableRowText>
                                                {this.state.refreshData[
                                                variant.product_pk
                                              ] &&
                                                this.state.refreshData[
                                                variant.product_pk
                                                ][this.state.selectedWarehouse] &&
                                                this.state.refreshData[
                                                  variant.product_pk
                                                ][this.state.selectedWarehouse].prices[
                                                this.state.selectedPrice
                                                ]}
                                              {this.state.refreshData[
                                                variant.product_pk
                                              ] &&
                                                this.state.refreshData[
                                                variant.product_pk
                                                ][this.state.selectedWarehouse] &&
                                                " AED"}
                                        </TableRowText>
                                      </Col>
                                      <Col span={3}>
                                        <Tag
                                              color="orange"
                                              style={{ marginBottom: "10px" }}
                                            >
                                              Total Channels:{variant.channels_of_prod}
                                            </Tag>
                                        <Tag color="red">
                                          Inactive: {variant.inactive_channels}
                                        </Tag>
                                      </Col>
                                      <Col span={2}>
                                        <Row
                                          style={{ paddingLeft: "20px" }}
                                          gutter={[8, 8]}
                                        >
                                          <Col span={12}>
                                            <Link
                                              to={`/edit-base-product/${product.base_product_pk}`}
                                            >
                                              <Tooltip title="Edit base product">
                                                <Icon
                                                  Icon
                                                  className="hover-icon"
                                                  type="form"
                                                  style={{
                                                    fontSize: "1.3em",
                                                    color: "grey",
                                                    marginLeft: "10px"
                                                  }}
                                                />
                                              </Tooltip>
                                            </Link>
                                          </Col>
                                          <Col span={12}>
                                            <Link
                                              to={`/add-new-variant/${product.base_product_pk}`}
                                            >
                                              <Tooltip title="Add new variant">
                                                <Icon
                                                  className="hover-icon"
                                                  type="file-add"
                                                  style={{
                                                    fontSize: "1.3em",
                                                    color: "grey"
                                                  }}
                                                />
                                              </Tooltip>
                                            </Link>
                                          </Col>
                                          <Col span={12}>
                                            <Link
                                              to={`/edit-product/${variant.product_pk}`}
                                            >
                                              <Tooltip title="Edit variant">
                                                <Icon
                                                  Icon
                                                  className="hover-icon"
                                                  type="edit"
                                                  style={{
                                                    fontSize: "1.3em",
                                                    color: "grey",
                                                    marginLeft: "10px"
                                                  }}
                                                />
                                              </Tooltip>
                                            </Link>
                                          </Col>
                                          <Col span={12}>
                                            <Tooltip title="Select channel">
                                              <Icon
                                                Icon
                                                className="hover-icon"
                                                onClick={() =>
                                                  this.showModal(variant.product_pk)
                                                }
                                                type="laptop"
                                                style={{
                                                  fontSize: "1.3em",
                                                  color: "grey"
                                                }}
                                              />
                                            </Tooltip>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </ProductVariantRow>
                                  </ProductVariantContainer>
                                </BaseProductContent1>
                              );
                            })
                          )}
                      </TableRowCollapse>
                    );
                  })
                )) : (
                    <React.Fragment>
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Ellipsis color="#1ABC9C" size={100} />
                      </Row>
                    </React.Fragment>
                  )}
                {this.props.productData && this.props.productData.is_available && (
                  <div className="center-child" style={{ width: "100%" }}>
                    <Button
                      type="primary"
                      loading={this.state.loadingInLoadMore}
                      onClick={this.handleLoadMore}
                    >
                      {this.state.loadingInLoadMore ? "" : "Load More..."}
                    </Button>
                  </div>
                )}
              </div>
            </TableContainer>
          </div>
          </div>
         
              <div className="card-container-parent">
                {this.props.productData && (
                  <ProductsMobileView
                  />
                )}
              </div>
        </div>

        {/* <Tooltip placement="top" title="Generate Reports">
          <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
            <Button
              type="danger"
              shape="circle"
              icon={this.state.GR_LOADER ? "loading" : "file-zip"}
              size="large"
              disabled={this.state.GR_LOADER}
              onClick={this.handelGenerateReports}
            ></Button>
          </Affix>
        </Tooltip> */}

        <Modal
          title="Select The Channel"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <ChooseChannelProduct
            handelChannelSelect={this.handelChannelSelect}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productList, user }) => {
  return {
    ...productList,
    ...user
  };
};

export default connect(mapStateToProps)(Products);
