import React, {useState, useEffect} from 'react';
import {Col, Icon, Input, Modal, Typography} from "antd";

const {Text} = Typography
const OrderNotes = ({itemNote, adminNotes, color}) => {

    const [orderNoteModal, setOrderNoteModal] = useState(false)
    //const [customerNote, setCustomerNote] = useState('')
    //const [tempAdminNote, setTempAdminNote] = useState('')

    return (
        <>
            <React.Fragment>
                <Text
                    style={{color: '#3CC9A9'}}
                    onClick={(e) => {
                        setOrderNoteModal(true)
                        e.stopPropagation()
                        // setCustomerNote(itemNote)
                    }}
                ><Icon style={{color: color?color:'#3CC9A9'}} type="file"/></Text>
                
            </React.Fragment>
            <Modal
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
                width="40%"
                visible={orderNoteModal}
                title="Order Notes"
                destroyOnClose
                onOk={(e) => {
                    setOrderNoteModal(false)
                    //setAdminNotes(tempAdminNote)
                    e.stopPropagation()
                }}
                onCancel={(e) => {
                    setOrderNoteModal(false)
                   // setTempAdminNote('')
                    e.stopPropagation()
                }}
                // okText="Save"
                footer={null}
            >
                <Col span={24} style={{ marginBottom: '10px'}}>
                    <Text style={{
                        fontWeight: "bold",
                        fontSize: '16px',
                        color: '#FFFFFF'
                    }}>Customer Notes</Text>
                </Col>
                {/*{console.log(item.additionalNote)}*/}
                <Input.TextArea defaultValue={itemNote} disabled onClick={(e) => e.stopPropagation()}/>
                {/*<Text style={{color: "white"}}>{itemNote}</Text>*/}
                <Col span={24} style={{marginTop: '20px', marginBottom: '10px'}}>
                    <Text style={{
                        fontWeight: "bold",
                        fontSize: '16px',
                        color: '#FFFFFF'
                    }}>Admin Notes</Text>
                </Col>
                <Input.TextArea defaultValue={adminNotes===""?"No admin note available":adminNotes} disabled onClick={(e) => e.stopPropagation()}/>

                {/*<Input.TextArea*/}
                {/*    defaultValue={adminNotes}*/}
                {/*    value={tempAdminNote}*/}
                {/*    onChange={(e) => setTempAdminNote(e.target.value)}*/}
                {/*    onClick={(e) => e.stopPropagation()}*/}
                {/*/>*/}
            </Modal>
        </>

    )
}

export default OrderNotes