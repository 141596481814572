import React, {Component} from "react";
import {
    Row,
    Col,
    Button,
    Typography,
    Radio,
    Spin,
    Modal,
    message,
    Icon
} from "antd";
import "./dealshubadmin.scss";
import {connect} from "react-redux";
import {
    addcategory,
    addBanner,
    fetchDealsHubCategoryData,
    handleUpdate,
    orderList,
    handleDelete
} from "../../actions/dealsHubAdmin";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import GenericBanner from "./generic-banner/GenericBanner";
import GenericPromotionalEngine from "./generic-promotional-engine/GenericPromotionalEngine";
import axios from "axios";
import isDev from "../../server";

const serverUrl = isDev();
const {Title} = Typography;
const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>;

const SortableItem = SortableElement((props) => {
        if (props.sectionData.type && props.sectionData.type == "Banner") {
            return (
                <Row className="categoriescontainer" key={props.sectionData.uuid}>
                    <Col xs={24}>
                        <div className="collapse-div">
                            <GenericBanner
                                spinnerOn={props.spinnerOn}
                                bannerData={props.sectionData}
                                bannerIndex={props.sectionIndex}
                                locationUuid={props.locationUuid}
                                siteName={props.siteName}
                            />
                        </div>
                    </Col>
                </Row>
            );
        } 
        else {
            return (
                <Row className="categoriescontainer" key={props.sectionData.uuid}>
                    <Col sm={24}>
                        <GenericPromotionalEngine
                            sectionIndex={props.sectionIndex}
                            deleteCategory={props.deleteCategory}
                            sectionData={props.sectionData}
                            locationUuid={props.locationUuid}
                            siteName={props.siteName}
                        />
                    </Col>
                </Row>
            );
        }
    }
);

const SortableList = SortableContainer((props) => {
        return (
            <ul className="row wrapper-ul-row">
                {props.items.map((section, index) => (
                    <SortableItem
                        key={`item-${section.uuid}`}
                        siteName={props.siteName}
                        index={index}
                        sectionIndex={index}
                        sectionData={section}
                        deleteCategory={props.deleteCategory}
                        spinnerOn={props.spinnerOn}
                        locationUuid={props.locationUuid}
                    />
                ))}
            </ul>
        );
    }
);

class DealsHubAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchingAdminSections: false,
            serverUrl: serverUrl,
            spinnerLoading: false,
            bannerTypes: null,
            displayBannerModal: false,
            bannerRadioValue: null,
            ItemLength: 1,
            searchListing: [],
            addCategoryInProgress: false,
            saveOrderInProgress: false,
            addBannerInProgress: false
        };
    }

    spinnerOn = (status) => {
        this.setState({spinnerLoading: status});
    }

    fetchInitialData = () => {
        this.setState({
            fetchingAdminSections: true
        }, () => {
            axios.post(`${serverUrl}/dealshub/fetch-${this.props.name === "WIGme - B2B" ? "b2b-" : ""}dealshub-admin-sections-oc/`, {
                websiteGroupName: localStorage.getItem("websiteGroupName"),
                locationGroupUuid: this.props.locationUuid
            }).then(response => {
                if (response.data.status === 200) {
                    this.props.fetchDealsHubCategoryData(response.data.sections_list);
                    this.setState({
                        fetchingAdminSections: false
                    });
                } else if (response.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong!");
                }
            });
        });

        axios.post(`${this.state.serverUrl}/dealshub/fetch-banner-types/`, {
            websiteGroupName: localStorage.getItem("websiteGroupName"),
            locationGroupUuid: this.props.locationUuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            this.setState({
                bannerTypes: response.data.bannerTypes,
                bannerRadioValue: response.data.bannerTypes.length > 0 ? response.data.bannerTypes[0].type : null
            });
        });
    }

    componentDidMount = () => {
        this.fetchInitialData();
        this.setState({
            locationUuid: this.props.locationUuid
        })
    };

    componentDidUpdate = (prevState, prevProps) => {
        if (prevState.locationUuid != this.state.locationUuid)
            this.fetchInitialData();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.locationUuid != prevState.locationUuid) {
            return {
                locationUuid: nextProps.locationUuid
            }
        }

        return null
    }

    saveOrdering = () => {
        this.setState({
            saveOrderInProgress: true
        });
        let orderedList = [];
        for(let i = 0;i < this.props.dealsHubAdmin?.length; i++){
            orderedList.push({uuid: this.props.dealsHubAdmin[i].uuid, type: this.props.dealsHubAdmin[i].type});
        }
        axios.post(`${this.state.serverUrl}/dealshub/save-dealshub-admin-sections-order/`, {
            websiteGroupName: localStorage.getItem("websiteGroupName"),
            dealshubAdminSections: orderedList,
            locationGroupUuid: this.props.locationUuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                message.success("Orders applied Successfully!");
                this.setState({
                    saveOrderInProgress: false
                });
            } else if (response.data.status === 405) {
                message.info("You are not authorised!")
            } else {
                message.error("Something went wrong!");
            }
        });
    };

    changeBannerRadioValue = (event) => {
        this.setState({
            bannerRadioValue: event.target.value
        });
    }

    defaultSectionCount = (data, sectionType) => {
        let foundArr = Array.apply(null, Array(data.length)).map(function () {
            return false
        });

        for (let outerIndex = 0; outerIndex < data.length; ++outerIndex) {
            for (let innerIndex = 0; innerIndex < data.length; ++innerIndex) {
                if (data[innerIndex].name.includes(`Default ${sectionType} ${outerIndex}`)) {
                    foundArr[outerIndex] = true;
                    break;
                }
            }
        }

        for (let index = 0; index < data.length; ++index) {
            if (foundArr[index] === false) {
                return index;
            }
        }
        return data.length + 1;
    }

    addCategory = () => {
        this.setState({
            addCategoryInProgress: true
        }, () => {
            let newCategory = {
                uuid: this.props.dealsHubAdmin.length + 1,
                type: "ProductListing",
                name: `Default Category ${this.defaultSectionCount(this.props.dealsHubAdmin, "Category")}`,
                listingType: "Carousel",
                products: [],
                isPublished: false,
                createdOn: "",
                modifiedOn: "",
                createdBy: "None",
                modifiedBy: "None",
                is_promotional: false,
                start_time: null,
                end_time: null,
                promotion_tag: null
            };

            axios.post(`${serverUrl}/dealshub/create-admin-category/`, {
                websiteGroupName: localStorage.getItem("websiteGroupName"),
                sectionData: newCategory,
                locationGroupUuid: this.props.locationUuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`

                }
            }).then(response => {
                if (response.data.status === 200) {
                    newCategory["uuid"] = response.data.uuid;
                    this.props.addcategory(newCategory);
                    message.success("Category created Successfully!");
                    this.setState({
                        addCategoryInProgress: false
                    });
                } else if (response.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong!");
                }
            });
        });
    };

    selectBannerModal = () => {
        this.setState({displayBannerModal: true});
    }

    handleBannerModalOk = () => {
        this.setState({
            addBannerInProgress: true
        }, () => {
            let newBanner = {
                uuid: this.props.dealsHubAdmin.length + 1,
                type: "Banner",
                name: `Default Banner ${this.defaultSectionCount(this.props.dealsHubAdmin, "Banner")}`,
                bannerType: this.state.bannerRadioValue,
                bannerImages: [],
                limit: 0,
                isPublished: false,
                is_promotional: false,
                createdOn: "",
                modifiedOn: "",
                createdBy: "None",
                modifiedBy: "None"
            };
            axios.post(`${serverUrl}/dealshub/create-banner/`, {
                name: newBanner.name,
                bannerType: this.state.bannerRadioValue,
                websiteGroupName: localStorage.getItem("websiteGroupName"),
                locationGroupUuid: this.props.locationUuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.data.status === 200) {
                    newBanner["uuid"] = response.data.uuid;
                    newBanner["limit"] = response.data.limit;
                    this.props.addBanner(newBanner);
                    message.success("Banner created Successfully!");
                    this.setState({
                        displayBannerModal: false,
                        bannerRadioValue: this.state.bannerTypes[0].type,
                        addBannerInProgress: false
                    });
                } else if (response.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong!");
                }
            });
        });
    }

    deleteCategory = (requiredIndex) => {
        this.setState({
            spinnerLoading: true
        }, () => {
            var tempState = this.props.dealsHubAdmin.splice(requiredIndex, 1);
            this.props.handleDelete(this.props.dealsHubAdmin);
            this.setState({spinnerLoading: false});
        });
    }

    handleBannerModalCancel = () => {
        this.setState({
            displayBannerModal: false,
            bannerRadioValue: this.state.bannerTypes[0].type
        });
    }

    willReceiveProps = () => {
        this.setState({ItemLength: this.props.dealsHubAdmin.length, spinnerLoading: false});
    }

    render() {

        if (this.state.ItemLength !== this.props.dealsHubAdmin.length) {
            this.willReceiveProps();
        }
        if (this.state.fetchingAdminSections) {
            return (
                <div className="dealshub-admin-section-loader-container">
                    <Spin indicator={antIcon} tip="Fetching Dealshub Admin Data"/>
                </div>
            );
        } else {
            return (

                <React.Fragment>
                    <Row className="dealshubadmincontainer">
                        <Row
                            className="buttons"
                            type="flex"
                            justify="space-between"
                            align="middle"
                        >
                            <Col>
                                <Title style={{marginBottom: "0px", color: "white"}} level={4}>
                                    Sections ({this.props.name})
                                </Title>
                            </Col>
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={this.saveOrdering}
                                    loading={this.state.saveOrderInProgress}
                                    disabled={
                                        this.state.addCategoryInProgress ||
                                        this.state.addBannerInProgress
                                    }
                                >
                                    Save Order
                                </Button>
                                <Button
                                    type="danger"
                                    onClick={this.addCategory}
                                    loading={this.state.addCategoryInProgress}
                                    disabled={
                                        this.state.addBannerInProgress ||
                                        this.state.saveOrderInProgress
                                    }
                                >
                                    Add Category
                                </Button>

                                {this.state.bannerTypes
                                    // this.state.bannerTypes.length > 0
                                    ? (
                                        <>
                                            <Button
                                                className="purple-btn"
                                                onClick={this.selectBannerModal}
                                                loading={this.state.addBannerInProgress}
                                                disabled={
                                                    this.state.saveOrderInProgress ||
                                                    this.state.addCategoryInProgress
                                                }
                                            >Add Banner</Button>
                                            <Modal
                                                title="Select Banner Type"
                                                visible={this.state.displayBannerModal}
                                                onOk={this.handleBannerModalOk}
                                                onCancel={this.handleBannerModalCancel}
                                                width="30%"
                                                style={{display: "flex", justifyContent: "center"}}
                                                footer={[
                                                    <Button key="back" onClick={this.handleBannerModalCancel}>
                                                        Cancel
                                                    </Button>,
                                                    <Button
                                                        key="submit"
                                                        type="primary"
                                                        onClick={this.handleBannerModalOk}
                                                        loading={this.state.addBannerInProgress}
                                                        disabled={
                                                            this.state.saveOrderInProgress ||
                                                            this.state.addCategoryInProgress
                                                        }
                                                    >
                                                        Create Banner
                                                    </Button>,
                                                ]}
                                            >
                                                <Radio.Group
                                                    onChange={this.changeBannerRadioValue}
                                                    value={this.state.bannerRadioValue}
                                                >
                                                    {this.state.bannerTypes.map(banner => (
                                                        <Radio
                                                            key={banner.type + "-radio-value"}
                                                            value={banner.type}
                                                            style={{display: "block"}}
                                                        >
                                                            {banner.name}
                                                        </Radio>
                                                    ))}
                                                </Radio.Group>
                                            </Modal>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Col>
                        </Row>
                        <SortableList
                            items={this.props.dealsHubAdmin}
                            deleteCategory={this.deleteCategory}
                            spinnerOn={this.spinnerOn}
                            onSortEnd={this.props.orderList}
                            axis="xy"
                            distance={2}
                            siteName={this.props.name}
                            locationUuid={this.props.locationUuid}
                        />
                        {
                            this.props.dealsHubAdmin.length == 0 &&
                            <Row type="flex" justify="center" align="middle" style={{marginTop: "100px"}}>
                                <h5 style={{color: "#fff"}}>No Sections Added</h5>
                            </Row>
                        }
                    </Row>


                </React.Fragment>

            );
        }
    }
}

const mapStateToProps = ({dealsHubAdmin}) => {
    return {
        dealsHubAdmin
    };
};

export default connect(mapStateToProps, {
    addcategory,
    addBanner,
    fetchDealsHubCategoryData,
    handleUpdate,
    orderList,
    handleDelete
})(DealsHubAdmin);
