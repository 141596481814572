import React, { Component } from 'react';
import { Row, Col, Typography, Button, Checkbox, Avatar, Icon, Tooltip, Tag, Collapse, InputNumber } from 'antd';
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import UserAvatar from "../../media/male_avatar.png";
import "./proforma-invoice.scss";
import ProformaInvoiceDownloadAllModal from '../components/proforma-invoice-download-all-modal/ProformaInvoiceDownloadAllModal';

const { Title, Text } = Typography;
const { Panel } = Collapse;
const genExtra = (str) => (
    <Button type="primary" icon="download" size="small" />
);

export default class ProformaInvoice extends Component {
    render() {
        return (
            <Row className="proforma-invoice-page-container ">
                <TitleWithBreadCrumb title="Proforma Invoice" breadcrumb={["OmnyComm", "Proforma Invoice"]} />
                <Col span={24} className="proforma-invoice-table-container" >
                    <Row className="top-nav">
                        <Col span={12}>

                        </Col>
                        <Col span={12}>
                            <Row type="flex" justify="end">
                                <ProformaInvoiceDownloadAllModal />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="proforma-table">
                        <Col span={24}>
                            <Row>
                                <Collapse expandIconPosition="left" className="collapse">
                                    <Panel header="Zibo Deli Glass Products Co.Ltd" extra={genExtra()} className="">
                                        <Row type="flex" align="middle" className="product-table-head-row">
                                            <Col span={14}>
                                                <Text className="table-head-text">
                                                    Product Name
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    Code
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    MOQ
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    Price
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    QTY
                                                </Text>
                                            </Col>
                                            <Col span={2}>

                                            </Col>
                                        </Row>
                                        <Row type="flex" align="middle" className="product-row">
                                            <Col span={14}>
                                                <Avatar shape="square" size={44} className="margin-right" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/1569678494GAF37501_K7TCjHF.jpg" />
                                                <Text >
                                                    8 Pcs Non Stick Cookware Set 1X2
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text >
                                                    RF4999
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text >
                                                    223
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text >
                                                    19
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <InputNumber size="small" min={0} max={100} />
                                            </Col>
                                            <Col span={2}>
                                                <Button type="primary">Remove</Button>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Row>

                            {/* row 2 */}
                            <Row>
                                <Collapse expandIconPosition="left" className="collapse">
                                    <Panel header="Zibo Deli Glass Products Co.Ltd" extra={genExtra()} className="">
                                        <Row type="flex" align="middle" className="product-table-head-row">
                                            <Col span={14}>
                                                <Text className="table-head-text">
                                                    Product Name
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    Code
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    MOQ
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    Price
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text className="table-head-text">
                                                    QTY
                                                </Text>
                                            </Col>
                                            <Col span={2}>

                                            </Col>
                                        </Row>
                                        <Row type="flex" align="middle" className="product-row">
                                            <Col span={14}>
                                                <Avatar shape="square" size={44} className="margin-right" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/1569678494GAF37501_K7TCjHF.jpg" />
                                                <Text >
                                                    8 Pcs Non Stick Cookware Set 1X2
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text >
                                                    RF4999
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text >
                                                    223
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <Text >
                                                    19
                                                </Text>
                                            </Col>
                                            <Col span={2}>
                                                <InputNumber size="small" min={0} max={100} />
                                            </Col>
                                            <Col span={2}>
                                                <Button type="primary">Remove</Button>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Row>

                        </Col>

                    </Row>
                </Col>
            </Row>
        )
    }
}
