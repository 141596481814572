import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';
import "../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss"
import "./image-slider-thumbnail.scss"

export default class ImageSliderThumbnail extends Component {



    render() {

        const images = this.props.imageData.map(image => { return { original: image.midimage_url, thumbnail: image.thumbnail_url } })
        return (
            <div className="carousel-container">
                <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} />
            </div>
        )
    }
}
