import React, { Component } from "react";
import {
  Card,
  Divider,
  Row,
  Col,
  Button,
  Modal,
  Collapse,
  Icon,
  Avatar,
  Typography,
} from "antd";

import "./factory-card.scss";

import { Link } from "react-router-dom";

import isDev from "../../../server";

const serverURL = isDev();

const { Text } = Typography;

export default class FactoryCard extends Component {


  componentDidMount = () => {

  }

  handleDownload = () => {
    window.open(this.props.data.filepath, "_blank");
  }

  render() {
    //.log(this.props.data)


    return (


      <React.Fragment>
        <Card bordered={true} className="factory-card">

          <Row style={{ padding: "10px 0px 0px 0px" }}>
            <Col span={24}>
              <Row type="flex" >
                <Col span={12}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 1, expandable: false }}
                    style={{ color: "rgba(255, 255, 255, 0.6)", margin: "0px" }}
                  >
                    Factory Name:
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 1, expandable: false }}
                    style={{ margin: "0px" }}
                  >
                    {this.props.data && this.props.data.factory_name}
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ padding: "0px 0px 0px 0px" }}>
            <Col span={24}>
              <Row type="flex">
                <Col span={12}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 1, expandable: false }}
                    style={{ color: "rgba(255, 255, 255, 0.6)", margin: "0px" }}
                  >
                    Total Product(s):
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Text>{this.props.data && this.props.data.product_count}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ padding: "0px 0px 0px 0px" }}>
            <Col span={24}>
              <Row type="flex">
                <Col span={12}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 1, expandable: false }}
                    style={{ color: "rgba(255, 255, 255, 0.6)", margin: "0px" }}
                  >
                    Total Qty:
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Text>{this.props.data && this.props.data.product_qty_count}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row type="flex">
                <Col span={12}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 1, expandable: false }}
                    style={{ color: "rgba(255, 255, 255, 0.6)", margin: "0px" }}
                  >
                    Date:
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Text>{this.props.data && this.props.data.created_date}</Text>
                </Col>
              </Row>
            </Col>
          </Row>



          <Row gutter={[8, 8]} style={{ marginTop: "10px" }}>
            <Col lg={12} md={12}>
              <Link to={`/view-pi/${this.props.data.uuid}`}>
                <Button type="default" style={{ width: "100%" }}>
                  View PI
              </Button>
              </Link>
            </Col>

            <Col lg={12} md={12}>
              <Button disabled={this.props.data && !this.props.data.pdf_ready} onClick={this.handleDownload} type="primary" style={{ width: "100%" }} icon="download">
                Download
              </Button>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}
