import React, { Component } from 'react';
import {
	Avatar,
	Button,
	Col,
	Icon,
	Row,
	Typography,
	Input,
	Rate,
	message,
	Upload,
	DatePicker
} from 'antd';
import axios from 'axios';
import isDev from '../../server';
import moment from 'moment';
//import ImageWall from "./ImageWall";

const { Text } = Typography;
const serverUrl = isDev();

export default class ReviewDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editable: false,
			processing: false,
			id: '',
			reviewList: '',
			disable: false,
		};
	}

	handleDelete = (e) => {
		const tmp = this.props.image_list.filter((item) => e.uid === item.uuid);
		if (tmp.length > 0) this.props.handleDeleteImage(tmp[0].uuid);
	};

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };



	render() {
		console.log(this.props, 'props');
		return (
			<React.Fragment>
				<Row>
					<Col span={24}>
						<Row style={{ marginBottom: '33px' }}>
							<Col span={4}>
								{this.props.reviewItem.is_fake === false ? (
									<Text style={{ color: '#F1F1F1' }}> Original Review</Text>
								) : (
									<Text style={{ color: '#F1F1F1' }}> Pirate Review</Text>
								)}
							</Col>
							<Col span={20}>
								{this.props.reviewItem.is_fake === true ? (
									<>
										<Text style={{ fontWeight: '600', color: '#F1F1F1' }}>
											Pirate Reviewer:{' '}
										</Text>
										<Text style={{ color: '#F1F1F1' }}>
											{this.props.reviewItem.fake_oc_user}
										</Text>
									</>
								) : (
									<Text> </Text>
								)}
							</Col>
						</Row>
						<Row style={{ backgroundColor: '#454E57', lineHeight: 2 }}>
							<Col
								span={24}
								style={{
									marginTop: '20px',
									marginBottom: '17px',
									marginLeft: '20px',
								}}
							>
								<Text
									style={{
										color: '#FFFFFF',
										fontWeight: 'bold',
										fontSize: '16px',
									}}
								>
									Product Information
								</Text>
							</Col>
							<Col
								span={3}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									marginLeft: '20px',

									marginBottom: '20px',
								}}
							>
								<Avatar
									shape='square'
									size={80}
									src={this.props.reviewItem.product_details.image_url}
								/>
							</Col>
							<Col span={20}>
								<Text style={{ color: '#dee2e6' }}>
									Code: {this.props.reviewItem.product_details.seller_sku}
								</Text>
							</Col>
							<Col span={20}>
								<Text style={{ color: '#dee2e6' }}>
									Name: {this.props.reviewItem.product_details.name}
								</Text>
							</Col>
							{/*<Col span={20}>*/}
							{/*    <Text style={{color: "#dee2e6"}}>Average Rating:</Text>*/}
							{/*    <Text style={{fontWeight: "bold", fontSize: "16px"}}>{" "}4.5</Text>*/}
							{/*    <Text style={{color:"#5BCAAA", fontSize: "16px"}}>{" "}(Based on 10 reviews)</Text>*/}
							{/*</Col>*/}
						</Row>
						<Row style={{ backgroundColor: '#454E57', marginTop: '30px' }}>
							<Col span={24} style={{ margin: '20px', display: "flex", alignItems: "center" }}>
								<Text
									style={{
										color: '#FFFFFF',
										fontWeight: 'bold',
										fontSize: '16px',
									}}
								>
									Review
								</Text>
								{this.props.reviewItem.is_fake && (
									this.state.editable ? 
										<div
											onClick={() => {
												this.setState({
													editable: false,
												});
											}}
											style={{
												display: "flex", 
												alignItems: "center", 
												width: 'fit-content',
												cursor: 'pointer'
											}}
										>
											<Text
												style={{
													fontSize: '12px',
													color: '#5BCAAA',
													paddingLeft: '12.5px',
													paddingRight: '2px',
												}}
												
											>
												Close
											</Text>
											<Icon
												style={{
													color: '#5BCAAA',
													height: '12px',
													width: '12px',
													//paddingLeft: '12.5px',
												}}
												type='close-circle'
											/>
										</div>
									 : 
										<div
											onClick={() => {
												this.setState({
													editable: true,
												});
											}}
											style={{
												display: "flex", 
												alignItems: "center", 
												width: 'fit-content',
												cursor: 'pointer'
											}}
										>
											<Text
												style={{
													fontSize: '12px',
													color: '#5BCAAA',
													paddingLeft: '12.5px',
													paddingRight: '2px',
												}}
												
											>
												Edit
											</Text>
											<Icon
												style={{
													color: '#5BCAAA',
													height: '12px',
													width: '12px',
													//paddingLeft: '12.5px',
												}}
												type='form'
											/>
										</div>
									
								)}
							</Col>
							{this.state.editable === true ? (
								<Col
									lg={21}
									md={21}
									sm={24}
									style={{ marginLeft: '20px', lineHeight: 2 }}
								>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Display Name:</Text>
										</Col>
										<Col lg={20} md={20} sm={14}>
											<Input
												onChange={(e) =>
													this.props.handleChangeDisplayName(e.target.value)
												}
												value={this.props.displayName}
												//defaultValue={this.props.reviewItem.display_name}
											/>
										</Col>
									</Row>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Review Date:</Text>
										</Col>
										<Col lg={20} md={20} sm={14}>
											<DatePicker
												value={moment(this.props.created_at)}
												onChange={(date,str) => this.props.handleChangeCreatedAt(str)}
											/>
										</Col>
									</Row>
									{/*<Row type="flex" gutter={[8, 8]}>*/}
									{/*    <Col lg={3} md={3} sm={10}>*/}
									{/*        <Text style={{color: "#dee2e6"}}>*/}
									{/*            Review Date:*/}
									{/*        </Text>*/}
									{/*    </Col>*/}
									{/*    <Col lg={21} md={21} sm={14}>*/}
									{/*        <Input defaultValue={this.props.reviewItem.created_date}/>*/}
									{/*    </Col>*/}

									{/*</Row>*/}
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Rating:</Text>
										</Col>

										<Col
											span={20}
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												paddingTop: '9px',
											}}
										>
											<Rate
												style={{ fontSize: '12px' }}
												onChange={(value) =>
													this.props.handleChangeRating(value)
												}
												value={Number(this.props.rating)}
												// defaultValue={this.props.reviewItem.rating}
											/>
										</Col>
									</Row>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Subject:</Text>
										</Col>

										<Col lg={20} md={20} sm={14}>
											<Input
												onChange={(e) =>
													this.props.handleSubjectChange(e.target.value)
												}
												value={this.props.reviewSubject}
												// defaultValue={this.props.reviewItem.review_content.subject}
											/>
										</Col>
									</Row>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={3} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Review Body:</Text>
										</Col>
										<Col lg={20} md={21} sm={14}>
											<Input.TextArea
												placeholder='Enter review content here'
												value={this.props.reviewBody}
												onChange={(e) =>
													this.props.handleChangeReviewBody(e.target.value)
												}
											/>
										</Col>
									</Row>
									<Row
										type='flex'
										gutter={[8, 8]}
										style={{ marginBottom: '20px' }}
									>
										<Col lg={4} md={3} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Images:</Text>
										</Col>
										{this.props.image_list.length > 0 && (
											<Col lg={20} md={21} sm={14}>
												{this.props.image_list.map((imageItem) => {
													return (
														<Col lg={3} md={3} sm={24} key={imageItem.uuid}>
															<Avatar
																shape='square'
																size={80}
																src={imageItem.url}
															/>
														</Col>
													);
												})}
											</Col>
										)}

										<Col
											lg={24}
											md={24}
											sm={24}
											style={{
												marginLeft: '17.92px',
												marginTop: '27.92px',
											}}
										>
											<Upload
												multiple={true}
												fileList={this.props.fileList}
												showUploadList={true}
												listType='picture-card'
												onChange={this.props.handleChangeImages}
												onRemove={this.handleDelete}
												customRequest={this.dummyRequest}
											>
												{this.props.fileList.length > 5 ? null : (
													<div>
														<Icon type='plus' />
														<div className='ant-upload-text'>Upload</div>
													</div>
												)}
											</Upload>
										</Col>

										<Col span={24}>
											<Button
												type='primary'
												loading={this.state.processing}
												onClick={() => {
													this.setState({ processing: true }, () => {
														this.props.handleUpdateReview(() => {
															this.setState({
																editable: false,
																processing: false,
															});
														});
													});
												}}
											>
												Save
											</Button>
										</Col>
									</Row>
								</Col>
							) : (
								<Col
									lg={21}
									md={21}
									sm={24}
									style={{ marginLeft: '20px', lineHeight: 2 }}
								>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Display Name:</Text>
										</Col>
										<Col lg={20} md={20} sm={14}>
											<Text>{this.props.displayName}</Text>
										</Col>
									</Row>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Review Date:</Text>
										</Col>
										<Col lg={20} md={20} sm={14}>
											<Text>{this.props.created_at}</Text>
										</Col>
									</Row>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Rating:</Text>
										</Col>

										<Col
											lg={20} md={20} sm={14}
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												paddingTop: '9px',
											}}
										>
											<Rate
												style={{ fontSize: '12px' }}
												disabled
												defaultValue={Number(this.props.rating)}
											/>
										</Col>
									</Row>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Subject:</Text>
										</Col>

										<Col lg={20} md={20} sm={14}>
											<Text>{this.props.reviewSubject}</Text>
										</Col>
									</Row>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Review Body:</Text>
										</Col>
										<Col lg={20} md={20} sm={14}>
											<Text>{this.props.reviewBody}</Text>
										</Col>
									</Row>
									<Row
										type='flex'
										gutter={[8, 8]}
										style={{ marginBottom: '20px' }}
									>
										<Col lg={4} md={4} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Images:</Text>
										</Col>
										{this.props.image_list.length > 0 && (
											<Col lg={20} md={20} sm={14}>
												{this.props.image_list.map((imageItem) => {
													return (
														<Col lg={3} md={3} sm={24} key={imageItem.uuid}>
															<Avatar
																shape='square'
																size={80}
																src={imageItem.url}
															/>
														</Col>
													);
												})}
											</Col>
										)}
									</Row>
								</Col>
							)}
						</Row>
						<Row style={{ backgroundColor: '#454E57', marginTop: '30px' }}>
							<Col span={24} style={{ margin: '20px' }}>
								<Text
									style={{
										color: '#FFFFFF',
										fontWeight: 'bold',
										fontSize: '16px',
									}}
								>
									Admin Reply
								</Text>
							</Col>

							{this.props.reviewItem.review_content.admin_comment === null ? (
								<Col
									lg={21}
									md={21}
									sm={24}
									style={{ marginLeft: '20px', lineHeight: 2 }}
								>
									<Row
										type='flex'
										gutter={[8, 8]}
										style={{ marginBottom: '20px' }}
									>
										<Col lg={3} md={3} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Comment:</Text>
										</Col>
										<Col lg={18} md={18} sm={14}>
											<Input.TextArea
												style={{
													backgroundColor: '#21262A',
													width: '585px',
													height: '88px',
												}}
												placeholder='Add a comment...'
												value={this.props.reply}
												onChange={(e) =>
													this.props.handleChangereply(e.target.value)
												}
											/>
										</Col>
										<Col lg={3} md={3} sm={10}>
											<Button
												type='primary'
												onClick={() => {
													this.props.handleAdminReply();
												}}
											>
												Reply
											</Button>
										</Col>
									</Row>
								</Col>
							) : (
								<Col
									lg={21}
									md={21}
									sm={24}
									style={{ marginLeft: '20px', lineHeight: 2 }}
								>
									<Row type='flex' gutter={[8, 8]}>
										<Col lg={3} md={3} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Admin Name:</Text>
										</Col>
										<Col lg={21} md={21} sm={14}>
											<Text>
												{
													this.props.reviewItem.review_content.admin_comment
														.username
												}
											</Text>
										</Col>
									</Row>

									<Row
										type='flex'
										gutter={[8, 8]}
										style={{ marginBottom: '20px' }}
									>
										<Col lg={3} md={3} sm={10}>
											<Text style={{ color: '#dee2e6' }}>Comment:</Text>
										</Col>
										<Col lg={18} md={18} sm={14}>
											<Input.TextArea
												style={{
													backgroundColor: '#21262A',
													width: '585px',
													height: '88px',
												}}
												placeholder='Add a comment...'
												// defaultValue={this.props.reviewItem.review_content.admin_comment.comment}
												value={this.props.reply}
												onChange={(e) =>
													this.props.handleChangereply(e.target.value)
												}
											/>
										</Col>
										<Col lg={3} md={3} sm={10}>
											<Button
												type='primary'
												onClick={() => {
													this.props.handleAdminReply();
												}}
											>
												Save
											</Button>
										</Col>
									</Row>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}
