/**
 * @description Static platform landing page describing about omnycomm
 *
 */

import React, { Component } from "react";
import {
  Layout,
  Menu,
  Card,
  Dropdown,
  Icon,
  Button,
  Row,
  Col,
  Typography,
  Avatar,
  Timeline,
  Divider,
  Input,
} from "antd";

import "./home-page.scss";
import HeroImg from "../media/hero-img.png";
import FeatureImg from "../media/features-img.png";
import Icon1 from "../media/ui.svg";
import Icon2 from "../media/launch.svg";
import Icon3 from "../media/shop.svg";
import Icon4 from "../media/business.png";
import Icon5 from "../media/pallet.png";
import Icon6 from "../media/team.png";
import Icon7 from "../media/rocket.svg";
import GeepasLogo from "../media/GEEPAS-logo2.jpeg";
import NestoLogo from "../media/nesto-logo.png";
import KryptonLogo from "../media/krypton-logo.png";
import OlsenmarkLogo from "../media/olsenmark-logo.png";
import RoyalfordLogo from "../media/royalford-logo.png";
import YounglifeLogo from "../media/younglife-logo.jpg";

import Icon8 from "../media/backup-file.svg";
import Icon9 from "../media/log.svg";
import Icon10 from "../media/setup.svg";
import Icon11 from "../media/stopwatch.svg";
import Icon12 from "../media/responsive-design.svg";
import HeaderLandingPage from "../views/components/landing-page-header/HeaderLandingPage";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;

export default class HomePage extends Component {
  render() {
    return (
      <div className="home-page-container">
        <Layout className="layout">
          <HeaderLandingPage />
          <Content>
            <div style={{ background: "#fff", minHeight: 1000 }}>
              <Row
                type="flex"
                align="middle"
                gutter={[120, 120]}
                className="hero-section"
              >
                <Col lg={12} sm={24}>
                  <Row gutter={[16, 16]} style={{ paddingRight: "250px" }}>
                    <Col span={24}>
                      <Title className="bold-font" level={1}>
                        Start Locally,
                      </Title>
                      <Title className="bold-font" level={1}>
                        Scale Globally.
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Text>
                        Omnycomm is a digital ecosystem that gets your products
                        online and facilitate e-commerce to scale your business.
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Button type="primary" size="large">
                        Book a Demo
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} sm={24}>
                  <img src={HeroImg} className="hero-img" />
                </Col>
              </Row>

              <Row
                type="flex"
                gutter={[80, 80]}
                className="product-info-section"
              >
                <Col span={8}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <img src={Icon1} alt="icon-1" />
                    </Col>
                    <Col span={24}>
                      <Text strong className="text-color-1">
                        Unify and manage product data
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        No more chasing scattered flat files containing product
                        information. Centralize and manage it on a single
                        platform.
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <img src={Icon2} alt="icon-2" />
                    </Col>
                    <Col span={24}>
                      <Text strong className="text-color-1">
                        Get your products online in a snap
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        Instead of uploading products manually on each
                        E-commerce channel, extrapolate product data into custom
                        flat files for easy bulk uploads.
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <img src={Icon3} alt="icon-3" />
                    </Col>
                    <Col span={24}>
                      <Text strong className="text-color-1">
                        Have your own e-store
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        With products synced on Omnycomm, generating your own,
                        proprietary e-commerce store will take less than 30
                        minutes.
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                className="timeline-section"
                type="flex"
                justify="center"
                gutter={[16, 16]}
              >
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Col>
                      <Title level={1} className="bold-font">
                        How it works
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Col>
                      <Timeline>
                        <Timeline.Item>
                          <Row>
                            <Col span={3}></Col>
                            <Col span={3}>
                              <Avatar src={Icon4} size={78} shape="square" />
                            </Col>
                            <Col span={18}>
                              <Text strong className="text-color-2">
                                You Partner With Us.{" "}
                              </Text>
                              <Text>
                                Book a demo to learn how we can sync your
                                requirements with our features.
                              </Text>
                            </Col>
                          </Row>
                        </Timeline.Item>
                        <Timeline.Item>
                          <Row>
                            <Col span={3}></Col>
                            <Col span={3}>
                              <Avatar src={Icon5} size={78} shape="square" />
                            </Col>
                            <Col span={18}>
                              <Text strong className="text-color-2">
                                Get Onboarded.{" "}
                              </Text>
                              <Text>
                                Our support teams will help you in sanitizing
                                your data and adding it to Omnycomm.
                              </Text>
                            </Col>
                          </Row>
                        </Timeline.Item>
                        <Timeline.Item>
                          <Row>
                            <Col span={3}></Col>
                            <Col span={3}>
                              <Avatar src={Icon6} size={78} shape="square" />
                            </Col>
                            <Col span={18}>
                              <Text strong className="text-color-2">
                                Grant Permissions.{" "}
                              </Text>
                              <Text>
                                Give access to your teams and assign role based
                                permissions.
                              </Text>
                            </Col>
                          </Row>
                        </Timeline.Item>
                        <Timeline.Item>
                          <Row>
                            <Col span={3}></Col>
                            <Col span={3}>
                              <Avatar src={Icon7} size={78} shape="square" />
                            </Col>
                            <Col span={18}>
                              <Text strong className="text-color-2">
                                Go Online.{" "}
                              </Text>
                              <Text>
                                Your company is ready and live on Omnycomm.
                              </Text>
                            </Col>
                          </Row>
                        </Timeline.Item>
                      </Timeline>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="center" className="features-section">
                <Col style={{ marginBottom: "50px" }}>
                  <Title level={3}>
                    Features on Omnycomm are designed to streamline your
                    operations and increase your distribution network
                  </Title>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={14}></Col>
                    <Col span={10}>
                      <Title className="bold-font" level={1}>
                        Key Features
                      </Title>
                    </Col>
                    <Col span={14}>
                      <img src={FeatureImg} className="features-img" />
                    </Col>
                    <Col span={10}>
                      <Row gutter={[32, 32]}>
                        <Col span={24} className="catalogue-container">
                          <Text strong className="display-block text-color-2">
                            Product Catalogue
                          </Text>
                          <Text className="display-block">
                            Catalogue all your products in a secure and
                            centralized database.
                          </Text>
                        </Col>
                        <Col span={24} className="">
                          <Text strong className="display-block text-color-1">
                            Generate E-commerce Exports
                          </Text>
                          <Text className="display-block">
                            Bundle your products and export them quickly to all
                            major E-commerce channels.
                          </Text>
                        </Col>
                        <Col span={24} className="">
                          <Text strong className="display-block text-color-1">
                            Manage Sourcing
                          </Text>
                          <Text className="display-block">
                            Simplify sourcing via features like automated PO
                            creation, barcode generation and multi-currency
                            transactions.
                          </Text>
                        </Col>
                        <Col span={24} className="">
                          <Text strong className="display-block text-color-1">
                            Go Online!
                          </Text>
                          <Text className="display-block">
                            Publish your products on your own proprietary
                            e-commerce channel and generate a fresh stream of
                            revenue for your company.
                          </Text>
                        </Col>
                        <Col span={24} className="">
                          <Text strong className="display-block text-color-1">
                            Generate Flyers
                          </Text>
                          <Text className="display-block">
                            Use our tool to generate flyers in less than 6
                            minutes for marketing to your customer segments
                          </Text>
                        </Col>
                        <Col span={24} className="">
                          <Row type="flex" justify="left">
                            <Col>
                              <Button type="primary" size="large">
                                Watch Tutorials
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="brand-section">
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Col style={{ marginTop: "30px" }}>
                      <Title level={3}>
                        Over 1,000 teams manage their product operations using
                        Omnycomm
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" align="middle" gutter={[16, 16]}>
                    <Col span={4}>
                      <img src={GeepasLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={NestoLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={KryptonLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={OlsenmarkLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={RoyalfordLogo} className="brand-logo" />
                    </Col>
                    <Col span={4}>
                      <img src={YounglifeLogo} className="brand-logo" />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                className="omnycomm-info-section"
                type="flex"
                justify="center"
                gutter={[32, 32]}
              >
                <Col span={24} style={{ marginBottom: "30px" }}>
                  <Row type="flex" justify="center">
                    <Col>
                      <Title level={1} className="bold-font">
                        Why you will love Omnycomm
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" justify="center" gutter={[22, 22]}>
                    <Col>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src={Icon12} />}
                        className="info-card"
                      >
                        <Meta
                          title="Responsive"
                          description="You can use Omnycoom's
                                                    cataloguing feature across
                                                    all devices - Web,
                                                    Tablet, and Mobile."
                        />
                      </Card>
                    </Col>

                    <Col>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src={Icon11} />}
                        className="info-card"
                      >
                        <Meta
                          title="Lightning Fast"
                          description="Omnycomm is super
                                                    fast, stable, and responsive."
                        />
                      </Card>
                    </Col>

                    <Col>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src={Icon10} />}
                        className="info-card"
                      >
                        <Meta
                          title="Support"
                          description="Our support team is
                                                    relentless in serving you
                                                    and making your experience
                                                    delightful."
                        />
                      </Card>
                    </Col>

                    <Col>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src={Icon9} />}
                        className="info-card"
                      >
                        <Meta
                          title="Activity Log"
                          description="We maintain an activity
                                                    log for each user to ensure
                                                    accountability of action.."
                        />
                      </Card>
                    </Col>

                    <Col>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src={Icon8} />}
                        className="info-card"
                      >
                        <Meta
                          title="Backups"
                          description="We maintain multiple
                                                    backups to secure your data."
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="demo-container-parent">
                  <Row
                    class="demo-container"
                    type="flex"
                    justify="space-between"
                    align="middle"
                    style={{
                      padding: "50px",
                      border: "1px solid #e5e5e5",
                      borderRadius: "5px",
                      backgroundColor: "white",
                    }}
                  >
                    <Col>
                      <Row>
                        <Col span={24}>
                          <Text strong className="text-color-2">
                            Ready to Get Started?
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Start your free 30 day trial now</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Button type="primary" size="large">
                        Book a Demo
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    className="review-section"
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{
                      padding: "80px",
                    }}
                  >
                    <Col span={3}>
                      <Avatar
                        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        size={78}
                      />
                    </Col>
                    <Col span={21} style={{ marginBottom: "30px" }}>
                      <Text className="text-color-3">
                        “Omnycomm is a game changer. Our product processes used
                        to involve an ineffective mix of spreadsheets,
                        documents, and a slew of file storage solutions. With
                        Omnycomm, we've solved all of these process problems and
                        more, and we can now start the sales process earlier. To
                        top it off, our clients love it too.”
                      </Text>
                    </Col>
                    <Col span={3}></Col>
                    <Col span={21}>
                      <Text strong className="display-block text-color-3">
                        John Doe
                      </Text>
                      <Text className="text-color-3">
                        Partner & Executive Director, VMLY&R
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                style={{
                  margin: "0px",
                  padding: "50px 100px",
                  backgroundColor: "#F1F1F1",
                }}
                type="flex"
                align="middle"
                justify=""
              >
                <Col style={{}}>
                  <Title level={3} style={{ margin: "0px" }}>
                    Need to talk to someone? Email us or Message us
                  </Title>
                </Col>
              </Row>
              {/* <Row type="flex" justify="center" style={{ padding: "50px" }}>
                <Col style={{ height: "1px", backgroundColor: "red" }}></Col>
              </Row> */}
              <Row className="info-footer-container">
                <Col span={24}>
                  <Row className="info-footer">
                    <Col span={6}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            PLATFORM FEATURES
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Content Hub </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content project Management</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Planning</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Templates </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Style Guides</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Content Management</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            PRODUCT
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Pricing</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Customers</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Demo</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Integrations </Text>
                        </Col>
                        <Col span={24}>
                          <Text>What's New</Text>
                        </Col>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            LEARNING
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Resources</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Blog</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Events</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            COMPANY
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>About Us</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Security and Backups</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Terms of Serrvice</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Privacy Policy </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Terms of Website Use</Text>
                        </Col>
                        <Col span={24}>
                          <Title level={3} className="text-color-2">
                            HELP
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Text>Help Center</Text>
                        </Col>
                        <Col span={24}>
                          <Text>API Documentation</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Email Us</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Message Us</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Title level={4} className="">
                            SIGN UP TO OUR WEEKLY NEWS LETTER
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Input size="large" placeholder="First Name" />
                        </Col>
                        <Col span={24}>
                          <Input size="large" placeholder="Email Address" />
                        </Col>
                        <Col span={24}>
                          <Button type="primary" size="large" block>
                            Subscribe
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Text>
                            We email useful things for people interested in
                            learning more about product management. No spam.
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Content>
          <Footer style={{ textAlign: "center", backgroundColor: "#f1f1f1" }}>
            © OmnyComm 2020, Jebel Ali, UAE. VAT No.: GB140105279. Company No.:
            SC400199{" "}
          </Footer>
        </Layout>
      </div>
    );
  }
}
