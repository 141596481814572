/**
 * @description This is a static ommnycom landing page
 *
 */

import React, { Component } from "react";
import {
  Layout,
  Menu,
  Card,
  Dropdown,
  Icon,
  Button,
  Row,
  Col,
  Typography,
  Avatar,
  Timeline,
  Divider,
  Input,
} from "antd";

import "./landing-page-header.scss";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;

export default class HeaderLandingPage extends Component {
  render() {
    return (
      <Header>
        <div className="logo">
          <Text>Omnycomm</Text>
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          style={{ lineHeight: "64px" }}
        >
          <Menu.Item key="4">Pricing</Menu.Item>
          <Menu.Item key="6">Login</Menu.Item>
          <Menu.Item key="7">
            <Button className="demo-btn">Book a Demo</Button>
          </Menu.Item>
        </Menu>
      </Header>
    );
  }
}
