import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Checkbox,
    Col,
    Icon,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Tabs,
    Typography,
    message,
    Radio,
    Spin
} from "antd";
import "./user-management.scss"
import axios from 'axios';
import isDev from "../../server";
import user from "../../reducers/user";
import { Ellipsis } from "react-spinners-css";
import { useDispatch } from 'react-redux'
import { storePermissionAction } from '../../actions/miscPermissionAction'

const serverUrl = isDev();
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;


const UserManagement = (props) => {

    const [loading, setLoading] = useState(false)
    const [currPage, setCurrPage] = useState(1)
    const [isAvailable, setIsAvailable] = useState(false)
    const [addUserModal, setAddUserModal] = useState(false)
    const [userList, setUserList] = useState([])
    const [userProfile, setUserProfile] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        emailId: '',
        designation: '',
        contactNo: '',
        isActive: true
    })
    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        emailId: '',
        designation: '',
        contactNo: '',
        isActive: true
    })
    const [userPermissions, setUserPermissions] = useState({
        brand_list: [],
        oc_reports: [],
        page_list: [],
        cohort: null,
        misc: [],
        location_group_uuid_list: []
    })
    const [addPermissions, setAddPermissions] = useState([])
    const [misc, setMisc] = useState([])
    const [oc, setOc] = useState([])
    const [pageList, setPageList] = useState([])
    const [brands, setBrands] = useState([])
    const [edit, setEdit] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [activeIndexUser, setActiveIndexUser] = useState("")
    const [toggleState, setToggleState] = useState(false)
    const [passwordModal, setPasswordModal] = useState(false);
    const [isValidDetails, setIsValidDetails] = useState(false);
    const [createUser, setCreateUser] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [userStatus, setUserStatus] = useState("All");
    const [fetchingUser, setFetchingUser] = useState(false);

    //Dispatcher for Redux
    const dispatch = useDispatch()

    function info(password) {
        Modal.info({
            // title: 'Please note down your password',
            content: `Please note down the password for the username '${newUser.userName}': ${password}`,
            width: "fit-content",
            className: "modal",
            onOk() {
            },
        });
    }

    function success(password) {
        Modal.success({
            width: "fit-content",
            content: `Password reset Successfully. Your new Password is: ${password}`,
            className: "modal"
        });
    }

    const resetPassword = () => {
        axios({
            method: 'POST',
            url: `${serverUrl}/reset-omnycomm-user-password/`,
            data: {
                username: userProfile.userName
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                success(res.data.password)
            } else {
                message.error("You are not authorized!")
            }

        })
    }

    useEffect(() => {

        if (newUser.firstName === "" && newUser.lastName === ""
            && newUser.userName === "" && newUser.emailId === ""
            && newUser.contactNo === "" && !newUser.emailId.includes('@')
            && newUser.emailId.length < 4 && newUser.contactNo.length < 9
        ) {
            setIsValidDetails(false);
        } else {
            setIsValidDetails(true);
        }
    }, [newUser])

    const createUserProfile = () => {
        const isnum = /^\d+$/.test(newUser.contactNo);
        if (!isValidDetails || !isnum) {
            message.info("Please enter the valid details");
        } else {
            setCreateUser(true);
            axios({
                method: 'POST',
                url: `${serverUrl}/create-omnycomm-user/`,
                data: {
                    username: newUser.userName,
                    first_name: newUser.firstName,
                    last_name: newUser.lastName,
                    email: newUser.emailId,
                    contact_number: "+" + newUser.contactNo,
                    designation: newUser.designation,
                    is_active: newUser.isActive
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then((res) => {
                setCreateUser(false);
                if (res.data.status === 200) {
                    message.success("User Profile Created!")
                    setAddUserModal(false)
                    setActiveIndex(0)
                    setPasswordModal(true)
                    info(res.data.password)
                    fetchUserList()
                } else if (res.data.status === 403) {
                    message.info(res.data.message)
                } else if (res.data.status === 402) {
                    message.info(res.data.message)
                } else {
                    message.error("Something went wrong!")
                }
            })
        }
    }

    const saveUserPermissions = () => {

        let p = {}

        if (addPermissions.includes("price_type")) p["price_type"] = true;
        if (addPermissions.includes("price_variant")) p["variant"] = true;
        if (addPermissions.includes("price_dealshub")) p["dealshub"] = true;

        axios({
            method: 'POST',
            url: `${serverUrl}/save-omnycomm-user-permissions/`,
            data: {
                username: userProfile.userName,
                brandPkList: userPermissions.brand_list,
                sap_functions: addPermissions.includes("sap_price_and_stock") ? {
                    price_stock: true
                } : {},
                price: p,
                stock: addPermissions.includes("stock_dealshub") ? {
                    dealshub: true
                } : {},
                misc: userPermissions.misc,
                page_list: userPermissions.page_list,
                LocationGroupUuidList: userPermissions.location_group_uuid_list,

                oc_reports: userPermissions.oc_reports,
                cohort: userPermissions.cohort
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {

                dispatch(storePermissionAction(userPermissions.misc))

                message.success("User Permissions Updated!")
                //setEdit(!edit)
            } else {
                message.error("You are not authorized!")
            }
        })
    }

    const saveUserProfile = () => {
        if (userProfile.contactNo.length < 9 || userProfile.userName === "" ||
            userProfile.firstName === "" || userProfile.lastName === "" ||
            !userProfile.emailId.includes("@") || userProfile.emailId.length < 4
        ) {
            message.info("Please enter valid details")
        } else {
            axios({
                method: 'POST',
                url: `${serverUrl}/save-omnycomm-user-details/`,
                data: {
                    username: userProfile.userName,
                    first_name: userProfile.firstName,
                    last_name: userProfile.lastName,
                    email: userProfile.emailId,
                    contact_number: "+" + userProfile.contactNo,
                    designation: userProfile.designation,
                    is_active: userProfile.isActive
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then((res) => {
                if (res.data.status === 200) {
                    message.success("User Profile Updated!")
                    fetchUserList()
                    setEdit(!edit)
                } else {
                    message.error("You are not authorized!")
                }
            })
        }
    }

    const changeUserStatus = () => {
        // console.log(userProfile.isActive, "inside func")
        axios({
            method: 'POST',
            url: `${serverUrl}/save-omnycomm-user-details/`,
            data: {
                username: userProfile.userName,
                first_name: userProfile.firstName,
                last_name: userProfile.lastName,
                email: userProfile.emailId,
                contact_number: userProfile.contactNo,
                designation: userProfile.designation,
                is_active: !userProfile.isActive
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                message.success("User Status Updated!")
                fetchUserList()
            } else {
                message.error("You are not authorized!")
            }
        })
    }

    const fetchLoadMore = () => {
        setLoading(true)
        axios({
            method: 'POST',
            url: `${serverUrl}/fetch-omnycomm-user-list/`,
            data: {
                locationGroupUuid: props.locationUuid,
                page: currPage
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setUserList([...userList, ...res.data.userList])
                setCurrPage(currPage + 1)
                //  setActiveIndexUser(res.data.userList[0].username)
                //  console.log(activeIndexUser, "first user", res.data.userList[0].username)
                setLoading(false)
                setIsAvailable(res.data.is_available)
                userList.length > 0 && fetchUserDetails(res.data.userList[activeIndex].username)
            } else if (res.data.status === 500) {
                message.error("Something went wrong!")
            } else if (res.data.status === 404) {
                message.error("Page number out of range")
            }
        })
    }

    const fetchUserList = () => {
        setLoading(true)
        axios({
            method: 'POST',
            url: `${serverUrl}/fetch-omnycomm-user-list/`,
            data: {
                locationGroupUuid: props.locationUuid,
                page: 1
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setUserList(res.data.userList)
                setCurrPage(currPage + 1)
                setLoading(false)
                setIsAvailable(res.data.is_available)
                fetchUserDetails(res.data.userList[activeIndex]?.username)
            } else {
                message.error("You are not authorized!")
            }
        })
    }

    const fetchUserDetails = (username) => {
        setFetchingUser(true);
        axios({
            method: 'POST',
            url: `${serverUrl}/fetch-omnycomm-user-details/`,
            data: {
                username: username
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setUserProfile({
                    userName: res.data.username,
                    firstName: res.data.first_name,
                    lastName: res.data.last_name,
                    contactNo: res.data.contact_no?.substr(1),
                    designation: res.data.designation,
                    emailId: res.data.email,
                    isActive: res.data.is_active
                })
                setToggleState(res.data.is_active)
                setUserPermissions({
                    brand_list: res.data.brand_list.map((item) => {
                        return (
                            item.pk
                        )
                    }),
                    oc_reports: res.data.oc_report_list,
                    page_list: res.data.page_list,
                    misc: res.data.other_list,
                    cohort: res.data.cohort,

                    location_group_uuid_list: res.data.location_group_list.map((item) => {
                        return (
                            item.uuid
                        )
                    })
                })
                let temp = [];
                res.data.stock.dealshub && temp.push("stock_dealshub");
                res.data.sap_functions.price_and_stock && temp.push("sap_price_and_stock")
                res.data.price.price_type && temp.push("price_type")
                res.data.price.dealshub && temp.push("price_dealshub")
                res.data.price.variant && temp.push("price_variant")
                setAddPermissions(temp);
            } else {
                message.error("You are not authorized!")
            }
            setFetchingUser(false);
        })
    }

    const fetchDefaultPermissions = () => {
        axios({
            method: 'POST',
            url: `${serverUrl}/fetch-default-permissions-list/`,
            data: {},
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setBrands(res.data.brand_list)
                setOc(res.data.default_permissions.oc_reports)
                setMisc(res.data.default_permissions.misc)
                setPageList(res.data.default_permissions.page_list)

                //  setActiveIndexUser(res.data.userList[0].username)
                //  console.log(activeIndexUser, "first user", res.data.userList[0].username)
                // fetchUserDetails(res.data.userList[activeIndex].username)
            } else {
                message.error("You are not authorized!")
            }
        })
    }

    useEffect(() => {
        fetchUserList()
        fetchDefaultPermissions()
        console.log(props, "my props")
    }, [])

    const locations = props.locationGroupList.map((item) => {
        return {
            label: item.name,
            value: item.uuid
        }
    })

    const handleUsersSearch = (searchList) => {
        setCurrPage(1)
        setActiveIndex(0)
        // const formData = new FormData()
        // formData.append('page', 1)
        // formData.append('search_list', JSON.stringify(searchList))
        axios({
            method: 'POST',
            url: `${serverUrl}/fetch-omnycomm-user-list/`,
            data: {
                page: 1,
                search_list: searchList
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setUserList(res.data.userList)
                setIsAvailable(res.data.is_available)
                if (res.data.is_available) {
                    setCurrPage(2)
                }
                if (res.data.userList.length > 0) {
                    fetchUserDetails(res.data.userList[0]?.username)
                }
            }
        })
    }

    const filterUsers = (e) => {
        console.log(e)
        setUserStatus(e)
    }

    const handleFilterUsers = () => {
        setCurrPage(1);
        setActiveIndex(0);
        // const formData = new FormData()
        // formData.append('page', 1);
        // formData.append()
        axios({
            method: 'POST',
            url: `${serverUrl}/fetch-omnycomm-user-list/`,
            data: {
                page: 1,
                filter_parameters: {
                    is_active: userStatus === "Active" ? true : userStatus === "InActive" ? false : null
                }
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            console.log(res, "my filter res")
            if (res.data.status === 200) {
                setUserList(res.data.userList)
                setIsAvailable(res.data.is_available)
                if (res.data.is_available) {
                    setCurrPage(2)
                }
                if (res.data.userList.length > 0) {
                    fetchUserDetails(res.data.userList[0]?.username)
                }
                setFilterModal(false)
            } else message.error("Something Went Wrong!")
        })
    }

    return (
        // <h1>Hey world</h1>
        <Row
            className='orders-account-manager-mobile-view-page-container'
            gutter={[16, 16]}
        >
            <Row style={{ paddingRight: '16px' }}>
                <Col span={24} className='title-container'>
                    <Row type='flex' justify='start'>
                        <Col>
                            <Title level={4} className='main-title'>
                                Users Management
                            </Title>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginTop: '20px' }}>
                    <Col span={3} style={{ paddingRight: '20px' }}>
                        <Button type={'primary'} style={{ width: '100%' }}
                            onClick={() => setFilterModal(true)}
                        >Filter</Button>
                    </Col>
                    <Col span={17} style={{ paddingRight: '20px' }}>
                        <Select
                            style={{ width: '100%' }}
                            dropdownClassName='search-chips'
                            dropdownMatchSelectWidth='false'
                            mode='tags'
                            onSearch={(e) => {
                                console.log(e)
                            }}
                            onChange={handleUsersSearch}
                            placeholder={[<Icon type='search' />, ' Search here...']}
                        />
                    </Col>
                    <Col span={3} style={{ textAlign: 'right' }}>
                        <Button
                            onClick={() => {
                                setAddUserModal(true)
                                console.log(addUserModal)
                            }}
                            style={{
                                borderRadius: '5px',
                                border: 'none',
                                backgroundColor: '#1ABC9C',
                                color: '#FFFFFF',
                                width: '140px',
                                height: '30px'
                            }}>ADD USER</Button>
                    </Col>
                </Col>
            </Row>
            <Row style={{ paddingRight: '16px' }}>
                <Col span={24}>
                    <Col lg={12} sm={24} style={{ paddingRight: '40px' }}>
                        <Row style={{ color: '#6D6D6D', fontSize: '18px', padding: '24px 24px 10px 24px' }}
                            type="flex"
                            justify="space-between"
                            align="middle"
                        >
                            <Col span={8}>
                                <Text style={{ color: '#FFFFFF' }}>Username</Text>
                            </Col>
                            <Col span={6}><Text style={{ color: '#FFFFFF' }}>Name</Text></Col>
                            <Col span={4}><Text style={{ color: '#FFFFFF' }}>Role</Text></Col>
                            {/*<Col span={4}>Cohort</Col>*/}
                            <Col span={4}><Text style={{ color: '#FFFFFF' }}>Status</Text></Col>
                        </Row>
                        {loading && <Row
                            type="flex"
                            align="middle"
                            justify="center"
                            style={{ width: "100%", height: "100%" }}
                        >
                            <Ellipsis color="#1ABC9C" size={50} />
                        </Row>}
                        <Col span={24} className={userList.length > 6 ? "user-rows-container" : ""}>
                            {userList.length && !loading > 0 ?
                                userList.map((item, index) => {
                                    return (
                                        <Row
                                            onClick={() => {
                                                setActiveIndex(index)
                                                fetchUserDetails(item.username)
                                                setActiveIndexUser(item.username)
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: activeIndex === index ? '#414C57' : "#364049",
                                                borderRadius: '4px',
                                                border: activeIndex === index ? '1px solid #5BCAAA' : "1px solid #5C5858",
                                                padding: '12px 20px 12px 20px',
                                                marginBottom: '20px'
                                            }}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                        >
                                            <Col span={8}>
                                                <Text className="body-text">{item.username}</Text>
                                            </Col>
                                            <Col span={6}>
                                                <Text
                                                    className="body-text">{item.first_name}{" "}{item.last_name}</Text>
                                            </Col>
                                            <Col span={4}>
                                                <Text className="body-text">{item.designation}</Text>
                                            </Col>
                                            <Col span={4}>
                                                <Text className="body-text"
                                                    style={{ color: item.is_active ? "#52C41A" : "#D1ADAD" }}>{item.is_active ? "Active" : "Inactive"}</Text>
                                            </Col>
                                        </Row>
                                    )
                                })
                                : <Text>No User Available</Text>
                            }
                            {isAvailable &&
                                <div
                                    className="center-child"
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        marginBottom: "50px",
                                    }}
                                >
                                    <Button type="primary"
                                        onClick={() => fetchLoadMore()}
                                    >Load More...</Button>
                                </div>}
                        </Col>
                    </Col>
                    {userList.length > 0 &&
                        <Col lg={12} sm={24} style={{
                            backgroundColor: '#414C57',
                            borderRadius: '4px',
                            padding: ' 0px 20px 20px 20px',
                            marginTop: '58px',
                            border: '1px solid #5BCAAA'
                        }}>
                            {/*{userProfile.userName === '' ? <Text>Create a user to continue...</Text> :*/}
                            {
                                fetchingUser ? (
                                    <Spin
                                        style={{ display: "flex", justifyContent: "center", padding: 15 }}
                                        indicator={<Icon type="loading"
                                            style={{ fontSize: 24 }} spin />} />
                                ) : (
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab="Profile" key="1">
                                            {/*Content of Tab Pane 1*/}
                                            <Row style={{ borderRadius: '4px' }}>
                                                <Row>
                                                    <Col span={16}>
                                                        <Avatar
                                                            //  size={68}
                                                            shape='square'
                                                            style={{
                                                                height: '80px',
                                                                width: '80px',
                                                                borderRadius: '8px',
                                                                padding: '25px'
                                                            }}
                                                        >
                                                            <Text
                                                                style={{ fontSize: '26px' }}>{userProfile.firstName[0]} {" "} {userProfile.lastName[0]}</Text>

                                                        </Avatar>
                                                    </Col>
                                                    <Col span={8} style={{ textAlign: 'right' }}>
                                                        <Switch size={"small"} style={{ marginRight: '28px', marginTop: '-8px' }}
                                                            onChange={() => {
                                                                setUserProfile({
                                                                    ...userProfile,
                                                                    isActive: !userProfile.isActive
                                                                })
                                                                changeUserStatus()
                                                            }}
                                                            checked={userProfile.isActive}
                                                        //defaultChecked={userProfile.isActive}
                                                        />
                                                        <Icon type={edit ? "close-circle" : "form"}
                                                            style={{ fontSize: '20px', color: '#1ABC9C' }}
                                                            onClick={() => {
                                                                setEdit(!edit)
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} style={{ marginTop: '10px' }}>
                                                        <Text style={{ lineHeight: 2, color: edit && "#FFFFFF" }}>Name <span style={{color:'#f74e45'}}>{edit ? <>*</>:<></>}</span></Text>
                                                        <br />
                                                        {edit ?
                                                            <>
                                                                
                                                                <Input
                                                                    value={userProfile.firstName}
                                                                    style={{ width: '45%', marginRight: '20px', color: "#b2b4b5" }}
                                                                    placeholder="Enter first name"
                                                                    onChange={(e) => {
                                                                        setUserProfile({
                                                                            ...userProfile,
                                                                            firstName: e.target.value
                                                                        })
                                                                    }}
                                                                    
                                                                />
                                                                <Input
                                                                    value={userProfile.lastName}
                                                                    style={{ width: '50%', color: "#b2b4b5" }}
                                                                    placeholder="Enter last name"
                                                                    onChange={(e) => {
                                                                        setUserProfile({
                                                                            ...userProfile,
                                                                            lastName: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </> : <Text
                                                                className="body-text">{userProfile.firstName}{" "}{userProfile.lastName}</Text>}
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: '10px' }}>
                                                        <Text style={{ lineHeight: 2, color: edit && "#FFFFFF" }}>Username <span style={{color:'#f74e45'}}>{edit ? <>*</>:<></>}</span></Text>
                                                        <br />
                                                        {edit ?
                                                            <Input
                                                                value={userProfile.userName}
                                                                onChange={(e) => {
                                                                    setUserProfile({ ...userProfile, userName: e.target.value })
                                                                }}
                                                                style={{ color: "#b2b4b5" }}
                                                                placeholder="Enter Username"
                                                            /> : <Text className="body-text">{userProfile.userName}</Text>}
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: '10px' }}>
                                                        <Text style={{ lineHeight: 2, color: edit && "#FFFFFF" }}>E-mail id <span style={{color:'#f74e45'}}>{edit ? <>*</>:<></>}</span></Text>
                                                        <br />
                                                        {edit ?
                                                            <Input
                                                                value={userProfile.emailId}
                                                                style={{ color: "#b2b4b5" }}
                                                                onChange={(e) => {
                                                                    setUserProfile({ ...userProfile, emailId: e.target.value })
                                                                }}
                                                                placeholder="Enter email id"
                                                            /> : <Text className="body-text">{userProfile.emailId}</Text>}
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: '10px' }}>
                                                        <Text style={{ lineHeight: 2, color: edit && "#FFFFFF" }}>Contact
                                                            Number <span style={{color:'#f74e45'}}>{edit ? <>*</>:<></>}</span></Text>
                                                        <br />
                                                        {edit ?

                                                            <Input
                                                                prefix={<Icon type={"plus"} />}
                                                                className="contact-input"
                                                                maxLength={14}
                                                                value={userProfile.contactNo}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        const tmp = e.target.value.slice(-1);
                                                                        if (tmp < '0' || tmp > '9') return;
                                                                    }
                                                                    setUserProfile({ ...userProfile, contactNo: e.target.value })
                                                                }}
                                                                style={{ color: "#b2b4b5" }}
                                                                placeholder="Enter contact number (with country code)"
                                                            />

                                                            : <Text className="body-text">{"+"}{userProfile.contactNo}</Text>}
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: '10px' }}>
                                                        <Text style={{ lineHeight: 2, color: edit && "#FFFFFF" }}>Role</Text>
                                                        <br />
                                                        {edit ?
                                                            <Input
                                                                value={userProfile.designation}
                                                                onChange={(e) => {
                                                                    setUserProfile({
                                                                        ...userProfile,
                                                                        designation: e.target.value
                                                                    })
                                                                }}
                                                                style={{ color: "#b2b4b5" }}
                                                                placeholder="Enter role"
                                                            /> : <Text className="body-text">{userProfile.designation}</Text>}
                                                    </Col>
                                                </Row>
                                            </Row>
                                            <Row type={"flex"} justify={"space-between"} style={{ marginTop: '25px' }}>
                                                <Button
                                                    style={{
                                                        borderRadius: '5px',
                                                        border: 'none',
                                                        backgroundColor: '#1ABC9C',
                                                        color: '#FFFFFF',
                                                        height: '30px'
                                                    }}
                                                    onClick={() => {
                                                        resetPassword()
                                                    }}
                                                >Reset Password</Button>
                                                {
                                                    edit && (
                                                        <Button
                                                            onClick={() => {
                                                                saveUserProfile()
                                                            }}
                                                            style={{
                                                                borderRadius: '5px',
                                                                border: 'none',
                                                                backgroundColor: '#1ABC9C',
                                                                color: '#FFFFFF',
                                                                width: '140px',
                                                                height: '30px'
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                    )
                                                }

                                            </Row>

                                        </TabPane>
                                        <TabPane tab="Permissions" key="2">
                                            {/*Content of Tab Pane 2*/}
                                            <Col span={24} className="user-permissions-container">
                                                <Row style={{
                                                    backgroundColor: '#515B66',
                                                    padding: '10px',
                                                    marginBottom: '24px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <Col span={24}>
                                                        <Text className="body-text">Add Permission</Text>
                                                    </Col>
                                                    {/*<Col span={3} style={{textAlign: 'right'}}>*/}
                                                    {/*    <Switch size="small"/>*/}
                                                    {/*</Col>*/}
                                                    <Col span={24} style={{ marginTop: '18px' }}>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Please select"
                                                            value={addPermissions}
                                                            //defaultValue={['option1', 'option2']}
                                                            onChange={(e) => {
                                                                setAddPermissions(e)
                                                                console.log(e)
                                                            }}
                                                        >
                                                            <Option value={"price_dealshub"}>price_dealshub</Option>
                                                            <Option value={"price_variant"}>price_variant</Option>
                                                            <Option value={"price_type"}>price_type</Option>
                                                            <Option value={"sap_price_and_stock"}>sap_price_and_stock</Option>
                                                            <Option value={"stock_dealshub"}>stock_dealshub</Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    backgroundColor: '#515B66',
                                                    padding: '10px',
                                                    marginBottom: '24px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <Col span={21}>
                                                        <Text className="body-text">Location Groups</Text>
                                                    </Col>
                                                    {/*<Col span={3} style={{textAlign: 'right'}}>*/}
                                                    {/*    <Switch size="small"/>*/}
                                                    {/*</Col>*/}
                                                    <Col span={24} style={{ marginTop: '18px' }}>
                                                        <Checkbox.Group
                                                            className="location-group"
                                                            style={{ width: '100%' }}
                                                            value={userPermissions.location_group_uuid_list}
                                                            //value={userPermissions.location_group_uuid_list.map((item) => {return (item.name)})}
                                                            onChange={(e) => {

                                                                setUserPermissions({
                                                                    ...userPermissions, location_group_uuid_list: e
                                                                })
                                                            }}
                                                            options={locations}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    backgroundColor: '#515B66',
                                                    padding: '10px',
                                                    marginBottom: '24px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <Col span={21}>
                                                        <Text className="body-text">Brand</Text>
                                                    </Col>
                                                    {/*<Col span={3} style={{textAlign: 'right'}}>*/}
                                                    {/*    <Switch size="small"/>*/}
                                                    {/*</Col>*/}
                                                    <Col span={24} style={{ marginTop: '18px' }}>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Please select"
                                                            value={userPermissions.brand_list}
                                                            onChange={(value) => {
                                                                setUserPermissions({ ...userPermissions, brand_list: value })
                                                                console.log(userPermissions.brand_list, "my brands")
                                                            }}
                                                        >
                                                            {brands.map((item) => {
                                                                return (
                                                                    <Option key={item.pk} value={item.pk}>{item.name}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    backgroundColor: '#515B66',
                                                    padding: '10px',
                                                    marginBottom: '24px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <Col span={21}>
                                                        <Text className="body-text">Page List</Text>
                                                    </Col>
                                                    {/*<Col span={3} style={{textAlign: 'right'}}>*/}
                                                    {/*    <Switch size="small"/>*/}
                                                    {/*</Col>*/}
                                                    <Col span={24} style={{ marginTop: '18px' }}>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Please select"
                                                            value={userPermissions.page_list}
                                                            onChange={(value) => setUserPermissions({
                                                                ...userPermissions,
                                                                page_list: value
                                                            })}
                                                        >
                                                            {pageList?.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item}>{item}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    backgroundColor: '#515B66',
                                                    padding: '10px',
                                                    marginBottom: '24px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <Col span={21}>
                                                        <Text className="body-text">OC Reports List</Text>
                                                    </Col>
                                                    {/*<Col span={3} style={{textAlign: 'right'}}>*/}
                                                    {/*    <Switch size="small"/>*/}
                                                    {/*</Col>*/}
                                                    <Col span={24} style={{ marginTop: '18px' }}>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Please select"
                                                            value={userPermissions.oc_reports}
                                                            onChange={(value) => setUserPermissions({
                                                                ...userPermissions,
                                                                oc_reports: value
                                                            })}
                                                        >
                                                            {oc.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item}>{item}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    backgroundColor: '#515B66',
                                                    padding: '10px',
                                                    marginBottom: '24px',
                                                    borderRadius: '4px'
                                                }}>
                                                    <Col span={21}>
                                                        <Text className="body-text">Other Permissions</Text>
                                                    </Col>
                                                    {/*<Col span={3} style={{textAlign: 'right'}}>*/}
                                                    {/*    <Switch size="small"/>*/}
                                                    {/*</Col>*/}
                                                    <Col span={24} style={{ marginTop: '18px' }}>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Please select"
                                                            value={userPermissions.misc}
                                                            onChange={(value) => {
                                                                setUserPermissions({ ...userPermissions, misc: value })
                                                                console.log(value)
                                                            }}
                                                        >
                                                            {misc.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item}>{item}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Col>
                                                </Row>

                                                <Col span={24} style={{ textAlign: 'right' }}>
                                                    <Button
                                                        onClick={() => saveUserPermissions()}
                                                        style={{
                                                            borderRadius: '5px',
                                                            border: 'none',
                                                            backgroundColor: '#1ABC9C',
                                                            color: '#FFFFFF',
                                                            width: '140px',
                                                            height: '30px'
                                                        }}>Save</Button>
                                                </Col></Col>
                                        </TabPane>
                                    </Tabs>
                                )
                            }
                        </Col>}
                </Col>
            </Row>

            <Modal
                width="fit-content"
                visible={filterModal}
                destroyOnClose
                title="Filter"
                onCancel={() => setFilterModal(false)}
                onOk={handleFilterUsers}
            >
                <Row>
                    <Col span={24}>
                        <Text strong type={"secondary"}>User Status</Text>
                        <br />
                        <Radio.Group onChange={(e) => filterUsers(e.target.value)} defaultValue={"All"} value={userStatus}>
                            <Radio value={"All"}>All Users</Radio>
                            <Radio value={"Active"}>Active Users</Radio>
                            <Radio value={"InActive"}>InActive Users</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </Modal>
            <Modal
                width="fit-content"
                visible={addUserModal}
                destroyOnClose
                title={"Add New User"}
                onCancel={() => setAddUserModal(false)}
                footer={[
                    <Button onClick={() => setAddUserModal(false)}>Cancel</Button>,
                    <Button
                        onClick={() => {
                            createUserProfile()

                        }}
                        loading={createUser}
                        disabled={!isValidDetails}
                        style={{
                            borderRadius: '5px',
                            border: 'none',
                            backgroundColor: '#1ABC9C',
                            color: '#FFFFFF',
                            //width: '140px',
                            height: '30px'
                        }}
                    >Create</Button>
                ]}

            >
                <Row>
                    <Col span={12} style={{ paddingRight: '20px' }}>
                        <Text>First Name</Text>
                        <Text style={{ color: 'red', fontSize: 10 }}>{" "}*</Text>
                        <br />
                        <Input
                            onChange={(e) => {
                                setNewUser({ ...newUser, firstName: e.target.value })
                            }}
                            placeholder="Enter First Name"
                        />
                    </Col>
                    <Col span={12}>
                        <Text>Last Name</Text>
                        <Text style={{ color: 'red', fontSize: 10 }}>{" "}*</Text>
                        <br />
                        <Input
                            onChange={(e) => {
                                setNewUser({ ...newUser, lastName: e.target.value })
                            }}
                            placeholder="Enter Last Name"
                        />
                    </Col>
                    <Col span={12} style={{ paddingRight: '20px', marginTop: '20px' }}>
                        <Text>User Name</Text>
                        <Text style={{ color: 'red', fontSize: 10 }}>{" "}*</Text>
                        <br />
                        <Input
                            onChange={(e) => {
                                setNewUser({ ...newUser, userName: e.target.value })
                            }}
                            placeholder="Enter Username"
                        />
                    </Col>
                    <Col span={12} style={{ marginTop: '20px' }}>
                        <Text>Email Id</Text>
                        <Text style={{ color: 'red', fontSize: 10 }}>{" "}*</Text>
                        <br />
                        <Input
                            onChange={(e) => {
                                setNewUser({ ...newUser, emailId: e.target.value })
                            }}
                            placeholder="Enter Email Id"
                        />
                    </Col>
                    <Col span={12} style={{ paddingRight: '20px', marginTop: '20px' }}>
                        <Text>Designation</Text>
                        <br />
                        <Input
                            onChange={(e) => {
                                setNewUser({ ...newUser, designation: e.target.value })
                            }}
                            placeholder="Enter Designation"
                        />
                    </Col>
                    <Col span={12} style={{ marginTop: '20px' }}>
                        <Text>Contact Number</Text>
                        <Text style={{ color: 'red', fontSize: 10 }}>{" "}*</Text>
                        <br />
                        <Input
                            className="contact-input"
                            prefix={<Icon type={"plus"} />}
                            onChange={(e) => {
                                if (e.target.value !== '') {
                                    const tmp = e.target.value.slice(-1);
                                    if (tmp < '0' || tmp > '9') return;
                                }
                                setNewUser({ ...newUser, contactNo: e.target.value });
                            }}
                            placeholder="Enter Contact Number (with country code)"
                            maxLength={14}
                        />


                    </Col>
                </Row>
            </Modal>


        </Row>
    )
}

export default UserManagement