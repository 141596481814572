import React, { useState, useEffect } from "react";
import {useDispatch , useSelector} from "react-redux"
import styled from "styled-components";
import { Row, Col, Collapse, Typography, Select , Button, Radio , Icon, message } from "antd";
import axios from 'axios'
import isDev from "server";
import "./contentHealth.scss"
import {fetchProductList , addFilters , addFiltersContentHealth} from "actions/index";

const { Panel } = Collapse;
const { Text } = Typography;

const TableContainer = styled(Row)``;
const TableHead = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #3f4a56;
  margin-top: 20px;
  border-bottom: 1px solid #495561;
  border-top: 1px solid #495561;
`;
const TableRow = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #313841;

  /* border-bottom: 1px solid red; */
`;
const TableRowText = styled(Text)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TableHeadText = styled(Text)`
  color: #dee2e6;
`;

const serverUrl = isDev()

const Table = ({data,values,setValues,handleSubmit , submitLoading}) => {

    // const dispatch = useDispatch();
    // const filters = useSelector(state => state.productList.filters);
    // const tags = useSelector(state => state.productList.tags);

    // const fetchProducts = (true_or_false , force_brand = null) => {
    //     console.log(brand , "CHALA CHALA")
    //     let brand_name_key = force_brand || (brand == "All" ? "" : data[brand].brand_name)
    //     let filters_new = {
    //         ...filters,
    //         brand_name : brand_name_key,
    //         [data[0]["keys"][attribute]] : true_or_false
    //     }
    //     dispatch(fetchProductList(filters_new , tags))
    //     dispatch(addFiltersContentHealth(filters_new));
    //     dispatch(addFilters({...filters , brand_name : brand_name_key}))
    // }

    return (
        <React.Fragment>
            <TableContainer
                type="flex"
                align="middle"
                className="table-container"
            >
                <TableHead
                    type="flex"
                    align="middle"
                    className="products-table-heading"
                >
                    <Col span={6}>
                        <Text style={{fontSize:"16px"}}>
                            Attributes
                        </Text>
                    </Col>
                    <Col 
                        offset={12} 
                        span={6} 
                    >
                        <Row type = "flex" justify = "end">
                            <Button type="primary" onClick={handleSubmit} loading = {submitLoading}>
                                {submitLoading ? "" : "Submit"}
                            </Button>
                        </Row>
                    </Col>
                    
                </TableHead>
                <Col span={24}
                     className="content-health-container"
                     //style={{maxHeight:"30vh",overflow:"scroll"}}
                >
                {data && data.map((key,ind)=>{
                    return (
                        <TableRow 
                            style={{
                                    height:"100%",
                                    display:"flex", 
                                    justifyContent:"center", 
                                    alignItems:"center"
                                }} 
                            key={ind}
                        >
                            <Col span={12}>
                                <TableRowText>
                                    {key}
                                </TableRowText>
                            </Col>
                            <Col span={12}>
                                <Radio.Group 
                                    value={values[key]} 
                                    onChange={e=>setValues({...values,[key]:e.target.value})}>
                                    <Col span={10}>
                                        <Radio value={true}>
                                            Yes
                                        </Radio>
                                    </Col>
                                    <Col span={10}>
                                        <Radio value={false}>
                                            No
                                        </Radio>
                                    </Col>
                                    <Col span={4}>
                                        <Radio value={null}>
                                            Default
                                        </Radio>
                                    </Col>
                                </Radio.Group>
                            </Col>
                        </TableRow>
                    )
                })}
                </Col>
            </TableContainer>
            
        </React.Fragment>
    )
}

const ReportTable = ({data,handleSubmit,CreateContentReport,available , handleLoadMore}) => {
    return (
        <div>
            <TableContainer
                type="flex"
                align="middle"
                className="table-container"
            >
                <TableHead>
                    <Col span={6}>
                        <TableHeadText>Brands</TableHeadText>
                    </Col>
                    <Col span={6}>
                        <TableHeadText>Total products</TableHeadText>
                    </Col>
                    <Col span={6}>
                        <TableHeadText>Result</TableHeadText>
                    </Col>
                    <Col span={6}>
                        <TableHeadText>Download Report</TableHeadText>
                    </Col>
                </TableHead>
                {data && 
                    data.map((item,ind)=>{
                        return (
                            <TableRow key={ind}>
                                <Col span={6}>
                                    <TableRowText>
                                        {item.brand_name}
                                    </TableRowText>
                                </Col>
                                <Col span={6}>
                                    <TableRowText>
                                        {item.total_products}
                                    </TableRowText>
                                </Col>
                                <Col span={6}>
                                    <TableRowText>
                                        {item.value}
                                    </TableRowText>
                                </Col>
                                <Col span={6}>
                                    {/* <Icon type = "download" onClick={()=>CreateContentReport(item.brand_name)}  /> */}
                                    <Button 
                                        onClick={()=>CreateContentReport(item.brand_name)} 
                                        icon="download" 
                                        type="primary"
                                    >
                                    </Button>
                                </Col>
                            </TableRow>
                        )
                    })
                }
                
            { available &&
                <Button 
                    onClick={handleLoadMore} 
                    style={{margin:"10px auto"}} 
                    type="primary"
                >
                    Load more
                </Button>
            }
            </TableContainer>
        </div>
    )
}

export default function ContentHealthTable() {

    const [data,setData] = useState([])
    const [values,setValues] = useState({})
    const [brands,setBrands] = useState([])
    const [page,setPage] = useState(1)
    const [submitLoading , setSubmitLoading] = useState(false);
    
    useEffect(() => {
        axios({
                method: 'post',
                url: `${serverUrl}/fetch-statistics/`,
                data: {
                    page: 1,
                    filter_parameters:{}
                },
                headers: {
                    'Authorization': `JWT ${localStorage.getItem("token")}`
                }
            })
            .then(response => {
                let tmp = {}
                for(let i=0;i<response.data.keys.length;i++){
                    tmp = {
                        ...tmp,
                        [response.data.keys[i]]:null
                    }
                }
                setData(response.data)
                setValues(tmp)
            })
    }, [])

    const handleLoadMore = () => {
        
        axios({
            method:"POST",
            url: `${serverUrl}/fetch-statistics/`,
            data: {
                page: page,
                filter_parameters:values
            },
            headers:{
                Authorization:`JWT ${localStorage.getItem("token")}`
            }
        })
        .then(response=>{
            setData(response.data)
            setBrands(
                [...brands,...response.data.brand_list]
            )
            setPage(page + 1)  
        })
    }

    const handleSubmit = () => {
        setSubmitLoading(true);
        axios({
            method:"POST",
            url: `${serverUrl}/fetch-statistics/`,
            data: {
                page: 1,
                filter_parameters:values
            },
            headers:{
                Authorization:`JWT ${localStorage.getItem("token")}`
            }
        })
        .then(response=>{
            setSubmitLoading(false);
            setData(response.data)
            setBrands(
                [...response.data.brand_list] 
            )
            setPage(2)  
        })
    }

    const CreateContentReport = (brand_name) => {
        axios({
            method:"POST",
            url: `${serverUrl}/create-content-report/`,
            data: {
                brand_name: brand_name,
                filter_parameters: values
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if(response.data.status == 200)
             message.success("Report generated successfully!");
             else if(response.data.status === 405)
             {
               message.info("You are not authorised!")
             }
            else
                message.error("Report could not be generated!");
        })
    }

    return (
        <React.Fragment>
            <Row style={{
                background: "#36404a",
                marginBottom: "2em",
                padding: "22px"
            }}>
                <Collapse bordered={false} expandIconPosition="right">
                    <Panel header="Content Health" key="1">
                        <Table 
                            data={data.keys} 
                            values={values} 
                            setValues={setValues} 
                            handleSubmit={handleSubmit} 
                            submitLoading = {submitLoading}
                        />
                        <ReportTable 
                            data={brands} 
                            handleSubmit={handleSubmit} 
                            handleLoadMore = {handleLoadMore}
                            CreateContentReport={CreateContentReport} 
                            available={page>1 && data.is_available} 
                        />
                    </Panel>
                </Collapse>
            </Row>
        </React.Fragment>
    )
}