import axios from "axios";
import isDev from "../server";
import { message } from "antd";
const serverUrl = isDev();

export const addcategory = newCategoryData => dispatch => {
  dispatch({
    type: "ADD_CATEGORY",
    payload: newCategoryData
  });
};

export const addBanner = newBannerData => dispatch => {
  dispatch({
    type: "ADD_BANNER",
    payload: newBannerData
  });
};

export const deleteBanner = bannerUuid => dispatch => {
  axios.post(`${serverUrl}/dealshub/delete-banner/`, {
    uuid: bannerUuid
  }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    }
  }).then(response => {
    if (response.data.status === 200) {
      dispatch({
        type: "DELETE_BANNER",
        payload: bannerUuid
      });
      message.success("Banner Deleted Successfully!");
    } 
    else if(response.data.status === 405)
    {
      message.info("You are not authorised!")
    }
    else {
      message.error("Something went wrong!");
    }
  });
};

export const publishBanner = bannerUuid => dispatch => {
  dispatch({
    type: "PUBLISH_BANNER",
    payload: { bannerUuid }
  });
}

export const unpublishBanner = bannerUuid => dispatch => {
  dispatch({
    type: "UNPUBLISH_BANNER",
    payload: { bannerUuid }
  });
}

export const handleUpdate = payload => {
  return { type: "HANDLE_UPDATE", payload };
};

export const handlePromoChange = (bannerIndex, unitBannerIndex, promoDetails) => {
  return {
    type: "HANDLE_PROMO_CHANGE",
    payload: {
      bannerIndex,
      unitBannerIndex,
      promoDetails
    }
  };
};

export const handlePromoSectionPriceChange = (sectionIndex, sectionProductIndex, promoPrice) => {
  return {
    type: "HANDLE_PROMO_SECTION_PRICE_CHANGE",
    payload: {
      sectionIndex,
      sectionProductIndex,
      promoPrice
    }
  };
};

export const handlePromoBannerPriceChange = (bannerIndex, unitBannerIndex, productIndex, promoPrice) => {
  return {
    type: "HANDLE_PROMO_BANNER_PRICE_CHANGE",
    payload: {
      bannerIndex,
      unitBannerIndex,
      productIndex,
      promoPrice
    }
  };
};

export const handleDelete = payload => {
  return { type: "HANDLE_DELETE", payload };
};

export const fetchDealsHubCategoryData = (sections_list) => dispatch => {
  dispatch({
    type: "FETCH_DEALS_HUB_DATA",
    payload: sections_list
  });
};

export const loadCategoryOrBanner = payload => {
  return { type: "UPDATE_DEALS_HUB_ADMIN", payload };
};

export const orderList = ({ oldIndex, newIndex }) => {
  return { type: "REORDER_LIST", payload: { oldIndex, newIndex } };
};