import React, { Component, useEffect } from "react";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { EditProductPageWrapper } from "./styled-components-library";
import { Row, Col, Button, Affix, Tooltip, message, Upload, Popconfirm } from "antd";
// import UploadImage from "../components/datainputs/upload-image/UploadImage";
import ImageManager from "../components/datainputs/image-manager/ImageManager";
import FormHeader from "../components/datainputs/FormHeader";
import "./edit-base-product-page.scss";
import VarientsInfoVital from "./vitali-info/VarientsInfoVital";
import VarientsInfoAttributes from "./vitali-info/VarientsInfoAttributes";
// import VarientsInfoDimensions from "./vitali-info/VarientsInfoDimensions";
import VarientsInfoPFL from "./vitali-info/VarientsInfoPFL";
import SpecialFeatures from "./vitali-info/SpecialFeatures";
import { Link } from "react-router-dom";
import EditorBox from "../components/EditorBox";
import {
  fetchProductDetails,
  saveProductDetails,
  publishToDealsHub,
  fetchImagesDetails,
  handelEditProductDetails,
} from "../../actions/index";
import { UploadOutlined } from '@ant-design/icons'

//validator
import SimpleReactValidator from "simple-react-validator";

import { connect } from "react-redux";
import BaseForm from "./BaseForm";

import isDev from "../../server";
import VarientsforFaq from "./vitali-info/VarientsforFaq";
import { useState } from "react";
const serverUrl = isDev();
class EditBaseProductVarientPage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Field is required",
        integer: "Must be number",
      },
      validators: {
        barcodestring: {
          // name the rule
          message: "value must be a number of length[10 | 12 | 13]",
          rule: (val, params, validator) => {
            if (isNaN(val)) return false;

            let lent = val.length;
            if (lent == 10 || lent == 12 || lent == 13) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: false, // optional
        },
        productIdEAN: {
          message: "value must be of length 13",
          rule: (val, params, validator) => {

            let lent = val.length;
            if (lent == 13) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: true, // optional
        },
        productIdASIN: {
          message: "value must be of length 10",
          rule: (val, params, validator) => {

            let lent = val.length;
            if (lent == 10) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: true, // optional
        },
        productIdUPC: {
          message: "value must be of length 12",
          rule: (val, params, validator) => {

            let lent = val.length;
            if (lent == 12) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: true, // optional
        },
      },
    });
    this.state = {
      dataList: [],
      dataListArabic: [],
      stepdataList: [],
      faqList: [],
      redirect: false,
      brandList: null,
      product_description: "",
      loadingVerify: false,
      loadingLock: false,
      delete_true: false,
      product_manual: undefined
    };
  }

  setProductManual = (file) => {
    this.setState({ product_manual: file })
  }

  setlist = (data) => {
    this.setState({ dataList: data });
  };

  setlistArabic = (data) => {
    this.setState({ dataListArabic: data });
  }

  setsteplist = (stepdata) => {
    this.setState({ stepdataList: stepdata });
  };
  setfaqlist = (faqdata) => {
    this.setState({ faqList: faqdata });
  };

  fetchCategories = () => {
    let data = {};
    fetch(`${serverUrl}/fetch-all-categories/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 500) message.error("Internal Server Error!");
        else if (data.status === 403) {
          message.info("You are not authorised!")
        }
        else {
          this.setState({
            super_categories: data.super_category_list,
          });
        }
      });
  };

  deleteThisProduct = () => {
    let data = {
      uuid: this.props.productDetails.product_uuid
    };
    fetch(`${serverUrl}/secure-delete-product/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 500) message.error("Internal Server Error!");
        else if (data.status === 403) {
          message.info("You are not authorised!")
        }
        else {
          message.info("Product is deleted!");
          window.location.replace("/products")
        }
      });
  }

  lockProduct = () => {
    this.setState({
      loadingLock: true
    })
    let data = {
      product_pk: this.props.id,
      locked: !this.props.productDetails.locked
    };
    fetch(`${serverUrl}/lock-product/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loadingLock: false
        })
        if (data.status === 500) message.error("Internal Server Error!");
        else if (data.status === 403) {
          message.info("You are not authorised!")
        }
        else {
          if (this.props.productDetails.locked)
            message.success("Product is successfully Unlocked");
          else
            message.success("Product is successfully Locked");
          this.props.handelEditProductDetails({
            ...this.props.productDetails,
            locked: !this.props.productDetails.locked,
          });
        }
      });
  }

  componentDidMount = () => {
    this.fetchCategories();
    if (!this.props.drawer) this.props.drawerOff();
    this.props.fetchProductDetails(this.props.id);
    fetch(`${serverUrl}/fetch-brands/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ brandList: data.brand_list });
      });
  };

  handelSave = () => {
    if (!this.validator.allValid()) {
      message.info("Fill Required Details");
    } else {


      this.props.saveProductDetails(
        this.props.productDetails,
        this.props.id,
        this.state.dataList,
        this.state.product_description,
        null,
        null,
        this.state.stepdataList,
        this.state.faqList,
        this.state.dataListArabic,
        this.state.product_manual
      );
    }
  };

  PublishToDealsHub = () => {
    this.props.publishToDealsHub(this.props.id);
    message.info("Product Published!");
  };

  error = (status) => {
    if (status == 500) message.error("Internal Server Error");
    else if (status == 409) message.warning("Duplicate Product Detected");
    else if (status == 403) message.error("access denied");
    else if (status == 200) {
      message.success("Saved Successfully!");
    }
  };

  getEditorText = (value) => {
    this.setState({ product_description: value });
  };


  setDataListFlag = 1;


  componentWillReceiveProps = (props) => {
    if (props.productDetails.status !== "") {
      this.error(props.productDetails.status);
    }

    if (this.setDataListFlag) {
      this.setState({
        dataList: props.productDetails.pfl_product_features,
        dataListArabic: props.productDetails.pfl_product_features_ar,
        product_description: props.productDetails.product_description,
        stepdataList: props.productDetails.how_to_use,
        faqList: props.productDetails.faqs,
      });

      this.setDataListFlag = 0;
    }

  };


  verifyProduct = () => {
    let data = {
      verify: !this.props.productDetails.verified,
      product_pk: this.props.id,
    };

    this.setState({
      loadingVerify: true,
    });

    fetch(`${serverUrl}/verify-product/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loadingVerify: false,
        });

        if (data.status === 500) message.error("Internal Server Error!");
        else if (data.status === 403) {
          message.info("You are not authorised!")
        }
        else {

          if (this.props.productDetails.verified)
            message.success("Successfully Unverified!");
          else
            message.success("Successfully Verified!");

          this.props.handelEditProductDetails({
            ...this.props.productDetails,
            verified: !this.props.productDetails.verified,
          });


        }
      });
  };

  render() {

    let {
      pfl_product_features,
    } = this.props.productDetails.pfl_product_features;


    const initSuperCat = (this.state.super_categories && this.props.productDetails.super_category_uuid && this.state.super_categories.filter(item => item.super_category_uuid ==
      this.props.productDetails.super_category_uuid)[0] || null)

    const initCat = (initSuperCat && initSuperCat.category_list && this.props.productDetails.category_uuid && initSuperCat.category_list.filter(
      (item) => item.category_uuid == this.props.productDetails.category_uuid)[0]) || null;

    const initSubCat = (initCat && initCat.sub_category_list.filter(item => item.sub_category_uuid == this.props.productDetails.sub_category_uuid)[0]) || null;





    return (
      <React.Fragment>
        <EditProductPageWrapper>
          <TitleWithBreadCrumb
            title={`Edit Product ${this.props.productDetails.product_id &&
              `(${this.props.productDetails.product_id})`
              }`}
            breadcrumb={["OmnyComm", "Product Edit"]}
          />

          <Row gutter={[40, 40]} style={{ height: '100%' }}>
            <Col className="gutter-row" lg={12} sm={24}>
              {this.props.productDetails && (
                <BaseForm
                  brandList={this.state.brandList || []}
                  disabled
                  validator={this.validator}
                  productDetails={this.props.productDetails}
                  super_categories={this.state.super_categories || []}
                  initiSuperCategory={initSuperCat}
                  initiCategory={initCat}
                  initiSubCategory={initSubCat}
                  base_product_pk={this.props.base_product_pk}
                />
              )}
            </Col>
            <Col className="gutter-row" lg={12} sm={24}>
              <Row gutter={[40, 40]}>
                {!this.props.drawer && (
                  <Col span={24}>
                    <Row gutter={[24, 24]} className="gutter-box">
                      <Col>
                        <ImageManager
                          product_pk={this.props.id}
                          disabled={this.props.productDetails.locked}
                          images={
                            this.props.productDetails &&
                            this.props.productDetails.images
                          }
                          fetchImagesOnly={this.props.fetchImagesDetails}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoVital
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>



                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoAttributes
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoPFL
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          initialdata={this.state.dataList}
                          sendList={this.setlist}
                          productDetails={this.props.productDetails}
                          name="Features: Variant (en)"
                          buttontitle="Add Attribute"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoPFL
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          initialdata={this.state.dataListArabic}
                          sendList={this.setlistArabic}
                          productDetails={this.props.productDetails}
                          name="Features: Variant (ar)"
                          buttontitle="Add Attribute"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                {/* // HOW TO USE :varient. */}
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoPFL
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          initialdata={this.state.stepdataList}
                          sendList={this.setsteplist}
                          productDetails={this.props.productDetails}
                          name="How to Use: Variant"
                          buttontitle="Add Steps"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                {/* FOR fAQ Section testing */}
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsforFaq
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          initialdata={this.state.faqList}
                          sendList={this.setfaqlist}
                          productDetails={this.props.productDetails}
                          name="FAQS: Variant"
                          buttontitle="Add FAQs"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <UploadProductManual url={this.props.productDetails?.user_manual} setProductManual={this.setProductManual} />
                </Col>

                {this.props.productDetails &&
                  this.props.productDetails.dynamic_form_attributes &&
                  Object.keys(this.props.productDetails.dynamic_form_attributes)
                    .length > 0 && (
                    <Col span={24}>
                      <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                        <Col>
                          {this.props.productDetails && (
                            <SpecialFeatures
                              disabled={this.props.drawer || this.props.productDetails.locked}
                              validator={this.validator}
                              initialdata={this.state.dataList || []}
                              sendList={this.setlist}
                              SpecialFeatures={
                                this.props.productDetails
                                  .dynamic_form_attributes
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}

                <Col className="gutter-row" span={24}>
                  <Row gutter={[24, 24]} className="gutter-box">
                    <Col>
                      <Row
                        gutter={[24, 24]}
                        style={{ margin: "0px", minHeight: "230px" }}
                      >
                        <Col style={{ padding: "0px" }} sm={24}>
                          <FormHeader header="Product Description (en)" />
                        </Col>
                        <Col sm={24} className="editorBox-container">
                          <EditorBox style={{ height: '500px' }}
                            value={this.state.product_description}
                            getText={this.getEditorText}
                            disabled={this.props.productDetails.locked}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {!this.props.drawer && (
              <Col className="gutter-row save-btn-edit-product-page" sm={24}>
                {/* <Row type="flex" justify="center" align="middle">
                                <Button onClick={this.handelSave} type="primary"> Save </Button>
                            </Row> */}

                <Tooltip placement="top" title={this.props.productDetails.locked ? "Product is locked! you can not save." : "Save"}>
                  <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
                    <Button
                      type="danger"
                      shape="circle"
                      icon="save"
                      size="large"
                      onClick={this.handelSave}
                      disabled={this.props.productDetails.locked}
                    ></Button>
                  </Affix>
                </Tooltip>



                <Tooltip placement="top" title="PFL">
                  <Affix style={{ position: "fixed", bottom: 20, right: 70 }}>
                    <a
                      href={`${serverUrl}/pfl/${this.props.productDetails.pfl_pk}`}
                      target="_blank"
                    >
                      <Button
                        type="default"
                        shape="circle"
                        icon="pic-left"
                        size="large"
                      ></Button>
                    </a>
                  </Affix>
                </Tooltip>

                <Tooltip placement="top" title="Ecommerce">
                  <Affix style={{ position: "fixed", bottom: 20, right: 120 }}>
                    <Link to={`/product-details/${this.props.id}`}>
                      <Button
                        type="default"
                        shape="circle"
                        icon="shop"
                        size="large"
                        onClick={this.makePfl}
                      ></Button>
                    </Link>
                  </Affix>
                </Tooltip>

                {this.props.productDetails && (
                  <Tooltip
                    placement="top"
                    title={
                      this.props.productDetails.verify_product
                        ? this.props.productDetails.verified
                          ? "Unverify"
                          : "Verify"
                        : "you are not authorised"
                    }
                  >
                    <Affix
                      style={{ position: "fixed", bottom: 20, right: 170 }}
                    >
                      <Button
                        type="default"
                        shape="circle"
                        icon={
                          this.props.productDetails.verified
                            ? "close-circle"
                            : "check-circle"
                        }
                        size="large"
                        disabled={!this.props.productDetails.verify_product}
                        loading={this.state.loadingVerify}
                        onClick={this.verifyProduct}
                        style={{
                          background: this.props.productDetails.verified
                            ? "red"
                            : "green",
                          color: "white",
                          border: "none",
                        }}
                      ></Button>
                    </Affix>
                  </Tooltip>
                )}

                {
                  this.props.productDetails &&
                  <Tooltip placement="top" title={this.props.productDetails.locked ? 'Unlock It' : 'Lock It'} >
                    <Affix
                      style={{ position: "fixed", bottom: 20, right: 220 }}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon="lock"
                        icon={this.props.productDetails.locked ? 'lock' : 'unlock'}
                        disabled={!this.props.productDetails.verify_product}
                        loading={this.state.loadingLock}
                        size="large"
                        style={{ backgroundColor: "#205595e6", border: "none" }}
                        onClick={this.lockProduct}
                      ></Button>

                    </Affix>
                  </Tooltip>
                }


                {this.props.productDetails && (
                  <Tooltip placement="top" title="Delete it">
                    <Affix
                      style={{ position: "fixed", bottom: 20, right: 270 }}
                    >
                      <Popconfirm
                        title="Are you sure delete this Product"
                        onConfirm={this.deleteThisProduct}
                        okText="Yes"
                        cancelText="No"
                        disabled={!this.props.productDetails.delete_product}
                      >
                        <Button
                          type="danger"
                          shape="circle"
                          icon="delete"
                          size="large"
                          disabled={!this.props.productDetails.delete_product}
                        ></Button>
                      </Popconfirm>
                    </Affix>
                  </Tooltip>
                )}


                {this.props.productDetails.base_product_pk && (
                  <Tooltip placement="top" title="Add variant">
                    <Affix
                      style={{ position: "fixed", bottom: 20, right: 320 }}
                    >
                      <Link
                        to={`/add-new-variant/${this.props.productDetails.base_product_pk}`}
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          icon="plus"
                          size="large"
                        ></Button>
                      </Link>
                    </Affix>
                  </Tooltip>
                )}




              </Col>
            )}
          </Row>
        </EditProductPageWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails,
  };
};

export default connect(mapStateToProps, {
  fetchProductDetails,
  saveProductDetails,
  publishToDealsHub,
  fetchImagesDetails,
  handelEditProductDetails,
})(EditBaseProductVarientPage);


//Component to handle all operations related to the Product Manual Requirements
const UploadProductManual = ({ url, setProductManual }) => {

  const [defaultList, setDefaultList] = useState([])

  useEffect(() => {
    if (url?.length > 0) {
      setDefaultList([{
        uid: '-2',
        name: 'Product Manual',
        status: 'done',
        url: url,
      }])
      setProductManual(undefined)
    } else {
      setDefaultList([])
      setProductManual("")
    }
  }, [url])

  return (
    <div className="upload-Product__Manual">
      <p className="title-Product__Manual">Product Manual</p>
      <Upload
        fileList={defaultList}
        beforeUpload={(e) => false}
        onChange={(e) => {
          setProductManual(e.fileList?.[0]?.originFileObj)
          setDefaultList([e.fileList?.[0]])
        }}
        listType="picture-card"
        multiple={false}
        onRemove={(e) => {
          setDefaultList([])
          setProductManual("")
          return false
        }}
      >
        {(defaultList?.length !== 1) && <Button>
          <UploadOutlined /> Upload Product Manual
        </Button>}
      </Upload>
    </div>
  )
}