import { combineReducers } from "redux";
import user from "./user";
import productList from "./productList";
import loading from "./loading";
import dealsHubAdmin from "./dealsHubAdmin";
import productDetails from "./editProductDetails";
import channelProducts from "./channelProducts";
import channelProductsUae from "./channelProductUae";
import channelProductEbay from "./channelProductEbay";
import channelProductNoon from "./channelProductNoon";
import channelwise from "./channelwise";
import exportListData from "./exportpage";
import dealsHubProducts from "./dealsHubProducts";
import DealshubAdminNavbarCategories from "./DealshubAdminNavbarCategories";
import ordersManagerDetails from "./ordersManager";
import miscPermission from "./miscPermission";
import manualSAPOverride from "./manualSAPOverride";
import refreshNotification from "./refreshNotificationReducer";

/**
 * @constant rootReducer
 * @description Combined reducer of all the reducers
 */

const rootReducer = combineReducers({
  user,
  dealsHubAdmin,
  user,
  productList,
  productDetails,
  channelProducts,
  channelProductsUae,
  channelProductEbay,
  channelProductNoon,
  channelwise,
  exportListData,
  dealsHubProducts,
  DealshubAdminNavbarCategories,
  ordersManagerDetails,
  miscPermission,
  manualSAPOverride,
  refreshNotification,
});

export default rootReducer;
