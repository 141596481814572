import isDev from "../server";
import axios from "axios";
import { message } from "antd";
const serverUrl = isDev();
export const setEmpty = () => {
  return { type: "SET_EMPTY" };
};

let pageCount = 1;
let cancelToken;

export const handleFilterChange = (filters) => dispatch => {
  dispatch({
    type: "HANDLE_FILTERS_CHANGE",
    payload: filters
  })
}


export const fetchProductList = (tags, locationUuid) => dispatch => {

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Previous Search running")
  }

  cancelToken = axios.CancelToken.source()

  let formData = new FormData();
  formData.append("page", 1);
  if (tags)
    formData.append("search_list", JSON.stringify(tags));
  formData.append("locationGroupUuid", locationUuid);

  axios
    .post(`${serverUrl}/fetch-dealshub-products/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      cancelToken: cancelToken.token
    })
    .then(response => {
      dispatch({
        type: "FETCH_DEALS_HUB_PRODUCT",
        payload: response.data
      });
      pageCount = 1;
    });

  // fetch(`${serverUrl}/fetch-dealshub-products/`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `JWT ${localStorage.getItem("token")}`
  //   },
  //   body: JSON.stringify(bodyData)
  // })
  //   .then(res => res.json())
  //   .then(data => {

  //     dispatch({
  //       type: "FETCH_DEALS_HUB_PRODUCT",
  //       payload: data
  //     });
  //     pageCount = 1;
  //   });
};

export const fetchProductListMore = (tags, filters, locationUuid, callback = null) => dispatch => {

  let formData = new FormData();
  formData.append("page", ++pageCount);
  if (tags)
    formData.append("search_list", JSON.stringify(tags));
  if (filters)
    formData.append("filter_parameters", JSON.stringify(filters))
  formData.append("locationGroupUuid", locationUuid);

  axios
    .post(`${serverUrl}/fetch-dealshub-products/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
    .then(response => {
      if (callback)
        callback();

      if (response.data.status == 200) {
        dispatch({
          type: "FETCH_DEALS_HUB_PRODUCT_MORE",
          payload: response.data
        });
      }
      else if (response.data.status === 405) {
        message.info("You are not authorised!")
      }
      else {
        message.error("Something went wrong");
      }
    });
};

export const fetchProductsByTags = (tags, filters, uploadFileWithSKU = null, locationUuid) => dispatch => {

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Previous Search running")
  }

  cancelToken = axios.CancelToken.source()

  pageCount = 1;
  let formData = new FormData();
  formData.append("page", pageCount);
  if (tags)
    formData.append("search_list", JSON.stringify(tags));
  if (filters)
    formData.append("filter_parameters", JSON.stringify(filters))

  if (uploadFileWithSKU)
    formData.append("import_file", uploadFileWithSKU);

  formData.append("locationGroupUuid", locationUuid);

  axios
    .post(`${serverUrl}/fetch-dealshub-products/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      cancelToken: cancelToken.token
    })
    .then(response => {
      dispatch({
        type: "FETCH_DEALS_HUB_PRODUCT",
        payload: response.data
      });
    });
}

export const addToExportList = payload => {
  return { type: "ADD_TO_EXPORT_LIST", payload };
};

export const removeFromExportList = payload => {
  return { type: "REMOVE_FROM_EXPORT_LIST", payload };
};
