import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogout } from "../../actions";
import {
  Layout,
  Menu,
  Dropdown,
  Icon,
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Button,
  Tooltip,
  Avatar,
  Spin,
  Divider,
  Modal,
  Select,
  Input,
  DatePicker,
} from "antd";
import styled from "styled-components";
import CategoryDetails from "views/category-details/CategoryDetails";
import AdminCategoryDetails from "views/admin-category-details/AdminCategoryDetails";
import "./dashboard.less";
import Products from "../components/modals/products-page/Products";
import ProductsDetails from "../Product-details-page/ProductsDetails";
import ProductDetails from "../Product-details-page/ProductDetailsNestoNew";
import EditBaseProductVarientPage from "../Edit-Base-Product-Page/EditBaseProductVarientPage";
import EditEcommerceProductPage from "views/Edit-Ecommerce-product-page/EditEcommerceProductPage";
import ChannelPage from "../channelpage/ChannelPage";
import ChannelDrawer from "../components/channel-drawer/ChannelDrawer";
import ChannelWiseView from "../export/ChannelWiseView";
import ExportsPage from "../export/ExportsPage";
import ReportsPage from "../reports/ReportsPage";
import ViewReportPage from "../reports/ViewReportPage";
import { Link } from "react-router-dom";
import ProfilePage from "../ProfilePage/ProfilePage";
import DealsHubAdmin from "../dealshubadmin/DealsHubAdmin";
import DealsHubVouchers from "../dealshub-vouchers/DealsHubVouchers";
import DealsHubAdminProducts from "../dealshubadmin/DealsHubAdminProducts";
import CreateNewProduct from "../Edit-Base-Product-Page/CreateNewProduct";
import CustomersB2B from "views/b2b/customers/CustomersB2B";
import CustomerB2BDetails from "views/b2b/customer-details/CustomerB2BDetails";
import UserManagement from "../user-management/UserManagement";
import CreateNewVariant from "../Edit-Base-Product-Page/CreateNewVariant";
import EditBaseProduct from "../Edit-Base-Product-Page/EditBaseProduct";
import { fetchProductList, setNestoUser } from "../../actions";
import AdminActivityHistoryPage from "../admin-activity-history-page/AdminActivityHistoryPage";
import Orders from "../orders-page/Orders";
import CustomersWithDetails from "../customers/CustomersWithDetails";
import Invoice from "../invoice/Invoice";
import DealsHubAdminCategory from "../dealshubadmin/DealsHubAdminCategory";
import Flyers from "../flyers/Flyers";
import CompanyProfile from "../company-profile-page/CompanyProfile";
import OrderDetails from "../order-details/OrderDetails";
import Factories from "../factories/Factories";
import FactoryProducts from "../factory-products/FactoryProducts";
import FactoryPageWithProducts from "../factory-products/FactoryPageWithProducts";
import FactoryProfile from "../factory-profile/FactoryProfile";
import CustomerDetails from "../customer-details/CustomerDetails";
import ProformaInvoice from "../proforma-invoice/ProformaInvoice";
import UserImg from "../../media/profile-img.jpg";
import PublishProducts from "../export/PublishProducts";
import PushInventory from "../export/PushInventory";
import PushPrices from "../export/PushPrices";
import DealshubCategorySlider from "../dealshub-category-slider/DealshubCategorySlider";
import OrderCancellation from "views/order-cancellation/OrderCancellation";
import EmployeeAnalytics from "views/employee-analytics/EmployeeAnalytics";
import isDev from "../../server";
import BundlePiPage from "../bundle-pi-page/BundlePiPage";

import BrandsPage from "../brands-listing/BrandsPage";
import BrandDetailsPage from "../brands-listing/BrandDetailsPage";
import BlogPage from "../blogPage/BlogPage";
import ReportsPageGeneralized from "../reports-generalized/ReportsPageGeneralized";
import ComposeArticle from "../blogPage/ComposeArticle";
import OrdersWarehouseManager from "../orders-warehouse-manager/OrdersWarehouseManager";
import PostaPlusTracking from "../orders-warehouse-manager/PostaPlusTracking";
import OrdersAccountManager from "../orders-account-manager-page/OrdersAccountManager";
import FactoryCardPage from "../factory-card-page/FactoryCardPage";
import { openRefreshPageNotification } from "../../actions/refreshNotificationAction";
import CreateFactoryProduct from "views/factories/create-factory-product/CreateFactoryProduct";
import RefreshPageNotification from "../components/refresh-page-notification";
import Categoryimage from "views/category-upload-image/Categoryimage";
import DealshubSettings from "views/dealshub-settings/DealshubSettings";
import SeoAdmin from "views/seo-admin/SeoAdmin";
import Notifications from "views/notifications/Notifications";
import ReviewManagement from "views/review-management/ReviewManagement";
import ActivityLogs from "views/activity-logs/ActivityLogs";
import OrderRequests from "../order-requests/OrderRequests";
import BlogSections from "../blogPage/BlogSections";
import {
  storePermissionAction,
  clearPermissionAction,
} from "../../actions/miscPermissionAction";
import axios from "axios";

const serverUrl = isDev();

const { Option } = Select;
const { Text } = Typography;

const { RangePicker } = DatePicker;

const UserNameLogo = styled(Text)`
  color: #dee2e6;
  text-transform: capitalize;
`;
/**
 * @description Dashboard is a class component
 * which renders on different conditions based on props(conditional rendering)
 * and return different components of the omnycomm  Dashboard
 */
class Dashboard extends Component {
  /**
   * @description initial state of the Dashboard component
   */
  state = {
    collapsed: false,
    isDrawer: false,
    isSaveBtn: true,
    form: "",
    visible: false,
    ChannelPage: "Amazon Uk",
    refresh: false,
    is_mobile: false,
    GR_LOADER: false,
    GSPR_LOADER: false,
    filters: null,
    leftPannel: {},
    openReportsModal: false,
    reportType: "Mega",
    reportModalNote: "",
    reportsGenerationLoading: false,
    selectedBrandsReportModal: [],
    oc_report_permission_list: [],
    start_date_reports_sales: "",
    end_date_reports_sales: "",
    orderAnalyticsAccess: false,
    salesAnalyticsAccess: false,
    locationGroup: "",
    locationGroupList: [],
    selectedLocationGroup: "",
    isNestoUser: false,
    fetchingPermissions: true,
    loggingOut: false,
  };

  handleTab = (name) => {
    this.setState({ activeTab: name });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  getFilters = (filters) => {
    this.setState({ filters });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  drawerOn = () => {
    this.setState({
      isDrawer: true,
    });
  };

  saveBtnOn = () => {
    this.setState({
      isSaveBtn: true,
    });
  };

  drawerOff = () => {
    this.setState({
      isDrawer: false,
    });
  };

  setForm = (f) => {
    this.setState({
      form: f,
    });
  };

  changeChannelPage = (e) => {
    this.setState({
      ChannelPage: e,
    });
  };

  handleLogout = () => {
    fetch(`${serverUrl}/logout-ocuser/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.setState(
            {
              loggingOut: true,
            },
            () => {
              this.props.history.replace("/");
              this.props.userLogout();
              localStorage.clear();
            }
          );
          this.props.clearPermissionAction();
        } else {
          message.error("Something went wrong!");
        }
      });
  };

  refresh = () => {
    this.setState({ refresh: true });
  };

  handelGenerateReports = () => {
    message.info("Please wait while server process your request!");
    this.setState({ GR_LOADER: true });
    let data = {
      filter_parameters: this.state.filters,
    };
    fetch(`${serverUrl}/generate-reports/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ GR_LOADER: false });
        if (data.status === 500) message.error("Internal Server Error!");
        else if (data.status === 403) {
          message.info("You are not authorised!");
        } else {
          window.open(data.file_path_1, "_blank");
          window.open(data.file_path_2, "_blank");
          window.open(data.file_path_3, "_blank");
        }
      });
  };

  /**
   * @Description Generates stock price report of dealshub for authorised users
   */
  handelGenerateStockPriceReportsDealsHub = () => {
    message.info("Please wait while server process your request!");
    this.setState({ GSPR_LOADER: true });
    let data = {};
    fetch(`${serverUrl}/dealshub/generate-stock-price-report/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ GSPR_LOADER: false });
        if (data.status == 500) message.error("Internal Server Error!");
        else if (data.status === 403) {
          message.info("You are not authorised!");
        } else {
          window.open(data.filepath, "_blank");
        }
      });
  };

  fetchReportPermissions = () => {
    let data = {};
    if (this.props.omnyCommUser) {
      fetch(`${serverUrl}/fetch-oc-report-permissions/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          //.log(data, "ssdsds");
          if (data.status == 200) {
            this.setState({
              oc_report_permission_list: data.oc_report_permission_list.filter(
                (item) => item !== "bulk upload product"
              ),
            });
          } else if (data.status === 405) {
            message.info("You are not authorised!");
          } else {
            message.error("Something Went Wrong!");
          }
        });
    }
  };

  checkCurrentVersionNumber = () => {
    axios
      .post(
        `${serverUrl}/fetch-current-version/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          if (localStorage.getItem("OCversionNumber")) {
            if (
              res.data.version_count !==
              JSON.parse(localStorage.getItem("OCversionNumber"))
            ) {
              localStorage.setItem("OCversionNumber", res.data.version_count);
              this.props.openRefreshPageNotification();
            }
          } else {
            localStorage.setItem("OCversionNumber", res.data.version_count);
          }
        }
      })
      .catch((err) => {});
  };

  async componentDidMount() {
    try {
      setInterval(async () => {
        this.checkCurrentVersionNumber();
      }, 120000);
    } catch (e) {}
    this.checkCurrentVersionNumber();

    this.fetchReportPermissions();
    if (window.screen.width < 768) {
      this.setState({ collapsed: true, is_mobile: true });
    }

    fetch(`${serverUrl}/fetch-location-group-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((r) => r.json())
      .then((data) => {
        this.setState({
          locationGroupList: data.location_group_list,
        });
      });

    let data = {};
    if (this.props.omnyCommUser) {
      // this.setState ({
      //     fetchingPermissions: true,
      // });
      fetch(`${serverUrl}/check-section-permissions/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            this.props.storePermissionAction(data.misc);
            this.props.setNestoUser(
              data.misc.includes("nesto-products") ? true : false,
              data.misc.includes("can-edit-nesto-product") ? true : false,
              data.misc.includes("image-delete") ? true : false,
              data.misc.includes("holding-details") ? true : false
            );

            this.setState(
              {
                leftPannel: {
                  ecom_pages: data.ecommerce_pages,
                  page_list: data.page_list,
                },
                orderAnalyticsAccess: data.misc.includes("analytics"),
                salesAnalyticsAccess: data.misc.includes("sales-analytics"),
                isNestoUser: data.misc.includes("nesto-products")
                  ? true
                  : false,
                fetchingPermissions: false,
                reportType: this.state.oc_report_permission_list[0],
              },
              () => {
                fetch(
                  `${serverUrl}/${
                    this.state.isNestoUser
                      ? "fetch-nesto-brands"
                      : "fetch-brands"
                  }/`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${localStorage.getItem("token")}`,
                    },
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    this.setState({ brandList: data.brand_list });
                  });
              }
            );
            localStorage.setItem("websiteGroupName", data.websiteGroupName);
          } else if (data.status === 405) {
            message.info("You are not authorised!");
          } else {
            this.setState({
              leftPannel: {
                ecom_pages: [],
              },
              fetchingPermissions: false,
            });
          }
        });
    }
  }

  handleReportBrands = (e) => {
    this.setState({
      selectedBrandsReportModal: e,
    });
  };
  data = {};
  handleOkReportsModal = () => {
    this.setState({
      reportsGenerationLoading: true,
    });

    this.data = {
      report_type: this.state.reportType,
      note: this.state.reportModalNote,
      brand_list: this.state.selectedBrandsReportModal,
      locationGroupUuid: this.locationGroupValidList.includes(
        this.state.reportType
      )
        ? this.state.selectedLocationGroup
        : undefined,
      shipping_method:
        this.state.reportType == "Delivery"
          ? this.state.shipping_method
          : undefined,
      from_date:
        (this.state.reportType == "Sales" ||
          this.state.reportType == "Verified Products" ||
          this.state.reportType == "Order" ||
          this.state.reportType == "Delivery" ||
          this.state.reportType == "daily_sales_order" ||
          this.state.reportType == "Abandoned Cart" ||
          this.state.reportType == "Customer Details") &&
        this.state.start_date_reports_sales,
      to_date:
        (this.state.reportType == "Sales" ||
          this.state.reportType == "Verified Products" ||
          this.state.reportType == "Order" ||
          this.state.reportType == "Delivery" ||
          this.state.reportType == "daily_sales_order" ||
          this.state.reportType == "Abandoned Cart" ||
          this.state.reportType == "Customer Details") &&
        this.state.end_date_reports_sales,
    };
    fetch(`${serverUrl}/create-oc-report/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(this.data),
    })
      .then((res) => res.json())
      .then((data) => {
        //.log(data, "ssdsds");
        this.setState({
          openReportsModal: false,
          reportsGenerationLoading: false,
        });
        if (data.status == 200) {
          if (data.approved)
            message.success("report request successfully submitted");
          else
            message.info("Request capacity exceeded. Please try again later");
        } else if (data.status === 403) {
          message.info("You are not authorised!");
        } else {
          message.error("Something Went Wrong!");
        }
      });
  };

  handleCancelReportsModal = () => {
    this.setState({
      openReportsModal: false,
    });
  };

  handleReportType = (e) => {
    this.setState({
      reportType: e,
    });
  };

  handleLocationGroup = (e) => {
    this.setState({
      selectedLocationGroup: e,
    });
  };

  dateFormat = "YYYY/MM/DD";
  locationGroupValidList = [
    "Ecommerce",
    "Search Keyword",
    "Sales",
    "Order",
    "Sap Billing",
    "Wishlist",
    "Abandoned Cart",
    "Stock",
    "Delivery",
    "Sales Executive",
    "daily_sales_order",
    "Customer Details",
  ];

  handleChange = (e) => {
    if (e[0] && e[1]) {
      this.setState({
        start_date_reports_sales: e[0].toDate(),
        end_date_reports_sales: e[1].toDate(),
      });
    } else {
      this.setState({
        start_date_reports_sales: "",
        end_date_reports_sales: "",
      });
    }
  };

  render() {
    let username = localStorage.getItem("username");

    const { Header, Sider, Content } = Layout;
    const { SubMenu } = Menu;

    const userMenu = (
      <Menu mode="horizontal">
        <Menu.Item>
          <a onClick={this.handleLogout}>Logout</a>
        </Menu.Item>
        {/* <Menu.Item>
                    <a>Activity Log</a>
                </Menu.Item>
                <Menu.Item>
                    <a>Upgrade to Pro</a>
                </Menu.Item> */}
      </Menu>
    );

    const checker = (str) => {
      return (
        this.state.leftPannel &&
        this.state.leftPannel.page_list &&
        this.state.leftPannel.page_list.includes(str)
      );
    };
    if (this.state.fetchingPermissions || this.state.loggingOut) {
      return (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#313841",
          }}
        >
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Layout>
            <Header style={{ background: "#fff", padding: 0 }}>
              <Row
                className="header-container"
                style={{ backgroundColor: "#36404A" }}
                type="flex"
                align="middle"
              >
                <Col lg={{ span: 1 }} xs={{ span: 2 }}>
                  <Icon
                    className="trigger"
                    type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                    style={{ opacity: "1!important" }}
                    onClick={this.toggle}
                  />
                </Col>

                <Col lg={{ span: 20, offset: 3 }} xs={{ span: 22, offset: 0 }}>
                  <Row
                    type="flex"
                    align="middle"
                    justify="end"
                    className="search-chips-container"
                  >
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 10 }}
                      sm={{ span: 13 }}
                      xs={{ span: 24 }}
                    >
                      <Row type="flex" align="middle" justify="end">
                        <Tooltip
                          placement="top"
                          title="Generate Reports"
                          className="margin-right-5"
                        >
                          <Button
                            type=""
                            shape="circle"
                            icon={"file-zip"}
                            size="small"
                            // disabled={this.state.GR_LOADER}
                            style={{
                              color: "white",
                              background: "gray",
                            }}
                            onClick={() => {
                              this.setState({
                                openReportsModal: true,
                              });
                            }}
                          ></Button>
                        </Tooltip>
                        {this.props.miscPermission.includes(
                          "support_and_progress_button"
                        ) && (
                          <Tooltip title="Tutorial" className="margin-right-5">
                            <Button
                              type="danger"
                              shape="circle"
                              icon="video-camera"
                              theme="filled"
                              size="small"
                              style={{
                                color: "white",
                              }}
                              onClick={(event) =>
                                window.open(
                                  "https://www.youtube.com/watch?v=7M1rd9spfvc&list=PLX27j92aezPETZF3Db6WsJfqC6Yyo4v0U&index=1",
                                  "_blank" // <- This is what makes it open in a new window.
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        {this.props.miscPermission.includes(
                          "support_and_progress_button"
                        ) && (
                          <Tooltip title="Support" className="margin-right-5">
                            <Button
                              type="primary"
                              shape="circle"
                              icon="question-circle"
                              theme="filled"
                              size="small"
                              style={{
                                color: "white",
                              }}
                              onClick={(event) =>
                                window.open(
                                  "https://forms.gle/oR8uQMTTT47iaort9",
                                  "_blank" // <- This is what makes it open in a new window.
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        {this.props.miscPermission.includes(
                          "support_and_progress_button"
                        ) && (
                          <Tooltip
                            title="Request user permission"
                            className="margin-right-5"
                          >
                            <Button
                              type="primary"
                              shape="circle"
                              icon="user-add"
                              theme="filled"
                              size="small"
                              ghost
                              style={{
                                color: "white",
                              }}
                              onClick={(event) =>
                                window.open(
                                  "https://forms.gle/gQeZYyRk1e2cbxTv8",
                                  "_blank" // <- This is what makes it open in a new window.
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        {this.props.miscPermission.includes(
                          "support_and_progress_button"
                        ) && (
                          <Tooltip title="Progress" className="margin-right-5">
                            <Button
                              type="primary"
                              shape="circle"
                              icon="schedule"
                              theme="filled"
                              size="small"
                              ghost
                              style={{
                                color: "white",
                              }}
                              onClick={(event) =>
                                window.open(
                                  "https://trello.com/b/f760PWIx/omnycommschedule",
                                  "_blank" // <- This is what makes it open in a new window.
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        {/* <HelpModal path={this.props.location.pathname} /> */}
                        <Divider
                          type="vertical"
                          style={{
                            backgroundColor: "#1ABC9C",
                          }}
                        />

                        <Dropdown overlay={userMenu}>
                          <a
                            className="ant-dropdown-link"
                            href="#"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            <Avatar
                              size={25}
                              src={UserImg}
                              className="margin-right-5"
                            />
                            {username} <Icon type="down" />
                          </a>
                        </Dropdown>
                      </Row>
                    </Col>

                    {/* <SearchChips /> */}
                  </Row>
                </Col>
              </Row>
            </Header>
            <Layout>
              <Sider
                theme="Dark"
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
                className={
                  this.state.collapsed ? "hide-on-small" : "overlay " + "sider"
                }
              >
                <Row
                  type="flex"
                  align="middle"
                  justify="start"
                  style={{
                    padding: "20px",
                    backgroundColor: "#1abc9c",
                  }}
                  className="username-logo-container"
                >
                  <Icon
                    type="user"
                    style={{
                      color: "white",
                      marginRight: "5px",
                      marginLeft: "1px",
                    }}
                  />
                  <UserNameLogo>{username}</UserNameLogo>
                </Row>

                {this.props.factoryUser ? (
                  <Menu mode="inline" defaultSelectedKeys="factory-products">
                    <Menu.Item
                      key="factory-products"
                      onClick={() => {
                        if (this.state.is_mobile)
                          this.setState({
                            collapsed: true,
                          });
                      }}
                    >
                      {/**
                       * Below are conditional rendered components
                       * which renders in a nested ternary operator
                       *  based on if user is a
                       * nesto user or B2B user
                       */}
                      <Icon type="appstore" />
                      <span> Products </span>{" "}
                      <Link to="/factory-products"></Link>
                    </Menu.Item>
                  </Menu>
                ) : this.state.isNestoUser ? (
                  <Menu mode="inline" defaultSelectedKeys={[this.props.page]}>
                    <Menu.Item
                      key="user"
                      onClick={() => {
                        if (this.state.is_mobile)
                          this.setState({
                            collapsed: true,
                          });
                      }}
                    >
                      <Icon type="user" />
                      <span>User </span>
                      <Link to="/user"></Link>
                    </Menu.Item>
                    <Menu.Item
                      key="products"
                      onClick={() => {
                        if (this.state.is_mobile)
                          this.setState({
                            collapsed: true,
                          });
                      }}
                    >
                      <Icon type="appstore" />
                      <span>Products </span>
                      <Link to="/products"></Link>
                    </Menu.Item>
                    {checker("OCReports") && (
                      <Menu.Item
                        key="oc-reports"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="file" />
                        <span>OC Reports </span>
                        <Link to="/oc-reports"></Link>
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key="brands"
                      onClick={() => {
                        if (this.state.is_mobile)
                          this.setState({
                            collapsed: true,
                          });
                      }}
                    >
                      <Icon type="tag" />
                      <span>Brands </span>
                      <Link to="/brands"></Link>
                    </Menu.Item>
                  </Menu>
                ) : (
                  <Menu mode="inline" defaultSelectedKeys={[this.props.page]}>
                    <Menu.Item
                      key="user"
                      onClick={() => {
                        if (this.state.is_mobile)
                          this.setState({
                            collapsed: true,
                          });
                      }}
                    >
                      <Icon type="user" />
                      <span>User </span>
                      <Link to="/user"></Link>
                    </Menu.Item>
                    {checker("UserManagement") && (
                      <Menu.Item
                        key="user-management"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="contacts" />
                        <span>User Management</span>
                        <Link to="/user-management"></Link>
                      </Menu.Item>
                    )}
                    {checker("BaseProduct") && (
                      <Menu.Item
                        key="products"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="appstore" />
                        <span>Products </span>
                        <Link to="/products"></Link>
                      </Menu.Item>
                    )}
                    {checker("CategoryHierarchy") && (
                      <Menu.Item
                        key="categories-details"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="appstore" />
                        <span>Category Hierarchy</span>
                        <Link to="/categories-details"></Link>
                      </Menu.Item>
                    )}
                    {checker("CategoryManagement") && (
                      <Menu.Item
                        key="admin-categories-details"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="appstore" />
                        <span>Category Management</span>
                        <Link to="/admin-categories-details"></Link>
                      </Menu.Item>
                    )}
                    {checker("AdminActivityLogs") && (
                      <Menu.Item
                        key={`activity-log`}
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="unordered-list" />
                        <span>Activity Logs</span>
                        <Link to={`/activity-logs/`}></Link>
                      </Menu.Item>
                    )}
                    {this.state.leftPannel &&
                      this.state.leftPannel.ecom_pages &&
                      this.state.leftPannel.ecom_pages.map((item, index) => {
                        return (
                          <SubMenu
                            key={`ecom_sections${index}`}
                            title={
                              <span>
                                <Icon type="tag" />
                                <span>{item.name}</span>
                              </span>
                            }
                          >
                            {checker("SectionsPage") && (
                              <Menu.Item
                                key={`deals-hub-admin-sections-${index}`}
                                onClick={() => {
                                  if (this.state.is_mobile)
                                    this.setState({
                                      collapsed: true,
                                    });
                                }}
                              >
                                <span>Sections</span>
                                <Link
                                  to={`/deals-hub-admin-sections/${item.uuid}/${item.name}`}
                                ></Link>
                              </Menu.Item>
                            )}
                            <Menu.Item
                              key={`deals-hub-admin-products-${index}`}
                              onClick={() => {
                                if (this.state.is_mobile)
                                  this.setState({ collapsed: true });
                              }}
                            >
                              <span>Products</span>
                              {item.is_b2b ? (
                                <Link
                                  to={`/deals-hub-admin-products/${
                                    item.uuid
                                  }/${item.name.toLowerCase()}/b2b`}
                                ></Link>
                              ) : (
                                <Link
                                  to={`/deals-hub-admin-products/${
                                    item.uuid
                                  }/${item.name.toLowerCase()}`}
                                ></Link>
                              )}
                            </Menu.Item>

                            <Menu.Item
                              key={`deals-hub-admin-category-slider-${index}`}
                              onClick={() => {
                                if (this.state.is_mobile)
                                  this.setState({
                                    collapsed: true,
                                  });
                              }}
                            >
                              <span>Category Slider</span>
                              <Link
                                to={`/deals-hub-admin-category-slider/${item.uuid}`}
                              ></Link>
                            </Menu.Item>
                            {checker("OrdersPage") && (
                              <Menu.Item
                                key={`orders-accounts-manager-${index}`}
                                onClick={() => {
                                  if (this.state.is_mobile)
                                    this.setState({
                                      collapsed: true,
                                    });
                                }}
                              >
                                <span>Orders Page</span>
                                {item.is_b2b ? (
                                  <Link
                                    to={`/orders-accounts-manager/${item.uuid}/b2b`}
                                  ></Link>
                                ) : (
                                  <Link
                                    to={`/orders-accounts-manager/${item.uuid}`}
                                  ></Link>
                                )}
                              </Menu.Item>
                            )}

                            <Menu.Item
                              key={`review-management-${index}`}
                              onClick={() => {
                                if (this.state.is_mobile)
                                  this.setState({
                                    collapsed: true,
                                  });
                              }}
                            >
                              <span>Review Management</span>
                              <Link to={`/review-management/${item.uuid}`} />
                            </Menu.Item>

                            {(item.uuid ===
                              "4724a505-8b23-45fe-bf98-4d4b23817aa4" ||
                              item.uuid ===
                                "f17c7336-644c-44a3-bc92-4f0c0b11463b") && (
                              <Menu.Item
                                key={`blogs-${index}`}
                                onClick={() => {
                                  if (this.state.is_mobile)
                                    this.setState({
                                      collapsed: true,
                                    });
                                }}
                              >
                                Blogs
                                <Link to={`/blogs/${item.uuid}`} />
                              </Menu.Item>
                            )}
                            {checker("ActivityLogs") && (
                              <Menu.Item
                                key={`activity-log-${index}`}
                                onClick={() => {
                                  if (this.state.is_mobile)
                                    this.setState({
                                      collapsed: true,
                                    });
                                }}
                              >
                                <span>Activity Logs </span>
                                <Link to={`/activity-logs/${item.uuid}`}></Link>
                              </Menu.Item>
                            )}

                            <Menu.Item
                              key={`deals-hub-admin-vouchers-${index}`}
                              onClick={() => {
                                if (this.state.is_mobile)
                                  this.setState({
                                    collapsed: true,
                                  });
                              }}
                            >
                              <span>Vouchers</span>
                              <Link
                                to={`/deals-hub-admin-vouchers/${item.uuid}`}
                              ></Link>
                            </Menu.Item>

                            <Menu.Item
                              key={`seo-admin-${index}`}
                              onClick={() => {
                                if (this.state.is_mobile)
                                  this.setState({
                                    collapsed: true,
                                  });
                              }}
                            >
                              <span>SEO Admin</span>
                              <Link to={`/seo-admin/${item.uuid}`}></Link>
                            </Menu.Item>

                            <Menu.Item
                              key={`deals-hub-admin-settings-${index}`}
                              onClick={() => {
                                if (this.state.is_mobile)
                                  this.setState({
                                    collapsed: true,
                                  });
                              }}
                            >
                              <span>Settings</span>
                              <Link
                                to={`/deals-hub-admin-settings/${item.uuid}`}
                              ></Link>
                            </Menu.Item>

                            {checker("Customers") && (
                              <Menu.Item
                                key={`customers-${index}`}
                                onClick={() => {
                                  if (this.state.is_mobile)
                                    this.setState({
                                      collapsed: true,
                                    });
                                }}
                              >
                                {/* <Icon type="profile" /> */}
                                <span>Customers</span>
                                {item.is_b2b ? (
                                  <Link
                                    to={`/b2b-customers/${item.uuid}`}
                                  ></Link>
                                ) : (
                                  <Link to={`/customers/${item.uuid}`}></Link>
                                )}
                              </Menu.Item>
                            )}

                            <Menu.Item
                              key={`deals-hub-admin-reports-${index}`}
                              onClick={() => {
                                if (this.state.is_mobile)
                                  this.setState({
                                    collapsed: true,
                                  });
                              }}
                            >
                              <span>Reports</span>
                              <Link
                                to={`/location-reports/${item.uuid}`}
                              ></Link>
                            </Menu.Item>
                            {checker("CompanyProfile") && (
                              <Menu.Item
                                key={`company-profile-${index}`}
                                onClick={() => {
                                  if (this.state.is_mobile)
                                    this.setState({
                                      collapsed: true,
                                    });
                                }}
                              >
                                <span>Company Profile</span>
                                <Link
                                  to={`/company-profile/${item.uuid}`}
                                ></Link>
                              </Menu.Item>
                            )}
                          </SubMenu>
                        );
                      })}
                    {checker("Notifications") && (
                      <Menu.Item
                        key="notifications"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="profile" />
                        <span>Notifications</span>
                        <Link to="/notifications"></Link>
                      </Menu.Item>
                    )}
                    {checker("ECommerce") && (
                      <SubMenu
                        key="sub2"
                        title={
                          <span>
                            <Icon type="shopping" />
                            <span>eCommerce</span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key="ecom-uk"
                          onClick={() => {
                            if (this.state.is_mobile)
                              this.setState({
                                collapsed: true,
                              });
                          }}
                        >
                          Amazon UK <Link to="/ecommerce/amazon-uk"></Link>
                        </Menu.Item>
                        <Menu.Item
                          key="ecom-uae"
                          onClick={() => {
                            if (this.state.is_mobile)
                              this.setState({
                                collapsed: true,
                              });
                          }}
                        >
                          Amazon UAE <Link to="/ecommerce/amazon-uae"></Link>
                        </Menu.Item>
                        <Menu.Item
                          key="ecom-ebay"
                          onClick={() => {
                            if (this.state.is_mobile)
                              this.setState({
                                collapsed: true,
                              });
                          }}
                        >
                          Ebay <Link to="/ecommerce/ebay"></Link>
                        </Menu.Item>
                        <Menu.Item
                          key="ecom-noon"
                          onClick={() => {
                            if (this.state.is_mobile)
                              this.setState({
                                collapsed: true,
                              });
                          }}
                        >
                          Noon <Link to="/ecommerce/noon"></Link>
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {checker("Factory") && (
                      <Menu.Item
                        key="factories"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="container" />
                        Factories <Link to="/factories"></Link>
                      </Menu.Item>
                    )}
                    {/* for category upload  images */}
                    {checker("CategoryImages") && (
                      <Menu.Item
                        key="categoryimage"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="container" />
                        Category Image <Link to="/categoryimage"></Link>
                      </Menu.Item>
                    )}

                    {checker("Export") && (
                      <Menu.Item
                        key="exports"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="export" />
                        <span>Export </span>
                        <Link to="/exports"></Link>
                      </Menu.Item>
                    )}
                    {(checker("OCReports") || checker("MWSReports")) && (
                      <SubMenu
                        key="reports-sub"
                        title={
                          <span>
                            <Icon type="database" />
                            <span>Reports</span>
                          </span>
                        }
                      >
                        {checker("MWSReports") && (
                          <Menu.Item
                            key="reports"
                            onClick={() => {
                              if (this.state.is_mobile)
                                this.setState({
                                  collapsed: true,
                                });
                            }}
                          >
                            <span>MWS Reports </span>
                            <Link to="/reports"></Link>
                          </Menu.Item>
                        )}

                        {checker("OCReports") && (
                          <Menu.Item
                            key="oc-reports"
                            onClick={() => {
                              if (this.state.is_mobile)
                                this.setState({
                                  collapsed: true,
                                });
                            }}
                          >
                            <span>Data Reports </span>
                            <Link to="/oc-reports"></Link>
                          </Menu.Item>
                        )}
                      </SubMenu>
                    )}

                    {checker("Flyers") && (
                      <Menu.Item
                        key="flyers"
                        onClick={() => {
                          if (this.state.is_mobile)
                            this.setState({
                              collapsed: true,
                            });
                        }}
                      >
                        <Icon type="sliders" />
                        <span>Flyers </span>
                        <Link to="/flyers"></Link>
                      </Menu.Item>
                    )}
                  </Menu>
                )}
              </Sider>

              <Content
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "1280px",
                  margin: "0 auto",
                }}
              >
                {this.props.page === "products" && checker("BaseProduct") && (
                  <Products
                    refresh={this.refresh}
                    changeChannelPage={this.changeChannelPage}
                    getFilters={this.getFilters}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "review-management" && (
                  <ReviewManagement
                    refresh={this.refresh}
                    changeChannelPage={this.changeChannelPage}
                    getFilters={this.getFilters}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                    locationGroupList={this.state.locationGroupList}
                  />
                )}
                {this.props.page === "activity-logs" &&
                  checker("ActivityLogs") && (
                    <ActivityLogs
                      refresh={this.refresh}
                      changeChannelPage={this.changeChannelPage}
                      getFilters={this.getFilters}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                    />
                  )}
                {this.props.page === "categories-details" &&
                  checker("CategoryHierarchy") && (
                    <CategoryDetails
                      refresh={this.refresh}
                      changeChannelPage={this.changeChannelPage}
                      getFilters={this.getFilters}
                      drawerOff={this.drawerOff}
                    />
                  )}
                {this.props.page === "admin-categories-details" &&
                  checker("CategoryManagement") && (
                    <AdminCategoryDetails
                      refresh={this.refresh}
                      changeChannelPage={this.changeChannelPage}
                      getFilters={this.getFilters}
                      drawerOff={this.drawerOff}
                    />
                  )}
                {this.props.page === "admin-activity-logs" &&
                  checker("AdminActivityLogs") && (
                    <ActivityLogs
                      refresh={this.refresh}
                      changeChannelPage={this.changeChannelPage}
                      getFilters={this.getFilters}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                    />
                  )}
                {this.props.page === "exports" && checker("Export") && (
                  <ExportsPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "reports" && checker("MWSReports") && (
                  <ReportsPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "company-profile" &&
                  checker("CompanyProfile") && (
                    <CompanyProfile
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                    />
                  )}
                {this.props.page === "view-report" && (
                  <ViewReportPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    report_pk={this.props.match.params.report_pk}
                  />
                )}
                {(this.props.page === "ecom-uk" ||
                  this.props.page === "ecom-uae" ||
                  this.props.page === "ecom-noon" ||
                  this.props.page === "ecom-ebay") &&
                  checker("ECommerce") && (
                    <ChannelWiseView
                      changeChannelPage={this.changeChannelPage}
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      channelname={this.props.channelname}
                      codeChannelName={this.props.codeChannelName}
                    />
                  )}
                {this.props.page === "product-details" &&
                  (this.state.isNestoUser ? (
                    <ProductDetails
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      productPk={this.props.match.params.product_pk}
                    />
                  ) : (
                    <ProductsDetails
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      productPk={this.props.match.params.product_pk}
                    />
                  ))}

                {this.props.page === "edit-product" &&
                  (this.state.isNestoUser ? (
                    <ProductDetails
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      productPk={this.props.match.params.userId}
                    />
                  ) : (
                    <EditBaseProductVarientPage
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      saveBtn={this.saveBtnOn}
                      id={this.props.match.params.userId}
                      base_product_pk={this.props.match.params.base_product_pk}
                    />
                  ))}

                {this.props.page === "notifications" &&
                  checker("Notifications") && (
                    <Notifications
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      saveBtn={this.saveBtnOn}
                      id={this.props.match.params.userId}
                    />
                  )}
                {this.props.page === "edit-ecommerce-product" && (
                  <EditEcommerceProductPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    saveBtn={this.saveBtnOn}
                    id={this.props.match.params.userId}
                    b2b={this.props.match.params.b2b}
                    locationGroupUuid={this.props.match.params.locationUuid}
                  />
                )}
                {this.props.page === "employee-analytics" && (
                  <EmployeeAnalytics
                    locationUuid={this.props.match.params.locationUuid}
                  />
                )}

                {this.props.page === "edit-channel-product" && (
                  <ChannelPage
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOn={this.drawerOn}
                    form={this.state.ChannelPage}
                    product_pk={this.props.match.params.product_pk}
                    channel_name={this.props.match.params.channel_name}
                    ToggelDrawer={() =>
                      this.setState({ visible: !this.state.visible })
                    }
                  />
                )}

                {this.props.page === "publish-product" && (
                  <PublishProducts
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    toggle={this.toggle}
                    drawerOff={this.drawerOff}
                    channelName={this.props.match.params.channelName}
                  />
                )}

                {this.props.page === "pushing-inventory" && (
                  <PushInventory
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    toggle={this.toggle}
                    drawerOff={this.drawerOff}
                    channelName={this.props.match.params.channelName}
                  />
                )}

                {this.props.page === "pushing-prices" && (
                  <PushPrices
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    toggle={this.toggle}
                    drawerOff={this.drawerOff}
                    channelName={this.props.match.params.channelName}
                  />
                )}

                {this.props.page === "create-new-product" &&
                  (this.state.isNestoUser ? (
                    <ProductDetails
                      createProduct
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      productPk={this.props.match.params.product_pk}
                    />
                  ) : (
                    <CreateNewProduct
                      refresh={this.refresh}
                      channelpagename={this.state.ChannelPage}
                      drawerOff={this.drawerOff}
                    />
                  ))}
                {/*{}*/}
                {this.props.page === "deals-hub-admin-sections" &&
                  checker("SectionsPage") && (
                    <DealsHubAdmin
                      refresh={this.refresh}
                      channelpagename={this.state.ChannelPage}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                      name={this.props.match.params.name}
                    />
                  )}
                {this.props.page === "deals-hub-admin-vouchers" && (
                  <DealsHubVouchers
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}

                {this.props.page === "deals-hub-admin-settings" && (
                  <DealshubSettings
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                    orderAnalyticsAccess={this.state.orderAnalyticsAccess}
                  />
                )}

                {this.props.page === "seo-admin" && (
                  <SeoAdmin
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}
                {checker("UserManagement") &&
                  this.props.page === "user-management" && (
                    <UserManagement
                      refresh={this.refresh}
                      channelpagename={this.state.ChannelPage}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                      locationGroupList={this.state.locationGroupList}
                    />
                  )}

                {this.props.page === "flyers" && checker("Flyers") && (
                  <Flyers
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "compose-article" && (
                  <ComposeArticle
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    id={this.props.match.params.blogId}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}

                {this.props.page === "blog-sections" && (
                  <BlogSections
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}

                {this.props.page === "deals-hub-admin-products" && (
                  <DealsHubAdminProducts
                    refresh={this.refresh}
                    b2b={this.props.match.params.b2b}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                    siteInfo={
                      this.props.match.params.siteInfo
                        ? this.props.match.params.siteInfo
                        : ""
                    }
                  />
                )}
                {this.props.page === "deals-hub-admin-category-slider" && (
                  <DealshubCategorySlider
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}
                {this.props.page === "user" && (
                  <ProfilePage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "activity-history" && (
                  <AdminActivityHistoryPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "orders" && (
                  <Orders refresh={this.refresh} drawerOff={this.drawerOff} />
                )}

                {this.props.page === "customers" &&
                  this.state.leftPannel.page_list &&
                  this.state.leftPannel.page_list.includes("Customers") && (
                    <CustomersWithDetails
                      data={this.data}
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                    />
                  )}

                {this.props.page === "invoice" && (
                  <Invoice refresh={this.refresh} drawerOff={this.drawerOff} />
                )}
                {this.props.page === "deals-hub-admin-category" && (
                  <DealsHubAdminCategory
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "edit-base-product" && (
                  <EditBaseProduct
                    refresh={this.refresh}
                    channelpagename={this.state.ChannelPage}
                    drawerOff={this.drawerOff}
                    base_product_pk={this.props.match.params.base_product_pk}
                  />
                )}
                {this.props.page === "factory-profile-for-sourcing" && (
                  <FactoryProfile
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    factory_pk={this.props.match.params.factory_pk}
                  />
                )}
                {this.props.page === "create-factory-product" && (
                  <CreateFactoryProduct
                    refresh={this.refresh}
                    drawoff={this.drawerOff}
                    factoryUser={this.props.factoryUser}
                    factory_uuid={this.props.match.params.factory_uuid}
                  />
                )}
                {}
                {this.props.page === "edit-factory-product" && (
                  <CreateFactoryProduct
                    refresh={this.refresh}
                    drawoff={this.drawerOff}
                    editMode
                    omnyCommUser={this.props.omnyCommUser}
                    factory_product_uuid={
                      this.props.match.params.factory_product_uuid
                    }
                  />
                )}
                {this.props.page === "create-new-variant" && (
                  <CreateNewVariant
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    id={this.props.match.params.base_product_pk}
                  />
                )}

                {this.props.page === "order-details" && (
                  <OrderDetails
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    uuid={this.props.match.params.uuid}
                    locationUuid={this.props.match.params.locationUuid}
                  />
                )}
                {this.props.page === "order-cancellation" && (
                  <OrderCancellation
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.locationUuid}
                  />
                )}
                {this.props.page === "factories" && checker("Factory") && (
                  <Factories
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}

                {/* for category upload image  */}
                {this.props.page === "categoryimage" &&
                  checker("CategoryImages") && (
                    <Categoryimage
                      refresh={this.refresh}
                      // changeChannelPage={this.changeChannelPage}
                      getFilters={this.getFilters}
                      drawerOff={this.drawerOff}
                    />
                  )}
                {this.props.page === "blogs" && (
                  <BlogPage
                    refresh={this.refresh}
                    // changeChannelPage={this.changeChannelPage}
                    getFilters={this.getFilters}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}
                {this.props.page === "order-requests" && (
                  <OrderRequests
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.locationUuid}
                  />
                )}
                {this.props.page === "factory-products-factorywise" && (
                  <FactoryProducts
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    factoryUser={this.props.factoryUser}
                    factory_uuid={
                      this.props.match.params.factory_uuid ||
                      this.props.factory_uuid
                    }
                  />
                )}
                {this.props.page === "factory-page" && (
                  <FactoryPageWithProducts
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "customer-details" && (
                  <CustomerDetails
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    emailId={this.props.match.params.emailId}
                  />
                )}
                {this.props.page === "proforma-invoice" && (
                  <ProformaInvoice
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "bundle-pi" && (
                  <BundlePiPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "orders-warehouse-manager" && (
                  <OrdersWarehouseManager
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}

                {this.props.page === "posta-plus-tracking" && (
                  <PostaPlusTracking
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}

                {this.props.page === "orders-accounts-manager" &&
                  checker("OrdersPage") && (
                    <OrdersAccountManager
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                      b2b={this.props.match.params.b2b}
                      orderAnalyticsAccess={this.state.orderAnalyticsAccess}
                      salesAnalyticsAccess={this.state.salesAnalyticsAccess}
                    />
                  )}
                {this.props.page === "b2b-customers" && (
                  <CustomersB2B
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    saveBtn={this.saveBtnOn}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}

                {this.props.page === "b2b-customers-details" && (
                  <CustomerB2BDetails
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    saveBtn={this.saveBtnOn}
                    username={this.props.match.params.username}
                    locationUuid={this.props.match.params.uuid}
                  />
                )}

                {this.props.page === "factory-cards" && (
                  <FactoryCardPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    piBundleUuid={this.props.match.params.piBundleUuid}
                  />
                )}
                {this.props.page === "brands" && (
                  <BrandsPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "edit-brand-details" && (
                  <BrandDetailsPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                    brandPk={this.props.match.params.brandPk}
                    is_edit={true}
                  />
                )}
                {this.props.page === "create-brand" && (
                  <BrandDetailsPage
                    refresh={this.refresh}
                    drawerOff={this.drawerOff}
                  />
                )}
                {this.props.page === "reports-generalized" &&
                  this.state.leftPannel.page_list &&
                  this.state.leftPannel.page_list.includes("OCReports") && (
                    <ReportsPageGeneralized
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                    />
                  )}
                {this.props.page === "location-reports" &&
                  this.state.leftPannel.page_list &&
                  this.state.leftPannel.page_list.includes("OCReports") && (
                    <ReportsPageGeneralized
                      refresh={this.refresh}
                      drawerOff={this.drawerOff}
                      locationUuid={this.props.match.params.uuid}
                    />
                  )}
              </Content>
            </Layout>
          </Layout>

          <Drawer
            className="channel-drawer"
            title="CHANNEL FORMS"
            placement={this.state.placement}
            mask={false}
            closable={true}
            onClose={this.onClose}
            visible={this.state.visible}
            width="47vw"
            keyboard={true}
            zIndex={1000}
            destroyOnClose={true}
          >
            <ChannelDrawer
              product_pk={this.props.match.params.product_pk}
              form={this.state.ChannelPage}
            />
          </Drawer>

          <Modal
            title="Generate Reports"
            className="generate-report"
            visible={this.state.openReportsModal}
            onOk={this.handleOkReportsModal}
            confirmLoading={this.state.reportsGenerationLoading}
            width="fit-content"
            onCancel={this.handleCancelReportsModal}
          >
            <Row gutter={[12, 12]}>
              <Col span={14}>
                <Text>Choose the report type</Text>
                <Select
                  style={{ width: "100%" }}
                  onSelect={this.handleReportType}
                  placeholder="Select Report Type"
                  value={this.state.reportType}
                >
                  {this.state.oc_report_permission_list.map((item, index) => {
                    return item !== "filtered nesto products" ? (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ) : null;
                  })}
                </Select>
              </Col>
              {this.locationGroupValidList.includes(this.state.reportType) && (
                <Col span={14}>
                  <Select
                    placeholder="Select Location Group"
                    style={{ width: "100%" }}
                    onSelect={this.handleLocationGroup}
                  >
                    {this.state.leftPannel.ecom_pages.map((item) => (
                      <Option key={item.name} value={item.uuid}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
              {this.state.reportType == "Delivery" && (
                <Col span={14}>
                  <Text>Choose Shipping Method</Text>
                  <Select
                    style={{ width: "100%" }}
                    onSelect={(e) => {
                      this.setState({
                        shipping_method: e,
                      });
                    }}
                    value={this.state.shipping_method}
                  >
                    <Option value="sendex">Sendex</Option>
                    <Option value="Emirates Post">Emirates Post</Option>
                  </Select>
                </Col>
              )}

              {!this.state.isNestoUser &&
                this.state.reportType != "Customer Details" && (
                  <Col span={14}>
                    <Select
                      allowClear
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select brands"
                      onChange={this.handleReportBrands}
                    >
                      {this.state.brandList &&
                        this.state.brandList.map((item, index) => (
                          <Option key={index} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                )}

              {(this.state.reportType == "Sales" ||
                this.state.reportType == "Verified Products" ||
                this.state.reportType == "Order" ||
                this.state.reportType == "Delivery" ||
                this.state.reportType == "daily_sales_order" ||
                this.state.reportType == "Abandoned Cart" ||
                this.state.reportType == "Customer Details") && (
                <Col span={18}>
                  <Text> Start & End Date </Text>
                  <br />
                  <RangePicker
                    format={this.dateFormat}
                    onChange={this.handleChange}
                  />
                </Col>
              )}

              <Col span={14}>
                <Text>Note:</Text>
                <Input.TextArea
                  style={{ background: "#3C4853" }}
                  onChange={(e) =>
                    this.setState({
                      reportModalNote: e.target.value,
                    })
                  }
                  value={this.state.reportModalNote}
                />
              </Col>
            </Row>
          </Modal>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (store, user, miscPermission) => {
  return {
    ...store,
    ...user,
    ...miscPermission,
  };
};

/**
 * The withRefreshNotificaion higher Order component takes the
 * Dashboard component and add the RefreshNotification component to it
 * @param {*} props
 * @returns RefreshPageNotification Component along with Dashboard
 */
const withRefreshNotification = (props) => {
  return (
    <>
      <RefreshPageNotification />
      <Dashboard {...props} />
    </>
  );
};

export default connect(mapStateToProps, {
  fetchProductList,
  userLogout,
  setNestoUser,
  storePermissionAction,
  clearPermissionAction,
  openRefreshPageNotification,
})(withRefreshNotification);
