import React, {Component} from 'react';
import {
    Avatar,
    Button,
    Col,
    Icon,
    Modal,
    Row,
    Switch,
    Typography,
    Rate,
    message,
    Empty,
} from 'antd';
import styled from 'styled-components';
import ReviewDetails from './ReviewDetails';
import axios from 'axios';
import isDev from '../../server';
import './review-management.scss';
import moment from 'moment';

const serverUrl = isDev();

const {Text} = Typography;

const BaseReviewContent = styled(Row)`
  padding: 12px;
  width: 100%;
  border-top: 1px solid #495561;
`;
const ReviewVariantContainer = styled(Row)`
  width: 100%;
`;
const ReviewVariantRow = styled(Row)`
  width: 100%;
  padding: 5px 2px;
`;

export default class ReviewTabularModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewDetailModal: false,
            reply: '',
            displayName: '',
            rating: '',
            reviewBody: '',
            reviewSubject: '',
            fileList: [],
            image_list: [],
            is_published: false,
            processing: false,
			created_at: '',
        };
    }

    componentDidMount() {
		this.setState({
			//reply: "hi",
			reply: this.props.reviewItem.review_content.admin_comment?.comment || '',
			reviewSubject: this.props.reviewItem.review_content.subject,
			reviewBody: this.props.reviewItem.review_content.content,
			rating: this.props.reviewItem.rating,
			displayName: this.props.reviewItem.display_name,
			image_list: this.props.reviewItem.review_content.image_url_list,
			fileList: [
				...this.props.reviewItem.review_content.image_url_list.map(
					(item, index) => {
						return {
							uid: item.uuid,
							url: item.url,
						};
					}
				),
			],
			is_published: this.props.reviewItem.is_published,
			created_at: this.props.reviewItem.created_date
		});
		console.log(this.state, "inside mount")
	}

    handleUpdateReview = (callback) => {
        let formData = new FormData();
        //formData.append('product_code', productUuid);
        formData.append('customerName', this.state.displayName);
        formData.append('review_uuid', this.props.reviewItem.review_uuid);
        formData.append('rating', this.state.rating);
		formData.append('created_at', moment(this.state.created_at).format('YYYY-MM-DD'));
        let current = 0;
        for (let i = 0; i < this.state.fileList.length; i++) {
            if (this.state.fileList[i].originFileObj) {
                formData.append(
                    `image_${current}`,
                    this.state.fileList[i].originFileObj
                );
                current++;
            }
        }
        formData.append('image_count', current);
        formData.append(
            'review_content',
            JSON.stringify({
                subject: this.state.reviewSubject,
                content: this.state.reviewBody,
            })
        );

        axios({
            method: 'post',
            url: `${serverUrl}/dealshub/update-review-admin/`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => {
                if (response.data.status === 200) {
                    message.success('Review Submitted!');
                    this.props.onSaveReview();
                    this.setState({
                        image_list: [
                            ...this.state.image_list,
                            ...response.data.image_url_list,
                        ],
                        fileList: [
                            ...this.state.image_list.map((item) => {
                                return {url: item.url, uid: item.uuid};
                            }),
                            ...response.data.image_url_list.map((item) => {
                                return {url: item.url, uid: item.uuid};
                            }),
                        ],
                    });
                } else {
                    message.error('Something went Wrong!');
                }
            })
            .then(() => callback());
    };

    handleDeleteImage = (uuid) => {
        axios({
            method: 'POST',
            url: `${serverUrl}/dealshub/delete-admin-review-image/`,
            data: {
                image_uuid: uuid,
                review_uuid: this.props.reviewItem.review_uuid,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    fileList: this.state.fileList.filter((item) => item.uid !== uuid),
                    image_list: this.state.image_list.filter(
                        (item) => item.uuid !== uuid
                    ),
                });
                message.success('Image removed');
            } else message.error('something went wrong');
        });
    };

    handleChangeImages = ({fileList}) => {
        this.setState({
            fileList: fileList,
        });
    };

    handleChangeReply = (comment) => {
        this.setState({
            reply: comment,
        });
    };

    handleChangeReviewBody = (review) => {
        this.setState({
            reviewBody: review,
        });
    };

    handleSubjectChange = (subject) => {
        this.setState({
            reviewSubject: subject,
        });
    };

    handleChangeRating = (rating) => {
        this.setState({
            rating: rating,
        });
    };

	handleChangeCreatedAt = (created_at) => {
		this.setState({
			created_at: created_at
		})
	}

    handleChangeDisplayName = (name) => {
        this.setState({
            displayName: name,
        });
    };

    handleAdminReply = () => {
        axios({
            method: 'POST',
            url: `${serverUrl}/dealshub/add-admin-comment/`,
            data: {
                uuid: this.props.reviewItem.review_uuid,
                comment: this.state.reply,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((response) => {
            if (response.data.status === 200) message.success('Reply Submitted');
            else if (response.data.status === 403) {
                message.info('You are not authorised!');
            } else message.error('Something went wrong!');
        });
    };

    handlePublishChange = () => {
        this.setState(
            {
                processing: true,
            },
            () => {
                axios({
                    method: 'POST',
                    url: `${serverUrl}/dealshub/update-review-publish-status/`,
                    data: {
                        review_uuid: this.props.reviewItem.review_uuid,
                        is_published: !this.state.is_published,
                    },
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }).then((response) => {
                    if (response.data.status === 200) {
                        this.setState({is_published: !this.state.is_published}, () => {
                            this.props.handlePublishChange(this.state.is_published);
                        });
                        message.success('Publish status changed successfully!');
                    } else message.error('Something went wrong');
                    this.setState({processing: false});
                });
            }
        );
    };

    render() {
        //console.log(this.props, "my propsss")
        return (
            // <BaseReviewContent
            //     type="flex"
            //     align="middle"
            // >
            //     <ReviewVariantContainer>
            <Row
                type='flex'
                gutter={[0, 0]}
                className='collapse-header'
                style={{padding: '15px'}}
            >
                <Col span={5}>
                    <Row gutter={[12, 12]}>
                        <Col span={24} style={{marginBottom: '10px'}}>
                            {/*<Icon type='user' style={{ background: '#5BCAAA', borderRadius: "10pxs" }} />*/}
                            {this.props.reviewItem.is_fake === false ? (
                                <Text> Original Review</Text>
                            ) : (
                                <Text> Pirate Review</Text>
                            )}
                        </Col>
                        <Col span={24}>
                            {this.props.reviewItem.is_fake === false ? (
                                <>
                                    <Text style={{color: 'white'}}>Display Name:</Text>
                                    <Text> {this.props.reviewItem.display_name}</Text>
                                </>
                            ) : (
                                <>
                                    <Text style={{color: 'white'}}>Display Name:</Text>
                                    <Text> {this.props.reviewItem.display_name}</Text>
                                    <br/>
                                    <Text style={{color: 'white'}}>Pirate Reviewer:</Text>
                                    <Text> {this.props.reviewItem.fake_oc_user}</Text>
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={6} style={{marginRight: '20px'}}>
                    <Row gutter={[12, 12]}>
                        <Col span={24} style={{marginBottom: '10px'}}>
                            <Text>Product Information</Text>
                        </Col>
                        <Col
                            span={6}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar
                                shape='square'
                                size={72}
                                src={this.props.reviewItem.product_details.image_url}
                            />
                        </Col>
                        <Col>
                            <Text style={{color: 'white', marginLeft: '5px'}}>
                                {this.props.reviewItem.product_details.seller_sku}
                            </Text>
                            <br/>
                            <Text
                                className='text-ellipsis'
                                style={{color: 'white', fontSize: '12px', paddingLeft: '5px'}}
                            >
                                {this.props.reviewItem.product_details.name}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={9}>
                    <Row gutter={[12, 12]}>
                        <Col
                            span={6}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                paddingBottom: '0px',
                            }}
                        >
                            <Rate
                                style={{fontSize: '12px'}}
                                disabled
                                value={Number(this.props.reviewItem.rating)}
                            />
                        </Col>
                        <Col span={2} style={{paddingBottom: '0px'}}>
                            <Text style={{fontWeight: 'bold'}}>
                                {this.props.reviewItem.rating}
                            </Text>
                        </Col>
                        <Col span={6} style={{paddingBottom: '0px'}}>
                            <Text style={{fontSize: '12px'}}>
                                {this.props.reviewItem.created_date}
                            </Text>
                        </Col>
                        <Col span={24} style={{paddingBottom: '0px'}}>
                            <Text style={{color: 'white'}}>
                                {this.props.reviewItem.review_content.subject}
                            </Text>
                        </Col>
                        <Col span={22} style={{paddingBottom: '0px'}}>
                            <Text style={{fontSize: '12px'}}>
                                {this.props.reviewItem.review_content.content}...{' '}
                            </Text>
                            <Text
                                style={{
                                    color: '#5BCAAA',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.setState({
                                        reviewDetailModal: true,
                                    });
                                }}
                            >
                                See More
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={3}>
                    <Row gutter={[12, 12]}>
                        <Col span={24} style={{alignItems: 'center'}}>
                            <Switch
                                size='small'
                                checked={this.state.is_published}
                                loading={this.state.processing}
                                onChange={this.handlePublishChange}
                            ></Switch>
                            <Text style={{color: 'white'}}> Publish</Text>
                        </Col>
                        <Col span={24}>
                            <Button
                                style={{width: '144px'}}
                                type='primary'
                                icon='form'
                                ghost
                                onClick={() => {
                                    this.setState({
                                        reviewDetailModal: true,
                                    });
                                }}
                            >
                                Edit
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button
                                style={{width: '144px'}}
                                type='primary'
                                icon='rollback'
                                ghost
                                onClick={() => {
                                    this.setState({
                                        reviewDetailModal: true,
                                    });
                                }}
                            >
                                Admin Reply
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Modal
                    title='Review Details'
                    visible={this.state.reviewDetailModal}
                    destroyOnClose={true}
                    closable={true}
                    onOk={() => {
                        this.setState({
                            reviewDetailModal: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            reviewDetailModal: false,
                        });
                    }}
                    width="fit-content"
                >
                    <ReviewDetails
                        handleUpdateReview={this.handleUpdateReview}
                        handleChangeImages={this.handleChangeImages}
                        handleChangeReviewBody={this.handleChangeReviewBody}
                        handleChangeDisplayName={this.handleChangeDisplayName}
                        handleChangeRating={this.handleChangeRating}
                        handleSubjectChange={this.handleSubjectChange}
                        handleChangereply={this.handleChangeReply}
                        handleAdminReply={this.handleAdminReply}
                        handleDeleteImage={this.handleDeleteImage}
						handleChangeCreatedAt={this.handleChangeCreatedAt}
						created_at={this.state.created_at}
                        image_list={this.state.image_list}
                        reviewSubject={this.state.reviewSubject}
                        rating={this.state.rating}
                        displayName={this.state.displayName}
                        reviewBody={this.state.reviewBody}
                        reply={this.state.reply}
                        fileList={this.state.fileList}
                        {...this.props}
                    />
                </Modal>
            </Row>
            //     </ReviewVariantContainer>
            // </BaseReviewContent>
        );
    }
}
