import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Icon,
    Modal,
    Row,
    Spin,
    Typography,
    Radio,
    Collapse,
    Switch,
    Tag,
    message,
    Input,
    Upload, AutoComplete, Card, Avatar
} from "antd";
import axios from "axios";
import isDev from "../../server";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import placeHolderImage from "../../media/placeholder3.png";
import arraymove from "array-move";

const serverUrl = isDev();
const { Title, Text, Paragraph } = Typography
const { Panel } = Collapse
const { Option } = AutoComplete
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const SortableItem = SortableElement((propsItem) => {
    console.log(propsItem)
    const [sectionName, setSectionName] = useState("")
    const [saveNameLoading, setSaveNameLoading] = useState(false)
    const [fileList, setFileList] = useState(propsItem.sectionData.sectionImageUrl ? [{
        url: propsItem.sectionData.sectionImageUrl,
        uid: 1,
        status: "done",
        name: "test.png"
    }] : [])
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState("")
    const [modalFileUid, setModalFileUid] = useState("")
    const [modalFileHttpLink, setModalFileHttpLink] = useState("")
    const [searchListing, setSearchListing] = useState([])
    const [searchString, setSearchString] = useState("")
    const [sectionBlogList, setSectionBlogList] = useState(propsItem.sectionData.sectionBlogPosts)
    const [bannerRemoved, setBannerRemoved] = useState(false)


    const removeBlogFromSection = (value) => {
        console.log(value)

        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/remove-blog-post-from-blog-section/`,
            data: {
                blogSectionUuid: propsItem.sectionData.sectionUuid,
                blogPostUuid: value
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setSectionBlogList(sectionBlogList.filter((blog) => blog.uuid !== value))
                message.success("Blog removed from section")
            } else message.error("Something went wrong!")
        })
    }

    const addBlogToSection = (value) => {
        console.log(value)
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/add-blog-post-to-blog-section/`,
            data: {
                blogSectionUuid: propsItem.sectionData.sectionUuid,
                blogPostUuid: value
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setSectionBlogList([...sectionBlogList, {
                    author: res.data.blogPostAuthor,
                    title: res.data.blogPostTitle,
                    coverImageUrl: res.data.blogPostImageUrl === "" ? placeHolderImage : res.data.blogPostImageUrl,
                    uuid: value
                }])
                message.success("Blog added to section!")
            } else {
                message.error("Something went wrong")
            }
        });
        console.log(sectionBlogList)
    }

    const autoCompleteSearch = (str) => {
        if (str !== "") {
            axios({
                method: "POST",
                url: `${serverUrl}/dealshub/search-blog-post-auto-complete/`,
                data: {
                    locationGroupUuid: propsItem.locationUuid,
                    search_string: str,
                    filterIsPublished: true
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then((res) => {
                if (res.data.status === 200) {
                    let tempList = []
                    res.data.blogPostList.map(list => (
                        tempList.push(
                            <Option key={list.uuid} value={list.uuid}>{list.title}</Option>
                        )
                    ));
                    setSearchListing([...[], ...tempList])
                }
            });
        } else {
            setSearchListing([...[], ...[]])
        }
    }

    const saveBannerName = (e) => {
        e.stopPropagation()
        setSaveNameLoading(true)
        axios({
            method: 'POST',
            url: `${serverUrl}/dealshub/edit-blog-section/`,
            data: {
                blogSectionUuid: propsItem.sectionData.sectionUuid,
                blogSectionName: sectionName
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setSaveNameLoading(false)
                message.success("Banner name updated Successfully!");
                propsItem.fetchSectionList()
            } else if (res.data.status === 403) {
                message.error("You are not authorized")
            } else message.error("Something went wrong!")
        })
    }

    const genExtra = (str, checked, uuid) => {
        return (
            <>
                <Switch
                    defaultChecked={checked}
                    onChange={(e) => {
                        modifySectionStatus(checked, uuid)
                    }}
                    disabled={str[0] === "FiveStyleBanner" && 5 - this.state.fileList.length}
                    style={{ marginRight: '10px' }}
                />
                <Tag color={str[1]}>{str[0]}</Tag>
            </>
        );
    }

    const modifySectionStatus = (checked, uuid) => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/modify-blog-section-status/`,
            data: {
                blogSectionUuid: uuid,
                isPublished: checked ? "False" : "True"
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                checked ? message.success("Blog unpublished successfully") :
                    message.success("Blog Published Successfully")
                //propsItem.fetchSectionList()
            } else if (res.data.status === 403) {
                message.error("You are not authorized")
            } else message.error("Something went wrong!")
        })
    }

    const handleChange = ({ fileList }) => {
        setFileList(fileList)
        // console.log(propsItem.sectionData.sectionName)
        let formData = new FormData();
        if (bannerRemoved) {
            formData.append('blogSectionImage', [])
        } else formData.append('blogSectionImage', fileList[0].originFileObj)
        formData.append('blogSectionUuid', propsItem.sectionData.sectionUuid)
        formData.append('blogSectionName', propsItem.sectionData.sectionName)
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/edit-blog-section/`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                //message.success("Image u")
            }
        })
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview)
        setModalFileHttpLink(file.httpLink)
        setModalFileUid(file.uid)

        // if (previewImage == null || previewImage === "") {
        //     setPreviewImage(placeHolderImage)
        // }
    };

    function beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG/GIF file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    // if (propsItem.sectionData.sectionType === "FullBannerImage") {
    return (
        <Row className="categoriescontainer" key={propsItem.sectionData.sectionUuid}>
            <Col xs={24}>
                <div className="collapse-div">
                    <Collapse expandIconPosition="left">
                        <Panel
                            className="dealshub-admin-collapse-panel"
                            header={propsItem.sectionData.sectionName}
                            extra={genExtra([propsItem.sectionData.sectionType, "blue"], propsItem.sectionData.is_published, propsItem.sectionData.sectionUuid)}
                        >
                            <div
                                className="content-container"
                                style={{ padding: "20px", background: "#36404A" }}
                            >
                                <Row>
                                    <Col span={24} style={{ marginBottom: "25px" }}>
                                        <Text className="text-color3">Section Display Name</Text>
                                        <Input
                                            defaultValue={propsItem.sectionData.sectionName}
                                            onChange={(e) => {
                                                e.stopPropagation()
                                                setSectionName(e.target.value);
                                            }}
                                            maxLength={100}
                                            style={{ marginTop: "5px" }}
                                        />
                                        <Button
                                            style={{ "marginTop": "20px" }}
                                            type="primary"
                                            loading={saveNameLoading}
                                            onClick={(e) => {
                                                saveBannerName(e);
                                            }}
                                        >Save Name</Button>
                                    </Col>
                                    <Col span={24}>
                                        {
                                            propsItem.sectionData.sectionType === "FullBannerImage" ?
                                                // <h6>hello</h6>
                                                <Col span={24} style={{ padding: '0px' }}>
                                                    <Upload
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onPreview={handlePreview}
                                                        onChange={handleChange}
                                                        onRemove={() => setBannerRemoved(true)}
                                                        customRequest={dummyRequest}
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        {fileList.length >= 1 ? null :
                                                            <div>
                                                                <Icon type="camera" />
                                                                <div className="ant-upload-text">
                                                                    You can drag and drop images or upload images
                                                                </div>
                                                            </div>}
                                                    </Upload>
                                                    <Modal visible={previewVisible} footer={null}
                                                        onCancel={() => setPreviewVisible(false)}>
                                                        <img style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Col>
                                                :
                                                <Col span={24}>
                                                    {(((propsItem.sectionData.sectionType === "FullWidthCard" || propsItem.sectionData.sectionType === "LargeCard") && sectionBlogList.length >= 1) ||
                                                        (propsItem.sectionData.sectionType === "FullWidthCard" && sectionBlogList.length >= 8)) &&
                                                        <Text>
                                                            <Icon type="info-circle" />
                                                            {`You can only add ${sectionBlogList.length} blog(s) in this section`}
                                                        </Text>
                                                    }
                                                    <AutoComplete
                                                        dataSource={searchListing}
                                                        onSelect={(value) => {
                                                            addBlogToSection(value)
                                                        }}
                                                        value={searchString}
                                                        onChange={(e) => setSearchString(e)}
                                                        onSearch={autoCompleteSearch}
                                                        placeholder="Search for Blogs"
                                                        style={{ width: "100%" }}
                                                        disabled={propsItem.sectionData.sectionType === "FullWidthCard" || propsItem.sectionData.sectionType === "LargeCard" ?
                                                            sectionBlogList.length >= 1 : sectionBlogList.length >= 8}
                                                    />

                                                    <Row style={{ padding: "20px 0px 0px 0px" }}>
                                                        {sectionBlogList.map((blog, index) => (
                                                            <Col
                                                                sm={24}
                                                                md={12}
                                                                lg={12}
                                                                xl={8}
                                                                xxl={8}
                                                                // span={24}
                                                                key={index}
                                                            >
                                                                <Card bordered className="categoryproductcard">
                                                                    <Col>
                                                                        <Col span={6}>
                                                                            <Avatar shape="square" size={64}
                                                                                src={blog.coverImageUrl} />
                                                                        </Col>
                                                                        <Col span={18}>
                                                                            <Paragraph
                                                                                className="text-color3"
                                                                                ellipsis={{ rows: 2, expandable: false }}
                                                                                style={{
                                                                                    boxSizing: "border-box",
                                                                                    height: "42px",
                                                                                    marginBottom: "0px"
                                                                                }}
                                                                            >
                                                                                {blog.title}
                                                                            </Paragraph>
                                                                            <Text className="text-color3">Author
                                                                                : </Text>
                                                                            <Text>{blog.author}</Text>
                                                                        </Col>
                                                                    </Col>
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            right: "10px",
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={() => {
                                                                            removeBlogFromSection(blog.uuid);
                                                                        }}
                                                                    // value={this.state.sectionData.uuid}
                                                                    // blogId={blog.blogPostUuid}
                                                                    >X
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Col>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </Col>
        </Row>
    )
})

const SortableList = SortableContainer((propsItem) => {
    return (
        <ul className="row wrapper-ul-row">
            {propsItem.sectionList.map((section, index) => (
                <SortableItem
                    fetchSectionList={propsItem.fetchSectionList}
                    spinnerOn={propsItem.spinnerOn}
                    locationUuid={propsItem.locationUuid}
                    sectionData={section}
                    index={index}
                    sectionIndex={index}
                    key={`item-${section.uuid}`}
                />
            ))}
        </ul>
    )
})

const BlogSections = (props) => {

    const [loading, setLoading] = useState(false)
    const [categoryModal, setCategoryModal] = useState(false)
    const [sectionTypes, setSectionTypes] = useState([])
    const [sectionRadioVal, setSectionRadioValue] = useState(null)
    const [addingSection, setAddingSection] = useState(false)
    const [sectionList, setSectionList] = useState([])
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [saveOrderInProgress, setSaveOrderInProgress] = useState(false)

    const saveOrdering = () => {
        setSaveOrderInProgress(true)
        const temp = sectionList.map((item) => {
            return (item.sectionUuid)
            //orderIndex: item.orderIndex,
        })

        console.log(temp, "save order api")
        axios.post(`${serverUrl}/dealshub/save-blog-section-order-index/`, {
            locationGroupUuid: props.locationUuid,
            blogSectionUuidList: temp
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                message.success("Orders applied Successfully!");

                setSaveOrderInProgress(false)

            } else if (response.data.status === 405) {
                message.info("You are not authorised!")
            } else {
                message.error("Something went wrong!");
            }
        });
    }

    const spinnerOn = (status) => {
        setSpinnerLoading(status)
    }

    const fetchSectionTypes = () => {
        axios.post(`${serverUrl}/dealshub/fetch-blog-section-types/`, {
            locationGroupUuid: props.locationUuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then((res) => {
            setSectionTypes(res.data.blogSectionTypes)
            setSectionRadioValue(res.data.blogSectionTypes.length > 0 ? res.data.blogSectionTypes[0] : null)
        })
    }

    const fetchSectionList = () => {
        axios.post(`${serverUrl}/dealshub/fetch-blog-section-list/`, {
            locationGroupUuid: props.locationUuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then((res) => {
            setSectionList(res.data.section_list)
        })
    }

    const handleSectionModalOk = () => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/create-blog-section/`,
            data: {
                blogSectionName: `Default Section ${sectionList.length + 1}`,
                BlogSectionType: sectionRadioVal,
                locationGroupUuid: props.locationUuid,
                orderIndex: sectionList.length + 1
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then((res) => {
            if (res.data.status === 200) {
                message.success("New Section created!")
                fetchSectionList()
                setCategoryModal(false)
            } else message.error("Something went wrong")
        })
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setSectionList(arraymove(sectionList, oldIndex, newIndex))
    }

    useEffect(() => {
        fetchSectionTypes()
        fetchSectionList()
    }, [])

    if (loading) {
        return (
            <div className="dealshub-admin-section-loader-container">
                <Spin indicator={antIcon} tip="Fetching Dealshub Admin Data" />
            </div>
        )
    } else
        return (
            <React.Fragment>
                <Row className="dealshubadmincontainer">
                    <Row
                        className="buttons"
                        type="flex"
                        justify="space-between"
                        align="middle"
                    >
                        <Col>
                            <Title style={{ marginBottom: "0px", color: "white" }} level={4}>
                                Sections
                            </Title>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={saveOrdering}
                                loading={saveOrderInProgress}
                            // disabled={
                            //     this.state.addCategoryInProgress ||
                            //     this.state.addBannerInProgress
                            // }
                            >
                                Save Order
                            </Button>
                            <Button
                                type="danger"
                                onClick={() => {
                                    setCategoryModal(true)
                                }}
                            //loading={this.state.addCategoryInProgress}
                            // disabled={
                            //     this.state.addBannerInProgress ||
                            //     this.state.saveOrderInProgress
                            // }
                            >
                                Add Category
                            </Button>
                            <Modal
                                title={"Select Section Type"}
                                visible={categoryModal}
                                onCancel={() => {
                                    setCategoryModal(false)
                                    setSectionRadioValue(sectionTypes[0])
                                }}
                                width="30%"
                                style={{ display: "flex", justifyContent: "center" }}
                                footer={[
                                    <Button key="back" onClick={() => {
                                        setCategoryModal(false)
                                        setSectionRadioValue(sectionTypes[0])
                                    }}>
                                        Cancel
                                    </Button>,
                                    <Button
                                        key="submit"
                                        type="primary"
                                        onClick={handleSectionModalOk}
                                    // loading={this.state.addBannerInProgress}
                                    // disabled={
                                    //     this.state.saveOrderInProgress ||
                                    //     this.state.addCategoryInProgress
                                    // }
                                    >
                                        Create Section
                                    </Button>
                                ]}
                            >
                                <Radio.Group
                                    onChange={(event) => setSectionRadioValue(event.target.value)}
                                    value={sectionRadioVal}
                                >
                                    {sectionTypes.map((item, index) => (
                                        <Radio
                                            key={index}
                                            value={item}
                                            style={{ display: "block" }}
                                        >
                                            {item}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Modal>
                        </Col>
                    </Row>
                    <SortableList
                        fetchSectionList={fetchSectionList}
                        spinnerOn={spinnerOn}
                        sectionList={sectionList}
                        axis="xy"
                        distance={2}
                        locationUuid={props.locationUuid}
                        onSortEnd={onSortEnd}
                    />
                    {sectionList.length == 0 &&
                        <Row type="flex" justify="center" align="middle" style={{ marginTop: "100px" }}>
                            <h5 style={{ color: "#fff" }}>No Sections Added</h5>
                        </Row>}
                </Row>
            </React.Fragment>
        )
}

export default BlogSections