import RightWrapper from "my-components/ui-components/RightWrapper";
import React , {useState , useEffect} from "react";
import TitleWithBreadCrumb from "views/components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {Row , Col, Button , Modal , message , Spin, notification} from "antd";
import NotificationCard from "./NotificationCard";
import SimpleReactValidator from "simple-react-validator";
import CalenderComponent from "views/components/calender-component/CalenderComponent";
import axios from "axios";
import isDev from "server";
import InputField from "views/components/datainputs/InputField";


const serverUrl = isDev();

function InnerWrapper(props){

    const [modalOpen , setModalOpen] = useState(false);
    const [initialLoader , setInitialLoader] = useState(false);
    const [notificationList , setNotificationList] = useState([]);
    const [inputData , setInputData] = useState({});
    const [expiryDate , setExpiryDate] = useState(null);
    const [modalMode , setModalMode] = useState("create");
    const [notificationSaveLoading , setNotificationSaveLoading] = useState(false);

    const fetchNotification = () => {
        setInitialLoader(true)
        let data = {
          
        };
        fetch(`${serverUrl}/sales-app/fetch-notification-list-for-admin/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then(data => {
              setInitialLoader(false);
              if(data.status == 200)
              {
                  setNotificationList(data.notification_list)
              }
              else if(data.status == 403 && data.message)
              {
                  message.info(data.message)
              }
              else
              {
                  message.error("Something went wrong!")
              }
          });
    }

    useEffect(() => {
         fetchNotification();
    } , [])

    const handelExpiryDate = (date) => {
        console.log(date);
        setExpiryDate(date);
    }

    const createNotification = () => {
        let data = {
            ...inputData,
            expiry_date : expiryDate
        };
        setNotificationSaveLoading(true);
        fetch(`${serverUrl}/sales-app/create-notification/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then(data => {
            setNotificationSaveLoading(false);
            setModalOpen(false);
              if(data.status == 200)
              {
                 message.success("Notification Created!");
                 fetchNotification();
              }
              else if(data.status == 403 && data.message)
              {
                  message.info(data.message)
              }
              else
              {
                  message.error("Something went wrong!")
              }
          });
    }

    const saveNotification = () => {
        let data = {
            ...inputData,
            expiry_date : expiryDate
        };
        setNotificationSaveLoading(true);
        fetch(`${serverUrl}/sales-app/save-notification/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then(data => {
              setNotificationSaveLoading(false);
              setModalOpen(false);
              if(data.status == 200)
              {
                message.success("Notification Updated!"); 
              }
              else if(data.status == 403 && data.message)
              {
                  message.info(data.message)
              }
              else
              {
                  message.error("Something went wrong!")
              }
          });
    }


    return (
        <React.Fragment>
             <TitleWithBreadCrumb
                title="Notifications"
                breadcrumb={["OmnyComm", "Notifications"]}
             />
             <Row gutter = {[12,12]} style = {{
                 padding : "35px",
             }}>
                 <Row style = {{
                       backgroundColor : "#36404A",
                       padding : "20px"
                 }} gutter = {[12,12]}>
                    <Col>
                        <Row gutter = {[12,12]} type = "flex">
                                <Col>
                                    <Button type = "primary" ghost>Result : 1200</Button>
                                </Col> 

                                <Col>
                                    <Button type = "danger" onClick = {
                                        () => {
                                            setModalMode("create");
                                            setInputData({});
                                            setExpiryDate(null);
                                            setModalOpen(true);
                                        }
                                    }>Add Notification</Button>
                                </Col> 
                        </Row>
                    </Col>
                    <Col>
                        {
                            initialLoader ? 
                            <Row type = "flex" justify = "center" align = "middle" style = {{
                                width : "100%",
                                height : "100px"
                            }}>
                                <Col>
                                    <Spin width = {120}/>
                                </Col>
                            </Row> :
                            <Row gutter = {[12,12]} style = {{
                                marginTop : "20px"
                            }}>
                                {
                                    notificationList.map((item , index) => {
                                        return (
                                            <Col span = {8}>
                                                <NotificationCard notification_item = {item} OpenDetails = {() => {
                                                        setModalMode("edit");
                                                        setInputData({
                                                            title : item.title,
                                                            subtitle : item.subtitle,
                                                            body : item.body,
                                                            notification_id : item.notification_id
                                                        })

                                                        if(item.expiry_date != "")
                                                            setExpiryDate(new Date(item.expiry_date));
                                                        else
                                                            setExpiryDate(null);

                                                        setModalOpen(true);
                                                }}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        }
                    </Col>
                </Row>
             </Row>

             <Modal title = {modalMode == "create" ? "Add Notification" : "Edit Notification" } visible = {modalOpen} onCancel = {() => {
                 setModalOpen(false);
             }}
                footer = {[
                    <Button onClick = {() => {
                        setModalOpen(false)
                    }} >Cancel</Button> , 
                    <Button type = "primary" loading = {notificationSaveLoading} disabled = {notificationSaveLoading} onClick = {
                        () => {
                            if(modalMode == "create"){
                                 createNotification();
                             }
                            else{
                                saveNotification();
                            }
                         }
                    } >{modalMode == "create" ? "Create" : "Save"}</Button>
                ]}
             >
                   <Row gutter = {[8,8]}>
                       <Col span = {24}>
                             <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "title",
                                    value: inputData.title,
                                    disabled: false
                                }}
                                onChange={(e)=>{
                                    setInputData({...inputData ,  [e.target.name] : e.target.value})
                                }}
                                labelText="Title"
                            />
                       </Col>
                       <Col span = {24}>
                             <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "subtitle",
                                    value: inputData.subtitle,
                                    disabled: false
                                }}
                                onChange={(e)=>{
                                    setInputData({...inputData ,  [e.target.name] : e.target.value})
                                }}
                                labelText="SubTitle"
                            />
                       </Col>
                       <Col span = {24}>
                             <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "body",
                                    value: inputData.body,
                                    disabled: false
                                }}
                                textarea
                                onChange={(e)=>{
                                    setInputData({...inputData ,  [e.target.name] : e.target.value})
                                }}
                                labelText="Body"
                            />
                       </Col>
                       <Col span = {24}>
                            <CalenderComponent
                                label="Expiry Date"
                                getDate={handelExpiryDate}
                                value={expiryDate}
                                size="large"
                                />
                       </Col>
                   </Row>
             </Modal>
        </React.Fragment>
    )
}

function Notifications(props){
    return (
        <RightWrapper
        innerwrapperTransparent
        InnerWrapper={
            <React.Fragment>
                <InnerWrapper  {...props}/>
            </React.Fragment>
        }
    />
    )
}

export default Notifications;