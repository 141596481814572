import React, { Component } from "react";
import { PageWrapper } from "../components/root-styled-components/pageObjects";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { Row, Col, Typography, message, Icon } from "antd";
import ReportsCard from "../components/cards/ReportsCard";

import "./reports-page.scss";


import isDev from "../../server";

const serverUrl = isDev();

const { Text } = Typography;

class ViewReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report_result: null
        }
    }


    componentDidMount = () => {
        let data = {
            report_pk: this.props.report_pk,
        };

        fetch(`${serverUrl}/mws/fetch-report-details/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, "DATA");
                if (data.status == 200) {
                    this.setState({
                        report_result: data
                    });
                } else {
                    message.error("Something went Wrong!");
                }
            });
    }

    render() {
        console.log(this.state);
        return (
            <PageWrapper gutter={40} className="exports-page-wrapper">
                <TitleWithBreadCrumb
                    title={`Report ${
                        this.state.report_result ? this.state.report_result.feed_submission_id : ""
                        }`}
                    breadcrumb={["OmnyComm", "View Report"]}
                />
                <Row
                    style={{
                        background: "#36404A",
                        minHeight: "80%",
                        borderRadius: "5px",
                        padding: "20px",
                        marginTop: "0px"
                    }}
                    gutter={[12, 12]}
                >
                    <Col sm={8}>
                        {this.state.report_result ?
                            <ReportsCard viewReport={true} reportDetails={{
                                pk: this.props.report_pk,
                                feed_submission_id: this.state.report_result.feed_submission_id,
                                operation_type: this.state.report_result.operation_type,
                                report_status: this.state.report_result.report_status,
                                is_read: this.state.report_result.is_read,
                                created_date: this.state.report_result.created_date,
                                product_count: this.state.report_result.product_count,
                                user: this.state.report_result.user,
                            }} /> :
                            <Row type="flex" justify="center" align="middle" className="v-r-p-table-rows">
                                <Col>
                                    <Icon type="loading" />
                                </Col>
                            </Row>

                        }

                    </Col>

                    <Col sm={16}>
                        {/* header */}

                        <Row type="flex" align="middle" className="v-r-p-table-rows">
                            <Col sm={2}>
                                <Text style={{ color: "white" }}>S.NO.</Text>
                            </Col>

                            <Col sm={10}>
                                <Text style={{ color: "white" }}> Product </Text>
                            </Col>

                            <Col sm={11} offset={1}>
                                <Text style={{ color: "white" }}>Status</Text>
                            </Col>
                        </Row>

                        {(this.state.report_result && this.state.report_result.errors) ? this.state.report_result.errors.map((item, index) => {
                            return (
                                <Row key={`errors-${index}`} type="flex" align="middle" className="v-r-p-table-rows">

                                    <Col sm={2}>
                                        <Text className="smallFont">{index + 1}.</Text>
                                    </Col>

                                    <Col sm={10}>
                                        <Typography.Paragraph
                                            // ellipsis={{
                                            //     row: 2,
                                            //     expandable: false
                                            // }}
                                            className="smallFont">{item.product_name}</Typography.Paragraph>
                                    </Col>

                                    <Col sm={11} offset={1} className="v-r-p-col">
                                        <Text className={`v-r-p-${item.error_type.toLowerCase()} smallFont`}>{item.error_type}: {item.error_code}</Text>
                                        <br />
                                        <Text className="smallFont">
                                            {item.error_message}
                                        </Text>
                                    </Col>
                                </Row>
                            )
                        }) :
                            <Row type="flex" justify="center" align="middle" className="v-r-p-table-rows">
                                <Col>
                                    <Icon type="loading" />
                                </Col>
                            </Row>
                        }



                        {/* {
                            this.state.report_result && this.state.report_result.errors && this.state.report_result.errors.length == 0 &&
                            this.state.report_result.report_status == "Done" &&
                            <React.Fragment>
                                <Row type="flex" justify="center" align="middle" style={{ paddingTop: "20px", marginBottom: "0px" }}>
                                    <Col>
                                        <Icon style={{ fontSize: "50px", color: "#59CAAA" }} type="check-circle" />
                                    </Col>
                                </Row>
                                <Row type="flex" justify="center" align="middle" style={{ paddingTop: "10px" }}>
                                    <Col>
                                        <Text style={{ fontSize: "14px" }}>No errors found</Text>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        } */}

                        {
                            this.state.report_result && this.state.report_result.errors && this.state.report_result.errors.length == 0 &&
                            this.state.report_result.report_status == "In Progress" &&
                            <React.Fragment>
                                <Row type="flex" justify="center" align="middle" style={{ paddingTop: "20px", marginBottom: "0px" }}>
                                    <Col>
                                        <Icon style={{ fontSize: "50px", color: "#F2DB8B" }} type="clock-circle" />
                                    </Col>
                                </Row>
                                <Row type="flex" justify="center" align="middle" style={{ paddingTop: "10px" }}>
                                    <Col>
                                        <Text style={{ fontSize: "14px" }}>Waiting for amazon to revert</Text>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        }


                       {(this.state.report_result && this.state.report_result.success &&
                            this.state.report_result.report_status == "Done" ) && this.state.report_result.success.map((item, index) => {
                            return (
                                <Row key={`errors-${index}`} type="flex" align="middle" className="v-r-p-table-rows">

                                    <Col sm={2}>
                                        <Text className="smallFont">{index + 1}.</Text>
                                    </Col>

                                    <Col sm={10}>
                                        <Typography.Paragraph
                                            ellipsis={{
                                                row: 2,
                                                expandable: false
                                            }}
                                            style = {{marginBottom : "0px"}}
                                            className="smallFont">{item.product_name}</Typography.Paragraph>
                                    </Col>

                                    <Col sm={11} offset={1} className="v-r-p-col">
                                        {/* <Text className={`v-r-p-${item.error_type.toLowerCase()} smallFont`}>{item.error_type}: {item.error_code}</Text>
                                        <br /> */}

                                        {
                                            item.stock != undefined ? 
                                            <Text className="smallFont">
                                              {`Stock is updated to ${item.stock} units`}
                                             </Text> :
                                           ( item.now_price != undefined ?
                                            <React.Fragment>
                                                <Text className="smallFont">
                                              {`Now price is updated to ${item.now_price} AED`}
                                             </Text>
                                             <br/>
                                             <Text className="smallFont">
                                              {`Was price is updated to ${item.was_price} AED`}
                                             </Text>
                                            </React.Fragment> :
                                                 <Text className="smallFont">
                                                 {`Published`}
                                                </Text>
                                           )
                                        }
                                       
                                    </Col>
                                </Row>
                            )
                        })}


                    </Col>


                </Row>
            </PageWrapper>
        );
    }
}

export default ViewReportPage;