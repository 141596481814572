import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Typography,
    Button,
    Icon,
    Collapse,
    Tooltip,
    Checkbox,
    Dropdown,
    Modal,
    Menu,
    Radio,
    message,
    Upload, Switch,
} from 'antd';
import './order-row.scss';
import IconChips from 'my-components/ui-components/IconChips';
import OrderNotes from "../order-notes/orderNotes";
import axios from "axios";
import isDev from "../../server";
import { useDispatch, useSelector } from 'react-redux';
import Input from 'antd/es/input/Input';
import { locationUuidConst } from 'constants/locationUuid';

const { Text, Title } = Typography;
const { Panel } = Collapse;
const serverOmnycommUrl = isDev();


function StatusModal(props) {
  const type =
    props.statusModal.type === "call"
      ? ["Pending", "Confirmed", "Unconfirmed"]
      : props.locationGroupUuid === locationUuidConst.Geepas_uganda ||
        props.locationGroupUuid === locationUuidConst.QA_geepas_uganda
      ? ["WIG Fleet"]
      : props.locationGroupUuid === locationUuidConst.WIGme_B2B ||
        props.locationGroupUuid === locationUuidConst.QA_wigme_B2B
      ? [
          "WIG Fleet",
          "grand gaadi",
          "Emirates Post",
          "weship",
          "Buy or Bid Wig Fleet",
          "Buy or Bid Sendex",
          "Buy or Bid Grand Gaadi",
        ]
      : props.locationGroupUuid === locationUuidConst.WIGMe_UAE ||
        props.locationGroupUuid === locationUuidConst.QA_wigme_UAE
      ? ["WIG Fleet", "grand gaadi", "Emirates Post", "weship"]
      : props.logix
      ? ["WIG Fleet", "grand gaadi", "Emirates Post", "Sendex", "LOGIX"]
      : ["WIG Fleet", "grand gaadi", "Emirates Post", "Sendex"];

  const [method, setMethod] = useState(props.statusModal.value);

  const bundleId = props.bundleId;
  let isSapManualUpdateInit = props.isSapManualUpdate;

  /**
     * Requirement: Manual Override of shipping method, if true then admin has the ability to change the status of the tracking
     to Approved
     */
  const [isSapManualUpdate, setIsSapManualUpdate] = useState(
    isSapManualUpdateInit
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setIsSapManualUpdate(isSapManualUpdateInit);
  }, [isSapManualUpdateInit]);

  function dispatchIsSapManualUpdate() {
    dispatch({
      type: "UPDATE",
      payload: {
        bundleId,
        isSapManualUpdate,
      },
    });
  }

  console.log(
    "STATUS",
    props.locationGroupUuid,
    locationUuidConst.QA_geepas_uganda
  );
  const initialWeight = props?.weight ?? 0;
  const [weight, setWeight] = useState(initialWeight);

  const handleSaveAndUpdate = () => {
    if (weight === "" || parseFloat(weight) < 0 || isNaN(weight)) {
      message.error("Invalid Weight Parameter. It should be >= 0");
      return;
    }

    dispatchIsSapManualUpdate();
    if (
      method === "Buy or Bid Wig Fleet" ||
      method === "Buy or Bid Sendex" ||
      method === "Buy or Bid Grand Gaadi"
    ) {
      props.onSave(method.toLowerCase(), isSapManualUpdate, weight.toString());
    } else {
      props.onSave(method, isSapManualUpdate, weight.toString());
    }
  };

  return (
    <Modal
      footer={null}
      title={null}
      visible={props.statusModal.visible}
      onCancel={props.onCancel}
      width="40%"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Title level={4} style={{ color: "white" }}>
        {props.statusModal.type === "call" ? "Call Status" : "Shipping Method"}
      </Title>
      <Radio.Group
        defaultValue={props.statusModal.value}
        onChange={(e) => {
          setMethod(e.target.value);
        }}
      >
        {type.map((item) => (
          <Radio
            value={item}
            style={{
              color: "white",
              margin: "10px 0px",
            }}
          >
            {item}
          </Radio>
        ))}
      </Radio.Group>
      <br />
      <br />

      {props.statusModal.type !== "call" &&
        (method === "Sendex" || method === "Buy or Bid Sendex") && (
          <>
            <Input
              maxLength={6}
              min={props.weight}
              style={{ width: "35%", marginBottom: "15px", padding: "10px" }}
              suffix="Kg"
              type="number"
              size="small"
              addonBefore="Weight: "
              value={weight}
              onChange={(e) => {
                setWeight(parseFloat(e.target.value));
              }}
            />
          </>
        )}
      {props.type === "shipping" && (
        <Row justify="end">
          <Col span={5}>
            <Text>Manual Override:</Text>
          </Col>
          <Col span={4}>
            <Switch
              checked={isSapManualUpdate}
              onChange={(checked) => setIsSapManualUpdate(checked)}
            />
          </Col>
        </Row>
      )}
      <Row
        style={{
          padding: "10px 0 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ background: "#1abc9c", color: "white", border: "none" }}
          disabled={!method}
          onClick={() => handleSaveAndUpdate()}
        >
          Save and Update
        </Button>
      </Row>
    </Modal>
  );
}


function CustomerRowHeader(props) {

    const isChecked = props.checkSelectedOrders(props.order.bundleId);
    const [statusModal, setStatusModal] = useState({
        type: null,
        visible: false,
        value: null,
    });
    const [status, setStatus] = useState({ call: null, shipping: null });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [chequeModal, setChequeModal] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewVisible, setPreviewVisible] = useState(false)
    const [chequeImgList, setChequeImgList] = useState([])
    // const [editImgs, setEditImgs] = useState(false)
    // const chequeImgCount = props.order.cheque_images_list?.length;

    const handleApproveHelper = () => {
        if (chequeImgList.length < 1) {
            message.info("Please add images before approval")
        } else {
            const formData = new FormData()
            formData.append('locationGroupUuid', props.locationGroupUuid)
            formData.append('uuid', props.order.uuid)
            formData.append('action', 'update')
            let curr = 0;
            for (let i = 0; i < chequeImgList.length; i++) {
                if (chequeImgList[i].originFileObj) {
                    formData.append(`cheque_image_${curr}`, chequeImgList[i].originFileObj);

                } else {
                    formData.append(`cheque_image_${curr}`, chequeImgList[i]);
                }
                curr++;
            }
            formData.append('cheque_image_count', chequeImgList.length)

            axios({
                method: 'POST',
                url: `${serverOmnycommUrl}/dealshub/order/set-order-cheque-image/`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                }
            }).then((res) => {
                if (res.data.status === 200) {
                    handleChequeImages('approve')
                } else {
                    message.error("Something Went Wrong!")
                }
            })
        }
    }
    const handleChequeImages = (action) => {
        if (action === "approve" && chequeImgList.length < 1) {
            message.info("Please add images before approval")
        } else {
            const formData = new FormData()
            formData.append('locationGroupUuid', props.locationGroupUuid)
            formData.append('uuid', props.order.uuid)
            formData.append('action', action)
            let curr = 0;
            for (let i = 0; i < chequeImgList.length; i++) {
                if (chequeImgList[i].originFileObj) {
                    formData.append(`cheque_image_${curr}`, chequeImgList[i].originFileObj);
                    curr++;
                }
                // else {
                //     formData.append(`cheque_image_${curr}`, chequeImgList[i]);
                // }
            }
            formData.append('cheque_image_count', curr)
            axios({
                method: 'POST',
                url: `${serverOmnycommUrl}/dealshub/order/set-order-cheque-image/`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                }
            }).then((res) => {
                if (res.data.status === 200) {
                    message.success("Success")
                    setButtonDisable(res.data.cheque_approved)
                    if (action !== "update") setChequeModal(false)
                    setChequeImgList(res.data.cheque_images_list.map((item) => {
                        return {
                            url: item.url,
                            uid: item.uuid,
                        }
                    }))
                } else {
                    message.error("Something Went Wrong!")
                }
            })
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleChange = ({ fileList }) => {
        setChequeImgList(fileList)
    }

    const misc = useSelector((state) => state.miscPermission)

    const handleDelete = (e) => {
        if (!e.originFileObj) {
            axios({
                method: 'POST',
                url: `${serverOmnycommUrl}/dealshub/order/set-order-cheque-image/`,
                data: {
                    action: 'delete',
                    image_uuid: e.uid,
                    uuid: props.order.uuid,
                    locationGroupUuid: props.locationGroupUuid
                },
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                if (res.data.status === 200) {
                    setChequeImgList(chequeImgList.filter((item) => item.uid !== e.uid))
                    message.success("Image removed")
                } else message.error("something went wrong")
            })
        }
    }

    const isSapManualUpdate = useSelector((state) => state.manualSAPOverride.get(props?.order?.bundleId))

    const displayTrackingStatus = () => {
        if (props?.order?.isSapManualUpdate === true || isSapManualUpdate === true) {
            const status = ['pending', 'approved', 'picked', 'dispatched', 'delivered', 'delivery failed', 'returned'];
            let currentStatus = status.findIndex(
                (item) => item.toLowerCase() === props?.order?.currentStatus?.toLowerCase()
            );
            if (currentStatus < 2) {
                return 'picked'
            } else return props?.order?.currentStatus;
        }
        else return props?.order?.currentStatus;
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
    };
    useEffect(() => {
        setStatus({
            call: props.order.call_status,
            shipping: props.order.shippingMethod,
            tracking: props.order.currentStatus,
        });
        setButtonDisable(props.order.cheque_approved)
        setChequeImgList(props.order.cheque_images_list?.map((item) => {
            return {
                uid: item.uuid,
                url: item.url,
            };
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.call_staus, props.order.shippingMethod]);
    return (
        <Row
            gutter={[12, 0]}
            style={{
                backgroundColor: '#36404a',
                position: 'relative',
                width: "100%"
                // borderTopWidth: "1px",
                // borderColor: "#1ABC9C",
                // borderStyle: "solid"
            }}
        >
            <Col span={1}>
                <Checkbox
                    checked={isChecked}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => props.setSelectedOrders(props.order)}
                />
            </Col>
            <Col span={7}>
                <Text style={{ color: '#69CAAA', fontSize: '16px' }}>
                    {props.isB2b ? (
                        props.order.companyName
                    ) : (
                        <>
                            <Text style={{ color: '#69caaa' }}>
                                {props.order.customerName}
                            </Text>
                            <Tooltip
                                placement='bottom'
                                title={
                                    <React.Fragment>
                                        <Text style={{ color: 'white' }}>
                                            {props.order.customerName}
                                        </Text>
                                        <br />
                                        <Text style={{ color: 'white', fontSize: '12px' }}>
                                            +{props.order.contactNumer}, {props.order.emailId}
                                        </Text>
                                    </React.Fragment>
                                }
                            >
                                <Icon
                                    type='info-circle'
                                    style={{
                                        padding: '5px',
                                        color: '#69caaa',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Tooltip>
                        </>
                    )}
                </Text>
                <br />
                <Text style={{ fontSize: '12px', color: 'white' }}>
                    {props.order.bundleId}
                </Text>
            </Col>
            <Col span={6}>
                {props.isB2b && (
                    <>
                        <Text style={{ color: 'white' }}>{props.order.customerName}</Text>
                        <Tooltip
                            placement='bottom'
                            title={
                                <React.Fragment>
                                    <Text style={{ color: 'white' }}>
                                        {props.order.customerName}
                                    </Text>
                                    <br />
                                    <Text style={{ color: 'white', fontSize: '12px' }}>
                                        +{props.order.contactNumer}, {props.order.emailId}
                                    </Text>
                                </React.Fragment>
                            }
                        >
                            <Icon
                                type='info-circle'
                                style={{ padding: '5px', color: 'white', cursor: 'pointer' }}
                            />
                        </Tooltip>
                        <OrderNotes
                            color='white'
                            itemNote={props.order.additionalNote === "" ? "No note available" : props.order.additionalNote}
                            adminNotes={props.order.adminNote} />
                        <br />
                    </>
                )}
                <Text style={{ color: 'white', fontSize: '12px' }}>
                    {props.order.dateCreated} <br />
                    {props.order.time}
                </Text>
            </Col>
            <Col span={5}>
                <Text style={{ color: 'white', fontSize: '14px' }}>
                    {props.order.unitOrderList.length}
                </Text>
            </Col>
            <Col span={5}>
                <Icon type='wallet' style={{ color: '#69CAAA', fontSize: '14px' }} />
                <Text
                    style={{
                        color: 'white',
                        fontSize: '14px',
                        marginLeft: '8px',
                    }}
                >
                    {props.order.currency} {props.order.toPay}
                </Text>
            </Col>

            <Col span={24} style={{ marginTop: '10px' }}>
                <Row type='flex' gutter={[12, 12]}>
                    <Col>
                        <IconChips
                            color={props.order.paymentMode === 'COD' ? "rgb(30, 212, 200)" : '#f2fc32'}
                            text={`Payment Mode - ${props.order.paymentMode}`}
                            icon='tags'
                            onClick={(e) => {
                                if (props.order.paymentMode === 'CHEQUE') {
                                    e.stopPropagation();
                                    setChequeModal(true)
                                }
                            }}
                        //borderclr = {props.order.paymentMode === 'COD' ? 'rgb(30, 212, 200)': 'rgb(54, 64, 74)' }
                        />
                    </Col>
                    <Col>
                        <IconChips
                            color={status.call === 'Confirmed' ? 'rgb(30, 212, 200)' : '#1890ff'}
                            text={`Call Status - ${status.call}`}
                            icon='phone'
                            onClick={(e) => {
                                if(!misc.includes("change-tracking-status")) return
                                e.stopPropagation();
                                setStatusModal({
                                    type: 'call',
                                    visible: true,
                                    value: status.call,
                                });
                            }}
                        />
                    </Col>
                    <Col>
                        <IconChips
                            color={props.order.sapStatus === 'success' ? 'rgb(30, 212, 200)' : '#faad14'}
                            text={`SAP Status - ${props.order.sapStatus}`}
                            icon={props.order.sapStatus === 'success' ? 'check-circle' : 'hourglass'}
                            onClick={() =>{
                                props.sapStatus(props.order.sap_final_billing_info)
                            }}
                        />
                    </Col>
                    <Col>
                        <IconChips
                            color='#52c41a'
                            text={`Shipping Method - ${status.shipping} ${(status.shipping !== 'pending') ? ((!props.order?.weight || props.order?.weight === 0) ? '' : `[${props.order.weight} Kg]`) : ''}`}
                            icon='car'
                            disabled={status.shipping !== 'pending'}
                            onClick={(e) => {
                                if(!misc.includes("change-tracking-status")) return
                                e.stopPropagation();
                                setStatusModal({
                                    type: 'shipping',
                                    visible: true,
                                    value: props.order.shippingMethod,
                                });
                            }}
                        />
                    </Col>
                    <Col>
                        <IconChips
                            color='pink'
                            text={`Tracking Status - ${displayTrackingStatus()}`}
                            icon='line-chart'
                            onClick={(e) => {
                                e.stopPropagation();
                                if (props.order.currentStatus !== 'cancelled')
                                    props.singleOrderTrackingModal(props.order);
                            }}
                        />
                    </Col>
                    {props.order.isOrderOffline && (
                        <Col>
                            <IconChips
                                color='#1ed4c8'
                                icon='tag'
                                text={`Offline Order ${props.order.referenceMedium && '/'} ${props.order.referenceMedium
                                    }`}
                            />
                        </Col>
                    )}
                    {props.order.isOrderOffline && (
                        <Col>
                            <IconChips
                                color='#1ed4c8'
                                icon='tag'
                                text={`Sales Person - ${props.order.salesPerson}`}
                            />
                        </Col>
                    )}
                </Row>
            </Col>

            <div style={{ position: 'absolute', right: '20px' }}>
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item onClick={props.downloadInvoice}>
                                <Text>Download Invoice</Text>
                            </Menu.Item>
                            <Menu.Item onClick={props.cancelOrder}>
                                <Text>Cancel Order</Text>
                            </Menu.Item>
                            <Menu.Item onClick={props.viewDetails}>
                                <Text>View Details</Text>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Icon
                        type='more'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={{ fontSize: '16px', color: 'white' }}
                    />
                </Dropdown>
            </div>
            <Modal
                visible={chequeModal}
                title={"Cheque Images"}
                width={"40%"}
                style={{ display: "flex", justifyContent: "center" }}
                onOk={() => setChequeModal(false)}
                onCancel={(e) => {
                    e.stopPropagation();
                    setChequeModal(false);
                }}
                footer={null}
            >
                {/*<Text>Upload Images</Text>*/}
                <Row>
                    {buttonDisable && <Col span={24}>
                        <Text style={{ color: 'red' }}><Icon
                            type="info-circle" />{""}{"Cheque Images for this order have already been approved"}</Text>
                    </Col>}
                    <Col span={24} style={{ marginTop: '16px' }}>
                        <Upload
                            listType={"picture-card"}
                            onPreview={handlePreview}
                            fileList={chequeImgList}
                            onChange={handleChange}
                            onRemove={handleDelete}
                            customRequest={dummyRequest}
                            disabled={!misc.includes("approving-payments")}
                        >
                            {chequeImgList?.length >= 2 ? null : <div>
                                <Icon type="plus" />
                                <div className="ant-upload-text">Upload</div>
                            </div>}
                        </Upload>
                    </Col>
                    {misc.includes("approving-payments") && <Col span={24}>
                        <Col span={12}>
                            <Button type={"primary"} disabled={buttonDisable} onClick={(e) => {
                                e.stopPropagation();
                                handleChequeImages("update");
                            }}>Save</Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type={"primary"} style={{ marginRight: '20px' }} disabled={buttonDisable || chequeImgList.length === 0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleApproveHelper();
                                }}>Approve</Button>
                            <Button type={"primary"} disabled={buttonDisable || chequeImgList.length === 0} onClick={(e) => {
                                e.stopPropagation();
                                handleChequeImages("disapprove");
                            }}>Disapprove</Button>
                        </Col>
                    </Col>}

                    <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </Row>
            </Modal>
            <StatusModal
                locationGroupUuid={props.locationGroupUuid}
                statusModal={statusModal}
                type={statusModal.type}
                weight={props.order?.weight}
                bundleId={props.order.bundleId}
                isSapManualUpdate={props.order.isSapManualUpdate}
                onCancel={() =>
                    setStatusModal({ type: null, visible: false, value: null })
                }
                onSave={(item, isSapManualUpdate, weight) => {
                    if (statusModal.type === 'call') {
                        props.callStatus(item);
                        setStatus({ ...status, call: item });
                    } else if (statusModal.type === 'shipping') {
                        props.shippingMethod(item, isSapManualUpdate, weight);
                        setStatus({ ...status, shipping: item });
                    }

                    setStatusModal({ type: null, visible: false, value: null });
                }}
                logix={props.order.website_group_name === 'daycart'}
            />
        </Row>
    );
}

function CustomerRowInnerHeader({ order, sapStatus }) {
    return (
        <Row
            style={{
                position: 'relative',
                marginTop: '20px',
                padding: '20px 0px',
                borderTop: '1px solid #ffffff1c',
            }}
            gutter={[12, 0]}
        >
            <Col span={16}>
                <Row gutter={[12, 12]}>
                    <Col span={3}>
                        <img
                            src={order.productImageUrl}
                            style={{ width: '100%', height: '100%' }}
                            alt="Inner Header"
                        />
                    </Col>
                    <Col span={21}>
                        <Text
                            style={{
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'lighter',
                            }}
                        >
                            {order.productName}
                        </Text>
                        <br />
                        <Text style={{ color: 'white', fontSize: '10px' }}>
                            {order.orderId}
                        </Text>
                        <Text style={{ color: 'white', fontSize: '14px' }}>{" "}x{" "}{order.quantity}</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={4}>
                <Text style={{ color: 'white' }}>{" "}</Text>
            </Col>
            {/*<Col span={4}>*/}
            {/*	<Text style={{ color: 'white' }}>{order.quantity}</Text>*/}
            {/*</Col>*/}
            <Col span={4}>
                <Text style={{ color: 'white' }}>
                    {order.currency} {order.totalPrice}
                </Text>
            </Col>

            <Col span={24} style={{ marginTop: '10px' }}>
                <Row type='flex' gutter={[12, 12]}>
                    <Col>
                        <IconChips
                            color={order.currentStatus === "cancelled" ? 'tomato' : 'pink'}
                            text={`Current Status - ${order.currentStatus}`}
                            icon='line-chart'
                        />
                    </Col>
                    <Col>
                        <IconChips
                            color='#faad14'
                            text={`SAP Status - ${order.sapStatus}`}
                            icon='hourglass'
                            onClick={() => sapStatus(order.sap_intercompany_info)}
                        />
                    </Col>
                    <Col>
                        <IconChips
                            color='#52c41a'
                            text={`Shipping Method - ${order.shippingMethod}`}
                            icon='car'
                        />
                    </Col>
                    {order.final_qty !== '' &&
                        order.intercompany_qty !== '' &&
                        Number(order.final_qty) !== Number(order.intercompany_qty) && (
                            <Col>
                                <IconChips
                                    color='#ff470f'
                                    text={`SAP final Qty - ${order.final_qty}`}
                                    icon='hourglass'
                                />
                            </Col>
                        )}
                    {order.final_qty !== '' &&
                        order.intercompany_qty !== '' &&
                        Number(order.final_qty) !== Number(order.intercompany_qty) && (
                            <Col>
                                <IconChips
                                    color='#ff470f'
                                    text={`SAP intercompany Qty - ${order.intercompany_qty}`}
                                    icon='hourglass'
                                />
                            </Col>
                        )}
                </Row>
            </Col>
        </Row>
    );
}

function OrderRow(props) {
    const [open, setNotOpen] = useState(false);
    return (
        <React.Fragment>
            {props.order.showSapWarning && (
                <Row
                    type='flex'
                    justify='space-between'
                    style={{
                        backgroundColor: 'rgba(255, 0, 0, 0.22)',
                        padding: '0px 10px 1px 10px',
                        width: "100%"
                    }}
                >
                    <Col>
                        <Icon type='warning' style={{ color: 'white', fontSize: '10px' }} />
                        <Text
                            style={{ color: 'white', fontSize: '10px', marginLeft: '5px' }}
                        >
                            This order not yet accepted
                        </Text>
                    </Col>
                    <Col>
                        <Text style={{ color: 'white', fontSize: '10px' }}>
                            This order not yet accepted
                        </Text>
                        <Icon
                            type='reload'
                            style={{ color: 'white', fontSize: '10px', marginLeft: '5px' }}
                            onClick={props.resendSapOrder}
                        />
                    </Col>
                </Row>
            )}
            {props.order.cancelStatus && (
                <Row
                    type='flex'
                    justify='space-between'
                    style={{
                        backgroundColor: `rgba(255,${props.order.partially_cancelled_by_user ? '255' : '0'
                            },0, 0.7)`,
                        padding: '0px 10px 1px 10px',
                        width: "100%"
                    }}
                >
                    <Col>
                        <Icon
                            type='stop'
                            style={{
                                color: props.order.partially_cancelled_by_user
                                    ? 'black'
                                    : 'white',
                                fontSize: '10px',
                            }}
                        />
                        <Text
                            style={{
                                color: props.order.partially_cancelled_by_user
                                    ? 'black'
                                    : 'white',
                                fontSize: '10px',
                                marginLeft: '5px',
                            }}
                        >
                            This order is{' '}
                            {props.order.partially_cancelled_by_user ? 'partially ' : ''}
                            cancelled{' '}
                            {props.order.cancelling_note !== ''
                                ? `- ${props.order.cancelling_note}`
                                : ''}
                        </Text>
                    </Col>
                    <Col></Col>
                </Row>
            )}
            <Collapse
                className='OrderHistoryRow'
                onChange={() => {
                    setNotOpen(!open);
                }}
            >
                <Panel
                    key='1'
                    extra={<CustomerRowHeader {...props} />}
                    showArrow={false}
                    style={{ width: "100%" }}
                >
                    {props.order.unitOrderList.map((order) => (
                        <CustomerRowInnerHeader order={order} sapStatus={props.sapStatus} />
                    ))}
                </Panel>
            </Collapse>
        </React.Fragment>
    );
}

export default OrderRow;
