// const development =
//   process.env.NODE_ENV === "development"

//     //UAT
//     // ? "https://omnycomm.com:8012"
//     // : "https://omnycomm.com:8012";

//qa
// ? "https://13.232.143.29:8028"
// : "https://13.232.143.29:8028";

// //prod
// // ? "https://omnycomm.com:8004"
// // : "https://omnycomm.com:8004";
/**
 * @description It exports server url for dealshub
 */

const developmentOmnyComm =
  //UAT
  // "https://uat.omnycomm.com:8012";

  //QA
  // "https://qa.omnycomm.com:8028";

  // PROD
  "https://api.omnycomm.com";

export default function isDev(isDealsHub) {
  return developmentOmnyComm;
}

// orders
// orders details
// customers
