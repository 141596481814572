import React, { Component } from "react";
import {
  Icon,
  Row,
  Col,
  Avatar,
  Breadcrumb,
  Button,

} from "antd";

import {
  BreadcrumbContainer,
  ProductDetailsTitle,
  ProductDetailsTitleIsTrancate
} from "./styled-components-library";
import "./title-with-bread-crumb.scss";

class TitleWithBreadCrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const breadcumblist = this.props.breadcrumb.map((temp, index) => {
    //   return (
    //     <React.Fragment>
    //       <Breadcrumb.Item key={index}>{temp}</Breadcrumb.Item>
    //       {index !== this.props.breadcrumb.length - 1 && (
    //         <Breadcrumb.Separator>
    //           <Icon type="right" className="breadcrumb-icon" />
    //         </Breadcrumb.Separator>
    //       )}
    //     </React.Fragment>
    //   );
    // });

    const breadcumblist = this.props.breadcrumb.map((temp, index) => {
      return (
        <Breadcrumb.Item key={index}>{temp}</Breadcrumb.Item>
      );
    });

    return (
      <BreadcrumbContainer
        type="flex"
        justify="space-between"
        className="hide-on-small-breadcrumb"
        style = {{
          padding : "25px 20px 20px 0px"
        }}
      >
        <Col lg={11} sm={24}>
          <Row>
            {this.props.imageTitle && <Avatar shape="square" size={64} />}
            {!this.props.imageTitle && !this.props.isTruncation && <ProductDetailsTitle level={4}>
              {this.props.title}
            </ProductDetailsTitle>}

            {this.props.isTruncation && <ProductDetailsTitleIsTrancate ellipsis={{ rows: 2, expandable: false }}>{this.props.title}</ProductDetailsTitleIsTrancate>}

          </Row>
        </Col>
        <Col className="hide-on-small-breadcrumb" lg={11} sm={24}>
          {
            this.props.rightPortion ? 
            <React.Fragment>
              {this.props.rightPortion}
            </React.Fragment>
             : 
            <Row>
              <Row
                type="flex"
                align="middle"
                justify="end"
                style={{ backgroundColor: "#313841" }}
              >
                <Breadcrumb separator=">">{breadcumblist}</Breadcrumb>
              </Row>
          </Row>
          }
          {this.props.additionalButtons &&
            <Row style={{ marginTop: "10px" }}>
              <Row
                type="flex"
                align="middle"
                justify="end"
                style={{ backgroundColor: "#313841" }}
              >

                <Button type="primary" icon="user" >Profile</Button>

              </Row>
            </Row>}

        </Col>
      </BreadcrumbContainer>
    );
  }
}

export default TitleWithBreadCrumb;
