import React, { Component } from "react";
import { Typography, Button, Icon, Row, Col } from "antd";
import "react-datepicker/dist/react-datepicker.css";

import "./view-pi-page.scss";
import GeepasLogo from "../../media/GEEPAS-logo2.jpeg";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

import piImage from "./images/my_tweet (2).jpg";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import axios from "axios";
import isDev from "../../server";

import { saveAs } from "@progress/kendo-file-saver";

/**
 * @description isDev checks if it is dev env. and gives us back the serverUrl according to that
 * @constant {string} serverUrl
 */

const serverUrl = isDev();

const { Paragraph, Text, Title } = Typography;

/**
 * @typedef {object} StateType - State type of ViewPiPage
 * @property {string} str - constant value not used anywhere
 * @property {boolean} pdfGenerating -
 * @property {{factory_name: string, factory_address: string, Sharjah: string, Dubai: string, UAE: string, factory_number: string, contact_person_name: string, contact_person_mobile_number: string, loading_port: string, discharge_port: string, vessel_details: string, vessel_final_destination: string, payment_terms: string, inco_terms: string, delivery_terms: string, shipment_lot_number: string, invoice_number: string, invoice_date: string, bank_name: string, account_number: string, ifsc_code: string, swift_code: string, branch_code: string, bank_address: string, important_notes: string, terms_and_condition: string}} data - This is all the data about factory
 */

export default class ViewPiPage extends Component {
  _element = null;
  /**
   * @description - This is ViewPiPage state. The state contains factory data, boolean for checking if pdf can be generated or not and one constant string of number which is not used anywhere
   * @type { StateType }
   */
  state = {
    str: "020 8051579286",
    pdfGenerating: false,
    data: {
      factory_name: "",
      factory_address: "",
      Sharjah: "",
      Dubai: "",
      UAE: "",
      factory_number: "",
      contact_person_name: "",
      contact_person_mobile_number: "",
      loading_port: "",
      discharge_port: "",
      vessel_details: "",
      vessel_final_destination: "",
      payment_terms: "",
      inco_terms: "",
      delivery_terms: "",
      shipment_lot_number: "",
      invoice_number: "",
      invoice_date: "",
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      swift_code: "",
      branch_code: "",
      bank_address: "",
      important_notes: "",
      terms_and_condition: "",
    },
  };
  /**
   * @param  {object} e - react event
   * @param  {string} name
   * @returns {void}
   */
  onChange = (e, name) => {
    this.setState({
      data: { ...this.state.data, [name]: e },
    });
  };

  /**
   * @description This function is handles to curate data for saving. Used at save button.
   */

  handleSave = () => {
    let data = {
      uuid: this.props.match.params.piUuid,
      ...this.state.data,
    };
    //.log(data);
    fetch(`${serverUrl}/sourcing/save-pi-form/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState(
          {
            pdfGenerating: true,
          },
          () => {
            let gridElement =
              document.getElementsByClassName("pi-page-container")[0];
            drawDOM(gridElement, { paperSize: "A1", margin: 100 })
              .then((group) => {
                return exportPDF(group);
              })
              .then((dataUri) => {
                let base64URI = dataUri;
                this.handleUpload(base64URI);
              });

            setTimeout(() => {
              this.setState({
                pdfGenerating: false,
              });
            }, 1000);
          }
        );
      });
  };

  /**
   *@description Used to upload the file
   * @param {object} File - file which needs to be saved
   * @return
   *
   */

  handleUpload = (File) => {
    let formData = new FormData();
    formData.append(`proforma_pdf`, File);
    formData.append("uuid", this.props.match.params.piUuid);
    axios
      .post(`${serverUrl}/sourcing/upload-factory-pi/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        //.log(res);
      })
      .catch((err) => {});
  };

  /**
   * @description sets innerHTML of node with id tnc to state.data.important_notes
   */

  renderTnC = () => {
    let outer = document.getElementById("tnc");
    let inner = this.state.data.important_notes;

    outer.innerHTML = inner;
  };

  componentDidMount = () => {
    let data = {
      uuid: this.props.match.params.piUuid,
    };

    //.log(data);
    fetch(`${serverUrl}/sourcing/fetch-pi-form/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        //.log(data);

        if (data.status == 200) {
          this.setState(
            {
              data,
            },
            this.renderTnC
          );
        }
      });
  };

  render() {
    //.log(this.state);
    return (
      <React.Fragment>
        <Row
          type="flex"
          align="middle"
          justify="end"
          gutter={[12, 12]}
          className="view-pi-controllers"
        >
          <Col>
            <Button
              size="default"
              style={{ zIndex: "100000" }}
              onClick={this.exportPDFWithMethod}
              icon="download"
            >
              Download
            </Button>
          </Col>
          <Col>
            <Button
              size="default"
              style={{ zIndex: "100000" }}
              onClick={this.handleSave}
              icon="save"
            >
              Save
            </Button>
          </Col>
          {/* <Button style={{ zIndex: "100000" }} onClick={this.exportPDFWithMethod} icon="download">Download</Button> */}
        </Row>

        <hr />

        {/* <PDFExport ref={(component) => this.pdfExportComponent = component} paperSize="auto" margin={100}> */}
        <div
          className="pi-page-container"
          ref={(div) => {
            this._element = div;
          }}
        >
          <nav>
            <div className="logo">
              <img src={GeepasLogo} />
            </div>
            <div
              className="nav-title-container"
              style={{ position: "absolute" }}
            >
              {/* <Text style={{ color: "black" }}>PERFORMA INVOICE</Text> */}

              <img
                style={{
                  height: "100px",
                  position: "relative",
                  top: "0px",
                  left: "0%",
                }}
                src={piImage}
              />
            </div>
          </nav>
          <br />
          <br />
          <div className="details-cards-container">
            <div className="details-cards-left-container">
              <div className="details-card">
                <div className="details-card-nav">
                  <p className="card-title">{this.state.data.factory_name}</p>
                </div>
                <div className="details-card-body">
                  <ul className="details-card-list-container">
                    <li className="details-card-list">
                      <p className="list-title">Address:</p>
                      <p className="list-value">
                        {this.state.data.factory_address}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title">Factory Number:</p>

                      <Paragraph
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data.factory_number}
                      </Paragraph>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title">Point of Contact:</p>
                      <p className="list-value">
                        {this.state.data.contact_person_name}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title">Contact’s number:</p>
                      <p className="list-value">
                        {this.state.data.contact_person_mobile_number}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title">Port of Loading:</p>
                      <p className="list-value">
                        {this.state.data.loading_port}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title">Port of Discharge:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) => this.onChange(e, "discharge_port"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["discharge_port"]}
                      </Paragraph>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title">Vessel Details:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) => this.onChange(e, "vessel_details"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["vessel_details"]}
                      </Paragraph>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title">Vessel Final Destination:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) =>
                            this.onChange(e, "vessel_final_destination"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["vessel_final_destination"]}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="details-card">
                <div className="details-card-nav">
                  <p className="card-title">Shipment Details</p>
                </div>
                <div className="details-card-body">
                  <ul className="details-card-list-container">
                    <li className="details-card-list">
                      <p className="list-title-sd">Payment Terms:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) => this.onChange(e, "payment_terms"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["payment_terms"]}
                      </Paragraph>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-sd">Inco Terms:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) => this.onChange(e, "inco_terms"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["inco_terms"]}
                      </Paragraph>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-sd">Delivery Terms:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) => this.onChange(e, "delivery_terms"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["delivery_terms"]}
                      </Paragraph>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-sd">Shipment Lot No:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) =>
                            this.onChange(e, "shipment_lot_number"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["shipment_lot_number"]}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="details-cards-right-container">
              <div className="details-card">
                <div className="details-card-nav">
                  <p className="card-title">Invoice Details</p>
                </div>
                <div className="details-card-body">
                  <ul className="details-card-list-container">
                    <li className="details-card-list">
                      <p className="list-title-id">Invoice Number:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) => this.onChange(e, "invoice_number"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["invoice_number"]}
                      </Paragraph>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-id">Invoice Date:</p>
                      <Paragraph
                        editable={{
                          onChange: (e) => this.onChange(e, "invoice_date"),
                        }}
                        className="list-value"
                        style={{ marginBottom: "0px" }}
                      >
                        {this.state.data["invoice_date"]}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="details-card">
                <div className="details-card-nav">
                  <p className="card-title">Bank Details</p>
                </div>
                <div className="details-card-body">
                  <ul className="details-card-list-container">
                    <li className="details-card-list">
                      <p className="list-title-bd">Bank Name:</p>
                      <p className="list-value-bd">
                        {this.state.data.bank_name}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-bd">A/c Number:</p>
                      <p className="list-value-bd">
                        {this.state.data.account_number}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-bd">IFSC Code:</p>
                      <p className="list-value-bd">
                        {this.state.data.ifsc_code}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-bd">SWIFT Code:</p>
                      <p className="list-value-bd">
                        {this.state.data.swift_code}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-bd">Branch Code:</p>
                      <p className="list-value-bd">
                        {this.state.data.branch_code}
                      </p>
                    </li>
                    <li className="details-card-list">
                      <p className="list-title-bd">Address:</p>
                      <p className="list-value-bd">
                        {this.state.data.bank_address}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              {/* {this.state.data && this.state.data.important_notes && */}

              <div className="details-card">
                <div className="details-card-nav">
                  <p className="card-title">Terms & Conditions</p>
                </div>
                <div className="details-card-body important-notes-container">
                  <div id="tnc"></div>

                  {/* <Paragraph
                    style={{ marginBottom: "6px", color: "black", lineHeight: "15px" }}
                  >
                    {this.state.data.important_notes}
                  </Paragraph> */}

                  {/* <Paragraph
      style={{ marginBottom: "6px", color: "black", lineHeight: "15px" }}
    >
      If there is no note added, this box will not appear.
      </Paragraph> */}
                </div>
              </div>
              {/* } */}
            </div>
          </div>
          <div className="product-table-container">
            <div className="product-table">
              <div className="product-table-head">
                <div
                  className="product-table-head-serial-container center-element"
                  style={{ textAlign: "center" }}
                >
                  <p className="product-table-head-text">Sr</p>
                </div>
                <div className="product-table-head-products-container">
                  <p className="product-table-head-text">Products</p>
                </div>
                <div className="product-table-head-quantity-container center-element">
                  <p className="product-table-head-text">Qty</p>
                </div>
                <div className="product-table-head-unit-container center-element">
                  <p className="product-table-head-text">Unit</p>
                </div>
                <div className="product-table-head-price-container center-element">
                  <p
                    className="product-table-head-text"
                    style={{ textAlign: "center" }}
                  >
                    Price (AED)
                  </p>
                </div>
                <div className="product-table-head-amount-container center-element">
                  <p className="product-table-head-text">Amount</p>
                </div>
              </div>

              {this.state.data &&
                this.state.data.unit_proforma_invoice_list &&
                this.state.data.unit_proforma_invoice_list.map(
                  (item, index) => {
                    return (
                      <div key={index} className="product-table-body">
                        <div className="product-table-head-serial-container center-element">
                          <p className="product-table-head-text">{index + 1}</p>
                        </div>
                        <div className="product-table-products-container">
                          <div className="img-container">
                            <img src={`${serverUrl}${item.image_url}`} />
                          </div>
                          <div className="label-container">
                            <div className="details-card-list">
                              <p className="list-key-big-table">Prod Name:</p>
                              <p className="list-value-big-table">
                                {item.product_name}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">Item Number:</p>
                              <p className="list-value-big-table">
                                {item.seller_sku}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">Brand Name:</p>
                              <p className="list-value-big-table">
                                {item.brand_name}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">Fact Number:</p>
                              <p className="list-value-big-table">FA1203</p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">Size:</p>
                              <p className="list-value-big-table">
                                {item.size}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">Weight:</p>
                              <p className="list-value-big-table">
                                {item.weight}
                              </p>
                            </div>
                          </div>

                          <div className="label-container">
                            <div className="details-card-list">
                              <p className="list-key-big-table">ThickNess:</p>
                              <p className="list-value-big-table">
                                {item.thickness}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">Design:</p>
                              <p className="list-value-big-table">
                                {item.design}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">PKG M CTN:</p>
                              <p className="list-value-big-table">
                                {item.pkg_m_ctn}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">P CTN CBM:</p>
                              <p className="list-value-big-table">
                                {item.p_ctn_cbm}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">PKG Inner:</p>
                              <p className="list-value-big-table">
                                {item.pkg_inner}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">TTL CTN:</p>
                              <p className="list-value-big-table">
                                {item.ttl_ctn}
                              </p>
                            </div>
                            <div className="details-card-list">
                              <p className="list-key-big-table">TTL CBM:</p>
                              <p className="list-value-big-table">
                                {item.ttl_cbm}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="product-table-head-quantity-container center-element">
                          <p className="product-table-head-text">
                            {item.quantity}
                          </p>
                        </div>
                        <div className="product-table-head-unit-container center-element">
                          <p className="product-table-head-text">
                            {item.quantity_meteric}
                          </p>
                        </div>
                        <div className="product-table-head-price-container center-element">
                          <p className="product-table-head-text">
                            {item.price}
                          </p>
                        </div>
                        <div className="product-table-head-amount-container center-element">
                          <p className="product-table-head-text">
                            {item.amount}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )}

              <div className="product-table-footer">
                <div className="label-container">
                  <p className="table-footer-label">Amount Chargeable:</p>
                  <p className="table-footer-label">Total Quantity:</p>
                  <p className="table-footer-label">Total Price:</p>
                </div>
                <div className="value-container">
                  <p className="list-value-footer">
                    {this.state.data.total_amount_in_words}
                  </p>
                  <p className="list-value-footer">
                    {this.state.data.total_quantity}
                  </p>
                  <p className="list-value-footer">{`AED ${this.state.data.total_amount}`}</p>
                </div>
              </div>
            </div>
          </div>

          {/* {this.state.pdfGenerating &&  */}
          <div className="signature-container prevent-split">
            <div className="signature-left-container">
              <div className="signature-title-container">
                <p className="signature-title">Signature</p>
              </div>
              <div className="signature-content"></div>
            </div>
            <div className="signature-right-container">
              <div className="signature-title-container">
                <p className="signature-title">Consignee Signature</p>
              </div>
            </div>
          </div>
          {/* } */}

          <div className="product-specification-container prevent-split">
            {/* <div className="product-specification-nav">
    <div className="product-details">
      <p className="product-table-head-text">Product Details</p>
    </div>
    <div className="specification">
      <p className="product-table-head-text">Specifications</p>
    </div>
    <div className="other-details">
      <p className="product-table-head-text">Other Details</p>
    </div>
  </div> */}
            {/* 
  <div className="product-specification-content">
    <div className="product-image-container">
      <img src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/GSB44023UK2001_jWfTzO0.jpg1" />
    </div>
    <div className="product-specification-container-1">
      <div className="product-content-box-1">
        <div className="left-box">
          <p>Product Name:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">
            500 ml, Cream Colour Kettle, Liquid Cooled and Automatic
        </p>
        </div>
      </div>
      <div className="product-content-box-1">
        <div className="left-box">
          <p>Item Number:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">GP1231</p>
        </div>
      </div>
      <div className="product-content-box-1">
        <div className="left-box">
          <p>Brand Name:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">Yonex</p>
        </div>
      </div>
      <div className="product-content-box-1">
        <div className="left-box">
          <p>Factory Number:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">FA1203</p>
        </div>
      </div>
    </div>

    <div className="product-specification-container-2">
      <div className="product-content-box-2">
        <div className="left-box">
          <p>Size:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">Medium</p>
        </div>
      </div>
      <div className="product-content-box-2">
        <div className="left-box">
          <p>Weight:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">13</p>
        </div>
      </div>
      <div className="product-content-box-2">
        <div className="left-box">
          <p>Thickness:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">NA</p>
        </div>
      </div>
      <div className="product-content-box-2">
        <div className="left-box">
          <p>Design:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">Test</p>
        </div>
      </div>
    </div>
    <div className="product-specification-container-3">
      <div className="product-content-box-2">
        <div className="left-box">
          <p>PKG M CTN:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">18.0</p>
        </div>
      </div>
      <div className="product-content-box-2">
        <div className="left-box">
          <p>P CTN CBM:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">19.5</p>
        </div>
      </div>
      <div className="product-content-box-2">
        <div className="left-box">
          <p>PKG Inner:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">15.2</p>
        </div>
      </div>
      <div className="product-content-box-2">
        <div className="left-box">
          <p>TTL CTN:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">14.8</p>
        </div>
      </div>
      <div className="product-content-box-2">
        <div className="left-box">
          <p>TTL CBM:</p>
        </div>
        <div className="right-box">
          <p className="right-box-text">18.1</p>
        </div>
      </div>
    </div>
  </div> */}

            {/* {this.state.data && this.state.data.terms_and_condition && */}
            {/* <div className="instructions-container prevent-split">
              <div className="details-card-nav">
                <p className="card-title">Terms & Condition</p>
              </div>
              <div className="details-card-body important-notes-container">


                <Paragraph
                  style={{ marginBottom: "6px", color: "black", lineHeight: "16px" }}
                >
                  {this.state.data.terms_and_condition}
                </Paragraph> */}

            {/* <p className="card-title">Packaging Standard:</p>
      <ul className="important-notes-lists">
        <li>
          <Paragraph
            style={{ marginBottom: "6px", color: "black", lineHeight: "16px" }}
          >
            Export carton must be 5 layer carton with minimum board GSM
            of 1300 as standard (3 layer will not be accepted). Kraft
            Liner preferred.
                          </Paragraph>

        </li>
        <li>
          <Paragraph
            style={{ marginBottom: "6px", color: "black", lineHeight: "16px" }}
          >
            Carton must be free of any humidity and should hold all
            products inside it without damage.
                          </Paragraph>

        </li>
        <li>
          <Paragraph
            style={{ marginBottom: "6px", color: "black", lineHeight: "16px" }}
          >
            Condition 3
                          </Paragraph></li>
        <li>
          <Paragraph
            style={{ marginBottom: "6px", color: "black", lineHeight: "16px" }}
          >
            Condition 4
                          </Paragraph></li>
      </ul> */}
            {/* </div> */}
          </div>
          {/* } */}
        </div>
        {/* </div> */}
        {/* </PDFExport> */}
      </React.Fragment>
    );
  }

  exportPDFWithComponent = () => {
    this.setState(
      {
        pdfGenerating: true,
      },
      () => {
        this.pdfExportComponent.save();
        setTimeout(() => {
          this.setState({
            pdfGenerating: false,
          });
        }, 1000);
      }
    );
  };

  exportPDFWithMethod = () => {
    this.setState(
      {
        pdfGenerating: true,
      },
      () => {
        drawDOM(this._element, {
          paperSize: "A4",
          multiple: "true",
          margin: "0.5cm",
          scale: 0.7,
          keepTogether: ".prevent-split",
        })
          .then((group) => {
            return exportPDF(group);
          })
          .then((dataUri) => {
            let base64URI = dataUri;
            //.log(base64URI);
            saveAs(dataUri, "export.pdf");
            this.handleUpload(base64URI);
          });

        setTimeout(() => {
          this.setState({
            pdfGenerating: false,
          });
        }, 1000);
      }
    );
  };
}
