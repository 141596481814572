import React, { Component } from "react";
import { Row, Col, Avatar, Typography, Icon, Table, Modal, Button } from "antd";
import { Ellipsis } from "react-spinners-css";
import ReactJson from "react-json-view";

import JSONViewer from "react-json-viewer";
import styled from "styled-components";
import "./activity-history-table.scss";
const { Text } = Typography;
const { Column, ColumnGroup } = Table;

export default class ActivityHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, changes: {} };
  }
  showModal = changes => {
    this.setState({
      visible: true,
      changes
    });
  };
  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };
  render() {

    return (
      <React.Fragment>
        {/* <Table
          scroll={{ x: 400 }}
          columns={columns}
          dataSource={this.props.tableDataList && this.props.tableDataList}
          pagination={false}
          className="activity-history-table"

        /> */}
        <Table
          scroll={{ x: 600 }}
          dataSource={this.props.tableDataList && this.props.tableDataList}
          pagination={false}
          className="admin-activity-history-table"
        >
          <Column title="Created" dataIndex="created_date" key="created_date" />
          <Column
            title="Resource"
            dataIndex="identifier"
            key="identifier"
            render={identifier => {
              return (
                <React.Fragment>
                  <Text>{identifier.name}</Text>
                  <br />
                  <Text>{identifier.seller_sku}</Text>
                </React.Fragment>
              );
            }}
          />
          <Column title="Action" dataIndex="action" key="action" />
          {/* <Column
            title="Changes"
            dataIndex="changes"
            key="changes"
            render={changes => {
              return (
                <Button onClick={() => this.showModal(changes)}>view</Button>
              );

            }}
          /> */}
        </Table>

        <Modal
          title="Changes"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="60vw"
        >
          <Row
            gutter={[12, 12]}
            style={{
              backgroundColor: "#36404A",
              padding: "8px",
              marginBottom: "10px"
            }}
          >
            <Col span={8}>
              <Text >Name</Text>
            </Col>
            <Col span={8}>
              <Text >
                Old Value
              </Text>
            </Col>
            <Col span={8}>
              <Text >
                New Value
              </Text>
            </Col>
          </Row>
          {Object.keys(this.state.changes).map(e => {
            // 
            let old = this.state.changes[e][0];
            let neww = this.state.changes[e][1];
            // old = JSON.stringify(old);
            // neww = JSON.stringify(neww);
            // if (typeof old === "string") old = JSON.parse(old);
            // if (typeof neww === "string") neww = JSON.parse(neww);

            // old = JSON.parse(old);
            return (
              <React.Fragment>
                <Row
                  gutter={[12, 12]}
                  style={{
                    backgroundColor: "#36404A",
                    padding: "20px",
                    marginBottom: "10px"
                  }}
                >
                  <Col span={8}>
                    <Text className="text-color3">
                      {e}
                    </Text>
                  </Col>
                  <Col span={8} className="json-viewer-container-old">
                    {/* <JSONViewer style={{ overflow: "scroll" }} json={old} /> */}
                    <Text>{old}</Text>
                  </Col>
                  <Col span={8} className="json-viewer-container-new">
                    {/* <JSONViewer style={{ overflow: "scroll" }} json={neww} /> */}
                    <Text>{neww}</Text>
                  </Col>
                </Row>
                {/* <Text></Text>
                <br />
                <br />
                <Text>old</Text>
                <br />
                
                <br />
                <Text>new</Text> <br />
                 <br /> */}
              </React.Fragment>
            );
          })}
        </Modal>
      </React.Fragment>
    );
  }
}
