import React, { Component } from "react";
import { Card, Row, Col, Typography, Icon, message } from "antd";
import "./cards.scss";

import isDev from "../../../server";
const serverUrl = isDev();

const { Text } = Typography;

const { Meta } = Card;

class FlyerCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <React.Fragment>
        {this.props.data && (
          <a
            href={`${serverUrl}/flyer/${this.props.data.flyer_pk}`}
            target="_blank"
          >
            <Card bordered className="categoryproductcard">
              <Row gutter={[16, 12]}>
                <Col sm={24}>
                  <img
                    src={this.props.data.flyer_image}
                    style={{ objectFit: "scale-down", maxHeight: "200px" }}
                  />
                </Col>
                <Col sm={24}>
                  <Text type="secondary">{this.props.data.flyer_name}</Text>
                </Col>
              </Row>
            </Card>
          </a>
        )}
      </React.Fragment>
    );
  }
}

export default FlyerCards;
