import React, { Component } from "react";
import { FilterBtn, ProductDetailsTitle } from "./styled-components-library";
// import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import ListTable from "../components/listtable/ListTable";
import { Row, Col, Breadcrumb, message, Button, Icon, Modal, Select, Typography, Radio, Tooltip } from "antd";
import "./channelwise.scss";
import Export from "../components/modals/export/Export";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { fetchAll, fetchExportList, addTags } from "../../actions/exports";
import ChannelWiseProductListFilterModal from "../components/modals/channelwiseproductlistmodal/ChannelWiseProductListFilterModal";

//props
//
import isDev from "../../server";
import axios from "axios";

import UploadModal from "./upload/UploadModal";

const serverUrl = isDev();

const { Text } = Typography;
const { Option } = Select;

class ChannelWiseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOnBackend: false,
      uploadModal: false,
      uploadType: "search",
      fileListSearch: [],
      fileListPrice: [],
      fileListStock: [],
      checkedSelect: [],
      loadingPublishPrice: false,
      loadingPublicStock: false,
      matchingDrawer : false
    };
  }

  checkedUpdate = (list) => {
    this.setState(prevState => ({
      checkedSelect: list
    }))
  }

  handlePublish = () => {

    this.setState({
      loadingPublicStock: true,
      loadingPublishPrice: true
    })

    let flag = false;
    let productList = this.props.channelwiseState.products.products;
    console.log(productList);

    let product_pk_list = [];
    for (let i = 0; i < productList.length; i++) {
      console.log(productList[i].status , "SSS");
      if (productList[i].status == "Active" && this.state.checkedSelect[productList[i]["product_pk"]]) {
        flag = true;
        product_pk_list.push(productList[i].product_pk);
      }
      else if(this.props.channelname == "Noon" && productList[i].status == "Listed" && this.state.checkedSelect[productList[i]["product_pk"]])
      {
        flag = true;
        product_pk_list.push(productList[i].product_pk);
      }
    }

    if (!flag) {
      message.error("No active products selected");
      this.setState({
        loadingPublicStock: false,
        loadingPublishPrice: false
      })
      return;
    }

    console.log(product_pk_list);

    const data = {
      product_pk_list,
    }

    let apiname1 = "noon";
    let apiname2 = "noon";
    switch (this.props.channelname) {
      case "Amazon UK":
        apiname1 = "mws"
        break;
      case "Amazon UAE":
        apiname1 = "mws/push-products-price-amazon-uae"
        apiname2 = "mws/push-products-inventory-amazon-uae"
        break;
      case "Ebay":
        apiname1 = "ebay"
        break;
      default:
        apiname1 = "noon-integration/push-price";
        apiname2 = "noon-integration/push-stock";
    }



    axios.post(`${serverUrl}/${apiname1}/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      }
    }).then(response => {
      this.setState({
        loadingPublishPrice: false
      })
      if (response.data.status == 200) {
        if(this.props.channelname == "Noon")
        message.success('Prices are successfully pushed!')
        else
        message.success(`Prices are successfully pushed, you can check reports in Reports Page for feed Product ID - ${response.data.feed_submission_id}`);
      }
      else if(response.data.status === 405)
      {
        message.info("You are not authorised!")
      }
      else {
        message.error("Internal Server Error in price update!");
      }
    });
    axios.post(`${serverUrl}/${apiname2}/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      }
    }).then(response => {
      this.setState({
        loadingPublicStock: false
      })
      if (response.data.status == 200) {
        if(this.props.channelname == "Noon")
        message.success('Stock are successfully pushed!')
        else
        message.success(`Stock are successfully pushed, you can check reports in Reports Page for feed Product ID - ${response.data.feed_submission_id}`)
      }
      else if(response.data.status === 405)
      {
        message.info("You are not authorised!")
      }
      else {
        message.error("Internal Server Error in stock update!");
      }
    });
  }


  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleChangeSearchTags = (e) => {
    this.props.fetchAll(
      this.props.channelname,
      this.props.channelwiseState.filters,
      e
    );
    this.props.addTags(e);
  };

  componentDidMount = () => {
    this.props.drawerOff();

    this.props.fetchAll(
      this.props.channelname,
      this.props.channelwiseState.filters,
      this.props.channelwiseState.tags
    );
    this.props.fetchExportList();
  };

  componentWillReceiveProps = (props) => {
    if (this.props.channelname !== props.channelname) {
      props.drawerOff();
      props.fetchAll(
        props.channelname,
        this.props.channelwiseState.filters,
        this.props.channelwiseState.tags
      );
      props.fetchExportList();
    }
  };

  selectOnBackend = (val) => {
    this.setState({ selectOnBackend: val });
  };


  handleOkUploadModal = () => {
    this.setState({
      uploadModal: false
    })
  }

 

  render() {

    const radioStyle = {
      display: "block",
    };

    // channelname = "Amazon UK"

    console.log(this.props.channelname);

    return (
      <React.Fragment>
        <div className="products-container-2">
          <div className="breadcrumb-row-parent">
            <Row
              type="flex"
              align="middle"
              style={{ backgroundColor: "#313841" }}
            >
              <Col span={12}>
                <ProductDetailsTitle level={4}>
                  {this.props.channelname}
                </ProductDetailsTitle>
              </Col>
              <Col span={12}>
                <Row
                  className="breadcrumb-row"
                  type="flex"
                  align="middle"
                  justify="end"
                  style={{ padding: "20px 0", backgroundColor: "#313841" }}
                >
                  <Breadcrumb separator="">
                    <Breadcrumb.Item>eCommerce</Breadcrumb.Item>
                    <Breadcrumb.Separator>
                      <Icon type="right" className="breadcrumb-icon" />
                    </Breadcrumb.Separator>
                    <Breadcrumb.Item href="">
                      {this.props.channelname}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Row>
              </Col>
            </Row>
          </div>
          <Row type="flex" gutter={[12, 12]} align="middle" style={{ position: 'relative', top: '20px', left: '1.5em', marginBottom: '25px' }}>
            {/* {
              this.props.channelname == "Amazon UAE" &&
              <React.Fragment>
                <Col lg={4} sm={8}>
                  <Link to={`/publish-products/${this.props.codeChannelName}`}>
                    <Button style={{ width: "100%", backgroundColor: "#4938d7", border: "none" }} type="primary" ><Icon type="amazon" />Publish Products</Button>
                  </Link>
                </Col>

                <Col lg={4} sm={8}>
                  <Link to={`/pushing-inventory/${this.props.codeChannelName}`}>
                    <Button style={{ width: "100%", backgroundColor: "#4938d7", border: "none" }} type="primary" ><Icon type="amazon" />Update Stock</Button>
                  </Link>
                </Col>

                <Col lg={4} sm={8}>
                  <Link to={`/pushing-prices/${this.props.codeChannelName}`}>
                    <Button style={{ width: "100%", backgroundColor: "#4938d7", border: "none" }} type="primary" ><Icon type="amazon" />Update Price</Button>
                  </Link>
                </Col>
              </React.Fragment>
            } */}
          </Row>
          <Row className="table-container-parent" style={{ paddingTop: "0px" }}>
            <Row type="flex" align="middle" style={{ marginBottom: "20px" }}>
              <Col lg={24} sm={24}>
                <Row type="flex" gutter={[12, 12]} align="middle">
                  <Col lg={3} sm={8}>
                    {this.props.channelwiseState.products.total_products !==
                      undefined ? (
                        <Button style={{ width: "100%" }} type="primary" ghost>
                          Results:{" "}
                          {this.props.channelwiseState.products.total_products}
                        </Button>
                      ) : (
                        <Button type="primary" loading ghost></Button>
                      )}
                  </Col>
                  <Col lg={3} sm={8}>
                    <ChannelWiseProductListFilterModal
                      channelname={this.props.channelname}
                    />
                  </Col>
                      
                    {
                      this.props.channelname != "Ebay" &&
                      <Col lg={3} sm={24}>
                      <Button style={{ width: "100%", background: "blue", color: "white", border: "none" }} icon="upload" onClick={() => this.setState({ uploadModal: true })}>
                        Upload
                      </Button>
                    </Col>
                    }

                  <Col lg={(this.props.channelname == "Noon" || this.props.channelname == "Amazon UAE" || this.props.channelname == "Amazon UK") ? 9 : 15} sm={24}>
                    <Select
                      dropdownClassName="search-chips"
                      dropdownMatchSelectWidth="false"
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder={[<Icon type="search" />, " Search here..."]}
                      onChange={this.handleChangeSearchTags}
                      value={this.props.channelwiseState.tags}
                    ></Select>
                  </Col>
                  <Col lg={3} sm={8}>
                    <Button
                      type="danger"
                      style={{ width: "100%" }}
                      icon={
                        this.props.channelwiseState &&
                          this.props.channelwiseState.loading
                          ? "loading"
                          : "export"
                      }
                      onClick={this.showModal}
                    >
                      Export
                    </Button>
                  </Col>
                  {
                    this.props.channelname == "Noon" &&
                    <Col lg={3} sm={8}>
                      <Tooltip title="Publish price and stock on Noon">
                        <Button
                          type="primary"
                          style={{ width: "100%", background: "#FBE92E", border: "none", color: "black" }}
                          // icon={
                          //   this.props.channelwiseState &&
                          //     this.props.channelwiseState.loading
                          //     ? "loading"
                          //     : "export"
                          // }
                          onClick={this.handlePublish}
                          loading={this.state.loadingPublicStock || this.state.loadingPublishPrice}
                          disabled={this.state.loadingPublicStock || this.state.loadingPublishPrice}
                        >
                          {
                            <React.Fragment>
                              <img style={{ width: "20px", position: "relative", bottom: "1px" }} src="https://media.glassdoor.com/sqll/1669856/noon-squarelogo-1530272609169.png" /><Text
                                style={{ color: "black", position: "relative", top: "1px", fontSize: "13px", left: "2px" }}> Publish</Text>
                            </React.Fragment>
                          }
                        </Button>
                      </Tooltip>
                    </Col>
                  }

                  {
                   ( this.props.channelname == "Amazon UAE" || this.props.channelname == "Amazon UK") && !this.state.matchingDrawer &&
                    <Col lg={3} sm={8}>
                      <Tooltip title="Publish price and stock on Amazon">
                        <Button
                          type="primary"
                          icon="amazon"
                          style={{ width: "100%", background: "#F3A847", border: "none", color: "white" }}
                          // icon={
                          //   this.props.channelwiseState &&
                          //     this.props.channelwiseState.loading
                          //     ? "loading"
                          //     : "export"
                          // }
                          onClick={this.handlePublish}
                          loading={this.state.loadingPublicStock || this.state.loadingPublishPrice}
                          disabled={this.state.loadingPublicStock || this.state.loadingPublishPrice}
                        >
                          {
                            <Text style={{ color: "white" }}>
                              Publish
                            </Text>
                          }
                        </Button>
                      </Tooltip>
                    </Col>
                  }

                </Row>
              </Col>
            </Row>
            <ListTable
              total_products={
                this.props.channelwiseState.products.total_products
              }
              channelName={this.props.channelname}
              selectOnBackend={this.selectOnBackend}
              checkedUpdate={this.checkedUpdate}
              updateMatchingDrawer = {(val) => this.setState({
                matchingDrawer : val
              })}
            />
          </Row>
        </div>
        

        <Modal
          title="Upload"
          visible={this.state.uploadModal}
          onOk={this.handleOkUploadModal}
          onCancel={() => this.setState({ uploadModal: false })}
          closable={false}
          destroyOnClose = {true}
        >

            <UploadModal channelname = {this.props.channelname} uploadModalFalse = {() => {this.setState({uploadModal : false})}}/>
       
        </Modal>

        {/* Modal */}
        <Modal
          title="Basic Modal"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Export
            exportlist={this.props.channelwiseState.exportList.export_list}
            closeModal={() => this.setState({ visible: false })}
            channelname={this.props.channelname}
            select_all={this.state.selectOnBackend}
          />
        </Modal>
      </React.Fragment >
    );
  }
}

const mapStateToProps = ({ channelwise }) => {
  return {
    ...channelwise,
  };
};

export default connect(mapStateToProps, { fetchAll, fetchExportList, addTags })(
  ChannelWiseView
);
