import React, { Component } from "react";
import { Row, Col, Menu, Dropdown, Icon } from "antd";

import "./image-manager.scss";
import UploadImage from "../upload-image/UploadImage";

class ImageManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSelected: "ALL IMAGES",
      hide: false,
      imagesData: {
        mainImages: null,
        subImages: null
      }
    };
  }

  onClick = item => {
    let str = item.item.props.children[0];
    this.setState({ itemSelected: str });
  };

  render() {

    const menu = (
      <Menu onClick={this.onClick}>
        {this.props.images &&
          Object.keys(this.props.images).map((key, index) => (
            <Menu.Item key={index}>
              {key
                .split("_")
                .join(" ")
                .toUpperCase()}{" "}
              ({this.props.images[key] && this.props.images[key].length})
            </Menu.Item>
          ))}
      </Menu>
    );

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col sm={24}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                style={{ color: "white" }}
                className="ant-dropdown-link"
                href="#"
              >
                {this.state.itemSelected} <Icon type="down" />
              </a>
            </Dropdown>
          </Col>
          <Col sm={24}>
            <UploadImage
              fileList={
                this.props.images &&
                this.props.images[
                this.state.itemSelected
                  .split(" ")
                  .join("_")
                  .toLowerCase()
                ]
              }
              hideEmpty={this.hideEmpty}
              showUploadList={true}
              scroll={true}
              category={this.state.itemSelected
                .split(" ")
                .join("_")
                .toLowerCase()}
              product_pk={this.props.product_pk}
              channel_name={this.props.channel_name}
              fetchImagesOnly={this.props.fetchImagesOnly}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ImageManager;
