import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row, Col, Select, Switch, Badge, Radio, Typography, Checkbox } from "antd";
import { handleFilterChange, fetchProductsByTags } from "../../actions/dealsHubProducts";
import styled from "styled-components";
import isDev from "../../server";
const serverUrl = isDev();
const FilterBtn = styled(Button)`
  && {
    background-color: #1abc9c;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #1abc9c;
      color: white;
      border: none;
    }
  }
`;



const { Option } = Select
const { Text } = Typography

export default function DealshubAdminProductsFilterModal({ tags , locationUuid }) {

    const [visible, setVisible] = useState(false);
    // const useFilters = () => useSelector(state => state.dealsHubProducts.dealsHubProduct.filters, []);

    const filters = useSelector(state => state.dealsHubProducts.dealsHubProduct.filters);

    //.log(filters, "dbhsdb")

    const dispatch = useDispatch();

    //.log(dispatch);

    const handleOk = () => {
        dispatch(fetchProductsByTags(tags, filters , null , locationUuid));
        setVisible(false);
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const [brandList, setBrandList] = useState([]);
    useEffect(() => {
        fetch(`${serverUrl}/fetch-brands/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setBrandList(data.brand_list);
            });
    }, [])

    const handleImageSelect = (e) => {
        dispatch(handleFilterChange({
            ...filters,
            "has_image": e.target.value
        }));
    }

    const handleBrandSelect = (e) => {
        dispatch(handleFilterChange({
            ...filters,
            "brand_name": e.target.value
        }));
    }

    const handlePriceSort = (e) => {
        dispatch(handleFilterChange({
            ...filters,
            "price_sort": e
        }));
    }

    const handleChangeStatus = (e) => {
        dispatch(handleFilterChange({
            ...filters,
            "active_status": e.target.value
        }));
    }

    const handleChangeStock = (e) => {
        dispatch(handleFilterChange({
            ...filters,
            "stock": e.target.value
        }));
    }

    //.log(filters, "dsds");

    // const [status, setStatus] = useState(false);

    // const []

    const radioStyle = {
        display: "block",
    };

    return (

        <React.Fragment>
            <FilterBtn
                style={{ width: "100%" }}
                type="primary"
                icon="filter"
                onClick={() => setVisible(true)}
                block
            >
                Filter
           </FilterBtn>

            <Modal
                title="Filter"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width="fit-content"
            >
                <Row gutter={[12, 12]}>
                    {/* <Col span={12}>
                        <Select
                            style={{ width: "100%" }}
                            onSelect={handleImageSelect}
                            value={filters.has_image}
                            placeholder="All Images"
                        >
                            <Option value={undefined}>All Images</Option>
                            <Option value={true}>With Images</Option>
                            <Option value={false}>Without Images</Option>
                        </Select>
                    </Col> */}

                    <Col span={12}>
                        <Text> Images </Text><br />
                        <Radio.Group onChange={handleImageSelect} value={filters.has_image}>
                            <Radio style={radioStyle} value={undefined}>
                                <Text style={{ marginRight: "15px" }}>All Images</Text>
                            </Radio>
                            <Radio style={radioStyle} value={true}>
                                <Text style={{ marginRight: "15px" }}>With Images </Text>
                            </Radio>
                            <Radio style={radioStyle} value={false}>
                                <Text style={{ marginRight: "15px" }}>Without Images</Text>
                            </Radio>
                        </Radio.Group>
                    </Col>

                    <Col span={12}>
                        <Text> Brands </Text><br />
                        <Radio.Group onChange={handleBrandSelect} value={filters.brand_name}>
                            <Radio style={radioStyle} value={undefined}>
                                <Text style={{ marginRight: "15px" }}>All Brands</Text>
                            </Radio>
                            {brandList &&
                                brandList.map((brand, index) => {
                                    return <Radio style={radioStyle} value={brand.name}>
                                        <Text style={{ marginRight: "15px" }}>{brand.name}</Text>
                                    </Radio>
                                })}
                        </Radio.Group>
                    </Col>

                </Row>


                <Row gutter={[12, 12]}>

                    {/* <Col span={12}>
                        <Select
                            style={{ width: "100%" }}
                            onSelect={handlePriceSort}
                            value={filters.price_sort}
                            placeholder="Without Sorting"
                        >
                            <Option value={undefined}>Without Sorting</Option>
                            <Option value="low">Low to High</Option>
                            <Option value="high">High to Low</Option>
                        </Select>
                    </Col> */}

                    <Col span={12}>
                        <Text> Status </Text><br />
                        <Radio.Group onChange={handleChangeStatus} value={filters.active_status}>
                            <Radio style={radioStyle} value={undefined}>
                                <Text style={{ marginRight: "15px" }}>All Products</Text>
                            </Radio>
                            <Radio style={radioStyle} value={true}>
                                <Text style={{ marginRight: "15px" }}>Active Products </Text>
                            </Radio>
                            <Radio style={radioStyle} value={false}>
                                <Text style={{ marginRight: "15px" }}>Inactive Products</Text>
                            </Radio>
                        </Radio.Group>
                    </Col>

                    <Col span={12}>
                        <Text> Stock </Text><br />
                        <Radio.Group onChange={handleChangeStock} value={filters.stock}>
                            <Radio style={radioStyle} value={undefined}>
                                <Text style={{ marginRight: "15px" }}>All Products</Text>
                            </Radio>
                            <Radio style={radioStyle} value={true}>
                                <Text style={{ marginRight: "15px" }}>In Stock </Text>
                            </Radio>
                            <Radio style={radioStyle} value={false}>
                                <Text style={{ marginRight: "15px" }}>Out of Stock</Text>
                            </Radio>
                        </Radio.Group>
                    </Col>


                    {/* <Col span={12}>

                        Products : <Switch checkedChildren="active" unCheckedChildren="Inactive" defaultChecked />

                    </Col>

                    <Col span={12}>

                        Stock : <Switch checkedChildren="In Stock" unCheckedChildren="Out of Stock" defaultChecked />

                    </Col> */}
                </Row>
            </Modal>
        </React.Fragment >

    )
}