import React, { Component } from 'react'
import Slider from "react-slick";
import ReactImageMagnify from 'react-image-magnify';


import "./react-slick.scss"

export default class ReactSlick extends Component {

    state = {
        nav1: null,
        nav2: null
    };

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    render() {
        return (
            <div className="react-slick-container">
                <Slider
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    className="slider-preview"
                >

                    {this.props.imageData.map((image, index) => {
                        return (
                            <ReactImageMagnify className="react-imageMagnify" key={index} enlargedImagePosition="beside"
                                enlargedImageContainerDimensions={{ width: '100%', height: '100%' }}
                                enlargedImagePortalId="enlarged-image" style={{ maxHeight: "50vh" }} {...{
                                    smallImage: {
                                        alt: 'product main image',
                                        isFluidWidth: true,
                                        src: image.midimage_url
                                    },
                                    largeImage: {
                                        src: image.main_url,
                                        width: 800,
                                        height: 800
                                    }
                                }} />

                        )
                    })}
                </Slider>

                <Slider
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={4}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    className="slider-bar"
                >
                    {this.props.imageData.map((image, index) => {
                        return (
                            <div className="silder-img-div">
                                <img src={image.midimage_url} alt="" />
                            </div>
                        )
                    })}
                </Slider>
            </div>
        )
    }
}
