import React, { useState, useEffect } from 'react';
import {
	Button,
	Col,
	Icon,
	message,
	Modal,
	Radio,
	Row,
	Typography,
	Upload,
} from 'antd';

const { Text } = Typography;

const UploadBox = ({
	files,
	setFiles,
	displayName,
	fileType,
	setFileType,
	recievedDataType,
	save,
	fileStatus,
	deleteB2BDocument,
	deleteB2BImage
}) => {
	const hiddenRef = React.createRef();

	const [newUpload, setNewUpload] = useState([]);

	useEffect(() => {
		setNewUpload([...files]);
	}, [])

	useEffect(()=>{
		if(save)
			setFiles(newUpload);
	},[save])	

	const customRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};

	const handleUploadClick = () => hiddenRef.current.click();

	const handleRadioChange = ({ target: { value } }) => {
		setFileType(value);
		if (value === recievedDataType) setNewUpload([...files]);
		else setNewUpload([]);
	};

	const handleUploadChange = ({ fileList }) => {
		if(fileType==='PDF' && newUpload.length===1){
			message.error('You can upload one PDF only!');
			return ;
		}
		if(newUpload.length>5){
			message.error('Cannot Upload more than 5 images');
			return ;
		}
		const file = fileList[fileList.length-1];
		if (fileType === 'IMG') {
			if (file?.type?.slice(0, 5) === 'image')
				setNewUpload([...fileList]);
		} else if (fileType === 'PDF') {
			if (file?.type === 'application/pdf')
				setNewUpload([...fileList]);
		}
	};

	const handleUploadChecks = (file) => {
		if (fileType === 'IMG') {
			if (file.type.slice(0, 5) !== 'image')
				message.error(`${file.name} is not an Image`);
			return file.type.slice(0, 5) === 'image';
		} else if (fileType === 'PDF') {
			if (file.type !== 'application/pdf')
				message.error(`${file.name} is not a PDF`);
			return file.type === 'application/pdf';
		}
	};

	const deleteThis = async (file) => {
		if(fileStatus==='Approved'){
			message.info('You cannot delete approved documents!');
			return ;
		}
		if(file.originFileObj) {
			message.success('Item Removed!');
			if(fileType==='IMG')
				setNewUpload(newUpload => newUpload.filter(item => item.uid !== file.uid))
			else
				setNewUpload([]);
			return ;
		}
		const type = displayName==='Vat Certificate' ? 'VAT' : displayName==='Trade License' ? 'TRADE' : 'PASSPORT';
		let res;
		if(fileType==='IMG') res = await deleteB2BImage(file.uid);
		else if(fileType==='PDF') res = await deleteB2BDocument(type);
		if(res.data.status!==200) message.error('Something went wrong!');
		else{
			message.success('Item Removed!');
			if(fileType==='IMG')
				setNewUpload(newUpload => newUpload.filter(item => item.uid !== file.uid))
			else
				setNewUpload([]);
		}
	}


	return (
		<Col span={24} style={{ margin: '10px 0' }}>
			<Text style={{ color: 'white' }}>{displayName}</Text>
			<Col
				span={24}
				style={{
					marginTop: '10px',
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'column',
				}}
			>
				{fileStatus!=='Approved' && <section
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Radio.Group
						name='fileType'
						onChange={handleRadioChange}
						value={fileType}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						<Radio
							value='PDF'
							style={{ color: fileType === 'PDF' ? '#1abc9c' : 'white' }}
						>
							<Icon
								type='file-pdf'
								style={{ fontSize: '24px', marginRight: '6px' }}
							/>
							PDF
						</Radio>
						<Radio
							value='IMG'
							style={{ color: fileType === 'IMG' ? '#1abc9c' : 'white' }}
						>
							<Icon
								type='file-jpg'
								style={{ fontSize: '24px', marginRight: '6px' }}
							/>
							JPEG
						</Radio>
					</Radio.Group>
					{((fileType==='PDF' && newUpload.length<1) || (fileType==='IMG' && newUpload.length<5)) && <Button
						style={{ background: '#1abc9c', color: 'white' }}
						onClick={handleUploadClick}
						icon='upload'
					>
						Upload
					</Button>}
				</section>}
				<br/>
				<section
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}
				>
					<Upload
						name='fileList'
						className='upload-docs-upload-button'
						fileList={newUpload}
						listType='picture-card'
						multiple={fileType==='PDF'?false:true}
						customRequest={customRequest}
						beforeUpload={handleUploadChecks}
						onChange={handleUploadChange}
						onRemove={deleteThis}
					>
						<span ref={hiddenRef}></span>
					</Upload>
				</section>
			</Col>
		</Col>
	);
};

function UploadModal({
	file,
    modal,
	setFile,
	fileType,
	setFileType,
    toggleModal,
	recievedData,
	deleteB2BDocument,
	deleteB2BImage
}) {
	const [save,setSave] = useState(false);

	const { vatCertificateType, passportCopyType, tradeLicenseType } = fileType;
	const { vat, passport, trade } = file;
	const { setVatCertificate, setPassportCopy, setTradeLicense } = setFile;
	const { setVatCertificateType, setPassportCopyType, setTradeLicenseType } = setFileType;

	const handleCancel = () => {
        toggleModal();
	};

	const handleOk = async () => {
		setSave(true);
		setTimeout(()=>{
			setSave(false);
            toggleModal();
		},1500)
	}

	return (
		<React.Fragment>
			<Modal
				title='Upload Your Documents'
				visible={modal}
				destroyOnClose={true}
				onCancel={handleCancel}
				footer={null}
				style={{left:'-200px'}}
			>
				<Row style={{ width: '100%' }}>
					<UploadBox
						files={vat}
						fileType={vatCertificateType}
						setFiles={setVatCertificate}
						setFileType={setVatCertificateType}
						displayName='Vat Certificate'
						fileStatus={recievedData?.fileStatus}
						recievedDataType={recievedData?.vatCertificateType}
						save={save}
						deleteB2BDocument={deleteB2BDocument}
						deleteB2BImage={deleteB2BImage}
					/>
					<UploadBox
						files={trade}
						fileType={tradeLicenseType}
						setFiles={setTradeLicense}
						setFileType={setTradeLicenseType}
						displayName='Trade License'
						fileStatus={recievedData?.fileStatus}
						recievedDataType={recievedData?.tradeLicenseType}
						save={save}
						deleteB2BDocument={deleteB2BDocument}
						deleteB2BImage={deleteB2BImage}
					/>
					<UploadBox
						files={passport}
						fileType={passportCopyType}
						setFiles={setPassportCopy}
						setFileType={setPassportCopyType}
						displayName='Passport Copy'
						fileStatus={recievedData?.fileStatus}
						recievedDataType={recievedData?.passportCopyType}
						save={save}
						deleteB2BDocument={deleteB2BDocument}
						deleteB2BImage={deleteB2BImage}
					/>
					<Col span={24} style={{ textAlign: 'right', marginTop: '50px' }}>
						<Button
							loading={save}
							onClick={async () => {
								handleOk();
							}}
							style={{ backgroundColor: '#1abc9c', border: 'none', color: 'white' }}
						>
							Done
						</Button>
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	);
}

export default UploadModal;
