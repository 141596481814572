import React, { Component } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Checkbox,
  Badge,
  Typography,
  Slider,
  InputNumber,
  message,
  Radio,
  AutoComplete,
  Select
} from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./orders-accounts-manager-filter-modal.scss";
import axios from "axios";
import isDev from 'server';

const serverOmnycommUrl = isDev();

const { Text } = Typography;
const { Option } = Select;


const trackingStatusList = [
  "pending",
  "approved",
  "picked",
  "dispatched",
  "delivered",
  "delivery failed",
  "cancelled",
];
const currencyList = ["AED"];
const shippingMethodList = ["WIG Fleet", "grand gaadi", "SENDEX", "Emirates Post", "pending"];
const paymentTypeList = ["Credit Card", "COD", "Installments"];
const QUANTITY_SLIDER = [0, 100];
const PRICE_SLIDER = [0, 10000];

export default class OrdersAccountsManagerFilterModal extends Component {
  state = {
    visible: false,
    startDate: null,
    endDate: null,
    paymentTypeList: [],
    shippingMethodList: [],
    salesPersonList : [],
    trackingStatusList: [],
    sapStatusFilters:[],
    isOrderOffline: undefined,
    minQty: 0,
    maxQty: 10000,
    minPrice: 0,
    maxPrice: 1000000,
    currencyList: "",
    salesPerson : {
      firstName : "All"
    }
  };

  handleStartDateChange = (date) => {
    console.log(date,'ffff')
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  setTrackingFilters = (checkedValues) => {
    this.setState({ trackingStatusList: checkedValues });
  };

  setShipmentFilters = (checkedValues) => {
    this.setState({ shippingMethodList: checkedValues });
  };
  
  setSapStatusFilters = (checkedValues) => {
    this.setState({sapStatusFilters : checkedValues})
  }

  setPaymentFilters = (checkedValues) => {
    this.setState({ paymentTypeList: checkedValues });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleQuantityChange = (quantityValue, minOrMax) => {
    if (
      quantityValue > QUANTITY_SLIDER[0] &&
      quantityValue < QUANTITY_SLIDER[1]
    ) {
      if (minOrMax === "min") {
        this.setState({ minQty: quantityValue });
      } else {
        this.setState({ maxQty: quantityValue });
      }
    } else {
      message.error("Invalid Quantity Range!");
    }
  };

  changeQuantitySlider = (valuesRange) => {
    this.setState({
      minQty: valuesRange[0],
      maxQty: valuesRange[1],
    });
  };

  handlePriceChange = (quantityValue, minOrMax) => {
    if (quantityValue > PRICE_SLIDER[0] && quantityValue < PRICE_SLIDER[1]) {
      if (minOrMax === "min") {
        this.setState({ minPrice: quantityValue });
      } else {
        this.setState({ maxPrice: quantityValue });
      }
    } else {
      message.error("Invalid Price Range!");
    }
  };

  changePriceSlider = (valuesRange) => {
    this.setState({
      minPrice: valuesRange[0],
      maxPrice: valuesRange[1],
    });
  };

  componentDidMount = () => {
    axios.post(`${serverOmnycommUrl}/dealshub/fetch-oc-sales-persons/`, {
			// "filterStatus": this.state.orderStatusFilter,
			locationGroupUuid : this.props.locationGroupUuid
		  }, {
			headers: {
			  "Content-Type": "application/json",
			  Authorization: `JWT ${localStorage.getItem("token")}`
			}
		  }).then(response => {
			if (response.data.status === 200) {
				this.setState({
					salesPersonList : response.data.salesPersonList
				})
			} else {
			  message.error("Something went wrong in fetching sales persons");
			}
		  });
  }

  componentDidUpdate = (previousProps) => {
    if(previousProps.locationGroupUuid !== this.props.locationGroupUuid) {
      this.setState({
        startDate: null,
        endDate: null,
        paymentTypeList: [],
        shippingMethodList: [],
        salesPersonList : [],
        trackingStatusList: [],
        sapStatusFilters:[],
        isOrderOffline: undefined,
        minQty: 0,
        maxQty: 10000,
        minPrice: 0,
        maxPrice: 1000000,
        currencyList: "",
        salesPerson : {
          firstName : "All"
        }
      })
    }
  }

  handleOk = (e) => {
    this.setState({ visible: false }, () => {
      console.log(this.state.startDate , "ffff");
      this.props.applyFilters({
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        paymentTypeList: this.state.paymentTypeList,
        minQty: this.state.minQty,
        maxQty: this.state.maxQty,
        minPrice: this.state.minPrice,
        maxPrice: this.state.maxPrice,
        currencyList: this.state.currencyList,
        shippingMethodList: this.state.shippingMethodList,
        trackingStatusList: this.state.trackingStatusList,
        isOrderOffline : this.state.isOrderOffline,
        sapStatusList : this.state.sapStatusFilters,
        salesPerson : this.state.salesPerson.username
      });
    });
  };

  handleCancel = (e) => {
    this.setState({ visible: false });
  };

  updateSalesPerson = (e) => {
    if(e == 0)
    this.setState({
      searchString : "",
			salesPerson : {
        firstName : "All",
      }
    })
    else
    this.setState({
			searchString : "",
			salesPerson : this.state.salesPersonList[e-1]
		})
	}

  render() {
    return (
      <div
        className="order-accounts-manager-modal-container"
      // style={{ width: "100%" }}
      >
        {/* <Badge count={this.props.filterCount} showZero> */}
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={this.showModal}
            icon="filter"
            disabled={this.props.someProcessingActive}
            block
          >
            Filter
          </Button>
        {/* </Badge> */}

        <Modal
          title="Filter"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="70vw"
          style={{display: "flex", justifyContent: "center"}}
        >
          <Row type="flex" align="middle" gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Start Date
                      </Text>
                      <DatePicker
                        size={this.props.size}
                        className="custom-datePicker"
                        style={{ backgroundColor: "#36404A", width: "100%" }}
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Text strong type="secondary">
                        End Date
                      </Text>
                      <DatePicker
                        size={this.props.size}
                        className="custom-datePicker"
                        style={{ backgroundColor: "#36404A", width: "100%" }}
                        selected={this.state.endDate}
                        // value={this.props.filterObj.endDate}
                        onChange={this.handleEndDateChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Channels
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox>Amazon UK</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox>Amazon UAE</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox>Dealshub</Checkbox>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Payment Type
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={this.setPaymentFilters}
                        value={this.state.paymentTypeList}
                      >
                        {paymentTypeList.map((status, index) => {
                          return (
                            <Col span={24} key={index}>
                              <Checkbox value={status}>{status}</Checkbox>
                            </Col>
                          );
                        })}
                      </Checkbox.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Shipment
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={this.setShipmentFilters}
                        value={this.state.shippingMethodList}
                      >
                        {shippingMethodList.map((status, index) => {
                          return (
                            <Col span={24} key={index}>
                              <Checkbox value={status}>{status}</Checkbox>
                            </Col>
                          );
                        })}
                      </Checkbox.Group>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Tracking Status
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={this.setTrackingFilters}
                        value={this.state.trackingStatusList}
                      >
                        {trackingStatusList.map((status) => {
                          return (
                            <Col span={24}>
                              <Checkbox value={status}>
                                {!(status === "delivery failed")
                                  ? status.charAt(0).toUpperCase() +
                                  status.substr(1)
                                  : "Delivery Failed"}
                              </Checkbox>
                            </Col>
                          );
                        })}
                      </Checkbox.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
                        
            <Col span = {24}>
                   <Row gutter = {[16,16]}>
                        <Col span = {12}>
                            <Row gutter = {[8,8]}>
                              <Col span={24}>
                                <Text strong type="secondary">
                                   SAP Status
                                </Text>
                              </Col>
                              <Col span={24}>
                                <Checkbox.Group
                                  style={{ width: "100%" }}
                                  onChange={this.setSapStatusFilters}
                                  value={this.state.sapStatusFilters}
                                >
                                 
                                      <Col span={24}>
                                        <Checkbox value="Pending">Pending</Checkbox>
                                      </Col>
                                      <Col span={24}>
                                        <Checkbox value="In GRN">In GRN</Checkbox>
                                      </Col>
                                      <Col span={24}>
                                        <Checkbox value="GRN Conflict">GRN Conflict</Checkbox>
                                      </Col>
                                      <Col span={24}>
                                        <Checkbox value="GRN Done">GRN Done</Checkbox>
                                      </Col>
                                      <Col span={24}>
                                        <Checkbox value="Failed">Failed</Checkbox>
                                      </Col>
                                  
                                </Checkbox.Group>
                              </Col>
                            </Row>
                        </Col>

                        <Col span = {12}>
                            <Row gutter = {[8,8]}>
                            <Col span={24}>
                                <Text strong type="secondary">
                                   Order type
                                </Text>
                              </Col>
                              <Col span={24}>
                                <Radio.Group
                                  style={{ width: "100%" }}
                                  value={this.state.isOrderOffline}
                                  onChange={(e) => {
                                    this.setState({
                                      isOrderOffline : e.target.value 
                                    })
                                  }}
                                >
                                      <Col span={24}>
                                        <Radio value={undefined}>All</Radio>
                                      </Col>
                                      <Col span={24}>
                                        <Radio value={true}>Offline</Radio>
                                      </Col>
                                      <Col span={24}>
                                        <Radio value={false}>Online</Radio>
                                      </Col>
                                </Radio.Group>
                              </Col>
                            </Row>
                        </Col>
                   </Row>     
            </Col>
            
            <Col span = {24}>
                  <Row gutter = {[16,16]}>
                        <Col span = {12}>
                        <Row gutter = {[8,8]}>
                              <Col span={24}>
                                <Text strong type="secondary">
                                   Sales Person
                                </Text>
                              </Col>
                              <Col span={24}>

                                                <AutoComplete onSelect={this.updateSalesPerson} value={`${this.state.salesPerson?.firstName}${this.state.salesPerson?.lastName ? ` ${this.state.salesPerson?.lastName}` : ""}`} style={{ width: "100%", marginBottom: "10px" }}>
                                                        <Option key = {0}>All</Option>
                                                        {this.state.salesPersonList.map((item, index) => {
                                                          return (
                                                            <Option key={index + 1}>{item.firstName} {item.lastName}</Option>
                                                          )
                                                        })}
                                                </AutoComplete>
                              </Col>
                            </Row>
                        </Col>
                  </Row>      
            </Col>
           
            <Col span={12}>
              <Row
                gutter={[16, 16]}
                type="flex"
                align="middle"
                justify="space-between"
              >
                <Col span={24}>
                  <Text strong type="secondary">
                    Quantity
                  </Text>
                </Col>
                <Col span={24}>
                  <Slider
                    range
                    value={[this.state.minQty, this.state.maxQty]}
                    onChange={this.changeQuantitySlider}
                  />
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.minQty}
                        onChange={(value) => {
                          this.handleQuantityChange(value, "min");
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.maxQty}
                        onChange={(value) => {
                          this.handleQuantityChange(value, "max");
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                gutter={[16, 16]}
                type="flex"
                align="middle"
                justify="space-between"
              >
                <Col span={24}>
                  <Text strong type="secondary">
                    Price
                  </Text>
                </Col>
                <Col span={24}>
                  <Slider
                    range
                    max={PRICE_SLIDER[1] + 1}
                    value={[this.state.minPrice, this.state.maxPrice]}
                    onChange={this.changePriceSlider}
                  />
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.minPrice}
                        onChange={(value) => {
                          this.handlePriceChange(value, "min");
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.maxPrice}
                        onChange={(value) => {
                          this.handlePriceChange(value, "max");
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
