import React, { Component } from "react";
import PlaceholderImage from "../../../media/placeholder3.png";
import { connect } from "react-redux";
import {
  Row,
  Avatar,
  Icon,
  Col,
  Typography,
  Card,
  Divider,
  Tag,
  Collapse,
  Select,
  Button,
} from "antd";
import styled from "styled-components";
import "./products-mobile-view.scss";
import { Link } from "react-router-dom";
import ProductListFilterModal from "../modals/product-list-filter-modal/ProductListFilterModal";
import {
  fetchProductList,
  fetchProductListMore,
  fetchProductListfromTags,
  addTags,
  setEmpty
} from "../../../actions";
const { Title } = Typography;
const { Text } = Typography;
const { Option } = Select;

const { Panel } = Collapse;

const ProductTitle = styled(Title)`
  color: #dee2e6 !important;
  margin: 0px !important;
`;

const CardText = styled(Text)`
  color: #8a8a8a;
`;
const CardTextValue = styled(Text)`
  color: #dee2e6;
`;
const CustomDivider = styled(Divider)`
  margin: 0px;
  margin-bottom: 5px;
  background-color: #495561;
`;

const CardCollapse = styled(Collapse)`
  width: 100%;
  border: none;
`;

class ProductsMobileView extends Component {
  state = {};


  handleChangeSearchTags = tags => {
    this.props.dispatch(setEmpty());
    this.props.dispatch(fetchProductList(this.props.filters, tags));
    this.props.dispatch(addTags(tags));
  };

  collapseHeader = (product, isNestoUser) => (
    isNestoUser ? (
      <Row
      style={{
        backgroundColor: "#313841",
        padding: "10px",
        border: "1px solid #495561",
      }}
    >
      <Col span={24} style={{ marginBottom: "10px" }}>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={4}>
            {product &&
              product.front_images[0] ? (
                <Avatar
                  shape="square"
                  style={{ width: "100%", height: "100%" }}
                  src={product.front_images[0].thumbnail_image}
                />
              ) : (
                <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={PlaceholderImage} />
              )}
          </Col>
          <Col span={18}>
            <ProductTitle
              level={4}
              title={product.product_name}
              className="text-ellipsis"
            >
              {product.product_name}
            </ProductTitle>
            <CardText>{product.article_number}</CardText>
            <br />
            <CardText>{product.brand}</CardText>
          </Col>
        </Row>
      </Col>
      <CustomDivider style={{ backgroundColor: "#495561" }} />
      <Col span={24}>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={8}>
            <CardText>Category:</CardText>
            <br />
            <CardText>Status:</CardText>
            <br />
          </Col>
          <Col span={12}>
            <CardTextValue>{product.category}</CardTextValue>
            <br />
            <Tag color="red">
              Inactive
            </Tag>
            {/* <Tag color="green">
              Active :
              {product.products.reduce(
              (acc, obj) => acc + obj.active_channels,
              0
            )}
            </Tag> */}
            <br />
          </Col>
        </Row>
      </Col>
    </Row>
    ) : (
    <Row
      style={{
        backgroundColor: "#313841",
        padding: "10px",
        border: "1px solid #495561",
      }}
    >
      <Col span={24} style={{ marginBottom: "10px" }}>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={4}>
            {product &&
              product.products.length > 0 &&
              product.base_main_images[0] ? (
                <Avatar
                  shape="square"
                  style={{ width: "100%", height: "100%" }}
                  src={product.base_main_images[0].thumbnail_url}
                />
              ) : (
                <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={PlaceholderImage} />
              )}
          </Col>
          <Col span={18}>
            <ProductTitle
              level={4}
              title={product.product_name}
              className="text-ellipsis"
            >
              {product.product_name}
            </ProductTitle>
            <CardText>{product.seller_sku}</CardText>
            <br />
            <CardText>{product.brand_name}</CardText>
          </Col>
        </Row>
      </Col>
      <CustomDivider style={{ backgroundColor: "#495561" }} />
      <Col span={24}>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={8}>
            <CardText>Category:</CardText>
            <br />
            <CardText>Sub-Category:</CardText>
            <br />
            <CardText>Ch. status:</CardText>
            <br />
          </Col>
          <Col span={12}>
            <CardTextValue>{product.category}</CardTextValue>
            <br />
            <CardTextValue>{product.sub_category}</CardTextValue>
            <br />
            <Tag color="red">
              Inactive :
              {product.products.reduce(
              (acc, obj) => acc + obj.inactive_channels,
              0
            )}
            </Tag>
            <Tag color="green">
              Active :
              {product.products.reduce(
              (acc, obj) => acc + obj.active_channels,
              0
            )}
            </Tag>
            <br />
          </Col>
        </Row>
      </Col>
    </Row>
    )
  );
  render() {

    if (this.props.productData)
      return (
        <React.Fragment>
          <div className="products-mobile-card-container" >
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Select
                  dropdownClassName="search-chips"
                  dropdownMatchSelectWidth="false"
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder={[<Icon type="search" />, " Search here..."]}
                  onChange={this.handleChangeSearchTags}
                  value={this.props.tags}
                ></Select>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <ProductListFilterModal />
                  </Col>
                  <Col span={12}>
                    {this.props.productData ? (
                      <Button style={{ width: "100%" }} type="primary" ghost>
                        Results:{" "}
                        {this.props.productData &&
                          this.props.productData.total_products}
                      </Button>
                    ) : (
                        <Button type="primary" loading ghost></Button>
                      )}
                  </Col>
                </Row>
              </Col>
            </Row>

            {this.props.productData
              ? this.props.productData.products.map((product, index) => {
                return (
                  <CardCollapse key={index}>
                    <Panel
                      key="1"
                      showArrow={false}
                      extra={this.collapseHeader(product, this.props.isNestoUser)}
                    >
                      {
                        !this.props.isNestoUser && (
                          product.products.map((productVariant, index) => {
                            return (
                              <Row
                                style={{
                                  backgroundColor: "#313841",
                                  padding: "10px",
                                  border: "1px solid #495561",
                                  marginBottom: "2px",
                                }}
                                key={index}
                              >
                                <Col span={24}>
                                  <Row
                                    type="flex"
                                    justify="space-around"
                                    align="start"
                                  >
                                    <Col span={4}>
                                      {productVariant.main_images[0] ? (
                                        <Avatar
                                          shape="square"
                                          style={{ width: "100%", height: "100%" }}
                                          src={
                                            productVariant.main_images[0]
                                              .thumbnail_url
                                          }
                                        />
                                      ) : (
                                          <Avatar
                                            shape="square"
                                            style={{ width: "100%", height: "100%" }}
                                            src={PlaceholderImage}
                                          />
                                        )}
                                    </Col>
                                    <Col span={18}>
                                      <Link
                                        to={{
                                          pathname: "/product-details",
                                          state: {
                                            productPK: productVariant.product_pk,
                                          },
                                        }}
                                      >
                                        <ProductTitle
                                          level={4}
                                          className="text-ellipsis"
                                        >
                                          {productVariant.product_name}
                                        </ProductTitle>
                                        <CardText>
                                          ID: {productVariant.product_id}
                                        </CardText>
                                      </Link>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            );
                          })
                        )
                      }
                    </Panel>
                  </CardCollapse>
                );
              })
              : ""}
          </div>
        </React.Fragment>
      );
  }
}


const mapStateToProps = ({ productList, user }) => {
  return {
    ...productList,
    ...user
  };
};

export default connect(mapStateToProps)(ProductsMobileView);