import React, { Component } from "react";

import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// import "./image-slider.scss";
export default class ImageSlider extends Component {
  state = {
    nav1: null,
    nav2: null
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
  render() {
    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: false
    // };

    return (
      <React.Fragment>
        <div className="carousel-container">
          <div>
            <Slider
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
              className="slider-preview"
            >

              {this.props.imageData.map((image, index) => {
                return (<div>
                  <img style={{ maxHeight: "50vh" }} src={image.midimage_url} alt="" />
                </div>)
              })}
            </Slider>

            <Slider
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={4}
              swipeToSlide={true}
              focusOnSelect={true}
              className="slider-bar"
            >
              {this.props.imageData.map((image, index) => {
                return (
                  <div className="silder-img-div">
                    <img src={image.midimage_url} alt="" />
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
