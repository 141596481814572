import React from 'react';
import {
    Row,
    Col,
    Typography,
    Checkbox,
    Avatar,
    Tooltip,
    Tag
} from "antd";
import { Link } from "react-router-dom";
import "./orders-account-manager.scss";

const { Text } = Typography;
const genExtra = (str) => (
    <Tag color={str[1]}>{str[0]}</Tag>
);

class OrdersAccountManagerTableBodyTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkboxSelected: false
        }
    }

    componentDidMount = () => {
        this.setState({
            checkboxSelected: this.props.order.checked
        });
    }

    static getDerivedStateFromProps(nextProps, previousState) {
        if (previousState.checkboxSelected != nextProps.order.checked) {
            return { checkboxSelected: nextProps.order.checked };
        } else {
            return null;
        }
    }

    render() {
        return (
            <Row
                className="orders-row"
                type="flex"
                align="middle"
            >
                <Col span={1}>
                    <Checkbox
                        value={this.props.order.uuid}
                        checked={this.state.checkboxSelected}
                        onChange={(e) => {
                            this.setState({
                                checkboxSelected: !this.state.checkboxSelected
                            });
                            this.props.toggleOrderCheck(this.state.checkboxSelected, e.target.value);
                        }}
                    />
                </Col>
                <Col span={2}>
                    <Row>
                        <Col>
                            <Typography.Paragraph
                                ellipsis={{ rows: 1, expandable: false }}
                                className="margin-zero  text-grey"
                            >
                                <Link to={`/order-details/${this.props.order.uuid}`}>
                                    <Tooltip title={this.props.order.orderId}>{this.props.order.orderId}</Tooltip>
                                </Link>
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text className="">{this.props.order.dateCreated}</Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row type="flex" align="middle" gutter={[8, 8]}>
                        <Col span={8}>
                            <Avatar
                                shape="square"
                                // size={68}
                                style={{ width: '100%', height: '100%' }}
                                src={this.props.order.productImageUrl}
                            />
                        </Col>
                        <Col span={16}>
                            <Typography.Paragraph
                                style={{ fontSize: '10px' }}
                                ellipsis={{ rows: 4, expandable: false }}
                                className="margin-zero padding-left text-grey"
                            >
                                <Tooltip title={this.props.order.productName}>{this.props.order.productName}</Tooltip>
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={1}>
                    <Row>
                        <Col span={24}>
                            <Text className="table-head-text">{this.props.order.quantity}</Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={2}>
                    <Text className="table-head-text">{this.props.order.price}</Text>
                </Col>
                <Col span={2}>
                    <Text className="table-head-text">{this.props.order.currency}</Text>
                </Col>
                <Col span={2} className="shipping-method-wrapper">
                    <Text className="table-head-text">
                        {/* {this.props.order.shippingMethod} */}
                        {
                            this.props.order.shippingMethod === 'grand gaadi' ? (
                                <React.Fragment>
                                    {genExtra([this.props.order.shippingMethod, "magenta"])}
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        {
                                            this.props.order.shippingMethod === 'pending' ? (
                                                <React.Fragment>
                                                    {genExtra([this.props.order.shippingMethod, "yellow"])}
                                                </React.Fragment>
                                            ) : (
                                                    <React.Fragment>
                                                        {genExtra([this.props.order.shippingMethod, "blue"])}
                                                    </React.Fragment>
                                                )
                                        }
                                    </React.Fragment>
                                )
                        }
                    </Text>
                </Col>
                <Col span={2}>
                    <Text className="table-head-text">
                        {
                            this.props.order.currentStatus === 'cancelled' || this.props.order.currentStatus === 'delivery failed' ? (
                                <React.Fragment>
                                    {this.props.order.currentStatus === 'cancelled' ? (
                                        <React.Fragment>
                                            {
                                                genExtra(["Cancelled", "red"])
                                            }
                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                {
                                                    genExtra(["Delivery Failed", "red"])
                                                }
                                            </React.Fragment>
                                        )}
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        {
                                            this.props.order.currentStatus === 'pending' ? (
                                                <React.Fragment>
                                                    {
                                                        genExtra([
                                                            this.props.order.currentStatus.charAt(0).toUpperCase() +
                                                            this.props.order.currentStatus.substr(1)
                                                            , "yellow"])
                                                    }
                                                </React.Fragment>
                                            ) : (
                                                    <React.Fragment>
                                                        {
                                                            genExtra([
                                                                this.props.order.currentStatus.charAt(0).toUpperCase() +
                                                                this.props.order.currentStatus.substr(1)
                                                                , "green"])
                                                        }
                                                    </React.Fragment>
                                                )
                                        }
                                    </React.Fragment>
                                )
                        }
                    </Text>
                </Col>
            </Row >
        );
    }
}

export default OrdersAccountManagerTableBodyTemplate;
