import isDev from "../server";
const serverUrl = isDev();

export const fetchAmazonUk = (product_pk) => (dispatch) => {
  const data = {
    product_pk: product_pk,
    channel_name: "Amazon UK"
  };
  fetch(`${serverUrl}/fetch-channel-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({
        type: "FETCH_CHANNEL_PRODUCT_AMAZON_UK",
        payload: data,
      });
    });
};

export const fetchChannelImages = (product_pk, channel_name) => (dispatch) => {
  let api_link = "";
  if (channel_name === "Amazon UK")
    api_link = "amazon-uk";
  else if (channel_name === "Amazon UAE")
    api_link = "amazon-uae";
  else if (channel_name === "Noon") api_link = "noon";
  else api_link = "ebay";

  let redux_dispatch = "";
  if (channel_name === "Amazon UK") redux_dispatch = "SET_IMAGE_AMAZON_UK";
  else if (channel_name === "Amazon UAE")
    redux_dispatch = "SET_IMAGE_AMAZON_UAE";
  else if (channel_name === "Noon") redux_dispatch = "SET_IMAGE_NOON";
  else redux_dispatch = "SET_IMAGE_EBAY";

  const data = {
    product_pk: product_pk,
    channel_name: api_link
  };

  fetch(`${serverUrl}/fetch-channel-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({
        type: redux_dispatch,
        payload: data,
      });
    });
};

export const handelChange = (payload) => {
  return { type: "HANDEL_CHANGE", payload };
};

export const sendFeedBackToChannelForm = (payload) => {
  return { type: "SEND_FEED_BACK_TO_CHANNEL_FORM", payload };
};

export const saveUkForm = (product_pk, inp) => (dispatch) => {
  //.log(inp.channel_product_json, "SAVING THE DATA");
  const data = {
    product_pk: product_pk,
    amazon_uk_product_json: JSON.stringify(inp.channel_product_json),
  };

  fetch(`${serverUrl}/save-channel-product-amazon-uk/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch(sendFeedBackToChannelForm(data.status));
    });
};

///////////////===========================================================================

export const fetchAmazonUae = (product_pk) => (dispatch) => {
  const data = {
    product_pk: product_pk,
    channel_name: "Amazon UAE"
  };
  fetch(`${serverUrl}/fetch-channel-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({
        type: "FETCH_CHANNEL_PRODUCT_AMAZON_UAE",
        payload: data,
      });
    });
};

export const handelChangeUae = (payload) => {
  return { type: "HANDEL_CHANGE_UAE", payload };
};

export const saveUaeForm = (product_pk, inp) => (dispatch) => {
  //.log(inp, "SAVING THE DATA");
  const data = {
    product_pk: product_pk,
    amazon_uae_product_json: JSON.stringify(inp.channel_product_json),
  };

  fetch(`${serverUrl}/save-channel-product-amazon-uae/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch(sendFeedBackToChannelForm(data.status));
    });
};

export const fetchEbay = (product_pk) => (dispatch) => {
  const data = {
    product_pk: product_pk,
    channel_name: "Ebay"
  };
  fetch(`${serverUrl}/fetch-channel-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({
        type: "FETCH_CHANNEL_PRODUCT_EBAY",
        payload: data,
      });
    });
};

export const handelChangeEbay = (payload) => {
  return { type: "HANDEL_CHANGE_EBAY", payload };
};

export const saveEbayForm = (product_pk, inp) => (dispatch) => {
  //.log(inp.channel_product_json, "SAVE THE DATA");
  const data = {
    product_pk: product_pk,
    ebay_product_json: JSON.stringify(inp.channel_product_json),
  };

  fetch(`${serverUrl}/save-channel-product-ebay/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch(sendFeedBackToChannelForm(data.status));
    });
};

export const fetchNoon = (product_pk) => (dispatch) => {
  const data = {
    product_pk: product_pk,
    channel_name: "Noon"
  };
  fetch(`${serverUrl}/fetch-channel-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({
        type: "FETCH_CHANNEL_PRODUCT_NOON",
        payload: data,
      });
    });
};

export const handelChangeNoon = (payload) => {
  return { type: "HANDEL_CHANGE_NOON", payload };
};

export const saveNoonForm = (product_pk, inp) => (dispatch) => {
  const data = {
    product_pk: product_pk,
    noon_product_json: JSON.stringify(inp.channel_product_json),
  };
  fetch(`${serverUrl}/save-channel-product-noon/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch(sendFeedBackToChannelForm(data.status));
    });
};
