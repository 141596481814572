import { Icon, Row, Typography, Select, Button, Table } from "antd";
import styled from "styled-components";
const { Title } = Typography;
const { Text } = Typography;


export const PermissionListIcon = styled(Icon)`
  color: #1ABC9C;
  font-size: 10px;
`;

export const PermissionListRow = styled(Row)`
    margin-bottom: 5px
`;