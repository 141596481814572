import React from "react";
import { Row, Col, Collapse, Typography, Tag, Avatar, Icon } from "antd";

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

export default function Cart({ customer, customerCartList }) {


    if (!customerCartList || customerCartList.length == 0) {
        return (
            <React.Fragment>
                <Row type="flex" justify="center" align="middle" style={{ paddingTop: "20px", marginBottom: "0px" }}>
                    <Col>
                        <Icon style={{ fontSize: "50px", color: "#59CAAA" }} type="shopping-cart" />
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle" style={{ paddingTop: "10px" }}>
                    <Col>
                        <Text style={{ fontSize: "14px" }}>Cart is Empty</Text>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    let totalAmount = 0;
    let currency = ""
    for (let i = 0; i < customerCartList.length; i++) {
        totalAmount += (parseInt(customerCartList[i].quantity) * parseInt(customerCartList[i].price));
        currency = customerCartList[i].currency;
    }


    return (
        <Row gutter={[12, 12]} style={{ maxHeight: "45vh", overflow: "scroll" }}>

            <Col sm={24} >
                <Row style={{ background: "#313841", borderRadius: "10px", padding: "15px" }}>
                    <Col sm={5}>
                        <Text style={{ color: "white", fontSize: "11px" }}>Last Modified:</Text>
                    </Col>

                    <Col sm={19}>
                        <Text style={{ color: "white", fontSize: "11px", fontWeight: "bold" }}>{customer.customerDetails.cart_last_modified}</Text>
                    </Col>
                    
                    <Col sm={5}>
                        <Text style={{ color: "white", fontSize: "11px" }}>Sub Total:</Text>
                    </Col>

                    <Col sm={19}>
                        <Text style={{ color: "white", fontSize: "11px", fontWeight: "bold" }}>{currency} {totalAmount}</Text>
                    </Col>

                    <Col sm={5}>
                        <Text style={{ color: "white", fontSize: "11px" }}>Delivery Charges:</Text>
                    </Col>

                    <Col sm={19}>
                        <Text style={{ color: "green", fontSize: "11px" }}>Free</Text>
                    </Col>
                </Row>
            </Col>

            {
                customerCartList.map((item, index) => {
                    return (
                        <Col sm={12} key={index}>
                            <Row style={{ background: "#313841", borderRadius: "10px", padding: "15px" }}>
                                <Col sm={5}>
                                    <img src={item.productImageUrl} style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "4px"
                                    }} />
                                </Col>
                                <Col sm={18} offset={1}>
                                    <Paragraph style={{ color: "white", fontSize: "10px", marginBottom: "0px" }} ellipsis={{ "rows": 1, "expandable": false }}>
                                         {item.sellerSku} {item.productName && item.sellerSku && '|'} {item.productName}
                                    </Paragraph>
                                    <Text style={{ fontSize: "11px", display: "block" }}>Quantity: <Text style={{ color: "white", fontSize: "11px" }}>{item.quantity}</Text></Text>
                                    <Text style={{ fontSize: "11px", display: "block" }}>Price: <Text style={{ color: "white", fontSize: "11px" }}>{item.currency} {item.price}</Text></Text>
                                </Col>
                            </Row>
                        </Col>
                    )
                })
            }



        </Row >
    )
}