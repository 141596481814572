import React, { useState } from "react";
import { Modal, Button, Col, Row, Typography, Upload, message } from "antd";
import styled from "styled-components";
import "./nesto-upload-modal.scss";
import axios from "axios";
import isDev from "../../../../../server";
import { connect } from "react-redux";
import {
    fetchProductList,
} from "../../../../../actions";
import fileTemplate from "./nesto-bulk-upload-template.xlsx";

const serverUrl = isDev();

const { Text } = Typography;

const UploadBtn = styled(Upload)`
    && {
        .ant-upload {
            height: 0px !important;
            width: 100% !important;
        }
        span {
            width: 100% !important;
        }
    }
`;

const NestoUploadModal = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);

    const beforeUpload = (file) => {
        setFile(file);
    };

    const handleOkUploadModal = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("import_file", file);

        axios
            .post(`${serverUrl}/bulk-upload-nesto-products/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                if (res.data.status === 200) {
                    setIsVisible(false);
                    message.success("Products Uploaded Successfully!");
                    props.fetchProductList(props.filters, props.tags, true);
                } else if (res.data.status === 500) {
                    message.error("Something went wrong!");
                } else if (res.data.status === 1 || res.data.status === 2 || res.data.status === 3) {
                    message.error(res.data.message);
                }
                setLoading(false);
            });
    };

    return (
        <div>
            <Button
                style={{
                    width: "100%",
                    background: "blue",
                    color: "white",
                    border: "none",
                }}
                icon="upload"
                onClick={() => setIsVisible(true)}
            >
                Upload
            </Button>
            <Modal
                className="upload-modal"
                width="fit-content"
                title="Upload"
                visible={isVisible}
                onOk={handleOkUploadModal}
                onCancel={() => setIsVisible(false)}
                destroyOnClose={true}
                confirmLoading={loading}
            >
                <Col>
                    <UploadBtn
                        style={{ width: "100%" }}
                        beforeUpload={(file) => {
                            beforeUpload(file)
                            return false;
                        }}
                    >
                        <Button
                            // loading={this.state.loading}
                            // disabled={
                            //     this.state.fileList.length !== 0 ||
                            //     this.state.published
                            // }
                            style={{ width: "30%" }}
                            type="primary"
                        >
                            {" "}
                            Upload{" "}
                        </Button>
                    </UploadBtn>
                    <Row style={{ marginTop: 20 }}>
                        <Text>
                            Download{" "}
                            <Text
                                onClick={() => {
                                    window.open(fileTemplate);
                                }}
                                style={{ color: "#fff", cursor: "pointer" }}
                            >
                                this template
                            </Text>{" "}
                            for Bulk uploading products.
                        </Text>
                    </Row>
                </Col>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ productList }) => {
    return {
        ...productList,
    };
};

export default connect(mapStateToProps, { fetchProductList })(NestoUploadModal);
