import React, { useState, useEffect } from "react"
import { Row, Col, Card, Tabs, Input, Button, Collapse, Typography, Form, AutoComplete, message, Select, Checkbox } from "antd";
import AddressCard from './AddressCard'
import isDev from '../../server'
import axios from "axios"
import './playground.scss'
import InputField from "views/components/datainputs/InputField";
import { locationUuidConst } from '../../constants/locationUuid';
import CityAdressDropdown from "./city.address.dropdown";

const { TabPane } = Tabs;
const { Panel } = Collapse
const { Text } = Typography
const { Option } = Select;
const serverUrl = isDev()

//Shipping Address to display Governorate instead of Emirates
const shippingAddressUUID = [locationUuidConst.WIGme_KWT, locationUuidConst.WIGme_BAH, locationUuidConst.QA_wigme_BAH, locationUuidConst.QA_wigme_KWT,locationUuidConst.QA_geepas_uganda,locationUuidConst.Geepas_uganda]

const CustomerDetails = ({ locationGroupUuid, userName, setUserName, selected, setSelected,
    billingSelected, setBillingSelected, isB2b, shippingAddressList, setShippingAddressList,
    billingAddressList, setBillingAddressList, sameShippingAndBilling,setSameShippingAndBilling }) => {

    const [address, setAddress] = useState({ line1: '', line2: '', line3: '', line4: '', postcode: '' ,emirates:''})
    const [billingAddress, setBillingAddress] = useState({ line1: '', line2: '', line3: '', line4: '', postcode: '',emirates:''});
    const [customerDetails, setCustomerDetails] = useState(null)
    const [fetchedCustomers, setFetchedCustomers] = useState([])
    const [newCustomer, setNewCustomer] = useState(false)
    const [createLoading, setCreateLoading] = useState(false);
    const [addressButtonLoading, setAddressButtonLoading] = useState(false);
    const [billingAddressButtonLoading, setBillingAddressButtonLoading] = useState(false);
    const [regionList, setRegionList] = useState([]);
    const [selectedAddress,setSelectedAddress]=useState({ line1: '', line2: '', line3: '', line4: '', postcode: '' ,emirates:''})

    useEffect(() => {
        let data = {
            locationGroupUuid: locationGroupUuid
        };
        fetch(`${serverUrl}/dealshub/fetch-location-group-settings/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setRegionList(data.region_list);
                }
                else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
    }, [])

    useEffect(() => {
        if (shippingAddressList[selected] === undefined && selected !== null) return;
        setAddress(selected === null ? { line1: '', line2: '', line3: '', line4: '', postcode: '',emirates:'' } : shippingAddressList[selected])
    }, [selected]);
    useEffect(() => {
        if (billingAddressList[billingSelected] === undefined && billingSelected !== null) return;
        setBillingAddress(billingSelected === null ? { line1: '', line2: '', line3: '', line4: '', postcode: '',emirates:'' } : billingAddressList[billingSelected])
    }, [billingSelected])

    useEffect(() => {
        if (!address.emirates) {
            setAddress({ ...address, emirates: regionList?.[0] })
        }
    }, [])

    //handles the changes in the address
    const handleAddressChange = (e) => {
        const { name, value } = e.target
        setAddress({ ...address, [name]: value })
    }

    /**
     * To control city dropdown for Bahrain and Kuwait
     * @param {string} value 
     */
    const handleCityAddressChange = (value) => {
        setAddress({ ...address, line4: value })
    }

    const handleBillingAddressChange = (e) => {
        const { name, value } = e.target
        setBillingAddress({ ...billingAddress, [name]: value })
    }

    //handles the customer detail changes
    const handleCustomerDetailChange = (e) => {
        const { name, value } = e.target
        setCustomerDetails({ ...customerDetails, [name]: value })
    }

    //handles the event when a customer is clicked upon
    const handleSelect = (selectedValue) => {
        setSelected(null);  
        setShippingAddressList([]);
        setBillingSelected(null);
        setBillingAddressList([]);
        setSameShippingAndBilling(false);
        const customerUserName = fetchedCustomers[selectedValue];
        if (customerUserName) {
            setUserName(customerUserName.username)
            axios({
                method: "POST",
                url: `${serverUrl}/dealshub/user/fetch-offline-user-profile/`,
                data: {
                    username: customerUserName.username,
                    is_b2b: isB2b,
                    isSetDefaultCODCharges: true,
                    locationGroupUuid: locationGroupUuid,
                },
                headers: {
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    let data = {
                        contact_number: response.data.contactNumber,
                        first_name: response.data.firstName,
                        last_name: response.data.lastName,
                        email: response.data.emailId
                    }
                    setCustomerDetails(data);
                    setShippingAddressList(response.data.shippingAddressList);
                    if (response.data.billingAddressList?.length > 0) {
                        setBillingAddressList(response.data.billingAddressList);
                        if (Object.keys(response.data.primeBillingAddress).length > 0 && billingSelected === null) {
                            for (let i in response.data.billingAddressList) {
                                if (response.data.billingAddressList[i].uuid === response.data.primeBillingAddress.uuid) {
                                    setBillingSelected(i);
                                    break;
                                }
                            }
                        }
                    }
                })
        }
    }

    //handles customer search
    const autoCompleteSearch = (searchString) => {
        if (searchString !== "") {
            axios({
                method: "POST",
                url: `${serverUrl}/dealshub/user/search-customer-autocomplete/`,
                data: {
                    searchString: searchString,
                    locationGroupUuid: locationGroupUuid
                },
                headers: {
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    setFetchedCustomers(response.data.userList)
                })
        }
        else {
            setFetchedCustomers([])
        }
    }

    //triggers when new customer button is clicked
    const handleNewCustomer = () => {
        let data = {
            contact_number: "",
            first_name: "",
            last_name: "",
            email: ""
        }
        setCustomerDetails(data)
        setNewCustomer(!newCustomer)
    }

    //triggers when new addres is added
    const addNewAddress = () => {
        setAddressButtonLoading(true)
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/address/create-offline-shipping-address/`,
            data: {
                ...address,
                state: "",
                locationGroupUuid: locationGroupUuid,
                username: userName
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                setAddressButtonLoading(false);
                if (response.data.status === 500) {
                    message.error("Something went wrong!");
                }
                else if (response.data.status === 403) {
                    message.info("You are not allowed to add new address!")
                }
                fetchCustomerProfile()
            })
            .then(() => {
                //setAddress({line1:'',line2:'',line3:'',line4:'',postcode:'',state:''})
                setAddress({ line1: '', line2: '', line3: '', line4: '', postcode: '' })
            })
    }

    const addNewBillingAddress = () => {
        setBillingAddressButtonLoading(true)
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/address/create-offline-billing-address/`,
            data: {
                ...billingAddress,
                state: "",
                locationGroupUuid: locationGroupUuid,
                username: userName
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                setBillingAddressButtonLoading(false);
                if (response.data.status === 500) {
                    message.error("Something went wrong!");
                }
                else if (response.data.status === 403) {
                    message.info("You are not allowed to add new address!")
                }
                fetchCustomerProfile()
            })
            .then(() => {
                //setAddress({line1:'',line2:'',line3:'',line4:'',postcode:'',state:''})
                setBillingAddress({ line1: '', line2: '', line3: '', line4: '', postcode: '' })
            })
    }
    //triggers when address is updated
    const updateAddress = () => {
        setAddressButtonLoading(true);
        console.log('address',address)
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/address/edit-shipping-address/`,
            data: {
                ...address,
                state: "",
                uuid: shippingAddressList[selected].uuid,
                firstName: shippingAddressList[selected].firstName,
                lastName: shippingAddressList[selected].lastName,
                tag: shippingAddressList[selected].tag,
                emirates: address.emirates ? address.emirates :
                '',
                // neighbourhood: "",
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                setAddressButtonLoading(false);
                if(response.data.status === 200)
                    message.success('Address Updated!')
                if (response.data.status === 500) {
                    message.error("Something went wrong!");
                }
                else if (response.data.status === 403) {
                    message.info("You are not allowed to add new address!")
                }
                let fetchTimeOut = setTimeout(() => {
                    fetchCustomerProfile();
                    clearTimeout(fetchTimeOut);
                }, 1000)
            })
    }

    const updateBillingAddress = () => {
        setBillingAddressButtonLoading(true);
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/address/edit-shipping-address/`,
            data: {
                ...billingAddress,
                state: "",
                uuid: billingAddressList[billingSelected].uuid,
                firstName: billingAddressList[billingSelected].firstName,
                lastName: billingAddressList[billingSelected].lastName,
                tag: billingAddressList[billingSelected].tag,
                emirates: billingAddress.emirates ? billingAddress.emirates :
                    billingAddressList[billingSelected].emirates,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                setBillingAddressButtonLoading(false);
                if (response.data.status === 500) {
                    message.error("Something went wrong!");
                }
                else if (response.data.status === 403) {
                    message.info("You are not allowed to add new address!")
                }
                let fetchTimeOut = setTimeout(() => {
                    fetchCustomerProfile();
                    clearTimeout(fetchTimeOut);
                }, 1000)
            })
    }

    //fetches the customer details based on the username
    const fetchCustomerProfile = () => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/user/fetch-offline-user-profile/`,
            data: {
                username: userName,
                is_b2b: isB2b,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                setShippingAddressList(response.data.shippingAddressList);
                if (response.data.billingAddressList?.length > 0) {
                    setBillingAddressList(response.data.billingAddressList);
                    if (Object.keys(response.data.primeBillingAddress).length > 0 && billingSelected === null) {
                        for (let i in response.data.billingAddressList) {
                            if (response.data.billingAddressList[i].uuid === response.data.primeBillingAddress.uuid) {
                                setBillingSelected(i);
                                break;
                            }
                        }
                    }
                }
            })
    }
    //creates a new customer
    const createNewCustomer = () => {
        setCreateLoading(true);
        setSelected(null);
        setShippingAddressList([]);
        setBillingSelected(null);
        setBillingAddressList([]);
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/user/create-offline-customer/`,
            data: {
                ...customerDetails,
                website_group_name: localStorage.getItem("websiteGroupName"),
                locationGroupUuid: locationGroupUuid,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                setCreateLoading(false);
                if (response.data.status === 200) {
                    message.success("New customer created!")
                    setNewCustomer(!newCustomer)
                    setUserName(response.data.username)
                }
                else if (response.data.status === 409)
                    message.error("User already exists!")
                else
                    message.error("Something went wrong!")
            })
            .then(() => {
                if (userName !== null)
                    fetchCustomerProfile()
            })
    }

    //updates customer details
    const updateCustomerDetails = () => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/user/update-offline-user-profile/`,
            data: {
                username: userName,
                emailId: customerDetails.email,
                firstName: customerDetails.first_name,
                lastName: customerDetails.last_name,
                contactNumber: customerDetails.contact_number
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                if (response.data.status === 200)
                    message.success("Customer details updated successfully!")
                else if (response.data.status === 403) {
                    message.info("You are not authorised!")
                }
                else
                    message.error("Something went wrong!")
            })
    }

    return (
        <Card style={{ marginTop: "20px" }}>
            <Row gutter={[24, 24]}>
                <Col span={19}>
                    <AutoComplete
                        // dataSource={fetchedCustomers.map(item=>item.name)}
                        onSelect={handleSelect}
                        onSearch={autoCompleteSearch}
                        placeholder="Search customer"
                        style={{ width: "100%" }}
                        disabled={customerDetails !== null}
                    >
                        {fetchedCustomers.map((item, index) => {
                            return (
                                <Option key={index}>{item.name}</Option>
                            )
                        })}
                    </AutoComplete>
                </Col>


                <Col span={5}>
                    <Button
                        className="button flex-center"
                        type="primary"
                        onClick={handleNewCustomer}
                        disabled={customerDetails !== null}
                        style={{ width: "100%" }}
                    >
                        Add New Customer
                    </Button>
                </Col>
            </Row>
            {customerDetails &&
                <Row style={{ margin: "20px 0" }}>
                    <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right">
                        <Panel
                            header={`${customerDetails.first_name || "customer"}`}
                            className="customer-collapse-panel"
                            key="1"
                        >
                            <Row style={{ padding: "10px 20px 20px" }}>
                                <Col span={24}>
                                    <Card>
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col span={4} className="flex-center">
                                                <div className="flex-center customer-avatar">
                                                    {customerDetails.first_name !== "" && customerDetails.first_name[0].toUpperCase()}{customerDetails.last_name !== "" && customerDetails.last_name[0].toUpperCase()}
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <Row style={{ marginTop: "10x" }} gutter={[16, 16]}>
                                                    <Col span={24}>
                                                        <InputField
                                                            label={{ type: "secondary" }}
                                                            input={{

                                                                name: "first_name",
                                                                value: customerDetails.first_name,
                                                                disabled: false
                                                            }}
                                                            onChange={(e) => {
                                                                handleCustomerDetailChange(e);
                                                            }}
                                                            labelText="First Name & Last Name"
                                                        ></InputField>
                                                    </Col>
                                                    {/* <Col span={12}>
                                                        <Input 
                                                            name="last_name" 
                                                            value={customerDetails.last_name} 
                                                            onChange={handleCustomerDetailChange} 
                                                            placeholder="Last Name"
                                                        ></Input>
                                                    </Col> */}
                                                </Row>
                                                <Row gutter={[16, 16]}>
                                                    <Col span={12}>
                                                        <InputField
                                                            label={{ type: "secondary" }}
                                                            input={{

                                                                name: "email",
                                                                value: customerDetails.email,
                                                                disabled: false
                                                            }}
                                                            onChange={(e) => {
                                                                handleCustomerDetailChange(e);
                                                            }}
                                                            labelText="Email Address"
                                                        ></InputField>
                                                    </Col>
                                                    <Col span={12}>
                                                        <InputField
                                                            label={{ type: "secondary" }}
                                                            input={{
                                                                name: "contact_number",
                                                                value: customerDetails.contact_number,
                                                                disabled: false
                                                            }}
                                                            onChange={(e) => {
                                                                handleCustomerDetailChange(e);
                                                            }}
                                                            labelText="Contact Number"
                                                        ></InputField>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Button type="primary" onClick={newCustomer ? createNewCustomer : updateCustomerDetails} loading={createLoading} disabled={createLoading} style={{ float: "right", marginTop: "10px" }}>{newCustomer ? "Create" : "Update"}</Button>
                                        </Row>
                                    </Card>
                                    {!newCustomer &&
                                        <Card style={{ margin: "10px 0 0" }}>
                                            <Row gutter={[16, 16]}>
                                                <Tabs>
                                                    {
                                                        isB2b && (
                                                            <TabPane tab="Billing Address" key="billing-address">
                                                                <Col span={12}>
                                                                    <Text>Address List</Text>
                                                                    <Checkbox
                                                                        style={{marginTop: '5px'}}
                                                                        checked={sameShippingAndBilling}
                                                                        onChange={()=>setSameShippingAndBilling(!sameShippingAndBilling)}
                                                                    >
                                                                    Billing and shipping address are the same
                                                                    </Checkbox>
                                                                    <div className="address-container">
                                                                        {billingAddressList && billingAddressList.length > 0 &&
                                                                            billingAddressList.map((oneAddress, ind) => <AddressCard
                                                                                key={ind}
                                                                                id={ind}
                                                                                onClick={setBillingSelected}
                                                                                address={oneAddress}
                                                                                selected={billingSelected}
                                                                                locationGroupUuid={locationGroupUuid}
                                                                            />)}
                                                                    </div>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Text>Address Form</Text>
                                                                    <Card style={{ width: "100%", marginTop: "5px" }}>
                                                                        <Row gutter={[16, 16]}>
                                                                            <Col span={24}>
                                                                                <InputField
                                                                                    label={{ type: "secondary" }}
                                                                                    input={{ 
                                                                                        size: "large",
                                                                                        name: "line1",
                                                                                        value: billingAddress.line1,
                                                                                        disabled: false
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        handleBillingAddressChange(e);
                                                                                    }}
                                                                                    labelText="House no/ Apartment no"
                                                                                ></InputField>
                                                                            </Col>
                                                                            <Col span={24}>
                                                                                <InputField
                                                                                    label={{ type: "secondary" }}
                                                                                    input={{
                                                                                        size: "large",
                                                                                        name: "line2",
                                                                                        value: billingAddress.line2,
                                                                                        disabled: false
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        handleBillingAddressChange(e);
                                                                                    }}
                                                                                    labelText="Street name"
                                                                                ></InputField>
                                                                            </Col>
                                                                            <Col span={24}>
                                                                                <InputField
                                                                                    label={{ type: "secondary" }}
                                                                                    input={{
                                                                                        size: "large",
                                                                                        name: "line3",
                                                                                        value: billingAddress.line3,
                                                                                        disabled: false
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        handleBillingAddressChange(e);
                                                                                    }}
                                                                                    labelText="Landmark"
                                                                                ></InputField>
                                                                            </Col>
                                                                            <Col span={24}>
                                                                                <InputField
                                                                                    label={{ type: "secondary" }}
                                                                                    input={{
                                                                                        size: "large",
                                                                                        name: "line4",
                                                                                        value: billingAddress.line4,
                                                                                        disabled: false
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        handleBillingAddressChange(e);
                                                                                    }}
                                                                                    labelText="city"
                                                                                ></InputField>
                                                                            </Col>
                                                                            <Col span={24}>
                                                                                <Text style={{
                                                                                    fontSize: "14px",
                                                                                    color: "rgba(255, 255, 255, 0.6)"
                                                                                }}>{locationGroupUuid == "335100bc-7b67-4e52-bb2c-88fc5eb9554d" ? "Governorate" : "Emirates"}</Text>
                                                                                <Select
                                                                                    defaultValue="ABU DHABI"
                                                                                    style={{ width: "100%", }}
                                                                                    size="large"
                                                                                    onSelect={(value) => {
                                                                                        setBillingAddress({ ...billingAddress, emirates: value })
                                                                                    }}
                                                                                    value={billingAddress.emirates}
                                                                                >
                                                                                    {
                                                                                        regionList.map((e, index) => {
                                                                                            return <Option key={index} value={e}>{e}</Option>
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                            </Col>
                                                                            <Button htmlType="submit" type="primary"
                                                                                loading={billingAddressButtonLoading}
                                                                                disabled={billingAddressButtonLoading}
                                                                                onClick={billingSelected === null ?
                                                                                    addNewBillingAddress :
                                                                                    updateBillingAddress}
                                                                                style={{ float: "right" }}>
                                                                                {
                                                                                    billingSelected === null ? "Add" : "Update"
                                                                                } Address</Button>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                            </TabPane>
                                                        )
                                                    }
                                                        <TabPane 
                                                        tab="Shipping Address" key="shipping-address" disabled={isB2b && sameShippingAndBilling}>
                                                        <Col span={12}>
                                                            <Text>Address List</Text>
                                                            <div className="address-container">
                                                        {shippingAddressList && shippingAddressList.length > 0 && shippingAddressList.map((oneAddress, ind) =>
                                                            <AddressCard
                                                                id={ind}
                                                                key={ind}
                                                                onClick={setSelected}
                                                                address={oneAddress}
                                                                selected={selected}
                                                                locationGroupUuid={locationGroupUuid} />
                                                        )
                                                        }
                                                                {(!shippingAddressList || shippingAddressList?.length === 0) && <Text strong={true} style={{ paddingTop: '10px' }}>No Address Found</Text>}
                                                            </div>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Text>Address Form</Text>
                                                            <Card style={{ width: "100%", marginTop: "5px" }}>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col span={24}>
                                                                        <InputField
                                                                            label={{ type: "secondary" }}
                                                                            input={{
                                                                                size: "large",
                                                                                name: "line1",
                                                                                value: address.line1,
                                                                                disabled: false
                                                                            }}
                                                                            onChange={(e) => {
                                                                                handleAddressChange(e);
                                                                            }}
                                                                            labelText="House no/ Apartment no"
                                                                        ></InputField>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <InputField
                                                                            label={{ type: "secondary" }}
                                                                            input={{
                                                                                size: "large",
                                                                                name: "line2",
                                                                                value: address.line2,
                                                                                disabled: false
                                                                            }}
                                                                            onChange={(e) => {
                                                                                handleAddressChange(e);
                                                                            }}
                                                                            labelText="Street name"
                                                                        ></InputField>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <InputField
                                                                            label={{ type: "secondary" }}
                                                                            input={{
                                                                                size: "large",
                                                                                name: "line3",
                                                                                value: address.line3,
                                                                                disabled: false
                                                                            }}
                                                                            onChange={(e) => {
                                                                                handleAddressChange(e);
                                                                            }}
                                                                            labelText="Landmark"
                                                                        ></InputField>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                {shippingAddressUUID.includes(locationGroupUuid) ?
                                                                    <CityAdressDropdown locationGroupUuid={locationGroupUuid} setCity={handleCityAddressChange} address={address} selected={selected} />
                                                                    :
                                                                    <InputField
                                                                            label={{ type: "secondary" }}
                                                                            input={{
                                                                                size: "large",
                                                                                name: "line4",
                                                                                value: address.line4,
                                                                                disabled: false
                                                                            }}
                                                                            onChange={(e) => {
                                                                                handleAddressChange(e);
                                                                            }}
                                                                            labelText="city"
                                                                    ></InputField>
                                                                }
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Text style={{
                                                                            fontSize: "14px",
                                                                    color: "rgba(255, 255, 255)",
                                                                    marginBottom: '10px'
                                                                        }}>{(locationGroupUuid === locationUuidConst.QA_geepas_uganda || locationGroupUuid === locationUuidConst.Geepas_uganda)? "Region": (shippingAddressUUID.includes(locationGroupUuid) ? "Governorate" : "Emirates")}</Text>
                                                                <Select
                                                                            style={{ width: "100%", }}
                                                                            size="large"
                                                                            onSelect={(value) => {
                                                                                setAddress({ ...address, emirates: value })
                                                                            }}
                                                                    value={address.emirates ?? regionList?.[0]}
                                                                        // placeholder="Emirates"
                                                                        >
                                                                            {
                                                                                regionList.map((e, index) => {
                                                                                    return <Option key={index} value={e}>{e}</Option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Col>
                                                                    <Button htmlType="submit" type="primary" loading={addressButtonLoading} disabled={addressButtonLoading} onClick={selected === null ? addNewAddress : updateAddress} style={{ float: "right" }}>{selected === null ? "Add" : "Update"} Address</Button>
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    </TabPane>
                                                </Tabs>
                                            </Row>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Row>
            }
        </Card>
    )
}

export default CustomerDetails