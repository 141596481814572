import { utils_copy } from "./Utils";

/**
 * @description This reducer is used to fetch product from different noon
 */

/**
 * @description State type for channelProductReducer
 * @typedef {ChannelProductNoon} - This is type of channel product state
 * @property {object} noon - to store product info
 *
 *
 */

/**
 * @type {ChannelProductNoon}
 */

const initialState = {
  noon: {
    repr_image_url: "",
    repr_high_def_url: "",
    images: {
      all_images: [
        {
          pk: "",
          "main-url": "",
          "midimage-url": "",
          "thumbnail-url": "",
        },
      ],
      main_images: [
        {
          pk: "",
          "main-url": "",
          "midimage-url": "",
          "thumbnail-url": "",
          is_main_image: true,
        },
      ],
      sub_images: [
        {
          pk: "",
          "main-url": "",
          "midimage-url": "",
          "thumbnail-url": "",
          is_sub_image: true,
          sub_image_index: "",
        },
      ],
    },
    product_id: "",
    material_type: "",
    channel_product_json: {
      product_name: "",
      noon_sku: "",
      partner_sku: "",
      partner_barcode: "",
      psku_code: "",
      category: "",
      subtitle: "",
      sub_category: "",
      model_number: "",
      model_name: "",
      msrp_ae: "",
      msrp_ae_unit: "",
      product_description: "",
      product_attribute_list: [],
      created_date: "",
      status: "Inactive",
      http_link: "",
      now_price: "",
      sale_price: "",
      sale_start: "",
      sale_end: "",
      stock: "",
      warranty: "",
    },
    status: "",
  },
};

/**
 *
 * @param {ChannelProductNoon} state
 * @param {object} action
 * @returns {ChannelProductNoon}
 */

export default function channelProducts(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CHANNEL_PRODUCT_NOON":
      let noon_json_data = utils_copy(
        state.noon.channel_product_json,
        action.payload.channel_product_json
      );
      action.payload.channel_product_json = noon_json_data;
      return { noon: { ...action.payload, ["status"]: "" } };
    case "SEND_FEED_BACK_TO_CHANNEL_FORM":
      return { noon: { ...state.noon, ["status"]: action.payload } };
    case "SET_IMAGE_NOON":
      return {
        noon: { ...state.noon, ["images"]: action.payload.images },
      };
    case "HANDEL_CHANGE_NOON":
      return { noon: { ...action.payload, ["status"]: "" } };
    default:
      return state;
  }
}
