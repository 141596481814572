import React , {useState, useEffect} from "react"
import RightWrapper from "my-components/ui-components/RightWrapper"
import {Row,Col,Typography,Select,Icon, message , Button} from "antd";
import axios from "axios";
import isDev from "server";
import InputField from "views/components/datainputs/InputField";

const serverOmnycommUrl = isDev();

const {Text , Title} = Typography;
const {Option} = Select;
/**
 * @description This function corresponds to 'Category Hierarchy' in ommnycomm dashboard
 * It handles three drop-downs 'Super Category', 'Category' ,'Sub Category'
 * and updates details based on input. It sets initial state for all the drop-downs 
 * @param  {*} props 
 * @returns It returns a react fragment which conditional renders based on drop-down inputs
 * it sets the states of 'Super Category', 'Category' ,'Sub Category'
 * based on conditional rendering.
 */
const InnerWrapper = (props) => {

    const [superCat , setSuperCat] = useState({
        start : true,
        end : false
    });
    const [category , setCategory] = useState({
        start : false,
        end : false
    });
    const [subCategory , setSubcategory] = useState({
        start : false,
        end : false
    });


    const [apiData , setApiData] = useState([]);
    const [updateLoading , setUpdateLoading] = useState(false);

    const updateDetails = () => {

        setUpdateLoading(true);

        axios
        .post(
          `${serverOmnycommUrl}/update-category-mapping/`,
          {
              pk : apiData[data.superCat].category[data.cat].sub_category[data.subCat].pk,
              atp_thresold : parseFloat(inputData.atp_thresold),
              holding_thresold : parseFloat(inputData.holding_thresold),
              recommended_browse_nodes : inputData.recommended_browse_node
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {

            setUpdateLoading(false);

            if(response.data.status == 200)
            {   

                let apiDataCopy = apiData;

                apiDataCopy[data.superCat].category[data.cat].sub_category[data.subCat].category_mapping.atp_thresold = inputData.atp_thresold;
                apiDataCopy[data.superCat].category[data.cat].sub_category[data.subCat].category_mapping.holding_thresold = inputData.holding_thresold;
                apiDataCopy[data.superCat].category[data.cat].sub_category[data.subCat].category_mapping.recommended_browse_nodes = inputData.recommended_browse_nodes;

                console.log([...apiDataCopy]);

                setApiData([...apiDataCopy]);

                message.success("Updation is Successfull!");
            }
            else
                message.error("Something went wrong!");
        });
    }


    useEffect(() => {
        axios
        .post(
          `${serverOmnycommUrl}/fetch-category-list/`,
          {
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
            if(response.data.status == 200)
            {   
                setApiData(response.data.super_category_list);
            }
            else
                message.error("Something went wrong!");
        });
    },[])

    const [data , setData] = useState({
        superCat : null , cat : null , subCat : null 
    })

    const [inputData , setInputData] = useState({
        atp_thresold : "",
        recommended_browse_node : "",
        holding_thresold : ""
    })

    return (
        <React.Fragment>
            <Row gutter = {[12,12]} style = {{marginTop : "0px" , padding : "14px"}}>
                <Col span = {24}>
                    <Title level={4} style = {{color : "white"}}>
                        Category Hierarchy
                    </Title>
                </Col>

                <Col span = {24}>
                    <Row style = {{backgroundColor : "#36404a" , padding : "20px"}} gutter = {[16,16]}>

                        {
                            superCat.start && 
                            <Col span = {8}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select a SuperCategory"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        console.log(e);
                                        setData({...data , superCat : e});
                                        setSuperCat({
                                            start : false,
                                            end : true
                                        })
                                        setCategory({
                                            start : true,
                                            end : false
                                        })
                                    }}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        apiData.map((item , index) => {
                                         return <Option value={index} key={index}>{item.name}</Option>;
                                        })
                                    }
                                   
                                </Select>
                            </Col>
                        }

                        
                        {
                            superCat.end && 
                            <Col span = {8}>
                                <Row style = {{backgroundColor : "#313841" , padding : "10px" , border : "1px dashed #69CAAA", position : "relative"}}>
                                    <Text style = {{color : "#69CAAA"}}>Super Category</Text><br/>
                                    <Text style = {{color : "white"}}>{apiData && apiData[data.superCat].name}</Text>

                                    <Icon
                                    
                                        onClick = {() => {
                                            setSuperCat({
                                                start : true,
                                                end : false
                                            })

                                            setCategory({
                                                start : false,
                                                end : false
                                            })

                                            setSubcategory({
                                                start : false,
                                                end : false
                                            })

                                            setData({
                                                superCat : null,
                                                cat : null,
                                                subCat: null
                                            })
                                        }}

                                    style = {{position : "absolute" , top : "10px" , right : "10px" , color : "#69CAAA" , cursor : "pointer"}} type = "close-circle"/>
                                </Row>
                            </Col>
                        }
                        

                        {
                            category.start && 
                            <Col span = {8}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select a Category"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        setData({...data , cat : e});
                                        setCategory({
                                            start : false,
                                            end : true
                                        })
                                        setSubcategory({
                                            start : true,
                                            end : false
                                        })
                                    }}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        apiData[data.superCat] && apiData[data.superCat].category.map((item , index) => {
                                        return  <Option value={index} key = {index}>{item.name}</Option>;
                                        })
                                    }
                                </Select>
                            </Col>
                        }

                        {
                            category.end && 
                            <Col span = {8}>
                                <Row style = {{backgroundColor : "#313841" , padding : "10px" , border : "1px dashed #69CAAA", position : "relative"}}>
                                    <Text style = {{color : "#69CAAA"}}>Category</Text><br/>
                                    <Text style = {{color : "white"}}>{apiData && apiData[data.superCat].category[data.cat].name}</Text>

                                    <Icon
                                    
                                    onClick = {() => {
                                    
                                        setCategory({
                                            start : true,
                                            end : false
                                        })

                                        setSubcategory({
                                            start : false,
                                            end : false
                                        })

                                        setData({
                                            ...data,
                                            cat : null,
                                            subCat: null
                                        })
                                    }}
                                    
                                    style = {{position : "absolute" , top : "10px" , right : "10px" , color : "#69CAAA" , cursor : "pointer"}} type = "close-circle"/>
                                </Row>
                            </Col>
                        }



                        {
                            subCategory.start && 
                            <Col span = {8}>
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select a Subcategory"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        setData({...data , subCat : e});
                                        setInputData({
                                            atp_thresold : apiData[data.superCat].category[data.cat].sub_category[e].category_mapping.atp_thresold,
                                            holding_thresold : apiData[data.superCat].category[data.cat].sub_category[e].category_mapping.holding_thresold,
                                            recommended_browse_node : apiData[data.superCat].category[data.cat].sub_category[e].category_mapping.recommended_browse_node
                                        })
                                        setSubcategory({
                                            start : false,
                                            end : true
                                        })
                                    }}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >

                                    {
                                        apiData[data.superCat].category[data.cat].sub_category.map((item , index) => {
                                        return <Option value={index} key = {index}>{item.name}</Option>;
                                        })
                                    }
                                    
                                </Select>
                            </Col>
                        }

                        {
                            subCategory.end && 
                            <Col span = {8}>
                                <Row style = {{backgroundColor : "#313841" , padding : "10px" , border : "1px dashed #69CAAA", position : "relative"}}>
                                    <Text style = {{color : "#69CAAA"}}>Sub category</Text><br/>
                                    <Text style = {{color : "white"}}>{apiData && apiData[data.superCat].category[data.cat].sub_category[data.subCat].name}</Text>



                                    <Icon
                                    
                                    onClick = {() => {
                                        setSubcategory({
                                            start : true,
                                            end : false
                                        })

                                        setData({
                                            ...data,
                                            subCat: null
                                        })
                                    }}
                                    
                                    style = {{position : "absolute" , top : "10px" , right : "10px" , color : "#69CAAA" , cursor : "pointer"}} type = "close-circle"/>
                                </Row>
                            </Col>
                        }


                    </Row>
                </Col>
           

               {
                   data.subCat != null &&
                   <Col span = {24}>

                    <Row style = {{backgroundColor : "#36404a" , padding : "20px" , marginTop : "12px"}} type  ="flex" align = "middle" gutter = {[16,16]}>
                        <Col sm={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "atp_thresold",
                                    value: inputData.atp_thresold,
                                    disabled: false
                                }}
                                onChange={(e)=>{
                                    setInputData({...inputData ,  [e.target.name] : e.target.value})
                                }}
                                labelText="ATP Thresold"
                            />
                        </Col>

                        <Col sm={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "holding_thresold",
                                    value: inputData.holding_thresold,
                                    disabled: false
                                }}
                                onChange={(e)=>{
                                    setInputData({...inputData ,  [e.target.name] : e.target.value})
                                }}
                                labelText="Holding Thresold"
                            />
                        </Col>

                        <Col sm={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "recommended_browse_node",
                                    value: inputData.recommended_browse_node,
                                    disabled: false
                                }}
                                onChange={(e)=>{
                                    setInputData({...inputData ,  [e.target.name] : e.target.value})
                                }}
                                labelText="Recommended Browse Node"
                            />
                        </Col>

                        <Col sm = {24}>
                                <Button type= "primary" onClick = {() => {
                                    updateDetails();
                                }} loading = {updateLoading}>Update Details</Button>
                        </Col>
                        

                    </Row>

                   </Col>
               }
                   
              

               
            </Row>
        </React.Fragment>
    )
}


export default function CategoryDetails(props){
    return (
      <React.Fragment>
            <RightWrapper
                innerwrapperTransparent
                InnerWrapper={
                    <React.Fragment>
                        <InnerWrapper  {...props}/>
                    </React.Fragment>
                }
            />
      </React.Fragment>
    )
}