export const setOrdersManagerDetails = (ordersList) => (dispatch) => {
	ordersList.map((bulkOrder) => {
		bulkOrder.unitOrderList.map((order) => {
			order['checked'] = true;
		});
	});
	dispatch({
		type: 'SET_ORDER_MANAGER_DETAILS',
		payload: ordersList,
	});
};

export const loadMoreOrdersManagerDetails = (ordersList) => (dispatch) => {
	ordersList.map((bulkOrder) => {
		bulkOrder.unitOrderList.map((order) => {
			order['checked'] = true;
		});
	});
	dispatch({
		type: 'LOAD_MORE_ORDER_MANAGER_DETAILS',
		payload: ordersList,
	});
};

export const setShippingMethod = (
	orderShippingMethod,
	ordersList,
	selectedOrders
) => (dispatch) => {
	let allOrders = ordersList.fetchedOrders;

	allOrders.map((bulkOrder) => {
		bulkOrder.unitOrderList.map((order) => {
			if (selectedOrders.indexOf(order.uuid) > -1) {
				order['shippingMethod'] = orderShippingMethod;
				order['currentStatus'] = 'approved';
			}
		});
	});
	console.log(allOrders);
	dispatch({
		type: 'SET_SHIPPING_METHOD',
		payload: allOrders,
	});
};

export const setOrderStatus = (orderStatus, ordersList, selectedOrders) => (
	dispatch
) => {
	let allOrders = ordersList.fetchedOrders;

	// allOrders.map(bulkOrder => {
	//     bulkOrder.unitOrderList.map(order => {
	//         if (selectedOrders.indexOf(order.uuid) > -1) {
	//             order['currentStatus'] = orderStatus;
	//             bulkOrder['currentStatus'] = orderStatus
	//         }
	//     });
	// });
	for (let i = 0; i < allOrders.length; i++) {
		for (let j = 0; j < allOrders[i].unitOrderList.length; j++) {
			if (selectedOrders.indexOf(allOrders[i].unitOrderList[j].uuid) > -1) {
				if (allOrders[i].unitOrderList[j]['currentStatus'] !== 'cancelled') {
					allOrders[i].unitOrderList[j]['currentStatus'] = orderStatus;
				}
				allOrders[i]['currentStatus'] = orderStatus;
			}
		}
	}

	console.clear();
	console.log(allOrders, selectedOrders, orderStatus);

	dispatch({
		type: 'SET_ORDER_STATUS',
		payload: allOrders,
	});
};

export const setOrderStatusBulk = (orderStatus, ordersList, selectedOrders) => (
	dispatch
) => {
	let allOrders = ordersList.fetchedOrders;

	// allOrders.map(bulkOrder => {
	//     if(selectedOrders.indexOf(bulkOrder.uuid)>-1)
	//         bulkOrder['currentStatus']=orderStatus
	// });
	for (let i = 0; i < allOrders.length; i++) {
		if (selectedOrders.indexOf(allOrders[i].uuid) > -1) {
			allOrders[i]['currentStatus'] = orderStatus;
			for (let j = 0; j < allOrders[i].unitOrderList.length; j++) {
				if (allOrders[i].unitOrderList[j]['currentStatus'] !== 'cancelled') {
					allOrders[i].unitOrderList[j]['currentStatus'] = orderStatus;
				}
			}
		}
	}

	console.clear();
	console.log(allOrders, selectedOrders, orderStatus);

	dispatch({
		type: 'SET_ORDER_STATUS',
		payload: allOrders,
	});
};

export const cancelOrder = (ordersList, bundleId, selectedOrders) => (
	dispatch
) => {
	let allOrders = ordersList.fetchedOrders;

	for (
		let bulkOrderIndex = 0;
		bulkOrderIndex < allOrders.length;
		++bulkOrderIndex
	) {
		if (allOrders[bulkOrderIndex]['bundleId'] === bundleId) {
			allOrders[bulkOrderIndex]['currentStatus'] = 'cancelled';
			let tempUnitOrderList = allOrders[bulkOrderIndex].unitOrderList;
			tempUnitOrderList.map((unitOrder) => {
				for (
					let unitOrderSelectedOrdersIndex = 0;
					unitOrderSelectedOrdersIndex < selectedOrders.length;
					++unitOrderSelectedOrdersIndex
				) {
					if (
						selectedOrders[unitOrderSelectedOrdersIndex] === unitOrder['uuid']
					) {
						unitOrder['currentStatus'] = 'cancelled';
					}
				}
			});
		}
	}

	dispatch({
		type: 'CANCEL_ORDER',
		payload: allOrders,
	});
};

export const checkOrderUuid = (ordersList, orderUuid, bundleId) => (
	dispatch
) => {
	ordersList.map((bulkOrder) => {
		if (bulkOrder.bundleId === bundleId) {
			for (let order = 0; order < bulkOrder.unitOrderList.length; ++order) {
				if (bulkOrder.unitOrderList[order]['uuid'] === orderUuid) {
					bulkOrder.unitOrderList[order]['checked'] = true;
					break;
				}
			}
		}
	});

	dispatch({
		type: 'CHECK_UUID',
		payload: {
			ordersList,
			orderUuid,
		},
	});
};

export const uncheckOrderUuid = (ordersList, orderUuid, bundleId) => (
	dispatch
) => {
	ordersList.map((bulkOrder) => {
		if (bulkOrder.bundleId === bundleId) {
			for (let order = 0; order < bulkOrder.unitOrderList.length; ++order) {
				if (bulkOrder.unitOrderList[order]['uuid'] === orderUuid) {
					bulkOrder.unitOrderList[order]['checked'] = false;
					break;
				}
			}
		}
	});

	dispatch({
		type: 'UNCHECK_UUID',
		payload: {
			ordersList,
			orderUuid,
		},
	});
};

export const unsetSelectedOrders = () => (dispatch) => {
	dispatch({
		type: 'UNSET_SELECTED_ORDERS',
		payload: null,
	});
};
