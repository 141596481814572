import React, { Component } from 'react';
import {
    Row,
    Col,
    Typography,
    Button,
    Avatar,
    Icon,
    Collapse,
    Spin,
    Modal,
    message,
    Popconfirm,
    Select,
    Input,
    AutoComplete,
} from 'antd';
import './order-details.scss';
import axios from 'axios';
import isDev from '../../server';
import ProductListCrudItems from 'views/dealshubadmin/ProductListCrudItems';
import InputField from 'views/components/datainputs/InputField';
import { connect } from 'react-redux'
import { openRefreshPageNotification, closeRefreshPageNotification } from '../../actions/refreshNotificationAction';
import { locationUuidConst } from "constants/locationUuid";

const serverUrl = isDev();

const serverDealsHubUrl = isDev(true);

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

class OrderDetails extends Component {
    state = {
        spinnerLoading: false,
        details: null,
        activityLogs: [],
        shipping_status: null,
        AddressModalOpen: false,
        activeAddressModalType: 'Shipping',
        addressUpdateLoading: false,
        searchListing: [],
        autoCompleteSearchString: '',
        codCharge: '',
        deliveryCharge: '',
        toPay: '',
        vat: '',
        orderNote: '',
        editStatus: { cod: false, delivery: false },
        productListUpdationinProgress: false,
        activities: [
            {
                name: 'MadhuSudan',
                date: '1 oct 2020',
            },
            {
                name: 'Sandeep',
                date: '29 nov 2020',
            },
            {
                name: 'Nisarg',
                date: '21 nov 2020',
            },
            {
                name: 'Sandeep',
                date: '2 April',
            },
            {
                name: 'Nisarg',
                date: '21 july',
            },
        ],
    };

    handleSelect = (selectedValue) => {
        this.addProduct(selectedValue);
        this.setState({
            autoCompleteSearchString: '',
        });
    };

    addProduct = (productUuid) => {
        this.setState({
            productListUpdationinProgress: true,
        });

        // add link here
        axios
            .post(
                `${serverUrl}/dealshub/add-product-to-order/`,
                {
                    orderUuid: this.props.uuid,
                    productUuid,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status === 200) {
                    // add prodect to unitorderlist
                    // this.state.details.unitOrderList
                    this.setState({
                        details: {
                            ...this.state.details,
                            unitOrderList: [
                                ...this.state.details.unitOrderList,
                                response.data.newUnitOrder,
                            ],
                        },
                    });

                    this.setState({
                        productListUpdationinProgress: false,
                    });

                    this.fetchOrderDetails();
                    this.fetchActivityLog();
                    message.success('Product Added Successfully!');
                } else if (response.data.status === 403) {
                    message.info('You are not authorised!');
                } else if (response.data.status === 500 && response.data.message) {
                    message.info(response.data.message);
                } else {
                    message.error('Something went wrong!');
                }
            });
    };

    autocompleteSearch = (searchString) => {
        if (searchString !== '') {
            // Add product search api here
            axios
                .post(
                    `${serverUrl}/dealshub/search-products/`,
                    {
                        websiteGroupName: localStorage.getItem('websiteGroupName'),
                        searchString: searchString,
                        // uuid : this.props.uuid,
                        locationGroupUuid: this.props.locationUuid,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.status === 200) {
                        let tempList = [];
                        response.data.productList.map((list) =>
                            tempList.push(
                                <Option key={list.uuid} value={list.uuid}>
                                    {list.name}
                                </Option>
                            )
                        );
                        this.setState({
                            searchListing: [...[], ...tempList],
                        });
                    } else if (response.data.status === 403) {
                        message.info('You are not authorised!');
                    } else {
                        message.error('Something went Wrong!');
                    }
                });
        } else {
            this.setState({
                searchListing: [...[], ...[]],
            });
        }
    };

    fetchOrderDetails = () => {
        this.setState({
            spinnerLoading: true,
        });

        const data = {
            uuid: this.props.uuid,
            is_b2b: this.props.isB2b,
        };

        axios
            .post(`${serverDealsHubUrl}/dealshub/order/fetch-order-details/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            })
            .then((response) => {
                this.setState({
                    spinnerLoading: false,
                });
                if (response.data.status === 200) {
                    this.setState({
                        details: response.data,
                        orderNote: response.data.additional_note,
                        codCharge: response.data.codFee,
                        deliveryCharge: response.data.deliveryFee,
                        toPay: response.data.toPay,
                        vat: response.data.vat
                    });
                }
                if (this.state.details.shippingMethod === 'LOGIX') {
                    this.handleShippingStatus();
                }
            });
    };

    fetchRegionList = () => {
        let data = {
            locationGroupUuid: this.props.locationUuid,
        };
        fetch(`${serverUrl}/dealshub/fetch-location-group-settings/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    this.setState({
                        region_list: data.region_list,
                    });
                } else if (data.status === 403) {
                    message.info('You are not authorised!');
                } else {
                    message.error('Something went wrong');
                }
            });
    };

    fetchActivityLog = () => {
        this.setState({
            spinnerLoading: true,
        });

        const data = {
            uuid: this.props.uuid,
        };

        axios
            .post(`${serverDealsHubUrl}/dealshub/order/fetch-order-version-details/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then((response) => {
                this.setState({
                    spinnerLoading: false,
                })
                if (response.data.status === 200) {
                    this.setState({
                        activityLogs: response.data.version_order_list
                    })
                }
            })
    }

    componentDidMount = () => {
        this.fetchOrderDetails();
        this.fetchRegionList();
        this.fetchActivityLog();
    };

    handleChangeAddressModalInput = (e) => {
        this.setState({
            details: {
                ...this.state.details,
                shippingAddress: {
                    ...this.state.details.shippingAddress,
                    [e.target.name]: e.target.value,
                },
            },
        });
    };

    handleAddressUpdate = () => {
        this.setState({
            addressUpdateLoading: true,
        });

        const data = {
            orderUuid: this.props.uuid,
            contact_number: this.state.details.shippingAddress.contactNumber,
            firstName: this.state.details.shippingAddress.firstName,
            lastName: this.state.details.shippingAddress.lastName,
            line1: this.state.details.shippingAddress.line1,
            line2: this.state.details.shippingAddress.line2,
            emirates: this.state.details.shippingAddress.emirates,
        };

        axios
            .post(
                `${serverDealsHubUrl}/dealshub/update-order-shipping-admin/`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                this.setState({
                    addressUpdateLoading: false,
                    AddressModalOpen: false,
                });
                if (response.data.status === 200) {
                    message.success('Address is successfully updated!');
                    this.fetchActivityLog()
                } else if (data.status === 403) {
                    message.info('You are not authorised!');
                } else message.error('Something went wrong during update');
            });
    };

    deleteThisProduct = (uuid, index) => {
        this.setState({
            productDeleteLoading: true,
        });

        const data = {
            unitOrderUuid: uuid,
            quantity: 0,
        };

        axios
            .post(
                `${serverDealsHubUrl}/dealshub/update-unit-order-qty-admin/`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                this.setState({
                    productDeleteLoading: false,
                });
                if (response.data.status === 200) {
                    message.success('Product is successfully deleted!');
                    this.setState({
                        details: {
                            ...this.state.details,
                            unitOrderList: this.state.details.unitOrderList.filter(
                                (e, idx) => idx !== index
                            ),
                        },
                    });
                    this.fetchOrderDetails();
                    this.fetchActivityLog();
                } else if (data.status === 403) {
                    message.info('You are not authorised!');
                } else message.error('Something went wrong during update');
            });
    };

    handleShippingStatus = () => {
        let data = {
            orderUuid: this.props.uuid,
        };
        fetch(`${serverUrl}/dealshub/fetch-logix-shipping-status/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    this.setState({
                        shipping_status: data.shipping_status,
                    });
                } else if (data.status === 403) {
                    message.info('You are not authorised!');
                } else {
                    message.error('Something went wrong');
                }
            });
    };

    updateOrderCharges = () => {
        axios({
            method: 'POST',
            url: `${serverUrl}/dealshub/update-order-charges/`,
            data: {
                orderUuid: this.props.uuid,
                offline_cod_charge: this.state.codCharge,
                offline_delivery_fee: this.state.deliveryCharge,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        }).then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    toPay: response.data.toPay,
                    vat: response.data.vat
                });
                this.fetchActivityLog();
                message.success('Successfully updated charges!');
                this.props.openRefreshPageNotification();

            } else {
                message.error('Something Went Wrong!');
            }
            this.setState({
                editStatus: { cod: false, delivery: false },
            });
        });
    };

    // getAmount = (unitOrderList) => {
    //     let retAns = 0;
    //     unitOrderList.map(item => {
    //         retAns += (item.quantity * item.price);
    //     })

    //     return retAns;
    // }

    render() {
        // let totalAmount = 0;

        // if (this.state.details) {
        //     totalAmount = this.getAmount(this.state.details.unitOrderList);
        // }

        // if(this.state.details.shippingMethod==="logix") {
        //     this.handleShippingStatus()
        // }
        //console.log("this.props",this.props);
        console.clear();

        return (
            <React.Fragment>
                {this.state.details && !this.state.spinnerLoading && (
                    <Row className='order-details-page-container'>
                        {/*<Col span={24} className='order-details-container'>*/}
                        <Row className='order-details-card-container'>
                            <Col span={24}>
                                <Row
                                    className='order-details-card-nav margin-bottom'
                                    type='flex'
                                    align='middle'
                                    justify='space-between'
                                    gutter={[8, 8]}
                                >
                                    <Col lg={20} md={20} sm={24}>
                                        <Row type='flex' gutter={[8, 8]}>
                                            <Col sm={24} lg={4} md={4}>
                                                <Text
                                                    className='text-color-1 margin-right'
                                                    strong='true'
                                                >
                                                    ORDER #{this.state.details.bundleId}
                                                </Text>
                                                <Text className=''>|</Text>
                                            </Col>
                                            <Col sm={24} lg={5} md={6}>
                                                <Text className='text-color-1 margin-right'>
                                                    Ordered on {this.state.details.dateCreated}
                                                </Text>
                                                <Text className=''>|</Text>
                                            </Col>
                                            <Col sm={24} lg={5} md={6}>
                                                <Text className='text-color-1'>
                                                    Ordered by {this.state.details.customerName}
                                                </Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* <Col>
                      <Button
                        type="danger"
                        icon="download"
                        className="invoice-btn"
                      >
                        Invoice
                      </Button>
                    </Col> */}
                                </Row>

                                <Row className='order-summery-container'>
                                    <Col span={24} className='margin-bottom'>
                                        <Row gutter={[16, 16]}>
                                            <Col lg={6} sm={24} md={6}>
                                                <Row>
                                                    <Text className='text-color-2' strong='true'>
                                                        Shipping Address
                                                        <Icon
                                                            style={{
                                                                color: '#3CC9A9',
                                                                fontSize: '10px',
                                                                marginLeft: '5px',
                                                            }}
                                                            type='edit'
                                                            onClick={() => {
                                                                //open Edit Address Modal
                                                                this.setState({
                                                                    AddressModalOpen: true,
                                                                    activeAddressModalType: 'Shipping',
                                                                });
                                                            }}
                                                        />
                                                    </Text>
                                                    <br />
                                                    <Text className=''>
                                                        {this.state.details.shippingAddress.firstName}
                                                    </Text>
                                                    <br />
                                                    <Text className=''>
                                                        {this.state.details.shippingAddress.line1}
                                                    </Text>
                                                    <br />
                                                    <Text className=''>
                                                        {this.state.details.shippingAddress.line2}
                                                    </Text>
                                                    <br />
                                                    {/* <Text className="">
                            {this.state.details.shippingAddress.line3},{" "}
                            {this.state.details.shippingAddress.line4}
                          </Text>
                          <br /> */}
                                                    {/* <Text className="">
                            {this.state.details.shippingAddress.state} -{" "}
                            {this.state.details.shippingAddress.postcode}
                          </Text>
                          <br /> */}
                                                    <Text className=''>
                                                        {(this.props.locationUuid === locationUuidConst.Geepas_uganda ||this.props.locationUuid === locationUuidConst.QA_geepas_uganda ) ? "Region: ":"Emirate: "}
                                                        {this.state.details.shippingAddress.emirates}
                                                    </Text>
                                                    <br />
                                                    <Text className=''>
                                                        Contact Number:{' '}
                                                        {this.state.details.shippingAddress.contactNumber}
                                                    </Text>
                                                </Row>
                                                <br />
                                                <Row>
                                                    {this.state.shipping_status && (
                                                        <>
                                                            <Col>
                                                                <Text className='text-color-2' strong='true'>
                                                                    Shipping Status:{' '}
                                                                </Text>

                                                                <Text className=''>
                                                                    {this.state.shipping_status}
                                                                </Text>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                                <Row>{this.state.orderNote && <>
                                                    <Col>
                                                        <Text className='text-color-2' strong='true'>
                                                            Order Note:{' '}
                                                        </Text>

                                                        <Text className=''>
                                                            {this.state.orderNote}
                                                        </Text>
                                                    </Col>
                                                </>}</Row>
                                            </Col>
                                            {this.state.details.billingAddress && Object.keys(this.state.details.billingAddress)?.length > 0 && (
                                                <Col lg={6} sm={24} md={6}>
                                                    <Row>
                                                        <Text className='text-color-2' strong='true'>
                                                            Billing Address
                                                        </Text>
                                                        <br />
                                                        {/* <Text className=''>
                                                            {this.state.details.billingAddress.firstName}
                                                        </Text> */}
                                                        <br />
                                                        <Text className=''>
                                                            {this.state.details.billingAddress.line1}
                                                        </Text>
                                                        <br />
                                                        <Text className=''>
                                                            {this.state.details.billingAddress.line2}
                                                        </Text>
                                                        <br />
                                                        <Text className=''>
                                                            {this.state.details.billingAddress.line3}
                                                        </Text>
                                                        <br />
                                                        <Text className=''>
                                                            {this.state.details.billingAddress.line4}
                                                        </Text>
                                                        <br />
                                                        <Text className=''>
                                                            {(this.props.locationUuid === locationUuidConst.Geepas_uganda ||this.props.locationUuid === locationUuidConst.QA_geepas_uganda ) ? "Region: ":"Emirate: "}
                                                            {this.state.details.billingAddress.emirates}
                                                        </Text>
                                                        <br />
                                                        {/* <Text className=''>
                                                            Contact Number:{' '}
                                                            {this.state.details.billingAddress.contactNumber}
                                                        </Text> */}
                                                    </Row>
                                                </Col>
                                            )}
                                            <Col lg={4} sm={24} md={5}>
                                                <Text className='text-color-2' strong='true'>
                                                    Payment method
                                                </Text>
                                                <br />
                                                <Text className=''>
                                                    {this.state.details.paymentMode}
                                                </Text>
                                            </Col>
                                            <Col lg={8} sm={24} md={7}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Text className='text-color-2' strong='true'>
                                                            Order summary
                                                        </Text>
                                                    </Col>
                                                    {/* <Col span={10}>
                              <Text>Item(s) Subtotal:</Text>
                            </Col>
                            <Col span={14}>
                              <Text>
                                {this.state.details.subtotal}{" "}
                                {this.state.details.currency}
                              </Text>
                            </Col> */}
                                                    <Row>
                                                        <Col span={18}>
                                                            <Text>Total:</Text>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Text>
                                                                {this.state.details.subtotal}{' '}
                                                                {this.state.details.currency}
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={18}>
                                                            <Text>Shipping:</Text>
                                                        </Col>
                                                        <Col span={6}>
                                                            {this.state.editStatus.delivery ? (
                                                                <>
                                                                    <Input
                                                                        onPressEnter={this.updateOrderCharges}
                                                                        style={{ width: '70%' }}
                                                                        value={this.state.deliveryCharge}

                                                                        onChange={(e) => {
                                                                            if (Number(e.target.value) < 0)
                                                                                return;
                                                                            this.setState({
                                                                                deliveryCharge: e.target.value,
                                                                            })
                                                                        }
                                                                        }
                                                                    />{' '}
                                                                    <Icon
                                                                        type='check-circle'
                                                                        style={{ color: '#1abc9c' }}
                                                                        onClick={this.updateOrderCharges}
                                                                    ></Icon>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {this.state.deliveryCharge}
                                                                    {this.state.details.is_order_offline && (
                                                                        <Icon
                                                                            type='edit'
                                                                            style={{ color: '#1abc9c' }}
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    editStatus: {
                                                                                        ...this.state.editStatus,
                                                                                        delivery: true,
                                                                                    },
                                                                                })
                                                                            }
                                                                        ></Icon>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {this.state.details.isVoucherApplied && (
                                                        <Row>
                                                            <Col span={18}>
                                                                <Text>
                                                                    Discount ({this.state.details.voucherCode})
                                                                </Text>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Text>
                                                                    -{this.state.details.voucherDiscount}
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {this.state.details.codFee !== '0' ? (
                                                        <Row>
                                                            <Col span={18}>
                                                                <Text>COD Charges:</Text>
                                                            </Col>
                                                            <Col span={6}>
                                                                {this.state.editStatus.cod ? (
                                                                    <>
                                                                        <Input
                                                                            onPressEnter={this.updateOrderCharges}
                                                                            style={{ width: '70%' }}
                                                                            value={this.state.codCharge}
                                                                            min={0}
                                                                            onChange={(e) => {
                                                                                if (Number(e.target.value) < 0)
                                                                                    return;
                                                                                this.setState({
                                                                                    codCharge: e.target.value,
                                                                                })
                                                                            }
                                                                            }
                                                                        />{' '}
                                                                        <Icon
                                                                            style={{ color: '#1abc9c' }}
                                                                            type='check-circle'
                                                                            onClick={this.updateOrderCharges}
                                                                        ></Icon>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {this.state.codCharge}
                                                                        {this.state.details.is_order_offline && (
                                                                            <Icon
                                                                                style={{ color: '#1abc9c' }}
                                                                                type='edit'
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        editStatus: {
                                                                                            ...this.state.editStatus,
                                                                                            cod: true,
                                                                                        },
                                                                                    })
                                                                                }
                                                                            ></Icon>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Row>
                                                        <Col span={18}>
                                                            <Text className='text-color-2' strong='true'>
                                                                Grand Total:
                                                            </Text>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Text className='text-color-2' strong='true'>
                                                                {this.state.toPay}{' '}
                                                                {this.state.details.currency}
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={18}>
                                                            <Text>Estimated VAT(5%):</Text>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Text>
                                                                {this.state.vat}{' '}
                                                                {this.state.details.currency}
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* <Col span={24}>
                      <Collapse className="transaction-collapse">
                        <Panel header="Track package">
                          <Row style={{ padding: "10px" }}>
                            <Col span={24}>
                              <Row>
                                <Col lg={3} sm={24} md={3}>
                                  <Text className="text-color-1">
                                    Shipment 1:
                                  </Text>
                                </Col>
                                <Col lg={21} sm={24} md={21}>
                                  <Text>
                                    22 January 2020 - Visa ending with 1234:
                                    59.00
                                  </Text>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={3} sm={24} md={3}>
                                  <Text className="text-color-1">
                                    Shipment 2:
                                  </Text>
                                </Col>
                                <Col lg={21} sm={24} md={21}>
                                  <Text>
                                    22 January 2020 - Visa ending width 3456:
                                    59.00
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col> */}
                                </Row>

                                <Collapse defaultActiveKey={['2']}>
                                    <Panel
                                        header='Products'
                                        key='2'
                                        className='order-details-collapse-panel'
                                    >
                                        {this.state.addMoreProducts ? (
                                            <AutoComplete
                                                style={{
                                                    width: '100%',
                                                    marginTop: '0px',
                                                    marginBottom: '30px',
                                                }}
                                                dataSource={this.state.searchListing}
                                                onSelect={this.handleSelect}
                                                value={this.state.autoCompleteSearchString}
                                                onChange={(e) => {
                                                    this.setState({
                                                        autoCompleteSearchString: e,
                                                    });
                                                }}
                                                onSearch={this.autocompleteSearch}
                                                placeholder='Search for Products'
                                            />
                                        ) : (
                                            <Col>
                                                {(this.props.misc.includes("adding-products-to-approved-order")) ?
                                                    <Button
                                                        type='primary'
                                                        icon='plus'
                                                        className='invoice-btn'
                                                        style={{ marginTop: '0px', marginBottom: '30px' }}
                                                        disabled={!this.state.details.enableOrderEdit}
                                                        onClick={() => {
                                                            this.setState({
                                                                addMoreProducts: true,
                                                            });
                                                        }}
                                                    >
                                                        Add Products
                                                    </Button>
                                                    : <></>
                                                }
                                            </Col>
                                        )}
                                        {this.state.details.unitOrderList.map(
                                            (unitOrder, index) => (
                                                <Row
                                                    className='ordered-products-container'
                                                    style={{ position: 'relative' }}
                                                >
                                                    {this.state.details.enableOrderEdit && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '10px',
                                                                right: '10px',
                                                            }}
                                                        >
                                                            <Popconfirm
                                                                title='Are you sure？'
                                                                okText='Yes'
                                                                cancelText='No'
                                                                onConfirm={() =>
                                                                    this.deleteThisProduct(
                                                                        unitOrder.uuid,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Text
                                                                    style={{
                                                                        color: 'red',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    Remove product
                                                                </Text>{' '}
                                                                <Icon
                                                                    type={
                                                                        this.state.productDeleteLoading
                                                                            ? 'loading'
                                                                            : 'close-circle'
                                                                    }
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                        zIndex: '1000',
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        </div>
                                                    )}
                                                    <Col span={20}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Row gutter={[18, 8]}>
                                                                    <Col lg={3} md={3} sm={24}>
                                                                        <Avatar
                                                                            shape='square'
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                            }}
                                                                            src={unitOrder.productImageUrl}
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        lg={21}
                                                                        md={21}
                                                                        sm={24}
                                                                        className='product-description-container'
                                                                    >
                                                                        <Row type='flex' gutter={[8, 8]}>
                                                                            <Col lg={6} md={6} sm={10}>
                                                                                <Text className='text-color-1'>
                                                                                    Product name :
                                                                                </Text>
                                                                            </Col>
                                                                            <Col lg={18} md={18} sm={14}>
                                                                                <Text>{unitOrder.productName}</Text>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row type='flex' gutter={[8, 8]}>
                                                                            <Col lg={6} md={6} sm={12}>
                                                                                <Text className='text-color-1'>
                                                                                    Current Status:
                                                                                </Text>
                                                                            </Col>
                                                                            <Col lg={18} md={18} sm={12}>
                                                                                <Text>{unitOrder.currentStatus}</Text>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row type='flex' gutter={[8, 8]}>
                                                                            <Col lg={6} md={6} sm={10}>
                                                                                <Text className='text-color-1'>
                                                                                    SKU :
                                                                                </Text>
                                                                            </Col>
                                                                            <Col lg={18} md={18} sm={14}>
                                                                                <Text>{unitOrder.sellerSku}</Text>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row type='flex' gutter={[8, 8]}>
                                                                            <Col lg={6} md={6} sm={10}>
                                                                                <Text className='text-color-1'>
                                                                                    Product Id :
                                                                                </Text>
                                                                            </Col>
                                                                            <Col lg={18} md={18} sm={14}>
                                                                                <Text>{unitOrder.productId}</Text>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row type='flex' gutter={[8, 8]}>
                                                                            <Col lg={6} md={6} sm={10}>
                                                                                <Text className='text-color-1'>
                                                                                    Price:
                                                                                </Text>
                                                                            </Col>
                                                                            <Col lg={18} md={18} sm={14}>
                                                                                <Text>{unitOrder.price} {(this.props.locationUuid === locationUuidConst.QA_geepas_uganda || this.props.locationUuid === locationUuidConst.Geepas_uganda) ? "UGX" : "AED"}</Text>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row type='flex' gutter={[8, 8]}>
                                                                            <Col lg={6} md={6} sm={10}>
                                                                                <Text className='text-color-1'>
                                                                                    Total price:
                                                                                </Text>
                                                                            </Col>
                                                                            <Col lg={18} md={18} sm={14}>
                                                                                <Text>
                                                                                    {unitOrder.price *
                                                                                        unitOrder.quantity}{' '}
                                                                                    {(this.props.locationUuid === locationUuidConst.QA_geepas_uganda ||  this.props.locationUuid === locationUuidConst.Geepas_uganda) ? "UGX" : "AED"}
                                                                                </Text>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row type='flex' gutter={[8, 8]}>
                                                                            <Col lg={6} md={6} sm={10}>
                                                                                <Text className='text-color-1'>
                                                                                    Qty :
                                                                                </Text>
                                                                            </Col>
                                                                            <Col lg={4} md={4} sm={4}>
                                                                                {this.state.details
                                                                                    .enableOrderEdit ? (
                                                                                    <ProductListCrudItems
                                                                                        value={unitOrder.quantity}
                                                                                        name='quantity'
                                                                                        type='int'
                                                                                        url='dealshub/update-unit-order-qty-admin/'
                                                                                        editOn={() => {
                                                                                        }}
                                                                                        callBackAfterUpdate={() => {
                                                                                            this.fetchOrderDetails()
                                                                                            this.fetchActivityLog()
                                                                                        }

                                                                                        }
                                                                                        request_data={{
                                                                                            //  "product_uuid": e.product_uuid
                                                                                            unitOrderUuid: unitOrder.uuid,
                                                                                        }}
                                                                                        locationUuid=''
                                                                                    />
                                                                                ) : (
                                                                                    <Text>{unitOrder.quantity}</Text>
                                                                                )}

                                                                                {/* <Text>{unitOrder.quantity}</Text> */}
                                                                            </Col>
                                                                        </Row>
                                                                        {/* <Row className="margin-top">
                                  <Col span={2}>
                                    <Button type="primary">Track package</Button>
                                  </Col>
                                </Row> */}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )
                                        )}
                                    </Panel>
                                </Collapse>


                                {/*Order Activity Log*/}

                                <Collapse>
                                    <Panel
                                        header="Activity Log"
                                        className="order-details-collapse-panel"
                                    >
                                        <Row style={{ backgroundColor: "#454E57", padding: "20px 20px 0px", display: "flex", flexDirection: "column" }}>
                                            <Col span={24}
                                                style={{ paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px" }}
                                            >
                                                <Col span={4}><Text
                                                    style={{ color: "white", fontSize: "14px" }}>User</Text></Col>
                                                <Col span={5}><Text
                                                    style={{ color: "white", fontSize: "14px" }}>Activity</Text></Col>
                                                <Col span={6}><Text
                                                    style={{ color: "white", fontSize: "14px" }}>From</Text></Col>
                                                <Col span={6}><Text
                                                    style={{ color: "white", fontSize: "14px" }}>To</Text></Col>
                                                <Col span={3}><Text
                                                    style={{ color: "white", fontSize: "14px" }}>Time</Text></Col>
                                            </Col>
                                            <Col style={{ display: "flex", flexDirection: "column", maxHeight: 200, overflow: "scroll" }}>
                                                {this.state.activityLogs.length > 0 ? this.state.activityLogs.map((activity, index) => {
                                                    return (
                                                        <Col
                                                            span={24}
                                                            key={index}
                                                            style={{
                                                                backgroundColor: "#313841",
                                                                borderRadius: "8px",
                                                                padding: "20px",
                                                                marginBottom: "20px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Col span={4}>
                                                                <Text style={{
                                                                    color: "#5BCAAA",
                                                                    fontSize: "14px"
                                                                }}>{activity.user}</Text>
                                                            </Col>
                                                            {activity.change_info.event === "address" &&
                                                                <>
                                                                    <Col span={5}>
                                                                        <Text style={{ color: "white" }}>Changed Shipping
                                                                            Address</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.old_address.line1}</Text>
                                                                        <br />
                                                                        <Text>{activity.change_info.information.old_address.line2}</Text>
                                                                        <br />
                                                                        <Text>{activity.change_info.information.old_address.emirates}</Text>

                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.new_address.line1}</Text>
                                                                        <br />
                                                                        <Text>{activity.change_info.information.new_address.line2}</Text>
                                                                        <br />
                                                                        <Text>{activity.change_info.information.new_address.emirates}</Text>

                                                                    </Col>
                                                                </>
                                                            }
                                                            {activity.change_info.event === "order_cancel" &&
                                                                <>
                                                                    <Col span={5}>
                                                                        <Text style={{ color: "white" }}>Changed Order Status</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>Pending</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text style={{ color: "#F44336" }}>Cancelled</Text></Col>
                                                                </>
                                                            }
                                                            {activity.change_info.event === "unit_order_add" &&
                                                                <>
                                                                    <Col span={5}>
                                                                        <Text style={{ color: "white" }}>Added New Product</Text>
                                                                        <br />
                                                                        <Text>Order
                                                                            ID: {activity.change_info.information.orderid}</Text>
                                                                        <br />
                                                                        <Text>Seller
                                                                            SKU: {activity.change_info.information.seller_sku}</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>0</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>1</Text>
                                                                    </Col>
                                                                </>
                                                            }

                                                            {activity.change_info.event === "unit_order_delete" &&
                                                                <>
                                                                    <Col span={6}>
                                                                        <Text style={{ color: "white" }}>Deleted Product</Text>
                                                                        <br />
                                                                        <Text>Order
                                                                            ID: {activity.change_info.information.orderid}</Text>
                                                                        <br />
                                                                        <Text>Seller
                                                                            SKU: {activity.change_info.information.seller_sku}</Text>
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Text>{activity.change_info.information.qty}</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>0</Text>
                                                                    </Col>
                                                                </>
                                                            }

                                                            {activity.change_info.event === "unit_order_update" &&
                                                                <>
                                                                    <Col span={6}>
                                                                        <Text style={{ color: "white" }}>Updated Product
                                                                            Quantity</Text>
                                                                        <br />
                                                                        <Text>Order
                                                                            ID: {activity.change_info.information.orderid}</Text>
                                                                        <br />
                                                                        <Text>Seller
                                                                            SKU: {activity.change_info.information.seller_sku}</Text>
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Text>{activity.change_info.information.old_qty}</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.new_qty}</Text>
                                                                    </Col>
                                                                </>
                                                            }

                                                            {activity.change_info.event === "order_status" &&
                                                                <>
                                                                    <Col span={5}>
                                                                        <Text style={{ color: "white" }}>Changed order status</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.old_status}</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.new_status}</Text>
                                                                    </Col>
                                                                </>
                                                            }

                                                            {activity.change_info.event === "call_status" &&
                                                                <>
                                                                    <Col span={5}>
                                                                        <Text style={{ color: "white" }}>Changed call status</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.old_status}</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.new_status}</Text>
                                                                    </Col>
                                                                </>
                                                            }

                                                            {activity.change_info.event === "update_delivery_fee" &&
                                                                <>
                                                                    <Col span={5}>
                                                                        <Text style={{ color: "white" }}>Delivery Fee Updated</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.old_delivery_fee}</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.new_delivery_fee}</Text>
                                                                    </Col>
                                                                </>
                                                            }

                                                            {activity.change_info.event === "update_cod_charge" &&
                                                                <>
                                                                    <Col span={5}>
                                                                        <Text style={{ color: "white" }}>COD Charge Updated</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.old_cod_charge}</Text>
                                                                    </Col>
                                                                    <Col span={6}>
                                                                        <Text>{activity.change_info.information.new_cod_charge}</Text>
                                                                    </Col>
                                                                </>
                                                            }

                                                            <Col span={3}>
                                                                <Text>
                                                                    {activity.date}{" "}{activity.time}
                                                                </Text>
                                                            </Col>
                                                        </Col>)
                                                })
                                                    :
                                                    <Col span={24}
                                                        style={{
                                                            backgroundColor: "#313841",
                                                            borderRadius: "8px",
                                                            padding: "20px",
                                                        }}
                                                    >
                                                        <Text>No activity logs present!</Text></Col>
                                                }
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>


                                {/* end of one product row */}
                            </Col>
                        </Row>
                        {/*</Col>*/}
                    </Row>
                )}

                {this.state.spinnerLoading && (
                    <Row
                        type='flex'
                        justify='center'
                        align='middle'
                        style={{ height: '50vh' }}
                    >
                        <Col span={1}>
                            <Spin tip='Loading' />
                        </Col>
                    </Row>
                )}

                {/* Edit Shipping Address */}
                <Modal
                    title={`Edit ${this.state.activeAddressModalType} Address`}
                    visible={this.state.AddressModalOpen}
                    onOk={this.handleAddressUpdate}
                    onCancel={() => {
                        this.setState({
                            AddressModalOpen: false,
                        });
                    }}
                    width={650}
                    footer={[
                        <Button
                            key='back'
                            onClick={() => {
                                this.setState({
                                    AddressModalOpen: false,
                                });
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key='submit'
                            type='primary'
                            disabled={this.state.addressUpdateLoading}
                            loading={this.state.addressUpdateLoading}
                            onClick={this.handleAddressUpdate}
                        >
                            Ok
                        </Button>,
                    ]}
                >
                    {this.state.details && (
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <InputField
                                    label={{ type: 'secondary' }}
                                    input={{
                                        size: 'large',
                                        name: 'firstName',
                                        value: this.state.details.shippingAddress.firstName,
                                        disabled: false,
                                    }}
                                    onChange={(e) => {
                                        this.handleChangeAddressModalInput(e);
                                    }}
                                    labelText='First Name & Last Name'
                                />
                            </Col>
                            {/* <Col span = {24}>
              <InputField
                     label={{ type: "secondary" }}
                     input={{
                         size: "large",
                         name: "lastName",
                         value: this.state.details.shippingAddress.lastName,
                         disabled: false
                     }}
                     onChange={(e)=>{
                      this.handleChangeAddressModalInput(e);
                     }}
                     labelText="Last Name"
                  />
              </Col> */}
                            <Col span={24}>
                                <InputField
                                    label={{ type: 'secondary' }}
                                    input={{
                                        size: 'large',
                                        name: 'line1',
                                        value: this.state.details.shippingAddress.line1,
                                        disabled: false,
                                    }}
                                    onChange={(e) => {
                                        this.handleChangeAddressModalInput(e);
                                    }}
                                    labelText='Flat / House No / FLoor / Building'
                                />
                            </Col>
                            <Col span={24}>
                                <InputField
                                    label={{ type: 'secondary' }}
                                    input={{
                                        size: 'large',
                                        name: 'line2',
                                        value: this.state.details.shippingAddress.line2,
                                        disabled: false,
                                    }}
                                    onChange={(e) => {
                                        this.handleChangeAddressModalInput(e);
                                    }}
                                    labelText='Street / Society / Office Name'
                                />
                            </Col>

                            <Col span={24}>
                                <InputField
                                    label={{ type: 'secondary' }}
                                    input={{
                                        size: 'large',
                                        name: 'contactNumber',
                                        value: this.state.details.shippingAddress.contactNumber,
                                        disabled: false,
                                    }}
                                    onChange={(e) => {
                                        this.handleChangeAddressModalInput(e);
                                    }}
                                    labelText='Contact Number'
                                />
                            </Col>

                            <Col span={24}>
                                <Text
                                    style={{
                                        fontSize: '14px',
                                        color: 'rgba(255, 255, 255, 0.6)',
                                    }}
                                >
                                    {this.props.locationUuid ===
                                        '335100bc-7b67-4e52-bb2c-88fc5eb9554d'
                                        ? 'Governorate'
                                        : 'Emirates'}
                                </Text>
                                <Select
                                    defaultValue='ABU DHABI'
                                    style={{ width: '100%' }}
                                    size='large'
                                    onSelect={(value) => {
                                        this.setState({
                                            details: {
                                                ...this.state.details,
                                                shippingAddress: {
                                                    ...this.state.details.shippingAddress,
                                                    emirates: value,
                                                },
                                            },
                                        });
                                    }}
                                    value={this.state.details.shippingAddress.emirates}
                                // placeholder="Emirates"
                                >
                                    {this.state.region_list?.map((e, index) => {
                                        return (
                                            <Option key={index} value={e}>
                                                {e}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Col>

                            {/* <Col span = {24}>
              <InputField
                     label={{ type: "secondary" }}
                     input={{
                         size: "large",
                         name: "line3",
                          value: this.state.details.shippingAddress.line3,
                         disabled: false
                     }}
                    onChange={(e)=>{
                      this.handleChangeAddressModalInput(e);
                     }}
                     labelText="Line 3"
                  />
              </Col>
              <Col span = {24}>
              <InputField
                     label={{ type: "secondary" }}
                     input={{
                         size: "large",
                         name: "line4",
                          value: this.state.details.shippingAddress.line4,
                         disabled: false
                     }}
                    onChange={(e)=>{
                      this.handleChangeAddressModalInput(e);
                     }}
                     labelText="Line 4"
                  />
              </Col>
              <Col span = {24}>
              <InputField
                     label={{ type: "secondary" }}
                     input={{
                         size: "large",
                         name: "state",
                          value: this.state.details.shippingAddress.state,
                         disabled: false
                     }}
                    onChange={(e)=>{
                      this.handleChangeAddressModalInput(e);
                     }}
                     labelText="State"
                  />
              </Col>
              <Col span = {24}>
              <InputField
                     label={{ type: "secondary" }}
                     input={{
                         size: "large",
                         name: "postcode",
                          value: this.state.details.shippingAddress.postcode,
                         disabled: false
                     }}
                    onChange={(e)=>{
                      this.handleChangeAddressModalInput(e);
                     }}
                     labelText="PostCode"
                  />
              </Col> */}
                        </Row>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        misc: state.miscPermission
    }
}
export default connect(mapStateToProps, {
    openRefreshPageNotification,
    closeRefreshPageNotification
})(OrderDetails)