import React from 'react'
import { Menu, Icon, Tag, Typography, Card, Row, Col, Dropdown, Avatar } from 'antd'
import "./customer-card.scss";
const { Text } = Typography

const menu = (
  <Menu style={{ backgroundColor: '#313841' }}>
    <Menu.Item style={{ borderBottom: "#252a30 solid 1px" }}>
      <a target="" rel="noopener noreferrer" href="">
        1st menu item
        </a>
    </Menu.Item>
    <Menu.Item style={{ borderBottom: "#252a30 solid 1px" }}>
      <a target="" rel="noopener noreferrer" href="">
        2nd menu item
        </a>
    </Menu.Item>
    <Menu.Item>
      <a target="" rel="noopener noreferrer" href="">
        3rd menu item
        </a>
    </Menu.Item>
  </Menu>
)

const CustomerCard = (props) => {
  return (
    <>
      <Card
        className="customer-card"
        style={props.selected ? {
          borderColor: "#1abc9c94",

        } : {}}
      >
        <Row type="flex" gutter={[8, 8]} style={{ background: "#233140" }} align="middle">

          <Col span={4}>
            <Avatar icon="user" />
          </Col>

          <Col span={12}>
            <Text style={{ color: "white" }}>{props.data.name == " " ? "-" : props.data.name}</Text><br />
            {/* {
              props.data.last_active ?
                <Text style={{
                  fontSize: "11px",
                  color: "#6ac66a"
                }}>
                  <Icon type="caret-right" style={{ color: "#6ac66a" }} /> Active</Text>
                :
                <Text style={{
                  fontSize: "11px",
                }}>12 June, 2020</Text>
            } */}

          </Col>

          <Col span={8}>
            <Row type="flex" justify="end">
              {
                !props.data.is_cart_empty &&
                <Col>
                  <Tag color="orange" style={{ borderRadius: "10px" }}><Icon type="crown" /> Lead</Tag>
                </Col>
              }

              {
                props.data.is_feedback_available ?
                  <Col>
                    <Icon
                      style={{ border: "1px solid #4ea5e7", padding: "4px", borderRadius: "15px", background: "white", color: "#4ea5e7" }}
                      type="message" />
                  </Col>
                  :
                  <Col>
                    <Icon
                      style={{ border: "1px solid gray", padding: "4px", borderRadius: "15px", background: "white", color: "gray" }}
                      type="message" />
                  </Col>
              }


            </Row>

          </Col>

          {/* <Col span={13}>
            <Text>
              {props.data.primaryTitle} {props.data.primary}
            </Text>
          </Col>
          <Col span={9} style={{ textAlign: 'right' }}>
            <Text>
              {props.data.secondaryTitle} {props.data.secondary}
            </Text>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
              <Icon type="more" />
            </Dropdown>
          </Col> */}
        </Row>
        <Row>
          <Col>

          </Col>
        </Row>
      </Card>
    </>
  )
}

export default CustomerCard