import React, { useEffect, useState } from 'react'
import { locationUuidConst } from '../../constants/locationUuid'
import { AutoComplete, message } from 'antd'

function CityAdressDropdown({ locationGroupUuid, setCity, address, selected }) {

    const [datasource, setDatasource] = useState([])
    const [governorate, setGovernorate] = useState('')
    const [input, setInput] = useState('')


    useEffect(() => {
        setDatasource(cityList(locationGroupUuid, address.emirates ?? ''))
        if (address.line4?.length > 0) {
            setInput(address.line4)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (governorate !== address.emirates) {
            let newCityList = cityList(locationGroupUuid, address.emirates)
            if (selected === null) {
                setCity('')
                setInput('')
            } else {
                let isCityCorrect = false

                newCityList.forEach((val) => {
                    if (val === address.line4) {
                        isCityCorrect = true
                    }
                })

                if (isCityCorrect) {
                    setInput(address.line4)
                } else {
                    message.warning('Choose a City')
                    setCity('')
                    setInput('')
                }
            }
            setGovernorate(address.emirates)
            setDatasource(newCityList)
        }

        if (governorate === address.emirates && selected !== null && input !== address.line4) {
            let newCityList = cityList(locationGroupUuid, address.emirates)
            let isCityCorrect = false
            newCityList.forEach((val) => {
                if (val === address.line4) {
                    isCityCorrect = true
                }
            })

            if (isCityCorrect) {
                setInput(address.line4)
            } else {
                message.warning('Choose a City')
                setCity('')
                setInput('')
            }
            setGovernorate(address.emirates)
            setDatasource(newCityList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationGroupUuid, address])

    const handleChange = (value) => {
        setInput(value)
        setCity(value)
    }

    const onSearch = (searchValue) => {
        let tempDataSource = cityList(locationGroupUuid, governorate)?.filter((city) => {
            return city?.toLowerCase()?.includes(searchValue.toLowerCase())
        })
        setDatasource(tempDataSource ?? [])
    }

    return (
        <>
            <p style={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: '16px', paddingBottom: '10px' }}>
                City
            </p>
            <AutoComplete
                value={input}
                dataSource={datasource}
                onChange={handleChange}
                onSearch={onSearch}
            />
        </>
    )
}

export default CityAdressDropdown

/**
 * 
 * @param {string} locationGroupUuid 
 * @param {string} governorate
 */

function cityList(locationGroupUuid, governorate) {
    if (locationGroupUuid === locationUuidConst.WIGme_BAH || locationGroupUuid === locationUuidConst.QA_wigme_BAH) {

        const cities = {
            Capital: [
                "Abu Bham",
                "Adhari",
                "Adliya",
                "Awadhiya",
                "Bahrain Bay",
                "Bilad Alqadeem",
                "Bu Ashira",
                "Bu Ghazal",
                "Burhama",
                "Ghuraifah (Old Juffair)",
                "Gudaibiya",
                "Gufool",
                "Hoora",
                "Juffair",
                "Karbabad",
                "Khmais",
                "Mahooz",
                "Manama City",
                "Mina Salman",
                "Nabih Saleh",
                "Noaim",
                "Ras Rumman",
                "Reef Island",
                "Salihiya",
                "Salmaniya",
                "Sanabis",
                "Sea Front",
                "Seef",
                "Sitra",
                "Sugaya",
                "Um Al Hasam",
                "Zinj",
            ],
            Central: [
                "AAl",
                "Alnasfa",
                "Eker",
                "Isa Town",
                "Jidd Ali",
                "Jurdab",
                "Maameer",
                "Nuwaidrat",
                "Salmabad",
                "Sanad",
                "Sitra",
                "Tubli",
            ],
            Muharraq: [
                "Al Busaiteen",
                "Al Dair",
                "Al Hidd",
                "Al Muharraq",
                "Al Gumra",
                "Al Jowder",
                "Al Ma Awida",
                "Al Zayayina",
                "Amwaj Islands",
                "Arad",
                "Bin Hindi",
                "Dilmunia Island",
                "Galali",
                "Samahij",
            ],
            Northern: [
                "Abu Sayba",
                "Al Malikiyah",
                "Al Qadam",
                "Bani Jamra",
                "Barbar",
                "Bu Quwah",
                "Budaiya",
                "Buri",
                "Daih",
                "Dar Kulaib",
                "Diraz",
                "Dumista",
                "Hajar",
                "Hamala",
                "Hillat Abdul Saleh",
                "Jabalat Hebshi",
                "Janabiya",
                "Jannusan",
                "Jasra",
                "Jid Al Haj",
                "Jidd Hafs",
                "Karranah",
                "Karzakkan",
                "Madinat Hamad",
                "Malkiyah",
                "Maqsha",
                "Markh",
                "Muqabah",
                "Nabih Saleh",
                "Northern City",
                "Qalah",
                "Quriyah",
                "Saar",
                "Sehla",
                "Shakhura",
                "Tashan",
            ],
            Southern: [
                "Al Mazrowiah",
                "Al Riffa",
                "Al Zallaq",
                "Al Zayayina",
                "Alhajiyat",
                "Alhunayniyah",
                "Askar",
                "Awali",
                "Bin Ghatim",
                "Durrat Al Bahrain",
                "Jary Al Shaikh",
                "Riffa Views",
                "Safreh",
                "Sakhir",
                "Wadi AlSail",
                "West Riffa",
            ]
        }

        try {
            if (!cities[governorate]) {
                throw new Error('Invalid Governorate Name')
            }
            return cities[governorate]
        } catch (e) {
            let total = []
            for (let g in cities) {
                total = [...total, ...cities[g]]
            }
            return total
        }


    } else if (locationGroupUuid === locationUuidConst.WIGme_KWT || locationGroupUuid === locationUuidConst.QA_wigme_KWT) {

        const cities = [
            "Abdali",
            "Abdullah Al Mubarak-West Jleeb",
            "Abdullah Al Salem",
            "Abraq Khaitan",
            "Abu Al Hasania",
            "Abu Fatira",
            "Abu Halifa",
            "Adailiya",
            "Ahmadi",
            "Al Adan",
            "Al Bidaa",
            "Al Dahar",
            "Al Ferdous",
            "Al Fintas",
            "Al Jahra",
            "Al Messilah",
            "Al Mutalaa",
            "Al Naeem",
            "Al Nahda (East Sulaibikhat)",
            "Al NaseemAl Oyoun",
            "Al QasrAl Qusur",
            "Al RaiAl Riggae",
            "Al WahaAl Zahra",
            "Al-Andalus",
            "Al-Masayel",
            "Al Rai Industrial",
            "Al Siddeeq",
            "Ali Al Salem Air Base",
            "Ali Sabah Al Salem",
            "Amghara",
            "Ardiya",
            "Ardiya Industrial",
            "Bayan",
            "Bnaider",
            "Bneid Al Gar",
            "Daiya",
            "Dajeej",
            "Dasma",
            "Dasman",
            "Doha",
            "Eqaila",
            "Fahad Al-Ahmad",
            "Fahaheel",
            "Faiha",
            "Farwaniya",
            "Fnaitees",
            "Gharnata",
            "Hadiya",
            "Hateen",
            "Hawalli",
            "Ishbilyah",
            "Jaber al Ahmad",
            "Jaber Al Ali",
            "Jabriya",
            "Jahra",
            "Jahra Industrial",
            "Jahra Military Camps",
            "Jleeb Al Shuyoukh",
            "Kabd",
            "Kaifan",
            "Kazmah",
            "Khaitan",
            "Khaldiya",
            "Kheiran",
            "Kuwait City",
            "Mahboula",
            "Maidan Hawalli",
            "Mangaf",
            "Mansouriya",
            "Mina Abdullah",
            "Mina Al Zour",
            "Mirqab",
            "Mishref",
            "Mubarak Abdulla",
            "Mubarak Al Kabeer",
            "Mubarak Al-Abdullah - West Mishref",
            "New Riggae",
            "Nuwaiseb",
            "Nuzha",
            "Omariya",
            "Qadsiya",
            "Qairawan",
            "Qairawan - (South Doha)",
            "Qibla",
            "Qortuba",
            "Qurain",
            "Rabia",
            "Rawdah",
            "Rawdatain",
            "Rehab",
            "Riqqa",
            "Rumaithiya",
            "Saad Al Abdullah (South Jahra)",
            "Sabah Al Ahmad",
            "Sabah Al Naser",
            "Sabah Al Salem",
            "Sabahiya",
            "Sabhan",
            "Sabiya",
            "Salam",
            "Salhiya",
            "Salmi",
            "Salmiya",
            "Salwa",
            "Shaab",
            "Shaab Al Bahri",
            "Shadadiya",
            "Shamiya",
            "Sharq",
            "Shuaiba",
            "Shuhada",
            "Shuwaikh (B-Residential)",
            "Shuwaikh Commercial",
            "Shuwaikh Educational",
            "Shuwaikh Health",
            "Shuwaikh Industrial 1",
            "Shuwaikh Industrial 2",
            "Shuwaikh Industrial 3",
            "Shuwaikh Sea Front",
            "South Doha",
            "South Jahra",
            "South Shuaiba",
            "Sulaibikhat",
            "Sulaibiya",
            "Sulaibiya Agricultural",
            "Sulaibiya Industrial",
            "Surra",
            "Tayma",
            "Wafra",
            "Wattayah",
            "Yarmouk",
        ]

        return cities

    } 
    else if(locationGroupUuid === locationUuidConst.QA_geepas_uganda || locationGroupUuid === locationUuidConst.Geepas_uganda)
    {
        const cities = {
          "Eastern Region": [
            "Amuria",
            "Budaka",
            "Bududa",
            "Bugiri",
            "Bukedea",
            "Bukwa",
            "Bulambuli",
            "Busia",
            "Butaleja",
            "Buyende",
            "Iganga",
            "Jinja",
            "kaberamaido",
            "Kaliro",
            "kamuli",
            "kapchorwa",
            "Katakwi",
            "Kibuku",
            "Kumi",
            "Kween",
            "Luuka",
            "Malaba",
            "Manafwa",
            "Mayuge",
            "Mbale",
            "Namayingo",
            "Namutumba",
            "Ngora",
            "Pallisa",
            "Serere",
            "Sironko",
            "Soroti",
            "Tororo",
          ],
          "Entebbe Area": [
            "Abayita Ababiri",
            "Banga",
            "Bugonga",
            "Entebbe Market Area",
            "Entebbe Town",
            "Katabi",
            "Kitoro",
            "Kitubulu",
            "Kiwafu-entebbe",
            "Lunyo",
            "Manyago",
            "Nakiwogo",
            "Nkumba",
            "Nsamizi",
          ],

          "Kampala Region": [
            "Bakuli",
            "Banda",
            "Bugolobi",
            "Bukasa",
            "Bukesa",
            "Bukoto",
            "Bulenga",
            "Bunga",
            "Busega",
            "Butabika",
            "Buwate",
            "Buziga",
            "Bwaise",
            "Bweyogerere",
            "Central Business District",
            "Down Town Kampala",
            "Ggaba",
            "Kabalagala",
            "Kabojja",
            "Kabowa",
            "Kabuusu",
            "Kagoma",
            "Kalerwe",
            "Kampala Industrial Area",
            "Kamwokya",
            "Kansanga",
            "Kanyanya",
            "Kasubi",
            "Katwe",
            "Kawaala",
            "Kawempe",
            "Kazo",
            "Kibiri",
            "Kibuli",
            "Kibuye",
            "Kikaya",
            "Kikoni",
            "Kinawataka",
            "Kira",
            "Kireka",
            "Kirinya",
            "Kirombe",
            "Kisaasi",
            "Kisenyi",
            "Kisugu",
            "Kiswa",
            "Kitala",
            "Kitante",
            "Kitebi",
            "Kitintale",
            "Kiwatule",
            "Kololo",
            "Komamboga",
            "Kulambiro",
            "Kyaliwajjala",
            "Kyambogo",
            "Kyanja",
            "Kyebando",
            "Kyengera",
            "Lubowa",
            "Lubya",
            "Lugala",
            "Lugoba",
            "Lugogo",
            "Lungujja",
            "Lusaze",
            "Luwafu",
            "Luzira",
            "Lweza",
            "Maganjo",
            "Makerere",
            "Makindye",
            "Masanafu",
            "Mbalwa",
            "Mbuya",
            "Mengo",
            "Mpererwe",
            "Mulago",
            "Munyonyo",
            "Mutundwe",
            "Mutungo",
            "Muyenga",
            "Naalya",
            "Nabisunsa",
            "Naguru",
            "Najjanankumbi",
            "Najjera",
            "Nakasero",
            "Nakawa",
            "Nakivubo",
            "Nalukolongo",
            "Namasuba",
            "Namirembe",
            "Namugongo",
            "Namungoona",
            "Namuwongo",
            "Nankulabye",
            "Nansana",
            "Nateete",
            "Ndeeba",
            "Nsambya",
            "Ntinda",
            "Nyanama",
            "Old Kampala",
            "Rubaga",
            "Salaama",
            "Seguku",
            "Sonde",
            "Wakaliga",
            "Wandegeya",
            "Wankulukuku",
            "Zana",
          ],
          "Northern Region": [
            "Abim",
            "Adjumani",
            "Agago",
            "Alebtong",
            "Amolatar",
            "Amudat",
            "Amuru",
            "Apac",
            "Arua",
            "Dokolo",
            "Gulu",
            "Kaabong",
            "Kitgum",
            "Koboko",
            "Kole",
            "Kotido",
            "Lamwo",
            "Lira",
            "Maracha",
            "Moroto",
            "Moyo",
            "Nakapiripirit",
            "Napak",
            "Nebbi",
            "Nwoya",
            "Otuke",
            "Oyam",
            "Packwach",
            "Pader",
            "Yumbe",
            "Zombo",
          ],
          "Rest of central region": [
            "Akright City",
            "Buikwe",
            "Bukomansimbi",
            "Butambala",
            "Gayaza",
            "Gomba",
            "Kajjansi",
            "Kakiri",
            "Kalangala",
            "Kalungu",
            "Kasangati",
            "Kayunga",
            "Kiboga",
            "Kikajjo",
            "Kisubi",
            "Kitende",
            "Kyankwanzi",
            "Kyotera",
            "Iugazi",
            "Luweero",
            "Lwengo",
            "Lyantonde",
            "Masaka",
            "Matugga",
            "Mityana",
            "Mpigi",
            "Mubende",
            "Mukono",
            "Nakaseke",
            "Nakasongola",
            "Namulanda",
            "Nsangi",
            "Nsasa",
            "Rakai",
            "Seeta",
            "Sembabule",
            "Wakiso",
            "Wampewo",
          ],
          "Western Region": [
            "Buhweju",
            "Buliisa",
            "Bundibugyo",
            "Bushenyi",
            "Hoima",
            "Ibanda",
            "Isingiro",
            "Kabale",
            "Kabarole(Fort Portal)",
            "Kamwenge",
            "Kanungu",
            "Kasese",
            "Kibaale",
            "Kiruhura",
            "Kiryandongo",
            "Kisoro",
            "Kyegegwa",
            "Kyenjojo",
            "Masindi",
            "Mbarara",
            "Mitooma",
            "Ntoroko",
            "Ntungamo",
            "Rubirizi",
            "Rukungiri",
            "Sheema",
          ],
        };

        try {
          if (!cities[governorate]) {
            throw new Error("Invalid Governorate Name");
          }
          return cities[governorate];
        } catch (e) {
          let total = [];
          for (let g in cities) {
            total = [...total, ...cities[g]];
          }
          return total;
        }


    }
    else return []
}