import React, { Component } from "react";
import { connect } from "react-redux";
import { Ellipsis } from 'react-spinners-css';

import { ExportPageWrapper } from "./styled-components-library";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { Row, Col } from "antd";
import ExportCards from "../components/cards/ExportCards";
import { fetchExportListPage } from "../../actions/exports";
import "./exports-page.scss"

class ExportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.props.drawerOff();
    this.props.fetchExportListPage();
  };

  reRenderProductCount = () => {
    this.props.fetchExportListPage();
  };

  render() {

    return (
      <ExportPageWrapper gutter={40} className="exports-page-wrapper">
        <TitleWithBreadCrumb
          title="Exports"
          breadcrumb={["OmnyComm", "Exports"]}
        />

        <Row
          style={{
            background: "#36404A",
            minHeight: "80%",
            borderRadius: "5px",
            padding: "20px",
            marginTop: "0px"
          }}
        >
          <Col sm={24}>
            {
              this.props.exportListdata.exportList.export_list ?
                (<Row gutter={[16, 16]}>
                  {this.props.exportListdata.exportList.export_list &&
                    this.props.exportListdata.exportList.export_list.map(e => {
                      return (
                        <Col sm={24} lg={8} md={8}>
                          <ExportCards
                            reRenderProductCount={this.reRenderProductCount}
                            refresh={this.props.refresh}
                            data={e}
                          />
                        </Col>
                      );
                    })}
                </Row>)
                : <Row type="flex" align="middle" justify="center" style={{ width: "100%", height: "80vh" }}>
                  <Ellipsis color="#1ABC9C" size={100} />
                </Row>
            }
          </Col>
        </Row>
      </ExportPageWrapper>
    );
  }
}

const mapStateToProps = ({ exportListData }) => {
  return {
    ...exportListData
  };
};

export default connect(mapStateToProps, { fetchExportListPage })(ExportsPage);
