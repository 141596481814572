import React, { useEffect, useState } from "react";
import {
    Form,
    Row,
    Col,
    Typography,
    Input,
    Button,
    Upload,
    message,
    Spin,
    Icon,
} from "antd";
import CloudImport from "media/cloud-upload.svg";
import axios from "axios";
import isDev from "../../server";
import { useHistory } from "react-router-dom";
import "./brand-details-page.scss";

const { Text } = Typography;
const { TextArea } = Input;

const serverUrl = isDev();

const BrandDetailsPage = (props) => {
    let history = useHistory();
    const [brandDetails, setBrandDetails] = useState({
        brandName: null,
        aboutBrand: null,
        logo: [],
    });

    const [isDeleteOn, setIsDeleteOn] = useState(false);
    const [logoUploading, setLogoUploading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.is_edit) {
            axios
                .post(
                    `${serverUrl}/fetch-nesto-brand-details/`,
                    {
                        pk: props.brandPk,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    }
                )
                .then(({ data }) => {
                    if (data.status === 200) {
                        setBrandDetails({
                            brandName: data.name,
                            aboutBrand: data.about_brand,
                            logo:
                                data.logo !== ""
                                    ? [
                                          {
                                              uid: "1",
                                              name: "logo.png",
                                              status: "done",
                                              url: data.logo,
                                          },
                                      ]
                                    : [],
                        });
                    } else {
                        message.error("Unable to fetch brand details!");
                    }
                })
                .catch((err) => {
                    message.error("Something went wrong!");
                });
        }
    }, []);

    const saveBrandDetails = () => {
        setLoading(true);
        axios
            .post(
                `${serverUrl}/${
                    props.is_edit
                        ? "update-nesto-brand/"
                        : "create-nesto-brand/"
                }`,
                {
                    name: brandDetails.brandName,
                    description: brandDetails.aboutBrand,
                    pk: props.is_edit && props.brandPk,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then(({ data }) => {
                if (data.status === 200) {
                    if (props.is_edit) {
                        message.success("Brand updated Successfully!");
                    } else {
                        message.success("Brand created Successfully!");
                        history.replace(`/edit-brand-details/${data.pk}`);
                    }
                } else if (data.status === 502) {
                    message.error("Brand already exists with the same name!");
                } else {
                    message.error(
                        `Unable to ${
                            props.is_edit ? "update" : "create"
                        } brand!`
                    );
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                message.error("Something went wrong!");
            });
    };

    const handleLogoChange = ({ fileList }) => {
        if (!isDeleteOn && !logoUploading && fileList.length > 0) {
            setBrandDetails({ ...brandDetails, logo: fileList });
            setLogoUploading(true);
            let formData = new FormData();
            formData.append("logo", fileList[0].originFileObj);
            formData.append("pk", props.brandPk);

            if (fileList[0].originFileObj !== undefined) {
                axios
                    .post(`${serverUrl}/add-nesto-brand-image/`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `JWT ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.data.status === 200) {
                            message.success("Logo updated successfully!");
                            setBrandDetails({
                                ...brandDetails,
                                logo: [
                                    {
                                        uid: "1",
                                        name: "logo.png",
                                        status: "done",
                                        url: res.data.logo_url,
                                    },
                                ],
                            });
                        } else {
                            message.error("Unable to update logo!");
                        }
                        setLogoUploading(false);
                    })
                    .catch((err) => {
                        message.error("Something went wrong!");
                    });
            }
        }
    };

    const removeBrandLogo = (file) => {
        console.log("delete");
        setIsDeleteOn(true);
        setLogoUploading(true);
        axios
            .post(
                `${serverUrl}/remove-nesto-brand-image/`,
                {
                    pk: props.brandPk,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === 200) {
                    message.success("Logo deleted successfully!");
                    setBrandDetails({ ...brandDetails, logo: [] });
                    setIsDeleteOn(false);
                    setLogoUploading(false);
                } else {
                    message.error("Unable to delete logo!");
                }
            })
            .catch((err) => {
                message.error("Something went wrong!");
            });
    };

    return (
        <div className="brand-details-page-container">
            <Row>
                <Col span={24}>
                    <Text
                        style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "20px",
                        }}
                    >
                        {props?.is_edit ? "Edit Brand" : "Add New Brand"}
                    </Text>
                </Col>
            </Row>
            <Form style={{ marginTop: 10 }}>
                <Row
                    style={{
                        backgroundColor: "#36404A",
                        padding: "20px",
                        width: "60%",
                        borderRadius: 8,
                    }}
                >
                    <Col span={24}>
                        <Form.Item
                            label="Brand Name"
                            name="name"
                            color="white"
                            style={{ color: "white", fontWeight: "lighter" }}
                        >
                            <Input
                                className="input-field"
                                style={{
                                    color: "white",
                                    backgroundColor: "#313841",
                                    marginTop: "3px",
                                }}
                                placeholder="Brand Name"
                                onChange={(e) => setBrandDetails({...brandDetails, brandName: e.target.value})}
                                value={brandDetails.brandName}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Brand Description"
                            name="description"
                            style={{ color: "white", fontWeight: "lighter" }}
                        >
                            <TextArea
                                className="input-field"
                                style={{
                                    color: "white",
                                    backgroundColor: "#313841",
                                    marginTop: "3px",
                                }}
                                rows={4}
                                placeholder="Add Description"
                                onChange={(e) => setBrandDetails({...brandDetails, aboutBrand: e.target.value})}
                                value={brandDetails.aboutBrand}
                            />
                        </Form.Item>
                    </Col>
                    {props.is_edit && (
                        <Col span={24}>
                            <Form.Item
                                label="Brand Logo"
                                name="description"
                                style={{
                                    color: "white",
                                    fontWeight: "lighter",
                                }}
                            >
                                {logoUploading ? (
                                    <div className="dealshub-admin-section-category-deletion-in-progress-container">
                                        <Spin
                                            indicator={
                                                <Icon
                                                    type="loading"
                                                    style={{ fontSize: 24 }}
                                                    spin
                                                />
                                            }
                                            tip={
                                                isDeleteOn
                                                    ? "Deleting Brand logo"
                                                    : "Uploading Brand logo"
                                            }
                                        />
                                    </div>
                                ) : (
                                    <Upload
                                        accept=".png, .jpg, .jpeg, .gif"
                                        listType="picture-card"
                                        fileList={brandDetails.logo}
                                        onChange={handleLogoChange}
                                        showUploadList={{
                                            showDownloadIcon: false,
                                            showPreviewIcon: false,
                                        }}
                                        onRemove={removeBrandLogo}
                                    >
                                        {brandDetails.logo.length < 1 && (
                                            <div>
                                                <img
                                                    src={CloudImport}
                                                    alt="cloud-upload"
                                                />
                                                <div className="ant-upload-text">
                                                    You can drag and drop images
                                                    or upload images
                                                </div>
                                            </div>
                                        )}
                                    </Upload>
                                )}
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24} style={{ float: "right" }}>
                        <Button
                            style={{
                                backgroundColor: "#1ABC9C",
                                borderColor: "#1ABC9C",
                            }}
                            loading={loading}
                            onClick={saveBrandDetails}
                        >
                            <Text style={{ color: "white" }}>
                                {props.is_edit ? "Save" : "Create"}
                            </Text>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default BrandDetailsPage;
