import React, { Component } from 'react';
import { Select, Icon } from "antd";

class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Select
                dropdownClassName="search-chips"
                dropdownMatchSelectWidth="false"
                mode="tags"
                style={{ width: "100%" }}
                placeholder={[<Icon type="search" />, " Search here..."]}
                onChange={this.props.getTagsList}
                value={this.props.tags}
            ></Select>
        );
    }
}

export default SearchInput;