import React, { Component } from 'react';
import { Row, Col, Typography, Button, Checkbox, Avatar, Icon, Tooltip, Tag, InputNumber, Select, Modal, Tabs } from 'antd';
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import UserAvatar from "../../media/male_avatar.png"
import "./factory-products.scss"
import InputField from "../components/datainputs/InputField"
import CalenderComponent from '../components/calender-component/CalenderComponent';
import EditorBox from '../components/EditorBox';

const { Title, Text } = Typography;
const { TabPane } = Tabs;



class PiDownloadForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tnc: {}
        };
    }

    handleChangeInvoiceDate = (e) => {
        this.props.handleChangeFormData(e, "invoice_date", this.props.factory_pk);
    }

    handelChange = (e) => {
        this.props.handleChangeFormData(e.target.value, e.target.name, this.props.factory_pk)
    }

    getEditorText = (e) => {
        //.log(e, "DELTA");
        this.setState((prevState) => ({
            tnc: { ...prevState.tnc, [this.props.factory_pk]: e }
        }))
        this.props.handleChangeFormData(e, "important_notes", this.props.factory_pk);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showMessages)
            nextProps.validator.showMessages();
        if (nextProps.factory_pk) {
            if (!prevState.tnc.hasOwnProperty(nextProps.factory_pk)) {
                nextProps.handleChangeFormData("", "important_notes", nextProps.factory_pk);
                nextProps.handleChangeFormData(new Date(), "invoice_date", nextProps.factory_pk);
                return {
                    tnc: { ...prevState.tnc, [nextProps.factory_pk]: "" }
                }
            }
            else return prevState;
        }
        else return prevState;
    }

    render() {

        //.log(this.props, "DELTA");
        //.log(this.state, "DELTA");

        return (
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "payment_terms",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["payment_terms"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "payment_terms",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["payment_terms"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Payment Terms"
                    />
                </Col>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "advance",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["advance"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "advance",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["advance"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Advance"
                    />
                </Col>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "inco_terms",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["inco_terms"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "inco_terms",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["inco_terms"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Inco Terms"
                    />
                </Col>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "ttl_cntrs",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["ttl_cntrs"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "ttl_cntrs",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["ttl_cntrs"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="TTL CNTRS"
                    />
                </Col>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "delivery_terms",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["delivery_terms"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "delivery_terms",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["delivery_terms"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Delivery Terms"
                    />
                </Col>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "invoice_number",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["invoice_number"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "invoice_number",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["invoice_number"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Invoice Number"
                    />
                </Col>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "discharge_port",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["discharge_port"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "discharge_port",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["discharge_port"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Discharge Port"
                    />
                </Col>
                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "vessel_details",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["vessel_details"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "vessel_details",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["vessel_details"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Vessel Details "
                    />
                </Col>



                <Col span={12}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "shipment_lot_no",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["shipment_lot_no"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "shipment_lot_no",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["shipment_lot_no"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Shipment Lot No"
                    />
                </Col>

                <Col span={12}>

                    <CalenderComponent
                        label="Invoice Date"
                        impo
                        labelStyle={{ color: "rgba(255, 255, 255, 0.6)" }}
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "invoice_date",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["invoice_date"] : "",
                                "required"
                            )
                        }
                        getDate={this.handleChangeInvoiceDate}
                        name="invoice_date"
                        value={this.props.formData[this.props.factory_pk] ?
                            (this.props.formData[this.props.factory_pk]["invoice_date"] ? this.props.formData[this.props.factory_pk]["invoice_date"] : new Date()) :
                            new Date()}
                        size="large"
                    />

                    {/* <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "invoice_date",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["invoice_date"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "invoice_date",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["invoice_date"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Invoice Date"
                    /> */}
                </Col>

                <Col span={24}>
                    <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "vessel_final_destination",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["vessel_final_destination"] : "",
                                "required"
                            )
                        }
                        impo
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "vessel_final_destination",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["vessel_final_destination"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Vessel Final Destination"
                    />
                </Col>

                <Col span={24}>

                    <Text>
                        Terms & Conditions
                         {/* <Text style={{ color: "#EF556B" }}>*</Text> */}
                    </Text>
                    {/* <Text style={{ color: "red", fontSize: "8px" }}>
                        {
                            this.props.validator &&
                            this.props.validator.message(
                                "important_notes",
                                this.state.tnc[this.props.factory_pk],
                                "required"
                            )
                        }
                    </Text> */}

                    {/*  for backend it is Important Notes */}
                    {/* <Text style={{ color: "rgba(255, 255, 255, 0.6)" }}>
                        {`Terms & Conditions`}
                    </Text> */}

                    <EditorBox
                        value={this.state.tnc[this.props.factory_pk] || ""}
                        getText={this.getEditorText}
                        name="important_notes"
                    />

                    {/* <InputField
                        errormsg={
                            this.props.validator &&
                            this.props.validator.message(
                                "important_notes",
                                this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["important_notes"] : "",
                                "required"
                            )
                        }
                        impo
                        textarea
                        label={{ type: "secondary" }}
                        input={{
                            size: "large",
                            name: "important_notes",
                            value: this.props.formData[this.props.factory_pk] ? this.props.formData[this.props.factory_pk]["important_notes"] : "",
                            disabled: this.props.disabled
                        }}
                        onChange={this.handelChange}
                        labelText="Terms & Conditions"
                    /> */}
                </Col>
            </Row>
        );
    }
}

export default PiDownloadForm;