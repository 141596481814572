import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row, Col, Typography, Collapse, Input, Empty, Button, Radio, message } from "antd";
import WigLogoImage from "../../../media/WIGME.PNG";
import KryptonLogoImage from "../../../media/krypton-logo.png"
import { Ellipsis } from "react-spinners-css";
import OrdersAccountManagerMobileViewRow from "../OrdersAccountManagerMobileViewRow";
import axios from "axios"
import isDev from "server";
import {
    setShippingMethod,
} from "actions/ordersManager";
import { useRefreshNotification } from "hooks/useRefreshNotification";

const serverOmnycommUrl = isDev();
const { Text, Title } = Typography;
const { Panel } = Collapse;
export default function AssignOrderModal(props) {

    const refreshNotification = useRefreshNotification()
    const [okDisabled, setOkDisabled] = useState(-1);
    const [xlist, setXlist] = useState({});
    const [loading, setLoading] = useState(false);
    const [modal_info_list_copy, set_modal_info_list_copy] = useState({});
    const [disableOk, setDisableOk] = useState(false);
    const dispatch = useDispatch();
    const isSapManualUpdate = useSelector((state) => state.manualSAPOverride.get(props.AssignModalOrder.bundleId));
    useEffect(() => {
        if (props.AssignModalOrder && props.AssignModalOrder.unitOrderList)
            setXlist(Array(props.AssignModalOrder.unitOrderList.length).fill(0))
    }, [props.AssignModalOrder.unitOrderList])

    const handleOkResend = () => {
        let user_input_sap = {};
        setLoading(true);
        for (let i = 0; i < props.modal_info_list.length; i++) {
            user_input_sap[props.modal_info_list[i].seller_sku] = xlist[props.modal_info_list[i].seller_sku] == 1 ? "X" : ""
        }

        axios.post(`${serverOmnycommUrl}/dealshub/resend-sap-order/`, {
            unitOrderUuidList: props.resend ? undefined : props.unitOrderUuidList,
            user_input_sap,
            orderUuid: props.AssignModalOrder.uuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            setLoading(false);
            if (response.data.status === 200) {
                message.success('Changes applied successfully!');
                // dispatch(setShippingMethod(
                //     props.orderTypeString,
                //     props.ordersManagerDetails,
                //     props.unitOrderUuidList
                //   ));
                props.closeThis();
            } else if (response.data.status === 403 && response.data.message) {
                message.error(response.data.message);
            } else {
                message.error('Something went wrong!\nTry refreshing the page!');
            }
        })
    }

    const handleOk = () => {
        let user_input_sap = {};
        setLoading(true);
        for (let i = 0; i < props.modal_info_list.length; i++) {
            user_input_sap[props.modal_info_list[i].seller_sku] = xlist[props.modal_info_list[i].seller_sku] == 1 ? "X" : ""
        }
        console.log(user_input_sap, "user input sap")
        axios.post(`${serverOmnycommUrl}/dealshub/set-shipping-method/`, {
            shippingMethod: props.shippingMethod,
            unitOrderUuidList: props.unitOrderUuidList,
            isSapManualUpdate: (!isSapManualUpdate) ? false : isSapManualUpdate,
            user_input_sap
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            setLoading(false);
            if (response.data.status === 200) {
                message.success('Changes applied successfully!');
                refreshNotification.dispatch()
                dispatch(setShippingMethod(
                    props.orderTypeString,
                    props.ordersManagerDetails,
                    props.unitOrderUuidList
                ));
                props.closeThis();
            } else if (response.data.status === 403 && response.data.message) {
                refreshNotification.remove()
                message.error(response.data.message);
            } else {
                message.error('Something went wrong!\nTry refreshing the page!');
            }
        })
    }


    useEffect(() => {
        //modal_info_list to dict in which modal_info_list mapped to it's uuid
        if (props.modal_info_list) {
            let modal_info_list_copy = {};
            for (let i = 0; i < props.modal_info_list.length; i++) {
                modal_info_list_copy[props.modal_info_list[i].uuid] = props.modal_info_list[i];
            }

            props.AssignModalOrder && props.AssignModalOrder.unitOrderList && props.AssignModalOrder.unitOrderList.forEach((product, index) => {
                if (modal_info_list_copy[product["uuid"]]) {
                    if (modal_info_list_copy[product["uuid"]]['disable_atp_holding']) {
                        setDisableOk(true)
                    }
                }
            })

            set_modal_info_list_copy(modal_info_list_copy);
        }

    }, [props.modal_info_list])

    return (
        <React.Fragment>
            <Modal
                destroyOnClose={true}
                title="Assign Order"
                visible={props.visible}
                footer={[
                    <Button key="back" onClick={() => {
                        props.closeThis()
                    }}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={okDisabled === -1 || loading || disableOk} onClick={props.resend ? handleOkResend : handleOk}>
                        Ok
                    </Button>,
                ]}
                onCancel={() => {
                    props.closeThis()
                }}
                className="product-export-modal"
                width={"70vw"}
                destroyOnClose={true}
            >

                <React.Fragment>
                    <Row gutter={[12, 12]} style={{
                        backgroundColor: "#36404A",
                        padding: "10px 5px 10px 5px",
                        marginBottom: "10px"
                    }} type="flex"
                        align="middle">
                        <Col span={9}>
                            <Title level={4} style={{ color: "white", margin: "0px", padding: "0px", fontSize: "14px" }}>Product</Title>
                        </Col>
                        <Col span={3}>
                            <Title level={4} style={{ color: "white", margin: "0px", padding: "0px", fontSize: "14px" }}>ATP Threshold</Title>
                        </Col>
                        <Col span={3}>
                            <Title level={4} style={{ color: "white", margin: "0px", padding: "0px", fontSize: "14px" }}>Total ATP</Title>
                        </Col>
                        <Col span={3}>
                            <Title level={4} style={{ color: "white", margin: "0px", padding: "0px", fontSize: "14px" }}>Holding Threshold</Title>
                        </Col>
                        <Col span={3}>
                            <Title level={4} style={{ color: "white", margin: "0px", padding: "0px", fontSize: "14px" }}>Total Holding</Title>
                        </Col>
                    </Row>
                    {
                        props.AssignModalOrder && props.AssignModalOrder.unitOrderList && props.AssignModalOrder.unitOrderList.map((product, index) => {

                            //check if product present in modal_info_list
                            if (modal_info_list_copy[product["uuid"]]) {

                                return (
                                    <Row
                                        key={index}
                                        gutter={[12, 12]}
                                        type="flex"
                                        align="middle"
                                    >
                                        <Col span={1}>
                                            <img style={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "4px"
                                            }} src={product.productImageUrl} />
                                        </Col>
                                        <Col span={7}>
                                            <Typography.Paragraph
                                                ellipsis={{
                                                    editing: false,
                                                    rows: 3
                                                }}
                                                style={{
                                                    margin: "0px"
                                                }}
                                            >{product.productName}</Typography.Paragraph>
                                        </Col>

                                        <Col span={1}>
                                        </Col>

                                        <Col span={3}>
                                            <Text>{modal_info_list_copy[product["uuid"]].atp_threshold}</Text>
                                        </Col>
                                        <Col span={3}>
                                            <Text>{modal_info_list_copy[product["uuid"]].total_atp}</Text>

                                        </Col>
                                        <Col span={3}>
                                            <Text>{modal_info_list_copy[product["uuid"]].holding_threshold}</Text>
                                        </Col>
                                        <Col span={3}>
                                            <Text>{modal_info_list_copy[product["uuid"]].total_holding}</Text>
                                        </Col>
                                        <Col span={3}>
                                            <Radio.Group value={xlist.length != 0 && xlist[modal_info_list_copy[product["uuid"]]["seller_sku"]]} onChange={(e) => {
                                                //update xList accoriding to seller_sku not index
                                                let xList_copy = xlist;
                                                xList_copy[modal_info_list_copy[product["uuid"]]["seller_sku"]] = e.target.value;
                                                setXlist({ ...xList_copy });
                                                setOkDisabled(e.target.value);
                                            }}>
                                                <Radio value={0} disabled={modal_info_list_copy[product['uuid']]['disable_atp'] || modal_info_list_copy[product['uuid']]['disable_atp_holding']}>
                                                    ATP
                                                </Radio>
                                                <Radio value={1} disabled={modal_info_list_copy[product['uuid']]['disable_holding'] || modal_info_list_copy[product['uuid']]['disable_atp_holding']}>
                                                    Holding
                                                </Radio>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                            else return null;

                        })
                    }
                </React.Fragment>
            </Modal>
        </React.Fragment>
    )
}