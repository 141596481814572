import React,{useState,useEffect} from 'react'
import {Button,Row,Col,Typography,Collapse,Card,DatePicker,Input,Radio,Checkbox,Switch,Tag, message,Select} from 'antd'
import axios from 'axios'
import './dealshubvouchers.scss'
import moment from "moment"
import isDev from "server"
const { TextArea } = Input;
const { Option } = Select;

const {Title,Text} = Typography
const {Panel} = Collapse
const {RangePicker} = DatePicker
const serverUrl = isDev()

const fetchVoucher = ({setVoucherData} , locationUuid) => {
    axios({
        method:'post',
        url: `${serverUrl}/dealshub/fetch-vouchers/`,
        data: {
            locationGroupUuid : locationUuid
        },
        headers: {
            'Authorization': `JWT ${localStorage.getItem("token")}`
        }
    })
    .then(response=>{
        if(response.data.status == 200)
        {
            // console.log('voucher-data',response.data.voucher_list);
            setVoucherData(response.data.voucher_list)
        }
        else if(response.data.status === 405)
        {
          message.info("You are not authorised!")
        }
        else
        {
            message.error("Something went wrong!");
        }
    })
}
const fetchCategories = ({setSuperCategories}) => {
    let data = {

    };
    axios.post(`${serverUrl}/dealshub/fetch-super-categories/`, {
        websiteGroupName: localStorage.getItem("websiteGroupName")
    }, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`
        }
    })
      .then(data => {

        if (data.status === 500)
          message.error("Internal Server Error!");
          else if(data.status === 405)
          {
            message.info("You are not authorised!")
          }
        else {
            console.log("Setting super_category to ",data.data.superCategoryList);
            setSuperCategories(data.data.superCategoryList);
        //   this.setState({
        //     super_categories: data.super_category_list
        //   })
        }

      });
  }
const updateVoucher = (data) => {
    console.log('data sending ',data);
    axios({
        method:'post',
        url: `${serverUrl}/dealshub/update-voucher/`,
        data,
        headers: {
            'Authorization': `JWT ${localStorage.getItem("token")}`
        }
    })
    .then(response=>{

          if(response.data.status == 200)
            {
                
                message.success(`Voucher is updated!`)
               
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else
            {
                message.error("Something went wrong!");
            }
    
    })
}

const Voucher = ({item , ...props}) => {

    const [value,setValue] = useState(item.voucher_type)
    const [valid,setvalid] = useState(item.is_published)
    const [voucherCode  ,setVoucherCode] = useState(item.voucher_code)
    const [fixedDiscount , setFixedDiscount] = useState(item.fixed_discount)
    const [minimumPurchaseAmount , setMinimumPurchaseAmount] = useState(item.minimum_purchase_amount);
    const [maximumUsageLimit , setMaximumUsageLimit] = useState(item.maximum_usage_limit == 0 ? "" : item.maximum_usage_limit);
    const [customerUsageLimit , setCustomerUsageLimit] = useState(item.customer_usage_limit == 0 ? "" : item.customer_usage_limit);
    const [customerUsageLimitOn , setCustomerUsageLimitOn] = useState(item.customer_usage_limit ? true : false);
    const [maximumUsageLimitOn , setMaximumUsageLimitOn] = useState(item.maximum_usage_limit ? true : false);
    const [dateRange , setDateRange] = useState({
        "start_time" : item.start_time,
        "end_time" : item.end_time
    })
    const [superCategoryList,setselectedSuperCategory]=useState(item.superCategoryList);
    const [precentageDiscount , setPrecentageDiscount] = useState(item.percent_discount || 0);
    const [maximumDiscount , setMaximumDiscount] = useState(item.maximum_discount)
    const [toggleLoading , setToggleLoading] = useState(false);
    const dateFormat = 'YYYY/MM/DD';
    const defaultDate = dateRange.start_time == null ? [moment() , moment()] : [moment(dateRange.start_time) , moment(dateRange.end_time)]

    // VOUCHER DESCRIPTION 
    const[voucherdescription,setVoucherDescription]=useState(item.description);
    
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    }

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value)
    }

    const toggel = () => {
        setToggleLoading(true);
        let api_str = valid ? "unpublish" : "publish";
        axios({
            method:'post',
            url: `${serverUrl}/dealshub/${api_str}-voucher/`,
            data : {
                voucher_uuid : item.uuid
            },
            headers: {
                'Authorization': `JWT ${localStorage.getItem("token")}`
            }
        })
        .then(response=>{console.log("update Voucher: ",response)
            setToggleLoading(false);

            if(response.data.status == 200)
            {
                setvalid(!valid)
                if(!valid)
                message.success(`Voucher is published!`)
                else
                message.success(`Voucher is unpublished!`)
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else
            {
                message.error("Something went wrong!");
            }
        })
    }

     const genExtra = (str) => {
        return (
            <>
                <Switch
                    checked={valid}
                    loading = {toggleLoading}
                    onChange={(event)=>
                        {
                           toggel()
                            // event.stopPropagation();
                        }}
                    style={{ marginRight: '10px' }}
                />
                {/* <Tag color={str[1]}>{str[0]}</Tag> */}
            </>
        );
    }

    const handleChange = (e) => {
        setDateRange({
            "start_time": e[0].toDate(),
            "end_time": e[1].toDate()
        });
    }

   

    return (
        <React.Fragment>
            <Collapse expandIconPosition="left">
                <Panel
                    header = {voucherCode}
                    className="dealshub-admin-collapse-panel"
                    extra={genExtra([ `${value}`, "blue"])}
                    style={{padding:"20px"}}
                >
                    <div
                        className="content-container"
                        style={{ padding: "20px" }}
                    >
                        <Row gutter={[16,16]}>
                            <Col span={24}>
                                <Card>
                                    <Text className="text-color3">Discount Code</Text>
                                        <Input value = {voucherCode} onChange = {(e) => setVoucherCode(e.target.value)} style={{margin:"10px 0px"}} placeholder="e.g. SPRINGSALE"  />
                                        <Text>Customers will enter this discount code at checkout.</Text>    
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Text className="text-color3">Types</Text>
                                    <Row>
                                        <Radio.Group onChange={onChange} value={value}>
                                            <Radio style={radioStyle} value="FD">
                                                Flat Discount
                                            </Radio>
                                            <Radio style={radioStyle} value="SD">
                                                Free Shipping
                                            </Radio>
                                            <Radio style={radioStyle} value="PD">
                                                Percentage Discount
                                            </Radio>
                                        </Radio.Group>
                                    </Row>
                                </Card>
                            </Col>
                            {value !== "SD" &&
                            <Col span={24}>
                                <Card>
                                    
                                    <Row gutter={[32,0]}>
                                        <Col span={12} >
                                            {/* <Text style={{marginTop:"10px 5px 0px"}} >Discount Value</Text> */}
                                            <Text className="text-color3" >Discount Value</Text>
                                            {
                                                value === "PD" ? 
                                                <Input placeholder={"Percentage"} type = "number" style = {{marginTop : "10px"}}  value = {precentageDiscount} onChange = {(e) => {
                                                    setPrecentageDiscount(e.target.value)
                                                }} /> : 
                                                <Input type = "number" placeholder={"Value"} style = {{marginTop : "10px"}} value = {fixedDiscount} onChange = {(e) => setFixedDiscount(e.target.value)} />
                                            }
                                        </Col>
                                        {value !== "FD" &&
                                        <Col span={12} >
                                            {/* <Text style={{margin:"5px 0px"}}>Maximum Discount</Text> */}
                                            <Text className="text-color3">Maximum Discount</Text>
                                            <Input placeholder="Value" type = "number" style = {{marginTop : "10px"}} value = {maximumDiscount} onChange = {(e) => setMaximumDiscount(e.target.value)}  />
                                        </Col>
                                        }
                                    </Row>
                                </Card>
                            </Col>}
                            <Col span={24}>
                                <Card>
                                    <Text className="text-color3">Minimum Cart Value</Text>
                                    <Input style={{marginTop:"10px"}} type = "number" value = {minimumPurchaseAmount} onChange = {(e) => {
                                        setMinimumPurchaseAmount(e.target.value)
                                    }} placeholder="minimum requirement - leave blank if none" />
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Text className="text-color3">Super Category List</Text>
                                    <Col span={24} style={{ marginTop: '18px' }}>
                                                        <Select
                                                          mode="multiple"
                                                          style={{ width: '100%' }}
                                                          defaultValue={superCategoryList}
                                                          placeholder="Please select"
                                                          onChange={(value)=>{
                                                            setselectedSuperCategory({...superCategoryList,superCategoryList:value})
                                                            // console.log('new list ',superCategoryList);
                                                          }}
                                                        >
                                                            {/* <Option key='test1' value='test-value 1'>Test Item 1</Option>
                                                            <Option key='test2' value='test-value 2'>Test Item 2</Option>
                                                            <Option key='test3'value='test-value 3'>Test Item 3</Option>
                                                            <Option key='test4' value='test-value 4'>Test Item 4</Option>
                                                            <Option key='test5' value='test-value 5'>Test Item 5</Option>
                                                            <Option key='test6' value='test-value 6'>Test Item 6</Option>
                                                            <Option key='test7' value='test-value 7'>Test Item 7</Option>
                                                            <Option key='test8' value='test-value 8'>Test Item 8</Option> */}
                                                            {props.super_category?.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item.name}>{item.name}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Col>
                                </Card>
                            </Col> 
                            {/* Voucher Description */}
                            <Col span={24}>
                                <Card>
                                    <Text className="text-color3">Voucher Description</Text>
                                    <TextArea style={{marginTop:"10px"}} type = "text" value = {voucherdescription} onChange = {(e) => {
                                        setVoucherDescription(e.target.value)
                                    }} placeholder="Voucher Description - leave blank if none" />
                                </Card>
                            </Col>
                                    


                            <Col span={24}>
                                <Card>
                                    <Text className="text-color3">Usage Limit</Text>  
                                    <br/>
                                    <Row>
                                        {/* <Checkbox checked = {maximumUsageLimitOn} onClick = {() => setMaximumUsageLimitOn(!maximumUsageLimitOn)}>Limit number of times this discount can be used in total</Checkbox> */}
                                        Limit number of times this discount can be used in total
                                        <Input 
                                        // disabled = {!maximumUsageLimitOn}
                                         value = {maximumUsageLimit} onChange = {(e) => {
                                            setMaximumUsageLimit(e.target.value == 0 ? "" : e.target.value)
                                            // if(e.target.value == 0)setMaximumUsageLimitOn(false);
                                        }}
                                        type = "number"
                                        style={{width:"100px", marginLeft:"10px"}} />
                                    </Row>
                                    <Row>
                                        {/* <Checkbox checked = {customerUsageLimitOn} onClick = {() => setCustomerUsageLimitOn(!customerUsageLimitOn)}>Limit usage per customer</Checkbox> */}
                                        Limit usage per customer
                                        <Input
                                        //  disabled = {!customerUsageLimitOn} 
                                         value = {customerUsageLimit} onChange = {(e) => {
                                            setCustomerUsageLimit(e.target.value == 0 ? "" : e.target.value)
                                            // if(e.target.value == 0)setCustomerUsageLimitOn(false);
                                        }} type = "number" style={{width:"100px", marginLeft:"10px"}} />
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Text className="text-color3">Validity Period</Text>
                                    <br/>  
                                    <RangePicker
                                         format={dateFormat}
                                         onChange={handleChange}
                                         defaultValue = {defaultDate}
                                    style={{marginTop:"5px"}} />
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{marginTop:"20px"}}>
                            <Button type="primary" style={{marginLeft:"10px"}} onClick={() => updateVoucher(
                               {
                                    voucher_uuid : item.uuid,
                                    voucher_code : voucherCode,
                                    start_time : dateRange.start_time,
                                    end_time : dateRange.end_time,
                                    voucher_type : value,
                                    percent_discount : precentageDiscount == "" ? 0 : precentageDiscount, 
                                    fixed_discount : fixedDiscount == "" ? 0 : fixedDiscount,
                                    maximum_discount : maximumDiscount == "" ? 0 : maximumDiscount,
                                    minimum_purchase_amount : minimumPurchaseAmount == "" ? 0 : minimumPurchaseAmount,                                                                                                      
                                    customer_usage_limit : customerUsageLimit == "" ? 0 : customerUsageLimit,   
                                    maximum_usage_limit: maximumUsageLimit == "" ? 0 : maximumUsageLimit,   
                                    description:voucherdescription||"",
                                    superCategoryList:superCategoryList.superCategoryList ==undefined ?item.superCategoryList : superCategoryList.superCategoryList

                               }
                            )}>Update</Button>
                            <Button type="danger" style={{marginLeft:"10px"}} onClick={() => {props.DeleteVoucher(item.uuid)}}>Delete</Button>
                        </Row>
                    </div>
                </Panel>
            </Collapse>
        </React.Fragment>
    )
}


const DealsHubVouchers = (props) => {

    const [voucherData,setVoucherData] = useState([])
    const [super_categories,setSuperCategories]=useState([]);
    useEffect(() => {
        fetchCategories({setSuperCategories},super_categories)
        fetchVoucher({setVoucherData} , props.locationUuid)
    }, [props.locationUuid])


    const createVoucher = () => {
        axios({
            method:'post',
            url: `${serverUrl}/dealshub/create-voucher/`,
            data : {
                locationGroupUuid : props.locationUuid
            },
            headers: {
                'Authorization': `JWT ${localStorage.getItem("token")}`
            }
        })
        .then(response=>{
            if(response.data.status == 200)
            {
                setVoucherData([...voucherData , {uuid : response.data.uuid , voucher_code : "VOUCHER" , voucher_type : "FD" , 
                start_time : null,
                end_time : null,
                fixed_discount : 0,
                maximum_discount: 0,
                maximum_usage_limit: 0,
                minimum_purchase_amount: 0,
                customer_usage_limit: 1,
                valid : false
            }])
                message.success("Voucher is created!");
                window.location.href=window.location.href;
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else
            {
                message.error("Something went wrong!");
            }
        })
    }

    const DeleteVoucher = (uuid) => {
        axios({
            method:'post',
            url: `${serverUrl}/dealshub/delete-voucher/`,
            data : {
                voucher_uuid : uuid
            },
            headers: {
                'Authorization': `JWT ${localStorage.getItem("token")}`
            }
        })
        .then(response=>{
            if(response.data.status == 200)
            {
                setVoucherData(voucherData.filter((e) => e.uuid != uuid))
                message.success("Voucher is deleted!")
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else
            {
                message.error("Something went wrong!");
            }
        })
    }

    return (
        <Row className="dealshubadmincontainer">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
            >
              <Col>
                <Title style={{ marginBottom: "0px", marginTop: "1em", color: "white" }} level={4}>
                  Vouchers
              </Title>
              </Col>
              <Col>
                <Button type="primary" onClick = {() => createVoucher()}>
                    Add Voucher
              </Button>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" style={{marginTop:"3em"}}>
                <Col span={24}>
                    {
                        voucherData && voucherData.map((item , index) => {
                            return (
                                <React.Fragment>
                                <Voucher DeleteVoucher = {DeleteVoucher} super_category={super_categories} key = {item.uuid} item = {item} locationUuid = {props.locationUuid} />
                                <br/>
                                </React.Fragment>
                            )
                        })
                    }
                </Col>
            </Row>
        </Row>
    )
}

export default DealsHubVouchers