import React, {Component} from "react";
import {Input, Typography} from "antd";

import "./datainput.scss";

const {Text} = Typography;

export default class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const star = <Text style={{color: "#EF556B"}}>*</Text>;

        const err = (
            <Text style={{color: "red", fontSize: "8px"}}>
                {this.props.errormsg}
            </Text>
        );

        return (
            <React.Fragment>
                <Text {...this.props.label} style={{color: 'rgba(255, 255, 255, 0.8)', fontSize: '16px'}}>
                    {this.props.labelText} {this.props.impo ? star : ""}{" "}
                </Text>
                {err}

                {this.props.textarea ?
                    <Input.TextArea
                        {...this.props.input}
                        style={{
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                            background: "#3C4853",
                            marginTop: '10px',
                            borderRadius: '2px'
                        }}
                        onChange={this.props.onChange}
                    /> :
                    <Input
                        {...this.props.input}
                        disabled={this.props.disabled}
                        style={{
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                            background: "#3C4853",
                            marginTop: '10px',
                            borderRadius: '2px'
                        }}
                        onChange={this.props.onChange}
                    />
                }


                <Text type="danger"> {this.props.error || ""} </Text>
            </React.Fragment>
        );
    }
}

{
    /* <InputField impo label={{ type: "secondary" }} input={{ size: "large" }} labelText="Product Name" /> */
}
