import React, { useState, useEffect } from "react";
import { Typography, Input, Icon, message } from "antd";

import isDev from "../../server";
const serverUrl = isDev();
export default function ProductListCrudItems(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(props.value);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  let Ref = React.createRef();

  useEffect(() => {
    setValue(props.value);
  }, [props.value])

  function isFloat(val) {
    var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val))
      return false;

    val = parseFloat(val);
    if (isNaN(val))
      return false;
    return true;
  }

  function isInt(val) {
    var intRegex = /^-?\d+$/;
    if (!intRegex.test(val))
      return false;

    var intVal = parseInt(val, 10);
    return parseFloat(val) == intVal && !isNaN(intVal);
  }

  const saveData = () => {

    if (!isFloat(inputValue) && props.type == "float") {
      message.info("only float values are aceptable");
      return;
    }

    if (!isInt(inputValue) && props.type == "int") {
      message.info("only Integer values are aceptable");
      return;
    }


    let data = {
      [props.name]: inputValue,
      ...props.request_data
    };
    setLoading(true);
    fetch(`${serverUrl}/${props.url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsEdit(false);
        if (data.status == 200) {
          let str = props.name.split("_");
          for(let i=0; i<str.length; i++){
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
          }
          message.success(str.join(" ") + " updated successfully!")
          setValue(inputValue);
          if(props.callBackAfterUpdate)
           props.callBackAfterUpdate();
        }
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
        else message.error("Something went wrong during update");
      });
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {

      saveData();
    }
  };

  useEffect(() => {
    if (isEdit) {
      Ref.current.focus();
    }
  }, [isEdit]);

  //.log(props, "price");

  return (
    <React.Fragment>
      {isEdit ? (
        <React.Fragment>
          <Input
            ref={Ref}
            size="small"
            style={{ marginTop: "5px", width: "90%" }}
            onKeyDown={_handleKeyDown}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            suffix={loading ? <Icon type="loading" /> : <Icon style={{ color: "#59CAAA" }} onClick={saveData} type="check-circle" />}
          />
        </React.Fragment>
      ) : (
          <React.Fragment>
            <Typography.Text
              style={{
                textDecoration: props.line_through ? "line-through" : "",
              }}
            >
              {value}
            </Typography.Text>{" "}
            <Icon style={{ color: "#3CC9A9", fontSize: "10px" }} type="edit" onClick={() => {
              props.editOn();
              setIsEdit(true);
              setInputValue(value);
            }} />
          </React.Fragment>
        )}
    </React.Fragment>
  );
}
