import React, { Component } from "react";
import { Input, Typography, Row, Col } from "antd";
import { Select } from "antd";
import "./datainput.scss";
const { Option } = Select;
const { Text } = Typography;

class InputNumberDimesions3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handelChange = e => {

    this.props.getData(e);
  };

  handleChangeM = (e, name) => {
    this.props.getMetriD(e, name);
  };

  render() {
    const selectAfter1 = (
      <Select
        value={this.props.valueunit1}
        name={this.props.unit1}
        onChange={e => this.handleChangeM(e, this.props.unit1)}
        style={{ width: 80 }}
        disabled={this.props.disabled}
      >
        {this.props.list1.map(e => {
          return <Option value={`${e}`}>{e}</Option>;
        })}
      </Select>
    );

    const selectAfter2 = (
      <Select
        value={this.props.valueunit2}
        name={this.props.unit2}
        onChange={e => this.handleChangeM(e, this.props.unit2)}
        style={{ width: 80 }}
        disabled={this.props.disabled}
      >
        {this.props.list2.map(e => {
          return <Option value={`${e}`}>{e}</Option>;
        })}
      </Select>
    );

    const selectAfter3 = (
      <Select
        value={this.props.valueunit3}
        name={this.props.unit3}
        onChange={e => this.handleChangeM(e, this.props.unit3)}
        style={{ width: 80 }}
        disabled={this.props.disabled}
      >
        {this.props.list3.map(e => {
          return <Option value={`${e}`}>{e}</Option>;
        })}
      </Select>
    );

    const star = <Text style={{ color: "#EF556B" }}>*</Text>;

    const err = (
      <Text style={{ color: "red", fontSize: "8px" }}>
        {this.props.errormsg}
      </Text>
    );

    return (
      <React.Fragment>
        <Text {...this.props.label}>
          {this.props.labelText} {this.props.impo ? star : ""}{" "}
        </Text>

        {err}

        <Row>
          <Col sm={8}>
            <Row>
              <Col sm={24}>
                <Input
                  style={{
                    border: "1px solid #45525f",
                    borderRadius: "5px 0px 0px 5px",

                  }}
                  {...this.props.input}
                  name={this.props.name1}
                  value={this.props.value1}
                  onChange={this.handelChange}
                  addonAfter={selectAfter1}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={8}>
            <Row>
              <Col sm={24}>
                <Input
                  style={{
                    border: "1px solid #45525f",
                    borderRadius: "0px",

                  }}
                  className="input-number-dimensions-3"
                  {...this.props.input}
                  name={this.props.name2}
                  value={this.props.value2}
                  onChange={this.handelChange}
                  addonAfter={selectAfter2}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={8}>
            <Row>
              <Col sm={24}>
                <Input
                  style={{
                    border: "1px solid #45525f",
                    borderRadius: "0px 5px 5px 0px",

                  }}
                  {...this.props.input}
                  name={this.props.name3}
                  value={this.props.value3}
                  onChange={this.handelChange}
                  addonAfter={selectAfter3}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InputNumberDimesions3;
