import { useDispatch } from 'react-redux'
import { openRefreshPageNotification, closeRefreshPageNotification } from '../actions/refreshNotificationAction'


/**
 * Use this hook to display the notificaton bar in the top of the screen to notify the user to refresh the page.
 * @returns The hook returns two functions dispatch and remove
 */
export function useRefreshNotification() {
    const reduxDispatcher = useDispatch()

    /**
     * The dispatch function is used to show the refresh page notification in the screen.
     * @param {number | string} duration An optional parameter. Provide the duration in milliseconds to make the notification disappear. 
     */
    const dispatch = (duration) => {
        if (duration) {
            reduxDispatcher(openRefreshPageNotification({ duration }))
        }
        reduxDispatcher(openRefreshPageNotification())
    }

    /**
     * The remove function is used to explicity remove the refresh page notification icon
     */
    const remove = () => {
        reduxDispatcher(closeRefreshPageNotification())
    }

    return { dispatch, remove }
}

/**
 * Note: If you are using the class based componennt just, pass the "openRefreshPageNotification" and "closeRefreshNotification" into the mapDispatchToProps from the
 * refreshNotificationAction.js
 */