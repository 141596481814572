/**
 * @description This reducer fetches and manages the state for downloading product information on exports page
 */

/**
 * @typedef ExportPageStateType
 * @property {{exportList: Array}} exportListdata - all the data which can be exported
 */

const initialState = {
  exportListdata: {
    exportList: [],
  },
};

/**
 *
 * @param {ExportPageStateType} state
 * @param {object} action
 * @returns {ExportPageStateType}
 */

export default function exportListData(state = initialState, action) {
  switch (action.type) {
    case "FETCH_EXPORT_LIST_PAGE":
      return {
        ...state,
        exportListdata: { ...state.exportListdata, exportList: action.payload },
      };
    default:
      return state;
  }
}
