import React, { Component } from "react";
import { Collapse, Icon, Row, Col, Select, Typography, Switch } from "antd";
import "./varients-info.scss";
import InputField from "../../components/datainputs/InputField";
import InputNumberDimensions3 from "../../components/datainputs/InputNumberDimesions3";

//formik
import { withFormik } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { handelEditProductDetails } from "../../../actions/index";

import "./varients-info.scss";

const { Panel } = Collapse;

const { Option } = Select;
const { Text } = Typography;

class VarientsInfoVital extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  customPanelStyle = {
    borderRadius: 4,
    // marginBottom: 24,
    border: 0,
    overflow: "hidden"
    // padding: "10px 0px 10px 0px",
  };

  collapsedStyle = {
    maxHeight: "50vh",
    overflow: "scroll",
    paddingTop: "5px"
  };

  collapsBlock = {
    margin: 0
  };

  onChange = e => {

    this.props.handelEditProductDetails({
      ...this.props.productDetails,
      ["product_id_type"]: e
    });
    this.props.validator.showMessages();
  };

  onBlur = () => {

  };

  onFocus = () => {

  };

  onSearch = val => {

  };

  handleChange = e => {
    this.props.handelEditProductDetails({
      ...this.props.productDetails,
      [e.target.name]: e.target.value
    });
    this.props.validator.showMessages();
  };

  handleBundleProduct = e => {
    this.props.handelEditProductDetails({
      ...this.props.productDetails,
      "is_bundle_product": e
    });
    this.props.validator.showMessages();
  }
  
  handleSapException = e => {
    this.props.handelEditProductDetails({
      ...this.props.productDetails,
      "is_sap_exception": e
    });
    this.props.validator.showMessages();
  }

  render() {
    const {
      product_name,
      product_id,
      product_id_type,
      barcode_string,
      factory_code,
      min_price,
      max_price,
      variant_price_permission,
      is_bundle_product,
      is_sap_exception,
      holding_threshold,
      atp_threshold
    } = this.props.productDetails;

    //.log(this.props.productDetails, "details");

    return (
      <React.Fragment>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
          style={this.collapsBlock}
        >
          <Panel header="Vital : Variant" key="1" style={this.customPanelStyle}>
            <Row gutter={[24, 24]} style={this.collapsedStyle}>
              <Col sm={24}>
                <InputField
                  errormsg={this.props.validator.message(
                    "product_name",
                    product_name,
                    "required"
                  )}
                  impo
                  label={{ type: "secondary" }}
                  onChange={this.handleChange}
                  input={{
                    size: "large",
                    name: "product_name",
                    value: product_name,
                    disabled: this.props.disabled
                  }}
                  labelText="Variant Name"
                />
              </Col>
              <Col sm={12}>
                <InputField
                  errormsg={this.props.validator.message(
                    "product_id",
                    product_id,
                    product_id_type == "ASIN" ? "productIdASIN" :
                      (product_id_type == "UPCN" ? "productIdUPC" :
                        (product_id_type == "EAN" ? "productIdEAN" : 
                          (
                            product_id_type == "ARTICLE" ? "" : "required"
                          )
                        )
                      )
                  )}
                  impo = {product_id_type != "ARTICLE"}
                  label={{ type: "secondary" }}
                  onChange={this.handleChange}
                  input={{
                    size: "large",
                    name: "product_id",
                    value: product_id,
                    disabled: this.props.disabled
                  }}
                  labelText="Product ID"
                />
              </Col>

              <Col sm={12}>
                <Text style={{ color: "#ffffff99" }}>
                  Product ID Type<Text style={{ color: "#EF556B" }}>*</Text>
                  <Text style={{ color: "red", fontSize: "8px" }}>
                    {this.props.validator &&
                      this.props.validator.message(
                        "product_id_type",
                        product_id_type,
                        "required"
                      )}
                  </Text>
                </Text>
                <Select
                  showSearch
                  style={{ width: "100%", background: "#21262a" }}
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  disabled={this.props.disabled}
                  name="product_id_type"
                  size="large"
                  value={product_id_type}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className="vitalselect"
                >
                  <Option selected value="ARTICLE">
                    ARTICLE
                  </Option>
                  <Option selected value="EAN">
                    EAN
                  </Option>
                  <Option value="GTIN">GTIN</Option>
                  <Option value="ASIN">ASIN</Option>
                  <Option value="GCID">GCID</Option>
                  <Option value="ISBN">ISBN</Option>
                  <Option value="UPCN">UPCN</Option>
                </Select>
              </Col>
              <Col sm={12}>
                <InputField
                  // errormsg={this.props.validator.message(
                  //   "barcode_string",
                  //   barcode_string,
                  //   // "barcodestring"
                  //   "required"
                  // )}
                  label={{ type: "secondary" }}
                  onChange={this.handleChange}
                  input={{
                    size: "large",
                    name: "barcode_string",
                    value: barcode_string,
                    disabled: this.props.disabled
                  }}
                  labelText="Barcode"
                />
              </Col>
              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  onChange={this.handleChange}
                  input={{
                    size: "large",
                    name: "factory_code",
                    value: factory_code,
                    disabled: this.props.disabled
                  }}
                  labelText="Factory Code"
                />
              </Col>

              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  onChange={this.handleChange}
                  input={{
                    size: "large",
                    name: "min_price",
                    type: "number",
                    value: min_price,
                    disabled: this.props.disabled || !variant_price_permission
                  }}
                  labelText="Min Price"
                />
              </Col>


              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  onChange={this.handleChange}
                  input={{
                    size: "large",
                    name: "max_price",
                    type: "number",
                    value: max_price,
                    disabled: this.props.disabled || !variant_price_permission
                  }}
                  labelText="Max Price"
                />
              </Col>

              <Col sm={12}>
                <Text>Is this a bundled product? </Text>
                <Switch disabled={this.props.disabled} checkedChildren="Yes" unCheckedChildren="No" onChange={this.handleBundleProduct} checked={is_bundle_product} />
              </Col>

              <Col sm={24}>
                <Text>Is this is a SAP exception? </Text>
                <Switch disabled={this.props.disabled} checkedChildren="Yes" unCheckedChildren="No" onChange={this.handleSapException} checked={is_sap_exception} />
              </Col>


                {
                  is_sap_exception && 

                  <Col sm={12}>
                  <InputField
                    label={{ type: "secondary" }}
                    onChange={this.handleChange}
                    input={{
                      size: "large",
                      name: "atp_threshold",
                      type: "number",
                      value: atp_threshold,
                      disabled: this.props.disabled
                    }}
                    labelText="ATP Threshold"
                  />
                </Col>
  
                }

                {
                    is_sap_exception && 
                    <Col sm={12}>
                    <InputField
                      label={{ type: "secondary" }}
                      onChange={this.handleChange}
                      input={{
                        size: "large",
                        name: "holding_threshold",
                        type: "number",
                        value: holding_threshold,
                        disabled: this.props.disabled
                      }}
                      labelText="Holding Threshold"
                    />
                  </Col>
                }
            

            
            </Row>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails
  };
};

export default connect(mapStateToProps, { handelEditProductDetails })(
  VarientsInfoVital
);
