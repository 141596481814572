export default function DealshubAdminNavbarCategories(state = [], action) {
    switch (action.type) {
        case "ADD_NAVBAR_CATEGORY":
            return [...state, action.payload];

        case "FETCH_DEALS_HUB_CATEGORY_DATA":
            return action.payload;

        case "HANDLE_UPDATE":
            const { uuid, value } = action.payload;
            for (let i in state) {
                if (state[i].uuid === uuid) {
                    state[i].headingName = value;
                    break;
                }
            }
            return state;

        case "HANDLE_IMAGE_UPDATE_LINK":
            for (let index in state) {
                if (state[index]['uuid'] == action.payload.headingUuid) {
                    for (let index2 in state[index]['imageList']) {
                        if (state[index]['imageList'][index2]['uid'] === action.payload.fileUuid) {
                            state[index]['imageList'][index2]['httpLink'] = action.payload.link;
                            break;
                        }
                    }
                    break;
                }
            }
            return state;

        case "CHANGE_HEADING_CATEGORY":
            return [...[], action.payload];

        default:
            return state;
    }
}
