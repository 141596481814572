import React from "react";
import { Button, Col, DatePicker, Modal, Row, Typography } from "antd";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";

const DatePickerModal = ({
  handleDatePickerChange,
  openModal,
  toggleOpenModalState,
}) => {
  return (
    <Modal
      title="Select Custom Duration"
      visible={openModal}
      onOk={toggleOpenModalState}
      width="fit-content"
      onCancel={toggleOpenModalState}
    >
      <Row gutter={[12, 12]}>
        <Col span={14}>
          <Text>Choose the Duration</Text>
        </Col>
        <Col span={14}>
          <RangePicker
            format={dateFormat}
            onChange={(e) => handleDatePickerChange(e)}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default DatePickerModal;
