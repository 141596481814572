import { utils_copy } from "./Utils";
/**
 * @description This is the initialState of channelProducts reducer
 * @property {object} ebay - All required product details and images fetched from ebay.
 */
const initialState = {
  ebay: {
    repr_image_url: "",
    repr_high_def_url: "",
    images: {
      all_images: [
        {
          pk: "",
          "main-url": "",
          "midimage-url": "",
          "thumbnail-url": "",
        },
      ],
      main_images: [
        {
          pk: "",
          "main-url": "",
          "midimage-url": "",
          "thumbnail-url": "",
          is_main_image: true,
        },
      ],
      sub_images: [
        {
          pk: "",
          "main-url": "",
          "midimage-url": "",
          "thumbnail-url": "",
          is_sub_image: true,
          sub_image_index: "",
        },
      ],
    },
    product_id: "",
    material_type: "",
    channel_product_json: {
      category: "",
      sub_category: "",
      product_name: "",
      product_description: "",
      product_attribute_list: [],
      created_date: "",
      status: "",
      http_link: "",
      was_price: "",
      now_price: "",
      stock: "",
    },
    status: "",
  },
};

/**
 * @description This is redux reducer for fetching product catalog from ebay
 * changes/updates state  for different cases like fetch channel product from amazon uae etc..
 * @typedef StateType - state type of channelProducts reducer, default state is initial state
 * @param {object} state
 * @param {object} action
 * @returns {StateType} returns updated state
 */
export default function channelProducts(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CHANNEL_PRODUCT_EBAY":
      let channel_product_json = utils_copy(
        state.ebay.channel_product_json,
        action.payload.channel_product_json
      );
      action.payload.channel_product_json = channel_product_json;

      return { ebay: { ...action.payload, ["status"]: "" } };
    case "SEND_FEED_BACK_TO_CHANNEL_FORM":
      return { ebay: { ...state.ebay, ["status"]: action.payload } };
    case "SET_IMAGE_EBAY":
      return {
        ebay: { ...state.ebay, ["images"]: action.payload.images },
      };
    case "HANDEL_CHANGE_EBAY":
      return { ebay: { ...action.payload, ["status"]: "" } };
    default:
      return state;
  }
}
