import React, { Component } from "react";
import { Row, Col, Avatar, Typography, Icon, Spin, Button, List } from "antd";
import { Ellipsis } from "react-spinners-css";
import { connect } from "react-redux";
import {
    PermissionListIcon,
    PermissionListRow,
} from "./styled-components-library";
import "./profilepage.scss";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import ActivityHistoryTable from "../components/activity-history-table/ActivityHistoryTable";
import NestoActivityLogs from "../components/activity-history-table/NestoActivityLogs";
import EditUserModal from "../components/edit-user-modal/EditUserModal";
import axios from "axios";

import isDev from "../../server";
const serverUrl = isDev();

const { Text } = Typography;

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: null,
            pageCount: 1,
            tableDataList: null,
            is_available: false,
        };
    }

    fetchMore = () => {
        this.setState({ is_available: false });
        fetch(`${serverUrl}/fetch-audit-logs/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ page: this.state.pageCount + 1 }),
        })
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    tableDataList: [
                        ...this.state.tableDataList,
                        ...data.log_entry_list,
                    ],
                    is_available: data.is_available,
                });
            });

        this.setState({ pageCount: this.state.pageCount + 1 });
    };

    componentDidMount = () => {
        axios
            .post(
                `${serverUrl}/fetch-user-profile/`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                this.setState({ userData: response.data });
            });

        fetch(`${serverUrl}/fetch-audit-logs-by-user/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ page: this.state.pageCount }),
        })
            .then((res) => res.json())
            .then((data) => {
                this.setState({ tableDataList: data.log_entry_list });
                this.setState({ is_available: data.is_available });
            });
    };

    render() {
        //console.log("userData",this.state.userData)
        return (
            <React.Fragment>
                <Row className="profilepagecontainer">
                    <TitleWithBreadCrumb
                        title="Profile"
                        breadcrumb={["OmnyComm", "Profile"]}
                    />
                    <Row gutter={[24, 24]} className="profilepagesubcontainer">
                        <Col lg={9} sm={24}>
                            {this.state.userData ? (
                                <Row className="profilecard">
                                    <Col sm={24}>
                                        <Row
                                            type="flex"
                                            className="username"
                                            justify="center"
                                            align="middle"
                                        >
                                            <Col>
                                                <Avatar size={68}>
                                                    {
                                                        this.state.userData
                                                            .first_name[0]
                                                    }{" "}
                                                    {
                                                        this.state.userData
                                                            .last_name[0]
                                                    }
                                                </Avatar>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={24}>
                                        <Row
                                            gutter={[16, 16]}
                                            className="details"
                                        >
                                            <Col>
                                                <Row type="flex" align="middle">
                                                    <Col span={7}>
                                                        <Text type="secondary">
                                                            Full Name :{" "}
                                                        </Text>
                                                    </Col>
                                                    <Col span={17}>
                                                        <Text
                                                            style={{
                                                                marginLeft:
                                                                    "12px",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .userData
                                                                    .first_name
                                                            }{" "}
                                                            {
                                                                this.state
                                                                    .userData
                                                                    .last_name
                                                            }
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row type="flex" align="middle">
                                                    <Col span={7}>
                                                        <Text type="secondary">
                                                            Mobile :{" "}
                                                        </Text>
                                                    </Col>
                                                    <Col span={17}>
                                                        <Text
                                                            style={{
                                                                marginLeft:
                                                                    "12px",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .userData
                                                                    .contact_number
                                                            }
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row type="flex" align="middle">
                                                    <Col span={7}>
                                                        <Text type="secondary">
                                                            Email :{" "}
                                                        </Text>
                                                    </Col>
                                                    <Col span={17}>
                                                        <Text
                                                            style={{
                                                                marginLeft:
                                                                    "12px",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .userData
                                                                    .email
                                                            }
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row type="flex" align="middle">
                                                    <Col span={7}>
                                                        <Text type="secondary">
                                                            Designation :{" "}
                                                        </Text>
                                                    </Col>
                                                    <Col span={17}>
                                                        <Text
                                                            style={{
                                                                marginLeft:
                                                                    "12px",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .userData
                                                                    .designation
                                                            }
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row type="flex">
                                                    <Col span={7}>
                                                        <Text type="secondary">
                                                            Permissions :{" "}
                                                        </Text>
                                                    </Col>
                                                    <Col span={17}>
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "15px",
                                                                color: "grey",
                                                                maxHeight:
                                                                    "40vh",
                                                                overflowY:
                                                                    "scroll",
                                                            }}
                                                        >
                                                            {this.state.userData
                                                                .permissions &&
                                                                this.state.userData.permissions.map(
                                                                    (
                                                                        permission,
                                                                        ind
                                                                    ) => {
                                                                        return (
                                                                            <Row>
                                                                                <Text>
                                                                                    {
                                                                                        permission.title
                                                                                    }
                                                                                </Text>
                                                                                <Col>
                                                                                    <ul>
                                                                                        {permission.Items &&
                                                                                            permission.Items.map(
                                                                                                (
                                                                                                    item,
                                                                                                    ind
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <li>
                                                                                                            {
                                                                                                                item
                                                                                                            }
                                                                                                        </li>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </ul>
                                                                                </Col>
                                                                            </Row>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row gutter={[12, 12]} className="permission-label-row">
                          {this.state.userData.permissions && this.state.userData.permissions.map((e, idx) => {
                            let propKey = Object.keys(e)[0];
                            return (<Col span={24}>
                              <Text type="secondary">{idx + 1} . {propKey}</Text><br />
                              {e[propKey].map((ev, idx) => {
                                return (<React.Fragment><Icon style={{ color: "#1abc9c", fontSize: "10px", marginRight: "5px" }} type="check-circle" />
                                  <Text >{ev}</Text> <br /></React.Fragment>);
                              })}
                            </Col>);
                          })}
                        </Row> */}
                                                {/* {this.state.userData.permissible_brands.map(
                          brandName => (
                            <PermissionListRow type="flex" align="middle">
                              <Col span={2}>
                                <PermissionListIcon type="check-circle" />
                              </Col>
                              <Col span={22}>
                                <Text>{brandName}</Text>
                              </Col>
                            </PermissionListRow>
                          )
                        )} */}
                                            </Col>
                                            <Col>
                                                <EditUserModal
                                                    userData={
                                                        this.state.userData
                                                    }
                                                    updateUser={(data) =>
                                                        this.setState({
                                                            userData: data,
                                                        })
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ) : (
                                <Row
                                    type="flex"
                                    align="middle"
                                    justify="center"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "#37414B",
                                    }}
                                >
                                    <Ellipsis color="#1ABC9C" size={100} />
                                </Row>
                            )}
                        </Col>

                        <Col lg={15} sm={24}>
                            <Row className="activity-log">
                                <Col sm={24}>
                                    <Text type="secondary">Activity log</Text>
                                </Col>
                                <Col
                                    sm={24}
                                    className="activity-history-table-container"
                                >
                                    {this.state.tableDataList ? (
                                        <>
                                            {
                                                this.props.isNestoUser ? (
                                                    <NestoActivityLogs/>
                                                ):(
                                                    <ActivityHistoryTable
                                                        tableDataList={
                                                            this.state.tableDataList
                                                        }
                                                    />
                                                )
                                            }
                                        </>
                                    ) : (
                                        <Row
                                            type="flex"
                                            align="middle"
                                            justify="center"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <Ellipsis
                                                color="#1ABC9C"
                                                size={100}
                                            />
                                        </Row>
                                    )}
                                </Col>
                                {this.state.is_available && (
                                    <div
                                        className="center-child"
                                        style={{
                                            width: "100%",
                                            padding: "20px 20px 0px 20px",
                                        }}
                                    >
                                        {this.state.tableDataList &&
                                            this.state.is_available && (
                                                <Button
                                                    type="primary"
                                                    onClick={this.fetchMore}
                                                >
                                                    Load more..
                                                </Button>
                                            )}

                                        {this.state.tableDataList &&
                                            !this.state.is_available && (
                                                <Button
                                                    type="primary"
                                                    onClick={this.fetchMore}
                                                    disabled
                                                    loading
                                                ></Button>
                                            )}
                                    </div>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStateToProps = ({ user }) => {
    return {
      ...user
    };
  };
  
export default connect(mapStateToProps)(ProfilePage);
