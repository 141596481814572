import React, { Component } from 'react';
import { Row, Col, Avatar, Typography, Icon, Spin, Button, Divider, Tabs, Select, Modal, Collapse, InputNumber, message } from "antd";
import Cards from 'react-credit-cards';

import { Ellipsis } from "react-spinners-css";
import 'react-credit-cards/es/styles-compiled.css';

import axios from "axios";

import "./customer-details.scss"
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';


import isDev from "../../server";
import { Link } from 'react-router-dom';



const serverDealsHubUrl = isDev(true);

const { Text } = Typography;
const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;
const genExtra = (str) => (
    <Text>
        00:01:00 PM, 20 Feb,     2020
    </Text>
);




export default class CustomerDetails extends Component {

    state = {
        visible: false,
        customerDetails: null,
        loading: false
    };


    componentDidMount = () => {

        this.setState({
            loading: true
        })

        const data = {
            emailId: this.props.emailId
        }

        axios.post(`${serverDealsHubUrl}/dealshub/fetch-customer-details/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
                
            }
        }).then(response => {

            this.setState({
                loading: false
            });
            if (response.data.status == 200) {
                this.setState({
                    customerDetails: response.data.customerDetails
                })
            }
            else {
                message.error("Internal Server Error!");
            }
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModal1 = () => {
        this.setState({
            visible1: true,
        });
    };

    handleOk = e => {

        this.setState({
            visible: false,
        });
    };
    handleOk1 = e => {

        this.setState({
            visible1: false,
        });
    };

    handleCancel = e => {

        this.setState({
            visible: false,
        });
    };
    handleCancel1 = e => {

        this.setState({
            visible1: false,
        });
    };
    render() {



        if (this.state.loading) {
            return (
                < Row
                    type="flex"
                    align="middle"
                    justify="center"
                    style={{ width: "100%", height: "100%" }
                    }
                >
                    <Ellipsis color="#1ABC9C" size={100} />
                </Row >
            )
        }

        if (!this.state.customerDetails) {
            return (
                < Row
                    type="flex"
                    align="middle"
                    justify="center"
                    style={{ width: "100%", height: "100%" }
                    }
                >
                    <Ellipsis color="#1ABC9C" size={100} />
                </Row >
            )
        }

        const { customerName, emailId, contactNumber, city } = this.state.customerDetails

        return (
            <Row className="customer-details-page">
                <TitleWithBreadCrumb
                    title="Customer Details"
                    breadcrumb={["OmnyComm", "Customer Details"]}
                />
                <Row gutter={[24, 24]} className="customer-details-page-content">
                    <Col lg={8} sm={24}>
                        <Row className="personal-details-card" type="flex" align="middle" gutter={[8, 8]}>
                            <Col span={24} className="user-avatar-container">
                                <Row type="flex" justify="center">
                                    <Avatar size={68} icon="user" className="margin-right margin-bottom-20" className="main-avatar" />
                                </Row>
                                <Row type="flex" justify="center">
                                    {/* <Text type="secondary" strong></Text> */}
                                    <Title level={4} style={{ color: "#dee2e6" }}>
                                        {customerName}
                                    </Title>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Email Id : </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {emailId}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Phone No: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {contactNumber}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">City: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {city}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={24}>

                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <Button type="primary" onClick={this.showModal} block>
                                                    View  Addresses
                                                </Button>
                                                <Modal
                                                    title="Addresses"
                                                    visible={this.state.visible}
                                                    onOk={this.handleOk}
                                                    onCancel={this.handleCancel}
                                                >

                                                    {
                                                        this.state.customerDetails.addressList.map((item, index) =>
                                                            (
                                                                <React.Fragment>
                                                                    <Row key={index}>
                                                                        <Col>
                                                                            <Text className="text-color3" strong>
                                                                                {item[0]}
                                                                            </Text>
                                                                        </Col>
                                                                        <Col>
                                                                            <Text>
                                                                                {item[1]}
                                                                            </Text>
                                                                            <br />
                                                                            <Text>
                                                                                {item[2]}{","}{item[3]}
                                                                            </Text>
                                                                            <Text>
                                                                                {" "}({item[5]})
                                                                        </Text>

                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    <br />
                                                                </React.Fragment>
                                                            )
                                                        )
                                                    }



                                                </Modal>
                                            </Col>
                                            <Col span={12}>
                                                <Button type="danger" onClick={this.showModal1} block>
                                                    Payments Info
                                                </Button>
                                                <Modal
                                                    title="Payments Info"
                                                    visible={this.state.visible1}
                                                    onOk={this.handleOk1}
                                                    onCancel={this.handleCancel1}
                                                    width={650}
                                                >

                                                    <Row gutter={[16, 16]}>
                                                        <Col sm={24} lg={12}>
                                                            <div id="PaymentForm">
                                                                <Cards
                                                                    expiry="0621"
                                                                    name="AZHAR SHEIKH"
                                                                    number="5555555555554444"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={24} lg={12}>
                                                            <div id="PaymentForm">
                                                                <Cards
                                                                    expiry="0621"
                                                                    name="AZHAR SHEIKH"
                                                                    number="4111111111111111"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={24} lg={12}>
                                                            <div id="PaymentForm">
                                                                <Cards
                                                                    expiry="0621"
                                                                    name="AZHAR SHEIKH"
                                                                    number="30569309025904"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={24} lg={12}>
                                                            <div id="PaymentForm">
                                                                <Cards
                                                                    expiry="0621"
                                                                    name="AZHAR SHEIKH"
                                                                    number="371449635398431"
                                                                />
                                                            </div>
                                                        </Col>

                                                    </Row>

                                                </Modal>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </Col>
                            {/* <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Payment Details: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            CREDIT CARD
                                        </Text>
                                    </Col>
                                </Row>
                            </Col> */}
                            <Divider />

                            <Col span={24}>
                                <Title level={4} style={{ color: "#dee2e6" }}>
                                    Metrics :
                                </Title>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Channel of entry: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {this.state.customerDetails.channelOfEntry}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Total billing: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {this.state.customerDetails.totalBilling}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Average billing: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {this.state.customerDetails.averageBilling}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Joining cohort: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            placeholder text
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Average session time: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {this.state.customerDetails.averageSessionTime}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" align="middle">
                                    <Col span={10}>
                                        <Text type="secondary">Last visited: </Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ marginLeft: "12px" }}>
                                            {this.state.customerDetails.lastVisited}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>


                        </Row>
                    </Col>

                    <Col lg={16} sm={24}>
                        <Row className="order-cart-tab-container">
                            <Col sm={24} >
                                <Tabs defaultActiveKey="1" >
                                    <TabPane tab="Orders" key="1">
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Row type="flex" align="middle" justify="start">
                                                    <Col>
                                                        <Select
                                                            className="search-select margin-right"
                                                            dropdownClassName="search-chips"
                                                            dropdownMatchSelectWidth="false"
                                                            mode="tags"
                                                            style={{ width: 200 }}
                                                            placeholder={[<Icon type="search" />, " Search here..."]}

                                                        ></Select>
                                                    </Col>
                                                    <Col>
                                                        <Button type="primary">
                                                            Filter
                                                        </Button>

                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Row className="order-details-container">
                                                    <Col span={24}>

                                                        {this.state.customerDetails.orderList.map((item, index) => (
                                                            <Row gutter={[12, 12]} className="order-details-row" type="flex" align="middle">
                                                                <Col span={4}>
                                                                    <Avatar shape="square" src={item.productImageUrl} style={{ width: "100%", height: "100%" }} />
                                                                </Col>
                                                                <Col span={20}>
                                                                    <Row type="flex" align="middle">
                                                                        <Col span={5}>
                                                                            <Text type="secondary">Product Name: </Text>
                                                                        </Col>
                                                                        <Col span={19}>
                                                                            <Text >
                                                                                {item.productName}
                                                                            </Text>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <Row type="flex" align="middle">
                                                                                <Col span={10}>
                                                                                    <Text type="secondary">Order Id: </Text>
                                                                                </Col>
                                                                                <Col span={14}>
                                                                                    <Text >
                                                                                        {item.orderId}
                                                                                    </Text>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row type="flex" align="middle">
                                                                                <Col span={10}>
                                                                                    <Text type="secondary">Channel: </Text>
                                                                                </Col>
                                                                                <Col span={14}>
                                                                                    <Text >
                                                                                        Amazon UK
                                                                                                                               </Text>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row type="flex" align="middle">
                                                                                <Col span={10}>
                                                                                    <Text type="secondary">Total: </Text>
                                                                                </Col>
                                                                                <Col span={14}>
                                                                                    <Text >
                                                                                        {item.quantity * item.price}{" "}{item.currency}
                                                                                    </Text>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row type="flex" align="middle">
                                                                                <Col span={10}>
                                                                                    <Text type="secondary">Date: </Text>
                                                                                </Col>
                                                                                <Col span={14}>
                                                                                    <Text >
                                                                                        {item.dateCreated}
                                                                                    </Text>
                                                                                </Col>
                                                                            </Row>


                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Row type="flex" align="middle">
                                                                                <Col span={12}>
                                                                                    <Text type="secondary">Order Status: </Text>
                                                                                </Col>
                                                                                <Col span={12}>
                                                                                    <Text >
                                                                                        {item.currentStatus}
                                                                                    </Text>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row type="flex" align="middle">
                                                                                <Col span={12}>
                                                                                    <Text type="secondary">Payment status: </Text>
                                                                                </Col>
                                                                                <Col span={12}>
                                                                                    <Text >
                                                                                        {
                                                                                            item.paymentStatus
                                                                                        }
                                                                                    </Text>
                                                                                </Col>
                                                                            </Row>


                                                                        </Col>

                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        ))}

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="Cart" key="2">
                                        Coming Soon ...
                                    </TabPane>
                                </Tabs>
                            </Col>

                        </Row>

                        {/* <Row className="sessions-container" gutter={[16, 16]}>
                            <Col span={24}>
                                <Title level={4} style={{ color: "#dee2e6" }}>
                                    Sessions :
                                </Title>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Collapse expandIconPosition="left" className="collapse">
                                            <Panel header="Session 1" extra={genExtra()} className="">
                                                <Row type="flex" align="middle" className="product-table-head-row">
                                                    hi this is session content
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>


                                </Row>
                            </Col>
                        </Row> */}
                    </Col>
                </Row>
            </Row >
        )
    }
}
