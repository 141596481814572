import React, { Component } from "react";
import axios from "axios";
import {
    Modal,
    Upload,
    Icon,
    message
} from "antd";
import isDev from "../../../server";
const serverUrl = isDev();

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img.originFileObj);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG/GIF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
}

export default class LogoUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadingInProgress: false,
            isDeleteOn: false,
            previewVisible: false,
            previewImage: "",
            fileList: [],
        };
    }

    componentDidMount = () => {
        this.setState({
            fileList: this.props.fileList
        });
    };

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true
        });
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handleChange = ({ fileList }) => {
        if (
            fileList.length > 0 &&
            !this.state.uploadingInProgress &&
            !this.state.isDeleteOn
        ) {
            this.setState({
                uploadingInProgress: true
            }, () => {
                let logoUploadUrl = this.props.isNavbar ?
                    `${serverUrl}/upload-company-logo/` :
                    `${serverUrl}/upload-company-footer-logo/`;

                let currentImage = fileList[fileList.length - 1];
                let formData = new FormData();
                formData.append("logo_image_url", currentImage.originFileObj);
                formData.append("websiteGroupName", localStorage.getItem("websiteGroupName"));
                formData.append("locationGroupUuid", this.props.locationGroupUuid);
                if (currentImage.originFileObj !== undefined) {
                    axios.post(logoUploadUrl, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `JWT ${localStorage.getItem("token")}`
                        }
                    }).then(response => {
                        if (response.data.status === 200) {
                            let temp = [{
                                uid: 123,
                                url: response.data.image_url
                            }];
                            this.setState({
                                fileList: [...[], ...temp],
                                uploadingInProgress: false
                            }, () => {
                                this.props.changeLogoInParent(
                                    this.props.stateVariableName,
                                    this.state.fileList
                                );
                            });

                            message.success("Logo Uploaded Successfully!");
                        } 
                        else if(response.data.status === 405)
                        {
                          message.info("You are not authorised!")
                        }
                        else {
                            message.error('Something went wrong!');
                        }
                    });
                }
            });
        }
    }

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    removeFile = file => {
        this.setState({
            fileList: []
        });
    };

    render() {
        if (this.props.fileList) {
            const uploadButton = (
                <div>
                    <Icon type="camera" />
                    <div className="ant-upload-text">You can drag and drop images or upload images</div>
                </div>
            );
            return (
                <div style={{ color: "#fff" }}>
                    <p>{this.props.dispText}</p>
                    <p>{this.props.Resolution}</p>
                    <p>{this.props.fileSize}</p>
                    <Upload
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        customRequest={this.dummyRequest}
                        onRemove={this.removeFile}
                    >
                        {this.state.fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    <Modal
                        visible={this.state.previewVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={this.state.previewImage}
                        />
                    </Modal>
                </div>
            );
        }
        else{
            return null
        }
    }
}
