import React, { useEffect, useState } from "react";
import "./activity-logs.scss";
import {
  Row,
  Col,
  Typography,
  AutoComplete,
  Select,
  Button,
  Icon,
  message,
  Empty,
  Modal,
  DatePicker,
  Spin,
  Input,
} from "antd";

import * as moment from "moment";
import isDev from "../../server";
import axios from "axios";
const { Title, Text } = Typography;
const { Option } = Select;

const serverOmnycommUrl = isDev();

const ActivtyLogs = (props) => {
  const [filterModal, setFilterModal] = useState(false);
  const [fetchedLogs, setFetchedLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [logs, setLogs] = useState([]);
  const [areLogsAvailable, setAreLogsAvailable] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  const fetchLogList = (currentPage = 0) => {
    axios
      .post(
        `${serverOmnycommUrl}/fetch-admin-activity-logs/`,
        {
          locationGroupUuid: props.locationUuid ? props.locationUuid : "",
          page: currentPage + 1,
          from_date: fromDate
            ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
            : "",
          to_date: toDate ? moment(toDate).format("YYYY-MM-DD HH:mm:ss") : "",
          search_string: searchString,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          if (currentPage === 0) {
            setLogs(response.data.activity_log_list);
          } else {
            setLogs((logs) => logs.concat(response.data.activity_log_list));
          }
          setCurrentPage(currentPage + 1);
          setTotalLogs(response.data.totalActivites);
          setAreLogsAvailable(response.data.is_available);
        } else {
          message.alert("Something went wrong");
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went Wrong!");
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchLogList();
  }, [props.locationUuid]);

  const handleLoadMore = () => {
    fetchLogList(currentPage);
  };

  const fetchFilteredLogs = () => {
    fetchLogList();
  };

  const handleSearchChange = (e) =>{
    setSearchString(e.target.value);
  }
  return loading === false ? (
    <Row
      className="orders-account-manager-mobile-view-page-container"
      gutter={[16, 16]}
    >
      <Col span={24} className="title-container">
        <Row type="flex" justify="start">
          <Col>
            <Title level={4} className="main-title">
              Activty Logs
            </Title>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col lg={3} md={3} sm={6}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              icon="filter"
              block
              onClick={() => {
                setFilterModal(true);
              }}
            >
              Filters
            </Button>
          </Col>
          <Col
            lg={17}
            md={17}
            sm={8}
            className="warehouse-manager-search-bar-container"
          >
            <Input
              value={searchString}
              onChange={handleSearchChange}
              onPressEnter={fetchFilteredLogs}
              placeholder="Search the logs"
              style={{ width: "100%" }}
            />
          </Col>
          <Col lg={3} md={3} sm={6}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={props.loading}
              ghost
            >
              <React.Fragment>
                {props.loading ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>Total Logs: {totalLogs}</React.Fragment>
                )}
              </React.Fragment>
            </Button>
          </Col>
        </Row>
      </Col>

      <Col
        sm={24}
        md={24}
        lg={24}
        className="collapse-container-account-manager"
      >
        <Col span={24} className="logs-list-titles">
          <Col span={3}>
            <Text style={{ color: "white", fontSize: "14px" }}>User</Text>
          </Col>
          <Col span={16}>
            <Text style={{ color: "white", fontSize: "14px" }}>Action</Text>
          </Col>
          <Col span={4}>
            <Text style={{ color: "white", fontSize: "14px" }}>Date</Text>
          </Col>
        </Col>
        {logs.map((log, index) => {
          return (
            <Col
              span={24}
              className="collapse-container-account-manager"
              key={index}
            >
              <LogItem log={log} />
            </Col>
          );
        })}
        {logs.length === 0 && (
          <Empty
            className="no-orders-wrapper"
            description="No Logs found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
        {areLogsAvailable ? (
          <div
            className="center-child"
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "50px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                handleLoadMore();
              }}
            >
              Load More...
            </Button>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Col>

      <Modal
        title="Filters"
        visible={filterModal}
        destroyOnClose={true}
        closable={true}
        onOk={() => {
          fetchFilteredLogs();
          setFilterModal(false);
        }}
        onCancel={() => {
          setFilterModal(false);
        }}
        style={{ marginLeft: "300px" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Text strong type="secondary">
                  Start Date
                </Text>
                <DatePicker
                  showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                  style={{ backgroundColor: "#36404A", width: "100%" }}
                  selected={fromDate}
                  onChange={(date) => {
                    setFromDate(date);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Text strong type="secondary">
                  End Date
                </Text>
                <DatePicker
                  showTime={{ defaultValue: moment("23:59:59", "HH:mm:ss") }}
                  style={{ backgroundColor: "#36404A", width: "100%" }}
                  selected={toDate}
                  onChange={(date) => {
                    setToDate(date);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Button
          style={{ marginTop: "15px" }}
          onClick={() => {
            setToDate("");
            setFromDate("");
          }}
        >
          Reset Filters
        </Button>
      </Modal>
    </Row>
  ) : (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#313841",
      }}
    >
      <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

const LogItem = (props) => {
  const [logDetailsModal, setLogDetailsModal] = useState(false);

  return (
    <Row
      type="flex"
      gutter={[0, 0]}
      className="collapse-header-activity"
      style={{ padding: "15px" }}
    >
      <Col span={3}>
        <Text>{props.log.username}</Text>
      </Col>
      <Col span={17}>
        <Icon
          type="diff"
          className="diff-icon"
          onClick={() => {
            setLogDetailsModal(true);
          }}
        />
        <Text>
          {props.log.render.length > 100
            ? props.log.render.substring(0, 100) + "..."
            : props.log.render}
        </Text>
      </Col>
      <Col span={4}>
        <Text>
          {props.log.date} {props.log.time}
        </Text>
      </Col>

      <Modal
        visible={logDetailsModal}
        destroyOnClose={true}
        closable={false}
        onCancel={() => {
          setLogDetailsModal(false);
        }}
        footer={null}
        className="log-details-modal"
        bodyStyle={{ minWidth: "950px", maxHeight: "580px" }}
      >
        <LogDetails log={props.log} />
      </Modal>
    </Row>
  );
};

const LogDetails = (props) => {
  var flag_prev = 0;
  var flag_new = 0;

  return (
    <Row gutter={[0, 0]} className="log-details-modal-row">
      <Col span={24}>
        <Row gutter={[0, 0]}>
          <Col span={4}>
            <Col span={24}>
              <Text>Username</Text>
            </Col>
            <Col span={24}>
              <Text style={{ color: "white" }}>{props.log.username}</Text>
            </Col>
          </Col>
          <Col span={15} style={{ maxHeight: "50px", overflowY: "scroll" }}>
            <Col span={24}>
              <Text>Action</Text>
            </Col>
            <Col span={24}>
              <Text style={{ color: "white" }}>{props.log.render}</Text>
            </Col>
          </Col>
          <Col span={5}>
            <Col span={24}>
              <Text>Date</Text>
            </Col>
            <Col span={24}>
              <Text style={{ color: "white" }}>
                {props.log.date} {props.log.time}
              </Text>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: "10px" }}>
        {props.log.table_name ? (
          <center style={{ marginBottom: "10px" }}>
            <Text>Change Class: </Text>
            <Text style={{ color: "white" }}>{props.log.table_name}</Text>
          </center>
        ) : null}
        <Row gutter={[0, 0]}>
          <Col span={12} style={{ paddingRight: "5px", minWidth: "420px" }}>
            <Col span={24} style={{ backgroundColor: "#36404A" }}>
              <Col
                span={24}
                style={{ paddingTop: "10px", paddingLeft: "15px" }}
              >
                <Text className="values-type-header">OLD INSTANCE</Text>
              </Col>
              <Col span={24} className="instance-dump-col">
                {Object.keys(props.log.prev_instance).map((key) => {
                  if (
                    props.log.prev_instance[key] !==
                    props.log.current_instance[key]
                  ) {
                    flag_prev = 1;
                  }
                  return props.log.prev_instance[key] !==
                    props.log.current_instance[key] ? (
                    <Col span={24}>
                      <Text>
                        {key}
                        {" : "}
                      </Text>
                      <Text style={{ color: "white" }}>
                        {props.log.prev_instance[key]}
                      </Text>
                    </Col>
                  ) : null;
                })}
                {flag_prev === 0 ? (
                  <Empty
                    style={{ paddingTop: "100px" }}
                    className="no-orders-wrapper"
                    description="no old instance"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ) : null}
              </Col>
            </Col>
          </Col>
          <Col span={12} style={{ paddingLeft: "5px", minWidth: "420px" }}>
            <Col span={24} style={{ backgroundColor: "#36404A" }}>
              <Col
                span={24}
                style={{ paddingTop: "10px", paddingLeft: "15px" }}
              >
                <Text className="values-type-header">NEW INSTANCE</Text>
              </Col>
              <Col span={24} className="instance-dump-col">
                {Object.keys(props.log.current_instance).map((key) => {
                  if (
                    props.log.prev_instance[key] !==
                    props.log.current_instance[key]
                  ) {
                    flag_new = 1;
                  }
                  return props.log.prev_instance[key] !==
                    props.log.current_instance[key] ? (
                    <Col span={24}>
                      <Text>
                        {key}
                        {" : "}
                      </Text>
                      <Text style={{ color: "white" }}>
                        {props.log.current_instance[key]}
                      </Text>
                    </Col>
                  ) : null;
                })}
                {flag_new === 0 ? (
                  <Empty
                    style={{ paddingTop: "100px" }}
                    className="no-orders-wrapper"
                    description="no new instance"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ) : null}
              </Col>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ActivtyLogs;
