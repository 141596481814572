// Should not mutate props directly (using the assign operator). 
// It has been done in almost all functions involving handleUpdate()
// For eg. loadData(), changeListingType(), handleSelectFile(), handleHoveringBannerUpload() etc.
// Could not be done due to time constraints.
// Please take a note of this while refactoring the code


import React, { Component } from "react";
import { Row, Col, Collapse, AutoComplete, Card, Avatar, Switch, Tooltip, Tag, message, Icon, Upload, DatePicker, Radio, Input, Button, Spin, Typography, Modal } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import {
    handleUpdate,
    deleteBanner,
    publishBanner,
    unpublishBanner,
    handleDelete,
    loadCategoryOrBanner 
} from "../../../actions/dealsHubAdmin";
import EditableProductAttributes from "./EditableProductAttributes";
import SectionBulkUploadFile from "./Section-Bulk-Upload-Products-Demo-Template.xlsx";
import moment from 'moment';
import "./generic-promotional-engine.scss";
import isDev from "../../../server";
import styled from "styled-components"

const serverUrl = isDev();
const { Text, Paragraph } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = AutoComplete;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const UploadBtn = styled(Upload)`
 && {
    .ant-upload {
        height:0px !important;
        width: 100% !important;
    }
    span {
      width : 100% !important;
    }
  }`

class GenericPromotionalEngine extends Component {
    rangePickerDateFormat = "YYYY-MM-DD HH:mm:ss";
    plainOptions = ["Carousel", "Grid Stack"];

    constructor(props) {
        super(props);
        this.state = {
            serverUrl: serverUrl,
            sectionData: null,
            categoryUpdationInProgress: false,
            categoryPublishInProgress: false,
            categoryDeletionInProgress: false,
            promotionActive: false,
            promotionStartDate: null,
            promotionEndDate: null,
            promotionalTag: null,
            productListUpdationinProgress: false,
            searchListing: [],
            fileList: [],
            hoveringFileList: [],
            hoverImageUploadingInProgress: false,
            isDeleteOnHover: false,
            cohortPricesModal: false,
            cohort_price1: "",
            cohort_price2: "",
            cohort_price3: "",
            cohort_price4: "",
            cohort_price5: "",
            now_price1: "",
            now_price2: "",
            now_price3: "",
            now_price4: "",
            now_price5: "",
            promotional_price: "",
            now_price: "",
            product_uuid: "",
            innerSpinner: false,
        };
    }

    componentDidMount = () => {
        this.setState({
            sectionData: this.props.sectionData,
        });
    };

    downloadProducts = () => {
        this.setState({
            loadingDownload : true
        })
        let data = {
            uuid: this.state.sectionData.uuid
          };
          fetch(`${serverUrl}/dealshub/section-bulk-download/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
          })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    loadingDownload : false
                })
              if (data.status === 500)
                message.error("Internal Server Error!");
                else if(data.status === 405)
                {
                  message.info("You are not authorised!")
                }
              else {
                window.open(data.filepath, '_blank');
              }
      
            });
    }

    handleSelectFile = file => {
        this.setState({ fileList: [file], uploadModal: false });

        let formData = new FormData();
        formData.append("uuid", this.state.sectionData.uuid);
        formData.append("import_file" , file);

        axios
        .post(`${serverUrl}/dealshub/section-bulk-upload/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        })
        .then(response => {
            switch(response.data.status){
                case 200:
                    if(response.data.unsuccessful_count)
                        {
                            message.info(`${response.data.unsuccessful_count} product(s) are rejected!`);
                            this.setState({
                                errorShow : true,
                                unsuccessful_count : response.data.unsuccessful_count
                            })
                        }
                    else {
                        message.success("All products added successfully!");
                        this.setState({
                            errorShow : false,
                        })
                    }

                    let uuid = this.state.sectionData.uuid,
                    length = this.props.dealsHubAdmin.length;
                    for (let i = 0; i<length; ++i){
                        if(this.props.dealsHubAdmin[i].uuid === uuid) {
                            this.props.dealsHubAdmin[i]["products"] = [...response.data.products];
                            break;
                        }
                    }
                    
                    this.props.handleUpdate(this.props.dealsHubAdmin);

                    break;
                case 500:
                    message.error("Something went wrong!");
                    break;
                case 403:
                    message.info("You are not authorized!");
                    break;
                default:
                    message.error("Something went wrong");
            }
            this.handleUnselectFile()
        });
    }


    handleUnselectFile = file => {
        this.setState({ fileList: [] });
    }

    genExtra = (str) => {
        return <Tag color={str[1]}>{str[0]}</Tag>
    }

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    validatePromotionAttributes = () => {
        return this.state.promotionStartDate !== null &&
            this.state.promotionEndDate !== null &&
            this.state.promotionalTag !== null;
    }

    autocompleteSearch = (searchString) => {
        if (searchString !== "") {
            axios.post(`${serverUrl}/dealshub/search-section-products-autocomplete/`, {
                websiteGroupName: localStorage.getItem("websiteGroupName"),
                searchString: searchString,
                sectionUuid: this.state.sectionData.uuid,
                type: "ProductListing",
                locationGroupUuid : this.props.locationUuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                let tempList = [];
                response.data.productList.map(list => (
                    tempList.push(
                        <Option key={list.uuid} value={list.uuid}>{list.name}</Option>
                    )
                ));
                this.setState({
                    searchListing: [...[], ...tempList]
                })
            });
        } else {
            this.setState({
                searchListing: [...[], ...[]]
            })
        }
    }

    saveCategory = () => {
        this.setState({
            categoryUpdationInProgress: true
        }, () => {
            let uuid = this.state.sectionData.uuid, dataModel = {};
            for (let index in this.props.dealsHubAdmin) {
                let current = this.props.dealsHubAdmin[index];
                if (current.uuid === uuid) {
                    dataModel["uuid"] = uuid;
                    dataModel["name"] = current.name;
                    dataModel["listingType"] = current.listingType;
                    dataModel["isPublished"] = current.isPublished;
                    let temp = [];
                    for (let index in current.products) {
                        temp.push(current.products[index].uuid);
                    }
                    dataModel["products"] = temp;
                    if (
                        this.state.promotionActive &&
                        this.validatePromotionAttributes()
                    ) {
                        dataModel["is_promotional"] = true;
                        dataModel["promotion"] = {
                            start_date: this.state.promotionStartDate,
                            end_date: this.state.promotionEndDate,
                            promotional_tag: this.state.promotionalTag
                        };
                    } else {
                        dataModel["is_promotional"] = false;
                    }
                    break;
                }
            }

            if (this.state.promotionActive && !this.validatePromotionAttributes()) {
                this.setState({
                    categoryUpdationInProgress: false
                });
                message.warning("Incorrect promotional attributes!");
            } else {
                axios.post(`${this.state.serverUrl}/dealshub/update-admin-category/`, {
                    sectionData: dataModel
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            categoryUpdationInProgress: false
                        });
                        message.success("Category Updated Successfully!");
                        this.props.sectionData.name = dataModel.name;
                    } 
                    else if(response.data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                    else {
                        message.error("Something went wrong!");
                    }
                });
            }
        });
    };

    changeCategoryName = (event) => {
        this.props.handleUpdate({
            uuid: this.state.sectionData.uuid,
            value: event.target.value
        });
    };

    changeListingType = event => {
        let uuid = this.state.sectionData.uuid;

        for (let i in this.props.dealsHubAdmin) {
            if (this.props.dealsHubAdmin[i].uuid === uuid) {
                this.props.dealsHubAdmin[i].listingType = event.target.value;
            }
        }
        this.props.handleUpdate(this.props.dealsHubAdmin);
    };

    deleteCategory = () => {
        this.setState({
            categoryDeletionInProgress: true
        }, () => {
            let uuid = this.state.sectionData.uuid;

            axios.post(`${this.state.serverUrl}/dealshub/delete-admin-category/`, {
                uuid: uuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.data.status === 200) {
                    let requiredIndex = 0;
                    for (let i in this.props.dealsHubAdmin) {
                        if (this.props.dealsHubAdmin[i].uuid === uuid) {
                            requiredIndex = i;
                            break;
                        }
                    }
                    this.props.deleteCategory(requiredIndex);
                    this.setState({
                        categoryDeletionInProgress: false
                    });
                    message.success("Category Deleted Successfully!");
                } 
                else if(response.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Something went wrong!");
                }
            });
        });
    };

    publishCategory = () => {
        this.setState({
            categoryPublishInProgress: true
        });
        let uuid = this.state.sectionData.uuid;

        axios.post(`${this.state.serverUrl}/dealshub/publish-admin-category/`, {
            uuid: uuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                for (let i in this.props.dealsHubAdmin) {
                    if (this.props.dealsHubAdmin[i].uuid === uuid) {
                        this.props.dealsHubAdmin[i].isPublished = true;
                    }
                }
                this.setState({
                    categoryPublishInProgress: false
                });
                message.success("Category Published Successfully!");
            } 
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else {
                message.error("Something went wrong!");
            }
        });

        this.props.handleUpdate(this.props.dealsHubAdmin);
    };

    unpublishCategory = () => {
        this.setState({
            categoryPublishInProgress: true
        });
        let uuid = this.state.sectionData.uuid;

        axios.post(`${this.state.serverUrl}/dealshub/unpublish-admin-category/`, {
            uuid: uuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                for (let i in this.props.dealsHubAdmin) {
                    if (this.props.dealsHubAdmin[i].uuid === uuid) {
                        this.props.dealsHubAdmin[i].isPublished = false;
                    }
                }
                this.setState({
                    categoryPublishInProgress: false
                });
                message.success("Category Unpublished Successfully!");
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else {
                message.error("Something went wrong!");
            }
        });
    };

    bulkUpload = () => {
        this.setState({
            spinnerLoading: true
        });

        let uuid = this.state.sectionData.uuid;
        let formData = new FormData();
        let fileToBeUploaded = document.getElementById(`file-${uuid}`);
        formData.append("import_file", fileToBeUploaded.files[0]);
        formData.append("uuid", uuid);

        axios.post(`${serverUrl}/dealshub/section-bulk-upload/`, formData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                let length = this.props.dealsHubAdmin.length;
                let temp = this.props.dealsHubAdmin[length - 1]["products"];
                temp = response.data.products;

                for (let pro in this.props.dealsHubAdmin) {
                    if (this.props.dealsHubAdmin[pro].uuid === uuid) {
                        this.props.dealsHubAdmin[pro]["products"] = temp;
                        break;
                    }
                }

                this.setState({
                    spinnerLoading: false,
                });
                message.success("Products added Successfully!");
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else {
                message.error("Something went Wrong!");
            }
        });
        this.props.handleUpdate(this.props.dealsHubAdmin);
    };

    beforeUpload = (file) => {
        // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
        // if (!isJpgOrPng) {
        //     message.error("You can only upload JPG/PNG/GIF file!");
        // }
        let isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isLt2M;
    }

    removeProduct = (productUuid) => {
        this.setState({
            productListUpdationinProgress: true
        }, () => {
            let length = this.props.dealsHubAdmin.length,
                temp,
                requiredIndex = 0;

            for (let index = 0; index < length; ++index) {
                if (this.props.dealsHubAdmin[index].uuid == this.state.sectionData.uuid) {
                    let newProducts = [];
                    temp = this.props.dealsHubAdmin[index]["products"];
                    for (let innerIndex = 0; innerIndex < temp.length; ++innerIndex) {
                        if (temp[innerIndex]["uuid"] !== productUuid) {
                            newProducts.push(temp[innerIndex]);
                        }
                    }
                    temp = newProducts;
                    requiredIndex = index;
                    break;
                }
            }

            this.props.dealsHubAdmin[requiredIndex]["products"] = temp;
            this.props.handleUpdate(this.props.dealsHubAdmin);

            axios.post(`${serverUrl}/dealshub/delete-product-from-section/`, {
                sectionUuid: this.state.sectionData.uuid,
                productUuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.data.status === 200) {
                    message.success("Product Removed Successfully!");
                    this.setState({
                        productListUpdationinProgress: false
                    });
                } 
                else if(response.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Something went wrong!");
                }
            });
        });
    };

    handleHoveringBannerUpload = ({file, fileList}) => {
        if(file.size > 2097152) {
            let temp = [];
            for(let i=0; i<fileList.length-1; i++){
                temp.push(fileList[i]);
            }
            this.setState({ hoveringFileList: temp });
        } else {
            this.setState({ hoveringFileList: fileList });
        }

        if(file.size <= 2097152 && fileList.length>0 && !this.hoverImageUploadingInProgress){
            this.setState({hoverImageUploadingInProgress:true})
            const currentImage = fileList[fileList.length-1]
            let formdata = new FormData()
            formdata.append("uuid",this.state.sectionData.uuid)
            formdata.append("image",currentImage.originFileObj)
            if(currentImage.originFileObj !== undefined){
                axios({
                    method:"POST",
                    url: `${serverUrl}/dealshub/add-section-hovering-image/`,
                    data: formdata,
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                })
                .then(response=>{
                    this.props.sectionData.hoveringBannerUrl = response.data.url
                    this.props.sectionData.hoveringBannerUuid = response.data.uuid
                    this.setState({sectionData:this.props.sectionData})
                    // this.props.handleUpdate(this.props.sectionData)
                    this.setState({hoverImageUploadingInProgress:false})
                })
            }
        }
    }

    deleteHoverBannerImage = () => {
        this.setState({isDeleteOnHover:true},()=>{
            axios({
                method:"POST",
                url: `${serverUrl}/dealshub/delete-hovering-image/`,
                data:{uuid:this.state.sectionData.hoveringBannerUuid},
                headers:{
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
            .then(response=>{
                if(response.data.status===200){
                    this.props.sectionData.hoveringBannerUrl = ""
                    this.props.sectionData.hoveringBannerUuid = ""
                    this.setState({sectionData:this.props.sectionData, hoveringFileList:[]})
                    // this.props.handleUpdate(this.props.sectionData)
                    this.setState({isDeleteOnHover:false})
                }
            })
        })
    }

    addProduct = (productUuid) => {
        this.setState({
            productListUpdationinProgress: true
        }, () => {
            axios.post(`${serverUrl}/dealshub/add-product-to-section/`, {
                locationGroupUuid: this.props.locationUuid,
                sectionUuid: this.state.sectionData.uuid,
                productUuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                this.setState({
                    autoCompleteSearchString : ""
                })
                if (response.data.status === 200) {
                    let length = this.props.dealsHubAdmin.length, requiredIndex;

                    for (let index = 0; index < length; ++index) {
                        if (this.props.dealsHubAdmin[index].uuid == this.state.sectionData.uuid) {
                            requiredIndex = index;
                            break;
                        }
                    }
                    if(this.props.siteName === "WIGme - B2B"){
                        this.props.dealsHubAdmin[requiredIndex]["products"].push({
                            thumbnailImageUrl: response.data.thumbnailImageUrl,
                            name: response.data.name,
                            displayId: response.data.displayId,
                            uuid: productUuid,
                            sellerSku: response.data.sellerSku,
                            now_price: response.data.now_price,
                            promotional_price: response.data.promotional_price,
                            status: response.data.status,
                            was_price: response.data.was_price,
                            stock: response.data.stock,
                            product_is_promotional: response.data.is_product_promotional,
                            promotional_price_cohort1: response.data.promotional_price_cohort1,
                            promotional_price_cohort2: response.data.promotional_price_cohort2,
                            promotional_price_cohort3: response.data.promotional_price_cohort3,
                            promotional_price_cohort4: response.data.promotional_price_cohort4,
                            promotional_price_cohort5: response.data.promotional_price_cohort5,
                            now_price_cohort1: response.data.now_price_cohort1,
                            now_price_cohort2: response.data.now_price_cohort2,
                            now_price_cohort3: response.data.now_price_cohort3,
                            now_price_cohort4: response.data.now_price_cohort4,
                            now_price_cohort5: response.data.now_price_cohort5,
                        });
                    } else {
                        this.props.dealsHubAdmin[requiredIndex]["products"].push({
                            thumbnailImageUrl: response.data.thumbnailImageUrl,
                            name: response.data.name,
                            displayId: response.data.displayId,
                            uuid: productUuid,
                            sellerSku: response.data.sellerSku,
                            now_price: response.data.now_price,
                            promotional_price: response.data.promotional_price,
                            status: response.data.status,
                            was_price: response.data.was_price,
                            stock: response.data.stock,
                            product_is_promotional: response.data.is_product_promotional,
                        });
                    }

                    this.props.handleUpdate(this.props.dealsHubAdmin);
                    
                    message.success("Product Added Successfully!");
                } 
                else if(response.data.status === 403)
                {
                    if(response.data.is_product_promotional) {
                        message.warn("Product already in Promotion")
                    } else {
                        message.info("You are not authorised!")
                    }
                }
                else {
                    message.error("Something went wrong!");
                }
                this.setState({
                    productListUpdationinProgress: false
                });
            });
        });
    };

    clearPromotionalAttributes = () => {
        this.setState({
            promotionStartDate: null,
            promotionEndDate: null,
            promotionalTag: null
        });
    }

    setPromotionActive = (newPromotionStatus) => {
        this.setState({
            promotionActive: newPromotionStatus
        }, () => {
            if (!newPromotionStatus) {
                this.clearPromotionalAttributes();
            }
        });
    }

    changePromotionRange = (dateRangeMoment, dateRangeStrings) => {
        this.setState({
            promotionStartDate: dateRangeStrings[0] === "" ? null : dateRangeStrings[0].replace(" ", "T").concat("Z"),
            promotionEndDate: dateRangeStrings[1] === "" ? null : dateRangeStrings[1].replace(" ", "T").concat("Z")
        });
    }

    changePromotionTag = (event) => {
        this.setState({
            promotionalTag: event.target.value === "" ? null : event.target.value
        });
    }

    editPromoPrice = (product) => {
        this.setState({
            cohortPricesModal: true,
            promotional_price: product.promotional_price,
            cohort_price1: product.promotional_price_cohort1,
            cohort_price2: product.promotional_price_cohort2,
            cohort_price3: product.promotional_price_cohort3,
            cohort_price4: product.promotional_price_cohort4,
            cohort_price5: product.promotional_price_cohort5,
            now_price: product.now_price,
            now_price1: product.now_price_cohort1,
            now_price2: product.now_price_cohort2,
            now_price3: product.now_price_cohort3,
            now_price4: product.now_price_cohort4,
            now_price5: product.now_price_cohort5,
            product_uuid: product.uuid
        })
    }

    setPromoPrices = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        }, () => {
            console.log(this.state.cohort_price1, "PRICE")
        })
    }

    updatePromoPrices = () => {
        this.setState({
            savePromoLoading: true
        })
        axios.post(`${serverUrl}/update-dealshub-product/`, {
            product_uuid: this.state.product_uuid,
            locationGroupUuid: this.props.locationUuid,
            promotional_price: this.state.promotional_price,
            promotional_price_cohort1: this.state.cohort_price1,
            promotional_price_cohort2: this.state.cohort_price2,
            promotional_price_cohort3: this.state.cohort_price3,
            promotional_price_cohort4: this.state.cohort_price4,
            promotional_price_cohort5: this.state.cohort_price5,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            }
        }).then(res => {
            if(res.data.status === 200) {
                let setionsLength = this.props.dealsHubAdmin.length, requiredIndex1, productsLength;

                for (let index = 0; index < setionsLength; ++index) {
                    if (this.props.dealsHubAdmin[index].uuid === this.state.sectionData.uuid) {
                        requiredIndex1 = index;
                        break;
                    }
                }

                productsLength = this.props.dealsHubAdmin[requiredIndex1]["products"].length

                for(let index = 0; index < productsLength; ++index) {
                    if(this.props.dealsHubAdmin[requiredIndex1]["products"][index].uuid === this.state.product_uuid) {
                        this.props.dealsHubAdmin[requiredIndex1]["products"][index] = {
                            ...this.props.dealsHubAdmin[requiredIndex1]["products"][index],
                            promotional_price: this.state.promotional_price,
                            promotional_price_cohort1: this.state.cohort_price1,
                            promotional_price_cohort2: this.state.cohort_price2,
                            promotional_price_cohort3: this.state.cohort_price3,
                            promotional_price_cohort4: this.state.cohort_price4,
                            promotional_price_cohort5: this.state.cohort_price5,
                        }
                        break;
                    }
                }
                this.props.handleUpdate(this.props.dealsHubAdmin);
                message.success("Promo Price Applied Successfully!")
                this.setState({savePromoLoading: false, cohortPricesModal: false});
            } else if(res.data.status === 403) {
              message.info("You are not authorised!");
              this.setState({savePromoLoading: false, cohortPricesModal: false});
            }
            else {
                message.error("Something went wrong during update");
                this.setState({savePromoLoading: false, cohortPricesModal: false});
            }
        })
    }

    loadData = () =>{
        if(this.props.sectionData.products === undefined){
            this.setState({innerSpinner: true});
            axios.post(`${serverUrl}/dealshub/fetch-${this.props.siteName === "WIGme - B2B" ? "b2b-" : ""}section-detail/`, {
                SectionUuid: this.props.sectionData.uuid,
                locationGroupUuid: this.props.locationUuid
            },{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                    },
            }).then(response =>{
                if(response.data.status === 200){
                    this.props.sectionData.products = response.data.sections_list[0].products;
                    this.props.sectionData.createdBy = response.data.sections_list[0].createdBy;
                    this.props.sectionData.createdOn = response.data.sections_list[0].createdOn;
                    this.props.sectionData.end_time = response.data.sections_list[0].end_time;
                    this.props.sectionData.hoveringBannerUrl = response.data.sections_list[0].hoveringBannerUrl;
                    this.props.sectionData.hoveringBannerUuid = response.data.sections_list[0].hoveringBannerUuid;
                    this.props.sectionData.is_promotional = response.data.sections_list[0].is_promotional;
                    this.props.sectionData.listingType = response.data.sections_list[0].listingType;
                    this.props.sectionData.modifiedBy = response.data.sections_list[0].modifiedBy;
                    this.props.sectionData.modifiedOn = response.data.sections_list[0].modifiedOn;
                    this.props.sectionData.promotion_tag = response.data.sections_list[0].promotion_tag;
                    this.props.sectionData.remaining_time = response.data.sections_list[0].remaining_time;
                    this.props.sectionData.start_time = response.data.sections_list[0].start_time;

                    this.setState({
                        sectionData: this.props.sectionData,
                        promotionActive: this.props.sectionData.is_promotional,
                        promotionStartDate: this.props.sectionData.start_time,
                        promotionEndDate: this.props.sectionData.end_time,
                        promotionalTag: this.props.sectionData.promotion_tag,
                        innerSpinner: false,
                    });
                }
                else{
                    message.error('Something Went Wrong!');
                }

            });
        }
        if(this.props.sectionData.products) {
            this.props.loadCategoryOrBanner({
                itemUuid: this.props.sectionData.uuid,
                itemValue: {
                    ...this.props.sectionData
                },
            });
        }
    }

    render() {
        if (this.state.sectionData) {
            return (
                <>
                    {this.state.categoryDeletionInProgress
                        ? (
                            <div className="dealshub-admin-section-category-deletion-in-progress-container">
                                <Spin indicator={antIcon} tip="Deleting Category Data" />
                            </div>
                        ) : (
                            <Collapse
                                expandIconPosition="left"
                                onChange={(e)=>{if(e.length === 1) this.loadData() }}
                            // defaultActiveKey={[this.state.sectionData.uuid]}
                            >
                                <Panel
                                    header={this.props.sectionData.name}
                                    // key={this.state.sectionData.uuid}
                                    extra={this.genExtra(["Section", "green"])}
                                    className="dealshub-admin-collapse-panel"
                                >
                                {
                                    this.state.innerSpinner ? (
                                        <Spin style={{display: 'flex', justifyContent: 'center'}}/>
                                    ):(
                                        <div className="categorycontainer">
                                            <Row gutter={[8, 24]}>
                                                <Col span={24}>
                                                    <Text className="text-color3">Category Name</Text>
                                                    <Input
                                                        data-categoryid={this.props.sectionData.uuid}
                                                        defaultValue={this.props.sectionData.name}
                                                        placeholder="Enter Category Name here"
                                                        maxLength={100}
                                                        onChange={this.changeCategoryName}
                                                        style={{ marginTop: "5px" }}
                                                    />
                                                </Col>

                                                


                                                <Col span={24}>
                                                    <Text>Promotion Status : </Text>
                                                    <Switch
                                                        checked={this.state.promotionActive}
                                                        onChange={this.setPromotionActive}
                                                    />
                                                </Col>
                                                {this.state.promotionActive ? (
                                                    <Col span={24}>
                                                        <Row gutter={[8, 24]} type = "flex" align = "bottom">
                                                            <Col span={12}>
                                                                <Text className="text-color3">Promotional Tag</Text>
                                                                <Input
                                                                    defaultValue={
                                                                        this.state.promotionalTag ?
                                                                            this.state.promotionalTag :
                                                                            ""
                                                                    }
                                                                    placeholder="Enter Promotion Tag here"
                                                                    maxLength={30}
                                                                    onChange={this.changePromotionTag}
                                                                    style={{ marginTop: "5px" }}
                                                                />
                                                            </Col>
                                                            <Col span={12}>
                                                                <RangePicker
                                                                    defaultValue={
                                                                        this.state.promotionStartDate && this.state.promotionEndDate ?
                                                                            [
                                                                                moment(this.state.promotionStartDate, this.rangePickerDateFormat),
                                                                                moment(this.state.promotionEndDate, this.rangePickerDateFormat)
                                                                            ] : null
                                                                    }
                                                                    showTime={{ format: 'HH:mm:ss' }}
                                                                    format={this.rangePickerDateFormat}
                                                                    placeholder={['Start Time', 'End Time']}
                                                                    onOk={() => { }}
                                                                    onChange={this.changePromotionRange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                ) : (
                                                        <></>
                                                    )}
                                            


                        <Col span = {24}>
                        <Row type = "flex" gutter = {[12,12]} align = "middle">
                                <Col>
                                <Button loading={this.state.loadingDownload}
                                disabled={this.state.loadingDownload}
                                onClick = {this.downloadProducts}
                                style={{ width: "100%",marginTop : "4px" }}
                                type="primary"> Download Products </Button>
                                </Col>
                                <Col>
                                <UploadBtn style={{ width: "100%" }} beforeUpload={this.handleSelectFile} onRemove={this.handleUnselectFile} fileList={this.state.fileList}>
                                    <Button loading={this.state.loading}
                                    disabled={this.state.fileList.length !== 0 || this.state.published}
                                    style={{ width: "100%", }}
                                    type="primary"> Bulk Upload </Button>
                                </UploadBtn>
                                </Col>
                                <Col style = {{margin : "4px"}}>
                                <Text> Use <a style = {{fontStyle : "bold" , color : "white"}} href = "https://qa.omnycomm.com/static/media/Section-Bulk-Upload-Products-Demo-Template.457cc86e.xlsx" target = "_blank">This Template</a> for uploading products for this banner </Text>
                                </Col>
                            </Row>
                        </Col>


                                                <Col span={24}>
                                                    <Row className="productslist">
                                                        <Col span={24}>
                                                            <AutoComplete
                                                                dataSource={this.state.searchListing}
                                                                onSelect={(selectedValue) => {
                                                                    this.addProduct(selectedValue);
                                                                }}
                                                                value= {this.state.autoCompleteSearchString}
                                                                onChange = {(e) => {
                                                                    this.setState({
                                                                        autoCompleteSearchString : e
                                                                    })
                                                                }}
                                                                onSearch={this.autocompleteSearch}
                                                                placeholder="Search for Products"
                                                                style={{ width: "100%" }}
                                                                disabled = {this.state.sectionData?.products?.length >= 40}
                                                            />
                                                        </Col>
                                                        <Col span={24} className="added-products-listing">
                                                            <Spin
                                                                indicator={antIcon} tip="Updating Products List Data"
                                                                spinning={this.state.productListUpdationinProgress}
                                                            >
                                                                <Row
                                                                    style={{ padding: "20px 0px 0px 0px" }}
                                                                >
                                                                    {this.state.sectionData?.products?.map((product, index) => (
                                                                        <Col
                                                                            sm={24}
                                                                            md={24}
                                                                            lg={24}
                                                                            xl={12}
                                                                            xxl={12}
                                                                            // span={24}
                                                                            key={product.uuid}
                                                                        >
                                                                            <Card bordered className="categoryproductcard">
                                                                                <Row gutter={[16, 24]}>
                                                                                    <Col span={4}>
                                                                                        <Avatar shape="square" size={64} src={product.thumbnailImageUrl} />
                                                                                    </Col>
                                                                                    <Col span={20}>
                                                                                        <Tooltip title={product.name} >
                                                                                            <Paragraph
                                                                                                className="text-color3"
                                                                                                ellipsis={{ rows: 2, expandable: false }}
                                                                                                style={{
                                                                                                    boxSizing: "border-box",
                                                                                                    height: "42px",
                                                                                                    marginBottom: "0px"
                                                                                                }}
                                                                                            >
                                                                                                {product.name}
                                                                                            </Paragraph>
                                                                                        </Tooltip>
                                                                                        <Text className="text-color3">ID : </Text>
                                                                                        <Text>{product.sellerSku} | {product.displayId}</Text>
                                                                                    </Col>
                                                                                </Row>
                                                                                {this.state.promotionActive ? (
                                                                                    <div className="promotional-product-price-wrapper">
                                                                                        <Row>
                                                                                            <Col span={12}>
                                                                                                <Text>Price : {product.now_price}</Text>
                                                                                            </Col>
                                                                                            <Col span={12}>
                                                                                                <Text>Previous Price : <Text
                                                                                                    style={{
                                                                                                        textDecoration: "line-through"
                                                                                                    }}
                                                                                                >{product.was_price}</Text>
                                                                                                </Text>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col span={12} style={{ height: "10px" }}>
                                                                                                <Text>
                                                                                                    Promo Price : {
                                                                                                        this.props.siteName === "WIGme - B2B" ? (
                                                                                                            <>
                                                                                                            {product.promotional_price}
                                                                                                            {" "}
                                                                                                            <Icon type="edit" 
                                                                                                                style={{color: "#3CC9A9", fontSize: "12px", cursor: "pointer"}}
                                                                                                                onClick={() => this.editPromoPrice(product)}    
                                                                                                            />
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <EditableProductAttributes
                                                                                                                isSection={true}
                                                                                                                productIndex={index}
                                                                                                                sectionIndex={this.props.sectionIndex}
                                                                                                                uuid={product.uuid}
                                                                                                                value={product.promotional_price}
                                                                                                                name="promotional_price"
                                                                                                            />
                                                                                                        )
                                                                                                        }
                                                                                                </Text>
                                                                                            </Col>
                                                                                            <Col span={12}>
                                                                                                <Text>Stock : {product.stock}</Text>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Col span={24} style={{height: 14}}>
                                                                                            <Text type="danger" style={{fontSize: 12}}>
                                                                                                {
                                                                                                    product.product_is_promotional && (
                                                                                                        "This Product will be removed from this section as it is already under Promotion!"
                                                                                                    )
                                                                                                }
                                                                                                
                                                                                            </Text>
                                                                                        </Col>
                                                                                    </div>
                                                                                ) : (
                                                                                        <></>
                                                                                    )}
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: "6px",
                                                                                        right: "10px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.removeProduct(product.uuid);
                                                                                    }}
                                                                                    value={this.props.sectionData.uuid}
                                                                                    productid={product.uuid}
                                                                                >X</div>
                                                                            </Card>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </Spin>
                                                        </Col>
                                                    </Row>
                                                </Col>

                    

                    

                        {this.state.errorShow && <Col span = {24}>
                            <Text style = {{color : "red"}}>
                                Please note that {this.state.unsuccessful_count} products could not be added to section/banner because of the following reasons:
                            </Text>
                            <ul>
                                <li>The product may not exist</li>
                                <li>The product may be unpublished</li>
                                <li>The product may be out of stock</li>
                                <li>The product price may be 0</li>
                            </ul>
                        </Col>}
                    

                    


                                                <Col span={24}
                                                    style={{
                                                        padding: "20px 0px"
                                                    }}
                                                >
                                                    <Paragraph className="text-color3"
                                                        ellipsis={{ rows: 2, expandable: false }}
                                                        style={{ marginBottom: "15px" }}
                                                    >
                                                        Hovering Banner :
                                                        {this.state.sectionData &&
                                                            this.state.sectionData?.hoveringBannerUrl && this.state.sectionData?.hoveringBannerUrl !== "" ? (
                                                                <Button
                                                                    style={{ marginLeft: "15px" }}
                                                                    type="danger"
                                                                    onClick={this.deleteHoverBannerImage}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            ) : (
                                                                <></>
                                                            )}
                                                    </Paragraph>
                                                    {this.state.isDeleteOnHover ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <Spin indicator={antIcon} tip="Deleting Hover Banner Image" />
                                                        </div>
                                                    ) : (
                                                            <>
                                                                {this.state.hoverImageUploadingInProgress ? (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <Spin indicator={antIcon} tip="Uploading Hover Banner Image" />
                                                                    </div>
                                                                ) : (
                                                                        <>
                                                                            {
                                                                                this.state.sectionData && this.state.sectionData?.hoveringBannerUrl &&
                                                                                    this.state.sectionData?.hoveringBannerUrl !== "" ? (
                                                                                        <img alt="example" style={{ width: "100%" }} src={this.state.sectionData?.hoveringBannerUrl} />
                                                                                    ) : (
                                                                                        <Upload
                                                                                            accept=".jpg, .jpeg, .png, .gif"
                                                                                            listType="picture-card"
                                                                                            fileList={this.state.hoveringFileList}
                                                                                            onChange={this.handleHoveringBannerUpload}
                                                                                            customRequest={this.dummyRequest}
                                                                                            beforeUpload={this.beforeUpload}
                                                                                        >
                                                                                            <div>
                                                                                                <Icon type="camera" />
                                                                                                <div className="ant-upload-text">
                                                                                                    You can drag and drop images or upload images
                                                                                                </div>
                                                                                            </div>
                                                                                        </Upload>
                                                                                    )}
                                                                        </>
                                                                    )}
                                                            </>
                                                        )}
                                                </Col>
                                                <Col span={24}>
                                                    <div className="btn-wrapper">
                                                        <Button
                                                            style={{
                                                                background: "blue",
                                                                border: "none",
                                                                color: "#fff"
                                                            }}
                                                            onClick={this.saveCategory}
                                                            loading={this.state.categoryUpdationInProgress}
                                                        >Save</Button>

                                                        {this.props.sectionData.isPublished ? (
                                                            <Button
                                                                onClick={this.unpublishCategory}
                                                                loading={this.state.categoryPublishInProgress}
                                                            >Unpublish</Button>
                                                        ) : (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={this.publishCategory}
                                                                    loading={this.state.categoryPublishInProgress}
                                                                >Publish</Button>
                                                            )}
                                                        <Button
                                                            type="danger"
                                                            loading={this.state.categoryDeletionInProgress}
                                                            onClick={this.deleteCategory}
                                                        >Delete</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>)}
                                </Panel>
                            </Collapse>
                        )}
                        <Modal
                            visible={this.state.cohortPricesModal}
                            width="fit-content"
                            okText="Save"
                            title="Promo Prices"
                            onCancel={() => this.setState({cohortPricesModal: false})}
                            onOk={() => this.updatePromoPrices()}
                            confirmLoading={this.state.savePromoLoading}
                        >
                            <Row>
                                <Col span={12} style={{display: 'flex', flexDirection: "column", padding: 10}}>
                                    Default (Now Price: {this.state.now_price}) 
                                    <Input 
                                        name="promotional_price" 
                                        value={this.state.promotional_price} 
                                        onChange={(e) => this.setPromoPrices(e)}
                                    />
                                </Col>
                                <Col span={12} style={{display: 'flex', flexDirection: "column", padding: 10}}>
                                    Cohort 1 (Now Price: {this.state.now_price1}) 
                                    <Input 
                                        name="cohort_price1" 
                                        value={this.state.cohort_price1}
                                        onChange={(e) => this.setPromoPrices(e)}
                                    />
                                </Col>
                                <Col span={12} style={{display: 'flex', flexDirection: "column", padding: 10}}>
                                    Cohort 2 (Now Price: {this.state.now_price2}) 
                                    <Input 
                                        name="cohort_price2" 
                                        value={this.state.cohort_price2}
                                        onChange={(e) => this.setPromoPrices(e)}
                                    />
                                </Col>
                                <Col span={12} style={{display: 'flex', flexDirection: "column", padding: 10}}>
                                    Cohort 3 (Now Price: {this.state.now_price3}) 
                                    <Input 
                                        name="cohort_price3" 
                                        value={this.state.cohort_price3}
                                        onChange={(e) => this.setPromoPrices(e)}
                                    />
                                </Col>
                                <Col span={12} style={{display: 'flex', flexDirection: "column", padding: 10}}>
                                    Cohort 4 (Now Price: {this.state.now_price4}) 
                                    <Input 
                                        name="cohort_price4" 
                                        value={this.state.cohort_price4}
                                        onChange={(e) => this.setPromoPrices(e)}
                                    />
                                </Col>
                                <Col span={12} style={{display: 'flex', flexDirection: "column", padding: 10}}>
                                    Cohort 5 (Now Price: {this.state.now_price5}) 
                                    <Input 
                                        name="cohort_price5" 
                                        value={this.state.cohort_price5}
                                        onChange={(e) => this.setPromoPrices(e)}
                                    />
                                </Col>
                            </Row>
                        </Modal>
                </>
            );
        } else {
            return (<></>);
        }
    }
}

const mapStateToProps = ({ dealsHubAdmin }) => {
    return {
        dealsHubAdmin
    };
};

export default connect(mapStateToProps, {
    handleUpdate,
    deleteBanner,
    publishBanner,
    unpublishBanner,
    handleDelete,
    loadCategoryOrBanner 
})(GenericPromotionalEngine);
