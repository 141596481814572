import React, { Component } from "react";
import { Typography } from "antd";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calender-component.scss"

const { Text } = Typography;
export default class CalenderComponent extends Component {
  render() {
    const star = <Text style={{ color: "#EF556B" }}>*</Text>;
    const err = (
      <Text style={{ color: "red", fontSize: "8px" }}>
        {this.props.errormsg}
      </Text>
    );

    //.log(this.props, "DELTA")

    return (
      <React.Fragment>
        <Text style={this.props.labelStyle}>{this.props.label} {this.props.impo ? star : ""}{" "}</Text>{err}
        <DatePicker
          size={this.props.size}
          className="custom-datePicker"
          style={{ backgroundColor: "#36404A", width: "100%" }}
          onChange={this.props.getDate}
          selected={this.props.value}
        />
      </React.Fragment>
    );
  }
}
