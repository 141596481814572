import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
/**
* @description This is index file of store for the redux state(global)
*/
/**
 * @constant composeEnhancers
 * @description It is a reference to function which is either __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ or compose
 * if clients browser has redux dev tools extension installed, it sets to __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
 * else redux compose
 */
const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
        : compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
/**
 * @constant store
 * @description It creates a store with all the reducers and enhancers.
 */
const store = createStore(rootReducer, enhancer);
export default store;