import React, { Component } from 'react'
import { Input, Typography } from "antd";
import "./input-link.scss"
const { Text } = Typography;

export default class InputLink extends Component {
    render() {
        return (
            <React.Fragment>
                <Text {...this.props.label}>
                    {this.props.labelText}
                </Text>
                <Input {...this.props.input}
                    // addonBefore="https://"
                    // addonAfter=".com"
                    style={{ border: "1px solid #45525f", background: "#3C4853" }}
                    onChange={this.props.onChange} />
            </React.Fragment>
        )
    }
}
