import React, { Component } from "react";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {
  ChannelProductPageWrapper,
  EbppCol
} from "./styled-components-library";
import { Row, Col } from "antd";
// import InputField from "../components/datainputs/InputField";
import ImageManagerEditChannelPage from "../components/datainputs/image-manager/ImageManagerEditChannelPage";
// import FormHeader from "../components/datainputs/FormHeader";
// import InputNumberDimensions3 from "../components/datainputs/InputNumberDimesions3";
import "./channel-page.scss";
import AmazonUkForm from "../components/channelform/AmazonUkForm";
import AmazonUAE from "../components/channelform/AmazonUAE";
import Ebay from "../components/channelform/Ebay";
import Noon from "../components/channelform/Noon";
import { fetchChannelImages } from "../../actions/channelProduct";
import { connect } from "react-redux";

class ChannelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: "",
      images: null
    };
  }

  componentDidMount = () => {

    this.props.drawerOn();
  };

  getImages = imageData => {
    this.setState({ images: imageData });
  };

  fetchImagesOnly = (pk, name) => {
    this.props.fetchChannelImages(pk, name);
  };

  render() {


    let titlename = this.props.channel_name.split("-").join(" ");

    if (titlename == "amazon uk") titlename = "Amazon UK";
    else if (titlename == "amazon uae") titlename = "Amazon UAE";
    else if (titlename == "noon") titlename = "Noon";
    else titlename = "Ebay";

    return (
      <React.Fragment>
        <ChannelProductPageWrapper>
          <TitleWithBreadCrumb
            title={titlename}
            breadcrumb={["OmnyComm", "Product Edit"]}
          />

          <Row gutter={[40, 40]}>
            <Col className="gutter-row" lg={12} sm={24}>
              {titlename === "Amazon UK" && (
                <AmazonUkForm
                  sendImages={this.getImages}
                  product_pk={this.props.product_pk}
                  ToggelDrawer={this.props.ToggelDrawer}
                />
              )}
              {titlename === "Amazon UAE" && (
                <AmazonUAE
                  sendImages={this.getImages}
                  product_pk={this.props.product_pk}
                  ToggelDrawer={this.props.ToggelDrawer}
                />
              )}
              {titlename === "Noon" && (
                <Noon
                  sendImages={this.getImages}
                  product_pk={this.props.product_pk}
                  ToggelDrawer={this.props.ToggelDrawer}
                />
              )}
              {titlename === "Ebay" && (
                <Ebay
                  sendImages={this.getImages}
                  product_pk={this.props.product_pk}
                  ToggelDrawer={this.props.ToggelDrawer}
                />
              )}
            </Col>
            <Col className="gutter-row" lg={12} sm={24}>
              <Row gutter={[40, 40]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box">
                    <Col>
                      <ImageManagerEditChannelPage
                        product_pk={this.props.product_pk}
                        channel_name={titlename}
                        images={this.state.images && this.state.images}
                        fetchImagesOnly={this.props.fetchChannelImages}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ChannelProductPageWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => { };

export default connect(mapStateToProps, { fetchChannelImages })(ChannelPage);
