/**
 * @description This is product list reducer
 */

/**
 * @description type defination for productList state
 * @typedef ProductListStateType - type for productList state
 * @property {null | object} productData - This is product data to be mapped
 * @property {{verified: boolean, start_date: string, end_date: string, brand_name: string, has_image: string }} filters - These are filters for products
 * @property {Array} tags - tags all tags of product
 * @property {{verified: boolean, start_date: string, end_date: string, brand_name: string, has_image: string}} filters_content_health
 * @property {Array} tags_content_health
 */

/**
 * @type {ProductListStateType} - InitialState for productList reducer
 */
const initialState = {
  productData: null,
  filters: {
    verified: false,
    start_date: "",
    end_date: "",
    brand_name: "",
    has_image: "",
  },
  tags: [],
  filters_content_health: {
    verified: false,
    start_date: "",
    end_date: "",
    brand_name: "",
    has_image: "",
  },
  tags_content_health: [],
};

/**
 * @description This is productList reducer function changes the state according to the dispatch action
 * @param {ProductListStateType} state - initialState as default
 * @param {object} action - reducer action
 * @returns {ProductListStateType} - returns updated state
 */

export default function productList(state = initialState, action) {
  switch (action.type) {
    case "FETCH_PRODUCT_LIST":
      return { ...state, productData: action.payload };
    case "FETCH_PRODUCT_LIST_FROM_TAGS":
      return { ...state, productData: action.payload };
    case "FETCH_PRODUCT_LIST_FROM_FILTER":
      return { ...state, productData: action.payload };
    case "FETCH_PRODUCT_LIST_MORE":
      return {
        ...state,
        productData: {
          ...state.productData,
          products: [...state.productData.products, ...action.payload.products],
          is_available: action.payload.is_available,
        },
      };
    // return state;
    case "ADD_TAGS_TO_PRODUCT_LIST":
      return { ...state, tags: action.payload };
    case "ADD_FILTER_TO_PRODUCT_LIST":
      return { ...state, filters: action.payload };
    case "ADD_TAGS_TO_PRODUCT_LIST_CONTENT_HEALTH":
      return { ...state, tags_content_health: action.payload };
    case "ADD_FILTER_TO_PRODUCT_LIST_CONTENT_HEALTH":
      return { ...state, filters_content_health: action.payload };
    case "SET_EMPTY":
      return { ...state, productData: null };
    default:
      return state;
  }
}
