import React from 'react';
import {Select} from 'antd';

const {Option} = Select;

const SelectAfter = (props) => {
    const handleChange = (e, value) => {
        props.handleChange(e, value);
    }
    return (
        <Select
            value={props.value}
            name={props.name}
            onChange={val => handleChange(val)}
            disabled={props.disabled}
            placeholder="unit"
            style={{ width: 80 }}
        >
            {props.list.map(unit => {
                return <Option value={`${unit}`}>{unit}</Option>;
            })}
        </Select>
    )
}

export default SelectAfter;