import React, { Component } from "react";
import { connect } from "react-redux";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { Row, Col, Affix, Tooltip, Button, Modal, message, Select, Icon } from "antd";
import { Ellipsis } from "react-spinners-css";
import axios from "axios";
import FlyerCards from "../components/cards/FlyerCards";
import AddFlyer from "../components/modals/addFlyerModal/AddFlyer";

import isDev from "../../server";
const serverUrl = isDev();

class Flyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flyer_list: null,
      visible: false,
      modalData: {},
      flyer_name: "",
      brandList: [],
      flyer_brand: "",
      type: "0",
      flyer_items: "",
      cpr: "4",
      ch: "11",
      file: {},
      mode: "A4 Portrait",
      page: 1,
      is_available: true,
      loadingInLoadMore: false,
      tags: []
    };
  }

  handelChangeInputOut = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleBrandSelect = e => {
    this.setState({ flyer_brand: e });
  };

  handleTypeSelect = e => {
    this.setState({ type: e });
  };

  handleCprSelect = e => {
    this.setState({ cpr: e });
  };

  handleModeSelect = e => {
    this.setState({ mode: e });
  };

  handleChSelect = e => {
    this.setState({ ch: e });
  };

  handleShow = () => {
    this.setState({
      visible: true
    });
  };

  handleGetModalData = data => {
    this.setState({ modalData: data });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  getFile = file => {
    this.setState({ file });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  handleSearch = () => {
    this.setState({ flyer_list: null });

    const bodyData = {
      page: 1,
      tags: this.state.tags
    };
    fetch(`${serverUrl}/fetch-flyer-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => res.json())
      .then(data => {
        this.setState(prevStatev => ({
          flyer_list: data.flyer_list,
          is_available: data.is_available,
          loadingInLoadMore: false
        }));

      });
  };

  handleSearchTags = d => {
    this.setState({ tags: d, page: 1, flyer_list: [] });
    setTimeout(() => {
      this.handleSearch();
    }, 1000);
  };

  handleCreate = () => {

    let fd = new FormData();
    fd.append("name", this.state.flyer_name);
    fd.append("brand_pk", this.state.flyer_brand.toString());
    fd.append("flyer_items", this.state.flyer_items);
    fd.append("columns_per_row", this.state.cpr);
    fd.append("grid_item_height", this.state.ch);
    fd.append("import_file", this.state.file);
    fd.append("create_option", this.state.type);
    fd.append("mode", this.state.mode);

    axios
      .post(`${serverUrl}/create-flyer/`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(res => {


        if (res["data"]["status"] == 200) {
          // window.location.pathname = "/flyer/" + res["data"]["flyer_pk"];
          window.open(
            `${serverUrl}/flyer/${res["data"]["flyer_pk"]}`,
            "_blank"
          );
        } else if (res["data"]["status"] == 403) {
          message.warning("You do not have permission to create flyers!");
        } else {
          message.error("Error creating flyer!");
        }

        // window.location.reload();
      })
      .catch(err => {

      });
  };

  componentDidMount = () => {
    const bodyData = {
      page: this.state.page,
      tags: []
    };
    fetch(`${serverUrl}/fetch-flyer-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          flyer_list: data.flyer_list,
          is_available: data.is_available
        });

      });
  };

  fetchMore = () => {
    this.setState({ loadingInLoadMore: true });
    const bodyData = {
      page: this.state.page + 1,
      tags: this.state.tags
    };
    fetch(`${serverUrl}/fetch-flyer-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          flyer_list: [...this.state.flyer_list, ...data.flyer_list],
          is_available: data.is_available,
          page: this.state.page + 1,
          loadingInLoadMore: false
        });

      });
  };

  render() {

    return (
      <Row
        style={{
          width: "95%",
          backgroundColor: "#313841",
          marginTop: "10px"
        }}
        gutter={40}
      >
        <TitleWithBreadCrumb
          title="Flyers"
          breadcrumb={["OmnyComm", "Flyers"]}
        />

        <Row
          style={{
            background: "#36404A",

            borderRadius: "5px",
            padding: "15px 15px 0px 15px",
            marginTop: "0px",
          }}
          className="flyer-content-container"
          gutter={[24, 24]}
        >
          <Col sm={24}>
            <Select
              dropdownClassName="search-chips"
              dropdownMatchSelectWidth="false"
              mode="tags"
              style={{ width: "100%" }}
              placeholder={[<Icon type="search" />, " Search here..."]}
              onChange={this.handleSearchTags}
              value={this.state.tags}
            ></Select>
          </Col>

          <Col sm={24}>
            <Row gutter={[16, 16]} type="flex" justify="start" align="middle" className="flyers-card-container">
              {/* {this.state.flyer_list &&
                this.state.flyer_list.map(e => {
                  return (
                    <Col sm={24} lg={6} md={8}>
                      <FlyerCards data={e} />
                    </Col>
                  );
                })} */}
              {this.state.flyer_list ? (
                this.state.flyer_list.map(e => {
                  return (
                    <Col sm={24} lg={6} md={8}>
                      <FlyerCards data={e} />
                    </Col>
                  );
                })
              ) : (
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Ellipsis color="#1ABC9C" size={100} />
                  </Row>
                )}
            </Row>
          </Col>
        </Row>

        {this.state.is_available && (
          <div
            className="center-child"
            style={{ width: "100%", marginTop: "20px", marginBottom: "50px" }}
          >
            <Button
              type="primary"
              loading={this.state.loadingInLoadMore}
              onClick={this.fetchMore}
            >
              {this.state.loadingInLoadMore ? "" : "Load More..."}
            </Button>
          </div>
        )}

        <Tooltip placement="top" title="Add Flyer">
          <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
            <Button
              type="primary"
              shape="circle"
              icon="plus"
              size="large"
              onClick={this.handleShow}
            ></Button>
          </Affix>
        </Tooltip>

        <Modal
          title="Add Flyer"
          visible={this.state.visible}
          onOk={this.handleCreate}
          onCancel={this.handleCancel}
          width="60vw"
        >
          <AddFlyer
            handelChangeInputOut={this.handelChangeInputOut}
            handleBrandSelect={this.handleBrandSelect}
            handleTypeSelect={this.handleTypeSelect}
            handleCprSelect={this.handleCprSelect}
            handleChSelect={this.handleChSelect}
            handleModeSelect={this.handleModeSelect}
            data={this.state}
            getFile={this.getFile}
          />
        </Modal>
      </Row>
    );
  }
}

export default Flyer;
