import React, { Component, useState } from 'react';
import './review-management.scss';
import {
	AutoComplete,
	Avatar,
	Button,
	Col,
	Icon,
	Input,
	Modal,
	Rate,
	Row,
	Select,
	Switch,
	Typography,
	message,
	Upload,
	Empty,
	Spin,
	Checkbox
} from 'antd';

import ReviewTabularModal from './ReviewTabularModal';
import isDev from '../../server';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as moment from 'moment';
import styled from 'styled-components';
import fileTemplate from './fileTemplate.xlsx';

const { Title, Text } = Typography;
const { Option } = Select;

const UploadBtn = styled(Upload)`
	&& {
		.ant-upload {
			height: 0px !important;
			width: 100% !important;
		}
		span {
			width: 100% !important;
		}
	}
`;

const serverOmnycommUrl = isDev();

export default class ReviewManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reviews: [],
			totalReviews: 0,
			areReviewsAvailable: false,
			fetchingReviewList: false,
			filterModal: false,
			addPirateReviewModal: false,
			productTerm: '',
			productTermReviews: '',
			fetchedProducts: [],
			displayName: '',
			rating: 0,
			subject: '',
			reviewBody: '',
			images: '',
			productUuid: '',
			imageurl: '',
			productname: '',
			displayOnlyPirate: false,
			displayOnlyOriginal: false,
			pirateReviews: [],
			fileList: [],
			startDate: null,
			endDate: null,
			reviewListIndex: 1,
			locationUuid: '',
			isFetching: false,
			addingReview: false,
			uploadModal: false,
			selectedLocations: []
		};
	}

	addProduct = (selectedValue) => {
		this.setState({
			productUuid: selectedValue,
		});
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/fetch-product-details/`,
			data: {
				uuid: selectedValue,
			},
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((res) => {
			this.setState({
				imageurl: res.data.heroImageUrl,
				productName: res.data.name,
			});
		});
	};

	filterProductReviews = (uuid) => {
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/fetch-reviews-admin/`,
			data: {
				locationGroupUuid: this.props.locationUuid,
				product_code: uuid,
				page: 1,
				from_date: this.state.startDate
					? moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
				to_date: this.state.endDate
					? moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			this.setState({
				areReviewsAvailable: response.data.is_available,
				reviews: response.data.reviewList,
				totalReviews: response.data.total_reviews,
			});
		});
	};

	handleStartDate = (date) => {
		this.setState({
			startDate: date,
		});
	};

	handleEndDate = (date) => {
		this.setState({
			endDate: date,
		});
	};

	handleLoadMore = () => {
		// if(this._isMounted) {
		// 	this.setState({
		// 		fetchingReviewList: true
		// 	});

		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/fetch-reviews-admin/`,
			data: {
				locationGroupUuid: this.props.locationUuid,
				from_date: this.state.startDate
					? moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
				to_date: this.state.endDate
					? moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
				page: this.state.reviewListIndex,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			this.setState({
				areReviewsAvailable: response.data.is_available,
				reviews: [...this.state.reviews, ...response.data.reviewList],
				reviewListIndex: this.state.reviewListIndex + 1,
				totalReviews: response.data.total_reviews,
				//fetchingReviewList: false
			});
		});
	};

	applyFilters = () => {
		// if(this._isMounted) {
		// 	this.setState({
		// 		fetchingReviewList: true,
		// 		reviewListIndex: 1
		// 	})
		// this.setState({
		// 	reviewListIndex: 1
		// })
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/fetch-reviews-admin/`,
			data: {
				locationGroupUuid: this.props.locationUuid,
				from_date: this.state.startDate
					? moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
				to_date: this.state.endDate
					? moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
				page: 1,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			this.setState({
				reviews: response.data.reviewList,
				//fetchingReviewList: false,
				areReviewsAvailable: response.data.is_available,
				reviewListIndex: 2,
				totalReviews: response.data.total_reviews,
			});
		});
		this.setState({
			filterModal: false,
		});
	};

	autoCompleteSearchReviews = (searchString) => {
		this.setState({
			productTermReviews: searchString,
		});

		if (searchString == '') {
			this.fetchReviews();
		}

		if (searchString !== '') {
			axios({
				method: 'POST',
				url: `${serverOmnycommUrl}/dealshub/search-products/`,
				data: {
					searchString: searchString,
					locationGroupUuid: this.props.locationUuid,
				},
				headers: {
					Authorization: `JWT ${localStorage.getItem('token')}`,
				},
			}).then((response) => {
				this.setState({
					fetchedProducts: response.data.productList,
				});
			});
		}
	};

	autoCompleteSearch = (searchString) => {
		this.setState({
			productTerm: searchString,
		});

		if (searchString !== '') {
			axios({
				method: 'POST',
				url: `${serverOmnycommUrl}/dealshub/search-products/`,
				data: {
					searchString: searchString,
					locationGroupUuid: this.props.locationUuid,
				},
				headers: {
					Authorization: `JWT ${localStorage.getItem('token')}`,
				},
			}).then((response) => {
				this.setState({
					fetchedProducts: response.data.productList,
				});
			});
		}
		// else {
		// 	this.fetchReviews()
		// }
	};

	fetchReviews = () => {
		// if(this._isMounted) {
		//
		// 	this.setState({
		// 		fetchingReviewList: true
		// 	})

		this.setState({
			isFetching: true,
		});

		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/fetch-reviews-admin/`,
			data: {
				locationGroupUuid: this.props.locationUuid,
				page: 1,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			this.setState({
				areReviewsAvailable: response.data.is_available,
				//fetchingReviewList: false,
				reviewListIndex: 2,
				isFetching: false,
				reviews: response.data.reviewList,
				totalReviews: response.data.total_reviews,
			});
		});
	};

	handleChangeImages = ({ fileList }) => {
		this.setState({
			fileList,
		});
	};

	clearPirateReviewData = () => {
		this.setState({
			productTerm: '',
			addPirateReviewModal: false,
			productUuid: '',
			rating: 0,
		});
	};

	handleAddFakeReview = () => {
		this.setState({ addingReview: true });
		let formData = new FormData();
		formData.append('product_code', this.state.productUuid);
		formData.append('customerName', this.state.displayName);
		formData.append('rating', this.state.rating);
		formData.append(
			'review_content',
			JSON.stringify({
				subject: this.state.subject,
				content: this.state.reviewBody,
			})
		);
		formData.append('image_count', this.state.fileList.length);
		for (let i = 0; i < this.state.fileList.length; i++) {
			formData.append(`image_${i}`, this.state.fileList[i].originFileObj);
		}

		axios({
			method: 'post',
			url: `${serverOmnycommUrl}/dealshub/add-fake-review-admin/`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			this.clearPirateReviewData();
			this.fetchReviews();
			if (response.data.status === 200) {
				message.success('Review Submitted!');
				this.setState({
					addPirateReviewModal: false,
					addingReview: false,
				});
			} else {
				message.error('Something went Wrong!');
			}
		});
	};

	componentDidMount() {
		this.fetchReviews();
		this.setState({
			locationUuid: this.props.locationUuid,
			selectedLocations: [this.props.locationUuid]
		});
		// console.log(this.state.selectedLocations)
	}
	componentDidUpdate = (prevState, prevProps) => {
		if (prevState.locationUuid !== this.props.locationUuid) {
			this.fetchReviews();
			this.setState({
				selectedLocations: [this.props.locationUuid]
			})
			//console.log("new loc", this.state.selectedLocations)
		};
	};

	dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};

	showOnlyPirateReviews = () => {
		this.setState({
			// pirateReview: this.state.reviews.filter((review)=>
			//     review.is_fake
			// ),
			displayOnlyPirate: !this.state.displayOnlyPirate,
		});

		if (this.state.displayOnlyOriginal) {
			this.setState({
				displayOnlyOriginal: !this.state.displayOnlyOriginal,
			});
		}
		// axios({
		//     method: 'POST',
		//     url: `${serverOmnycommUrl}/dealshub/fetch-reviews-admin/`,
		//     data: {
		//         locationGroupUuid : this.props.locationUuid,
		//         is_fake: true,
		//         page : 1
		//     },
		//     headers: {
		//         "Content-Type": "application/json",
		//         Authorization: `JWT ${localStorage.getItem("token")}`
		//     }
		// }).then(response => {
		//     this.setState({
		//         pirateReviews: response.data.reviewList
		//     })
		// })
	};

	showOnlyOriginalReviews = () => {
		this.setState({
			displayOnlyOriginal: !this.state.displayOnlyOriginal,
		});
		if (this.state.displayOnlyPirate) {
			this.setState({
				displayOnlyPirate: !this.state.displayOnlyPirate,
			});
		}
	};

	handleLocationGroupsSelected = (checkedVals) => {
		console.log(this.state.selectedLocations)
		this.setState({
			selectedLocations: checkedVals
			//selectedLocations: [...this.state.selectedLocations, ...checkedVals]
		})
	}

	handleBulkUploadReview = (file) => {
		this.setState({ uploadModal: false });
		let formdata = new FormData();
		formdata.append('import_file', file);
		formdata.append('locationGroupUuidList', JSON.stringify(this.state.selectedLocations));
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/bulk-upload-fake-review-admin/`,
			data: formdata,
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
				'Content-Type': 'multipart/form-data',
			},
		}).then((response) => {
			if (response.data.status === 200) {
				message.success('Reviews Uploaded Successfully!');
				// setTimeout(()=>{
				// 	window.location.reload();
				// },1000)
			} else message.error('Something went wrong!');
		});
	};
	handlePublishChange = (review, status) => {
		let temp = [...this.state.reviews];
		for(let i=0; i<temp.length; i++) {
			if(temp[i].review_uuid === review.review_uuid) {
				temp[i].is_published = status;
				break;
			}
		}
		this.setState({
			reviews: [...temp]
		})
	}

	render() {
		return (
			<Row
				className='orders-account-manager-mobile-view-page-container'
				gutter={[16, 16]}
			>
				<Col span={24} className='title-container'>
					<Row type='flex' justify='start'>
						<Col>
							<Title level={4} className='main-title'>
								Review Management
							</Title>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row gutter={[8, 8]}>
						<Col lg={3} md={3} sm={6}>
							<Button
								style={{ width: '100%' }}
								type='primary'
								icon='filter'
								block
								onClick={() => {
									this.setState({
										filterModal: true,
									});
								}}
							>
								Filter
							</Button>
						</Col>

						{/*<Col lg={3} md={3} sm={6}>*/}
						{/*    <Text strong type="secondary">Start Date</Text>*/}
						{/*    <DatePicker*/}
						{/*        //size={this.props.size}*/}
						{/*        className="custom-datePicker"*/}
						{/*        style={{ backgroundColor: "#36404A", width: "100%" }}*/}
						{/*        //selected={this.state.startDate}*/}
						{/*        //onChange={this.handleStartDateChange}*/}
						{/*    />*/}

						{/*</Col>*/}
						{/*<Col lg={3} md={3} sm={6}>*/}
						{/*    <Text strong type="secondary">End Date</Text>*/}
						{/*    <DatePicker*/}
						{/*        //size={this.props.size}*/}
						{/*        className="custom-datePicker"*/}
						{/*        style={{ backgroundColor: "#36404A", width: "100%" }}*/}
						{/*        //selected={this.state.startDate}*/}
						{/*        //onChange={this.handleStartDateChange}*/}
						{/*    />*/}
						{/*</Col>*/}
						<Col
							lg={18}
							md={18}
							sm={9}
							className='warehouse-manager-search-bar-container'
						>
							<AutoComplete
								dataSource={this.state.fetchedProducts}
								allowClear={true}
								value={this.state.productTermReviews}
								onSelect={(selectedValue) => {
									this.filterProductReviews(selectedValue);
								}}
								onSearch={this.autoCompleteSearchReviews}
								placeholder='search the product'
								style={{ width: '100%' }}
							>
								{this.state.fetchedProducts.map((list) => {
									return (
										<Option key={list.uuid} value={list.uuid}>
											{list.name}
										</Option>
									);
								})}
							</AutoComplete>
						</Col>
						<Col lg={3} md={3} sm={6}>
							<Button
								style={{ width: '100%' }}
								type='primary'
								icon='plus'
								onClick={() => this.setState({ addPirateReviewModal: true })}
							>
								Pirate Review
							</Button>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row gutter={[8, 8]} type='flex'>
						<Col lg={3} md={3} sm={6}>
							{this.state.displayOnlyPirate === false &&
							this.state.displayOnlyOriginal === false ? (
								<Button
									type='primary'
									style={{ width: '100%' }}
									loading={this.props.loading}
									ghost
								>
									<React.Fragment>
										{this.props.loading ? (
											<React.Fragment></React.Fragment>
										) : (
											<React.Fragment>
												Total Reviews: {this.state.totalReviews}
											</React.Fragment>
										)}
									</React.Fragment>
								</Button>
							) : this.state.displayOnlyPirate ? (
								<Button
									type='primary'
									style={{ width: '100%' }}
									loading={this.props.loading}
									ghost
								>
									<React.Fragment>
										{this.props.loading ? (
											<React.Fragment></React.Fragment>
										) : (
											<React.Fragment>
												Pirate Reviews:{' '}
												{
													this.state.reviews.filter((item) => item.is_fake)
														.length
												}
											</React.Fragment>
										)}
									</React.Fragment>
								</Button>
							) : (
								<Button
									type='primary'
									style={{ width: '100%' }}
									loading={this.props.loading}
									ghost
								>
									<React.Fragment>
										{this.props.loading ? (
											<React.Fragment></React.Fragment>
										) : (
											<React.Fragment>
												Original Reviews:{' '}
												{
													this.state.reviews.filter(
														(item) => item.is_fake === false
													).length
												}
											</React.Fragment>
										)}
									</React.Fragment>
								</Button>
							)}
						</Col>
						<Col lg={3} md={3} sm={3}>
							<Button
								icon='upload'
								type='primary'
								style={{ width: '100%' }}
								onClick={() => this.setState({ uploadModal: true })}
							>
								Upload
							</Button>
						</Col>
						<Col lg={6} md={6} sm={6}></Col>
						<Col
							lg={6}
							md={6}
							sm={6}
							align='end'
							style={{ paddingTop: '10px' }}
						>
							<Switch
								size='small'
								checked={this.state.displayOnlyPirate}
								onChange={() => {
									this.showOnlyPirateReviews();
								}}
								//checkedChildren={this.showOnlyPirateReviews()}
								// unCheckedChildren={this.fetchReviews()}
								// checkedChildren={props.checkedText} unCheckedChildren={props.uncheckedText}
								// onChange={() => {
								//     updateStatus();
								// }}
							/>
							<Text style={{ color: 'white' }}>
								{' '}
								Display only Pirate Reviews
							</Text>
						</Col>
						<Col
							lg={6}
							md={6}
							sm={6}
							align='end'
							style={{ paddingTop: '10px' }}
						>
							<Switch
								size='small'
								checked={this.state.displayOnlyOriginal}
								onChange={() => {
									this.showOnlyOriginalReviews();
								}}
								//checkedChildren={this.showOnlyPirateReviews()}
								// unCheckedChildren={this.fetchReviews()}
								// checkedChildren={props.checkedText} unCheckedChildren={props.uncheckedText}
								// onChange={() => {
								//     updateStatus();
								// }}
							/>
							<Text style={{ color: 'white' }}>
								{' '}
								Display only Original Reviews
							</Text>
						</Col>
					</Row>
				</Col>
				<Col
					sm={24}
					md={24}
					lg={24}
					className='collapse-container-account-manager'
				>
					{this.state.isFetching ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#313841',
							}}
						>
							<Spin
								indicator={
									<Icon type='loading' style={{ fontSize: 24 }} spin />
								}
							/>
						</div>
					) : (
						<>
							{this.state.reviews.map((review, index) => {
								if (this.state.displayOnlyPirate)
									return review.is_fake ? (
										<Col
											sm={24}
											md={24}
											lg={24}
											className='collapse-container-account-manager'
											key={index}
										>
											<ReviewTabularModal
												reviewItem={review}
												onSaveReview={() => this.fetchReviews()}
											/>
										</Col>
									) : null;
								else if (this.state.displayOnlyOriginal)
									return review.is_fake === false ? (
										<Col
											sm={24}
											md={24}
											lg={24}
											className='collapse-container-account-manager'
											key={index}
										>
											<ReviewTabularModal
												reviewItem={review}
												onSaveReview={() => this.fetchReviews()}
											/>
										</Col>
									) : null;
								else
									return (
										<Col
											sm={24}
											md={24}
											lg={24}
											className='collapse-container-account-manager'
											key={index}
										>
											<ReviewTabularModal
												reviewItem={review}
												onSaveReview={() => this.fetchReviews()}
											/>
										</Col>
									);
							})}
						</>
					)}

					{this.state.reviews.length === 0 && (
						<Empty
							className='no-orders-wrapper'
							description='No Reviews found'
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					)}
					{this.state.areReviewsAvailable && !this.state.isFetching ? (
						<div
							className='center-child'
							style={{
								width: '100%',
								marginTop: '10px',
								marginBottom: '50px',
							}}
						>
							<Button
								type='primary'
								//loading={this.props.fetchingOrdersList}
								onClick={() => {
									this.handleLoadMore();
								}}
							>
								Load More...
							</Button>
						</div>
					) : (
						<React.Fragment></React.Fragment>
					)}
				</Col>
				<Modal
					title='Add Pirate Review'
					visible={this.state.addPirateReviewModal}
					destroyOnClose={true}
					closable={true}
					okText='Submit'
					confirmLoading={this.state.addingReview}
					onOk={() => {
						this.handleAddFakeReview();
					}}
					onCancel={() => {
						this.clearPirateReviewData();
						// this.setState({
						// 	productTerm: "",
						// 	addPirateReviewModal: false,
						// 	productUuid: ""
						// });
					}}
					width='fit-content'
				>
					<Row>
						<Col
							span={24}
							style={{ marginBottom: '20px' }}
							className='warehouse-manager-search-bar-container'
						>
							<AutoComplete
								dataSource={this.state.fetchedProducts}
								value={this.state.productTerm}
								onSelect={(selectedValue) => {
									this.addProduct(selectedValue);
								}}
								onSearch={this.autoCompleteSearch}
								placeholder='search the product'
								style={{ width: '100%' }}
							>
								{this.state.fetchedProducts.map((list) => {
									return (
										<Option key={list.uuid} value={list.uuid}>
											{list.name}
										</Option>
									);
								})}
							</AutoComplete>
						</Col>
					</Row>
					{this.state.productUuid && (
						<Row style={{ backgroundColor: '#454E57', lineHeight: 2 }}>
							<Col
								span={24}
								style={{
									marginTop: '20px',
									marginBottom: '17px',
									marginLeft: '20px',
								}}
							>
								<Text
									style={{
										color: '#FFFFFF',
										fontWeight: 'bold',
										fontSize: '16px',
									}}
								>
									Product Information
								</Text>
							</Col>
							<Col
								span={3}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									marginLeft: '20px',
									marginBottom: '20px',
								}}
							>
								<Avatar shape='square' size={80} src={this.state.imageurl} />
							</Col>
							<Col span={20}>
								<Text style={{ color: '#dee2e6' }}>
									Code: {this.state.productUuid}
								</Text>
							</Col>
							<Col span={20}>
								<Text style={{ color: '#dee2e6' }}>
									Name: {this.state.productName}
								</Text>
							</Col>

							{/*<Col span={20}>*/}
							{/*    <Text style={{color: "#dee2e6"}}>Average Rating:</Text>*/}
							{/*    <Text style={{fontWeight: "bold", fontSize: "16px"}}>{" "}4.5</Text>*/}
							{/*    <Text style={{color:"#5BCAAA", fontSize: "16px"}}>{" "}(Based on 10 reviews)</Text>*/}
							{/*</Col>*/}
						</Row>
					)}
					<Row style={{ backgroundColor: '#454E57', marginTop: '30px' }}>
						<Col span={24} style={{ margin: '20px' }}>
							<Text
								style={{
									color: '#FFFFFF',
									fontWeight: 'bold',
									fontSize: '16px',
								}}
							>
								Review
							</Text>
						</Col>
						<Col
							lg={21}
							md={21}
							sm={24}
							style={{ marginLeft: '20px', lineHeight: 3 }}
						>
							<Row type='flex' gutter={[8, 8]}>
								<Col lg={4} md={3} sm={10}>
									<Text style={{ color: '#dee2e6' }}>Display Name:</Text>
								</Col>
								<Col lg={20} md={21} sm={14}>
									<Input
										placeholder='Enter Display Name'
										style={{ backgroundColor: '#21262A', width: '585px' }}
										onChange={(e) => {
											this.setState({
												displayName: e.target.value,
											});
										}}
									/>
								</Col>
							</Row>
							{/*<Row type="flex" gutter={[8, 8]}>*/}
							{/*    <Col lg={3} md={3} sm={10}>*/}
							{/*        <Text style={{color: "#dee2e6"}}>*/}
							{/*            Review Date:*/}
							{/*        </Text>*/}
							{/*    </Col>*/}
							{/*    <Col lg={21} md={21} sm={14}>*/}
							{/*        <Input placeholder="Enter Display Name" style={{backgroundColor: "#21262A", width:"585px"}}/>*/}
							{/*    </Col>*/}
							{/*</Row>*/}
							<Row type='flex' gutter={[8, 8]}>
								<Col lg={4} md={3} sm={10}>
									<Text style={{ color: '#dee2e6' }}>Rating:</Text>
								</Col>

								<Col
									lg={20}
									md={21}
									sm={14}
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										paddingTop: '9px',
									}}
								>
									<Rate
										style={{ fontSize: '12px' }}
										value={Number(this.state.rating)}
										onChange={(value) => {
											this.setState({
												rating: value,
											});
										}}
									/>
								</Col>
							</Row>
							<Row type='flex' gutter={[8, 8]}>
								<Col lg={4} md={3} sm={10}>
									<Text style={{ color: '#dee2e6' }}>Subject:</Text>
								</Col>
								<Col lg={20} md={21} sm={14}>
									<Input
										placeholder='Enter Subject'
										style={{ backgroundColor: '#21262A', width: '585px' }}
										onChange={(e) => {
											this.setState({
												subject: e.target.value,
											});
										}}
									/>
								</Col>
							</Row>
							<Row type='flex' gutter={[8, 8]}>
								<Col lg={4} md={3} sm={10}>
									<Text style={{ color: '#dee2e6' }}>Review Body:</Text>
								</Col>
								<Col lg={20} md={21} sm={14}>
									<Input.TextArea
										placeholder='Enter review content'
										style={{
											backgroundColor: '#21262A',
											width: '585px',
											height: '106px',
										}}
										onChange={(e) => {
											this.setState({
												reviewBody: e.target.value,
											});
										}}
									/>
								</Col>
							</Row>
							<Row type='flex' gutter={[8, 8]} style={{ marginBottom: '20px' }}>
								<Col lg={4} md={4} sm={10}>
									<Text style={{ color: '#dee2e6' }}>Images:</Text>
								</Col>
								<Col lg={20} md={20} sm={14}>
									<Upload
										multiple={true}
										fileList={this.state.fileList}
										showUploadList={true}
										listType='picture-card'
										onChange={this.handleChangeImages}
										customRequest={this.dummyRequest}
									>
										{this.state.fileList.length > 5 ? null : (
											<div>
												<Icon type='plus' />
												<div className='ant-upload-text'>Upload</div>
											</div>
										)}
									</Upload>
								</Col>
							</Row>
						</Col>
					</Row>
				</Modal>
				<Modal
					title='Filter'
					visible={this.state.filterModal}
					destroyOnClose={true}
					closable={true}
					onOk={() => {
						this.applyFilters();
					}}
					onCancel={() => {
						this.setState({
							filterModal: false,
						});
					}}
					width='25%'
					style={{ display: 'flex' }}
				>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Row>
								<Col span={24}>
									<Text strong type='secondary'>
										Start Date
									</Text>
									<DatePicker
										//size={this.props.size}
										className='custom-datePicker'
										style={{ backgroundColor: '#36404A', width: '100%' }}
										selected={this.state.startDate}
										onChange={this.handleStartDate}
									/>
								</Col>
							</Row>
						</Col>
						<Col span={12}>
							<Row>
								<Col span={24}>
									<Text strong type='secondary'>
										End Date
									</Text>
									<DatePicker
										size={this.props.size}
										className='custom-datePicker'
										style={{ backgroundColor: '#36404A', width: '100%' }}
										selected={this.state.endDate}
										onChange={this.handleEndDate}
									/>
								</Col>
							</Row>
						</Col>
						{/*<Col span={24}>*/}
						{/*	<Text strong type='secondary'>*/}
						{/*		Category*/}
						{/*	</Text>*/}
						{/*	<Col span={24}>*/}
						{/*		<AutoComplete />*/}
						{/*	</Col>*/}
						{/*</Col>*/}
					</Row>
				</Modal>
				<Modal
					title='Upload Pirate Reviews'
					visible={this.state.uploadModal}
					onCancel={()=>{this.setState({uploadModal: false})}}
					footer={[
						<Button
							onClick={() => {
								this.setState({ uploadModal: false });
							}}
							type='primary'
						>
							Cancel
						</Button>,
					]}
					style={{ left: '-100px' }}
				>
					<Row gutter={[24, 24]}>
						<Col span={24}>
							<Text>Select the Location groups:</Text>
						</Col>
						<Col span={24}>
							<Checkbox.Group onChange={this.handleLocationGroupsSelected} value={this.state.selectedLocations}>
								{this.props.locationGroupList.map((item, index)=>{
									return(
										<Checkbox value={item.uuid} key={index}>{item.name}</Checkbox>
									)
								})}
							</Checkbox.Group>
						</Col>
						<Col span={12}>
							<UploadBtn
								style={{ width: '100%' }}
								beforeUpload={this.handleBulkUploadReview}
								fileList={this.state.fileList}
							>
								<Button type='primary'>Upload</Button>
							</UploadBtn>
						</Col>
						<Col span={18} style={{ width: '100%' }}>
							<Row>
								<Text>
									Download{' '}
									<Text
										onClick={() => {
											window.open(fileTemplate);
										}}
										style={{ color: '#fff', cursor: 'pointer' }}
									>
										this template
									</Text>{' '}
									for searching products.
								</Text>
							</Row>
						</Col>
					</Row>
				</Modal>
			</Row>
		);
	}
}
