import { Typography, Row, Col, Button } from "antd";
import styled from "styled-components";
const { Title } = Typography;

export const PageWrapper = styled(Row)`
    width: 95%;
    background-color: #313841;
`;

export const PageSubWrapper = styled(Row)`
background: "#36404A",
minHeight: "80%",
borderRadius: "5px",
padding: "20px",
marginTop: "0px"
`
