import axios from "axios";
import isDev from "../server";
import { Form, message } from "antd";
const serverUrl = isDev();
export const userLogin = payload => {
  return { type: "USER_LOGIN", value: payload };
};

export const reload = payload => {
  return { type: "REFRESH", value: payload };
};

export const userLogout = () => {
  return { type: "USER_LOGOUT" };
};

export const setEmpty = () => {
  return { type: "SET_EMPTY" };
};

let fetchProductTagNumber = 1;
let tagArr = [];

export const addTags = tags => {
  return { type: "ADD_TAGS_TO_PRODUCT_LIST", payload: tags };
};

export const addFilters = filter => {
  return { type: "ADD_FILTER_TO_PRODUCT_LIST", payload: filter };
};

export const addTagsContentHealth = tags => {
  return { type: "ADD_TAGS_TO_PRODUCT_LIST_CONTENT_HEALTH", payload: tags };
};

export const addFiltersContentHealth = filter => {
  console.log(filter);
  return { type: "ADD_FILTER_TO_PRODUCT_LIST_CONTENT_HEALTH", payload: filter };
}

export const setNestoUser = (isNestoUser, canEditProducts, canDeleteImage, canViewHoldings) => dispatch => {
  dispatch({
    type: "NESTO_USER",
    payload: {
      isNestoUser, canEditProducts, canDeleteImage, canViewHoldings
    }
  });
}

export const fetchProductList = (filters, tags, isNestoUser) => dispatch => {
  fetchProductTagNumber = 1;
  tagArr = [];
  const bodyData = {
    filter_parameters: filters,
    tags: tags,
    page: 1
  };
  fetch(`${serverUrl}/${isNestoUser ? "fetch-nesto-product-list" : "fetch-product-list"}/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(bodyData)
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: "FETCH_PRODUCT_LIST",
        payload: data
      });
    });
};

export const fetchProductListMore = (filter, tags, isNestoUser) => dispatch => {
  fetchProductTagNumber++;
  const data = {
    filter_parameters: filter,
    tags: tags,
    page: fetchProductTagNumber
  };
  fetch(`${serverUrl}/${isNestoUser ? "fetch-nesto-product-list" : "fetch-product-list"}/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {

      dispatch({
        type: "FETCH_PRODUCT_LIST_MORE",
        payload: data
      });
    });
};

export const fetchProductDetails = id => dispatch => {
  const data = {
    product_pk: id
  };
  fetch(`${serverUrl}/fetch-product-details/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: "FETCH_PRODUCT_DETAILS",
        payload: data
      });
    });
};


export const fetchImagesDetails = id => dispatch => {

  const data = {
    product_pk: id
  };
  fetch(`${serverUrl}/fetch-product-details/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {


      dispatch({
        type: "SET_IMAGES_ONLY",
        payload: data
      });
    });
};

export const fetchBaseProductDetails = id => dispatch => {
  const data = {
    base_product_pk: id
  };
  fetch(`${serverUrl}/fetch-base-product-details/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {

      dispatch({
        type: "FETCH_BASE_PRODUCT_DETAILS",
        payload: data
      });
    });
};

export const sendFeedBackSaveBaseProduct = status => {
  return { type: "SEND_FEED_BACK_SAVE_BASE_PRODUCT", payload: status };
};

export const sendProductDescription = des => {
  return { type: "SEND_PRODUCT_DESCRIPTION", payload: des };
};
export const sendFAQ = des => {
  return { type: "SEND_FAQ", payload: des };
};

export const sendFeedBackCreateVariant = des => {
  return { type: "SEND_FEED_BACK_CREATE_VARIANT", payload: des };
};

export const saveBaseProduct = (pk, inp, cat, subCat) => dispatch => {

  const data = {
    base_product_pk: pk,
    base_product_name: inp.base_product_name,
    seller_sku: inp.seller_sku,
    brand_name: inp.brand_name,
    manufacturer: inp.manufacturer,
    manufacturer_part_number: inp.manufacturer_part_number,
    category: inp.category,
    subtitle: inp.subtitle,
    base_dimensions: inp.base_dimensions,
    sub_category: inp.sub_category,
    category_uuid: cat.category_uuid,
    sub_category_uuid: subCat ? subCat.sub_category_uuid : ""
  };



  fetch(`${serverUrl}/save-base-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {
      dispatch(sendFeedBackSaveBaseProduct(data.status));

    });
};

export const handelEditProductDetails = payload => {
  return { type: "HANDEL_EDIT_PRODUCT_DETAILS", payload };
};

export const handelEditProductDetailsSpecialFeatures = payload => {
  return { type: "HANDEL_EDIT_PRODUCT_DETAILS_SPECIAL_FEATURES", payload }
}

export const sendProductPk = pk => {
  return { type: "SEND_PRODUCT_PK", payload: pk };
};

let imagecount = 0;

export const saveImages = (imagesData, pk, createVariant) => dispatch => {

  Object.keys(imagesData).map(key => {
    let formData = new FormData();
    imagesData[key].map((e, index) => {
      formData.append("image_" + index, e.originFileObj);
    });
    formData.append("product_pk", pk);
    formData.append("image_count", imagesData[key].length);
    formData.append("image_category", key);
    formData.append("channel_name", "");
    axios
      .post(`${serverUrl}/upload-product-image/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      })
      .then(() => {
        imagecount++;
        if (imagecount === 13) {
          imagecount = 0;
          if (createVariant) dispatch(sendFeedBackCreateVariant("reload"));
          else dispatch(sendFeedBackSaveBaseProduct("reload"));
        }

      })
      .catch(() => {
        if (createVariant) dispatch(sendFeedBackCreateVariant("error_reload"));
        else dispatch(sendFeedBackSaveBaseProduct("error_reload"));

      });
  });
};

export const saveProductDetails = (
  inp,
  pk,
  pflattributes,
  product_description,
  imagesData,
  createVariant,
  stepdataList,
  faqList,
  pflatrributes_ar,
  product_manual
) => dispatch => {

  // console.log(data,"faqcheck");

  let barcode_type = "ASIN";
  if (inp.barcode_string.length === 12) barcode_type = "UPC";
  if (inp.barcode_string.length === 13) barcode_type = " EAN";

  //.log(inp);

  //.log(pflattributes, "SAVING THE PRODUCT");

  const data = {
    product_pk: pk,
    product_name: inp.product_name || "",
    barcode_string: inp.barcode_string || "",
    barcode_type,
    color: inp.color || "",
    color_map: inp.color_map || "",
    standard_price: inp.standard_price || "",
    quantity: inp.quantity || "",
    product_id_type: inp.product_id_type || "",
    material_type: inp.material_type || "",
    pfl_product_name: inp.pfl_product_name || "",
    pfl_product_features: pflattributes || [],
    pfl_product_features_ar: pflatrributes_ar || [],
    factory_notes: inp.factory_notes || "",
    product_id: inp.product_id || "",
    factory_code: inp.factory_code || "",
    is_bundle_product: inp.is_bundle_product || false,
    is_sap_exception: inp.is_sap_exception || false,
    is_cod_allowed: inp.is_cod_allowed || false,
    product_description: product_description || "",
    faqs: faqList || [],
    how_to_use: stepdataList || [],
    min_price: parseInt(inp.min_price) || 0,
    max_price: parseInt(inp.max_price) || 0,
    atp_threshold: parseInt(inp.atp_threshold) || 0,
    holding_threshold: parseInt(inp.holding_threshold) || 0,
    was_price: parseInt(inp.was_price) || 0,
    now_price: parseInt(inp.now_price) || 0,
    stock: parseInt(inp.stock) || 0,
    dynamic_form_attributes: inp.dynamic_form_attributes || {},
    weight: inp.weight || "",
    size: inp.size || "",
    size_unit: inp.size_unit || "",
    capacity: inp.capacity || "",
    capacity_unit: inp.capacity_unit || "",
    target_age_range: inp.target_age_range || "",
    user_manual: product_manual,
  };
  let formData = new FormData()

  //Converting the data into formData and checking undefined specifically for the user_manual as per the logic 
  //by the backend team
  for (let key in data) {
    if (data[key] !== undefined) {
      let value = data[key];
      if(value.constructor === Array || value.constructor === Object){
        value = JSON.stringify(value);
      }
      formData.append(key.toString(), value);
    }
  }

  axios.post(`${serverUrl}/save-product/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
  })
    .then(res => res.data)
    .then(data => {
      dispatch(sendProductPk(pk));
      dispatch(sendProductDescription(product_description));
      dispatch(sendFeedBackSaveBaseProduct(data.status));
      if (imagesData) dispatch(saveImages(imagesData, pk, createVariant));

    });

};

export const saveProductPk = pk => {
  return { type: "SAVE_PRODUCT_PK", payload: pk };
};

export const publishToDealsHub = product_pk => dispatch => {
  const data = {
    product_pk: product_pk
  };
  fetch(`${serverUrl}/dealshub/create-dealshub-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {
      if (data.status == 200) {

      }
      else if (data.status === 403) {
        message.info("You are not authorised!")
      }
    });
};

export const sendFeedBackSaveBaseProductDetails = status => {
  return { type: "FEEDBACK_SAVE_BASE_PRODUCT_DETAILS", payload: status };
};

export const saveBaseProductDetails = (
  inp,
  pflattributes,
  product_description,
  imagesData,
  cat,
  subCat,
  stepdataList,
  faqList
) => dispatch => {


  const dimensions = inp.base_dimensions;
  const data = {
    base_product_name: inp.base_product_name,
    seller_sku: inp.seller_sku,
    brand_name: inp.brand_name,
    category_uuid: cat.category_uuid,
    sub_category_uuid: subCat ? subCat.sub_category_uuid : "",
    manufacturer: inp.manufacturer,
    manufacturer_part_number: inp.manufacturer_part_number,
    product_description: product_description,
    base_dimensions: {
      export_carton_quantity_l: dimensions.export_carton_quantity_l,
      export_carton_quantity_l_metric:
        dimensions.export_carton_quantity_l_metric,
      export_carton_quantity_b: dimensions.export_carton_quantity_b,
      export_carton_quantity_b_metric:
        dimensions.export_carton_quantity_b_metric,
      export_carton_quantity_h: dimensions.export_carton_quantity_h,
      export_carton_quantity_h_metric:
        dimensions.export_carton_quantity_h_metric,
      export_carton_crm_l: dimensions.export_carton_crm_l,
      export_carton_crm_l_metric: dimensions.export_carton_crm_l_metric,
      export_carton_crm_b: dimensions.export_carton_crm_b,
      export_carton_crm_b_metric: dimensions.export_carton_crm_b_metric,
      export_carton_crm_h: dimensions.export_carton_crm_h,
      export_carton_crm_h_metric: dimensions.export_carton_crm_h_metric,
      product_dimension_l: dimensions.product_dimension_l,
      product_dimension_l_metric: dimensions.product_dimension_l_metric,
      product_dimension_b: dimensions.product_dimension_b,
      product_dimension_b_metric: dimensions.product_dimension_b_metric,
      product_dimension_h: dimensions.product_dimension_h,
      product_dimension_h_metric: dimensions.product_dimension_h_metric,
      giftbox_l: dimensions.giftbox_l,
      giftbox_l_metric: dimensions.giftbox_l_metric,
      giftbox_b: dimensions.giftbox_b,
      giftbox_b_metric: dimensions.giftbox_b_metric,
      giftbox_h: dimensions.giftbox_h,
      giftbox_h_metric: dimensions.giftbox_h_metric
    }
  };
  fetch(`${serverUrl}/create-new-base-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {

      dispatch(sendFeedBackSaveBaseProductDetails(data.status));
      if (data.status == 200) {

        dispatch(saveProductPk(data.product_pk));
        dispatch(
          saveProductDetails(
            inp,
            data.product_pk,
            pflattributes,
            product_description,
            imagesData,
            null,
            stepdataList,
            faqList
          )
        );
      }
      else if (data.status === 403) {
        message.info("You are not authorised!")
      }
    });
};

export const createNewProduct = (inp, pk, des, imageData, stepdataList, faqList) => dispatch => {

  const data = {
    base_product_pk: pk
  };
  fetch(`${serverUrl}/create-new-product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(data => {

      dispatch(
        saveProductDetails(
          inp,
          data.product_pk,
          inp.pfl_product_features,
          des,
          imageData,
          true,
          stepdataList,
          faqList
        )
      );
      // if (imageData) dispatch(saveImages(imageData, data.product_pk, true));
    });
};
