import React, { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Typography,
	Button,
	Icon,
	Tabs,
	Collapse,
	Input,
	message,
	Select,
	Divider,
	Tooltip
} from 'antd';
import RightWrapper from 'my-components/ui-components/RightWrapper';
import TitleWithBreadCrumb from 'views/components/titlewithbreadcrumb/TitleWithBreadCrumb';
import UploadModal from './UploadModal';
import InputField from 'views/components/datainputs/InputField';
import '../customers/customers.scss';
import './customerb2bdetails.scss';
import IconChips from 'my-components/ui-components/IconChips';
import axios from 'axios';
import isDev from 'server';
import { useSelector } from 'react-redux';
import { propTypes } from 'react-json-viewer';

const serverDealsHubUrl = isDev(true);

const { Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

function DocumentIdInput({
	name,
	setStatus,
	displayName,
	value,
	docTypeValue,
	setDocTypeValue,
	inputName,
}) {

	const state = useSelector((state) => state.miscPermission)

	return (
		<React.Fragment>
			<span
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					margin: '0 0 10px',
					width: '100%',
				}}
			>
				<span>
					<Text style={{ color: 'white' }}>{displayName}</Text>
				</span>
				{state.includes("approving-customers") && <Select
					className='file-status-select'
					value={value}
					onChange={(value) => setStatus(name, value)}
				>
					<Option value='Pending'>Pending</Option>
					<Option value='Approved'>Approved</Option>
					<Option value='Rejected'>Rejected</Option>
				</Select>}
			</span>
			<span
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '10px',
					marginBottom: '10px',
				}}
			>
				<Input
					style={{ background: '#3c4853' }}
					placeholder={`${displayName} Id`}
					name={inputName}
					value={docTypeValue}
					onChange={setDocTypeValue}
				/>
			</span>
			<Divider style={{ margin: '10px 0' }} />
		</React.Fragment>
	);
}

function CustomerDetails({ locationGroupUuid, username, setCustomerName, setCompanyName }) {
	const [modal, setModal] = useState(false);
	const [customerDetails, setCustomerDetails] = useState({});
	const [loadingSaveDetails, setloadingSaveDetails] = useState(false);
	const [trade, setTrade] = useState([]);
	const [passport, setPassport] = useState([]);
	const [vat, setVat] = useState([]);
	const [types, setTypes] = useState({ tradeLicenseType: 'IMG', vatCertificateType: 'IMG', passportCopyType: 'IMG' });
	const setVatCertificate = (value) => { setVat(value); console.log(value, 'setting vat') }
	const setTradeLicense = (value) => { setTrade(value); console.log(value, 'setting trade') }
	const setPassportCopy = (value) => { setPassport(value); console.log(value, 'setting passport') }
	const setVatCertificateType = (value) => { setTypes({ ...types, vatCertificateType: value }); console.log(value, 'setting vat') }
	const setTradeLicenseType = (value) => { setTypes({ ...types, tradeLicenseType: value }); console.log(value, 'setting trade') }
	const setPassportCopyType = (value) => { setTypes({ ...types, passportCopyType: value }); console.log(value, 'setting passport') }

	useEffect(() => {
		const data = {
			username,
			locationGroupUuid,
		};

		axios
			.post(`${serverDealsHubUrl}/dealshub/fetch-customer-details/`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `JWT ${localStorage.getItem('token')}`,
				},
			})
			.then((response) => {
				if (response.data.status === 200) {
					let data = response.data.customerDetails;
					let vatCertificate = data.vatCertificateType === 'IMG' ? data.vatCertificate.map(item => ({ uid: item.pk, url: item.image_url })) : data.vatCertificateType === 'PDF' ? [...[], { uid: 1, url: data.vatCertificate }] : ''
					let passportCopy = data.passportCopyType === 'IMG' ? data.passportCopy.map(item => ({ uid: item.pk, url: item.image_url })) : data.passportCopyType === 'PDF' ? [...[], { uid: 1, url: data.passportCopy }] : ''
					let tradeLicense = data.tradeLicenseType === 'IMG' ? data.tradeLicense.map(item => ({ uid: item.pk, url: item.image_url })) : data.tradeLicenseType === 'PDF' ? [...[], { uid: 1, url: data.tradeLicense }] : ''
					let vatCertificateType = data.vatCertificateType;
					let passportCopyType = data.passportCopyType;
					let tradeLicenseType = data.tradeLicenseType;
					setTrade(tradeLicense); setPassport(passportCopy); setVat(vatCertificate);
					setTypes({ tradeLicenseType, vatCertificateType, passportCopyType });
					setCustomerDetails(response.data.customerDetails);
					setCustomerName(response.data.customerDetails.customerName);
					setCompanyName(response.data.customerDetails.companyName)
				} else {
					message.error('Internal Server Error!');
				}
			});
	}, [locationGroupUuid, setCompanyName, setCustomerName, username]);

	const saveDetails = () => {
		const {
			companyName,
			vatCertificateStatus,
			tradeLicenseStatus,
			passportCopyStatus,
			customerName,
			emailId,
			cohort,
			vatCertificateId, tradeLicenseId, passportCopyId
		} = customerDetails;

		if (customerName === '') {
			message.error('Please enter Customer name!');
			return;
		}
		if (companyName === '') {
			message.error('Please enter Company name!');
			return;
		}

		let formData = new FormData();
		formData.append('companyName', companyName);
		formData.append('tradeLicenseStatus', tradeLicenseStatus);
		formData.append('vatCertificateStatus', vatCertificateStatus);
		formData.append('passportCopyStatus', passportCopyStatus);
		formData.append('vatCertificateId', vatCertificateId);
		formData.append('tradeLicenseId', tradeLicenseId);
		formData.append('passportCopyId', passportCopyId);
		formData.append('customerName', customerName);
		formData.append('emailId', emailId);
		formData.append('cohort', cohort === 'Default' ? '' : cohort);
		formData.append('username', username);
		formData.append('websiteGroupName', 'shopnestob2b');
		console.log(vat, trade, passport, types, 'why is this happening')
		if (types.vatCertificateType === 'IMG') {
			const tmp = vat ? vat.filter(item => typeof customerDetails.vatCertificate !== 'string' ? customerDetails.vatCertificate.findIndex(i => i.pk === item.uid) === -1 : true) : []
			console.log(tmp, 'vat')
			formData.append('vatCertificateType', tmp.length === 0 ? '' : types.vatCertificateType);
			formData.append('vatCertificateImageCount', tmp.length);
			for (let i = 0; i < tmp.length; i++)
				formData.append(`vat-certificate-image-${i + 1}`, tmp[i].originFileObj);
		}
		else if (types.vatCertificateType === 'PDF') {
			const tmp = vat;
			if (tmp.length === 1 && tmp[0].originFileObj) {
				formData.append('vatCertificateType', types.vatCertificateType);
				formData.append(`vat-certificate-document`, tmp[0].originFileObj)
			}
			else
				formData.append('vatCertificateType', '');
		}
		else {
			formData.append('vatCertificateType', '');
		}
		if (types.tradeLicenseType === 'IMG') {
			const tmp = trade ? trade.filter(item => typeof customerDetails.tradeLicense !== 'string' ? customerDetails.tradeLicense.findIndex(i => i.pk === item.uid) === -1 : true) : []
			console.log(tmp, 'trade')
			formData.append('tradeLicenseType', tmp.length === 0 ? '' : types.tradeLicenseType);
			formData.append('tradeLicenseImageCount', tmp.length);
			for (let i = 0; i < tmp.length; i++)
				formData.append(`trade-license-image-${i + 1}`, tmp[i].originFileObj);
		}
		else if (types.tradeLicenseType === 'PDF') {
			const tmp = trade
			if (tmp.length === 1 && tmp[0].originFileObj) {
				formData.append('tradeLicenseType', types.tradeLicenseType);
				formData.append(`trade-license-document`, tmp[0].originFileObj)
			}
			else
				formData.append('tradeLicenseType', '');
		}
		else {
			formData.append('tradeLicenseType', '');
		}
		if (types.passportCopyType === 'IMG') {
			const tmp = passport ? passport.filter(item => typeof customerDetails.passportCopy !== 'string' ? customerDetails.passportCopy.findIndex(i => i.pk === item.uid) === -1 : true) : []
			console.log(tmp, 'passport')
			formData.append('passportCopyType', tmp.length === 0 ? '' : types.passportCopyType);
			formData.append('passportCopyImageCount', tmp.length);
			for (let i = 0; i < tmp.length; i++)
				formData.append(`passport-copy-image-${i + 1}`, tmp[i].originFileObj);
		}
		else if (types.passportCopyType === 'PDF') {
			const tmp = passport
			if (tmp.length === 1 && tmp[0].originFileObj) {
				formData.append('passportCopyType', types.passportCopyType);
				formData.append(`passport-copy-document`, tmp[0].originFileObj)
			}
			else
				formData.append('passportCopyType', '');
		}
		else {
			formData.append('passportCopyType', '');
		}

		setloadingSaveDetails(true);
		axios
			.post(
				`${serverDealsHubUrl}/dealshub/update-b2b-customer-status/`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `JWT ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((response) => {
				setloadingSaveDetails(false);
				if (response.data.status === 200) {
					message.success('Updated Successfully!');
					window.location.reload();
				} else {
					message.error(response.data.message || 'Internal Server Error!');
				}
			}).catch(err => {
				message.error('Internal Server Error!');
			})
	};

	const toggleModal = () => {
		setModal(!modal);
	};

	const handleSetStatus = (name, status) => {
		setCustomerDetails({
			...customerDetails,
			[name]: status,
		});
	};

	const handleCustomerDetailsChange = ({ target: { name, value } }) => {
		setCustomerDetails({
			...customerDetails,
			[name]: value,
		});
	};

	const handleCohortChange = (e) => {
		setCustomerDetails({
			...customerDetails,
			cohort: e,
		});
	};

	const deleteB2BDocument = async (type) =>
		axios({
			method: 'POST',
			url: `${serverDealsHubUrl}/dealshub/delete-b2b-document/`,
			data: {
				documentType: type,
				contactNumber: customerDetails.contactNumber,
				isDealshub: false,
				locationGroupUuid
			},
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json'
			}
		})


	const deleteB2BImage = async (pk) =>
		axios({
			method: 'POST',
			url: `${serverDealsHubUrl}/dealshub/delete-b2b-document-image/`,
			data: {
				pk
			},
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json'
			}
		})


	return (
		<React.Fragment>
			<Row style={{ padding: '10px 16px' }} gutter={[48, 12]}>
				<Col span={12}>
					<Text style={{ fontSize: '18px', color: 'white' }}>
						Customer Details
					</Text>
					<Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
						<Col span={12}>
							<InputField
								label={{ type: 'secondary' }}
								input={{
									size: 'large',
									name: 'customerName',
									value: customerDetails.customerName || '',
									disabled: false,
								}}
								onChange={handleCustomerDetailsChange}
								labelText='Full Name'
							/>
						</Col>
						<Col span={12}>
							<InputField
								label={{ type: 'secondary' }}
								input={{
									size: 'large',
									name: 'emailId',
									value: customerDetails.emailId || '',
									disabled: false,
								}}
								onChange={handleCustomerDetailsChange}
								labelText='Email'
							/>
						</Col>
						<Col span={12}>
							<InputField
								label={{ type: 'secondary' }}
								input={{
									size: 'large',
									name: 'contactNumber',
									value: customerDetails.contactNumber || '',
									disabled: true,
								}}
								onChange={handleCustomerDetailsChange}
								labelText='Phone Number'
							/>
						</Col>
						<Col span={12}>
							<InputField
								label={{ type: 'secondary' }}
								input={{
									size: 'large',
									name: 'companyName',
									value: customerDetails.companyName,
									disabled: false,
								}}
								onChange={handleCustomerDetailsChange}
								labelText='Company Name'
							/>
						</Col>
						<Col
							span={24}
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Text style={{ color: '#ffffff99' }}>Cohort</Text>
							<Select
								size='large'
								value={!customerDetails.cohort ? 'Default' : customerDetails.cohort}
								onChange={handleCohortChange}
								style={{ width: '100%' }}
							>
								{['Default', '1', '2', '3', '4', '5'].map((item) => (
									<Option value={item}>
										{item === 'Default' ? item : `Cohort ${item}`}
									</Option>
								))}
							</Select>
						</Col>
						<Col span={24} style={{ marginTop: '20px' }}>
							<Button
								type='primary'
								onClick={saveDetails}
								loading={loadingSaveDetails}
							>
								SAVE DETAILS
							</Button>
						</Col>
					</Row>
				</Col>
				<Col offset={2} span={8}>
					<Text style={{ fontSize: '18px', color: 'white' }}>
						Customer Documents
					</Text>
					<Row style={{ marginTop: '10px' }}>
						<DocumentIdInput
							value={customerDetails.vatCertificateStatus}
							name='vatCertificateStatus'
							displayName='Vat Certificate'
							inputName='vatCertificateId'
							setStatus={handleSetStatus}
							docTypeValue={customerDetails.vatCertificateId}
							setDocTypeValue={handleCustomerDetailsChange}
						/>
						<DocumentIdInput
							value={customerDetails.tradeLicenseStatus}
							name='tradeLicenseStatus'
							displayName='Trade License'
							inputName='tradeLicenseId'
							setStatus={handleSetStatus}
							docTypeValue={customerDetails.tradeLicenseId}
							setDocTypeValue={handleCustomerDetailsChange}
						/>
						<DocumentIdInput
							value={customerDetails.passportCopyStatus}
							name='passportCopyStatus'
							displayName='Passport Copy'
							inputName='passportCopyId'
							setStatus={handleSetStatus}
							docTypeValue={customerDetails.passportCopyId}
							setDocTypeValue={handleCustomerDetailsChange}
						/>
					</Row>
					<Row>
						<Button
							icon='upload'
							type='primary'
							ghost
							style={{ width: '100%' }}
							onClick={toggleModal}
							size='large'
						>
							{' '}
							Upload/Preview Documents{' '}
						</Button>
					</Row>
				</Col>
			</Row>
			<UploadModal
				recievedData={customerDetails}
				file={{ vat, trade, passport }}
				setFile={{ setVatCertificate, setTradeLicense, setPassportCopy }}
				fileType={types}
				setFileType={{ setPassportCopyType, setTradeLicenseType, setVatCertificateType }}
				modal={modal}
				toggleModal={toggleModal}
				deleteB2BDocument={deleteB2BDocument}
				deleteB2BImage={deleteB2BImage}
			/>
		</React.Fragment>
	);
}

function OrderHistoryRowHeader(props) {
	return (
		<Row style={{ position: 'relative' }}>
			<Col span={8}>
				<Text style={{ color: '#69CAAA', fontSize: '16px' }}>
					Order ID #{props.order.bundleId}
				</Text>
				<br />
				<Icon type='check' style={{ color: '#69CAAA', fontSize: '10px' }} />
				<Text
					style={{
						color: 'white',
						fontSize: '10px',
						paddingLeft: '5px',
						fontWeight: 'lighter',
					}}
				>
					Bundle ID:
				</Text>
				<Text style={{ color: 'white', fontSize: '10px', paddingLeft: '5px' }}>
					{props.order.bundleId}
				</Text>
			</Col>
			<Col span={6}>
				<Text style={{ color: 'white' }}>{props.customerName}</Text>

				<br />
				<Text style={{ color: 'white', fontSize: '12px' }}>
					{props.order.datePlaced}
				</Text>
			</Col>
			<Col span={3}>
				<Text style={{ color: 'white' }}>
					{props.order.unitOrderList.length}
				</Text>
			</Col>
			<Col span={3}>
				<Text style={{ color: 'white' }}>-</Text>
			</Col>
			<Col span={4}>
				<Text style={{ color: 'white' }}>
					<Icon type='wallet' style={{ color: '#69C7A4' }} />{' '}
					{props.order.totalBilling}
				</Text>
			</Col>

			<Col span={24} style={{ marginTop: '10px' }}>
				<Row type='flex' gutter={[12, 12]}>
					<Col>
						<IconChips
							color='#1890ff'
							text={`Call Status - ${props.order.call_status}`}
							icon='phone'
						/>
					</Col>
					<Col>
						<IconChips
							color='#faad14'
							text={`SAP Status - ${props.order.sapStatus}`}
							icon='hourglass'
						/>
					</Col>
					<Col>
						<IconChips
							color='#52c41a'
							text={`Shipping Method - ${props.order.shippingMethod}`}
							icon='car'
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}

function OrderHistoryRowInnerHeader(props) {
	return (
		<Row
			style={{
				position: 'relative',
				marginTop: '20px',
				padding: '20px 0px',
				borderTop: '1px solid #ffffff1c',
			}}
		>
			<Col span={14}>
				<Row gutter={[12, 12]}>
					<Col span={3}>
						<img
							src={props.order.productImageUrl}
							style={{ width: '100%', height: '100%' }}
							alt=""
						/>
					</Col>
					<Col span={21}>
						<Text
							style={{
								color: 'white',
								fontSize: '14px',
								fontWeight: 'lighter',
							}}
						>
							{props.order.productName}
						</Text>
						<br />
						<Text style={{ color: 'white', fontSize: '10px' }}>
							{props.order.productId}
						</Text>
					</Col>
				</Row>
			</Col>
			<Col span={3}>
				<Text style={{ color: 'white' }}>-</Text>
			</Col>
			<Col span={3}>
				<Text style={{ color: 'white' }}>{props.order.quantity}</Text>
			</Col>
			<Col span={4}>
				<Text style={{ color: 'white' }}>
					<Icon type='wallet' style={{ color: '#69C7A4' }} />{' '}
					{props.order.price} {props.order.currency}
				</Text>
			</Col>

			<Col span={24} style={{ marginTop: '10px' }}>
				<Row type='flex' gutter={[12, 12]}>
					<Col>
						<IconChips
							color='#1890ff'
							text={`Current Status-${props.order.currentStatus}`}
							icon='phone'
						/>
					</Col>
					<Col>
						<IconChips
							color='#faad14'
							text={`SAP Status - ${props.order.sapStatus}`}
							icon='hourglass'
						/>
					</Col>
					<Col>
						<IconChips
							color='#52c41a'
							text={`Shipping Method - ${props.order.shippingMethod}`}
							icon='car'
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}

function OrderHistoryRow(props) {
	const [open, setNotOpen] = useState(false);
	return (
		<Collapse
			className='OrderHistoryRow'
			style={open ? { border: '1px solid #69C7A4' } : {}}
			onChange={() => {
				setNotOpen(!open);
			}}
		>
			<Panel
				key='1'
				extra={
					<OrderHistoryRowHeader
						order={props.order}
						customerName={props.customerName}
					/>
				}
				showArrow={false}
			>
				{props.order.unitOrderList.map((item) => (
					<OrderHistoryRowInnerHeader order={item} />
				))}
			</Panel>
		</Collapse>
	);
}

function OrderHistory(props) {
	const [orderHistory, setOrderHistory] = useState([]);
	const [customerName, setCustomerName] = useState('');

	useEffect(() => {
		const data = {
			page: 1,
			username: props.username,
		};
		axios
			.post(`${serverDealsHubUrl}/dealshub/fetch-customer-orders/`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `JWT ${localStorage.getItem('token')}`,
				},
			})
			.then((response) => {
				if (response.data.status === 200) {
					setOrderHistory(response.data.orderList);
					setCustomerName(response.data.customerName);
					console.log(response.data);
				} else {
					message.error('Internal Server Error!');
				}
			});
	}, [props.username]);

	return (
		<React.Fragment>
			<Row style={{ padding: '10px 16px' }}>
				<Col span={24}>
					<Text style={{ fontSize: '18px', color: 'white' }}>
						Order History
					</Text>
					<Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
						<Col span={24}>
							<Row
								gutter={[12, 0]}
								style={{
									padding: '0px 20px',
								}}
							>
								<Col span={1}></Col>
								<Col span={6}>
									<Text>ORDER DETAILS</Text>
								</Col>
								<Col span={5}>
									<Text>ORDER PLACED ON</Text>
								</Col>
								<Col span={4}>
									<Text>ITEMS</Text>
								</Col>
								<Col span={4}>
									<Text>QUANTITY</Text>
								</Col>
								<Col span={4}>
									<Text>PRICE</Text>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							{orderHistory.length > 0 ? (
								orderHistory.map((item) => (
									<OrderHistoryRow order={item} customerName={customerName} />
								))
							) : (
								<Text style={{ color: 'white' }}>
									No orders from {props.customerName}
								</Text>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
}

function OrderRequestsRowHeader(props) {
	return (
		<Row style={{ 
			position: 'relative', 
			backgroundColor : "#313841", 
			padding:"13px",
			paddingBottom: "25px",
			}}>
			<Col span={6}>
				<Text style={{ color: '#69CAAA', fontSize: '16px' }}>
					{props.order.companyName}
				</Text>
				<br />
				<Text
					style={{
						color: 'white',
						fontSize: '10px',
						fontWeight: 'lighter',
					}}
				>
					Bundle ID:
				</Text>
				<Text style={{ color: 'white', fontSize: '10px', paddingLeft: '5px' }}>
					{props.order.bundleId}
				</Text>
			</Col>
			<Col span={5}>
				<Text style={{ color: 'white', fontSize: '15px', fontWeight: '600' }}>
					{props.order.datePlaced}
				</Text>
				<br/>
				<Text style={{ color: 'white', fontSize: '12px' }}>{props.customerName}</Text>
				{/* <Tooltip
				placement='bottom'
				title={
					<React.Fragment>
						<Text style={{ color: 'white' }}>
							{props.customerName}
						</Text>
						<br />
						<Text style={{ color: 'white', fontSize: '12px' }}>
							+888888888, temptemp@gmail.com
						</Text>
					</React.Fragment>
				}
			>
				<Icon type="info-circle" style={{
					color: '#F1F1F1',
					marginLeft: '8px',
					marginRight: '5px',
					cursor: "pointer"
				}} /></Tooltip> */}
			</Col>
			<Col span={2} style={{display: "flex", justifyContent:"center"}}>
				<Text style={{ color: 'white', marginRight: '5px'}}>
					{props.order.unitOrderRequestList.length}
				</Text>
			</Col>
			<Col span={3} style={{display: "flex", justifyContent:"center"}}>
				<Text style={{ color: 'white', marginRight: "20px"}}>{props.order.paymentMode}</Text>
			</Col>
			<Col span={3} style={{display: "flex", justifyContent:"center"}}>
				<Text style={{ color: 'white', marginRight: "20px"}}>{props.order.totalQuantity}</Text>
			</Col>
			<Col span={3} style={{display: "flex", justifyContent:"center"}}>
				<Text style={{ color: 'white', marginRight:"20px" }}>
					<Icon type='wallet' style={{ color: '#69C7A4' }} />{' '}
					{props.order.totalBilling}
				</Text>
			</Col>
			<Col span={2} style={{
							display: "flex", 
							justifyContent:"center", 
							background: props.order.requestStatus === 'Approved' ? 
								"#1AC454": props.order.requestStatus === 'Rejected' ? "#F5222D" : "#FF7F00" }}>
				<Text style={{ color: 'white'}}>{props.order.requestStatus}</Text>
			</Col>
		</Row>
	);
}

function OrderRequestsRowInnerHeader(props) {
	return (
		<Row
			style={{
				position: 'relative',
				//marginTop: '20px',
				padding: '20px 10px',
				paddingBottom: "30px",
				borderTop: '1px solid #ffffff1c',
				background: '#313841',
			}}
		>
			<Col span={16}>
				<Row gutter={[12, 12]}>
					<Col span={3}>
						<img
							src={props.order.productImageUrl}
							style={{ width: '100%', height: '100%' }}
							alt=""
						/>
					</Col>
					<Col span={21}>
						<Text
							style={{
								color: 'white',
								fontSize: '14px',
								fontWeight: 'lighter',
							}}
						>
							{props.order.productName}
						</Text>
						<br />
						<Text style={{ color: 'white', fontSize: '10px' }}>
							{props.order.productId}
						</Text>
					</Col>
				</Row>
			</Col>
			<Col span={3}>
				<Text style={{ color: 'white', 
								display:"flex", 
								justifyContent:"center",
								paddingRight:'20px'
							}}>
							{
								props.order.initialQuantity !== props.order.finalQuantity ? 
									(<Col style={{display:"flex", flexDirection:"column"}}>
										<Text 
											style={{
												textDecoration:"line-through",
												color:"white",
											}}>
											{props.order.initialQuantity}
										</Text>
										{props.order.finalQuantity}
									</Col>
									)
								:
									props.order.initialQuantity
							}</Text>
			</Col>
			<Col span={4}>
				{
					props.order.initialPrice !== props.order.finalPrice ? (
						<Col style={{display:"flex", flexDirection:"column"}}>
						<Text style={{ color: 'white', textDecoration: "line-through"}}>
							<Icon type='wallet' style={{ color: '#69C7A4' }} />{' '}
							{props.order.initialPrice} {props.order.currency}
						</Text>
						<Text style={{ color: 'white' }}>
							<Icon type='wallet' style={{ color: '#69C7A4' }} />{' '}
							{props.order.finalPrice} {props.order.currency}
						</Text>
						</Col>
					)
					:
					(
						<Text style={{ color: 'white' }}>
							<Icon type='wallet' style={{ color: '#69C7A4' }} />{' '}
							{props.order.initialPrice} {props.order.currency}
						</Text>
					)
				}
			</Col>
		</Row>
	);
}


function OrderRequestsRow(props) {
	const [open, setNotOpen] = useState(false);
	return (
		<Collapse
			className='OrderHistoryRow'
			style={open ? { border: '1px solid #69C7A4' } : {}}
			onChange={() => {
				setNotOpen(!open);
			}}
		>
			<Panel
				key='1000'
				showArrow={false}
				style={{
					marginBottom: "10px",
				}}
				extra={
					<OrderRequestsRowHeader
						order={props.order}
						customerName={props.customerName}
					/>
				}
			>
				{props.order.unitOrderRequestList.map((item) => (
					<OrderRequestsRowInnerHeader order={item} />
				))}
			</Panel>
		</Collapse>
	);
}

function OrderRequests(props) {
	const [originalRequest, setOriginalRequest] = useState([]);
	const [customerName, setCustomerName] = useState('');

	useEffect(() => {
		const data = {
			page: 1,
			username: props.username,
		};
		axios
			.post(`${serverDealsHubUrl}/dealshub/fetch-customer-orders-requests/`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `JWT ${localStorage.getItem('token')}`,
				},
			})
			.then((response) => {
				console.log(response, "RESPONSE");
				if (response.data.status === 200) {
					setOriginalRequest(response.data.orderList);
					setCustomerName(response.data.customerName);
				} else {
					message.error('Internal Server Error!');
				}
			});
	}, [props.username]);

	return (
		// <div></div>
		<React.Fragment>
			<Row style={{ padding: '10px 16px' }}>
					<Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
						<Col span={24}>
							<Row
								gutter={[12, 0]}
								style={{
									padding: '0px 30px',
								}}
							>
								<Col span={6}>
									<Text style={{color: 'white'}}>ORDER REQUESTED BY</Text>
								</Col>
								<Col span={5}>
									<Text style={{color: 'white'}}>ORDER PLACED ON</Text>
								</Col>
								<Col span={2}>
									<Text style={{color: 'white'}}>ITEMS</Text>
								</Col>
								<Col span={3}>
									<Text style={{color: 'white'}}>PAYMENT</Text>
								</Col>
								<Col span={3}>
									<Text style={{color: 'white'}}>QUANTITY</Text>
								</Col>
								<Col span={3}>
									<Text style={{color: 'white'}}>PRICES</Text>
								</Col>
								<Col span={2}>
									<Text style={{color: 'white'}}>STATUS</Text>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							{originalRequest.length > 0 ? (
								originalRequest.map((item) => (
									<OrderRequestsRow order={item} customerName={customerName}/>
								))
							) : (
								<Text style={{ color: 'white' }}>
									No orders from {props.customerName}
								</Text>
							)}
						</Col>
					</Row>
			</Row>
		</React.Fragment>
	);
}


function InnerWrapper(props) {

	const [customerName, setCustomerName] = useState('');
	const [companyName, setCompanyName] = useState('');

	return (
		<React.Fragment>
			<TitleWithBreadCrumb
				title={`Customer Details & Order History`}
				breadcrumb={['B2B Customer', 'Customer Details & Order History']}
			/>

			<Row style={{ padding: '0px 35px' }} gutter={[12, 12]}>
				<Col span={24}>
					<Row style={{ backgroundColor: '#36404a', padding: '20px' }}>
						<Col span={24}>
							<Text style={{ color: '#69CAAA', fontSize: '16px' }}>
								{companyName}
							</Text>
							<br />
						</Col>

						<Col span={24} style={{ marginTop: '20px' }}>
							<Tabs defaultActiveKey='1'>
								<TabPane tab='Customer Details' key='1'>
									<CustomerDetails
										locationGroupUuid={props.locationUuid}
										username={props.username}
										setCustomerName={setCustomerName}
										setCompanyName={setCompanyName}
									/>
								</TabPane>
								<TabPane tab='Order History' key='2'>
									<OrderHistory
										username={props.username}
										customerName={customerName}
									/>
								</TabPane>
								<TabPane tab='Order Requests' key='3'>
									<OrderRequests
										username={props.username}
										customerName={customerName}
									/>
								</TabPane>
							</Tabs>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
}

function CustomerB2BDetails(props) {
	return (
		<React.Fragment>
			<RightWrapper
				innerwrapperTransparent
				InnerWrapper={
					<React.Fragment>
						<InnerWrapper {...props} />
					</React.Fragment>
				}
			/>
		</React.Fragment>
	);
}

export default CustomerB2BDetails;
