import React, { Component } from "react";
import { Row, Col, Avatar, Typography, Icon, Button } from "antd";
import { Ellipsis } from "react-spinners-css";

import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import "./admin-activity-history-page.scss";
import AdminActivityHistoryTable from "../components/admin-activity-history-table/AdminActivityHistoryTable";
import isDev from "../../server";
const serverUrl = isDev();

export default class AdminActivityHistoryPage extends Component {
  state = {
    pageCount: 1,
    tableDataList: null,
    is_available: true
  };

  fetchMore = () => {
    this.setState({ is_available: false });
    fetch(`${serverUrl}/fetch-audit-logs/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({ page: this.state.pageCount + 1 })
    })
      .then(res => res.json())
      .then(data => {

        this.setState({
          tableDataList: [...this.state.tableDataList, ...data.log_entry_list],
          is_available: data.is_available
        });
      });

    this.setState({ pageCount: this.state.pageCount + 1 });
  };

  componentDidMount() {
    fetch(`${serverUrl}/fetch-audit-logs/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({ page: this.state.pageCount })
    })
      .then(res => res.json())
      .then(data => {

        this.setState({
          tableDataList: data.log_entry_list,
          is_available: data.is_available
        });
      });
  }

  render() {
    return (
      <Row className="admin-activity-history-page-container">
        <TitleWithBreadCrumb
          title="Activity history"
          breadcrumb={["OmnyComm", "Activity"]}
        />
        <Row className="admin-activity-history-table-container">
          {this.state.tableDataList ? (
            <Col span={24}>
              <AdminActivityHistoryTable
                tableDataList={this.state.tableDataList}
              />
            </Col>
          ) : (
              <Row
                type="flex"
                align="middle"
                justify="center"
                style={{ width: "100%", height: "100%" }}
              >
                <Ellipsis color="#1ABC9C" size={100} />
              </Row>
            )}
          <div
            className="center-child"
            style={{ width: "100%", padding: "20px" }}
          >
            {this.state.tableDataList && this.state.is_available && (
              <Button type="primary" onClick={this.fetchMore}>
                Load more..
              </Button>
            )}

            {this.state.tableDataList && !this.state.is_available && (
              <Button
                type="primary"
                onClick={this.fetchMore}
                disabled
                loading
              ></Button>
            )}
          </div>
        </Row>
      </Row>
    );
  }
}
