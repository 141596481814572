export const emailValidation = {
    emailRegexString: 'Invalid Email!',
    emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})).{14,254}$/
};

// currently only for dubai
export const contactNumberValidation = {
    contactNumberRegexString: 'Invalid Contact Number!',
    contactNumberRegex: /^\d{7,10}$/
    // contactNumberRegex: /^(?:50|51|52|55|56|2|3|4|6|7|9)\d{7,10}$/
};

export const trailingSpacesValidation = {
    trailingSpacesRegex: /\s+$/
}

export const leadingSpacesValidation = {
    leadingSpacesRegex: /^\s+/
}

export const middleSpacesValidation = {
    middleSpacesRegex: /.\s+./
}

export const mobileNumberFormat = {
    mobileNumberFormatRegex: /^-?[0-9]*(\.[0-9]*)?$/
}

export const mobileNumberValidation = {
    mobileNumberRegex: /^[0-9]{9}$/
}

export const linkValidation = {
    linkRegex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
}