import React, { Component } from "react";
import { Row, Col, Tabs } from "antd";
import "./channeldrawer.scss";
import AmazonUkForm from "../channelform/AmazonUkForm";
import AmazonUAE from "../channelform/AmazonUAE";
import Ebay from "../channelform/Ebay";
import Noon from "../channelform/Noon";
import EditBaseProductVarientPage from "../../Edit-Base-Product-Page/EditBaseProductVarientPage";
import isDev from "../../../server";
const serverUrl = isDev();

const { TabPane } = Tabs;

class ChannelDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  onChange = e => {
    this.setState({
      placement: e.target.value
    });
  };

  render() {
    return (
      <React.Fragment>
        <Tabs
          defaultActiveKey="1"
          tabPosition="top"
          style={{ backgroundColor: "#313841" }}
        >
          {
            <TabPane tab={`Product Details`} key={"Product-Details"}>
              <Row gutter={[40, 40]} style={{ padding: "20px" }}>
                <Col>
                  <EditBaseProductVarientPage
                    Alldisabled={true}
                    drawer
                    id={this.props.product_pk}
                  />
                </Col>
              </Row>
            </TabPane>
          }
          {this.props.form !== "Amazon Uk" && (
            <TabPane tab={`Amazon UK`} key={"Amazon-UK"}>
              <Row gutter={[40, 40]} style={{ padding: "20px" }}>
                <Col>
                  <AmazonUkForm
                    Alldisabled={true}
                    drawer
                    product_pk={this.props.product_pk}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {this.props.form !== "Amazon Uae" && (
            <TabPane tab={`Amazon UAE`} key={"Amazon-UAE"}>
              <Row gutter={[40, 40]} style={{ padding: "20px" }}>
                <Col>
                  <AmazonUAE
                    Alldisabled={true}
                    drawer
                    product_pk={this.props.product_pk}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {this.props.form !== "Ebay" && (
            <TabPane tab={`EBAY`} key={"EBAY"}>
              <Row gutter={[40, 40]} style={{ padding: "20px" }}>
                <Col>
                  <Ebay
                    Alldisabled={true}
                    drawer
                    product_pk={this.props.product_pk}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {this.props.form !== "Noon" && (
            <TabPane tab={`NOON`} key={"NOON"}>
              <Row gutter={[40, 40]} style={{ padding: "20px" }}>
                <Col>
                  <Noon
                    Alldisabled={true}
                    drawer
                    product_pk={this.props.product_pk}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
        </Tabs>
      </React.Fragment>
    );
  }
}

export default ChannelDrawer;
