import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  message,
  Empty,
  Typography,
  Collapse,
  Icon,
  Tag,
  Select,
  Input,
  Divider,
  Modal,
} from "antd";
import { Ellipsis } from "react-spinners-css";
import OrdersWarehouseManagerFilterModal from "../components/modals/orders-warehouse-manager-filter-modal/OrdersWarehouseManagerFilterModal";
import OrdersWarehouseManagerMobileViewRow from "./OrdersWarehouseManagerMobileViewRow";
import { array } from "yup";


const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const orderStatues = [
  "approved",
  "picked",
  "dispatched",
  "delivered",
  "delivery failed",
  "returned",
];
const { Panel } = Collapse;
let selectedOrderForDeliveryFailed;

const getDynamicIcon = (currentStatus) => {
  if (currentStatus === orderStatues[0]) {
    // icon for picked
    return "dropbox";
  } else if (currentStatus === orderStatues[1]) {
    // icon for dispatched
    return "environment";
  } else if (currentStatus === orderStatues[2]) {
    // icons for delivered
    return "carry-out";
  }
};

export default class OrdersWarehouseManagerMobileView extends Component {
  collapseHeader = (order) => {
    let tempTotalCost = 0;
    let countObj = {
      approved: 0,
      picked: 0,
      dispatched: 0,
      delivered: 0,
      "delivery failed": 0,
    };
    order.unitOrderList.forEach((unitOrder) => {
      tempTotalCost += unitOrder.quantity * unitOrder.price;
      ++countObj[unitOrder.currentStatus];
    });
    return (
      <Row
        type="flex"
        justify="space-between"
        className="collapse-header"
        gutter={[8, 8]}
      >
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Text type="secondary">ID : </Text>
              <Text>{order.bundleId}</Text>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col>
                  <Text type="secondary">Date: </Text>
                  <Text>{order.dateCreated}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Text type="secondary">Item count : </Text>
              <Text>{order.unitOrderList.length}</Text>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col>
                  <Text type="secondary">Time: </Text>
                  <Text>{order.time}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Text>{order.customerName}</Text>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col>
                  <Text>Dealshub</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row type="flex" justify="center">
            <Col span={24}>
              <Divider style={{ margin: "2px 0px" }} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Row type="flex" justify="start" align="middle" gutter={[8, 8]}>
                <Col>
                  <Icon type="check-circle" style={{ color: "#1ABC9C" }} />
                </Col>
                <Col>
                  <Text type="secondary">Approved :</Text>
                </Col>
                <Col>
                  <Text>{countObj["approved"]}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="start" align="middle" gutter={[8, 8]}>
                <Col>
                  <Icon type="check-circle" style={{ color: "#1ABC9C" }} />
                </Col>
                <Col>
                  <Text type="secondary">Picked :</Text>
                </Col>
                <Col>
                  <Text>{countObj["picked"]}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="start" align="middle" gutter={[8, 8]}>
                <Col>
                  <Icon type="check-circle" style={{ color: "#1ABC9C" }} />
                </Col>
                <Col>
                  <Text type="secondary">Dispatched :</Text>
                </Col>
                <Col>
                  <Text>{countObj["dispatched"]}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="start" align="middle" gutter={[8, 8]}>
                <Col>
                  <Icon type="check-circle" style={{ color: "#1ABC9C" }} />
                </Col>
                <Col>
                  <Text type="secondary">Delivered :</Text>
                </Col>
                <Col>
                  <Text>{countObj["delivered"]}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="start" align="middle" gutter={[8, 8]}>
                <Col>
                  <Icon type="check-circle" style={{ color: "#1ABC9C" }} />
                </Col>
                <Col>
                  <Text type="secondary">Failed :</Text>
                </Col>
                <Col>
                  <Text>{countObj["delivery failed"]}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ padding: "0px" }}>
          <Row
            className="billing-container"
            gutter={[16, 16]}
            style={{ padding: "0px", margin: "0px" }}
          >
            <Col span={24} style={{ padding: "4px" }}>
              {/* <Row type="flex" justify="space-between"> */}
              <Row type="flex" gutter={[8, 8]}>
                <Col>
                  <Text type="secondary">Total Bill: </Text>
                  <Text>
                    {tempTotalCost} {order.currency}
                  </Text>
                </Col>
                <Col>
                  <Tag color="blue">
                    {order.paymentStatus.charAt(0).toUpperCase() +
                      order.paymentStatus.substr(1)}
                  </Tag>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col
                  span={`${
                    this.props.orderStatusFilter === orderStatues[2] ? 12 : 0
                    }`}
                >
                  {this.props.allowStatusChange &&
                    this.props.orderStatusFilter === orderStatues[2] ? (
                      <Button
                        style={{
                          color: "#fff",
                          background: "red",
                          border: "none",
                          width: "100%",
                          padding: "0px",
                        }}
                        icon="close-circle"
                        onClick={() => {
                          selectedOrderForDeliveryFailed = order.bundleId;
                          this.props.toggleModalShow(order.bundleId);
                        }}
                        disabled={this.props.someProcessingActive}
                      >
                        Delivery Failed
                      </Button>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                </Col>
                <Col
                  span={`${
                    this.props.orderStatusFilter === orderStatues[2] ? 12 : 24
                    }`}
                >
                  {this.props.allowStatusChange &&
                    this.props.orderStatusFilter !== orderStatues[3] &&
                    this.props.orderStatusFilter !== orderStatues[4] ? (
                      <Button
                        style={{
                          color: "#fff",
                          background: "#4B9A29",
                          width: "100%",
                          border: "none",
                        }}
                        onClick={() => {
                          this.props.setOrderStatus(
                            orderStatues[
                            orderStatues.indexOf(this.props.orderStatusFilter) +
                            1
                            ],
                            order.bundleId
                          );
                        }}
                        icon={getDynamicIcon(this.props.orderStatusFilter)}
                        disabled={this.props.someProcessingActive}
                      >
                        {orderStatues[
                          orderStatues.indexOf(this.props.orderStatusFilter) + 1
                        ]
                          .charAt(0)
                          .toUpperCase() +
                          orderStatues[
                            orderStatues.indexOf(this.props.orderStatusFilter) + 1
                          ].substr(1)}
                      </Button>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                </Col>
              </Row>
              {/* <Button type="primary" icon="dropbox" block>
                Picked
              </Button> */}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  render() {
    let filterObj = {
      fromDate: "",
      toDate: "",
      paymentTypeList: [],
      minQty: "",
      maxQty: "",
      minPrice: "",
      maxPrice: "",
      currencyList: [],
      shippingMethodList: [],
      trackingStatusList: [],
      clearFilters: true,
    };
    return (
      <Row
        className="orders-account-manager-mobile-view-page-container"
        gutter={[8, 8]}
      >
        <Col span={24} className="title-container">
          <Row type="flex" justify="start">
            <Col>
              <Title level={4} className="main-title">
                Orders Tracking
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]} type="flex">
            <Col
              lg={22}
              md={22}
              sm={19}
              className="warehouse-manager-search-bar-container"
            >
              <Select
                dropdownClassName="search-chips"
                dropdownMatchSelectWidth="false"
                mode="tags"
                style={{ width: "100%" }}
                onChange={this.props.handleSearchChange}
                placeholder={[<Icon type="search" />, " Search here..."]}
              ></Select>
            </Col>
            <Col lg={2} md={2} sm={5}>
              <OrdersWarehouseManagerFilterModal
                filterCount={this.props.filterCount}
                applyFilters={this.props.applyFilters}
                someProcessingActive={this.props.someProcessingActive}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]} type="flex">
            <Col></Col>
          </Row>
        </Col>

        <Col span={24} className="filter-row-container">
          <Row gutter={[8, 8]} type="flex" className="filter-row">
            <Col>
              <Button type="primary" loading={this.props.loading} ghost>
                <React.Fragment>
                  {this.props.loading ? (
                    <React.Fragment></React.Fragment>
                  ) : (
                      <React.Fragment>Results: {this.props.totalResults}</React.Fragment>
                    )}
                </React.Fragment>
              </Button>
            </Col>
            <Col>
              <Button
                className={
                  this.props.orderStatusFilter === orderStatues[0]
                    ? "active-class"
                    : ""
                }
                icon={
                  this.props.orderStatusFilter === orderStatues[0]
                    ? "check-circle"
                    : ""
                }
                onClick={() => {
                  let temp = [];
                  temp.push(orderStatues[0]);
                  filterObj["trackingStatusList"] = temp;
                  this.props.applyFilters(filterObj);
                }}
                disabled={this.props.someProcessingActive}
              >
                Approved
              </Button>
            </Col>
            <Col>
              <Button
                className={
                  this.props.orderStatusFilter === orderStatues[1]
                    ? "active-class"
                    : ""
                }
                icon={
                  this.props.orderStatusFilter === orderStatues[1]
                    ? "check-circle"
                    : ""
                }
                onClick={() => {
                  let temp = [];
                  temp.push(orderStatues[1]);
                  filterObj["trackingStatusList"] = temp;
                  this.props.applyFilters(filterObj);
                }}
                disabled={this.props.someProcessingActive}
              >
                Picked
              </Button>
            </Col>
            <Col>
              <Button
                className={
                  this.props.orderStatusFilter === orderStatues[2]
                    ? "active-class"
                    : ""
                }
                icon={
                  this.props.orderStatusFilter === orderStatues[2]
                    ? "check-circle"
                    : ""
                }
                onClick={() => {
                  let temp = [];
                  temp.push(orderStatues[2]);
                  filterObj["trackingStatusList"] = temp;
                  this.props.applyFilters(filterObj);
                }}
                disabled={this.props.someProcessingActive}
              >
                Dispatched
              </Button>
            </Col>
            <Col>
              <Button
                className={
                  this.props.orderStatusFilter === orderStatues[3]
                    ? "active-class"
                    : ""
                }
                icon={
                  this.props.orderStatusFilter === orderStatues[3]
                    ? "check-circle"
                    : ""
                }
                onClick={() => {
                  let temp = [];
                  temp.push(orderStatues[3]);
                  filterObj["trackingStatusList"] = temp;
                  this.props.applyFilters(filterObj);
                }}
                disabled={this.props.someProcessingActive}
              >
                Delivered
              </Button>
            </Col>
            <Col>
              <Button
                className={
                  this.props.orderStatusFilter === orderStatues[4]
                    ? "active-class"
                    : ""
                }
                icon={
                  this.props.orderStatusFilter === orderStatues[4]
                    ? "check-circle"
                    : ""
                }
                onClick={() => {
                  let temp = [];
                  temp.push(orderStatues[4]);
                  filterObj["trackingStatusList"] = temp;
                  this.props.applyFilters(filterObj);
                }}
                disabled={this.props.someProcessingActive}
              >
                Failed
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Col>

        {this.props.fetchingOrdersList &&
          this.props.accountsOrderListIndex === 1 ? (
            <Row
              type="flex"
              align="middle"
              justify="center"
              style={{ width: "100%", height: "100%" }}
            >
              <Ellipsis color="#1ABC9C" size={100} />
            </Row>
          ) : (
            <React.Fragment>
              {this.props.ordersManagerDetails.fetchedOrders.length > 0 ? (
                < React.Fragment>
                  {this.props.ordersManagerDetails.fetchedOrders.map(
                    (order, index) => {
                      return (
                        <Col
                          sm={24}
                          md={12}
                          lg={8}
                          xl={8}
                          className="collapse-container-warehouse-manager"
                          key={index}
                        >
                          <Collapse>
                            <Panel
                              showArrow={false}
                              extra={this.collapseHeader(order)}
                            >
                              {order.unitOrderList.map(
                                (unitOrder, unitOrderIndex) => {
                                  return (
                                    <OrdersWarehouseManagerMobileViewRow
                                      key={unitOrderIndex}
                                      bundleId={order.bundleId}
                                      bundleUuid={order.uuid}
                                      toggleOrderCheck={
                                        this.props.toggleOrderCheck
                                      }
                                      order={unitOrder}
                                    />
                                  );
                                }
                              )}
                              <Row></Row>
                            </Panel>
                          </Collapse>
                        </Col>
                      );
                    }
                  )}
                  <Modal
                    title="Type Delivery Failed Reason"
                    visible={this.props.toggleShowlModal}
                    onOk={() => {
                      this.props.handleCancelModalSubmit(selectedOrderForDeliveryFailed);
                    }}
                    onCancel={() => {
                      this.props.handleCancelModalClose(selectedOrderForDeliveryFailed);
                    }}
                    loading={!this.props.toggleShowlModal}
                    destroyOnClose={true}
                    width="50vw"
                  >
                    <Input.TextArea
                      defaultValue={this.props.deliveryFailureReason}
                      placeholder="Describe failed delivery reason using a minimum of 20 characters and maximum of 160 characters!"
                      maxLength={160}
                      onChange={this.props.handleCancelTextChange}
                    />
                  </Modal>
                </React.Fragment>
              ) : (
                  <Empty
                    className="no-orders-wrapper"
                    description="No Orders found"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              {this.props.areOrdersAvailable ? (
                <div
                  className="center-child"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "50px",
                  }}
                >
                  <Button
                    type="primary"
                    loading={this.props.fetchingOrdersList}
                    disabled={this.props.fetchingOrdersList}
                    onClick={this.props.loadMoreOrders}
                  >
                    {this.props.fetchingOrdersList ? "" : "Load More..."}
                  </Button>
                </div>
              ) : (
                  <React.Fragment></React.Fragment>
                )}
            </React.Fragment>
          )}
      </Row>
    );
  }
}
