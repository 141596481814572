/**
 * @description This is user changes state if user is looged in, logged out and if he is a nesto user
 */

/**
 * @description InitialState for user reducer
 * @typedef StateType - intial state type of user reducer
 * @type {object}
 * @property {null | object} user - user in initial state needs to be null
 * @property {boolean} isLoggedIn - Boolean to check if user is logged in
 * @property {boolean} isNestoUser - Boolean to check if user is nesto user
 * @property {boolean} canEditProducts - Boolean to check if user can edit product
 * @property {boolean} canDeleteImage - Boolean to check if user can delete images
 * @property {boolean} canViewHoldings - Boolean to check if user can view holdings
 *
 */

/**
 * @type {StateType} -ihsdfkdsj
 */

const initialState = {
  user: null,
  isLoggedIn: false,
  isNestoUser: false,
  canEditProducts: false,
  canDeleteImage: false,
  canViewHoldings: false,
};
/**
 * @param  {StateType} state - state for the reducer. Default state is initialState
 * @param  {object} action
 * @returns {StateType} - returns updated state
 */

export default function user(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, user: action.value, isLoggedIn: true };
    case "USER_LOGOUT":
      return { ...state, user: action.value, isLoggedIn: false };
    case "NESTO_USER":
      return {
        ...state,
        isNestoUser: action.payload.isNestoUser,
        canEditProducts: action.payload.canEditProducts,
        canDeleteImage: action.payload.canDeleteImage,
        canViewHoldings: action.payload.canViewHoldings,
      };
    default:
      return state;
  }
}
