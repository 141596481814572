import React, { useEffect, useState } from "react";
import { Row, Col, Typography, AutoComplete, Avatar, Icon, Spin, Card, Tooltip, message } from "antd";
import PlaceholderImage from "../../../media/placeholder3.png"
import axios from "axios";
import isDev from "../../../server";
const serverUrl = isDev();

const {Text, Paragraph} = Typography;
const { Option } = AutoComplete;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const ProductBasket = (props) => {

    const [searchListing, setSearchListing] = useState([]);
    const [isProductUploading, setIsProductUploading] = useState(false);
    const [autocompleteStr, setAutocompleteStr] = useState("");
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        if(props.products)
            setProductList([...props.products]);
    }, [props.products])
    

    const autocompleteSearch = (searchString) => {
        if(searchString !== "") {
            axios.post(`${serverUrl}/search-nesto-product-autocomplete/`, {
                searchString: searchString,
                product_uuid: props.product_uuid,
                link_type: props.linkType
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(res => {
                if(res.data.status === 200) {
                    let tempList = [];
                    res.data.nesto_product_list.map(list => (
                        tempList.push(
                            <Option key={list.uuid} value={list.uuid}>{list.name} | {list.barcode}</Option>
                        )
                    ));
                    setSearchListing([...tempList])
                }
            })
        }
    }

    const addProduct = (productUuid) => {
        setIsProductUploading(true);
        axios.post(`${serverUrl}/link-nesto-product/`, {
            product_uuid: props.product_uuid,
            linked_product_uuid: productUuid,
            link_type: props.linkType
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(res => {
            setAutocompleteStr("");
            if(res.data.status === 200) {
                props.handleUpdate(true);
                message.success("Product Added Successfully!");
            } else if(res.data.status === 403) {
                message.info("You are not authorised!")
            }
            else {
                message.error("Something went wrong!");
            }
            setIsProductUploading(false);
        })
    }

    const removeProduct = (productUuid) => {
        setIsProductUploading(true);
        axios.post(`${serverUrl}/unlink-nesto-product/`, {
            product_uuid: props.product_uuid,
            linked_product_uuid: productUuid,
            link_type: props.linkType
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(res => {
            if(res.data.status === 200) {
                props.handleUpdate(true);
                message.success("Product Removed Successfully!");
            } else if(res.data.status === 403) {
                message.info("You are not authorised!")
            }
            else {
                message.error("Something went wrong!");
            }
            setIsProductUploading(false);
        })
    }

    return (
        <>
            <Col span={24} style={{marginTop: 30}}>
                <AutoComplete
                    dataSource={searchListing}
                    onSelect={(selectedValue) => {addProduct(selectedValue);}}
                    value= {autocompleteStr}
                    onChange = {(e) => {
                        setAutocompleteStr(e);
                    }}
                    onSearch={autocompleteSearch}
                    placeholder="Search for Products"
                    style={{ width: "100%" }}
                    //disabled = {this.state.sectionData.products.length >= 40}
                />
            </Col>
            <Col span={24} className="added-products-listing">
                <Spin
                    indicator={antIcon}
                    tip="Updating Products List Data"
                    spinning={isProductUploading}
                >
                    <Row style={{ margin: "30px 0px", maxHeight: 300, overflow: 'scroll' }}>
                        {
                            productList.map((product, index) => {
                                return (
                                    <Col
                                        sm={24}
                                        md={24}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        // span={24}
                                        key={product.uuid}
                                    >
                                        <Card
                                            bordered
                                            bodyStyle={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Row
                                                gutter={[16, 24]}
                                                style={{ width: "100%", display: 'flex', alignItems: 'center' }}
                                            >
                                                <Col sm={4} xs={5} style={{display: 'flex', alignItems: 'center'}}>
                                                    <Avatar
                                                        shape="square"
                                                        size={window.innerWidth < 480 ? 48 : 64}
                                                        src={product.image ? product.image : PlaceholderImage}
                                                    />
                                                </Col>
                                                <Col sm={20} xs={19}>
                                                    <Tooltip>
                                                        <Paragraph
                                                            ellipsis={{
                                                                rows: 2,
                                                                expandable: false,
                                                            }}
                                                            style={{
                                                                boxSizing: "border-box",
                                                                height: "42px",
                                                                marginBottom: "0px",
                                                            }}
                                                        >
                                                            {product.name}
                                                        </Paragraph>
                                                    </Tooltip>
                                                    <Col span={12} style={{ padding: 0 }}>
                                                        <Text>Barcode : </Text>
                                                        <Text>{product.barcode}</Text>
                                                    </Col>
                                                    <Col span={12} style={{ padding: 0 }}>
                                                        <Text>Article No. : </Text>
                                                        <Text>{product.article_number}</Text>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "6px",
                                                    right: "10px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => {removeProduct(product.uuid);
                                                }}
                                                productid={product.uuid}
                                            >X</div>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Spin>
            </Col>
        </>
    );
};

export default ProductBasket;
