import { utils_copy } from "./Utils";

/**
 * @description This reducer is used to fetch product from different channels ex: amazon, noon, etc.
 */

/**
 * @description State type for channelProductReducer
 * @typedef ChannelProductsStateType - This is type of channel product state
 * @property {object} amazon_uk - to store product info
 *
 *
 */

/**
 * @type {ChannelProductsStateType}
 */

const initialState = {
  amazon_uk: {
    repr_image_url: "",
    repr_high_def_url: "",
    product_id: "",
    material_type: "",
    images: {},
    channel_product_json: {
      product_name: "",
      product_description: "",
      product_attribute_list: [],
      category: "",
      sub_category: "",
      created_date: "",
      parentage: "",
      parent_sku: "",
      relationship_type: "",
      variation_theme: "",
      feed_product_type: "",
      ASIN: "",
      update_delete: "",
      recommended_browse_nodes: "",
      search_terms: "",
      enclosure_material: "",
      cover_material_type: "",
      special_features: [],
      sale_price: "",
      sale_from: "",
      sale_end: "",
      wattage: "",
      wattage_metric: "",
      item_count: "",
      item_count_metric: "",
      item_condition_note: "",
      max_order_quantity: "",
      number_of_items: "",
      condition_type: "",
      dimensions: {
        package_length: "",
        package_length_metric: "",
        package_width: "",
        package_width_metric: "",
        package_height: "",
        package_height_metric: "",
        package_weight: "",
        package_weight_metric: "",
        package_quantity: "",
        shipping_weight: "",
        shipping_weight_metric: "",
        item_display_weight: "",
        item_display_weight_metric: "",
        item_display_volume: "",
        item_display_volume_metric: "",
        item_display_length: "",
        item_display_length_metric: "",
        item_weight: "",
        item_weight_metric: "",
        item_length: "",
        item_length_metric: "",
        item_width: "",
        item_width_metric: "",
        item_height: "",
        item_height_metric: "",
        item_display_width: "",
        item_display_width_metric: "",
        item_display_height: "",
        item_display_height_metric: "",
      },
      status: "",
      http_link: "",
      was_price: "",
      now_price: "",
      stock: "",
    },
    status: "",
  },
};

/**
 *
 * @param {ChannelProductsStateType} state
 * @param {object} action
 * @returns {ChannelProductsStateType}
 */

export default function channelProducts(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CHANNEL_PRODUCT_AMAZON_UK":
      let channel_product_json = utils_copy(
        state.amazon_uk.channel_product_json,
        action.payload.channel_product_json
      );
      action.payload.channel_product_json = channel_product_json;
      return { amazon_uk: { ...action.payload, ["status"]: "" } };
    case "SEND_FEED_BACK_TO_CHANNEL_FORM":
      return { amazon_uk: { ...state.amazon_uk, ["status"]: action.payload } };
    case "SET_IMAGE_AMAZON_UK":
      return {
        amazon_uk: { ...state.amazon_uk, ["images"]: action.payload.images },
      };
    case "HANDEL_CHANGE":
      return { amazon_uk: { ...action.payload, ["status"]: "" } };
    default:
      return state;
  }
}
