import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignIn from "../views/sign-in-page/SignIn";
import HomePage from "../home-page/HomePage";
import PlatformContentPage from "../views/platform-content-page/PlatformContentPage";

/**
 * @description PublicRoute is a class component which on render returns to 
 * SignIn or Homepage or PlatformContentPage based on URL Route
 */
export default class PublicRoute extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/platform" component={PlatformContentPage} />

          <Route
            render={() => (
              <p className="text-center">
                O oo !! <br /> I Think You Are Lost
              </p>
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}
