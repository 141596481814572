import React, { Component } from "react";
import { Ellipsis } from "react-spinners-css";

import { PageWrapper } from "../components/root-styled-components/pageObjects";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { Row, Col, Button, message, Icon } from "antd";
import ReportsCard from "../components/cards/ReportsCard";
import SearchInput from "../components/searchinput/SearchInput";
import "./reports-page.scss";
import isDev from "../../server";
import ReportsPageFilterModal from "./ReportsPageFilterModal";

const serverUrl = isDev();

class ReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportList: [],
      page: 1,
      loadMoreLoading: false,
      is_available: false,
      loading: true,
      filters: {},
      tags: []
    };
  }

  handleFilterChange = (filters) => {
    this.setState({
      filters
    }, () => {
      console.log(filters)
    })
  }

  fetchList = (update = false) => {

    if (update) {
      this.setState({
        reportList: [],
        loading: true
      })
    }

    let data = {
      page: this.state.page,
      filter_parameters: this.state.filters,
      tags: this.state.tags
    };

    fetch(`${serverUrl}/mws/fetch-report-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "DATA");
        if (data.status == 200) {
          this.setState({
            reportList: (update) ? data.report_list : [...this.state.reportList, ...data.report_list],
            is_available: data.is_available,
            loadMoreLoading: false,
            loading: false
          });
        } else {
          message.error("Something went Wrong!");
          this.setState({
            loadMoreLoading: false,
            loading: false
          })
        }
      });
  }

  componentDidMount = () => {
    this.fetchList();
  };

  handleLoadMore = () => {
    this.setState({
      page: this.state.page + 1,
      loadMoreLoading: true
    }, () => {
      this.fetchList()
    })
  }

  handleFilters = () => {
    this.fetchList(true);
  }

  getTags = (tags) => {
    this.setState({
      tags
    }, () => {
      this.fetchList(true);
    })
  }

  render() {
    if (this.state.reportList == null) {
      return (
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ width: "100%", height: "80vh" }}
        >
          <Ellipsis color="#1ABC9C" size={100} />
        </Row>
      );
    }

    return (
      <PageWrapper gutter={40} className="exports-page-wrapper">
        <TitleWithBreadCrumb
          title="Reports"
          breadcrumb={["OmnyComm", "Reports"]}
        />

        <Row
          style={{
            background: "#36404A",
            minHeight: "80%",
            borderRadius: "5px",
            padding: "20px",
            marginTop: "0px",
          }}
          gutter={[12, 12]}
        >
          <Col sm={24}>
            <Row gutter={[12, 12]}>
              <Col sm={20}>
                <SearchInput getTagsList={this.getTags} tags={this.state.tags} />
              </Col>
              <Col sm={4}>
                <ReportsPageFilterModal handleFilterChange={this.handleFilterChange} handleFilters={this.handleFilters} />
              </Col>
              {/* <Col sm={3}>
                <Button type="primary" style={{ width: "100%" }}>
                  Product
                </Button>
              </Col>
              <Col sm={3}>
                <Button type="danger" style={{ width: "100%" }}>
                  Price
                </Button>
              </Col>
              <Col sm={3}>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#1890ff",
                    color: "white",
                    border: "none",
                  }}
                >
                  Inventory
                </Button>
              </Col>
              <Col sm={3}>
                <Button type="primary" style={{ width: "100%" }} ghost>
                  All
                </Button>
              </Col> */}
            </Row>
          </Col>
          <Col sm={24}>
            <Row gutter={[16, 16]}>
              {this.state.reportList &&
                this.state.reportList.map((e, index) => {
                  return (
                    <Col key={index} sm={24} lg={8} md={12}>
                      <ReportsCard reportDetails={e} />
                    </Col>
                  );
                })}
            </Row>
            {
              this.state.loading &&

              <Row type="flex" align="middle" justify="center" style={{ width: "100%", height: "80vh" }}>
                <Ellipsis color="#1ABC9C" size={100} />
              </Row>
            }


          </Col>

          {
            this.state.is_available &&
            <Col sm={24}>
              <Row type="flex" justify="center" align="middle">
                <Button onClick={this.handleLoadMore} loading={this.state.loadMoreLoading} type="primary" disabled={this.state.loadMoreLoading}>
                  {!this.state.loadMoreLoading && "Load More"}</Button>
              </Row>
            </Col>
          }
        </Row>
      </PageWrapper>
    );
  }
}

export default ReportsPage;
