import React from "react";
import { Tabs, Icon, Badge } from "antd";
import "./customer-card.scss";
import Review from "./Review";
import Orders from "./Orders";
import Cart from "./Cart";

const { TabPane } = Tabs;

const TabsContent = (props) => {
    return (
        <div style={{
            padding: "20px",
            background: "#233140"
        }}>
            {props.children}
        </div>
    )
}

export default function CustomerDetailsTabs({ customer, username, unitCartList , reviewList , updatePost}) {

    console.log(unitCartList, "dfdf");

    return (
        <React.Fragment>
            <div className="customer-details-tabs">
                <Tabs defaultActiveKey="2" >
                    <TabPane
                        tab={
                            <span>
                                <Icon type="eye" />
                        Review
                        </span>
                        }
                        key="1"
                    >
                        <Review reviewList = {reviewList} updatePost = {updatePost} username = {username} />
                    </TabPane>
                    <TabPane
                        tab={
                            // <Badge count={99} style={{ fontSize: "5px" }}>
                            <span>
                                <Icon type="shopping-cart" />
                                    Cart
                                </span>
                            // </Badge>
                        }
                        key="2"
                    >
                        {
                            <Cart customer={customer} customerCartList={unitCartList} />
                        }
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Icon type="wallet" />
                        Orders
                        </span>
                        }
                        key="3"
                    >
                        <Orders username={username} />
                    </TabPane>
                </Tabs>
            </div>
        </React.Fragment>
    )
}