import React from "react";
import {Radio , Typography} from "antd";

const {Text} = Typography;

const Options = ({setOptionType , optionType , channelname}) => {

    const options = {
        "Noon" : {
            values : ["Product ID" , "Seller SKU" , "Partner SKU" , "Noon SKU"],
            show : ["Product ID" , "Seller SKU" , "Partner SKU" , "Noon SKU"]
        },
        "Amazon UAE" : {
            values : ["Product ID" , "Seller SKU" , "ASIN"],
            show : ["Product ID" , "Seller SKU" , "ASIN"]
        },
        "Amazon UK" : {
            values : ["Product ID" , "Seller SKU" , "ASIN"],
            show : ["Product ID" , "Seller SKU" , "ASIN"]
        }
    }

    const radioStyle = {
        display: "block",
      };


    return (
        <React.Fragment>
             <Text>Choose the Option:</Text><br />
              <Radio.Group onChange={(e) => setOptionType(e.target.value)} value={optionType}>
                  {options[channelname].values.map((e,index) => {
                      return (
                            <Radio style={radioStyle} value={e}>
                                <Text style={{ marginRight: "15px" }}>{options[channelname].show[index]}</Text>
                            </Radio>
                      )
                  })}
              </Radio.Group>
        </React.Fragment>
    )
}

export default Options;