import React , {useState , useEffect} from "react";
import { Col, Row, Typography, Rate ,Input , Button, message , Icon } from "antd";
import { setCommentRange } from "typescript";
import isDev from "server";
import axios from "axios";

const serverDealsHubUrl = isDev(true);
const serverOmnyComm = isDev();

const { Text, Paragraph } = Typography;
const {TextArea} = Input;

export default function Review({reviewList , updatePost , username}) {

    const [activeIndex , setActiveIndex] = useState(0);

    console.log(reviewList);

    let activeReviewDetails  = {};
    let reviewComment = ""

    activeReviewDetails = (reviewList && reviewList[activeIndex]) || {};
    reviewComment = "";
    if(activeReviewDetails.isReply)
    reviewComment = activeReviewDetails.comment;
   

    const [comment , setComment] = useState(reviewComment);
    const [editMode , setEditMode] = useState(false);
    const [submitLoading , setSubmitLoading] = useState(false); 

    const [userProfile , setUserProfile] = useState("");


    useEffect(() => {
         let data = {

          };
          fetch(`${serverOmnyComm}/fetch-user-profile/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((data) => {

              if (data.status == 200) {
                    setUserProfile(data.first_name+" "+data.last_name)
              } 
              
            });
    },[])

    // useEffect(() => {
    //     if(editMode)
    //     {
    //         setComment("")
    //     }
    //     else
    //     {
    //         setComment(reviewComment)
    //     }
    // },[editMode])

    const submitPost = () => {
        setSubmitLoading(true)
        const data = {
            uuid : activeReviewDetails.uuid,
            username : localStorage.getItem("username"),
            displayName : activeReviewDetails.displayName || userProfile,
            comment
        }

        axios.post(`${serverDealsHubUrl}/dealshub/add-admin-comment/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            setSubmitLoading(false);
            if (response.data.status == 200) {
                setEditMode(false);
                updatePost(username , activeIndex , comment);
                message.success("Admin comment succesfully updated")
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else {
                message.error("Internal Server Error!");
            }
        });
    }

    useEffect(() => {
        setComment(reviewComment);
        setEditMode(false);
    },[activeIndex])

    if((reviewList && reviewList.length == 0) || !reviewList)
    {
        return (
            <React.Fragment>
                <Text style={{  fontSize: "10px" }}>No Reviews</Text>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col span={4}  style = {{maxHeight : "400px" , overflow : "scroll" , borderRight : "3px solid #54BD9D"}} >
                    <Row gutter = {[12,12]}>
                        {
                            reviewList.map((e , index) => (
                                <Col onClick = {() => setActiveIndex(index)} style = {activeIndex == index ? {border : "0.1px solid #295D5B"} : {}}>
                                    <img src={`${e.productImageUrl}`} style={{
                                    width: "100%",
                                    borderRadius: "4px",
                                    padding: "4px",
                                    backgroundColor: "#313841"
                                }} />
                                <Text style = {{color : "#54BD9D" , fontSize : "12px"}}>{e.sellerSku}</Text>
                            </Col>
                            ))
                        }
                    </Row>
                </Col>

                <Col span={20}>
                    <Row gutter={[4, 4]}>
                        <Col span={3}>
                            <Text style={{ color: "#54BD9D", fontSize: "12px" }}>Ratings : </Text>
                        </Col>
                        <Col span={21}>
                            <Rate disabled style={{ fontSize: "12px", lineHeight: "0px" }} defaultValue={Number(activeReviewDetails.rating)} />
                        </Col>
                        <Col span={3}>
                            <Text style={{ color: "#54BD9D", fontSize: "12px" }}>Subject : </Text>
                        </Col>
                        <Col span={21}>
                        <Text style={{ color: "white", fontSize: "12px", fontWeight: "bold" }}>{activeReviewDetails.subject}</Text>
                        </Col>
                        <Col span={3}>
                            <Text style={{ color: "#54BD9D", fontSize: "12px" }}>Review : </Text>
                        </Col>
                        <Col span={21}>
                      
                        <Text style={{ color: "white", fontSize: "12px" }}>
                                  {
                                    activeReviewDetails.content
                                }
                         </Text>
                        </Col>

                        {
                            (activeReviewDetails.isReply && !editMode) &&
                            <Col style = {{marginTop : "0px"}} span = {21} offset = {3}>
                                <Row type = "flex" justify = "end">
                                    <Col>
                                        <Icon onClick = {() => setEditMode(true)} style={{ color: "#54BD9D", fontSize: "11px" , cursor: "pointer" }} type = "edit" />
                                    </Col>
                                </Row>
                            </Col>
                        }

                        <Col span = {21} offset = {3} style = {{marginTop : "2px"}}>
                            <TextArea
                            disabled = {!editMode  && activeReviewDetails.isReply}
                            placeholder="Reply back to user"
                            value = {comment}
                            rows={4} autoSize={{ minRows: 3, maxRows: 6 }} onChange = {(e) => setComment(e.target.value)} style = {{border : "1px solid #54BD9D" , fontSize : "12px"}}/>
                        </Col>

                        <Col style = {{marginTop : "4px"}} span = {21} offset = {3}>
                                <Row type = "flex" justify = "end" gutter = {[8,8]}>      
                                        {
                                            (activeReviewDetails.isReply && !editMode) ?
                                            <Col>
                                             <Text style={{ color: "#54BD9D", fontSize: "11px" }}>{activeReviewDetails.displayName}</Text>
                                             </Col> :
                                             <React.Fragment>
                                                 {
                                                     editMode &&
                                                    <Col span = {4}>
                                                        <Button onClick = {() => setEditMode(false)} className = "tag-button-primary" ghost type = "primary" size = "small">
                                                            cancel
                                                        </Button>
                                                    </Col>
                                                 }
                                            <Col span = {4}>
                                                <Button loading = {submitLoading} icon = "message" onClick = {submitPost} className = "tag-button-primary" ghost type = "primary" size = "small">
                                                    Submit
                                                </Button>
                                            </Col>
                                            </React.Fragment>
                                        }
                                
                                </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}