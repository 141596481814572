import React, { Component } from "react";
import { Collapse, Icon, Row, Col } from "antd";
import "./varients-info.scss";
import Faq from "../../components/datainputs/FAQ/Faq";

const { Panel } = Collapse;

class VarientsforFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  customPanelStyle = {
    borderRadius: 4,
    // marginBottom: 24,
    border: 0,
    overflow: "hidden"
    // padding: "10px 0px 10px 0px",
  };

  collapsedStyle = {
    maxHeight: "50vh",
    overflow: "scroll",
    paddingTop: "5px"
  };

  collapsBlock = {
    // margin: "10px 0px 10px 0px"
  };

  render() {

    return (
      <React.Fragment>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header={this.props.name}
            key="4"
            style={this.customPanelStyle}
          >
            <Row gutter={[24, 24]} style={this.collapsedStyle}>
              <Col sm={24} style={{ marginTop: "10px" }}>
                <Faq
                  disabled={this.props.disabled}
                  initialdata={this.props.initialdata || []}
                  sendList={this.props.sendList}
                  addbuttontitle={this.props.buttontitle}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default VarientsforFaq;
