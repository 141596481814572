import React, { Component } from "react";
import { Row, Col, Menu, Dropdown, Icon, Button } from "antd";

import "./image-manager.scss";
import UploadImage from "../upload-image/UploadImage";

class ImageManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSelected: "ALL IMAGES",
      hide: false,
      imagesData: {
        mainImages: null,
        subImages: null,
        visible: false
      }
    };
  }

  onClick = item => {

    let str = item.item.props.children[0];
    this.setState({ itemSelected: str });
  };

  handleBtnClick = () =>{
    this.setState((state, props)=>(
        {visible: !state.visible}
    ))
  }

  render() {

    const menu = (
      <Menu onClick={this.onClick}>
        {this.props.images &&
          Object.keys(this.props.images).map((key, index) => (
            <Menu.Item key={index}>
              {key
                .split("_")
                .join(" ")
                .toUpperCase()}{" "}
              ({this.props.images[key] && this.props.images[key].length})
            </Menu.Item>
          ))}
      </Menu>
    );
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col lg={13}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                style={{ color: "white" }}
                className="ant-dropdown-link"
                href="#"
              >
                {this.state.itemSelected} <Icon type="down" />
              </a>
            </Dropdown>
          </Col>
          <Col lg={11}>
              <Row type = "flex" justify = "end">
              {
                this.state.itemSelected === "BEST IMAGES" && 
                <Button type="text" onClick={this.handleBtnClick}>Add from existing Images</Button>
              }
              </Row>
          </Col>
          <Col sm={24}>
            <UploadImage
              modalVisible={this.state.visible}
              handleVisibility={(flag) => this.setState({visible: flag})}
              fileList={
                this.props.images &&
                this.props.images[
                this.state.itemSelected
                  .split(" ")
                  .join("_")
                  .toLowerCase()
                ]
              }
              hideEmpty={this.hideEmpty}
              showUploadList={true}
              scroll={true}
              category={this.state.itemSelected
                .split(" ")
                .join("_")
                .toLowerCase()}
              product_pk={this.props.product_pk}
              classname={
                this.state.itemSelected == "all_images"
                  ? "hide-on-all-images"
                  : ""
              }
              fetchImagesOnly={this.props.fetchImagesOnly}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ImageManager;
