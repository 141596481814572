import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Icon,
  message,
  Button,
  Modal,
  Radio,
  Select,
  Tooltip,
  Spin,
} from "antd";
import RightWrapper from "../../my-components/ui-components/RightWrapper";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import CustomerCard from "./components/CustomerCard";
import isDev from "../../server";
import axios from "axios";
import { Ellipsis } from "react-spinners-css";

import "./customers.scss";
import CustomerDetails from "./components/CustomerDetails";
import user from "reducers/user";

const serverDealsHubUrl = isDev(true);

const { Text } = Typography;

const CustomerPannel = (props) => {
  
  const [customerList, setCustomerList] = useState(null);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [available, setAvailable] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    is_feedback_available: "",
    is_cart_empty: "",
  });
  const [selectedUsername, setSelectedUsername] = useState(-1);
  const [customerDetails, setCustomerDetails] = useState({});
  const [isSubscribersLoading, setIsSubscribersLoading] = useState(false);
  const [isCustomerReportLoading, setIsCustomerReportLoading] = useState(false);

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    props.setDetails(customerDetails);
  }, [customerDetails]);

  useEffect(() => {
    if (selectedUsername == -1) return;
    props.setName(selectedUsername);
    if (!customerDetails.selectedUsername) {
      let username = selectedUsername;
      const data = {
        username,
        locationGroupUuid: props.locationUuid,
      };

      axios
        .post(`${serverDealsHubUrl}/dealshub/fetch-customer-details/`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.status == 200) {
            setCustomerDetails({
              ...customerDetails,
              [username]: response.data,
            });
          } else {
            message.error("Internal Server Error!");
          }
        });
    }
  }, [selectedUsername]);

  const fetchProduct = () => {
    setLoading(true);

    const data = {
      page,
      search_list: tags,
      filter_parameters: filters,
      locationGroupUuid: props.locationUuid,
      // websiteGroupName: localStorage.getItem("websiteGroupName"),
    };

    axios
      .post(`${serverDealsHubUrl}/dealshub/fetch-customer-list/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status == 200) {
          if (customerList && page > 1)
            setCustomerList([...customerList, ...response.data.customerList]);
          else setCustomerList(response.data.customerList);
          setAvailable(response.data.is_available);
        } else {
          message.error("Internal Server Error!");
        }
      });
  };

  const fetchNewsletterSubscribers = async () => {
    setIsSubscribersLoading(true);
    const data = {
      report_type: "NewsletterSubscribers",
      note: "",
      locationGroupUuid: props.locationUuid,
    };
    try {
      const response = await axios.post(
        `${serverDealsHubUrl}/create-oc-report/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setVisible(false);
        message.success("Report generated successfully");
      }
    } catch (error) {
    } finally {
      setIsSubscribersLoading(false);
    }
  };

  const fetchCustomerReport = async () => {
    setIsCustomerReportLoading(true);
    const data = {
      report_type: "customer details",
      note: "",
       brand_list: [],
       from_date: "",
      to_date: "",
      locationGroupUuid: props.locationUuid,

    };
    try {
      const response = await axios.post(
        `${serverDealsHubUrl}/create-oc-report/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setVisible(false);
        message.success("Report downloaded successfully");
      }
    } catch (error) {
    } finally {
      setIsCustomerReportLoading(false);
    }
  };

  useEffect(() => {
    setCustomerList([]);
    setPage(1);
    if (page == 1) fetchProduct();
  }, [tags]);

  useEffect(() => {
    setCustomerList([]);
    setPage(1);
    if (page == 1) fetchProduct();
  }, [filters]);

  useEffect(() => {
    fetchProduct();
  }, [page]);

  useEffect(() => {
    fetchProduct();
  }, [...props.locationUuid]);

  const radioStyle = {
    display: "block",
  };

  return (
    <Row
      gutter={[8, 8]}
      style={{
        height: "87vh",
        overflow: "scroll",
      }}
    >
      <Row
        type="flex"
        justify="end"
        align="middle"
        gutter={[8, 8]}
        style={{ marginBottom: "6px", paddingLeft: "5px" }}
      >
        <Col span={21}>
          <Select
            dropdownClassName="search-chips"
            dropdownMatchSelectWidth="false"
            mode="tags"
            style={{ width: "100%" }}
            placeholder={[<Icon type="search" />, " Search Customers..."]}
            onChange={(e) => setTags(e)}
            value={tags}
          ></Select>
        </Col>
        <Col span={3}>
          <Tooltip title="Filters">
            <Button
              icon="filter"
              onClick={() => setVisible(true)}
              style={{ cursor: "pointer", color: "#858688" }}
              ghost
              type="primary"
              style={{ color: "while" }}
            ></Button>
          </Tooltip>
        </Col>
      </Row>
      {customerList &&
        customerList.map((customer, index) => {
          return (
            <Col
              onClick={() => {
                props.openDetails(customer);
                setSelectedUsername(customer.username);
              }}
            >
              <CustomerCard
                data={{
                  primaryTitle: "",
                  name: customer.name,
                  secondaryTitle: "",
                  secondary: customer.contactNumber,
                  is_cart_empty: customer.is_cart_empty,
                  is_feedback_available: customer.is_feedback_available,
                  status: "",
                }}
                selected={customer.username == selectedUsername}
              />
            </Col>
          );
        })}

      {loading && (
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ width: "100%", height: "100%" }}
        >
          <Ellipsis color="#1ABC9C" size={50} />
        </Row>
      )}
      {available && (
        <Col>
          <Button
            loading={loading}
            onClick={() => setPage(page + 1)}
            type="primary"
            ghost
            style={{ fontSize: "11px" }}
          >
            {loading ? "" : "Load More"}
          </Button>
        </Col>
      )}

      <Modal
        title="Filter"
        visible={visible}
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Row gutter={[12, 12]}>
          {/* <Col span={12}>
                        <Text> Is feedback available </Text><br />
                        <Radio.Group onChange={(e) => { setFilters({ ...filters, "is_feedback_available": e.target.value }); }} value={filters.is_feedback_available}>
                            <Radio style={radioStyle} value="">
                                <Text style={{ marginRight: "15px" }}>All</Text>
                            </Radio>
                            <Radio style={radioStyle} value={true}>
                                <Text style={{ marginRight: "15px" }}>Yes</Text>
                            </Radio>
                            <Radio style={radioStyle} value={false}>
                                <Text style={{ marginRight: "15px" }}>No</Text>
                            </Radio>
                        </Radio.Group>
                    </Col> */}

          <Col span={12}>
            <Text> User Cart </Text>
            <br />
            <Radio.Group
              onChange={(e) =>
                setFilters({ ...filters, is_cart_empty: e.target.value })
              }
              value={filters.is_cart_empty}
            >
              <Radio style={radioStyle} value="">
                <Text style={{ marginRight: "15px" }}>All</Text>
              </Radio>
              <Radio style={radioStyle} value={true}>
                <Text style={{ marginRight: "15px" }}>Cart Empty</Text>
              </Radio>
              <Radio style={radioStyle} value={false}>
                <Text style={{ marginRight: "15px" }}>Cart Filled</Text>
              </Radio>
            </Radio.Group>
            <p style={{ marginTop: "1rem" }}>Newsletter Subscribers</p>
            <Button onClick={() => fetchNewsletterSubscribers()}>
              {isSubscribersLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                "Generate Report"
              )}
            </Button>
            <Button
              style={{ marginLeft: "15px" }}
              onClick={() => fetchCustomerReport()}
            >
              {isCustomerReportLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                "Download Customer Report"
              )}
            </Button>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

const AnalyticsPannel = () => {
  return (
    <Row type="flex" justify="center" align="middle" style={{ height: "100%" }}>
      <Col>
        <Text>Analytics Coming Soon...</Text>
      </Col>
    </Row>
  );
};

const InnerWrapper = ({ reports, locationUuid }) => {
  const [visible, setVisible] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [selectedUsername, setSelectedUsername] = useState(-1);

  const setDetails = (details) => {
    setCustomerDetails(details);
  };

  const setName = (username) => {
    setSelectedUsername(username);
  };

  const updatePost = (username, index, comment) => {
    let copy_review_list = customerDetails[username].reviewList;
    copy_review_list[index].comment = comment;
    setCustomerDetails({
      ...customerDetails,
      [username]: {
        ...customerDetails[username],
        reviewList: copy_review_list,
      },
    });
  };

  const borderColor = visible ? "#54BD9D" : "gray";

  return (
    <React.Fragment>
      <Row
        gutter={[24, 8]}
        style={{
          padding: "20px",
          height: "100%",
        }}
      >
        <Col span={8} style={{ borderRight: `5px solid ${borderColor}` }}>
          <CustomerPannel
            setDetails={setDetails}
            setName={setName}
            locationUuid={locationUuid}
            openDetails={(customer) => {
              setVisible(true);
            }}
          />
        </Col>
        {!visible && (
          <Col span={16} style={{ height: "100%" }}>
            <AnalyticsPannel />
          </Col>
        )}
        {visible && (
          <Col span={16} style={{ height: "100%" }}>
            <CustomerDetails
              locationGroupUuid={locationUuid}
              customer={customerDetails[selectedUsername]}
              updatePost={updatePost}
              username={selectedUsername}
              closeIt={() => setVisible(false)}
            />
          </Col>
        )}

        {/* <Col span={16} style={{ position: "relative" }}>
                    <Drawer
                        title="Basic Drawer"
                        placement="right"
                        closable={true}
                        onClose={() => setVisible(false)}
                        visible={visible}
                        getContainer={false}
                        style={{ position: 'absolute' }}
                        mask={false}
                        maskClosable={false}
                    >
                        <p>Some contents...</p>
                    </Drawer>
                </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default function CustomersWithDetails(props) {
  return (
    <React.Fragment>
      <RightWrapper
        innerwrapperTransparent
        InnerWrapper={
          <React.Fragment>
            <InnerWrapper locationUuid={props.locationUuid} />
            {/* {
                            isAvailable &&
                            <Row type="flex" justify="center">
                                <Col style={{ marginBottom: "20px" }}>
                                    <Button type="primary" icon={availableLoading ? "loading" : ""} onClick={() => {
                                        loadMore();
                                    }}>{availableLoading ? "" : "Load More"}</Button>
                                </Col>
                            </Row>
                        } */}
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
