import React, { Component } from "react";
import {
  Card,
  Divider,
  Row,
  Col,
  BuParagraphtton,
  Modal,
  Typography,
  Tag,
  Button,
  Tooltip,
  Icon,
  message
} from "antd";
import "./cards.scss";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import isDev from "../../../server";
const serverUrl = isDev();

const { Text, Paragraph } = Typography;

class ExportCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report_status: this.props.reportDetails.report_status,
      loader: false
    };
  }


  refresh = (pk) => {
    this.setState({
      loader: true
    })

    let data = {
      report_pk: pk
    };

    fetch(`${serverUrl}/mws/refresh-report-status/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loader: false
        })
        console.log(data, "DATA");
        if (data.status == 200) {
          this.setState({
            report_status: data.report_status
          });
        } 
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
        else {
          message.error("Something went Wrong!");
        }
      });
  }

  componentWillReceiveProps = (props) => {
    console.log(props);
    this.setState({
      report_status: props.reportDetails.report_status || ""
    })
  }

  render() {

    console.log(this.props);

    const {
      pk,
      feed_submission_id,
      operation_type,
      report_status,
      is_read,
      created_date,
      product_count,
      user,
    } = this.props.reportDetails || {
      pk: "",
      feed_submission_id: "",
      operation_type: "",
      report_status: "",
      is_read: "",
      created_date: "",
      product_count: "",
      user: "",
    };

    return (
      <React.Fragment>
        <Card style={{ position: "relative" }}>

          {
            is_read &&
            <div
              style={{
                position: "absolute",
                top: "7px",
                right: "30px",

              }}
            >
              <Tooltip title="Already read">
                <Icon style={{ color: "#F18090" }} type="eye" />
              </Tooltip>
            </div>

          }

          <div
            style={{
              position: "absolute",
              top: "7px",
              right: "10px",

            }}
          >
            {
              this.state.report_status == "In Progress" ?
                <Icon style={{ color: "#ffe58f" }} type="clock-circle" /> :
                <Icon style={{ color: "#1abc9c" }} type="check-circle" />
            }

          </div>

          <Row>
            <React.Fragment>
              <Col span={10}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-key"
                >
                  {" "}
                  Op type:{" "}
                </Paragraph>
              </Col>
              <Col span={13} offset={1}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-value"
                >
                  {" "}
                  {operation_type === "Inventory" ? "Stock Update" : (operation_type === "Push" ? "Uploading Products" : (operation_type === "Price" ? "Price Update" : "NA"))}{" "}
                </Paragraph>
              </Col>
            </React.Fragment>
            <React.Fragment>
              <Col span={10}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-key"
                >
                  {" "}
                  Submission Id:{" "}
                </Paragraph>
              </Col>
              <Col span={13} offset={1}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-value"
                >
                  {" "}
                  {feed_submission_id}{" "}
                </Paragraph>
              </Col>
            </React.Fragment>
            <React.Fragment>
              <Col span={10}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-key br"
                >
                  {" "}
                  Time Stamp:{" "}
                </Paragraph>
              </Col>
              <Col span={13} offset={1}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-value br"
                >
                  {" "}
                  {created_date}{" "}
                </Paragraph>
              </Col>
            </React.Fragment>

            <React.Fragment>
              <Col span={10}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-key br"
                >
                  {" "}
                  Product Count:{" "}
                </Paragraph>
              </Col>
              <Col span={13} offset={1}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-value br"
                >
                  {" "}
                  {product_count}{" "}
                </Paragraph>
              </Col>
            </React.Fragment>

            <React.Fragment>
              <Col span={10}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-key br"
                >
                  {" "}
                  User:{" "}
                </Paragraph>
              </Col>
              <Col span={13} offset={1}>
                <Paragraph
                  ellipsis={{ row: "1", expandable: false }}
                  className="r-card-value br"
                >
                  {" "}
                  {user}{" "}
                </Paragraph>
              </Col>
            </React.Fragment>

            <React.Fragment>
              <Col span={24}>
                <Row gutter={[8, 8]}>
                  {/* <Col span={12}>
                    <Button style={{ width: "100%" }} type="primary">
                      Report
                    </Button>
                  </Col> */}

                  {
                    !this.props.viewReport &&

                    <Col span={8}>
                      <Link to={`/view-report/${pk}`}>
                        <Button
                          style={{ width: "100%" }}
                          icon="eye"
                          ghost
                          type="primary"
                          size="default"
                        >View</Button>
                      </Link>
                    </Col>

                  }

                  {
                    this.state.report_status == "In Progress" &&
                    < Col span={8}>
                      <Button
                        icon="reload"
                        ghost
                        type="primary"
                        size="default"
                        loading={this.state.loader}
                        onClick={() => this.refresh(pk)}
                      >Recheck</Button>
                    </Col>
                  }



                </Row>
              </Col>
            </React.Fragment>
          </Row>
        </Card>
      </React.Fragment >
    );
  }
}

export default ExportCards;
