import React, { Component } from 'react';
import {
    Row, Col, Typography, Button, Checkbox, Avatar, Icon, Tooltip, Tag, InputNumber, Select, Modal, Tabs, message,
    Dropdown, Menu
} from 'antd';
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import UserAvatar from "../../media/male_avatar.png"
import "./factory-products.scss"
import InputField from "../components/datainputs/InputField"
import PiDownloadForm from './PiDownloadForm';
import isDev from "../../server";
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import "./factory-products.scss"

const serverUrl = isDev();

const { Title, Text } = Typography;
const { TabPane } = Tabs;





export default class FactoryProducts extends Component {

    state = {
        total_products: null,
        selected: 0,
        is_select_all_db: false,
        is_select_all_op: false,
        selected_pk: {},
        products_pk: [1, 2],
        downloadPiModalVisible: false,
        formData: {},
        showMessages: false,
        selectedTab: "",
        selectedPK: null,
        isAvaialable : false,
        loadingInLoadMore : false,
        page : 1,
        tags : []
    }

    validator = new SimpleReactValidator({
        messages: {
            required: "Field is required",
            integer: "Must be number",
            default: "Validation has failed!" // will override all messages
        }
    });

   
    handleCheck = (pk, factory_pk, factory_name, factory_code) => {
        let map_pk = this.state.selected_pk[factory_pk] && this.state.selected_pk[factory_pk][pk];

        if (map_pk == undefined || map_pk == 0) {
            this.setState({
                selected_pk: {
                    ...this.state.selected_pk, [factory_pk]: {
                        ...this.state.selected_pk[factory_pk], [pk]: 1, factory_name, factory_code, total_from_this:
                            this.state.selected_pk[factory_pk] ? (this.state.selected_pk[factory_pk].total_from_this ? this.state.selected_pk[factory_pk].total_from_this + 1 : 1) : 1
                    }
                }, selected: this.state.selected + 1
            });
        } else {
            this.setState({
                selected_pk: {
                    ...this.state.selected_pk, [factory_pk]: {
                        ...this.state.selected_pk[factory_pk], [pk]: 0, factory_name, factory_code, total_from_this:
                            this.state.selected_pk[factory_pk] ? (this.state.selected_pk[factory_pk].total_from_this ? this.state.selected_pk[factory_pk].total_from_this - 1 : 0) : 0
                    }
                }, selected: this.state.selected - 1
            });
        }
    }

    handleChangeNumber = (factory_pk, pk, e, factory_name, factory_code, minimum_order_qty) => {
        let val = e;
        let inc = 0;

        val = Math.min(val, minimum_order_qty);

        if ((!this.state.selected_pk[factory_pk] && val > 0) ||
            (this.state.selected_pk[factory_pk] && !this.state.selected_pk[factory_pk][pk] && val > 0) ||
            (this.state.selected_pk[factory_pk] && this.state.selected_pk[factory_pk][pk] == 0 && val > 0))
            inc = 1;

        if (this.state.selected_pk[factory_pk] && this.state.selected_pk[factory_pk][pk] > 0 && val == 0)
            inc = -1;

        this.setState((prevstate) => ({
            selected: prevstate.selected + inc
        }))

        this.setState((prevstate) => ({
            selected_pk: {
                ...prevstate.selected_pk, [factory_pk]: {
                    ...prevstate.selected_pk[factory_pk], [pk]: val, factory_name, factory_code, total_from_this:
                        (prevstate.selected_pk[factory_pk] ? (prevstate.selected_pk[factory_pk].total_from_this ? (prevstate.selected_pk[factory_pk][pk] ? parseInt(prevstate.selected_pk[factory_pk].total_from_this) - parseInt(prevstate.selected_pk[factory_pk][pk]) + parseInt(val) : parseInt(prevstate.selected_pk[factory_pk].total_from_this) + parseInt(val)) : parseInt(val)) : parseInt(val))
                }
            }
        }));

    }

    handle_select_all_op = () => {
        if (this.state.is_select_all_op) {
            //unselect
            this.state.products_pk.map(e => {
                this.setState((prevstate) => ({ selected_pk: { ...prevstate.selected_pk, [e]: 0 } }))
            })
            this.setState({ selected: 0, is_select_all_op: false });
        }
        else {
            //select
            this.state.products.map(e => {
                let copy = this.state.selected_pk;
                if (!copy[e.factory_pk]) copy[e.factory_pk] = {};

                copy[e.factory_pk][e.product_uuid] = 1;
                copy[e.factory_pk].factory_name = e.factory_name;
                copy[e.factory_pk].factory_code = e.factory_code;
                if (!copy[e.factory_pk].total_from_this) copy[e.factory_pk].total_from_this = 1;
                else copy[e.factory_pk].total_from_this++;

                this.setState((prevState) => ({
                    selected_pk: copy,
                    selected: this.state.products.length,
                    is_select_all_op: true,
                }))
            })
            // this.state.products_pk.map(e => {
            //     this.setState((prevstate) => ({ selected_pk: { ...prevstate.selected_pk, [e]: prevstate.selected_pk[e] || 1 } }))
            // })
            // this.setState({ selected: this.state.products_pk.length, is_select_all_op: true });
        }
    }

    handlePiModalOk = (e) => {
        if (!this.validator.allValid()) {
            this.setState({
                showMessages: true
            })
            return;
        }
        this.setState({
            downloadPiModalVisible: false
        })
        let data = {
            "factory_list": []
        };
        let factory_list = Object.keys(this.state.formData);

        for (let i = 0; i < factory_list.length; i++) {
            let tempData = {};
            let factory_pk = factory_list[i];
            let factory_code = this.state.selected_pk[factory_pk]["factory_code"];
            let invoice_details = this.state.formData[factory_list[i]];
            let product_list = [];

            let products = Object.keys(this.state.selected_pk[factory_pk]);

            for (let j = 0; j < products.length; j++) {
                if (products[j] == "factory_name" || products[j] == "factory_code" || products[j] == "total_from_this") continue;

                product_list.push({
                    "uuid": products[j],
                    "quantity": this.state.selected_pk[factory_pk][products[j]]
                })
            }

            tempData = {
                factory_code,
                invoice_details,
                product_list
            }

            data["factory_list"].push(tempData);
        }

        //.log(data);

        fetch(`${serverUrl}/sourcing/create-bulk-pi/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {

                //.log(data);

                if (data.status === 200)
                    // window.open(data.filepath, "_blank")
                    message.success("PI is created Successfully!");
                    else if(data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                else
                    message.error("Something Went Wrong");
            });



    }

    handlePiModalCancel = (e) => {
        this.setState({
            downloadPiModalVisible: false
        })
    }


    handleLoadMore = () => {
        this.setState({
            loadingInLoadMore : true
        })
        let data = {
            "tags" : this.state.tags,
             "page" : this.state.page + 1,
            "factory_uuid" : this.props.factory_uuid
        };
            fetch(`${serverUrl}/sourcing/fetch-factory-product-list/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    loadingInLoadMore : false
                })
                if (data.status == 200) {
                    this.setState({
                            products : [...this.state.products , ...data.factory_product_list], 
                            total_products : data.total_products,
                            isAvaialable : data.is_available,
                            page : this.state.page + 1
                        })
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else
                {
                    message.error("Something went wrong");
                }
            });
    }

    fetchProductList = () => {
        let data = {
            "tags" : this.state.tags,
             "page" : 1,
            "factory_uuid" : this.props.factory_uuid
        };
            fetch(`${serverUrl}/sourcing/fetch-factory-product-list/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {

                if (data.status == 200) {
                    this.setState({
                            products : data.factory_product_list , 
                            total_products : data.total_products,
                            isAvaialable : data.is_available
                        })
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
            });
    }

    //without pi
    componentDidMount = () => {
        this.fetchProductList();
    }

    //with pi
    // componentDidMount = () => {

    //     let data = {
    //         "tags": [],
    //         "factory_pk": this.props.factory_pk
    //     };
    //     fetch(`${serverUrl}/sourcing/fetch-product-listing-for-pi/`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `JWT ${localStorage.getItem("token")}`
    //         },
    //         body: JSON.stringify(data)
    //     })
    //         .then(res => res.json())
    //         .then(data => {

    //             if (data.status == 200) {
    //                 this.setState({ products: data.product_list })
    //             }
    //         });

    // }

    handleChangeFormData = (value, name, factory_pk) => {
        this.setState((prevstate) => ({
            formData: {
                ...prevstate.formData, [factory_pk]: { ...prevstate.formData[factory_pk], [name]: value }
            }
        }))
    }

    // handle_select_all_db = () => {
    //     if(this.state.is_select_all_db)
    //     {
    //         this.state.
    //     }
    //     else
    //     {

    //     }
    // }

    handleDropDownClick = (e, pk, name) => {
        this.setState({
            selectedPK: pk,
            selectedTab: name
        })
    }

    handleChangeSearchTags = (tags) => {
        this.setState({
            tags
        }, () => this.fetchProductList())
    }


    render() {

        //.log(this.state, "state");

        const pageTitle = this.props.factory_pk ? (this.state.products ? `${this.state.products[0].factory_name}` : "Factory Products") : "Factory Products"

        let menu = Object.keys(this.state.selected_pk).map((key, index) => {

            if (this.state.selected_pk[key].total_from_this == 0) return null;

            return (
                <Menu.Item key={index} onClick={(e) => this.handleDropDownClick(e, key, this.state.selected_pk[key]["factory_name"])}>
                    {this.state.selected_pk[key]["factory_name"]}
                </Menu.Item>
            )
        })

        menu = (
            <Menu> {menu} </Menu>
        )


        return (
            <Row className="factory-products-page-container">
                <TitleWithBreadCrumb title={pageTitle} isTruncation={pageTitle !== "Factory Products"} breadcrumb={["OmnyComm", "Factories", "Factory products"]} />
                <Col span={24} className="factory-products-table-container" >
                    <Row className="top-nav">

                        <Col span={24}>
                            <Row gutter={[12, 12]} type="flex" align="middle">
                                <Col lg={3} sm={8}>
                                    {this.state.total_products != null ? (
                                        <Button style={{ width: "100%" }} type="primary" ghost>
                                            Results:{" "}
                                            {this.state.total_products}
                                        </Button>
                                    ) : (
                                            <Button type="primary" loading ghost></Button>
                                        )}
                                </Col>
                                {/* <Col lg={3} sm={8}>
                                  
                                    <Button style={{ width: "100%", borderColor: "#50C6E1", color: "#50C6E1" }} type="primary" ghost>
                                        Selected:{" "}
                                        {this.state.selected}
                                    </Button>
                                </Col> */}
                                {/* <Col lg = {3}>
                                    <Button icon = "filter" style={{ width: "100%"}} type="primary">
                                        filter
                                    </Button>
                                </Col> */}
                                <Col lg={17} sm={24}>
                                    <Select
                                        dropdownClassName="search-chips"
                                        dropdownMatchSelectWidth="false"
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder={[<Icon type="search" />, " Search here..."]}
                                        onChange={this.handleChangeSearchTags}
                                        value={this.state.tags}
                                    ></Select>
                                </Col>
                                {/* <Col lg={4} sm={8}>
                                    <Button
                                        type="danger"
                                        style={{ width: "100%" }}
                                        icon="form"
                                        onClick={() => this.setState({ downloadPiModalVisible: true })}
                                    >
                                        Create PI
                                    </Button>
                                </Col> */}
                                {
                                    this.props.factoryUser &&
                                    <Col lg={4} sm={8}>
                                    <Link to = {`/create-factory-product/${this.props.factory_uuid}`}>
                                    <Button
                                        type="danger"
                                        style={{ width: "100%" }}
                                        icon="plus"
                                    >
                                        Create New Product
                                    </Button>
                                    </Link>
                                </Col>
                                }
                               
                            </Row>
                        </Col>
                    </Row>
                    <Row className="factory-products-table">

                        <Col span={24}>
                            <Row className="table-head-row" gutter = {[24,0]} >
                                {/* {this.state.selected > 0 && (
                                    <Col span={24} style={{ marginBottom: "12px" }}>
                                        <Text style={{ color: "white" }}>
                                            Selected{" "}
                                            {this.state.selected}
                                        </Text>
                                        {(this.state.is_select_all_op || this.state.is_select_all_db) && (
                                    <Button
                                        style={{ marginLeft: "12px" }}
                                        size="small"
                                        type={this.state.is_select_all_db ? "primary" : ""}
                                        onClick={this.handle_select_all_db}
                                    >
                                        {" "}
                                        Select All Products{" "}
                                    </Button>
                                )}
                                    </Col>
                                )} */}
                                {/* <Col span={1}>
                                    <Checkbox onChange={this.handle_select_all_op} checked={this.state.is_select_all_op} />
                                </Col> */}
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Images
                                    </Text>
                                </Col>
                                <Col span={5}>
                                    <Text className="table-head-text">
                                        Product name
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <Text className="table-head-text">
                                        Manufacturer PN
                                    </Text>
                                </Col>
                                <Col span={3}>
                                    <Text className="table-head-text">
                                        Brand
                                    </Text>
                                </Col>
                                <Col span={5}>
                                    <Text className="table-head-text">
                                        Domain
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <Text className="table-head-text">
                                        MOQ
                                     </Text>
                                </Col>
                              
                            </Row>

                            <Row style = {{overflowY: "scroll" , height : "65vh"}} className = "factory-products-content">
                            {this.state.products && this.state.products.map((item, index) => {
                                return (
                                    <Link to = {`/edit-factory-product/${item.uuid}`}>
                                    <Row key={index} className="factory-products-row"  gutter = {[24 , 0]} style = {{padding : "10px 20px"}} type="flex" align="middle">
                                        {/* <Col span={1}>
                                            <Checkbox checked={this.state.selected_pk[item.factory_pk] && this.state.selected_pk[item.factory_pk][item.product_uuid]}
                                                onChange={() => this.handleCheck(item.product_uuid, item.factory_pk, item.factory_name, item.factory_code)} />
                                        </Col> */}
                                        <Col span={2}>
                                            <Avatar style = {{width : "100%" , height : "100%"}} shape="square" src={item.image} />
                                        </Col>
                                        <Col span={5}>
                                            <Row style={{ paddingRight: "20px" }}>
                                                <Col span={24}>
                                                    <Text className="text-color3 text-ellipsis">
                                                        {item.product_name}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4} style={{ paddingRight: "20px" }}>
                                            <Text className="text-ellipsis">
                                                {item.manufacturer_part_number}
                                            </Text>
                                        </Col>
                                        <Col span={3} style={{ paddingRight: "20px" }}>
                                            <Text className="text-ellipsis">
                                                {item.brand}
                                            </Text>
                                        </Col>
                                        <Col span={5}>
                                            <Row style={{ paddingRight: "20px" }}>
                                                <Col span={24}>
                                                    <Text className="text-ellipsis">
                                                        {item.category}
                                                    </Text>
                                                </Col>
                                                <Col span={24}>
                                                    <Text className="text-ellipsis">
                                                        {item.sub_category}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4} style={{ paddingRight: "20px" }}>
                                            <Text className="text-ellipsis">
                                                {item.moq}
                                            </Text>
                                        </Col>
                                    </Row>
                                    </Link>
                                )
                            })}
                            </Row>

                            {
                                this.state.isAvaialable && 
                                <div className="center-child" style={{ width: "100%" }}>
                                    <Button
                                        type="primary"
                                        loading={this.state.loadingInLoadMore}
                                        onClick={this.handleLoadMore}
                                        >
                                        {this.state.loadingInLoadMore ? "" : "Load More..."}
                                    </Button>
                              </div>
                             }
                        </Col>
                    </Row>
                </Col>

                <Modal
                    title="Fill the Information"
                    visible={this.state.downloadPiModalVisible}
                    onCancel={this.handlePiModalCancel}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={this.handlePiModalCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handlePiModalOk}>
                            Create
                        </Button>,
                    ]}
                    className="pi-modal"

                >
                    <div style={{ maxHeight: "60vh", overflow: "scroll", padding: "20px" }}>



                        <Dropdown.Button overlay={menu}>
                            <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {this.state.selectedTab ? this.state.selectedTab : "Choose the Factory"}
                            </span>
                        </Dropdown.Button>

                        {this.state.selectedPK &&
                            <div style={{ marginTop: "10px" }}>
                                <PiDownloadForm showMessages={this.state.showMessages} validator={this.validator} factory_pk={this.state.selectedPK} handleChangeFormData={this.handleChangeFormData} formData={this.state.formData} />
                            </div>
                        }


                        {/* <Tabs >
                            {
                                Object.keys(this.state.selected_pk).map((key, index) => {
                                    return (
                                        <TabPane
                                            tab={
                                                <span>
                                                    {this.state.selected_pk[key]["factory_name"]}
                                                </span>
                                            }
                                            key={key}
                                            style={{ padding: "20px" }}
                                        >
                                            <PiDownloadForm showMessages={this.state.showMessages} validator={this.validator} factory_pk={key} handleChangeFormData={this.handleChangeFormData} formData={this.state.formData} />
                                        </TabPane>)
                                })}
                        </Tabs> */}


                    </div>

                </Modal>
            </Row>
        )
    }
}
