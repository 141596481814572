import React, { Component } from "react";
import { Collapse, Icon, Row, Col, Typography } from "antd";
import "./varients-info.scss";
import InputField from "../../components/datainputs/InputField";
import InputNumberDimensions3 from "../../components/datainputs/InputNumberDimesions3";

import { connect } from "react-redux";
import { handelEditProductDetails } from "../../../actions/index";
import MyEditor from "../../components/datainputs/MyEditor";
import EditorBox from "../../components/EditorBox";

const { Panel } = Collapse;
const { Text } = Typography;

class VarientsInfoAttributes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  customPanelStyle = {
    borderRadius: 4,
    // marginBottom: 24,
    border: 0,
    overflow: "hidden"
    // padding: "10px 0px 10px 0px",
  };

  collapsedStyle = {
    maxHeight: "80vh",
    overflow: "scroll",
    paddingTop: "5px"
  };

  collapsBlock = {
    // margin: "10px 0px 10px 0px"
  };

  handleChange = e => {
    if(this.props.getInfo)
    {
      this.props.getInfo({
        ...this.props.productDetailsFromParent,
        [e.target.name]: e.target.value
      });
    }
    else{
      this.props.handelEditProductDetails({
        ...this.props.productDetails,
        [e.target.name]: e.target.value
      });
    }
   
    this.props.validator.showMessages();
  };

  getEditorText = value => {
    if(this.props.getInfo)
    {
      this.props.getInfo({
        ...this.props.productDetailsFromParent,
        ["factory_notes"]: value
      });
    }
    else
    { 
      this.props.handelEditProductDetails({
        ...this.props.productDetails,
        ["factory_notes"]: value
      });
    }
  };

  render() {
    const {
      color,
      quantity,
      color_map,
      material_type,
        size,
        size_unit,
        capacity,
        capacity_unit,
        target_age_range,
      factory_notes,
      weight
    } = this.props.productDetailsFromParent || this.props.productDetails;

    console.log(this.props.productDetails);

    return (
      <React.Fragment>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header="Attributes : Variant"
            key="3"
            style={this.customPanelStyle}
          >
            <Row gutter={[24, 24]} style={this.collapsedStyle}>
              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  impo = {this.props.color_impo}
                  errormsg={
                    this.props.validator && this.props.color_impo &&
                    this.props.validator.message(
                      "color",
                      color,
                      "required"
                    )
                  }
                  input={{
                    size: "large",
                    name: "color",
                    value: color,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handleChange}
                  labelText="Color"
                />
              </Col>
              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  input={{
                    size: "large",
                    name: "color_map",
                    value: color_map,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handleChange}
                  labelText="Color Map"
                />
              </Col>
              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  input={{
                    size: "large",
                    name: "weight",
                    value: weight,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handleChange}
                  labelText="Weight (KG)"
                />
              </Col>
              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  impo = {this.props.material_type_impo}
                  errormsg={
                    this.props.validator && this.props.material_type_impo &&
                    this.props.validator.message(
                      "material_type",
                      material_type,
                      "required"
                    )
                  }
                  input={{
                    size: "large",
                    name: "material_type",
                    value: material_type,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handleChange}
                  labelText="Material Type"
                />
              </Col>
              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  errormsg={
                    this.props.validator &&
                    this.props.validator.message(
                      "quantity",
                      quantity,
                      "integer"
                    )
                  }
                  impo = {this.props.moq_impo}
                  errormsg={
                    this.props.validator && this.props.moq_impo &&
                    this.props.validator.message(
                      "quantity",
                      quantity,
                      "required|integer"
                    )
                  }
                  input={{
                    size: "large",
                    name: "quantity",
                    value: quantity,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handleChange}
                  labelText="MOQ"
                />
              </Col>
              <Col sm={12}>
                <InputField
                    label={{ type: "secondary" }}
                    input={{
                      size: "large",
                      name: "target_age_range",
                      value: target_age_range,
                      disabled: this.props.disabled
                    }}
                    onChange={this.handleChange}
                    labelText="Target Age Range"
                />
              </Col>

              <Col sm={12}>
                <InputField
                    label={{ type: "secondary" }}
                    input={{
                      size: "large",
                      name: "size",
                      value: size,
                      disabled: this.props.disabled
                    }}
                    onChange={this.handleChange}
                    labelText="Size"
                />
              </Col>

              <Col sm={12}>
                <InputField
                    label={{ type: "secondary" }}
                    input={{
                      size: "large",
                      name: "size_unit",
                      value: size_unit,
                      disabled: this.props.disabled
                    }}
                    onChange={this.handleChange}
                    labelText="Size Unit"
                />
              </Col>

              <Col sm={12}>
                <InputField
                    label={{ type: "secondary" }}
                    input={{
                      size: "large",
                      name: "capacity",
                      value: capacity,
                      disabled: this.props.disabled
                    }}
                    onChange={this.handleChange}
                    labelText="Capacity"
                />
              </Col>

              <Col sm={12}>
                <InputField
                    label={{ type: "secondary" }}
                    input={{
                      size: "large",
                      name: "capacity_unit",
                      value: capacity_unit,
                      disabled: this.props.disabled
                    }}
                    onChange={this.handleChange}
                    labelText="Capacity Unit"
                />
              </Col>

              <Col sm={24}>
                {/* <InputField impo label={{ type: "secondary" }} input={{ size: "large", name: "factory_notes", value: factory_notes }} onChange={this.handleChange} labelText="Factory Notes" /> */}
                <Text className="editorbox-label">Factory Notes</Text>
                <EditorBox
                  disabled={this.props.disabled}
                  value={factory_notes}
                  getText={this.getEditorText}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails
  };
};

export default connect(mapStateToProps, { handelEditProductDetails })(
  VarientsInfoAttributes
);
