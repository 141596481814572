import React, { useEffect, useMemo, useState } from 'react'
import { Spin, message, Menu, Dropdown, Table, Tag } from 'antd'
import { DownOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import axios from 'axios'
import styles from './SapPullProduct.module.scss'
import isDev from '../../server.js'

const serverUrl = isDev()
function SapPullProducts({ baseProductPK }) {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(undefined)


    useEffect(() => {
        if (localStorage.getItem("token") && baseProductPK) {
            setLoading(true)
            let body = {
                "base_product_pk": baseProductPK.toString(),
            }

            axios.post(`${serverUrl}/sap/fetch-sap-attributes/`, body, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem("token")}`,

                    "Content-Type": "application/json",
                }
            }).then((response) => {
                if (response.data?.status === 200) {
                    setData(response.data)
                } else {
                    throw new Error('Response Status not OK')
                }
            }).catch((e) => {
                setData(undefined)
            }).finally(() => {
                setLoading(false)
            })

        }
        else{
            setLoading(false);
        }
    }, [baseProductPK])

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Spin />
            </div>
        )
    }

    if (!baseProductPK || !localStorage.getItem("token") || !data) {
        return <NothingSelected placeholder="No SAP data found" />
    }



    if (!data?.sapAttributeSetCodes || !data?.sapAttributeSetObjs || !data?.sapCertificateCodes || !data?.sapCertificateObjs) {
        return <NothingSelected placeholder="Some or more SAP data not found" />
    }

    return (
        <>
            <DisplayData heading={"SAP Attributes"} codes={data?.sapAttributeSetCodes} values={data?.sapAttributeSetObjs} />
            <DisplayData heading={"SAP Certificates"} codes={data?.sapCertificateCodes} values={data?.sapCertificateObjs} />
        </>
    )
}

export default SapPullProducts


const defaultSelectedDropdown = 'Selected None'
function DisplayData({ heading, codes, values }) {

    const [selectedDropdown, setSelectedDropdown] = useState(defaultSelectedDropdown)

    const dropdownOptions = useMemo(() => {
        return [defaultSelectedDropdown, ...Object.keys(values)]
    }, [values])

    const data = useMemo(() => {

        if (selectedDropdown === defaultSelectedDropdown) {
            return []
        }

        let temp = []
        let counter = 0
        for (let k in values[selectedDropdown]) {
            if (k === 'uuid') continue
            temp.push({
                key: counter++,
                name: k,
                value: values[selectedDropdown][k],
                code: codes[k]
            })
        }

        return temp

    }, [selectedDropdown, values, codes])

    if (!codes || !values) {
        return <></>
    }

    const onClick = ({ key }) => {
        message.info(`Table Updated`);
        setSelectedDropdown(key)
    };

    const menu = (
        <Menu onClick={onClick}>
            {dropdownOptions.map((val) => <Menu.Item key={val}>{val}</Menu.Item>)}
        </Menu>
    );


    return (
        <div className={styles.card} >
            <div className={styles.header} >
                <p className={styles.heading}>{heading}</p>
                {(Object.keys(values).length !== 0) && <Dropdown overlay={menu} className={styles.dropdown} >
                    <span className={styles.selectedDropdown} >
                        {selectedDropdown} <DownOutlined />
                    </span>
                </Dropdown>}
            </div>

            {(selectedDropdown === defaultSelectedDropdown) ?
                <NothingSelected placeholder={(Object.keys(values).length === 0) ? 'No Data Found' : undefined} /> :
                <Table dataSource={data} className={styles.dataTable} >
                    <Table.Column title="Name" dataIndex="name" />
                    <Table.Column title="Value" dataIndex="value" />
                    <Table.Column title="Code" dataIndex="code" />
                </Table>
            }
        </div>
    )
}

function NothingSelected({ placeholder }) {
    return (
        <div className={styles.nothingCard} >
            <EyeInvisibleOutlined className={styles.eye} />
            <p className={styles.text} >{placeholder ?? `Select from the dropdown to view data`}</p>
        </div>
    )
}