import React, { useState, useEffect } from "react"

import RightWrapper from "../../my-components/ui-components/RightWrapper"
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb"
import { Ellipsis } from "react-spinners-css";
import { Card, Row, Col, Divider, Button, message, Tag, Icon, Badge, Modal, Typography } from "antd";
import isDev from "../../server";

const serverUrl = isDev();

const {Text} = Typography;

const ReportsCard = ({ report }) => {

    const [modal, setModal] = useState(false);

    const handleOkNoteModal = () => {
        setModal(false);
    }

    const handleCancelReportsModal = () => {
        setModal(false)
    }

    return (
        <React.Fragment>
            <Card
                title={report.name}
                extra={report.created_date}
                bordered={true}
                className="export-card"
            >
                <Divider style={{ margin: "0px" }} />

                <Row style={{ padding: "10px 0px 0px 0px", height: "50px" }}>
                    <Col span={12}>Created By:</Col>
                    <Col className="text-color3 " span={12}>
                        {report.created_by}
                    </Col>

                    {report.is_processed &&
                        <React.Fragment>
                            <Col span={12}>Date Completed:</Col>
                            <Col className="text-color3 " span={12}>
                                {report.completion_date}
                            </Col>
                        </React.Fragment>
                    }


                </Row>

                <Row
                    style={{ marginTop: "10px" }}
                    gutter={[12, 12]}
                    type="flex"
                    justify="space-between"
                >
                    {report.is_processed ?
                        <Col>
                            <Tag color="green">Completed</Tag>
                        </Col>
                        :
                        <Col>
                            <Tag color="gold">Under Processing</Tag>
                        </Col>
                    }


                    <Col>
                        <Button.Group>
                            <Button
                                type="primary"
                                onClick={() => {
                                    window.open(report.filename)
                                }}
                                size="small"
                                icon="download"
                                disabled={!report.is_processed}
                            >

                            </Button>

                            <Button
                                type="primary"
                                icon="edit"
                                size="small"
                                onClick={() => setModal(true)}
                            >
                            </Button>

                        </Button.Group>
                    </Col>


                </Row>
            </Card >


            <Modal
                title="Note"
                visible={modal}
                onOk={handleOkNoteModal}
                onCancel={handleCancelReportsModal}
                width="30%"
                style={{display: "flex", justifyContent: "center"}}
            >
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Text style={{fontWeight: "bold"}}>Report Type: <Text style={{fontWeight: "normal", color: "white"}}>{report.note.report_type}</Text></Text>
                    <Text style={{fontWeight: "bold"}}>Note: <Text style={{fontWeight: "normal", color: "white"}}>{report.note.note}</Text></Text>
                    <Text style={{fontWeight: "bold"}}>Brand List: <Text style={{fontWeight: "normal", color: "white"}}>{report.note.brand_list === "all" ? "All Brands" : report.note.brand_list?.toString()}</Text></Text>
                    <Text style={{fontWeight: "bold"}}>From Date: <Text style={{fontWeight: "normal", color: "white"}}>{report.note.from_date}</Text></Text>
                    <Text style={{fontWeight: "bold"}}>To Date: <Text style={{fontWeight: "normal", color: "white"}}>{report.note.to_date}</Text></Text>
                </div>

            </Modal>

        </React.Fragment>
    )
}

const InnerWrapper = ({ reports }) => {
    return (
        <React.Fragment>
            <Row gutter={[12, 12]} style={{
                padding: "20px",
            }}>
                {reports && reports.map((item, index) => {
                    return (
                        <Col key={index} sm={8}>
                            <ReportsCard report={item} />
                        </Col>
                    )
                })}

            </Row>
        </React.Fragment>
    )
}

export default function ReportsPageGeneralized(props) {

    const [reports, setReports] = useState(null);
    const [isAvailable, setIsAvailable] = useState(true);
    const [availableLoading, setAvailableLoading] = useState(false);
    const [page, setPage] = useState(1);

    console.clear()
    console.log(props, 'this are the props')

    useEffect(() => {
        let data = {
            page,
            locationGroupUuid: props.locationUuid
        };
        fetch(`${serverUrl}/fetch-oc-report-list/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                //.log(data, "ssdsds");
                console.log(data);
                if (data.status == 200) {
                    setIsAvailable(data.is_available);
                    setReports(data.oc_report_list);
                }
                else {
                    message.error("Something Went Wrong!");
                }
            });

    }, [props.locationUuid])

    const loadMore = () => {
        setAvailableLoading(true);
        let data = {
            page: page + 1
        };
        fetch(`${serverUrl}/fetch-oc-report-list/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                setPage(page + 1);
                setAvailableLoading(false);
                //.log(data, "ssdsds");
                if (data.status == 200) {
                    setIsAvailable(data.is_available);
                    setReports([...reports, ...data.oc_report_list]);
                }
                else {
                    message.error("Something Went Wrong!");
                }
            });

    }

    if (reports == null) {
        return (
            <React.Fragment>
                <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    style={{ width: "100%", height: "100%" }}
                >
                    <Ellipsis color="#1ABC9C" size={100} />
                </Row>
            </React.Fragment>
        )

    }

    return (
        <React.Fragment>
            <RightWrapper
                Upper={<TitleWithBreadCrumb
                    title="Reports"
                    breadcrumb={["OmnyComm", "Reports"]}
                />}
                InnerWrapper={
                    <React.Fragment>
                        <InnerWrapper reports={reports} />

                        {
                            isAvailable &&
                            <Row type="flex" justify="center">
                                <Col style={{ marginBottom: "20px" }}>
                                    <Button type="primary" icon={availableLoading ? "loading" : ""} onClick={() => {
                                        loadMore();
                                    }}>{availableLoading ? "" : "Load More"}</Button>
                                </Col>
                            </Row>
                        }


                    </React.Fragment>
                }
            />
        </React.Fragment>
    )
}