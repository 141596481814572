/**
 * @description This is the initialState of dealsHubProducts reducer
 * @property {object} dealsHubProduct - All required product details and filters of dealshub.
 */
const initialState = {
  dealsHubProduct: {
    products: [],
    listToExport: [],
    filters: {},
  },
};
/**
 * @description This is redux reducer for fetching dealshub 
 * products and handling change in filter applied
 * changes/updates state  for different cases like 
 * fetching products , handling filter change etc..
 * @typedef StateType - state type of dealsHubProducts reducer, default state is initial state
 * @param {object} state
 * @param {object} action
 * @returns {StateType} returns updated state
 */
export default function dealsHubProducts(state = initialState, action) {
  switch (action.type) {
    case "FETCH_DEALS_HUB_PRODUCT":
      return {
        ...state,
        dealsHubProduct: { ...state.dealsHubProduct, products: action.payload },
      };

    case "FETCH_DEALS_HUB_PRODUCT_MORE":
      return {
        ...state,
        dealsHubProduct: {
          ...state.dealsHubProduct,
          products: {
            ...state.dealsHubProduct.products,
            products: [
              ...state.dealsHubProduct.products.products,
              ...action.payload.products,
            ],
            is_available: action.payload.is_available,
          },
        },
      };

    case "HANDLE_FILTERS_CHANGE":
      return {
        ...state,
        dealsHubProduct: {
          ...state.dealsHubProduct,
          filters: action.payload,
        },
      };

    default:
      return state;
  }
}
