import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Form,
  Tabs,
  Select,
  Affix,
  Spin,
  Icon,
  message,
  Popover,
  Switch,
  Empty,
  Modal,
  Radio
} from "antd";
import { NestoFormCol, NestoFormRow } from "./styled-components-library";
import "./product-details.scss";
import EditorBox from "../components/EditorBox";
import ProductBasket from "../components/product-basket/ProductBasket";
import ImageManagerNesto from "../components/datainputs/image-manager/ImageManagerNesto";
import SelectAfter from "../components/datainputs/SelectAfter";
import { connect } from "react-redux";
import { fetchImagesDetails } from "../../actions/index";
import isDev from "../../server";
import { countryList } from "../../constants/country_codes";
import { useHistory } from "react-router-dom";
import placeHolderImage from "media/placeholder3.png";
import productDetails from "reducers/editProductDetails";
import axios from "axios";

const serverUrl = isDev();

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const dimensionUnits = ["FT", "IN", "CM", "MM", "M"];

const ProductDetails = (props) => {
  let history = useHistory();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [productDetails, setProductDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [imagesUpdated, setImagesUpdated] = useState(false);
  const [basketProducts, setBasketProducts] = useState({});
  const [productsUpdated, setProductsUpdated] = useState(false);
  const [info, setInfo] = useState("not ecommerce");
  const [superCategoryList, setSuperCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [primaryTags, setPrimaryTags] = useState([]);
  const [secondaryTags, setSecondaryTags] = useState([]);
  const [categories, setCategories] = useState({
    super_category: null,
    category: null,
    sub_category: null,
  });
  const [productBrand, setProductBrand] = useState({});
  const [createStoreModal, setCreateStoreModal] = useState(false);
  const [newStore, setNewStore] = useState({});
  const [sellerList, setSellerList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(true);

  const onCategorySelect = (type, value) => {
    let data, url, uuid;
    if (type === "Sub") {
      uuid = subCategoryList.filter((item) => item.name === value);
      setCategories({ ...categories, sub_category: value });
      setProductDetails({ ...productDetails, sub_category_uuid: uuid[0].uuid });
    } else {
      if (type === "Super") {
        uuid = superCategoryList.filter((item) => item.name === value);
        url = "admin/fetch-admin-categories";
        data = {
          super_category_uuid: uuid[0].uuid,
          organization: "Nesto Group",
        };
      } else {
        uuid = categoryList.filter((item) => item.name === value);
        url = "admin/fetch-admin-sub-categories";
        data = {
          category_uuid: uuid[0].uuid,
          organization: "Nesto Group",
        };
      }
      fetch(`${serverUrl}/${url}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (type === "Super") {
            setCategories({...categories, super_category: value, category: null, sub_category: null})
            setCategoryList([...data.categoryList]);
          } else {
            setCategories({...categories,category: value, sub_category: null})
            setSubCategoryList([...data.subCategoryList]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleSave = () => {
    setLoading(true);
    if (props.createProduct) {
      let product = {
        article_number: productDetails?.article_number
          ? productDetails?.article_number
          : "",
        product_name: productDetails?.product_name
          ? productDetails?.product_name
          : "",
        product_name_ar: productDetails?.product_name_ar
          ? productDetails?.product_name_ar
          : "",
        product_name_ecommerce: productDetails?.product_name_ecommerce
          ? productDetails?.product_name_ecommerce
          : "",
        barcode: productDetails?.barcode ? productDetails?.barcode : "",
        uom: productDetails?.uom ? productDetails?.uom : "",
        language_key: productDetails?.language_key
          ? productDetails?.language_key
          : "",
        brand: productDetails?.brand ? productDetails?.brand : "",
        weight_volume: productDetails?.weight_volume
          ? productDetails?.weight_volume
          : "",
        country_of_origin: productDetails?.country_of_origin
          ? productDetails?.country_of_origin
          : "",
        highlights: productDetails?.highlights
          ? productDetails?.highlights
          : "",
        storage_condition: productDetails?.storage_condition
          ? productDetails?.storage_condition
          : "",
        storage_condition_ar: productDetails?.storage_condition_ar
          ? productDetails?.storage_condition_ar
          : "",
        preparation_and_usage: productDetails?.preparation_and_usage
          ? productDetails?.preparation_and_usage
          : "",
        preparation_and_usage_ar: productDetails?.preparation_and_usage_ar
          ? productDetails?.preparation_and_usage_ar
          : "",
        allergic_information: productDetails?.allergic_information
          ? productDetails?.allergic_information
          : "",
        allergic_information_ar: productDetails?.allergic_information_ar
          ? productDetails?.allergic_information_ar
          : "",
        product_description: productDetails?.product_description
          ? productDetails?.product_description
          : "",
        product_description_ar: productDetails?.product_description_ar
          ? productDetails?.product_description_ar
          : "",
        dimensions: productDetails?.dimensions
          ? productDetails?.dimensions
          : "",
        nutrition_facts: productDetails?.nutrition_facts
          ? productDetails?.nutrition_facts
          : "",
        ingredients: productDetails?.ingredients
          ? productDetails?.ingredients
          : "",
        ingredients_ar: productDetails?.ingredients_ar
          ? productDetails?.ingredients_ar
          : "",
        return_days: productDetails?.return_days
          ? productDetails?.return_days
          : "",
        product_status: productDetails?.product_status
          ? productDetails?.product_status
          : "not ecommerce",
        sub_category_uuid: productDetails?.sub_category_uuid
          ? productDetails?.sub_category_uuid
          : "",
        is_verified: productDetails?.is_verified
          ? productDetails?.is_verified
          : false,
        is_online: productDetails?.is_online
          ? productDetails?.is_online
          : false,
        vendor_category: productDetails?.vendor_category
          ? productDetails?.vendor_category
          : "",
        primary_keywords: primaryTags ? primaryTags : [],
        secondary_keywords: secondaryTags ? secondaryTags : [],
        
      };
      fetch(`${serverUrl}/create-nesto-product/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(product),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.status === 200) {
            message.success("Product Created Successfully!");
            //window.location.replace("/")
            history.replace(`/edit-product/${data.product_uuid}`);
          } else {
            message.error("Something went Wrong!");
          }
        });
    } else {
      let updateProductDetails = {
        ...productDetails,
        product_uuid: props.productPk,
        product_status: info,
        primary_keywords: primaryTags,
        secondary_keywords: secondaryTags,
      };
      fetch(`${serverUrl}/update-nesto-product/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(updateProductDetails),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          message.success("Product Updated Successfully!");
        })
        .catch((e) => {
          message.error("Something went Wrong!");
        });
    }
  };

  useEffect(() => {
    fetch(`${serverUrl}/admin/fetch-admin-super-categories/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        organization: "Nesto Group",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSuperCategoryList([...data.superCategoryList]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (productDetails.super_category_uuid) {
      fetch(`${serverUrl}/admin/fetch-admin-categories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          super_category_uuid: productDetails.super_category_uuid,
          organization: "Nesto Group",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setCategoryList([...data.categoryList]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [productDetails.super_category_uuid]);

  useEffect(() => {
    if (productDetails.category_uuid) {
      fetch(`${serverUrl}/admin/fetch-admin-sub-categories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          category_uuid: productDetails.category_uuid,
          organization: "Nesto Group",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setSubCategoryList([...data.subCategoryList]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [productDetails.category_uuid]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (imagesUpdated) {
      let passedProductPK = { product_uuid: props.productPk };
      fetch(`${serverUrl}/fetch-nesto-product-details/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(passedProductPK),
      })
        .then((res) => res.json())
        .then((data) => {
          //delete data.status;
          setProductDetails({ ...productDetails, images: data.images });
          setImagesUpdated(false);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  }, [imagesUpdated]);

  useEffect(() => {
    if (productsUpdated) {
      let passedProductPK = { product_uuid: props.productPk };
      fetch(`${serverUrl}/fetch-linked-nesto-products/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(passedProductPK),
      })
        .then((res) => res.json())
        .then((data) => {
          setBasketProducts(data.linked_nesto_products);
          setProductsUpdated(false);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  }, [productsUpdated]);

  useEffect(() => {
    let passedProductPK = { product_uuid: props.productPk };
    fetch(`${serverUrl}/fetch-linked-nesto-products/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(passedProductPK),
    })
      .then((res) => res.json())
      .then((data) => {
        setBasketProducts(data.linked_nesto_products);
        setProductsUpdated(false);
      })
      .catch((e) => {
        //console.log(e);
      });
  }, []);

  useEffect(() => {
    setPageLoading(true);
    //console.log(props, "PROPS")
    let passedProductPK = { product_uuid: props.productPk };
    fetch(`${serverUrl}/fetch-nesto-product-details/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(passedProductPK),
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data);
        setPageLoading(false);
        delete data.status;
        setProductDetails(data);
        setCategories({
          super_category: data?.super_category,
          category: data?.category,
          sub_category: data?.sub_category,
        });
        setProductBrand({brand: data?.brand, logo: data?.logo, about_brand: data?.about_brand});
        setPrimaryTags(data?.primary_keywords);
        setSecondaryTags(data?.secondary_keywords);
      })
      .catch((e) => {
        //console.log(e);
      });
  }, [props.productPk]);

  useEffect(() => {
    setPageLoading(true);
    fetch(`${serverUrl}/fetch-nesto-store-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPageLoading(false);
        setSellerList(data.stores_list);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`${serverUrl}/fetch-nesto-brands/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBrandList([...data.brand_list]);
      });
  }, []);

  const handleChangePrimaryTags = (e) =>{
    setPrimaryTags(e);
  }
  const handleChangeSecondaryTags = (e) =>{
    setSecondaryTags(e);
  }

  const help = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Text style={{ color: "red", display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: 10,
            width: 10,
            borderRadius: "100%",
            backgroundColor: "red",
            marginRight: 8,
          }}
        />{" "}
        <Text>Basic</Text>
      </Text>
      <Text style={{ color: "#009429", display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: 10,
            width: 10,
            borderRadius: "100%",
            backgroundColor: "#009429",
            marginRight: 8,
          }}
        />{" "}
        <Text>Ecommerce Ready</Text>
      </Text>
      <Text style={{ color: "#0066FF", display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: 10,
            width: 10,
            borderRadius: "100%",
            backgroundColor: "#0066FF",
            marginRight: 8,
          }}
        />{" "}
        <Text>Information Rich</Text>
      </Text>
    </div>
  );

  useEffect(() => {
    if (checkRichProduct(productDetails)) {
      setInfo("rich");
      //setProductDetails({...productDetails, product_status: "Rich Product"})
    } else if (checkEcommerceReady(productDetails)) {
      setInfo("ecommerce");
      //setProductDetails({...productDetails, product_status: "E-commerce Ready"})
    }
    // else if(checkBasicInfo(productDetails)) {
    //     setInfo("Added Basic Info");
    //     setProductDetails({...productDetails, product_status: "Added Basic Info"})
    // }
    else {
      setInfo("not ecommerce");
      //setProductDetails("Not E-commerce Ready")
    }
  }, [productDetails]);

  useEffect(() => {
    setProductDetails({ ...productDetails, product_status: info });
  }, [info]);

  const checkBasicInfo = (productDetails) =>
    productDetails?.barcode &&
    productDetails?.product_name &&
    productDetails?.article_number
      ? true
      : false;

  const handleBrandSelect = (value, e) =>{
    if(value !== productDetails.brand){
      axios.post(`${serverUrl}/fetch-nesto-brand-details/`, {
          pk :e.key,
        }, 
        {
          headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem(
                  "token"
              )}`,
          },
        }).then((res) => {
          setProductBrand({
            logo: res.data.logo, 
            brand: value, 
            about_brand: res.data.about_brand
          });
          setProductDetails({
            ...productDetails,
            brand: value,
          });
        }
      ).catch((e) => {
        setProductDetails({
          ...productDetails,
          brand: value,
        });
        setProductBrand({...productBrand, brand :value});
      })
    }
  }

  const checkEcommerceReady = (productDetails) =>
    productDetails?.barcode &&
    productDetails?.product_name &&
    productDetails?.product_name_ecommerce &&
    productDetails?.brand &&
    productDetails?.language_key &&
    productDetails?.weight_volume &&
    productDetails?.country_of_origin &&
    productDetails?.storage_condition &&
    productDetails?.allergic_information &&
    productDetails?.nutrition_facts !== "<p><br></p>" &&
    productDetails?.nutrition_facts &&
    productDetails?.ingredients &&
    productDetails?.return_days &&
    productDetails?.article_number
      ? true
      : false;

  const checkRichProduct = (productDetails) =>
    productDetails?.barcode &&
    productDetails?.product_name &&
    productDetails?.product_name_ecommerce &&
    productDetails?.brand &&
    productDetails?.language_key &&
    productDetails?.weight_volume &&
    productDetails?.country_of_origin &&
    productDetails?.storage_condition &&
    productDetails?.allergic_information &&
    productDetails?.nutrition_facts !== "<p><br></p>" &&
    productDetails?.nutrition_facts &&
    productDetails?.ingredients &&
    productDetails?.return_days &&
    productDetails?.uom &&
    productDetails?.about_brand &&
    productDetails?.product_description &&
    productDetails?.article_number
      ? true
      : false;
  useEffect(() => {
    if (superCategoryList.length < 1) {
      setCategories({ ...categories, super_category: "" });
    }
  }, [superCategoryList]);

  useEffect(() => {
    if (categoryList.length < 1) {
      setCategories({ ...categories, category: "" });
    }
  }, [categoryList]);

  useEffect(() => {
    if (subCategoryList.length < 1) {
      setCategories({ ...categories, sub_category: "" });
    }
  }, [subCategoryList]);
  return pageLoading ? (
    <div className="loader-container">
      <Spin indicator={antIcon} tip="Fetching Product Details" />
    </div>
  ) : (
    <Row className="product-details-form">
      <Form colon={false}>
        <div>
          <Title
            style={{
              color: "white",
              marginTop: "1em",
              fontSize: 20,
              fontWeight: "bold",
              display: "flex",
            }}
            level={3}
          >
            Product Information
            {(info === "not ecommerce" ||
              info === "ecommerce" ||
              info === "rich") && (
              <div
                style={{
                  borderRadius: 5,
                  backgroundColor: "white",
                  letterSpacing: "normal",
                  fontSize: 14,
                  fontWeight: "700",
                  marginLeft: 35,
                  display: "flex",
                  alignItems: "center",
                  padding: "0 10px",
                  color:
                    info === "rich"
                      ? "#0066FF"
                      : info === "ecommerce"
                      ? "#009429"
                      : "red",
                }}
              >
                <div
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: "100%",
                    backgroundColor:
                      info === "rich"
                        ? "#0066FF"
                        : info === "ecommerce"
                        ? "#009429"
                        : "red",
                    marginRight: 8,
                  }}
                />
                {info === "rich"
                  ? "Rich Product"
                  : info === "ecommerce"
                  ? "E-commerce Ready"
                  : "Not E-commerce Ready"}
              </div>
            )}
            <Popover placement="bottomRight" content={help}>
              <Icon
                type="question-circle"
                style={{
                  height: 22,
                  cursor: "pointer",
                  position: "absolute",
                  right: 0,
                }}
              />
            </Popover>
          </Title>
          <Row type='flex' style={{justifyContent:'flex-end', marginBottom:'5px'}}>
            <Text
                style={{
                    fontWeight: '500',
                    color: '#FFFFFF',
                    marginRight: "25px",
                }}>
                Language:
            </Text>
            <Radio.Group
              style={{
                  // width: "100%",
                  display: "inline",
              }}
              value={isEnglishLanguage}
              onChange={(e) => {
                setIsEnglishLanguage(e.target.value);
              }}
              >
              <Radio
                  value={true}
                  style={{
                      color: '#FFFFFF',
                      display: "inline",
                      marginRight: "5px",
                  }}>
                  en
              </Radio>
              <Radio
                  value={false}
                  style={{
                      color: '#FFFFFF',
                      display: "inline",
                  }}>
                  ar
              </Radio>
            </Radio.Group>
          </Row>
        </div>
        {
        isEnglishLanguage ? (
        <Row gutter={[0, 0]}>
          <Col span={15} style={{ paddingRight: "10px" }}>
            <NestoFormCol span={24}>
              <Col span={24}>
                <Form.Item
                  label="Product Name for E-Commerce"
                  name="product-name-ecommerce"
                  style={{ color: "white", fontWeight: "lighter" }}
                  className="ecommerce-info"
                >
                  <Input
                    className="input-field"
                    style={{
                      color: "#b6b6b6",
                      marginTop: "5px",
                    }}
                    value={productDetails?.product_name_ecommerce}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        product_name_ecommerce: e.target.value,
                      })
                    }
                    placeholder="Product Name"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Article Name"
                  name="article-name"
                  style={{ color: "white", fontWeight: "lighter" }}
                  className="basic-info"
                >
                  <Input
                    className="input-field"
                    style={{
                      color: "#b6b6b6",
                      marginTop: "5px",
                    }}
                    value={productDetails?.product_name}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        product_name: e.target.value,
                      })
                    }
                    placeholder="Article Name"
                    disabled={!props.canEditProducts}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Product Description"
                  name="product-description"
                  className="rich-info"
                  style={{ color: "white", fontWeight: "lighter" }}
                >
                  <TextArea
                    className="input-field"
                    value={productDetails?.product_description}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        product_description: e.target.value,
                      })
                    }
                    style={{
                      color: "#b6b6b6",
                      marginTop: "5px",
                    }}
                    rows={5}
                    placeholder="Add Product Description"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Product Image"
                  name="product-image"
                  style={{ color: "white", fontWeight: "lighter" }}
                >
                  <ImageManagerNesto
                    isNesto
                    createProduct={props.createProduct}
                    product_pk={props.productPk}
                    //disabled={this.props.productDetails.locked}
                    handleChange={(value) => setImagesUpdated(value)}
                    images={
                      productDetails?.images ? productDetails?.images : {}
                    }
                    fetchImagesOnly={props.fetchImagesDetails}
                    canDeleteImage={props.canDeleteImage}
                  />
                </Form.Item>
                <Form.Item 
                  label="Primary Keywords"
                  name="primary-keywords"
                >
                  <Select
                      dropdownClassName="search-chips"
                      dropdownMatchSelectWidth="false"
                      mode="tags"
                      style={{
                          border: "1px solid rgba(255, 255, 255, 0.3)",
                          width: "100%",
                          marginTop: "10px",
                          backgroundColor: '#3C4853'
                      }}
                      placeholder={["Primary Keywords"]}
                      onChange={handleChangePrimaryTags}
                      value={primaryTags}/>
                </Form.Item>
                <Form.Item 
                  label="Secondary Keywords"
                  name="secondary-keywords"
                >
                  <Select
                      dropdownClassName="search-chips"
                      dropdownMatchSelectWidth="false"
                      mode="tags"
                      style={{
                          border: "1px solid rgba(255, 255, 255, 0.3)",
                          width: "100%",
                          marginTop: "10px",
                          backgroundColor: '#3C4853'
                      }}
                      placeholder={["Secondary Keywords"]}
                      onChange={handleChangeSecondaryTags}
                      value={secondaryTags}/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    borderColor: "rgba(255,255,255,0.15)",
                    borderStyle: "solid",
                    borderWidth: "0.01em",
                    marginBottom: "16px",
                    minWidth: "100%",
                  }}
                />
                <Text style={{ fontSize: "16px", color: "white" }}>
                  Units and Dimensions
                </Text>
                <Row gutter={12} style={{ marginTop: "10px" }}>
                  <Col span={8}>
                    <Form.Item
                      label="Weight/Volume"
                      name="weight-volume"
                      className="ecommerce-info"
                      style={{ color: "white", fontWeight: "lighter" }}
                    >
                      <Input
                        className="input-field"
                        style={{
                          color: "#b6b6b6",
                          marginTop: "5px",
                        }}
                        value={productDetails?.weight_volume}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            weight_volume: e.target.value,
                          })
                        }
                        placeholder="Weight or Volume of the product"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Unit of Measurement"
                      name="unit"
                      className="rich-info"
                      style={{ color: "white", fontWeight: "lighter" }}
                    >
                      <Input
                        className="input-field"
                        style={{
                          color: "#b6b6b6",
                          marginTop: "5px",
                        }}
                        value={productDetails?.uom}
                        disabled={true}
                        placeholder="Unit"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Type"
                      name="carton"
                      style={{ color: "white", fontWeight: "lighter" }}
                    >
                      <Select
                        defaultValue="carton"
                        style={{
                          color: "#b6b6b6",
                          marginTop: "5px",
                        }}
                      >
                        <Option value="product">Each Product</Option>
                        <Option value="carton">Each Carton</Option>
                        <Option value="pack">Each Pack</Option>
                        <Option value="box">Each Box</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Product Dimensions ( L x B x H )"
                  name="product-dimensions"
                  style={{ color: "white", fontWeight: "lighter" }}
                >
                  <Row gutter={12} style={{ marginTop: "5px" }}>
                    <Col span={8}>
                      <Input
                        className="input-field"
                        style={{ color: "#b6b6b6" }}
                        value={productDetails?.dimensions?.product_length}
                        placeholder="Length"
                        addonAfter={
                          <SelectAfter
                            value={
                              productDetails?.dimensions?.product_length_metric
                            }
                            name={"product_length_metric"}
                            handleChange={(val) =>
                              setProductDetails({
                                ...productDetails,
                                dimensions: {
                                  ...productDetails.dimensions,
                                  product_length_metric: val,
                                },
                              })
                            }
                            list={dimensionUnits}
                          />
                        }
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            dimensions: {
                              ...productDetails.dimensions,
                              product_length: e.target.value,
                            },
                          })
                        }
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        className="input-field"
                        style={{ color: "#b6b6b6" }}
                        value={productDetails?.dimensions?.product_width}
                        placeholder="Width"
                        addonAfter={
                          <SelectAfter
                            value={
                              productDetails?.dimensions?.product_width_metric
                            }
                            name={"product_width_metric"}
                            handleChange={(val) =>
                              setProductDetails({
                                ...productDetails,
                                dimensions: {
                                  ...productDetails.dimensions,
                                  product_width_metric: val,
                                },
                              })
                            }
                            list={dimensionUnits}
                          />
                        }
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            dimensions: {
                              ...productDetails.dimensions,
                              product_width: e.target.value,
                            },
                          })
                        }
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        className="input-field"
                        style={{ color: "#b6b6b6" }}
                        value={productDetails?.dimensions?.product_height}
                        placeholder="Height"
                        addonAfter={
                          <SelectAfter
                            value={
                              productDetails?.dimensions?.product_height_metric
                            }
                            name={"product_height_metric"}
                            handleChange={(val) =>
                              setProductDetails({
                                ...productDetails,
                                dimensions: {
                                  ...productDetails.dimensions,
                                  product_height_metric: val,
                                },
                              })
                            }
                            list={dimensionUnits}
                          />
                        }
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            dimensions: {
                              ...productDetails.dimensions,
                              product_height: e.target.value,
                            },
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    borderColor: "rgba(255,255,255,0.15)",
                    borderStyle: "solid",
                    borderWidth: "0.01em",
                    marginBottom: "16px",
                    minWidth: "100%",
                  }}
                />
                <Row>
                  {!props.createProduct && (
                    <Col span={19}>
                      <Text style={{ fontSize: "16px", color: "white" }}>
                        Seller Details
                      </Text>
                    </Col>
                  )}
                  {!props.createProduct && (
                    <Col span={5}>
                      <div
                        style={{ color: "#1ABC9C", cursor: "pointer" }}
                        onClick={() => setCreateStoreModal(true)}
                      >
                        <Icon type="plus"></Icon>
                        <Text style={{ color: "#1ABC9C" }}>Add New Seller</Text>
                      </div>
                    </Col>
                  )}
                  {!props.createProduct &&
                    productDetails.available_stores &&
                    productDetails.available_stores.length > 0 &&
                    productDetails.available_stores.map((store) => {
                      return (
                        <Col span={24}>
                          <SellerDetails
                            product_uuid={props.productPk}
                            setProductDetails={setProductDetails}
                            productDetails={productDetails}
                            store={store}
                          />
                          <div
                            style={{
                              borderColor: "rgba(255,255,255,0.15)",
                              borderStyle: "solid",
                              borderWidth: "0.01em",
                              marginBottom: "16px",
                              minWidth: "100%",
                            }}
                          />
                        </Col>
                      );
                    })}

                  <Col span={24} style={{ marginTop: "20px" }}>
                    <Text style={{ fontSize: "16px", color: "white" }}>
                      Ingredients & Preparation information
                    </Text>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Ingredients"
                          name="ingredients"
                          className="ecommerce-info"
                        >
                          <TextArea
                            className="input-field"
                            value={productDetails?.ingredients}
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                ingredients: e.target.value,
                              })
                            }
                            style={{ marginTop: 5, color: "#b6b6b6" }}
                            rows={4}
                            placeholder="Add Ingredients"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Preparation and Usage"
                          name="preparation-and-usage"
                        >
                          <TextArea
                            className="input-field"
                            value={productDetails?.preparation_and_usage}
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                preparation_and_usage: e.target.value,
                              })
                            }
                            style={{ marginTop: 5, color: "#b6b6b6" }}
                            rows={4}
                            placeholder="Add Preparation and Usage"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Storage Conditions"
                          name="storage-conditions"
                          className="ecommerce-info"
                        >
                          <TextArea
                            className="input-field"
                            value={productDetails?.storage_condition}
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                storage_condition: e.target.value,
                              })
                            }
                            style={{ marginTop: 5, color: "#b6b6b6" }}
                            rows={4}
                            placeholder="Add Storage Conditions"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Allergic Information"
                          name="allergic-information"
                          className="ecommerce-info"
                        >
                          <TextArea
                            className="input-field"
                            value={productDetails?.allergic_information}
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                allergic_information: e.target.value,
                              })
                            }
                            style={{ marginTop: 5, color: "#b6b6b6" }}
                            rows={4}
                            placeholder="Add Allergic Information"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <div
                          style={{
                            borderColor: "rgba(255,255,255,0.15)",
                            borderStyle: "solid",
                            borderWidth: "0.01em",
                            marginBottom: "16px",
                            minWidth: "100%",
                          }}
                        />
                        
                        <Form.Item
                          label="Product Highlights"
                          name="product-highlights"
                        >
                          <EditorBox
                            height={300}
                            placeholder="Add Product Highlights"
                            value={
                              productDetails?.highlights
                                ? productDetails.highlights
                                : ""
                            }
                            getText={(value) =>
                              setProductDetails({
                                ...productDetails,
                                highlights: value,
                              })
                            }
                            disabled={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Nutrition Facts"
                          name="nutrition-facts"
                          className="ecommerce-info"
                        >
                          <EditorBox
                            height={300}
                            placeholder="Add Nutrition Facts"
                            value={
                              productDetails?.nutrition_facts
                                ? productDetails.nutrition_facts
                                : ""
                            }
                            getText={(value) =>
                              setProductDetails({
                                ...productDetails,
                                nutrition_facts: value,
                              })
                            }
                            disabled={false}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </NestoFormCol>
          </Col>
          <Col span={9} style={{ paddingLeft: "10px" }}>
            <NestoFormCol span={24}>
              <Col span={24}>
                <Form.Item
                  label="Barcode"
                  name="barcode"
                  style={{ color: "white", fontWeight: "lighter" }}
                  className="basic-info"
                >
                  <Input
                    className="input-field"
                    style={{ color: "#b6b6b6", marginTop: "5px" }}
                    value={productDetails?.barcode}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        barcode: e.target.value,
                      })
                    }
                    placeholder="Barcode"
                    // disabled={!props.createProduct}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Article Number"
                  name="article-number"
                  className="basic-info"
                  style={{ color: "white", fontWeight: "lighter" }}
                >
                  <Input
                    style={{ color: "#b6b6b6", marginTop: "5px" }}
                    className="input-field"
                    value={productDetails?.article_number}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        article_number: e.target.value,
                      })
                    }
                    placeholder="Article Number"
                    // disabled={!props.createProduct}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <div
                  style={{
                    borderColor: "rgba(255,255,255,0.15)",
                    borderStyle: "solid",
                    borderWidth: "0.01em",
                    marginBottom: "16px",
                    minWidth: "100%",
                  }}
                />
                <Text style={{ fontSize: "16px", color: "white" }}>
                  Product Category
                </Text>
                <Row style={{ marginTop: "10px" }}>
                  <Col span={24}>
                    <Form.Item
                      label="Super Category"
                      name="super-category"
                      style={{ color: "white", fontWeight: "lighter" }}
                      //className="basic-info"
                    >
                      <Select
                        placeholder="Select Super Category"
                        showSearch
                        value={categories.super_category}
                        //onChange={(value) => setCategories({...categories, super_category: value.name})}
                        onSelect={(value) => onCategorySelect("Super", value)}
                      >
                        {superCategoryList.map((superCat) => {
                          return (
                            <Option value={superCat.name} key={superCat.uuid}>
                              {superCat.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Category"
                      name="category"
                      style={{ color: "white", fontWeight: "lighter" }}
                      //className="basic-info"
                    >
                      <Select
                        placeholder="Select Category"
                        showSearch
                        value={categories.category}
                        //onChange={(value) => setCategories({...categories, category: value.name})}
                        onSelect={(value) =>
                          onCategorySelect("Category", value)
                        }
                      >
                        {categoryList.map((cateogry) => {
                          return (
                            <Option value={cateogry.name} key={cateogry.uuid}>
                              {cateogry.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Sub Category"
                      name="sub-category"
                      style={{ color: "white", fontWeight: "lighter" }}
                      //className="basic-info"
                    >
                      <Select
                        placeholder="Select Sub Category"
                        showSearch
                        value={categories.sub_category}
                        //onChange={(value) => console.log(value, "SUB")}
                        onSelect={(value) => onCategorySelect("Sub", value)}
                      >
                        {subCategoryList.map((subCat) => {
                          return (
                            <Option value={subCat.name} key={subCat.uuid}>
                              {subCat.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    borderColor: "rgba(255,255,255,0.15)",
                    borderStyle: "solid",
                    borderWidth: "0.01em",
                    marginBottom: "16px",
                    minWidth: "100%",
                  }}
                />
                <Text style={{ fontSize: "16px", color: "white" }}>
                  Brand Details
                </Text>
                <Row style={{ marginTop: "10px" }}>
                  <Col span={24}>
                    <Form.Item
                      label="Brand Name"
                      name="brand-name"
                      className="ecommerce-info"
                    >
                      <Select
                        value={productBrand.brand}
                        showSearch
                        placeholder="Brand Name"
                        onSelect={handleBrandSelect}
                      >
                        {brandList.map((brand) => {
                          return (
                            <Option value={brand.name} key={brand.pk}>
                              {brand.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  {!props.createProduct && (
                    <div>
                      <Col span={6} style={{width: "fit-content", maxWidth: "100%"}}>
                        <img src={productBrand?.logo  || placeHolderImage} alt="brand logo" style={{width: 40, height: 40, borderRadius: 8}}/>
                      </Col>
                      <Col offset={1} span={17} style={{height: 40, display: "flex", alignItems: "center"}}>
                        <Text style={{fontSize: 20, color: "white"}}>{productBrand.brand}</Text>
                      </Col>
                      <Col span={24} style={{paddingTop: 15}}>
                        <Text style={{color: "white"}}>Description :</Text>
                        {" "}
                        <Text>
                          {productBrand.about_brand}
                        </Text>
                      </Col>
                    </div>
                  )}
                  <Col span={24} style={{ marginTop: "10px" }}>
                    <Form.Item
                      label="Country of Origin"
                      name="country-of-origin"
                      className="ecommerce-info"
                    >
                      <Select
                        placeholder="Select Country"
                        showSearch
                        value={productDetails?.country_of_origin}
                        onChange={(value) =>
                          setProductDetails({
                            ...productDetails,
                            country_of_origin: value,
                          })
                        }
                      >
                        {countryList.map((country) => {
                          return (
                            <Option value={country.name.toLowerCase()}>
                              {country.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Return Days"
                      name="return-days"
                      className="ecommerce-info"
                    >
                      <Input
                        className="input-field"
                        style={{ color: "#b6b6b6" }}
                        value={productDetails?.return_days}
                        placeholder="Return Days"
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            return_days: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Language Key"
                      name="language-key"
                      className="ecommerce-info"
                    >
                      <Select
                        placeholder="Select Language"
                        value={productDetails?.language_key}
                        onChange={(value) =>
                          setProductDetails({
                            ...productDetails,
                            language_key: value,
                          })
                        }
                      >
                        <Option value="E">English (E)</Option>
                        <Option value="A">Arabic (A)</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Vendor Category" name="vendor-category">
                      <Select
                        placeholder="Select Vendor Category"
                        value={
                          productDetails?.vendor_category === ""
                            ? undefined
                            : productDetails?.vendor_category
                        }
                        onChange={(value) =>
                          setProductDetails({
                            ...productDetails,
                            vendor_category: value,
                          })
                        }
                      >
                        <Option value="Market">Market</Option>
                        <Option value="Own Brand">Own Brand</Option>
                        <Option value="Four Digit">Four Digit</Option>
                        <Option value="Extras">Extras</Option>
                        <Option value="Vendor">Vendor</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Verified"
                      name="verified"
                      colon
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Switch
                        checked={productDetails?.is_verified}
                        onChange={(value) =>
                          setProductDetails({
                            ...productDetails,
                            is_verified: value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Online"
                      name="online"
                      colon
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Switch
                        checked={productDetails?.is_online}
                        onChange={(value) =>
                          setProductDetails({
                            ...productDetails,
                            is_online: value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </NestoFormCol>
          </Col>
        </Row>  
        ):(
          <Row gutter={[0, 0]}>
          <Col span={24} style={{ paddingRight: "10px" }}>
            <NestoFormCol span={24}>
              <Col span={24}>
                <Form.Item
                  label="Product Name (ar)"
                  name="product-name-ar"
                  style={{ color: "white", fontWeight: "lighter" }}
                  className="ecommerce-info"
                >
                  <Input
                    className="input-field"
                    style={{
                      color: "#b6b6b6",
                      marginTop: "5px",
                    }}
                    value={productDetails?.product_name_ar}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        product_name_ar: e.target.value,
                      })
                    }
                    placeholder="Product Name"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Product Description (ar)"
                  name="product-description-ar"
                  className="rich-info"
                  style={{ color: "white", fontWeight: "lighter" }}
                >
                  <TextArea
                    className="input-field"
                    value={productDetails?.product_description_ar}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        product_description_ar: e.target.value,
                      })
                    }
                    style={{
                      color: "#b6b6b6",
                      marginTop: "5px",
                    }}
                    rows={5}
                    placeholder="Add Product Description"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    borderColor: "rgba(255,255,255,0.15)",
                    borderStyle: "solid",
                    borderWidth: "0.01em",
                    marginBottom: "16px",
                    minWidth: "100%",
                  }}
                />
                <Col span={24} style={{ marginTop: "20px" }}>
                  <Text style={{ fontSize: "16px", color: "white" }}>
                    Ingredients & Preparation information (ar)
                  </Text>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Ingredients"
                        name="ingredients-ar"
                        className="ecommerce-info"
                      >
                        <TextArea
                          className="input-field"
                          value={productDetails?.ingredients_ar}
                          onChange={(e) =>
                            setProductDetails({
                              ...productDetails,
                              ingredients_ar: e.target.value,
                            })
                          }
                          style={{ marginTop: 5, color: "#b6b6b6" }}
                          rows={4}
                          placeholder="Add Ingredients"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Preparation and Usage"
                        name="preparation-and-usage-ar"
                      >
                        <TextArea
                          className="input-field"
                          value={productDetails?.preparation_and_usage_ar}
                          onChange={(e) =>
                            setProductDetails({
                              ...productDetails,
                              preparation_and_usage_ar: e.target.value,
                            })
                          }
                          style={{ marginTop: 5, color: "#b6b6b6" }}
                          rows={4}
                          placeholder="Add Preparation and Usage"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Storage Conditions"
                        name="storage-conditions-ar"
                        className="ecommerce-info"
                      >
                        <TextArea
                          className="input-field"
                          value={productDetails?.storage_condition_ar}
                          onChange={(e) =>
                            setProductDetails({
                              ...productDetails,
                              storage_condition_ar: e.target.value,
                            })
                          }
                          style={{ marginTop: 5, color: "#b6b6b6" }}
                          rows={4}
                          placeholder="Add Storage Conditions"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Allergic Information"
                        name="allergic-information-ar"
                        className="ecommerce-info"
                      >
                        <TextArea
                          className="input-field"
                          value={productDetails?.allergic_information_ar}
                          onChange={(e) =>
                            setProductDetails({
                              ...productDetails,
                              allergic_information_ar: e.target.value,
                            })
                          }
                          style={{ marginTop: 5, color: "#b6b6b6" }}
                          rows={4}
                          placeholder="Add Allergic Information"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </NestoFormCol>
          </Col>
        </Row>  
        )
        }
        {!props.createProduct && isEnglishLanguage && (
          <NestoFormRow>
            <Text
              style={{
                textTransform: "uppercase",
                fontSize: 18,
                color: "white",
              }}
            >
              Product Baskets
            </Text>
            <Tabs defaultActiveKey="1" tabBarStyle={{ color: "white" }}>
              <TabPane tab="Substitute Products" key="1">
                <ProductBasket
                  linkType="substitute"
                  product_uuid={props.productPk}
                  products={basketProducts?.substitute_products}
                  handleUpdate={(val) => setProductsUpdated(val)}
                />
              </TabPane>
              <TabPane tab="Upsell Products" key="2">
                <ProductBasket
                  linkType="upselling_products"
                  product_uuid={props.productPk}
                  products={basketProducts?.upselling_products}
                  handleUpdate={(val) => setProductsUpdated(val)}
                />
              </TabPane>
              <TabPane tab="Cross-sell Products" key="3">
                <ProductBasket
                  linkType="cross_selling"
                  product_uuid={props.productPk}
                  products={basketProducts?.cross_selling_products}
                  handleUpdate={(val) => setProductsUpdated(val)}
                />
              </TabPane>
            </Tabs>
          </NestoFormRow>
        )}
        <Form.Item>
          <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={props.createProduct ? "file-add" : "save"}
              loading={loading}
              onClick={handleSave}
              style={{
                borderRadius: 5,
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#d80000",
                color: "white",
                outline: "none",
                borderColor: "#d80000",
              }}
            >
              <Text style={{ lineHeight: 1, color: "white" }}>
                {props.createProduct ? "Create" : "Save"}
              </Text>
            </Button>
          </Affix>
        </Form.Item>
      </Form>
      <Modal
        title="Create New Seller"
        visible={createStoreModal}
        destroyOnClose={true}
        closable={true}
        onOk={() => {
          var valid_flag = 0;
          productDetails.available_stores.map((available_store) => {
            if (available_store.store_uuid === newStore.store_uuid) {
              valid_flag = 1;
            }
          });
          if (valid_flag == 1) {
            message.error("Store Already Added");
          } else {
            setProductDetails({
              ...productDetails,
              available_stores: [...productDetails.available_stores, newStore],
            });
            setCreateStoreModal(false);
            setNewStore({});
          }
        }}
        onCancel={() => {
          setCreateStoreModal(false);
        }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Select
              placeholder="Select Seller"
              showSearch
              labelInValue
              onSelect={(value) => {
                setNewStore({
                  ...newStore,
                  store_uuid: value.key,
                  name: value.label,
                });
              }}
              style={{ width: "250px" }}
            >
              {sellerList.map((seller) => {
                return (
                  <Option value={seller.uuid} key={seller.uuid}>
                    {seller.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Seller SKU"
              name="store-seller-sku"
              style={{ color: "white", fontWeight: "lighter" }}
            >
              <Input
                className="input-field"
                style={{ color: "#b6b6b6" }}
                value={newStore.seller_sku}
                onChange={(e) =>
                  setNewStore({ ...newStore, seller_sku: e.target.value })
                }
                placeholder="seller SKU"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Stock"
              name="store-stock"
              style={{ color: "white", fontWeight: "lighter" }}
            >
              <Input
                className="input-field"
                style={{ color: "#b6b6b6" }}
                value={newStore.stock}
                onChange={(e) =>
                  setNewStore({ ...newStore, stock: e.target.value })
                }
                placeholder="Stock"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Normal Price"
              name="store-normal-price"
              style={{ color: "white", fontWeight: "lighter" }}
            >
              <Input
                className="input-field"
                style={{ color: "#b6b6b6" }}
                value={newStore.normal_price}
                onChange={(e) =>
                  setNewStore({ ...newStore, normal_price: e.target.value })
                }
                placeholder="Normal Price"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Special Price"
              name="store-special-price"
              style={{ color: "white", fontWeight: "lighter" }}
            >
              <Input
                className="input-field"
                style={{ color: "#b6b6b6" }}
                value={newStore.special_price}
                onChange={(e) =>
                  setNewStore({ ...newStore, special_price: e.target.value })
                }
                placeholder="Special Price"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Strike Price"
              name="store-strike-price"
              style={{ color: "white", fontWeight: "lighter" }}
            >
              <Input
                className="input-field"
                style={{ color: "#b6b6b6" }}
                value={newStore.strike_price}
                onChange={(e) =>
                  setNewStore({ ...newStore, strike_price: e.target.value })
                }
                placeholder="Strike Price"
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

const SellerDetails = (props) => {
  const handleDeleteSeller = () => {
    fetch(`${serverUrl}/delete-nesto-product-store/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        product_uuid: props.product_uuid,
        store_uuid: props.store.store_uuid,
      }),
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((e) => {
        //console.log(e);
      });
  };

  return (
    <Row gutter={12}>
      <Col span={23} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Text style={{ fontSize: "16px" }}>Store Name : </Text>
        <Text style={{ fontSize: "17px", color: "white" }}>
          {props.store.name}
        </Text>
      </Col>
      <Col
        span={1}
        style={{ paddingTop: "15px", paddingBottom: "10px" }}
        onClick={(e) => {
          handleDeleteSeller();
          const final_available_stores = props.productDetails.available_stores.filter(
            (available_store) => {
              return available_store !== props.store;
            }
          );
          props.setProductDetails({
            ...props.productDetails,
            available_stores: [...final_available_stores],
          });
        }}
      >
        <Icon style={{ fontSize: "16px" }} type="delete"></Icon>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Seller SKU"
          name="store-seller-sku"
          style={{ color: "white", fontWeight: "lighter" }}
        >
          <Input
            className="input-field"
            style={{ color: "#b6b6b6" }}
            value={props.store.seller_sku}
            onChange={(e) => {
              let temp = [...props.productDetails.available_stores];
              for(let i=0; i<temp.length; i++) {
                if(temp[i] === props.store) {
                  temp[i].seller_sku = e.target.value;
                  break;
                }
              }
              props.setProductDetails({
                ...props.productDetails,
                available_stores: [...temp],
              })
            }}
            disabled={true}
            placeholder="seller SKU"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Stock"
          name="store-stock"
          style={{ color: "white", fontWeight: "lighter" }}
        >
          <Input
            className="input-field"
            style={{ color: "#b6b6b6" }}
            value={props.store.stock}
            onChange={(e) => {
              let temp = [...props.productDetails.available_stores];
              for(let i=0; i<temp.length; i++) {
                if(temp[i] === props.store) {
                  temp[i].stock = e.target.value;
                  break;
                }
              }
              props.setProductDetails({
                ...props.productDetails,
                available_stores: [...temp],
              })
            }}
            placeholder="Stock"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Normal Price"
          name="store-normal-price"
          style={{ color: "white", fontWeight: "lighter" }}
        >
          <Input
            className="input-field"
            style={{ color: "#b6b6b6" }}
            value={props.store.normal_price}
            onChange={(e) => {
              let temp = [...props.productDetails.available_stores];
              for(let i=0; i<temp.length; i++) {
                if(temp[i] === props.store) {
                  temp[i].normal_price = e.target.value;
                  break;
                }
              }
              props.setProductDetails({
                ...props.productDetails,
                available_stores: [...temp],
              })
            }}
            placeholder="Normal Price"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Special Price"
          name="store-special-price"
          style={{ color: "white", fontWeight: "lighter" }}
        >
          <Input
            className="input-field"
            style={{ color: "#b6b6b6" }}
            value={props.store.special_price}
            onChange={(e) => {
              let temp = [...props.productDetails.available_stores];
              for(let i=0; i<temp.length; i++) {
                if(temp[i] === props.store) {
                  temp[i].special_price = e.target.value;
                  break;
                }
              }
              props.setProductDetails({
                ...props.productDetails,
                available_stores: [...temp],
              })
            }}
            placeholder="Special Price"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Strike Price"
          name="store-strike-price"
          style={{ color: "white", fontWeight: "lighter" }}
        >
          <Input
            className="input-field"
            style={{ color: "#b6b6b6" }}
            value={props.store.strike_price}
            onChange={(e) => {
              let temp = [...props.productDetails.available_stores];
              for(let i=0; i<temp.length; i++) {
                if(temp[i] === props.store) {
                  temp[i].strike_price = e.target.value;
                  break;
                }
              }
              props.setProductDetails({
                ...props.productDetails,
                available_stores: [...temp],
              })
            }}
            placeholder="Strike Price"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    ...user,
  };
};

export default connect(mapStateToProps, {
  fetchImagesDetails,
})(ProductDetails);
