import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Select,
  Collapse,
  Icon,
  Button,
  Affix,
  message,
  Tooltip,
} from "antd";
import InputField from "../datainputs/InputField";
// import ImageManager from "../datainputs/image-manager/ImageManager";
import FormHeader from "../datainputs/FormHeader";
import InputNumberDimensions3 from "../datainputs/InputNumberDimesions3";
import EditorBox from "../EditorBox";
import "./channel-page.scss";
import Attributes from "../datainputs/attributes/Attributes";
import { connect } from "react-redux";
import moment from "moment";
import {
  fetchAmazonUk,
  handelChange,
  saveUkForm,
} from "../../../actions/channelProduct";

import SimpleReactValidator from "simple-react-validator";
import CalenderComponent from "../calender-component/CalenderComponent";
import isDev from '../../../server';

const serverUrl = isDev();
const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

class AmazonUkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      features: null,
      attributes: null,
      saleFrom: null,
      saleEnd: null,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Field is required",
        integer: "Must be number",
        default: "Validation has failed!", // will override all messages
      },
    });
  }

  collapsBlock = {
    margin: 0,
  };

  dimlist = ["FT", "IN", "CM", "MM", "M"];
  dimvolume = ["CC", "L", "CUBIC-M"];
  dimweight = ["KG", "LB", "OZ", "MG", "GR"];
  dimcount = ["Bag", "Box", "Dzn", "Mah", "Pieces", "Packet"];

  customPanelStyle = {};

  componentDidMount = () => {
    this.props.fetchAmazonUk(this.props.product_pk);
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  getAttributesData = (data) => {
    this.setState({ attributes: data });
  };

  getFeaturesData = (data) => {
    this.setState({ features: data });
  };

  handelChangeInputOut = (e) => {
    this.props.handelChange({
      ...this.props.amazon_uk,
      [e.target.name]: e.target.value,
    });
  };

  handelChangeInput = (e) => {
    this.props.handelChange({
      ...this.props.amazon_uk,
      channel_product_json: {
        ...this.props.amazon_uk.channel_product_json,
        [e.target.name]: e.target.value,
      },
    });
    this.validator.showMessages();
  };

  handelChangeDimenssion = (e) => {
    this.props.handelChange({
      ...this.props.amazon_uk,
      channel_product_json: {
        ...this.props.amazon_uk.channel_product_json,
        dimensions: {
          ...this.props.amazon_uk.channel_product_json.dimensions,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  handleChangeMD = (e, name) => {
    this.props.handelChange({
      ...this.props.amazon_uk,
      channel_product_json: {
        ...this.props.amazon_uk.channel_product_json,
        dimensions: {
          ...this.props.amazon_uk.channel_product_json.dimensions,
          [name]: e,
        },
      },
    });
  };

  handelSave = () => {
    //.log(this.props, "SAVING THE DATA");
    if (this.validator.allValid()) {
      this.props.amazon_uk.channel_product_json.product_attribute_list = this.state.attributes;
      this.props.amazon_uk.channel_product_json.special_features = this.state.features;
      this.props.amazon_uk.channel_product_json.sale_from = this.state.saleFrom;
      this.props.amazon_uk.channel_product_json.sale_end = this.state.saleEnd;

      this.props.saveUkForm(this.props.product_pk, this.props.amazon_uk);
    } else {
      message.warning("Fill Required data");
    }
  };

  getEditorText = (value) => {
    this.props.handelChange({
      ...this.props.amazon_uk,
      channel_product_json: {
        ...this.props.amazon_uk.channel_product_json,
        ["product_description"]: value,
      },
    });
  };

  error = (status) => {
    if (status == 500) message.error("Internal Server Error");
    else if (status == 409) message.warning("Duplicate Product Detected");
    else if (status == 403) message.error("access denied");
    else {
      message.success("Saved successfully! ");
    }
  };
  setAttributeFlag = 1;
  setFeatureFlag = 1;
  componentWillReceiveProps = (props) => {
    if (
      props.amazon_uk.channel_product_json &&
      props.amazon_uk.channel_product_json.sale_from !== ""
    ) {
      this.setState({
        saleFrom: new Date(props.amazon_uk.channel_product_json.sale_from),
      });
    }

    if (
      props.amazon_uk.channel_product_json &&
      props.amazon_uk.channel_product_json.sale_end !== ""
    ) {
      this.setState({
        saleEnd: new Date(props.amazon_uk.channel_product_json.sale_end),
      });
    }

    if (
      props.amazon_uk.channel_product_json &&
      this.setAttributeFlag &&
      props.amazon_uk.channel_product_json.product_attribute_list &&
      props.amazon_uk.channel_product_json.product_attribute_list.length
    ) {
      this.setState({
        attributes:
          props.amazon_uk.channel_product_json.product_attribute_list,
      });

      this.setAttributeFlag = 0;
    }
    if (
      props.amazon_uk.channel_product_json &&
      this.setFeatureFlag &&
      props.amazon_uk.channel_product_json.special_features &&
      props.amazon_uk.channel_product_json.special_features.length
    ) {
      this.setState({
        features: props.amazon_uk.channel_product_json.special_features,
      });
      this.setFeatureFlag = 0;
    }
    if (props.amazon_uk.status !== "") {
      this.error(props.amazon_uk.status);
    }
  };

  handelSaleFromDate = (date) => {
    this.setState({ saleFrom: date });
  };

  handelSaleEndDate = (date) => {
    this.setState({ saleEnd: date });
  };

  handleAmazonClick = () => {
    let data = {
      product_pk_list: [...[],this.props.product_pk],
      is_partial:true
    }
    let dataImage = {
      product_pk_list: [...[],this.props.product_pk]
    }
    fetch(`${serverUrl}/mws/push-products-amazon-uk/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
    .then(response=>response.json())
    .then(data=>{
      if(data.status === 200)
        message.success("Successfully published please check reports in Reports Page for feed product id = " +
        data.feed_submission_id)
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
      else  
        message.error("something went wrong!")
    })
    fetch(`${serverUrl}/mws/push-product-images-amazon-uk/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(dataImage),
    })
    .then(response=>response.json())
    .then(data=>{
      if(data.status === 200)
        message.success("Successfully published please check reports in Reports Page for feed product id = " +
        data.feed_submission_id)
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
      else  
        message.error("something went wrong!")
    })
  }

  render() {
    //.log(this.props, "SAVING THE DATA");

    const { product_id, material_type } = this.props.amazon_uk;
    const {
      feed_product_type,
      product_name,
      variation_theme,
      update_delete,
      recommended_browse_nodes,
      search_terms,
      enclosure_material,
      cover_material_type,
      sale_price,
      wattage,
      parentage,
      parent_sku,
      relationship_type,
      item_count,
      item_condition_note,
      max_order_quantity,
      number_of_items,
      condition_type,
      product_attribute_list,
      special_features,
      product_description,
      category,
      sub_category,
      http_link,
      was_price,
      now_price,
      stock,
    } = this.props.amazon_uk.channel_product_json;

    const {
      package_length,
      package_width,
      package_height,
      package_weight,
      shipping_weight,
      item_display_weight,
      item_display_volume,
      item_display_length,
      item_weight,
      item_length,
      item_width,
      item_height,
      item_display_width,
      item_display_height,
    } = this.props.amazon_uk.channel_product_json.dimensions;

    const {
      package_length_metric,
      package_width_metric,
      package_height_metric,
      package_weight_metric,
      shipping_weight_metric,
      item_display_weight_metric,
      item_display_volume_metric,
      item_display_length_metric,
      item_weight_metric,
      item_length_metric,
      item_width_metric,
      item_height_metric,
      item_display_width_metric,
      item_display_height_metric,
    } = this.props.amazon_uk.channel_product_json.dimensions;

    if (!this.props.drawer) this.props.sendImages(this.props.amazon_uk.images);

    return (
      <React.Fragment>
        {/* Amazon Button */}
        <Tooltip placement="top" title="Amazon UK">
          <Affix style={{ position: "fixed", bottom: 20, right: 10 }}>
            <Button
              type="primary"
              shape="circle"
              icon="amazon"
              size="large"
              style={{background:"#F3A847",borderColor:"#F3A847"}}
              onClick={this.handleAmazonClick}
            ></Button>
          </Affix>
        </Tooltip>
        {!this.props.drawer && (
          <Tooltip placement="top" title="Save">
            <Affix style={{ position: "fixed", bottom: 20, right: 55 }}>
              <Button
                type="danger"
                shape="circle"
                icon="save"
                size="large"
                onClick={this.handelSave}
              ></Button>
            </Affix>
          </Tooltip>
        )}

        <Tooltip placement="top" title="Channel Forms">
          <Affix style={{ position: "fixed", bottom: 20, right: 100 }}>
            <Button
              type="primary"
              shape="circle"
              icon="border-right"
              size="large"
              onClick={this.props.ToggelDrawer}
            ></Button>
          </Affix>
        </Tooltip>

        <Row gutter={[40, 40]}>
          <Col className="gutter-row" span={24}>
            {/* <div className="gutter-box">col-6</div> */}

            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel header="GENERAL" key="1" style={this.customPanelStyle}>
                    <Row
                      gutter={[24, 24]}
                      style={{
                        height: "70vh",
                        overflow: "scroll",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <Col sm={24}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "product_name",
                            value: product_name,
                          }}
                          onChange={this.handelChangeInput}
                          errormsg={
                            this.validator &&
                            this.validator.message(
                              "product_name",
                              product_name,
                              "required"
                            )
                          }
                          labelText="Product Name"
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "now_price",
                            value: now_price,
                          }}
                          labelText="Now Price"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "was_price",
                            value: was_price,
                          }}
                          labelText="Was Price"
                          onChange={this.handelChangeInput}
                        />
                      </Col>



                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "stock",
                            value: stock,
                            type: "number",
                          }}
                          labelText="Stock"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "product_id",
                            value: product_id,
                            disabled: true,
                          }}
                          labelText="Product ID"
                          onChange={this.handelChangeInputOut}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "feed_product_type",
                            value: feed_product_type,
                          }}
                          labelText="Feed Product Type"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "variation_theme",
                            value: variation_theme,
                          }}
                          labelText="Variation Theme"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      {/* <Col sm={12}>
                      
                        <Text>Update / Delete</Text>
                        <Select
                          defaultValue="update"
                          size="large"
                          style={{ width: "100%" }}
                          onChange={this.handleChange}
                        >
                          <Option value="update">Update</Option>
                          <Option value="delete">Delete</Option>
                        </Select>
                      </Col> */}
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "recommended_browse_nodes",
                            value: recommended_browse_nodes,
                          }}
                          labelText="Recommended Browse Nodes"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "search_terms",
                            value: search_terms,
                          }}
                          labelText="Search Terms"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "enclosure_material",
                            value: enclosure_material,
                          }}
                          labelText="Enclosure Material"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "cover_material_type",
                            value: cover_material_type,
                          }}
                          labelText="Cover Material Type"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "sale_price",
                            value: sale_price,
                          }}
                          labelText="Sale Price"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        {/* <InputField

                          label={{ type: "secondary" }}
                          input={{ size: "large", name: "sale_from", value: sale_from }}
                          labelText="Sale From"
                          onChange={this.handelChangeInput}
                        /> */}
                        <CalenderComponent
                          label="Sale From"
                          getDate={this.handelSaleFromDate}
                          value={this.state.saleFrom}
                          size="large"
                        />
                      </Col>
                      <Col sm={12}>
                        {/* <InputField

                          label={{ type: "secondary" }}
                          input={{ size: "large", name: "sale_end", value: sale_end }}
                          labelText="Sale End"
                          onChange={this.handelChangeInput}
                        /> */}
                        <CalenderComponent
                          label="Sale End"
                          getDate={this.handelSaleEndDate}
                          value={this.state.saleEnd}
                          size="large"
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "wattage",
                            value: wattage,
                          }}
                          labelText="Wattage"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      {/* <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "material_type",
                            value: material_type,
                          }}
                          labelText="Material Type"
                          onChange={this.handelChangeInputOut}
                        />
                      </Col> */}
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "parentage",
                            value: parentage,
                          }}
                          labelText="Parentage"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "parent_sku",
                            value: parent_sku,
                          }}
                          labelText="Parent SKU"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "relationship_type",
                            value: relationship_type,
                          }}
                          labelText="Relationship Type"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "item_count",
                            value: item_count,
                          }}
                          labelText="Item Count"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "item_condition_note",
                            value: item_condition_note,
                          }}
                          labelText="Item Condition Note"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "max_order_quantity",
                            value: max_order_quantity,
                          }}
                          labelText="Max Order Quantity"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "number_of_items",
                            value: number_of_items,
                          }}
                          labelText="Number Of Items"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "category",
                            value: category,
                          }}
                          labelText="Category"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "sub_category",
                            value: sub_category,
                          }}
                          labelText="Sub Category"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                      <Col sm={12}>
                        {/* <InputField

                          label={{ type: "secondary" }}
                          input={{ size: "large", name: "condition_type", value: condition_type }}
                          labelText="Condition Type"
                          onChange={this.handelChangeInput}
                        /> */}
                        <Text>Condition Type</Text>
                        <Select
                          defaultValue="new"
                          size="large"
                          style={{ width: "100%" }}
                          onChange={this.handleChange}
                        >
                          <Option value="new">New</Option>
                          <Option value="old">Old</Option>
                        </Select>
                      </Col>
                      <Col sm={24}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "http_link",
                            value: http_link,
                          }}
                          labelText="HTTP Link"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["0"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel
                    header="DIMENSIONS"
                    key="1"
                    style={this.customPanelStyle}
                  >
                    <Row
                      gutter={[24, 24]}
                      style={{ margin: "10px 0px 10px 0px" }}
                    >
                      <Col sm={24}>
                        <InputNumberDimensions3
                          name1="package_length"
                          name2="package_width"
                          name3="package_height"
                          value1={package_length}
                          value2={package_width}
                          value3={package_height}
                          unit1="package_length_metric"
                          unit2="package_width_metric"
                          unit3="package_height_metric"
                          valueunit1={package_length_metric}
                          valueunit2={package_width_metric}
                          valueunit3={package_height_metric}
                          getData={this.handelChangeDimenssion}
                          getMetriD={this.handleChangeMD}
                          label={{ type: "secondary" }}
                          labelText="Package ( L x B x H )"
                          input={{ size: "large" }}
                          list1={this.dimlist}
                          list2={this.dimlist}
                          list3={this.dimlist}
                        />
                      </Col>
                      <Col sm={24}>
                        <InputNumberDimensions3
                          name1="item_length"
                          name2="item_width"
                          name3="item_height"
                          value1={item_length}
                          value2={item_width}
                          value3={item_height}
                          unit1="item_length_metric"
                          unit2="item_width_metric"
                          unit3="item_height_metric"
                          valueunit1={item_length_metric}
                          valueunit2={item_width_metric}
                          valueunit3={item_height_metric}
                          getData={this.handelChangeDimenssion}
                          getMetriD={this.handleChangeMD}
                          label={{ type: "secondary" }}
                          labelText="Item ( L x B x H )"
                          input={{ size: "large" }}
                          list1={this.dimlist}
                          list2={this.dimlist}
                          list3={this.dimlist}
                        />
                      </Col>
                      <Col sm={24}>
                        <InputNumberDimensions3
                          name1="item_display_length"
                          name2="item_display_width"
                          name3="item_display_height"
                          value1={item_display_length}
                          value2={item_display_width}
                          value3={item_display_height}
                          unit1="item_display_length_metric"
                          unit2="item_display_width_metric"
                          unit3="item_display_height_metric"
                          valueunit1={item_display_length_metric}
                          valueunit2={item_display_width_metric}
                          valueunit3={item_display_height_metric}
                          getData={this.handelChangeDimenssion}
                          getMetriD={this.handleChangeMD}
                          label={{ type: "secondary" }}
                          labelText="Item Display ( L x B x H )"
                          input={{ size: "large" }}
                          list1={this.dimlist}
                          list2={this.dimlist}
                          list3={this.dimlist}
                        />
                      </Col>
                      <Col sm={24}>
                        <InputNumberDimensions3
                          name1="package_weight"
                          name2="shipping_weight"
                          name3="item_display_weight"
                          value1={package_weight}
                          value2={shipping_weight}
                          value3={item_display_weight}
                          unit1="package_weight_metric"
                          unit2="shipping_weight_metric"
                          unit3="item_display_weight_metric"
                          valueunit1={package_weight_metric}
                          valueunit2={shipping_weight_metric}
                          valueunit3={item_display_weight_metric}
                          getData={this.handelChangeDimenssion}
                          getMetriD={this.handleChangeMD}
                          label={{ type: "secondary" }}
                          labelText="Weight ( Package x Shipping x ItemDisplay )"
                          input={{ size: "large" }}
                          list1={this.dimweight}
                          list2={this.dimweight}
                          list3={this.dimweight}
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["0"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel
                    header="ATTRIBUTES"
                    key="1"
                    style={this.customPanelStyle}
                  >
                    <Row
                      gutter={[24, 24]}
                      style={{ margin: "10px 0px 10px 0px" }}
                    >
                      <Col sm={24}>
                        <Attributes
                          initialdata={this.state.attributes || []}
                          sendList={this.getAttributesData}
                          addbuttontitle="Add More Attributes"
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["0"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel
                    header="SPECIAL FEATURES"
                    key="1"
                    style={this.customPanelStyle}
                  >
                    <Row
                      gutter={[24, 24]}
                      style={{ margin: "10px 0px 10px 0px" }}
                    >
                      <Col sm={24}>
                        <Attributes
                          initialdata={this.state.features || []}
                          sendList={this.getFeaturesData}
                          addbuttontitle="Add More Features"
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Row
                  gutter={[24, 24]}
                  style={{ margin: "0px", minHeight: "230px" }}
                >
                  <Col style={{ padding: "0px" }} sm={24}>
                    <FormHeader header="Product Description" />
                  </Col>
                  <Col sm={24} className="editorBox-container">
                    <EditorBox
                      value={product_description}
                      getText={this.getEditorText}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ channelProducts }) => {
  return {
    ...channelProducts,
  };
};

export default connect(mapStateToProps, {
  fetchAmazonUk,
  handelChange,
  saveUkForm,
})(AmazonUkForm);
