import React, { Component } from "react";
import { Row, Col, Button, Affix, Tooltip, message, Upload, Icon, Spin } from "antd";
import FormHeader from "../components/datainputs/FormHeader";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import InputField from "../components/datainputs/InputField";
import ImageManager from "../components/datainputs/image-manager/ImageManagerCreateBaseProduct";

class FactoryProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { factory_name, factory_address, factory_email_id, factory_number, loading_port, notes } = this.props;

        let uploadButton;


        uploadButton = (
            <div>
                {!this.state.isUploading && (
                    <Icon type={this.state.loading ? "loading" : "plus"} />
                )}{" "}
                {this.state.isUploading && <Spin />}
                <div className="ant-upload-text">
                    You can drag and drop image or upload image
              </div>
            </div>
        );


        return (<React.Fragment>
            <Row style={{
                width: "95%",
                backgroundColor: "#313841",
                height: "90vh"
            }}>
                <TitleWithBreadCrumb
                    title="Factory Profile"
                    breadcrumb={["OmnyComm", "Factories", "Manufacturer", "FactoryProfile"]}
                />

                <Row gutter={[40, 40]} style={{ marginTop: "0.1px" }}>

                    <Col className="gutter-row" lg={12} sm={24}>

                        <Row gutter={[40, 32]}>
                            <Col className="gutter-row" span={24}>
                                {/* <div className="gutter-box">col-6</div> */}

                                <Row gutter={[24, 24]} className="gutter-box">
                                    <Col style={{ paddingBottom: "0px" }} sm={24}>
                                        <FormHeader header="Basic Info" />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_name",
                                                value: factory_name,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Factory Name"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_email_id",
                                                value: factory_email_id,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Factory Email ID"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_number",
                                                value: factory_number,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Factory Number"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "loading_port",
                                                value: loading_port,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Loading Port"
                                        />
                                    </Col>


                                    <Col sm={24}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_address",
                                                value: factory_address,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Factory Address"
                                        />
                                    </Col>


                                    <Col sm={24}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "notes",
                                                value: notes,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Notes"
                                        />
                                    </Col>




                                </Row>
                            </Col>


                            <Col className="gutter-row" span={24}>
                                {/* <div className="gutter-box">col-6</div> */}

                                <Row gutter={[24, 24]} className="gutter-box">
                                    <Col style={{ paddingBottom: "0px" }} sm={24}>
                                        <FormHeader header="Bank Details" />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_name",
                                                value: factory_name,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Bank Name"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_email_id",
                                                value: factory_email_id,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Bank Address"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_number",
                                                value: factory_number,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Account Number"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "loading_port",
                                                value: loading_port,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="IFSC Code"
                                        />
                                    </Col>


                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_address",
                                                value: factory_address,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Swift Code"
                                        />
                                    </Col>


                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "notes",
                                                value: notes,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Branch Code"
                                        />
                                    </Col>




                                </Row>
                            </Col>
                        </Row>

                    </Col>

                    <Col className="gutter-row" lg={12} sm={24}>
                        <Row gutter={[40, 40]}>
                            <Col span={24}>
                                <Row gutter={[24, 24]} className="gutter-box">
                                    <Col>

                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={true}
                                            beforeUpload={() => false}
                                            // fileList={this.state.fileList}
                                            // onChange={this.handleUpload}
                                            // onRemove={this.handleRemove}
                                            // disabled={this.props.category === "all_images" ? true : false}
                                            multiple={false}
                                            style={{ height: "150px" }}
                                        >

                                            {uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row gutter={[24, 24]} className="gutter-box">
                                    <Col style={{ paddingBottom: "0px" }} sm={24}>
                                        <FormHeader header="Contact Person" />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_name",
                                                value: factory_name,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Contact Person Name"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_email_id",
                                                value: factory_email_id,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Contact Person Mobile Number"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_number",
                                                value: factory_number,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Contact Person Email ID"
                                        />
                                    </Col>

                                    <Col sm={12}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "loading_port",
                                                value: loading_port,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Tag"
                                        />
                                    </Col>


                                    <Col sm={24}>
                                        <InputField
                                            label={{ type: "secondary" }}
                                            input={{
                                                size: "large",
                                                name: "factory_address",
                                                value: factory_address,
                                            }}
                                            onChange={this.handelChange}
                                            labelText="Tag Information"
                                        />
                                    </Col>



                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Row>
        </React.Fragment>);
    }
}

export default FactoryProfile;