import React, { Component } from "react";
import { Row, Col, message } from "antd";
import ProductCardExportpage from "../../cards/productcardexportpage";
import { Ellipsis } from 'react-spinners-css';
import "../modals.scss";

import isDev from "../../../../server";

const serverUrl = isDev();

class ViewProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  componentDidMount = () => {
    const bodyData = {
      export_pk: this.props.data.pk
    };
    fetch(`${serverUrl}/fetch-export-product-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status == 200) this.setState({ data: data.product_list });
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
        else
        {
          message.error("Internal Server Error!");
        }
      });
  };

  reRender = () => {
    this.props.reRenderProductCount();

    const bodyData = {
      export_pk: this.props.data.pk
    };
    fetch(`${serverUrl}/fetch-export-product-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status == 200) this.setState({ data: data.product_list });
      });
  };

  render() {
    let data_str = "";
    if (this.props.data.channel_name == "Amazon UK")
      data_str = "amazon_uk_product";
    else if (this.props.data.channel_name == "Amazon UAE")
      data_str = "amazon_uae_product";
    else if (this.props.data.channel_name == "Noon") data_str = "noon_product";
    else data_str = "ebay_product";

    return (
      <React.Fragment>
        <Row gutter={[24, 24]} type="flex" justify="start">
          {this.state.data ?
            this.state.data.map(e => (
              <Col lg={8} sm={24}>
                <ProductCardExportpage
                  image={
                    e.main_images[0] == undefined
                      ? "https://wig-wams-s3-bucket.s3.amazonaws.com/No_image_placeholder_sfhfbJv.png"
                      : e.main_images[0].thumbnail_url
                  }
                  name={e[data_str]}
                  ID={e.product_id}
                  export_pk={this.props.data.pk}
                  product_pk={e.product_pk}
                  reRender={this.reRender}
                  product_sku={e.seller_sku}
                />
              </Col>
            )) : <Row type="flex" align="middle" justify="center" style={{ width: "100%", height: "80vh" }}>
              <Ellipsis color="#1ABC9C" size={100} />
            </Row>}
        </Row>
      </React.Fragment>
    );
  }
}

export default ViewProductModal;
