import React, { Component } from "react";
import { Upload, Icon, message, Progress, Spin, Modal } from "antd";
import axios from "axios";
import "./upload-image.scss";
import { fetchProductDetails } from "../../../../actions/index";
import { Col, Button } from "antd";
import { connect } from "react-redux";
import isDev from "../../../../server";
import CopyModal from "./CopyModal";
const serverUrl = isDev();

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpgdl1";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/jpgdl1 file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

// array.slice(-1).join(' ')

class UploadImage extends Component {
  state = {
    loading: false,
    fileListLength: null,
    fileList: [],
    selectedImages: [],
    isDelete: false,
    isCopying: false,
    isUploading: false,
    loading: 0,
    uploadedImageCounter: 0
  };

  fetchOnlyImages = (len, fileList) => {
    if (len == this.state.uploadedImageCounter) {

      this.setState(prevState => ({
        fileList,
        fileListLength: prevState.fileListLength + 1,
        isUploading: false,
        uploadedImageCounter: 0
      }));
      message.success("Image(s) Uploaded!");
      this.props.fetchImagesOnly(
        this.props.product_pk,
        this.props.channel_name
      );
    }
  };

  handleUpload = ({ file, fileList }) => {
    // for(let i = 0;i<fileList.length;i++)
    // {
    //   if(file.uid === fileList[i].originFileObj.uid)
    //   {
    //     let len = i - this.state.fileList.length + 1;
    //     this.setState((prevState)=>{
    //       loading : prev
    //     })
    //   }
    // }

    if (!this.state.isDelete) {

      if(this.props.editMode)
      {
        this.setState({loading : true})
        this.props.handleUpload({file , fileList} , () => this.setState({loading : false}));
        return;
      }

      this.setState({ isUploading: true });
      let formData = new FormData();

      let noOfImages = fileList.length - this.state.fileList.length;


      //new length
      // let leng = fileList.length;

      // 

      // //set new length
      // this.setState({ fileListLength: leng });

      // extract all
      // let prevLength = this.state.fileList.length;
      // let currLength = fileList.length;
      // for (let i = prevLength; i < currLength; i++) {
      //   let ind = i - prevLength;
      //   formData.append(`image_${ind}`, fileList[i].originFileObj);
      // }
      // formData.append("product_pk", this.props.product_pk);
      // formData.append("image_count", currLength - prevLength);
      // formData.append("image_category", this.props.category);
      // formData.append(
      //   "channel_name",
      //   this.props.channel_name ? this.props.channel_name : ""
      // );



      // extract one
      formData.append(`image_0`, file);

      formData.append("product_pk", this.props.product_pk);
      formData.append("image_count", 1);
      formData.append("image_category", this.props.category);
      formData.append(
        "channel_name",
        this.props.channel_name ? this.props.channel_name : ""
      );

      //set fileList to state




      axios
        .post(`${serverUrl}/upload-product-image/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        })
        .then(res => {

          // if (file.uid === fileList[fileList.length - 1].originFileObj.uid) {
          //   
          //   this.setState(prevState => ({
          //     fileList,
          //     fileListLength: prevState.fileListLength + 1,
          //     isUploading: false
          //   }));
          //   message.success("Image(s) Uploaded!")
          //   this.props.fetchImagesOnly(this.props.product_pk);
          // }

          this.setState(prevState => ({
            uploadedImageCounter: prevState.uploadedImageCounter + 1
          }));

          this.fetchOnlyImages(noOfImages, fileList);

          // window.location.reload();
        })
        .catch(err => {

        });
    }
  };

  handleRemove = file => {
    this.setState({ isDelete: true });

    if(this.props.editMode)
      {
        this.setState({loading : true})
        this.props.handleRemove(file ,  () => this.setState({loading : false}));
        return;
      }

    let cate = "other";
    if (this.props.category === "main_images") cate = "main";
    else if (this.props.category === "sub_images") cate = "sub";
    
    if(cate === "other"){
        axios.post(
        `${serverUrl}/remove-image/`,
        {
          image_category: this.props.category,
          image_pk: file.pk,
          product_pk: this.props.product_pk
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {

        this.setState({
          fileList: this.state.fileList.filter(e => {
            return e.uid !== file.uid;
          })
        });
        this.props.fetchImagesOnly(
          this.props.product_pk,
          this.props.channel_name
        );
        this.setState({ isDelete: false });
      })
      .catch(err => {

        this.setState({ isDelete: false });
      });
    } else{
        axios.post(
            `${serverUrl}/delete-image/`,
            {
            image_type: cate,
            image_pk: file.pk,
            channel_name: this.props.channel_name || "",
            product_pk: this.props.product_pk || ""
            },
            {
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
            }
        )
        .then(res => {
            this.setState({
            fileList: this.state.fileList.filter(e => {
                return e.uid !== file.uid;
            })
            });
            this.props.fetchImagesOnly(
            this.props.product_pk,
            this.props.channel_name
            );
            this.setState({ isDelete: false });
        })
        .catch(err => {
            this.setState({ isDelete: false });
        });
    }
  };

  // fileList = [
  //     {
  //         uid: '-1',
  //         name: 'image.png',
  //         status: 'done',
  //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     },
  //     {
  //         uid: '-2',
  //         name: 'image.png',
  //         status: 'done',
  //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     },
  //     {
  //         uid: '-3',
  //         name: 'image.png',
  //         status: 'done',
  //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     },
  //     {
  //         uid: '-4',
  //         name: 'image.png',
  //         status: 'done',
  //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     },
  //     {
  //         uid: '-5',
  //         name: 'image.png',
  //         status: 'error',
  //     },
  // ]

  flag = true;

  componentWillReceiveProps = nextProps => {

    console.log(nextProps);

    if(nextProps.productDetails && nextProps.productDetails.images && this.flag)
    {
      console.log(nextProps , "NP")
      this.setState({
        selectedImages : nextProps.productDetails && nextProps.productDetails.images && nextProps.productDetails.images.best_images
      })

      this.flag = false;
    }

    if (nextProps.fileList)
      this.setState({
        fileListLength: nextProps.fileList.length
      });

    let fileList = [];



    if (nextProps.fileList) {
      for (let i = 0; i < nextProps.fileList.length; i++) {
        fileList = [
          ...fileList,
          {
            uid: i,
            name: "",
            status: "done",
            hdurl: nextProps.fileList[i].main_url || nextProps.fileList[i].url,
            url: nextProps.fileList[i].midimage_url || nextProps.fileList[i].url,
            pk: nextProps.fileList[i].pk
          }
        ];
      }
    }

    this.setState({ fileList });
  };

  handlePreview=(file)=>{
    window.open(file.hdurl,'_blank')
  }

  handleConfirmCopy = () => {
      this.setState({isCopying: true});
      let tempImageData = [];
      for(let i=0; i<this.state.selectedImages.length; i++){
          tempImageData=[
              ...tempImageData,
              this.state.selectedImages[i].pk
          ]
      }

      const payloadData = {
          image_pk_list: tempImageData,
          product_pk: this.props.product_pk
      }
      
    axios.post(
      `${serverUrl}/copy-best-images/`, payloadData,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      }
    )
    .then(res => {
        this.setState({isCopying: false})
        if(res.status === 200){
            // this.setState({selectedImages: []});
            this.props.handleVisibility(false);
            message.success("Image(s) Copied Successfully!");
            this.props.fetchImagesOnly(
                this.props.product_pk,
                this.props.channel_name
            );
        } else{
            message.error("Error Occured");
        }
    })
  }

  onPick = (images) => {
    let tempList = this.props.productDetails.images["all_images"];  
    let tempList_copy = [];
    for(let i = 0;i<images.length;i++)
    {
      for(let j = 0;j<tempList.length;j++)
      {
        if(images[i].src === tempList[j].thumbnail_url)
        {
          tempList_copy.push(tempList[j]);
          break;
        }
      }
    }
    // tempList = tempList.filter(
    //     (image) => {
    //       for(let i = 0;i<images.length;i++)
    //       {
    //         if(images[i].src === image.thumbnail_url)
    //         return true;
    //       }
    //       return false;
    //     }
    // );
    console.log(tempList_copy , this.state.selectedImages);
    this.setState({selectedImages: [...this.props.productDetails.images.best_images , ...tempList_copy]})
  }

  render() {

    let uploadButton;

    let uploadButtonPrevent;

    if (this.props.showUploadList || this.state.imageUrl == undefined)
      uploadButton = (
        <div>
          {!this.state.isUploading && (
            <Icon type={this.state.loading ? "loading" : "plus"} />
          )}{" "}
          {this.state.isUploading && <Spin />}
          <div className="ant-upload-text">
            You can drag and drop images or upload images
          </div>
        </div>
      );
    else
      uploadButton = (
        <img src={this.state.imageUrl} alt="avatar" style={{ width: "100%" }} />
      );
    if (this.props.showUploadList || this.state.imageUrl == undefined)
      uploadButtonPrevent = (
        <div>
          <Icon
            style={{ color: "red" }}
            type={this.state.loading ? "loading" : "warning"}
          />
          <div className="ant-upload-text" style={{ color: "red" }}>
            You cannot upload images for all images section. Please select
            another category
          </div>
        </div>
      );
    else
      uploadButtonPrevent = (
        <img src={this.state.imageUrl} alt="avatar" style={{ width: "100%" }} />
      );

    

      console.log(this.props.productDetails.images)

    return (
      <React.Fragment>
        {/* {this.state.isUploading && <Progress percent={this.state.loading} />} */}

        {/* {this.state.fileList.map((e, index) => {
          if (e.url) return <img key={index} src={e.url} />;
          
          return <img key={index} src={e.thumbUrl} />;
        })} */}

        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={this.props.showUploadList}
          beforeUpload={() => false}
          fileList={this.state.fileList}
          onPreview={this.handlePreview}
          onChange={this.handleUpload}
          onRemove={this.props.handleRemove || this.handleRemove}
          disabled={this.props.category === "all_images" || this.props.disabled}
          multiple={true}
          id="aditional-button"
          style={{ height: "150px" }}
        >
          {this.props.category === "all_images" && uploadButtonPrevent}
          {this.props.category !== "all_images" && uploadButton}
        </Upload>
        <Modal
            visible={this.props.modalVisible}
            width={1200}
            onCancel={() => {this.props.handleVisibility(false);
              this.setState({
                selectedImages : this.props.productDetails.images.best_images
              })
            }}
            title={"Choose Images to copy"}
            okText={"Confirm"}
            onOk={this.handleConfirmCopy}
            confirmLoading={this.state.isCopying}
            destroyOnClose={true}>
            <CopyModal 
                bucketName={this.props.category} 
                imageList={this.props.productDetails.images} 
                onPick={this.onPick.bind(this)} 
                selectedImages={this.state.selectedImages}
                previousBestImages = {this.props.productDetails && this.props.productDetails.images && this.props.productDetails.images.best_images}
                />
        </Modal>
        {/* {this.props.category == "all_images" ? (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={this.props.showUploadList}
            beforeUpload={() => false}
            fileList={this.state.fileList}
            onChange={this.handleUpload}
            onRemove={this.handleRemove}
            disabled
            className="upload-select"
            style={{ height: "150px" }}
          >
            {uploadButtonPrevent}
          </Upload>
        ) : (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={this.props.showUploadList}
            beforeUpload={() => false}
            fileList={this.state.fileList}
            onChange={this.handleUpload}
            onRemove={this.handleRemove}
            multiple={true}
            className="upload-select"
            style={{ height: "150px" }}
          >
            {uploadButton}
          </Upload>
        )} */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails
  };
};

export default connect(mapStateToProps, { fetchProductDetails })(UploadImage);
