import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../views/dashboard/Dashboard";

/**
 * @description FactoryUserRoute is a class component 
 * which on render returns 
 * Dashboard component with different updated props 
 * based on different URL paths.
 */
export default class FactoryUserRoute extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>

        <Route
            path="/"
            exact
            render={(props) => (
              <Dashboard {...props} page="factory-products-factorywise" factoryUser factory_uuid = {this.props.factory_uuid} />
            )}
          />

        <Route
            path="/factory-products"
            exact
            render={(props) => (
              <Dashboard {...props} page="factory-products-factorywise" factoryUser factory_uuid = {this.props.factory_uuid} />
            )}
          />

        <Route 
            path = "/create-factory-product/:factory_uuid"
            exact
            render = {
              (props) => (
                <Dashboard {...props} factoryUser page = "create-factory-product" />
              )
            }
          >
        </Route>

          <Route 
            path = "/edit-factory-product/:factory_product_uuid"
            exact
            render = {
              (props) => (
                <Dashboard {...props} factoryUser page = "edit-factory-product" />
              )
            }
          >
          </Route>

            <Route
            render={() => (
              <p className="text-center">
               you are not allowed here
              </p>
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}
