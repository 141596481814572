import React, { useState, useEffect } from "react";
import RightWrapper from "my-components/ui-components/RightWrapper";
import {
  Row,
  Col,
  Button,
  Checkbox,
  Typography,
  Icon,
  Tooltip,
  Drawer,
  Select,
  message,
  Spin,
  Pagination,
  Modal,
} from "antd";
import TitleWithBreadCrumb from "views/components/titlewithbreadcrumb/TitleWithBreadCrumb";
import DatePicker from "react-datepicker";
import axios from "axios";
import { Link } from "react-router-dom";
import isDev from "server";
import "./customers.scss";

const { Text } = Typography;
const { Option } = Select;

const serverDealsHubUrl = isDev(true);

function FilterDrawer(props) {
  const [dateType, setDateType] = useState("custom");
  const [b2bVerified, setB2bVerified] = useState(null);

  return (
    <Modal
      // placement="right"
      // closable={false}
      // onClose={this.onClose}
      okText="Apply"
      onCancel={props.closeThis}
      onOk={() => {
        props.applyFilters({
          "b2b-verified": b2bVerified,
        });
        props.closeThis();
      }}
      title="Apply Filter"
      visible={props.visible}
      style={{ display: "flex", justifyContent: "center" }}
      width="30%"
    >
      <Row
        style={{
          position: "relative",
        }}
        gutter={[24, 24]}
      >
        {/* <Col span = {24}>
                <Row type = "flex" justify = "space-between" slign = "middle">
                    <Text style = {{color : "white" , fontSize : "20px"}}>Apply Filter</Text>
                    <Icon type="close" style = {{fontSize : "20px" , cursor : "pointer" , color : "white"}} onClick = {props.closeThis} />
                </Row>
            </Col> */}

        <Col span={12}>
          <Text>Customer Status</Text>
          <Select
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Select a person"
            optionFilterProp="children"
            value={b2bVerified}
            onChange={(value) => setB2bVerified(value)}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={null}>All Customers</Option>
            <Option value={true}>Only Verified Customer Orders</Option>
            <Option value={false}>Only Non Verified Customer Orders</Option>
          </Select>
        </Col>

        {/* <Col span = {12}>
                <Text>Date</Text>
                
                {
                    dateType == "custom" ? (
                        <React.Fragment>
                             <DatePicker
                                    // size={this.props.size}
                                    className="custom-datePicker"
                                    style={{ backgroundColor: "#36404A", width: "100%" }}
                                    // selected={this.state.startDate}
                                    // onChange={this.handleStartDateChange}
                                />
                        </React.Fragment>
                    ) :
                    (
                        <Select
                            showSearch
                            style={{ width: "100%"}}
                            size = "large"
                            placeholder="Select a person"
                            optionFilterProp="children"
                            // onChange={onChange}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="tom">Tom</Option>
                        </Select>
                    )
                }

            </Col> */}

        {/* <div style = {{position : "absolute" , bottom : "20px" , right : "60px"}}>
                <Button type = "primary" onClick = {() => {
                    props.applyFilters({
                        "b2b-verified" : b2bVerified
                    })
                }}>Apply Filters</Button>
            </div> */}
      </Row>
    </Modal>
  );
}

function CustomerRow({ customer, locationUuid }) {
  return (
    <Row
      gutter={[12, 0]}
      style={{
        backgroundColor: "#36404a",
        padding: "20px",
        position: "relative",
      }}
    >
      <Col span={1}>
        <Checkbox />
      </Col>
      <Col span={8}>
        <Link
          to={`/b2b-customers-details/${locationUuid}/${customer.username}`}
        >
          <Text style={{ color: "#69CAAA", fontSize: "16px" }}>
            {customer.companyName}
          </Text>
        </Link>
        <br />
        {/* <Icon type="check" style = {{color : "#69CAAA" , fontSize : "10px"}} /> */}
        {/* <Text style = {{color : "white" , fontSize : "12px" , paddingLeft : "5px" , fontWeight : "lighter"}}>Customer ID:</Text>
                <Text style = {{color : "white" , fontSize : "12px" , paddingLeft : "5px"}}>1820 abcd 1001 aasw</Text> */}
      </Col>
      <Col span={6}>
        <Text style={{ color: "white" }}>{customer.name}</Text>
        <Tooltip
          placement="bottom"
          title={
            <React.Fragment>
              <Text style={{ color: "white" }}>{customer.name}</Text>
              <br />
              <Text style={{ color: "white", fontSize: "12px" }}>
                {customer.contactNumber}, {customer.emailId}
              </Text>
            </React.Fragment>
          }
        >
          <Icon
            type="info-circle"
            style={{ padding: "5px", color: "white", cursor: "pointer" }}
          />
        </Tooltip>
        <br />
        <Text style={{ color: "white", fontSize: "12px" }}>
          {customer.date_created}
        </Text>
      </Col>
      <Col span={5}>
        <Text style={{ color: "white" }}>{customer.total_items}</Text>
      </Col>
      <Col span={4}>
        <Text style={{ color: "white" }}>{customer.total_qty}</Text>
      </Col>

      {/* <div style = {{position : "absolute" , right : "20px"}}>
                <Icon type="more" style = {{fontSize : "16px" , color : "white"}} />
            </div> */}
    </Row>
  );
}

function InnerWrapper(props) {
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [tags, setTags] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const fetchCustomers = (
    filters = {
      is_feedback_available: "",
      is_cart_empty: "",
      b2b_verified: "",
    },
    tags = [],
  ) => {
    setLoading(true);
    const data = {
      page: page,
      search_list: tags,
      filter_parameters: filters,
      locationGroupUuid: props.locationUuid,
    };
    axios
      .post(`${serverDealsHubUrl}/dealshub/fetch-customer-list/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status == 200) {
          setTotalCustomers(response.data.total_customers);
          setCustomers(response.data.customerList);
        } else {
          message.error("Internal Server Error!");
        }
      });
  };

  const applyFilters = (filters) => {
    setFilters(filters);
    fetchCustomers(filters, tags);
  };

  useEffect(() => {
    fetchCustomers();
  }, [page]);

  const handleChangeSearchTags = (tags, page) => {
    setTags(tags);
    fetchCustomers(filters, tags);
  };

  return (
    <React.Fragment>
      <TitleWithBreadCrumb
        title={`B2B Customers`}
        breadcrumb={[]}
        rightPortion={
          <React.Fragment>
            <Row type="flex" justify="end" gutter={[8, 8]}>
              <Col span={20}>
                {/* <Button type="primary" icon="search" ghost />
                 */}
                <Select
                  dropdownClassName="search-chips"
                  dropdownMatchSelectWidth="false"
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder={[<Icon type="search" />, " Search here..."]}
                  onChange={(e) => handleChangeSearchTags(e, page)}
                  value={tags}
                ></Select>
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon="filter"
                  ghost
                  onClick={() => {
                    setVisibleFilterDrawer(true);
                  }}
                />
              </Col>
              {/* <Col>
                             <Button type="primary">ADD CUSTOMERS</Button>
                            </Col> */}
            </Row>
          </React.Fragment>
        }
      />

      {/* <Row type = "flex" style = {{padding : "0px 35px"}} justify = "space-between" align = "middle">
                <Col span = {16}>
                    <Text style = {{
                        backgroundColor : "white",
                        padding : "5px 20px 5px 10px",
                        fontSize : "12px",
                        position : "relative"
                    }}>Some Text
                        <Icon style = {{position : "absolute" , right : "5px" , top : "7px" , fontSize : "10px"}} type = "close"/>
                    </Text>
                </Col>

                <Col span = {4}>
                    <Row type = "flex" justify = "end">
                        <Button type = "primary" ghost>Restore To Default</Button>
                    </Row>
                </Col>
            </Row> */}

      {loading ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ padding: "35px" }}
          gutter={[12, 12]}
        >
          <Spin />
        </Row>
      ) : (
        <Row style={{ padding: "35px" }} gutter={[12, 12]}>
          {/* table head */}
          <Col span={24}>
            <Row
              gutter={[12, 0]}
              style={{
                padding: "0px 20px",
              }}
            >
              <Col span={1}>
                <Checkbox />
              </Col>
              <Col span={8}>
                <Text>Customer Details</Text>
              </Col>
              <Col span={6}>
                <Text>JOINED ON</Text>
              </Col>
              <Col span={5}>
                <Text>ITEMS PURCHASED</Text>
              </Col>
              <Col span={4}>
                <Text>TOTAL QUANTITY</Text>
              </Col>
            </Row>
          </Col>

          {customers.map((item, index) => {
            return (
              <Col key={index} span={24}>
                <CustomerRow
                  customer={item}
                  locationUuid={props.locationUuid}
                />
              </Col>
            );
          })}
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Pagination
              total={totalCustomers}
              current={page}
              defaultPageSize={20}
              onChange={(p) => {
                setPage(p);
              }}
            />
          </Col>
        </Row>
      )}

      <FilterDrawer
        visible={visibleFilterDrawer}
        applyFilters={applyFilters}
        closeThis={() => {
          setVisibleFilterDrawer(false);
        }}
      />
    </React.Fragment>
  );
}

function CustomersB2B(props) {
  return (
    <React.Fragment>
      <RightWrapper
        innerwrapperTransparent
        InnerWrapper={
          <React.Fragment>
            <InnerWrapper {...props} />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}

export default CustomersB2B;
