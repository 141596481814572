import {
    contactNumberValidation,
    emailValidation,
    mobileNumberFormat,
    mobileNumberValidation,
    trailingSpacesValidation,
    leadingSpacesValidation,
    middleSpacesValidation,
    linkValidation
} from './RegexValidators';

export function validateContactNumber(contactNumberString) {
    return (
        contactNumberString !== undefined &&
        contactNumberValidation.contactNumberRegex.test(contactNumberString)
    ) ? true : false;
}

export function validateEmail(emailString){
    return (
        emailString !== undefined &&
        emailValidation.emailRegex.test(emailString)
    ) ? true : false;
}

export function validateMobileNumber(mobileString){
    return (
        mobileString !== undefined &&
        mobileNumberValidation.mobileNumberRegex.test(mobileString)
    ) ? true : false;
}

export function validateMobileNumberFormat(mobileString) {
    return (
        (!isNaN(mobileString) && mobileNumberFormat.mobileNumberFormatRegex.test(mobileString)) || 
        (mobileString === '' && mobileString !== undefined)
    ) ? true : false;
}

export function validateTrailingSpaces(emailString) {
    return (
        emailString !== undefined && 
        trailingSpacesValidation.trailingSpacesRegex.test(emailString)
    ) ? true : false;
}

export function validateLeadingSpaces(emailString) {
    return (
        emailString !== undefined && 
        leadingSpacesValidation.leadingSpacesRegex.test(emailString)
    ) ? true : false;
}

export function validateMiddleSpaces(emailString) {
    return (
        emailString !== undefined && 
        middleSpacesValidation.middleSpacesRegex.test(emailString)
    ) ? true : false;
}

export function validateLink(linkString) {
    return(
        linkString !== undefined && 
        (linkString.length ? (linkValidation.linkRegex.test(linkString) ? true : false) : true)
    ) ? true : false;
}