import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../views/dashboard/Dashboard";
import HomePage from "../home-page/HomePage";
import PlatformContentPage from "../views/platform-content-page/PlatformContentPage";
import ViewPiPage from "../views/view-pi-page/ViewPiPage";
import PlayGround from "../views/offline-order/PlayGround";
import isDev from "server";
const serverUrl = isDev();

/**
 * @description This is a class component ProtectedRoute if user is logged in and the component is mount
 * it returns 'Dashboard' component with different props based on the route.
 */
export default class ProtectedRoute extends Component {


  componentDidMount = () => {
    // fetch(`${serverUrl}/dealshub/fetch-user-website-group/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `JWT ${localStorage.getItem("token")}`
    //   },
    //   body: JSON.stringify({
    //     userName: localStorage.getItem("username")
    //   })
    // })
    //   .then(res => res.json())
    //   .then(brandData => {
    //     localStorage.setItem(
    //       "websiteGroupName",
    //       brandData.websiteGroupName
    //     );
    //   })
    //   .catch(error => {

    //   });
  }

  render() {
    return (
		<BrowserRouter>
			<Switch>
				<Route
					path="/"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="user" />
					)}
				/>

				<Route
					path="/categories-details"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="categories-details"
						/>
					)}
				/>

				<Route
					path="/admin-categories-details"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="admin-categories-details"
						/>
					)}
				/>
				<Route
					path="/activity-logs"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="admin-activity-logs"
						/>
					)}
				/>
				<Route
					path="/activity-logs/:uuid?"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="activity-logs"
						/>
					)}
				/>
				<Route
					path="/review-management/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="review-management"
						/>
					)}
				/>
				<Route
					path="/company-profile/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="company-profile"
						/>
					)}
				/>
				<Route
					path="/factory-profile-for-sourcing/:factory_pk"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="factory-profile-for-sourcing"
						/>
					)}
				/>
				<Route
					path="/user"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="user" />
					)}
				/>

				<Route
					path="/user-management"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="user-management" />
					)}
				/>

				<Route
					path="/deals-hub-admin-vouchers/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="deals-hub-admin-vouchers"
						/>
					)}
				/>

				<Route
					path="/deals-hub-admin-settings/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="deals-hub-admin-settings"
						/>
					)}
				/>

				<Route
					path="/customers/:uuid"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="customers" />
					)}
				/>

				<Route
					path="/seo-admin/:uuid"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="seo-admin" />
					)}
				/>

				<Route
					path="/notifications"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="notifications"
						/>
					)}
				/>

				<Route
					path="/b2b-customers/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="b2b-customers"
						/>
					)}
				/>
				<Route
					path="/b2b-customers-details/:uuid/:username"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="b2b-customers-details"
						/>
					)}
				/>

				<Route
					path="/flyers"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="flyers" />
					)}
				/>
				<Route
					path="/activity-history"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="activity-history"
						/>
					)}
				/>
				<Route
					path="/orders"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="orders" />
					)}
				/>

				<Route
					path="/customers-old"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="customers-old"
						/>
					)}
				/>

				<Route
					path="/invoice"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="invoice" />
					)}
				/>
				<Route
					path="/deals-hub-admin-category"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="deals-hub-admin-category"
						/>
					)}
				/>

				<Route
					path="/products"
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="products" />
					)}
				/>

				<Route
					path="/create-new-product"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="create-new-product"
						/>
					)}
				/>

				<Route
					path="/exports"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="exports" />
					)}
				/>

				<Route
					path="/oc-reports"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="reports-generalized"
						/>
					)}
				/>

				<Route
					path="/brands"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="brands"
						/>
					)}
				/>	

				<Route
					path="/location-reports/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="location-reports"
						/>
					)}
				/>

				<Route
					path="/reports"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="reports" />
					)}
				/>

				<Route
					path="/view-report/:report_pk"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="view-report" />
					)}
				/>

				<Route
					path="/ecommerce/amazon-uk"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							channelname="Amazon UK"
							codeChannelName="amazon-uk"
							page="ecom-uk"
						/>
					)}
				/>

				<Route
					path="/ecommerce/amazon-uae"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							channelname="Amazon UAE"
							codeChannelName="amazon-uae"
							page="ecom-uae"
						/>
					)}
				/>

				<Route
					path="/ecommerce/ebay"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							channelname="Ebay"
							codeChannelName="ebay"
							page="ecom-ebay"
						/>
					)}
				/>

				<Route
					path="/ecommerce/noon"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							channelname="Noon"
							codeChannelName="noon"
							page="ecom-noon"
						/>
					)}
				/>

				<Route
					path="/edit-product/:userId"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="edit-product"
						/>
					)}
				/>

				<Route
					path="/edit-ecommerce-product/:userId/:locationUuid/:b2b?"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="edit-ecommerce-product"
						/>
					)}
				/>

				<Route path="/play-ground" render={(props) => <PlayGround />} />

				<Route
					path="/publish-products/:channelName"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="publish-product"
						/>
					)}
				/>

				<Route
					path="/pushing-inventory/:channelName"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="pushing-inventory"
						/>
					)}
				/>

				<Route
					path="/pushing-prices/:channelName"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="pushing-prices"
						/>
					)}
				/>

				<Route
					path="/edit-base-product/:base_product_pk"
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="edit-base-product"
						/>
					)}
				/>

				<Route
					path="/add-new-variant/:base_product_pk"
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="create-new-variant"
						/>
					)}
				/>

				<Route
					path="/edit-channel-product/:channel_name/:product_pk"
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="edit-channel-product"
						/>
					)}
				/>

				<Route
					path="/product-details/:product_pk"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="product-details"
						/>
					)}
				/>
				<Route
					path="/edit-brand-details/:brandPk"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="edit-brand-details"
						/>
					)}
				/>
				<Route
					path="/create-brand"
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="create-brand"
						/>
					)}
				/>
				<Route
					path="/deals-hub-admin-sections/:uuid/:name"
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="deals-hub-admin-sections"
						/>
					)}
				/>
				<Route
					path="/deals-hub-admin-banners/"
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="deals-hub-admin-banners"
						/>
					)}
				/>

				<Route
					path="/deals-hub-admin-products/:uuid/:siteInfo/:b2b?"
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="deals-hub-admin-products"
						/>
					)}
				/>
				<Route
					path="/order-details/:locationUuid/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="order-details"
						/>
					)}
				/>

				<Route
					path="/order-cancellation/:locationUuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="order-cancellation"
						/>
					)}
				/>
				<Route
					path="/employee-analytics/:locationUuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="employee-analytics"
						/>
					)}
				/>

				<Route
					path="/order-requests/:locationUuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="order-requests"
						/>
					)}
				/>

				<Route
					path="/factories"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="factories" />
					)}
				/>
				<Route
					path="/categoryimage"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="categoryimage"
						/>
					)}
				/>
				<Route
					path="/blogs/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="blogs"
						/>
					)}
				/>
				<Route
					path="/compose-article/:blogId/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="compose-article"
						/>
					)}
				/>
				<Route
					path="/blog-sections/:uuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="blog-sections"
						/>
					)}
				/>
				<Route
					path="/factory-products"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="factory-products"
						/>
					)}
				/>

				<Route
					path="/factory-products/:factory_uuid"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="factory-products-factorywise"
						/>
					)}
				/>

				{/* <Route
            path = "/create-factory-product/:factory_uuid"
            exact
            render = {
              (props) => (
                <Dashboard {...props} page = "create-factory-product" />
              )
            }
          >
          </Route> */}

				<Route
					path="/edit-factory-product/:factory_product_uuid"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="edit-factory-product"
						/>
					)}
				></Route>

				<Route
					path="/draft-pi"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="draft-pi" />
					)}
				/>
				<Route
					path="/bundle-pi"
					exact
					render={(props) => (
						<Dashboard omnyCommUser {...props} page="bundle-pi" />
					)}
				/>

				<Route
					path="/factory-page"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="factory-page"
						/>
					)}
				/>
				<Route
					path="/customer-details/:emailId"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="customer-details"
						/>
					)}
				/>
				<Route
					path="/proforma-invoice"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="proforma-invoice"
						/>
					)}
				/>

				<Route
					path="/orders-warehouse-manager/:uuid"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="orders-warehouse-manager"
						/>
					)}
				/>

				<Route
					path="/posta-plus-tracking/:uuid"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="posta-plus-tracking"
						/>
					)}
				/>

				<Route
					path="/orders-accounts-manager/:uuid"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="orders-accounts-manager"
						/>
					)}
				/>
				<Route
					path="/orders-accounts-manager/:uuid/:b2b"
					exact
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="orders-accounts-manager"
						/>
					)}
				/>
				<Route
					path="/factory-cards/:piBundleUuid"
					exact
					render={(props) => (
						<Dashboard
							omnyCommUser
							{...props}
							page="factory-cards"
						/>
					)}
				/>
				<Route exact path="/home" component={HomePage} />
				<Route exact path="/platform" component={PlatformContentPage} />

				<Route
					exact
					path="/view-pi/:piUuid"
					render={(props) => <ViewPiPage {...props} />}
				/>

				<Route
					path="/deals-hub-admin-category-slider/:uuid"
					render={(props) => (
						<Dashboard
							{...props}
							omnyCommUser
							page="deals-hub-admin-category-slider"
						/>
					)}
				/>

				{/* <Route exact path="/factory-cards" component={FactoryCardPage} /> */}

				<Route
					render={() => (
						<p className="text-center">
							O oo !! <br /> I Think You Are Lost
						</p>
					)}
				/>
			</Switch>
		</BrowserRouter>
	);
  }
}
