import React, { Component } from "react";
import { Row, Col, AutoComplete, Avatar, Card, Tooltip, Input, Switch, Upload, message, Icon, DatePicker, Button, Spin, Typography , Radio } from "antd";
import moment from "moment";
import EditableProductAttributes from "../generic-promotional-engine/EditableProductAttributes";
import placeHolderImage from "media/placeholder3.png"
import axios from "axios";
import { connect } from "react-redux";
import styled from "styled-components"
import {
    handleUpdate,
    deleteBanner,
    publishBanner,
    unpublishBanner,
    handlePromoChange
} from "../../../actions/dealsHubAdmin";
import "./generic-banner.scss";
import isDev from "../../../server";

const serverUrl = isDev();
const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = AutoComplete;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const UploadBtn = styled(Upload)`
 && {
    .ant-upload {
        height:0px !important;
        width: 100% !important;
    }
    span {
      width : 100% !important;
    }
  }`

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class GenericBannerModalContent extends Component {
    rangePickerDateFormat = "YYYY-MM-DD HH:mm:ss";

    constructor(props) {
        super(props);
        this.state = {
            spinnerLoading: false,
            searchListing: [],
            addedProducts: null,
            requiredBannerImagesObj: null,
            fileList: [],
            hoverFileList:[],
            mobileImageUploadingInProgress: false,
            hoverImageUploadingInProgress:false,
            isDeleteOn: false,
            isDeleteOnWebImage : false,
            isDeleteOnHover: false,
            promotionActive: false,
            promotionStartDate: null,
            promotionEndDate: null,
            promotionalTag: null,
            promoDetailsUpdationInProgress: false,
            productListUpdationinProgress: false,
            is_english_language : true
        };
    }

    componentDidMount = () => {
        this.setState({
            spinnerLoading: true
        }, () => {
            this.setState({ addedProducts: this.getAddedProducts() }, () => {
                this.setState({
                    spinnerLoading: false
                });
            });
        });
    }

    setProducts = () => {
        
    }

    getAddedProducts = () => {
        let temp = null, length = this.props.dealsHubAdmin.length, index1 = null, index2 = null;

        for (index1 = 0; index1 < length; ++index1) {
            if (this.props.dealsHubAdmin[index1].uuid == this.props.bannerData.uuid) {
                let tempAdminBanner = this.props.dealsHubAdmin[index1].bannerImages;
                let tempAdminBannerLength = this.props.dealsHubAdmin[index1].bannerImages.length;
                for (index2 = 0; index2 < tempAdminBannerLength; ++index2) {
                    if (tempAdminBanner[index2].uid === this.props.modalFileUid) {
                        this.setState({
                            requiredBannerImagesObj: tempAdminBanner[index2],
                            promotionActive: tempAdminBanner[index2]["is_promotional"],
                            promotionStartDate: tempAdminBanner[index2]["start_time"],
                            promotionEndDate: tempAdminBanner[index2]["end_time"],
                            promotionalTag: tempAdminBanner[index2]["promotion_tag"],
                        });
                        temp = tempAdminBanner[index2];
                        break;
                    }
                }
                if (temp !== null) {
                    break;
                }
            }
        }
        return temp;
    }

    autocompleteSearch = (searchString) => {
        if (searchString !== "") {
            axios.post(`${serverUrl}/dealshub/search-section-products-autocomplete/`, {
                websiteGroupName: localStorage.getItem("websiteGroupName"),
                searchString: searchString,
                sectionUuid: this.state.requiredBannerImagesObj.uid,
                type: "Banner",
                locationGroupUuid : this.props.locationUuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.data.status === 200) {
                    let tempList = [];
                    response.data.productList.map(list => (
                        tempList.push(
                            <Option key={list.uuid} value={list.uuid}>{list.name}</Option>
                        )
                    ));
                    this.setState({
                        searchListing: [...[], ...tempList]
                    });
                } 
                else if(response.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Something went Wrong!");
                }
            });
        } else {
            this.setState({
                searchListing: [...[], ...[]]
            })
        }
    }

    bulkUpload = event => {
        this.setState({
            spinnerLoading: true
        });

        let tempUuid = event.target.getAttribute("id");
        let uuid = tempUuid.substr(0, tempUuid.indexOf("#"));
        let formData = new FormData();
        let fileToBeUploaded = document.getElementById(uuid);
        formData.append("import_file", fileToBeUploaded.files[0]);
        formData.append("uuid", uuid.substr(0, uuid.indexOf("$")));
        axios
            .post(`${serverUrl}/dealshub/section-bulk-upload/`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
            .then(response => {
                let length = this.props.dealsHubAdmin.length;
                let temp = this.props.dealsHubAdmin[length - 1]["products"];
                temp = response.data.products;
                for (let pro in this.props.dealsHubAdmin) {
                    if (
                        this.props.dealsHubAdmin[pro].uuid ==
                        uuid.substr(0, uuid.indexOf("$"))
                    ) {
                        this.props.dealsHubAdmin[pro]["products"] = temp;
                        break;
                    }
                }

                this.setState({
                    spinnerLoading: false
                });
            });
        this.props.handleUpdate(this.props.dealsHubAdmin);
    };

    removeProduct = (productUuid) => {
        this.setState({
            spinnerLoading: true
        });

        axios.post(`${serverUrl}/dealshub/delete-product-from-unit-banner/`, {
            unitBannerImageUuid: this.props.modalFileUid,
            productUuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                let length = this.props.dealsHubAdmin.length, newProducts = [],
                    bannerIndex = null,
                    bannerFileIndex = null,
                    bannerProductIndex = null;

                for (let index = 0; index < length; ++index) {
                    if (this.props.dealsHubAdmin[index].uuid === this.props.bannerData.uuid) {
                        bannerIndex = index;
                        let tempAdminBanner = this.props.dealsHubAdmin[index].bannerImages;
                        let tempAdminBannerLength = this.props.dealsHubAdmin[index].bannerImages.length;

                        for (let index2 = 0; index2 < tempAdminBannerLength; ++index2) {
                            if (tempAdminBanner[index2].uid === this.props.modalFileUid) {

                                for (let index3 = 0; index3 < tempAdminBanner[index2].products.length; ++index3) {
                                    if (tempAdminBanner[index2].products[index3].uuid === productUuid) {
                                        bannerProductIndex = index3;
                                        break;
                                    }
                                }

                                bannerFileIndex = index2;
                                break;
                            }
                        }
                        if (bannerIndex !== null) {
                            break;
                        }
                    }
                }

                let tempvar = this.props.dealsHubAdmin[bannerIndex].bannerImages[bannerFileIndex].products;
                tempvar.splice(bannerProductIndex, 1);

                // this.props.dealsHubAdmin[bannerIndex]["bannerImages"][bannerProductIndex]["products"] = newProducts;
                this.props.handleUpdate(this.props.dealsHubAdmin);
                this.setState({
                    spinnerLoading: false
                });
                message.success('Product Deleted Successfully!');
            } 
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else {
                message.error('Something went wrong!');
            }
        });
    };

    addProduct = (productUuid, unitBannerImageUuid) => {
        this.setState({
            productListUpdationinProgress: true
        });

        axios.post(`${serverUrl}/dealshub/add-product-to-unit-banner/`, {
            unitBannerImageUuid,
            productUuid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                let length = this.props.dealsHubAdmin.length, bannerIndex = null, bannerProductIndex = null;

                for (let index = 0; index < length; ++index) {
                    if (this.props.dealsHubAdmin[index].uuid == this.props.bannerData.uuid) {
                        let tempAdminBanner = this.props.dealsHubAdmin[index].bannerImages;
                        let tempAdminBannerLength = this.props.dealsHubAdmin[index].bannerImages.length;

                        for (let index2 = 0; index2 < tempAdminBannerLength; ++index2) {
                            if (tempAdminBanner[index2].uid === this.props.modalFileUid) {
                                bannerIndex = index;
                                bannerProductIndex = index2;
                                break;
                            }
                        }
                        if (bannerIndex !== null) {
                            break;
                        }
                    }
                }
                console.log(this.props, 'this are the props from the generic banner modal content')
                if(!this.props.dealsHubAdmin[bannerIndex]["bannerImages"][bannerProductIndex]["products"])
                    this.props.dealsHubAdmin[bannerIndex]["bannerImages"][bannerProductIndex]["products"]=[]
                this.props.dealsHubAdmin[bannerIndex]["bannerImages"][bannerProductIndex]["products"].push({
                    thumbnailImageUrl: response.data.thumbnailImageUrl,
                    name: response.data.name,
                    displayId: response.data.displayId,
                    uuid: productUuid,
                    sellerSku: response.data.sellerSku,
                    now_price: response.data.now_price,
                    promotional_price: response.data.promotional_price,
                    status: response.data.status,
                    was_price: response.data.was_price,
                    stock: response.data.stock
                });
                this.props.handleUpdate(this.props.dealsHubAdmin);
                this.setState({
                    productListUpdationinProgress: false
                });
                message.success('Product Added Successfully!');
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else {
                message.error('Something went wrong!');
            }
        });
    };

    handleSelect = (selectedValue) => {
        this.addProduct(selectedValue, this.props.modalFileUid);
        this.setState({
            autoCompleteSearchString : ""
        })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            modalFileUid: file.uid,
            modalFileHttpLink: file.httpLink
        });
    };

    handleHoveringBannerUpload = ({file, fileList}) => {
        if(file.size > 2097152) {
            let temp = [];
            for(let i=0; i<fileList.length-1; i++){
                temp.push(fileList[i]);
            }
            this.setState({ fileList: temp });
        } else {
            this.setState({ fileList });
        }
        if(file.size <= 2097152 && fileList.length>0 && !this.state.hoverImageUploadingInProgress){
            this.setState({hoverImageUploadingInProgress:true})
            let currentImage = fileList[fileList.length-1]
            let formdata = new FormData()
            formdata.append("uuid",this.state.requiredBannerImagesObj.uid)
            formdata.append("image",currentImage.originFileObj)

            if(currentImage.originFileObj!==undefined){
                axios({
                    method:"POST",
                    url: `${serverUrl}/dealshub/add-unit-banner-hovering-image/`,
                    data: formdata,
                    headers:{
                        "Content-Type": 'multipart/form-data',
                        Authorization:`JWT ${localStorage.getItem("token")}`
                    }
                })
                .then(response=>{
                    console.log(response.data)
                    this.setState({
                        fileList : []
                    })
                    if(response.data.status === 200){
                        let tmp=null,len=this.props.dealsHubAdmin.length,index1=null,index2=null
                        for(index1=0;index1<len;index1++){
                            if(this.props.dealsHubAdmin[index1].uuid===this.props.bannerData.uuid){
                                let tmpHoverBanner = this.props.dealsHubAdmin[index1].bannerImages
                                let tmpHoverBannerLen = tmpHoverBanner.length
                                for(index2=0;index2<tmpHoverBannerLen;index2++){
                                    if(tmpHoverBanner[index2].uid===this.props.modalFileUid){
                                        this.props.dealsHubAdmin[index1].bannerImages[index2].hoveringBannerUrl = response.data.url
                                        this.props.dealsHubAdmin[index1].bannerImages[index2].hoveringBannerUuid = response.data.uuid
                                        this.setState({ requiredBannerImagesObj: this.props.dealsHubAdmin[index1].bannerImages[index2] })
                                        tmp = tmpHoverBanner[index2]
                                        break;
                                    }
                                }
                                if(tmp!==null)
                                    break
                            }
                        }
                    }
                    else if(response.data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                    this.props.handleUpdate(this.props.dealsHubAdmin)
                    this.setState({hoverImageUploadingInProgress:false,fileList:[]})
                })
            }
        }
    }

    handleMobileUpload = ({ file, fileList } , imageType , is_ar) => {
        if(file.size > 2097152) {
            let temp = [];
            for(let i=0; i<fileList.length-1; i++){
                temp.push(fileList[i]);
            }
            this.setState({ fileList: temp });
        } else {
            this.setState({ fileList });
        }
        if (
            file.size <= 2097152 &&
            fileList.length > 0 &&
            !this.state.mobileImageUploadingInProgress &&
            !this.state.webImageUploadingInProgress &&
            !this.state.mobileImageUploadingInProgress_ar &&
            !this.state.webImageUploadingInProgress_ar
            // &&
            //     !this.state.isDeleteOn
        ) {
            if(imageType == "mobile" && !is_ar)
                this.setState({ mobileImageUploadingInProgress: true });
            if(imageType == "web" && !is_ar)
                this.setState({ webImageUploadingInProgress: true });
            if(imageType == "mobile" && is_ar)
                this.setState({ mobileImageUploadingInProgress_ar: true });
            if(imageType == "web" && is_ar)
                this.setState({ webImageUploadingInProgress_ar: true });

            let currentImage = fileList[fileList.length - 1];
            let formData = new FormData();
            formData.append("image", currentImage.originFileObj);
            formData.append("websiteGroupName", localStorage.getItem("websiteGroupName"));
            formData.append("uuid", this.state.requiredBannerImagesObj.uid);
            formData.append("imageType", imageType);
            formData.append("language" , is_ar ? "ar" : "en")

            if (currentImage.originFileObj !== undefined) {
                axios.post(`${serverUrl}/dealshub/update-banner-image/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                }).then(response => {
                    if (response.data.status === 200) {

                        let temp = null, length = this.props.dealsHubAdmin.length, index1 = null, index2 = null;

                        for (index1 = 0; index1 < length; ++index1) {
                            if (this.props.dealsHubAdmin[index1].uuid == this.props.bannerData.uuid) {
                                let tempAdminBanner = this.props.dealsHubAdmin[index1].bannerImages;
                                let tempAdminBannerLength = this.props.dealsHubAdmin[index1].bannerImages.length;
                                for (index2 = 0; index2 < tempAdminBannerLength; ++index2) {
                                    if (tempAdminBanner[index2].uid === this.props.modalFileUid) {

                                        if(imageType == "mobile" && !is_ar)
                                            this.props.dealsHubAdmin[index1].bannerImages[index2]["mobileUrl"] = response.data.url;
                                        if(imageType == "web" && !is_ar)
                                            this.props.dealsHubAdmin[index1].bannerImages[index2]["url"] = response.data.url;
                                        if(imageType == "mobile" && is_ar)
                                            this.props.dealsHubAdmin[index1].bannerImages[index2]["mobileUrl-ar"] = response.data.url;
                                        if(imageType == "web" && is_ar)
                                            this.props.dealsHubAdmin[index1].bannerImages[index2]["url-ar"] = response.data.url;


                                        this.setState({ requiredBannerImagesObj: this.props.dealsHubAdmin[index1].bannerImages[index2] });

                                        temp = tempAdminBanner[index2];
                                        break;
                                    }
                                }
                                if (temp !== null) {
                                    break;
                                }
                            }
                        }

                        this.props.handleUpdate(this.props.dealsHubAdmin);

                        // this.props.bannerData.bannerImages.push({
                        //     httpLink: "",
                        //     products: [],
                        //     uid: response.data.uuid,
                        //     url: ""
                        // });

                        // let temp = this.state.fileList;

                        // temp[temp.length - 1]["uid"] = response.data.uuid;

                        this.setState({
                            // fileList: [...[], ...temp],
                            // fileList: [...[], ...fileList],
                            fileList:[],
                            mobileImageUploadingInProgress: false,
                            webImageUploadingInProgress : false,
                            mobileImageUploadingInProgress_ar: false,
                            webImageUploadingInProgress_ar : false
                        });
                    }
                    else if(response.data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                });
            }
        }
    };

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    removeFile = file => {
        this.setState({
            isDeleteOn: true
        });
        axios.post(`${this.state.serverUrl}/dealshub/delete-banner-image/`, {
            uuid: file.uid
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            this.setState({
                isDeleteOn: false
            });
        });
    };

    beforeUpload = (file) => {
        let isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isLt2M;
    }

    deleteHoverBannerImage = () => {
        this.setState({isDeleteOnHover:true}, ()=>{
            axios({
                method:"POST",
                url: `${serverUrl}/dealshub/delete-hovering-image/`,
                data:{uuid:this.state.requiredBannerImagesObj.hoveringBannerUuid},
                headers:{
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
            .then(response=>{

                let temp = null, length = this.props.dealsHubAdmin.length, index1 = null, index2 = null
                for (index1 = 0; index1 < length; ++index1) {
                    if (this.props.dealsHubAdmin[index1].uuid == this.props.bannerData.uuid) {
                        let tempAdminBanner = this.props.dealsHubAdmin[index1].bannerImages
                        let tempAdminBannerLength = this.props.dealsHubAdmin[index1].bannerImages.length
                        for (index2 = 0; index2 < tempAdminBannerLength; ++index2) {
                            if (tempAdminBanner[index2].uid === this.props.modalFileUid) {
                                this.props.dealsHubAdmin[index1].bannerImages[index2].hoveringBannerUrl = ""
                                this.props.dealsHubAdmin[index1].bannerImages[index2].hoveringBannerUuid = ""
                                this.setState({ requiredBannerImagesObj: this.props.dealsHubAdmin[index1].bannerImages[index2] })
                                temp = tempAdminBanner[index2]
                                break
                            }
                        }
                        if (temp !== null)
                            break
                    }
                }
                this.props.handleUpdate(this.props.dealsHubAdmin)
                this.setState({isDeleteOnHover: false})
            })
        })
    }

    deleteBannerImage = (imageType , is_ar = false) => {
        this.setState({
            isDeleteOn: imageType == "mobile" && !is_ar,
            isDeleteOn_ar : imageType == "mobile" && is_ar,
            isDeleteOnWebImage : imageType == "web" && !is_ar,
            isDeleteOnWebImage_ar : imageType == "web" && is_ar
        }, () => {
            axios.post(`${serverUrl}/dealshub/delete-banner-image/`, {
                uuid: this.state.requiredBannerImagesObj.uid,
                imageType : imageType,
                language : is_ar ? "ar" : "en"
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                let temp = null, length = this.props.dealsHubAdmin.length, index1 = null, index2 = null;

                for (index1 = 0; index1 < length; ++index1) {
                    if (this.props.dealsHubAdmin[index1].uuid == this.props.bannerData.uuid) {
                        let tempAdminBanner = this.props.dealsHubAdmin[index1].bannerImages;
                        let tempAdminBannerLength = this.props.dealsHubAdmin[index1].bannerImages.length;
                        for (index2 = 0; index2 < tempAdminBannerLength; ++index2) {
                            if (tempAdminBanner[index2].uid === this.props.modalFileUid) {

                                if(imageType == "mobile" && !is_ar)
                                this.props.dealsHubAdmin[index1].bannerImages[index2].mobileUrl = "";
                                if(imageType == "web" && !is_ar)
                                this.props.dealsHubAdmin[index1].bannerImages[index2].url = placeHolderImage;
                                if(imageType == "mobile" && is_ar)
                                this.props.dealsHubAdmin[index1].bannerImages[index2]["mobileUrl-ar"] = "";
                                if(imageType == "web" && is_ar)
                                this.props.dealsHubAdmin[index1].bannerImages[index2]["url-ar"] = "";

                                this.setState({ requiredBannerImagesObj: this.props.dealsHubAdmin[index1].bannerImages[index2] });

                                temp = tempAdminBanner[index2];
                                break;
                            }
                        }
                        if (temp !== null) {
                            break;
                        }
                    }
                }

                this.props.handleUpdate(this.props.dealsHubAdmin);

                this.setState({
                    isDeleteOn: false,
                    isDeleteOnWebImage : false,
                    isDeleteOn_ar : false,
                    isDeleteOnWebImage_ar : false
                });
            });
        });
    }

    clearPromotionalAttributes = () => {
        this.setState({
            promotionStartDate: null,
            promotionEndDate: null,
            promotionalTag: null
        });
    }

    setPromotionActive = (newPromotionStatus) => {
        this.setState({
            promotionActive: newPromotionStatus
        }, () => {
            if (!newPromotionStatus) {
                this.clearPromotionalAttributes();
            }
        });
    }

    changePromotionRange = (dateRangeMoment, dateRangeStrings) => {
        this.setState({
            promotionStartDate: dateRangeStrings[0] === "" ? null : dateRangeStrings[0].replace(" ", "T").concat("Z"),
            promotionEndDate: dateRangeStrings[1] === "" ? null : dateRangeStrings[1].replace(" ", "T").concat("Z")
        });
    }

    changePromotionTag = (event) => {
        this.setState({
            promotionalTag: event.target.value === "" ? null : event.target.value
        });
    }

    validatePromotionAttributes = () => {
        return this.state.promotionStartDate !== null &&
            this.state.promotionEndDate !== null &&
            this.state.promotionalTag !== null;
    }

    savePromoDetails = () => {
        let dataModel = {};

        dataModel["uuid"] = this.state.requiredBannerImagesObj.uid;
        if (
            this.state.promotionActive &&
            this.validatePromotionAttributes()
        ) {
            dataModel["is_promotional"] = true;
            let tempStartDate = this.state.promotionStartDate;
            let tempEndDate = this.state.promotionEndDate;
            dataModel["promotion"] = {
                start_date: tempStartDate,
                end_date: tempEndDate,
                promotional_tag: this.state.promotionalTag
            };
        } else {
            dataModel["is_promotional"] = false;
            dataModel["promotion"] = {
                start_date: null,
                end_date: null,
                promotional_tag: null
            };
        }
        console.log(dataModel);

        let length = this.props.dealsHubAdmin.length, bannerIndex = null, unitBannerIndex = null;

        for (let index = 0; index < length; ++index) {
            if (this.props.dealsHubAdmin[index].uuid == this.props.bannerData.uuid) {
                let tempAdminBanner = this.props.dealsHubAdmin[index].bannerImages;
                let tempAdminBannerLength = this.props.dealsHubAdmin[index].bannerImages.length;

                for (let index2 = 0; index2 < tempAdminBannerLength; ++index2) {
                    if (tempAdminBanner[index2].uid === this.props.modalFileUid) {
                        bannerIndex = index;
                        unitBannerIndex = index2;
                        break;
                    }
                }
                if (bannerIndex !== null) {
                    break;
                }
            }
        }

        // this.props.dealsHubAdmin[bannerIndex]["bannerImages"][unitBannerIndex]["is_promotional"] = dataModel["is_promotional"];
        // this.props.dealsHubAdmin[bannerIndex]["bannerImages"][unitBannerIndex]["promotion"] = {};
        // this.props.dealsHubAdmin[bannerIndex]["bannerImages"][unitBannerIndex]["promotion"]["start_time"] = dataModel["promotion"]["start_date"];
        // this.props.dealsHubAdmin[bannerIndex]["bannerImages"][unitBannerIndex]["promotion"]["end_time"] = dataModel["promotion"]["end_date"];
        // this.props.dealsHubAdmin[bannerIndex]["bannerImages"][unitBannerIndex]["promotion"]["promotion_tag"] = dataModel["promotion"]["promotional_tag"];
        let promoDetails = {
            is_promotional: dataModel["is_promotional"],
            promotion: {
                start_time: dataModel["promotion"]["start_date"],
                end_time: dataModel["promotion"]["end_date"],
                promotion_tag: dataModel["promotion"]["promotional_tag"]
            }
        };
        this.props.handlePromoChange(
            bannerIndex,
            unitBannerIndex,
            promoDetails
        );

        if (this.state.promotionActive && !this.validatePromotionAttributes()) {
            message.warning("Incorrect promotional attributes!");
        } else {
            this.setState({
                promoDetailsUpdationInProgress: true
            }, () => {
                axios.post(`${serverUrl}/dealshub/update-unit-banner/`, dataModel, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            promotionActive: dataModel["is_promotional"],
                            promotionStartDate: dataModel["promotion"]["start_date"],
                            promotionEndDate: dataModel["promotion"]["end_date"],
                            promotionalTag: dataModel["promotion"]["promotional_tag"]
                        })
                        message.success("Promotion Details Changed Successfully!");
                    } 
                    else if(response.data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                    else {
                        message.error("Something went wrong!");
                    }
                    this.setState({ promoDetailsUpdationInProgress: false });
                });
            });
            // this.handleCancel();
        }
    }

    downloadProducts = () => {
        this.setState({
            loadingDownload : true
        })
        let data = {
            uuid: this.props.modalFileUid
          };
          fetch(`${serverUrl}/dealshub/banner-bulk-download/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
          })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    loadingDownload : false
                })
              if (data.status === 500)
                message.error("Internal Server Error!");
                else if(data.status === 405)
                {
                  message.info("You are not authorised!")
                }
              else {
                window.open(data.filepath, '_blank');
              }
      
            });
    }

    handleSelectFile = file => {
        this.setState({ fileList: [file], uploadModal: false });

        console.log(this.props , "UUI")
        let formData = new FormData();
        formData.append("uuid", this.props.modalFileUid);
        formData.append("import_file" , file);

        axios
        .post(`${serverUrl}/dealshub/banner-bulk-upload/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        })
        .then(response => {
            switch(response.data.status){
                case 200:
                    if(response.data.unsuccessful_count)
                        {
                            message.info(`${response.data.unsuccessful_count} product(s) are rejected!`);
                            this.setState({
                                errorShow : true,
                                unsuccessful_count : response.data.unsuccessful_count
                            })
                        }
                    else {
                        message.success("All products added successfully!");
                        this.setState({
                            errorShow : false,
                        })
                    }
                    this.setState({
                        addedProducts : {
                            ...this.state.addedProducts,
                            products : [
                                ...response.data.products
                            ]
                        }
                    })

                    break;
                case 500:
                    message.error("Something went wrong!");
                    break;
                case 403:
                    message.info("You are not authorized!");
                    break;
                default:
                    message.error("Something went wrong");
            }
            this.handleUnselectFile()
        });
    }


    handleUnselectFile = file => {
        this.setState({ fileList: [] });
    }


    render() {
        console.log(this.props);
        const uploadButton = (
            <div>
                <Icon type="camera" />
                <div className="ant-upload-text">
                    You can drag and drop images or upload images
                </div>
            </div>
        );
        if (
            !this.state.spinnerLoading &&
            this.state.addedProducts &&
            !this.props.modalFileUid.includes("rc-upload")) {
            return (
                <Row className="generic-banner-modal-content" style = {{
                    position : "relative"
                }} gutter={[24, 24]}>

                    <div style = {{
                        position : "absolute",
                        top: "15px",
                        right : "50px"
                    }}>
                          <Text style = {{marginRight : "12px"}}>Language </Text>
                                      <Radio.Group
                                        style={{ width: "100%" , display : "inline" }}
                                        value = {this.state.is_english_language}
                                        onChange={(e) => {
                                          this.setState({
                                           is_english_language : e.target.value 
                                          })
                                        }}
                                      >    
                                        <Radio value={true} style = {{display : "inline" , marginRight : "5px"}}>en</Radio>
                                        <Radio value={false} style = {{display : "inline"}}>ar</Radio>
                                    </Radio.Group>
                    </div>
                
                    {
                        this.state.is_english_language && 
                        <React.Fragment>

                    <Col span={24}
                        style={{
                            padding: "20px 0px"
                        }}
                    >
                            <Paragraph className="text-color3"
                            ellipsis={{ rows: 2, expandable: false }}
                            style={{ marginBottom: "15px" }}
                        >
                            Desktop/Tablet/Laptop Devices Image :
                            {
                                this.state.previewImage !== "" ? (
                                    <Button
                                        style={{ marginLeft: "15px" }}
                                        type="danger"
                                        loading = {this.state.isDeleteOnWebImage}
                                        onClick={() => this.deleteBannerImage("web")}
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    <></>
                                )}
                        </Paragraph>
                        {
                                this.state.webImageUploadingInProgress ? (
                                <div style={{ textAlign: "center" }}>
                                    <Spin indicator={antIcon} tip="Uploading Web Banner Image" />
                                </div>
                            ) : <>
                            {
                                 (this.state.requiredBannerImagesObj && this.state.requiredBannerImagesObj.url == placeHolderImage) ?
                                 <Upload
                                     listType="picture-card"
                                     fileList={[]}
                                     onChange={(e) => this.handleMobileUpload(e , "web")}
                                     customRequest={this.dummyRequest}
                                     beforeUpload={this.beforeUpload}
                                 >
                                     {uploadButton}
                                 </Upload>
                                 :
                                  <img alt="example" style={{ width: "100%" }} src={this.state.requiredBannerImagesObj && this.state.requiredBannerImagesObj.url} />
                            }
                            </>
                        }
                       
                    </Col>
                    
                     <Col span={24}
                        style={{
                            padding: "20px 0px"
                        }}
                    >
                        <Paragraph className="text-color3"
                            ellipsis={{ rows: 2, expandable: false }}
                            style={{ marginBottom: "15px" }}
                        >
                            Mobile Device Image :
                            {this.state.requiredBannerImagesObj &&
                                this.state.requiredBannerImagesObj.mobileUrl !== "" ? (
                                    <Button
                                        style={{ marginLeft: "15px" }}
                                        type="danger"
                                        onClick={() => this.deleteBannerImage("mobile")}
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    <></>
                                )}
                        </Paragraph>
                        <Paragraph
                            ellipsis={{ rows: 2, expandable: false }}
                            style={{ marginBottom: "15px" }}
                        >
                            Dimensions : 575px * 240px
                        </Paragraph>
                        {this.state.isDeleteOn ? (
                            <div style={{ textAlign: "center" }}>
                                <Spin indicator={antIcon} tip="Deleting Mobile Banner Image" />
                            </div>
                        ) : (
                                <>
                                    {this.state.mobileImageUploadingInProgress ? (
                                        <div style={{ textAlign: "center" }}>
                                            <Spin indicator={antIcon} tip="Uploading Mobile Banner Image" />
                                        </div>
                                    ) : (
                                            <>
                                                {
                                                    this.state.requiredBannerImagesObj &&
                                                        this.state.requiredBannerImagesObj.mobileUrl !== "" ? (
                                                            <img alt="example" style={{ width: "100%" }} src={this.state.requiredBannerImagesObj.mobileUrl} />
                                                        ) : (
                                                            <Upload
                                                                accept=".jpg, .jpeg, .png, .gif"
                                                                listType="picture-card"
                                                                fileList={[]}
                                                                onChange={(e) => this.handleMobileUpload(e , "mobile")}
                                                                customRequest={this.dummyRequest}
                                                                beforeUpload={this.beforeUpload}
                                                            >
                                                                {uploadButton}
                                                            </Upload>
                                                        )}
                                            </>
                                        )}
                                </>
                            )}
                    </Col>
                 
                    <Col span={24}
                        style={{
                            padding: "20px 0px"
                        }}
                    >
                        <Paragraph className="text-color3"
                            ellipsis={{ rows: 2, expandable: false }}
                            style={{ marginBottom: "15px" }}
                        >
                            Hovering Banner :
                            {this.state.requiredBannerImagesObj && this.state.requiredBannerImagesObj.hoveringBannerUrl &&
                                this.state.requiredBannerImagesObj.hoveringBannerUrl !== "" ? (
                                    <Button
                                        style={{ marginLeft: "15px" }}
                                        type="danger"
                                        onClick={this.deleteHoverBannerImage}
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    <></>
                                )}
                        </Paragraph>

                        {this.state.isDeleteOnHover ? (
                            <div style={{ textAlign: "center" }}>
                                <Spin indicator={antIcon} tip="Deleting Hovering Banner Image" />
                            </div>
                        ) : (
                                <>
                                
                                    {this.state.hoverImageUploadingInProgress ? (
                                        <div style={{ textAlign: "center" }}>
                                            <Spin indicator={antIcon} tip="Uploading Hover Banner Image" />
                                        </div>
                                    ) : (
                                            <>
                                                {
                                                    this.state.requiredBannerImagesObj.hoveringBannerUrl && this.state.requiredBannerImagesObj.hoveringBannerUrl &&
                                                        this.state.requiredBannerImagesObj.hoveringBannerUrl !== "" ? (
                                                             
                                                            <img alt="example" style={{ width: "100%" }} src={this.state.requiredBannerImagesObj.hoveringBannerUrl} />
                                                        ) : (
                                                            <Upload
                                                                accept=".jpg, .jpeg, .png, .gif"
                                                                listType="picture-card"
                                                                fileList={[]}
                                                                onChange={this.handleHoveringBannerUpload}
                                                                customRequest={this.dummyRequest}
                                                                beforeUpload={this.beforeUpload}
                                                            >
                                                                {uploadButton}
                                                            </Upload>
                                                        )}
                                            </>
                                        )}
                                </>
                            )}
                    </Col>
                    <Col span={24} style = {{
                        marginBottom : "20px"
                    }}>
                        <Row gutter={[8, 0]} type = "flex">
                            <Col>
                                <Text className="text-color3">Promotion Status : </Text>
                                <Switch
                                    checked={this.state.promotionActive}
                                    onChange={this.setPromotionActive}
                                />
                            </Col>
                            <Col style={{ marginTop: "-6px" }}>
                                <Button
                                    style={{
                                        background: "blue",
                                        border: "none",
                                        color: "#fff"
                                    }}
                                    onClick={this.savePromoDetails}
                                    loading={this.state.promoDetailsUpdationInProgress}
                                >Save Promo Details</Button>
                            </Col>
                        </Row>
                        <Row gutter={[0, 24]}>
                            {this.state.promotionActive ? (
                                <Col span={24}>
                                    <Row gutter={[8, 24]}>
                                        <Col span={24}>
                                            <Text className="text-color3">Promotional Tag</Text>
                                            <Input
                                                defaultValue={
                                                    this.state.promotionalTag ?
                                                        this.state.promotionalTag :
                                                        ""
                                                }
                                                placeholder="Enter Promotion Tag here"
                                                maxLength={30}
                                                onChange={this.changePromotionTag}
                                                style={{ marginTop: "5px" }}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <RangePicker
                                                defaultValue={
                                                    this.state.promotionStartDate && this.state.promotionEndDate ?
                                                        [
                                                            moment(this.state.promotionStartDate, this.rangePickerDateFormat),
                                                            moment(this.state.promotionEndDate, this.rangePickerDateFormat)
                                                        ] : null
                                                }
                                                showTime={{ format: 'HH:mm:ss' }}
                                                format={this.rangePickerDateFormat}
                                                placeholder={['Start Time', 'End Time']}
                                                onOk={() => { }}
                                                onChange={this.changePromotionRange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : (
                                    <></>
                                )}
                        </Row>
                    </Col>
               
                   <Col span = {24}>
                        <Row type = "flex" gutter = {[12,12]} align = "middle">
                            <Col>
                            <Button loading={this.state.loadingDownload}
                            disabled={this.state.loadingDownload}
                            onClick = {this.downloadProducts}
                            style={{ width: "100%",marginTop : "4px" }}
                            type="primary"> Download Products </Button>
                            </Col>
                            <Col>
                            <UploadBtn style={{ width: "100%" }} beforeUpload={this.handleSelectFile} onRemove={this.handleUnselectFile} fileList={this.state.fileList}>
                                    <Button loading={this.state.loading}
                                    disabled={this.state.fileList.length !== 0 || this.state.published}
                                    style={{ width: "100%", }}
                                    type="primary"> Bulk Upload  </Button>
                            </UploadBtn>
                            </Col>
                            <Col style = {{margin : "4px"}}>
                            <Text> Use <a style = {{fontStyle : "bold" , color : "white"}} href = "https://qa.omnycomm.com/static/media/Section-Bulk-Upload-Products-Demo-Template.457cc86e.xlsx" target = "_blank">This Template</a> for uploading products for this banner </Text>
                            </Col>
                        </Row>
                    </Col>

                    {this.state.errorShow && <Col span = {24}>
                        <Text style = {{color : "red"}}>
                            Please note that {this.state.unsuccessful_count} products could not be added to section/banner because of the following reasons:
                        </Text>
                        <ul>
                            <li>The product may not exist</li>
                            <li>The product may be unpublished</li>
                            <li>The product may be out of stock</li>
                            <li>The product price may be 0</li>
                        </ul>
                    </Col>}
                   
                    <Col span={24}>
                        <AutoComplete
                            style={{ width: "100%" , marginTop : "20px" }}
                            dataSource={this.state.searchListing}
                            onSelect={this.handleSelect}
                            value = {this.state.autoCompleteSearchString}
                            onChange = {(e) => {
                                this.setState({
                                    autoCompleteSearchString : e
                                })
                            }}
                            onSearch={this.autocompleteSearch}
                            placeholder="Search for Products"
                            disabled = {this.state.addedProducts["products"]?.length >= 40}
                        />
                    </Col>
                
                   
                   
                    <Col span={24} className="added-products-listing">
                       {
                           this.state.productListUpdationinProgress && 
                           <Spin
                           indicator={antIcon} tip="Updating Products List Data"
                           spinning={this.state.productListUpdationinProgress}
                       >  </Spin>
                       }
                      
                            <Row
                                gutter={[24, 24]}
                                style={{ padding: "0px 0px 0px 0px" }}
                            >
                                {this.state.addedProducts["products"]?.map((product, index) => (
                                    <Col
                                        span={8}
                                        key={product.uuid}
                                    >
                                        <Card bordered className="categoryproductcard">
                                            <Row gutter={[16, 24]}>
                                                <Col span={4}>
                                                    <Avatar shape="square" style = {{
                                                        width : "100%",
                                                        height : "100%"
                                                    }} src={product.thumbnailImageUrl} />
                                                </Col>
                                                <Col span={20} style = {{
                                                    height : "80px",
                                                    overflow : "scroll"
                                                }}>
                                                    <Tooltip title={product.name} >
                                                        <Paragraph
                                                            className="text-color3"
                                                            ellipsis={{ rows: 2, expandable: false }}
                                                            style={{
                                                                boxSizing: "border-box",
                                                                marginBottom: "0px",
                                                            }}
                                                        >
                                                            {product.name}
                                                        </Paragraph>
                                                    </Tooltip>
                                                    <Text className="text-color3">ID : </Text>
                                                    <Text>{product.sellerSku} | {product.displayId}</Text>
                                                </Col>
                                            </Row>
                                            {this.state.promotionActive ? (
                                                <div className="promotional-product-price-wrapper">
                                                    <Row>
                                                        <Col span={12}>
                                                            <Text>Price : {product.now_price}</Text>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Text>Previous Price : <Text
                                                                style={{
                                                                    textDecoration: "line-through"
                                                                }}
                                                            >{product.was_price}</Text>
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={12} style={{ height: "10px" }}>
                                                            <Text>
                                                                Promo Price : <EditableProductAttributes
                                                                    isBanner={true}
                                                                    productIndex={index}
                                                                    bannerIndex={this.props.bannerIndex}
                                                                    unitBannerUuid={this.props.modalFileUid}
                                                                    uuid={product.uuid}
                                                                    value={product.promotional_price}
                                                                    name="promotional_price"
                                                                />
                                                            </Text>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Text>Stock : {product.stock}</Text>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : (
                                                    <></>
                                                )}
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "6px",
                                                    right: "10px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={(e) => {
                                                    this.removeProduct(product.uuid);
                                                }}
                                            >X</div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                       
                    </Col>
                
                        </React.Fragment>
                    }


                    {
                        !this.state.is_english_language &&
                        <React.Fragment>
                            
                        <Col span={24}
                            style={{
                                padding: "20px 0px"
                            }}
                        >
                                        <Paragraph className="text-color3"
                                        ellipsis={{ rows: 2, expandable: false }}
                                        style={{ marginBottom: "15px" }}
                                    >
                                        Desktop/Tablet/Laptop Devices Image :
                                        {
                                            this.state.previewImage !== "" ? (
                                                <Button
                                                    style={{ marginLeft: "15px" }}
                                                    type="danger"
                                                    loading = {this.state.isDeleteOnWebImage_ar}
                                                    onClick={() => this.deleteBannerImage("web" , true)}
                                                >
                                                    Delete
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                    </Paragraph>
                                    {
                                            this.state.webImageUploadingInProgress_ar ? (
                                            <div style={{ textAlign: "center" }}>
                                                <Spin indicator={antIcon} tip="Uploading Web Banner Image" />
                                            </div>
                                        ) : <>
                                        {
                                            (this.state.requiredBannerImagesObj?.["url-ar"] == "") ?
                                            <Upload
                                                listType="picture-card"
                                                fileList={[]}
                                                onChange={(e) => this.handleMobileUpload(e , "web" , true)}
                                                customRequest={this.dummyRequest}
                                                beforeUpload={this.beforeUpload}
                                            >
                                                {uploadButton}
                                            </Upload>
                                            :
                                            <img alt="example" style={{ width: "100%" }} src={this.state.requiredBannerImagesObj?.["url-ar"]} />
                                        }
                                        </>
                                    }
                           
                        </Col>
                                    
                        
                     <Col span={24}
                        style={{
                            padding: "20px 0px"
                        }}
                    >
                        <Paragraph className="text-color3"
                            ellipsis={{ rows: 2, expandable: false }}
                            style={{ marginBottom: "15px" }}
                        >
                            Mobile Device Image :
                            {
                                this.state.requiredBannerImagesObj?.["mobileUrl-ar"] !== "" ? (
                                    <Button
                                        style={{ marginLeft: "15px" }}
                                        type="danger"
                                        onClick={() => this.deleteBannerImage("mobile" , true)}
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    <></>
                                )}
                        </Paragraph>
                        <Paragraph
                            ellipsis={{ rows: 2, expandable: false }}
                            style={{ marginBottom: "15px" }}
                        >
                            Dimensions : 575px * 240px
                        </Paragraph>
                        {this.state.isDeleteOn_ar ? (
                            <div style={{ textAlign: "center" }}>
                                <Spin indicator={antIcon} tip="Deleting Mobile Banner Image" />
                            </div>
                        ) : (
                                <>
                                    {this.state.mobileImageUploadingInProgress_ar ? (
                                        <div style={{ textAlign: "center" }}>
                                            <Spin indicator={antIcon} tip="Uploading Mobile Banner Image" />
                                        </div>
                                    ) : (
                                            <>
                                                {
                                                        this.state.requiredBannerImagesObj?.["mobileUrl-ar"] !== "" ? (
                                                            <img alt="example" style={{ width: "100%" }} src={this.state.requiredBannerImagesObj?.["mobileUrl-ar"]} />
                                                        ) : (
                                                            <Upload
                                                                listType="picture-card"
                                                                fileList={[]}
                                                                onChange={(e) => this.handleMobileUpload(e , "mobile" , true)}
                                                                customRequest={this.dummyRequest}
                                                                beforeUpload={this.beforeUpload}
                                                            >
                                                                {uploadButton}
                                                            </Upload>
                                                        )}
                                            </>
                                        )}
                                </>
                            )}
                    </Col>
                 

                            </React.Fragment>
                    }
                
                </Row >
            );
        } else {
            return (
                <div style={{ textAlign: "center" }}>
                    <Spin indicator={antIcon} tip="Loading Banner Data" />
                </div>
            );
        }
    }
}

const mapStateToProps = ({ dealsHubAdmin }) => {
    return {
        dealsHubAdmin
    };
};

export default connect(mapStateToProps, {
    handleUpdate,
    deleteBanner,
    publishBanner,
    unpublishBanner,
    handlePromoChange
})(GenericBannerModalContent);
