import React, { Component } from "react";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { EditProductPageWrapper, EbppCol } from "./styled-components-library";
import { Row, Col, Button, Affix, message, Tooltip } from "antd";
import InputField from "../components/datainputs/InputField";
// import UploadImage from "../components/datainputs/upload-image/UploadImage";
import ImageManagerCreateBaseProduct from "../components/datainputs/image-manager/ImageManagerCreateBaseProduct";
import FormHeader from "../components/datainputs/FormHeader";
import InputNumberDimensions3 from "../components/datainputs/InputNumberDimesions3";
import "./edit-base-product-page.scss";
import VarientsInfoVital from "./vitali-info/VarientsInfoVital";
import VarientsInfoAttributes from "./vitali-info/VarientsInfoAttributes";
// import VarientsInfoDimensions from "./vitali-info/VarientsInfoDimensions";
import VarientsInfoPFL from "./vitali-info/VarientsInfoPFL";
import VarientsforFaq from "./vitali-info/VarientsforFaq";
// import EditorBox from '../components/EditorBox';
import {
  setEmpty,
  fetchBaseProductDetails,
  createNewProduct
} from "../../actions/index";

//validator
import SimpleReactValidator from "simple-react-validator";

import { connect } from "react-redux";
import BaseForm from "./BaseForm";
import { Redirect } from "react-router";

import isDev from "../../server";
import EditorBox from "../components/EditorBox";
import DealshubProductVarientSection from "./vitali-info/DealshubProductVarientSection";
const serverUrl = isDev();

class CreateNewProduct extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Field is required",
        integer: "Must be number",
        default: "Validation has failed!" // will override all messages
      }
    });
    this.state = {
      redirect: false,
      dataList: [],
      brandList: null,
      imagedata: null,
      description: "",
      loading: false,
      stepdataList:[],
      faqList:[],
    };
  }

  updateImageList = dict => {

    this.setState({ imagedata: dict });
  };

  setlist = data => {

    this.setState({ dataList: data });
  };

  setsteplist = (stepdata) => {
    this.setState({ stepdataList: stepdata });
  };
  setfaqlist = (faqdata) => {
    this.setState({ faqList: faqdata });
  };


  fetchCategories = () => {
    let data = {

    };
    fetch(`${serverUrl}/fetch-all-categories/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status === 500)
          message.error("Internal Server Error!");
          else if(data.status === 405)
          {
            message.info("You are not authorised!")
          }
        else {
          this.setState({
            super_categories: data.super_category_list
          })
        }

      });
  }


  componentDidMount = () => {
    this.props.drawerOff();

    this.props.setEmpty();
    this.props.fetchBaseProductDetails(this.props.id);
    this.fetchCategories();

    fetch(`${serverUrl}/fetch-brands/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ brandList: data.brand_list });
      });
  };

  handelSave = () => {
    if (!this.validator.allValid()) {
      message.info("Fill The Required Details");
    } else {

      if (this.state.imagedata) this.setState({ loading: true });
      this.props.productDetails.pfl_product_features = this.state.dataList;
      this.props.createNewProduct(
        this.props.productDetails,
        this.props.id,
        this.state.description,
        this.state.imagedata,
        this.state.stepdataList,
        this.state.faqList,
      );
    }
  };

  error = status => {
    if (status == 500) message.error("Internal Server Error");
    else if (status == 409) message.warning("Duplicate Product Detected");
    else if (status == 403) message.error("access denied");
    else {
      message.success("Save Successfully!");
      // this.setState({ redirect: true });
    }
  };

  getEditorText = value => {
    this.setState({ description: value });
  };

  componentWillReceiveProps = props => {

    if (props.productDetails.status !== "") {
      this.error(props.productDetails.status);
    }

    if (!this.state.imagedata) {
      if (props.productDetails.product_pk !== null)
        this.setState({ redirect: true, loading: false });
    } else {
      if (
        props.productDetails.product_pk !== null &&
        props.productDetails.status == "reload"
      )
        this.setState({ redirect: true, loading: false });
      else if (
        props.productDetails.product_pk !== null &&
        props.productDetails.status == "error_reload"
      ) {
        message.error("Internal Server Error In Image Uploading!");
        this.setState({ redirect: true, loading: false });
      }
    }
  };

  render() {


    const initSuperCat = (this.state.super_categories && this.props.productDetails.super_category_uuid && this.state.super_categories.filter(item => item.super_category_uuid ==
      this.props.productDetails.super_category_uuid)[0] || null)

    const initCat = (initSuperCat && initSuperCat.category_list && this.props.productDetails.category_uuid && initSuperCat.category_list.filter(
      (item) => item.category_uuid == this.props.productDetails.category_uuid)[0]) || null;

    const initSubCat = (initCat && initCat.sub_category_list.filter(item => item.sub_category_uuid == this.props.productDetails.sub_category_uuid)[0]) || null;




    if (this.state.redirect) {
      this.props.refresh();
      return (
        <Redirect
          to={`/edit-product/${this.props.productDetails.product_pk}`}
        ></Redirect>
      );
    }





    // if (this.state.redirect)
    //     return (<Redirect to="/products/"></Redirect>)

    return (
      <React.Fragment>
        <EditProductPageWrapper>
          <TitleWithBreadCrumb
            title="Create New Variant"
            breadcrumb={["OmnyComm", "Product Edit"]}
          />

          <Row gutter={[40, 40]}>
            <Col className="gutter-row" lg={12} sm={24}>
              {this.props.productDetails && (
                <BaseForm
                  brandList={this.state.brandList || []}
                  validator={this.validator}
                  disabled
                  productDetails={this.props.productDetails}
                  initiSuperCategory = {initSuperCat}
                  initiCategory={initCat}
                  initiSubCategory={initSubCat}
                />
              )}
            </Col>
            <Col className="gutter-row" lg={12} sm={24}>
              <Row gutter={[40, 40]}>
                {/* <Col span={24}>
                                    <Row gutter={[24, 24]} className="gutter-box">
                                        <Col>
                                            <ImageManager />
                                        </Col>
                                    </Row>
                                </Col> */}

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box">
                    <Col>
                      <ImageManagerCreateBaseProduct
                        updateImageList={this.updateImageList}
                        loading={this.state.loading}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoVital
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <DealshubProductVarientSection
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoAttributes
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoPFL
                          sendList={this.setlist}
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                          name = "Features: Variant"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                   {/* // HOW TO USE :varient. */}
                   <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoPFL
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          sendList={this.setsteplist}
                          productDetails={this.props.productDetails}
                          name = "How to Use: Variant"
                          buttontitle="Add Steps"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                  {/* FOR fAQ Section testing */}
                  <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsforFaq
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          sendList={this.setfaqlist}
                          productDetails={this.props.productDetails}
                          name = "FAQS: Variant"
                          buttontitle="Add FAQs"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col className="gutter-row" span={24}>
                  <Row gutter={[24, 24]} className="gutter-box">
                    <Col>
                      <Row
                        gutter={[24, 24]}
                        style={{ margin: "0px", minHeight: "230px" }}
                      >
                        <Col style={{ padding: "0px" }} sm={24}>
                          <FormHeader header="Product Description" />
                        </Col>
                        <Col sm={24} className="editorBox-container">
                          <EditorBox
                            value={this.state.description}
                            getText={this.getEditorText}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="gutter-row save-btn-edit-product-page" sm={24}>
              {/* <Row type="flex" justify="center" align="middle">
                                <Button onClick={this.handelSave} type="primary"> Save </Button>
                            </Row> */}
              <Tooltip placement="top" title="Save">
                <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
                  <Button
                    type="danger"
                    shape="circle"
                    icon="save"
                    size="large"
                    onClick={() => this.handelSave()}
                  ></Button>
                </Affix>
              </Tooltip>
            </Col>
          </Row>
        </EditProductPageWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails
  };
};

export default connect(mapStateToProps, {
  setEmpty,
  fetchBaseProductDetails,
  createNewProduct
})(CreateNewProduct);
