import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Checkbox,
  Avatar,
  Icon,
  Tooltip,
  Tag,
  message,
  Modal,
  Select,
} from "antd";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import UserAvatar from "../../media/male_avatar.png";

import { Ellipsis } from "react-spinners-css";

import "./factories.scss";

import isDev from "../../server";
import { Link } from "react-router-dom";

const serverUrl = isDev();

const { Title, Text } = Typography;

export default class Factories extends Component {
  state = {
    factories: null,
    contactModal: false,
    contactData: null,
    loader: false,
    total_factories : 0,
  };

  fetchFactories = (tags = []) => {
    this.setState({
      loader: true,
    });

    let data = {
      tags,
    };
    
    fetch(`${serverUrl}/sourcing/fetch-factory-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 200) {
          this.setState({ factories: data.factory_list,
          total_factories : data.factory_list.length });
        }
        this.setState({
          loader: false,
        });
      });
  }

  componentDidMount = () => {
   
    // factory details
    // let data = {
    //     "pk": 1
    // };
    // fetch(`${serverUrl}/sourcing/fetch-factory-details/`, {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `JWT ${localStorage.getItem("token")}`
    //     },
    //     body: JSON.stringify(data)
    // })
    //     .then(res => res.json())
    //     .then(data => {
    //

    //     });

    // factories
    
    this.fetchFactories();

    //fetch-products-factories
    // let data = {
    //     "tags": []
    // };
    // fetch(`${serverUrl}/sourcing/fetch-products-from-factory/`, {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `JWT ${localStorage.getItem("token")}`
    //     },
    //     body: JSON.stringify(data)
    // })
    //     .then(res => res.json())
    //     .then(data => {
    //
    //         if (data.status == 200) {
    //             this.setState({ factories: data.factories })
    //         }
    //     });

    //save factory details
    // let data = {
    //     "pk": 1,
    //     "factory_name": "sandeep",
    //     "factory_address": "",
    //     "factory_emailid": "",
    //     "loading_port": "",
    //     "location": "",
    //     "notes": "",
    //     "contact_person_name": "",
    //     "contact_person_mobile_number": "",
    //     "contact_person_emailid": "",
    //     "tag": "",
    //     "tag_info": "",
    // };
    // fetch(`${serverUrl}/sourcing/save-factory-details/`, {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `JWT ${localStorage.getItem("token")}`
    //     },
    //     body: JSON.stringify(data)
    // })
    //     .then(res => res.json())
    //     .then(data => {
    //
    //         if (data.status == 200) {
    //             this.setState({ factories: data.factories })
    //         }
    //     });

    //factory wise products
    // let data = {
    //     "factory_pk": 1,
    //     "tags": []
    // };
    // fetch(`${serverUrl}/sourcing/fetch-proforma-bundle-list/`, {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `JWT ${localStorage.getItem("token")}`
    //     },
    //     body: JSON.stringify(data)
    // })
    //     .then(res => res.json())
    //     .then(data => {
    //
    //         if (data.status == 200) {
    //             this.setState({ factories: data.factories })
    //         }
    //     });
  };

  handleChangeSearchTags = (e) => {
    this.setState({
      tags :e
    },() => {
      this.fetchFactories(this.state.tags);
    })
  }

  handleOk = (e) => {
    this.setState({
      contactModal: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      contactModal: false,
    });
  };

  openContact = (e, item) => {
    this.setState({
      contactModal: true,
      contactData: item.phone_numbers,
    });
  };

  render() {
    if (this.state.loader) {
      return (
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ width: "100%", height: "100%" }}
        >
          <Ellipsis color="#1ABC9C" size={100} />
        </Row>
      );
    }

    return (
      <Row className="factories-page-container">
        <TitleWithBreadCrumb
          title="Factories"
          breadcrumb={["OmnyComm", "Factories"]}
        />
        <Col span={24} className="factories-table-container">
          <Row gutter={[12, 0]} className="top-nav">
            <Col lg={3} sm={8}>
              {/* {this.props.productData ? (
                                <Button style={{ width: "100%" }} type="primary" ghost>
                                    Results:{" "}
                                    {this.props.productData &&
                                        this.props.productData.total_products}
                                </Button>
                            ) : (
                                    <Button type="primary" loading ghost></Button>
                                )} */}

              <Button style={{ width: "100%" }} type="primary" ghost loading = {this.state.loading}>
                Result : {this.state.total_factories}
              </Button>
            </Col>

            <Col lg={17} sm={24}>
              <Select
                dropdownClassName="search-chips"
                dropdownMatchSelectWidth="false"
                mode="tags"
                style={{ width: "100%" }}
                placeholder={[<Icon type="search" />, " Search here..."]}
                onChange={this.handleChangeSearchTags}
                value={this.state.tags}
              ></Select>
            </Col>

            {/* <Col lg={4} sm={8}>
              <Link to="/create-new-product">
                <Tooltip placement="top" title={`This feature is comming soon!`}>
                  <Button disabled={true} type="danger" style={{ width: "100%" }} icon="upload">
                    Bulk Upload
                </Button>
                </Tooltip>
              </Link>
            </Col> */}
          </Row>
          <Row className="factories-table">
            {this.state.factories &&
              this.state.factories.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={8}
                    md={12}
                    sm={24}
                    className="factory-card"
                  >
                     <Link
                              to={{ pathname: `factory-products/${item.uuid}` }}
                            >
                    <Row className="factory-card-content ">
                      <Col span={24}>
                        <Row style={{ marginBottom: "0px" }}>
                          <Col span={6}>
                            <Avatar
                              className="factory-avatar"
                              shape="square"
                              style={{ width: "60px", height: "60px" }}
                              src={item.image}
                            />
                          </Col>
                          <Col span={17} offset={1}>
                            <Typography.Paragraph
                              ellipsis={{ rows: 1, expandable: false }}
                              style={{ marginBottom: "0px" }}
                              className="text-color-1"
                              strong="true"
                            >
                              {item.name}
                            </Typography.Paragraph>

                            <div style={{ height: "40px" }}>
                              <Typography.Paragraph
                                ellipsis={{ rows: 2, expandable: false }}
                                style={{ marginBottom: "0px" }}
                              >
                                {item.address}
                              </Typography.Paragraph>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row gutter={[8, 8]}>
                          <Col lg={12} md={24}>
                            <Link
                              to={{ pathname: `factory-products/${item.uuid}` }}
                            >
                              <Button
                                icon="appstore"
                                block="true"
                                theme="filled"
                                className="margin-right"
                              >
                                {item.total_products} Products
                              </Button>
                            </Link>
                          </Col>
                          <Col lg={12} md={24}>
                            <Button
                              icon="phone"
                              onClick={(e) => this.openContact(e, item)}
                              block="true"
                              type="primary"
                            >
                              Call
                            </Button>
                          </Col>
                        </Row> */}
                      </Col>
                    </Row>
                    </Link>
                  </Col>
                );
              })}
          </Row>
        </Col>

        <Modal
          title="Phone Numbers"
          visible={this.state.contactModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {this.state.contactData &&
            this.state.contactData.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
        </Modal>
      </Row>
    );
  }
}
