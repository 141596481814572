import React, { Component } from "react";
import { Upload, Icon, message, Spin } from "antd";
import axios from "axios";
import "./upload-image.scss";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpgdl1";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/jpgdl1 file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}


class UploadImageAfter extends Component {
  state = {
    loading: false,
    fileListLength: null
  };

  handleUpload = ({ fileList }) => {
    this.props.handleUpload(fileList);
  };

  handleRemove = file => {
    this.props.handleRemove(file);
  };

  render() {
    let uploadButton;

    let uploadButtonPrevent;

    if (this.props.showUploadList || this.state.imageUrl == undefined)
      uploadButton = (
        
        <div>
          {this.props.loading && <Spin />}
          {!this.props.loading && (
            <Icon type={this.state.loading ? "loading" : "plus"} />
          )}
          <div className="ant-upload-text">
            You can drag and drop images or upload images
          </div>
          {
            this.props.extraText && 
            <div className="ant-upload-extra-text">
              {this.props.extraText}
            </div>
          }
          
        </div>
      );
    else
      uploadButton = (
        <img src={this.state.imageUrl} alt="avatar" style={{ width: "100%" }} />
      );

    if (this.props.showUploadList || this.state.imageUrl == undefined)
      uploadButtonPrevent = (
        <div>
          <Icon
            style={{ color: "red" }}
            type={this.state.loading ? "loading" : "warning"}
          />
          <div className="ant-upload-text" style={{ color: "red" }}>
            You cannot upload images for all images section. Please select
            another category
          </div>
        </div>
      );
    else
      uploadButtonPrevent = (
        <img src={this.state.imageUrl} alt="avatar" style={{ width: "100%" }} />
      );



    return (
      <React.Fragment>
        {/* {this.state.fileList.map(e => {
        return (<img src = {} />)
      })} */}

        {this.props.category == "all_images" ? (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={this.props.showUploadList}
            beforeUpload={() => false}
            fileList={this.props.fileList}
            onChange={this.handleChange}
            disabled = {this.props.disabled}
            onRemove={this.handleRemove}
            multiple={true}
            disabled
            style={{ height: "150px" }}
          >
            {uploadButtonPrevent}
          </Upload>
        ) : (
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={this.props.showUploadList}
              beforeUpload={() => false}
              fileList={this.props.fileList}
              disabled = {this.props.disabled}
              onChange={this.handleUpload}
              onRemove={this.handleRemove}
              style={{ height: "150px" }}
              multiple={true}
            >
              {uploadButton}
            </Upload>
          )}
      </React.Fragment>
    );
  }
}

export default UploadImageAfter;
