import React, { Component } from "react";
import { Row, Col, Menu, Dropdown, Icon , Typography } from "antd";

import "./image-manager.scss";
import UploadImageAfter from "../upload-image/UploadImageAfter";
import UploadImage from "../upload-image/UploadImage";
import isDev from "server";
import axios from "axios";
const serverUrl = isDev();

class ImageManagerForFactories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
      imagesData: [],
      imageDataNoCategories :[]
    };
  }

  onClick = item => {
    let str = item.item.props.children[0];
    this.setState({ itemSelected: str });
  };

  handleUpload = fileList => {
      this.setState({
          imagesData : fileList
      } , () => this.props.updateImageList(this.state.imagesData))
  };


  handleRemove = file => {
    this.setState({
        imagesData : this.state.imagesData.filter(e => e.uid != file.uid)
    } , () =>  this.props.updateImageList(this.state.imageData));
  };

  handleUploadEditMode = ({ file, fileList } , callback = null) => {
      let formData = new FormData();
      formData.append(`image_0`, file);
      formData.append("images_count", 1);
      formData.append("factory_product_uuid" , this.props.uuid)
      axios
        .post(`${serverUrl}/sourcing/upload-factory-product-images/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        })
        .then(res => {
          this.props.fetchImagesOnly();
          if(callback)
          callback();
          // this.fetchOnlyImages(noOfImages, fileList);
        })
        .catch(err => {

        });
  };

  handleRemoveEditMode = (file , callback = null) => {
        let data = {
            image_pk : file.pk,
            factory_product_uuid : this.props.uuid
        };
        fetch(`${serverUrl}/sourcing/delete-factory-product-image/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then(data => {
              if(callback)
              callback();
          });
    }

  

  render() {

    console.log(this.props , "Hey Buddy")

    let fileList = this.props.images || []

    for(let i = 0;i<fileList.length;i++)
    {
        fileList[i] = {...fileList[i], uid : i}
    }

    console.log(fileList);
    
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col sm={24}>
            <Typography.Text style = {{color : "white"}}>Images</Typography.Text>
          </Col>
          <Col sm={24}>
            {
              this.props.editMode ?
              <UploadImage
              fileList={fileList}
              hideEmpty={this.hideEmpty}
              showUploadList={true}
              scroll={true}
              category="main_images"
              disabled = {this.props.disabled}
              product_pk={this.props.product_pk}
              editMode = {this.props.editMode}
              handleUpload = {this.handleUploadEditMode}
              handleRemove = {this.handleRemoveEditMode}
              extraText = "Important: Please add atleast 3 high quality images"
            />
            :
            <UploadImageAfter
            fileList = {fileList}
            disabled = {this.props.disabled}
            handleUpload={this.handleUpload}
            handleRemove={this.handleRemove}
            showUploadList={true}
            scroll={true}
            loading={this.props.loading}
            extraText = "Important: Please add atleast 3 high quality images"
            />
            }
           
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ImageManagerForFactories;
