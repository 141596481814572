import React from "react";
import { Tabs, Spin } from "antd";
import AnalyticsCards from "./AnalyticsCard";

/**
 * 
 * @param {*} props The function takes data(from fetch-order-sales-analytics) and filtered data from (fetch-filtered-order-analytics) endpoint
 * @returns returns Tabs with Tabpanes as child holding Today, Monthly, and Filtered Analytics data
 */

const SalesOrderAnalytics = (props) => {

    if (!props.data) {
        return <Spin spinning={true} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }} />
    }


    return (
        <Tabs>
            <Tabs.TabPane tab="Today" key="today">
                <AnalyticsCards target={props.data.targets} data={props.data.todays} currency={props.data.currency} isToday={true} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="This Month" key="month">
                <AnalyticsCards target={props.data.targets} data={props.data.monthly} currency={props.data.currency} isMonthly={true} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Filtered" key="filtered">
                <AnalyticsCards target={props.data.targets} data={props.filteredData} currency={props.data.currency} isFiltered={true} />
            </Tabs.TabPane>
        </Tabs>
    );
};

export default SalesOrderAnalytics;