import React, {useEffect, useState} from 'react';
import {AutoComplete, Avatar, Button, Col, Empty, Icon, message, Row, Spin, Switch, Typography} from "antd";
import "./BlogPage.scss"
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import isDev from '../../server';
import placeHolderImage from "../../media/placeholder3.png";
import moment from 'moment';

const {Title, Text} = Typography;
const serverUrl = isDev();

const BlogPage = (props) => {
    const [loading, setLoading] = useState(false)
    const [blogList, setBlogList] = useState(Array.from({length: 10}, (_, index) => index + 1))
    const [isAvailable, setIsAvailable] = useState(false)
    const [pageIndex, setPageIndex] = useState(1)
    const [blogUuid, setBlogUuid] = useState("")
    const [searchString, setSearchString] = useState('')
    const history=useHistory()
    const fetchLoadMore = () => {
        //api call
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/fetch-blog-post-list/`,
            data: {
                locationGroupUuid: props.locationUuid,
                page: pageIndex
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setBlogList([...blogList, ...res.data.blogPostList])
                setIsAvailable(res.data.isAvailable)
                setPageIndex(pageIndex + 1)
            } else if (res.data.status === 403) {
                message.error("You are not authorized!")
            } else message.error("Something Went Wrong!")
        })
    }

    const autoCompleteSearch = (str) => {
        if (str !== "") {
            axios({
                method: "POST",
                url: `${serverUrl}/dealshub/search-blog-post-auto-complete/`,
                data: {
                    locationGroupUuid: props.locationUuid,
                    search_string: str
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then((res) => {
                if (res.data.status === 200) {
                    setBlogList(res.data.blogPostList)
                }
            });
        } else fetchBlogList()
    }

    const fetchBlogList = () => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/fetch-blog-post-list/`,
            data: {
                locationGroupUuid: props.locationUuid,
                page: 1
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setBlogList(res.data.blogPostList)
                setIsAvailable(res.data.isAvailable)
                setPageIndex(2)
            } else if (res.data.status === 403) {
                message.error("You are not authorized!")
            } else message.error("Something Went Wrong!")
        })
    }

    const createBlogPost = () => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/create-blog-post/`,
            data: {
                locationGroupUuid: props.locationUuid,
                title: "",
                headline: "",
                body: "",
                author: ""
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setBlogUuid(res.data.blogPostUuid)
                history.replace(`/compose-article/${res.data.blogPostUuid}/${props.locationUuid}`)
            } else if (res.data.status === 403) {
                message.error("You are not authorized!")
            } else message.error("Something Went Wrong!")
        })
    }


    const modifyBlogStatus = (checked, uuid) => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/modify-blog-post-status/`,
            data: {
                blogPostUuid: uuid,
                isPublished: checked ? "False" : "True"
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                checked ? message.success("Blog unpublished successfully") :
                    message.success("Blog Published Successfully")
                setBlogList(
                        blogList.map(blog => {
                        if (blog.uuid === uuid) {
                            return {
                                ...blog,
                                is_published: checked ? false : true
                            }
                        }
                        else return blog
                    })
                );
            } else if (res.data.status === 403) {
                message.error("You are not authorized")
            } else message.error("Something went wrong!")
        })
    }

    useEffect(() => {
        fetchBlogList()
    }, [])

    return (
        !loading ?
            <Row
                className="orders-account-manager-mobile-view-page-container"
                style={{marginRight: '10px'}}
                gutter={[16, 16]}>
                <Col span={24} className="title-container" style={{paddingRight: '0px'}}>
                    <Row type="flex" justify="start">
                        <Col span={17}>
                            <Title level={4} className="main-title">
                                Blog
                            </Title>
                        </Col>
                        <Col span={4} style={{textAlign: 'right'}}>
                            <Link to={`/blog-sections/${props.locationUuid}`}>
                                <Button type="primary" style={{width: '140px'}}>
                                    Sections
                                </Button>
                            </Link>
                        </Col>
                        <Col span={3} style={{textAlign: 'right'}}>

                            <Button type="primary" style={{width: '140px'}} onClick={createBlogPost}>
                                Compose Article
                            </Button>

                        </Col>
                    </Row>
                </Col>
                {/*<Col span={24} style={{marginTop: '30px'}}>*/}
                {/*    <Row gutter={[16, 16]}>*/}
                {/*        <Col lg={4} md={4} sm={8} xs={8} style={{padding: '0px 20px 0px 0px'}}>*/}
                {/*            <Col span={24} className="blog-analytics">*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text style={{color: '#FFFFFF'}}>*/}
                {/*                        <Icon type="file-text" style={{marginRight: '14px', fontSize: '20px'}}/>*/}
                {/*                        Articles Posted*/}
                {/*                    </Text>*/}
                {/*                </Col>*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text className="analytics-data">05</Text>*/}
                {/*                </Col>*/}
                {/*            </Col>*/}
                {/*        </Col>*/}
                {/*        <Col lg={4} md={4} sm={8} xs={8} style={{padding: '0px 20px 0px 0px'}}>*/}
                {/*            <Col span={24} className="blog-analytics">*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text style={{color: '#FFFFFF'}}>*/}
                {/*                        <Icon type="eye" style={{marginRight: '14px', fontSize: '20px'}}/>*/}
                {/*                        Total Views*/}
                {/*                    </Text>*/}
                {/*                </Col>*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text className="analytics-data">05</Text>*/}
                {/*                </Col>*/}
                {/*            </Col>*/}
                {/*        </Col>*/}
                {/*        <Col lg={4} md={4} sm={8} xs={8} style={{padding: '0px 20px 0px 0px'}}>*/}
                {/*            <Col span={24} className="blog-analytics">*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text style={{color: '#FFFFFF'}}>*/}
                {/*                        <Icon type="user" style={{marginRight: '14px', fontSize: '20px'}}/>*/}
                {/*                        Subscribers*/}
                {/*                    </Text>*/}
                {/*                </Col>*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text className="analytics-data">05</Text>*/}
                {/*                </Col>*/}
                {/*            </Col>*/}
                {/*        </Col>*/}
                {/*        <Col lg={5} md={5} sm={10} xs={10} style={{padding: '0px 20px 0px 0px'}}>*/}
                {/*            <Col span={24} className="blog-analytics">*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text style={{color: '#FFFFFF'}}>*/}
                {/*                        <Icon type="eye" style={{marginRight: '14px', fontSize: '20px'}}/>*/}
                {/*                        Avg Article Views*/}
                {/*                    </Text>*/}
                {/*                </Col>*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text className="analytics-data">05</Text>*/}
                {/*                </Col>*/}
                {/*            </Col>*/}
                {/*        </Col>*/}
                {/*        <Col lg={7} md={7} sm={14} xs={14} style={{padding: '0px'}}>*/}
                {/*            <Col span={24} className="blog-analytics">*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text style={{color: '#FFFFFF'}}>*/}
                {/*                        <Icon type="trophy" style={{marginRight: '14px', fontSize: '20px'}}/>*/}
                {/*                        Best Performing Article*/}
                {/*                    </Text>*/}
                {/*                </Col>*/}
                {/*                <Col span={24}>*/}
                {/*                    <Text className="analytics-data">Blog Name...</Text>*/}
                {/*                </Col>*/}
                {/*            </Col>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Col>*/}
                <Col span={24} style={{marginTop: '24px', backgroundColor: '#36404A', padding: '20px'}}>
                    <Col span={24} style={{padding: '0px'}}>
                        <Text style={{fontWeight: 'bold', fontSize: '20px', color: '#FFFFFF'}}>All Articles</Text>
                    </Col>
                    <Col span={24} style={{marginTop: '12px', padding: '0px'}}>
                        {/*Search Bar*/}
                        <AutoComplete
                            // dataSource={searchListing}
                            value={searchString}
                            onChange={(e) => setSearchString(e)}
                            onSearch={autoCompleteSearch}
                            placeholder="Search for Blogs"
                            style={{width: "100%"}}
                        >

                        </AutoComplete>
                    </Col>
                    <Col span={24} style={{
                        marginTop: '12px',
                        marginBottom: '5px',
                        backgroundColor: '#3F4A56',
                        height: '56px',
                        borderRadius: '4px'
                    }}>
                        <Col span={3} style={{color: '#DEE2E6', textAlign: 'center'}}>Cover</Col>
                        <Col span={13} style={{color: '#DEE2E6'}}>Title</Col>
                        <Col span={3} style={{color: '#DEE2E6'}}>Date Created</Col>
                        {/*<Col span={2} style={{color: '#DEE2E6'}}>Views</Col>*/}
                        <Col span={3} style={{color: '#DEE2E6'}}>Publish</Col>
                        <Col span={2}></Col>
                    </Col>
                    {/*Map blog list items*/}
                    <Col span={24} style={{padding: '0px'}} className={blogList.length > 5 ? "article-container" : ""}>
                        {blogList.length > 0 ? blogList.map((item) => (
                                <Col
                                    key={item} span={24}
                                    style={{marginBottom: '20px', backgroundColor: '#313841', borderRadius: '4px'}}>
                                    <Col span={3} style={{textAlign: 'center'}}>
                                        <Avatar shape={"square"} className="cover-img"
                                                src={item.cover_image === "" ? placeHolderImage : item.cover_image}/>
                                    </Col>
                                    <Col span={13}>
                                        <Text className="blog-text">{item.title}</Text>
                                        {/*<br/>*/}
                                        {/*<Text className="blog-text"> Line 2</Text>*/}
                                    </Col>
                                    <Col span={3}>
                                        <Text className="blog-text">{moment(item.date_created).format('DD-MM-YYYY')}</Text>
                                    </Col>
                                    {/*<Col span={2}>*/}
                                    {/*    <Text className="blog-text">0</Text>*/}
                                    {/*</Col>*/}
                                    <Col span={3}>
                                        <Switch
                                            defaultChecked={item.is_published}
                                            onChange={() => modifyBlogStatus(item.is_published, item.uuid)}
                                        />
                                    </Col>
                                    <Col span={2}>
                                        <Link to={`/compose-article/${item.uuid}/${props.locationUuid}`}>
                                            <Icon type="form" style={{fontSize: '24px', color: '#BFBFBF'}}/>
                                        </Link>
                                    </Col>
                                </Col>
                            )) :
                            <Empty
                                className='no-orders-wrapper'
                                description='No Blogs found'
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        }
                        {isAvailable &&
                        <Button
                            type="primary"
                            onClick={() => fetchLoadMore()}
                        >Load More...</Button>
                        }
                    </Col>
                </Col>
            </Row> :
            <div
                style={{
                    minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#313841",
                }}
            >
                <Spin indicator={<Icon type="loading" style={{fontSize: 24}} spin/>}/>
            </div>
    )
}

export default BlogPage