import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Typography,
	Avatar,
	Checkbox,
	Tag,
	Button,
	Modal,
} from 'antd';

const { Text } = Typography;
const genExtra = (str, heading) => (
	<Tag color={str[1]}>
		{heading ? heading + ' - ' : ''}
		{str[0]}
	</Tag>
);

const SAPListView = ({ SAPList }) => {
	console.log(SAPList);
	return (
		<Row style={{ width: '100%' }}>
			<Col span={24}>
				{SAPList && SAPList.doc_list && SAPList.doc_list.map((item) => {
					return item.type ? (
						<Row style={{ width: '100%' }}>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>Type</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>{item.type || '-'}</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>Id</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>{item.id || '-'}</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>Message Type</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>
										{item.message_type || '-'}
									</Text>
								</Col>
							</Row>
							<Row
								style={{
									marginBottom: '10px',
									borderBottom: '1px solid #1abc9c',
									width: '100%',
								}}
							>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>Message</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#1abc9c' }}>
										{item.message || '-'}
									</Text>
								</Col>
							</Row>
						</Row>
					) : null;
				})}
			</Col>
			<Col span={24}>
				{SAPList && SAPList.msg_list && SAPList.msg_list.map((item) => {
					return item.type ? (
						<>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Document Number</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>
										{item.document_number || '-'}
									</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Id</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>{item.id || '-'}</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Message</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>
										{item.message || '-'}
									</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Message V1</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>
										{item.message_v1 || '-'}
									</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Message V2</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>
										{item.message_v2 || '-'}
									</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Message V3</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>
										{item.message_v3 || '-'}
									</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Message V4</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>
										{item.message_v4 || '-'}
									</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>number</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>{item.number || '-'}</Text>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>Parameter</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>
										{item.parameter || '-'}
									</Text>
								</Col>
							</Row>
							<Row
								style={{
									marginBottom: '10px',
									borderBottom: '1px solid #f1556c',
									width: '100%',
								}}
							>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>type</Text>
								</Col>
								<Col span={12}>
									<Text style={{ color: '#f1556c' }}>{item.type || '-'}</Text>
								</Col>
							</Row>
						</>
					) : null;
				})}
			</Col>
		</Row>
	);
};

export default class OrdersAccountManagerMobileViewRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkboxSelected: false,
			SAPVisible: false,
			billing: false,
		};
	}

	componentDidMount = () => {
		this.setState({
			checkboxSelected: this.props.order.checked,
		});
	};

	static getDerivedStateFromProps(nextProps, previousState) {
		if (previousState.checkboxSelected != nextProps.order.checked) {
			return { checkboxSelected: nextProps.order.checked };
		} else {
			return null;
		}
	}

	toggleSAPVisible = (arg) => {
		this.setState({
			billing: !this.state.SAPVisible ? arg : null,
			SAPVisible: !this.state.SAPVisible,
		});
	};

	render() {
		// console.clear();
		console.log(this.props, this.state);

		return (
			<Row className='order-container' gutter={[8, 8]}>
				<Col span={24} className='order-row'>
					<Row type='flex' align='top'>
						<Col
							span={6}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								flexDirection: 'column',
							}}
						>
							<Avatar
								shape='square'
								size={72}
								src={this.props.order.productImageUrl}
							/>
						</Col>
						<Col span={18}>
							<Row>
								<Col span={24}>
									<Row type='flex' justify='space-between'>
										<Col>
											<Link
												to={`/order-details/${this.props.locationGroupUuid}/${this.props.bundleUuid}`}
												style={{ fontSize: '14px' }}
											>
												<Text>{this.props.order.orderId}</Text>
											</Link>
										</Col>
										<Col>
											<Col span={2}>
												<Checkbox
													value={this.props.order.uuid}
													checked={this.state.checkboxSelected}
													onChange={(e) => {
														this.props.toggleOrderCheck(
															this.state.checkboxSelected,
															e.target.value,
															this.props.bundleId,
														);
														this.setState({
															checkboxSelected: !this.state.checkboxSelected,
														});
													}}
												/>
											</Col>
										</Col>
									</Row>
								</Col>

								<Col span={24}>
									<Row>
										<Col>
											<Typography.Paragraph
												ellipsis={{ rows: 2, expandable: false }}
											>
												{this.props.order.productName}
											</Typography.Paragraph>
										</Col>
									</Row>
								</Col>
								<Col span={24}>
									<Text>
										{this.props.order.price} {this.props.order.currency}
									</Text>
								</Col>

								<Col span={24}>
									<Row type='flex' gutter={[8, 8]}>
										<Col>
											<Text>{this.props.order.quantity}</Text>
										</Col>
										<Col>
											<Text>Qty</Text>
										</Col>
									</Row>
								</Col>
								<Col span={24}>
									<Row type='flex' gutter={[8, 8]}>
										<Col>
											<Text>Intercompany Order Id : {this.props.order.intercompany_order_id}</Text>
										</Col>
									</Row>
								</Col>
								<Col span={24} style={{ paddingTop: '4px' }}>
									<Row type='flex' gutter={[4, 4]}>
									<Col>
											<Text className='table-head-text'>
												{/* {this.props.order.shippingMethod} */}
												{this.props.order.shippingMethod === 'grand gaadi' ? (
													<React.Fragment>
														{genExtra(
															[this.props.order.shippingMethod, 'magenta'],
															'Shipping Method',
														)}
													</React.Fragment>
												) : (
													<React.Fragment>
														{this.props.order.shippingMethod === 'pending' ? (
															<React.Fragment>
																{genExtra(
																	[
																		this.props.order.shippingMethod
																			.charAt(0)
																			.toUpperCase() +
																			this.props.order.shippingMethod.substr(1),
																		'yellow',
																	],
																	'Shipping Method',
																)}
															</React.Fragment>
														) : (
															<React.Fragment>
																{genExtra(
																	[this.props.order.shippingMethod, 'blue'],
																	'Shipping Method',
																)}
															</React.Fragment>
														)}
													</React.Fragment>
												)}
											</Text>
										</Col>

										<Col>
											<Text className='table-head-text'>
												{this.props.order.currentStatus === 'cancelled' ||
												this.props.order.currentStatus === 'delivery failed' ? (
													<React.Fragment>
														{this.props.order.currentStatus === 'cancelled' ? (
															<React.Fragment>
																{genExtra(
																	['Cancelled', 'red'],
																	'Current Status',
																)}
															</React.Fragment>
														) : (
															<React.Fragment>
																{genExtra(['Delivery Failed', 'red'])}
															</React.Fragment>
														)}
													</React.Fragment>
												) : (
													<React.Fragment>
														{this.props.order.currentStatus === 'pending' ? (
															<React.Fragment>
																{genExtra(
																	[
																		this.props.order.currentStatus
																			.charAt(0)
																			.toUpperCase() +
																			this.props.order.currentStatus.substr(1),
																		'orange',
																	],
																	'Current Status',
																)}
															</React.Fragment>
														) : (
															<React.Fragment>
																{genExtra(
																	[
																		this.props.order.currentStatus
																			.charAt(0)
																			.toUpperCase() +
																			this.props.order.currentStatus.substr(1),
																		'green',
																	],
																	'Current Status',
																)}
															</React.Fragment>
														)}
													</React.Fragment>
												)}
											</Text>
										</Col>

										{this.props.order.sapStatus ? (
											<Col onClick={this.toggleSAPVisible}>
												<Text className='table-head-text'>
													<React.Fragment>
														{genExtra(
															[this.props.order.sapStatus, 'geekblue'],
															'SAP Status',
														)}
													</React.Fragment>
												</Text>
											</Col>
										) : (
											<></>
										)}
										{this.props.order.final_qty !== '' && this.props.order.intercompany_qty !== '' && Number(this.props.order.final_qty) !==
										Number(this.props.order.intercompany_qty) ? (
											<>
												<Col>
												<Text className='table-head-text'>
													<React.Fragment>
														{genExtra(
															[Number(this.props.order.final_qty), 'red'],
															'SAP Final Qty',
														)}
													</React.Fragment>
														</Text>
														</Col>
														<Col>
												<Text className='table-head-text'>
													<React.Fragment>
														{genExtra(
															[
																Number(this.props.order.intercompany_qty),
																'red',
															],
															'SAP Intercompany Qty',
														)}
													</React.Fragment>
												</Text>
											</Col>
											</>
										) : (
											<></>
										)}
									</Row>
								</Col>
							</Row>
						
								<Modal
									visible={this.state.SAPVisible}
									footer={null}
									onOk={this.toggleSAPVisible}
									onCancel={this.toggleSAPVisible}
								>
									<div
										style={{
											display: 'flex',
											maxHeight: '500px',
											overflowY: 'scroll',
											padding: '10px',
										}}
									>
										<SAPListView
											SAPList={this.props.order.sap_intercompany_info}
										/>
									</div>
								</Modal>

						</Col>
					</Row>
				</Col>
				<Modal
					visible={this.state.SAPVisible}
					footer={null}
					onOk={this.toggleSAPVisible}
					onCancel={this.toggleSAPVisible}
				>
					<div
						style={{
							display: 'flex',
							maxHeight: '500px',
							overflowY: 'scroll',
							padding: '10px',
						}}
					>
						<SAPListView SAPList={this.props.order.sap_intercompany_info} />
					</div>
				</Modal>
			</Row>
		);
	}
}

// <Col span={24} style={{ marginTop: '5px' }}>
//                                     <Row type="flex">
//                                         <Col>
//                                             <Text type="secondary">Tracking Status :</Text>
//                                         </Col>
//                                         <Col style={{ marginLeft: '5px' }}>
//                                             <Text className="table-head-text">
//                                                 {
//                                                     this.props.order.currentStatus === 'cancelled' || this.props.order.currentStatus === 'delivery failed' ? (
//                                                         <React.Fragment>
//                                                             {this.props.order.currentStatus === 'cancelled' ? (
//                                                                 <React.Fragment>
//                                                                     {
//                                                                         genExtra(["Cancelled", "red"])
//                                                                     }
//                                                                 </React.Fragment>
//                                                             ) : (
//                                                                     <React.Fragment>
//                                                                         {
//                                                                             genExtra(["Delivery Failed", "red"])
//                                                                         }
//                                                                     </React.Fragment>
//                                                                 )}
//                                                         </React.Fragment>
//                                                     ) : (
//                                                             <React.Fragment>
//                                                                 {
//                                                                     this.props.order.currentStatus === 'pending' ? (
//                                                                         <React.Fragment>
//                                                                             {
//                                                                                 genExtra([
//                                                                                     this.props.order.currentStatus.charAt(0).toUpperCase() +
//                                                                                     this.props.order.currentStatus.substr(1)
//                                                                                     , "yellow"])
//                                                                             }
//                                                                         </React.Fragment>
//                                                                     ) : (
//                                                                             <React.Fragment>
//                                                                                 {
//                                                                                     genExtra([
//                                                                                         this.props.order.currentStatus.charAt(0).toUpperCase() +
//                                                                                         this.props.order.currentStatus.substr(1)
//                                                                                         , "green"])
//                                                                                 }
//                                                                             </React.Fragment>
//                                                                         )
//                                                                 }
//                                                             </React.Fragment>
//                                                         )
//                                                 }
//                                             </Text>
//                                         </Col>
//                                     </Row>
//                                 </Col>
