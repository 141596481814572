import React, { Component } from 'react'
import { Row, Col, Typography, Tag, Button } from 'antd';
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import "./invoice.scss"
const { Title, Text } = Typography;



export default class Invoice extends Component {
    render() {
        return (
            <Row className="invoice-container">
                <TitleWithBreadCrumb title="Invoice" breadcrumb={["OmnyComm", "Invoice"]} />
                <Col span={24} className="invoice-content-container" >
                    <Row className="invoice-content">
                        <Col span={24}>
                            <Row type="flex" justify="end" style={{ marginBottom: "10px" }}>
                                <Title level={4} className="text-color-2">Invoice</Title>
                            </Row>
                            <Row type="flex" justify="space-between" style={{ marginBottom: "30px" }}>
                                <Col span={12}>
                                    {/* <Row>
                                <Text strong="true" className="text-color-3">Hello, Stanley Jones</Text>
                                <br />
                                <Text className="text-color-1">Thanks a lot because you keep purchasing our products. Our company promises to provide high quality products for you as well as outstanding customer service for every transaction.</Text>
                            </Row> */}
                                </Col>
                                <Col span={4} offset={8}>
                                    <Row type="flex" justify="end" gutter={[8, 8]}>
                                        <Col span={24}>
                                            <Row type="flex" justify="space-between">
                                                <Col span={12}>
                                                    <Text strong="true" className="text-color-3">Order Date:</Text>
                                                </Col>
                                                <Col span={11} offset={1}>
                                                    <Text className="text-color-1">Jan 17, 2016</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}>
                                                    <Text strong="true" className="text-color-3">Order Status:</Text>
                                                </Col>
                                                <Col span={11} offset={1}>
                                                    <Tag color="green">Paid</Tag>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}>
                                                    <Text strong="true" className="text-color-3">Order No.:</Text>
                                                </Col>
                                                <Col span={11} offset={1}>
                                                    <Text className="text-color-1">000028</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "30px" }}>
                                <Col span={12}>
                                    <Text className="text-color-2">Billing Address</Text>
                                    <br />
                                    <Text className="text-color-1">
                                        Stanley Jones
                                    </Text>
                                    <br />
                                    <Text className="text-color-1">
                                        795 Folsom Ave, Suite 600
                            </Text>
                                    <br />
                                    <Text className="text-color-1">
                                        San Francisco, CA 94107
                            </Text>
                                    <br />
                                    <Text className="text-color-1">
                                        P: (123) 456-7890
                            </Text>
                                </Col>
                                <Col span={12}>
                                    <Text className="text-color-2">Shipping Address</Text>
                                    <br />
                                    <Text className="text-color-1">
                                        Stanley Jones
                            </Text>
                                    <br />
                                    <Text className="text-color-1">
                                        795 Folsom Ave, Suite 600
                            </Text>
                                    <br />
                                    <Text className="text-color-1">
                                        San Francisco, CA 94107
                            </Text>
                                    <br />
                                    <Text className="text-color-1">
                                        P: (123) 456-7890
                            </Text>
                                </Col>
                            </Row>
                            <Row className="product-table" style={{ marginBottom: "30px" }}>
                                <Col span={24}>
                                    <Row className="table-head">
                                        <Col span={2}>
                                            <Text className="text-color-2">
                                                #
                                    </Text>
                                        </Col>
                                        <Col span={18}>
                                            <Text className="text-color-2">
                                                Item
                                    </Text>
                                        </Col>
                                        <Col span={2}>
                                            <Text className="text-color-2">
                                                Quantity
                                    </Text>
                                        </Col>
                                        <Col span={2}>
                                            <Text className="text-color-2">
                                                Total
                                    </Text>
                                        </Col>
                                    </Row>
                                    <Row className="table-content-row" type="flex" align="middle">
                                        <Col span={2}>
                                            <Text className="text-color-1">
                                                1
                                    </Text>
                                        </Col>
                                        <Col span={18}>
                                            <Text className="text-color-1" strong="true">
                                                Web Design
                                    </Text>
                                            <br />
                                            <Text className="text-color-1">
                                                2 Pages static website - my website
                                    </Text>
                                        </Col>
                                        <Col span={2}>
                                            <Text className="text-color-1">
                                                30
                                    </Text>
                                        </Col>
                                        <Col span={2}>
                                            <Text className="text-color-1">
                                                660 AED
                                    </Text>
                                        </Col>
                                    </Row>
                                    <Row className="table-content-row" type="flex" align="middle">
                                        <Col span={2}>
                                            <Text className="text-color-1">
                                                2
                                    </Text>
                                        </Col>
                                        <Col span={18}>
                                            <Text className="text-color-1" strong="true">
                                                Software Development
                                    </Text>
                                            <br />
                                            <Text className="text-color-1">
                                                Invoice editor software - AB'c Software
                                    </Text>
                                        </Col>
                                        <Col span={2}>
                                            <Text className="text-color-1">
                                                35
                                    </Text>
                                        </Col>
                                        <Col span={2}>
                                            <Text className="text-color-1">
                                                3937 AED
                                    </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={5} offset={19}>
                                    <Row type="flex" justify="space-between" gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Text strong="true" className="text-color-1">Sub Total:</Text>
                                        </Col>
                                        <Col span={11} offset={1}>
                                            <Text className="text-color-1">4597.50 AED</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text strong="true" className="text-color-1">Discount (10%):</Text>
                                        </Col>
                                        <Col span={11} offset={1}>
                                            <Text className="text-color-1">459.75 AED</Text>
                                        </Col>
                                        <Col span={24}>
                                            <Title level={4} className="text-color-2">
                                                4137.75 AED
                                    </Title>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Text strong="true" className="text-color-2">
                                        Notes:
                            </Text>
                                    <br />
                                    <Text className="text-color-1">
                                        All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above.
                            </Text>
                                </Col>
                            </Row>
                            <Row type="flex" justify="end">
                                <Button type="danger" icon="printer" className="margin-right">
                                    Print
                        </Button>
                                <Button type="primary" >
                                    Submit
                        </Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
