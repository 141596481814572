import React from "react";
import { Row, Col, Avatar, Typography, Icon, Tag } from "antd";
import { locationUuidConst } from "constants/locationUuid";

const { Text } = Typography;

export default function BasicCustomerDetails(props) {
    return (
        <React.Fragment>
            <Row style={{ padding: "5px 5px 0px 5px" }} type="flex" align="middle">

                <Col span={18}>
                    <Row type="flex" justify="start" align="middle" gutter={[12, 12]}>
                        <Col span={4}>
                            <Avatar size={64} icon="user" />
                        </Col>

                        <Col>
                            <Text style={{ color: "white", fontSize: "14px" }}>
                                {props.customer && (props.customer.customerDetails.customerName == " " ? "-" : props.customer.customerDetails.customerName)}
                            </Text>
                        </Col>

                        {/* <Col>
                            <Text style={{
                                fontSize: "11px",
                                color: "#6ac66a",
                                fontWeight: "lighter"
                            }}><Icon type="caret-right" style={{ color: "#6ac66a" }} /> Active</Text>
                        </Col> */}
                    </Row>
                </Col>

                <Col span={6} >
                    <Row type="flex" justify="end" align="middle">
                        {
                            props.customer && !props.customer.customerDetails.is_cart_empty &&
                            <Col>
                                <Tag color="orange" style={{ borderRadius: "10px" }}><Icon type="crown" /> Lead</Tag>
                            </Col>
                        }

                    </Row>
                </Col>

            </Row>

            <Row style={{ padding: "0px 5px 5px 5px", position: "relative", bottom: "10px" }} type="flex" align="middle">
                <Col span={24}>
                    <Row gutter={[4, 12]} type="flex" justify="start" align="middle">
                        <Col span={3}></Col>
                        <Col>
                            <Tag color="green" style={{
                                borderRadius: "10px", fontSize: "10px", padding: "0px 20px"
                                , backgroundColor: "#1abc9c66", color: "#59CAAA", borderColor: "#59CAAA" 
                            }}><Icon type="phone" /> {props.locationGroupUuid && (props.locationGroupUuid === locationUuidConst.QA_geepas_uganda||props.locationGroupUuid === locationUuidConst.Geepas_uganda) ? "+256":"+971" }-{props.customer && props.customer.customerDetails.contactNumber}</Tag>
                            {/* This locationGroupUuid is basically added to distinguish b/w geepas uganda & other websites as geepas uganda's number start with +256 & it has locationGroupUuid == 86689-513438asd-112ddcsd */}
                        </Col>

                        <Col>
                            <Tag color="green" style={{
                                borderRadius: "10px", fontSize: "10px", padding: "0px 20px"
                                , backgroundColor: "#52c41a70", color: "#52c41a", borderColor: "#52c41a"
                            }}><Icon type="mail" /> {props.customer && props.customer.customerDetails.emailId}</Tag>
                        </Col>

                    </Row>
                    <div style={{ marginTop: "7px" }}></div>
                    {
                        props.customer && props.customer.customerDetails.addressList.map((item) => {
                            return (
                                <Row style={{ marginTop: "3px" }} type='flex'>
                                    <Col span={3}></Col>
                                    <Col>
                                        <Icon type="environment" />
                                    </Col>

                                    <Col style={{ marginLeft: "4px" }}>
                                        <Text>{item}</Text>
                                    </Col>

                                </Row>
                            )
                        })
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
}