
import React , {Component , useState , useEffect} from "react";
import RightWrapper from  "my-components/ui-components/RightWrapper";
import { Row,
    Col,
    Button,
    message,
    Empty,
    Typography,
    Collapse,
    Icon,
    Tag,
    Select,
    Input,
    Divider,
    Spin,
    Modal,
    Tooltip,} from "antd";
import OrdersWarehouseManagerMobileViewRow from "./OrdersWarehouseManagerMobileViewRow";
import axios from "axios";
import { saveAs } from '@progress/kendo-file-saver';
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import isDev from "../../server";
import { setOrdersManagerDetails } from "actions/ordersManager";
import "./orders-warehouse-manager.scss"
import "views/orders-account-manager-page/orders-account-manager-page.scss"
import LOGO from "./logo_PPLUS.jpg"
import bwipjs from 'bwip-js';
import createDOMPurify from 'dompurify';

const serverOmnycommUrl = isDev();

const {Title , Text} = Typography;
const { Panel } = Collapse;

const DOMPurify = createDOMPurify(window);

const orderStatues = [
    "approved",
    "picked",
    "dispatched",
    "delivered",
    "delivery failed",
  ];


const DetailsOrderTracking = (props) => {

    const [loading , setLoading] = useState(false);
    const [data , setData] = useState([])
    const [awb_number , setawb_number] = useState("");

    useEffect(() => {
        setLoading(true);
        axios
        .post(
          `${serverOmnycommUrl}/dealshub/fetch-postaplus-tracking/`,
          {
            uuid : props.orderUuid
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
            setLoading(false)
            if(response.data.status == 200)
            {
               setData(response.data.tracking_data);
               setawb_number(response.data.awb_number);
            }
            else if(response.data.staus == 403)
            {
                message.info("you are not authorised!")
            }
            else
            {
                message.error("Something went wrong!");
            }
        });
    },[])

    if(loading)
    {
      return (
        <div>
        <Spin size="small" />
      </div>
      )
    }

    const convertTime = (str) => {
       let timeFir = parseInt(str.split(":")[0]);
       let AMorPM = timeFir >= 12 ? "PM" : "AM";

       if(timeFir == 24)timeFir = 0;
       else if(timeFir == 12)timeFir = 12;
       else timeFir = timeFir%12;

       return timeFir + ":" + str.split(":")[1] + " " + AMorPM;
    }

    let dateMap = {};

    return (
        <React.Fragment>
            <Row gutter = {[8,8]}>

                <Col>
                <Text type="secondary" style = {{fontSize : "20px"}}>Delivery by PostaPlus</Text>
                </Col>
                <Col>
                <Text type="secondary" style = {{fontSize : "14px", marginTop : "10px" , marginBottom : "20px"}}>Tracking ID : {awb_number}</Text>
                </Col>

                {
                    data.map((item , index) => {

                        let flag = false;

                        if(!dateMap[item.timestamp.split(" ")[0]]){
                          flag = true;
                          dateMap[item.timestamp.split(" ")[0]] = true;
                        }

                        return (
                          <React.Fragment>

                            {
                              flag &&
                              <Col span = {24} style = {{margin : "10px 0px 0px 0px"}}>
                              <Text type="secondary">{item.timestamp.split(" ")[0]}</Text>
                              </Col>
                            }
                          <Row style = {{marginBottom : "8px" , paddingLeft : "10px"}}>
                              <Col span = {5} key = {index} style = {{borderRight : "1px solid #495561" , padding : "0px"}}>
                                <Text type="secondary">{convertTime(item.timestamp.split(" ")[1])}</Text>
                              </Col>

                              <Col span = {17} style = {{marginLeft : "10px"}}>
                                    <Text style = {{textAlign : "right"}}>{item.event}</Text>
                              </Col>
                            </Row>
                            
                            </React.Fragment>
                        )
                    })
                }
            </Row>
        </React.Fragment>
    )
}
  

const PostaPlusPDF = (props) => {

  let _element;

  const [data , setData] = useState({
    consignee_from_address: "",
    consignee_to_address: "",
    ship_date: ""
  })

  const [loading , setLoading] = useState(true);

  useEffect(()=>{

    setLoading(true);
    axios
        .post(
          `${serverOmnycommUrl}/dealshub/fetch-postaplus-details/`,
          {
            orderUuid : props.orderUuid
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
              setLoading(false);
              if(response.data.status == 200)
              {
                   setData({...data , ...response.data});
                   try {
                    // The return value is the canvas element
                    let canvas = bwipjs.toCanvas('mycanvas', {
                              bcid:        'code39',       // Barcode type
                              text:        response.data.awb_number,    // Text to encode
                              scale:       2,               // 3x scaling factor
                              height:      8,              // Bar height, in millimeters
                              includetext: true,            // Show human-readable text       // Always good to set this
                          });
                  } catch (e) {
                      // `e` may be a string or Error object
                  }
              }
              else
              {
                message.error("Something went wrong");
              }
        });

  },[])

 const setElement = (e) => {
    props.set_element(e , data.awb_number);
  }

  if(loading)
    {
      return (
        <div>
        <Spin size="small" />
      </div>
      )
    }
  


  return (
    <React.Fragment>
       <div ref={(div) => {setElement(div)}}>
                        <Row
                          gutter={[8, 8]}
                          className="order-invoice-wrapper"
                        >
                              <Col span={24} className="order-invoice-inner-col">
                                <Row gutter={[8, 8]}>
                                  <Col span={8}>
                                    <img
                                      alt="wig me logo"
                                      style = {{width : "200px"}}
                                      src={LOGO}
                                    />
                                  </Col>
                                  <Col span={4}>
                                  </Col>
                                  <Col span={7} offset={5}>
                                    <Row>
                                      <Col span={24}>
                                        <Text className = "normal-text">POSTA PLUS</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text className = "normal-text">Modern line Distribution LLC.</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text className = "normal-text">PO Box: 25111, 9th Street - Umm Ramool</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text className = "normal-text">Dubai . UAE.</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text className = "normal-text">Tele: +9714 7018600 </Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text className = "normal-text">Email: dxbfreight@postaplus.com</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text className = "normal-text" style = {{fontStyle : "bold" , textDecoration : "underline"}}>Domestic Standard</Text>
                                      </Col>
                                      <Col span={24}>
                                        <Text className = "normal-text"></Text>
                                      </Col>
                                      <Col span={24}>
                                          <Text className = "normal-text">Ship Date : {data.ship_date}</Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>

                              <Col span={24} className="order-invoice-inner-col">
                                    <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
                                          <Text className="title">From : </Text>
                                        </Col>
                                        <Col span = {16}>
                                        <div className = "normal-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.consignee_from_address) }} ></div>
                                        </Col>
                                    </Row>                              
                              </Col>

                              <Col span={24} className="order-invoice-inner-col">
                                    <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
                                          <Text className="title">To : </Text>
                                        </Col>
                                        <Col span = {16}>
                                           <div className = "normal-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.consignee_to_address) }}></div>
                                        </Col>
                                    </Row>                              
                              </Col>

                              <Col
                                span={24}
                                className="table-wrapper"
                              >
                              <Row
                              style={{ display: "flex" }}
                              className="orders-listing-table-wrapper"
                            >
                              <Col span={8}>
  <Text className="title">weight : {data.weight}</Text>
                              </Col>
                              <Col span={8}>
  <Text className="title">Pieces : {data.total_pieces}</Text>
                              </Col>
                              <Col span={8}>
                                <Text className="title">ID : </Text>
                              </Col>
                            </Row>


                            <Row
                              style={{ display: "flex" }}
                              className="orders-listing-table-wrapper"
                            >
                              <Col span={8}>
  <Text className="title">COD Amt : {data.cod_amt}</Text>
                              </Col>
                              <Col span={8}>
  <Text className="title">COD Currency : {data.cod_currency}</Text>
                              </Col>
                              <Col span={8}>
                                <Text className="title"></Text>
                              </Col>
                            </Row>


                            <Row
                              style = {{padding : "10px" , border : "1px solid black"}}
                            >
                                  <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
                                          <Text className="title">Description : </Text>
                                        </Col>
                                        <Col span = {16}>
  <Text className = "normal-text">{data.description}</Text>
                                        </Col>
                                  </Row>
                                         <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
                                          <Text className="title">Reference-1 : </Text>
                                        </Col>
                                        <Col span = {16}>
                                            <Text className = "normal-text">-</Text>
                                        </Col>  
                                        </Row>

                                        <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
  <Text className="title">Reference-2 : </Text>
                                        </Col>
                                        <Col span = {16}>
  <Text className = "normal-text">{data.reference2}</Text>
                                        </Col>
                                        </Row>
                                        <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
                                          <Text className="title">Service : </Text>
                                        </Col>
                                        <Col span = {16}>
                                            <Text className = "normal-text">Domestic Standard</Text>
                                        </Col>
                                        </Row>

                                </Row>

                                <Row
                              style = {{padding : "10px" , border : "1px solid black"}}
                            >
                                        <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
                                          <Text className="title">Shipper : </Text>
                                        </Col>
                                        <Col span = {16}>
  <Text className = "normal-text">{data.shipper}</Text>
                                        </Col>
                                        </Row>
                                        <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8} style = {{height : "100%"}}>
                                          <Text className="title">Order number : </Text>
                                        </Col>
                                        <Col span = {16}>
                                            <Text><canvas id="mycanvas"></canvas></Text>
                                        </Col>
                                        </Row>
                              </Row>
                                        <Row
                              style = {{padding : "10px" , border : "1px solid black"}}
                            >
                                        <Row type = "flex" justify = "start" align = "middle">
                                        <Col span = {8}>
                                          <Text className="title">Note : </Text>
                                        </Col>
                                        <Col span = {16}>
                                            <Text className = "normal-text">-</Text>
                                        </Col>
                                        </Row>
                                    </Row> 

        

                             </Col>

                          </Row>
        </div>
    </React.Fragment>
  )
}

const collapseHeader = (order , callback1 , callback2) => {
    
    let tempTotalCost = 0;
    order.unitOrderList.forEach((unitOrder) => {
      tempTotalCost += unitOrder.quantity * unitOrder.price;
    });
    return (
      <Row
        type="flex"
        justify="space-between"
        className="collapse-header"
        gutter={[8, 8]}
        style = {{padding : "10px"}}
      >
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Text type="secondary">ID : </Text>
              <Text>{order.bundleId}</Text>
              <Tooltip title = "Track this order">
                <Icon type = "environment" onClick = {(e) => {e.stopPropagation();callback1();}}
                style = {{cursor : "pointer" , marginLeft : "5px" , color : "#1ABC9C"}}/>
              </Tooltip>
              <Tooltip title = "Download">
                <Icon type = "pic-left" onClick = {(e) => {e.stopPropagation();callback2();}}
                style = {{cursor : "pointer" , marginLeft : "5px" , color : "#1ABC9C"}}/>
              </Tooltip>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col>
                  <Text type="secondary">Date: </Text>
                  <Text>{order.dateCreated}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Text type="secondary">Item count : </Text>
              <Text>{order.unitOrderList.length}</Text>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col>
                  <Text type="secondary">Time: </Text>
                  <Text>{order.time}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Text>{order.customerName}</Text>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col>
                <Text type="secondary">Total: </Text>
                  <Text>{tempTotalCost} {order.currency}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* <Col span={24}>
          <Row>
            <Col span={12}>
              <Button type = "primary" onClick = {(e) => {e.stopPropagation();callback();}}>Track</Button>
            </Col>
          </Row>
        </Col> */}

                   

      </Row>
    );
  };

const InnerWrapper = (props) => {

    const [loading , setLoading] = useState(false);
    const [totalResults , setTotalResults] = useState(0);
    const [fetchingOrdersList , setfetchingOrdersList] = useState(false);
    const [someProcessingActive , setsomeProcessingActive] = useState(false);
    const [allowStatusChange , setallowStatusChange] = useState(false);
    const [website_group_name , setWebsite_group_name] = useState(null);
    const [orders , setorders] = useState([]);
    const [isAvailable , setIsAvailable] = useState(false);
    const [page , setPage] = useState(1);
    const [showModal , setShowModal] = useState(false);
    const [showModalPDF , setShowModalPDF] = useState(false);
    const [orderUuid , setOrderUuid] = useState(null);
    const [searchList , setSearchList] = useState([]);
    const [openModal , setopenModal] = useState({});

    const handleSearchChange = (tags) => {
        setorders([]);
        setSearchList(tags);
    }



    const fetchData = (this_page , this_orders) => {
      setfetchingOrdersList(true);
      setsomeProcessingActive(true);
      setallowStatusChange(true);
      setLoading(true);
      let temp = [];
      axios
        .post(
          `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
          {
            trackingStatusList: temp,
            page : this_page,
            "website_group_name": website_group_name,
            locationGroupUuid : props.locationUuid,
            isPostaplus : true,
            searchList
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
            setLoading(false)
            if(response.data.status == 200)
            {
                setIsAvailable(response.data.isAvailable);
                setfetchingOrdersList(false);
                setsomeProcessingActive(false);
                setTotalResults(response.data.totalOrders)
                setorders([...this_orders , ...response.data.orderList]);
            }
            else
            {
                message.error("Something went wrong!");
            }
        });
    }


    useEffect(() => {
         fetchData(1 , []);
    },[website_group_name,searchList])


    useEffect(()=>{
         fetchData(1 , []);
    },[props.locationUuid])

    const loadMoreOrders = () => {
      fetchData(page + 1 , [])  
      setPage(page+1)
    }


    const fetchInitialData = () => {
        axios
        .post(
          `${serverOmnycommUrl}/fetch-company-profile/`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`
            }
          }
        )
        .then(response => {
          if (response.data.status == 200)
            setWebsite_group_name( response.data.company_data.name)
        });
      }

      let _element;
      let _awb_number;

      const  exportPDFWithMethod = () => {
          drawDOM(_element, {
            paperSize: 'A4',
            multiple: 'true',
            margin: '0.2cm',
            scale: 0.6,
            keepTogether: ".prevent-split"
          }).then(
            (group) => {
              return exportPDF(group);
            }).then((dataUri) => {
              let base64URI = dataUri;
              saveAs(dataUri, `Postaplus_${_awb_number}.pdf`);
              message.success("Invoice Generated successfully!");
            });
      }
    

    return (
        <React.Fragment>
            {/* title */}
            {/* searchBar */}
            {/* count */}
            {/* Cards */}

            <Row gutter = {[12,12]} style = {{marginTop : "0px" , padding : "8px"}}>
                <Col span = {24}>
                    <Title level={4} style = {{color : "white"}}>
                        PostaPlus Tracking
                    </Title>
                </Col>

                <Col span = {24}>

                    <Row gutter = {[8,8]}>
                        <Col span = {21}>
                            <Select
                              dropdownClassName="search-chips"
                              dropdownMatchSelectWidth="false"
                              mode="tags"
                              style={{ width: "100%" }}
                              onChange={handleSearchChange}
                              placeholder={[<Icon type="search" />, " Search here..."]}
                            ></Select>
                        </Col>

                        <Col span = {3}>
                        <Button type="primary" loading={loading} ghost style = {{width : "100%"}}>
                            <React.Fragment>
                            {loading ? (
                                <React.Fragment></React.Fragment>
                            ) : (
                                <React.Fragment>Results: {totalResults}</React.Fragment>
                                )}
                            </React.Fragment>
                        </Button>
                        </Col>
                    </Row>
                </Col>


                <Col style = {{overflow : "scroll" , maxHeight : "80vh"}}>

                    {
                        loading ? 
                        <div className="spinner-wrapper">
                        <Spin size="large" />
                      </div> :

                    <Row gutter = {[16,16]}>
                       
                        {
                            orders.map((order , index) => {
                                return(
                                    <Col sm={24}
                                    md={12}
                                    lg={8}
                                    xl={8}
                                    className="collapse-container-warehouse-manager"
                                    onClick = {() => {console.log("clicked");setopenModal({...openModal , [index] : !openModal[index]})}}
                                    >
          
                                    <Collapse key = {index}>
                                        <Panel
                                        showArrow={false}
                                        extra={collapseHeader(order , () => {setShowModal(true);setOrderUuid(order.uuid)} , () => {
                                          setShowModalPDF(true);setOrderUuid(order.uuid)
                                        })}
                                        style = {openModal[index] ? {border : "1px solid #1ABC9C"} : {border : "1px solid #495561"}}
                                        >
                                        {order.unitOrderList.map(
                                            (unitOrder, unitOrderIndex) => {
                                            return (
                                                <div style = {{padding : "2px 20px 0px 20px"}}>
                                                <Divider style={{ margin: "0px 0px 10px 0px" }} />
                                                <OrdersWarehouseManagerMobileViewRow
                                                key={unitOrderIndex}
                                                bundleId={order.bundleId}
                                                bundleUuid={order.uuid}
                                                toggleOff
                                                // toggleOrderCheck={
                                                //     this.props.toggleOrderCheck
                                                // }
                                                order={unitOrder}
                                                />
                                                </div>
                                            );
                                            }
                                        )}
                                        <Row></Row>
                                        </Panel>
                                    </Collapse>
                                    </Col>
                                    )
                            })
                        } 
                    {
                        isAvailable && 
                        <Col span = {24}>
                            <Row type = "flex" justify = "center" align = "middle">
                                <Button
                                type="primary"
                                loading={fetchingOrdersList}
                                disabled={fetchingOrdersList}
                                onClick={loadMoreOrders}
                                >
                                    {fetchingOrdersList ? "" : "Load More..."}
                                </Button>
                            </Row>
                        </Col>
                    }
                    </Row>

                     }

                </Col>


            </Row>

                     <Modal
                        visible={showModal}
                        onClose = {() => setShowModal(false)}
                        onCancel = {() => setShowModal(false)}
                        footer = {null}
                        destroyOnClose = {true}
                      > 
                        <DetailsOrderTracking orderUuid = {orderUuid} />
                    </Modal>


                     {/* PDF MODAL */}

                     <Modal
                        title="Download"
                        visible={showModalPDF}
                        onClose = {() => setShowModalPDF(false)}
                        onCancel = {() => setShowModalPDF(false)}
                        // footer = {null}
                        onOk={exportPDFWithMethod}
                        destroyOnClose = {true}
                        className="order-invoice-modal-wrapper"
                      > 
                        <PostaPlusPDF orderUuid = {orderUuid} set_element = {(e,awb_number) => {_element = e ; _awb_number = awb_number}} />
                    </Modal>

        </React.Fragment>
    )
}

class PostaPlusTracking extends Component{
    constructor(props){
        super(props);
    }



    render(){
        return (
            <React.Fragment>
                 <RightWrapper
                    innerwrapperTransparent
                    InnerWrapper={
                        <React.Fragment>
                            <InnerWrapper  {...this.props} />
                        </React.Fragment>
                    }
            />
            </React.Fragment>
        );
    }
}

export default PostaPlusTracking