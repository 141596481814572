import React from 'react';
import { Row, Col, Button, message, Empty, Collapse } from 'antd';
import OrdersAccountManagerTableBodyTemplate from './OrdersAccountManagerTableBodyTemplate';
import OrdersAccountManagerHeaderTemplate from './OrdersAccountManagerHeaderTemplate';
import OrdersAccountManagerTableHeaderTemplate from './OrdersAccountManagerTableHeaderTemplate';
import CollapsibleHeaderContent from './CollapsibleHeaderContent';
import { Ellipsis } from 'react-spinners-css';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    setOrdersManagerDetails,
    loadMoreOrdersManagerDetails,
    setShippingMethod,
    checkOrderUuid,
    uncheckOrderUuid,
    unsetSelectedOrders,
    cancelOrder,
    setOrderStatus,
    setOrderStatusBulk,
} from '../../actions/ordersManager';
import styled from 'styled-components';
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import OrdersAccountManagerMobileView from './OrdersAccountManagerMobileView';
import axios from 'axios';
import isDev from '../../server';
import AssignOrderModal from './assign-order-modal/AssignOrderModal';
import { openRefreshPageNotification, closeRefreshPageNotification } from '../../actions/refreshNotificationAction'

const serverOmnycommUrl = isDev();
const orderStatues = ['pending', 'approved', 'cancelled', 'delivery failed'];
const orderType = ['WIG Fleet', 'grand gaadi'];

const { Panel } = Collapse;
const TableRowCollapse = styled(Collapse)`
  width: 100%;
  border: none;
  margin-bottom: 10px;
`;
const BaseProductContent = styled(Row)`
  padding: 12px;
  width: 100%;
  border-top: 1px solid #495561;
`;
const BaseProductContent1 = styled(Row)`
  padding: 12px;
  width: 100%;
  background-color: #313841;
`;
const ProductVariantContainer = styled(Row)`
  width: 100%;
`;

const ProductVariantRow = styled(Row)`
  width: 100%;
  padding: 5px 2px;
`;

class OrdersAccountManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchStringList: [],
            orderStatusFilter: orderStatues[0],
            fetchingOrdersList: true,
            areOrdersAvailable: false,
            // orderList: [],
            totalResults: 0,
            accountsOrderListIndex: 1,
            someProcessingActive: false,
            filterActive: false,
            filterObj: {
                fromDate: '',
                toDate: '',
                paymentTypeList: '',
                minQty: '',
                maxQty: '',
                minPrice: '',
                maxPrice: '',
                currencyList: '',
                shippingMethodList: '',
                trackingStatusList: '',
            },
            toggleShowlModal: false,
            cancellationReason: '',
            searchActive: false,
            filterCount: 0,
            website_group_name: '',
            assignModalOpen: false,
            AssignModalOrder: {},
            filterDataAvgOrderValue: 0,
            filterDataDelivered: 0,
            filterDataTotalOrders: 0,
            filterDataPendingOrders: 0,
            filterDataTotalSales: 0,
            order_analytics: undefined,
        };
    }

    setOrdersListing = () => {
        if (this._isMounted) {
            this.setState({
                fetchingOrdersList: true,
                someProcessingActive: true,
            });

            //Order Analytics API call
            axios
                .post(
                    `${serverOmnycommUrl}/dealshub/fetch-filtered-order-analytics/`,
                    {
                        page: 1,
                        website_group_name: this.state.website_group_name,
                        locationGroupUuid: this.props.locationUuid,

                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((response) => {
                    this.setState({
                        filterDataAvgOrderValue:
                            response.data.order_analytics?.avg_order_value,
                        filterDataDelivered: response.data.order_analytics?.delivered_orders,
                        filterDataTotalOrders: response.data.order_analytics?.orders,
                        filterDataPendingOrders:
                            response.data.order_analytics?.pending_orders,
                        filterDataTotalSales: response.data.order_analytics?.sales,
                        order_analytics: response.data.order_analytics
                    })
                }).catch((err) => console.log('Error in Fetching Filtered Order Analytics'))

            axios
                .post(
                    `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
                    {
                        // "filterStatus": this.state.orderStatusFilter,
                        page: 1,
                        website_group_name: this.state.website_group_name,
                        locationGroupUuid: this.props.locationUuid,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data, 'orders account manager page');
                    this.props.setOrdersManagerDetails(response.data.orderList);
                    this.setState({
                        areOrdersAvailable: response.data.isAvailable,
                        // orderList: [...this.state.orderList, ...response.data.orderList],
                        fetchingOrdersList: false,
                        someProcessingActive: false,
                        accountsOrderListIndex: 2,
                        totalResults: this.props.ordersManagerDetails.fetchedOrders.length,
                        totalRevenue: response.data.totalRevenue,
                        currency: response.data.currency,
                        totalOrders: response.data.totalOrders,
                        pendingOrderRequestCount: response.data.pendingOrderRequestCount,

                    });
                });
            // .catch(error => {
            //   message(`Something's went wrong!\nTry refreshing the page!`);
            // });
        }
    };

    fetchInitialData = () => {
        axios
            .post(
                `${serverOmnycommUrl}/fetch-company-profile/`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status == 200)
                    this.setState(
                        {
                            website_group_name: response.data.company_data.name,
                        },
                        () => {
                            this._isMounted = true;
                            if (this._isMounted) {
                                this.setOrdersListing();
                            }
                        }
                    );
            });
    };

    componentDidMount = () => {
        console.log(this.props, 'orders account manager props');
        this.fetchInitialData();
        this.setState({
            locationUuid: this.props.locationUuid,
        });

    };

    componentDidUpdate = (prevState, prevProps) => {
        if (prevState.locationUuid != this.state.locationUuid) {
            this.fetchInitialData();
            this.setState({
                searchStringList: [],
                filterObj: {
                    fromDate: '',
                    toDate: '',
                    paymentTypeList: [],
                    minQty: 0,
                    maxQty: 10000,
                    minPrice: 0,
                    maxPrice: 1000000,
                    currencyList: '',
                    shippingMethodList: [],
                    trackingStatusList: [],
                    salesPersonList: [],
                },
            })
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.locationUuid != prevState.locationUuid) {
            return {
                locationUuid: nextProps.locationUuid,
            };
        }

        return null;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
        this.props.unsetSelectedOrders();
    };

    getCheckedOrders = (bundleId) => {
        let allOrders = this.props.ordersManagerDetails.fetchedOrders;

        for (
            let bundleOrderIndex = 0;
            bundleOrderIndex < allOrders.length;
            ++bundleOrderIndex
        ) {
            if (allOrders[bundleOrderIndex].bundleId === bundleId) {
                let unitOrderList = allOrders[bundleOrderIndex].unitOrderList;
                let tempCheckedOrders = [];
                for (
                    let unitOrderIndex = 0;
                    unitOrderIndex < unitOrderList.length;
                    ++unitOrderIndex
                ) {
                    if (
                        unitOrderList[unitOrderIndex].checked === true &&
                        unitOrderList[unitOrderIndex].currentStatus === 'pending'
                    ) {
                        tempCheckedOrders.push(unitOrderList[unitOrderIndex]['uuid']);
                    }
                }
                return tempCheckedOrders;
            }
        }
    };

    getCheckedOrdersForCancellation = (bundleId) => {
        let allOrders = this.props.ordersManagerDetails.fetchedOrders;
        console.log(allOrders);
        for (
            let bundleOrderIndex = 0;
            bundleOrderIndex < allOrders.length;
            ++bundleOrderIndex
        ) {
            if (allOrders[bundleOrderIndex].bundleId === bundleId) {
                let unitOrderList = allOrders[bundleOrderIndex].unitOrderList;
                console.log(unitOrderList);
                let tempCheckedOrders = [];
                for (
                    let unitOrderIndex = 0;
                    unitOrderIndex < unitOrderList.length;
                    ++unitOrderIndex
                ) {
                    if (unitOrderList[unitOrderIndex].checked === true) {
                        // if (unitOrderList[unitOrderIndex].currentStatus === 'pending') {
                        //   tempCheckedOrders.push(unitOrderList[unitOrderIndex]['uuid']);
                        // } else {
                        //   return [];
                        // }
                        tempCheckedOrders.push(unitOrderList[unitOrderIndex]['uuid']);
                    }
                }
                return tempCheckedOrders;
            }
        }
    };

    updateManualOrderToSuccess = (uuid) => {
        let ordersManagerDetails_fetchOrders_COPY = this.props.ordersManagerDetails
            .fetchedOrders;
        for (let i = 0; i < ordersManagerDetails_fetchOrders_COPY.length; i++) {
            if (ordersManagerDetails_fetchOrders_COPY[i].uuid == uuid) {
                ordersManagerDetails_fetchOrders_COPY[i].sapStatus = 'Success';
                break;
            }
        }

        this.props.setOrdersManagerDetails([
            ...ordersManagerDetails_fetchOrders_COPY,
        ]);
    };

    setOrderStatusBulk = (
        orderTypeString,
        orderList,
        deliveryFailedReason = ''
    ) => {
        if (this._isMounted) {
            const orders = orderList.map((item) => item.uuid);
            axios({
                method: 'POST',
                url: `${serverOmnycommUrl}/dealshub/set-orders-status-bulk/`,
                data: {
                    orderStatus: orderTypeString,
                    orderUuidList: orders,
                    deliveryFailedReason,
                },
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                if (response.data.status === 200) {
                    this.props.setOrderStatusBulk(
                        orderTypeString,
                        this.props.ordersManagerDetails,
                        orders
                    );
                    message.success('Changes applied successfully!');
                } else {
                    message.error('Something went wrong! Try refreshing the page!');
                }
            });
        }
    };

    setOrderStatus = (orderTypeString, order, deliveryFailedReason = '') => {
        if (this._isMounted) {
            if (order.unitOrderList.length > 0) {
                const unitOrders = order.unitOrderList.map((item) => item.uuid);
                axios
                    .post(
                        `${serverOmnycommUrl}/dealshub/set-orders-status/`,
                        {
                            orderStatus: orderTypeString,
                            unitOrderUuidList: unitOrders,
                            deliveryFailedReason,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `JWT ${localStorage.getItem('token')}`,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data.status === 200) {
                            this.props.setOrderStatus(
                                orderTypeString,
                                this.props.ordersManagerDetails,
                                unitOrders
                            );
                            message.success('Changes applied successfully!');
                        } else {
                            message.error('Something went wrong! Try refreshing the page!');
                        }
                    });
            } else {
                message.error('No orders Selected!');
            }
        }
    };

    setShippingMethod = (orderTypeString, bundleId, order, isSapManualUpdate, weight) => {
        if (this._isMounted) {
            let selectedOrders = this.getCheckedOrders(bundleId);
            console.log(bundleId);
            if (selectedOrders.length > 0) {
                this.setState({ someProcessingActive: true });
                this.props.unsetSelectedOrders();

                axios
                    .post(
                        `${serverOmnycommUrl}/dealshub/set-shipping-method/`,
                        {
                            shippingMethod: orderTypeString,
                            unitOrderUuidList: selectedOrders,
                            isSapManualUpdate: (!isSapManualUpdate) ? false : isSapManualUpdate,
                            weight: weight.toString()
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `JWT ${localStorage.getItem('token')}`,
                            },
                        }
                    )
                    .then((response) => {
                        this.setState({
                            someProcessingActive: false,
                            selectedOrders,
                        });
                        if (response.data.status === 200) {
                            this.props.openRefreshPageNotification()
                            if (response.data.modal_info_list) {
                                this.setState({
                                    assignModalOpen: true,
                                    AssignModalOrder: order,
                                    modal_info_list: response.data.modal_info_list,
                                    shippingMethod: orderTypeString,
                                    unitOrderUuidList: selectedOrders,
                                    orderTypeString,
                                    ordersManagerDetails: this.props.ordersManagerDetails,
                                });
                            } else if (this.props.b2b === 'b2b' && response.data.status === 406) {
                                message.info("Set TRN number.")
                            } else {
                                console.log(this.props.ordersManagerDetails);
                                message.success('Item(s) build from ATP!');
                                this.props.setShippingMethod(
                                    orderTypeString,
                                    this.props.ordersManagerDetails,
                                    selectedOrders
                                );
                            }
                        } else if (response.data.status === 403 && response.data.message) {
                            this.props.closeRefreshPageNotification()
                            message.error(response.data.message);
                        } else {
                            message.error('Something went wrong!\nTry refreshing the page!');
                        }
                    });
            } else {
                message.warning('Check the orders Selected!');
            }
        }
    };

    setSapWarningFalse = (sapIntercomInfo, uuid) => {
        let ordersManagerDetails_fetchOrders_COPY = this.props.ordersManagerDetails
            .fetchedOrders;
        for (let i = 0; i < ordersManagerDetails_fetchOrders_COPY.length; i++) {
            if (ordersManagerDetails_fetchOrders_COPY[i].uuid == uuid) {
                ordersManagerDetails_fetchOrders_COPY[i].showSapWarning = false;
                break;
            }
        }

        this.props.setOrdersManagerDetails([
            ...ordersManagerDetails_fetchOrders_COPY,
        ]);
    };

    resendSapOrder = (order, callback = null) => {
        axios
            .post(
                `${serverOmnycommUrl}/dealshub/resend-sap-order/`,
                {
                    // "filterStatus": this.state.orderStatusFilter,
                    orderUuid: order.uuid,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status === 200) {
                    // this.setState({
                    // 	salesPersonList : response.data.salesPersonList
                    // })
                    if (callback) callback();
                    if (response.data.modal_info_list) {
                        this.setState({
                            assignModalOpen: true,
                            AssignModalOrder: order,
                            modal_info_list: response.data.modal_info_list,
                            resend: true,
                        });
                    } else {
                        this.setSapWarningFalse(response.data, order.uuid);
                        // console.log(this.props.ordersManagerDetails);
                        // message.success('Item(s) build from ATP!');
                        // this.props.setShippingMethod(
                        //   orderTypeString,
                        //   this.props.ordersManagerDetails,
                        //   selectedOrders
                        // );
                    }
                } else if (response.data.status === 403) {
                    message.error(response.data.message);
                } else {
                    message.error('Something went wrong in resend sap order');
                }
            });
    };

    loadMoreOrders = () => {
        console.log("IN");
        if (this._isMounted) {
            this.setState({
                fetchingOrdersList: true,
                someProcessingActive: true,
            });

            console.log(this.state.filterObj, 'hdhdhdhd');

            let requestObj = this.state.filterActive
                ? {
                    fromDate: this.state.filterObj.fromDate
                        ? moment(this.state.filterObj.fromDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                        : '',
                    toDate: this.state.filterObj.toDate
                        ? moment(this.state.filterObj.toDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                        : '',
                    paymentTypeList: this.state.filterObj.paymentTypeList,
                    minQty: this.state.filterObj.minQty,
                    maxQty: this.state.filterObj.maxQty,
                    minPrice: this.state.filterObj.minPrice,
                    maxPrice: this.state.filterObj.maxPrice,
                    currencyList: this.state.filterObj.currencyList,
                    shippingMethodList: this.state.filterObj.shippingMethodList,
                    trackingStatusList: this.state.filterObj.trackingStatusList,
                    page: this.state.accountsOrderListIndex,
                    locationGroupUuid: this.props.locationUuid,
                    website_group_name: this.state.website_group_name,
                    salesPerson: this.state.filterObj.salesPerson,
                    isOrderOffline: this.state.filterObj.isOrderOffline,
                    sapStatusList: this.state.filterObj.sapStatusList,
                    searchList: this.state.searchStringList,
                    // salesPerson: this.state.filterObj.salesPerson,
                }
                : {
                    page: this.state.accountsOrderListIndex,
                    locationGroupUuid: this.props.locationUuid,
                    searchList: this.state.searchStringList,
                    website_group_name: this.state.website_group_name,
                };

            //Order Analytics API call
            axios
                .post(
                    `${serverOmnycommUrl}/dealshub/fetch-filtered-order-analytics/`,
                    requestObj,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((response) => {
                    this.setState({
                        filterDataAvgOrderValue:
                            response.data.order_analytics?.avg_order_value,
                        filterDataDelivered: response.data.order_analytics?.delivered_orders,
                        filterDataTotalOrders: response.data.order_analytics?.orders,
                        filterDataPendingOrders:
                            response.data.order_analytics?.pending_orders,
                        filterDataTotalSales: response.data.order_analytics?.sales,
                        order_analytics: response.data.order_analytics
                    })
                }).catch((err) => console.log('Error in Fetching Filtered Order Analytics'))
            axios
                .post(
                    `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
                    requestObj,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((response) => {
                    this.props.loadMoreOrdersManagerDetails(response.data.orderList);
                    this.setState({
                        areOrdersAvailable: response.data.isAvailable,
                        // orderList: [...this.state.orderList, ...response.data.orderList],
                        fetchingOrdersList: false,
                        someProcessingActive: false,
                        accountsOrderListIndex: this.state.accountsOrderListIndex + 1,
                        totalResults: this.props.ordersManagerDetails.fetchedOrders.length,
                        totalRevenue: response.data.totalRevenue,
                        currency: response.data.currency,
                        totalOrders: response.data.totalOrders,
                        pendingOrderRequestCount: response.data.pendingOrderRequestCount,
                    });
                });
        }
    };

    toggleModalShow = (bundleId) => {
        if (this._isMounted) {
            let selectedOrders = this.getCheckedOrdersForCancellation(bundleId);
            if (selectedOrders.length > 0) {
                this.setState({ toggleShowlModal: !this.state.toggleShowlModal });
            } else {
                message.warning('Only pending products can be cancelled');
            }
        }
    };

    handleCancelModalSubmit = (bundleId) => {
        if (this.state.cancellationReason.length < 5) {
            message.warning('Invalid Length');
        } else {
            let selectedOrders = this.getCheckedOrdersForCancellation(bundleId);
            axios
                .post(
                    `${serverOmnycommUrl}/dealshub/cancel-orders/`,
                    {
                        cancellingNote: this.state.cancellationReason,
                        unitOrderUuidList: selectedOrders,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.status === 200) {
                        let ordersManagerDetails_fetchOrders_COPY = this.props
                            .ordersManagerDetails.fetchedOrders;
                        console.log(this.props.ordersManagerDetails);
                        for (
                            let i = 0;
                            i < ordersManagerDetails_fetchOrders_COPY.length;
                            i++
                        ) {
                            if (
                                ordersManagerDetails_fetchOrders_COPY[i].bundleId == bundleId
                            ) {
                                ordersManagerDetails_fetchOrders_COPY[i].cancelStatus = true;
                                break;
                            }
                        }
                        this.props.setOrdersManagerDetails([
                            ...ordersManagerDetails_fetchOrders_COPY,
                        ]);
                        this.props.cancelOrder(
                            this.props.ordersManagerDetails,
                            bundleId,
                            selectedOrders
                        );
                        message.success('Orders successfully cancelled!');
                        this.setState({
                            someProcessingActive: false,
                            toggleShowlModal: !this.state.toggleShowlModal,
                        });
                    } else {
                        message.error('Something went wrong!\nTry refreshing the page!');
                    }
                });
        }
    };

    handleCancelModalClose = (bundleId) => {
        this.setState({
            cancellationReason: '',
            toggleShowlModal: !this.state.toggleShowlModal,
        });
    };

    handleCancelTextChange = (event) => {
        this.setState({ cancellationReason: event.target.value });
    };

    toggleOrderCheck = (orderChecked, orderUuid, bundleId) => {
        !orderChecked
            ? this.props.checkOrderUuid(
                this.props.ordersManagerDetails.fetchedOrders,
                orderUuid,
                bundleId
            )
            : this.props.uncheckOrderUuid(
                this.props.ordersManagerDetails.fetchedOrders,
                orderUuid,
                bundleId
            );
    };

    // handleStartDateChange = (date) => {
    //     this.setState({
    //         filterObj: {...this.state.filterObj, fromDate: date}
    //     });
    // };
    //
    // handleEndDateChange = (date) => {
    // 	this.setState({
    // 		filterObj: {...this.state.filterObj, toDate: date}
    // 	});
    // };

    applyFilters = (filterObj) => {
        if (this._isMounted) {
            this.props.unsetSelectedOrders();
            this.setState(
                {
                    fetchingOrdersList: true,
                    someProcessingActive: true,
                    accountsOrderListIndex: 1,
                    filterActive: true,
                    filterObj: filterObj,
                },
                () => {
                    let temp = 0;
                    console.log(filterObj, 'FILTER APPLIED');
                    for (let filterKey in filterObj) {
                        if (
                            Array.isArray(filterObj[filterKey]) &&
                            filterObj[filterKey].length > 0
                        ) {
                            ++temp;
                        } else if (
                            filterObj[filterKey] &&
                            filterObj[filterKey].toString().length > 0
                        ) {
                            ++temp;
                        }
                    }
                    this.setState({ filterCount: temp });
                    //Order Analytics API call
                    axios
                        .post(
                            `${serverOmnycommUrl}/dealshub/fetch-filtered-order-analytics/`,
                            {
                                // "filterStatus": this.state.orderStatusFilter,
                                fromDate: filterObj.fromDate
                                    ? moment(filterObj.fromDate).format('YYYY-MM-DD HH:mm:ss')
                                    : '',
                                toDate: filterObj.toDate
                                    ? moment(filterObj.toDate).format('YYYY-MM-DD HH:mm:ss')
                                    : '',
                                paymentTypeList: filterObj.paymentTypeList,
                                minQty: filterObj.minQty.toString(),
                                maxQty: filterObj.maxQty.toString(),
                                minPrice: filterObj.minPrice.toString(),
                                maxPrice: filterObj.maxPrice.toString(),
                                currencyList: filterObj.currencyList,
                                shippingMethodList: filterObj.shippingMethodList,
                                trackingStatusList: filterObj.trackingStatusList,
                                page: this.state.accountsOrderListIndex,
                                website_group_name: this.state.website_group_name,
                                sapStatusList: filterObj.sapStatusList,
                                isOrderOffline: filterObj.isOrderOffline,
                                locationGroupUuid: this.props.locationUuid,
                                salesPerson: filterObj.salesPerson,
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `JWT ${localStorage.getItem('token')}`,
                                },
                            }
                        )
                        .then((response) => {
                            this.setState({
                                filterDataAvgOrderValue:
                                    response.data.order_analytics?.avg_order_value,
                                filterDataDelivered: response.data.order_analytics?.delivered_orders,
                                filterDataTotalOrders: response.data.order_analytics?.orders,
                                filterDataPendingOrders:
                                    response.data.order_analytics?.pending_orders,
                                filterDataTotalSales: response.data.order_analytics?.sales,
                                order_analytics: response.data.order_analytics
                            })
                        }).catch((err) => console.log('Error in Fetching Filtered Order Analytics'))

                    axios
                        .post(
                            `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
                            {
                                // "filterStatus": this.state.orderStatusFilter,
                                fromDate: filterObj.fromDate
                                    ? moment(filterObj.fromDate).format('YYYY-MM-DD HH:mm:ss')
                                    : '',
                                toDate: filterObj.toDate
                                    ? moment(filterObj.toDate).format('YYYY-MM-DD HH:mm:ss')
                                    : '',
                                paymentTypeList: filterObj.paymentTypeList,
                                minQty: filterObj.minQty.toString(),
                                maxQty: filterObj.maxQty.toString(),
                                minPrice: filterObj.minPrice.toString(),
                                maxPrice: filterObj.maxPrice.toString(),
                                currencyList: filterObj.currencyList,
                                shippingMethodList: filterObj.shippingMethodList,
                                trackingStatusList: filterObj.trackingStatusList,
                                page: this.state.accountsOrderListIndex,
                                website_group_name: this.state.website_group_name,
                                sapStatusList: filterObj.sapStatusList,
                                isOrderOffline: filterObj.isOrderOffline,
                                locationGroupUuid: this.props.locationUuid,
                                salesPerson: filterObj.salesPerson,
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `JWT ${localStorage.getItem('token')}`,
                                },
                            }
                        )
                        .then((response) => {
                            this.props.setOrdersManagerDetails(response.data.orderList);
                            this.setState({
                                areOrdersAvailable: response.data.isAvailable,
                                // orderList: [...this.state.orderList, ...response.data.orderList],
                                fetchingOrdersList: false,
                                someProcessingActive: false,
                                accountsOrderListIndex: this.state.accountsOrderListIndex + 1,
                                totalResults: this.props.ordersManagerDetails.fetchedOrders
                                    .length,
                                totalRevenue: response.data.totalRevenue,
                                currency: response.data.currency,
                                totalOrders: response.data.totalOrders,
                                pendingOrderRequestCount: response.data.pendingOrderRequestCount,

                            });
                        });
                }
            );
        }
    };

    handleSearchChange = (searchStringsList) => {
        this.setState({
            searchStringList: searchStringsList
        }, () => {
            if (this._isMounted) {
                if (this.state.searchStringList.length > 0) {
                    this.props.unsetSelectedOrders();
                    this.setState(
                        {
                            fetchingOrdersList: true,
                            someProcessingActive: true,
                            accountsOrderListIndex: 1,
                            searchActive: true,
                        },
                        () => {

                            //Order Analytics API call
                            axios
                                .post(
                                    `${serverOmnycommUrl}/dealshub/fetch-filtered-order-analytics/`,
                                    {
                                        searchList: this.state.searchStringList,
                                        page: this.state.accountsOrderListIndex,
                                        website_group_name: this.state.website_group_name,
                                        locationGroupUuid: this.props.locationUuid,
                                    },
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: `JWT ${localStorage.getItem('token')}`,
                                        },
                                    }
                                )
                                .then((response) => {
                                    this.setState({
                                        filterDataAvgOrderValue:
                                            response.data?.order_analytics?.avg_order_value,
                                        filterDataDelivered:
                                            response.data?.order_analytics?.delivered_orders,
                                        filterDataTotalOrders: response.data?.order_analytics?.orders,
                                        filterDataPendingOrders:
                                            response.data.order_analytics?.pending_orders,
                                        filterDataTotalSales: response.data?.order_analytics?.sales,
                                        order_analytics: response.data?.order_analytics
                                    })
                                }).catch((err) => console.log('Something went wrong while fetching data'))

                            //Orders List API Call
                            axios
                                .post(
                                    `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
                                    {
                                        searchList: this.state.searchStringList,
                                        page: this.state.accountsOrderListIndex,
                                        website_group_name: this.state.website_group_name,
                                        locationGroupUuid: this.props.locationUuid,
                                    },
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: `JWT ${localStorage.getItem('token')}`,
                                        },
                                    }
                                )
                                .then((response) => {
                                    this.props.setOrdersManagerDetails(response.data.orderList);
                                    this.setState({
                                        areOrdersAvailable: response.data.isAvailable,
                                        // orderList: [...this.state.orderList, ...response.data.orderList],
                                        fetchingOrdersList: false,
                                        someProcessingActive: false,
                                        accountsOrderListIndex: this.state.accountsOrderListIndex + 1,
                                        totalResults: this.props.ordersManagerDetails.fetchedOrders
                                            .length,
                                        totalRevenue: response.data.totalRevenue,
                                        currency: response.data.currency,
                                        totalOrders: response.data.totalOrders,
                                        pendingOrderRequestCount: response.data?.pendingOrderRequestCount,

                                    });
                                });
                        }
                    );
                } else {
                    this.setOrdersListing();
                }
            }
        })
    };

    getCollapsibleHeaderData = (order) => {
        let mainImages = [];
        order.unitOrderList.map((unitOrder) => {
            mainImages.push(unitOrder.productImageUrl);
        });
        return {
            dateCreated: order.dateCreated,
            mainImages,
            customerName: order.customerName,
            paymentStatus: order.paymentStatus,
        };
    };

    importreport = () => {
    };

    downloadReport = (reportType) => {
        let filterObj = this.state.filterObj;
        message.success('Generating report will take a while.');
        message.success('Please be patient and do not close the modal.');
        axios
            .post(
                `${serverOmnycommUrl}/dealshub/download-orders/`,
                {
                    // "filterStatus": this.state.orderStatusFilter,
                    fromDate: filterObj.fromDate,
                    toDate: filterObj.toDate,
                    paymentTypeList: filterObj.paymentTypeList,
                    minQty: filterObj.minQty.toString(),
                    maxQty: filterObj.maxQty.toString(),
                    minPrice: filterObj.minPrice.toString(),
                    maxPrice: filterObj.maxPrice.toString(),
                    currencyList: filterObj.currencyList,
                    shippingMethodList: filterObj.shippingMethodList,
                    trackingStatusList: filterObj.trackingStatusList,
                    reportType: reportType,
                    website_group_name: this.state.website_group_name,
                    locationGroupUuid: this.props.locationUuid,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status === 200) {
                    message.success('Report generated Successfully!');
                    window.open(response.data.filepath, '_target');
                } else {
                    message.error('Something went wrong!');
                }
            });
    };

    render() {
        return (
            <React.Fragment>
                <OrdersAccountManagerMobileView
                    isB2b={this.props.b2b === 'b2b'}
                    importreport={this.importreport}
                    downloadReport={this.downloadReport}
                    totalResults={this.state.totalResults}
                    loading={this.state.fetchingOrdersList}
                    toggleShowlModal={this.state.toggleShowlModal}
                    handleCancelModalSubmit={this.handleCancelModalSubmit}
                    handleCancelModalClose={this.handleCancelModalClose}
                    handleCancelTextChange={this.handleCancelTextChange}
                    toggleModalShow={this.toggleModalShow}
                    cancellationReason={this.state.cancellationReason}
                    setShippingMethod={this.setShippingMethod}
                    setOrderStatusBulk={this.setOrderStatusBulk}
                    setOrderStatus={this.setOrderStatus}
                    someProcessingActive={this.state.someProcessingActive}
                    applyFilters={this.applyFilters}
                    filterObj={this.state.filterObj}
                    handleSearchChange={this.handleSearchChange}
                    searchStringList={this.state.searchStringList}
                    filterCount={this.state.filterCount}
                    accountsOrderListIndex={this.state.accountsOrderListIndex}
                    fetchingOrdersList={this.state.fetchingOrdersList}
                    loadMoreOrders={this.loadMoreOrders}
                    ordersManagerDetails={this.props.ordersManagerDetails}
                    areOrdersAvailable={this.state.areOrdersAvailable}
                    updateManualOrderToSuccess={this.updateManualOrderToSuccess}
                    toggleOrderCheck={this.toggleOrderCheck}
                    locationGroupUuid={this.props.locationUuid}
                    setOrdersListing={this.setOrdersListing}
                    resendSapOrder={this.resendSapOrder}
                    totalRevenue={this.state.totalRevenue}
                    currency={this.state.currency}
                    totalOrders={this.state.totalOrders}
                    pendingOrderRequestCount={this.state.pendingOrderRequestCount}
                    filterDataAvgOrderValue={this.state.filterDataAvgOrderValue}
                    filterDataDelivered={this.state.filterDataDelivered}
                    filterDataTotalOrders={this.state.filterDataTotalOrders}
                    filterDataPendingOrders={this.state.filterDataPendingOrders}
                    filterDataTotalSales={this.state.filterDataTotalSales}
                    orderAnalyticsAccess={this.props.orderAnalyticsAccess}
                    salesAnalyticsAccess={this.props.salesAnalyticsAccess}
                    order_analytics={this.state.order_analytics}
                //locationUuid={{this.props.locationUu}}
                />
                <AssignOrderModal
                    visible={this.state.assignModalOpen}
                    closeThis={() => {
                        this.setState({
                            assignModalOpen: false,
                        });
                    }}
                    AssignModalOrder={this.state.AssignModalOrder}
                    modal_info_list={this.state.modal_info_list}
                    shippingMethod={this.state.shippingMethod}
                    unitOrderUuidList={this.state.unitOrderUuidList}
                    orderTypeString={this.state.orderTypeString}
                    ordersManagerDetails={this.state.ordersManagerDetails}
                    resend={this.state.resend}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ ordersManagerDetails }) => {
    return {
        ordersManagerDetails,
    };
};

export default connect(mapStateToProps, {
    setOrdersManagerDetails,
    loadMoreOrdersManagerDetails,
    setShippingMethod,
    checkOrderUuid,
    uncheckOrderUuid,
    unsetSelectedOrders,
    cancelOrder,
    setOrderStatus,
    setOrderStatusBulk,
    openRefreshPageNotification,
    closeRefreshPageNotification
})(OrdersAccountManager);
