import React, { Component,useState,useEffect } from 'react';
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {
    Row,
    Col,
    Typography,
    Button,
    Checkbox,
    Avatar,
    Icon,
    Tooltip,
    Tag,
    message,
    Modal,
    Select,
    Card,
    Upload
  } from "antd";
import "./Categoryimages.scss";
import { Ellipsis } from "react-spinners-css";
import axios from "axios";
import isDev from "server";

const serverOmnycommUrl = isDev();

const {Option} = Select;

// import isDev from "../../../../server";
// const serverUrl = isDev();


const ModalContent = (props) =>{
  // console.log(props.itemUploadFor);
  console.log(props);
  return(
    <React.Fragment>
               <Row gutter={[24,24]}  className="modalmaindetailedimage">
                 <p>Main image</p>  
                 <Col span={24}>
                  <Col span={6}>
                     <Avatar
                        className="categoryimage-avatar"
                        shape="square"
                        style={{ width: "180px", height: "180px" }}
                        src={props.itemUploadFor.image_url}
                      />
                    </Col>
                  
                  
                  <Col span={18}> 
                      <Button
                      disabled= {props.upload_loading}
                      icon = {props.upload_loading ? "loading" : "upload"}
                     size = {"small"} type = "primary" ghost style = {{
                        position : "absolute",
                        top:"168px",
                        right:"16px"
                      }}
                       onPreview={props.handlePreview}
                      >
                        <input onChange = {(e) => props.handleImageUpload(e , props.itemUploadFor.uuid)} type="file" style = {{
                        position : "absolute",
                        top : "0px",
                        left : "0px",
                        opacity : "0",
                        width : "100%",
                        cursor : "pointer"
                      }} />
                      </Button>
                   </Col>



                   </Col>
                  </Row> 
                  <Row gutter={[24,24]}>
                  <p>Detailed image</p>
                    <Col span={24}> 
                    <Col span={6}>
                    <Avatar
                        className="categoryimage-avatar"
                        shape="square"
                        style={{ width: "180px", height: "180px" }}
                        src={props.itemUploadFor.image_url_detailed}
                    
                      />
                    </Col>
                    <Col span={18} >  
                      <Button
                         disabled= {props.upload_loading}
                         icon = {props.upload_loading ? "loading" : "upload"}
                         size = {"small"} type = "primary" ghost style = {{
                        position : "absolute",
                        top:"168px",
                        right:"16px"
                      }}
                      onPreview={props.handlePreview}
                      >
                        <input onChange = {(e) => props.handleImageUpload(e ,props.itemUploadFor.uuid , true)} type="file" style = {{
                        position : "absolute",
                        top : "0px",
                        left : "0px",
                        opacity : "0",
                        width : "100%",
                        cursor : "pointer"
                      }} />
                      </Button>
                      </Col>
                  </Col>
                  
                  </Row>  
                  </React.Fragment>  

  );
};

export default class Categoryimage extends Component {
    state={
        category_list: null,
        is_available : false,
        page : 1,
        loadmoreLoading : false,
        upload_loading: false,
        filterOpen : false,
        tags: [],
        filters : {
          has_image : null
        },
        total_categories: null
      }

      componentDidMount = () => {
        axios
          .post(
            `${serverOmnycommUrl}/sales-app/fetch-categories-for-sales/`,
            {
              page: 1,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.setState({
                is_available: response.data.is_available,
                category_list: response.data.category_list,
                total_categories: response.data.total_category
              });
            } else message.error("Something went wrong!");
          });
      }

      applyFilter = () => {

        this.setState({
          category_list : null
        })

        axios
        .post(
          `${serverOmnycommUrl}/sales-app/fetch-categories-for-sales/`,
          {
            page : 1,
            has_image : this.state.filters.has_image,
            tags: this.state.tags
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          this.setState({
            filterOpen : false
          })
            if(response.data.status == 200)
            {   
               this.setState({
                 is_available : response.data.is_available,
                 category_list : [...response.data.category_list],
                 page : this.state.page + 1,
                 total_categories: response.data.total_category
               })
            }
            else
                message.error("Something went wrong!");
        });
      }

      handleChangeSearchTags = tags => {
        this.setState({
          category_list : null,
          tags: tags
        })

        axios
        .post(
          `${serverOmnycommUrl}/sales-app/fetch-categories-for-sales/`,
          {
            page : 1,
            tags : tags,
            has_image : this.state.filters.has_image
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          this.setState({
            filterOpen : false
          })
            if(response.data.status === 200)
            {   
               this.setState({
                 is_available : response.data.is_available,
                 category_list : [...response.data.category_list],
                 page : this.state.page + 1,
                 total_categories: response.data.total_category
               })
            }
            else
                message.error("Something went wrong!");
        });
      }

      loadMore = () => {
        this.setState({
          loadmoreLoading : true
        })
        axios
          .post(
            `${serverOmnycommUrl}/sales-app/fetch-categories-for-sales/`,
            {
              page: this.state.page + 1,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            this.setState({
              loadmoreLoading: false,
            });
            if (response.data.status == 200) {
              this.setState({
                is_available: response.data.is_available,
                category_list: [
                  ...this.state.category_list,
                  ...response.data.category_list,
                ],
                page: this.state.page + 1,
                total_categories: response.data.total_category
              });
            } else message.error("Something went wrong!");
          });
      }

        //  for main image
      handleImageUpload = (e , uuid , Detailed) => {
        this.setState({
          upload_loading : true
        })

        let formData = new FormData();
        formData.append("category_uuid", uuid);
        formData.append("image" , e.target.files[0]);
        if(Detailed)
        formData.append("image_type" , "detailed");

        let image_url_just_uploaded = URL.createObjectURL(e.target.files[0])

        axios
        .post(`${serverOmnycommUrl}/upload-category-sales-image/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        })
        .then(response => {
            this.setState({
              upload_loading : false,
            })

            let itemUploadFor_image_url = Detailed ? "image_url_detailed" : "image_url";

            switch(response.data.status){
                case 200:
                    message.success("Image Uploaded!");
                    let category_list_copy = this.state.category_list;
        
                    if(!Detailed)
                      category_list_copy[this.state.itemUploadFor.index].image_url = image_url_just_uploaded;
                    else
                      category_list_copy[this.state.itemUploadFor.index].image_url_detailed = image_url_just_uploaded;

                    this.setState({
                      itemUploadFor : {
                        ...this.state.itemUploadFor,
                        [itemUploadFor_image_url] : image_url_just_uploaded
                      },
                      category_list : category_list_copy
                    })
                    break;
                case 500:
                    message.error("Something went wrong!");
                    break;
                case 403:
                    message.info("You are not authorized!");
                    break;
                default:
                    message.error("Something went wrong");
            }
        });
      }


      showModal = (index) => {
        this.setState({
            visible : true,
        })
        
      };
      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };

     
    render() {
        
        return (
    <Row className="Categoryimage-page-container">
         <TitleWithBreadCrumb
           title="Category Images"
           breadcrumb={["OmnyComm", "Category Images"]}
         />
        <Col span={24} className="Categoryimage-table-container">
        <Row gutter={[12, 0]} className="top-nav">
            <Col lg={3} sm={8}>
              
              <Button style={{ width: "100%" }} type="primary" ghost>
                Result : {this.state.total_categories}
              </Button>
            </Col>

            <Col lg={17} sm={24}>
              <Select
                dropdownClassName="search-chips"
                dropdownMatchSelectWidth="false"
                mode="tags"
                style={{ width: "100%" }}
                placeholder={[<Icon type="search" />, " Search here..."]}
                onChange={this.handleChangeSearchTags}
                value={this.state.tags}
              ></Select>
            </Col>

            <Col lg={4}>
                <Button style={{ width: "100%" }} type="primary" onClick = {() => {
                  this.setState({
                    filterOpen : true
                  })
                }}>    
                Filter    
                </Button>   
            </Col>
        </Row>
        <Row className="categoryimage-table">
            {this.state.category_list ?
              this.state.category_list.map((item, index) => {

                return (
                  <Col
                    key={index}
                    lg={8}
                    md={12}
                    sm={24}
                    className="categoryimage-card"
                  >
                     {/* <Link
                              to={{ pathname: `factory-products/${item.uuid}` }}
                            > */}
                              <Card bordered={true} className="factory-card">
                    <Row className="categoryimage-card-content ">
                      <Col span={24}>
                        <Row style={{ marginBottom: "0px"  }}>
                          <Col span={6}>
                            <Avatar
                              className="categoryimage-avatar"
                              shape="square"
                              style={{ width: "60px", height: "60px" }}
                              src={item.image_url}
                            />
                          </Col>
                          <Col span={17} offset={1}>
                            <Typography.Paragraph
                              ellipsis={{ rows: 1, expandable: false }}
                              style={{ marginBottom: "0px" }}
                              className="text-color-1"
                              strong="true"
                            >
                              {item.category_name}
                            </Typography.Paragraph>

                            <div style={{ height: "40px" }}>
                              <Typography.Paragraph
                                ellipsis={{ rows: 2, expandable: false }}
                                style={{ marginBottom: "0px" }}
                              >
                                {item.super_category_name}
                              </Typography.Paragraph>
                            </div>
                          </Col>
                         
                        </Row>
                        
                      </Col>
                      <div  style = 
                          {{position : "absolute" , bottom : "10px" , right : "10px"}}>
                    
                   
                      {/* <Tooltip title = "upload/change image">
                        <Button
                        disabled = {this.state.upload_loading[index]} size = {"small"} type = "primary" ghost icon = {
                          this.state.upload_loading[index] ? "loading" : "upload"
                        } style = {{
                          position : "relative"
                        }}
                        onPreview={this.handlePreview}
                        >
                          <input onChange = {(e) => this.handleImageUpload(e ,item.uuid , index)} type="file" style = {{
                          position : "absolute",
                          top : "0px",
                          left : "0px",
                          opacity : "0",
                          width : "100%",
                          cursor : "pointer"
                        }} />
                        </Button>
                      </Tooltip> */}

                      <Tooltip title="upload/change image"> 
                        <Button  size={"small"}  type = "primary" onClick={() => {
                          console.log(item) 
                          this.setState({
                            itemUploadFor : {...item , index}
                          } , () => {
                            this.showModal();
                          })  
                      }} ghost 
                            onPreview={this.handlePreview}>
                              Upload Images
                        </Button>  
                      </Tooltip>
                  </div>


                    </Row>
                    </Card>
                    {/* </Link> */}
                  </Col>
                ) 
              }
              )
              :
              <Row
                      type="flex"
                      align="middle"
                      justify="center"
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#37414B"
                      }}
                    >
                      <Ellipsis color="#1ABC9C" size={100} />
                    </Row>
            }


{
            this.state.is_available &&

            <Row
                      type="flex"
                      align="middle"
                      justify="center"
                      style={{
                        width: "100%",
                        marginTop : "10px"
                      }}
                    >
                      <Button type = "primary"
                      onClick = {this.loadMore}
                      loading = {this.state.loadmoreLoading} disabled = {this.state.loadmoreLoading}>Load More</Button>
                    </Row>
          }

          </Row>
        </Col>

        <Modal
                      title="Upload/change image"
                      visible={this.state.visible}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      width={500}
                      Style={{height:"100px"}}
                >     
        <ModalContent itemUploadFor = {this.state.itemUploadFor} handlePreview = {this.handlePreview} handleImageUpload = {this.handleImageUpload} upload_loading = {this.state.upload_loading} />
        </Modal>

        <Modal
          title="Filter"
          visible={this.state.filterOpen}
          onOk={this.applyFilter}
          onCancel={() => {
            this.setState({
              filterOpen : false
            })
          }}
        >

            <Row type="flex" align="middle" gutter={[32, 32]}>
              <Col span={12}>
                <Select
                  defaultValue="allImages"
                  style={{ width: "100%" }}
                  onSelect={(value) => {
                    this.setState({
                      filters : {
                        has_image : value
                      }
                    })
                  }}
                  value={this.state.filters.has_image}
                  placeholder="All Images"
                >
                  <Option value={null}>All Images</Option>
                  <Option value={true}>With Images</Option>
                  <Option value={false}>Without Images</Option>
                </Select>
              </Col>
            </Row>

          </Modal>

        
    </Row>       
        )
    }
}
