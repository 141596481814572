import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Card, Tooltip, Avatar, Button, Input, Row, Col, Select,
Checkbox, Typography, Upload, Icon, AutoComplete, List, Spin, message } from "antd";
import { Link, Redirect } from "react-router-dom";

import "./create-and-update-product-modal.scss";

import axios from "axios";

import Fuse from "fuse.js";

import styled from "styled-components";
import ExportCards from "../../cards/ExportCards";
import ChannelCard from "../../cards/ChannelCard";

import isDev from "../../../../server";

const serverUrl = isDev();
const { Option } = Select;
const { Text, Paragraph } = Typography;
const { Search } = Input

const UploadBtn = styled(Upload)`
 && {
    .ant-upload {
        height:"15px !important",
    }
  }
`

const ExportBtn = styled(Button)`
  && {
    background-color: #50C6E1;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #50C6E1;
      color: white;
      border: none;
    }
  }
`;

class CreateAndUpdateProductModal extends Component {

    state = {
       visible: false,
       operationType : null,
       CompositeAttributes : {},
       SingleAttributes : {},
       checked : {},
       fileList : [],
       uploading : false,
       resultLink : "",
       searchSingleAttributeStr : "",
       searchedAttrDict : {}
    };

    reset = () => {
        this.setState({
            operationType : null,
            CompositeAttributes : {},
            SingleAttributes : {},
            checked : {},
            fileList : [],
            uploading : false,
            resultLink : ""
        })
    }

    showModal = () => {
        this.setState({
            visible : true,
        })
    }

    handleOk = () => {

    }

    handleCheck = (type , variable) => {
            this.setState({
                checked : {
                    ...this.state.checked,
                    [variable] : !this.state.checked[variable]
                }
            })
    }

    handleCancel = () => {
        this.setState({
            visible : false
        })
    }

    fetchDataPoints = () => {
        let data = {
            operation: this.state.operationType,
        };
        this.setState({
            loading : true
        })
        fetch(`${serverUrl}/fetch-data-points-for-upload/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    loading : false
                })
                if (data.status === 200) {
                    let CompositeAttributes = {};
                    data.data_point_list.map((attr) => {
                        if(attr.is_list){
                            attr.currLength = 1;
                            CompositeAttributes[attr.variable] = attr
                        }
                    })
                    let SingleAttributes = {};
                    let checked = {};
                    for(let i = 0;i < data.data_point_list.length ; i++)
                    {
                        let attr = data.data_point_list[i];
                        if(!attr.is_list){
                            if(attr.is_required)
                            {
                                checked[attr.variable] = true
                            }
                            SingleAttributes[attr.variable] = attr
                        }
                    }
                    this.setState({ CompositeAttributes , SingleAttributes , checked , resultLink : "" , searchedAttrDict : SingleAttributes });
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else
                {
                    message.error("Something went wrong in fetching attribute list!")
                }
            });
    }

    onChangeType = (e) => {
        this.setState({
            operationType : e
        },() => {
            this.fetchDataPoints();
        })
    }

    componentDidMount = () => {
        // this.fetchDataPoints();
    }

    changeLength = (variable , x) => {
        this.setState({
            CompositeAttributes : {
                ...this.state.CompositeAttributes,
                [variable] : {
                    ...this.state.CompositeAttributes[variable],
                   currLength : this.state.CompositeAttributes[variable].currLength + x
                }
            }
        })
    }

    getDataPointList = () => {
        let ObjectKeysList = Object.keys(this.state.checked);

        let ans = []

        for(let i = 0;i<ObjectKeysList.length;i++)
        {
            let key = ObjectKeysList[i];
            if(this.state.checked[key] == false)continue;

            if(this.state.CompositeAttributes[key])
            {
                let baseStr = key.substring(0 , key.lastIndexOf("_"));
                let count = this.state.CompositeAttributes[key].currLength;
                for(let j = 1;j<=count;j++)
                {
                   ans.push(`${baseStr}_${j}`)
                }
            }
            else{
                ans.push(key);
            }
        }

        return ans;
    }

    DownloadDynamicExcelTemplate = () => {
        let data = {
            data_point_list : []
        }

        data.data_point_list = this.getDataPointList();

        console.log(data.data_point_list);

        fetch(`${serverUrl}/download-dynamic-excel-template/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                    if(data.status == 200)
                    {
                        window.open(data.path , "_blank")
                    }
                    else if(data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                    else
                    {
                        message.error("Something went wrong during downloading the template!")
                    }
        });
    }

    handleUnselectFile = () => {
        this.setState({
            fileList : []
        })
    }    


    BulkUploadDynamicExcel = file => {
        let formData = new FormData();
        let data_point_list = JSON.stringify(this.getDataPointList());
        formData.append("import_file", file);
        formData.append("data_point_list" , data_point_list);
        formData.append("operation" , this.state.operationType)
        this.setState({ uploading: true });
        axios
            .post(`${serverUrl}/bulk-upload-dynamic-excel/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
            .then(res => {
                if(res.data.status == 200)
                {   
                    this.setState({ uploading: false , fileList : [] , resultLink : res.data.path ,
                         preductAccepted : res.data.accepted_products , productRejected : res.data.rejected_products });
                    message.success("File is successfully uploaded!");
                }
                else if(res.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else{
                    message.error("Internal Server Error during file upload!");
                }
            })
            .catch(err => {
                this.setState({ uploading: false , fileList : [] });
                message.error("Internal Server Error during file upload!");
            });
    }

    handleSelectFileForExcelUpload = (file) => {
        this.setState({ fileList: [file] });
        this.BulkUploadDynamicExcel(file);
    }

    searchAttrList = (value) => {

        if(value == "")
        {
                this.setState({
                    searchedAttrDict : this.state.SingleAttributes
                })
                return;
        }

        let options = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            minMatchCharLength: 1,
            keys: [
                "name",
            ]
        };

        let attributeList = [];
        Object.keys(this.state.SingleAttributes).map(e => {
            attributeList.push(this.state.SingleAttributes[e]);
        })
        console.log(attributeList);
        let fuse = new Fuse(attributeList, options); // "list" is the item array
        let result = fuse.search(value);

        console.log(result);

        let aL = [];
        for (let ind in result) {
            aL.push(result[ind]["item"]);
        }

        let searchedAttrDict = {};

        aL.map(e => {
            searchedAttrDict[e.variable] = e;
        })

        console.log(searchedAttrDict);

        this.setState({ searchedAttrDict });
    }
  
    render() {

        console.log(this.state);

        let {CompositeAttributes , searchedAttrDict} =  this.state;

        return (
            <div className="create-and-update-product-modal">

                <Tooltip title="Create And Update Product">
                    <ExportBtn
                        style={{ width: "100%" }}
                        type="danger"
                        onClick={this.showModal}
                        block
                    >
                         Product
                 </ExportBtn>
                </Tooltip>


                <Modal
                    destroyOnClose={true}
                    title="Dynamic Bulk Product Upload"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className="product-export-modal"
                    width="fit-content"
                    style={{display: "flex", justifyContent: "center"}}
                    footer={[
                        <Button key="back"
                        style = {{
                            marginRight : "10px"
                        }} 
                        onClick={this.DownloadDynamicExcelTemplate}>
                            Download Template
                        </Button>,
                        <UploadBtn 
                         beforeUpload={this.handleSelectFileForExcelUpload} onRemove={this.handleUnselectFile} fileList={this.state.fileList}>
                          <Button type="primary" loading = {this.state.uploading} disabled={this.state.fileList.length !== 0} className="upload-btn">
                              Upload File
                          </Button>
                        </UploadBtn>,
                    ]}
                >

                    <Row gutter={[16, 16]}>
                            <Col span={24} >
                                <Row>
                                    <Col span = {20}>
                                        <Checkbox checked = {this.state.operationType === "Create"} onChange = {() => this.onChangeType("Create")}>Create Product</Checkbox>
                                        <Checkbox checked = {this.state.operationType === "Update"} onChange = {() => this.onChangeType("Update")}>Update Product</Checkbox>
                                    </Col>
                                    <Col span = {4}>
                                        <Row type = "flex" justify = "end" align = "middle">
                                            <Col>
                                                <Icon type = "redo" onClick = {() => {
                                                    this.reset()
                                                }} style = {{cursor : "pointer" , color : "#1ABC9C"}}  />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            {
                                this.state.resultLink != "" &&
                                <Col span={24} style = {{padding : "12px" , marginTop : "20px"}} className="custom-card-2">
                                        <Row type = "flex" justify = "center" align = "middle">
                                            <Col>
                                                <Button onClick = {
                                                    () => {
                                                        window.open(this.state.resultLink , "_blank")
                                                    }
                                                }>
                                                    Download results
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row type = "flex" justify = "center" align = "middle" style = {{marginTop : "10px"}}>
                                            <Col>
                                                Total products accepted : {this.state.preductAccepted}
                                            </Col>
                                        </Row>
                                        <Row type = "flex" justify = "center" align = "middle">
                                            <Col>
                                                Total products rejected : {this.state.productRejected}
                                            </Col>
                                        </Row>
                                </Col>
                            }

                            {
                                (this.state.loading) ? 
                                <div style = {{
                                    textAlign : "center"
                                }}>
                                     <Spin size="large" />
                                </div> :
                                <React.Fragment>
                                {
                                    this.state.operationType && 
                                    <React.Fragment>
                                    {
                                        Object.keys(CompositeAttributes).length > 0 &&
                                        <React.Fragment>
                                           
                                            <Col style = {{marginTop : "12px"}} span={24}>Composite Attributes</Col>

                                            <Col span={24} style = {{padding : "12px"}} className="custom-card-2">
                                                <Row gutter = {4} style = {{ maxHeight : "20vh" , overflow : "scroll" }}>
                                                {
                                                   Object.keys(CompositeAttributes).map(e => {
                                                        return (
                                                            <Col>
                                                                <Checkbox

                                                                    checked = {this.state.checked[e]}
                                                                    onChange = {() => this.handleCheck("composite-type" , e)}
                                                                
                                                                >{CompositeAttributes[e].name.substring(0 , CompositeAttributes[e].name.lastIndexOf(" "))}{" "}{CompositeAttributes[e].currLength}</Checkbox>
                                                               { CompositeAttributes[e].currLength > 1 && <Icon type="minus-circle" style = {{cursor : "pointer" , color : "#1ABC9C" , marginRight : "5px"}}
                                                                    onClick = {() => this.changeLength(e , -1)}
                                                                /> }

                                                                {
                                                                    CompositeAttributes[e].currLength < 10 && 
                                                                    <Icon type="plus-circle" style = {{cursor : "pointer" , color : "#1ABC9C"}} 
                                                                    onClick = {() => this.changeLength(e , 1)}
                                                                />
                                                                }
                                                               
                                                            </Col>
                                                        )
                                                    })
                                                }
                                                </Row>
                                            </Col>
                                        </React.Fragment>
                                    }
                                  
                                        <React.Fragment>
                                            
                                            <Col span={24} style = {{marginTop : "12px"}}>Single Attributes 
                                            </Col>

                                            <Col span={24} style = {{padding : "12px" , marginBottom : "12px"}} className="custom-card-2">
                                                <Row gutter = {4}>

                                                <Col>
                                                    <Input
                                                            placeholder="input search text"
                                                            onChange={value => { this.setState({ searchSingleAttributeStr: value.target.value }); this.searchAttrList(value.target.value) }}
                                                            value={this.state.searchSingleAttributeStr}
                                                            style={{ width: 200 , marginBottom : "10px" }}
                                                    />
                                                </Col>

                                                <div style = {{ maxHeight : "20vh" , overflow : "scroll" }}>
                                                {
                                                    Object.keys(searchedAttrDict).map(e => {
                                                        return (
                                                            <Col span = {12}>
                                                                <Checkbox 
                                                                      checked = {this.state.checked[e]}
                                                                      onChange = {() => this.handleCheck("single-type" , e)}
                                                                      disabled = {searchedAttrDict[e].is_required}
                                                                >{searchedAttrDict[e].name}</Checkbox>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                </Row>
                                            </Col>
                                        </React.Fragment>
                                   
                                </React.Fragment>
                                }
                                </React.Fragment>
                            }

                           
                    </Row>
                   
                </Modal>
            </div>
        );
    }
}



export default connect()(CreateAndUpdateProductModal);
