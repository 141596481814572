import React , {useState , useEffect} from "react";
import {useDispatch} from "react-redux";
import {Row , Col , Radio , Typography , Button , Upload , message} from "antd";
import styled from "styled-components"
import isDev from "server";
import axios from "axios";
import {fetchAll} from "actions/exports";
import Options from "./Options";
import UploadType from "./UploadType";
import { options } from "less";
import NoonTemplates from "./Noon Bulk Upload Templates.xlsx";
import AmazonTemplates from "./Data Upload Templates - Amazon UAE.xlsx";
const {Text} = Typography

const UploadBtn = styled(Upload)`
 && {
    .ant-upload {
        height:0px !important;
        width: 100% !important;
    }
    span {
      width : 100% !important;
    }
  }`



const serverUrl = isDev();

const UploadModal = (props) => {

    const [uploadType , setUploadType] = useState("");
    const [optionType , setOptionType] = useState("");
    const [loading , setLoading] = useState(false);
    const [fileListSearch , setFileListSearch] = useState([]);
    const [fileListStock , setFileListStock] = useState([]);
    const [fileListPrice , setFileListPrice] = useState([]);
    const [fileListPriceAndStock , setFileListPriceAndStock] = useState([]);
    const [errorShow , setErrorShow] = useState(null);
    const dispatch = useDispatch();

    const apis = {
        "Noon" : {
            stocks : "bulk-update-noon-product-stock",
            prices : "bulk-update-noon-product-price",
            prices_stocks : "bulk-update-noon-product-price-and-stock"
        },
        "Amazon UAE" : {
            stocks : "bulk-update-amazon-uae-product-stock",
            prices : "bulk-update-amazon-uae-product-price",
            prices_stocks : "bulk-update-amazon-uae-product-price-and-stock"  
        },
        "Amazon UK" : {
            stocks : "bulk-update-amazon-uk-product-stock",
            prices : "bulk-update-amazon-uk-product-price",
            prices_stocks : "bulk-update-amazon-uk-product-price-and-stock" 
        }
    }
    
    const handleSelectFile = (file) => {
        setFileListSearch([file]);
        props.uploadModalFalse();
        dispatch(fetchAll(
            props.channelname , 
            {
                verified: false,
                start_date: "",
                end_date: "",
                brand_name: "",
                has_image: ""
              },
              [], file, optionType
        ))
      }

    const  handleSelectFileStocks = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("channel_name", props.channelname);
        formData.append("option" , optionType);
        setLoading(true);
        axios
          .post(`${serverUrl}/${apis[props.channelname].stocks}/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("token")} `
            }
          })
          .then(res => {

            if(res.data.excel_errors && res.data.excel_errors.length > 0)setErrorShow(res.data.excel_errors);
            else props.uploadModalFalse();
           
            if (res.data.status === 200) {

              dispatch(fetchAll(
                props.channelname , 
                {
                    verified: false,
                    start_date: "",
                    end_date: "",
                    brand_name: "",
                    has_image: ""
                  },
                  [], file, optionType
              ))

              message.success("Success!")
            }
            else if(res.data.status === 405)
            {
              message.error("Wrong Template");
            }
            else if(res.data.status === 406)
            {
              message.error("No Sheet 1");
            }
            else if(res.data.status === 407)
            {
              message.error("Unsupported file format");
            }
            else {
              message.error("Internal Server Error!");
            }
            setLoading(false);
            setFileListStock([file]);
          })
          .catch(err => {
            setLoading(false);
            message.error("Internal Server Error!");
          });
      }
    
     const handleSelectFilePrices = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("channel_name", props.channelname);
        formData.append("option" , optionType);
        setLoading(true);
        axios
          .post(`${serverUrl}/${apis[props.channelname].prices}/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("token")} `
            }
          })
          .then(res => {

            if(res.data.excel_errors && res.data.excel_errors.length > 0)setErrorShow(res.data.excel_errors);
            else props.uploadModalFalse();

            if (res.data.status === 200) {

              dispatch(fetchAll(
                props.channelname , 
                {
                    verified: false,
                    start_date: "",
                    end_date: "",
                    brand_name: "",
                    has_image: ""
                  },
                  [], file, optionType
              ))
              message.success("Success!")
            }
            else if(res.data.status === 405)
            {
              message.error("Wrong Template");
            }
            else if(res.data.status === 406)
            {
              message.error("No Sheet 1");
            }
            else if(res.data.status === 407)
            {
              message.error("Unsupported file format");
            }
            else {
              message.error("Internal Server Error!");
            }
            setLoading(false);
            setFileListPrice([file]);
            
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
            message.error("Internal Server Error!");
          });
      }

     const handleSelectFilePricesAndStocks = (file) => {
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("channel_name", props.channelname);
        formData.append("option" , optionType);
        setLoading(true);
        axios
          .post(`${serverUrl}/${apis[props.channelname].prices_stocks}/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("token")} `
            }
          })
          .then(res => {

            if(res.data.excel_errors && res.data.excel_errors.length > 0)setErrorShow(res.data.excel_errors);
            else props.uploadModalFalse();
           
            if (res.data.status === 200) {

              dispatch(fetchAll(
                props.channelname , 
                {
                    verified: false,
                    start_date: "",
                    end_date: "",
                    brand_name: "",
                    has_image: ""
                  },
                  [], file, optionType
              ))
              message.success("Success!")
            }
            else if(res.data.status === 405)
            {
              message.error("Wrong Template");
            }
            else if(res.data.status === 406)
            {
              message.error("No Sheet 1");
            }
            else if(res.data.status === 407)
            {
              message.error("Unsupported file format");
            }
            else {
              message.error("Internal Server Error!");
            }
            setLoading(false);
            setFileListPriceAndStock([file]);
           
          })
          .catch(err => {
            setLoading(false);
            message.error("Internal Server Error!");
          });
      }


    return (
        <React.Fragment>
            <Row gutter={[12, 18]}  >
                

                <Col span={12}>
                    <UploadType uploadType = {uploadType} setUploadType = {(e) => setUploadType(e)} channelname = {props.channelname} />
                </Col>

                <Col span={12}>
                    <Options optionType = {optionType} setOptionType = {(e) => setOptionType(e)} channelname = {props.channelname} />
                </Col>

               

            {uploadType == "search" && optionType !== "" &&
              <Col span={12}>
                <UploadBtn style={{ width: "100%" }} beforeUpload={handleSelectFile} >
                  <Button loading={loading}
                    disabled={fileListSearch.length != 0}
                    style={{ width: "100%", }}
                    type="primary"> Upload </Button>
                </UploadBtn>
              </Col>
            }

            {uploadType == "price" && optionType !== "" &&
              <Col span={12}>
                <UploadBtn style={{ width: "100%", marginLeft: "5px" }} beforeUpload={handleSelectFilePrices} onRemove = {() => setFileListPrice([])} >
                  <Button loading={loading}
                    disabled={fileListPrice.length != 0}
                    style={{ width: "100%", }}
                    type="primary"> Upload </Button>
                </UploadBtn>
              </Col>
            }

            {uploadType == "stock" && optionType !== "" &&
              <Col span={12}>
                <UploadBtn style={{ width: "100%", marginLeft: "5px" }} beforeUpload={handleSelectFileStocks} onRemove  ={()=>setFileListStock([])}>
                  <Button loading={loading}
                    disabled={fileListStock.length != 0}
                    style={{ width: "100%", }}
                    type="primary"> Upload </Button>
                </UploadBtn>
              </Col>
            }

            {uploadType == "price and stock" && optionType !== "" &&
              <Col span={12}>
                <UploadBtn style={{ width: "100%", marginLeft: "5px" }} beforeUpload={handleSelectFilePricesAndStocks} onRemove = {()=>setFileListPriceAndStock([])} >
                  <Button loading={loading}
                    disabled={fileListPriceAndStock.length != 0}
                    style={{ width: "100%", }}
                    type="primary"> Upload </Button>
                </UploadBtn>
              </Col>
            }

            {
              errorShow && 
              <Col span = {24} style = {{color : "red"}}>
                  Excel Errors
                  <br/>
                  {errorShow.map((e , index) => {
                   return <Text style = {{color : "red"}} key = {index}>{e}</Text>
                  })}
              </Col>
            }

              {props.channelname == "Noon" &&
                <Col span={18}>
                  <Text>Download <Text
                    onClick={() => {
                      window.open(NoonTemplates);
                    }}
                     style={{ color: "#fff", cursor: "pointer" }}>Noon templates</Text></Text>
                </Col>
              }

            {(props.channelname == "Amazon UK" || props.channelname == "Amazon UAE") &&
                <Col span={18}>
                  <Text>Download <Text
                    onClick={() => {
                      window.open(AmazonTemplates);
                    }}
                     style={{ color: "#fff", cursor: "pointer" }}>Amazon templates</Text></Text>
                </Col>
            }


            <Col span = {24}>
                <ul>  
                  <li>Upload an excel file with .xlsx extension only</li>
                  <li>Excel file should contain the sheet named "Sheet1"</li>
                  <li>File size should be less than 20 MB</li>
                  <li>Select an appropriate option according to the objective</li>
                  <li>Stock must be an integer</li>
                  <li>The price must be a decimal value</li>
                  <li>Leave the cell blank if data is not available</li>
                </ul>
            </Col>

          </Row>

        </React.Fragment>
    )
}   

export default UploadModal;