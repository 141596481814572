import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Modal,
    Button,
    Row,
    Col,
    Select,
    Radio,
    Badge,
    Typography,
} from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./product-list-filter-modal.scss";
import styled from "styled-components";
import { fetchProductList, addFilters, setEmpty } from "../../../../actions";

import isDev from "../../../../server";

const serverUrl = isDev();
const { Option } = Select;

const { Text } = Typography;

const FilterBtn = styled(Button)`
    && {
        background-color: #1abc9c;
        /* width:"100%"; */

        color: white;
        border: none;

        &:hover {
            background-color: #1abc9c;
            color: white;
            border: none;
        }
    }
`;

class ProductListFilterModal extends Component {
    state = {
        visible: false,
        imageSelect: 0,
        brandSelect: 0,
        hasImage: "0",
        imageType: "all",
        productStatus: "all",
        imageSelectCount: 0,
        productStatusCount: 0,
        imageCount: 0,
        isOnline: "",
        isVerified: "",
        vendorCategory: "all",
        onlineCount: 0,
        verifiedCount: 0,
        vendorCount: 0,
    };

    showModal = () => {
        this.setState({
            visible: true,
            startDate: new Date(),
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
        let filterData = {
            brand_name: this.props.filters.brand_name,
            has_image: this.props.filters.has_image,
            start_date: this.state.startDate ? this.state.startDate : "",
            end_date: this.state.endDate ? this.state.endDate : "",
            verified: this.state.verified ? this.state.verified : "",
        };

        // this.props.dispatch({ type: "ACTIVATE_FILTER" })
        this.props.dispatch(setEmpty());
        this.props.dispatch(
            fetchProductList(
                filterData,
                this.props.tags,
                this.props.isNestoUser
            )
        );
        this.props.dispatch(addFilters(filterData));
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };

    // handleCheckbox = ({ target: { checked } }) => {
    //
    //     this.setState({
    //         verified: checked
    //     })
    // }

    handleImageSelect = (value) => {
        let filterData = {
            brand_name: this.props.filters.brand_name,
            has_image: value,
            start_date: this.state.startDate ? this.state.startDate : "",
            end_date: this.state.endDate ? this.state.endDate : "",
            verified: this.state.verified ? this.state.verified : "",
        };

        if (value !== "") this.setState({ imageSelect: 1 });
        if (this.props.filters.brand_name !== "")
            this.setState({ brandSelect: 1 });
        if (value == "") this.setState({ imageSelect: 0 });
        if (this.props.filters.brand_name == "")
            this.setState({ brandSelect: 0 });
        this.props.dispatch(addFilters(filterData));
    };

    handleBrandSelect = (value) => {
        let filterData = {
            brand_name: value,
            has_image: this.props.filters.has_image,
            start_date: this.state.startDate ? this.state.startDate : "",
            end_date: this.state.endDate ? this.state.endDate : "",
            verified: this.state.verified ? this.state.verified : "",
        };
        if (this.props.filters.has_image !== "")
            this.setState({ imageSelect: 1 });
        if (value !== "") this.setState({ brandSelect: 1 });
        if (this.props.filters.has_image == "")
            this.setState({ imageSelect: 0 });
        if (value == "") this.setState({ brandSelect: 0 });
        this.props.dispatch(addFilters(filterData));
    };

    // handleStartDate = (date) => {
    //     this.setState({
    //         startDate: date
    //     });
    // }
    // handleEndDate = (date) => {
    //     this.setState({
    //         endDate: date
    //     });
    // }

    handleImageChange = (e) => {
      
      let filterData = {
        brand_name: this.props.filters.brand_name,
        has_image: e.target.value === "0" ? "" : e.target.value === "1" ? "1" : "0",
        start_date: this.state.startDate ? this.state.startDate : "",
        end_date: this.state.endDate ? this.state.endDate : "",
        verified: this.state.verified ? this.state.verified : "",
        image_type: this.state.imageType,
        product_status: this.state.productStatus,
        is_online: this.state.isOnline === "1" ? true : (this.state.isOnline === "0" ? false : ""),
        is_verified: this.state.isVerified === "1" ? true : (this.state.isVerified === "0" ? false : ""),
        vendor_category: this.state.vendorCategory
      };
      this.setState({
        hasImage: e.target.value
      })
      this.props.dispatch(addFilters(filterData));
    }

    handleOkNesto = (e) => {
        if(this.state.imageType === "all") {
            this.setState({
                imageSelectCount: 0
            })
        } else {
            this.setState({
                imageSelectCount: 1
            })
        }

        if(this.state.productStatus === "all") {
            this.setState({
                productStatusCount: 0
            })
        } else {
            this.setState({
                productStatusCount: 1
            })
        }

        if(this.state.hasImage === "0") {
            this.setState({imageCount: 0})
        } else {
            this.setState({imageCount: 1})
        }

        if(this.state.isOnline === "") {
            this.setState({
                onlineCount: 0
            })
        } else {
            this.setState({
                onlineCount: 1
            })
        }

        if(this.state.isVerified === "") {
            this.setState({
                verifiedCount: 0
            })
        } else {
            this.setState({
                verifiedCount: 1
            })
        }

        if(this.state.vendorCategory === "all") {
            this.setState({vendorCount: 0})
        } else {
            this.setState({vendorCount: 1})
        }
        
      this.setState({
          visible: false,
      });

      let filterData = {
          brand_name: this.props.filters.brand_name,
          has_image: this.props.filters.has_image,
          start_date: this.state.startDate ? this.state.startDate : "",
          end_date: this.state.endDate ? this.state.endDate : "",
          verified: this.state.verified ? this.state.verified : "",
          image_type: this.state.imageType,
          product_status: this.state.productStatus,
          is_online: this.state.isOnline === "1" ? true : (this.state.isOnline === "0" ? false : ""),
          is_verified: this.state.isVerified === "1" ? true : (this.state.isVerified === "0" ? false : ""),
          vendor_category: this.state.vendorCategory
      };

      // this.props.dispatch({ type: "ACTIVATE_FILTER" })
      this.props.dispatch(setEmpty());
      this.props.dispatch(
          fetchProductList(
              filterData,
              this.props.tags,
              this.props.isNestoUser
          )
      );
      this.props.dispatch(addFilters(filterData));
    };

    handleImageSelectNesto = (value) => {
      let filterData = {
          brand_name: this.props.filters.brand_name,
          has_image: this.props.filters.has_image,
          start_date: this.state.startDate ? this.state.startDate : "",
          end_date: this.state.endDate ? this.state.endDate : "",
          verified: this.state.verified ? this.state.verified : "",
          image_type: value,
          product_status: this.state.productStatus,
          is_online: this.state.isOnline === "1" ? true : (this.state.isOnline === "0" ? false : ""),
          is_verified: this.state.isVerified === "1" ? true : (this.state.isVerified === "0" ? false : ""),
        vendor_category: this.state.vendorCategory
      };

      this.setState({
        imageType: value
      })

      this.props.dispatch(addFilters(filterData));
    };

    handleStatusSelect = (value) => {
      let filterData = {
        brand_name: this.props.filters.brand_name,
        has_image: this.props.filters.has_image,
        start_date: this.state.startDate ? this.state.startDate : "",
        end_date: this.state.endDate ? this.state.endDate : "",
        verified: this.state.verified ? this.state.verified : "",
        image_type: this.state.imageType,
        product_status: value,
        is_online: this.state.isOnline === "1" ? true : (this.state.isOnline === "0" ? false : ""),
        is_verified: this.state.isVerified === "1" ? true : (this.state.isVerified === "0" ? false : ""),
        vendor_category: this.state.vendorCategory
      };

      this.setState({
        productStatus: value
      })

      this.props.dispatch(addFilters(filterData));
    }

    handleOnlineSelect = (value) => {
        let filterData = {
            brand_name: this.props.filters.brand_name,
            has_image: this.props.filters.has_image,
            start_date: this.state.startDate ? this.state.startDate : "",
            end_date: this.state.endDate ? this.state.endDate : "",
            verified: this.state.verified ? this.state.verified : "",
            image_type: this.state.imageType,
            product_status: this.state.productStatus,
            is_online: value === "1" ? true : (value === "0" ? false : ""),
            is_verified: this.state.isVerified === "1" ? true : (this.state.isVerified === "0" ? false : ""),
            vendor_category: this.state.vendorCategory
          };
    
          this.setState({
            isOnline: value
          })
    
          this.props.dispatch(addFilters(filterData));
    }

    handleVerifiedSelect = (value) => {
        let filterData = {
            brand_name: this.props.filters.brand_name,
            has_image: this.props.filters.has_image,
            start_date: this.state.startDate ? this.state.startDate : "",
            end_date: this.state.endDate ? this.state.endDate : "",
            verified: this.state.verified ? this.state.verified : "",
            image_type: this.state.imageType,
            product_status: this.state.productStatus,
            is_online: this.state.isOnline === "1" ? true : (this.state.isOnline === "0" ? false : ""),
            is_verified: value === "1" ? true : (value === "0" ? false : ""),
            vendor_category: this.state.vendorCategory
          };
    
          this.setState({
            isVerified: value
          })
    
          this.props.dispatch(addFilters(filterData));
    }

    handleVendorSelect = (value) => {
        let filterData = {
            brand_name: this.props.filters.brand_name,
            has_image: this.props.filters.has_image,
            start_date: this.state.startDate ? this.state.startDate : "",
            end_date: this.state.endDate ? this.state.endDate : "",
            verified: this.state.verified ? this.state.verified : "",
            image_type: this.state.imageType,
            product_status: this.state.productStatus,
            is_online: this.state.isOnline === "1" ? true : (this.state.isOnline === "0" ? false : ""),
            is_verified: this.state.isVerified === "1" ? true : (this.state.isVerified === "0" ? false : ""),
            vendor_category: value
          };
    
          this.setState({
            vendorCategory: value
          })
    
          this.props.dispatch(addFilters(filterData));
    }

    componentDidMount() {
      this.setState({
        isOnline: this.props.filters.is_online === false ? "0" : this.props.filters.is_online === true ? "1" : "",
        isVerified: this.props.filters.is_verified === false ? "0" : this.props.filters.is_verified === true ? "1" : "",
        vendorCategory: this.props.filters.vendor_category ? this.props.filters.vendor_category : "all",
        onlineCount: this.props.filters.is_online === true || this.props.filters.is_online === false ? 1 : 0,
        verifiedCount: this.props.filters.is_verified === true || this.props.filters.is_verified === false ? 1 : 0,
        vendorCount: this.props.filters.vendor_category && this.props.filters.vendor_category !== "all" ? 1 : 0,
        imageType: this.props.filters.image_type ? this.props.filters.image_type : "all",
        productStatus: this.props.filters.product_status ? this.props.filters.product_status : "all",
        hasImage: this.props.filters.has_image ? 
          (this.props.filters.has_image === "0" ? "2" : (this.props.filters.has_image === "1" ? "1": "0")) : "0",
        productStatusCount: this.props.filters.product_status && 
          this.props.filters.product_status !== "all" && this.props.filters.product_status !== "" ? 1 : 0,
        imageCount: this.props.filters.has_image && 
          this.props.filters.has_image !== "" ? 1 : 0,
        imageSelectCount: this.props.filters.image_type && 
          this.props.filters.image_type !== "all" && this.props.filters.image_type !== "" ? 1 : 0
      })
        fetch(
            `${serverUrl}/${
                this.props.isNestoUser ? "fetch-nesto-brands" : "fetch-brands"
            }/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`,
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                this.setState({ brandList: data.brand_list });
            });
    }

    componentWillReceiveProps = (props) => {
        if (props.filters.has_image !== "") this.setState({ imageSelect: 1 });
        if (props.filters.brand_name !== "") this.setState({ brandSelect: 1 });
    };

    render() {
        //
        return (
            <div className="product-list-filter-modal-container">
                <Badge
                    count={this.props.isNestoUser ? this.state.brandSelect + this.state.imageCount + 
                      this.state.productStatusCount + this.state.imageSelectCount + this.state.onlineCount + this.state.verifiedCount + this.state.vendorCount
                      : this.state.brandSelect + this.state.imageSelect
                    }
                    showZero
                >
                    <FilterBtn
                        style={{ width: "100%" }}
                        type="primary"
                        onClick={this.showModal}
                        block
                    >
                        Filter
                    </FilterBtn>
                </Badge>
                <Modal
                    title="Filter"
                    okText="Apply"
                    visible={this.state.visible}
                    onOk={this.props.isNestoUser ? this.handleOkNesto : this.handleOk}
                    width={"fit-content"}
                    onCancel={this.handleCancel}
                    className={this.props.isNestoUser && "filter-modal"}
                >
                    {this.props.isNestoUser ? (
                        <Row type="flex" align="middle" gutter={[16, 16]}>
                            <Col span={24}>
                                <Text style={{color: 'white'}}>Brands</Text>
                                <Select
                                    showSearch
                                    style={{ width: "100%", marginTop: 5 }}
                                    onSelect={this.handleBrandSelect}
                                    value={this.props.filters.brand_name ? this.props.filters.brand_name : undefined}
                                    placeholder="All"
                                >
                                    <Option value="">All</Option>
                                    {this.state.brandList &&
                                        this.state.brandList.map(
                                            (brand, index) => {
                                                return (
                                                    <Option value={brand.name}>
                                                        {brand.name}
                                                    </Option>
                                                );
                                            }
                                        )}
                                </Select>
                            </Col>

                            <Col span={24}>
                                <Text style={{color: 'white'}}>Product Status</Text>
                                <Select
                                    style={{ width: "100%", marginTop: 5 }}
                                    onSelect={this.handleStatusSelect}
                                    value={this.state.productStatus}
                                    placeholder="All"
                                >
                                    <Option value="all">All</Option>
                                    <Option value="not ecommerce">Not Ecommerce Ready</Option>
                                    <Option value="ecommerce">Ecommerce Ready</Option>
                                    <Option value="rich">Rich Products</Option>
                                </Select>
                            </Col>

                            <Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
                                <Text style={{color: 'white'}}>Images</Text>
                                  <Select
                                      style={{ width: "100%", margin: "5px 0" }}
                                      onSelect={this.handleImageSelectNesto}
                                      value={this.state.imageType}
                                      placeholder="All Images"
                                  >
                                      <Option value="all">All Images</Option>
                                      <Option value="front">Front Images</Option>
                                      <Option value="back">Back Images</Option>
                                      <Option value="side">Side Images</Option>
                                      <Option value="nutrition">Nutrition Images</Option>
                                      <Option value="product content">Product Content Images</Option>
                                      <Option value="supplier">Supplier Images</Option>
                                      <Option value="lifestyle">Lifestyle Images</Option>
                                      <Option value="ads">Ads Images</Option>
                                      <Option value="box">Box Images</Option>
                                      <Option value="highlight">Highlight Images</Option>
                                  </Select>
                                  <Radio.Group onChange={this.handleImageChange} value={this.state.hasImage}>
                                    <Radio value={"0"}>
                                      All Products
                                    </Radio>
                                    <Radio value={"1"}>
                                      With Images
                                    </Radio>
                                    <Radio value={"2"}>
                                      Without Images
                                    </Radio>
                                  </Radio.Group>
                            </Col>
                            <Col span={24}>
                                <Text style={{color: 'white'}}>Online</Text>
                                <Select
                                    style={{ width: "100%", marginTop: 5 }}
                                    onSelect={this.handleOnlineSelect}
                                    value={this.state.isOnline}
                                >
                                    <Option value="">All</Option>
                                    <Option value="0">Not Online</Option>
                                    <Option value="1">Online</Option>
                                </Select>
                            </Col>
                            <Col span={24}>
                                <Text style={{color: 'white'}}>Verified</Text>
                                <Select
                                    style={{ width: "100%", marginTop: 5 }}
                                    onSelect={this.handleVerifiedSelect}
                                    value={this.state.isVerified}
                                >
                                    <Option value="">All</Option>
                                    <Option value="0">Not Verified</Option>
                                    <Option value="1">Verified</Option>
                                </Select>
                            </Col>
                            <Col span={24}>
                                <Text style={{color: 'white'}}>Vendor Category</Text>
                                <Select
                                    style={{ width: "100%", marginTop: 5 }}
                                    onSelect={this.handleVendorSelect}
                                    value={this.state.vendorCategory}
                                    placeholder="All"
                                >
                                    <Option value="all">All</Option>
                                    <Option value="Market">Market</Option>
                                    <Option value="Own Brand">Own Brand</Option>
                                    <Option value="Four Digit">Four Digit</Option>
                                    <Option value="Extras">Extras</Option>
                                    <Option value="Vendor">Vendor</Option>
                                </Select>
                            </Col>
                        </Row>
                    ) : (
                        <Row type="flex" align="middle" gutter={[32, 32]}>
                            <Col span={12}>
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    onSelect={this.handleBrandSelect}
                                    value={this.props.filters.brand_name}
                                    placeholder="All"
                                >
                                    <Option value="">All</Option>
                                    {this.state.brandList &&
                                        this.state.brandList.map(
                                            (brand, index) => {
                                                return (
                                                    <Option value={brand.name}>
                                                        {brand.name}
                                                    </Option>
                                                );
                                            }
                                        )}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Select
                                    defaultValue="allImages"
                                    style={{ width: "100%" }}
                                    onSelect={this.handleImageSelect}
                                    value={this.props.filters.has_image}
                                    placeholder="All Images"
                                >
                                    <Option value="">All Images</Option>
                                    <Option value="1">With Images</Option>
                                    <Option value="0">Without Images</Option>
                                    <Option value="2">Unedited Images</Option>
                                </Select>
                            </Col>
                            {/* <Col span={12}>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDate}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={this.handleEndDate}
                                    />
                                </Col> */}
                            {/* <Col span={12}>
                                    <Checkbox onChange={this.handleCheckbox} checked={this.state.verified}>Verified</Checkbox>
                                </Col> */}
                        </Row>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ productList }) => {
    return {
        ...productList,
    };
};

export default connect(mapStateToProps)(ProductListFilterModal);
