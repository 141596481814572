import React, { Component } from 'react';
import { Row, Col } from "antd";

import "./modals.scss"
import { Select } from 'antd';

const { Option } = Select;

class ChooseChannelProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onChange = (e) => {
        this.props.handelChannelSelect(e);
    }



    render() {

        return (<React.Fragment>
            <Row gutter={[24, 24]} type="flex" justify="space-between" >

                <Col sm={24}>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Channel"
                        optionFilterProp="children"
                        onChange={this.onChange}

                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="Amazon Uk">Amazon Uk</Option>
                        <Option value="Amazon Uae">Amazon Uae</Option>
                        <Option value="Ebay">Ebay</Option>
                        <Option value="Noon">Noon</Option>
                    </Select>
                </Col>

            </Row>
        </React.Fragment>);
    }
}

export default ChooseChannelProduct;