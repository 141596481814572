import React, { useState, useEffect } from "react";
import { Row, Col, Collapse, Typography, message, Tag, Avatar, Icon , Button } from "antd";
import axios from "axios";

import isDev from "../../../server";

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const serverDealsHubUrl = isDev(true);


const OrderscardsHeader = ({ order }) => {
    return (
        <Row gutter={[8, 2]}>
            <Col sm={12}>
                <Row type="flex" justify="start">
                    <Text style={{ color: "white", fontSize: "11px" }}>{order.bundleId}</Text>
                </Row>
            </Col>

            <Col sm={12}>
                <Row type="flex" justify="end">
                    <Text style={{ color: "white", fontSize: "11px" }}>{order.datePlaced}</Text>
                </Row>
            </Col>

            <Col sm={12}>
                <Row type="flex" justify="start">
                    <Text style={{ color: "white", fontSize: "11px" }}>{order.unitOrderList.length} order items</Text>
                </Row>
            </Col>

            <Col sm={12}>
                <Row type="flex" justify="end">
                    <Text style={{ color: "white", fontSize: "11px" }}>{order.timePlaced}</Text>
                </Row>
            </Col>

            {/* <Col sm={12}>
                <Row type="flex" justify="start">
                    <Text style={{ color: "white", fontSize: "11px" }}>Sandeep Sharma</Text>
                </Row>
            </Col>

            <Col sm={12}>
                <Row type="flex" justify="end">
                    <Text style={{ color: "white", fontSize: "11px" }}>Wigme</Text>
                </Row>
            </Col> */}


        </Row>
    )
}

const TotalBill = ({ totalbill, mod }) => {
    return (
        <Row type="flex" justify="start" align="middle" gutter={[8, 8]}>
            <Col>
                <Text style={{ fontSize: "11px" }}>Total Bill: <Text style={{ color: "white", fontSize: "11px" }}>{totalbill} </Text>
                </Text>
            </Col>
            <Col>
                <Tag color="green" style={{
                    borderRadius: "10px", fontSize: "8px", padding: "0px 15px", lineHeight: "15px"
                    , backgroundColor: "#1abc9c66", color: "#59CAAA", borderColor: "#59CAAA", position: "relative",
                    bottom: "3px"
                }}>{mod}</Tag>
            </Col>
        </Row>
    )
}

const OrdersProducts = ({ product }) => {
    return (
        <React.Fragment>
            <Row gutter={[12, 12]} style={{ marginTop: "8px" }}>
                <Col span={6}>
                    <Avatar src={product.productImageUrl} shape="square" style={{ width: "100%", height: "100%" }} />
                </Col>
                <Col span={18}>
                    <Text style={{ fontSize: "11px" }}>{product.orderId}</Text>
                    <Paragraph style={{ color: "white", fontSize: "10px", marginBottom: "0px" }} ellipsis={{ "rows": 2, "expandable": false }}>
                        {product.productName}
                    </Paragraph>
                    <Text style={{ fontSize: "11px" }}>{product.price} {product.currency} | {product.quantity} Quantity</Text>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const OrdersCards = ({ order }) => {
    return (
        <Row style={{ backgroundColor: "#313841", padding: "12px", borderRadius: "4px" }}>

            <OrderscardsHeader order={order} />
            <hr style={{ borderColor: "#59CAAA", borderStyle: "dotted", borderLeft: "none", borderRight: "none", borderBottom: "none" }} />
            <Collapse defaultActiveKey={['1']}
                expandIconPosition="right"
                expandIcon={({ isActive }) => {
                    return isActive ? <Icon type="minus" /> : <Icon type="plus" />
                }}
            >
                <Panel style={{ backgroundColor: "#313841" }} header={<TotalBill totalbill={order.totalBilling} mod={order.paymentMode} />} key="2">
                    {
                        order.unitOrderList.map((item, index) => {
                            return <OrdersProducts key={index} product={item} />
                        })
                    }
                </Panel>
            </Collapse>
        </Row>
    )
}

export default function Orders({ username }) {

    const [orders, setOrders] = useState([]);
    const [isAvailable , setIsAvailable] = useState(false);
    const [loadMoreLoading , setLoadMoreLoading] = useState(false);
    const [page , setPage] = useState(1);

   const fetchOrders = (force_page = null , force_init_order = null) => {
        const data = {
            page : force_page || page ,
            username
        }
        axios.post(`${serverDealsHubUrl}/dealshub/fetch-customer-orders/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`

            }
        }).then(response => {
            if (response.data.status == 200) {
                setOrders([...(force_init_order || orders) , ...response.data.orderList]);
                setIsAvailable(response.data.is_available);
                setLoadMoreLoading(false);
            }
            else {
                message.error("Internal Server Error!");
            }
        });
    }
 

    useEffect(() => {
        fetchOrders(1 , []);
        setPage(1);
        setOrders([]);
        setLoadMoreLoading(false);
        setIsAvailable(false);
    }, [username])

    return (
        <React.Fragment>
            <Row gutter={[8, 8]} style={{ overflow: "scroll", maxHeight: "43vh" }} type="flex">
                {
                    orders.map((order, index) => {
                        return (
                            <Col span={12} key={index}>
                                <OrdersCards order={order} />
                            </Col>
                        )
                    })
                }

                {
                    isAvailable &&
                    <Col span = {24}>
                    <Row type = "flex" justify = "center" align = "middle">
                        <Col>
                            <Button size = "small" style = {{fontSize : "10px"}} onClick = {() => {
                                setLoadMoreLoading(true);
                                setPage(page + 1);
                                fetchOrders(page+1);
                            }} type = "primary" loading = {loadMoreLoading}>{loadMoreLoading ? "" : "Load More"}</Button>
                        </Col>
                    </Row>
                    </Col>
                }

                {
                    orders.length == 0 &&
                    <Col span={24}>
                        <Text style={{ fontSize: "11px" }}>NO ORDERS</Text>
                    </Col>
                }

            </Row>
        </React.Fragment>
    )
}