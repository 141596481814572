import React, { Component } from "react";
import { Card, Divider, Row, Col, Button, Modal , message } from "antd";
import "./cards.scss";
import ViewProductModal from "../modals/viewProductsModal/ViewProductModal";
import { Redirect } from "react-router";

import isDev from "../../../server";
const serverUrl = isDev();

class ExportCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      download: false,
      file: "",
      downloadModal: false,
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  showModalDownload = () => {
    this.setState({
      downloadModal: true,
    });
  };

  handleOkDownload = (e) => {
    this.setState({
      downloadModal: false,
    });
  };

  handleCancelDownload = (e) => {
    this.setState({
      downloadModal: false,
    });
  };

  handelDownload = (e) => {
    const bodyData = {
      export_pk: this.props.data.pk,
      export_format: this.props.data.channel_name,
    };
    fetch(`${serverUrl}/download-export-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 200) {
          this.setState({
            download: true,
            file: `${serverUrl}` + data.file_path,
          });
          window.open(`${serverUrl}` + data.file_path);
        }
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
      });
  };

  render() {
    const {
      title,
      created_date,
      product_count,
      channel_name,
    } = this.props.data;

    return (
      <React.Fragment>
        <Card
          title={title}
          extra={created_date}
          bordered={true}
          className="export-card"
        >
          <Divider style={{ margin: "0px" }} />

          <Row style={{ padding: "10px 0px 0px 0px" }}>
            <Col span={12}>Product:</Col>
            <Col className="text-color3 " span={12}>
              {product_count}
            </Col>
            <Col span={12}>Channel Name:</Col>
            <Col className="text-color3 " span={12}>
              {channel_name}
            </Col>
          </Row>

          <Row
            style={{ marginTop: "10px" }}
            gutter={[8, 8]}
          >
            <Col sm={12}>
              <Button type="default" style={{ width: "100%" }} onClick={this.showModal}>
                View Product
            </Button>
            </Col>

            <Col sm={12}>
              <Button
                type="primary"
                onClick={this.handelDownload}
                icon="download"
                style={{ width: "100%" }}
              >
                Download
            </Button>
            </Col>


          </Row>
        </Card>
        <Modal
          title="Products"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width="82vw"
          height="80vh"
          className="export-card-modal"
        >
          <ViewProductModal
            reRenderProductCount={this.props.reRenderProductCount}
            data={this.props.data}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default ExportCards;
