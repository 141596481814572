export function utils_copy(to, from) {
    let key_array = Object.keys(to);
    for (let i = 0; i < key_array.length; i++) {
        if (typeof to[key_array[i]] == "object" && key_array[i] != "product_attribute_list" && key_array[i] != "special_features") {
            if (from[key_array[i]])
                to[key_array[i]] = utils_copy(to[key_array[i]], from[key_array[i]]);
            else
                to[key_array[i]] = {}
        }
        else {
            if (from[key_array[i]])
                to[key_array[i]] = from[key_array[i]];
            else
                to[key_array[i]] = ""
        }

    }
    return to;
}
