import React, { Component } from "react";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { EditProductPageWrapper, EbppCol } from "./styled-components-library";
import {
  Row,
  Col,
  Button,
  Affix,
  Alert,
  Popconfirm,
  Icon,
  message,
  Tooltip
} from "antd";

import InputField from "../components/datainputs/InputField";
// import UploadImage from "../components/datainputs/upload-image/UploadImage";
import ImageManagerCreateBaseProduct from "../components/datainputs/image-manager/ImageManagerCreateBaseProduct";
import FormHeader from "../components/datainputs/FormHeader";
import InputNumberDimensions3 from "../components/datainputs/InputNumberDimesions3";
import "./edit-base-product-page.scss";
import VarientsInfoVital from "./vitali-info/VarientsInfoVital";
import VarientsInfoAttributes from "./vitali-info/VarientsInfoAttributes";
// import VarientsInfoDimensions from "./vitali-info/VarientsInfoDimensions";
import VarientsInfoPFL from "./vitali-info/VarientsInfoPFL";
import VarientsforFaq from "./vitali-info/VarientsforFaq";
// import EditorBox from '../components/EditorBox';
import { setEmpty, saveBaseProductDetails } from "../../actions/index";

//validator
import SimpleReactValidator from "simple-react-validator";

import { connect } from "react-redux";
import BaseForm from "./BaseForm";
import { Redirect } from "react-router";

import isDev from "../../server";
import EditorBox from "../components/EditorBox";
import DealshubProductVarientSection from "./vitali-info/DealshubProductVarientSection";
const serverUrl = isDev();

class CreateNewProduct extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Field is required",
        integer: "Must be number",
      },
      validators: {
        barcodestring: {
          // name the rule
          message: "value must be a number of length[10 | 12 | 13]",
          rule: (val, params, validator) => {
            if (isNaN(val)) return false;

            let lent = val.length;
            if (lent == 10 || lent == 12 || lent == 13) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: false, // optional
        },
        productIdEAN: {
          message: "value must be of length 13",
          rule: (val, params, validator) => {

            let lent = val.length;
            if (lent == 13) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: true, // optional
        },
        productIdASIN: {
          message: "value must be of length 10",
          rule: (val, params, validator) => {

            let lent = val.length;
            if (lent == 10) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: true, // optional
        },
        productIdUPC: {
          message: "value must be of length 12",
          rule: (val, params, validator) => {

            let lent = val.length;
            if (lent == 12) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)),
          required: true, // optional
        },
      },
    });
    this.state = {
      fields: [],
      refresh: false,
      brandList: null,
      saved: true,
      imagesdata: null,
      product_description: "",
      loading: false,
      categories: null,
      super_categories : null,
      category: "",
      subCategory: "",
      stepdataList:[],
      faqList:[],
    };
    this.getBrandName=this.getBrandName.bind(this);
  }

  setFields = data => {
    this.setState({ fields: data });
  };

  setsteplist = (stepdata) => {
    this.setState({ stepdataList: stepdata });
  };
  setfaqlist = (faqdata) => {
    this.setState({ faqList: faqdata });
  };
  

  updateImageList = dict => {
    this.setState({ imagesdata: dict });
  };


  fetchCategories = () => {
    let data = {

    };
    fetch(`${serverUrl}/fetch-all-categories/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status === 500)
          message.error("Internal Server Error!");
          else if(data.status === 405)
          {
            message.info("You are not authorised!")
          }
        else {
          this.setState({
            super_categories: data.super_category_list
          })
        }

      });
  }



  componentDidMount = () => {
    this.props.drawerOff();
    this.props.setEmpty();
    this.fetchCategories();



    fetch(`${serverUrl}/fetch-brands/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ brandList: data.brand_list });
      });
  };

  getEditorText = value => {
    this.setState({ product_description: value });
  };

  handelSave = () => {
    if (!this.validator.allValid()) {
      message.info("Fill Required Details!");
    } else {


      if (this.state.imagesdata) this.setState({ loading: true });

      this.props.saveBaseProductDetails(
        this.props.productDetails,
        this.state.fields,
        this.state.product_description,
        this.state.imagesdata,
        this.state.super_categories[this.state.superCategory].category_list[this.state.category],
        this.state.super_categories[this.state.superCategory].category_list[this.state.category].sub_category_list[this.state.subCategory],
        this.state.stepdataList,
        this.state.faqList,
      );
    }
  };

  error = status => {
    if (status == 500) message.error("Internal Server Error!");
    else if (status == 409) message.warning("Duplicate Product Detected!");
    else if (status == 403) message.error("access denied!");
    else if (this.state.saved) {
      message.success("Product Created!");
      if (!this.state.imagesdata)
        this.setState({ saved: false, refresh: true });
      else this.setState({ saved: false });
    }
  };

  getSuperCategory = (e) => {
    this.setState({
      superCategory : e
    })
  }

  getCategory = (e) => {
    this.setState({
      category: e
    })
  }

  getSubCategory = (e) => {
    this.setState({
      subCategory: e
    })
  }



  componentWillReceiveProps = props => {

    if (props.productDetails.status !== "") {
      this.error(props.productDetails.status);
      if (this.state.imagesdata && props.productDetails.status == "reload") {
        this.setState({ refresh: true, loading: false });
      }
    }
  };

//   function for  Brandname to add brandname into the list
    getBrandName(brand_name){
      // event.preventDefault();
      let brandList={...this.state.brandList};
      // brandList.push(brand_name)
      this.setState({
      brandList : brandList
      })
    }

  render() {
    if (this.state.refresh && this.props.productDetails.product_pk) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return (
        <Redirect
          to={`/edit-product/${this.props.productDetails.product_pk}`}
        ></Redirect>
      );
    }
  

    return (
      <React.Fragment>
        <EditProductPageWrapper>
          <TitleWithBreadCrumb
            title="Create Product"
            breadcrumb={["OmnyComm", "Products", "Create Base Product"]}
          />

          <Row gutter={[40, 40]}>
            <Col className="gutter-row" lg={12} sm={24}>
              {this.props.productDetails && (
                <BaseForm
                  brandList={this.state.brandList || []}
                  validator={this.validator}
                  productDetails={this.props.productDetails}
                  super_categories={this.state.super_categories || []}
                  getCategory={this.getCategory}
                  getSubCategory={this.getSubCategory}
                  getSuperCategory = {this.getSuperCategory}
                  getBrandName={this.getBrandName}
                  // function passed .
                />
              )}
            </Col>

            <Col className="gutter-row" lg={12} sm={24}>
              <Row gutter={[40, 40]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box">
                    <Col>
                      <ImageManagerCreateBaseProduct
                        updateImageList={this.updateImageList}
                        loading={this.state.loading}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoVital
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>


                

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <DealshubProductVarientSection
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                {/* <Col span={24}>
                                    <Row gutter={[24, 24]} className="gutter-box">
                                        <Col>
                                            <VarientsInfoDimensions />
                                        </Col>
                                    </Row>
                                </Col> */}
                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoAttributes
                          validator={this.validator}
                          productDetails={this.props.productDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoPFL
                          validator={this.validator}
                          sendList={this.setFields}
                          productDetails={this.props.productDetails}
                          name = "Features: Variant"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                  {/* // HOW TO USE :varient. */}
                  <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsInfoPFL
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          sendList={this.setsteplist}
                          productDetails={this.props.productDetails}
                          name = "How to Use: Variant"
                          buttontitle="Add Steps"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                  {/* FOR fAQ Section testing */}
                  <Col span={24}>
                  <Row gutter={[24, 24]} className="gutter-box DarkerTheme">
                    <Col>
                      {this.props.productDetails && (
                        <VarientsforFaq
                          disabled={this.props.drawer || this.props.productDetails.locked}
                          validator={this.validator}
                          sendList={this.setfaqlist}
                          productDetails={this.props.productDetails}
                          name = "FAQS: Variant"
                          buttontitle="Add FAQs"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>


                <Col className="gutter-row" span={24}>
                  <Row gutter={[24, 24]} className="gutter-box">
                    <Col>
                      <Row
                        gutter={[24, 24]}
                        style={{ margin: "0px", minHeight: "230px" }}
                      >
                        <Col style={{ padding: "0px" }} sm={24}>
                          <FormHeader header="Product Description" />
                        </Col>
                        <Col sm={24} className="editorBox-container">
                          <EditorBox
                            value={this.state.product_description}
                            getText={this.getEditorText}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="gutter-row save-btn-edit-product-page" sm={24}>
              {/* <Row type="flex" justify="center" align="middle">
                                <Button onClick={this.handelSave} type="primary"> Save </Button>
                            </Row> */}
              <Tooltip placement="top" title="Save">
                <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
                  <Button
                    type="danger"
                    shape="circle"
                    icon="save"
                    size="large"
                    onClick={this.handelSave}
                  ></Button>
                </Affix>
              </Tooltip>
            </Col>
          </Row>
        </EditProductPageWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails
  };
};

export default connect(mapStateToProps, { setEmpty, saveBaseProductDetails })(
  CreateNewProduct
);
