import React from 'react'
import {Col,Row,Typography} from 'antd'

const {Title} = Typography

const ProgressBar = ({current}) => {
    return (
        <Row>
            <Col span={4}>
                <Row className="flex-center" style={{justifyContent:"left"}}>
                    <div className="flex-center" style={{width:"30px",height:"30px",borderRadius:"50%", color: "white",backgroundColor: "#1abc9c" }}>1</div>
                    <div style={{width:"30px",backgroundColor:current === 1 ? "#1abc9c" : "white", margin:"0 5px",borderBottom:`1px solid ${ current === 1 ? "#1abc9c" : "white"}`}}></div>
                    <div className="flex-center" style={{width:"30px",height:"30px",borderRadius:"50%",color:current === 1 ? "white" : "#1abc9c",backgroundColor: current === 1 ? "#1abc9c" : "white" }}>2</div>
                </Row>
                <Row style={{marginTop:"20px"}} className="flex-center">
                    <Title level={4} style={{color:"white"}}>{current === 1 ? "Add Order" : "Add Customer"}</Title>
                </Row>
            </Col>
        </Row>
    )
}

export default ProgressBar