import React, { useState, useEffect } from "react";
import { Typography, Input, Icon, message } from "antd";
import {
    handlePromoSectionPriceChange,
    handlePromoBannerPriceChange
} from "../../../actions/dealsHubAdmin";
import { connect } from "react-redux";
import isDev from "../../../server";
const serverUrl = isDev();

function EditableProductAttributes(props) {
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(props.value);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);

    let Ref = React.createRef();

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const saveData = () => {
        let data = {
            [props.name]: inputValue,
            product_uuid: props.uuid,
            locationGroupUuid: props.locationGroupUuid
        };
        setLoading(true);
        fetch(`${serverUrl}/update-dealshub-product/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setIsEdit(false);
                if (data.status == 200) {
                    setValue(inputValue);
                    message.success('Promo Price Applied Successfully!');
                    if (props.isSection) {
                        console.log(props);
                        props.handlePromoSectionPriceChange(
                            props.sectionIndex,
                            props.productIndex,
                            inputValue);
                    } else if (props.isBanner) {
                        console.log(props);
                        let unitBannerIndex = 0,
                            bannerImages = props.dealsHubAdmin[props.bannerIndex].bannerImages;

                        for (let index = 0; index < bannerImages.length; ++index) {
                            if (bannerImages[index].uid === props.unitBannerUuid) {
                                unitBannerIndex = index;
                                break;
                            }
                        }

                        props.handlePromoBannerPriceChange(
                            props.bannerIndex,
                            unitBannerIndex,
                            props.productIndex,
                            inputValue);
                    }
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else message.error("Something went wrong during update");
            });
    };

    useEffect(() => {
        if (isEdit) {
            Ref.current.focus();
        }
    }, [isEdit]);

    return (
        <React.Fragment>
            {isEdit ? (
                <React.Fragment>
                    {loading ? (
                        <Icon type="loading" />
                    ) : (
                            <Input
                                ref={Ref}
                                size="small"
                                style={{ width: "40%" }}
                                value={inputValue}
                                maxLength={7}
                                onPressEnter={saveData}
                                onChange={(e) => setInputValue(e.target.value)}
                                suffix={<Icon
                                    type="check-circle"
                                    style={{ color: "#59CAAA" }}
                                    onClick={saveData}
                                />}
                            />
                        )}
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        <Typography.Text
                            onClick={() => {
                                setIsEdit(true);
                                setInputValue(value);
                            }}
                        >{value}</Typography.Text>{" "}
                        <Icon
                            style={{
                                color: "#3CC9A9",
                                fontSize: "10px"
                            }}
                            type="edit" onClick={() => {
                                setIsEdit(true);
                                setInputValue(value);
                            }}
                        />
                        {/* <br/>
                        {
                            value == "0" && (
                                <Typography.Text type="warning" style={{fontSize: 10}}>
                                    Promo price is 0
                                </Typography.Text>
                            )
                        } */}
                    </React.Fragment>
                )}
        </React.Fragment>
    );
}

const mapStateToProps = ({ dealsHubAdmin }) => {
    return {
        dealsHubAdmin
    };
};

export default connect(mapStateToProps, {
    handlePromoSectionPriceChange,
    handlePromoBannerPriceChange
})(EditableProductAttributes);