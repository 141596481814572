import React, { Component } from "react";
import {
  TableContainer,
  TableHead,
  TableHeadText,
  WhSelect,
  PriceSelect,
  WhOption,
  PriceOption,
  TableRow,
  TableRowText,
  TableRowCollapse,
} from "../components/listtable/styled-components-library";
import axios from "axios";
import { connect } from "react-redux";
import {
  fetchProductList,
  addToExportList,
  removeFromExportList,
  fetchProductListMore,
  handleFilterChange,
  fetchProductsByTags
} from "../../actions/dealsHubProducts";

import {
  Row,
  Col,
  Avatar,
  Collapse,
  Checkbox,
  Tag,
  Icon,
  Tooltip,
  Button,
  Input,
  Switch,
  Upload,
  message,
  Typography,
  Dropdown,
  Menu,
  Empty
} from "antd";

import "../components/listtable/listtable.scss";
import { Link } from "react-router-dom";
import styled from "styled-components";
import isDev from "../../server";
import ProductListCrudItems from "./ProductListCrudItems";
import ProductListToggle from "./ProductListToggle";
import StockHoldingModal from "views/components/modals/stock-holding-modal/StockHoldingModal";
const serverUrl = isDev();

const { Panel } = Collapse;

const UploadBtn = styled(Upload)`
 && {
    .ant-upload {
        height:15px !important;
        width: 100% !important;
        display : inline !important;
        margin-left : 1px !important;
    }
    span {
      width : 100% !important;
    }
  }`

class ListTableDealshubproducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: {},
      loadingInLoadMore: false,
      loadingPrice: false,
      loadingStocks: false,
      selectAll: false,
      stockModal: false,
    };
  }

  handleLoadMore = () => {
    this.setState({ loadingInLoadMore: true });
    this.props.fetchProductListMore(this.props.tags, this.props.dealsHubProducts.dealsHubProduct.filters, this.props.locationUuid, () => {
      this.setState({ loadingInLoadMore: false })
    });
  };


  componentDidMount = () => {
    this.props.fetchProductList([], this.props.locationUuid);
    this.setState({
      locationUuid: this.props.locationUuid
    })
  };

  componentDidUpdate = (prevState, prevProps) => {
    if (prevState.locationUuid != this.state.locationUuid)
      this.props.fetchProductList([], this.props.locationUuid);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.locationUuid != prevState.locationUuid) {
      return {
        locationUuid: nextProps.locationUuid
      }
    }

    return null
  }

  componentWillReceiveProps = () => {
    this.setState({ loadingInLoadMore: false });
  };

  handelCheck = (e) => {
    var x = this.state.checked[e];
    if (!x) {
      this.setState({
        checked: { ...this.state.checked, [e]: true },
      }, () => {
        this.props.sendList(Object.keys(this.state.checked));
      });
    } else {
      let prev = this.state.checked;
      delete prev[e];
      this.setState({
        checked: prev,
      }, () => {
        this.props.sendList(Object.keys(this.state.checked));
      });
    }
  };

  handleSelectAll = () => {

    let arr = this.props.dealsHubProducts.dealsHubProduct.products.products;

    let checked = {};

    if (!this.state.selectAll)
      for (let i = 0; i < arr.length; i++) {
        checked[arr[i].product_pk] = true;
      }

    this.setState((prev) => {
      return {
        checked,
        selectAll: !prev.selectAll
      }
    }, () => {
      this.props.sendList(Object.keys(this.state.checked));
    })
  }

  uploadFilePrices = (file) => {
    let formData = new FormData();
    formData.append("import_file", file);
    this.setState({ loadingPrice: true });
    axios
      .post(`${serverUrl}/bulk-update-dealshub-product-price/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("token")} `
        }
      })
      .then(res => {
        this.setState({ loadingPrice: false });
        if (res.data.status === 200) {
          message.success("Success!")
        }
        else if (res.data.status === 405) {
          message.info("You are not authorised!")
        }
        else {
          message.error("Internal Server Error!");
        }
        this.setState({ loading: false, fileList: [] });
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error("Internal Server Error!");
      });
  }

  uploadFileStocks = (file) => {
    let formData = new FormData();
    formData.append("import_file", file);
    this.setState({ loadingStocks: true });
    axios
      .post(`${serverUrl}/bulk-update-dealshub-product-stock/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("token")} `
        }
      })
      .then(res => {
        this.setState({ loadingStocks: false });
        if (res.data.status === 200) {
          message.success("Success!")
        }
        else if (res.data.status === 405) {
          message.info("You are not authorised!")
        }
        else {
          message.error("Internal Server Error!");
        }
        this.setState({ loading: false, fileList: [] });
      })
      .catch(err => {
        this.setState({ loading: false });
        message.error("Internal Server Error!");
      });
  }

  handleSelectFilePrices = (file) => {
    this.uploadFilePrices(file);
  }

  handleSelectFileStocks = (file) => {
    this.uploadFileStocks(file);
  }

  sort = (val) => {
    this.props.handleFilterChange({
      ...this.props.dealsHubProducts.dealsHubProduct.filters,
      "price_sort": val
    });

    this.props.fetchProductsByTags(null, {
      ...this.props.dealsHubProducts.dealsHubProduct.filters,
      "price_sort": val
    }, null, this.props.locationUuid)
  }



  collapseHeader = (e, a, b) => {
    return (
      <TableRow type="flex" align="middle" gutter={[8, 4]} key={e.product_id}>
        <Col span={1}>
          <Checkbox onChange={() => this.handelCheck(e.product_uuid)} checked={this.state.checked[e.product_uuid]}></Checkbox>
        </Col>
        <Col span={2}>
          <Row gutter={[8, 8]} style={{ width: "80%" }}>
            <Col span={24}>
              <Avatar shape="square" size={68} src={e.repr_image_url} />
            </Col>
          </Row>
        </Col>

        <Col span={5}>
          <Row>
            <Tooltip placement="topLeft" title={e.product_name || ""}>
              <TableRowText
                style={{ color: "white", paddingRight: "20px" }}
                className="text-ellipsis"
              >
                {e?.product_name}
              </TableRowText>
            </Tooltip>

            <Typography.Paragraph ellipsis={{ rows: 1, expandable: false }}>{e?.seller_sku} {(e.product_id && e.product_id != "") && (e.seller_sku && e.seller_sku != "") && " | "} {e?.product_id}</Typography.Paragraph>
          </Row>
        </Col>
        <Col span={3}>
          <TableRowText>{e.brand_name || "NA"}</TableRowText>
        </Col>
        <Col span={4}>
          <Row>
            <Typography.Paragraph ellipsis={{ rows: 1, expandable: false }} style={{ marginBottom: "0px" }} >{e.category || "NA"}</Typography.Paragraph>
            <Typography.Paragraph ellipsis={{ rows: 1, expandable: false }} style={{ marginBottom: "0px" }}>{e.sub_category || "NA"}</Typography.Paragraph>
          </Row>
        </Col>
        
        <Col xl={2} lg={3} md={3} style={{ paddingLeft: "10px" }}>
          {a ?



            <React.Fragment>
              <ProductListCrudItems
                value={e.now_price}
                type="float"
                name="now_price"
                editOn={() => { }}
                url="update-dealshub-product/"
                request_data={{
                  "product_uuid": e.product_uuid
                }}
                locationUuid={this.props.locationUuid}
              />
              <br />
              <ProductListCrudItems
                value={e.was_price}
                name="was_price"
                type="float"
                line_through
                editOn={() => { }}
                url="update-dealshub-product/"
                request_data={{
                  "product_uuid": e.product_uuid
                }}
                locationUuid={this.props.locationUuid}
              />
            </React.Fragment>
            :

            <React.Fragment>
              <TableRowText>{e.now_price}</TableRowText>
              <TableRowText>{e.was_price}</TableRowText>
            </React.Fragment>
          }

        </Col>

        <Col span={2}>

          {b ?

            <ProductListCrudItems
              value={e.stock}
              name="stock"
              type="int"
              url="update-dealshub-product/"
              editOn={() => { }}
              request_data={{
                "product_uuid": e.product_uuid
              }}
              locationUuid={this.props.locationUuid}
            />

            :
            <TableRowText>{e.stock}</TableRowText>

          }

        </Col>


        <Col span={1} style={{ paddingLeft: "10px" }}>
          <ProductListToggle uuid={e.product_uuid} checkedText="" uncheckedText="" cod checked={e.is_cod_allowed} locationUuid={this.props.locationUuid} />
        </Col>


        <Col xl={4} lg={3} md={3}>
          <Row style={{ paddingLeft: "20px" }} gutter={[12, 12]} type="flex" align="middle" justify="space-arround">

            <Col>
              <ProductListToggle uuid={e.product_uuid} checkedText="" uncheckedText="" checked={e.channel_status} locationUuid={this.props.locationUuid} />
            </Col>
            {
              this.props.canViewHoldings &&
                (this.props.siteInfo === "wigme - uae" || this.props.siteInfo === "wigme - b2b" || this.props.siteInfo === "wigme - dubai" || this.props.siteInfo === "wigme - kwt" || this.props.siteInfo === "wigme - bah") ?
                (
                  <Col style={{ display: 'flex', alignItems: "center" }}>
                    <Col style={{ display: 'flex', alignItems: "center" }}>
                      <Link to={`/edit-ecommerce-product/${e.product_uuid}/${this.props.locationUuid}${this.props.b2b ? '/b2b' : ''}`}
                        style={{ fontSize: 'inherit', display: "flex" }}
                      >
                        <Tooltip title="Edit Product">
                          <Icon
                            type="form"
                            style={{ fontSize: "1.3em", color: "grey", marginRight: "10px" }}
                          />
                        </Tooltip>
                      </Link>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: "center" }}>
                      {/**Stock Managment */}
                      <Tooltip title={`Stock Management`}>
                        <Icon
                          type="control"
                          onClick={() => this.setState({ stockModal: true, productUuid: e.product_uuid })}
                          style={{ fontSize: "1.3em", color: "grey", marginRight: "10px" }}
                        />
                      </Tooltip>
                    </Col>
                  </Col>
                )
                : (
                  <Col style={{ display: 'flex', alignItems: "center" }}>
                    <Link to={`/edit-ecommerce-product/${e.product_uuid}/${this.props.locationUuid}${this.props.b2b ? '/b2b' : ''}`}
                      style={{ fontSize: 'inherit', display: "flex" }}
                    >
                      <Tooltip title={`Edit Product`}>
                        <Icon
                          type="form"
                          style={{ fontSize: "1.3em", color: "grey", marginRight: "10px" }}
                        />
                      </Tooltip>
                    </Link>
                  </Col>
                )
            }

          </Row>
        </Col>


      </TableRow>
    )
  };

  render() {

    return (
      <React.Fragment>
        <TableContainer type="flex" align="middle" className="table-container">
          <TableHead type="flex" align="middle" className="table-header">
            <Col span={1}>
              <Checkbox onClick={this.handleSelectAll} checked={this.state.selectAll}></Checkbox>
            </Col>
            <Col span={2}>
              <TableHeadText>Images</TableHeadText>
            </Col>
            <Col span={5}>
              <TableHeadText>Product ID</TableHeadText>
            </Col>
            <Col span={3}>
              <TableHeadText>Brand</TableHeadText>
            </Col>
            <Col span={4}>
              <TableHeadText>Domain</TableHeadText>
            </Col>

            <Col xl={2} lg={3} md={3}>
              <TableHeadText>Price
                <Dropdown overlay={
                  <Menu>
                    <Menu.Item key="0" onClick={() => this.sort(undefined)}>
                      {this.props.dealsHubProducts.dealsHubProduct.filters.price_sort === undefined && < Icon type="check" />} All
                    </Menu.Item>
                    <Menu.Item key="1" onClick={() => this.sort("low")}>
                      {this.props.dealsHubProducts.dealsHubProduct.filters.price_sort === "low" && < Icon type="check" />} Low to High
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => this.sort("high")}>
                      {this.props.dealsHubProducts.dealsHubProduct.filters.price_sort === "high" && < Icon type="check" />} High to Low
                    </Menu.Item>
                  </Menu>
                } trigger={['click']}>
                  <Icon type="ordered-list" style={{ marginLeft: "5px", curser: "pointer", color: "#3CC9A9" }} />
                </Dropdown>
                {/* <UploadBtn style={{ width: "100%", marginLeft: "5px" }} beforeUpload={this.handleSelectFilePrices}>
                  < Icon type={this.state.loadingPrice ? "loading" : "upload"}
                    style={{ cursor: "pointer" }}
                  />
                </UploadBtn> */}
              </TableHeadText>

            </Col>

            <Col span={2}>
              <TableHeadText>Stock
                {/* <UploadBtn style={{ width: "100%", marginLeft: "5px" }} beforeUpload={this.handleSelectFileStocks}>
                  < Icon type={this.state.loadingStocks ? "loading" : "upload"}
                    style={{ cursor: "pointer" }}
                  />
                </UploadBtn> */}
              </TableHeadText>

            </Col>

            {/* <Col span={3}>
              <TableHeadText>Min Price</TableHeadText>
              <br />
              <TableHeadText>Max Price</TableHeadText>
            </Col> */}

            {/* <Col span={2}>
              <TableHeadText>Ch. Status</TableHeadText>
            </Col> */}
            <Col span={1} >
              <TableHeadText>COD</TableHeadText>
            </Col>
            <Col xl={4} lg={3} md={3} style={{ paddingLeft: "20px" }}>
              <TableHeadText>Publish</TableHeadText>
            </Col>
          </TableHead>

          {/* {this.props.channelwiseState.products.map(e => {
                    return (
                        <TableRowCollapse key={e.base_product_pk}>
                            <Panel key="1" showArrow={false} extra={this.collapseHeader(e)}>
                                {//.log(e, "E FROM LISTTABLE")}


                            </Panel>
                        </TableRowCollapse>
                    )
                })} */}
        </TableContainer>

        <div className="list-table-dealshub-products-content">
          {this.props.dealsHubProducts.dealsHubProduct.products.products &&
            this.props.dealsHubProducts.dealsHubProduct.products.products.map(
              (e) => {
                return (
                  <TableRowCollapse key={e.base_product_pk}>
                    <Panel
                      key="1"
                      showArrow={false}
                      extra={this.collapseHeader(e, this.props.dealsHubProducts.dealsHubProduct.products.dealshub_price_permission,
                        this.props.dealsHubProducts.dealsHubProduct.products.dealshub_stock_permission)}
                    ></Panel>
                  </TableRowCollapse>
                );
              }
            )}
          {this.props.dealsHubProducts.dealsHubProduct.products.products &&
            this.props.dealsHubProducts.dealsHubProduct.products.products.length == 0 &&
            <Row>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          }
          {this.props.dealsHubProducts &&
            this.props.dealsHubProducts.dealsHubProduct.products
              .is_available && (
              <div className="center-child" style={{ width: "100%" }}>
                <Button
                  type="primary"
                  loading={this.state.loadingInLoadMore}
                  onClick={this.handleLoadMore}
                >
                  {this.state.loadingInLoadMore ? "" : "Load More..."}
                </Button>
              </div>
            )}
        </div>

        {/* {this.props.productData && this.props.productData.is_available && (
          <div className="center-child" style={{ width: "100%" }}>
            <Button
              type="primary"
              loading={this.state.loadingInLoadMore}
              onClick={this.handleLoadMore}
            >
              {this.state.loadingInLoadMore ? "" : "Load More..."}
            </Button>
          </div>
        )} */}
        <StockHoldingModal
          isVisible={this.state.stockModal}
          setVisible={(val) => this.setState({ stockModal: val })}
          productUuid={this.state.productUuid}
          locationUuid={this.props.locationUuid}
          tags={this.props.tags}
          filters={this.props.dealsHubProducts.dealsHubProduct.filters}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ dealsHubProducts, user }) => {
  return {
    dealsHubProducts,
    ...user
  };
};

export default connect(mapStateToProps, {
  fetchProductList,
  addToExportList,
  removeFromExportList,
  fetchProductListMore,
  handleFilterChange,
  fetchProductsByTags
})(ListTableDealshubproducts);
