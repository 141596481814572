import React, { Component } from "react";
import {
  Card,
  Divider,
  Row,
  Col,
  Button,
  Modal,
  Collapse,
  Icon,
  Avatar,
  Typography,
} from "antd";

import "./bundle-pi-page.scss";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";

import BulkPiCard from "../components/bulk-pi-card/BulkPiCard";

const { Text } = Typography;

export default class BundlePiPage extends Component {
  render() {
    return (
      <Row className="bundle-pi-page-container">
        <TitleWithBreadCrumb
          title="Bundle Pi"
          breadcrumb={["OmnyComm", "Bundle Pi"]}
        />
        <Col span={24} className="bundle-pi-container">
          <Row className="" gutter={[16, 16]}>
            <Col sm={24} lg={8} md={12}>
              <BulkPiCard />
            </Col>
            <Col sm={24} lg={8} md={12}>
              <BulkPiCard />
            </Col>
            <Col sm={24} lg={8} md={12}>
              <BulkPiCard />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
