import React , {useState , useEffect} from "react";

import {Row , Col , Card , Avatar , Typography , Tag , Button , message} from "antd";

import NullImage from "media/placeholder3.png";
import isDev from "server";
import axios from "axios";

const serverUrl = isDev();
const {Text , Paragraph} = Typography;

function NotificationCard({notification_item , OpenDetails}){

    const [upload_loading , setUpload_loading] = useState(false);
    const [delete_loading , setDeleteLoading] = useState(false);
    const [notification , setNotification] = useState(notification_item);

    const deleteImage = () => {
        let data = {
            notification_id : notification.notification_id
        }
        setDeleteLoading(true);
        fetch(`${serverUrl}/sales-app/delete-notification-image/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
          })
            .then(res => res.json())
            .then(data => {
                setDeleteLoading(false);
                if(data.status == 200)
                {
                    message.success("Image is successfully deleted!");
                    setNotification({...notification , image : ""})
                }
                else if(data.status == 403 && data.message)
                {
                    message.info(data.message)
                }
                else
                {
                    message.error("Something went wrong!")
                }
            });
    }

    //  for main image
    const handleImageUpload = (e) => {
        setUpload_loading(true)
        let formData = new FormData();
        formData.append("notification_id", notification.notification_id);
        formData.append("image_url" , e.target.files[0]);

        let image_url_just_uploaded = URL.createObjectURL(e.target.files[0])

        axios
        .post(`${serverUrl}/sales-app/upload-notification-image/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        })
        .then(response => {
            setUpload_loading(false)

            switch(response.data.status){
                case 200:
                    message.success("Image Uploaded!");
                    setNotification({...notification , image : image_url_just_uploaded});
                    break;
                case 500:
                    message.error("Something went wrong!");
                    break;
                case 403:
                    message.info("You are not authorized!");
                    break;
                default:
                    message.error("Something went wrong");
            }
        });
      }

    const sendNotification = () => {
        let data = {
            notification_id : notification.notification_id
        }
        fetch(`${serverUrl}/sales-app/send-notification/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
          })
            .then(res => res.json())
            .then(data => {
              //   if(data.status == 200)
              //   {
              //       setNotificationList(data.notification_list)
              //   }
              //   else if(data.status == 403 && data.message)
              //   {
              //       message.info(data.message)
              //   }
              //   else
              //   {
              //       message.error("Something went wrong!")
              //   }
            });
    }


    return (
        <React.Fragment>
            <Card> 
                <Row gutter = {[12,12]}>
                    <Col span = {6}>
                        <Avatar shape = "square" style = {{width : "100%" , height : "100%"}} src = {notification.image == "" ? NullImage : notification.image}/>
                    </Col>  
                    <Col span = {18}>
                        <Text style = {{color : "white"}}>{notification.title}</Text><br/>
                        <Text style = {{color : "white" , fontSize : "10px"}}>{notification.subtitle}</Text>
                        <Paragraph ellipsis = {{rows : "3" , expandable : true}} >{notification.body}</Paragraph>
                    </Col>
                    <Col span = {24}>

                            <Row type = "flex" gutter = {[8,8]} align = "top">
                                <Col>
                                    <Tag color = "green" style = {{width : "100%"}}>{notification.notification_status}</Tag>
                                </Col>
                                    {
                                        notification.image == "" ? 
                                        <Col>
                                        <Button
                                        disabled= {upload_loading}
                                        icon = {upload_loading ? "loading" : "upload"}
                                        size = {"small"} type = "primary" ghost style = {{
                                            cursor : "pointer"
                                        }}
                                        >
                                            Upload Image
                                            <input onChange = {(e) => handleImageUpload(e)} type="file" style = {{
                                            position : "absolute",
                                            top : "0px",
                                            left : "0px",
                                            opacity : "0",
                                            width : "100%",
                                            cursor : "pointer"
                                        }} />
                                    </Button></Col> : 
                                    <Col>
                                        <Button
                                            disabled= {delete_loading}
                                            loading = {delete_loading}
                                            size = {"small"} type = "primary" ghost style = {{
                                                cursor : "pointer"
                                            }}
                                            onClick = {deleteImage}
                                            >
                                            Delete Image
                                    </Button> </Col>}

                                    <Col>
                                    <Button
                                            size = {"small"} type = "primary" ghost style = {{
                                                cursor : "pointer"
                                            }}
                                            onClick = {OpenDetails}
                                            >
                                            Details
                                    </Button>
                                    </Col>

                                    {
                                        notification.notification_status == "Pending" && 
                                        <Col>
                                        <Button
                                            size = {"small"} type = "primary" style = {{
                                                cursor : "pointer"
                                            }}
                                            onClick = {sendNotification}
                                            >
                                            Send
                                        </Button>
                                        </Col>
                                    }
                                    
                                    
                                </Row>

                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default NotificationCard;