import React, { Component, useState } from "react";
import { Input, Row, Col, Button, Icon, Typography } from "antd";
import "./attributes.scss";

const { Text } = Typography;
const { TextArea } = Input;
const Attributes = props => {


  const [fields, setFields] = useState(props.initialdata);

  function handleChange(i, event) {
    const values = [...fields];
    values[i] = event.target.value;
    setFields(values);
    props.sendList(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push("");
    setFields(values);
    props.sendList(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    props.sendList(values);
  }

  //on Submit Return value = [...fields]

  return (
    <div style={{ padding: "10px 20px 10px 20px" }}>
      {!props.disabled && (
        <Row type="flex" align="middle" style={{ marginBottom: "20px" }}>
          <Icon
            type="plus-circle"
            style={{
              color: "#1ABC9C",
              marginRight: "10px",
              fontSize: "1.5rem"
            }}
            onClick={() => handleAdd()}
          />
          <Text>{props.addbuttontitle ? props.addbuttontitle : "Add"}</Text>
        </Row>
      )}

      {fields &&
        fields.map((field, idx) => {
          return (
            <div key={`${idx}`}>
              <Row
                gutter={[24, 24]}
                type="flex"
                justify="space-arround"
                align="middle"
              >
                <Col sm={20}>
                  <TextArea
                    type="text"
                    placeholder="Enter text"
                    value={field || ""}
                    onChange={e => handleChange(idx, e)}
                    style={{ display: "inline", minHeight: "35px" }}
                    autoSize
                  />
                </Col>

                <Col sm={4}>
                  <Icon
                    type="minus-circle"
                    style={{ color: "#f1556c", fontSize: "1.5rem" }}
                    onClick={() => handleRemove(idx)}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
    </div>
  );
};

export default Attributes;
