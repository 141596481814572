import React, { useState } from 'react';
import {
    Row,
    Col,
    Button,
    Icon,
    Select,
    Modal,
    Input
} from "antd";
import OrdersAccountsManagerFilterModal from "../components/modals/orders-accounts-manager-filter-modal/OrdersAccountsManagerFilterModal";
import "./orders-account-manager.scss";

const { TextArea } = Input;

const OrdersAccountManagerHeaderTemplate = (props) => {
    return (
        <React.Fragment>
            <Row>
                <Col span={24} className="filter-row-container">
                    <Row gutter={[12, 0]} className="filter-row" type="flex">
                        <Col span={3}>
                            <Button
                                style={{ width: "100%" }}
                                type="primary"
                                loading={props.loading}
                                ghost
                            >
                                <React.Fragment>
                                    {props.loading ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                            <React.Fragment>Results: {props.totalResults}</React.Fragment>
                                        )}
                                </React.Fragment>
                            </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                style={{ width: "100%" }}
                                onClick={() => {
                                    props.setShippingMethod('WIG Fleet');
                                }}
                                type="secondary"
                                loading={props.processingWigShippingMethod}
                                disabled={props.someProcessingActive}
                                className="dispatched-btn"
                            >
                                WIG Fleet
                             </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                style={{ width: "100%" }}
                                onClick={() => {
                                    props.setShippingMethod('grand gaadi')
                                }}
                                loading={props.processingThirdPartyShippingMethod}
                                disabled={props.someProcessingActive}
                                type="primary"
                                className="delivered-btn"
                            >
                                Grand Gaadi
                            </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                style={{ width: "100%" }}
                                onClick={() => {
                                    props.setShippingMethod('Sendex');
                                }}
                                type="secondary"
                                loading={props.processingWigShippingMethod}
                                disabled={props.someProcessingActive}
                                className="dispatched-btn"
                            >
                                SENDEX
                             </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                style={{ width: "100%" }}
                                onClick={() => {
                                    props.setShippingMethod('Emirates Post');
                                }}
                                type="secondary"
                                loading={props.processingWigShippingMethod}
                                disabled={props.someProcessingActive}
                                className="dispatched-btn"
                            >
                                Emirates Post
                             </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                onClick={() => {
                                    props.toggleModalShow();
                                }}
                                disabled={props.someProcessingActive}
                                type="danger"
                                className="returned-btn"
                            >
                                Cancel Order
                    </Button>
                        </Col>
                        <Col span={9}>
                            <Select
                                dropdownClassName="search-chips"
                                dropdownMatchSelectWidth="false"
                                mode="tags"
                                style={{ width: "100%" }}
                                onChange={props.handleSearchChange}
                                placeholder={[<Icon type="search" />, " Search here..."]}
                            ></Select>
                        </Col>
                        <Col span={3}>
                            <OrdersAccountsManagerFilterModal
                                filterCount={props.filterCount}
                                applyFilters={props.applyFilters}
                                someProcessingActive={props.someProcessingActive}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                title="Type Cancellation Reason"
                visible={props.toggleShowlModal}
                onOk={props.handleCancelModalSubmit}
                onCancel={props.handleCancelModalClose}
                loading={!props.toggleShowlModal}
                destroyOnClose={true}
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
                width="50%"
            >
                <TextArea
                    defaultValue={props.cancellationReason}
                    placeholder='Describe cancellation reason using a minimum of 5 characters and maximum of 160 characters!'
                    maxLength={160}
                    onChange={props.handleCancelTextChange}
                />
            </Modal>
        </React.Fragment>
    );
}

export default OrdersAccountManagerHeaderTemplate;