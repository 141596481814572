import React, { useState, useEffect } from "react";
import axios from "axios";
import isDev from "server";
import AnalyticsCards from "views/sales-order-analytics/AnalyticsCard";
import { Col, Row, Typography, Spin, Tabs, Button } from "antd";
import DatePickerModal from "./DatePickerModal";

const { Text } = Typography;

const serverOmnycommUrl = isDev();

const EmployeeAnalytics = (props) => {
  const [data, setData] = useState(undefined);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [openModal, setOpenModal] = useState(false);

  const handleDatePickerChange = (e) => {
    if (e[0] && e[1]) {
      setDate({
        startDate: e[0].toDate(),
        endDate: e[1].toDate(),
      });
    } else {
      setDate({
        startDate: "",
        endDate: "",
      });
    }
  };
  const toggleOpenModalState = () => {
    setOpenModal((p) => !p);
  };
  let req = {
    locationGroupUuid: props.locationUuid,
  };
  const fetchSalesExecutiveAnalysis = () => {
    if (date.startDate && date.endDate) {
      req.fromDate = date.startDate;
      req.toDate = date.endDate;
    }
    axios
      .post(
        `${serverOmnycommUrl}/dealshub/fetch-sales-executive-analysis/`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("res.data: ", res.data);
        setData(res.data);
      });
  };

  useEffect(() => {
    fetchSalesExecutiveAnalysis();
  }, [date]);

  if (!data || data.length === 0) {
    return <Spin spinning={true} />;
  }
  return (
    <Row>
      <Row>
          <Button
            style={{
              backgroundColor: "#3cc9a9",
              border: "none",
              color: "#F1F1F1",
              left: "20px",
              marginTop:"20px",
              marginBottom: "10px",
            }}
            onClick={()=>setOpenModal(true)}
          >
            Select Duration
          </Button>
          {openModal?  <DatePickerModal
            openModal={openModal}
            toggleOpenModalState={toggleOpenModalState}
            handleDatePickerChange={handleDatePickerChange}
          />:null}
      </Row>
      <Row>
        {data.sales_target_list.length > 0 &&
          data.sales_target_list.map((item, index) => {
            return (
              <Row
                key={index}
                style={{
                  backgroundColor: "#3F4A56",
                  borderRadius: "4px",
                  padding: "24px",
                  margin: "12px",
                  width: "100%",
                  minWidth: "1190.66px",
                }}
              >
                <Col span={24} style={{ marginBottom: "18px" }}>
                  <Text
                    style={{
                      color: "#F1F1F1",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {item.first_name}
                  </Text>
                </Col>

                <Col span={24} style={{ marginBottom: "18px" }}>
                  <Tabs>
                    <Tabs.TabPane tab="Today" key="today">
                      {item.todays && (
                        <AnalyticsCards
                          target={item.targets}
                          data={item.todays}
                          currency={item.currency}
                          isToday={true}
                        />
                      )}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="This Month" key="month">
                      {item.monthly && (
                        <AnalyticsCards
                          target={item.targets}
                          data={item.monthly}
                          currency={item.currency}
                          isMonthly={true}
                        />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Custom Duration" key="date">
                      {item.dateFilter &&
                        Object.keys(item.dateFilter).length !== 0 && (
                          <AnalyticsCards
                            data={item.dateFilter}
                            currency={item.currency}
                            isDate={true}
                          />
                        )}
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
              
            );
          })}
      </Row>
    </Row>
  );
};
export default EmployeeAnalytics;
