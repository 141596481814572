import React, { Component } from "react";
import { Row, Col, Button, message, Empty, Collapse } from "antd";
import OrdersWarehouseManagerTableBodyTemplate from "./OrdersWarehouseManagerTableBodyTemplate";
import OrdersWarehouseManagerHeaderTemplate from "./OrdersWarehouseManagerHeaderTemplate";
import OrdersWarehouseManagerTableHeaderTemplate from "./OrdersWarehouseManagerTableHeaderTemplate";
import CollapsibleHeaderContent from "./CollapsibleHeaderContent";
import { Ellipsis } from "react-spinners-css";
import moment from "moment";
import { connect } from "react-redux";
import {
  setOrdersManagerDetails,
  loadMoreOrdersManagerDetails,
  setOrderStatus,
  checkOrderUuid,
  uncheckOrderUuid,
  unsetSelectedOrders,
} from "../../actions/ordersManager";
import styled from "styled-components";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import axios from "axios";
import isDev from "../../server";
import "./orders-warehouse-manager.scss";
import OrdersWarehouseManagerMobileView from "./OrdersWarehouseManagerMobileView";

const serverOmnycommUrl = isDev();
const orderStatues = [
  "approved",
  "picked",
  "dispatched",
  "delivered",
  "delivery failed",
];

const { Panel } = Collapse;
const TableRowCollapse = styled(Collapse)`
  width: 100%;
  border: none;
  margin-bottom: 10px;
`;
const BaseProductContent = styled(Row)`
  padding: 12px;
  width: 100%;
  border-top: 1px solid #495561;
`;
const BaseProductContent1 = styled(Row)`
  padding: 12px;
  width: 100%;
  background-color: #313841;
`;
const ProductVariantContainer = styled(Row)`
  width: 100%;
`;

const ProductVariantRow = styled(Row)`
  width: 100%;
  padding: 5px 2px;
`;

class OrdersWarehouseManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderStatusFilter: orderStatues[0],
      fetchingOrdersList: true,
      areOrdersAvailable: false,
      totalResults: 0,
      accountsOrderListIndex: 1,
      someProcessingActive: false,
      filterActive: false,
      filterObj: {},
      searchActive: false,
      filterCount: 0,
      allowStatusChange: true,
      toggleShowlModal: false,
      deliveryFailureReason: '',
      website_group_name: ''
    };
  }

  setOrdersListing = () => {
    this.setState({
      fetchingOrdersList: true,
      someProcessingActive: true,
      allowStatusChange: true,
    });
    let temp = [];
    temp.push(orderStatues[0]);
    this.setState({ orderStatusFilter: orderStatues[0] });
    axios
      .post(
        `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
        {
          trackingStatusList: temp,
          page: 1,
          "website_group_name": this.state.website_group_name,
          locationGroupUuid : this.props.locationUuid
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        this.props.setOrdersManagerDetails(response.data.orderList);
        this.setState({
          areOrdersAvailable: response.data.isAvailable,
          fetchingOrdersList: false,
          someProcessingActive: false,
          accountsOrderListIndex: 2,
          totalResults: this.props.ordersManagerDetails.fetchedOrders.length,
        });
      });
  };

  fetchInitialData = () => {
    axios
    .post(
      `${serverOmnycommUrl}/fetch-company-profile/`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      }
    )
    .then(response => {
      if (response.data.status == 200)
        this.setState({
          website_group_name: response.data.company_data.name,
        }, () => {
          this._isMounted = true;
          if (this._isMounted) {
            this.setOrdersListing();
          }

        });
    });
  }

  componentDidMount = () => {
      this.setState({
        locationUuid : this.props.locationUuid
      })
      
      this.fetchInitialData()
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.props.unsetSelectedOrders();
  };

  componentDidUpdate = (prevState , prevProps) => {
    if(prevState.locationUuid != this.state.locationUuid)
      this.fetchInitialData();
  }

  static getDerivedStateFromProps(nextProps , prevState){
      if(nextProps.locationUuid != prevState.locationUuid)
      {
        return {
          locationUuid : nextProps.locationUuid
        }
      }

      return null
  } 

  getCheckedOrders = (bundleId) => {
    let allOrders = this.props.ordersManagerDetails.fetchedOrders;

    for (let bundleOrderIndex = 0; bundleOrderIndex < allOrders.length; ++bundleOrderIndex) {
      if (allOrders[bundleOrderIndex].bundleId === bundleId) {
        let unitOrderList = allOrders[bundleOrderIndex].unitOrderList;
        let tempCheckedOrders = [];
        for (let unitOrderIndex = 0; unitOrderIndex < unitOrderList.length; ++unitOrderIndex) {
          if (unitOrderList[unitOrderIndex].checked === true) {
            tempCheckedOrders.push(unitOrderList[unitOrderIndex]['uuid']);
          }
        }
        return tempCheckedOrders;
      }
    }
  }

  validateOrdersStatuses = (bundleId) => {
    //.log(bundleId);
    let allOrders = this.props.ordersManagerDetails.fetchedOrders;

    for (let bundleOrderIndex = 0; bundleOrderIndex < allOrders.length; ++bundleOrderIndex) {
      if (allOrders[bundleOrderIndex].bundleId === bundleId) {
        let unitOrderList = allOrders[bundleOrderIndex].unitOrderList;
        for (let unitOrderIndex = 0; unitOrderIndex < unitOrderList.length; ++unitOrderIndex) {
          if (
            unitOrderList[unitOrderIndex].checked === true &&
            unitOrderList[unitOrderIndex].currentStatus !== this.state.orderStatusFilter
          ) {
            return false;
          }
        }
        return true;
      }
    }
  }

  setOrderStatus = (orderTypeString, bundleId, deliveryFailedReason = "") => {
    if (this._isMounted) {
      let selectedOrders = this.getCheckedOrders(bundleId);
      if (selectedOrders.length > 0) {
        let isStateValid = this.validateOrdersStatuses(bundleId);

        if (isStateValid) {
          this.props.unsetSelectedOrders();
          this.setState({ someProcessingActive: true });
          axios
            .post(
              `${serverOmnycommUrl}/dealshub/set-orders-status/`,
              {
                orderStatus: orderTypeString,
                unitOrderUuidList: selectedOrders,
                deliveryFailedReason,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((response) => {
              if (response.data.status === 200) {
                this.props.setOrderStatus(
                  orderTypeString,
                  this.props.ordersManagerDetails,
                  selectedOrders
                );
                message.success("Changes applied successfully!");
                this.setState({ someProcessingActive: false });
              } else {
                message.error("Something went wrong! Try refreshing the page!");
              }
            });
        } else {
          message.warning("Check Statuses of orders!");
        }
      } else {
        message.error("No orders Selected!");
      }
    }

  };

  loadMoreOrders = () => {
    if (this._isMounted) {
      this.setState({
        fetchingOrdersList: true,
        someProcessingActive: true,
      });

      let requestObj = this.state.filterActive
        ? {
          fromDate: this.state.filterObj.fromDate,
          toDate: this.state.filterObj.toDate,
          paymentTypeList: this.state.filterObj.paymentTypeList,
          minQty: this.state.filterObj.minQty,
          maxQty: this.state.filterObj.maxQty,
          minPrice: this.state.filterObj.minPrice,
          maxPrice: this.state.filterObj.maxPrice,
          currencyList: this.state.filterObj.currencyList,
          shippingMethodList: this.state.filterObj.shippingMethodList,
          trackingStatusList: this.state.filterObj.trackingStatusList,
          page: this.state.accountsOrderListIndex,
          "website_group_name": this.state.website_group_name,
          locationGroupUuid : this.props.locationUuid
        }
        : {
          page: this.state.accountsOrderListIndex,
          "website_group_name": this.state.website_group_name,
          locationGroupUuid : this.props.locationUuid
        };

      axios
        .post(
          `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
          requestObj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          this.props.loadMoreOrdersManagerDetails(response.data.orderList);
          this.setState({
            areOrdersAvailable: response.data.isAvailable,
            // orderList: [...this.state.orderList, ...response.data.orderList],
            fetchingOrdersList: false,
            someProcessingActive: false,
            accountsOrderListIndex: this.state.accountsOrderListIndex + 1,
            totalResults: this.props.ordersManagerDetails.fetchedOrders.length,
          });
        });
    }
  };

  toggleOrderCheck = (orderChecked, orderUuid, bundleId) => {
    !orderChecked
      ? this.props.checkOrderUuid(
        this.props.ordersManagerDetails.fetchedOrders,
        orderUuid,
        bundleId
      )
      : this.props.uncheckOrderUuid(
        this.props.ordersManagerDetails.fetchedOrders,
        orderUuid,
        bundleId
      );
  };

  applyFilters = (filterObj, allowStatusChange = true) => {
    if (this._isMounted) {
      this.props.unsetSelectedOrders();
      this.setState(
        {
          fetchingOrdersList: true,
          someProcessingActive: true,
          accountsOrderListIndex: 1,
          filterActive: true,
          filterObj: filterObj,
          allowStatusChange: allowStatusChange,
        },
        () => {
          let temp = 0;
          console.log(filterObj , "ffff");
          for (let filterKey in filterObj) {
            if (
              Array.isArray(filterObj[filterKey]) &&
              [filterKey].length > 0
            ) {
              ++temp;
            } else if (filterObj[filterKey] && filterObj[filterKey].toString().length > 0) {
              ++temp;
            }
          }
          this.setState({
            filterCount: temp,
            orderStatusFilter: filterObj["trackingStatusList"][0],
          });
          axios
            .post(
              `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
              {
                fromDate: filterObj.fromDate ? moment(filterObj.fromDate).format('YYYY-MM-DD HH:mm:ss') : "",
                toDate: filterObj.toDate ? moment(filterObj.toDate).format('YYYY-MM-DD HH:mm:ss') : "",
                paymentTypeList: filterObj.paymentTypeList,
                minQty: filterObj.minQty.toString(),
                maxQty: filterObj.maxQty.toString(),
                minPrice: filterObj.minPrice.toString(),
                maxPrice: filterObj.maxPrice.toString(),
                currencyList: filterObj.currencyList,
                shippingMethodList: filterObj.shippingMethodList,
                trackingStatusList: filterObj.trackingStatusList,
                page: this.state.accountsOrderListIndex,
                "website_group_name": this.state.website_group_name,
                locationGroupUuid : this.props.locationUuid
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((response) => {
              this.props.setOrdersManagerDetails(response.data.orderList);
              if (
                this.state.filterObj.clearFilters &&
                this.state.filterCount > 0
              ) {
                this.setState({ filterCount: 0 });
              }
              this.setState({
                areOrdersAvailable: response.data.isAvailable,
                // orderList: [...this.state.orderList, ...response.data.orderList],
                fetchingOrdersList: false,
                someProcessingActive: false,
                accountsOrderListIndex: this.state.accountsOrderListIndex + 1,
                totalResults: this.props.ordersManagerDetails.fetchedOrders
                  .length,
              });
            });
        }
      );
    }
  };

  handleSearchChange = (searchStringsList) => {
    if (this._isMounted) {
      if (searchStringsList.length > 0) {
        this.props.unsetSelectedOrders();
        this.setState(
          {
            fetchingOrdersList: true,
            someProcessingActive: true,
            accountsOrderListIndex: 1,
            searchActive: true,
          },
          () => {
            axios
              .post(
                `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
                {
                  searchList: searchStringsList,
                  page: this.state.accountsOrderListIndex,
                  "website_group_name": this.state.website_group_name,
                  locationGroupUuid : this.props.locationUuid
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then((response) => {
                this.props.setOrdersManagerDetails(response.data.orderList);
                this.setState({
                  areOrdersAvailable: response.data.isAvailable,
                  // orderList: [...this.state.orderList, ...response.data.orderList],
                  fetchingOrdersList: false,
                  someProcessingActive: false,
                  accountsOrderListIndex: this.state.accountsOrderListIndex + 1,
                  totalResults: this.props.ordersManagerDetails.fetchedOrders
                    .length,
                });
              });
          }
        );
      } else {
        this.setOrdersListing();
      }
    }
  };

  toggleModalShow = (bundleId) => {
    if (this._isMounted) {
      let selectedOrders = this.getCheckedOrders(bundleId);
      if (selectedOrders.length > 0) {
        this.setState({ toggleShowlModal: !this.state.toggleShowlModal });
      } else {
        message.error('No orders Selected!');
      }
    }
  }

  handleCancelModalSubmit = (bundleId) => {
    if (this.state.deliveryFailureReason.length < 20) {
      message.warning('Invalid Length');
    } else {
      this.setOrderStatus('delivery failed', bundleId, this.state.deliveryFailureReason);
      this.setState({ toggleShowlModal: false });
    }
  }

  handleCancelModalClose = (bundleId) => {
    this.setState({ deliveryFailureReason: '' });
    this.toggleModalShow(bundleId);
  }

  handleCancelTextChange = (event) => {
    this.setState({ deliveryFailureReason: event.target.value });
  }

  getCollapsibleHeaderData = (order) => {
    let mainImages = [];
    order.unitOrderList.map((unitOrder) => {
      mainImages.push(unitOrder.productImageUrl);
    });
    return {
      dateCreated: order.dateCreated,
      mainImages,
      customerName: order.customerName,
      paymentStatus: order.paymentStatus,
    };
  };
  

  render() {
    return (
      <OrdersWarehouseManagerMobileView
        totalResults={this.state.totalResults}
        loading={this.state.fetchingOrdersList}
        setOrderStatus={this.setOrderStatus}
        someProcessingActive={this.state.someProcessingActive}
        accountsOrderListIndex={this.state.accountsOrderListIndex}
        applyFilters={this.applyFilters}
        filterCount={this.state.filterCount}
        handleSearchChange={this.handleSearchChange}
        orderStatusFilter={this.state.orderStatusFilter}
        allowStatusChange={this.state.allowStatusChange}
        toggleOrderCheck={this.toggleOrderCheck}
        fetchingOrdersList={this.state.fetchingOrdersList}
        loadMoreOrders={this.loadMoreOrders}
        ordersManagerDetails={this.props.ordersManagerDetails}
        areOrdersAvailable={this.state.areOrdersAvailable}
        toggleShowlModal={this.state.toggleShowlModal}
        handleCancelModalSubmit={this.handleCancelModalSubmit}
        handleCancelModalClose={this.handleCancelModalClose}
        handleCancelTextChange={this.handleCancelTextChange}
        toggleModalShow={this.toggleModalShow}
        deliveryFailureReason={this.deliveryFailureReason}
      />
    );
  }
}

const mapStateToProps = ({ ordersManagerDetails }) => {
  return {
    ordersManagerDetails,
  };
};

export default connect(mapStateToProps, {
  setOrdersManagerDetails,
  loadMoreOrdersManagerDetails,
  setOrderStatus,
  checkOrderUuid,
  uncheckOrderUuid,
  unsetSelectedOrders,
})(OrdersWarehouseManager);
