import React, { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Button,
	Typography,
	Input,
	Select,
	Icon,
	Collapse,
	Popconfirm,
	Checkbox,
	Tag,
} from 'antd';
import axios from 'axios';
import './order-cancellation.scss';
import moment from 'moment';
import isDev from 'server';
import OrdersAccountsManagerFilterModal from 'views/components/modals/orders-accounts-manager-filter-modal/OrdersAccountsManagerFilterModal';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const serverOmnycommUrl = isDev();

const CancelRequest = ({
	request,
	selectedList,
	setSelectedList,
	approveCancellationRequest,
	rejectCancellationRequest,
}) => {
	const handleSelection = (uuid) => {
		const tmp = selectedList.findIndex((item) => item === uuid);
		if (tmp === -1) {
			setSelectedList([...selectedList, uuid]);
			console.log([...selectedList, uuid]);
		} else {
			setSelectedList(selectedList.filter((item) => item !== uuid));
			console.log(selectedList.filter((item) => item !== uuid));
		}
	};
	return (
		<Col span={24}>
			{request.unitOrderList &&
				request.unitOrderList.map((item) => (
					<Row className='unit-orders'>
						<Col span={18}>
							<Row
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									gap: '10px',
									width: '100%',
								}}
							>
								<span>
									<Checkbox
										checked={
											selectedList.findIndex((uuid) => uuid === item.uuid) > -1
										}
										onChange={() => handleSelection(item.uuid)}
									></Checkbox>
								</span>
								<img
									className='image-container'
									src={item.productImageUrl}
									alt={`${item.productName}-${item.orderId}`}
								/>
								<span>
									<Row>
										<Text className='text small-font'>{item.productName}</Text>
									</Row>
									<Row>
										<Text>
											Order Id:{' '}
											<span style={{ color: 'white' }}>{item.orderId}</span>
										</Text>
									</Row>
									<Row>
										<Text>
											Intercompany order id:{' '}
											<span style={{ color: 'white' }}>
												{item.intercompany_order_id !== ''
													? item.intercompany_order_id
													: '-'}
											</span>
										</Text>
									</Row>
								</span>
							</Row>
							<Row style={{ paddingInlineStart: '116px', marginTop: '5px' }}>
								<Text>
									Reason:{' '}
									<span style={{ color: 'white' }}>
										{item.user_cancellation_note === ''
											? 'No reason provided'
											: item.user_cancellation_note}
									</span>
								</Text>
							</Row>
						</Col>
						<Col span={4} offset={2}>
							<Row style={{ textAlign: 'end', marginBottom: '10px' }}>
								<Text className='text' style={{ fontSize: '16px' }}>
									{item.quantity} x {item.price} {item.currency}
								</Text>
							</Row>
							<Row
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
								}}
							>
								<Tag color='geekblue' style={{ margin: '0' }}>
									Order Staus - {item.currentStatus}
								</Tag>
							</Row>
						</Col>
					</Row>
				))}
			<Row className='buttons-bar'>
				<Popconfirm
					title='Are you sure you want to reject the request?'
					okType='danger'
					onConfirm={() => rejectCancellationRequest(request.uuid)}
				>
					<Button type='danger' ghost>
						Reject Cancellation Request
					</Button>
				</Popconfirm>
				<Popconfirm
					title='Are you sure you want to cancel these items?'
					okType='danger'
					onConfirm={() => approveCancellationRequest(request.uuid)}
				>
					<Button type='danger'>Cancel Selected Items</Button>
				</Popconfirm>
			</Row>
		</Col>
	);
};

const OrderCancellation = (props) => {
	const [cancelRequests, setCancelRequests] = useState({
		totalOrders: null,
		orderList: [],
		totalRevenue: null,
		currency: 'AED',
	});
	const [selectedList, setSelectedList] = useState([]);
	const [websiteGroupName, setWebsiteGroupName] = useState(null);
	const [archivedState, setArchivedState] = useState(false);

	useEffect(() => {
		console.log(props, 'cancellation props');
		axios
			.post(
				`${serverOmnycommUrl}/fetch-company-profile/`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `JWT ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((response) => {
				if (response.data.status == 200) {
					setWebsiteGroupName(response.data.company_data.name);
					fetchOrdersForWarehouseManager(response.data.company_data.name, {});
				}
			});
	}, []);

	const fetchOrdersForWarehouseManager = (name, filterObj) => {
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/fetch-orders-for-warehouse-manager/`,
			data: {
				page: 1,
				website_group_name: websiteGroupName || name,
				locationGroupUuid: props.locationUuid,
				cancellation_requested: true,
				fromDate: filterObj.fromDate
					? moment(filterObj.fromDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
				toDate: filterObj.toDate
					? moment(filterObj.toDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
				paymentTypeList: filterObj.paymentTypeList,
				minQty: filterObj.minQty?.toString(),
				maxQty: filterObj.maxQty?.toString(),
				minPrice: filterObj.minPrice?.toString(),
				maxPrice: filterObj.maxPrice?.toString(),
				currencyList: filterObj.currencyList,
				shippingMethodList: filterObj.shippingMethodList,
				trackingStatusList: filterObj.trackingStatusList,
				sapStatusList: filterObj.sapStatusList,
				isOrderOffline: filterObj.isOrderOffline,
				searchList: filterObj.searchList,
			},
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json',
			},
		}).then((response) => {
			// console.log(response.data);
			setCancelRequests(response.data);
		});
	};

	const approveCancellationRequest = (uuid) => {
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/approve-cancellation-request/`,
			data: { unit_order_uuid_list: selectedList },
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				console.log(response.data);
				rejectCancellationRequest(uuid);
			})
			.then(() => fetchOrdersForWarehouseManager(null, {}));
	};
	const updateCancellationRequestRefundStatus = () => {
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/update-cancellation-request-refund-status/`,
			data: {},
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json',
			},
		}).then((response) => {
			console.log(response.data);
		});
	};
	const rejectCancellationRequest = (uuid) => {
		const rejectionList = cancelRequests.orderList
			.filter((item) => item.uuid === uuid)[0]
			.unitOrderList.filter(
				(item) => selectedList.findIndex((i) => i === item.uuid) === -1
			);
		axios({
			method: 'POST',
			url: `${serverOmnycommUrl}/dealshub/reject-cancellation-request/`,
			data: { unit_order_uuid_list: rejectionList.map((item) => item.uuid) },
			headers: {
				Authorization: `JWT ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json',
			},
		}).then((response) => {
			console.log(response.data);
			fetchOrdersForWarehouseManager(null, {});
		});
	};

	const applyFilters = (filterObj) => {
		fetchOrdersForWarehouseManager(null, filterObj);
	};

	const toggleCondition = () => {
		setArchivedState(!archivedState);
	};

	console.log(cancelRequests, 'cancelRequests');

	return (
		<Row className='order-cancellation' gutter={[16, 16]}>
			<Col span={24} className='order-cancellation-title'>
				<Title level={4} className='header-title'>
					Manage Cancellation Requests
				</Title>
			</Col>
			<Col span={24}>
				<Row gutter={[8, 0]}>
					<Col span={4}>
						<Button className='button' type='primary' ghost>
							{archivedState ? 'Archived' : 'Active'} Requests:{' '}
							{
								cancelRequests.orderList.filter(
									(item) =>
										(item.cancelStatus ||
											item.cancellation_request_action_taken) === archivedState
								).length
							}
						</Button>
					</Col>
					<Col span={4}>
						<OrdersAccountsManagerFilterModal
							applyFilters={applyFilters}
							locationGroupUuid={props.locationUuid}
						/>
					</Col>
					<Col span={12}>
						<Select
							dropdownClassName='search-chips'
							dropdownMatchSelectWidth='false'
							mode='tags'
							style={{ width: '100%' }}
							onChange={(searchList) =>
								fetchOrdersForWarehouseManager(null, { searchList })
							}
							placeholder={[<Icon type='search' />, ' Search here...']}
						></Select>
					</Col>
					<Col span={4}>
						<Button className='button' type='primary' onClick={toggleCondition}>
							Go to {archivedState ? 'Active' : 'Archived'} Requests
						</Button>
					</Col>
				</Row>
			</Col>
			<Col span={24} className='cancel-requests'>
				{cancelRequests.orderList.length > 0 ? (
					cancelRequests.orderList.map((item) => {
						if (
							(item.cancelStatus || item.cancellation_request_action_taken) ===
							archivedState
						)
							return (
								<Collapse className='cancel-collapse' expandIcon={null}>
									<Panel
										showArrow={false}
										extra={
											<Row className='panel-header'>
												<Col span={20} className='panel-header-name'>
													<Row>
														<Text
															style={{ color: '#1abc9c', fontWeight: 'bold' }}
														>
															{item.customerName}
														</Text>{' '}
														<Text style={{ color: 'white' }}>
															requested{' '}
															{item.cancelled_by_user
																? 'complete order cancellation'
																: 'Partial order cancellation'}{' '}
															with {item.unitOrderList.length} items on{' '}
															{item.dateCreated}
														</Text>
													</Row>
													<Row style={{ marginTop: '10px' }}>
														<Col span={24}>
															<Text className='text'>{item.bundleId}</Text>
														</Col>
														<Col span={24}>
															<Text className='text'>{item.dateCreated}</Text>
														</Col>
														<Col span={24}>
															<Text className='text'>
																Ref: {item.merchant_reference}
															</Text>
														</Col>
														<Col span={24}>
															<Text className='text'>
																Item count: {item.unitOrderList.length}
															</Text>
														</Col>
													</Row>
												</Col>
												<Col span={3} offset={1}>
													<Row
														style={{ textAlign: 'end', marginBottom: '10px' }}
													>
														<Text
															style={{
																color: 'white',
																fontWeight: 'bolder',
																fontSize: '18px',
																textAlign: 'end',
															}}
														>
															{item.subtotal} {item.currency}
														</Text>
													</Row>
												</Col>
											</Row>
										}
									>
										<CancelRequest
											request={item}
											selectedList={selectedList}
											setSelectedList={setSelectedList}
											approveCancellationRequest={approveCancellationRequest}
											rejectCancellationRequest={rejectCancellationRequest}
										/>
									</Panel>
								</Collapse>
							);
					})
				) : (
					<Text style={{ color: 'white' }}>No cancellation requests</Text>
				)}
			</Col>
		</Row>
	);
};

export default OrderCancellation;
