import React from "react";
import ColorPicker from "rc-color-picker";
import { Row, Col, Typography } from "antd";

const { Text } = Typography;

const ColorComponent = (props) => {
    let colorType = props.colorType;
    colorType = colorType.replace(/_/g, " ");
    return (
        <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
            <Row type="flex" align="middle">
                <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{height: 42, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Text className="label">
                        {colorType.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()})}
                    </Text>
                </Col>
                <Col span={4} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <ColorPicker
                        animation="slide-up"
                        color={props.color}
                        onChange={(color) => props.onChange(color)}
                    />
                </Col>
            </Row>
        </Col>
    )
}

export default ColorComponent;