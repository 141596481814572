import React, { Component } from "react";

import {
  Row,
  Col,
  Button,
  Select,
  Icon,
  Card,
  Typography,
  Avatar,
  Tooltip,
  Upload,
  message,
  AutoComplete,
  Collapse,
  Tag,
  Checkbox
} from "antd";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import axios from "axios";
import "./channelwise.scss";

import styled from "styled-components";

import RuleBook from "./RuleBook";

import isDev from "../../server";
import { isCompositeComponent } from "react-dom/test-utils";
const { Panel } = Collapse;

const serverUrl = isDev();

const { Paragraph, Text } = Typography;

const { Option } = AutoComplete;

const UploadBtn = styled(Upload)`
  && {
    .ant-upload {
      height: 15px !important;
    }
  }
`;

const WhSelect = styled(Select)`
  && {
    color: #dee2e6;
    .ant-select-arrow {
      color: #1abc9c;
    }
    .ant-select-selection {
      background-color: #36404a;
    }
  }
`;

const PriceSelect = styled(Select)`
  && {
    color: #dee2e6;
    .ant-select-arrow {
      color: #1abc9c;
    }
    .ant-select-selection {
      background-color: #36404a;
    }
  }
`;

const PriceOption = styled(Option)``;

const WhOption = styled(Option)``;

class PublishProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftTable: "publish-product-left-table",
      rightTable: "publish-product-left-table hide",
      fileList: [],
      step_2_product_list: [],
      options: [],
      searchedAttrList: [],
      searchString: "",
      searchStringForStep3: "",
      selectedWarehouse: "1070",
      selectedPrice: "EX_EA",
      pageRefreshLoader: false,
      refreshLoaders: {},
      matchedLoader: false,
      compPriceLoader: false,
      published: false,
      left: true,
      timer: 120,
      timerOn: false,
      feed_submission_id: null,
      timerValue: 120,
      //status
      statusFetchedSuccesfully: false,
      statusFetchLoading: false,
      statusData: null,
      loadingPublishing: false,
    };
  }

  handelCheck = (e, index) => {
    console.log(e, index)
    let copy_step_2_product_list = this.state.step_2_product_list;
    copy_step_2_product_list[index].is_matched = e.target.checked;
    this.setState({
      step_2_product_list: copy_step_2_product_list
    })
  }

  showLeftTable = () => {
    this.setState({
      leftTable: "publish-product-left-table",
      rightTable: "publish-product-left-table hide",
      left: true,
    });
  };

  showRightTable = () => {
    this.setState({
      leftTable: "publish-product-left-table hide",
      rightTable: "publish-product-left-table",
      left: false,
    });
  };

  setStatusDataToList = () => {
    //.log(this.state, "check status Product Response");
    let statusMap = {};
    for (let i = 0; i < this.state.statusData.length; i++) {
      if (!statusMap[this.state.statusData[i].product_pk]) {
        statusMap[this.state.statusData[i].product_pk] = {};
        statusMap[this.state.statusData[i].product_pk] = this.state.statusData[
          i
        ];
      }
    }

    //.log(statusMap);

    let step_2_product_list_copy = this.state.step_2_product_list;
    for (let i = 0; i < step_2_product_list_copy.length; i++) {
      if (statusMap[step_2_product_list_copy[i].product_pk]) {
        step_2_product_list_copy[i].errorStatus =
          statusMap[step_2_product_list_copy[i].product_pk];
      } else step_2_product_list_copy[i].errorStatus = "success";
    }

    this.setState({
      step_2_product_list: step_2_product_list_copy,
    });

    //.log(this.step_2_product_list_copy, "check status Product Response");
  };

  handleCheckStatus = () => {
    if (this.state.statusFetchedSuccesfully) {
      this.showRightTable();
    } else {
      this.setState({
        statusFetchLoading: true,
      });

      let data = {
        feed_submission_id: this.state.feed_submission_id,
      };

      //.log(data, "Publishing Product");
      fetch(
        `${serverUrl}/mws/get-push-products-result-${this.props.channelName}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          //.log(data, "check status Product Response");

          if (data.status == 200) {
            if (data.result_status == "In Progress") {
              // timer on
              this.setState(
                {
                  timer: this.state.timerValue,
                  timerOn: true,
                  statusFetchLoading: false,
                },
                () => {
                  var statusTimer = setInterval(() => {
                    if (this.state.timer == 0) {
                      clearInterval(statusTimer);
                      this.setState({
                        timerOn: false,
                      });
                    }
                    this.setState((prevState) => ({
                      timer: prevState.timer - 1,
                    }));
                  }, 1000);
                }
              );
            } else if (data.result_status == "Done") {
              this.setState(
                {
                  statusData: data.errors,
                  statusFetchedSuccesfully: true,
                  statusFetchLoading: false,
                },
                () => {
                  this.setStatusDataToList();
                }
              );
              this.showRightTable();
            } else {
              message.error("Something Went Wrong!");
              this.setState({
                statusFetchLoading: false,
              });
            }
          } 
          else if(data.status === 405)
          {
            message.info("You are not authorised!")
          }
          else {
            message.error("Something Went Wrong!");
          }
        });
    }
  };

  uploadFile = (file) => {
    let formData = new FormData();
    formData.append("import_file", file);
    this.setState({ loading: true });
    axios
      .post(`${serverUrl}/upload-bulk-export/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // this.setState(prevState => ({
        //     step_2_product_list: prevState.uploadedImageCounter + 1
        // }));
        if (res.data.status === 200) {
          this.setState({ step_2_product_list: res.data.product_list });
        } 
        else if(res.data.status === 405)
        {
          message.info("You are not authorised!")
        }
        else {
          message.error("Internal Server Error!");
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Internal Server Error!");
      });
  };

  handleSelectFile = (file) => {
    this.setState({ fileList: [file] });
    this.uploadFile(file);
  };

  handleUnselectFile = (file) => {
    this.setState({ fileList: [] });
  };

  handleRemoveProduct = (e, item) => {
    this.setState({
      step_2_product_list: this.state.step_2_product_list.filter(
        (e) => e.uuid !== item.uuid
      ),
    });
  };

  addToStepTwoProductList = (item) => {
    this.setState({
      step_2_product_list: [...this.state.step_2_product_list, item],
    });
  };

  addAfterSerach = (e) => {
    this.setState({ searchString: "" });
    this.addToStepTwoProductList(this.state.options[e]);
  };

  handleSearch = (value) => {
    this.setState({ searchString: value });
    let data = {
      search_string: value,
    };
    fetch(`${serverUrl}/search-bulk-export/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.setState({ options: data.product_list });
        }
      });
  };

  handleRefreshAll = () => {
    this.setState({
      pageRefreshLoader: true,
    });

    let product_pk_list = [];

    for (let i in this.state.step_2_product_list) {
      product_pk_list.push(this.state.step_2_product_list[i].product_pk);

      this.setState((prevState) => ({
        refreshLoaders: {
          ...prevState.refreshLoaders,
          [this.state.step_2_product_list[i].product_pk]: true,
        },
      }));
    }

    let data = {
      product_pk_list,
      warehouse_code: this.state.selectedWarehouse,
    };

    fetch(`${serverUrl}/refresh-page-price-and-stock/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        let tempList = this.state.step_2_product_list;

        for (let j in data.warehouses_information) {
          if (!tempList[j][`cc_${data.warehouses_information[j].company_code}`])
            tempList[j][
              `cc_${data.warehouses_information[j].company_code}`
            ] = {};

          tempList[j][
            `cc_${data.warehouses_information[j].company_code}`
          ].prices = data.warehouses_information[j].prices;
          tempList[j][`cc_${data.warehouses_information[j].company_code}`].qty =
            data.warehouses_information[j].qty;
        }

        this.setState({
          pageRefreshLoader: false,
          step_2_product_list: tempList,
        });

        for (let i in this.state.step_2_product_list) {
          this.setState((prevState) => ({
            refreshLoaders: {
              ...prevState.refreshLoaders,
              [this.state.step_2_product_list[i].product_pk]: false,
            },
          }));
        }
      });
  };

  handleRefreshWarehousePrice = (pk) => {
    this.setState({
      refreshLoaders: { ...this.state.refreshLoaders, [pk]: true },
    });

    let data = {
      product_pk: pk,
      warehouse_code: this.state.selectedWarehouse,
    };
    fetch(`${serverUrl}/refresh-product-price-and-stock/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.warehouses_dict) {
          let tempList = this.state.step_2_product_list;
          for (let i in tempList) {
            if (tempList[i].product_pk === pk) {
              if (!tempList[i][`cc_${data.warehouses_dict.company_code}`])
                tempList[i][`cc_${data.warehouses_dict.company_code}`] = {};

              tempList[i][`cc_${data.warehouses_dict.company_code}`].prices =
                data.warehouses_dict.prices;
              tempList[i][`cc_${data.warehouses_dict.company_code}`].qty =
                data.warehouses_dict.qty;

              break;
            }
          }

          this.setState({
            step_2_product_list: tempList,
          });
        }
        this.setState({
          refreshLoaders: { ...this.state.refreshLoaders, [pk]: false },
        });
      });
  };

  handleSelectWareHouse = (e) => {
    this.setState({
      selectedWarehouse: e,
    });
  };

  handelSelectPrices = (e) => {
    this.setState({
      selectedPrice: e,
    });
  };

  componentDidMount = () => {
    this.props.toggle();
  };

  handleMatched = () => {
    this.setState({
      matchedLoader: true,
    });

    let product_pk_list = this.state.step_2_product_list.map((e) =>
      parseInt(e.product_pk)
    );

    let data = {
      product_pk_list,
    };

    //.log(data);

    fetch(`${serverUrl}/mws/get-matching-products-${this.props.channelName}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        //.log(data);

        let step_2_product_list = this.state.step_2_product_list;
        let pkwise_matched = {};

        if (data.status == 200) {
          let matched_products = data.matched_products_list;
          for (let i = 0; i < matched_products.length; i++) {
            pkwise_matched[matched_products[i].product_pk] =
              matched_products[i];
          }

          for (let i = 0; i < step_2_product_list.length; i++) {
            step_2_product_list[i].matched_products =
              pkwise_matched[step_2_product_list[i].product_pk];

            step_2_product_list[i].is_matched = pkwise_matched[step_2_product_list[i].product_pk].status == "Success";
          }

          this.setState({
            step_2_product_list,
          });
        }
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
        else
        {
          message.error("Internal Server Error!");
        }

        this.setState({
          matchedLoader: false,
        });
      });
  };

  handleCompLoader = () => {
    this.setState({
      compPriceLoader: true,
    });

    let product_pk_list = this.state.step_2_product_list.map((e) =>
      parseInt(e.product_pk)
    );

    let data = {
      product_pk_list,
    };

    //.log(data);

    fetch(
      `${serverUrl}/mws/get-pricing-of-products-${this.props.channelName}/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //.log(data);

        let step_2_product_list = this.state.step_2_product_list;
        let pkwise_matched = {};

        //.log(data);

        if (data.status == 200) {
          let pricing_info = data.pricing_information;
          for (
            let i = 0;
            i < pricing_info.competitive_pricing_list.length;
            i++
          ) {
            pkwise_matched[
              pricing_info.competitive_pricing_list[i].product_pk
            ] = pricing_info.competitive_pricing_list[i];
          }

          for (let i = 0; i < step_2_product_list.length; i++) {
            step_2_product_list[i].pricing_info =
              pkwise_matched[step_2_product_list[i].product_pk];
          }

          this.setState({
            step_2_product_list,
          });
        }
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
        else {
          message.error("Internal Server Error!");
        }
        

        this.setState({
          compPriceLoader: false,
        });
      });
  };

  handlePublish = () => {
    this.setState(
      {
        published: true,
        timer: this.state.timerValue,
        timerOn: true,
      },
      () => {
        var statusTimer1 = setInterval(() => {
          if (this.state.timer == 0) {
            clearInterval(statusTimer1);
            this.setState({
              timerOn: false,
            });
          }
          this.setState((prevState) => ({
            timer: prevState.timer - 1,
          }));
        }, 1000);
      }
    );

    let copy_step_2_product_list = this.state.step_2_product_list.filter((e) =>
      e.is_matched
    );

    let product_pk_list = copy_step_2_product_list.map((e) =>
      parseInt(e.product_pk)
    );

    let data = {
      product_pk_list,
    };

    //.log(data, "Publishing Product");

    this.setState({
      loadingPublishing: true,
    });

    fetch(`${serverUrl}/mws/push-products-${this.props.channelName}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loadingPublishing: false,
        });
        //.log(data, "Publishing Product Response");
        // this.setState({
        //     timer: 30,
        //     timerOn: true
        // })
        if (data.status == 200) {
          this.setState({
            feed_submission_id: data.feed_submission_id,
          });

          message.success(
            "Successfully published please check reports in Reports Page for feed product id = " +
            data.feed_submission_id
          );
        } 
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
        else {
          this.setState({
            timerOn: false,
          });
          message.error("Something went Wrong!");
        }
      });
  };

  render() {

    console.log(this.state.step_2_product_list);

    function getPrice(data) {
      let total =
        parseFloat(data.ListingPrice.Amount.value) +
        parseFloat(data.Shipping.Amount.value);
      //.log(total, "Total");
      return (
        <React.Fragment>
          <Text>
            {`${data.ListingPrice.Amount.value} ${data.ListingPrice.CurrencyCode.value}`}
            <br />
            <Tooltip placement="right" title={`Shipping Price`}>
              <Text>
                {`${data.Shipping.Amount.value} ${data.Shipping.CurrencyCode.value}`}{" "}
              </Text>
            </Tooltip>
          </Text>
          <br />
          <Tooltip placement="right" title={`Total Price`}>
            <Text
              style={{ color: "white" }}
            >{`${total} ${data.Shipping.CurrencyCode.value}`}</Text>
          </Tooltip>
        </React.Fragment>
      );
    }

    //.log(this.state);

    let isPublishEnable = false;
    let isMatchingEnabled = false;
    let isComPriceEnabled = false;

    for (let i = 0; i < this.state.step_2_product_list.length; i++) {
      if (this.state.step_2_product_list[i].is_matched) {
        isPublishEnable = true;
        break;
      }
    }

    if (this.state.step_2_product_list.length == 0) isPublishEnable = false;
    else if (
      !this.state.step_2_product_list[
        this.state.step_2_product_list.length - 1
      ].hasOwnProperty("matched_products")
    )
      isPublishEnable = false;

    if (this.state.step_2_product_list.length == 0) isMatchingEnabled = false;
    else if (
      !this.state.step_2_product_list[
        this.state.step_2_product_list.length - 1
      ].hasOwnProperty("matched_products")
    )
      isMatchingEnabled = true;

    if (this.state.step_2_product_list.length == 0) isComPriceEnabled = false;
    else if (
      !this.state.step_2_product_list[
        this.state.step_2_product_list.length - 1
      ].hasOwnProperty("pricing_info") &&
      this.state.step_2_product_list[0].hasOwnProperty("matched_products")
    )
      isComPriceEnabled = true;

    //.log(isMatchingEnabled);

    return (
      <React.Fragment>
        <div className="publish-product-container">
          <TitleWithBreadCrumb
            title={`Amazon Sync`}
            breadcrumb={["Channel", "Amazon UK", "Publish Products"]}
          />

          <Row className="publish-product-container-containt">
            <Col spam={24}>
              <Row gutter={[12, 12]}>
                <Col lg={3} sm={8} className="publish-product-upload">
                  <Button style={{ width: "100%" }} type="primary" ghost>
                    Result :{" "}
                    {this.state.step_2_product_list
                      ? this.state.step_2_product_list.length
                      : "0"}
                  </Button>
                </Col>

                <Col lg={4} sm={8} className="publish-product-upload">
                  <UploadBtn
                    beforeUpload={this.handleSelectFile}
                    onRemove={this.handleUnselectFile}
                    fileList={this.state.fileList}
                  >
                    <Button
                      disabled={
                        this.state.fileList.length !== 0 || this.state.published
                      }
                      style={{ width: "100%" }}
                      type="primary"
                    >
                      <Icon type="file-excel" />
                      Upload
                    </Button>
                  </UploadBtn>
                </Col>

                <Col lg={17} sm={8}>
                  <AutoComplete
                    disabled={this.state.published}
                    onSelect={this.addAfterSerach}
                    value={this.state.searchString}
                    style={{ width: "100%", marginBottom: "10px" }}
                    onSearch={this.handleSearch}
                    placeholder={[<Icon type="search" />, " Add products by searching here..."]}
                  >
                    {this.state.options.map((item, index) => {
                      return (
                        <Option key={index}>
                          {" "}
                          <Avatar
                            shape="square"
                            src={item.image_url}
                            style={{ marginRight: "5px" }}
                          />
                          {item.name}
                        </Option>
                      );
                    })}
                  </AutoComplete>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className="product-basket-text">
                <Text className="text-color3">Product Basket</Text>
              </Row>

              <Row className="product-basket">
                <Col span={24}>
                  <Row gutter={[12, 12]} type="flex" align="middle">
                    {this.state.step_2_product_list &&
                      this.state.step_2_product_list.map((item, index) => {
                        return (
                          <Col key={index} span={8}>
                            <Card bordered className="categoryproductcard">
                              <Row gutter={[16, 0]}>
                                <Col sm={5}>
                                  <Avatar
                                    shape="square"
                                    style={{ width: "100%", height: "100%" }}
                                    src={item.image_url}
                                  />
                                </Col>
                                <Col sm={19} style={{ paddingLeft: "0px" }}>
                                  <Tooltip title={item.name}>
                                    <Paragraph
                                      className="text-color3"
                                      ellipsis={{ rows: 2, expandable: false }}
                                      style={{
                                        marginBottom: "0px",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      {item.name}
                                    </Paragraph>
                                  </Tooltip>
                                  <Text className="text-color3">ID :</Text>
                                  <Text> {item.product_id}</Text>
                                </Col>
                              </Row>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  this.handleRemoveProduct(e, item)
                                }
                              >
                                X
                              </div>
                            </Card>
                          </Col>
                        );
                      })}

                    {this.state.step_2_product_list.length == 0 && (
                      <Col span={24}>
                        <Row type="flex" justify="center" align="middle">
                          <Text style={{ marginBottom: "0px" }}>
                            No Products in the Basket (you can upload a sheet or
                            search from search bar above)
                          </Text>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className="product-basket-text">
                <Text className="text-color3">Sync Table</Text>
              </Row>

              <Row className="publish-product-table">
                <Col span={24}>
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <Row gutter={[8, 8]}>
                        {/* <Col span={2}>
                                                <Button style={{ width: "100%", backgroundColor: "#129CFC", border: "none" }} type="primary"> Sync </Button>
                                            </Col> */}

                        <Col span={3}>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "#0A73BC",
                              border: "none",
                            }}
                            onClick={() => {
                              window.open(
                                "https://m.media-amazon.com/images/G/01/AmazonServices/How_to_Solve_Common_Feed_Errors_3_30._CB1522402454_.pdf?ld=ASDEGeneralDirect",
                                "_blank"
                              );
                            }}
                            type="primary"
                          >
                            {" "}
                            Dictionary{" "}
                          </Button>
                        </Col>

                        {/* <Col span={3}>
                                                <Button style={{ width: "100%", backgroundColor: "#A8385C", border: "none" }} type="primary"> Offer Pricing </Button>
                                            </Col> */}

                        <Col span={3}>
                          <Button
                            disabled={!isPublishEnable || this.state.published}
                            loading={this.state.loadingPublishing}
                            onClick={this.handlePublish}
                            style={{
                              width: "100%",
                              backgroundColor: "#3A7A06",
                              border: "none",
                            }}
                            type="primary"
                          >
                            {" "}
                            Publish{" "}
                          </Button>
                        </Col>

                        {/* {this.state.published && (
                          <Col span={16}>
                            {this.state.left && this.state.timerOn && (
                              <Row gutter={[8, 8]}>
                                <Col span={3}>
                                  <Button
                                    type="primary"
                                    style={{ color: "white", width: "100%" }}
                                    loading={true}
                                    disabled={true}
                                  >
                                    {`${this.state.timer}`}
                                  </Button>
                                </Col>

                                <Col span={21}>
                                  <Button
                                    style={{ color: "white" }}
                                    disabled={true}
                                    type="primary"
                                  >
                                    {`Please wait, Omnycomm is publishing your products on Amazon.`}
                                  </Button>
                                </Col>
                              </Row>
                            )}

                            {this.state.left && !this.state.timerOn && (
                              <Row gutter={[8, 8]}>
                                <Col span={5}>
                                  <Button
                                    type="primary"
                                    style={{ color: "white", width: "100%" }}
                                    onClick={this.handleCheckStatus}
                                    loading={this.state.statusFetchLoading}
                                  >
                                    Check Status
                                  </Button>
                                </Col>
                                {!this.state.statusFetchedSuccesfully && (
                                  <Col span={19}>
                                    <Button
                                      style={{ color: "white" }}
                                      disabled={true}
                                      type="primary"
                                    >
                                      {`Click on Check Status to view the results in the sync table.`}
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            )}

                            {!this.state.left && this.state.timerOn && (
                              <Row gutter={[8, 8]}>
                                <Button
                                  type="primary"
                                  style={{ color: "white", width: "100%" }}
                                  disabled={true}
                                >
                                  {this.state.timer}
                                </Button>
                                <Button
                                  onClick={this.showLeftTable}
                                  type="primary"
                                >
                                  <Icon type="left" />
                                </Button>
                              </Row>
                            )}

                            {!this.state.left && !this.state.timerOn && (
                              <Button.Group size={`default`}>
                                <Button
                                  style={{ color: "white" }}
                                  disabled={true}
                                  type="primary"
                                >
                                  {`Results retrieved. Please check the sync table below.`}
                                </Button>
                          
                              </Button.Group>
                            )}
                          </Col>
                        )} */}

                        {/* {!this.state.left && !this.state.timerOn && (
                          <Col span={2}>
                            <Row type="flex" justify="end" align="middle">
                              <Button
                                type="primary"
                                size="small"
                                ghost
                                onClick={this.showLeftTable}
                                shape="circle"
                                icon="left"
                              />
                            </Row>
                          </Col>
                        )} */}

                        {/* {
                                                this.state.timerOn &&
                                                <Col span={8}>
                                                    <Text className="header-text">The process is under progress wait for some time!</Text>
                                                </Col>
                                            } */}
                      </Row>
                    </Col>

                    <Col span={24}>
                      {/* Left Table */}

                      <Row className={this.state.leftTable}>
                        <Col className="header">
                          <Row gutter={[8, 8]} type="flex" align="middle">
                            <Col span={1}>
                            </Col>
                            <Col className="header-text" span={6}>
                              <Text className="header-text">Product Cart</Text>
                            </Col>
                            {/* 
                            <Col span={4}>
                              <Row gutter={[8, 8]}>
                                <Col span={24} style={{ position: "relative" }}>
                                  <WhSelect
                                    value={this.state.selectedWarehouse}
                                    style={{ width: 120 }}
                                    onSelect={this.handleSelectWareHouse}
                                    className="header-text"
                                  >
                                    <WhOption
                                      value="1070"
                                      className="header-text"
                                    >
                                      G-UAE-Jafza
                                    </WhOption>
                                    <WhOption
                                      value="1000"
                                      className="header-text"
                                    >
                                      {" "}
                                      G-UAE-Techno Park
                                    </WhOption>
                                  </WhSelect>

                                  <Button
                                    size="small"
                                    shape="circle"
                                    type="primary"
                                    style={{
                                      position: "absolute",
                                      top: "6px",
                                      left: "-30px",
                                    }}
                                    onClick={() => this.handleRefreshAll()}
                                    disabled={this.state.pageRefreshLoader}
                                    className="header-text"
                                  >
                                    {this.state.pageRefreshLoader && (
                                      <Icon
                                        className="header-text"
                                        type="sync"
                                        spin
                                      />
                                    )}
                                    {!this.state.pageRefreshLoader && (
                                      <Icon
                                        className="header-text"
                                        type="sync"
                                      />
                                    )}
                                  </Button>
                                </Col>

                                <Col span={24}>
                                  <PriceSelect
                                    value={this.state.selectedPrice}
                                    style={{ width: 120 }}
                                    onSelect={this.handelSelectPrices}
                                    className="header-text"
                                  >
                                    <PriceOption
                                      className="header-text"
                                      value="EX_EA"
                                    >
                                      EX_EA
                                    </PriceOption>
                                    <PriceOption
                                      className="header-text"
                                      value="IC_EA"
                                    >
                                      IC_EA
                                    </PriceOption>
                                    <PriceOption
                                      className="header-text"
                                      value="OD_EA"
                                    >
                                      OD_EA
                                    </PriceOption>
                                    <PriceOption
                                      className="header-text"
                                      value="RET_EA"
                                    >
                                      RET_EA
                                    </PriceOption>
                                  </PriceSelect>
                                </Col>
                              </Row>
                            </Col>

                            <Col span={4} className="header-text">
                              <Text className="header-text">
                                {" "}
                                Min - Max <br /> Price
                              </Text>
                            </Col> */}

                            <Col
                              span={6}
                              className="header-text"
                              style={{ position: "relative" }}
                            >
                              <Text className="header-text">
                                {" "}
                                Matched Product
                              </Text>

                              <Button
                                size="small"
                                shape="circle"
                                type="primary"
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  left: "-30px",
                                }}
                                onClick={() => this.handleMatched()}
                                disabled={
                                  this.state.matchedLoader || !isMatchingEnabled
                                }
                                className="header-text"
                              >
                                {this.state.matchedLoader && (
                                  <Icon
                                    className="header-text"
                                    type="sync"
                                    spin
                                  />
                                )}
                                {!this.state.matchedLoader && (
                                  <Icon className="header-text" type="sync" />
                                )}
                              </Button>
                            </Col>

                            <Col span={6} className="header-text">
                              <Text className="header-text">
                                {" "}
                                New /
                                Exsiting
                              </Text>
                            </Col>

                            {/* <Col span={3} style={{ position: "relative" }}>
                          
                              <Text className="header-text">Comp Price</Text>

                              <Button
                                size="small"
                                shape="circle"
                                type="primary"
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  left: "-30px",
                                }}
                                onClick={() => this.handleCompLoader()}
                                disabled={
                                  this.state.compPriceLoader ||
                                  !isComPriceEnabled
                                }
                                className="header-text"
                              >
                                {this.state.compPriceLoader && (
                                  <Icon
                                    className="header-text"
                                    type="sync"
                                    spin
                                  />
                                )}
                                {!this.state.compPriceLoader && (
                                  <Icon className="header-text" type="sync" />
                                )}
                              </Button>
                            </Col> */}
                          </Row>
                        </Col>

                        {this.state.step_2_product_list.map((item, index) => {
                          return (
                            <Col key={index} className="table-row">
                              <Row
                                gutter={[8, 8]}
                                type="flex"
                                align="middle"
                                style={{ height: "80px" }}
                              >
                                <Col span={1}>
                                  {
                                    item.matched_products &&
                                    <Checkbox
                                      onChange={(e) => this.handelCheck(e, index)}
                                      checked={item.is_matched}
                                      disabled={item.matched_products.status != "Success"}
                                    ></Checkbox>
                                  }
                                </Col>
                                <Col span={6}>
                                  <Paragraph
                                    className="header-text"
                                    style={{
                                      marginBottom: "0px",
                                      paddingRight: "20px",
                                    }}
                                    ellipsis={{ rows: 3, expandable: false }}
                                  >
                                    {item.name}
                                  </Paragraph>
                                </Col>
                                {/* 
                                <Col span={4}>
                                  <Row>
                                    <Col span={24}>
                                      <Paragraph
                                        className="row-text"
                                        style={{ marginBottom: "0px" }}
                                        ellipsis={{
                                          rows: 3,
                                          expandable: false,
                                        }}
                                      >
                                        {item[
                                          `cc_${this.state.selectedWarehouse}`
                                        ] &&
                                          `${
                                          item[
                                            `cc_${this.state.selectedWarehouse}`
                                          ].qty
                                          } Units`}

                                        <Button
                                          style={{ marginLeft: "5px" }}
                                          type="primary"
                                          shape="circle"
                                          size="small"
                                          disabled={
                                            this.state.refreshLoaders[
                                            item.product_pk
                                            ]
                                          }
                                          onClick={() =>
                                            this.handleRefreshWarehousePrice(
                                              item.product_pk
                                            )
                                          }
                                        >
                                          {this.state.refreshLoaders[
                                            item.product_pk
                                          ] ? (
                                              <Icon type="sync" spin />
                                            ) : (
                                              <Icon type="sync" />
                                            )}
                                        </Button>
                                      </Paragraph>
                                    </Col>

                                    <Col span={24}>
                                      <Paragraph
                                        className="row-text"
                                        style={{ marginBottom: "0px" }}
                                        ellipsis={{
                                          rows: 3,
                                          expandable: false,
                                        }}
                                      >
                                        {item[
                                          `cc_${this.state.selectedWarehouse}`
                                        ] &&
                                          `${
                                          item[
                                            `cc_${this.state.selectedWarehouse}`
                                          ].prices[this.state.selectedPrice]
                                          } AED`}
                                      </Paragraph>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col span={4}>
                                  <Paragraph
                                    className="row-text"
                                    style={{ marginBottom: "0px" }}
                                    ellipsis={{ rows: 3, expandable: false }}
                                  >
                                    120 - 450 AED
                                  </Paragraph>
                             
                                </Col> */}

                                <Col span={6}>
                                  <Paragraph
                                    className="row-text"
                                    style={{ marginBottom: "0px" }}
                                    ellipsis={{ rows: 3, expandable: false }}
                                  >
                                    <Paragraph
                                      className="header-text"
                                      style={{
                                        marginBottom: "0px",
                                        paddingRight: "20px",
                                      }}
                                      ellipsis={{ rows: 3, expandable: false }}
                                    >
                                      {(item.matched_products &&
                                        item.matched_products
                                          .matched_product_title)
                                      }
                                      {
                                        !item.matched_products ? '-' :
                                          !(item.matched_products &&
                                            item.matched_products
                                              .matched_product_title) && 'No matching found'
                                      }
                                    </Paragraph>
                                  </Paragraph>
                                </Col>

                                <Col span={6}>
                                  <Paragraph
                                    className="row-text"
                                    style={{ marginBottom: "0px" }}
                                    ellipsis={{ rows: 3, expandable: false }}
                                  >
                                    {item.matched_products &&
                                      item.matched_products.status === "Success"
                                      && <Tag color="green">Exsiting</Tag>}
                                    {item.matched_products &&
                                      item.matched_products.status === "New Product" && <Tag color="orange">New</Tag>}
                                  </Paragraph>
                                </Col>

                                {/* <Col span={3}>
                                  <Paragraph
                                    className="row-text"
                                    style={{ marginBottom: "0px" }}
                                    ellipsis={{ rows: 3, expandable: false }}
                                  >
                                    {item.pricing_info &&
                                      (item.pricing_info.status == "Success"
                                        ? getPrice(
                                            item.pricing_info
                                              .competitive_pricing
                                          )
                                        : "Not Found")}
                                  </Paragraph>
                                </Col> */}

                                {/* <Col span={2}>
                                                                <Icon
                                                                    onClick={this.showRightTable}
                                                                    style={{
                                                                        fontSize: "1.3em",
                                                                        color: "grey",
                                                                        marginLeft: "10px",
                                                                        cursor: "pointer"
                                                                    }} type="right-square" />
                                                            </Col> */}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>

                      {/* Right Table */}

                      <Row className={this.state.rightTable}>
                        <Col className="header">
                          <Row gutter={[8, 8]} type="flex" align="middle">
                            <Col className="header-text" span={5}>
                              <Text className="header-text">Product Cart</Text>
                            </Col>

                            <Col span={4} offset={1}>
                              <Text className="header-text">Status</Text>
                            </Col>

                            <Col
                              span={6}
                              className="header-text"
                              style={{ position: "relative" }}
                            >
                              <Text className="header-text">Error Code</Text>
                            </Col>

                            <Col span={8} className="header-text">
                              <Text className="header-text">Error Message</Text>
                            </Col>
                          </Row>
                        </Col>

                        {this.state.step_2_product_list.map((item, index) => {
                          return (
                            <Col key={index} className="table-row">
                              <Row
                                gutter={[8, 8]}
                                type="flex"
                                align="middle"
                                style={{ height: "80px" }}
                              >
                                <Col span={5}>
                                  <Paragraph
                                    className="header-text"
                                    style={{
                                      marginBottom: "0px",
                                      paddingRight: "20px",
                                    }}
                                    ellipsis={{ rows: 3, expandable: false }}
                                  >
                                    {item.name}
                                  </Paragraph>
                                </Col>

                                {this.state.statusFetchedSuccesfully &&
                                  item.errorStatus &&
                                  item.errorStatus == "success" && (
                                    <React.Fragment>
                                      <Col span={4} offset={1}>
                                        <Tag color="green">Success</Tag>
                                      </Col>

                                      <Col span={6}>
                                        <Text className="header-text">-</Text>
                                      </Col>

                                      <Col span={8}>
                                        <Text className="header-text">-</Text>
                                      </Col>
                                    </React.Fragment>
                                  )}

                                {this.state.statusFetchedSuccesfully &&
                                  item.errorStatus &&
                                  item.errorStatus !== "success" && (
                                    <React.Fragment>
                                      <Col span={4} offset={1}>
                                        <Tag color="red">
                                          {item.errorStatus.error_type}
                                        </Tag>
                                      </Col>

                                      <Col span={6}>
                                        <Tag color="geekblue">
                                          {" "}
                                          {item.errorStatus.error_code}
                                        </Tag>
                                      </Col>

                                      <Col span={8}>
                                        <Paragraph
                                          className="row-text"
                                          style={{
                                            marginBottom: "0px",
                                            color: "white",
                                          }}
                                          ellipsis={{
                                            rows: 2,
                                            expandable: false,
                                          }}
                                        >
                                          {item.errorStatus.error_message}
                                        </Paragraph>
                                      </Col>
                                    </React.Fragment>
                                  )}

                                {/* <Col span={2}>
                                                                <Icon
                                                                    onClick={this.showRightTable}
                                                                    style={{
                                                                        fontSize: "1.3em",
                                                                        color: "grey",
                                                                        marginLeft: "10px",
                                                                        cursor: "pointer"
                                                                    }} type="right-square" />
                                                            </Col> */}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default PublishProduct;
