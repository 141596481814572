import React,{useState} from "react"
import { Row, Modal, Button, message, Tooltip } from "antd"
import CustomerDetails from './CustomerDetails'
import OrderDetails from './OrderDetails'
import ProgressBar from './ProgressBar'
import isDev from '../../server'
import axios from "axios"
import './playground.scss'

const serverUrl = isDev()

const OrderModal = ({locationGroupUuid,visible,setVisible,setOrdersListing, isB2b}) => {

    const [current,setCurrent] = useState(0)
    const [select,setSelect] = useState(null)
    const [billingSelect, setBillingSelect] = useState(null);
    const [isCartEmpty,setIsCartEmpty] = useState(true)
    const [customerUserName,setCustomerUserName] = useState(null)
    const [loading,setLoading] = useState(false)
    const [orderNote, setOrderNote] = useState('')
    const [shippingAddressList,setShippingAddressList] = useState([]);
    const [billingAddressList, setBillingAddressList] = useState([]);
    const [sameShippingAndBilling, setSameShippingAndBilling] = useState(false);

    //triggers when next button is clicked
    const handleNext = () => {
        setCurrent(1);
    }

    const onChangeOrderNote = () => {
        setLoading(true)
        axios({
            method:"POST",
            url:`${serverUrl}/dealshub/checkout/select-offline-address/`,
            data:{
                username: customerUserName,
                shippingAddressUuid: sameShippingAndBilling ? billingAddressList[billingSelect].uuid : shippingAddressList[select].uuid,
                is_b2b: isB2b,
                billingAddressUuid: isB2b ? billingAddressList[billingSelect].uuid : "", 
            },
            headers:{
                Authorization:`JWT ${localStorage.getItem("token")}`
            }
        })
        .then(response=>{
            if(response.data.status !== 200){
                message.error("Something Went Wrong!");
            }
            else{
                axios({
                    method: "POST",
                    url: `${serverUrl}/dealshub/add-offline-additional-note/`,
                    data: {
                        locationGroupUuid: locationGroupUuid,
                        username: customerUserName,
                        additional_note: orderNote
                    },
                    headers: {
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                })
                .then(response => {
                    if (response.data.status === 200) {
                        handlePlaceOrder()
                    }
                    else {
                        message.error("Something went worng!")
                    }
                })
            }
        })
    }


    //triggers when place order button is clicked
    const handlePlaceOrder = () => {
        axios({
            method:"POST",
            url:`${serverUrl}/dealshub/checkout/place-offline-order/`,
            data:{
                locationGroupUuid: locationGroupUuid,
                username: customerUserName
            },
            headers:{
                Authorization:`JWT ${localStorage.getItem("token")}`
            }
        })
        .then(response=>{
            setLoading(false)
            if(response.data.status === 200){
                // onChangeOrderNote(orderNote)
                message.success("Offline order placed!")
                setOrdersListing()
            }
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else
            message.error("Something went wrong!")
            setCurrent(0);setSelect(null);setCustomerUserName(null);setVisible();
            setBillingSelect(null);
            // setLoading(false)
        })
    }
    



    return (
        <React.Fragment>
            <Modal 
                visible={visible} 
                footer={null}
                style={{display: "flex", justifyContent: "center"}}
                width="60%"  
                destroyOnClose = {true}
                onCancel={() => {setCurrent(0);setBillingSelect(null);setSelect(null);setCustomerUserName(null);setVisible();}}  
            >
                <ProgressBar 
                    current={current} 
                    locationGroupUuid={locationGroupUuid} 
                />
                <CustomerDetails 
                    locationGroupUuid={locationGroupUuid} 
                    userName={customerUserName}
                    setUserName={setCustomerUserName} 
                    selected={select} 
                    setSelected={setSelect} 
                    billingSelected = {billingSelect}
                    setBillingSelected = {setBillingSelect}
                    isB2b={isB2b}
                    shippingAddressList={shippingAddressList}
                    setShippingAddressList={setShippingAddressList}
                    billingAddressList={billingAddressList}
                    setBillingAddressList={setBillingAddressList}
                    sameShippingAndBilling={sameShippingAndBilling}
                    setSameShippingAndBilling={setSameShippingAndBilling}
                />
                <OrderDetails 
                    current={current===1} 
                    locationGroupUuid={locationGroupUuid} 
                    customerUserName={customerUserName}
                    setIsCartEmpty={setIsCartEmpty}
                    orderNote={orderNote}
                    setOrderNote = {setOrderNote}
                />
                <Row style={{marginTop:"20px"}}>
                    <Tooltip 
                        title={current 
                            ? isCartEmpty 
                                ? "You need to add products in the cart to place the order" 
                                : "" 
                            : customerUserName === null 
                                ? "You need to select a customer" 
                                : isB2b ? (
                                    (!sameShippingAndBilling && select === null) || billingSelect === null ?
                                    "You need to select shipping and billing address" : ""
                                ): select === null
                                        ? "You need to select an address"
                                        : ""
                        }
                    >
                        <Button 
                            type="primary" 
                            style={{float:"right"}} 
                            onClick={current ? onChangeOrderNote : handleNext}
                            disabled={current ? isCartEmpty || 
                                                isB2b ? ((!sameShippingAndBilling && select === null) || billingSelect === null) 
                                                    : (select === null)
                                        : customerUserName === null || 
                                                isB2b ? ((!sameShippingAndBilling && select === null) || billingSelect === null) 
                                                    : (select === null)
                                        }
                            loading={current ? loading : ""}
                        >
                            {current ? "Place Order" : "Next"}
                        </Button>
                    </Tooltip>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default function OfflineOrder(props) {
    return (
        <React.Fragment>
            
            <Row style={{ background: "#36404A", padding: 0 }} type="flex" justify="center" align="middle">
                <OrderModal 
                    visible={props.visible} 
                    locationGroupUuid={props.locationGroupUuid} 
                    setVisible={props.setVisible} 
                    setOrdersListing={props.setOrdersListing}
                    isB2b={props.isB2b}
                />
            </Row>
        </React.Fragment>
    )
}