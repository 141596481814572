import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Typography,
    Tabs,
    Input,
    Upload,
    Icon,
    Button,
    message,
    AutoComplete,
    Select,
    Card, Avatar
} from "antd";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import styles from './ComposeArticle.module.scss'
import axios from "axios";
import isDev from '../../server';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { UploadOutlined } from '@ant-design/icons'
import "./../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { v4 as uuidV4 } from 'uuid'

const serverUrl = isDev();
const { Title, Text } = Typography
const { TabPane } = Tabs;
const { TextArea } = Input
const { Option } = Select;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const ComposeArticle = (props) => {

    const [title, setTitle] = useState("")
    const [summary, setSummary] = useState("")
    const [author, setAuthor] = useState("")
    const [coverImg, setCoverImg] = useState([])
    const [fetchedProducts, setFetchedProducts] = useState([])
    const [productTerm, setProductTerm] = useState("")
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [htmlString, setHtmlString] = useState("")
    const [productList, setProductList] = useState([])
    const [activeTabKey, setActiveTabKey] = useState("1")

    const fetchBlogDetails = () => {
        axios({
            method: 'POST',
            url: `${serverUrl}/dealshub/fetch-blog-post-details/`,
            data: {
                blogPostUuid: props.id
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                setAuthor(res.data.author)
                setTitle(res.data.title)
                setSummary(res.data.headline)
                setProductList(res.data.productList)
                if (res.data.cover_image === "") {
                    setCoverImg([])
                } else {
                    setCoverImg([{
                        uid: '-2',
                        url: res.data.cover_image
                    }])
                }

                if (res.data.body !== "") {
                    const contentBlock = htmlToDraft(res.data.body);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        setEditorState(EditorState.createWithContent(contentState));
                        setHtmlString(res.data.body)
                    }
                }
            }
        })
    }

    useEffect(() => {
        fetchBlogDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleTitle = (value) => {
        setTitle(value)
    }

    const handleAuthor = (value) => {
        setAuthor(value)
    }

    const handleSummary = (value) => {
        setSummary(value)
    }


    const addProduct = (selectedValue) => {
        axios({
            method: 'POST',
            url: `${serverUrl}/dealshub/add-product-to-blog-post/`,
            data: {
                dealsHubProductUuid: selectedValue,
                blogPostUuid: props.id
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            setProductList([...productList, {
                productImageUrl: res.data.productImageUrl,
                productName: res.data.productName,
                productUuid: res.data.productUuid,
                sellerSKU: res.data.sellerSKU
            }])
        });
    }

    const removeProduct = (uuid) => {
        axios({
            method: 'POST',
            url: `${serverUrl}/dealshub/remove-product-from-blog-post/`,
            data: {
                dealsHubProductUuid: uuid,
                blogPostUuid: props.id
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            setProductList(productList.filter((item) => item.productUuid !== uuid))
        })
    }

    const autocompleteSearch = (searchString) => {
        setProductTerm(searchString)

        if (searchString !== '') {
            axios({
                method: 'POST',
                url: `${serverUrl}/dealshub/search-products/`,
                data: {
                    searchString: searchString,
                    locationGroupUuid: props.locationUuid,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then((response) => {
                setFetchedProducts(response.data.productList)
            });
        }
    };

    const handleSaveArticle = () => {

        if (title.length === 0) {
            message.error('Please Enter a Title')
            return
        } else if (summary.length === 0) {
            message.error('Please Enter a Summary')
            return
        } else if (author.length === 0) {
            message.error('Please Enter Author Name')
            return
        } else if (htmlString.length < 10) {
            message.error('Please Enter a Body for the Blog')
            return
        } else if (coverImg.length !== 1) {
            message.error('Please Add a Cover Image')
            return
        }

        let formData = new FormData();
        formData.append('locationGroupUuid', props.locationUuid);
        formData.append('title', title)
        formData.append('author', author)
        formData.append('headline', summary)
        formData.append('blogPostUuid', props.id)
        if (coverImg[0]?.uid === '-2') {
            formData.append('isCoverImage', "False")
        } else {
            formData.append('isCoverImage', coverImg.length > 0 ? "True" : "False")
            if (coverImg.length > 0)
                formData.append('coverImage', coverImg[0].originFileObj || coverImg[0])
        }

        formData.append('body', htmlString)

        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/edit-blog-post/`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((res) => {
            if (res.data.status === 200) {
                console.log("Blogs Response Data:", JSON.stringify(res.data))
                message.success("Blog saved successfully")
            } else if (res.data.status === 403) {
                message.error("You are not authorized")
            } else message.error("Something went wrong!")
        })
    }

    const onEditorStateChange = editorState => {
        setEditorState(editorState)
        setHtmlString(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    function uploadImageCallBack(file) {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', `${serverUrl}/dealshub/upload-blog-post-image/`);
                xhr.setRequestHeader('Authorization', `JWT ${localStorage.getItem('token')}`);
                const data = new FormData();
                data.append('blogPostImage', file);
                xhr.send(data);
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve({
                        data: {
                            link: response.imageUrl
                        }
                    });
                });
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText);
                    reject(error);
                });
            }
        );
    }

    return (
        <Row className="orders-account-manager-mobile-view-page-container"
            style={{ marginRight: '10px' }}
            gutter={[16, 16]}>
            <Col span={24} className="title-container" style={{ paddingRight: '0px' }}>
                <Title level={4} className="main-title">
                    Blog
                </Title>
            </Col>
            <Col span={24} style={{ backgroundColor: '#36404A', marginTop: '24px', padding: '0px 12px 12px 12px' }}>
                <Tabs
                    activeKey={activeTabKey}
                    onTabClick={(key) => setActiveTabKey(key)}
                >
                    <TabPane tab="Compose Article" key="1">
                        <Col span={12}>
                            <Text className="input-header">Title</Text>
                            <br />
                            <Input
                                style={{ marginTop: '10px' }}
                                value={title}
                                placeholder="Enter title for your Blog"
                                maxLength={100}
                                onChange={(e) => handleTitle(e.target.value)}
                            />
                        </Col>
                        <Col span={12}>
                            <Text className="input-header">Author</Text>
                            <br />
                            <Input
                                style={{ marginTop: '10px' }}
                                value={author}
                                placeholder="Enter author name"
                                maxLength={100}
                                onChange={(e) => handleAuthor(e.target.value)}
                            />
                        </Col>
                        <Col span={24} style={{ marginTop: '24px', padding: '0px' }}>
                            <Col span={12}>
                                <Col span={24}
                                    style={{ padding: '0px', justifyContent: 'space-between', display: 'flex' }}>
                                    <Text className="input-header">Article Summary</Text>
                                    <Text style={{ color: '#A8A8A8' }}>Character limit: {summary?.length}/255</Text>
                                </Col>
                                <Col span={24} style={{ padding: '0px' }}>
                                    <TextArea
                                        value={summary}
                                        onChange={(e) => handleSummary(e.target.value)}
                                        style={{ marginTop: "10px", height: '105px' }}
                                        maxLength={255}
                                    />
                                </Col>
                            </Col>
                            <Col span={12}>
                                <Upload
                                    beforeUpload={() => false}
                                    fileList={coverImg}
                                    onChange={(e) => {
                                        setCoverImg(e.fileList)
                                    }}
                                    multiple={false}
                                    onPreview={() => setActiveTabKey("2")}
                                    listType="picture-card"
                                >
                                    {(coverImg.length !== 1) && <Button>
                                        <UploadOutlined />
                                        Upload Cover Image
                                    </Button>}
                                </Upload>
                            </Col>
                        </Col>
                        <Col span={24} style={{ marginTop: '24px' }}>
                            <Col span={24} style={{ padding: '0px' }}>
                                <Text className="input-header">Article Body</Text>
                            </Col>

                            <Col span={24} style={{ padding: '0px' }}>
                                <div>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            image: {
                                                uploadCallback: uploadImageCallBack,
                                                alt: { present: true, mandatory: false },
                                                defaultSize: { width: "100%", height: "100%" }
                                            },
                                        }}
                                    />
                                </div>

                            </Col>
                        </Col>
                        <Col span={24} style={{ marginTop: '24px' }}>
                            <Text className="input-header">Link Products</Text>
                            <br />
                            <AutoComplete
                                dataSource={fetchedProducts}
                                value={productTerm}
                                onSelect={(selectedValue) => {
                                    addProduct(selectedValue);
                                }}
                                onSearch={autocompleteSearch}
                                placeholder='Search for products'
                                style={{ width: '100%', marginTop: '10px' }}
                            >
                                {fetchedProducts && fetchedProducts.map((list) => {
                                    return (
                                        <Option key={list.uuid} value={list.uuid}>
                                            {list.name}
                                        </Option>
                                    );
                                })}
                            </AutoComplete>
                            <Col span={24} style={{ padding: '0px' }}>
                                {productList?.length > 0 && productList.map((item, index) => {
                                    return (
                                        <Col span={6}
                                            key={index}
                                        >
                                            <Card
                                                title={
                                                    <>
                                                        <Avatar shape={"square"} style={{ height: '28px', width: '28px' }}
                                                            src={item.productImageUrl} />
                                                        <Text style={{ marginLeft: "12px" }}>{item.productName}</Text>
                                                    </>
                                                }
                                                size={"small"}
                                                extra={<Icon type="close"
                                                    onClick={() => removeProduct(item.productUuid)} />}
                                            //style={{width: '250px', height: '82px'}}
                                            >
                                                <Text>ID: </Text>
                                                <Text>{item.sellerSKU}</Text>
                                            </Card>
                                        </Col>
                                    )
                                })}

                            </Col>

                        </Col>
                        <Col span={24} style={{ marginTop: '8px', textAlign: 'right' }}>
                            <Button onClick={() => handleSaveArticle()}
                                style={{
                                    border: "none",
                                    color: "#F1F1F1",
                                    borderRadius: '4px',
                                    backgroundColor: '#F44336',
                                    height: '45px',
                                    width: '140px'
                                }}
                            >
                                SAVE
                            </Button>
                        </Col>
                    </TabPane>
                    <TabPane tab="Preview" key="2" forceRender={true}>
                        <div style={{ backgroundColor: 'white' }}>
                            <PreviewTab productList={productList} title={title} author={author} body={htmlString} cover_image={coverImg} />
                        </div>
                    </TabPane>
                </Tabs>
            </Col>
        </Row>
    )
}

export default ComposeArticle



function PreviewTab({ cover_image, title, author, body, productList }) {

    const screenWidth = window.innerWidth
    document.title = title
    const [src, setSrc] = useState('')

    const setSrsAsync = async (cover_image) => {
        if (cover_image?.[0]?.url !== undefined) {
            setSrc(cover_image[0].url)
        }
        else setSrc(await getBase64(cover_image?.[0]?.originFileObj));
    }

    useEffect(() => {
        if (cover_image.length > 0)
            setSrsAsync(cover_image);
    }, [cover_image])

    return (
        <>
            <Row style={{ padding: "30px 100px" }}>
                <Col span={24}>
                    {cover_image.length > 0 &&
                        <img
                            src={src}
                            style={{
                                maxHeight: "400px",
                                width: "100%",
                                objectFit: "contain",
                            }}
                            alt="Blog Coverr"
                        />}
                </Col>
                <Col
                    span={24}
                    style={{ marginTop: "40px", padding: "0px 92px 20px 92px" }}
                >
                    <Col
                        span={24}
                        style={{
                            padding: "0px 0px 30px 0px",
                            borderBottom: "1px solid #E8E7E7",
                        }}
                    >
                        <Text
                            style={{
                                color: "#2D3748",
                                fontWeight: "bold",
                                fontSize: "45px",
                            }}
                        >
                            {title}
                        </Text>
                        <br />
                        <Text
                            style={{
                                color: "#2D3748",
                                fontWeight: "bold",
                                fontSize: "18px",
                            }}
                        >
                            Written by {author}
                        </Text>
                        <Text
                            style={{
                                color: "#718096",
                                fontSize: "18px",
                                marginLeft: "20px",
                            }}
                        >
                            {new Date().toLocaleDateString()}
                        </Text>
                    </Col>
                    <Col span={24} style={{ marginTop: "30px" }}>
                        <div
                            className={styles.content}
                            dangerouslySetInnerHTML={{ __html: body }}
                        />
                    </Col>
                </Col>
                <Col
                    span={24}
                    style={{
                        marginTop: "60px",
                        padding: "0px 59px 20px 59px",
                        textAlign: "center",
                    }}
                >
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Text
                            style={{
                                fontWeight: 500,
                                fontSize: "28px",
                                color: "#222222",
                            }}
                        >
                            Get it here...
                        </Text>
                    </Col>
                    <Col span={24} style={{ marginTop: "30px" }}>
                        {productList.map((item) => (
                            <Col
                                span={Math.floor(
                                    24 / Math.floor((screenWidth - 200) / 320)
                                )}
                                style={{
                                    padding: "0px 20px 20px 0px",
                                }}
                                key={uuidV4()}
                            >
                                <Card
                                    style={{
                                        cursor: "pointer",
                                        maxWidth: "258px",
                                        padding: "15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 4px rgba(192, 189, 189, 0.25)",
                                        backgroundColor: 'white'
                                    }}
                                    cover={
                                        <img
                                            alt={item.productName}
                                            src={item.productImageUrl}
                                            style={{
                                                maxWidth: "100%",
                                                height: 180,
                                            }}
                                        />
                                    }
                                    bodyStyle={{
                                        padding: "20px 0px 0px",
                                        backgroundColor: 'white'
                                    }}
                                >
                                    <Card.Meta title={item.productName} />
                                </Card>
                            </Col>
                        ))}
                    </Col>
                </Col>
            </Row>
        </>
    )
}