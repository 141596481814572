import React, { Component } from "react";
import {
  Row,
  Col,
  Collapse,
  Icon,
  Button,
  Affix,
  message,
  Tooltip,
  Typography,
  Select,
} from "antd";
import InputField from "../datainputs/InputField";
// import ImageManager from "../datainputs/image-manager/ImageManager";
import FormHeader from "../datainputs/FormHeader";
// import InputNumberDimensions3 from "../datainputs/InputNumberDimesions3";
import "./channel-page.scss";
import EditorBox from "../EditorBox";
import Attributes from "../datainputs/attributes/Attributes";
import { connect } from "react-redux";
import {
  fetchAmazonUae,
  handelChangeUae,
  saveUaeForm,
} from "../../../actions/channelProduct";
import isDev from "../../../server";
import SimpleReactValidator from "simple-react-validator";

const serverUrl = isDev(); 
const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

class AmazonUkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: null,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Field is required",
        integer: "Must be number",
        default: "Validation has failed!", // will override all messages
      },
    });
  }

  componentDidMount = () => {
    this.props.fetchAmazonUae(this.props.product_pk);
  };

  handelChangeInputOut = (e) => {
    this.props.handelChangeUae({
      ...this.props.amazon_uae,
      [e.target.name]: e.target.value,
    });
  };

  handelChangeInput = (e) => {
    this.props.handelChangeUae({
      ...this.props.amazon_uae,
      channel_product_json: {
        ...this.props.amazon_uae.channel_product_json,
        [e.target.name]: e.target.value,
      },
    });
  };

  handelSave = () => {
    if (this.validator.allValid()) {
      this.props.amazon_uae.channel_product_json.product_attribute_list = this.state.attributes;

      this.props.saveUaeForm(this.props.product_pk, this.props.amazon_uae);
    } else {
      message.info("Fill Required data");
    }
  };

  getAttributesData = (data) => {
    this.setState({ attributes: data });
  };

  getEditorText = (value) => {
    this.props.handelChangeUae({
      ...this.props.amazon_uae,
      channel_product_json: {
        ...this.props.amazon_uae.channel_product_json,
        ["product_description"]: value,
      },
    });
  };

  error = (status) => {
    if (status == 500) message.error("Internal Server Error");
    else if (status == 409) message.info("Duplicate Product Detected");
    else if (status == 403) message.error("access denied");
    else {
      message.success(" Saved successfully! ");
    }
  };
  setAttributeFlag = 1;
  componentWillReceiveProps = (props) => {
    if (
      props.amazon_uae.channel_product_json &&
      this.setAttributeFlag &&
      props.amazon_uae.channel_product_json.product_attribute_list &&
      props.amazon_uae.channel_product_json.product_attribute_list.length
    ) {
      this.setState({
        attributes:
          props.amazon_uae.channel_product_json.product_attribute_list,
      });

      this.setAttributeFlag = 0;
    }

    if (props.amazon_uae.status !== "") {
      this.error(props.amazon_uae.status);
    }
  };

  handleAmazonClick = () => {
    let data = {
      product_pk_list: [...[],this.props.product_pk],
      is_partial:true
    }
    let dataImage = {
      product_pk_list: [...[],this.props.product_pk]
    }
    fetch(`${serverUrl}/mws/push-products-amazon-uae/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
    .then(response=>response.json())
    .then(data=>{
      if(data.status === 200)
        message.success("Successfully published please check reports in Reports Page for feed product id = " +
        data.feed_submission_id)
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
      else  
        message.error("something went wrong!")
    })
    fetch(`${serverUrl}/mws/push-product-images-amazon-uae/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(dataImage),
    })
    .then(response=>response.json())
    .then(data=>{
      if(data.status === 200)
        message.success("Successfully published please check reports in Reports Page for feed product id = " +
        data.feed_submission_id)
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }
      else  
        message.error("something went wrong!")
    })
  }

  render() {
    const { product_id, material_type } = this.props.amazon_uae;
    const {
      feed_product_type,
      product_name,
      update_delete,
      recommended_browse_nodes,
      product_description,
      is_active,
      http_linsk,
      product_attribute_list,
      http_link,
      category,
      sub_category,
      was_price,
      sale_price,
      stock,
    } = this.props.amazon_uae.channel_product_json;

    if (!this.props.drawer) this.props.sendImages(this.props.amazon_uae.images);

    return (
      <React.Fragment>
        
        {/* Amazon Button */}
        <Tooltip placement="top" title="Amazon UAE">
          <Affix style={{ position: "fixed", bottom: 20, right: 10 }}>
            <Button
              type="primary"
              shape="circle"
              icon="amazon"
              size="large"
              style={{background:"#F3A847",borderColor:"#F3A847"}}
              onClick={this.handleAmazonClick}
            ></Button>
          </Affix>
        </Tooltip>
        
        {!this.props.drawer && (
          <Tooltip placement="top" title="Save">
            <Affix style={{ position: "fixed", bottom: 20, right: 55 }}>
              <Button
                type="danger"
                shape="circle"
                icon="save"
                size="large"
                onClick={this.handelSave}
              ></Button>
            </Affix>
          </Tooltip>
        )}


        <Tooltip placement="top" title="Channel Forms">
          <Affix style={{ position: "fixed", bottom: 20, right: 100 }}>
            <Button
              type="primary"
              shape="circle"
              icon="border-right"
              size="large"
              onClick={this.props.ToggelDrawer}
            ></Button>
          </Affix>
        </Tooltip>
        
        

        <Row gutter={[40, 40]}>
          <Col className="gutter-row" span={24}>
            {/* <div className="gutter-box">col-6</div> */}

            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel header="GENERAL" key="1" style={this.customPanelStyle}>
                    <Row
                      gutter={[24, 24]}
                      style={{
                        maxHeight: "70vh",
                        overflow: "scroll",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <Col sm={24}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "product_name",
                            value: product_name,
                          }}
                          onChange={this.handelChangeInput}
                          labelText="Product Name"
                          errormsg={
                            this.validator &&
                            this.validator.message(
                              "product_name",
                              product_name,
                              "required"
                            )
                          }
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "product_id",
                            value: product_id,
                            disabled: true,
                          }}
                          labelText="Product ID"
                          onChange={this.handelChangeInputOut}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{ size: "large" }}
                          input={{
                            size: "large",
                            name: "category",
                            value: category,
                          }}
                          labelText="Category"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{ size: "large" }}
                          input={{
                            size: "large",
                            name: "sub_category",
                            value: sub_category,
                          }}
                          labelText="Sub Category"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "sale_price",
                            value: sale_price,
                          }}
                          labelText="Sale Price"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "was_price",
                            value: was_price,
                          }}
                          labelText="Was Price"
                          onChange={this.handelChangeInput}
                        />
                      </Col>


                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{ size: "large" }}
                          input={{
                            size: "large",
                            name: "stock",
                            value: stock,
                            type: "number",
                          }}
                          labelText="Stock"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{ size: "large" }}
                          input={{
                            size: "large",
                            name: "feed_product_type",
                            value: feed_product_type,
                          }}
                          labelText="Feed Product Type"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      {/* <Col sm={12}>
                    
                        <Text>Update / Delete</Text>
                        <Select
                          defaultValue="update"
                          size="large"
                          style={{ width: "100%" }}
                          onChange={() => { }}
                        >
                          <Option value="update">Update</Option>
                          <Option value="delete">Delete</Option>
                        </Select>
                      </Col> */}
                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "recommended_browse_nodes",
                            value: recommended_browse_nodes,
                          }}
                          labelText="Recommended Browse Nodes"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                    </Row>
                    <Col sm={24}>
                      <InputField
                        label={{ type: "secondary" }}
                        input={{
                          size: "large",
                          name: "http_link",
                          value: http_link,
                        }}
                        labelText="HTTP Link"
                        onChange={this.handelChangeInput}
                      />
                    </Col>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          {/* <Col className="gutter-row" span={24}>
                    <Row gutter={[24, 24]} className="gutter-box">
                        <Col>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['0']}
                                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                                style={this.collapsBlock}
                            >

                                <Panel header="DIMENSIONS" key="1" style={this.customPanelStyle}>

                                    <Row gutter={[24, 24]} style={{ margin: "10px 0px 10px 0px" }}>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="Export Carton Qty ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="Export Carton CBM ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="Product Dimension ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="GiftBox ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                    </Row>

                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Col> */}
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["0"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel
                    header="ATTRIBUTES"
                    key="1"
                    style={this.customPanelStyle}
                  >
                    <Row
                      gutter={[24, 24]}
                      style={{ margin: "10px 0px 10px 0px" }}
                    >
                      <Col sm={24}>
                        <Attributes
                          sendList={this.getAttributesData}
                          initialdata={this.state.attributes || []}
                          addbuttontitle="Add More Attributes"
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          {/* <Col className="gutter-row" span={24}>
                    <Row gutter={[24, 24]} className="gutter-box">
                        <Col>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['0']}
                                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                                style={this.collapsBlock}
                            >

                                <Panel header="SPECIAL FEATURES" key="1" style={this.customPanelStyle}>

                                    <Row gutter={[24, 24]} style={{ margin: "10px 0px 10px 0px" }}>
                                        <Col sm={24}>
                                            <Attributes getData={this.getFeaturesData} addbuttontitle="Add More Features" />
                                        </Col>
                                    </Row>

                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Col> */}
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Row
                  gutter={[24, 24]}
                  style={{ margin: "0px", minHeight: "230px" }}
                >
                  <Col style={{ padding: "0px" }} sm={24}>
                    <FormHeader header="Product Description" />
                  </Col>
                  <Col sm={24}>
                    <EditorBox
                      value={product_description}
                      getText={this.getEditorText}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ channelProductsUae }) => {
  return {
    ...channelProductsUae,
  };
};

export default connect(mapStateToProps, {
  fetchAmazonUae,
  handelChangeUae,
  saveUaeForm,
})(AmazonUkForm);
