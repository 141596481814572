import React, { Component } from "react";
import { Collapse, Icon, Row, Col, Select, Typography, Switch } from "antd";
import "./varients-info.scss";
import InputField from "../../components/datainputs/InputField";
import InputNumberDimensions3 from "../../components/datainputs/InputNumberDimesions3";

//formik
import { withFormik } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { handelEditProductDetails } from "../../../actions/index";

import "./varients-info.scss";

const { Panel } = Collapse;

const { Option } = Select;
const { Text } = Typography;

class DealshubProductVarientSection extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    customPanelStyle = {
        borderRadius: 4,
        // marginBottom: 24,
        border: 0,
        overflow: "hidden"
        // padding: "10px 0px 10px 0px",
    };

    collapsedStyle = {
        maxHeight: "50vh",
        overflow: "scroll",
        paddingTop: "5px"
    };

    collapsBlock = {
        margin: 0
    };

    onChange = e => {
        this.props.handelEditProductDetails({
            ...this.props.productDetails,
            ["product_id_type"]: e
        });
        this.props.validator.showMessages();
    };

    handleCODAllowed = e => {
        this.props.handelEditProductDetails({
            ...this.props.productDetails,
            ["is_cod_allowed"]: e
        });
        this.props.validator.showMessages();
    }

    onBlur = () => {

    };

    onFocus = () => {

    };

    onSearch = val => {

    };

    handleChange = e => {

        this.props.handelEditProductDetails({
            ...this.props.productDetails,
            [e.target.name]: e.target.value
        });

        this.props.validator.showMessages();
    };

    render() {
        const {
            was_price,
            now_price,
            stock,
            dealshub_price_permission,
            dealshub_stock_permission,
            is_cod_allowed
        } = this.props.productDetails;

        //.log(this.props.productDetails, "details");

        return (
            <React.Fragment>
                <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    expandIcon={({ isActive }) => (
                        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                    )}
                    style={this.collapsBlock}
                >
                    <Panel header="Dealshub : Variant" key="2" style={this.customPanelStyle}>
                        <Row gutter={[24, 24]} style={this.collapsedStyle}>

                            <Col sm={12}>
                                <InputField
                                    label={{ type: "secondary" }}
                                    onChange={this.handleChange}
                                    input={{
                                        size: "large",
                                        name: "was_price",
                                        type: "number",
                                        value: was_price,
                                        disabled: this.props.disabled || !dealshub_price_permission
                                    }}
                                    labelText="Was Price"
                                />
                            </Col>


                            <Col sm={12}>
                                <InputField
                                    label={{ type: "secondary" }}
                                    onChange={this.handleChange}
                                    input={{
                                        size: "large",
                                        name: "now_price",
                                        type: "number",
                                        value: now_price,
                                        disabled: this.props.disabled || !dealshub_price_permission
                                    }}
                                    labelText="Now Price"
                                />
                            </Col>

                            <Col sm={12}>
                                <InputField
                                    label={{ type: "secondary" }}
                                    onChange={this.handleChange}
                                    input={{
                                        size: "large",
                                        name: "stock",
                                        type: "number",
                                        value: stock,
                                        disabled: this.props.disabled || !dealshub_stock_permission
                                    }}
                                    labelText="Stock"
                                />
                            </Col>

                            <Col sm={12}>
                                <Row type="flex" align="middle">
                                    <Col style={{ marginTop: "30px" }}>
                                        <Text>COD Allowed </Text>
                                        <Switch disabled={this.props.disabled} checkedChildren="Yes" unCheckedChildren="No" onChange={this.handleCODAllowed} checked={is_cod_allowed} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ productDetails }) => {
    return {
        ...productDetails
    };
};

export default connect(mapStateToProps, { handelEditProductDetails })(
    DealshubProductVarientSection
);
