import React from "react";
import {Tag , Icon , Typography} from "antd";


const {Text} = Typography;

function IconChips(props){
    return (
        <React.Fragment>
             <div 
               style = {{
                   position : "relative",
                   padding : "0px 10px 1px 10px",
                   cursor: props.disabled ? 'not-allowed' : 'pointer'
               }}
               onClick={props.disabled ? null :props.onClick}
             >
                 {/* overlay */}
                 <div 
                style = {{
                    position : "absolute",
                    top : "0px",
                    left : "0px",
                    width : "100%",
                    height : "100%",
                    backgroundColor : props.color,
                    opacity : 0.2,
                    //borderColor: props.borderclr
                }}></div>


                 <Icon type = {props.icon} style = {{
                     color : props.color,
                     fontSize : "10px"
                 }} />
                 <Text style = {{
                     color : 'white',
                     fontSize : "11px",
                     marginLeft : "5px"
                 }}>
                     {props.text}
                </Text>
             </div>
        </React.Fragment>
    )
}

export default IconChips;