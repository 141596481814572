import React, { Component } from "react";
import LogoUpload from "./components/LogoUpload";
import axios from "axios";
import {
  Row,
  Col,
  Typography,
  Input,
  Button,
  Spin,
  message,
  Select,
  Divider
} from "antd";
import "rc-color-picker/assets/index.css";
import "./company-profile.scss";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import InputField from "../components/datainputs/InputField";
import InputLink from "../components/datainputs/input-links/InputLink";
import ColorComponent from "./components/ColorComponent";
import { Ellipsis } from "react-spinners-css";
import isDev from "../../server";
import { connect } from "react-redux";
import {
  validateLink,
  validateLeadingSpaces,
  validateTrailingSpaces,
  validateMiddleSpaces,
  validateMobileNumber
} from "../../constants/FieldValidators"
import ReactQuill from "react-quill";
import { locationUuidConst } from "constants/locationUuid";

const serverUrl = isDev();
const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      contact_info: [],
      whatsapp_info: '',
      email_info: '',
      address: '',
      facebook_link: '',
      twitter_link: '',
      instagram_link: '',
      youtube_link: '',
      crunchbase_link: '',
      linkedin_link: '',
      loading: false,
      uploadingInProgress: false,
      footerLogoUploadingInProgress: false,
      isDeleteOn: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      color_scheme: [],
      fileListUpdated: false,
      footer_logo: [],
      footerLogoUpdated: false,
      companyDataUploading: false,
      seo_title: '',
      seo_long_description: '',
      seo_short_description: '',
      seo_google_meta: '',
      seo_google_meta_title: ''

    };
  }

  componentDidMount = () => {
    this.setState({ loading: true })
    axios.post(
      `${serverUrl}/fetch-company-profile/`, {
      locationGroupUuid: this.props.locationUuid
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    }).then(response => {
      if (response.data.status === 200) {
        this.setState({
          //name: response.data.company_data.name,
          contact_info: response.data.company_data.contact_info,
          whatsapp_info: response.data.company_data.whatsapp_info,
          color_scheme: response.data.company_data.color_scheme,
          email_info: response.data.company_data.email_info,
          address: response.data.company_data.address,
          facebook_link: response.data.company_data.facebook_link,
          twitter_link: response.data.company_data.twitter_link,
          instagram_link: response.data.company_data.instagram_link,
          youtube_link: response.data.company_data.youtube_link,
          crunchbase_link: response.data.company_data.crunchbase_link,
          linkedin_link: response.data.company_data.linkedin_link,
          fileList: response.data.company_data.logo,
          footer_logo: response.data.company_data.footer_logo,
          fileListUpdated: true,
          footerLogoUpdated: true,
          loading: false,
          seo_title: response.data.seo_title,
          seo_short_description: response.data.seo_short_description,
          seo_long_description: response.data.seo_long_description,
          seo_google_meta: response.data.seo_google_meta,
          seo_google_meta_title: response.data.seo_google_meta_title
        });
      }
      else if (response.data.status === 405) {
        message.info("You are not authorised!")
      }
      else {
        message.error('Something went Wrong!');
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.locationUuid !== this.props.locationUuid) {
      this.setState({ loading: true });
      axios.post(
        `${serverUrl}/fetch-company-profile/`, {
        locationGroupUuid: this.props.locationUuid
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
      }).then(response => {
        if (response.data.status === 200) {
          this.setState({
            //name: response.data.company_data.name,
            contact_info: response.data.company_data.contact_info,
            whatsapp_info: response.data.company_data.whatsapp_info,
            color_scheme: response.data.company_data.color_scheme,
            email_info: response.data.company_data.email_info,
            address: response.data.company_data.address,
            facebook_link: response.data.company_data.facebook_link,
            twitter_link: response.data.company_data.twitter_link,
            instagram_link: response.data.company_data.instagram_link,
            youtube_link: response.data.company_data.youtube_link,
            crunchbase_link: response.data.company_data.crunchbase_link,
            linkedin_link: response.data.company_data.linkedin_link,
            fileList: response.data.company_data.logo,
            footer_logo: response.data.company_data.footer_logo,
            fileListUpdated: true,
            footerLogoUpdated: true,
            loading: false,
            seo_title: response.data.seo_title,
            seo_short_description: response.data.seo_short_description,
            seo_long_description: response.data.seo_long_description,
            seo_google_meta: response.data.seo_google_meta,
            seo_google_meta_title: response.data.seo_google_meta_title
          });
        }
        else if (response.data.status === 405) {
          message.info("You are not authorised!")
        }
        else {
          message.error('Something went Wrong!');
        }
      });
    }
  }

  changeColor = (item, color) => {
    let color_scheme = this.state.color_scheme;
    color_scheme[item] = color.color
    this.setState({ color_scheme: color_scheme });
  };

  validateEmailInfo = () => {
    let cnt = 0, emailId = this.state.email_info;

    if (emailId.length === 0) {
      return true;
    } else if (emailId.length < 3 || validateLeadingSpaces(emailId) || validateTrailingSpaces(emailId) || validateMiddleSpaces(emailId)) {
      message.error('Invalid Email!')
      return false;
    } else {
      for (let i = 0; i < emailId.length; i++) {
        if (emailId[i] == '@')
          cnt++;
      }
      if (cnt !== 1) {
        message.error('Invalid Email!')
        return false;
      } else {
        return true;
      }
    }
  }

  validateLinks = () => {
    if (
      validateLink(this.state.facebook_link) &&
      validateLink(this.state.twitter_link) &&
      validateLink(this.state.instagram_link) &&
      validateLink(this.state.youtube_link) &&
      validateLink(this.state.crunchbase_link) &&
      validateLink(this.state.linkedin_link)
    ) {
      return true
    } else {
      message.error("Invalid Social Media Links!")
      return false;
    }
  }

  validateWhatsAppInfo = () => {
    let whatsapp_info = this.state.whatsapp_info;
    if (!validateMobileNumber(whatsapp_info)) {
      message.error("Invalid WhatsApp number");
      return false;
    } else {
      return true;
    }
  }

  saveCompanyProfileData = () => {
    if (this.validateLinks() && this.validateEmailInfo() && this.validateWhatsAppInfo()) {
      this.setState({
        companyDataUploading: true
      }, () => {
        axios.post(`${serverUrl}/save-company-profile/`, {
          company_data: {
            //name: this.state.name,
            contact_info: this.state.contact_info,
            whatsapp_info: this.state.whatsapp_info,
            color_scheme: this.state.color_scheme,
            email_info: this.state.email_info,
            address: this.state.address,
            facebook_link: this.state.facebook_link,
            twitter_link: this.state.twitter_link,
            instagram_link: this.state.instagram_link,
            youtube_link: this.state.youtube_link,
            crunchbase_link: this.state.crunchbase_link,
            linkedin_link: this.state.linkedin_link,
            logo_image_url: this.state.fileList.length === 0 ? "" : this.state.fileList[0].url,
            footer_logo_image_url: this.state.footer_logo.length === 0 ? "" : this.state.footer_logo[0].url,
            seo_title: this.state.seo_title,
            seo_short_description: this.state.seo_short_description,
            seo_long_description: this.state.seo_long_description,
            seo_google_meta: this.state.seo_google_meta,
            seo_google_meta_title: this.state.seo_google_meta_title
          },
          locationGroupUuid: this.props.locationUuid,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`
          }
        }).then(response => {
          if (response.data.status === 200) {
            this.setState({ companyDataUploading: false });
            message.success('Changes Applied successfully!');
          }
          else if (response.data.status === 405) {
            message.info("You are not authorised!")
          }
          else {
            message.alert('Could not process your request!');
          }
        }).catch(error => {
          message.error('Something went wrong!');
        });
      });
    }
  }

  getCountryCode = () => {
    if ([locationUuidConst.Geepas_uganda, locationUuidConst.QA_geepas_uganda].includes(this.props.locationUuid))
      return "+256"

    return "+971"
  }

  handleInputChange = e => {
    if (e.target.value.length <= 100) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSeoInputChange = e => {
    this.setState({ [e.target.name]: e.target.value ?? '' });
  };


  handleChangeTags = value => {
    let invalidPhoneNumber = false;
    value.forEach((element) => {
      if (!validateMobileNumber(element)) {
        invalidPhoneNumber = true
      }
    })
    if (!invalidPhoneNumber) {
      this.setState({ contact_info: value });
    } else {
      message.error("Invalid Phone Number");
    }
  }

  changeLogoInParent = (stateVariableName, newState) => {
    this.setState({
      [stateVariableName]: newState
    });
  }

  render() {
    if (this.state.name !== null) {
      if (this.state.loading)
        return (
          <Row
            type="flex"
            align="middle"
            justify="center"
            style={{ width: "100%", height: "100%" }}
          >
            <Ellipsis color="#1ABC9C" size={100} />
          </Row>
        )
      return (
        <React.Fragment>
          <Row className="company-profile-page-main-container">
            <TitleWithBreadCrumb
              title="Company Profile"
              breadcrumb={["OmnyComm", "Company Profile"]}
            />
            <Row gutter={[24, 24]} className="company-page-sub-container">
              <Row style={{ margin: "1em" }} gutter={[24]}>
                <Col span={12}>
                  {this.state.fileListUpdated ? (
                    <LogoUpload
                      dispText="Upload Navbar Logo"
                      Resolution="Resolution: 250px * 100px"
                      fileSize="File Size Range: 10KB - 50KB"
                      fileList={this.state.fileList}
                      stateVariableName="fileList"
                      changeLogoInParent={this.changeLogoInParent}
                      isNavbar={true}
                      locationGroupUuid={this.props.locationUuid}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
                <Col span={12}>
                  {this.state.footerLogoUpdated ? (
                    <LogoUpload
                      dispText="Upload Footer Logo"
                      Resolution="Resolution: 250px * 100px"
                      fileSize="File Size Range: 10KB - 50KB"
                      fileList={this.state.footer_logo}
                      stateVariableName="footer_logo"
                      changeLogoInParent={this.changeLogoInParent}
                      isNavbar={false}
                      locationGroupUuid={this.props.locationUuid}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Col lg={12} sm={24}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <InputLink
                      label={{ type: "secondary" }}
                      input={{
                        size: "large",
                        name: "facebook_link",
                        value: this.state.facebook_link
                      }}
                      onChange={this.handleInputChange}
                      labelText="Facebook"
                    />
                  </Col>
                  <Col span={24}>
                    <InputLink
                      label={{ type: "secondary" }}
                      input={{
                        size: "large",
                        name: "twitter_link",
                        value: this.state.twitter_link
                      }}
                      onChange={this.handleInputChange}
                      labelText="Twitter"
                    />
                  </Col>
                  <Col span={24}>
                    <InputLink
                      label={{ type: "secondary" }}
                      input={{
                        size: "large",
                        name: "instagram_link",
                        value: this.state.instagram_link
                      }}
                      onChange={this.handleInputChange}
                      labelText="Instagram"
                    />
                  </Col>
                  <Col span={24}>
                    <InputLink
                      label={{ type: "secondary" }}
                      input={{
                        size: "large",
                        name: "youtube_link",
                        value: this.state.youtube_link
                      }}
                      onChange={this.handleInputChange}
                      labelText="Youtube"
                    />
                  </Col>
                  <Col span={24}>
                    <InputLink
                      label={{ type: "secondary" }}
                      input={{
                        size: "large",
                        name: "linkedin_link",
                        value: this.state.linkedin_link
                      }}
                      onChange={this.handleInputChange}
                      labelText="LinkedIn"
                    />
                  </Col>
                  <Col span={24}>
                    <InputLink
                      label={{ type: "secondary" }}
                      input={{
                        size: "large",
                        name: "crunchbase_link",
                        value: this.state.crunchbase_link
                      }}
                      onChange={this.handleInputChange}
                      labelText="Crunchbase"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={12} sm={24}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Text type={"secondary"}>Phone Number</Text>
                    <Input.Group compact>
                      <Select
                        size="large"
                        style={{ width: "20%", border: "1px solid #45525f", background: "#3C4853", borderRadius: "4px" }} defaultValue="dubaiCode"
                      >
                        <Option value="dubaiCode">{this.getCountryCode()}</Option>
                      </Select>
                      <Select
                        style={{ width: "80%", border: "1px solid #45525f", background: "#3C4853", borderRadius: "4px" }}
                        dropdownClassName="search-chips"
                        dropdownMatchSelectWidth="false"
                        mode="tags"
                        size="large"
                        onChange={this.handleChangeTags}
                        value={this.state.contact_info}
                      ></Select>
                    </Input.Group>
                  </Col>
                  <Col span={24}>
                    <Text type={"secondary"}>WhatsApp Number</Text>
                    <Input.Group compact>
                      <Select
                        size="large"
                        style={{ width: "20%", border: "1px solid #45525f", background: "#3C4853", borderRadius: "4px" }}
                        defaultValue="dubaiCode"
                      >
                        <Option value="dubaiCode">{this.getCountryCode()}</Option>
                      </Select>
                      <Input
                        style={{ width: "80%", height: "42px", borderRadius: "4px", border: "1px solid #45525f", background: "#3C4853", fontSize: "16px" }}
                        value={this.state.whatsapp_info}
                        name="whatsapp_info"
                        onChange={this.handleInputChange}
                      />
                    </Input.Group>
                  </Col>
                  <Col span={24}>
                    <InputField
                      label={{ type: "secondary" }}
                      input={{
                        size: "large",
                        name: "email_info",
                        value: this.state.email_info
                      }}
                      labelText="Email Address"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col span={24}>
                    <Text className="label">Address</Text>
                    <TextArea
                      rows={6}
                      name="address"
                      value={this.state.address}
                      maxLength={160}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={24} sm={24}>
                <Divider orientation='left'>
                  <h6 className="label">SEO Description</h6>
                </Divider>
              </Col>

              {/*SEO Descriptions*/}
              <Col lg={24} sm={24}>
                <Col span={24}>
                  <InputLink
                    label={{ type: "secondary" }}
                    input={{
                      size: "large",
                      name: "seo_google_meta_title",
                      value: this.state.seo_google_meta_title,
                      disabled: !this.props.miscPermission.includes("edit_SEO")
                    }}
                    onChange={this.handleSeoInputChange}
                    labelText="Google Meta Title"
                  />
                </Col>
                <Col span={24}>
                  <Text className="label">Google Meta Description</Text>
                  <TextArea
                    rows={6}
                    name="seo_google_meta"
                    disabled={!this.props.miscPermission.includes("edit_SEO")}
                    value={this.state.seo_google_meta}
                    onChange={this.handleSeoInputChange}
                  />
                </Col>
                <Col span={24}>
                  <InputLink
                    label={{ type: "secondary" }}
                    input={{
                      size: "large",
                      name: "seo_title",
                      value: this.state.seo_title,
                      disabled: !this.props.miscPermission.includes("edit_SEO")
                    }}
                    onChange={this.handleSeoInputChange}
                    labelText="Description Title"
                  />
                </Col>
                <Col span={24}>
                  <Text className="label">Short Description</Text>
                  <TextArea
                    rows={6}
                    name="seo_short_description"
                    disabled={!this.props.miscPermission.includes("edit_SEO")}
                    value={this.state.seo_short_description}
                    onChange={this.handleSeoInputChange}
                  />
                </Col>
                <Col span={24}>
                  <Text className="label">Long Description</Text>
                  <ReactQuill
                    value={this.state.seo_long_description}
                    theme="snow"
                    readOnly={!this.props.miscPermission.includes("edit_SEO")}
                    modules={{
                      toolbar: [
                        [{ font: [] }],
                        [{ size: ["small", false, "large", "huge"] }],
                        ["bold", "italic", "underline"],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }],
                        [{ 'direction': 'rtl' }],
                        [{ color: [] }, { background: [] }],
                      ],
                    }}
                    formats={["font", "size", "bold", "italic", "underline", "script", "direction", "list", "bullet", "align", "color", "background"]}
                    onChange={(value) => {
                      this.setState({
                        seo_long_description: value,
                      });
                    }}
                    className="react-quill-component"
                    style={{
                      height: "500px",
                      marginTop: "10px",
                      marginBottom: "20px"
                    }}
                  />
                </Col>
              </Col>

              <Col lg={24} sm={24}>
                <Button
                  type="primary"
                  loading={this.state.companyDataUploading}
                  onClick={this.saveCompanyProfileData}
                  style={{
                    width: '20vw',
                    padding: '10px',
                    paddingBottom: '30px',
                    textAlign: 'center',
                    float: 'right'
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <div className="spinner-wrapper">
          <Spin size="large" />
        </div>
      );
    }
  }
}
const mapStateToProps = ({ miscPermission }) => {
  return {
    miscPermission,
  };
};

export default connect(mapStateToProps)(CompanyProfile)
