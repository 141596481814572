import React, { Component } from "react";
import "./varients-info.scss";
import InputField from "../../components/datainputs/InputField";
import InputNumberDimensions3 from "../../components/datainputs/InputNumberDimesions3";
import Attributes from "../../components/datainputs/attributes/Attributes";
import { Collapse, Icon, Row, Col, Select, Typography } from "antd";

//formik
import { withFormik } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { handelEditProductDetailsSpecialFeatures } from "../../../actions/index";

import "./varients-info.scss";

const { Panel } = Collapse;

const { Option } = Select;
const { Text } = Typography;

class SpecialFeatures extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    customPanelStyle = {
        borderRadius: 4,
        // marginBottom: 24,
        border: 0,
        overflow: "hidden"
        // padding: "10px 0px 10px 0px",
    };

    collapsedStyle = {
        maxHeight: "50vh",
        overflow: "scroll",
        paddingTop: "5px"
    };

    handleChange = e => {

        this.props.handelEditProductDetailsSpecialFeatures({
            ...this.props.SpecialFeatures,
            [e.target.name]: { ...this.props.SpecialFeatures[e.target.name], value: e.target.value }
        })
    }

    onChange = (e, itemKey) => {

        this.props.handelEditProductDetailsSpecialFeatures({
            ...this.props.SpecialFeatures,
            [itemKey]: { ...this.props.SpecialFeatures[itemKey], value: e }
        })
    };

    sendList = (e, itemKey) => {
        this.props.handelEditProductDetailsSpecialFeatures({
            ...this.props.SpecialFeatures,
            [itemKey]: { ...this.props.SpecialFeatures[itemKey], value: e }
        })
    }

    collapsBlock = {
        // margin: "10px 0px 10px 0px"
    };

    render() {


        return (
            <React.Fragment>
                <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    expandIcon={({ isActive }) => (
                        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                    )}
                >
                    <Panel
                        header="Special Features : Variant"
                        key="4"
                        style={this.customPanelStyle}
                    >
                        <Row gutter={[24, 24]} style={this.collapsedStyle}>

                            {Object.keys(this.props.SpecialFeatures).map((itemKey, index) => {

                                let item = this.props.SpecialFeatures[itemKey]

                                if (item.type === "text")
                                    return (
                                        <Col sm={24} key={index}>
                                            {/* <input type="text" name={itemKey} value={this.props.SpecialFeatures[itemKey]["value"]} onChange={this.handleChange} /> */}
                                            <InputField
                                                label={{ type: "secondary" }}
                                                onChange={this.handleChange}
                                                input={{
                                                    size: "large",
                                                    name: itemKey,
                                                    value: this.props.SpecialFeatures[itemKey]["value"],
                                                    disabled: this.props.disabled
                                                }}

                                                labelText={item.labelText}
                                            />
                                        </Col>
                                    )
                                else if (item.type === "dropdown")
                                    return (
                                        <Col sm={24} key={index}>
                                            <Text style={{ color: "#ffffff99" }}>
                                                {item.labelText}
                                                {/* <Text style={{ color: "red", fontSize: "8px" }}>
                                                 {this.props.validator &&
                                                    this.props.validator.message(
                                                        "product_id_type",
                                                        product_id_type,
                                                        "required"
                                                    )} 
                                            </Text> */}
                                            </Text>
                                            <Select
                                                showSearch
                                                style={{ width: "100%", background: "#21262a" }}
                                                optionFilterProp="children"
                                                onChange={(e) => this.onChange(e, itemKey)}
                                                onFocus={this.onFocus}
                                                onBlur={this.onBlur}
                                                onSearch={this.onSearch}
                                                disabled={this.props.disabled}
                                                name={itemKey}
                                                size="large"
                                                value={item.value}
                                                className="vitalselect"
                                            >
                                                {item.options.map((subItem, subIndex) => {
                                                    return (
                                                        <Option key={subIndex} selected value={subItem}>
                                                            {subItem}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </Col>

                                    )
                                else if (item.type === "attribute")
                                    return (
                                        <Col sm={24} key={index} style={{ marginTop: "10px" }}>
                                            <Attributes
                                                disabled={this.props.disabled}
                                                initialdata={item.value || []}
                                                sendList={(e) => this.sendList(e, itemKey)}
                                                addbuttontitle={item.labelText}
                                            />
                                        </Col>
                                    )
                            })}

                        </Row>
                    </Panel>
                </Collapse>
            </React.Fragment>
        );
    }
}


const mapStateToProps = ({ productDetails }) => {
    return {
        ...productDetails
    };
};

export default connect(mapStateToProps, { handelEditProductDetailsSpecialFeatures })(
    SpecialFeatures
);

