import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import "./proforma-invoice-download-all-modal.scss"
import InputField from '../datainputs/InputField';

export default class ProformaInvoiceDownloadAllModal extends Component {
    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = e => {

        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {

        this.setState({
            visible: false,
        });
    };
    render() {
        return (

            <React.Fragment>
                <Button type="danger" icon="download" size="medium" onClick={this.showModal}>
                    Download all
                </Button>
                <Modal
                    title="PI Information"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width="50vw"
                    okText="Generate PI"
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="Type of Payment"
                            />
                        </Col>
                        <Col span={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="Percentage of Payment"
                            />
                        </Col>
                        <Col span={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="Delivery Days"
                            />
                        </Col>
                        <Col span={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="Delivery Terms"
                            />
                        </Col>
                        <Col span={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="Inco Terms"
                            />
                        </Col>
                        <Col span={12}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="TTL CNTRS"
                            />
                        </Col>
                        <Col span={24}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="Special Instructions"
                            />
                        </Col>
                        <Col span={24}>
                            <InputField
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "base_product_name",

                                    disabled: this.props.disabled
                                }}
                                onChange={this.handelChange}
                                labelText="Important Points"
                            />
                        </Col>
                    </Row>
                </Modal>
            </React.Fragment>
        )
    }
}
