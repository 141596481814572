const initialState = {
	fetchedOrders: [],
	selectedOrders: [],
};

export default function ordersManagerDetails(state = initialState, action) {
	console.log(action, 'ye mara');

	switch (action.type) {
		case 'SET_ORDER_MANAGER_DETAILS':
			console.log(action.payload);
			return {
				fetchedOrders: [...action.payload],
				selectedOrders: [],
			};

		case 'LOAD_MORE_ORDER_MANAGER_DETAILS':
			let fetchedOrders = state.fetchedOrders;
			let newOrders = action.payload;

			let mappi = {};
			let result = [];

			for (let i = 0; i < fetchedOrders.length; i++) {
				if (mappi[fetchedOrders[i].bundleId]) continue;
				mappi[fetchedOrders[i].bundleId] = true;

				result.push(fetchedOrders[i]);
			}

			for (let i = 0; i < newOrders.length; i++) {
				if (mappi[newOrders[i].bundleId]) continue;
				mappi[newOrders[i].bundleId] = true;

				result.push(newOrders[i]);
			}

			state.fetchedOrders = [...result];
			return state;

		case 'SET_SHIPPING_METHOD':
			console.log(action.payload);
			return {
				...state,
				fetchedOrders: [...action.payload],
				selectedOrders: [],
			};

		case 'SET_ORDER_STATUS':
			return {
				...state,
				fetchedOrders: [...action.payload],
				selectedOrders: [],
			};

		case 'CHECK_UUID':
			state.fetchedOrders = action.payload.ordersList;
			state.selectedOrders.push(action.payload.orderUuid);
			return state;

		case 'UNCHECK_UUID':
			state.fetchedOrders = action.payload.ordersList;
			const index = state.selectedOrders.indexOf(action.payload.orderUuid);
			if (index > -1) {
				state.selectedOrders.splice(index, 1);
			}
			return state;

		case 'UNSET_SELECTED_ORDERS':
			return {
				...state,
				selectedOrders: [],
			};

		case 'CANCEL_ORDER':
			state.fetchedOrders = action.payload;
			return state;

		default:
			return state;
	}
}
