import React from 'react';
import {
    Row,
    Col,
    Avatar,
    Typography,
    Tooltip,
    Tag
} from "antd";
import styled from "styled-components";

const genExtra = (str) => (
    <Tag color={str[1]}>{str[0]}</Tag>
);

const { Text } = Typography;
const TableRow = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #313841;

  /* border-bottom: 1px solid red; */
`;

export class CollapsibleHeaderContent extends React.Component {
    state = {
        paymentStatus: ['pending', 'paid']
    }
    render() {
        return (
            <TableRow type="flex" align="middle">
                <Col span={1}></Col>
                <Col span={2}>
                    {this.props.order.dateCreated}
                </Col>
                <Col span={4}>
                    <Row gutter={[8, 8]}>
                        {this.props.order.mainImages ? (
                            <React.Fragment>
                                {this.props.order.mainImages.length > 0 ? (
                                    <Col span={12}>
                                        <Avatar
                                            shape="square"
                                            style={{ width: '80%', height: '80%' }}
                                            src={this.props.order.mainImages[0]}
                                        />
                                    </Col>
                                ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                {this.props.order.mainImages.length > 1 ? (
                                    <Col span={12}>
                                        <Avatar
                                            shape="square"
                                            style={{ width: '80%', height: '80%' }}
                                            src={this.props.order.mainImages[1]}
                                        />
                                    </Col>
                                ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                {this.props.order.mainImages.length > 2 ? (
                                    <Col span={12}>
                                        <Avatar
                                            shape="square"
                                            style={{ width: '80%', height: '80%' }}
                                            src={this.props.order.mainImages[2]}
                                        />
                                    </Col>
                                ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                {/* {this.props.order.mainImages.forEach((thumbnailUrl) => (
                                    <Col span={12}>
                                        {//.log(typeof (thumbnailUrl))}
                                        <Avatar
                                            shape="square"
                                            style={{width:'100%',height:'100%'}}
                                            src={thumbnailUrl}
                                        />
                                    </Col>
                                ))} */}
                                {this.props.order.mainImages.length > 3 ? (
                                    <Col span={12}>
                                        <Avatar
                                            shape="square"
                                            style={{ width: '80%', height: '80%' }}
                                            style={{ background: "white", color: "#8a8a8a" }}
                                        > + {this.props.order.mainImages.length > 3 ? this.props.order.mainImages.length - 3 : 0}
                                        </Avatar>
                                    </Col>
                                ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                            </React.Fragment>
                        ) : (
                                <React.Fragment></React.Fragment>
                            )}
                    </Row>
                </Col>

                <Col span={2}>
                    <Text className="">-</Text>
                </Col>
                <Col span={2}>
                    <Text className="">Dealshub</Text>
                </Col>
                <Col span={2}>
                    <Tooltip title={this.props.order.customerName}>
                        <Text>{this.props.order.customerName}</Text>
                    </Tooltip>
                </Col>
                <Col span={2}>
                    <Text className="table-head-text">
                        {
                            genExtra([
                                this.props.order.paymentStatus.charAt(0).toUpperCase() +
                                this.props.order.paymentStatus.substr(1)
                                , this.props.order.paymentStatus === this.state.paymentStatus[0] ? 'red' : 'green'])
                        }
                    </Text>
                </Col>
                <Col span={1}></Col>
                <Col span={2}></Col>
                <Col span={2}>
                </Col>
                <Col span={2}></Col>
                <Col span={2}></Col>
            </TableRow>

        );
    }
}

export default CollapsibleHeaderContent;