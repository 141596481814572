import React, {useState, useEffect} from 'react'
import RightWrapper from "my-components/ui-components/RightWrapper"
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {DeleteOutlined} from '@ant-design/icons' 
import {
    Button,
    Row,
    Col,
    Typography,
    Collapse,
    Card,
    DatePicker,
    Input,
    Radio,
    Checkbox,
    Switch,
    Tag,
    Tooltip,
    message,
    Popconfirm,
    Spin, Icon, Avatar, Modal, Select
} from 'antd'


import axios from 'axios'
import './dealshubsettings.scss'
import moment from "moment"
import isDev from "server"
import InputField from 'views/components/datainputs/InputField';

const { Option } = Select;

const {TextArea} = Input;

const {Title, Text} = Typography
const {Panel} = Collapse
const {RangePicker} = DatePicker
const serverUrl = isDev()

function InnerWrapper(props) {

    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({});
    const [addNewEmployeeModal, setAddNewEmployeeModal] = useState(false)
    const [userName, setUserName] = useState("")
    const [todaySalesTarget, setTodaySalesTarget] = useState("")
    const [todayOrdersTarget, setTodayOrdersTarget] = useState("")
    const [monthlySalesTarget, setMonthlySalesTarget] = useState("")
    const [monthlyOrdersTarget, setMonthlyOrdersTarget] = useState("")
    const [salesTargetList, setSalesTargetList] = useState([])
    const [salesPersonList, setSalesPersonList] = useState([])
    const [currency, setCurrency] = useState("")


    const fetchSalesPersonData = () => {
        let data= {
            locationGroupUuid: props.locationUuid,
            is_sales_executive: true
        }
        fetch(`${serverUrl}/dealshub/fetch-oc-sales-persons/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setSalesPersonList(data.salesPersonList);
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });

    }

    const fetchDetails = () => {
        setLoading(true);
        let data = {
            locationGroupUuid: props.locationUuid
        };
        fetch(`${serverUrl}/dealshub/fetch-location-group-settings/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.status === 200) {
                    setDetails(data);
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
    }

    const fetchSalesTData = () => {
        let data = {
            locationGroupUuid: props.locationUuid
        };
        fetch(`${serverUrl}/dealshub/fetch-sales-targets-list/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                //setLoading(false);
                if (data.status === 200) {
                    setSalesTargetList(data.sales_target_list);
                    setCurrency(data.currency)
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
    }

    const save = () => {
        setSaveLoading(true);
        let data = {
            locationGroupUuid: props.locationUuid,
            ...details
        };
        fetch(`${serverUrl}/dealshub/update-location-group-settings/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                setSaveLoading(false);
                if (data.status === 200) {
                    message.success("Details are successfully saved!")
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
    }

    const addSalesTarget = () => {
        axios.post(`${serverUrl}/dealshub/add-sales-target/`,
            {
                locationGroupUuid: props.locationUuid,
                sales_person_username: userName,
                today_sales_target: todaySalesTarget,
                monthly_sales_target: monthlySalesTarget,
                today_orders_target: todayOrdersTarget,
                monthly_orders_target: monthlyOrdersTarget
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then(res => {
            if (res.data.status === 200) {
                message.success("Employee target added!")
                setAddNewEmployeeModal(false)
                fetchSalesTData()
            } else if (res.data.status === 403) {
                message.info("You are not authorised!")
            } else if (res.data.status === 409) {
                message.info("Employee target already exists!")
            } else {
                message.error("Something went wrong")
            }
            //console.log(res.data)

        })
    }

    const TargetRowItem = ({item}) => {

        const handleUpdateTarget = () => {
            axios.post(`${serverUrl}/dealshub/update-sales-target/`,
                {
                    locationGroupUuid: props.locationUuid,
                    sales_target_uuid: item.uuid,
                    today_sales_target: inputTodaySales,
                    monthly_sales_target: inputMonthlySales,
                    today_orders_target: inputTodayOrders,
                    monthly_orders_target: inputMonthlyOrders
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }).then(res => {
                if (res.data.status === 200) {
                    message.success("Target updated")
                    setEditTarget(!editTarget)
                    fetchSalesTData()
                } else if (res.data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
                //console.log(res.data)

            })
        }

        const deleteSalesTarget = () => {
            axios.post(`${serverUrl}/dealshub/delete-sales-target/`,
            {
                locationGroupUuid: props.locationUuid,
                sales_target_uuid: item.uuid,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            }).then((response) => {

                //console.log(response, "RESRES");
                if(response.data.status === 200){
                    setSalesTargetList(salesTargetList.filter(target => target.uuid!==item.uuid));
                    message.success("Item deleted successfully!");
                }
                else message.error("Something went wrong!");
            }).catch((e)=>{
                message.error("Something went wrong!");
            });
        }

        const [inputMonthlyOrders, setInputMonthlyOrders] = useState(item.monthly_orders_target)
        const [inputMonthlySales, setInputMonthlySales] = useState(item.monthly_sales_target)
        const [inputTodaySales, setInputTodaySales] = useState(item.today_sales_target)
        const [inputTodayOrders, setInputTodayOrders] = useState(item.today_orders_target)
        const [editTarget, setEditTarget] = useState(false)
        return (
            <>
                <Col span={5}>
                    <Avatar size={51}
                            style={{margin: '18px 12px 18px 12px'}}>{item.user_first_name[0]}</Avatar>
                    <Text style={{
                        color: '#FFFFFF',
                        fontWeight: '700'
                    }}>{item.user_first_name}</Text>
                </Col>
                <Col span={4} style={{textAlign: 'center'}}>
                    {editTarget ?
                        <>
                            <Input
                                value={inputTodaySales}
                                onChange={(e) => setInputTodaySales(e.target.value)}
                                style={{
                                    width: '70%',
                                    backgroundColor: '#36404A'
                                }}
                            />
                            {/*<Icon type="check-circle" style={{*/}
                            {/*    cursor: "pointer",*/}
                            {/*    color: "#1abc9c",*/}
                            {/*    marginLeft: '10px'*/}
                            {/*}} onClick={() => setEditTarget(!editTarget)}/>*/}
                        </>
                        :
                        <Text style={{
                            fontWeight: '700',
                            color: '#FFFFFF'
                        }}>{currency}{" "}
                            {item.today_sales_target}</Text>
                    }
                </Col>
                <Col span={4} style={{textAlign: 'center'}}>
                    {editTarget ?
                        <>
                            <Input
                                value={inputMonthlySales}
                                onChange={(e) => setInputMonthlySales(e.target.value)}
                                style={{
                                    width: '70%',
                                    backgroundColor: '#36404A'
                                }}
                            />
                            {/*<Icon type="check-circle" style={{*/}
                            {/*    cursor: "pointer",*/}
                            {/*    color: "#1abc9c",*/}
                            {/*    marginLeft: '10px'*/}
                            {/*}} onClick={() => setEditTarget(!editTarget)}/>*/}
                        </>
                        :
                        <Text style={{
                            fontWeight: '700',
                            color: '#FFFFFF'
                        }}>{currency}{" "}
                            {item.monthly_sales_target}</Text>
                    }
                </Col>
                <Col span={4} style={{textAlign: 'center'}}>
                    {editTarget ?
                        <>
                            <Input
                                value={inputTodayOrders}
                                onChange={(e) => setInputTodayOrders(e.target.value)}
                                style={{
                                    width: '70%',
                                    backgroundColor: '#36404A'
                                }}
                            />
                            {/*<Icon type="check-circle" style={{*/}
                            {/*    cursor: "pointer",*/}
                            {/*    color: "#1abc9c",*/}
                            {/*    marginLeft: '10px'*/}
                            {/*}} onClick={() => setEditTarget(!editTarget)}/>*/}
                        </>
                        :
                        <Text style={{
                            fontWeight: '700',
                            color: '#FFFFFF'
                        }}>{item.today_orders_target}</Text>
                    }
                </Col>
                <Col span={4} style={{textAlign: 'center'}}>
                    {editTarget ?
                        <>
                            <Input
                                value={inputMonthlyOrders}
                                onChange={(e) => setInputMonthlyOrders(e.target.value)}
                                style={{
                                    width: '70%',
                                    backgroundColor: '#36404A'
                                }}
                            />
                            {/*<Icon type="check-circle" style={{*/}
                            {/*    cursor: "pointer",*/}
                            {/*    color: "#1abc9c",*/}
                            {/*    marginLeft: '10px'*/}
                            {/*}} onClick={() => setEditTarget(!editTarget)}/>*/}
                        </>
                        :
                        <Text style={{
                            fontWeight: '700',
                            color: '#FFFFFF'
                        }}>{item.monthly_orders_target}</Text>
                    }
                </Col>
                <Col span={1}>
                    <Popconfirm
                        title="Are you sure you want to delete this employee sales target?"
                        onConfirm={deleteSalesTarget}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            style={{
                                background: '#F1556C', 
                                color: 'white', 
                                borderColor: '#F1556C',
                                // width: '20px', 
                                // display:'flex',
                                // justifyContent:'center',
                                // alignItems: 'center'
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Col>
                <Col span={2} style={{textAlign: 'right'}}>
                    <div>
                        {!editTarget ? <Button
                                style={{marginRight: '24px'}}
                                type='primary'
                                onClick={() => setEditTarget(!editTarget)}>
                                <Icon type={"form"}/> Edit </Button> :
                            <Button
                                type='default'
                                style={{
                                    marginRight: '24px',
                                    border: 'none'
                                }}
                                onClick={() => handleUpdateTarget()}
                            >
                                <Icon type='check-circle'/>
                                Save
                            </Button>
                        }
                    </div>
                </Col>
            </>

        )
    }


    const AddEmployeeTarget = () => (
        <Button
            style={{backgroundColor: '#F1556C', border: 'none', color: '#F1F1F1', float: 'right'}}
            onClick={(e) => {
                e.stopPropagation()
                setAddNewEmployeeModal(true)
            }}
        ><Icon type="plus"/>Add Employee</Button>
    )


    useEffect(() => {
        fetchDetails();
        props.orderAnalyticsAccess && fetchSalesTData();
        props.orderAnalyticsAccess && fetchSalesPersonData();
    }, [props.locationUuid])

    return (
        <React.Fragment>
            <TitleWithBreadCrumb
                title="Settings"
                breadcrumb={[]}
            />
            <Row style={{
                padding: "30px"
            }}>


                {
                    loading ?
                        <Row type="flex" justify="center" align="middle" style={{
                            width: "100%",
                            height: "100px"
                        }}>
                            <Col>
                                <Spin width={120}/>
                            </Col>
                        </Row> :
                        <>
                            <React.Fragment>
                                <Row style={{
                                    backgroundColor: "#36404a",
                                    padding: "20px"
                                }} gutter={[18, 18]}>
                                    <Col sm={12}>
                                        <InputField
                                            label={{type: "secondary"}}
                                            input={{
                                                size: "large",
                                                name: "cod_charge",
                                                value: details.cod_charge,
                                                disabled: false
                                            }}
                                            onChange={(e) => {
                                                setDetails({
                                                    ...details,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                            labelText="COD"
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <InputField
                                            label={{type: "secondary"}}
                                            input={{
                                                size: "large",
                                                name: "delivery_fee",
                                                value: details.delivery_fee,
                                                disabled: false
                                            }}
                                            onChange={(e) => {
                                                setDetails({
                                                    ...details,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                            labelText="Delivery Fee"
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <InputField
                                            label={{type: "secondary"}}
                                            input={{
                                                size: "large",
                                                name: "free_delivery_threshold",
                                                value: details.free_delivery_threshold,
                                                disabled: false
                                            }}
                                            onChange={(e) => {
                                                setDetails({
                                                    ...details,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                            labelText="Free Delivery Threshold"
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <InputField
                                            label={{type: "secondary"}}
                                            input={{
                                                size: "large",
                                                name: "vat",
                                                value: details.vat,
                                                disabled: false
                                            }}
                                            onChange={(e) => {
                                                setDetails({
                                                    ...details,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                            labelText="Vat"
                                        />
                                    </Col>
                                    {props.orderAnalyticsAccess &&
                                    <>
                                        <Col sm={12}>
                                            <InputField
                                                label={{type: "secondary"}}
                                                input={{
                                                    size: "large",
                                                    name: "today_sales_target",
                                                    value: details.today_sales_target,
                                                    disabled: false
                                                }}
                                                onChange={(e) => {
                                                    setDetails({
                                                        ...details,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                                labelText="Daily Sales Target"
                                            />
                                        </Col>
                                        <Col sm={12}>
                                            <InputField
                                                label={{type: "secondary"}}
                                                input={{
                                                    size: "large",
                                                    name: "monthly_sales_target",
                                                    value: details.monthly_sales_target,
                                                    disabled: false
                                                }}
                                                onChange={(e) => {
                                                    setDetails({
                                                        ...details,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                                labelText="Monthly Sales Target"
                                            />
                                        </Col>
                                        <Col sm={12}>
                                            <InputField
                                                label={{type: "secondary"}}
                                                input={{
                                                    size: "large",
                                                    name: "today_orders_target",
                                                    value: details.today_orders_target,
                                                    disabled: false
                                                }}
                                                onChange={(e) => {
                                                    setDetails({
                                                        ...details,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                                labelText="Daily Orders Target"
                                            />
                                        </Col>
                                        <Col sm={12}>
                                            <InputField
                                                label={{type: "secondary"}}
                                                input={{
                                                    size: "large",
                                                    name: "monthly_orders_target",
                                                    value: details.monthly_orders_target,
                                                    disabled: false
                                                }}
                                                onChange={(e) => {
                                                    setDetails({
                                                        ...details,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                                labelText="Monthly Orders Target"
                                            />
                                        </Col>
                                    </>
                                    }

                                    <Col sm={24} style={{
                                        marginTop: "10px"
                                    }}>
                                        <Button type="primary" onClick={save} loading={saveLoading}
                                                disabled={saveLoading}>Save</Button>
                                    </Col>
                                </Row>
                            </React.Fragment>
                            {props.orderAnalyticsAccess && <React.Fragment>
                                <Row style={{
                                    background: "#36404a",
                                    marginTop: "2em",
                                    marginLeft: '-9px',
                                    minWidth: '1215.19px',
                                    padding: "22px"
                                }}>
                                    <Collapse bordered={false} className="settings-collapse">
                                        <Panel
                                            header='Employee Sales Target'
                                            key={1}
                                            extra={AddEmployeeTarget()}
                                        >
                                            <Row style={{marginTop: '30px', marginHorizontal: '53px'}}>
                                                <Col span={5} style={{textAlign: 'center'}}>
                                                    <Text style={{fontSize: '16px'}}>Employee</Text>
                                                </Col>
                                                <Col span={4} style={{textAlign: 'center'}}>
                                                    <Text style={{fontSize: '16px'}}>Daily Sales</Text>
                                                </Col>
                                                <Col span={4} style={{textAlign: 'center'}}>
                                                    <Text style={{fontSize: '16px'}}>Monthly Sales</Text>
                                                </Col>
                                                <Col span={4} style={{textAlign: 'center'}}>
                                                    <Text style={{fontSize: '16px'}}>Daily Orders</Text>
                                                </Col>
                                                <Col span={4} style={{textAlign: 'center'}}>
                                                    <Text style={{fontSize: '16px'}}>Monthly Orders</Text>
                                                </Col>
                                            </Row>
                                            <React.Fragment>
                                                {salesTargetList.length > 0 ?
                                                    salesTargetList.map((item, index) => {
                                                        return (
                                                            <Row
                                                                key={index}
                                                                type='flex'
                                                                justify='space-around'
                                                                align='middle'
                                                                style={{
                                                                    backgroundColor: '#3F4A56',
                                                                    width: '100%',
                                                                    marginTop: '12px'
                                                                }}
                                                            >
                                                                <TargetRowItem item={item}/>
                                                            </Row>
                                                        )
                                                    }) : <Text>No data available</Text>
                                                }

                                            </React.Fragment>
                                        </Panel>
                                    </Collapse>
                                </Row>
                            </React.Fragment>}

                            <Modal
                                visible={addNewEmployeeModal}
                                title='Add Employee Target'
                                //style={{backgroundColor: '#FFFFFF', height: '434px', width:'846px'}}
                                onCancel={() => setAddNewEmployeeModal(false)}
                                onOk={() => setAddNewEmployeeModal(false)}
                                footer={[
                                    <Button type='primary' onClick={() => addSalesTarget()}>Save</Button>
                                ]}
                            >
                                <Row>
                                    <Col span={12}>
                                        <Text>Employee Name{" "}</Text>
                                        <Select
                                            style={{width: '359px'}}
                                            placeholder='Select employee'
                                            onChange={(e)=>setUserName(e)}>
                                            {salesPersonList && salesPersonList.map((item,index)=>(
                                                <Option key={item.username}>
                                                    {item.firstName}{" "}{item.lastName}
                                                </Option>))}
                                        </Select>
                                        {/*<Input*/}
                                        {/*    value={userName}*/}
                                        {/*    onChange={(e) => setUserName(e.target.value)}*/}
                                        {/*    style={{width: '359px'}}*/}
                                        {/*/>*/}
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '24px'}}>
                                    <Col span={12}>
                                        <Text>Set Daily Sales {" "}</Text>
                                        <Input
                                            style={{width: '359px'}}
                                            value={todaySalesTarget}
                                            onChange={(e) => setTodaySalesTarget(e.target.value)}/>
                                    </Col>
                                    <Col span={12}>
                                        <Text>Set Monthly Sales{" "}</Text>
                                        <Input
                                            value={monthlySalesTarget}
                                            onChange={(e) => setMonthlySalesTarget(e.target.value)}
                                            style={{width: '359px'}}/>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '24px'}}>
                                    <Col span={12}>
                                        <Text>Set Daily Orders {" "}</Text>
                                        <Input
                                            value={todayOrdersTarget}
                                            onChange={(e) => setTodayOrdersTarget(e.target.value)}
                                            style={{width: '359px'}}/>
                                    </Col>
                                    <Col span={12}>
                                        <Text>Set Monthly Orders {" "}</Text>
                                        <Input
                                            value={monthlyOrdersTarget}
                                            onChange={(e) => setMonthlyOrdersTarget(e.target.value)}
                                            style={{width: '359px'}}/>
                                    </Col>
                                </Row>
                            </Modal>
                        </>

                }
            </Row>
        </React.Fragment>
    )
}

function DealshubSettings(props)
{
    return (
        <React.Fragment>
            <RightWrapper
                innerwrapperTransparent
                InnerWrapper={
                    <React.Fragment>
                        <InnerWrapper  {...props}/>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    )
}

export default DealshubSettings
