import React from 'react';
import {
    Row,
    Col,
    Button,
    Icon,
    Select,
    Modal,
    Input,
    message
} from "antd";
import OrdersWarehouseManagerFilterModal from "../components/modals/orders-warehouse-manager-filter-modal/OrdersWarehouseManagerFilterModal";

const orderStatues = ["approved", "picked", "dispatched", "delivered", "delivery failed", "returned"];
const { TextArea } = Input;

const getDynamicIcon = (currentStatus) => {
    if (currentStatus === orderStatues[0]) {
        // icon for picked
        return 'dropbox';
    } else if (currentStatus === orderStatues[1]) {
        // icon for dispatched
        return 'environment';
    } else if (currentStatus === orderStatues[2]) {
        // icons for delivered
        return 'carry-out';
    }
}

export default class OrdersAccountManagerHeaderTemplate extends React.Component {
    state = {
        toggleShowlModal: false,
        cancellationReason: ''
    }

    handleCancelModalClose = () => {
        this.toggleModal();
        this.setState({ cancellationReason: '' });
    }

    handleCancelModalSubmit = () => {
        if (
            this.state.cancellationReason.length >= 5 &&
            this.state.cancellationReason.length <= 160
        ) {
            this.props.setOrderStatus("delivery failed", this.state.cancellationReason);
            this.handleCancelModalClose();
        } else {
            message.error('Invalid Length for reason');
        }
    }

    toggleModal = () => {
        this.setState({ toggleShowlModal: !this.state.toggleShowlModal });
    }

    handleCancelTextChange = (event) => {
        this.setState({ cancellationReason: event.target.value });
    }

    render() {
        let filterObj = {
            "fromDate": '',
            "toDate": '',
            "paymentTypeList": [],
            "minQty": '',
            "maxQty": '',
            "minPrice": '',
            "maxPrice": '',
            "currencyList": [],
            "shippingMethodList": [],
            "trackingStatusList": [],
            "clearFilters": true
        }
        return (
            <Row>
                <Col span={24} className="orders-warehouse-manager-table-container">
                    <Row gutter={[12, 0]} className="top-nav" type="flex">
                        <Col lg={3} sm={10}>
                            <Button
                                style={{
                                    width: "100%"
                                }}
                                type="primary"
                                loading={this.props.loading}
                                ghost
                            >
                                <React.Fragment>
                                    {this.props.loading ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                            <React.Fragment>Results: {this.props.totalResults}</React.Fragment>
                                        )}
                                </React.Fragment>
                            </Button>
                        </Col>
                        <Col lg={18} sm={24}>
                            <Select
                                dropdownClassName="search-chips"
                                dropdownMatchSelectWidth="false"
                                mode="tags"
                                style={{ width: "100%" }}
                                onChange={this.props.handleSearchChange}
                                placeholder={[<Icon type="search" />, " Search here..."]}
                            ></Select>
                        </Col>
                        <Col lg={3} sm={14}>
                            <OrdersWarehouseManagerFilterModal
                                filterCount={this.props.filterCount}
                                applyFilters={this.props.applyFilters}
                                someProcessingActive={this.props.someProcessingActive}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[12, 0]} className="top-nav-2" type="flex">
                        <Col span={3}>
                            <Button
                                // className={this.props.orderStatusFilter}
                                className={
                                    this.props.orderStatusFilter === orderStatues[0] ?
                                        'active-class' : ''
                                }
                                icon={this.props.orderStatusFilter === orderStatues[0] ? 'check-circle' : ''}
                                style={{
                                    width: "100%"
                                }}
                                onClick={() => {
                                    let temp = [];
                                    temp.push(orderStatues[0]);
                                    filterObj['trackingStatusList'] = temp;
                                    this.props.applyFilters(filterObj);
                                }}
                                // loading={this.props.processingPickedOrderStatus}
                                disabled={this.props.someProcessingActive}
                            >
                                Approved
                          </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                className={
                                    this.props.orderStatusFilter === orderStatues[1] ?
                                        'active-class' : ''
                                }
                                icon={this.props.orderStatusFilter === orderStatues[1] ? 'check-circle' : ''}
                                style={{
                                    width: "100%"
                                }}
                                onClick={() => {
                                    // this.props.setOrderStatus(orderStatues[1])
                                    let temp = [];
                                    temp.push(orderStatues[1]);
                                    filterObj['trackingStatusList'] = temp;
                                    this.props.applyFilters(filterObj);
                                }}
                                // loading={this.props.processingPickedOrderStatus}
                                disabled={this.props.someProcessingActive}
                            >
                                Picked
                          </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                className={
                                    this.props.orderStatusFilter === orderStatues[2] ?
                                        'active-class' : ''
                                }
                                icon={this.props.orderStatusFilter === orderStatues[2] ? 'check-circle' : ''}
                                style={{
                                    width: "100%"
                                }}
                                onClick={() => {
                                    // this.props.setOrderStatus(orderStatues[2])
                                    let temp = [];
                                    temp.push(orderStatues[2]);
                                    filterObj['trackingStatusList'] = temp;
                                    this.props.applyFilters(filterObj);
                                }}
                                // loading={this.props.processingDispatchedOrderStatus}
                                disabled={this.props.someProcessingActive}
                            >
                                Dispatched
                          </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                className={
                                    this.props.orderStatusFilter === orderStatues[3] ?
                                        'active-class' : ''
                                }
                                icon={this.props.orderStatusFilter === orderStatues[3] ? 'check-circle' : ''}
                                style={{
                                    width: "100%"
                                }}
                                onClick={() => {
                                    // this.props.setOrderStatus(orderStatues[3])
                                    let temp = [];
                                    temp.push(orderStatues[3]);
                                    filterObj['trackingStatusList'] = temp;
                                    this.props.applyFilters(filterObj);
                                }}
                                // loading={this.props.processingDeliveredOrderStatus}
                                disabled={this.props.someProcessingActive}
                            >
                                Delivered
                         </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                className={
                                    this.props.orderStatusFilter === orderStatues[4] ?
                                        'active-class' : ''
                                }
                                icon={this.props.orderStatusFilter === orderStatues[4] ? 'check-circle' : ''}
                                style={{
                                    width: "100%"
                                }}
                                onClick={() => {
                                    // this.props.setOrderStatus(orderStatues[4])
                                    let temp = [];
                                    temp.push(orderStatues[4]);
                                    filterObj['trackingStatusList'] = temp;
                                    this.props.applyFilters(filterObj);
                                }}
                                // loading={this.props.processingFailedOrderStatus}
                                disabled={this.props.someProcessingActive}
                            >
                                Failed
                         </Button>
                        </Col>
                        <Col span={3}>
                            {/* <Button
                            className={
                                this.props.orderStatusFilter === orderStatues[5] ?
                                    'active-class' : ''
                            }
                            style={{
                                width: "100%"
                            }}
                            icon={this.props.orderStatusFilter === orderStatues[5] ?'check-circle':''}
                            onClick={() => {
                                // this.props.setOrderStatus(orderStatues[5])
                                let temp = [];
                                temp.push(orderStatues[5]);
                                filterObj['trackingStatusList'] = temp;
                                this.props.applyFilters(filterObj);
                            }}
                            // loading={this.props.processingWigShippingMethod}
                            disabled={this.props.someProcessingActive}
                        >
                            Returned
                          </Button> */}
                        </Col>
                        <Col span={3}>
                            {
                                this.props.allowStatusChange &&
                                    this.props.orderStatusFilter === orderStatues[2] ? (
                                        <Button
                                            style={{
                                                color: "#fff",
                                                background: "red",
                                                border: "none",
                                                width: "100%",
                                                padding: "0px"
                                            }}
                                            icon='close-circle'
                                            onClick={() => {
                                                this.toggleModal();
                                            }}
                                            // loading={this.props.processingWigShippingMethod}
                                            disabled={this.props.someProcessingActive}
                                        >
                                            Delivery Failed
                                        </Button>
                                    ) : (
                                        <React.Fragment></React.Fragment>

                                    )
                            }
                        </Col>
                        <Col span={3}>
                            {
                                this.props.allowStatusChange &&
                                    this.props.orderStatusFilter !== orderStatues[3]
                                    && this.props.orderStatusFilter !== orderStatues[4] ? (
                                        <Button
                                            style={{
                                                color: "#fff",
                                                background: "#4B9A29",
                                                border: "none",
                                                width: "100%"
                                            }}
                                            onClick={() => {
                                                this.props.setOrderStatus(
                                                    orderStatues[
                                                    orderStatues.indexOf(this.props.orderStatusFilter) + 1
                                                    ]
                                                )
                                            }}
                                            icon={getDynamicIcon(this.props.orderStatusFilter)}
                                            // loading={this.props.processingWigShippingMethod}
                                            disabled={this.props.someProcessingActive}
                                        >
                                            {
                                                orderStatues[
                                                    orderStatues.indexOf(this.props.orderStatusFilter) + 1
                                                ].charAt(0).toUpperCase() +
                                                orderStatues[
                                                    orderStatues.indexOf(this.props.orderStatusFilter) + 1
                                                ].substr(1)
                                            }
                                        </Button>
                                    ) : (
                                        <React.Fragment></React.Fragment>

                                    )}
                        </Col>
                    </Row>
                </Col >
                <Modal
                    title="Type Delivery Failed Reason"
                    visible={this.state.toggleShowlModal}
                    onOk={this.handleCancelModalSubmit}
                    onCancel={this.handleCancelModalClose}
                    loading={!this.state.toggleShowlModal}
                    destroyOnClose={true}
                    width="50vw"
                >
                    <TextArea
                        defaultValue={this.state.cancellationReason}
                        placeholder='Describe failed delivery reason using a minimum of 5 characters and maximum of 160 characters!'
                        maxLength={160}
                        onChange={this.handleCancelTextChange}
                    />
                </Modal>
            </Row >
        );
    }
}
