import React, { Component } from 'react'
import { connect } from "react-redux";

import "./search-chips.scss"

import { Select, Icon } from "antd";
// import { fetchProductList, fetchProductListfromTags } from '../../../../actions';



class SearchChips extends Component {


    handleChange = (tags) => {
        // dispatching array of tags

        // this.props.dispatch(fetchProductListfromTags(tags))
    }
    render() {
        return (

            <Select
                dropdownClassName="search-chips"
                dropdownMatchSelectWidth="false"
                mode="tags"
                style={{ width: "100%" }}
                placeholder={[<Icon type="search" />, " Search here..."]}
                onChange={this.handleChange}
            >
            </Select>

        )
    }
}





export default connect()(SearchChips);