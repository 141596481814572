import React, { useEffect } from 'react'
import styles from './notif.module.scss'
import { CloseOutlined } from '@ant-design/icons'
import { closeRefreshPageNotification } from '../../../actions/refreshNotificationAction'
import { useSelector, useDispatch } from 'react-redux'


/**
 * Please refer to the useRefreshNotification.js file for more details 😘 
 */


//timneOut variable is used so that the timeout is set only once
let timeOut;

function RefreshPageNotification({ serverRefresh = false }) {
    const state = useSelector(state => state.refreshNotification)
    const dispatch = useDispatch()

    useEffect(() => {
        if (state.payload?.duration) {
            if (!timeOut) {
                try {
                    timeOut = setTimeout(() => {
                        dispatch(closeRefreshPageNotification())
                    }, parseInt(state.payload?.duration))

                } catch (e) {

                    timeOut = setTimeout(() => {
                        dispatch(closeRefreshPageNotification())
                    }, parseInt(5000))
                }
            }
        }
    }, [state])

    const handleRefreshClick = () => {
        window.location.reload(serverRefresh)
    }

    const handleCloseClick = () => {
        dispatch(closeRefreshPageNotification())
    }

    if (!state.status) return <></>

    return (
        <div className={styles.main}>
            New Activity Detected.
            {" "}
            <span className={styles.refresh} onClick={handleRefreshClick}>Click to Refresh</span>
            {" "}
            <CloseOutlined onClick={handleCloseClick} className={styles.close} />
        </div>
    )
}

export default RefreshPageNotification
    