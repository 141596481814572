import arraymove from "array-move";

/**
 * @description This is the redux reducer for admin panel of dealshub which 
 * changes/updates state for different cases like adding category,adding banner etc..
 * @typedef StateType - state type of dealsHubAdmin reducer, default state is initial state
 * @param {StateType} state 
 * @param {object} action 
 * @returns {StateType} updated/changed state
 */
export default function dealsHubAdmin(state = [], action) {
  switch (action.type) {
    case "ADD_CATEGORY":
      return [...state, action.payload];

    case "ADD_BANNER":
      return [...state, action.payload];

    case "DELETE_BANNER":
      return state.filter(e => e.uuid !== action.payload);

    case "PUBLISH_BANNER":
      for (let index in state) {
        if (state[index].uuid === action.payload.bannerUuid) {
          state[index].isPublished = true;
        }
      }
      return state;

    case "UNPUBLISH_BANNER":
      for (let index in state) {
        if (state[index].uuid === action.payload.bannerUuid) {
          state[index].isPublished = false;
        }
      }
      return state;

    case "FETCH_DEALS_HUB_DATA":
      let temp = [];
      if (action.payload) {
        for (let index = 0; index < action.payload.length; ++index) {
          temp = [...temp, action.payload[index]];
        }
      }
      return temp;

    case "HANDLE_UPDATE":
      const { uuid, value } = action.payload;
      for (let i in state) {
        if (state[i].uuid === uuid) {
          state[i].name = value;
          break;
        }
      }
      return state;

    case "HANDLE_PROMO_CHANGE":
      const { bannerIndex, unitBannerIndex, promoDetails } = action.payload;
      state[bannerIndex]["bannerImages"][unitBannerIndex]["is_promotional"] = promoDetails.is_promotional;
      state[bannerIndex]["bannerImages"][unitBannerIndex]["start_time"] = promoDetails.promotion["start_time"];
      state[bannerIndex]["bannerImages"][unitBannerIndex]["end_time"] = promoDetails.promotion["end_time"];
      state[bannerIndex]["bannerImages"][unitBannerIndex]["promotion_tag"] = promoDetails.promotion["promotion_tag"];
      return state;

    case "HANDLE_PROMO_SECTION_PRICE_CHANGE":
      state[action.payload.sectionIndex]
      ["products"][action.payload.sectionProductIndex]
      ["promotional_price"] = action.payload.promoPrice;
      return state;

    case "HANDLE_PROMO_BANNER_PRICE_CHANGE":
      console.log(action.payload);
      state[action.payload.bannerIndex]
      ["bannerImages"][action.payload.unitBannerIndex]
      ["products"][action.payload.productIndex]
      ["promotional_price"] = action.payload.promoPrice;
      return state;

    case "HANDLE_DELETE":
      state = action.payload;
      return state;

    case "UPDATE_DEALS_HUB_ADMIN":
      const { itemUuid, itemValue } = action.payload;
      for (let i in state) {
        if (state[i].uuid === itemUuid) {
          state[i] = {...state[i], ...itemValue}
          break;
        }
      }
      return state;
      
    case "REORDER_LIST":
      return arraymove(
        state,
        action.payload.oldIndex,
        action.payload.newIndex
      );

    default:
      return state;
  }
}
