import React, { Component } from "react";
import {
  Row,
  Col,
  Collapse,
  Icon,
  Button,
  Affix,
  message,
  Tooltip,
} from "antd";
import InputField from "../datainputs/InputField";
// import ImageManager from "../datainputs/image-manager/ImageManager";
import FormHeader from "../datainputs/FormHeader";
// import InputNumberDimensions3 from "../datainputs/InputNumberDimesions3";
import "./channel-page.scss";
import EditorBox from "../EditorBox";
import Attributes from "../datainputs/attributes/Attributes";
import { connect } from "react-redux";
import {
  fetchEbay,
  handelChangeEbay,
  saveEbayForm,
} from "../../../actions/channelProduct";
import SimpleReactValidator from "simple-react-validator";
const { Panel } = Collapse;

class AmazonUkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: null,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Field is required",
        integer: "Must be number",
        default: "Validation has failed!", // will override all messages
      },
    });
  }

  componentDidMount = () => {
    this.props.fetchEbay(this.props.product_pk);
  };

  handelChangeInputOut = (e) => {
    this.props.handelChangeEbay({
      ...this.props.ebay,
      [e.target.name]: e.target.value,
    });
  };

  handelChangeInput = (e) => {
    this.props.handelChangeEbay({
      ...this.props.ebay,
      channel_product_json: {
        ...this.props.ebay.channel_product_json,
        [e.target.name]: e.target.value,
      },
    });
  };

  handelSave = () => {
    if (this.validator.allValid()) {
      this.props.ebay.channel_product_json.product_attribute_list = this.state.attributes;

      this.props.saveEbayForm(this.props.product_pk, this.props.ebay);
    } else {
      message.info("Fill Required data");
    }
  };

  getAttributesData = (data) => {
    this.setState({ attributes: data });
  };

  getEditorText = (value) => {
    this.props.handelChangeEbay({
      ...this.props.ebay,
      channel_product_json: {
        ...this.props.ebay.channel_product_json,
        ["product_description"]: value,
      },
    });
  };

  error = (status) => {
    if (status == 500) message.error("Internal Server Error");
    else if (status == 409) message.info("Duplicate Product Detected");
    else if (status == 403) message.error("access denied");
    else {
      message.success(" Saved successfully! ");
    }
  };

  setAttribute = 1;

  componentWillReceiveProps = (props) => {
    if (
      props.ebay.channel_product_json &&
      this.setAttribute &&
      props.ebay.channel_product_json.product_attribute_list &&
      props.ebay.channel_product_json.product_attribute_list.length
    ) {
      this.setState({
        attributes: props.ebay.channel_product_json.product_attribute_list,
      });
      this.setAttribute = 0;
    }

    if (props.ebay.status !== "") {
      this.error(props.ebay.status);
    }
  };

  render() {
    const { product_id, material_type } = this.props.ebay;
    const {
      product_name,
      product_description,
      category,
      sub_category,
      product_attribute_list,
      http_link,
      was_price,
      now_price,
      stock,
    } = this.props.ebay.channel_product_json;

    //.log(this.props, "SAVING THE FORM");

    if (!this.props.drawer) this.props.sendImages(this.props.ebay.images);

    return (
      <React.Fragment>
        {!this.props.drawer && (
          <Tooltip placement="top" title="Save">
            <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
              <Button
                type="danger"
                shape="circle"
                icon="save"
                size="large"
                onClick={this.handelSave}
              ></Button>
            </Affix>
          </Tooltip>
        )}

        <Tooltip placement="top" title="Channel Forms">
          <Affix style={{ position: "fixed", bottom: 20, right: 70 }}>
            <Button
              type="primary"
              shape="circle"
              icon="border-right"
              size="large"
              onClick={this.props.ToggelDrawer}
            ></Button>
          </Affix>
        </Tooltip>
        {/* <Row style={{ margin: "30px 0px 30px 0px " }} type="flex" justify="left" align="middle">
          <Button onClick={this.handelSave} type="primary"> Save </Button>
        </Row> */}

        <Row gutter={[40, 40]}>
          <Col className="gutter-row" span={24}>
            {/* <div className="gutter-box">col-6</div> */}

            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel header="GENERAL" key="1" style={this.customPanelStyle}>
                    <Row
                      gutter={[24, 24]}
                      style={{
                        maxHeight: "70vh",
                        overflow: "scroll",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <Col sm={24}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "product_name",
                            value: product_name,
                          }}
                          onChange={this.handelChangeInput}
                          labelText="Product Name"
                          errormsg={
                            this.validator &&
                            this.validator.message(
                              "product_name",
                              product_name,
                              "required"
                            )
                          }
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "product_id",
                            value: product_id,
                            disabled: true,
                          }}
                          labelText="Product ID"
                          onChange={this.handelChangeInputOut}
                        />
                      </Col>
                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "category",
                            value: category,
                          }}
                          labelText="Category"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "sub_category",
                            value: sub_category,
                          }}
                          labelText="Sub Category"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "now_price",
                            value: now_price,
                          }}
                          labelText="Now Price"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={12}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "was_price",
                            value: was_price,
                          }}
                          labelText="Was Price"
                          onChange={this.handelChangeInput}
                        />
                      </Col>


                      <Col sm={12}>
                        <InputField
                          impo
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            type: "number",
                            name: "stock",
                            value: stock,
                          }}
                          labelText="Stock"
                          onChange={this.handelChangeInput}
                        />
                      </Col>

                      <Col sm={24}>
                        <InputField
                          label={{ type: "secondary" }}
                          input={{
                            size: "large",
                            name: "http_link",
                            value: http_link,
                          }}
                          labelText="HTTP Link"
                          onChange={this.handelChangeInput}
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          {/* <Col className="gutter-row" span={24}>
                    <Row gutter={[24, 24]} className="gutter-box">
                        <Col>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['0']}
                                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                                style={this.collapsBlock}
                            >

                                <Panel header="DIMENSIONS" key="1" style={this.customPanelStyle}>

                                    <Row gutter={[24, 24]} style={{ margin: "10px 0px 10px 0px" }}>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="Export Carton Qty ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="Export Carton CBM ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="Product Dimension ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                        <Col sm={12}>
                                            <InputNumberDimensions3 label={{ type: "secondary" }} labelText="GiftBox ( L x B x H )" input={{ size: "large" }} />
                                        </Col>
                                    </Row>

                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Col> */}
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Collapse
                  bordered={false}
                  defaultActiveKey={["0"]}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  style={this.collapsBlock}
                >
                  <Panel
                    header="ATTRIBUTES"
                    key="1"
                    style={this.customPanelStyle}
                  >
                    <Row
                      gutter={[24, 24]}
                      style={{ margin: "10px 0px 10px 0px" }}
                    >
                      <Col sm={24}>
                        <Attributes
                          sendList={this.getAttributesData}
                          initialdata={this.state.attributes || []}
                          addbuttontitle="Add More Attributes"
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          {/* <Col className="gutter-row" span={24}>
                    <Row gutter={[24, 24]} className="gutter-box">
                        <Col>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['0']}
                                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                                style={this.collapsBlock}
                            >

                                <Panel header="SPECIAL FEATURES" key="1" style={this.customPanelStyle}>

                                    <Row gutter={[24, 24]} style={{ margin: "10px 0px 10px 0px" }}>
                                        <Col sm={24}>
                                            <Attributes getData={this.getFeaturesData} addbuttontitle="Add More Features" />
                                        </Col>
                                    </Row>

                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Col> */}
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col>
                <Row
                  gutter={[24, 24]}
                  style={{ margin: "0px", minHeight: "230px" }}
                >
                  <Col style={{ padding: "0px" }} sm={24}>
                    <FormHeader header="NOTES" />
                  </Col>
                  <Col sm={24}>
                    <EditorBox
                      value={product_description}
                      getText={this.getEditorText}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ channelProductEbay }) => {
  return {
    ...channelProductEbay,
  };
};

export default connect(mapStateToProps, {
  fetchEbay,
  handelChangeEbay,
  saveEbayForm,
})(AmazonUkForm);
