import { Typography, Row } from "antd";
import styled from "styled-components";
const { Title } = Typography;

export const BreadcrumbContainer = styled(Row)`
  padding: 20px 0px;
  width: 100%;
  background-color: "#313841";
  margin: 5px 0px 0px 0px;
`;

export const ProductDetailsTitle = styled(Title)`
  color: #dee2e6 !important;
  margin: 0px !important;
`;

export const ProductDetailsTitleIsTrancate = styled(Title)`
color: #dee2e6 !important;
font-size : 20px !important;
margin: 0px !important;
`;
