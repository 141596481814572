const OPEN_RPN = "OPEN_REFRESH_PAGE_NOTIFICATION"
const CLOSE_RPN = "CLOSE_REFRESH_PAGE_NOTIFICATION"

function openRefreshPageNotification(payload) {
    return {
        type: OPEN_RPN,
        payload: payload
    }
}

function closeRefreshPageNotification(payload) {
    return {
        type: CLOSE_RPN,
        payload: payload
    }
}

export { OPEN_RPN, CLOSE_RPN, openRefreshPageNotification, closeRefreshPageNotification }