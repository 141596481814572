import React from "react";
import { Row, Col, Avatar, Icon, Typography, Collapse } from "antd";
import UserAvatar from "../../../media/male_avatar.png"
import BasicCustomerDetails from "./BasicCustomerDetails";
import CustomerDetailsTabs from "./CustomerDetailsTabs";

const { Panel } = Collapse;
const { Text } = Typography;

export default function CustomerDetails({ customer, username, closeIt, updatePost,locationGroupUuid }) {

    console.clear()
    console.log("this is the customer",customer)
    //console.log("List Uuid",locationGroupUuid);

    return (
        <Row style={{ height: "90vh", position: "relative", background: "#36404a", padding: "10px", }} gutter={[12, 12]} className="slide-in">
            <Col>
                <Row gutter={[12, 12]}>
                    <Col sm={24}>

                        {/* <Text style={{ color: "#54BD9D", fontWeight: "bold", fontSize: "10px" }} className="">
                            CUSTOMER DETAILS
                        </Text> */}

                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIconPosition="right"
                            className="customer-details-collapse"
                            style={{
                                padding: "10px",
                                background: "#233140",
                                color: "#54BD9D", fontWeight: "bold", fontSize: "10px"
                            }}
                            expandIcon={({ isActive }) => {
                                return isActive ? <Icon type="minus" /> : <Icon type="plus" />
                            }}
                        >
                            <Panel header="CUSTOMER DETAILS" key="1">
                                <BasicCustomerDetails customer={customer} locationGroupUuid={locationGroupUuid}/>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col sm={24} >
                        <CustomerDetailsTabs customer={customer} username={username} updatePost = {updatePost} unitCartList={customer && customer.unitCartList} reviewList = {customer && customer.reviewList} />
                    </Col>
                </Row>
            </Col>

            {/* <Col style={{
                background: "#233140"
            }}>
                <Row style={{
                    padding: "5px"
                }}>
                    <Col sm={12}>
                        <Text style={{ color: "#54BD9D", fontWeight: "bold", fontSize: "10px" }} className="">
                            Basic
                        </Text>
                    </Col>
                </Row>
            </Col> */}


            <div style={{ position: "absolute", right: "-10px", top: "-10px" }}>
                <Icon type="close-circle" style={{ cursor: "pointer", color: "#59CAAA", fontSize: "20px" }} onClick={closeIt} />
            </div>
        </Row>
    )
}