import React, { Component } from "react";
import {
  Card,
  Divider,
  Row,
  Col,
  Button,
  Modal,
  Collapse,
  Icon,
  Avatar,
  Typography,
  message,
} from "antd";

import "./factory-card-page.scss";
import BulkPiCard from "../components/bulk-pi-card/BulkPiCard";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import FactoryCard from "../components/factory-card/FactoryCard";
import { Ellipsis } from "react-spinners-css";
import isDev from "../../server";

const serverUrl = isDev();

export default class FactoryCardPage extends Component {

  state = {
    data: null,
    loading: false
  }

  componentDidMount = () => {
    let data = {
      uuid: this.props.piBundleUuid
    };
    fetch(`${serverUrl}/sourcing/fetch-pi-factory-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 200) {
          this.setState({
            data,
          })
        }
        else {
          message.error("Something Went Wrong!");
        }
      });
  }

  render() {

    if (!this.state.data) {
      return (
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ width: "100%", height: "100%" }}
        >
          <Ellipsis color="#1ABC9C" size={100} />
        </Row>
      )
    }

    return (
      <Row className="factory-cards-page-container">
        <TitleWithBreadCrumb
          title="Factory Cards"
          breadcrumb={["OmnyComm", "Factory Cards"]}
        />
        <Col span={24} className="factory-cards-container">
          <Row className="" gutter={[16, 16]}>

            {
              this.state.data && this.state.data.pi_factory_list.map((item, index) => {
                return (
                  <Col key={index} sm={24} lg={8} md={12}>
                    <FactoryCard data={item} />
                  </Col>
                )
              })
            }
          </Row>
        </Col>
      </Row>
    );
  }
}
