import { Typography, Row, Select, Button, Collapse } from "antd";
import styled from "styled-components";
const { Title, Text, Paragraph } = Typography;

export const TableContainer = styled(Row)``;

export const TableHead = styled(Row)`
    width: 100%;
    padding: 12px;
    background-color: #313841;

    /* border-bottom: 1px solid red; */
`;

export const TableRowText = styled(Paragraph)`
    display: block;
    margin-bottom : 0px !important;
`;

export const TableHeadText = styled(Text)`
    color: #dee2e6;
`;

export const WhSelect = styled(Select)`
  && {
    color: #dee2e6;
    .ant-select-arrow {
      color: #1abc9c;
    }
    .ant-select-selection {
      background-color: #36404a;
    }
  }
`;
export const WhOption = styled(Option)``;
export const PriceSelect = styled(Select)`
  && {
    color: #dee2e6;
    .ant-select-arrow {
      color: #1abc9c;
    }
    .ant-select-selection {
      background-color: #36404a;
    }
  }
`;
export const PriceOption = styled(Option)``;
export const ProductDetailsTitle = styled(Title)`
  color: #dee2e6 !important;
  margin: 0 !important;
`;
export const FilterBtn = styled(Button)`
  && {
    background-color: #36404a;
    border: 1px solid #45525f;

    &:hover {
      background-color: #313841;
      color: white;
    }
  }
`;
export const TableRow = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #313841;

  /* border-bottom: 1px solid red; */
`;

export const TableRowCollapse = styled(Collapse)`
width: 100%;
border: none;
margin-bottom: 10px;
`;

export const BaseProductContent = styled(Row)`
padding: 12px;
width: 100%;
border-top: 1px solid #495561;
`;

export const ProductVariantContainer = styled(Row)`
width: 100%;
`;

export const ProductVariantRow = styled(Row)`
width: 100%;
padding: 5px 2px;
/* background-color: #3f4a56; */
/* border-top: 0.5px solid #495561; */
/* border-bottom: 0.5px solid #495561; */
`;