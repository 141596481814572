import React, { Component } from 'react';
import { Card, Divider, Row, Col, Button, Modal, Typography } from "antd";
import "./cards.scss";

const { Text } = Typography;


export default class ChannelCard extends Component {
    render() {
        return (
            <Card
                title="Amazon UAE"
                bordered={true}
                className={
                    this.props.activeIndex == this.props.index ?
                        "border-is-active channel-card" :
                        "channel-card"
                }

            >
                <Divider style={{ margin: "0px" }} />

                <Row style={{ padding: "10px 0px 0px 0px" }}>
                    <Col span={12} style={{ borderRight: "1px solid #495561" }}>
                        <Text className="text-ellipsis">
                            Category
                        </Text>
                    </Col>
                    <Col className="text-color3 padding-left" span={12}>
                        <Text className="text-ellipsis">
                            Sub-Category
                        </Text>
                    </Col>
                </Row>


            </Card>
        )
    }
}
