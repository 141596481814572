import React, {useState, useEffect, useCallback} from "react"
import {Row, Col, Card, Input, Avatar, Typography, Icon, AutoComplete, message, Button, Select, InputNumber} from "antd"
import styled from 'styled-components'
import isDev from '../../server'
import axios from "axios"
import './playground.scss'
import {handelEditProductDetails} from "../../actions";
import {locationUuidConst} from 'constants/locationUuid'

const {Text} = Typography
const {Option} = Select
const serverUrl = isDev()


const TableContainer = styled(Row)``
const TableHead = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #3f4a56;
  margin-top: 20px;
  border-bottom: 1px solid #495561;
  border-top: 1px solid #495561;
 
`
const TableRow = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #313841;
  border: 1px;
  margin: 5px 0;

  /* border-bottom: 1px solid red; */
`
const TableRowText = styled(Text)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const TableHeadText = styled(Text)`
  color: #dee2e6;
`
// function debounce(func, wait, immediate) {
//     let timeout;
//     return function () {
//         let context = this, args = arguments;
//         let later = function () {
//             timeout = null;
//             if (!immediate) func.apply(context, args);
//         };
//         let callNow = immediate && !timeout;
//         clearTimeout(timeout);
//         timeout = setTimeout(later, wait);
//         if (callNow) func.apply(context, args);
//     };
// };


const ProductTableRow = ({product, index, deleteProductFromCart, fetchCartDetails}) => {

    const [qty, setQty] = useState(product.quantity || 1)
    const [price, setPrice] = useState(0)
    const [editQty, setEditQty] = useState(false)
    const [editPrice, setEditPrice] = useState(false)

    useEffect(() => {
        setPrice(product.price)
    }, [product.price])

    const onChangeQuantity = (uuid) => {
        console.log(price)
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/cart/update-cart-details/`,
            data: {
                unitCartUuid: uuid,
                quantity: qty,
                offline_price: price,
                is_order_offline: true,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                fetchCartDetails()
                if (response.data.status === 200)
                    message.success("Quantity changed!")
                else if (response.data.status === 403) {
                    message.info("You are not authorised!")
                } else
                    message.error("Something went wrong!")
                setEditQty(!editQty)
            })
    }
    const onChangePrice = (uuid) => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/cart/update-cart-details/`,
            data: {
                unitCartUuid: uuid,
                offline_price: price,
                quantity: qty,
                is_order_offline: true,

            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                fetchCartDetails()
                if (response.data.status === 200)
                    message.success("Price changed!")
                else if (response.data.status === 403) {
                    message.info("You are not authorised!")
                } else
                    message.error("Something went wrong!")
                setEditPrice(!editPrice)
            })
    }

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         console.log("hit enterS")
    //     }
    // }

    return (
        <TableRow key={index} gutter={[8, 8]} className="flex-center">
            <Col span={3}>
                <TableRowText>
                    <Avatar shape="square" size={68} src={product.productImageUrl}/>
                </TableRowText>
            </Col>
            <Col span={10}>
                <TableRowText>
                    <Text style={{fontSize: "14px", color: "#fff", fontWeight: "bold"}}>
                        {product.productName}
                    </Text>
                    <div>
                        {product.sellerSku}
                    </div>
                </TableRowText>
            </Col>
            <Col span={2}>
                <TableRowText>
                    {product.brand}
                </TableRowText>
            </Col>
            <Col span={3} style={{display: 'flex', justifyContent: 'center'}}>
                <TableRowText>
                    {editPrice
                        ? <>
                            <Input value={price}
                                   onPressEnter={() => onChangePrice(product.uuid)}
                                   onChange={(e) => setPrice(e.target.value)}
                                   style={{width: "70%", marginRight: "5px"}}/>
                            <Icon type="check-circle" style={{cursor: "pointer", color: "#1abc9c"}}
                                  onClick={() => onChangePrice(product.uuid)}/>
                        </>
                        : <>
                            {product.price}{" "}
                            <Icon type="edit" style={{color: "#1abc9c"}} onClick={() => setEditPrice(!editPrice)}/>
                        </>
                    }
                </TableRowText>
            </Col>
            <Col span={3} style={{display: 'flex', justifyContent: 'center'}}>
                <TableRowText>
                    {editQty
                        ? <>
                            <InputNumber min={1}
                                         value={qty}
                                         onPressEnter={() => onChangeQuantity(product.uuid)}
                                         onChange={(value) => setQty(value)}
                                         style={{width: "50%", marginRight: "5px"}}/>
                            <Icon type="check-circle" style={{cursor: "pointer", color: "#1abc9c"}}
                                  onClick={() => onChangeQuantity(product.uuid)}/>
                        </>
                        : <>
                            {product.quantity}{" "}
                            <Icon type="edit" style={{color: "#1abc9c"}} onClick={() => setEditQty(!editQty)}/>
                        </>
                    }
                </TableRowText>
            </Col>
            <Col span={2}>
                <TableRowText>
                    {product.price * product.quantity}
                </TableRowText>
            </Col>
            <Col span={1}>
                <Icon type="delete" onClick={() => deleteProductFromCart(product.uuid)}
                      style={{color: "red", cursor: "pointer"}}/>
            </Col>
        </TableRow>
    )
}

const OrderDetails = ({current, locationGroupUuid, customerUserName, setIsCartEmpty, orderNote, setOrderNote}) => {

    const [total, setTotal] = useState({})
    const [productList, setProductList] = useState([])
    const [productTerm, setProductTerm] = useState("")
    const [fethcedProducts, setFetchedProducts] = useState([])
    const [paymentData, setPaymentData] = useState({
        delivery_fee: 0,
        toPay: "",
        vat: ""
    });

    const [cartUuid, setCartUuid] = useState("")

    const [voucherFlowStart, setVoucherFlowStart] = useState(true);
    const [voucherCode, setVoucherCode] = useState("");
    const [addVoucherLoading, setAddVoucherLoading] = useState(false);
    const [errorMeassge, setErrorMessage] = useState("");
    const [afterVoucherApplied, setAfterVoucherApplied] = useState({});
    const [showVoucherDiscount, setShowVoucherDiscount] = useState(false);
    const [removeVoucherLoading, setRemoveVoucherLoading] = useState(false);
    const [refferenceMedium, setRefferenceMedium] = useState("none");
    // const [shippingCharge, setShippingCharge] = useState(paymentData.delivery_fee)
    // const [codCharge, setCodCharge] = useState(paymentData.codCharge)
    const [editShippingCharge, setEditShippingCharge] = useState(false)
    const [editCodCharge, setEditCodCharge] = useState(false)

    const isNotGeepasUganda = locationGroupUuid !== locationUuidConst.Geepas_uganda && 
                            locationGroupUuid !== locationUuidConst.QA_geepas_uganda;
    //const [orderNote, setOrderNote] = useState("")
    const onChangeCodCharge = (uuid) => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/cart/update-offline-cart-details/`,
            data: {
                cartUuid: uuid,
                offline_cod_charge: paymentData.codCharge,
                offline_delivery_fee: parseFloat(paymentData.delivery_fee)
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                // fetchCartDetails()
                if (response.data.status === 200) {
                    message.success("Price changed!")
                    setPaymentData({
                        ...paymentData,
                        delivery_fee: response.data.codBill.delivery_fee,
                        toPay: response.data.codBill.toPay,
                        vat: response.data.codBill.vat
                    })
                } else if (response.data.status === 403) {
                    message.info("You are not authorised!")
                } else
                    message.error("Something went wrong!")
                setEditCodCharge(!editCodCharge)
            })
    }

    const onChangeDeliveryFee = (uuid) => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/cart/update-offline-cart-details/`,
            data: {
                cartUuid: uuid,
                offline_cod_charge: paymentData.codCharge,
                offline_delivery_fee: parseFloat(paymentData.delivery_fee)
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                // fetchCartDetails()
                if (response.data.status === 200) {
                    message.success("Price changed!")
                    setPaymentData({
                        ...paymentData,
                        delivery_fee: response.data.codBill.delivery_fee,
                        toPay: response.data.codBill.toPay,
                        vat: response.data.codBill.vat
                    })
                } else if (response.data.status === 403) {
                    message.info("You are not authorised!")
                } else
                    message.error("Something went wrong!")
                setEditShippingCharge(!editShippingCharge)
            })
    }



    // const debouncedApiCall = useCallback(debounce(nextVal => onChangeOrderNote(nextVal),1000), [])
    //
    // const handleOrderNote = (e) => {
    //     setOrderNote(e)
    //     debouncedApiCall(e)
    // }
    const onChange = (e) => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/add-offline-reference-medium/`,
            data: {
                locationGroupUuid: locationGroupUuid,
                username: customerUserName,
                reference_medium: e
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                if (response.data.status == 200) {
                    setRefferenceMedium(e);
                } else {
                    message.error("Something went worng!")
                }
            })
    }

    //when a customer is selected or created
    useEffect(() => {
        fetchCartDetails()
    }, [customerUserName])

    //fetch cart details for the customer
    const fetchCartDetails = () => {
        if (customerUserName == null) return;
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/cart/fetch-offline-cart-details/`,
            data: {
                locationGroupUuid: locationGroupUuid,
                username: customerUserName,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                if (response.data.status == 200) {
                    setProductList(response.data.unitCartList)
                    setTotal({total: response.data.subtotal, currency: response.data.currency})
                    setCartUuid(response.data.cartUuid)
                    setIsCartEmpty(response.data.unitCartList === undefined ? true : response.data.unitCartList.length === 0 ? true : false)
                    setPaymentData({
                        delivery_fee: response.data.codBill.delivery_fee,
                        toPay: response.data.codBill.toPay,
                        vat: response.data.codBill.vat,
                        codCharge: response.data.codBill.codCharge,
                    })

                    if (response.data.codBill.is_voucher_applied) {
                        setVoucherFlowStart(false);
                        setVoucherCode(response.data.codBill.voucher_code);
                        setAfterVoucherApplied(response.data.codBill);
                        setShowVoucherDiscount(true);
                    }
                } else {
                    message.error("Something went worng!")
                }
            })
    }

    const submitVoucherCode = () => {
        setAddVoucherLoading(true);
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/apply-offline-voucher-code/`,
            data: {
                locationGroupUuid: locationGroupUuid,
                username: customerUserName,
                voucher_code: voucherCode,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                if (response.data.status == 200) {
                    setAddVoucherLoading(false);
                    if (response.data.voucher_success == false) {
                        setErrorMessage(response.data.error_message);
                    } else {
                        setAfterVoucherApplied(response.data.codBill)
                        setPaymentData({
                            ...paymentData,
                            delivery_fee: response.data.codBill.delivery_fee,
                            toPay: response.data.codBill.toPay
                        })
                        setShowVoucherDiscount(true);
                        setVoucherFlowStart(false);
                    }
                } else {
                    message.error("Something went worng!")
                }
            })
    }

    const removeVoucher = () => {
        setRemoveVoucherLoading(true);
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/remove-offline-voucher-code/`,
            data: {
                locationGroupUuid: locationGroupUuid,
                username: customerUserName,
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                setRemoveVoucherLoading(false);
                if (response.data.status == 200) {
                    setShowVoucherDiscount(true);
                    fetchCartDetails();
                    setVoucherFlowStart(true);
                    setVoucherCode("");
                } else {
                    message.error("Something went worng!")
                }
            })
    }

    //handles the event when a product is clicked upon
    const handleSelect = (selectedValue) => {
        const selectedProduct = fethcedProducts[selectedValue];
        setProductList([...productList, selectedProduct])
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/cart/add-to-offline_cart/`,
            data: {
                productUuid: selectedProduct.uuid,
                quantity: 1,
                username: customerUserName,
                locationGroupUuid: locationGroupUuid
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                fetchCartDetails()
                setProductTerm("")
            })

    }

    //handles the product search
    const autoCompleteSearch = (searchString) => {
        setProductTerm(searchString)
        if (searchString !== "") {
            axios({
                method: "POST",
                url: `${serverUrl}/dealshub/search-products/`,
                data: {
                    searchString: searchString,
                    locationGroupUuid: locationGroupUuid
                },
                headers: {
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    setFetchedProducts(response.data.productList)
                })
        }
    }

    //delete the product from the cart
    const deleteProductFromCart = (uuid) => {
        axios({
            method: "POST",
            url: `${serverUrl}/dealshub/cart/remove-from-cart/`,
            data: {
                unitCartUuid: uuid
            },
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(response => {
                fetchCartDetails()
                if (response.data.status === 200) {
                    message.success("product removed from the cart!")
                    // setPaymentData({
                    //     delivery_fee: response.data.codBill.delivery_fee,
                    //     toPay: response.data.codBill.toPay,
                    //     vat: response.data.codBill.vat,
                    //     codCharge: response.data.codBill.codCharge,
                    // })
                } else
                    message.error("Something went wrong!")
            })
    }

    const onKeyUp = (event) => {
        console.log(event)
        if (event.charCode === 13) {
            submitVoucherCode();
        }
    }

    return (
        <Card style={{marginTop: "20px", display: current ? "" : "none"}}>
            <Row>
                <AutoComplete
                    // dataSource={fethcedProducts.map(item=>item.name)}
                    value={productTerm}
                    onSelect={handleSelect}
                    onSearch={autoCompleteSearch}
                    placeholder="search the product you want to add in the cart"
                    style={{width: "100%"}}
                >
                    {fethcedProducts.map((item, index) => {
                        return (
                            <Option key={index}>{item.name}</Option>
                        )
                    })}
                </AutoComplete>
            </Row>
            <TableContainer
                type="flex"
                align="middle"
                style={{marginTop: "20px"}}
            >
                <TableHead gutter={[8, 8]} className="flex-center">
                    <Col span={3}>
                        <TableHeadText>
                            Image
                        </TableHeadText>
                    </Col>
                    <Col span={10}>
                        <TableHeadText>
                            Product Name
                        </TableHeadText>
                    </Col>
                    <Col span={2}>
                        <TableHeadText>
                            Brand
                        </TableHeadText>
                    </Col>
                    <Col span={3} style={{display: 'flex', justifyContent: 'center'}}>
                        <TableHeadText>
                            Price
                        </TableHeadText>
                    </Col>
                    <Col span={3} style={{display: 'flex', justifyContent: 'center'}}>
                        <TableHeadText>
                            Qty
                        </TableHeadText>
                    </Col>

                    <Col span={2}>
                        <TableHeadText>
                            Total
                        </TableHeadText>
                    </Col>
                    <Col span={1}>
                        <></>
                    </Col>
                </TableHead>

                {productList &&
                productList.map((product, index) =>
                    <ProductTableRow
                        product={product}
                        index={index}
                        deleteProductFromCart={deleteProductFromCart}
                        fetchCartDetails={fetchCartDetails}
                    />
                )}

                <TableRow style={{margin: "5px 0px 5px 0px", border: "1px solid gray", padding: "10px"}}>

                    <Col span={24} style={{padding: "5px"}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex" justify="start" align="middle">
                                    Sub Total
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row type="flex" justify="end" align="middle">
                                    {total.total}{" "}{total.currency}
                                </Row>
                            </Col>
                        </Row>
                    </Col>


                    <Col span={24} style={{padding: "5px"}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex" justify="start" align="middle">
                                    Shipping Charges
                                </Row>
                            </Col>
                            {

                                editShippingCharge ? <>
                                        <Col span={12}>
                                            <Row type="flex" justify="end" align="middle">
                                                <Input value={paymentData.delivery_fee}
                                                       onPressEnter={() => onChangeDeliveryFee(cartUuid)}
                                                       onChange={(e) => setPaymentData({
                                                           ...paymentData,
                                                           delivery_fee: e.target.value
                                                       })} style={{width: '20%', marginRight: '5px'}}/>
                                                <Icon type="check-circle" style={{cursor: "pointer", color: "#1abc9c"}}
                                                      onClick={() => onChangeDeliveryFee(cartUuid)}/>
                                            </Row>
                                        </Col>
                                    </> :
                                    <>
                                        {paymentData.delivery_fee != 0 ?
                                            <Col span={12}>
                                                <Row type="flex" justify="end" align="middle">
                                                    {paymentData.delivery_fee}{" "}{total.currency}
                                                    <Icon type="edit" style={{color: "#1abc9c", marginLeft: "5px"}}
                                                          onClick={() => setEditShippingCharge(!editShippingCharge)}/>
                                                </Row>
                                            </Col> :
                                            <Col span={12}>
                                                <Row type="flex" justify="end" align="middle">
                                                    FREE
                                                    <Icon type="edit" style={{color: "#1abc9c", marginLeft: "5px"}}
                                                          onClick={() => setEditShippingCharge(!editShippingCharge)}/>
                                                </Row>
                                            </Col>}

                                    </>
                            }

                        </Row>
                    </Col>

                    <Col span={24} style={{padding: "5px"}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex" justify="start" align="middle">
                                    COD Charges
                                </Row>
                            </Col>
                            {
                                editCodCharge ? <>
                                        <Col span={12}>
                                            <Row type="flex" justify="end" align="middle">
                                                <Input value={paymentData.codCharge}
                                                       onPressEnter={() => onChangeCodCharge(cartUuid)}
                                                       onChange={(e) => setPaymentData({
                                                           ...paymentData,
                                                           codCharge: e.target.value
                                                       })} style={{width: '20%', marginRight: '5px'}}/>
                                                <Icon type="check-circle" style={{cursor: "pointer", color: "#1abc9c"}}
                                                      onClick={() => onChangeCodCharge(cartUuid)}/>
                                            </Row>
                                        </Col>

                                    </> :
                                    <>
                                        {paymentData.codCharge != 0 ?
                                            <Col span={12}>
                                                <Row type="flex" justify="end" align="middle">
                                                    {paymentData.codCharge}{" "}{total.currency}
                                                    <Icon type="edit" style={{color: "#1abc9c", marginLeft: "5px"}}
                                                          onClick={() => setEditCodCharge(!editCodCharge)}/>
                                                </Row>
                                            </Col> :
                                            <Col span={12}>
                                                <Row type="flex" justify="end" align="middle">
                                                    FREE
                                                    <Icon type="edit" style={{color: "#1abc9c", marginLeft: "5px"}}
                                                          onClick={() => setEditCodCharge(!editCodCharge)}/>
                                                </Row>
                                            </Col>}

                                    </>
                            }
                        </Row>
                    </Col>

                    <Col span={24} style={{padding: "5px"}}>
                        <Row>
                            {
                                voucherFlowStart ?
                                    <React.Fragment>
                                        <Col span={8}>
                                            {
                                                <Row type="flex" justify="start" align="middle">
                                                    <Input value={voucherCode} onKeyPress={onKeyUp} onChange={(e) => {
                                                        setVoucherCode(e.target.value);
                                                        setErrorMessage("")
                                                    }} type="text"/>
                                                    {
                                                        errorMeassge != "" &&
                                                        <Text style={{color: "red"}}>{errorMeassge}</Text>
                                                    }
                                                </Row>
                                            }

                                        </Col>

                                        <Col span={16}>
                                            <Row type="flex" justify="end" align="middle">
                                                <Button onClick={() => submitVoucherCode()} loading={addVoucherLoading}
                                                        type="primary">Add Voucher</Button>
                                            </Row>
                                        </Col>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <Col span={8}>

                                            <Row type="flex" justify="start" align="middle">
                                                <Text style={{
                                                    color: "#1ABC9C",
                                                    border: "1px dotted #1ABC9C",
                                                    padding: "5px"
                                                }}>{voucherCode}{" "}Applied</Text>
                                            </Row>

                                        </Col>

                                        <Col span={16} style={{textAlign: "right"}}>
                                            {
                                                showVoucherDiscount &&
                                                <React.Fragment>-{afterVoucherApplied.voucher_discount}{" "}{total.currency}
                                                    {" "}<Icon type={removeVoucherLoading ? "loading" : "close-circle"}
                                                               style={{color: "red", marginLeft: "3px"}}
                                                               onClick={() => removeVoucher()}/>
                                                </React.Fragment>
                                            }
                                        </Col>
                                    </React.Fragment>
                            }


                        </Row>
                    </Col>

                    <Col style={{padding: "10px"}}></Col>

                    <Col span={24} style={{padding: "5px"}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex" justify="start" align="middle">
                                    Estimated VAT
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row type="flex" justify="end" align="middle">
                                    {paymentData.vat}{" "}{total.currency}
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24} style={{padding: "5px"}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex" justify="start" align="middle">
                                    Total (Inc. of VAT)
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row type="flex" justify="end" align="middle">
                                    {paymentData.toPay}{" "}{total.currency}
                                </Row>
                            </Col>
                        </Row>
                    </Col>


                </TableRow>

                <TableRow type="flex" justify="right">
                    <Col span={14}
                        style={{marginBottom: "16px"}}
                    >
                        {/*<Button>Add Order Note</Button>*/}
                        <Input.TextArea
                            placeholder={"Add order note..."}
                            //value={orderNote}
                            onChange={(e) => setOrderNote(e.target.value)}
                            //onPressEnter={(e) => onChangeOrderNote(e.target.value)}
                        />
                    </Col>

                    <Col span={24}>
                        <Text>
                            Add which medium the offline orders where placed through.
                        </Text>
                        <Col>
                            <Select
                                showSearch
                                style={{width: "200px"}}
                                onChange={onChange}
                                size="large"
                                optionFilterProp="children"
                                value={refferenceMedium}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value={"none"}>
                                    None
                                </Option>
                                <Option value={"whatsapp"}>
                                    Whatsapp
                                </Option>
                                <Option value={"facebook"}>
                                    Facebook
                                </Option>
                                <Option value={"instagram"}>
                                    Instagram
                                </Option>
                                <Option value={"b2b"}>
                                    B2B
                                </Option>
                                <Option value={"replacement"}>
                                    Replacement
                                </Option>
                                { isNotGeepasUganda &&
                                    <Option value={"direct"}>
                                        Direct
                                    </Option>
                                }
                                { isNotGeepasUganda &&
                                    <Option value={"geepas"}>
                                        Geepas
                                    </Option>
                                }
                                { isNotGeepasUganda &&
                                    <Option value={"krypton world"}>
                                        Krypton world
                                    </Option>
                                }
                                { isNotGeepasUganda &&
                                    <Option value={"pulcon"}>
                                        Pulcon
                                    </Option>
                                }
                                { isNotGeepasUganda &&
                                    <Option value={"ikhwa"}>
                                        Ikhwa
                                    </Option>
                                }
                                { isNotGeepasUganda &&
                                    <Option value={"noon"}>
                                        Noon
                                    </Option>
                                }
                                { isNotGeepasUganda &&
                                    <Option value={"amazon"}>
                                        Amazon
                                    </Option>
                                }
                            </Select>
                        </Col>
                    </Col>
                </TableRow>

            </TableContainer>
        </Card>
    )
}

export default OrderDetails