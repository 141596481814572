import React, { Component } from "react";
import {
  Card,
  Divider,
  Row,
  Col,
  Button,
  Modal,
  Collapse,
  Icon,
  Avatar,
  Typography,
} from "antd";

import "./bulk-pi-card.scss";

const { Text } = Typography;

export default class BulkPiCard extends Component {
  render() {
    return (
      <React.Fragment>
        <Card bordered={true} className="bulk-pi-card">
          <Row style={{ padding: "10px 0px 0px 0px" }}>
            <Col span={12}>
              <Row type="flex">
                <Col>
                  <Text type="secondary" className="margin-right">
                    Products:
                  </Text>
                </Col>
                <Col>
                  <Text>02</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row type="flex">
                <Col>
                  <Text type="secondary" className="margin-right">
                    Date:
                  </Text>
                </Col>
                <Col>
                  <Text>11/12/2020</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ padding: "10px 0px 0px 0px" }}>
            <Col span={12}>
              <Row type="flex">
                <Col>
                  <Text type="secondary" className="margin-right">
                    Total Qty:
                  </Text>
                </Col>
                <Col>
                  <Text>05</Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row type="flex">
                <Col>
                  <Text type="secondary" className="margin-right">
                    Total Price:
                  </Text>
                </Col>
                <Col>
                  <Text>1010 AED</Text>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 8]} style={{ marginTop: "10px" }}>
            <Col lg={12} md={12}>
              <Button type="default" style={{ width: "100%" }}>
                06 PI
              </Button>
            </Col>

            <Col lg={12} md={12}>
              <Button type="primary" style={{ width: "100%" }} icon="download">
                Download
              </Button>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}
