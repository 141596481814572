/**
 * action.payload = {
 * bundleId,
 * isSapManualUpdate
 * }
 */

const manualSAPOverride = (state = new Map(), action) => {
    switch (action.type) {
        case 'UPDATE': state.set(action.payload.bundleId, action.payload.isSapManualUpdate);
            return state;
        default: return state;
    }
}

export default manualSAPOverride