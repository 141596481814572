import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Row, Col, Select, Checkbox, Icon } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styled from "styled-components";
import { fetchAll, addFilters } from "../../../../actions/exports";

import isDev from "../../../../server";

const serverUrl = isDev();

const { Option } = Select;

const FilterBtn = styled(Button)`
  && {
    background-color: #1abc9c;
    color: white;
    border: none;

    &:hover {
      background-color: #1abc9c;
      color: white;
      border: none;
    }
  }
`;

class ChannelWiseProductListFilterModal extends Component {
  state = { visible: false , has_image : "" };

  showModal = () => {
    this.setState({
      visible: true,
      startDate: new Date()
    });
  };

  handleOk = e => {

    this.setState({
      visible: false
    });

    let filterData = {
      brand_name: this.state.brand_name ? this.state.brand_name : "",
      has_image: this.state.has_image,
      start_date: this.state.startDate ? this.state.startDate : "",
      end_date: this.state.endDate ? this.state.endDate : "",
      verified: this.state.verified ? this.state.verified : ""
    };

    // this.props.dispatch({ type: "ACTIVATE_FILTER" })
    this.props.dispatch(
      fetchAll(
        this.props.channelname,
        filterData,
        this.props.channelwiseState.tags
      )
    );
    this.props.dispatch(addFilters(filterData));
  };

  handleCancel = e => {

    this.setState({
      visible: false,
      has_image: ""
    });
  };

  // handleCheckbox = ({ target: { checked } }) => {
  //     
  //     this.setState({
  //         verified: checked
  //     })
  // }

  handleImageSelect = value => {
    this.setState({ has_image: value });
    let filterData = {
      brand_name: this.state.brand_name,
      has_image: value,
      start_date: this.state.startDate ? this.state.startDate : "",
      end_date: this.state.endDate ? this.state.endDate : "",
      verified: this.state.verified ? this.state.verified : ""
    };
    this.props.dispatch(addFilters(filterData));
  };

  handleBrandSelect = value => {
    this.setState({ brand_name: value });
    let filterData = {
      brand_name: value,
      has_image: this.state.has_image ? this.state.has_image : "",
      start_date: this.state.startDate ? this.state.startDate : "",
      end_date: this.state.endDate ? this.state.endDate : "",
      verified: this.state.verified ? this.state.verified : ""
    };
    this.props.dispatch(addFilters(filterData));
  };

  componentDidMount() {
    fetch(`${serverUrl}/fetch-brands/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ brandList: data.brand_list });
      });
  }

  render() {

    return (
      <div className="product-list-filter-modal-container">
        <FilterBtn
          style={{ width: "100%" }}
          type="primary"
          icon="filter"
          onClick={this.showModal}
        >
          Filter
        </FilterBtn>
        <Modal
          title="Filter"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row type="flex" align="middle" gutter={[32, 32]}>
            <Col span={12}>
              <Select
                style={{ width: 220 }}
                onSelect={this.handleBrandSelect}
                placeholder="All"
              >
                <Option value="">All</Option>
                {this.state.brandList &&
                  this.state.brandList.map((brand, index) => {
                    return <Option value={brand.name}>{brand.name}</Option>;
                  })}
              </Select>
            </Col>
            <Col span={12}>
              <Select
                defaultValue="allImages"
                style={{ width: 220 }}
                onSelect={this.handleImageSelect}
                value={this.state.has_image}
                placeholder="All Images"
              >
                <Option value="">All Images</Option>
                <Option value={true}>With Images</Option>
                <Option value={false}>Without Images</Option>
              </Select>
            </Col>
            {/* <Col span={12}>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleStartDate}
                            />
                        </Col>
                        <Col span={12}>
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleEndDate}
                            />
                        </Col> */}
            {/* <Col span={12}>
                            <Checkbox onChange={this.handleCheckbox} checked={this.state.verified}>Verified</Checkbox>
                        </Col> */}
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ channelwise }) => {
  return {
    ...channelwise
  };
};

export default connect(mapStateToProps)(ChannelWiseProductListFilterModal);
