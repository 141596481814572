import React, { useState, useEffect, useMemo } from "react";
import {
    Row,
    Col,
    Modal,
    Typography,
    Button,
    InputNumber,
    Spin,
    Icon,
    message,
    Avatar,
} from "antd";
import "./stock-holding-modal.scss";
import axios from "axios";
import isDev from "../../../../server";
import PlaceholderImage from "../../../../media/placeholder3.png";
import { fetchProductsByTags } from "../../../../actions/dealsHubProducts";
import { connect } from "react-redux";
import { locationUuidConst } from "constants/locationUuid";

const serverUrl = isDev();

const { Text, Paragraph } = Typography;

//Hard Coded values for the Location the Modal will be visible to
const SHOW_STOCK_MANAGEMENT = [
    locationUuidConst.WIGMe_UAE,
    locationUuidConst.WIGme_B2B,
    locationUuidConst.WIGme_KWT,
    locationUuidConst.WIGme_BAH,
    locationUuidConst.QA_wigme_B2B,
    locationUuidConst.QA_wigme_UAE,
    locationUuidConst.QA_wigme_BAH,
    locationUuidConst.QA_wigme_KWT
]

const StockHoldingModal = ({ isVisible, setVisible, productUuid, fetchProductsByTags, locationUuid, tags, filters }) => {
    const [holding, setHolding] = useState(null);
    const [productDetails, setProductDetails] = useState({});
    const [atp, setAtp] = useState(null);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [max, setMax] = useState(null);

    useEffect(() => {

        if (isVisible && !SHOW_STOCK_MANAGEMENT.includes(locationUuid)) {
            message.warn(`Stock Management Feature is Not Available`)
        }

        if (isVisible && productUuid) {
            setLoading(true);
            axios
                .post(
                    `${serverUrl}/sap/fetch-product-holding-details/`,
                    {
                        product_uuid: productUuid,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${localStorage.getItem(
                                "token"
                            )} `,
                        },
                    }
                )
                .then((res) => {
                    //console.log(res);
                    delete res.data.status;
                    setProductDetails(res.data);
                    setHolding(
                        res.data.holding_details?.holding
                            ? res.data.holding_details?.holding
                            : 0
                    );
                    setAtp(res.data.holding_details?.ATP);
                    setMax((res.data.holding_details?.ATP && res.data.holding_details?.holding) ?
                        res.data.holding_details?.ATP + res.data.holding_details?.holding : (
                            (res.data.holding_details?.ATP && !res.data.holding_details?.holding) ?
                                res.data.holding_details?.ATP : res.data.holding_details?.holding))
                    setLoading(false);
                });
        }
    }, [isVisible, productUuid]);

    const handleUpdateStock = () => {
        setUpdate(true);
        axios
            .post(
                `${serverUrl}/sap/update-product-holding-details/`,
                {
                    product_uuid: productUuid,
                    holding: holding,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")} `,
                    },
                }
            )
            .then((res) => {
                //console.log(res);
                if (res.data.status === 200) {
                    fetchProductsByTags(tags, filters, null, locationUuid);
                    setUpdate(false);
                    setVisible(false);
                    message.success("Holding Successfully updated!");
                } else if (res.data.status === 503) {
                    setUpdate(false);
                    message.error("Internal SAP Error!");
                } else if (res.data.status === 501) {
                    setUpdate(false);
                    message.error("Internal Error!");
                } else {
                    setUpdate(false);
                    message.error("Something went Wrong!");
                }
            });
    };

    const changeHolding = (value) => {
        setAtp((atp + holding) - value);
        setHolding(value);
    }

    const isUpdateStock = useMemo(() => {
        if (!isVisible) {
            return false
        }

        //Hard coded that if Wigme-Kuwait or Bahrain Prod & QA then dont display the Update field
        const NOT_UPDATE_STOCK_LOCATION = [
            locationUuidConst.WIGme_KWT,
            locationUuidConst.WIGme_BAH,
            locationUuidConst.QA_wigme_KWT,
            locationUuidConst.QA_wigme_BAH
        ]
        if (NOT_UPDATE_STOCK_LOCATION.includes(locationUuid)) {
            return false
        }

        return true
    }, [isVisible, locationUuid])


    if (!SHOW_STOCK_MANAGEMENT.includes(locationUuid)) {
        return <></>
    }

    return (
        <Modal
            title="Holding Stock Management"
            visible={isVisible}
            onCancel={() => setVisible(false)}
            footer={null}
            maskClosable={!update}
            closable={!update}
            width={"fit-content"}
        >
            {
                loading &&
                <div
                    style={{
                        minHeight: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spin
                        indicator={
                            <Icon
                                type="loading"
                                style={{ fontSize: 18 }}
                                spin
                            />
                        }
                    />
                </div>
            }

            {!loading &&
                <Row>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            backgroundColor: "#454E57",
                            padding: 20,
                            marginBottom: 10,
                        }}
                    >
                        <Col span={3} style={{ height: 80, width: 100 }}>
                            <Avatar
                                src={
                                    productDetails.product_image
                                        ? productDetails.product_image
                                        : PlaceholderImage
                                }
                                alt="product"
                                shape="square"
                                style={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: 8,
                                }}
                            />
                        </Col>
                        <Col
                            span={20}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                            }}
                        >
                            <Paragraph
                                ellipsis={{ rows: 2, expandable: false }}
                                style={{ color: "#E7E7E7", fontSize: 20 }}
                            >
                                {productDetails.product_name}
                            </Paragraph>
                            <Col>
                                <Text
                                    style={{ color: "#E7E7E7", fontSize: 16 }}
                                >
                                    ID :
                                    <Text
                                        style={{
                                            color: "#E7E7E7",
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {" "}
                                        {productDetails.product_id}{" "}
                                    </Text>
                                </Text>
                                |
                                <Text
                                    style={{ color: "#E7E7E7", fontSize: 16 }}
                                >
                                    {" "}
                                    Seller SKU :
                                    <Text
                                        style={{
                                            color: "#E7E7E7",
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {" "}
                                        {productDetails.seller_sku}
                                    </Text>
                                </Text>
                            </Col>
                        </Col>
                    </Col>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#454E57",
                            padding: 20,
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#e7e7e7",
                            }}
                        >
                            Holding Stock for other channels
                        </Text>
                        <Col style={{ padding: 8 }}>
                            <Col
                                span={12}
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#e5e5e5",
                                }}
                            >
                                Sales Person
                            </Col>
                            <Col
                                span={12}
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#e5e5e5",
                                }}
                            >
                                Holding Stock
                            </Col>
                        </Col>
                        <Col style={{ maxHeight: 282, overflow: "scroll" }}>
                            {productDetails.holding_details
                                ? productDetails.holding_details?.channel_holding_qty_list?.map(
                                    (item) => {
                                        return (
                                            item.channel && (
                                                <Col
                                                    style={{
                                                        backgroundColor:
                                                            "#383F46",
                                                        margin: "5px 0px",
                                                        padding: 8,
                                                        height: 37,
                                                    }}
                                                >
                                                    <Col span={12}>
                                                        <Text
                                                            ellipsis={true}
                                                            style={{
                                                                fontSize: 14,
                                                                color:
                                                                    "white",
                                                            }}
                                                        >
                                                            {item.channel}
                                                        </Text>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Text
                                                            ellipsis={true}
                                                            style={{
                                                                fontSize: 14,
                                                                color:
                                                                    "white",
                                                            }}
                                                        >
                                                            {item.qty}
                                                        </Text>
                                                    </Col>
                                                </Col>
                                            )
                                        );
                                    }
                                )
                                : "No Channel data available"}
                        </Col>
                    </Col>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            backgroundColor: "#454E57",
                            padding: 20,
                            marginTop: 10,
                        }}
                    >
                        <Col
                            span={8}
                            style={{
                                paddingRight: 15,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Text
                                style={{
                                    color: "#e7e7e7",
                                    fontSize: 14,
                                }}
                            >
                                ATP Stock
                            </Text>
                            <InputNumber
                                min={0}
                                max={max}
                                style={{ color: "#858687" }}
                                value={atp ? atp : 0}
                                disabled={true}
                            />
                        </Col>
                        {isUpdateStock && <Col
                            span={8}
                            style={{
                                padding: "0 15px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Text
                                className="input-number"
                                style={{
                                    color: "#e7e7e7",
                                    fontSize: 14,
                                }}
                            >
                                WIGme Holding Stock
                            </Text>
                            <InputNumber
                                className="input-number"
                                onPressEnter={() => handleUpdateStock()}
                                style={{
                                    color: "white",
                                }}
                                max={max}
                                min={0}
                                value={holding ? holding : 0}
                                onChange={changeHolding}
                            />
                        </Col>}
                        {isUpdateStock &&
                            <Col
                                span={8}
                                style={{
                                    paddingLeft: 15,
                                    display: "flex",
                                    alignItems: "flex-end",
                                    paddingBottom: 2,
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: "#1ABC9C",
                                        color: "white",
                                        outline: "none",
                                        border: "none",
                                        height: 30,
                                    }}
                                    onClick={() => handleUpdateStock()}
                                    loading={update}
                                >
                                    Update Stock
                                </Button>
                            </Col>}
                    </Col>
                </Row>
            }
        </Modal>
    );
};

export default connect(null, { fetchProductsByTags })(StockHoldingModal);
