import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";

import App from "./App";

/**
 *@description This is the root element of the project
 *App component is wrapped in provider component
 *Provider takes store as a prop, store is to pass the global redux-state to child components
 */

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById("root")
);
