import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Select, Switch, Badge, Radio, Typography, Checkbox, DatePicker } from "antd";
import styled from "styled-components";
import moment from 'moment';
// import DatePicker from "react-datepicker";

import "./reports-page.scss"

const FilterBtn = styled(Button)`
  && {
    background-color: #1abc9c;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #1abc9c;
      color: white;
      border: none;
    }
  }
`;


const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

export default function ReportsPageFilterModal({ handleFilterChange, handleFilters }) {

    const [visible, setVisible] = useState(false);
    const [filters, setFilters] = useState({
        channel_name: "",
        operation_type: "",
        status: "",
        is_read: "",
    });

    const handleOk = () => {
        handleFilters();
        setVisible(false);
    }

    useEffect(() => {
        handleFilterChange(filters);
    }, [filters])

    const handleCancel = () => {
        setVisible(false);
    }

    const thisHandleFilterChange = (name, value) => {
        setFilters({
            ...filters,
            [name]: value
        })
    }

    const dateFormat = 'YYYY/MM/DD';

    const handleChange = (e) => {
        setFilters({
            ...filters,
            "start_date": e[0].toDate(),
            "end_date": e[1].toDate()
        });
    }

    const radioStyle = {
        display: "block",
    };

    return (
        <React.Fragment>
            <FilterBtn
                style={{ width: "100%" }}
                type="primary"
                onClick={() => setVisible(true)}
                block
                icon="filter"
            >
                Filter
       </FilterBtn>

            <Modal
                title="Filter"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Row gutter={[12, 12]}>

                    <Col span={12}>
                        <Text> Channel </Text><br />
                        <Radio.Group onChange={(e) => thisHandleFilterChange("channel_name", e.target.value)} value={filters.channel_name}>
                            <Radio style={radioStyle} value="">
                                <Text style={{ marginRight: "15px" }}>All Channels</Text>
                            </Radio>
                            <Radio style={radioStyle} value="Amazon UK">
                                <Text style={{ marginRight: "15px" }}>Amazon UK</Text>
                            </Radio>
                            <Radio style={radioStyle} value="Amazon UAE">
                                <Text style={{ marginRight: "15px" }}>Amazon UAE</Text>
                            </Radio>
                        </Radio.Group>
                    </Col>
                    {/* <Col sm={12}>
                        <Text> Channel </Text><br />
                        <Select
                            style={{ width: "100%" }}
                            onSelect={(e) => thisHandleFilterChange("channel_name", e)}
                            value={filters.channel_name}
                            placeholder="All Channels"
                        >
                            <Option value="">All Channels</Option>
                            <Option value="Amazon UK">Amazon UK</Option>
                            <Option value="Amazon UAE">Amazon UAE</Option>
                        </Select>
                    </Col> */}

                    <Col span={12}>
                        <Text> Operation Type </Text><br />
                        <Radio.Group onChange={(e) => thisHandleFilterChange("operation_type", e.target.value)} value={filters.operation_type}>
                            <Radio style={radioStyle} value="">
                                <Text style={{ marginRight: "15px" }}>All</Text>
                            </Radio>
                            <Radio style={radioStyle} value="Push">
                                <Text style={{ marginRight: "15px" }}>Publish Products</Text>
                            </Radio>
                            <Radio style={radioStyle} value="Inventory">
                                <Text style={{ marginRight: "15px" }}>Stock Update</Text>
                            </Radio>
                            <Radio style={radioStyle} value="Price">
                                <Text style={{ marginRight: "15px" }}>Price Update</Text>
                            </Radio>
                        </Radio.Group>
                    </Col>

                    {/* <Col sm={12}>
                        <Text> Operation Type </Text><br />
                        <Select
                            style={{ width: "100%" }}
                            onSelect={(e) => thisHandleFilterChange("operation_type", e)}
                            value={filters.operation_type}
                            placeholder="All"
                        >
                            <Option value="">All</Option>
                            <Option value="Push">Push</Option>
                            <Option value="Inventory">Inventory</Option>
                            <Option value="Price">Price</Option>
                        </Select>
                    </Col> */}

                    <Col span={12}>
                        <Text> Status </Text><br />
                        <Radio.Group onChange={(e) => thisHandleFilterChange("status", e.target.value)} value={filters.status}>
                            <Radio style={radioStyle} value="">
                                <Text style={{ marginRight: "15px" }}>Any</Text>
                            </Radio>
                            <Radio style={radioStyle} value="In Progress">
                                <Text style={{ marginRight: "15px" }}>In Progress</Text>
                            </Radio>
                            <Radio style={radioStyle} value="Done">
                                <Text style={{ marginRight: "15px" }}>Done</Text>
                            </Radio>
                        </Radio.Group>
                    </Col>

                    {/* <Col sm={12}>
                        <Text> Status </Text><br />
                        <Select
                            style={{ width: "100%" }}
                            onSelect={(e) => thisHandleFilterChange("status", e)}
                            value={filters.status}
                            placeholder="Any"
                        >
                            <Option value="">Any</Option>
                            <Option value="In Progress">In Progress</Option>
                            <Option value="Done">Done</Option>
                        </Select>
                    </Col> */}


                    <Col span={12}>
                        <Text> Is read </Text><br />
                        <Radio.Group onChange={(e) => thisHandleFilterChange("is_read", e.target.value)} value={filters.is_read}>
                            <Radio style={radioStyle} value="">
                                <Text style={{ marginRight: "15px" }}>All</Text>
                            </Radio>
                            <Radio style={radioStyle} value={true}>
                                <Text style={{ marginRight: "15px" }}>Yes</Text>
                            </Radio>
                            <Radio style={radioStyle} value={false}>
                                <Text style={{ marginRight: "15px" }}>No</Text>
                            </Radio>
                        </Radio.Group>
                    </Col>


                    {/* <Col sm={12}>
                        <Text> Is read </Text><br />
                        <Select
                            style={{ width: "100%" }}
                            onSelect={(e) => thisHandleFilterChange("is_read", e)}
                            value={filters.is_read}
                            placeholder="All"
                        >
                            <Option value="">All</Option>
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                        </Select>
                    </Col> */}

                    {/* <Col sm={12}>
                        <Text> Start Date </Text><br />
                        <DatePicker
                            className="custom-datePicker-small"
                            size="small"
                            style={{ backgroundColor: "#36404A", width: "100%", height: "32px" }}
                            selected={filters.start_date}
                            onChange={(e) => thisHandleFilterChange("start_date", e)}
                        />
                    </Col>

                    <Col sm={12}>
                        <Text> End Date </Text><br />
                        <DatePicker
                            className="custom-datePicker-small"
                            size="small"
                            style={{ backgroundColor: "#36404A", width: "100%", height: "32px" }}
                            selected={filters.start_date}
                            onChange={(e) => thisHandleFilterChange("start_date", e)}
                        />
                    </Col> */}

                    <Col sm={24}>
                        <Text> Start & End Date </Text><br />
                        <RangePicker
                            format={dateFormat}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </Modal>
        </React.Fragment >

    )
}