import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Collapse,
    Typography,
    Radio,
    Input,
    Card,
    Icon,
    Spin,
    Tooltip,
    Tag,
    Modal,
    AutoComplete,
    Avatar,
    Upload,
    Switch,
    Empty,
    message
} from "antd";
import "./dealshub-category-slider.scss";
import { connect } from "react-redux";
import {
    fetchDealsHubCategoryData,
    handleUpdate,
    orderList
} from "../../actions/dealsHubAdmin";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import GenericBanner from "./generic-banner/GenericBanner";
import axios from "axios";
import isDev from "../../server";

const serverUrl = isDev();

const { Title } = Typography;
const { Panel } = Collapse;

const { Option } = AutoComplete;

class DealshubCategorySlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverUrl: serverUrl,
            spinnerLoading: false,
            superCategoriesList: [],
            bannerTypes: null,
            displayBannerModal: false,
            bannerRadioValue: null,
            ItemLength: 1,
            searchListing: [],
            virtualDeleteList: [],
            showAddCatModal: false
        };
    }

    componentDidMount = () => {
        this.setState({
            spinnerLoading: true
        }, () => {
            axios.post(`${serverUrl}/dealshub/fetch-super-categories/`, {
                websiteGroupName: localStorage.getItem("websiteGroupName")
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                this.setState({
                    superCategoriesList: [...[], ...response.data.superCategoryList],
                    spinnerLoading: false
                });
            });
        });
    };

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            modalFileUid: file.uid,
            modalFileHttpLink: file.httpLink
        });
    };

    handleChange = (fileList, categoryObj) => {
        if (
            fileList.length > 0 &&
            !this.state.uploadingInProgress
            // && !this.state.isDeleteOn
        ) {
            this.setState({ uploadingInProgress: true });

            let currentImage = fileList[fileList.length - 1];
            let formData = new FormData();
            formData.append("image", currentImage.originFileObj);
            formData.append("websiteGroupName", localStorage.getItem("websiteGroupName"));
            formData.append("uuid", categoryObj.uuid);

            if (currentImage.originFileObj !== undefined) {
                axios.post(`${this.state.serverUrl}/dealshub/update-super-category-image/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                }).then(response => {

                    if (response.data.status === 200) {
                        let temp = this.state.superCategoriesList;
                        let tempVirtualDeleteList = this.state.virtualDeleteList;

                        for (let index2 = 0; index2 < this.state.superCategoriesList.length; ++index2) {
                            if (temp[index2].uuid === categoryObj.uuid) {
                                temp[index2].imageUrl = response.data.imageUrl;
                                if (this.state.virtualDeleteList.indexOf(categoryObj.uuid) > -1) {
                                    tempVirtualDeleteList.splice(
                                        this.state.virtualDeleteList.indexOf(categoryObj.uuid)
                                    );
                                }
                                break;
                            }
                        }

                        this.setState({
                            superCategoriesList: temp,
                            virtualDeleteList: tempVirtualDeleteList,
                            uploadingInProgress: false
                        });
                        message.success("Category Image changed Successfully!");
                    } 
                    else if(response.data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                    else {
                        message.error("Something went wrong!");
                    }
                });
            }
        }
    };

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG/GIF file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    }

    removeCategoryImage = (file, index) => {
        this.setState({
            isDeleteOn: true
        }, () => {
            this.setState({
                virtualDeleteList: [
                    ...this.state.virtualDeleteList,
                    ...[this.state.superCategoriesList[index].uuid]
                ]
            }, () => {
                message.success('Category Image removed Successfully!');
                message.success('Category Image will be restored if not replaced correctly!');
            }, () => {
                this.setState({
                    isDeleteOn: false
                })
            });
        });
    };

    deleteCategory = (uuid, index) => {
        axios.post(`${this.state.serverUrl}/dealshub/remove-category-from-website-group/`, {
            categoryUuid: uuid,
            websiteGroupName: localStorage.getItem("websiteGroupName")
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            if (response.data.status === 200) {
                let temp = this.state.superCategoriesList;
                temp.splice(1, index);
                this.setState({
                    superCategoriesList: temp
                }, () => {
                    //.log(this.state.superCategoriesList);
                    //.log(this.state.virtualDeleteList);
                });

                message.success("Category removed Successfully!");
            } 
            else if(response.data.status === 403)
            {
              message.info("You are not authorised!")
            }
            else {
                message.error("Something went Wrong!");
            }
        });
    }

    render() {
        const uploadButton = (
            <div>
                <Icon type="camera" />
                <div className="ant-upload-text">
                    Upload Category Display Image
                </div>
            </div>
        );
        return (
            <Row className="dealshubadmincontainer">
                <Col span={24}>
                    <Row
                        className="buttons"
                        type="flex"
                        justify="space-between"
                        align="middle"
                    >
                        <Col>
                            <Title style={{ marginBottom: "0px", color: "white" }} level={4}>
                                Dealshub Category Slider
                        </Title>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    {!this.state.spinnerLoading ? (
                        <>
                            {this.state.superCategoriesList.length > 0 ? (
                                this.state.superCategoriesList.map((categoryObj, index) => (
                                    <Row className="categoriescontainer" key={index}>
                                        <Col sm={24}>
                                            <Collapse
                                                expandIconPosition="left"
                                            >
                                                <Panel
                                                    header={categoryObj.name}
                                                    key={categoryObj.uuid}
                                                >
                                                    <div className="category-slider-wrapper">
                                                        {/* <Button
                                                            style={{ margin: "20px 0px" }}
                                                            type="danger"
                                                            onClick={() => {
                                                                this.deleteCategory(categoryObj.uuid, index);
                                                            }}
                                                        >
                                                            Delete Category
                                                        </Button> */}
                                                        <Upload
                                                            listType="picture-card"
                                                            fileList={
                                                                this.state.virtualDeleteList.includes(categoryObj.uuid) ||
                                                                    categoryObj.imageUrl === ""
                                                                    ?
                                                                    [] :
                                                                    [{
                                                                        uid: categoryObj.uuid,
                                                                        url: categoryObj.imageUrl
                                                                    }]
                                                            }
                                                            // onPreview={this.handlePreview}
                                                            onChange={({ fileList }) => {
                                                                this.handleChange(fileList, categoryObj);
                                                            }}
                                                            onRemove={(file) => {
                                                                this.removeCategoryImage(file, index);
                                                            }}
                                                            customRequest={this.dummyRequest}
                                                            beforeUpload={this.beforeUpload}
                                                        >
                                                            {
                                                                this.state.virtualDeleteList.includes(categoryObj.uuid) ||
                                                                    categoryObj.imageUrl === ""
                                                                    ?
                                                                    uploadButton :
                                                                    null
                                                            }
                                                        </Upload>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                ))
                            ) : (
                                    <Empty
                                        className="no-orders-wrapper"
                                        description="No Super Categories found"
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                )}
                        </>
                    ) : (
                            <Spin></Spin>
                        )}
                </Col>
                <Col span={24}>
                    <Modal
                        visible={this.state.showAddCatModal}
                    >

                    </Modal>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ dealsHubAdmin }) => {
    return {
        dealsHubAdmin
    };
};

export default connect(mapStateToProps, {
    fetchDealsHubCategoryData,
    handleUpdate,
    orderList
})(DealshubCategorySlider);
