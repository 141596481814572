import React, { Component } from 'react';
import { Card, Divider, Row, Col, Button, Modal, Typography , Icon , Popconfirm , message } from "antd";
import "./cards.scss";


import isDev from "server";


const serverUrl = isDev();
const { Text , Paragraph } = Typography;


export default class ChannelCard extends Component {

    confirm = (e) => {
        e.stopPropagation();
        let data = {
            uuid : this.props.uuid
        };
        fetch(`${serverUrl}/delete-export-template/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    createTemplateModal : false,
                    createTemplateLoading : false,
                })            

                if (data.status === 200) {
                   message.success("Template is deleted!");
                   this.props.fetchTemplates();
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Internal Server Error!");
                }
            });
    }

    render() {
        return (
            <Card
                bordered={true}
                className={
                    this.props.activeIndex == this.props.index ?
                        "border-is-active channel-card" :
                        "channel-card"
                }
                style = {{
                    position : "relative",
                    cursor : "pointer"
                }}
            >
                <Paragraph ellipsis = {{rows : 1 , expandable : false}} style = {{marginBottom : "0px"}}>{this.props.name}</Paragraph>
                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" 
                      onConfirm={this.confirm}
                >
                    <Icon type = "close" style = {{
                        position : "absolute",
                        top : "3px",
                        right : "3px",
                        fontSize : "12px",
                        cursor : "pointer"
                    }} onClick = {(e) => {
                        e.stopPropagation();
                        console.log("sdsds");
                    }} />
                 </Popconfirm>
              
            </Card>
        )
    }
}
