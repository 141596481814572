import React from "react";
import { Row, Col, Typography } from "antd";

import "./waiting-screen.scss";

/**
 * @description This is a function which returns waiting screen
 * @returns three styled dots and a waiting text
 */
export default function WaitingScreen() {
  return (
    <Row
      style={{
        height: "100vh",
        weight: "100vw",
        backgroundColor: "#313841",
      }}
      type="flex"
      justify="center"
      align="middle"
    >
      <Col>
        <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
        <Typography.Text
          style={{
            fontSize: "20px",
          }}
        >
          {" "}
          Wait for a moment! collecting the data for your preferences.{" "}
        </Typography.Text>
      </Col>
    </Row>
  );
}
