import React, { Component } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import { Typography } from "antd";

import "./editor-box.scss";
import Paragraph from "antd/lib/skeleton/Paragraph";


class EditorBox extends Component {
  constructor(props) {
    super(props);
    this.state = { text: '' };
    this.modules = {
      toolbar: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        [{ 'color': ["#3DC9A9"] }, { 'background': ['#3DC9A9'] }],
        ['link']
      ]
    };

    if(props.h1h2)
    {
      this.modules.toolbar = [
        [{ 'font': [] }],
        [{'header' : []}],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        [{ 'color': ["#d42512" , "black"] }, { 'background': ['#d42512' , "white"] }],
        ['link']
      ]
    }

    this.formats = [
      'font',
      'size',
      'bold', 'italic', 'underline',
      'list', 'bullet',
      'align',
      'color', 'background',
      'link'
    ];

    if(props.h1h2)
      this.formats = [
        'font',
        'header',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'align',
        'color', 'background',
        'link'
      ]

  }

  handleChange = (value) => {
    //.log(value, "DELTA")
    this.setState({ text: value })
    this.props.getText(value);
  }

  componentDidMount = () => {
    if (this.props.value)
      this.setState({ text: this.props.value });
  }

  componentWillReceiveProps = (props) => {
    this.setState({ text: props.value });
  }

  render() {
    return (
      <div className="quill-container">
        <ReactQuill value={this.state.text}
          placeholder={this.props.placeholder}
          theme="snow"
          modules={this.modules}
          formats={this.formats}
          onChange={this.handleChange}
          className="react-quill-component"
          style={{ height: this.props.height ? this.props.height : '500px' }}
        />
      </div>
    );
  }
}

export default EditorBox;
