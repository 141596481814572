import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './order-requests.scss';
import isDev from 'server';
import {
    Col,
    Collapse,
    Empty,
    Icon,
    Input,
    Row,
    Select,
    Typography,
    message,
    Button,
    Checkbox,
    Tooltip,
    Modal,
} from "antd";
import styled from 'styled-components';
import OrderNotes from "../order-notes/orderNotes";
import { useSelector } from 'react-redux'

const serverOmnycommUrl = isDev();

const { Title, Text } = Typography
const { Panel } = Collapse
const { Option } = Select

const BaseRequestContainer = styled(Row)`
  //padding-bottom: 12px;
  width: 100%;
  background-color: #313841;
  margin-bottom: 12px;
`;

const RequestItem = ({ unitOrder, selectedItems, setSelectedItems, currency, disablecheckbox, id, orderUuid, updateOrder }) => {
    const [editPrice, setEditPrice] = useState(false)
    const [price, setPrice] = useState(unitOrder.finalPrice)
    const [editQty, setEditQty] = useState(false)
    const [qty, setQty] = useState(unitOrder.finalQuantity)
    const onChangePrice = () => {
        if (price < 0) {
            message.info("Price cannot be less than 0!")
        } else {
            setEditPrice(!editPrice)
            let tmp = selectedItems.map(item => {
                if (item.uuid === unitOrder.uuid) {
                    return {
                        ...item, price: price
                    }
                } else
                    return item

            })
            setSelectedItems(tmp)
            axios.post(`${serverOmnycommUrl}/dealshub/order/update-unit-order-request-admin/`, {
                OrderRequestUuid: orderUuid,
                UnitOrderRequestUuid: unitOrder.uuid,
                //finalQuantity: qty,
                finalPrice: price,
                // requestStatus: selectedItems && selectedItems[Number(id)]?.checked ? "Approved" : "Rejected",
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res.status === 200) {
                    updateOrder(orderUuid, { ...res.data });
                    message.success("Updated Successfully!");
                } else {
                    message.error("Something went wrong!");
                }
            }).catch(err => {
                console.error(err);
                message.error("Something went wrong!");
            })
        }
    }

    const onChangeQty = () => {
        if (qty < 0) {
            message.info("Quantity cannot be less than 0!")
        } else {
            setEditQty(!editQty)
            let tmp = selectedItems.map(item => {
                if (item.uuid === unitOrder.uuid) {
                    return {
                        ...item, quantity: qty
                    }
                } else
                    return item

            })
            setSelectedItems(tmp)
            axios.post(`${serverOmnycommUrl}/dealshub/order/update-unit-order-request-admin/`, {
                OrderRequestUuid: orderUuid,
                UnitOrderRequestUuid: unitOrder.uuid,
                finalQuantity: qty,
                // finalPrice: price,
                // requestStatus: selectedItems && selectedItems[Number(id)]?.checked ? "Approved" : "Rejected",
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                if (res.status === 200) {
                    updateOrder(orderUuid, { ...res.data });
                    message.success("Updated Successfully!");
                } else {
                    message.error("Something went wrong!");
                }
            }).catch(err => {
                console.error(err);
                message.error("Something went wrong!");
            })
        }
    }

    return (
        <Row className='unit-requests'>
            <Col span={12}>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '10px',
                        width: '100%',
                        marginLeft: '-18px'
                    }}
                >
                    <img
                        className='image-container'
                        src={unitOrder.productImageUrl}
                    //alt={`${item.productName}-${item.orderId}`}
                    />
                    <span>
                        <Row>
                            <Text className='text small-font text-ellipses'>{unitOrder.productName}</Text>
                        </Row>
                        <Row>
                            <Text>
                                Product Id:{' '}
                                <span style={{ color: 'white' }}>{unitOrder.orderRequestId}</span>
                            </Text>
                        </Row>
                    </span>
                </Row>
            </Col>
            <Col offset={2} span={3} style={{ textAlign: 'center' }}>
                {editQty ?
                    <>
                        <Input value={qty}
                            onPressEnter={() => onChangeQty()}
                            onChange={(e) => setQty(e.target.value)}
                            style={{ width: "50%", marginRight: "5px" }} />
                        <Icon type="check-circle" style={{ cursor: "pointer", color: "#1abc9c" }}
                            onClick={() => onChangeQty()}
                        />
                    </> :
                    <>
                        <Text style={{
                            backgroundColor: '#495561',
                            borderRadius: '2px',
                            textAlign: 'center',
                            padding: '6px',
                            color: '#F1F1F1'
                        }}>{qty}</Text>
                        <Icon type="form" style={{
                            color: unitOrder.requestStatus === "Pending" ? "#1abc9c" : "grey",
                            marginLeft: '5px'
                        }}
                            onClick={() => unitOrder.requestStatus === "Pending" && setEditQty(!editQty)} />
                    </>
                }
            </Col>
            <Col span={4} style={{ textAlign: 'center' }}>
                <Row>
                    <Text style={{ marginRight: '12px' }}>Unit Price</Text>
                    {editPrice ?
                        <>
                            <Input value={price}
                                onPressEnter={() => onChangePrice()}
                                onChange={(e) => setPrice(e.target.value)}
                                style={{ width: "40%", marginRight: "5px" }} />
                            <Icon type="check-circle" style={{ cursor: "pointer", color: "#1abc9c" }}
                                onClick={() => onChangePrice()}
                            //onClick={() => setPrice(price)}
                            />
                        </> :
                        <>
                            <Text style={{
                                backgroundColor: '#495561',
                                borderRadius: '2px',
                                textAlign: 'center',
                                padding: '6px',
                                color: '#F1F1F1'
                            }}>{price}</Text>
                            <Icon type="form" style={{
                                color: unitOrder.requestStatus === "Pending" ? "#1abc9c" : "grey",
                                marginLeft: '12px'
                            }}
                                onClick={() => unitOrder.requestStatus === "Pending" && setEditPrice(!editPrice)}
                            />
                        </>
                    }
                </Row>
                <Row style={{ marginTop: '18px' }}>
                    <Text style={{
                        color: '#F1F1F1',
                        fontWeight: '300',
                        fontSize: '16px'
                    }}>{currency}{" "}{parseFloat(qty * price).toFixed(2)}</Text>
                </Row>
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>

                <Checkbox
                    disabled={disablecheckbox}
                    checked={selectedItems && selectedItems[Number(id)]?.checked}
                    onClick={(e) => e.stopPropagation()}
                    value={unitOrder.uuid}
                    onChange={() => {
                        console.clear()
                        console.log(selectedItems, "abbbb")
                        let tmp = selectedItems.map(item => {
                            if (item.uuid === unitOrder.uuid) {
                                return {
                                    ...item, checked: !item.checked
                                }
                            } else
                                return item

                        })

                        setSelectedItems(tmp)
                    }}
                />

                {/*<Icon type="close-circle" onClick={() => removeRequestItem()}*/}
                {/*      style={{color: '#F5222D', fontSize: '24px', marginLeft: '8px'}}/>*/}
            </Col>
        </Row>
    )
}

const OrderRequests = (props) => {

    const [activeIndex, setActiveIndex] = useState(null)
    const [orderRequestList, setOrderRequestList] = useState([])
    const [isAvailable, setIsAvailable] = useState(false);
    const [currency, setCurrency] = useState("AED");
    const [reqListIndex, setReqListIndex] = useState(1);
    const [reqType, setReqType] = useState("");
    // const [rejectedItemIds, setRejectedItemIds] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [orderNoteModal, setOrderNoteModal] = useState(false)
    const [additionalNote, setAdditionalNote] = useState('No customer note')
    const [adminNote, setAdminNote] = useState("")
    const [orderAction, setOrderAction] = useState("")
    const [orderUuid, setOrderUuid] = useState("")
    const misc = useSelector((state) => state.miscPermission)

    const fetchOrderRequests = () => {
        axios
            .post(
                `${serverOmnycommUrl}/dealshub/fetch-order-requests-for-warehouse-manager/`,
                {
                    locationGroupUuid: props.locationUuid,
                    page: 1
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status == 200) {
                    console.log(response)
                    setOrderRequestList(response.data.orderRequestList)
                    setIsAvailable(response.data.isAvailable)
                    setCurrency(response.data.currency)
                    if (response.data.isAvailable === true) {
                        console.log(reqListIndex, 'my page')
                        setReqListIndex(2)
                    }

                }
            });
    }

    const acceptOrderRequest = (uuid) => {
        //const tmp = selectedItems.filter((item)=>item.checked)
        let count = 0;
        const temp = selectedItems?.map((item) => {
            if (item.checked) count++
            return {
                uuid: item.uuid,
                quantity: item.quantity,
                price: item.price,
                status: item.checked ? "Approved" : "Rejected"
            }

        })


        if (count === 0) {
            message.info("Cannot approve empty order")
            return
        }
        axios
            .post(
                `${serverOmnycommUrl}/dealshub/process-order-request/`,
                {
                    locationGroupUuid: props.locationUuid,
                    requestStatus: "Approved",
                    UnitOrderRequests: temp,
                    OrderRequestUuid: uuid,
                    adminNote: adminNote
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                console.log(response.data)
                if (response.data.status === 200) {
                    message.success("Order Status Updated!")
                    fetchOrderRequests()
                } else if (response.data.status === 403) {
                    message.info("You are not authorized!")
                } else (
                    message.error("Something went wrong")
                )
            });
    }

    const rejectOrderRequest = (uuid) => {
        axios
            .post(
                `${serverOmnycommUrl}/dealshub/process-order-request/`,
                {
                    locationGroupUuid: props.locationUuid,
                    requestStatus: "Rejected",
                    UnitOrderRequests: [],
                    OrderRequestUuid: uuid,
                    adminNote: adminNote
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                console.log(response.data)
                if (response.data.status === 200) {
                    message.success("Order Status Updated!")
                    fetchOrderRequests()
                } else if (response.data.status === 403) {
                    message.info("You are not authorized!")
                } else (
                    message.error("Something went wrong")
                )
            });
    }

    const handleRequestType = (key) => {
        setReqType(key)
        console.log(key)
        if (key === "1") {
            fetchOrderRequests()
        } else {
            axios
                .post(
                    `${serverOmnycommUrl}/dealshub/fetch-order-requests-for-warehouse-manager/`,
                    {
                        locationGroupUuid: props.locationUuid,
                        page: 1,
                        requestStatus: key
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.status == 200) {
                        console.log(response)
                        setOrderRequestList(response.data.orderRequestList)
                        setIsAvailable(response.data.isAvailable)
                    }
                });
        }

    }

    const handleLoadMore = () => {

        axios
            .post(
                `${serverOmnycommUrl}/dealshub/fetch-order-requests-for-warehouse-manager/`,
                {
                    locationGroupUuid: props.locationUuid,
                    page: reqListIndex,
                    requestStatus: reqType === "1" ? "" : reqType
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status == 200) {
                    console.log(response)
                    setOrderRequestList([...orderRequestList, ...response.data.orderRequestList])
                    setIsAvailable(response.data.isAvailable)
                    if (response.data.isAvailable) {
                        setReqListIndex(reqListIndex + 1)
                    }
                }
            });
    }

    useEffect(() => {
        fetchOrderRequests()
    }, []);

    useEffect(() => {
        const tmp = orderRequestList[activeIndex]?.unitOrderRequestList.map((item) => {
            return { uuid: item.uuid, checked: true, quantity: item.initialQuantity, price: item.initialPrice }
        })
        setSelectedItems(tmp)
    }, [activeIndex])

    const updateOrderList = (uuid, val) => {
        let length = orderRequestList.length, temp, orderRequestListTemp = [...orderRequestList];

        for (let i = 0; i < length; i++) {
            if (orderRequestListTemp[i].uuid === uuid) {
                temp = {
                    ...orderRequestList[i],
                    totalQuantity: val.totalQuantity,
                    subtotal: val.subtotal,
                    toPay: val.toPay,
                    vat: val.vat,
                    deliveryFee: val.deliveryFee,
                    codFee: val.codFee,
                }
                orderRequestListTemp[i] = temp;
                break;
            }
        }
        console.clear();
        console.log(orderRequestListTemp, orderRequestList, "TEST")
        setOrderRequestList([...orderRequestListTemp])
    }

    return (
        <Row className='order-requests' gutter={[16, 16]}>
            <Col span={19} className='order-request-title'>
                <Title level={4} className='header-title'>
                    Manage Order Requests
                </Title>
            </Col>
            <Col span={5}>
                <Select
                    style={{ width: "100%" }}
                    defaultValue='Display All Requests'
                    onSelect={handleRequestType}
                >
                    <Option key={1}>Display All Requests</Option>
                    <Option key={"Pending"}>Display Pending Requests</Option>
                    <Option key={"Approved"}>Display Approved Requests</Option>
                    <Option key={"Rejected"}>Display Rejected Requests</Option>
                </Select>
            </Col>
            <React.Fragment>
                <Col span={24}>
                    <Row
                        gutter={[12, 0]}
                        style={{
                            padding: '0px 20px',
                        }}
                    >
                        <Col span={(misc.includes("approving-orders")) ? 6 : 7} style={(misc.includes("approving-orders")) ? { textAlign: 'left' } : { textAlign: 'center' }}><Text className='row-header'>ORDER REQUESTED BY</Text></Col>
                        <Col span={(misc.includes("approving-orders")) ? 4 : 4} style={(misc.includes("approving-orders")) ? { textAlign: 'left' } : { textAlign: 'center' }}><Text className='row-header'>ORDER PLACED ON</Text></Col>
                        <Col span={(misc.includes("approving-orders")) ? 2 : 3} style={{ textAlign: 'center' }}><Text className='row-header'>ITEMS</Text></Col>
                        <Col span={(misc.includes("approving-orders")) ? 3 : 2} style={(misc.includes("approving-orders")) ? { textAlign: 'center' } : { textAlign: 'left' }} ><Text className='row-header'>PAYMENT</Text></Col>
                        <Col span={(misc.includes("approving-orders")) ? 2 : 3} style={{ textAlign: 'center' }}><Text className='row-header'>QUANTITY</Text></Col>
                        <Col span={(misc.includes("approving-orders")) ? 4 : 3} style={{ textAlign: 'center' }}><Text className='row-header'>PRICES</Text></Col>
                        {misc.includes("approving-orders") && <Col span={3} style={{ textAlign: 'center' }}><Text className='row-header'>ACTION</Text></Col>}

                    </Row>
                </Col>
                {orderRequestList.length > 0 ? orderRequestList.map((item, index) => {
                    return (
                        <>
                            <BaseRequestContainer
                                type='flex'
                                align='middle'
                                key={index}
                                onClick={() => setActiveIndex(index)}
                                className={
                                    activeIndex === index && 'border-is-active'
                                }
                            >
                                {/*<Col span={24} className='cancel-requests'>*/}
                                <Collapse className='request-collapse' style={{ width: '100%' }} expandIcon={null}>
                                    <Panel

                                        showArrow={false}
                                        extra={
                                            <Row className='panel-header'>
                                                <Col span={6}>
                                                    <Col span={24}><Text
                                                        style={{
                                                            color: '#3CC9A9',
                                                            fontSize: '16px',
                                                            fontWeight: '500'
                                                        }}>{item.companyName}</Text></Col>
                                                    <Col span={24}>
                                                        <Text
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: '400',
                                                                color: '#CCCCCC'
                                                            }}>Bundle
                                                            Id:</Text>
                                                        <Text style={{
                                                            fontSize: '14px',
                                                            fontWeight: '700',
                                                            color: '#CCCCCC'
                                                        }}>{' '}{item.bundleId}</Text>
                                                    </Col>
                                                    {/*<Col span={24}>*/}
                                                    {/*    <Text*/}
                                                    {/*        style={{color: '#3CC9A9'}}*/}
                                                    {/*        onClick={(e) => {*/}
                                                    {/*            setOrderNoteModal(true)*/}
                                                    {/*            e.stopPropagation()*/}
                                                    {/*            // setCustomerNote(itemNote)*/}
                                                    {/*        }}*/}
                                                    {/*    >Order Notes {" "}</Text>*/}
                                                    {/*    <Icon style={{color: '#3CC9A9'}} type="file"/>*/}
                                                    {/*</Col>*/}
                                                </Col>

                                                <Col span={4}>
                                                    <Text className='text'>{item.dateCreated}</Text>
                                                    <br />
                                                    <Text className='text'>{item.customerName}</Text>
                                                    <Tooltip
                                                        placement='bottom'
                                                        title={
                                                            <React.Fragment>
                                                                <Text style={{ color: 'white' }}>
                                                                    {item.customerName}
                                                                </Text>
                                                                <br />
                                                                <Text style={{ color: 'white', fontSize: '12px' }}>
                                                                    +{item.contactNumber}, {item.emailId}
                                                                </Text>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Icon type="info-circle" style={{
                                                            color: '#F1F1F1',
                                                            marginLeft: '8px',
                                                            marginRight: '5px',
                                                            cursor: "pointer"
                                                        }} /></Tooltip>
                                                    <OrderNotes
                                                        color='white'
                                                        itemNote={item.additionalNote ? item.additionalNote : "No note available"}
                                                        adminNotes={item.adminNote}
                                                    />
                                                </Col>
                                                <Col span={2} style={{ textAlign: 'center' }}>
                                                    <Text className='text'>{item.itemsCount}</Text>

                                                </Col>
                                                <Col span={3} style={{ textAlign: 'center' }}>
                                                    {item.paymentMode}
                                                    {/*<Icon type="credit-card"*/}
                                                    {/*      style={{color: '#1ABC9C', fontSize: '16px', marginLeft: '4px'}}/>*/}

                                                </Col>
                                                <Col span={2} style={{ textAlign: 'center' }}>

                                                    <Text className='text'>{item.totalQuantity}</Text>

                                                </Col>
                                                <Col span={4} style={{ textAlign: 'center' }}>
                                                    <Icon type="wallet"
                                                        style={{
                                                            color: '#1ABC9C',
                                                            fontSize: '16px',
                                                            marginRight: '8px',
                                                            marginLeft: '4px'
                                                        }} />
                                                    <Text className='text'>{currency}{" "}{item.toPay}</Text>
                                                    <Tooltip
                                                        placement='bottom'
                                                        title={
                                                            <React.Fragment>
                                                                <Text style={{ color: 'white' }}>
                                                                    COD Fee: {item.codFee}
                                                                </Text>
                                                                <br />
                                                                <Text style={{ color: 'white' }}>
                                                                    Delivery Fee: {item.deliveryFee}
                                                                </Text>
                                                                <br />
                                                                <Text style={{ color: 'white' }}>
                                                                    VAT: {item.vat}
                                                                </Text>
                                                                <br />
                                                                <Text style={{ color: 'white' }}>
                                                                    Sub Total: {item.subtotal}
                                                                </Text>
                                                                <br />
                                                                <Text style={{ color: 'white' }}>
                                                                    Total: {item.toPay}
                                                                </Text>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Icon type="info-circle" style={{
                                                            color: '#F1F1F1',
                                                            marginLeft: '8px',
                                                            marginRight: '5px',
                                                            cursor: "pointer"
                                                        }} /></Tooltip>
                                                </Col>
                                                {misc.includes("approving-orders") && <Col span={3} style={{ textAlign: 'center' }}>
                                                    {item.orderRequestStatus === "Pending" ? <>
                                                        <Icon type="check-circle"
                                                            onClick={() => {
                                                                setOrderNoteModal(true)
                                                                setAdditionalNote(item.additionalNote)
                                                                setOrderUuid(item.uuid)
                                                                setOrderAction("Accept")
                                                            }}
                                                            style={{
                                                                color: '#36B661',
                                                                fontSize: '24px',
                                                                marginRight: '8px'
                                                            }} />
                                                        <Icon type="close-circle"
                                                            onClick={() => {
                                                                setOrderNoteModal(true)
                                                                setAdditionalNote(item.additionalNote)
                                                                setOrderUuid(item.uuid)
                                                                setOrderAction("Reject")
                                                            }}
                                                            style={{
                                                                color: '#F5222D',
                                                                fontSize: '24px',
                                                                marginLeft: '8px'
                                                            }} />


                                                    </> : <Col
                                                        style={{ backgroundColor: item.orderRequestStatus === "Approved" ? "#1AC454" : "#F5222D" }}>
                                                        <Text style={{ color: "white" }}>{item.orderRequestStatus}</Text>
                                                    </Col>
                                                    }
                                                </Col>}
                                            </Row>

                                        }
                                    >
                                        {item.unitOrderRequestList?.map((unitOrder, id) => {
                                            return (
                                                <Col span={24} key={id}>
                                                    <RequestItem
                                                        id={id}
                                                        orderUuid={item.uuid}
                                                        disablecheckbox={item.orderRequestStatus !== "Pending"}
                                                        currency={currency}
                                                        unitOrder={unitOrder}
                                                        selectedItems={selectedItems}
                                                        updateOrder={(uuid, val) => updateOrderList(uuid, val)}
                                                        setSelectedItems={setSelectedItems} />
                                                </Col>

                                            )
                                        })}
                                    </Panel>
                                </Collapse>
                                {/*</Col>*/}

                            </BaseRequestContainer>
                        </>
                    )
                }) : <Empty
                    className='no-orders-wrapper'
                    description='No Requests found'
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />}
                {isAvailable ? (
                    <div
                        className='center-child'
                        style={{
                            width: '100%',
                            marginTop: '10px',
                            marginBottom: '50px',
                        }}
                    >
                        <Button
                            type='primary'
                            //loading={this.props.fetchingOrdersList}
                            onClick={() => {
                                handleLoadMore();
                            }}
                        >
                            Load More...
                        </Button>
                    </div>
                ) : (
                    <React.Fragment></React.Fragment>
                )}

            </React.Fragment>

            <Modal
                width="40%"
                style={{ display: "flex", justifyContent: "center" }}
                visible={orderNoteModal}
                title="Order Notes"
                onCancel={() => { setAdminNote(""); setOrderNoteModal(false); }}
                footer={orderAction === "Accept" ? (
                    <>
                        <Col span={20} style={{ textAlign: 'left' }}><Text style={{ textAlign: 'left' }}>Are you sure you want
                            to {selectedItems?.filter((item) => item.checked)?.length === orderRequestList[activeIndex]?.unitOrderRequestList.length ? "" : "partially"} approve the order?</Text></Col>

                        <Button style={{ backgroundColor: '#36B661', color: 'white', border: 'none' }}
                            onClick={() => {
                                setOrderNoteModal(false)
                                acceptOrderRequest(orderUuid)
                            }}><Icon type="check" />Approve</Button></>
                )
                    : (
                        <>
                            <Col span={20} style={{ textAlign: 'left' }}><Text style={{ textAlign: 'left' }}>Are Sure you
                                want
                                to reject the order?</Text></Col>
                            <Button style={{ backgroundColor: '#DE3841', color: 'white', border: 'none' }}
                                onClick={() => {
                                    setOrderNoteModal(false)
                                    rejectOrderRequest(orderUuid)
                                }}><Icon type="close" />Reject</Button>
                        </>
                    )
                }
            >

                <Text style={{
                    fontWeight: "bold",
                    fontSize: '16px',
                    color: '#FFFFFF'
                }}>Customer Notes</Text>
                <Input.TextArea
                    defaultValue={additionalNote === '' ? "No customer note" : additionalNote}
                    disabled
                    onClick={(e) => e.stopPropagation()}
                    rows={3}
                    style={{ marginTop: 10, marginBottom: 20 }}
                />
                <Text style={{
                    fontWeight: "bold",
                    fontSize: '16px',
                    color: '#FFFFFF'
                }}>Admin Notes</Text>
                <Input.TextArea
                    placeholder="Add Notes..."
                    //defaultValue={adminNotes}
                    value={adminNote}
                    rows={3}
                    onChange={(e) => setAdminNote(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </Row>

    )
}

export default OrderRequests