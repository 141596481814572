import React, { Component } from 'react';
import { Row, Col, Typography, Button, Checkbox, Avatar, Icon, Tooltip, Tag, InputNumber, Select } from 'antd';
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import UserAvatar from "../../media/male_avatar.png"
import "./factory-products.scss"
const { Title, Text } = Typography;

export default class FactoryPageWithProducts extends Component {

    state = {
        total_products: 1030,
        selected: 0,
        is_select_all_db: false,
        is_select_all_op: false,
        selected_pk: {},
        products_pk: [1, 2]
    }

    handleCheck = (pk) => {
        let map_pk = this.state.selected_pk[pk];

        if (map_pk == undefined || map_pk == 0) {
            this.setState({ selected_pk: { ...this.state.selected_pk, [pk]: 1 }, selected: this.state.selected + 1 });
        } else {
            this.setState({ selected_pk: { ...this.state.selected_pk, [pk]: this.state.selected_pk[pk] - 1 }, selected: this.state.selected - 1 });
        }
    }

    handleChangeNumber = (pk, e) => {
        let val = e;

        this.setState((prevstate) => ({ selected_pk: { ...prevstate.selected_pk, [pk]: val } }));
    }

    handle_select_all_op = () => {
        if (this.state.is_select_all_op) {
            this.state.products_pk.map(e => {
                this.setState((prevstate) => ({ selected_pk: { ...prevstate.selected_pk, [e]: 0 } }))
            })
            this.setState({ selected: 0, is_select_all_op: false });
        }
        else {
            this.state.products_pk.map(e => {
                this.setState((prevstate) => ({ selected_pk: { ...prevstate.selected_pk, [e]: prevstate.selected_pk[e] || 1 } }))
            })
            this.setState({ selected: this.state.products_pk.length, is_select_all_op: true });
        }
    }

    // handle_select_all_db = () => {
    //     if(this.state.is_select_all_db)
    //     {
    //         this.state.
    //     }
    //     else
    //     {

    //     }
    // }

    render() {



        return (
            <Row className="factory-products-page-container">
                <TitleWithBreadCrumb imageTitle={true} additionalButtons={true} title="Factory products" breadcrumb={["OmnyComm", "Factories", "Factory products"]} />
                <Col span={24} className="factory-products-table-container" >
                    <Row className="top-nav">

                        <Col span={24}>
                            <Row gutter={[12, 12]} type="flex" align="middle">
                                <Col lg={3} sm={8}>
                                    {this.state.total_products !== undefined ? (
                                        <Button style={{ width: "100%" }} type="primary" ghost>
                                            Results:{" "}
                                            {this.state.total_products}
                                        </Button>
                                    ) : (
                                            <Button type="primary" loading ghost></Button>
                                        )}
                                </Col>
                                <Col lg={2} sm={8}>
                                    {/* <ChannelWiseProductListFilterModal
                                        channelname={this.props.channelname}
                                    /> */}
                                </Col>
                                <Col lg={15} sm={24}>
                                    <Select
                                        dropdownClassName="search-chips"
                                        dropdownMatchSelectWidth="false"
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder={[<Icon type="search" />, " Search here..."]}
                                    // onChange={this.handleChangeSearchTags}
                                    // value={this.props.channelwiseState.tags}
                                    ></Select>
                                </Col>
                                <Col lg={4} sm={8}>
                                    <Button
                                        type="danger"
                                        style={{ width: "100%" }}
                                        icon="vertical-align-top"
                                    // onClick={this.showModal}
                                    >
                                        Download PI
                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="factory-products-table">

                        <Col span={24}>
                            <Row className="table-head-row" >
                                {this.state.selected > 0 && (
                                    <Col span={24} style={{ marginBottom: "12px" }}>
                                        <Text style={{ color: "white" }}>
                                            Selected{" "}
                                            {this.state.selected}
                                        </Text>
                                        {/* {(this.state.is_select_all_op || this.state.is_select_all_db) && (
                                    <Button
                                        style={{ marginLeft: "12px" }}
                                        size="small"
                                        type={this.state.is_select_all_db ? "primary" : ""}
                                        onClick={this.handle_select_all_db}
                                    >
                                        {" "}
                                        Select All Products{" "}
                                    </Button>
                                )} */}
                                    </Col>
                                )}
                                <Col span={1}>
                                    <Checkbox onChange={this.handle_select_all_op} checked={this.state.is_select_all_op} />
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Images
                                    </Text>
                                </Col>
                                <Col span={5}>
                                    <Text className="table-head-text">
                                        Product Id
                                    </Text>
                                </Col>
                                <Col span={3}>
                                    <Text className="table-head-text">
                                        Brand
                                    </Text>
                                </Col>
                                <Col span={3}>
                                    <Text className="table-head-text">
                                        Domain
                                    </Text>
                                </Col>
                                <Col span={3}>
                                    <Text className="table-head-text">
                                        MOQ
                                     </Text>
                                </Col>
                                <Col span={3}>
                                    <Text className="table-head-text">
                                        Delivery
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Price
                                     </Text>
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Qty
                                    </Text>
                                </Col>
                            </Row>
                            <Row className="factory-products-row" type="flex" align="middle">
                                <Col span={1}>
                                    <Checkbox checked={this.state.selected_pk[1]} onChange={() => this.handleCheck(1)} />
                                </Col>
                                <Col span={2}>
                                    <Row gutter={[8, 8]} style={{ width: "80%" }}>
                                        <Col span={12}>
                                            <Avatar shape="square" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/cooker1_3fCsFwx.jpg" />
                                        </Col>
                                        <Col span={12}>
                                            <Avatar shape="square" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/1569678416GAF2706_piBfgmZ.jpg" />
                                        </Col>
                                        <Col span={12}>
                                            <Avatar shape="square" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/GWH590562028129_MTI3u2P.jpgdl1" />
                                        </Col>
                                        <Col span={12}>
                                            <Avatar shape="square">
                                                <Text>
                                                    +2
                                        </Text>
                                            </Avatar>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={5}>
                                    <Row>
                                        <Col span={24}>
                                            <Text className="text-color3 text-ellipsis">
                                                Geepas 30M 1/2" Automatic
                                            </Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text className="">
                                                SKU8999123
                                            </Text>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={3}>
                                    <Text className="">
                                        Geepas
                                    </Text>
                                </Col>
                                <Col span={3}>
                                    <Row>
                                        <Col span={24}>
                                            <Text className="">
                                                Home App
                                            </Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text className="">
                                                sub cat.
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={3}>
                                    <Text className="">
                                        223
                                     </Text>
                                </Col>
                                <Col span={3}>
                                    <Text className="">
                                        0
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Text className="">
                                        123 AED
                                     </Text>
                                </Col>
                                <Col span={2}>
                                    <InputNumber size="small" min={0} max={100} value={this.state.selected_pk[1]} onChange={(e) => this.handleChangeNumber(1, e)} />
                                </Col>
                            </Row>


                            <Row className="factory-products-row" type="flex" align="middle">
                                <Col span={1}>
                                    <Checkbox checked={this.state.selected_pk[2]} onChange={() => this.handleCheck(2)} />
                                </Col>
                                <Col span={2}>
                                    <Row gutter={[8, 8]} style={{ width: "80%" }}>
                                        <Col span={12}>
                                            <Avatar shape="square" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/cooker1_3fCsFwx.jpg" />
                                        </Col>
                                        <Col span={12}>
                                            <Avatar shape="square" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/1569678416GAF2706_piBfgmZ.jpg" />
                                        </Col>
                                        <Col span={12}>
                                            <Avatar shape="square" src="https://wig-wams-s3-bucket.s3.amazonaws.com/thumbnails/GWH590562028129_MTI3u2P.jpgdl1" />
                                        </Col>
                                        <Col span={12}>
                                            <Avatar shape="square">
                                                <Text>
                                                    +2
                                        </Text>
                                            </Avatar>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={5}>
                                    <Row>
                                        <Col span={24}>
                                            <Text className="text-color3 text-ellipsis">
                                                Geepas 30M 1/2" Automatic
                                            </Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text className="">
                                                SKU8999123
                                            </Text>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={3}>
                                    <Text className="">
                                        Geepas
                                    </Text>
                                </Col>
                                <Col span={3}>
                                    <Row>
                                        <Col span={24}>
                                            <Text className="">
                                                Home App
                                            </Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text className="">
                                                sub cat.
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={3}>
                                    <Text className="">
                                        223
                                     </Text>
                                </Col>
                                <Col span={3}>
                                    <Text className="">
                                        0
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Text className="">
                                        123 AED
                                     </Text>
                                </Col>
                                <Col span={2}>
                                    <InputNumber size="small" min={0} max={100} value={this.state.selected_pk[2]} onChange={(e) => this.handleChangeNumber(2, e)} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
