import React, { Component } from "react";
import { Row, Col, Select, Typography } from "antd";
import InputField from "../components/datainputs/InputField";
// import UploadImage from "../components/datainputs/upload-image/UploadImage";
import FormHeader from "../components/datainputs/FormHeader";
import InputNumberDimensions3 from "../components/datainputs/InputNumberDimesions3";
import "./edit-base-product-page.scss";
// import VarientsInfoDimensions from "./vitali-info/VarientsInfoDimensions";
// import EditorBox from '../components/EditorBox';
import { handelEditProductDetails } from "../../actions/index";

import "./edit-base-product-page.scss";
import SapPullProducts from "./SapPullProduct";
import { connect } from "react-redux";
// const {  PlusOutlined  } = icons;



const { Option } = Select;
const { Text } = Typography;

class BaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      super_category: null,
      category: null,
      subCategory: null,
      brandList:null,
      OtherBrandOn : false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  dimlist = ["FT", "IN", "CM", "MM", "M"];

  componentDidUpdate(prevProps){
    if(this.props.enableEdit){
      if(this.props !== prevProps && !this.state.super_category){
        if(this.props.initiSuperCategory){
          for(let i = 0;i<this.props.super_categories.length;i++){
            if(this.props.super_categories[i].super_category_uuid === this.props.initiSuperCategory.super_category_uuid){
              this.onChangeSuperCategory(i);
              break;
            }
          }
        }
      }
      if(!this.state.category){
        if(this.props.initiCategory && this.state.super_category){
          for(let i = 0;i<this.state.super_category.category_list.length;i++){
            if(this.state.super_category.category_list[i].category_uuid === this.props.initiCategory.category_uuid){
              this.onChangeCategory(i);
              break;
            }
          }
        }
      }
      if(!this.state.subCategory){
        if(this.props.initiSubCategory && this.state.category){
          for(let i=0;i<this.state.category.sub_category_list.length;i++){
            if(this.state.category.sub_category_list[i].sub_category_uuid === this.props.initiSubCategory.sub_category_uuid){
              this.onChangeSubCategory(i);
              break;
            }
          }
        }
      }
    }
  }
  handelChange = e => {
    if(this.props.getInfo)
    {
      this.props.getInfo({
        ...this.props.productDetailsFromParent,
        [e.target.name]: e.target.value
      });
    }else
    {
      this.props.handelEditProductDetails({
        ...this.props.productDetails,
        [e.target.name]: e.target.value
      });
    }
    this.props.validator.showMessages();
  };

  handleChangeMD = (e, name) => {
    if(this.props.getInfo)
    {
      this.props.getInfo({
        ...this.props.productDetailsFromParent,
        dimensions: {
          ...this.props.productDetailsFromParent.dimensions,
          [name]: e
        }
      });
    }else{
      this.props.handelEditProductDetails({
        ...this.props.productDetails,
        base_dimensions: {
          ...this.props.productDetails.base_dimensions,
          [name]: e
        }
      });
    }

    this.props.validator.showMessages();
  };

  handleChangeD = e => {
    if(this.props.getInfo)
    {
      this.props.getInfo({
        ...this.props.productDetailsFromParent,
        dimensions: {
          ...this.props.productDetailsFromParent.dimensions,
          [e.target.name]: e.target.value
        }
      });
    }
    else {this.props.handelEditProductDetails({
      ...this.props.productDetails,
      base_dimensions: {
        ...this.props.productDetails.base_dimensions,
        [e.target.name]: e.target.value
      }
    });
  }
  this.props.validator.showMessages();
};

  onChange = e => {

    if (e === "Other")
    {
      this.setState({
        OtherBrandOn : true
      })
    }else
    {
      this.setState({
        OtherBrandOn : false
      })
    }

    if(this.props.getInfo)
    {
      this.props.getInfo({
        ...this.props.productDetailsFromParent,
        "brand_name": e
      });
    }
    else{
      this.props.handelEditProductDetails({
      ...this.props.productDetails,
        "brand_name": e
    });
  }
    this.props.validator.showMessages();
  };

  onChangeOtherBrand = e => {
    if(this.props.getInfo)
    {
      this.props.getInfo({
        ...this.props.productDetailsFromParent,
        "brand_name": e.target.value
      });
    }
    else{
      this.props.handelEditProductDetails({
      ...this.props.productDetails,
        "brand_name": e.target.value
    });
  }}

  onChangeSuperCategory = (e) => {
    this.setState({
      super_category : this.props.super_categories[e],
      category : null,
    })

    this.props.getSuperCategory(e);
    this.props.getCategory(null);
    this.props.getSubCategory(null);    
  }

  onChangeCategory = (e) => {
    this.setState({
      category: this.state.super_category.category_list[e],
      subCategory: null,
    })
    this.props.getCategory(e);
    this.props.getSubCategory(null);
  }

  onChangeSubCategory = (e) => {
    this.setState({
      subCategory: this.state.category.sub_category_list[e]
    })
    this.props.getSubCategory(e);
  }
  // for brandName 
  onInputChange(e) {
    this.setState({ brandList: e.target.value });
  }

  handleSubmit() {
    this.props.getBrandName(this.state.brandList);
  }


  onBlur = () => {

  };

  onFocus = () => {

  };

  onSearch = val => {

  };

  _otherInput;

  render() {
    console.log(this.props , "PROPSS")

    const {
      base_product_name,
      seller_sku,
      manufacturer_part_number,
      brand_name,
      manufacturer,
    } = this.props.productDetailsFromParent || this.props.productDetails;

    const {
      export_carton_quantity_l,
      export_carton_quantity_b,
      export_carton_quantity_h,
      export_carton_crm_l,
      export_carton_crm_b,
      export_carton_crm_h,
      product_dimension_l,
      product_dimension_b,
      product_dimension_h,
      giftbox_l,
      giftbox_b,
      giftbox_h,
      export_carton_quantity_l_metric,
      export_carton_quantity_b_metric,
      export_carton_quantity_h_metric,
      export_carton_crm_l_metric,
      export_carton_crm_b_metric,
      export_carton_crm_h_metric,
      product_dimension_l_metric,
      product_dimension_b_metric,
      product_dimension_h_metric,
      giftbox_l_metric,
      giftbox_b_metric,
      giftbox_h_metric
    } = (this.props.productDetailsFromParent && this.props.productDetailsFromParent.dimensions) || this.props.productDetails.base_dimensions;


    console.log(this.props)


    return (
      <React.Fragment>
        <Row gutter={[40, 32]}>
            <Col className="gutter-row" span={24}>
            {/* <div className="gutter-box">col-6</div> */}

            <Row gutter={[24, 24]} className="gutter-box">
              <Col style={{ paddingBottom: "0px" }} sm={24}>
                <FormHeader header="General : Base" />
              </Col>

              <Col sm={24}>
                <InputField
                  errormsg={
                    this.props.validator &&
                    this.props.validator.message(
                      "base_product_name",
                      base_product_name,
                      "required"
                    )
                  }
                  impo
                  label={{ type: "secondary" }}
                  input={{
                    size: "large",
                    name: "base_product_name",
                    value: base_product_name,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handelChange}
                  labelText="Product Name"
                />
              </Col>

              {!this.props.nosellerSKU && 
                <Col sm={12}>
                <InputField
                  errormsg={
                    this.props.validator &&
                    this.props.validator.message(
                      "seller_sku",
                      seller_sku,
                      "required"
                    )
                  }
                  impo
                  label={{ type: "secondary" }}
                  input={{
                    size: "large",
                    name: "seller_sku",
                    value: seller_sku,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handelChange}
                  labelText="Seller SKU"
                />
               </Col>
              }
            
              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  impo = {this.props.manufacturer_part_number_impo}
                  errormsg={
                    this.props.validator && this.props.manufacturer_part_number_impo &&
                    this.props.validator.message(
                      "manufacturer_part_number",
                      manufacturer_part_number,
                      "required"
                    )
                  }
                  input={{
                    size: "large",
                    name: "manufacturer_part_number",
                    value: manufacturer_part_number,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handelChange}
                  labelText="Manufacturer Part Number"
                />
              </Col>
              <Col sm={12}>
                <Text style={{ color: "#ffffff99" }}>
                  Brand Name {!this.props.brand_not_impo && <Text style={{ color: "#EF556B" }}>*</Text>}
                  <Text style={{ color: "red", fontSize: "8px" }}>
                    {this.props.validator && !this.props.brand_not_impo &&
                      this.props.validator.message(
                        "brand_name",
                        this.state.OtherBrandOn ? "Other" : brand_name,
                        "required"
                      )}
                  </Text>
                </Text>
                {/* <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  name="brand_name"
                  size="large"
                  value={brand_name}
                  disabled={this.props.disabled}
                  className={this.props.disabled && "disabledSelect"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.brandList.map(e => {
                    return <Option selected value={e.name}>
                      {e.name}
                    </Option>;
                  })}
                </Select> */}
  {/* Custom Select list */}
                <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    name="brand_name"
                    size="large"
                    value={this.state.OtherBrandOn ? "Other" : brand_name}
                    disabled={this.props.disabled}
                    className={this.props.disabled && "disabledSelect"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.props.brandList.map(e => {
                      return <Option selected value={e.name}>
                        {e.name}
                      </Option>;
                    })}
                      <Option selected value={"Other"}>
                        Other
                      </Option>
                  </Select>
              


                {/* <InputField errormsg={this.props.validator.message('brand_name', brand_name, 'required')} impo label={{ type: "secondary" }} input={{ size: "large", name: "brand_name", value: brand_name, disabled: this.props.disabled }} onChange={this.handelChange} labelText="Brand" /> */}
              </Col>


             {this.state.OtherBrandOn && <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  input={{
                    size: "large",
                    name: "Other",
                    value: brand_name === "Other" ? "" : brand_name,
                    disabled: this.props.disabled,
                  }}
                  onChange={this.onChangeOtherBrand}
                  labelText="Other"
                />
              </Col>
              }

              <Col sm={12}>
                <InputField
                  label={{ type: "secondary" }}
                  input={{
                    size: "large",
                    name: "manufacturer",
                    value: manufacturer,
                    disabled: this.props.disabled
                  }}
                  onChange={this.handelChange}
                  labelText="Manufacturer"
                />
              </Col>

              
               <Col sm={12}>

                <Text style={{ color: "#ffffff99" }}>
                 Department <Text style={{ color: "#EF556B" }}>*</Text>
                  <Text style={{ color: "red", fontSize: "8px" }}>
                    {this.props.validator &&
                      this.props.validator.message(
                        "category",
                        this.props.initiSuperCategory || this.state.super_category,
                        "required"
                      )}
                  </Text>
                </Text>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Category"
                  optionFilterProp="children"
                  onChange={this.onChangeSuperCategory}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  name="category"
                  size="large"
                  value={(this.state.super_category && this.state.super_category.name) || (this.props.initiSuperCategory && this.props.initiSuperCategory.name) || ""}
                  disabled={this.props.disabled}
                  className={this.props.disabled && "disabledSelect"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.super_categories && this.props.super_categories.map((e, index) => {
                    return <Option selected value={index}>
                      {e.name}
                    </Option>;
                  })}
                </Select>
              </Col>

                {
                  (this.props.initiCategory || this.state.super_category) &&
              <Col sm={12}>
                <Text style={{ color: "#ffffff99" }}>
                  Category <Text style={{ color: "#EF556B" }}>*</Text>
                  <Text style={{ color: "red", fontSize: "8px" }}>
                    {this.props.validator &&
                      this.props.validator.message(
                        "category",
                        this.props.initiCategory || this.state.category,
                        "required"
                      )}
                  </Text>
                </Text>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Category"
                  optionFilterProp="children"
                  onChange={this.onChangeCategory}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  name="category"
                  size="large"
                  value={(this.state.category && this.state.category.name) || (this.props.initiCategory && this.props.initiCategory.name) || ""}
                  disabled={this.props.disabled}
                  className={this.props.disabled && "disabledSelect"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.super_category ?
                   this.state.super_category.category_list.map((e, index) => {
                    return <Option selected value={index}>
                      {e.name}
                    </Option>;
                  }) :
                  this.props.initiSuperCategory.category_list.map((e, index) => {
                    return <Option selected value={index}>
                      {e.name}
                    </Option>;
                  }) 
                }
                </Select>
              </Col>
              } 
             
                {
                  (this.props.initiSubCategory || this.state.category) &&
                  <Col sm={12}>
                  <React.Fragment>
                    <Text style={{ color: "#ffffff99" }}>
                      Sub Category <Text style={{ color: "#EF556B" }}>*</Text>
                      <Text style={{ color: "red", fontSize: "8px" }}>
                        {this.props.validator &&
                          this.props.validator.message(
                            "sub category",
                            this.props.initiSubCategory || this.state.subCategory,
                            "required"
                          )}
                      </Text>
                    </Text>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select a Category"
                      optionFilterProp="children"
                      onChange={this.onChangeSubCategory}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      name="category"
                      size="large"
                      value={(this.state.subCategory && this.state.subCategory.name) || (this.props.initiSubCategory && this.props.initiSubCategory.name) || ""}
                      disabled={this.props.disabled}
                      className={this.props.disabled && "disabledSelect"}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.category ?
                       this.state.category.sub_category_list.map((e, index) => {
                        return <Option selected value={index}>
                          {e.name}
                        </Option>;
                      }) :
                      this.props.initiCategory.sub_category_list.map((e, index) => {
                        return <Option selected value={index}>
                          {e.name}
                        </Option>;
                      })
                    }
                    </Select>
                  </React.Fragment>
                  </Col>
                }
            
            </Row>
          </Col>
        
          
          <Col className="gutter-row" span={24}>
            <Row gutter={[24, 24]} className="gutter-box">
              <Col style={{ paddingBottom: "0px" }} sm={24}>
                <FormHeader header="Dimensions : Base" />
              </Col>
              <Col sm={24}>
                <InputNumberDimensions3
                  impo = {this.props.ECQ_impo}
                  errormsg = {
                    this.props.validator && this.props.ECQ_impo &&
                    this.props.validator.message(
                      "manufacturer_part_number",
                      export_carton_quantity_l && export_carton_quantity_b && export_carton_quantity_h
                      && export_carton_quantity_l_metric && export_carton_quantity_b_metric && export_carton_quantity_h_metric,
                      "required"
                    )
                  }
                  name1="export_carton_quantity_l"
                  value1={export_carton_quantity_l}
                  name2="export_carton_quantity_b"
                  value2={export_carton_quantity_b}
                  name3="export_carton_quantity_h"
                  value3={export_carton_quantity_h}
                  unit1="export_carton_quantity_l_metric"
                  unit2="export_carton_quantity_b_metric"
                  unit3="export_carton_quantity_h_metric"
                  valueunit1={export_carton_quantity_l_metric}
                  valueunit2={export_carton_quantity_b_metric}
                  valueunit3={export_carton_quantity_h_metric}
                  getData={this.handleChangeD}
                  getMetriD={this.handleChangeMD}
                  label={{ type: "secondary" }}
                  labelText="Export Carton Qty ( L x B x H )"
                  input={{ size: "large", disabled: this.props.disabled }}
                  disabled={this.props.disabled}
                  list1={this.dimlist}
                  list2={this.dimlist}
                  list3={this.dimlist}
                />
              </Col>
              <Col sm={24}>
                <InputNumberDimensions3
                impo = {this.props.ECC_impo}
                errormsg = {
                  this.props.validator && this.props.ECC_impo &&
                  this.props.validator.message(
                    "manufacturer_part_number",
                    export_carton_crm_l && export_carton_crm_b && export_carton_crm_h
                    && export_carton_crm_l_metric && export_carton_crm_b_metric && export_carton_crm_h_metric,
                    "required"
                  )
                }
                  name1="export_carton_crm_l"
                  value1={export_carton_crm_l}
                  name2="export_carton_crm_b"
                  value2={export_carton_crm_b}
                  name3="export_carton_crm_h"
                  value3={export_carton_crm_h}
                  getData={this.handleChangeD}
                  getMetriD={this.handleChangeMD}
                  label={{ type: "secondary" }}
                  unit1="export_carton_crm_l_metric"
                  unit2="export_carton_crm_b_metric"
                  unit3="export_carton_crm_h_metric"
                  valueunit1={export_carton_crm_l_metric}
                  valueunit2={export_carton_crm_b_metric}
                  valueunit3={export_carton_crm_h_metric}
                  labelText="Export Carton CBM ( L x B x H )"
                  input={{ size: "large", disabled: this.props.disabled }}
                  disabled={this.props.disabled}
                  list1={this.dimlist}
                  list2={this.dimlist}
                  list3={this.dimlist}
                />
              </Col>
              <Col sm={24}>
                <InputNumberDimensions3
                impo = {this.props.PD_impo}
                errormsg = {
                  this.props.validator && this.props.PD_impo &&
                  this.props.validator.message(
                    "manufacturer_part_number",
                    product_dimension_l && product_dimension_b && product_dimension_h
                    && product_dimension_l_metric && product_dimension_b_metric && product_dimension_h_metric,
                    "required"
                  )
                }
                  name1="product_dimension_l"
                  value1={product_dimension_l}
                  name2="product_dimension_b"
                  value2={product_dimension_b}
                  name3="product_dimension_h"
                  value3={product_dimension_h}
                  getData={this.handleChangeD}
                  getMetriD={this.handleChangeMD}
                  label={{ type: "secondary" }}
                  unit1="product_dimension_l_metric"
                  unit2="product_dimension_b_metric"
                  unit3="product_dimension_h_metric"
                  valueunit1={product_dimension_l_metric}
                  valueunit2={product_dimension_b_metric}
                  valueunit3={product_dimension_h_metric}
                  labelText="Product Dimension ( L x B x H )"
                  input={{ size: "large", disabled: this.props.disabled }}
                  disabled={this.props.disabled}
                  list1={this.dimlist}
                  list2={this.dimlist}
                  list3={this.dimlist}
                />
              </Col>
              <Col sm={24}>
                <InputNumberDimensions3
                impo = {this.props.GB_impo}
                errormsg = {
                  this.props.validator && this.props.GB_impo &&
                  this.props.validator.message(
                    "manufacturer_part_number",
                    giftbox_l && giftbox_b && giftbox_h
                    && giftbox_l_metric && giftbox_b_metric && giftbox_h_metric,
                    "required"
                  )
                }
                  name1="giftbox_l"
                  value1={giftbox_l}
                  name2="giftbox_b"
                  value2={giftbox_b}
                  name3="giftbox_h"
                  value3={giftbox_h}
                  unit1="giftbox_l_metric"
                  unit2="giftbox_b_metric"
                  unit3="giftbox_h_metric"
                  valueunit1={giftbox_l_metric}
                  valueunit2={giftbox_b_metric}
                  valueunit3={giftbox_h_metric}
                  getData={this.handleChangeD}
                  getMetriD={this.handleChangeMD}
                  label={{ type: "secondary" }}
                  labelText="GiftBox ( L x B x H )"
                  input={{ size: "large", disabled: this.props.disabled }}
                  disabled={this.props.disabled}
                  list1={this.dimlist}
                  list2={this.dimlist}
                  list3={this.dimlist}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <SapPullProducts baseProductPK={this.props.productDetails.base_product_pk} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails
  };
};

export default connect(mapStateToProps, { handelEditProductDetails })(BaseForm);
