import React, { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Typography,
	Icon,
	Modal,
	Button,
	Input,
	Upload,
	message,
} from 'antd';
import axios from 'axios';
import isDev from 'server';
import './admin-category-details.scss';

const omnycommUrl = isDev();

const { Title, Text } = Typography;
/**
 * @description This function corresponds to 'Category Management' in ommnycomm dashboard
 * It handles three buttons 'Super Category', 'Category' ,'Sub Category'
 * and updates details/images based on input.  
 * @param {object} * An object which has data/handler functions about data columns.   
 * @returns It returns a react fragment which conditional renders based on products selected.
 */
const DataColumns = ({
	columnName,
	columnData,
	handleClick,
	hierarchy,
	handleAdd,
	handleEdit,
	loading,
}) => {
	const initialState = {
		name: '',
		image: '',
		uuid: '',
		active: false,
		type: columnName,
	};
	const [modal, setModal] = useState(initialState);

	useEffect(() => {
		if (!loading) setModal(initialState);
	}, [loading]);

	return (
		<>
			<Col span={8} style={{ padding: '20px' }}>
				<Col span={24} style={{ margin: '10px 0 20px' }}>
					<Text strong style={{ color: 'white', fontSize: '18px' }}>
						{columnName} List : {columnData && columnData.length}
					</Text>
				</Col>
				<Col
					className='column-button'
					onClick={() => setModal({ ...modal, active: true })}
				>
					Add {columnName}
				</Col>
				<Col span={24} className='column-bar-container'>
					{columnData.map((item) => {
						return (
							<Col
								span={24}
								key={item.uuid}
								onClick={() => handleClick(item.uuid)}
								className={`column-bar ${
									hierarchy === item.uuid ? 'selected' : ''
								}`}
							>
								{item.name}
								{hierarchy === item.uuid && (
									<Icon
										type='edit'
										style={{ color: '#1abc9c', fontSize: '18px' }}
										onClick={() =>
											setModal({
												...modal,
												active: true,
												...item,
												image:
													item.image !== ''
														? [{ uid: item.uuid, url: item.image }]
														: '',
											})
										}
									/>
								)}
							</Col>
						);
					})}
				</Col>
			</Col>
			<Modal
				visible={modal.active}
				destroyOnClose={true}
				onCancel={() => setModal({ ...modal, active: false })}
				footer={[
					<Button
						type='danger'
						onClick={() => setModal({ ...modal, active: false })}
					>
						Cancel
					</Button>,
					<Button
						loading={loading}
						type='primary'
						onClick={() => {
							if (modal.name === '')
								message.error(`${modal.type} name required!`);
							if (modal.uuid !== '') handleEdit(modal);
							else handleAdd(modal);
						}}
					>
						{modal.uuid !== '' ? 'Update' : 'Add'}
					</Button>,
				]}
				title={modal.uuid !== '' ? 'Update ' + modal.type : 'Add ' + modal.type}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Input
							value={modal.name}
							style={{ color: 'white' }}
							onChange={(e) => setModal({ ...modal, name: e.target.value })}
						/>
					</Col>
					<Col span={24}>
						<Upload
							listType='picture-card'
							fileList={modal.image}
							onChange={({ fileList }) => {
								console.clear()
								console.log(fileList)
								setModal({ ...modal, image: fileList.length > 0 ? fileList : '' });
							}}
							onRemove={() => setModal({ ...modal, image: '' })}
						>
							<div>
								<Icon type='camera' />
								<div className='ant-upload-text'>
									You can drag and drop images or upload images
								</div>
							</div>
						</Upload>
					</Col>
				</Row>
			</Modal>
		</>
	);
};
/**
 * @description  It  handles input and fetches the 'Super Category', 'Category' ,'Sub Category' data
 * of desired product.
 * @returns It returns a react fragment which renders  on selected products and display the 
 * corresponding 'Super Category', 'Category' ,'Sub Category'.
 */
const AdminCategoryDetails = () => {
	const [superCategories, setSuperCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [hierarchy, setHierarchy] = useState({
		superCategory: '',
		category: '',
		subCategory: '',
	});

	const fetchAdminSuperCategories = () => {
		axios({
			method: 'POST',
			url: `${omnycommUrl}/admin/fetch-admin-super-categories/`,
			data: {
				organization : "WIG"
			},
			headers: {
				'content-type': 'application/json',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			setSuperCategories(response.data.superCategoryList);
			console.clear();
			console.log(response.data);
		});
	};
	const fetchAdminCategories = (uuid) => {
		axios({
			method: 'POST',
			url: `${omnycommUrl}/admin/fetch-admin-categories/`,
			data: {
				super_category_uuid: uuid,
				organization : "WIG"
			},
			headers: {
				'content-type': 'application/json',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			setCategories(response.data.categoryList);
			setHierarchy({ superCategory: uuid, category: '', subCategory: '' });
		});
	};
	const fetchAdminSubCategories = (uuid) => {
		axios({
			method: 'POST',
			url: `${omnycommUrl}/admin/fetch-admin-sub-categories/`,
			data: {
				category_uuid: uuid,
				organization : "WIG"
			},
			headers: {
				'content-type': 'application/json',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			setSubCategories(response.data.subCategoryList);
			setHierarchy({ ...hierarchy, category: uuid, subCategory: '' });
		});
	};
	const updateAdminCategoryType = (category) => {
		setLoading(true);
		let formData = new FormData();
		formData.append('name', category.name);
		if (category.type === 'Super Category')
			formData.append('super_category_uuid', category.uuid);
		else if (category.type === 'Category')
			formData.append('category_uuid', category.uuid);
		else formData.append('sub_category_uuid', category.uuid);
		formData.append(
			'image',
			category.image !== '' ? category.image[0].originFileObj : ''
		);
		axios({
			method: 'POST',
			url: `${omnycommUrl}/admin/update-admin-${
				category.type === 'Sub Category'
					? 'sub-'
					: category.type === 'Super Category'
					? 'super-'
					: ''
			}category-details/`,
			data: formData,
			headers: {
				'content-type': 'multipart/form-data',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			if (response.data.status === 200) {
				console.log('updated:', response.data);
				const newCategory = {
					uuid: response.data.uuid,
					name: response.data.name,
					image: response.data.image,
				};
				if (category.type === 'Super Category')
					setSuperCategories([
						...superCategories.map((item) =>
							response.data.uuid !== item.uuid ? item : newCategory
						),
					]);
				else if (category.type === 'Category')
					setCategories([
						...categories.map((item) =>
							response.data.uuid !== item.uuid ? item : newCategory
						),
					]);
				else
					setSubCategories([
						...subCategories.filter((item) =>
							response.data.uuid !== item.uuid ? item : newCategory
						),
					]);
				message.success(`${category.type} updated successfully!`);
				setLoading(false);
			} else {
				message.error('Something went wrong!');
				setLoading(false);
			}
		});
	};
	const addNewAdminCategoryType = (category) => {
		setLoading(true);
		let formData = new FormData();
		console.log('category: ', category);
		formData.append('name', category.name);
		formData.append('organization', "WIG");
		formData.append(
			'image',
			category.image !== '' ? category.image[0].originFileObj : ''
		);
		if (category.type === 'Category') {
			formData.append('super_category_uuid', hierarchy.superCategory);
		}
		else if (category.type === 'Sub Category')
			formData.append('category_uuid', hierarchy.category);
		axios({
			method: 'POST',
			url: `${omnycommUrl}/admin/add-new-admin-${
				category.type === 'Sub Category'
					? 'sub-'
					: category.type === 'Super Category'
					? 'super-'
					: ''
			}category/`,
			data: formData,
			headers: {
				'content-type': 'multipart/form-data',
				Authorization: `JWT ${localStorage.getItem('token')}`,
			},
		}).then((response) => {
			if (response.data.status === 200) {
				console.log('added', response.data);
				const newCategory = {
					uuid: response.data.uuid,
					name: response.data.name,
					image: response.data.image,
				};
				if (category.type === 'Super Category')
					setSuperCategories([newCategory, ...superCategories]);
				else if (category.type === 'Category')
					setCategories([newCategory, ...categories]);
				else setSubCategories([newCategory, ...subCategories]);
				message.success(`${category.type} added successfully!`);
				setLoading(false);
			} else {
				message.error('Something went wrong!');
				setLoading(false);
			}
		});
	};
	const handleSubCategory = (uuid) => {
		setHierarchy({ ...hierarchy, subCategory: uuid });
	};

	useEffect(() => {
		fetchAdminSuperCategories();
	}, []);

	return (
    <React.Fragment>
      <Row
        style={{
          marginTop: "0px",
          padding: "14px",
          width: "100%",
        }}
      >
        <Title level={4} style={{ color: "white" }}>
          Category Management
        </Title>
        <DataColumns
          hierarchy={hierarchy.superCategory}
          columnName="Super Category"
          columnData={superCategories}
          loading={loading}
          handleClick={fetchAdminCategories}
          handleAdd={addNewAdminCategoryType}
          handleEdit={updateAdminCategoryType}
        />
        {hierarchy.superCategory !== "" && (
          <DataColumns
            hierarchy={hierarchy.category}
            columnName="Category"
            columnData={categories}
            loading={loading}
            handleClick={fetchAdminSubCategories}
            handleAdd={addNewAdminCategoryType}
            handleEdit={updateAdminCategoryType}
          />
        )}
        {hierarchy.category !== "" && (
          <DataColumns
            hierarchy={hierarchy.subCategory}
            columnName="Sub Category"
            columnData={subCategories}
            loading={loading}
            handleClick={handleSubCategory}
            handleAdd={addNewAdminCategoryType}
            handleEdit={updateAdminCategoryType}
          />
        )}
      </Row>
    </React.Fragment>
  );
};

export default AdminCategoryDetails;
