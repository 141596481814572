import React, { Component } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  message,
  Checkbox,
  Badge,
  Typography,
  Slider,
  InputNumber,
} from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./orders-warehouse-manager-filter-modal.scss";

const { Text } = Typography;

const trackingStatusList = [
  "pending",
  "approved",
  "picked",
  "dispatched",
  "delivered",
  "delivery failed",
  "cancelled",
];
const currencyList = ["AED"];
const shippingMethodList = ["WIG Fleet", "grand gaadi", "SENDEX", "Emirates Post", "pending"];
const paymentTypeList = ["Credit Card", "COD", "Installments"];
const QUANTITY_SLIDER = [0, 100];
const PRICE_SLIDER = [0, 10000];

export default class OrdersWarehouseManagerFilterModal extends Component {
  state = {
    visible: false,
    startDate: null,
    endDate: null,
    paymentTypeList: [],
    shippingMethodList: [],
    trackingStatusList: [],
    minQty: 0,
    maxQty: 100,
    minPrice: 0,
    maxPrice: 10000,
    currencyList: "",
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  setTrackingFilters = (checkedValues) => {
    this.setState({ trackingStatusList: checkedValues });
  };

  setShipmentFilters = (checkedValues) => {
    this.setState({ shippingMethodList: checkedValues });
  };

  setPaymentFilters = (checkedValues) => {
    this.setState({ paymentTypeList: checkedValues });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleQuantityChange = (quantityValue, minOrMax) => {
    if (
      quantityValue > QUANTITY_SLIDER[0] &&
      quantityValue < QUANTITY_SLIDER[1]
    ) {
      if (minOrMax === "min") {
        this.setState({ minQty: quantityValue });
      } else {
        this.setState({ maxQty: quantityValue });
      }
    } else {
      message.error("Invalid Quantity Range!");
    }
  };

  changeQuantitySlider = (valuesRange) => {
    this.setState({
      minQty: valuesRange[0],
      maxQty: valuesRange[1],
    });
  };

  handlePriceChange = (quantityValue, minOrMax) => {
    if (quantityValue > PRICE_SLIDER[0] && quantityValue < PRICE_SLIDER[1]) {
      if (minOrMax === "min") {
        this.setState({ minPrice: quantityValue });
      } else {
        this.setState({ maxPrice: quantityValue });
      }
    } else {
      message.error("Invalid Price Range!");
    }
  };

  changePriceSlider = (valuesRange) => {
    this.setState({
      minPrice: valuesRange[0],
      maxPrice: valuesRange[1],
    });
  };

  handleOk = (e) => {
    this.setState({ visible: false }, () => {
      this.props.applyFilters(
        {
          fromDate: this.state.startDate,
          toDate: this.state.endDate,
          paymentTypeList: this.state.paymentTypeList,
          minQty: this.state.minQty,
          maxQty: this.state.maxQty,
          minPrice: this.state.minPrice,
          maxPrice: this.state.maxPrice,
          currencyList: this.state.currencyList,
          shippingMethodList: this.state.shippingMethodList,
          trackingStatusList: this.state.trackingStatusList,
        },
        false
      );
    });
  };

  handleCancel = (e) => {
    this.setState({ visible: false });
  };

  render() {
    return (
      <div
        className="order-warehouse-manager-modal-container"
        style={{ width: "100%" }}
      >
        {/* <Badge count={this.props.filterCount} showZero> */}
          <Button
            style={{ width: "100%" }}
            type="danger"
            onClick={this.showModal}
            disabled={this.props.someProcessingActive}
          >
            Filter
          </Button>
        {/* </Badge> */}

        <Modal
          title="Filter"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="80vw"
        >
          <Row type="flex" align="middle" gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Start Date
                      </Text>
                      <DatePicker
                        size={this.props.size}
                        className="custom-datePicker"
                        style={{ backgroundColor: "#36404A", width: "100%" }}
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Text strong type="secondary">
                        End Date
                      </Text>
                      <DatePicker
                        size={this.props.size}
                        className="custom-datePicker"
                        style={{ backgroundColor: "#36404A", width: "100%" }}
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Channels
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox>Amazon UK</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox>Amazon UAE</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox>Dealshub</Checkbox>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Payment Type
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={this.setPaymentFilters}
                      >
                        {paymentTypeList.map((status, index) => {
                          return (
                            <Col span={24} key={index}>
                              <Checkbox value={status}>{status}</Checkbox>
                            </Col>
                          );
                        })}
                      </Checkbox.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Shipment
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={this.setShipmentFilters}
                      >
                        {shippingMethodList.map((status, index) => {
                          return (
                            <Col span={24} key={index}>
                              <Checkbox value={status}>{status}</Checkbox>
                            </Col>
                          );
                        })}
                      </Checkbox.Group>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Text strong type="secondary">
                        Tracking Status
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={this.setTrackingFilters}
                      >
                        {trackingStatusList.map((status) => {
                          return (
                            <Col span={24}>
                              <Checkbox value={status}>
                                {!(status === "delivery failed")
                                  ? status.charAt(0).toUpperCase() +
                                    status.substr(1)
                                  : "Delivery Failed"}
                              </Checkbox>
                            </Col>
                          );
                        })}
                      </Checkbox.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                gutter={[16, 16]}
                type="flex"
                align="middle"
                justify="space-between"
              >
                <Col span={24}>
                  <Text strong type="secondary">
                    Quantity
                  </Text>
                </Col>
                <Col span={24}>
                  <Slider
                    range
                    max={QUANTITY_SLIDER[1]}
                    value={[this.state.minQty, this.state.maxQty]}
                    onChange={this.changeQuantitySlider}
                  />
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.minQty}
                        onChange={(value) => {
                          this.handleQuantityChange(value, "min");
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.maxQty}
                        onChange={(value) => {
                          this.handleQuantityChange(value, "max");
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                gutter={[16, 16]}
                type="flex"
                align="middle"
                justify="space-between"
              >
                <Col span={24}>
                  <Text strong type="secondary">
                    Price
                  </Text>
                </Col>
                <Col span={24}>
                  <Slider
                    range
                    max={PRICE_SLIDER[1]}
                    value={[this.state.minPrice, this.state.maxPrice]}
                    onChange={this.changePriceSlider}
                  />
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.minPrice}
                        onChange={(value) => {
                          this.handlePriceChange(value, "min");
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        value={this.state.maxPrice}
                        onChange={(value) => {
                          this.handlePriceChange(value, "max");
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
