import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Avatar, Checkbox, Tag } from "antd";

const { Text } = Typography;
const genExtra = (str) => <Tag color={str[1]}>{str[0]}</Tag>;

export default class OrdersWarehouseManagerMobileViewRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxSelected: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      checkboxSelected: true,
    });
  };

  // static getDerivedStateFromProps(nextProps, previousState) {
  //   if (previousState.checkboxSelected != nextProps.order.checked) {
  //     return { checkboxSelected: nextProps.order.checked };
  //   } else {
  //     return null;
  //   }
  // }



  render() {
    return (
      <Row className="order-container" gutter={[8, 8]}>
        <Col span={24} className="order-row">
          <Row type="flex" align="top">
            <Col span={6}>
              {/* <Avatar shape="square" src={this.props.order.productImageUrl} /> */}
              <img
                src={this.props.order.productImageUrl}
                style={{ width: "90%", height: "auto", borderRadius: "4px" }}
              />
            </Col>
            <Col span={18}>
              <Row>
                <Col span={24}>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <Link
                        // to={`order-details/${this.props.order.uuid}`}
                        to={`/order-details/${this.props.bundleUuid}`}
                        style={{ fontSize: "14px" }}
                      >
                        <Text>{this.props.order.orderId}</Text>
                      </Link>
                    </Col>
                    <Col>
                      <Col span={2}>
                        {
                          !this.props.toggleOff &&
                          <Checkbox
                          value={this.props.order.uuid}
                          checked={this.state.checkboxSelected}
                          onChange={(e) => {
                            this.props.toggleOrderCheck(
                              this.state.checkboxSelected,
                              e.target.value,
                              this.props.bundleId
                            );
                            this.setState({
                              checkboxSelected: !this.state.checkboxSelected,
                            });
                          }}
                        />
                        }

                      </Col>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col>
                      <Typography.Paragraph
                        ellipsis={{ rows: 2, expandable: false }}
                        style = {{padding : "2px 0px" , margin : "0px"}}
                      >
                        {this.props.order.productName}
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Text>
                    {this.props.order.price} {this.props.order.currency}
                  </Text>
                </Col>

                <Col span={24}>
                  <Row type="flex" gutter={[8, 8]}>
                    <Col>
                      <Text>{this.props.order.quantity}</Text>
                    </Col>
                    <Col>
                      <Text>Qty</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ paddingTop: "4px" }}>
                  <Row type="flex" gutter={[4, 4]}>
                    {
                      !this.props.toggleOff &&
                      <Col>
                      <Text className="table-head-text">
                        {/* {this.props.order.shippingMethod} */}
                        {this.props.order.shippingMethod === "grand gaadi" ? (
                          <React.Fragment>
                            {genExtra([
                              this.props.order.shippingMethod,
                              "magenta",
                            ])}
                          </React.Fragment>
                        ) : (
                            <React.Fragment>
                              {this.props.order.shippingMethod === "pending" ? (
                                <React.Fragment>
                                  {genExtra([
                                    this.props.order.shippingMethod,
                                    "yellow",
                                  ])}
                                </React.Fragment>
                              ) : (
                                  <React.Fragment>
                                    {genExtra([
                                      this.props.order.shippingMethod,
                                      "blue",
                                    ])}
                                  </React.Fragment>
                                )}
                            </React.Fragment>
                          )}
                      </Text>
                    </Col>
                    }
                  {
                    !this.props.toggleOff &&
                    <Col>
                    <Text className="table-head-text">
                      {this.props.order.currentStatus === "cancelled" ||
                        this.props.order.currentStatus === "delivery failed" ? (
                          <React.Fragment>
                            {this.props.order.currentStatus === "cancelled" ? (
                              <React.Fragment>{genExtra(["Cancelled", "red"])}</React.Fragment>
                            ) : (
                                <React.Fragment>{genExtra(["Delivery Failed", "red"])}</React.Fragment>
                              )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {this.props.order.currentStatus === "pending" ? (
                              <React.Fragment>
                                {genExtra([
                                  this.props.order.currentStatus
                                    .charAt(0)
                                    .toUpperCase() +
                                  this.props.order.currentStatus.substr(1),
                                  "yellow",
                                ])}
                              </React.Fragment>
                            ) : (
                                <React.Fragment>
                                  {genExtra([
                                    this.props.order.currentStatus
                                      .charAt(0)
                                      .toUpperCase() +
                                    this.props.order.currentStatus.substr(1),
                                    "green",
                                  ])}
                                </React.Fragment>
                              )}
                          </React.Fragment>
                        )}
                    </Text>
                  </Col>
                  }
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
