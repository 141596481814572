import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Icon,
  Select,
  message,
  Typography,
  Upload
} from "antd";
import InputField from "../../datainputs/InputField";
import "./addflyer.scss";
import isDev from "../../../../server";
const serverUrl = isDev();
const { Option } = Select;
const { Text } = Typography;

class AddFlyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandList: []
    };
  }

  handleChange = ({ file }) => {
    this.props.getFile(file);
    // this.setState({ fileList });
  };

  componentDidMount() {
    fetch(`${serverUrl}/fetch-brands/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ brandList: data.brand_list });
      });
  }

  render() {
    const { flyer_name, flyer_items } = this.props.data;
    const props = {
      onChange: this.handleChange,
      multiple: false
    };
    return (
      <React.Fragment>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col sm={12}>
            <InputField
              impo
              label={{ type: "secondary" }}
              input={{
                size: "large",
                name: "flyer_name",
                value: flyer_name
              }}
              labelText="Flyer Name"
              onChange={this.props.handelChangeInputOut}
            />
          </Col>

          <Col span={12}>
            <Text>Brand</Text>
            <Text style={{ color: "rgb(239, 85, 107)" }}> *</Text>
            <br />
            <Select
              style={{ width: "100%" }}
              onSelect={this.props.handleBrandSelect}
              value={this.props.data.flyer_brand}
              placeholder="All"
              size="large"
            >
              <Option value="">Choose brand</Option>
              {this.state.brandList &&
                this.state.brandList.map((brand, index) => {
                  return (
                    <Option key={brand.pk} value={brand.pk}>
                      {brand.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col span={12}>
            <Text>Type</Text>
            <br />
            <Select
              style={{ width: "100%" }}
              onSelect={this.props.handleTypeSelect}
              value={this.props.data.type}
              size="large"
            >
              <Option value="0">Create Empty</Option>
              <Option value="1">Upload Excel</Option>
            </Select>
          </Col>

          {this.props.data.type == "0" && (
            <Col sm={12}>
              <InputField
                impo
                label={{ type: "secondary" }}
                input={{
                  size: "large",
                  name: "flyer_items",
                  value: flyer_items,
                  type: "number"
                }}
                labelText="Number of Products"
                onChange={this.props.handelChangeInputOut}
              />
            </Col>
          )}

          {this.props.data.type == "1" && (
            <Col sm={12} className="selectFlyerForm">
              <Text>Import Excel</Text>
              <br />
              <Upload {...props} beforeUpload={() => false}>
                <Button>
                  <Icon type="upload" size="large" /> Select File
                </Button>
              </Upload>
            </Col>
          )}
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col span={12}>
            <Text>Columns per Row</Text>
            <br />
            <Select
              style={{ width: "100%" }}
              onSelect={this.props.handleCprSelect}
              value={this.props.data.cpr}
              placeholder="All"
              size="large"
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
            </Select>
          </Col>

          <Col span={12}>
            <Text>Containers Height</Text>
            <br />
            <Select
              style={{ width: "100%" }}
              onSelect={this.props.handleChSelect}
              value={this.props.data.ch}
              placeholder="All"
              size="large"
            >
              {/* <Option value="11">11 Small</Option>
              <Option value="15">15 Medium</Option>
              <Option value="30">30 Long</Option> */}

              <Option value="4">4 Very Small Size</Option>
              <Option value="5">5 Small Size</Option>
              <Option value="6">6 Medium Size</Option>
              <Option value="11">11 Long</Option>
              <Option value="15">15 Very Long</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col span={12}>
            <Text>Template</Text>
            <br />
            <Select
              style={{ width: "100%" }}
              onSelect={this.props.handleModeSelect}
              value={this.props.data.mode}
              placeholder="All"
              size="large"
            >
              <Option value="A4 Portrait">A4 Portrait</Option>
              <Option value="A4 Landscape">A4 Landscape</Option>
              <Option value="A5 Portrait">A5 Portrait</Option>
              <Option value="A5 LandScape">A5 LandScape</Option>
            </Select>
          </Col>

          {this.props.data.type === "1" && (
            <Col span={12}>
              <br />
              <Text>Click
          <a style={{ color: "#1ABC9C" }} href="https://api.omnycomm.com/files/csv/flyer-template.xlsx" target="_blank"> here </a>
                 to download the excel template</Text>
            </Col>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default AddFlyer;
