import React, { Component } from "react";
import { Row, Col, Table, Button , Typography , Icon } from "antd";
import { Ellipsis } from "react-spinners-css";
import "./product-details.scss";
import styled from "styled-components";
import ImageSlider from "../components/imageslider/ImageSlider";
import { Link, Redirect } from "react-router-dom";
import createDOMPurify from "dompurify";

import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {
  ProductDetailsPageContainer,
  ProductDetailsContainer,
  ProductDetailsLeft,
  ProductDetailsRight,
  websiteGroupNameLink,
  ProductName,
  ProductPriceRow,
  ProductPriceLabel,
  ProductPrice,
  ProductPriceDiscounted,
  ProductStock,
  ProductOutOfStock,
  ProductInfoText,
  ProductFeaturesContainer,
  ProductFeatureRow,
  ProductFeatureIcon,
  ProductFeatureInfo,
  ProductQtySizeRow,
  SizeLabel,
  SizeSelect,
  SizeOption,
  ProductDetailsTable
} from "./styled-components-library";

import ImageSliderThumbnail from "../components/image-slider-thumbnail/ImageSliderThumbnail";
import ReactSlick from "../components/react-slick/ReactSlick";

import isDev from "../../server";
import BaseForm from "views/Edit-Base-Product-Page/BaseForm";
const serverUrl = isDev();

const { Column } = Table;
const {Text} = Typography;

const DOMPurify = createDOMPurify(window);

const channelwizedataSource = [
  {
    key: "1",
    channel_name: "Amazon - UK",
    was_price: "NA",
    sale_price: "NA",
    discount_per: "NA",
    stock: "NA",
    revenue: "NA"
  },
  {
    key: "2",
    channel_name: "Noon",
    was_price: "NA",
    sale_price: "NA",
    discount_per: "NA",
    stock: "NA",
    revenue: "NA"
  },
  {
    key: "3",
    channel_name: "Amazon - UAE",
    was_price: "NA",
    sale_price: "NA",
    discount_per: "NA",
    stock: "NA",
    revenue: "NA"
  }
];

const channelwizecolumns = [
  {
    title: "Channel Name",
    dataIndex: "channel_name",
    key: "channel_name"
  },
  {
    title: "Was Price",
    dataIndex: "was_price",
    key: "was_price"
  },
  {
    title: "Sale Price",
    dataIndex: "sale_price",
    key: "sale_price"
  },
  {
    title: "Discount %",
    dataIndex: "discount_per",
    key: "discount_per"
  },
  {
    title: "Stock",
    dataIndex: "stock",
    key: "stock"
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue"
  }
];

export default class ProductsDetails extends Component {
  state = {
    productDetails: null,
    warehouseInfo: null,
    stock: true,
    minPrice: 10000000000,
    maxPrice: 0,
    description: "",
    desLength: 1000,
    read_more: false,
    needOfReadMore: false
  };

  warehousedataSource = [];

  handleReadMore = () => {
    if (this.state.read_more) {
      this.setState({
        description: DOMPurify.sanitize(this.state.productDetails.product_description_amazon_uk)
      });
    } else {
      this.setState({
        description:
        DOMPurify.sanitize(this.state.productDetails.product_description_amazon_uk.substring(
            0,
            this.state.desLength
          ) + " ...")
      });
    }
    this.setState({ read_more: !this.state.read_more });
  };

  componentDidMount = () => {
    this.props.drawerOff();
    let passedProductPK = { product_pk: this.props.productPk };
    // let passedProductPK = { "product_pk": 44 };

    fetch(`${serverUrl}/fetch-product-details/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(passedProductPK)
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ productDetails: data });
        if (data.product_description_amazon_uk.length > this.state.desLength) {
          this.setState({
            description:
              DOMPurify.sanitize(data.product_description_amazon_uk.substring(
                0,
                this.state.desLength
              ) + " ..."),
            read_more: true,
            needOfReadMore: true
          });
        } else {
          this.setState({
            description: DOMPurify.sanitize(data.product_description_amazon_uk)
          });
        }
      });

    fetch(`${serverUrl}/sap-integration/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(passedProductPK)
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ warehouseInfo: data });

        let stockdata = 0;

        if (data.warehouses_information) {
          data.warehouses_information.map(e => {
            this.warehousedataSource = [
              ...this.warehousedataSource,
              {
                warehouse_code: e.company_code,
                EX_EA: e.prices.EX_EA + " AED",
                IC_EA: e.prices.IC_EA + " AED",
                OD_EA: e.prices.OD_EA + " AED",
                RET_EA: e.prices.RET_EA + " AED",
                qty: e.qty
              }
            ];

            stockdata += e.qty;
            this.setState(prevState => ({
              minPrice: Math.min(prevState.minPrice, e.prices.OD_EA),
              maxPrice: Math.max(prevState.maxPrice, e.prices.OD_EA)
            }));
          });
        }

        if (stockdata == 0) this.setState({ stock: false });
        // 
      });
  };

  getString = str => {
    switch (str) {
      case "EX_EA":
        return "Export Rate";
      case "IC_EA":
        return "Intercompany Rate";
      case "OD_EA":
        return "Outdoor Rate";
      case "RET_EA":
        return "Retail Rate";
    }
  };

  render() {
    // const warehousedataSource = [
    //   {
    //     key: "1",
    //     warehouse_name: "Geepas",
    //     qty: "234"
    //   },
    //   {
    //     key: "2",
    //     warehouse_name: "RoyalFord",
    //     qty: "2324"
    //   },
    //   {
    //     key: "3",
    //     warehouse_name: "Amazon",
    //     qty: "23446"
    //   }
    // ];

    console.log(this.state, "Imagesssss");

    return (
      <React.Fragment>
        {this.state.productDetails !== null ? (
          <ProductDetailsPageContainer className="product-details-container" style = {{marginBottom : "30px"}}>
            <TitleWithBreadCrumb
              title="Product Details"
              breadcrumb={["OmnyComm", "eCommerce", "Product"]}
            />
            <ProductDetailsContainer style = {{position : "relative"}}>
                <div style = {{position : "absolute" , top : "20px" , right : "20px"}}>
                  <Link to = {`/edit-product/${this.props.productPk}`} style = {{zIndex : "1000"}}><Icon type = "edit" style = {{color : "#63C0A7" , fontSize : "18px" , cursor : "pointer"}} /></Link>
                </div>
              <Col lg={10} sm={24}>
                <ProductDetailsLeft>
                  {/* <ImageSlider imageData={this.state.productDetails.images.all_images} /> */}
                  <ImageSliderThumbnail
                    imageData={this.state.productDetails.images.all_images}
                  />


                  {
                    this.state.productDetails.images.all_images.length == 0 && 
                    <Row gutter = {[12,12]} type = "flex" align = "middle" justify = "center" style = {{border : "1px dashed #61BB9D" }}> 
                        <Col>
                          <img src = {this.state.productDetails.repr_high_def_url} style = {{width : "100%"}} />
                        </Col>
                    </Row>  
                  }


                  {/* <ReactSlick imageData={this.state.productDetails.images.all_images} /> */}
                </ProductDetailsLeft>
              </Col>
              <Col lg={14} sm={24} style = {{marginTop : "20px"}}>
                <ProductDetailsRight>
                  <div id="enlarged-image"></div>
                  <websiteGroupNameLink
                    type="link"
                    className="brand-name-product"
                  >
                    {" "}
                    {this.state.productDetails.brand_name}
                  </websiteGroupNameLink>
                  <ProductName level={4}>
                    {this.state.productDetails.product_name} 
                  </ProductName>

                  {/* {this.state.stock ? (
                    <ProductOutOfStock code>In Stock</ProductOutOfStock>
                  ) : (
                      <ProductStock code>Out of Stock</ProductStock>
                    )} */}

                  {/* <ProductPriceRow type="flex" align="middle">
                    <ProductPriceLabel level={4}>
                      Price :{" "}
                      {this.state.minPrice !== 0 &&
                        this.state.minPrice !== 10000000000 &&
                        `${this.state.minPrice} - `}
                      {this.state.maxPrice}
                    </ProductPriceLabel> */}
                    {/* <ProductPrice level={4} delete>
                      80 AED
                  </ProductPrice> */}
                    {/* <ProductPriceDiscounted level={4}>
                      {this.state.productDetails.standard_price === ""
                        ? "- "
                        : this.state.productDetails.standard_price}
                    </ProductPriceDiscounted> */}
                  {/* </ProductPriceRow> */}

                  <ProductInfoText>
                    {/* {this.state.productDetails.product_description_amazon_uk} */}
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.description
                        }}
                      ></div>
                    }
                  </ProductInfoText>

                  {this.state.read_more && this.state.needOfReadMore && (
                    <a
                      style={{ color: "#1ABC9C" }}
                      onClick={this.handleReadMore}
                    >
                      read more
                    </a>
                  )}

                  {!this.state.read_more && this.state.needOfReadMore && (
                    <a
                      style={{ color: "#1ABC9C" }}
                      onClick={this.handleReadMore}
                    >
                      read less
                    </a>
                  )}

                  <ProductFeaturesContainer
                    gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}
                  >
                    <Text style = {{color : "white"}}>Features:</Text>
                    {this.state.productDetails.pfl_product_features &&
                      this.state.productDetails.pfl_product_features.map(
                        (e, index) => {
                          return (
                            <Col sm={24} key={index}>
                              <ProductFeatureRow type = "flex">
                                <Col span={1}>
                                  <ProductFeatureIcon type="check-circle" />
                                </Col>
                                <Col span={21}>
                                  <ProductFeatureInfo>{e}</ProductFeatureInfo>
                                </Col>
                              </ProductFeatureRow>
                            </Col>
                          );
                        }
                      )}
                  </ProductFeaturesContainer>


                  <ProductFeaturesContainer
                    gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}
                  >

                    <Col span = {24} style = {{paddingLeft : "0px"}}>
                      <Text style = {{color : "white"}}>Specification:</Text>
                    </Col>
                 
                      <Row>
                        <Col span = {5} style = {{backgroundColor : "#3C4853"}}>
                          
                          <div style = {{width : "100%" ,  height : "100%" , 
                          border : "1px solid #7263636b"
                          , padding : "14px"}}>
                          <Text >Dimensions</Text>  
                          </div>

                        </Col>

                        <Col span = {7} >
                          
                        <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b"
                          ,borderLeft : "none"
                        , padding : "14px"}}>
                          <Typography.Paragraph  ellipsis = {{rows : 1 , expandable : false}} style = {{
                            marginBottom : "0px"
                          }}>
                            {this.state.productDetails && ((this.state.productDetails.dimensions == "" || !this.state.productDetails.dimensions ) ? "NA" : this.state.productDetails.dimensions)}</Typography.Paragraph>  
                          </div>


                        </Col>

                        <Col span = {5} style = {{backgroundColor : "#3C4853"}}>
                          
                          <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b", borderLeft : "none" , padding : "14px"}}>
                          <Text >Material</Text>  
                          </div>

                        </Col>

                        <Col span = {7}>
                          
                        <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b" , borderLeft : "none" , padding : "14px"}}>
                        <Typography.Paragraph  ellipsis = {{rows : 1 , expandable : false}} style = {{
                            marginBottom : "0px"
                          }}>{this.state.productDetails && ((this.state.productDetails.material_type == "" || !this.state.productDetails.material_type) ? "NA" : this.state.productDetails.material_type)}</Typography.Paragraph>  
                          </div>

                        </Col>

                      </Row>
                      
                      <Row>
                        <Col span = {5} style = {{backgroundColor : "#3C4853"}}>
                          
                          <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b", borderTop : "none" , padding : "14px"}}>
                          <Text >Seller SKU</Text>  
                          </div>

                        </Col>

                        <Col span = {7}>
                          
                        <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b", borderTop : "none" , borderLeft : "none" , padding : "14px"}}>
                        <Typography.Paragraph  ellipsis = {{rows : 1 , expandable : false}} style = {{
                            marginBottom : "0px"
                          }}>{this.state.productDetails && ((this.state.productDetails.seller_sku == "" || !this.state.productDetails.seller_sku) ? "NA" : this.state.productDetails.seller_sku)}</Typography.Paragraph>  
                          </div>

                        </Col>

                        <Col span = {5} style = {{backgroundColor : "#3C4853"}}>
                          
                          <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b", borderTop : "none" , borderLeft : "none" , padding : "14px"}}>
                          <Text >Weight</Text>  
                          </div>

                        </Col>

                        <Col span = {7}>
                          
                        <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b", borderTop : "none" , borderLeft : "none" , padding : "14px"}}>
                        <Typography.Paragraph  ellipsis = {{rows : 1 , expandable : false}} style = {{
                            marginBottom : "0px"
                          }}>{this.state.productDetails && ((this.state.productDetails.weight == "" || !this.state.productDetails.weight) ? "NA" : this.state.productDetails.weight + "KG")}</Typography.Paragraph>  
                          </div>

                        </Col>
                      </Row>

                      <Row>
                      <Col span = {5} style = {{backgroundColor : "#3C4853"}}>
                          
                          <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b", borderTop : "none" , padding : "14px"}}>
                          <Text >Color</Text>  
                          </div>

                        </Col>

                        <Col span = {7}>
                          
                        <div style = {{width : "100%" ,  height : "100%" , border : "1px solid #7263636b", borderTop : "none" , borderLeft : "none" , padding : "14px"}}>
                        <Typography.Paragraph  ellipsis = {{rows : 1 , expandable : false}} style = {{
                            marginBottom : "0px"
                          }}>{this.state.productDetails && ((this.state.productDetails.color == "" || !this.state.productDetails.color) ? "NA" : this.state.productDetails.color)}</Typography.Paragraph>  
                          </div>

                        </Col>
                      </Row>
                       
                       
                  </ProductFeaturesContainer>
                        
                </ProductDetailsRight>
              </Col>
              {/* <Col sm={12}>
                <ProductDetailsTable
                  scroll={{ x: true }}
                  dataSource={this.warehousedataSource}
                  bordered
                  pagination={false}
                  style={{ marginBottom: "20px" }}
                >
                  <Column
                    title="Warehouse Code"
                    dataIndex="warehouse_code"
                    key="warehouse_code"
                  />
                  <Column title="Export Rate" dataIndex="EX_EA" key="EX_EA" />
                  <Column
                    title="Intercompany Rate"
                    dataIndex="IC_EA"
                    key="IC_EA"
                  />
                  <Column title="Outdoor Rate" dataIndex="OD_EA" key="OD_EA" />
                  <Column title="Retail Rate" dataIndex="RET_EA" key="RET_EA" />
                  <Column title="Quantity" dataIndex="qty" key="qty" />
                </ProductDetailsTable>
              </Col>
              <Col sm={24}>
                <ProductDetailsTable
                  scroll={{ x: true }}
                  dataSource={channelwizedataSource}
                  columns={channelwizecolumns}
                  pagination={false}
                  bordered
                />
              </Col> */}
            </ProductDetailsContainer>
          </ProductDetailsPageContainer>
        ) : (
            <React.Fragment>
              <Row
                type="flex"
                align="middle"
                justify="center"
                style={{ width: "100%", height: "90%" }}
              >
                <Ellipsis color="#1ABC9C" size={100} />
              </Row>
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }
}
