import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Card, Tooltip, Avatar, Button, Input, Row, Col, Select, Checkbox, Typography, Upload, Icon, AutoComplete, List, Spin, message } from "antd";
import { Link, Redirect } from "react-router-dom";


import "./product-page-export-modal.scss";

import axios from "axios";

import Fuse from "fuse.js";

import styled from "styled-components";
import ExportCards from "../../cards/ExportCards";
import ChannelCard from "../../cards/ChannelCard";
import TemplateCard from "../../cards/TemplateCard";

import isDev from "../../../../server";


const serverUrl = isDev();
const { Option } = Select;
const { Text, Paragraph } = Typography;
const { Search } = Input

const UploadBtn = styled(Upload)`
 && {
    .ant-upload {
        height:"15px !important"
    }
  }
`

const ExportBtn = styled(Button)`
  && {
    background-color: #4938d7;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #4938d7;
      color: white;
      border: none;
    }
  }
`;

class ProductPageExportModal extends Component {
    state = {
        visible: false,
        visible1: false,
        activeIndex: null,
        activeIndexTemplates : null,
        selected_list: [],
        step_2_product_list: [],
        checked_attributes: new Set(),
        attributes_list: [],
        fileList: [],
        loading: false,
        options: [],
        searchedAttrList: [],
        searchString: "",
        searchStringForStep3: "",
        createTemplateModal : false,
        createTemplateModalInput : "",
        createTemplateLoading : false,
        templateList : [],
    };

    handelCheck = (e, item) => {

        if(this.state.activeIndexTemplates != null && e != false)
            {
                this.setState({
                    activeIndexTemplates : null
                })
            }

        if (this.state.checked_attributes.has(item)) {
            let tempSet = this.state.checked_attributes;
            tempSet.delete(item);
            this.setState((prevState) => {
                return {
                    checked_attributes: tempSet
                }
            })
        }
        else this.setState((prevState) => {
            return {
                checked_attributes: prevState.checked_attributes.add(item)
            }
        })
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
        this.fetchDataPoint();
        this.fetchExportTemplate();   
    };

    addToStepTwoProductList = (item) => {
        this.setState({ step_2_product_list: [...this.state.step_2_product_list, item] });
    }

    addAfterSerach = (e) => {
        this.setState({ searchString: "" });
        this.addToStepTwoProductList(this.state.options[e]);
    }

    handleOk = e => {

        this.setState({ loading: true });



        let product_uuid_list = [];

        for (let ind in this.state.step_2_product_list) {
            let item = this.state.step_2_product_list[ind];
            product_uuid_list.push(item.uuid);
        }

        let data = {
            data_point_list: [...this.state.checked_attributes],
            product_uuid_list
        };
        fetch(`${serverUrl}/download-bulk-export/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {

                if (data.status == 200) {
                    window.open(data.file_path, '_blank');
                    this.setState({
                        visible: false,
                        visible1: false,
                        activeIndex: null,
                        activeIndexTemplates : null,
                        selected_list: [],
                        step_2_product_list: [],
                        checked_attributes: new Set(),
                        fileList: [],
                        loading: false,
                        options: [],
                        searchedAttrList: [],
                        searchString: "",
                        searchStringForStep3: ""
                    });
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.info("Something Went Wrong!");
                    this.setState({ loading: false });
                }
            });
    };

    handleCancel = e => {

        this.setState({
            visible: false,
            visible1: false,
            activeIndex: null,
            activeIndexTemplates : null,
            selected_list: [],
            step_2_product_list: [],
            checked_attributes: new Set(),
            fileList: [],
            loading: false,
            options: [],
            searchedAttrList: [],
            searchString: "",
            searchStringForStep3: ""
        });

    };
    showModal1 = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk1 = e => {

        this.setState({
            visible: false,
            visible1: false,
            activeIndex: null,
            activeIndexTemplates : null,
            selected_list: [],
            step_2_product_list: [],
            checked_attributes: new Set(),
            fileList: [],
            loading: false,
            options: [],
            searchedAttrList: [],
            searchString: "",
            searchStringForStep3: ""
        });
    };

    handleCancel1 = e => {

        this.setState({
            visible: false,
            visible1: false,
            activeIndex: null,
            activeIndexTemplates : null,
            selected_list: [],
            step_2_product_list: [],
            checked_attributes: new Set(),
            fileList: [],
            loading: false,
            options: [],
            searchedAttrList: [],
            searchString: "",
            searchStringForStep3: ""
        });
    };
    
    handleBorder = index => {
        this.setState({ activeIndex: index });
    };


    updateSelection = () => {
        let data_point_list_selected = this.state.templateList[this.state.activeIndexTemplates].data_point_list;
        for(let i = 0;i<data_point_list_selected.length ; i++)
        {
            this.handelCheck(false , data_point_list_selected[i].variable);
        }
    }

    handleBorderTemplate = (index) => {
        if(this.state.activeIndexTemplates == index)
        {
            this.setState({
                activeIndexTemplates : null,
                checked_attributes : new Set()
            })
        }
        else
        this.setState({ activeIndexTemplates : index , checked_attributes : new Set() } , () =>  this.updateSelection());
    }

    handleSearch = value => {
        this.setState({ searchString: value })
        let data = {
            search_string: value
        };
        fetch(`${serverUrl}/search-bulk-export/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {

                if (data.status === 200) {
                    this.setState({ options: data.product_list });
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else
                {
                    message.error("Something went wrong!");
                }
            });
    }

    uploadFile = file => {
        let formData = new FormData();
        formData.append("import_file", file);
        this.setState({ loading: true });
        axios
            .post(`${serverUrl}/upload-bulk-export/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            })
            .then(res => {

                // this.setState(prevState => ({
                //     step_2_product_list: prevState.uploadedImageCounter + 1
                // }));
                if (res.data.status === 200) {
                    this.setState({ step_2_product_list: res.data.product_list })
                }
                else if(res.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Internal Server Error!");
                }
                this.setState({ loading: false });
            })
            .catch(err => {

                this.setState({ loading: false });
                message.error("Internal Server Error!");
            });
    }

    handleSelectFile = file => {

        this.setState({ fileList: [file] });
        this.uploadFile(file);
    }

    handleUnselectFile = file => {
        this.setState({ fileList: [] });
    }


    fetchDataPoint = () => {
        let data = {

        };
        fetch(`${serverUrl}/fetch-data-points/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {

                if (data.status === 200) {
                    this.setState({ attributes_list: data.data_point_list });
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Internal Server Error!");
                }
            });

     }

     fetchExportTemplate = () => {
         this.setState({
             fetchExportTemplateLoading : true
         })
         let data = {

         }
        fetch(`${serverUrl}/fetch-export-templates/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    fetchExportTemplateLoading : false,
                })
                if (data.status === 200) {
                    this.setState({ templateList : data.export_template_list });
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Internal Server Error!");
                }
            });    
     }

    componentDidMount = () => {

            this.fetchDataPoint();
            this.fetchExportTemplate();   
        
    }

    handleRemoveProduct = (e, item) => {

        this.setState({ step_2_product_list: this.state.step_2_product_list.filter(e => e.uuid !== item.uuid) });
    }


    searchAttrList = (value) => {
        let options = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            minMatchCharLength: 1,
            keys: [
                "name",
            ]
        };
        let fuse = new Fuse(this.state.attributes_list, options); // "list" is the item array
        let result = fuse.search(value);

        let aL = [];
        for (let ind in result) {
            aL.push(result[ind]["item"]);
        }
        this.setState({ searchedAttrList: aL });
    }

    sendToDownload = () => {
        window.open('https://api.omnycomm.com/files/csv/bulk_export_sample_template.xlsx')
    }

    createTemplate = () => {
        this.setState({
            createTemplateModal : true,
        })
    }

    CreateTemplateWithName = () => {

        this.setState({
            createTemplateLoading : true,
        })
       
        let data = {
            name : this.state.createTemplateModalInput,
            data_point_list: [...this.state.checked_attributes],
        };
        fetch(`${serverUrl}/create-export-template/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    createTemplateModal : false,
                    createTemplateLoading : false,
                })            

                if (data.status === 200) {
                   message.success("Template is created!");
                   this.fetchExportTemplate();
                }
                else if(data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Internal Server Error!");
                }
            });
    }


    render() {

        let sortedAL = (this.state.searchedAttrList.length > 0 ? this.state.searchedAttrList : this.state.attributes_list);



        return (
            <div className="product-export-modal-container">

                <ExportBtn
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={this.showModal}
                    block
                >
                    Export
                 </ExportBtn>


                 <Modal
                    destroyOnClose={true}
                    title="Create Template"
                    visible={this.state.createTemplateModal}
                    onOk={() => {
                        this.setState({
                            createTemplateModal : false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            createTemplateModal : false
                        })
                    }}
                    className="product-export-modal"

                    width={450}
                    footer={[
                        <Button key="submit" type="primary" loading = {this.state.createTemplateLoading} onClick={this.CreateTemplateWithName}>
                            Create
                        </Button>,
                    ]}
                >
                    <Input placeholder = "Template name" value = {this.state.createTemplateModalInput} onChange = {(e) => {
                        this.setState({
                            createTemplateModalInput : e.target.value
                        })
                    }} />    
                </Modal>                


                <Modal
                    destroyOnClose={true}
                    title="Dynamic Export"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className="product-export-modal"
                    style={{display: "flex", justifyContent: "center"}}
                    width={900}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                         <Button key="createTemplate" disabled = {this.state.checked_attributes.size == 0 || this.state.activeIndexTemplates != null} onClick={this.createTemplate}>
                         Create Template
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            Export
                        </Button>,
                    ]}
                >
                    {this.state.loading ? <Row type="flex" justify="center" align="middle"><Spin /></Row> :
                        <Row type="flex" align="middle" gutter={[16, 16]}>

                            <Col span={24} >
                                <Row>
                                    <Col span={24}>
                                        <Text className="text-color3">
                                            Step 1 : Upload an excel containing products.
                                                        </Text>
                                    </Col>
                                </Row>
                                <Row className="custom-card-2">
                                    <Col span={24}>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Text>Click </Text>
                                                <a onClick={this.sendToDownload}>
                                                    here
                                                </a>
                                                <Text> to download template</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <UploadBtn beforeUpload={this.handleSelectFile} onRemove={this.handleUnselectFile} fileList={this.state.fileList}>


                                                    <Button type="primary" disabled={this.state.fileList.length !== 0} className="upload-btn">
                                                        Click to Upload
                                                    </Button>


                                                </UploadBtn>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} >
                                <Row>
                                    <Col span={24}>
                                        <Text className="text-color3">
                                            Step 2 : Verify your products.
                                                        </Text>
                                    </Col>
                                </Row>
                                <Row className="custom-card-2">
                                    <Col span={24}>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <AutoComplete onSelect={this.addAfterSerach} value={this.state.searchString} style={{ width: "100%", marginBottom: "10px" }} onSearch={this.handleSearch} placeholder="Add products by searching here.">
                                                    {this.state.options.map((item, index) => {
                                                        return (
                                                            <Option key={index}> <Avatar shape="square" src={item.image_url} style={{ marginRight: "5px" }} /> {item.name}</Option>
                                                        )
                                                    })}
                                                </AutoComplete>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} style={{ maxHeight: "30vh", overflow: "scroll" }}>
                                            {this.state.step_2_product_list.map((item, index) => {
                                                return (
                                                    <Col key={index} span={12}>

                                                        <Card bordered className="categoryproductcard">
                                                            <Row gutter={[16, 0]}>
                                                                <Col sm={5}>
                                                                    <Avatar shape="square" size={64} src={item.image_url} />
                                                                </Col>
                                                                <Col sm={19} style={{ paddingLeft: "0px" }}>
                                                                    <Tooltip title="Geepas 1200W Compact Food Processor Blender" >
                                                                        <Paragraph className="text-color3" ellipsis={{ rows: 2, expandable: false }} style={{ marginBottom: "0px", paddingRight: "15px" }}>
                                                                            {item.name}
                                                                        </Paragraph>
                                                                    </Tooltip>
                                                                    <Text className="text-color3">
                                                                        ID :
                                                                                </Text>
                                                                    <Text>
                                                                        {" "}{item.product_id}
                                                                    </Text>
                                                                </Col>
                                                            </Row>
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "2px",
                                                                    right: "5px",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={(e) => this.handleRemoveProduct(e, item)}
                                                            >
                                                                X
                                                                </div>
                                                        </Card>
                                                    </Col>

                                                )
                                            })}




                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col span={24} >
                                
                                                <Row className="custom-card-2">
                                                    <Col span={24}>
                                                        <Row type="flex" align="middle" gutter={[8, 8]}>
                                                            <Col span={8} onClick={() => this.handleBorder("1")}>
                                                                <ChannelCard activeIndex={this.state.activeIndex} index="1" />
                                                            </Col>
                                                            <Col span={8} onClick={() => this.handleBorder("2")}>
                                                                <ChannelCard activeIndex={this.state.activeIndex} index="2" />
                                                            </Col>
                                                            <Col span={8} onClick={() => this.handleBorder("3")} >
                                                                <ChannelCard activeIndex={this.state.activeIndex} index="3" />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col> */}


                            {/* <Col span={24} >
                                <Row>
                                    <Col span={24}>
                                        <Text className="text-color3">
                                            Step 3 : Templates
                                        </Text>
                                    </Col>
                                </Row>
                                
                                <Row className="custom-card-2">
                                    <Col span={24}>
                                        <Row type="flex" align="middle" gutter={[8, 8]}>
                                            <Col span={4} onClick={() => this.handleBorder("1")}>
                                                <TemplateCard activeIndex={this.state.activeIndex} name = "Noon" index="1" />
                                            </Col>
                                            <Col span={4} onClick={() => this.handleBorder("2")}>
                                                <TemplateCard activeIndex={this.state.activeIndex} name = "Quick" index="2" />
                                            </Col>
                                            <Col span={4} onClick={() => this.handleBorder("3")} >
                                                <TemplateCard activeIndex={this.state.activeIndex} name = "Detailed" index="3" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col> */}

                            <Col span={24} >
                                <Row>
                                    <Col span={24}>
                                        <Text className="text-color3">
                                            Step 3 : Select template OR attributes to export.
                                                        </Text>
                                    </Col>
                                </Row>

                                <Row className="custom-card-2">
                                    <Col span={24}>
                                        <Row type="flex" align="middle" style = {{maxHeight : "150px" , overflow : "scroll"}} gutter={[8, 8]}>
                                          
                                            {
                                              !this.state.fetchExportTemplateLoading && (this.state.templateList.length > 0 ? this.state.templateList.map((item , index) => {
                                                    return   <Col span={4} onClick={() => this.handleBorderTemplate(index)}>
                                                        <TemplateCard activeIndex={this.state.activeIndexTemplates} uuid = {item.uuid} fetchTemplates = {this.fetchExportTemplate} name = {item.name} index={index} />
                                                    </Col>
                                                }) : 
                                                <Col span={24}>
                                                        <Text>No templates created yet</Text>
                                                </Col>)
                                            }
                                            {
                                                this.state.fetchExportTemplateLoading &&
                                                <Spin size = "small" />
                                            }
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="custom-card-2">
                                    <Col span={24}>

                                        <Row gutter={[16, 16]}>
                                            <Input
                                                placeholder="input search text"
                                                onChange={value => { this.setState({ searchStringForStep3: value.target.value }); this.searchAttrList(value.target.value) }}
                                                value={this.state.searchStringForStep3}
                                                style={{ width: 200 }}
                                            />
                                        </Row>
                                        <br />

                                        <Row gutter={[8, 8]} style={{ maxHeight: "30vh", overflow: "scroll" }}>
                                            {
                                                sortedAL.map((item, index) => {
                                                    return (
                                                        <Col span={8} key={index} >
                                                            <Row>
                                                                <Col span={2}>
                                                                    <Checkbox checked={this.state.checked_attributes.has(item.variable)} onClick={(e) => this.handelCheck(e, item.variable)} className="margin-right" />
                                                                </Col>
                                                                <Col span={20}>
                                                                    <Tooltip title={item.name}>
                                                                        <Typography.Paragraph ellipsis={{ rows: 1, expandable: false }}>
                                                                            {item.name}
                                                                        </Typography.Paragraph>
                                                                    </Tooltip>
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                        <Row type="flex" align="middle" >
                                        </Row>
                                        <Row type="flex" align="middle">
                                        </Row>
                                        {/* <Row type="flex" align="middle" style={{ marginTop: "10px" }} >
                                                            <Button type="primary" className="margin-right" onClick={this.showModal1}>
                                                                Create New Template
                                                            </Button>
                                                            <Modal
                                                                title="Basic Modal"
                                                                visible={this.state.visible1}
                                                                onOk={this.handleOk1}
                                                                onCancel={this.handleCancel1}
                                                            >
                                                                <p>Some contents...</p>
                                                                <p>Some contents...</p>
                                                                <p>Some contents...</p>
                                                            </Modal>
                                                            <Button>
                                                                Save Existing Template
                                                            </Button>
                                                        </Row> */}

                                    </Col>
                                </Row>
                            </Col>



                        </Row>
                    }

                </Modal>
            </div>
        );
    }
}



export default connect()(ProductPageExportModal);
