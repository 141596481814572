import React, {Component} from "react";
import TitleWithBreadCrumb from "views//components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {EditProductPageWrapper, EbppCol} from "views/Edit-Base-Product-Page/styled-components-library";
import {
    Row,
    Col,
    Button,
    Affix,
    Tooltip,
    message,
    Typography,
    Select,
    Switch,
    Icon,
    Input,
    DatePicker,
    Radio,
    Dropdown,
    Menu,
} from "antd";
import InputField from "views/components/datainputs/InputField";
import FormHeader from "views/components/datainputs/FormHeader";
import ReactQuill from "react-quill";
import "views/Edit-Base-Product-Page/edit-base-product-page.scss";
import {Link, Redirect} from "react-router-dom";
import moment from "moment";

//validator
import SimpleReactValidator from "simple-react-validator";

import isDev from "../../server";
import EditorBox from "views/components/EditorBox";

const {RangePicker} = DatePicker;
const {Text} = Typography;
const {Option} = Select;
const serverUrl = isDev();

class EditEcommerceProductPage extends Component {
    rangePickerDateFormat = "YYYY-MM-DD HH:mm:ss";

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Field is required",
                integer: "Must be number",
                default: "Validation has failed!", // will override all messages
            },
            validators: {},
        });
        this.state = {
            categories: null,
            category: null,
            subCategory: null,
            loading: false,
            dealshub_price_permission: false,
            dealshub_content_permission: false,
            dealshub_stock_permission: false,
            is_english_language: true,
            product_is_promotional: false,
            promotionStartDate: null,
            promotionEndDate: null,
            promotionalTag: null,
            now_price_list: [],
            promotional_price_list: [],
            moq_list: [],
            selectedMoq: {value: null, id: "0"},
            selectedNowPrice: {value: null, id: "0"},
            selectedPromotionalPrice: {value: null, id: "0"},
            category_rows: [],
            other_super_category: [],
            other_category: [],
            other_sub_category: []
        };
    }

    fetchCategories = (callback) => {
        let data = {};
        fetch(`${serverUrl}/fetch-all-categories/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status !== 200) message.error("Internal Server Error!");
                else {
                    this.setState(
                        {
                            super_categories: data.super_category_list,
                        },
                        () => callback()
                    );
                    this.setMultipleCategories()
                }
            });
    };

    fetchProductDetails = () => {
        let data = {
            product_uuid: this.props.id,
            locationGroupUuid: this.props.locationGroupUuid,
            b2b: this.props.b2b === "b2b" ? true : false,
        };
        fetch(`${serverUrl}/fetch-dealshub-product-details/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status !== 200) message.error("Internal Server Error!");
                else {
                    console.clear();
                    console.log(data);

                    this.fetchCategories(() =>
                        this.setCategory(data.super_category_uuid, data.category_uuid, data.sub_category_uuid)
                    );
                    let now_price_list = [];
                    let promotional_price_list = [];
                    let moq_list = [];
                    if (this.props.b2b === "b2b") {
                        now_price_list = [
                            data.now_price_cohort1,
                            data.now_price_cohort2,
                            data.now_price_cohort3,
                            data.now_price_cohort4,
                            data.now_price_cohort5,
                        ];
                        promotional_price_list = [
                            data.promotional_price_cohort1,
                            data.promotional_price_cohort2,
                            data.promotional_price_cohort3,
                            data.promotional_price_cohort4,
                            data.promotional_price_cohort5,
                        ];
                        moq_list = [
                            data.moq_cohort1,
                            data.moq_cohort2,
                            data.moq_cohort3,
                            data.moq_cohort4,
                            data.moq_cohort5,
                        ];
                    }
                    this.setState({
                        was_price: data.was_price,
                        now_price: data.now_price,
                        now_price_list,
                        promotional_price_list,
                        moq_list,
                        promotional_price: data.promotional_price,
                        stock: data.stock,
                        seller_sku: data.seller_sku,
                        product_name: data.product_name,
                        product_name_ar: data.product_name_ar,
                        product_id: data.product_id,
                        category_uuid: data.category_uuid,
                        sub_category_uuid: data.sub_category_uuid,
                        is_cod_allowed: data.is_cod_allowed,
                        is_promo_restricted: data.is_promo_restricted,
                        is_new_arrival: data.is_new_arrival,
                        is_on_sale: data.is_on_sale,
                        is_featured: data.is_featured,
                        is_bestseller: data.is_bestseller,
                        is_published: data.is_published,
                        dealshub_price_permission: data.dealshub_price_permission,
                        dealshub_content_permission: data.dealshub_content_permission,
                        dealshub_stock_permission: data.dealshub_stock_permission,
                        allowed_qty: data.allowed_qty,
                        product_description: data.product_description,
                        product_description_ar: data.product_description_ar,
                        product_description_initial: data.product_description,
                        url: data.url,
                        tags: data.search_keywords,
                        product_is_promotional: data.product_is_promotional,
                        is_promotional: data.is_promotional,
                        promotionalTag: data.promo_tag_name,
                        promotionStartDate: data.promo_start_time,
                        promotionEndDate: data.promo_end_time,
                        showEditor: true,
                        moq: data.moq,
                        selectedMoq: {value: data.moq, id: "0"},
                        selectedNowPrice: {value: data.now_price, id: "0"},
                        selectedPromotionalPrice: {value: data.promotional_price, id: "0"},
                        additional_category_list: data.additional_sub_categories,
                        //category_rows: data.additional_sub_categories.length
                    });
                    const N = data.additional_sub_categories.length;
                    const arr = Array.from({length: N}, (_, index) => index + 1);
                    if (N !== 0) {
                        this.setState({
                            category_rows: arr
                        });
                    }
                }
            });
    };
    fetchProductDetails;

    setMultipleCategories = () => {
        this.state.additional_category_list.length > 0 ? this.state.additional_category_list.map((item, id) => {
                const myUuid = item.super_category_uuid;
                this.setState({
                    other_super_category: [...this.state.other_super_category, {
                        index: id + 1,
                        value: this.state.super_categories.filter((obj) => obj.super_category_uuid === myUuid)[0]
                    }]
                })
                this.setState({
                    other_category: [...this.state.other_category, {
                        index: id + 1,
                        value: this.state.other_super_category[id].value.category_list.filter((obj) => obj.category_uuid === item.category_uuid)[0]
                    }]
                })
                this.setState({
                    other_sub_category: [...this.state.other_sub_category, {
                        index: id + 1,
                        value: this.state.other_category[id].value.sub_category_list.filter((obj) => obj.sub_category_uuid === item.sub_category_uuid)[0]
                    }]
                })
            }) :
            this.setState({
                other_super_category: [{
                    index: 1,
                    value: {}
                }],
                other_category: [{
                    index: 1,
                    value: {}
                }],
                other_sub_category: [{
                    index: 1,
                    value: {}
                }]
            })
    }

    setCategory = (superCatUuid, catUuid, subCatUuid) => {
        console.log(catUuid, subCatUuid, superCatUuid, "dsdsdsd");
        if (
            superCatUuid == "" ||
            subCatUuid == "" ||
            catUuid == "" ||
            superCatUuid == undefined ||
            subCatUuid == undefined ||
            catUuid == undefined
        ) {
            this.setState({
                super_category: null,
            });
            return;
        }

        let superCatInd, catInd, subCatind;
        for (let i = 0; i < this.state.super_categories.length; i++) {
            if (this.state.super_categories[i].super_category_uuid == superCatUuid) {
                superCatInd = i;
                for (let j = 0; j < this.state.super_categories[i].category_list.length; j++) {
                    if (this.state.super_categories[i].category_list[j].category_uuid == catUuid) {
                        catInd = j;
                        for (
                            let k = 0;
                            k < this.state.super_categories[i].category_list[j].sub_category_list.length;
                            k++
                        ) {
                            if (
                                this.state.super_categories[i].category_list[j].sub_category_list[k]
                                    .sub_category_uuid == subCatUuid
                            ) {
                                subCatind = k;
                                break;
                            }
                        }
                    }
                }
            }
        }
        console.log(this.state, superCatInd, "dsdsdsd");
        this.setState(
            {
                super_category: this.state.super_categories[superCatInd],
            },
            () => {
                console.log(this.state.super_category, "dsdsdsd");
                this.setState(
                    {
                        category: this.state.super_category.category_list[catInd],
                    },
                    () => {
                        this.setState({
                            subCategory: this.state.category.sub_category_list[subCatind],
                        });
                    }
                );
            }
        );
    };

    formats = ["font", "size", "bold", "italic", "underline", "list", "bullet", "align", "color", "background","link"];

    modules = {
        toolbar: [
            [{font: []}],
            [{size: ["small", false, "large", "huge"]}],
            ["bold", "italic", "underline"],
            [{list: "ordered"}, {list: "bullet"}],
            [{align: []}],
            [{color: ["#3DC9A9"]}, {background: ["#3DC9A9"]}],
            ['link']
        ],
    };

    handelChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        this.validator.showMessages();
    };

    onChangeSuperCategory = (e) => {
        this.setState({
            super_category: this.state.super_categories[e],
            category: null,
            subCategory: null,
        });
    };

    onChangeCategory = (e) => {
        this.setState({
            category: this.state.super_category.category_list[e],
            subCategory: null,
        });
    };

    onChangeSubCategory = (e) => {
        this.setState({
            subCategory: this.state.category.sub_category_list[e],
        });
    };

    onChangeOtherSuperCategory = (e, i) => {
        console.log(this.state.super_categories[e])
        let flag = false;
        this.setState({
            other_super_category: this.state.other_super_category.map((item) => {
                if (item.index === i) {
                    flag = true
                    console.log("holaa")
                    return {
                        index: item.index,
                        value: this.state.super_categories[e]
                    }
                } else return item
            })
        })
        this.setState({
            other_category: this.state.other_category.map((item) => {
                if (item.index === i) {
                    return {
                        index: item.index,
                        value: {}
                    }
                } else return item
            })
        })
        this.setState({
            other_sub_category: this.state.other_sub_category.map((item) => {
                if (item.index === i) {
                    return {
                        index: item.index,
                        value: {}
                    }
                } else return item
            })
        })
        if (!flag) {
            this.setState({
                other_super_category: [...this.state.other_super_category, {
                    index: i,
                    value: this.state.super_categories[e]
                }]
            })
        }
    }

    onChangeOtherCategory = (e, i) => {

        console.log(this.state.other_super_category, "my state")

        let otherValue = this.state.other_super_category.filter((other) => other.index === i)

        console.log(otherValue, "my value", e)
        let flag = false;
        this.setState({
            other_category: this.state.other_category.map((item) => {
                if (item.index === i) {
                    flag = true;
                    return {
                        index: item.index,
                        value: otherValue[0].value.category_list[e]
                    }
                } else return item
            })
        })
        this.setState({
            other_sub_category: this.state.other_sub_category.map((item) => {
                if (item.index === i) {
                    return {
                        index: item.index,
                        value: {}
                    }
                } else return item
            })
        })
        if (!flag) {
            this.setState({
                other_category: [...this.state.other_category, {
                    index: i,
                    value: otherValue[0].value.category_list[e]
                }]
            })
        }
    }

    onChangeOtherSubCategory = (e, i) => {
        console.log(this.state.other_category, "my state")
        let otherSub = this.state.other_category.filter((other) => other.index === i)
        let flag = false;
        this.setState({
            other_sub_category: this.state.other_sub_category.map((item) => {
                if (item.index === i) {
                    flag = true;
                    return {
                        index: item.index,
                        value: otherSub[0].value.sub_category_list[e]
                    }
                } else return item
            })
        })

        if (!flag) {
            this.setState({
                other_sub_category: [...this.state.other_sub_category, {
                    index: i,
                    value: otherSub[0].value.sub_category_list[e]
                }]
            })
        }
    }

    deleteCategoryRow = (i) => {
        this.setState({
            other_super_category: this.state.other_super_category.filter((obj) => obj.index !== i),
            other_category: this.state.other_category.filter((obj) => obj.index !== i),
            other_sub_category: this.state.other_sub_category.filter((obj) => obj.index !== i)
        })
    }

    handleBundleProduct = (e) => {
        this.setState({
            is_cod_allowed: e,
        });
    };

    handlePromoRestriction = (e) => {
        this.setState({
            is_promo_restricted: e,
        });
    };

    handleNewArrival = (e) => {
        this.setState({
            is_new_arrival: e,
        });
    };
    handleFeaturedProduct = (e) => {
        this.setState({
            is_featured: e,
        });
    };
    handleBestSellerProduct = (e) => {
        this.setState({
            is_bestseller: e,
        });
    };

    handleSaleProduct = (e) => {
        this.setState({
            is_on_sale: e,
        });
    };

    handlePublish = () => {
        let url = "dealshub/unpublish-dealshub-product/";
        if (!this.state.is_published) url = "dealshub/publish-dealshub-product/";

        this.setState({
            publishLoading: true,
        });

        let data = {
            product_uuid: this.props.id,
        };
        //.log(data);
        fetch(`${serverUrl}/${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status == 200) {
                    if (!this.state.is_published) message.success("Product Is Published");
                    else message.success("Product Is UnPublished");

                    this.setState({
                        is_published: !this.state.is_published,
                        publishLoading: false,
                    });
                } else message.error("Something went wrong during update");
            });
    };

    handelSave = () => {
        if (!this.validator.allValid()) {
            message.info("Fill Required Details");
            return;
        }

        if (this.state.is_promotional && this.state.promotional_price === 0) {
            message.error("Promotional Price cannot be 0");
            return;
        }

        let data = {
            locationGroupUuid: this.props.locationGroupUuid,
            product_uuid: this.props.id,
            was_price: this.state.was_price,
            now_price: Number(this.state.now_price),
            promotional_price: Number(this.state.promotional_price),
            stock: this.state.stock,
            seller_sku: this.state.seller_sku,
            product_name: this.state.product_name,
            product_name_ar: this.state.product_name_ar,
            product_id: this.state.product_id,
            super_category_uuid: this.state.super_category ? this.state.super_category.super_category_uuid : "",
            category_uuid: this.state.category ? this.state.category.category_uuid : "",
            sub_category_uuid: this.state.subCategory ? this.state.subCategory.sub_category_uuid : "",
            is_cod_allowed: this.state.is_cod_allowed,
            is_promo_restricted: this.state.is_promo_restricted,
            is_published: this.state.is_published,
            allowed_qty: this.state.allowed_qty,
            product_description: this.state.product_description,
            product_description_ar: this.state.product_description_ar,
            is_new_arrival: this.state.is_new_arrival,
            is_on_sale: this.state.is_on_sale,
            is_featured: this.state.is_featured,
            is_bestseller: this.state.is_bestseller,
            search_keywords: this.state.tags,
            promotion: {
                promotional_tag: this.state.promotionalTag,
                start_date: this.state.promotionStartDate,
                end_date: this.state.promotionEndDate,
            },
            is_promotional: this.state.product_is_promotional,
            url: this.state.url,
            moq: Number(this.state.moq),
            other_sub_category_uuid_list: this.state.other_sub_category.map((item) => item.value.sub_category_uuid)
        };

        if (this.props.b2b === 'b2b') {
            for (let i = 1; i < 6; i++) {
                data[`now_price_cohort${i}`] = Number(this.state.now_price_list[i - 1])
                data[`promotional_price_cohort${i}`] = Number(this.state.promotional_price_list[i - 1])
                data[`moq_cohort${i}`] = Number(this.state.moq_list[i - 1])
            }
        }

        this.setState({
            loading: true,
        });
        console.clear();
        console.log(data, 'this is data');
        fetch(`${serverUrl}/save-dealshub-product-details/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status !== 200) message.error("Internal Server Error!");
                else {
                    this.setState({
                        loading: false,
                    });
                    message.success("Successfully Saved!");
                }
            });
    };

    handleChangeSearchTags = (tags) => {
        this.setState({
            tags,
        });
    };

    onBlur = () => {
    };

    onFocus = () => {
    };

    onSearch = (val) => {
    };

    componentDidMount = () => {
        this.fetchProductDetails();
    };

    getEditorText = (value) => {
        this.setState({
            product_description: value,
        });
    };

    handleLanguageSwitch = (e) => {
        this.setState({
            is_english_language: e,
        });
    };

    clearPromotionalAttributes = () => {
        this.setState({
            promotionStartDate: null,
            promotionEndDate: null,
            promotionalTag: null,
        });
    };

    setPromotionActive = (newPromotionStatus) => {
        this.setState(
            {
                product_is_promotional: newPromotionStatus,
                is_promotional: newPromotionStatus,
            },
            () => {
                if (!newPromotionStatus) {
                    this.validator.purgeFields()
                    this.clearPromotionalAttributes();
                }
            }
        );
    };

    changePromotionRange = (dateRangeMoment, dateRangeStrings) => {
        this.setState({
            promotionStartDate: dateRangeStrings[0] === "" ? null : dateRangeStrings[0].replace(" ", "T").concat("Z"),
            promotionEndDate: dateRangeStrings[1] === "" ? null : dateRangeStrings[1].replace(" ", "T").concat("Z"),
        });
        this.validator.showMessages();
    };

    changePromotionTag = (event) => {
        this.setState({
            promotionalTag: event.target.value === "" ? null : event.target.value,
        });
        this.validator.showMessages();
    };

    handleSelectedChange = (e) => {
        console.clear()
        console.clear()

        if (e.target.name === "now price") {
            console.clear()
            console.log(this.state.selectedNowPrice, e.target.value)
            if (this.state.selectedNowPrice.id === "0")
                this.setState({
                    now_price: e.target.value,
                    selectedNowPrice: {...this.state.selectedNowPrice, value: e.target.value},
                });
            else
                this.setState({
                    now_price_list: this.state.now_price_list.map((item, index) =>
                        index + 1 === Number(this.state.selectedNowPrice.id) ? e.target.value : item
                    ),
                    selectedNowPrice: {...this.state.selectedNowPrice, value: e.target.value},
                });
        } else if (e.target.name === "promotional price") {
            if (this.state.selectedPromotionalPrice.id === "0")
                this.setState({
                    promotional_price: e.target.value,
                    selectedPromotionalPrice: {...this.state.selectedPromotionalPrice, value: e.target.value},
                });
            else
                this.setState({
                    promotional_price_list: this.state.promotional_price_list.map((item, index) =>
                        Number(this.state.selectedPromotionalPrice.id) === index + 1 ? e.target.value : item
                    ),
                    selectedPromotionalPrice: {...this.state.selectedPromotionalPrice, value: e.target.value},
                });
        } else if (e.target.name === "moq") {
            if (this.state.selectedMoq.id === "0")
                this.setState({
                    moq: e.target.value,
                    selectedMoq: {...this.state.selectedMoq, value: e.target.value},
                });
            else
                this.setState({
                    moq_list: this.state.moq_list.map((item, index) =>
                        index + 1 === Number(this.state.selectedMoq.id) ? e.target.value : item
                    ),
                    selectedMoq: {...this.state.selectedMoq, value: e.target.value},
                });
        }
    };

    menu = (type) => {
        const list =
            type === "now price"
                ? this.state.now_price_list
                : type === "moq"
                ? this.state.moq_list
                : this.state.promotional_price_list;
        const defaultOne =
            type === "now price"
                ? this.state.now_price
                : type === "moq"
                ? this.state.moq
                : this.state.promotional_price;
        return (
            <Menu
                onClick={(e) => {
                    const val = e.key === "0" ? defaultOne : list[e.key - 1];
                    console.clear()
                    console.log(e.key, val)
                    this.setState(
                        type === "now price"
                            ? {
                                selectedNowPrice: {value: val, id: e.key},
                            }
                            : type === "moq"
                            ? {selectedMoq: {value: val, id: e.key}}
                            : {selectedPromotionalPrice: {value: val, id: e.key}}
                    );
                }}>
                <Menu.Item key="0">
                    Default {type} - {defaultOne}
                </Menu.Item>
                {list.map((item, index) => (
                    <Menu.Item key={index + 1}>
                        Cohort {index + 1} {type} - {item}
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    render() {
        // const initCat = (this.state.categories && this.state.category_uuid && this.state.categories.filter(
        //     (item) => item.category_uuid == this.state.category_uuid)[0]) || null;

        // const initSubCat = (initCat && initCat.sub_category_list.filter(item => item.sub_category_uuid == this.state.sub_category_uuid)[0]) || null;b

        // const initAdditionalSuperCats = null;

        const initSuperCat =
            (this.state.super_categories &&
                this.state.super_category_uuid &&
                this.state.super_categories.filter(
                    (item) => item.super_category_uuid == this.state.super_category_uuid
                )[0]) ||
            null;

        //console.log(initSuperCat, "thisss")

        const initCat =
            (initSuperCat &&
                initSuperCat.category_list &&
                this.state.category_uuid &&
                initSuperCat.category_list.filter((item) => item.category_uuid == this.state.category_uuid)[0]) ||
            null;

        const initSubCat =
            (initCat &&
                initCat.sub_category_list.filter(
                    (item) => item.sub_category_uuid == this.state.sub_category_uuid
                )[0]) ||
            null;

        let product_description_initial = this.state.product_description_initial;

        console.log(this.state.category, "CAT");

        return (
            <React.Fragment>
                <EditProductPageWrapper>
                    <Row style={{marginLeft: '-10px', marginBottom: '18px'}}>


                        <TitleWithBreadCrumb title={`Edit Ecommerce Product`}
                                             breadcrumb={["OmnyComm", "Product Edit"]}/>
                    </Row>
                    <Row gutter={[40, 40]} style={{height: "100%", margin: '0px -20px -20px -20x !important'}}
                         type="flex" justify="center" align="middle">
                        <Col className="gutter-row" lg={24} sm={24}>
                            <Row gutter={[40, 32]}>
                                <Col className="gutter-row" span={24}>
                                    {/* <div className="gutter-box">col-6</div> */}

                                    <Row gutter={[24, 24]} className="gutter-box-1">
                                        <Col style={{paddingBottom: "0px"}} sm={24}>
                                            <Row type="flex" justify="space-between">
                                                <Col style={{marginTop: '10px'}}>
                                                    <FormHeader header="Ecommerce : Details"/>
                                                </Col>
                                                <Col>
                                                    <Text
                                                        style={{
                                                            fontWeight: '500',
                                                            color: '#FFFFFF',
                                                            marginRight: "25px",
                                                        }}>
                                                        Language:
                                                    </Text>
                                                    <Radio.Group
                                                        style={{
                                                            width: "100%",
                                                            display: "inline",
                                                        }}
                                                        value={this.state.is_english_language}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                is_english_language: e.target.value,
                                                            });
                                                        }}>
                                                        <Radio
                                                            value={true}
                                                            style={{
                                                                color: '#FFFFFF',
                                                                display: "inline",
                                                                marginRight: "5px",
                                                            }}>
                                                            en
                                                        </Radio>
                                                        <Radio
                                                            value={false}
                                                            style={{
                                                                color: '#FFFFFF',
                                                                display: "inline",
                                                            }}>
                                                            ar
                                                        </Radio>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        </Col>

                                        {this.state.is_english_language && (
                                            <React.Fragment>
                                                <Row>

                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        <InputField
                                                            errormsg={
                                                                this.validator &&
                                                                this.validator.message(
                                                                    "product_name",
                                                                    this.state.product_name,
                                                                    "required"
                                                                )
                                                            }
                                                            impo
                                                            disabled={!this.state.dealshub_content_permission}
                                                            label={{
                                                                type: "secondary",
                                                            }}
                                                            input={{
                                                                size: "large",
                                                                name: "product_name",
                                                                value: this.state.product_name,
                                                            }}
                                                            onChange={this.handelChange}
                                                            labelText="Product Name (en)"
                                                        />
                                                    </Col>

                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        <InputField
                                                            errormsg={
                                                                this.validator &&
                                                                this.validator.message(
                                                                    "product_id",
                                                                    this.state.product_id,
                                                                    "required"
                                                                )
                                                            }
                                                            impo
                                                            disabled={!this.state.dealshub_content_permission}
                                                            label={{
                                                                type: "secondary",
                                                            }}
                                                            input={{
                                                                size: "large",
                                                                name: "product_id",
                                                                value: this.state.product_id,
                                                                disabled: true,
                                                            }}
                                                            onChange={this.handelChange}
                                                            labelText="Product ID"
                                                        />
                                                    </Col>

                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        <InputField
                                                            errormsg={
                                                                this.validator &&
                                                                this.validator.message(
                                                                    "seller_sku",
                                                                    this.state.seller_sku,
                                                                    "required"
                                                                )
                                                            }
                                                            disabled={!this.state.dealshub_content_permission}
                                                            impo
                                                            label={{
                                                                type: "secondary",
                                                            }}
                                                            input={{
                                                                size: "large",
                                                                name: "seller_sku",
                                                                value: this.state.seller_sku,
                                                                disabled: true,
                                                            }}
                                                            onChange={this.handelChange}
                                                            labelText="Seller SKU"
                                                        />
                                                    </Col>
                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        <InputField
                                                            errormsg={
                                                                this.validator &&
                                                                this.validator.message(
                                                                    "was_price",
                                                                    this.state.was_price,
                                                                    "required"
                                                                )
                                                            }
                                                            disabled={!this.state.dealshub_price_permission }
                                                            label={{
                                                                type: "secondary",
                                                            }}
                                                            input={{
                                                                size: "large",
                                                                name: "was_price",
                                                                value: this.state.was_price,
                                                                disabled: !this.state.dealshub_price_permission,
                                                            }}
                                                            onChange={this.handelChange}
                                                            labelText="Was Price"
                                                        />
                                                    </Col>

                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        {this.props.b2b === "b2b" ? (
                                                            <Col
                                                                span={24}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "flex-start",
                                                                    padding: "0",
                                                                    justifyContent: "center",
                                                                    flexDirection: "column",
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        color: "#ffffff99",
                                                                        fontSize: '16px'
                                                                    }}>
                                                                    Now Price{" "}
                                                                    <span
                                                                        style={{
                                                                            color: "#f1556c",
                                                                        }}>
																	*
																</span>
                                                                </Text>
                                                                <Dropdown
                                                                    trigger={["click"]}
                                                                    overlay={() => this.menu("now price")}>
                                                                    <Input
                                                                        style={{
                                                                            marginTop: '10px',
                                                                            border: "1px solid rgba(255, 255, 255, 0.3)",
                                                                            background: "#3C4853",
                                                                            borderRadius: '2px'
                                                                        }}
                                                                        disabled={!this.state.dealshub_price_permission}
                                                                        size="large"
                                                                        name="now price"
                                                                        value={this.state.selectedNowPrice.value}
                                                                        key={this.state.selectedNowPrice.id}
                                                                        onChange={this.handleSelectedChange}
                                                                    />
                                                                </Dropdown>
                                                            </Col>
                                                        ) : (
                                                            <InputField
                                                                errormsg={
                                                                    this.validator &&
                                                                    this.validator.message(
                                                                        "now_price",
                                                                        this.state.now_price,
                                                                        "required"
                                                                    )
                                                                }
                                                                impo
                                                                disabled={!this.state.dealshub_price_permission }
                                                                label={{
                                                                    type: "secondary",
                                                                }}
                                                                input={{
                                                                    size: "large",
                                                                    name: "now_price",
                                                                    value: this.state.now_price,
                                                                    disabled: !this.state.dealshub_price_permission,
                                                                }}
                                                                onChange={this.handelChange}
                                                                labelText="Now Price"
                                                            />
                                                        )}
                                                    </Col>

                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        {this.props.b2b === "b2b" ? (
                                                            <Col
                                                                span={24}
                                                                style={{
                                                                    padding: "0",
                                                                    display: "flex",
                                                                    alignItems: "flex-start",
                                                                    justifyContent: "center",
                                                                    flexDirection: "column",
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        color: "#ffffff99",
                                                                        fontSize: '16px'
                                                                    }}>
                                                                    Promotional Price{" "}
                                                                    <span
                                                                        style={{
                                                                            color: "#f1556c",
                                                                        }}>
																	*
																</span>
                                                                </Text>
                                                                <Dropdown
                                                                    trigger={["click"]}
                                                                    disabled={!this.state.is_promotional || !this.state.dealshub_price_permission}
                                                                    overlay={() => this.menu("promotional price")}>
                                                                    <Input
                                                                        style={{
                                                                            marginTop: '10px',
                                                                            border: "1px solid rgba(255, 255, 255, 0.3)",
                                                                            background: "#3C4853",
                                                                            borderRadius: '2px'
                                                                        }}
                                                                        size="large"
                                                                        name="promotional price"
                                                                        key={this.state.selectedPromotionalPrice.id}
                                                                        value={this.state.selectedPromotionalPrice.value}
                                                                        onChange={this.handleSelectedChange}
                                                                    />
                                                                </Dropdown>
                                                            </Col>
                                                        ) : (
                                                            <InputField
                                                                errormsg={
                                                                    this.validator &&
                                                                    this.validator.message(
                                                                        "promotional_price",
                                                                        this.state.promotional_price,
                                                                        "required"
                                                                    )
                                                                }
                                                                impo
                                                                disabled={!this.state.is_promotional || !this.state.dealshub_price_permission}
                                                                label={{
                                                                    type: "secondary",
                                                                }}
                                                                input={{
                                                                    size: "large",
                                                                    name: "promotional_price",
                                                                    value: this.state.promotional_price,
                                                                }}
                                                                onChange={this.handelChange}
                                                                labelText="Promotional Price"
                                                            />
                                                        )}
                                                    </Col>
                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        <InputField
                                                            errormsg={
                                                                this.validator &&
                                                                this.validator.message(
                                                                    "stock",
                                                                    this.state.stock,
                                                                    "required"
                                                                )
                                                            }
                                                            impo
                                                            disabled={!this.state.dealshub_stock_permission}
                                                            label={{
                                                                type: "secondary",
                                                            }}
                                                            input={{
                                                                size: "large",
                                                                name: "stock",
                                                                value: this.state.stock,
                                                                disabled: !this.state.dealshub_stock_permission,
                                                            }}
                                                            onChange={this.handelChange}
                                                            labelText="Stock"
                                                        />
                                                    </Col>

                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        <InputField
                                                            errormsg={
                                                                this.validator &&
                                                                this.validator.message(
                                                                    "allowed_qty",
                                                                    this.state.allowed_qty,
                                                                    "required"
                                                                )
                                                            }
                                                            impo
                                                            disabled={!this.state.dealshub_stock_permission}
                                                            label={{
                                                                type: "secondary",
                                                            }}
                                                            input={{
                                                                size: "large",
                                                                name: "allowed_qty",
                                                                value: this.state.allowed_qty,
                                                            }}
                                                            onChange={this.handelChange}
                                                            labelText="Allowed quantity"
                                                        />
                                                    </Col>

                                                    <Col sm={7} style={{marginTop: '10px', padding: '12px'}}>
                                                        {this.props.b2b === "b2b" ? (
                                                            <Col
                                                                span={24}
                                                                style={{
                                                                    padding: "0",
                                                                    display: "flex",
                                                                    alignItems: "flex-start",
                                                                    justifyContent: "center",
                                                                    flexDirection: "column",
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        color: "#ffffff99",
                                                                        fontSize: '16px'
                                                                    }}>
                                                                    Minimum Order Quantity{" "}
                                                                    <span
                                                                        style={{
                                                                            color: "#f1556c",
                                                                        }}>
																	*
																</span>
                                                                </Text>
                                                                <Dropdown
                                                                    trigger={["click"]}
                                                                    overlay={() => this.menu("moq")}>
                                                                    <Input
                                                                        style={{
                                                                            marginTop: '10px',
                                                                            border: "1px solid rgba(255, 255, 255, 0.3)",
                                                                            background: "#3C4853",
                                                                            borderRadius: '2px'
                                                                        }}
                                                                        size="large"
                                                                        name="moq"
                                                                        disabled={!this.state.dealshub_stock_permission}
                                                                        key={this.state.selectedMoq.id}
                                                                        value={this.state.selectedMoq.value}
                                                                        onChange={this.handleSelectedChange}
                                                                    />
                                                                </Dropdown>
                                                            </Col>
                                                        ) : (
                                                            <InputField
                                                                errormsg={
                                                                    this.validator &&
                                                                    this.validator.message(
                                                                        "Minimum order quantity",
                                                                        this.state.moq,
                                                                        "required"
                                                                    )
                                                                }
                                                                impo
                                                                disabled={!this.state.dealshub_stock_permission}
                                                                label={{
                                                                    type: "secondary",
                                                                }}
                                                                input={{
                                                                    size: "large",
                                                                    name: "moq",
                                                                    value: this.state.moq,
                                                                }}
                                                                onChange={this.handelChange}
                                                                labelText="Minimum order quantity"
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/*Add rows*/}
                                                <Row
                                                    type={"flex"}
                                                    align="middle"
                                                    style={{marginTop: '60px'}}
                                                >
                                                    <Col span={24} style={{padding: '12px'}}>
                                                        <Text style={{color: '#FFFFFF', fontSize: '16px'}}>
                                                            Default Category</Text>
                                                    </Col>
                                                    <Col sm={7} style={{padding: '12px'}}>
                                                        <Text
                                                            style={{
                                                                color: 'rgba(255, 255, 255, 0.8)',
                                                                fontSize: '16px'
                                                            }}>
                                                            Super Category{" "}
                                                            <Text
                                                                style={{
                                                                    color: "#EF556B",
                                                                }}>
                                                                *
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "8px",
                                                                }}>
                                                                {this.validator &&
                                                                this.validator.message(
                                                                    "category",
                                                                    initSuperCat || this.state.super_category,
                                                                    "required"
                                                                )}
                                                            </Text>
                                                        </Text>
                                                        <Select

                                                            showSearch
                                                            style={{
                                                                marginTop: '10px',
                                                                width: "100%",
                                                                border: "1px solid rgba(255, 255, 255, 0.3)"
                                                            }}
                                                            placeholder="Select a Category"
                                                            optionFilterProp="children"
                                                            onChange={this.onChangeSuperCategory}
                                                            onFocus={this.onFocus}
                                                            onBlur={this.onBlur}
                                                            onSearch={this.onSearch}
                                                            name="category"
                                                            size="large"
                                                            value={
                                                                (this.state.super_category &&
                                                                    this.state.super_category.name) ||
                                                                (initSuperCat && initSuperCat.name) ||
                                                                null
                                                            }
                                                            disabled={!this.state.dealshub_content_permission}
                                                            
                                                            // className={this.props.disabled && "disabledSelect"}
                                                            filterOption={(input, option) =>
                                                                option.props.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                            {this.state.super_categories &&
                                                            this.state.super_categories.map((e, index) => {
                                                                return (
                                                                    <Option selected value={index}>
                                                                        {e.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Col>

                                                    {(initCat || this.state.super_category) && (
                                                        <Col sm={7} style={{padding: '12px'}}>
                                                            <Text
                                                                style={{
                                                                    color: 'rgba(255, 255, 255, 0.8)',
                                                                    fontSize: '16px'
                                                                }}>
                                                                Category{" "}
                                                                <Text
                                                                    style={{
                                                                        color: "#EF556B",
                                                                    }}>
                                                                    *
                                                                </Text>
                                                                <Text
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "8px",
                                                                    }}>
                                                                    {this.validator &&
                                                                    this.validator.message(
                                                                        "category",
                                                                        initCat || this.state.category,
                                                                        "required"
                                                                    )}
                                                                </Text>
                                                            </Text>
                                                            <Select
                                                                showSearch
                                                                style={{
                                                                    marginTop: '10px',
                                                                    width: "100%",
                                                                    border: "1px solid rgba(255, 255, 255, 0.3)"
                                                                }}
                                                                placeholder="Select a Category"
                                                                optionFilterProp="children"
                                                                onChange={this.onChangeCategory}
                                                                onFocus={this.onFocus}
                                                                onBlur={this.onBlur}
                                                                onSearch={this.onSearch}
                                                                name="category"
                                                                size="large"
                                                                value={
                                                                    (this.state.category && this.state.category.name) ||
                                                                    (initCat && initCat.name) ||
                                                                    null
                                                                }
                                                            disabled={!this.state.dealshub_content_permission}
                                                                className={this.state.disabled && "disabledSelect"}
                                                                filterOption={(input, option) =>
                                                                    option.props.children
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {
                                                                    this.state.super_category
                                                                        ? this.state.super_category.category_list.map(
                                                                        (e, index) => {
                                                                            return (
                                                                                <Option selected
                                                                                        value={index}>
                                                                                    {e.name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                        )
                                                                        : initSuperCat.category_list.map((e, index) => {
                                                                            return (
                                                                                <Option selected
                                                                                        value={index}>
                                                                                    {e.name}
                                                                                </Option>
                                                                            );
                                                                        })
                                                                }
                                                            </Select>
                                                        </Col>
                                                    )}

                                                    {(initSubCat || this.state.category) && (
                                                        <Col sm={7} style={{padding: '12px'}}>
                                                            <React.Fragment>
                                                                <Text
                                                                    style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}
                                                                >
                                                                    Sub Category{" "}
                                                                    <Text
                                                                        style={{
                                                                            color: "#EF556B",
                                                                        }}>
                                                                        *
                                                                    </Text>
                                                                    <Text
                                                                        style={{
                                                                            color: "red",
                                                                            fontSize: "8px",
                                                                        }}>
                                                                        {this.validator &&
                                                                        this.validator.message(
                                                                            "sub category",
                                                                            initSubCat || this.state.subCategory,
                                                                            "required"
                                                                        )}
                                                                    </Text>
                                                                </Text>
                                                                <Select
                                                                    showSearch
                                                                    style={{
                                                                        marginTop: '10px',
                                                                        width: "100%",
                                                                        border: "1px solid rgba(255, 255, 255, 0.3)"
                                                                    }}
                                                                    placeholder="Select a Category"
                                                                    optionFilterProp="children"
                                                                    onChange={this.onChangeSubCategory}
                                                                    onFocus={this.onFocus}
                                                                    onBlur={this.onBlur}
                                                                    onSearch={this.onSearch}
                                                                    name="category"
                                                                    size="large"
                                                                    value={
                                                                        (this.state.subCategory &&
                                                                            this.state.subCategory.name) ||
                                                                        (initSubCat && initSubCat.name) ||
                                                                        null
                                                                    }
                                                                    disabled={!this.state.dealshub_content_permission}

                                                                    // className={this.props.disabled && "disabledSelect"}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children
                                                                            .toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }>
                                                                    {
                                                                        this.state.category
                                                                            ? this.state.category.sub_category_list.map(
                                                                            (e, index) => {
                                                                                return (
                                                                                    <Option selected
                                                                                            value={index}>
                                                                                        {e.name}
                                                                                    </Option>
                                                                                );
                                                                            }
                                                                            )
                                                                            : initCat.sub_category_list.map((e, index) => {
                                                                                return (
                                                                                    <Option selected
                                                                                            value={index}>
                                                                                        {e.name}
                                                                                    </Option>
                                                                                );
                                                                            })
                                                                    }
                                                                </Select>
                                                            </React.Fragment>
                                                        </Col>
                                                    )}
                                                    <Col span={24} style={{marginTop: '24px', padding: '12px'}}>
                                                        <Text style={{color: '#FFFFFF', fontSize: '16px'}}>
                                                            Additional Categories</Text>
                                                    </Col>
                                                </Row>

                                                {
                                                    this.state.category_rows?.map((item) => {
                                                        return (
                                                            <Row key={item}
                                                                 type={"flex"}
                                                                 align="middle"
                                                                 style={{marginTop: '10spx'}}>
                                                                <Col sm={7} style={{padding: '12px'}}>
                                                                    <Text
                                                                        style={{
                                                                            color: 'rgba(255, 255, 255, 0.8)',
                                                                            fontSize: '16px'
                                                                        }}>
                                                                        Super Category{" "}
                                                                        <Text
                                                                            style={{
                                                                                color: "#EF556B",
                                                                            }}>
                                                                            *
                                                                        </Text>
                                                                        <Text
                                                                            style={{
                                                                                color: "red",
                                                                                fontSize: "8px",
                                                                            }}>
                                                                            {this.validator &&
                                                                            this.validator.message(
                                                                                "category",
                                                                                initSuperCat || this.state.super_category,
                                                                                "required"
                                                                            )}
                                                                        </Text>
                                                                    </Text>
                                                                    <Select
                                                                        showSearch
                                                                        style={{
                                                                            marginTop: '10px',
                                                                            width: "100%",
                                                                            border: "1px solid rgba(255, 255, 255, 0.3)"
                                                                        }}
                                                                        placeholder="Select a Category"
                                                                        optionFilterProp="children"
                                                                        onChange={(e) => this.onChangeOtherSuperCategory(e, item)}
                                                                        onFocus={this.onFocus}
                                                                        onBlur={this.onBlur}
                                                                        onSearch={this.onSearch}
                                                                        name="category"
                                                                        size="large"
                                                                        value={this.state.other_super_category.filter((i) => i.index === item)[0]?.value.name}
                                                                        disabled={!this.state.dealshub_content_permission}

                                                                        // className={this.props.disabled && "disabledSelect"}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children
                                                                                .toLowerCase()
                                                                                .indexOf(input.toLowerCase()) >= 0
                                                                        }>
                                                                        {this.state.super_categories &&
                                                                        this.state.super_categories.map((e, index) => {
                                                                            return (
                                                                                <Option selected value={index}
                                                                                        key={index}>
                                                                                    {e.name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </Col>

                                                                {
                                                                    (this.state.other_super_category.filter((obj) => obj.index === item)[0]?.value.category_list) &&
                                                                    (
                                                                        <Col sm={7} style={{padding: '12px'}}>
                                                                            <Text
                                                                                style={{
                                                                                    color: 'rgba(255, 255, 255, 0.8)',
                                                                                    fontSize: '16px'
                                                                                }}>
                                                                                Category{" "}
                                                                                <Text
                                                                                    style={{
                                                                                        color: "#EF556B",
                                                                                    }}>
                                                                                    *
                                                                                </Text>
                                                                                <Text
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "8px",
                                                                                    }}>
                                                                                    {this.validator &&
                                                                                    this.validator.message(
                                                                                        "category",
                                                                                        initCat || this.state.category,
                                                                                        "required"
                                                                                    )}
                                                                                </Text>
                                                                            </Text>
                                                                            <Select
                                                                                showSearch
                                                                                style={{
                                                                                    marginTop: '10px',
                                                                                    width: "100%",
                                                                                    border: "1px solid rgba(255, 255, 255, 0.3)"
                                                                                }}
                                                                                placeholder="Select a Category"
                                                                                optionFilterProp="children"
                                                                                onChange={(e) => this.onChangeOtherCategory(e, item)}
                                                                                onFocus={this.onFocus}
                                                                                onBlur={this.onBlur}
                                                                                onSearch={this.onSearch}
                                                                                name="category"
                                                                                size="large"
                                                                                value={this.state.other_category.filter((i) => i.index === item)[0]?.value.name || ""}
                                                                                disabled={!this.state.dealshub_content_permission}

                                                                                className={this.state.disabled && "disabledSelect"}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children
                                                                                        .toLowerCase()
                                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                                }>
                                                                                {
                                                                                    this.state.other_super_category.filter((obj) => obj.index === item)[0]?.value.category_list?.map((e, id) => {
                                                                                        return (
                                                                                            <Option selected
                                                                                                    value={id}
                                                                                                    key={id}
                                                                                                //value={id}
                                                                                            >
                                                                                                {e.name}
                                                                                            </Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </Col>
                                                                    )}

                                                                {this.state.other_category.filter((obj) => obj.index === item)[0]?.value.sub_category_list && (
                                                                    <Col sm={7} style={{padding: '12px'}}>
                                                                        <React.Fragment>
                                                                            <Text
                                                                                style={{
                                                                                    color: 'rgba(255, 255, 255, 0.8)',
                                                                                    fontSize: '16px'
                                                                                }}
                                                                            >
                                                                                Sub Category{" "}
                                                                                <Text
                                                                                    style={{
                                                                                        color: "#EF556B",
                                                                                    }}>
                                                                                    *
                                                                                </Text>
                                                                                <Text
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "8px",
                                                                                    }}>
                                                                                    {this.validator &&
                                                                                    this.validator.message(
                                                                                        "sub category",
                                                                                        initSubCat || this.state.subCategory,
                                                                                        "required"
                                                                                    )}
                                                                                </Text>
                                                                            </Text>
                                                                            <Select
                                                                                showSearch
                                                                                style={{
                                                                                    marginTop: '10px',
                                                                                    width: "100%",
                                                                                    border: "1px solid rgba(255, 255, 255, 0.3)"
                                                                                }}
                                                                                placeholder="Select a Category"
                                                                                optionFilterProp="children"
                                                                                onChange={(e) => this.onChangeOtherSubCategory(e, item)}
                                                                                onFocus={this.onFocus}
                                                                                onBlur={this.onBlur}
                                                                                onSearch={this.onSearch}
                                                                                name="category"
                                                                                size="large"
                                                                                value={this.state.other_sub_category.filter((i) => i.index === item)[0]?.value.name || ""}
                                                                                disabled={!this.state.dealshub_content_permission}
                                                                                
                                                                                // className={this.props.disabled && "disabledSelect"}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children
                                                                                        .toLowerCase()
                                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                                }>
                                                                                {
                                                                                    this.state.other_category.filter((obj) => obj.index === item)[0]?.value.sub_category_list?.map((e, index) => {
                                                                                        return (
                                                                                            <Option selected
                                                                                                    values={index}
                                                                                                    key={index}
                                                                                            >
                                                                                                {e.name}
                                                                                            </Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </React.Fragment>
                                                                    </Col>
                                                                )}

                                                                <Col sm={3}>
                                                                    {/*<Text>{item}</Text>*/}
                                                                    <Icon
                                                                        onClick={() => {
                                                                            // const last = this.state.category_rows[this.state.category_rows.length - 1]
                                                                            this.setState({
                                                                                category_rows: this.state.category_rows.filter((i) => i !== item)
                                                                            })
                                                                            this.deleteCategoryRow(item)
                                                                        }}
                                                                        type="delete"
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            marginLeft: '20px',
                                                                            color: 'rgba(255, 255, 255, 0.6)'
                                                                        }}
                                                                    />
                                                                </Col>

                                                            </Row>
                                                        )
                                                    })
                                                }
                                                <Col span={24}>
                                                    <Button
                                                        disabled={!this.state.dealshub_content_permission}

                                                        onClick={() => {

                                                            this.setState({
                                                                category_rows: this.state.category_rows.length === 0 ? [1] : this.state.category_rows.concat(this.state.category_rows[this.state.category_rows.length - 1] + 1),
                                                                //other_super_category: this.state.other_super_category.map()
                                                            })

                                                            console.log(this.state.category_rows, "modified rows")
                                                        }}
                                                        style={{
                                                            backgroundColor: '#5BCAAA',
                                                            border: 'none',
                                                            color: '#F1F1F1'
                                                        }}>
                                                        <Icon type="plus"/>{" "}
                                                        Add Category</Button>
                                                </Col>
                                                <Col span={21} style={{marginTop: '60px',}}>
                                                    <Col span={11} style={{padding: '0px'}}>
                                                        <Col sm={12} style={{padding: '0px'}}>
                                                            <Row type="flex" justify="start" align="middle">
                                                                <Col>
                                                                    <Text style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}>Promo Restricted? </Text>
                                                                    <Switch
                                                                        
                                                                        style={{marginLeft: '24px'}}
                                                                        className="switch-color"
                                                                        size={"small"}
                                                                        disabled={!this.state.dealshub_content_permission}
                                                                        // checkedChildren="Yes"
                                                                        // unCheckedChildren="No"
                                                                        onChange={this.handlePromoRestriction}
                                                                        checked={this.state.is_promo_restricted}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} style={{padding: '0px'}}>
                                                            <Row type="flex" justify="start" align="middle">
                                                                <Col>
                                                                    <Text style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}>New Arrival? </Text>
                                                                    <Switch
                                                                        style={{marginLeft: '24px'}}
                                                                        size={"small"}
                                                                        disabled={!this.state.dealshub_content_permission}

                                                                        // checkedChildren="Yes"
                                                                        // unCheckedChildren="No"
                                                                        onChange={this.handleNewArrival}
                                                                        checked={this.state.is_new_arrival}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} style={{padding: '0px', marginTop: '30px'}}>
                                                            <Row
                                                                type="flex"
                                                                justify="start"
                                                                align="middle"
                                                            >
                                                                <Col>
                                                                    <Text style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}>Is Cod Allowed? </Text>
                                                                    <Switch
                                                                        style={{marginLeft: '24px'}}
                                                                        size={"small"}
                                                                        disabled={!this.state.dealshub_content_permission}

                                                                        // checkedChildren="Yes"
                                                                        // unCheckedChildren="No"
                                                                        onChange={this.handleBundleProduct}
                                                                        checked={this.state.is_cod_allowed}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} style={{padding: '0px', marginTop: '30px'}}>
                                                            <Row type="flex" justify="start" align="middle">
                                                                <Col>
                                                                    <Text style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}>Sale Product? </Text>
                                                                    <Switch
                                                                        style={{marginLeft: '24px'}}
                                                                        size={"small"}
                                                                        disabled={!this.state.dealshub_content_permission}

                                                                        // checkedChildren="Yes"
                                                                        // unCheckedChildren="No"
                                                                        onChange={this.handleSaleProduct}
                                                                        checked={this.state.is_on_sale}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} style={{padding: '0px', marginTop: '30px'}}>
                                                            <Row type="flex" justify="start" align="middle">
                                                                <Col>
                                                                    <Text style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}>Best Seller? </Text>

                                                                    <Switch
                                                                        style={{marginLeft: '24px'}}
                                                                        size={"small"}
                                                                        disabled={!this.state.dealshub_content_permission}

                                                                        // checkedChildren="Yes"
                                                                        // unCheckedChildren="No"
                                                                        onChange={this.handleBestSellerProduct}
                                                                        checked={this.state.is_bestseller}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} style={{padding: '0px', marginTop: '30px'}}>
                                                            <Row type="flex" justify="start" align="middle">
                                                                <Col>
                                                                    <Text style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}>Featured? </Text>
                                                                    <Switch
                                                                        disabled={!this.state.dealshub_content_permission}
                                                                        
                                                                        style={{marginLeft: '24px'}}
                                                                        size={"small"}
                                                                        // checkedChildren="Yes"
                                                                        // unCheckedChildren="No"
                                                                        onChange={this.handleFeaturedProduct}
                                                                        checked={this.state.is_featured}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Col>


                                                    <Col span={13} style={{padding: '0px'}}>
                                                        <Col span={11} style={{padding: "0px", marginTop: '-20px'}}>
                                                            {this.state.is_promotional && !this.state.product_is_promotional && (
                                                                <Text
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                    type="warning">
                                                                    This product is under Section Promotion
                                                                </Text>
                                                            )}
                                                            <br/>
                                                            <Text style={{
                                                                color: 'rgba(255, 255, 255, 0.8)',
                                                                fontSize: '16px'
                                                            }}>Promotion Status? </Text>
                                                            <Switch
                                                                style={{marginLeft: '24px'}}
                                                                size={"small"}
                                                                disabled={
                                                                    (this.state.is_promotional &&
                                                                    !this.state.product_is_promotional) ||
                                                                    !this.state.dealshub_content_permission
                                                                }
                                                                checked={this.state.product_is_promotional}
                                                                onChange={this.setPromotionActive}
                                                                // checkedChildren="On"
                                                                // unCheckedChildren="Off"
                                                            />
                                                        </Col>

                                                        {this.state.product_is_promotional ? (
                                                            <Col span={13} style={{padding: '0px'}}>
                                                                <Col span={24} style={{padding: '0px'}}>
                                                                    <InputField
                                                                        errormsg={
                                                                            this.validator &&
                                                                            this.validator.message(
                                                                                "promotional tag",
                                                                                this.state.promotionalTag,
                                                                                "required"
                                                                            )
                                                                        }
                                                                        impo
                                                                        disabled={!this.state.dealshub_content_permission}

                                                                        label={{
                                                                            type: "secondary",
                                                                        }}
                                                                        input={{
                                                                            name: "promotional tag",
                                                                            value: this.state.promotionalTag,
                                                                            maxLength: 30,
                                                                            placeholder: "Enter Promotion Tag here",
                                                                        }}
                                                                        onChange={this.changePromotionTag}
                                                                        labelText="Promotional Tag "
                                                                    />
                                                                </Col>
                                                                <Col span={24}
                                                                     style={{padding: '0px', marginTop: '10px'}}>
                                                                    <Text style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        fontSize: '16px'
                                                                    }}>
                                                                        Validity Period{" "}
                                                                        <Text
                                                                            style={{
                                                                                color: "#EF556B",
                                                                            }}>
                                                                            *
                                                                        </Text>{" "}
                                                                        <Text
                                                                            style={{
                                                                                color: "red",
                                                                                fontSize: "8px",
                                                                            }}>
                                                                            {this.validator &&
                                                                            this.validator.message(
                                                                                "validity period",
                                                                                this.state.promotionStartDate &&
                                                                                this.state.promotionEndDate,
                                                                                "required"
                                                                            )}
                                                                        </Text>
                                                                    </Text>
                                                                    <RangePicker
                                                                        disabled={!this.state.dealshub_content_permission}
                                                                        
                                                                        defaultValue={
                                                                            this.state.promotionStartDate &&
                                                                            this.state.promotionEndDate
                                                                                ? [
                                                                                    moment(
                                                                                        this.state.promotionStartDate,
                                                                                        this.rangePickerDateFormat
                                                                                    ),
                                                                                    moment(
                                                                                        this.state.promotionEndDate,
                                                                                        this.rangePickerDateFormat
                                                                                    ),
                                                                                ]
                                                                                : null
                                                                        }
                                                                        style={{
                                                                            marginTop: '10px',
                                                                            width: "fit-content",
                                                                        }}
                                                                        className="calendar-bgcolor"
                                                                        showTime={{
                                                                            format: "HH:mm:ss",
                                                                        }}
                                                                        format={this.rangePickerDateFormat}
                                                                        placeholder={["Start Time", "End Time"]}
                                                                        onOk={() => {
                                                                        }}
                                                                        onChange={this.changePromotionRange}
                                                                    />
                                                                </Col>
                                                            </Col>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Col>
                                                </Col>


                                                <Col span={21}>
                                                    <InputField
                                                        // errormsg={
                                                        //     this.validator &&
                                                        //     this.validator.message(
                                                        //       "url",
                                                        //       this.state.url,
                                                        //       "required"
                                                        //     )
                                                        //   }
                                                        // impo
                                                        disabled={!this.state.dealshub_content_permission}
                                                        label={{
                                                            type: "secondary",
                                                        }}
                                                        input={{
                                                            size: "large",
                                                            name: "url",
                                                            value: this.state.url,
                                                        }}
                                                        onChange={this.handelChange}
                                                        labelText="URL"
                                                    />
                                                </Col>

                                                <Col span={21}>
                                                    <Text
                                                        style={{fontSize: '16px', fontWeight: '500', color: '#FFFFFF'}}>
                                                        Search Keywords</Text>
                                                    <Select
                                                        dropdownClassName="search-chips"
                                                        dropdownMatchSelectWidth="false"
                                                        mode="tags"
                                                        style={{
                                                            border: "1px solid rgba(255, 255, 255, 0.3)",
                                                            width: "100%",
                                                            marginTop: "10px",
                                                            backgroundColor: '#3C4853'
                                                        }}
                                                        disabled={!this.state.dealshub_content_permission}
                                                        placeholder={["Search Keywords"]}
                                                        onChange={this.handleChangeSearchTags}
                                                        value={this.state.tags}/>
                                                </Col>

                                                {/* <Col sm={24} style = {{
                      paddingTop : "0px"
                    }}>
                                <InputField
                                textarea
                                label={{ type: "secondary" }}
                                input={{
                                    size: "large",
                                    name: "product_description",
                                    value: this.state.product_description,
                                  }}
                                  onChange={this.handelChange}
                                labelText="Product Description"
                                />
                            </Col> */}

                                                {this.state.showEditor && (
                                                    <React.Fragment>
                                                        <Col sm={21}>
                                                            <Text
                                                                style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '500',
                                                                    color: '#FFFFFF'
                                                                }}>
                                                                Product Description</Text>
                                                            <div className="quill-container">
                                                                <ReactQuill
                                                                    value={this.state.product_description}
                                                                    theme="snow"
                                                                    readOnly={!this.state.dealshub_content_permission}
                                                                    modules={this.modules}
                                                                    formats={this.formats}
                                                                    onChange={(value) => {
                                                                        this.setState({
                                                                            product_description: value,
                                                                        });
                                                                    }}
                                                                    className="react-quill-component"
                                                                    style={{
                                                                        height: "500px",
                                                                        marginTop: "10px",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}

                                        {!this.state.is_english_language && (
                                            <React.Fragment>
                                                <Col sm={21} style={{marginTop: '30px'}}>
                                                    <InputField
                                                        errormsg={
                                                            this.validator &&
                                                            this.validator.message(
                                                                "product_name_ar",
                                                                this.state.product_name_ar,
                                                                "required"
                                                            )
                                                        }
                                                        impo
                                                        disabled={!this.state.dealshub_content_permission}
                                                        label={{
                                                            type: "secondary",
                                                        }}
                                                        input={{
                                                            size: "large",
                                                            name: "product_name_ar",
                                                            value: this.state.product_name_ar,
                                                        }}
                                                        onChange={this.handelChange}
                                                        labelText="Product Name (ar)"
                                                    />
                                                </Col>

                                                {this.state.showEditor && (
                                                    <React.Fragment>
                                                        <Col sm={21}>
                                                            <Text
                                                                style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '500',
                                                                    color: '#FFFFFF'
                                                                }}>
                                                                Product Description</Text>

                                                            <div className="quill-container">
                                                                <ReactQuill
                                                                    value={this.state.product_description_ar}
                                                                    theme="snow"
                                                                    modules={this.modules}
                                                                    formats={this.formats}
                                                                    readOnly={!this.state.dealshub_content_permission}
                                                                    onChange={(value) => {
                                                                        this.setState({
                                                                            product_description_ar: value,
                                                                        });
                                                                    }}
                                                                    className="react-quill-component"
                                                                    style={{
                                                                        border: "1px solid rgba(255, 255, 255, 0.3)",
                                                                        height: "500px",
                                                                        marginTop: "10px",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="gutter-row save-btn-edit-product-page" sm={24}>
                            {/* <Row type="flex" justify="center" align="middle">
                                <Button onClick={this.handelSave} type="primary"> Save </Button>
                            </Row> */}

                            <Tooltip
                                placement="top"
                                title={this.state.is_published ? "Unpublish this product" : "Publish this product"}>
                                <Affix
                                    style={{
                                        position: "fixed",
                                        bottom: 20,
                                        right: 70,
                                    }}>
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={this.state.is_published ? "vertical-align-bottom" : "vertical-align-top"}
                                        size="large"
                                        disabled={this.state.publishLoading}
                                        loading={this.state.publishLoading}
                                        onClick={this.handlePublish}></Button>
                                </Affix>
                            </Tooltip>

                            <Tooltip placement="top" title="Save">
                                <Affix
                                    style={{
                                        position: "fixed",
                                        bottom: 20,
                                        right: 20,
                                    }}>
                                    <Button
                                        type="danger"
                                        shape="circle"
                                        icon="save"
                                        size="large"
                                        disabled={this.state.loading}
                                        loading={this.state.loading}
                                        onClick={this.handelSave}></Button>
                                </Affix>
                            </Tooltip>
                        </Col>
                    </Row>
                </EditProductPageWrapper>
            </React.Fragment>
        );
    }
}

export default EditEcommerceProductPage;
