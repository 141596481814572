import React, {useState, useEffect} from "react"
import RightWrapper from "my-components/ui-components/RightWrapper"
import TitleWithBreadCrumb from "views/components/titlewithbreadcrumb/TitleWithBreadCrumb";
import {Row, Col, AutoComplete, Select, message, Button, Spin, Icon, Modal, Typography, Upload} from "antd";
import EditorBox from "views/components/EditorBox";
import FormHeader from "views/components/datainputs/FormHeader";
import isDev from "server";
import InputField from "views/components/datainputs/InputField";
import { useSelector } from "react-redux";
import {Thumbs} from "react-responsive-carousel";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import styled from "styled-components";


const serverUrl = isDev();

const {Option} = Select
const {Text} = Typography;

const UploadBtn = styled(Upload)`
  && {
    .ant-upload {
      height: 0px !important;
      width: 100% !important;
    }

    span {
      width: 100% !important;
    }
  }`

function InnerWrapper(props) {
    const misc = useSelector((state) => state.miscPermission)
    const [options, setOptions] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [selectedSearchResult, setSelectedSearchResult] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);
    const [isloading, setIsLoading] = useState(false);
    const [details, setDetails] = useState({});
    const [saveLoading, setSaveLoading] = useState(false);
    const [pagetype, setPageType] = useState("product");
    const [resetEnabled, setResetEnabled] = useState(false);
    const [brandList, setBrandList] = useState([]);
    const [brandName, setBrandName] = useState("all");
    const [shortDesc, setShortDesc] = useState("");
    const [longDesc, setLongDesc] = useState("");
    const [downloadModel, setDownloadModel] = useState(false);
    //const [uploadModel, setUploadModel] = useState(false);
    const [downloadType, setDownloadType] = useState("product");
    const [downloadNote, setDownloadNote] = useState("")
    const [fileList, setFileList] = useState([]);
    const reset = () => {
        setOptions([]);
        setSearchString("");
        setSelectedSearchResult({});
        setIsDisabled(true);
        setDetails({});
        setResetEnabled(false);
        setShortDesc("");
        setLongDesc("");
        setBrandList([]);
        setBrandName("all")
        // setPageType("product")
    }

    useEffect(() => {
        reset();
    }, [props.locationUuid])


    const addAfterSerach = (idx) => {
        setSearchString(options[idx].name);
        setSelectedSearchResult(options[idx]);
        fetchSeoAdminDetails(idx);
        setResetEnabled(true);
    }

    const fetchBrands = () => {
        fetch(`${serverUrl}/fetch-brands/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        })
            .then(res => res.json())
            .then(data => {
                setBrandList(data.brand_list);
            });
    }

    useEffect(() => {
        if ((pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category") && brandList.length == 0) {
            fetchBrands();
        }
    }, [pagetype])

    useEffect(() => {
        setSearchString("");
        setOptions([]);
     }, [brandName]);


    const fetchSeoAdminDetails = (idx) => {
        setIsLoading(true);

        let pagetype_new = pagetype;

        if ((pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category") && brandName != "all") {
            pagetype_new = categoryToBrandsWith[pagetype];
        }

        let data = {
            page_type: pagetype_new,
            uuid: options[idx].uuid
        };
        fetch(`${serverUrl}/dealshub/fetch-seo-admin-details/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                setIsLoading(false)
                if (data.status === 200) {
                    setIsDisabled(false)
                    setDetails(data)
                    setShortDesc(data.short_description ? data.short_description : "");
                    setLongDesc(data.long_description ? data.long_description : "");
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
    }

    const categoryToBrandsWith = {
        "super_category": "brand_super_category",
        "category": "brand_category",
        "sub_category": "brand_sub_category"
    }

    const handleSearch = (value) => {
        setSearchString(value)
        console.log("handle search", value);

        let pagetype_new = pagetype;
        let brand_new = undefined;

        if ((pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category") && brandName != "all") {
            pagetype_new = categoryToBrandsWith[pagetype];
            brand_new = brandName
        }

        let data = {
            page_type: pagetype_new,
            search_string: value,
            brand_name: brand_new,
            locationGroupUuid: props.locationUuid
        };
        fetch(`${serverUrl}/dealshub/fetch-seo-admin-autocomplete/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setOptions(data.autocomplete_list);
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
    }

    const saveDetails = () => {
        setSaveLoading(true);

        let pagetype_new = pagetype;

        if ((pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category") && brandName != "all") {
            pagetype_new = categoryToBrandsWith[pagetype];
        }


        let data = {
            page_type: pagetype_new,
            uuid: selectedSearchResult.uuid,
            ...details,
            short_description: (pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category" || pagetype == "brand") ? shortDesc : undefined,
            long_description: (pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category" || pagetype == "brand") ? longDesc : undefined
        };
        fetch(`${serverUrl}/dealshub/save-seo-admin-details/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                setSaveLoading(false);
                if (data.status === 200) {
                    message.success("Details are saved successfully!")
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
    }

    const maptoTitle = {
        "product": "Product",
        "super_category": "Super Category",
        "category": "Category",
        "sub_category": "Sub Category",
        "brand": "Brand"
    }


    const getEditorTextShortDesc = (value) => {
        setShortDesc(value)
    }

    const getEditorTextLongDesc = (value) => {
        setLongDesc(value);
    }

    const handleOkDownload = () => {
        let data = {
            locationGroupUuid: props.locationUuid,
            note: "this is test report",
            seo_type: downloadType
        }
        fetch(`${serverUrl}/create-seo-report/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    if (data.approved === "false") {
                        message.info("Try again later")
                    } else {
                        message.success("Download processed successfully!")
                    }
                } else if (data.status === 403) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Something went wrong")
                }
            });
        setDownloadModel(false);
    }

    const handleUploadFile = (file) => {
        // let data = {
        //     locationGroupUuid: props.locationUuid,
        //     import_file: file
        // }
        let formData = new FormData();
        formData.append("import_file", file);
        formData.append("locationGroupUuid", props.locationUuid);
        axios
            .post(`${serverUrl}/bulk-upload-seo-details/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `JWT ${localStorage.getItem("token")} `
                }
            })
            .then(res => {
                if (res.data.status === 200) {
                    message.success("Success!")
                    // window.location.reload();
                } else if (res.data.status === 405) {
                    message.info("You are not authorised!")
                } else {
                    message.error("Internal Server Error!");
                }
                setFileList([]);
            })
            .catch(err => {
                message.error("Internal Server Error!");
            });
    }

    return (
        <React.Fragment>
            <TitleWithBreadCrumb
                title={`SEO Admin : ${maptoTitle[pagetype]}`}
                breadcrumb={[]}
            />
            <Row

                style={{
                    padding: "35px"
                }} gutter={[12, 12]}>


                {
                    isloading ?
                        <Row type="flex" justify="center" align="middle" style={{
                            width: "100%",
                            height: "100px"
                        }}>
                            <Col>
                                <Spin width={120}/>
                            </Col>
                        </Row>
                        :

                        <React.Fragment>

                            <Row

                                style={{
                                    backgroundColor: "#36404a",
                                    padding: "20px"
                                }} gutter={[18, 18]}>

                                <Col span={24}>
                                    <Row type="flex" gutter={[12, 12]}>
                                        <Col>
                                            <Select
                                                showSearch
                                                style={{width: 200}}
                                                placeholder="Select a parameter"
                                                optionFilterProp="children"
                                                onChange={(value) => {
                                                    setPageType(value);
                                                    reset();
                                                }}
                                                value={pagetype}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="product">Product</Option>
                                                <Option value="super_category">Super categories</Option>
                                                <Option value="category">Categories</Option>
                                                <Option value="sub_category">Sub categories</Option>
                                                <Option value="brand">Brand</Option>
                                            </Select>
                                        </Col>

                                        {
                                            (pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category") &&
                                            <Col>
                                                <Select
                                                    showSearch
                                                    style={{width: 200}}
                                                    placeholder="Select a Brand"
                                                    optionFilterProp="children"
                                                    onChange={(value) => {
                                                        setBrandName(value);
                                                    }}
                                                    value={brandName}
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {brandList.map(e => {
                                                        return <Option selected value={e.name}>
                                                            {e.name}
                                                        </Option>;
                                                    })}
                                                </Select>
                                            </Col>
                                        }
                                        <Col span={12}>
                                            <AutoComplete onSelect={addAfterSerach} value={searchString}
                                                          style={{width: "100%", marginBottom: "10px"}}
                                                          onSearch={handleSearch} placeholder="search">
                                                {options.map((item, index) => {
                                                    return (
                                                        <Option key={index}>{item.name}</Option>
                                                    )
                                                })}
                                            </AutoComplete>
                                        </Col>


                                        <Col>
                                            <Button icon="reload" onClick={() => {
                                                reset();
                                                setPageType("product")
                                            }} ghost type="primary"></Button>
                                        </Col>

                                        <Col>
                                            <UploadBtn style={{width: "100%"}} beforeUpload={handleUploadFile}>
                                                <Button style={{ textAlign: 'left'}}
                                                        type='primary'
                                                        icon="upload">Upload</Button>
                                            </UploadBtn>
                                        </Col>
                                        <Col lg={3} md={3} sm={6}>
                                            <Button style={{
                                                width: "100%"
                                            }}
                                                    type='primary'
                                                    icon="vertical-align-bottom"
                                                    onClick={() => {
                                                        setDownloadModel(true)
                                                    }}>Download</Button>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm={20}>
                                    <InputField
                                        label={{type: "secondary"}}
                                        input={{
                                            size: "large",
                                            name: "seo_title",
                                            value: details.seo_title,
                                            disabled: isDisabled || !misc.includes("edit_SEO"),
                                            autosize: true
                                        }}
                                        
                                        textarea
                                        onChange={(e) => {
                                            setDetails({
                                                ...details,
                                                [e.target.name]: e.target.value
                                            })
                                        }}
                                        labelText="SEO Title"
                                    />
                                </Col>


                                <Col sm={20}>
                                    <InputField
                                        label={{type: "secondary"}}
                                        input={{
                                            size: "large",
                                            name: "seo_keywords",
                                            value: details.seo_keywords,
                                            disabled: isDisabled || !misc.includes("edit_SEO"),
                                            autosize: true,
                                        }}
                                        textarea
                                        onChange={(e) => {
                                            setDetails({
                                                ...details,
                                                [e.target.name]: e.target.value
                                            })
                                        }}
                                        labelText="SEO keywords"
                                    />
                                </Col>

                                <Col sm={20}>
                                    <InputField
                                        label={{type: "secondary"}}
                                        input={{
                                            size: "large",
                                            name: "page_description",
                                            value: details.page_description,
                                            disabled: isDisabled || !misc.includes("edit_SEO"),
                                            autosize: true
                                        }}
                                        textarea
                                        onChange={(e) => {
                                            setDetails({
                                                ...details,
                                                [e.target.name]: e.target.value
                                            })
                                        }}
                                        labelText="Page Description"
                                    />
                                </Col>

                                <Col sm={20}>
                                    <InputField
                                        label={{type: "secondary"}}
                                        input={{
                                            size: "large",
                                            name: "seo_description",
                                            value: details.seo_description,
                                            disabled: isDisabled || !misc.includes("edit_SEO"),
                                            autosize: true
                                        }}
                                        textarea
                                        onChange={(e) => {
                                            setDetails({
                                                ...details,
                                                [e.target.name]: e.target.value
                                            })
                                        }}
                                        labelText="SEO Description"
                                    />
                                </Col>

                                {
                                    (pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category" || pagetype == "brand") && !isDisabled &&
                                    <Col span={20} style={{
                                        marginBottom: "40px"
                                    }}>
                                        <FormHeader header="Short Description"/>
                                        <EditorBox
                                            disabled={isDisabled}
                                            value={shortDesc}
                                            getText={getEditorTextShortDesc}
                                            h1h2
                                        />
                                    </Col>

                                }

                                {
                                    (pagetype == "super_category" || pagetype == "category" || pagetype == "sub_category" || pagetype == "brand") && !isDisabled &&
                                    <Col span={20} style={{
                                        marginBottom: "40px"
                                    }}>
                                        <FormHeader header="Long Description"/>
                                        <EditorBox
                                            disabled={isDisabled}
                                            value={longDesc}
                                            getText={getEditorTextLongDesc}
                                            h1h2
                                        />
                                    </Col>

                                }


                                <Col sm={24}>
                                    <Button type="primary" loading={saveLoading} disabled={saveLoading}
                                            onClick={saveDetails}>
                                        Save
                                    </Button>
                                </Col>

                            </Row>
                        </React.Fragment>

                }
                <Modal
                    title="Generate SEO Report"
                    visible={downloadModel}
                    onCancel={() => {
                        setDownloadModel(false)
                    }}
                    onOk={handleOkDownload}
                    closable={false}
                    destroyOnClose={true}
                >
                    <Row gutter={[12, 18]}>
                        <Col span={14}>
                            <Text>Choose the SEO Type:</Text>
                            <Select
                                style={{width: "100%", display: "inline"}}
                                onChange={(e) => {
                                    setDownloadType(e)
                                }}
                                value={downloadType}
                            >
                                <Option value="product">Product</Option>
                                <Option value="subcategory">Sub Category</Option>
                                <Option value="category">Category</Option>
                                <Option value="supercategory">Super Category</Option>
                                <Option value="brandsubcategory">Brands Sub Category</Option>
                                <Option value="brandcategory">Brand Category</Option>
                                <Option value="brandsupercategory">Brand Super Category</Option>
                                <Option value="brand">Brand</Option>
                            </Select>
                        </Col>
                        <Col span={14}>
                            <TextArea placeholder="Note" onChange={(e) => {
                                setDownloadNote(e.target.value)
                            }} value={downloadNote}/>
                        </Col>

                    </Row>
                </Modal>
                {/*<Modal*/}
                {/*    title="Upload"*/}
                {/*    visible={uploadModel}*/}
                {/*    onCancel={() => {*/}
                {/*        setUploadModel(false)*/}
                {/*    }}*/}
                {/*    onOk={() => {*/}
                {/*        setUploadModel(false)*/}
                {/*    }}*/}
                {/*    closable={false}*/}
                {/*    destroyOnClose={true}*/}
                {/*>*/}
                {/*    <Row gutter={[12, 18]}>*/}
                {/*        <Col>*/}


                {/*        </Col>*/}

                {/*    </Row>*/}
                {/*</Modal>*/}

            </Row>
        </React.Fragment>
    )
}

function SeoAdmin(props) {
    return (
        <React.Fragment>
            <RightWrapper
                innerwrapperTransparent
                InnerWrapper={
                    <React.Fragment>
                        <InnerWrapper {...props} />
                    </React.Fragment>
                }
            />
        </React.Fragment>

    )
}

export default SeoAdmin;
