import React, { Component } from 'react';
import { Typography } from "antd";

const { Title } = Typography;

class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (<React.Fragment>
            <Title level={4} style={{ marginBottom: "0px", color: "white", fontSize: "16px" }}>{this.props.header}</Title>
        </React.Fragment>);
    }
}

export default InputField;