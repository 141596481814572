import React from "react";

import {Radio , Typography} from "antd";

const {Text} = Typography;

const UploadType = ({uploadType , setUploadType, channelname}) => {

    const types = {
        "Noon" : {
            values : ["search" , "price" , "stock" , "price and stock"],
            show : ["Search Products" , "Upload Price" , "Upload Stock" , "Upload Price and Stock"]
        },
        "Amazon UAE" : {
            values : ["search" , "price" , "stock" , "price and stock"],
            show : ["Search Products" , "Upload Price" , "Upload Stock" , "Upload Price and Stock"]
        },
        "Amazon UK" : {
            values : ["search" , "price" , "stock" , "price and stock"],
            show : ["Search Products" , "Upload Price" , "Upload Stock" , "Upload Price and Stock"]
        }
    }

    const radioStyle = {
        display: "block",
      };


    return (
        <React.Fragment>
        <Text>Choose the upload type:</Text><br />
        <Radio.Group onChange={(e) => setUploadType(e.target.value)} value={uploadType}>
            {
                types[channelname].values.map((e, index) => {
                    return (
                      <Radio style={radioStyle} value={e}>
                        <Text style={{ marginRight: "15px" }}>{types[channelname].show[index]}</Text>
                      </Radio>
                    )
                })
            }
        </Radio.Group>
    </React.Fragment>
    )
}

export default UploadType;