import React, { Component } from "react";
import TitleWithBreadCrumb from "../components/titlewithbreadcrumb/TitleWithBreadCrumb";
import { EditProductPageWrapper, EbppCol } from "./styled-components-library";
import { Row, Col, Button, Affix, message, Tooltip } from "antd";
import InputField from "../components/datainputs/InputField";
// import UploadImage from "../components/datainputs/upload-image/UploadImage";

import ImageManager from "../components/datainputs/image-manager/ImageManager";
import FormHeader from "../components/datainputs/FormHeader";
import InputNumberDimensions3 from "../components/datainputs/InputNumberDimesions3";
import "./edit-base-product-page.scss";
import VarientsInfoVital from "./vitali-info/VarientsInfoVital";
import VarientsInfoAttributes from "./vitali-info/VarientsInfoAttributes";
// import VarientsInfoDimensions from "./vitali-info/VarientsInfoDimensions";
import VarientsInfoPFL from "./vitali-info/VarientsInfoPFL";
// import EditorBox from '../components/EditorBox';
import {
  fetchBaseProductDetails,
  saveBaseProduct,
  setEmpty
} from "../../actions/index";

//validator
import SimpleReactValidator from "simple-react-validator";

import { connect } from "react-redux";
import BaseForm from "./BaseForm";
import { Redirect } from "react-router";

import isDev from "../../server";
const serverUrl = isDev();

class EditBaseProductVarientPage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Field is required",
        integer: "Must be number",
        default: "Validation has failed!" // will override all messages
      }
    });
    this.state = {  
      redirect: false,
      brandList: null,
      categories: null,
      category: "",
      subCategory: ""
    };
  }

  fetchCategories = () => {
    let data = {

    };
    fetch(`${serverUrl}/fetch-all-categories/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {

        if (data.status === 500)
          message.error("Internal Server Error!");
          else if(data.status === 405)
          {
            message.info("You are not authorised!")
          }
        else {
          this.setState({
            super_categories: data.super_category_list
          })
        }

      });
  }


  getSuperCategory = (e) => {
    this.setState({
      superCategory : e
    })
  }


  getCategory = (e) => {
    this.setState({
      category: e
    })
  }

  getSubCategory = (e) => {
    this.setState({
      subCategory: e
    })
  }

  componentDidMount = () => {
    this.fetchCategories();
    this.props.drawerOff();
    this.props.fetchBaseProductDetails(this.props.base_product_pk);

    fetch(`${serverUrl}/fetch-brands/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ brandList: data.brand_list });
      });
  };

  handelSave = () => {

    let superCat = this.state.super_categories[this.state.superCategory] || (this.state.super_categories && this.props.productDetails.super_category_uuid && this.state.super_categories.filter(
      (item) => item.super_category_uuid == this.props.productDetails.super_category_uuid)[0])
   
    let cat = (this.state.super_categories[this.state.superCategory] && this.state.super_categories[this.state.superCategory].category_list[this.state.category]) ||
              (superCat && superCat.category_list.filter(item => item.category_uuid == this.props.productDetails.category_uuid)[0])
    
    if(!cat){
      message.error("Select an appropriate Category and SubCategory to save");
      return;
    }
      
    let subcat = (this.state.super_categories[this.state.superCategory] && this.state.super_categories[this.state.superCategory].category_list[this.state.category].sub_category_list[this.state.subCategory]) || (cat && cat.sub_category_list.filter(item => item.sub_category_uuid == this.props.productDetails.sub_category_uuid)[0])

    if(!subcat){
      message.error("Select an appropriate Category and SubCategory to save");
      return;
    }

    if (!this.validator.allValid()) {
      message.info("Fill Required Details");
    } else {
      this.props.saveBaseProduct(
        this.props.base_product_pk,
        this.props.productDetails,
        cat,
        subcat
      );
    }
  };

  error = status => {
    if (status == 500) message.error("Internal Server Error");
    else if (status == 409) message.warning("Duplicate Product Detected");
    else if (status == 403) message.error("access denied");
    else {
      message.success(" Saved successfully! ");
    }
  };

  componentWillReceiveProps = props => {
    if (props.productDetails.status !== "") {
      this.error(props.productDetails.status);
    }
    console.log(props , " yha toh pakda gya mc ");
  };

  render() {

    const initSuperCat = (this.state.super_categories && this.props.productDetails.super_category_uuid && this.state.super_categories.filter(item => item.super_category_uuid ==
      this.props.productDetails.super_category_uuid)[0] || null)

    const initCat = (initSuperCat && initSuperCat.category_list && this.props.productDetails.category_uuid && initSuperCat.category_list.filter(
      (item) => item.category_uuid == this.props.productDetails.category_uuid)[0]) || null;

    const initSubCat = (initCat && initCat.sub_category_list.filter(item => item.sub_category_uuid == this.props.productDetails.sub_category_uuid)[0]) || null;

    console.log(initSuperCat , " " , initCat , " " , initSubCat);

    

    return (
      <React.Fragment>
        <EditProductPageWrapper>
          <TitleWithBreadCrumb
            title="Edit Base Product"
            breadcrumb={["OmnyComm", "Product Edit"]}
          />

          <Row gutter={[40, 40]} type="flex" justify="center">
            <Col className="gutter-row" lg={12} sm={24}>
              {this.props.productDetails && (
                <BaseForm
                  brandList={this.state.brandList || []}
                  validator={this.validator}
                  productDetails={this.props.productDetails}
                  super_categories={this.state.super_categories || []}
                  getSuperCategory = {this.getSuperCategory}
                  getCategory={this.getCategory}
                  getSubCategory={this.getSubCategory}
                  initiSuperCategory = {initSuperCat}
                  initiCategory={initCat}
                  initiSubCategory={initSubCat}
                  enableEdit={true}
                  base_product_pk={this.props.base_product_pk}
                />
              )}
            </Col>
            <Col className="gutter-row save-btn-edit-product-page" sm={24}>
              {/* <Row type="flex" justify="center" align="middle">
                                <Button onClick={this.handelSave} type="primary"> Save </Button>
                            </Row> */}
              <Tooltip placement="top" title="Save">
                <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
                  <Button
                    type="danger"
                    shape="circle"
                    icon="save"
                    size="large"
                    onClick={this.handelSave}
                  ></Button>
                </Affix>
              </Tooltip>
            </Col>
          </Row>
        </EditProductPageWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ productDetails }) => {
  return {
    ...productDetails
  };
};

export default connect(mapStateToProps, {
  fetchBaseProductDetails,
  saveBaseProduct,
  setEmpty
})(EditBaseProductVarientPage);
