import React from "react";
import { Row, Col } from 'antd';
import ImagePicker from 'react-image-picker'
import './CopyModal.scss'

class CopyModal extends React.Component{

    renderImage = (image, i) => {
        return(
            <div style={{position: "relative", marginRight: "20px", marginBottom: "10px", float:"left", width: "150px", height: "150px"}}>
                <img src={image.thumbnail_url} alt="" style={{width: 150, height: 150, objectFit: "cover", 
                    border:"1px solid #ddd", borderRadius: "4px", padding:"5px", opacity:1, margin:0}}/>
                <div className="checked">
                <div className="icon"/>
                </div>
            </div>
        )
    }

    render() {

        console.log(this.props.selectedImages , "SI")

        let imageList_copy = this.props.imageList["all_images"];
        imageList_copy = imageList_copy.filter((image) => {
            for(let i = 0;i<this.props.previousBestImages.length;i++){
                if(image.pk == this.props.previousBestImages[i].pk)
                 return false;
            }

            return true;
        })

        return( 
            <Row style={{maxHeight: 500, height: 500}}>
                <Col span={12} style={{marginBottom: 10}}>
                    <p>EXISTING IMAGES</p>
                </Col>
                <Col span={12} style={{marginBottom: 10}}>
                    <p>{this.props.bucketName.split("_").join(" ").toUpperCase()}</p>
                </Col>
                <Col span={12} style={{ maxHeight: 450, overflowY: "auto", height: 450}}>
                    <ImagePicker 
                        images={imageList_copy.map((image, i) => ({src: image.thumbnail_url, value: i}))}
                        onPick={(images) => this.props.onPick(images)}
                        style={{width: "100%"}}
                        multiple
                    />
                </Col>
                <Col span={12} style={{ maxHeight: 450, overflowY: "auto", height: 450, display: this.props.selectedImages.length ? "initial" : "flex",
                        justifyContent: this.props.selectedImages.length ? "initial" : "center", alignItems: this.props.selectedImages.length ? "initial" : "center"}}>
                    <div style={{maxWidth: "100%", width: this.props.selectedImages.length ? "100%" : "fit-content"}}>
                        { this.props.selectedImages.length ? this.props.selectedImages.map(this.renderImage) : "No items to show" }
                    </div>
                </Col>
            </Row>
        )
    }
}

export default CopyModal;