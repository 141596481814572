import { Typography, Row, Col, Button } from "antd";
import styled from "styled-components";
const { Title } = Typography;

export const ChannelProductPageWrapper = styled(Row)`
    width: 95%;
    background-color: #313841;
    height: 90vh;
`;

export const ExportPageWrapper = styled(Row)`
    width: 95%;
    background-color: #313841;
`;

export const EbppCol = styled(Col)`
   
    background-color : #36404A;
`;

export const FilterBtn = styled(Button)`
  && {
    background-color: #36404a;
    border: 1px solid #45525f;

    &:hover {
      background-color: #313841;
      color: white;
    }
  }
`;

export const ProductDetailsTitle = styled(Title)`
  color: #dee2e6 !important;
  margin: 0 !important;
`;

