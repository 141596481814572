import React, { Component } from "react";
import { Row, Col, Menu, Dropdown, Icon , Typography } from "antd";

import "./image-manager.scss";
import UploadImageAfter from "../upload-image/UploadImageAfter";

class ImageManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSelected: "MAIN IMAGES",
      hide: false,
      imagesData: {
        main_images: [],
        sub_images: [],
        pfl_images: [],
        pfl_generated_images: [],
        white_background_images: [],
        lifestyle_images: [],
        certificate_images: [],
        giftbox_images: [],
        diecut_images: [],
        aplus_content_images: [],
        ads_images: [],
        unedited_images: [],
        transparent_images: []
      },
      imageDataNoCategories :[]
    };
  }

  onClick = item => {
    let str = item.item.props.children[0];
    this.setState({ itemSelected: str });
  };

  // componentWillReceiveProps(props)
  // {
  //   if(props.images)
  //   {
  //     let listDemo = props.images.map(e => e.url);
  //     this.setState({
  //       imagesData : {
  //         ...this.state.imageData , 
  //         main_images : [...this.state.imagesData.main_images , ...listDemo]
  //       }
  //     })
  //   }
  // }

  handleUpload = fileList => {
    this.setState({
      imagesData: {
        ...this.state.imagesData,
        [this.state.itemSelected
          .split(" ")
          .join("_")
          .toLowerCase()]: fileList
      }
    } , () => this.props.updateImageList(this.state.imagesData));
  };

  handleRemove = file => {
    this.setState({
      imagesData: {
        ...this.state.imagesData,
        [this.state.itemSelected
          .split(" ")
          .join("_")
          .toLowerCase()]: this.state.imagesData[
            this.state.itemSelected
              .split(" ")
              .join("_")
              .toLowerCase()
          ].filter(e => {
            return e.uid !== file.uid;
          })
      }
    });

    setTimeout(() => {
      this.props.updateImageList(this.state.imagesData);
    }, 100);
  };

  render() {
    const menu = (
      <Menu onClick={this.onClick}>
        {this.state.imagesData &&
          Object.keys(this.state.imagesData).map((key, index) => (
            <Menu.Item key={index}>
              {key
                .split("_")
                .join(" ")
                .toUpperCase()}{" "}
              ({this.state.imagesData[key] && this.state.imagesData[key].length}
              )
            </Menu.Item>
          ))}
      </Menu>
    );

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col sm={24}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                style={{ color: "white" }}
                className="ant-dropdown-link"
                href="#"
              >
                {this.state.itemSelected} <Icon type="down" />
              </a>
            </Dropdown>
          </Col>
          <Col sm={24}>
            <UploadImageAfter
              fileList={
                this.state.imagesData &&
                this.state.imagesData[
                this.state.itemSelected
                  .split(" ")
                  .join("_")
                  .toLowerCase()
                ]
              }
              handleUpload={this.handleUpload}
              handleRemove={this.handleRemove}
              showUploadList={true}
              scroll={true}
              loading={this.props.loading}
              category={this.state.itemSelected
                .split(" ")
                .join("_")
                .toLowerCase()}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ImageManager;
