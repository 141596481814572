import React, { useState } from "react";
import { Modal, Row, Col, Typography, Button, Input, message } from "antd";
import axios from "axios";
import isDev from "../../../../server";

const { Text } = Typography;
const { TextArea } = Input;
const serverUrl = isDev();

const ProductPageExportNesto = ({ filters }) => {
    const [isVIsible, setIsVisible] = useState(false);
    const [note, setNote] = useState();
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        axios
            .post(
                `${serverUrl}/fetch-nesto-product-list/`,
                {
                    filter_parameters: { ...filters },
                    generate_report: true,
                    note: note ? note : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === 200) {
                    message.success("Report request submitted successfully!");
                } else {
                    message.info(
                        "Request capacity exceeded. Please try again later!"
                    );
                }
                setLoading(false);
                setIsVisible(false);
            });
    };

    return (
        <div>
            <Button
                style={{
                    width: "100%",
                    background: "#50C6E1",
                    color: "white",
                    border: "none",
                }}
                onClick={() => setIsVisible(true)}
            >
                Export
            </Button>
            <Modal
                title="Generate Filtered Report"
                visible={isVIsible}
                onCancel={() => setIsVisible(false)}
                onOk={handleSubmit}
                width="50%"
                okText="Generate"
                confirmLoading={loading}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Row>
                    <Text style={{ fontSize: 18, color: "white" }}>
                        Filters
                    </Text>
                    <Row style={{ padding: "10px 0px 20px" }}>
                        <Col xl={12} xs={24} sm={24} md={24} lg={24}>
                            <Text style={{ fontWeight: 600 }}>Is Online :</Text>{" "}
                            <Text style={{ fontWeight: 500, color: "white" }}>
                                {filters.is_online === true
                                    ? "True"
                                    : filters.is_online === false
                                    ? "False"
                                    : "None"}
                            </Text>
                        </Col>
                        <Col xl={12} xs={24} sm={24} md={24} lg={24}>
                            <Text style={{ fontWeight: 600 }}>
                                Is Verified :
                            </Text>{" "}
                            <Text style={{ fontWeight: 500, color: "white" }}>
                                {filters.is_verified === true
                                    ? "True"
                                    : filters.is_verified === false
                                    ? "False"
                                    : "None"}
                            </Text>
                        </Col>
                        <Col xl={12} xs={24} sm={24} md={24} lg={24}>
                            <Text style={{ fontWeight: 600 }}>Brand :</Text>{" "}
                            <Text style={{ fontWeight: 500, color: "white" }}>
                                {filters.brand_name
                                    ? filters.brand_name
                                    : "All Brands"}
                            </Text>
                        </Col>
                        <Col xl={12} xs={24} sm={24} md={24} lg={24}>
                            <Text style={{ fontWeight: 600 }}>Images :</Text>{" "}
                            <Text style={{ fontWeight: 500, color: "white" }}>
                                {filters.has_image
                                    ? filters.has_image === "0"
                                        ? "Without Images"
                                        : "With Images"
                                    : "All Images"}
                            </Text>
                        </Col>
                        <Col xl={12} xs={24} sm={24} md={24} lg={24}>
                            <Text style={{ fontWeight: 600 }}>
                                Image Type :
                            </Text>{" "}
                            <Text
                                style={{
                                    fontWeight: 500,
                                    color: "white",
                                    textTransform: "capitalize",
                                }}
                            >
                                {filters.image_type &&
                                filters.image_type !== "all"
                                    ? `${filters.image_type} Images`
                                    : "All Images"}
                            </Text>
                        </Col>
                        <Col xl={12} xs={24} sm={24} md={24} lg={24}>
                            <Text style={{ fontWeight: 600 }}>
                                Product Status :
                            </Text>{" "}
                            <Text
                                style={{
                                    fontWeight: 500,
                                    color: "white",
                                    textTransform: "capitalize",
                                }}
                            >
                                {filters.product_status &&
                                filters.product_status !== "all"
                                    ? filters.product_status === "not ecommerce"
                                        ? "Not Ecommerce Ready Product"
                                        : filters.product_status === "ecommerce"
                                        ? "Ecommerce Ready Product"
                                        : "Rich Product"
                                    : "All Products"}
                            </Text>
                        </Col>
                        <Col xl={12} xs={24} sm={24} md={24} lg={24}>
                            <Text style={{ fontWeight: 600 }}>
                                Vendor Category :
                            </Text>{" "}
                            <Text style={{ fontWeight: 500, color: "white" }}>
                                {filters.vendor_category &&
                                filters.vendor_category !== "all"
                                    ? filters.vendor_category
                                    : "All Products"}
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Text>Report Note :</Text>
                        <TextArea
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="Enter Report Note"
                            rows={3}
                        />
                    </Row>
                </Row>
            </Modal>
        </div>
    );
};

export default ProductPageExportNesto;
