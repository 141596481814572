import { Icon, Row, Col, Typography, Select, Button, Table } from "antd";
import styled from "styled-components";
const { Title } = Typography;
const { Text } = Typography;
const { Option } = Select;

export const ProductDetailsPageContainer = styled(Row)`
  width: 95%;
  background-color: #313841;
  height: 90vh;
`;

export const ProductDetailsContainer = styled(Row)`
  background-color: #36404a;
  min-height: 80vh;
  padding: 20px;
  border-radius: 5px;
  margin-top: -10px;
`;

export const ProductDetailsLeft = styled(Row)`
  /* background-color: red;
  min-height: 400px; */
  padding: 20px;
`;

export const ProductDetailsRight = styled(Row)`
  padding: 20px;
  position: relative;
`;

export const websiteGroupNameLink = styled(Button)`
  && {
    color: #9189bc;
    padding: 0px;

    &:hover {
      color: #9122dc;
    }
  }
`;

export const ProductName = styled(Title)`
  color: #dee2e6 !important;
  margin: 0 0 20px 0 !important;
`;

export const DiscountPercent = styled(Text)`
  color: #f1556c;
  font-size: 0.8rem;
  margin-bottom: 10px;
`;

export const ProductPriceRow = styled(Row)`
  margin: 10px 0 20px 0 !important;
  width: 100%;
`;

export const ProductPriceLabel = styled(Title)`
  color: #dee2e6 !important;
  margin: 0 10px 0 0 !important;
`;

export const ProductPrice = styled(Title)`
  color: #dee2e6 !important;
  margin: 0 20px 0 0 !important;
  color: #98a6ad !important;
`;

export const ProductPriceDiscounted = styled(Title)`
  color: #dee2e6 !important;
  margin: 0 !important;
`;

export const ProductStock = styled(Text)`
  color: #f1556c;
  font-weight: 600;
`;
export const ProductOutOfStock = styled(Text)`
  color: #1abc9c;
  font-weight: 600;
`;

export const ProductInfoText = styled(Text)`
  display: block;
  margin: 20px 0;
`;

export const ProductFeaturesContainer = styled(Row)`
  margin: 30px 0px !important;
`;

export const ProductFeatureRow = styled(Row)``;

export const ProductFeatureIcon = styled(Icon)`
  color: #1abc9c;
  font-size: 10px;
`;

export const ProductFeatureInfo = styled(Text)``;

export const ProductQtySizeRow = styled(Row)`
  margin-bottom: 30px;
`;

export const QtyLabel = styled(Text)`
  margin-right: 10px;
`;

export const QtySelect = styled(Select)`
  && {
    margin-right: 30px;
    .ant-select-selection-selected-value {
      color: #dee2e6;
    }
  }
`;

export const QtyOption = styled(Option)``;

export const SizeLabel = styled(Text)`
  margin-right: 10px;
`;

export const SizeSelect = styled(Select)`
  && {
    .ant-select-selection-selected-value {
      color: #dee2e6;
    }
  }
`;

export const SizeOption = styled(Option)``;

export const ProductCartContainer = styled(Row)``;

export const FavoriteBtn = styled(Button)`
  border: none;
  margin-right: 10px;
`;

export const AddToCartBtn = styled(Button)``;

export const ProductDetailsTable = styled(Table)`
  && {
    .ant-table-placeholder {
      background-color: #313841;
      border: 1px solid #495561 !important;
    }
  }
`;

export const NestoFormRow = styled(Row)`
  background-color: #36404A;
  padding: 16px 16px 0px;
  margin-bottom: 20px;
`;

export const NestoFormCol = styled(Col)`
  background-color: #36404A;
  padding: 16px 16px 0px;
  margin-bottom: 20px;
`;
