/**
 * @description This component is user for signing in and have username and password in state
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Icon,
  Row,
  Col,
  Input,
  Avatar,
  Button,
  Form,
  Typography,
  message,
} from "antd";
import Logo from "../../media/wig-logo.png";
import styled from "styled-components";

import "./sign-in.scss";
import { userLogin } from "../../actions";

import isDev from "../../server";

const serverUrl = isDev();
const { Text } = Typography;

const TextLoginInfo = styled(Text)`
  color: "#98a6ad";
`;

class SignInForm extends Component {
  /**
   * @description State of SignInForm
   * @type {{username: string, password: string}} state
   */
  state = {
    username: "priyanka",
    password: "p7165wig",
  };

  /**
   * @description Function to handle signin form event
   * @param {object} e - react submit event for signin form submit
   * @returns
   */

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          username: values.username,
          password: values.password,
        };
        fetch(`${serverUrl}/token-auth/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((data) => {
            data.non_field_errors && message.error(data.non_field_errors);
            // fetch and set brand for logged in user
            // fetch(`${serverUrl}/dealshub/fetch-user-website-group/`, {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //     Authorization: `JWT ${data.token}`
            //   },
            //   body: JSON.stringify({
            //     userName: data.user.username
            //   })
            // })
            //   .then(res => res.json())
            //   .then(brandData => {
            //     localStorage.setItem(
            //       "websiteGroupName",
            //       brandData.websiteGroupName
            //     );
            //   })
            //   .catch(error => {

            //   });

            localStorage.setItem("token", data.token);
            localStorage.setItem("username", data.user.username);

            localStorage.setItem("isLoggedIn", true);

            if (data.token) {
              // this.props.history.push("/");
              setTimeout(() => {
                this.props.dispatch(userLogin(data));
              }, 100);

              message.success("Logged in Successfully");
              window.location.reload();
            }
          })
          .catch((error) => {});
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ minHeight: "100vh", backgroundColor: "#495561" }}
        >
          <Col lg={8}>
            <Row
              type="flex"
              align="middle"
              justify="center"
              className="signin-form-container"
            >
              <Avatar
                src={Logo}
                size={68}
                shape="square"
                style={{ backgroundColor: "#E7F0FE", marginBottom: "20px" }}
              />
              <Col span={24}>
                <Row type="flex" justify="center">
                  <TextLoginInfo>
                    Enter your username and password to
                  </TextLoginInfo>
                  <TextLoginInfo>access admin panel.</TextLoginInfo>
                </Row>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <Form.Item>
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Username"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.user,
  };
};

const SignIn = Form.create({ name: "normal_login" })(SignInForm);

export default connect(mapStateToProps)(SignIn);
