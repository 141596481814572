import React, { Component, useState, useEffect } from 'react';

import {
    Row,
    Col,
    Button,
    message,
    Empty,
    Typography,
    Icon,
    Tag,
    Steps,
    Select,
    Checkbox,
    Input,
    Modal,
    Tooltip,
    Menu,
    Popconfirm,
    Progress,
    Dropdown,
} from 'antd';
import { Ellipsis } from 'react-spinners-css';
import { Link } from 'react-router-dom';
import OrderRow from 'views/order-rows/OrderRow';
import OrdersAccountsManagerFilterModal
    from '../components/modals/orders-accounts-manager-filter-modal/OrdersAccountsManagerFilterModal';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import axios from 'axios';
import styled from 'styled-components';
import isDev from '../../server';
import { useDispatch, useSelector } from 'react-redux';
import './orders-account-manager-page.scss';
import { saveAs } from '@progress/kendo-file-saver';
import WigLogoImage from 'media/WIGME.PNG';
import KryptonLogoImage from 'media/krypton-logo.png';
import ParajohnLogoImage from 'media/parajohn-logo.png';
import DayCartLogoImage from 'media/daycartLogo.svg';
import KuwaitLogoImage from 'media/WIGME-kuwait.png';
import B2bLogoImage from 'media/b2bLogo.png';
import GeepasLogo from "media/GEEPAS-logo2.jpeg";
import JSZip from 'jszip';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import OfflineOrder from 'views/offline-order/OfflineOrder';
import OrderDetails from 'views/order-details/OrderDetails';
import SalesOrderAnalytics from "../sales-order-analytics/SalesOrderAnalytics.jsx";

import createDOMPurify from "dompurify";

const serverOmnycommUrl = isDev();


const BaseProductContent1 = styled(Row)`
  //padding-bottom: 12px;
  width: 100%;
  background-color: #313841;
  margin-bottom: 12px;
`;

const { Title, Text } = Typography;
const { Step } = Steps;

const DOMPurify = createDOMPurify(window);

const DaycartArabicLogo = () => {
    return (
        <svg
            version='1.1'
            id='Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 117 30'
            width='400px'
        >
            <g>
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M28,25.47c-1.27,0-2.26,1-2.25,2.28c0.01,1.24,0.99,2.23,2.23,2.25c1.23,0.02,2.28-1.02,2.29-2.26
		C30.28,26.49,29.25,25.47,28,25.47z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M18.29,25.47c-1.26-0.01-2.27,0.99-2.28,2.24C16,28.96,17,29.99,18.25,30c1.24,0.01,2.27-1,2.28-2.25
		C20.54,26.49,19.54,25.48,18.29,25.47z'
                />
                <g>
                    <path
                        fill='#c9f427'
                        class='st0'
                        d='M38.04,10.65C37.35,4.56,32.28,0.02,26.15,0.02c-2.73,0-8.19,0-8.19,0l-4.29,0.01
			c-0.55,0.01-1.02,0.34-1.25,0.81c-0.09,0.18-0.14,0.38-0.14,0.6c0,0.63,0.41,1.15,0.98,1.33c0.13,0.04,0.26,0.07,0.41,0.07v0
			c0,0,9.83-0.01,12.6,0c4.98,0.03,9.04,4.14,9.02,9.11c-0.02,5-4.04,9.04-9.05,9.07c-3.08,0.02-6.16-0.01-9.24,0.02
			c-0.43,0-0.59-0.14-0.73-0.53c-2.4-6.62-4.81-13.23-7.2-19.85C8.9,0.14,8.68,0,8.16,0c-1,0-6.76,0-6.76,0
			C0.91,0.01,0.48,0.28,0.23,0.65C0.09,0.87,0,1.14,0,1.42c0,0.71,0.5,1.38,1.22,1.38c0.16,0,4.45,0.01,4.45,0.01
			c0.21,0,0.41,0,0.63,0c0.44,0,0.63,0.13,0.78,0.54c2.4,6.65,4.83,13.3,7.24,19.95c0.14,0.39,0.3,0.53,0.74,0.53
			c3.66-0.02,7.32-0.01,10.97-0.02c0.51,0,1.01-0.03,1.52-0.09C34.01,23.05,38.77,17.11,38.04,10.65z'
                    />
                    <path
                        fill='#c9f427'
                        class='st0'
                        d='M16.27,7.03c-0.57,0.01-1.38,0.51-1.38,1.41c0,0.6,0.38,1.11,0.9,1.31c0.15,0.06,0.31,0.09,0.47,0.1
			c0,0,8.01-0.01,9.89,0c1.55,0.01,2.57,1.48,1.98,2.87c-0.4,0.95-1.18,1.32-2.19,1.32c-1,0-7.28,0-7.28,0
			c-0.51,0.01-0.94,0.28-1.18,0.68c-0.12,0.21-0.2,0.45-0.2,0.71c0,0.66,0.47,1.22,1.1,1.36c0.09,0.02,0.19,0.03,0.29,0.03
			c0,0,6.45,0,7.53,0c0.3,0,0.59-0.02,0.89-0.07c2.49-0.43,4.24-2.75,4.01-5.14c-0.25-2.68-2.3-4.57-4.97-4.58
			C24.27,7.02,16.27,7.03,16.27,7.03z'
                    />
                </g>
            </g>
            <g>
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M50.66,11.83c0.71,0.16,1.33-0.46,1.18-1.18c-0.08-0.36-0.37-0.65-0.73-0.73c-0.71-0.16-1.33,0.46-1.18,1.18
		C50.02,11.46,50.31,11.75,50.66,11.83z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M58.97,12.61L58.97,12.61c-0.04-0.56-0.5-1-1.06-1c-0.57,0-1.02,0.44-1.06,1h-0.01v2.53
		c0,0.73-0.26,1.2-0.77,1.42c-0.23,0.11-0.65,0.15-1.27,0.15h-3.98c-1,0-1.71-0.12-2.16-0.39c-0.57-0.32-0.86-0.96-0.86-1.87V12.7
		c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02v-0.06h-0.01c-0.04-0.55-0.5-0.98-1.06-0.98c-0.56,0-1.01,0.43-1.06,0.98h-0.01v1.87
		c0,2.89,1.7,4.32,5.11,4.32h5.59c1.67,0,2.61-0.94,2.61-2.55v-3.54c0-0.01,0-0.01,0-0.02c0-0.01,0-0.01,0-0.02V12.61z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M53.63,11.83c0.71,0.16,1.33-0.46,1.18-1.18c-0.08-0.36-0.37-0.65-0.73-0.73c-0.71-0.16-1.33,0.46-1.18,1.18
		C52.99,11.46,53.28,11.75,53.63,11.83z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M87.86,13.16c0.15-0.01,0.42-0.03,0.61-0.07c0.67-0.14,0.92-0.42,0.92-0.98c0-0.56-0.35-0.96-1-1.04
		c-0.25-0.03-0.43-0.03-0.58-0.01c-1.4,0.11-2.5,0.5-3.37,1.47c-0.62,0.71-1.01,1.56-1.19,2.58c-0.11,0.63,0.38,1.21,1.02,1.21h2.92
		c-0.03,0.45-0.12,0.85-0.26,1.19c-0.48,1.11-1.65,1.84-3.12,1.84c-1.48,0-2.66-0.73-3.13-1.84c-0.19-0.46-0.28-1-0.28-1.62V14.3
		c0-0.01,0-0.03,0-0.04c0-0.6-0.49-1.08-1.08-1.08c-0.59,0-1.06,0.47-1.08,1.05h0v0.02c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01v1.81
		c0,1.5,0.53,2.75,1.59,3.75c1.07,1,2.38,1.51,3.94,1.51h0.09c1.56,0,2.86-0.51,3.92-1.51c1.07-1,1.59-2.25,1.59-3.75v-0.71
		c0-0.61-0.49-1.1-1.1-1.1h-2.48C86.3,13.53,86.88,13.2,87.86,13.16z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M62.88,12.72c0-0.6-0.49-1.09-1.09-1.09c-0.6,0-1.09,0.49-1.09,1.09c0,0,0,0.01,0,0.01h0v4.82
		c0,1.36-0.71,2.04-2.13,2.04v0c-0.39,0-0.75,0.26-0.85,0.68c-0.07,0.3,0.04,0.62,0.26,0.83c0.18,0.17,0.39,0.23,0.59,0.23v0
		c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0c1.47,0,2.54-0.3,3.2-0.89c0.73-0.63,1.08-1.65,1.08-3.07L62.88,12.72L62.88,12.72
		C62.88,12.73,62.88,12.72,62.88,12.72z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M82.59,22.74c-0.71-0.16-1.33,0.46-1.18,1.18c0.08,0.36,0.37,0.65,0.73,0.73c0.71,0.16,1.33-0.46,1.18-1.18
		C83.24,23.11,82.95,22.82,82.59,22.74z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M85.56,22.74c-0.71-0.16-1.33,0.46-1.18,1.18c0.08,0.36,0.37,0.65,0.73,0.73c0.71,0.16,1.33-0.46,1.18-1.18
		C86.21,23.11,85.92,22.82,85.56,22.74z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M93.25,8.84L93.25,8.84l0-1.43c0,0,0,0,0-0.01c0,0,0,0,0-0.01V7.38h0c-0.01-0.59-0.49-1.06-1.08-1.06
		c-0.59,0-1.07,0.47-1.08,1.06h0v1.33l0,0c0,0,0,0.56,0,1.01v0.94h0v8.09c0,0,0,0,0,0.01s0,0,0,0.01v0.01h0
		c0.01,0.59,0.49,1.06,1.08,1.06c0.59,0,1.07-0.47,1.08-1.06h0V15.5l0,0v-1.02l0,0v-3.81h0C93.25,9.64,93.25,8.84,93.25,8.84z'
                />
                <line
                    fill='#c9f427'
                    class='st0'
                    x1='64.79'
                    y1='18.78'
                    x2='64.79'
                    y2='18.78'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M72.53,11.4L72.53,11.4l-0.18,0h-1.96c0.33-0.49,2.29-3.4,2.3-3.4c0.33-0.51,0.19-1.18-0.3-1.5
		c-0.49-0.31-1.14-0.17-1.48,0.32l-3.06,4.72c-0.21,0.33-0.24,0.71-0.13,1.04c0.07,0.5,0.5,0.88,1.02,0.88h0.18h0.14h1.49h1.82
		c0.9,0,1.64,0.73,1.64,1.64c0,0.9-0.73,1.63-1.63,1.64l-4.2-0.01h-0.63c-0.88,0-0.76-0.91-0.76-1.57v-0.66l0,0v-3.81h0
		c0-1.03,0-1.83,0-1.83l0,0V7.41c0,0,0,0,0-0.01c0,0,0,0,0-0.01V7.38h0c-0.01-0.59-0.49-1.06-1.08-1.06c-0.59,0-1.07,0.47-1.08,1.06
		h0V8.7l0,0c0,0,0,1.6,0,1.6v0.36h0l0,0v5.57c0,1.59,0.96,2.55,2.61,2.55h1.19h-0.25l0.49,0c0.03,0,0.06,0,0.08,0h3.8v0
		c1.96-0.09,3.51-1.71,3.51-3.69C76.04,13.11,74.48,11.49,72.53,11.4z'
                />
                <path
                    fill='#c9f427'
                    class='st0'
                    d='M96.58,12.42c0,0.57,0.46,1.03,1.03,1.03l1.82,0c0.9,0,1.67,0.7,1.69,1.6c0.02,0.92-0.72,1.66-1.63,1.67
		l-3.48-0.01c-0.57,0-1.04,0.46-1.04,1.03v0c0,0.57,0.46,1.03,1.03,1.03l3.66,0v0c1.96-0.09,3.51-1.71,3.51-3.69
		c0-1.98-1.56-3.6-3.51-3.69v0h-0.18h-1.87C97.04,11.39,96.58,11.85,96.58,12.42L96.58,12.42z'
                />
            </g>
        </svg>
    );
};

const CallStatusTag = (props) => {
    const [tag, setTag] = useState('');
    useEffect(() => {
        setTag(props.tag);
    }, [props.tag]);

    const setCallTag = (selectedTag) => {
        setTag(selectedTag);
        axios({
            method: 'POST',
            url: `${serverOmnycommUrl}/dealshub/set-call-status/`,
            data: {
                call_status: selectedTag,
                orderUuid: props.uuid,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then(() => {
        });
    };

    return (
        <Dropdown
            trigger={['click']}
            overlay={
                <Menu>
                    <Menu.Item onClick={() => setCallTag('Unconfirmed')}>
                        Unconfirmed
                    </Menu.Item>
                    <Menu.Item onClick={() => setCallTag('Confirmed')}>
                        Confirmed
                    </Menu.Item>
                    <Menu.Item onClick={() => setCallTag('No Response')}>
                        No Response
                    </Menu.Item>
                </Menu>
            }
        >
            <Tag
                color='purple'
                style={{ zIndex: '10' }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                Call Status - {tag}
            </Tag>
        </Dropdown>
    );
};

const ManualTag = (props) => {
    const [loading, setLoading] = useState(false);

    const setCallTag = () => {
        setLoading(true);
        axios({
            method: 'POST',
            url: `${serverOmnycommUrl}/dealshub/update-manual-order/`,
            data: {
                orderUuid: props.uuid,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then((response) => {
            if (response.data.status === 200) {
                props.updateManualOrderToSuccess(props.uuid);
                setLoading(false);
            } else {
                message.error('Something went wrong!');
            }
        });
    };

    return (
        <Dropdown
            trigger={['click']}
            overlay={
                <Menu>
                    <Menu.Item onClick={() => setCallTag('Success')}>Success</Menu.Item>
                </Menu>
            }
        >
            <Tag
                color='purple'
                onClick={(e) => {
                    e.stopPropagation();
                }}
                style={{ zIndex: '10' }}
            >
                SAP Status - {loading ? <Icon type='loading' /> : 'Manual'}
            </Tag>
        </Dropdown>
    );
};

const PdfTemplate = ({ invoiceModalOrder, refFetch, refFetch1, isB2b }) => {

    const clientTRN = () => {
        try {
            const TRN = invoiceModalOrder.shippingAddress.vatCertificateId
            if (!TRN) {
                throw new Error('TRN Number is Undefined')
            }

            if (invoiceModalOrder.website_group_name !== "shopnestob2b") {
                throw new Error('TRN Number only allowed for Wigme B2B at this point')
            }

            return (
                <>
                    <br />
                    <Text className='title'>TRN:</Text>
                    <Text> {TRN}</Text>
                </>
            )

        } catch (e) {
            return <></>
        }
    }

    return (
      <>
        <div
          ref={(div) => {
            refFetch(div);
          }}
        >
          <Row
            gutter={[8, 8]}
            style={{
              marginBottom:
                invoiceModalOrder.website_group_name === "daycart"
                  ? "10px"
                  : "0px",
            }}
            className="order-invoice-wrapper"
          >
            <Col span={24} className="order-invoice-inner-col">
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <img
                    alt={`${invoiceModalOrder.website_group_name} me logo`}
                    style={{ width: "400px" }}
                    src={
                      invoiceModalOrder.website_group_name === "shopnesto"
                        ? WigLogoImage
                        : invoiceModalOrder.website_group_name ===
                          "kryptonworld"
                        ? KryptonLogoImage
                        : invoiceModalOrder.website_group_name === "parajohn"
                        ? ParajohnLogoImage
                        : invoiceModalOrder.website_group_name ===
                          "shopnestokuwait"
                        ? KuwaitLogoImage
                        : invoiceModalOrder.website_group_name ===
                          "shopnestob2b"
                        ? B2bLogoImage
                        : invoiceModalOrder.website_group_name ===
                          "shopnestobahrain"
                        ? WigLogoImage
                        : invoiceModalOrder.website_group_name === "daycart"
                        ? DayCartLogoImage
                        : invoiceModalOrder.website_group_name ===
                          "geepasuganda"
                        ? GeepasLogo
                        : null
                    }
                  />
                </Col>
                <Col span={4}></Col>
                <Col span={7} offset={5}>
                  <Row>
                    <Col span={24}>
                      <Text>
                        Call us on: {invoiceModalOrder.support_contact_number}
                      </Text>
                    </Col>
                    {invoiceModalOrder.website_group_name !==
                      "shopnestokuwait" && (
                      <Col span={24}>
                        <Text>TRN: {invoiceModalOrder.trn_number}</Text>
                      </Col>
                    )}
                    <Col span={24}>
                      <Text>Contact Us:</Text>
                    </Col>
                    {invoiceModalOrder.website_group_name ===
                    "shopnestobahrain" ? (
                      <React.Fragment>
                        <Col span={24}>
                          <Text>
                            Westline International Trading Company W.L.L,
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Building 1000, Road 5218, Block 9,</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Ras Zuwayed,</Text>
                        </Col>
                        <Col span={24}>
                          <Text> Sitra, Bahrain</Text>
                        </Col>
                      </React.Fragment>
                    ) : invoiceModalOrder.website_group_name ===
                      "shopnestokuwait" ? (
                      <React.Fragment>
                        <Col span={24}>
                          <Text>Al Waha International General</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Trading Company, Al Bahar Building,</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Second Floor, Office No:1</Text>
                        </Col>
                        <Col span={24}>
                          <Text>New Street,</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Mubarakiya, Kuwait</Text>
                        </Col>
                      </React.Fragment>
                    ) : invoiceModalOrder.website_group_name === "daycart" ? (
                      <React.Fragment>
                        <Col span={24}>
                          <Text>OFFICE TOWER, TAJ CENTER</Text>
                        </Col>
                        <Col span={24}>
                          <Text>BATHA, RIYADH</Text>
                        </Col>
                      </React.Fragment>
                    ) : invoiceModalOrder.website_group_name ===
                      "geepasuganda" ? (
                      <React.Fragment>
                        <Col span={24}>
                          <Text>Afro Finance and Leasing Limited </Text>
                        </Col>
                        <Col span={24}>
                          <Text>
                            Geepas Exclusive Showroom and Service Center{" "}
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text>
                            Madhvani Foundation Building, Opposite TOTAL Fuel
                            Station
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Jinja Road ,Kampala </Text>
                        </Col>
                        <Col span={24}>
                          <Text>Uganda</Text>
                        </Col>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Col span={24}>
                          <Text>P.O. Box #18742,</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Techno Park,</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Jebel Ali,</Text>
                        </Col>
                        <Col span={24}>
                          <Text>Dubai,</Text>
                        </Col>
                        <Col span={24}>
                          <Text>U.A.E</Text>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="order-invoice-inner-col">
              <Text className="title">
                Order ID : {invoiceModalOrder.bundleId}
              </Text>
              <br />
              {invoiceModalOrder?.sap_final_billing_info?.doc_list?.filter(
                (e) => e.type === "INV"
              ) && (
                <>
                  <Text className="title">
                    INV ID :{" "}
                    {invoiceModalOrder?.sap_final_billing_info?.doc_list.map(
                      (e) => {
                        if (e.type !== "INV") return;
                        return e.id;
                      }
                    )}
                  </Text>
                  <br />
                </>
              )}
              <Text className="title">Order Date & Time : </Text>
              <Text>
                {invoiceModalOrder.dateCreated} {invoiceModalOrder.time}
              </Text>
              <br />
              <Text className="title">Payment Method : </Text>
              <Text>
                {invoiceModalOrder.paymentMode === "COD"
                  ? "Cash On Delivery"
                  : invoiceModalOrder.paymentMode}
              </Text>
            </Col>
            <Col span={24} className="order-invoice-inner-col">
              <Text className="title-large">CUSTOMER DETAILS</Text>
            </Col>
            <Col span={24} className="order-invoice-inner-col">
              <Row style={{ display: "flex", justifyContent: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    paddingRight: "40px",
                  }}
                >
                  <Col style={{ float: "left" }}>
                    <Text
                      style={{
                        padding: "10px 0px",
                        borderBottom: "2px solid #e8e8e8",
                      }}
                      className="title-large"
                    >
                      Delivery Address
                    </Text>
                    <br />
                  </Col>
                  <Col
                    style={{
                      marginTop: "15px",
                    }}
                    // span={12}
                  >
                    <Text className="title">
                      {invoiceModalOrder.shippingAddress.firstName}{" "}
                      {invoiceModalOrder.shippingAddress.lastName}
                    </Text>
                    {invoiceModalOrder.shippingAddress.line1 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line1}</Text>
                      </React.Fragment>
                    )}

                    {invoiceModalOrder.shippingAddress.line2 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line2}</Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.line3 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line3}</Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.line4 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line4}</Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.emirates !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>
                          {invoiceModalOrder.website_group_name ===
                          "geepasuganda"
                            ? "Region"
                            : invoiceModalOrder.website_group_name ===
                              "shopnestokuwait"
                            ? "Governorate"
                            : "Emirates"}
                          : {invoiceModalOrder.shippingAddress.emirates}
                        </Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.state !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.state}</Text>
                      </React.Fragment>
                    )}
                    <br />
                  </Col>
                  <Col
                  // span={12}
                  >
                    <Text className="title">Mobile No:</Text>
                    <Text> {invoiceModalOrder.contactNumer}</Text>
                    <br />
                    <Text className="title">Email:</Text>
                    <Text> {invoiceModalOrder.emailId}</Text>
                    {clientTRN()}
                  </Col>
                </div>
                {isB2b &&
                  Object.keys(invoiceModalOrder.billingAddress).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        paddingRight: "40px",
                      }}
                    >
                      <Col style={{ float: "left" }}>
                        <Text
                          style={{
                            padding: "10px 0px",
                            borderBottom: "2px solid #e8e8e8",
                          }}
                          className="title-large"
                        >
                          Billing Address
                        </Text>
                        <br />
                      </Col>
                      <Col
                        style={{
                          marginTop: "15px",
                        }}
                        // span={24}
                      >
                        <Text className="title">
                          {invoiceModalOrder.billingAddress.firstName}{" "}
                          {invoiceModalOrder.billingAddress.lastName}
                        </Text>
                        {invoiceModalOrder.billingAddress.line1 !== "" && (
                          <React.Fragment>
                            <br />
                            <Text>
                              {invoiceModalOrder.billingAddress.line1}
                            </Text>
                          </React.Fragment>
                        )}

                        {invoiceModalOrder.billingAddress.line2 !== "" && (
                          <React.Fragment>
                            <br />
                            <Text>
                              {invoiceModalOrder.billingAddress.line2}
                            </Text>
                          </React.Fragment>
                        )}
                        {invoiceModalOrder.billingAddress.line3 !== "" && (
                          <React.Fragment>
                            <br />
                            <Text>
                              {invoiceModalOrder.billingAddress.line3}
                            </Text>
                          </React.Fragment>
                        )}
                        {invoiceModalOrder.billingAddress.line4 !== "" && (
                          <React.Fragment>
                            <br />
                            <Text>
                              {invoiceModalOrder.billingAddress.line4}
                            </Text>
                          </React.Fragment>
                        )}
                        {invoiceModalOrder.billingAddress.emirates !== "" && (
                          <React.Fragment>
                            <br />
                            <Text>
                            {invoiceModalOrder.website_group_name ===
                            "geepasuganda"
                                ? "Region" :
                                invoiceModalOrder.website_group_name ===
                              "shopnestokuwait"
                                ? "Governorate"
                                : "Emirates "}
                              : {invoiceModalOrder.billingAddress.emirates}
                            </Text>
                          </React.Fragment>
                        )}
                        {invoiceModalOrder.billingAddress.state !== "" && (
                          <React.Fragment>
                            <br />
                            <Text>
                              {invoiceModalOrder.billingAddress.state}
                            </Text>
                          </React.Fragment>
                        )}
                        <br />
                      </Col>
                    </div>
                  )}
              </Row>
            </Col>
            <Col span={24} className="order-invoice-inner-col">
              <Text className="title-large">ORDER SUMMARY</Text>
            </Col>
            <Col span={24} className="table-wrapper">
              <Row
                style={{ display: "flex" }}
                className="orders-listing-table-wrapper"
              >
                <Col span={3}>
                  <Text className="title">REF</Text>
                </Col>
                <Col span={4}>
                  <Text className="title">Item Name</Text>
                </Col>
                <Col span={2}>
                  <Text className="title">QTY</Text>
                </Col>

                <Col span={3}>
                  <Text className="title">{`UnitPrice(${invoiceModalOrder.currency})`}</Text>
                  <Text className="title">(Exclusive</Text>
                  <br />
                  <Text className="title">of VAT)</Text>
                </Col>

                <Col span={3}>
                  <Text className="title">{`UnitPrice(${invoiceModalOrder.currency})`}</Text>
                  <Text className="title">(Inclusive</Text>
                  <br />
                  <Text className="title">of VAT)</Text>
                </Col>

                <Col span={3}>
                  <Text className="title">{`Total(${invoiceModalOrder.currency})`}</Text>
                  <Text className="title">(Exclusive of VAT)</Text>
                </Col>

                <Col span={3}>
                  <Text className="title">{`VAT(${invoiceModalOrder.currency})`}</Text>
                </Col>

                <Col span={3}>
                  <Text className="title">{`Total(${invoiceModalOrder.currency})`}</Text>
                  <Text className="title">(Inclusive of VAT)</Text>
                </Col>
              </Row>

              {invoiceModalOrder.unitOrderList.map((unitOrder) => (
                <Row
                  style={{ display: "flex" }}
                  className="orders-listing-table-wrapper prevent-split"
                >
                  <Col span={3}>
                    <Text className="title">{unitOrder.orderId}</Text>
                  </Col>
                  <Col span={4}>
                    <Text className="title">{unitOrder.productName}</Text>
                  </Col>
                  <Col span={2}>
                    <Text className="title">{unitOrder.quantity}</Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{unitOrder.price_without_vat}</Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{unitOrder.price}</Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">
                      {unitOrder.total_price_without_vat}
                    </Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{unitOrder.vat}</Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{unitOrder.totalPrice}</Text>
                  </Col>
                </Row>
              ))}

              <Row
                style={{ display: "flex" }}
                className="orders-listing-table-wrapper"
              >
                <Col style={{ textAlign: "right" }} span={15}>
                  <Text className="title">
                    {`SUB TOTAL (${invoiceModalOrder.currency}) :`}
                  </Text>
                </Col>

                <Col className="title" style={{ textAlign: "right" }} span={3}>
                  {invoiceModalOrder.subtotalWithoutVat}
                </Col>
                <Col className="title" style={{ textAlign: "right" }} span={3}>
                  {invoiceModalOrder.subtotalVat}
                </Col>
                <Col style={{ textAlign: "right" }} span={3}>
                  <Text className="title">{invoiceModalOrder.subtotal}</Text>
                </Col>
              </Row>

              {invoiceModalOrder.isVoucherApplied && (
                <Row
                  style={{ display: "flex" }}
                  className="orders-listing-table-wrapper"
                >
                  <Col style={{ textAlign: "right" }} span={15}>
                    <Text className="title">
                      VOUCHER DISCOUNT ({invoiceModalOrder.voucherCode}) :
                    </Text>
                  </Col>

                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.voucherDiscountWithoutVat}
                  </Col>
                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.voucherDiscountVat}
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">
                      {invoiceModalOrder.voucherDiscount}
                    </Text>
                  </Col>
                </Row>
              )}

              <Row
                style={{ display: "flex" }}
                className="orders-listing-table-wrapper"
              >
                <Col style={{ textAlign: "right" }} span={15}>
                  <Text className="title">
                    {`SHIPPING COST (Order Value Below ${invoiceModalOrder.currency} ${invoiceModalOrder.free_delivery_threshold}, Shipping Charge ${invoiceModalOrder.currency} ${invoiceModalOrder.shipping_charge}) (${invoiceModalOrder.currency}) :`}
                  </Text>
                </Col>
                <Col className="title" style={{ textAlign: "right" }} span={3}>
                  {invoiceModalOrder.deliveryFeeWithoutVat}
                </Col>
                <Col className="title" style={{ textAlign: "right" }} span={3}>
                  {invoiceModalOrder.deliveryFeeVat}
                </Col>
                <Col style={{ textAlign: "right" }} span={3}>
                  <Text className="title">{invoiceModalOrder.deliveryFee}</Text>
                </Col>
              </Row>

              <Row
                style={{ display: "flex" }}
                className="orders-listing-table-wrapper"
              >
                <Col style={{ textAlign: "right" }} span={15}>
                  <Text className="title">
                    {`Cash On Delivery Charges (${invoiceModalOrder.currency}) :`}
                  </Text>
                </Col>
                <Col className="title" style={{ textAlign: "right" }} span={3}>
                  {invoiceModalOrder.codFeeWithoutVat}
                </Col>
                <Col className="title" style={{ textAlign: "right" }} span={3}>
                  {invoiceModalOrder.codFeeVat}
                </Col>
                <Col style={{ textAlign: "right" }} span={3}>
                  <Text className="title">{invoiceModalOrder.codFee}</Text>
                </Col>
              </Row>

              {/* <Row
															style={{ display: "flex" }}
															className="orders-listing-table-wrapper"
														>
															<Col
															style={{ textAlign: "right" }}
															span={12}
															>
															<Text className="title">
																Cash On Delivery Charges (AED) :
															</Text>
															</Col>
															<Col style={{ textAlign: "right" }} span={3}>4.74</Col>
															<Col style={{ textAlign: "right" }} span={3}>0.26</Col>
															<Col
															style={{ textAlign: "right" }}
															span={6}
															>
															<Text className="title">5.00</Text>
															</Col>
														</Row> */}

              <Row
                style={{ display: "flex" }}
                className="orders-listing-table-wrapper"
              >
                <Col style={{ textAlign: "right" }} span={15}>
                  <Text className="title">
                    {`ORDER TOTAL (${invoiceModalOrder.currency}) :`}
                  </Text>
                </Col>
                <Col style={{ textAlign: "right" }} span={3}>
                  <Text className="title">
                    {invoiceModalOrder.toPayWithoutVat}
                  </Text>
                </Col>
                <Col style={{ textAlign: "right" }} span={3}>
                  <Text className="title">{invoiceModalOrder.vat}</Text>
                </Col>
                <Col style={{ textAlign: "right" }} span={3}>
                  <Text className="title">{invoiceModalOrder.toPay}</Text>
                </Col>
              </Row>

              <Text></Text>
              <br />
              <div
                style={{ margin: "10px 0px" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(invoiceModalOrder.footer_text),
                }}
              ></div>
            </Col>
          </Row>
        </div>
        {invoiceModalOrder.website_group_name === "daycart" && (
          <div ref={(div) => refFetch1(div)}>
            <Row
              gutter={[8, 8]}
              style={{ direction: "rtl" }}
              className="order-invoice-wrapper"
            >
              <Col span={24} className="order-invoice-inner-col">
                <Row gutter={[8, 8]}>
                  <Col span={7}>
                    <Row>
                      <Col span={24}>
                        <Text>
                          اتصل بنا: {invoiceModalOrder.support_contact_number}
                        </Text>
                      </Col>
                      {invoiceModalOrder.website_group_name !==
                        "shopnestokuwait" && (
                        <Col span={24}>
                          <Text>TRN: {invoiceModalOrder.trn_number}</Text>
                        </Col>
                      )}
                      <Col span={24}>
                        <Text>اتصل:</Text>
                      </Col>
                      <React.Fragment>
                        <Col span={24}>
                          <Text>مركز تاج مكتب رقم : 511</Text>
                        </Col>
                        <Col span={24}>
                          <Text> شارع الملك فيصل</Text>
                        </Col>
                        <Col span={24}>
                          <Text>حي الديرة.الرياض.</Text>
                        </Col>
                      </React.Fragment>
                    </Row>
                  </Col>

                  <Col span={5} offset={4}></Col>

                  <Col span={8}>
                    <DaycartArabicLogo />
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="order-invoice-inner-col">
                <Text className="title">
                  رقم التعريف الخاص بالطلب: {invoiceModalOrder.bundleId}
                </Text>
                <br />
                {invoiceModalOrder?.sap_final_billing_info?.doc_list?.filter(
                  (e) => e.type === "INV"
                ) && (
                  <>
                    <Text className="title">
                      هوية صوتية :{" "}
                      {invoiceModalOrder?.sap_final_billing_info?.doc_list.map(
                        (e) => {
                          if (e.type !== "INV") return;
                          return e.id;
                        }
                      )}
                    </Text>
                    <br />
                  </>
                )}
                <Text className="title"> :وقت تاريخ الطلب </Text>
                <Text>
                  {invoiceModalOrder.dateCreated} {invoiceModalOrder.time}
                </Text>
              </Col>
              <Col span={24} className="order-invoice-inner-col">
                <Text className="title-large">تفاصيل العميل</Text>
              </Col>
              <Col span={24} className="order-invoice-inner-col">
                <Row>
                  <Col style={{ float: "left" }}>
                    <Text
                      style={{
                        padding: "10px 0px",
                        borderBottom: "2px solid #e8e8e8",
                      }}
                      className="title-large"
                    >
                      عنوان التسليم
                    </Text>
                    <br />
                  </Col>
                  <Col
                    style={{
                      marginTop: "15px",
                    }}
                    span={24}
                  >
                    <Text className="title">
                      {invoiceModalOrder.shippingAddress.firstName}{" "}
                      {invoiceModalOrder.shippingAddress.lastName}
                    </Text>
                    {invoiceModalOrder.shippingAddress.line1 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line1}</Text>
                      </React.Fragment>
                    )}

                    {invoiceModalOrder.shippingAddress.line2 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line2}</Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.line3 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line3}</Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.line4 !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.line4}</Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.emirates !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>
                          {invoiceModalOrder.website_group_name ===
                          "shopnestokuwait"
                            ? "محافظة"
                            : "الإمارات"}{" "}
                          : {invoiceModalOrder.shippingAddress.emirates}
                        </Text>
                      </React.Fragment>
                    )}
                    {invoiceModalOrder.shippingAddress.state !== "" && (
                      <React.Fragment>
                        <br />
                        <Text>{invoiceModalOrder.shippingAddress.state}</Text>
                      </React.Fragment>
                    )}
                    <br />
                  </Col>
                  <Col span={24}>
                    <Text className="title">رقم المحمول:</Text>
                    <Text> {invoiceModalOrder.contactNumer}</Text>
                    <br />
                    <Text className="title">البريد الإلكتروني:</Text>
                    <Text> {invoiceModalOrder.emailId}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="order-invoice-inner-col">
                <Text className="title-large">ملخص الطلب</Text>
              </Col>
              <Col span={24} className="table-wrapper">
                <Row
                  style={{ display: "flex" }}
                  className="orders-listing-table-wrapper"
                >
                  <Col span={3}>
                    <Text className="title">مرجع</Text>
                  </Col>
                  <Col span={4}>
                    <Text className="title">اسم العنصر</Text>
                  </Col>
                  <Col span={2}>
                    <Text className="title">كمية</Text>
                  </Col>

                  <Col span={3}>
                    <Text className="title">{`سعر الوحدة(${invoiceModalOrder.currency})`}</Text>
                    <Text className="title">(حصرية</Text>
                    <br />
                    <Text className="title">ضريبة القيمة المضافة)</Text>
                  </Col>

                  <Col span={3}>
                    <Text className="title">{`سعر الوحدة(${invoiceModalOrder.currency})`}</Text>
                    <Text className="title">(شامل</Text>
                    <br />
                    <Text className="title">ضريبة القيمة المضافة)</Text>
                  </Col>

                  <Col span={3}>
                    <Text className="title">{`إجمالي(${invoiceModalOrder.currency})`}</Text>
                    <Text className="title">
                      (باستثناء ضريبة القيمة المضافة)
                    </Text>
                  </Col>

                  <Col span={3}>
                    <Text className="title">{`VAT(${invoiceModalOrder.currency})`}</Text>
                  </Col>

                  <Col span={3}>
                    <Text className="title">{`إجمالي(${invoiceModalOrder.currency})`}</Text>
                    <Text className="title">(شامل ضريبة القيمة المضافة)</Text>
                  </Col>
                </Row>

                {invoiceModalOrder.unitOrderList.map((unitOrder) => (
                  <Row
                    style={{ display: "flex" }}
                    className="orders-listing-table-wrapper prevent-split"
                  >
                    <Col span={3}>
                      <Text className="title">{unitOrder.orderId}</Text>
                    </Col>
                    <Col span={4}>
                      <Text className="title">{unitOrder.productName}</Text>
                    </Col>
                    <Col span={2}>
                      <Text className="title">{unitOrder.quantity}</Text>
                    </Col>
                    <Col style={{ textAlign: "right" }} span={3}>
                      <Text className="title">
                        {unitOrder.price_without_vat}
                      </Text>
                    </Col>
                    <Col style={{ textAlign: "right" }} span={3}>
                      <Text className="title">{unitOrder.price}</Text>
                    </Col>
                    <Col style={{ textAlign: "right" }} span={3}>
                      <Text className="title">
                        {unitOrder.total_price_without_vat}
                      </Text>
                    </Col>
                    <Col style={{ textAlign: "right" }} span={3}>
                      <Text className="title">{unitOrder.vat}</Text>
                    </Col>
                    <Col style={{ textAlign: "right" }} span={3}>
                      <Text className="title">{unitOrder.totalPrice}</Text>
                    </Col>
                  </Row>
                ))}

                <Row
                  style={{ display: "flex" }}
                  className="orders-listing-table-wrapper"
                >
                  <Col style={{ textAlign: "right" }} span={15}>
                    <Text className="title">
                      {`حاصل الجمع (${invoiceModalOrder.currency}) :`}
                    </Text>
                  </Col>

                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.subtotalWithoutVat}
                  </Col>
                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.subtotalVat}
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{invoiceModalOrder.subtotal}</Text>
                  </Col>
                </Row>

                {invoiceModalOrder.isVoucherApplied && (
                  <Row
                    style={{ display: "flex" }}
                    className="orders-listing-table-wrapper"
                  >
                    <Col style={{ textAlign: "right" }} span={15}>
                      <Text className="title">
                        خصم قسيمة ({invoiceModalOrder.voucherCode}) :
                      </Text>
                    </Col>

                    <Col
                      className="title"
                      style={{ textAlign: "right" }}
                      span={3}
                    >
                      {invoiceModalOrder.voucherDiscountWithoutVat}
                    </Col>
                    <Col
                      className="title"
                      style={{ textAlign: "right" }}
                      span={3}
                    >
                      {invoiceModalOrder.voucherDiscountVat}
                    </Col>
                    <Col style={{ textAlign: "right" }} span={3}>
                      <Text className="title">
                        {invoiceModalOrder.voucherDiscount}
                      </Text>
                    </Col>
                  </Row>
                )}

                <Row
                  style={{ display: "flex" }}
                  className="orders-listing-table-wrapper"
                >
                  <Col style={{ textAlign: "right" }} span={15}>
                    <Text className="title">
                      {`تكلفة الشحن ( قيمة الطلب أدناه ${invoiceModalOrder.currency} ${invoiceModalOrder.free_delivery_threshold}, الشحن`}
                    </Text>
                    <Text className="title">
                      {`ثمن ${invoiceModalOrder.currency} ${invoiceModalOrder.shipping_charge}) (${invoiceModalOrder.currency}) :`}
                    </Text>
                  </Col>
                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.deliveryFeeWithoutVat}
                  </Col>
                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.deliveryFeeVat}
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">
                      {invoiceModalOrder.deliveryFee}
                    </Text>
                  </Col>
                </Row>

                <Row
                  style={{ display: "flex" }}
                  className="orders-listing-table-wrapper"
                >
                  <Col style={{ textAlign: "right" }} span={15}>
                    <Text className="title">
                      {`الدفع نقدًا عند رسوم التوصيل (${invoiceModalOrder.currency}) :`}
                    </Text>
                  </Col>
                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.codFeeWithoutVat}
                  </Col>
                  <Col
                    className="title"
                    style={{ textAlign: "right" }}
                    span={3}
                  >
                    {invoiceModalOrder.codFeeVat}
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{invoiceModalOrder.codFee}</Text>
                  </Col>
                </Row>

                {/* <Row
														style={{ display: "flex" }}
														className="orders-listing-table-wrapper"
													>
														<Col
														style={{ textAlign: "right" }}
														span={12}
														>
														<Text className="title">
															Cash On Delivery Charges (AED) :
														</Text>
														</Col>
														<Col style={{ textAlign: "right" }} span={3}>4.74</Col>
														<Col style={{ textAlign: "right" }} span={3}>0.26</Col>
														<Col
														style={{ textAlign: "right" }}
														span={6}
														>
														<Text className="title">5.00</Text>
														</Col>
													</Row> */}

                <Row
                  style={{ display: "flex" }}
                  className="orders-listing-table-wrapper"
                >
                  <Col style={{ textAlign: "right" }} span={15}>
                    <Text className="title">
                      {`الطلب الكلي (${invoiceModalOrder.currency}) :`}
                    </Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">
                      {invoiceModalOrder.toPayWithoutVat}
                    </Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{invoiceModalOrder.vat}</Text>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={3}>
                    <Text className="title">{invoiceModalOrder.toPay}</Text>
                  </Col>
                </Row>

                <Text></Text>
                <br />
                <div
                  style={{ margin: "10px 0px" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(invoiceModalOrder.footer_text),
                  }}
                ></div>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
};

const SAPListView = ({ SAPList }) => {
    return (
        <Row style={{ width: '100%' }} gutter={[12, 12]}>
            {SAPList.doc_list &&
                SAPList.doc_list.map((item, index) => {
                    return item.type ? (
                        <Col span={24}>
                            <Row
                                style={{
                                    width: '100%',
                                    padding: '20px',
                                    backgroundColor: '#36404A',
                                }}
                                key={index}
                            >
                                <Row style={{ width: '100%' }}>
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>Type</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>{item.type || '-'}</Text>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%' }}>
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>Id</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>{item.id || '-'}</Text>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%' }}>
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>Message Type</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>
                                            {item.message_type || '-'}
                                        </Text>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>Message</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text style={{ color: '#1abc9c' }}>
                                            {item.message || '-'}
                                        </Text>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    ) : null;
                })}

            {SAPList.msg_list &&
                SAPList.msg_list.map((item) => {
                    return item.id ? (
                        <>
                            <Col span={24}>
                                <Row
                                    style={{
                                        width: '100%',
                                        padding: '20px',
                                        backgroundColor: '#36404A',
                                    }}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Document Number</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.document_number || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Id</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>{item.id || '-'}</Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Message</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.message || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Message V1</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.message_v1 || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Message V2</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.message_v2 || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Message V3</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.message_v3 || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Message V4</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.message_v4 || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>number</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.number || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>Parameter</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.parameter || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>type</Text>
                                        </Col>
                                        <Col span={12}>
                                            <Text style={{ color: '#f1556c' }}>
                                                {item.type || '-'}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Row>
                            </Col>
                        </>
                    ) : null;
                })}
        </Row>
    );
};

const TrackingModal = (props) => {



    const status = ['pending', 'approved', 'picked', 'dispatched', 'delivered', 'delivery failed', 'returned'];
    const firstOrder = props.trackingModalOrders && props.trackingModalOrders[0];

    const bundleId = firstOrder?.bundleId;

    let currentStatus = status.findIndex(
        (item) => item.toLowerCase() === firstOrder?.currentStatus.toLowerCase()
    );

    const oldStatus = status.findIndex(
        (item) => item.toLowerCase() === firstOrder?.oldStatus?.toLowerCase()
    );

    //To Display the Steps in the Tracking status Modal
    let statusSteps = ['pending', 'approved', 'picked', 'dispatched', 'delivered', 'delivery failed', 'returned'];
    let statusStepsCurrent = currentStatus

    if (oldStatus) {
        if (firstOrder?.currentStatus?.toLowerCase() === 'delivered') {
            statusSteps = ['pending', 'approved', 'picked', 'dispatched', 'delivered', 'returned'];
            statusStepsCurrent = statusSteps.findIndex(
                (item) => item.toLowerCase() === firstOrder?.currentStatus?.toLowerCase()
            );
        } else if (firstOrder?.currentStatus?.toLowerCase() === 'delivery failed') {
            statusSteps = ['pending', 'approved', 'picked', 'dispatched', 'delivery failed', 'returned'];
            statusStepsCurrent = statusSteps.findIndex(
                (item) => item.toLowerCase() === firstOrder?.currentStatus?.toLowerCase()
            );
        }
    }

    if (firstOrder?.currentStatus?.toLowerCase() === 'returned') {
        statusSteps = ['pending', 'approved', 'picked', 'dispatched', firstOrder?.oldStatus?.toLowerCase(), 'returned'];
        statusStepsCurrent--;
    }

    const misc = useSelector((state) => state.miscPermission)

    const isSapManualUpdate = useSelector((state) => state.manualSAPOverride.get(bundleId))
    const dispatch = useDispatch()

    //Changes the Status to Picked automatically as the same happens in the backed
    if (isSapManualUpdate && currentStatus < 2) {
        currentStatus = 2
        statusStepsCurrent = 2
    }

    if (isSapManualUpdate === undefined) {
        dispatch({
            type: 'UPDATE',
            payload: {
                bundleId,
                isSapManualUpdate: firstOrder?.isSapManualUpdate
            }
        })
    }

    useEffect(() => {
        props.updateSapManualUpdate(isSapManualUpdate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSapManualUpdate])

    const argumentOrder =
        props.trackingModalOrders?.length === 1
            ? props.trackingModalOrders[0]
            : props.trackingModalOrders;
    return (
        <Modal
            footer={null}
            title={null}
            visible={props.visible}
            onCancel={props.onCancel}
            closeIcon={false}
            width="50%"
            style={{ display: "flex", justifyContent: "center" }}
        >
            <Title level={4} style={{ color: 'white' }}>
                Tracking Status
            </Title>
            <Row gutter={[8, 8]}>
                <Col span={10}>
                    <Steps
                        size='small'
                        direction='vertical'
                        labelPlacement='vertical'
                        current={statusStepsCurrent}
                    >
                        {statusSteps.map((s, index) => (
                            <Step
                                title={
                                    <Text
                                        style={{
                                            color: index <= statusStepsCurrent ? '#1abc9c' : 'white',
                                            textTransform: 'capitalize'
                                        }}
                                        strong
                                    >
                                        {s}
                                    </Text>
                                }
                            />
                        ))}
                    </Steps>
                </Col>
                <Col
                    span={14}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '20px',
                        height: '100%',
                    }}
                >
                    <Row>
                        Current Status: <br />
                        <span style={{ color: 'white', textTransform: 'capitalize' }}>
                            {firstOrder && status[currentStatus]}
                        </span>
                    </Row>

                    {misc.includes("change-tracking-status") && currentStatus >= 1 && currentStatus < 4 && (
                        <Row type='flex' align='middle' style={{ gap: '5px' }}>
                            Change to: <br />
                            <Col span={24}>
                                <Button
                                    style={{ marginRight: '5px' }}
                                    type='primary'
                                    onClick={() => {
                                        props.setOrderStatus(
                                            status[currentStatus + 1],
                                            argumentOrder
                                        );
                                        props.onCancel();
                                    }}
                                >
                                    {status[currentStatus + 1]}
                                </Button>
                                {currentStatus === 3 && <Button
                                    style={{ marginRight: '5px' }}
                                    type='primary'
                                    onClick={() => {
                                        props.setOrderStatus(
                                            status[currentStatus + 2],
                                            argumentOrder
                                        );
                                        props.onCancel();
                                    }}
                                >
                                    {status[currentStatus + 2]}
                                </Button>}
                            </Col>
                        </Row>
                    )}
                    {misc.includes("change-tracking-status") && (currentStatus === 4 || currentStatus === 5) && (
                        <Row type='flex' align='middle' style={{ gap: '5px' }}>
                            Change to: <br />
                            <Col span={24}>
                                <Button
                                    style={{ marginRight: '5px' }}
                                    type='primary'
                                    onClick={() => {
                                        props.setOrderStatus(
                                            status[6], //Hard Coded, 6th Index is Returned
                                            argumentOrder
                                        );
                                        props.onCancel();
                                    }}
                                >
                                    {status[6]}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {misc.includes("change-tracking-status") && isSapManualUpdate && currentStatus === 0 && (
                        <Row type='flex' align='middle' style={{ gap: '5px' }}>
                            Change to: <br />
                            <Col span={24}>
                                <Button
                                    style={{ marginRight: '5px' }}
                                    type='primary'
                                    onClick={() => {
                                        props.setOrderStatus(
                                            status[currentStatus + 1],
                                            argumentOrder
                                        );
                                        props.onCancel();
                                    }}
                                >
                                    {status[currentStatus + 1]}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};
export default class OrdersAccountManagerMobileView extends Component {
    state = {
        activeIndex: null,
        currentBundleId: null,
        fileList: [],
        loading: false,
        showInvoiceModal: false,
        invoiceModalOrder: null,
        pdfGenerating: false,
        toggleReportDownloadModal: false,
        reportType: 'sap',
        generatingReport: false,
        showImportModal: false,
        importUploadingInProgress: false,
        offlineOrderModal: false,
        SAPFinal: false,
        SAPList: null,
        selectedOrder: {},
        selectedOrders: [],
        tooltipToggle: { active: false, type: null, orderId: null },
        cancellationNote: null,
        showDetailsModal: false,
        resendSapLoader: false,
        trackingModal: false,
        currentMonthSummary: false,
        todaySummary: true,
        filterDataSummary: false,
        todaySalesTarget: null,
        todayOrdersTarget: null,
        monthlySalesTarget: null,
        monthlyOrderTarget: null,
        todayTotalSales: null,
        todayTotalOrders: null,
        todayAvgOrderValue: null,
        todayDelivered: null,
        todayPendingDelivery: null,
        todayPercentSales: null,
        todayPercentOrders: null,
        todayPercentAvg: null,
        todayPercentDelivered: null,
        todayPercentPending: null,
        todaySalesDelta: null,
        todayOrdersDelta: null,
        todayAvgOrderDelta: null,
        todayDeliveredDelta: null,
        todayPendingDelta: null,
        monthlyTotalSales: null,
        monthlyTotalOrders: null,
        monthlyAvgOrderValue: null,
        monthlyDelivered: null,
        monthlyPendingDelivery: null,
        monthlySalesDelta: null,
        monthlyOrderedDelta: null,
        monthlyAvgOrderDelta: null,
        monthlyDeliveredDelta: null,
        monthlyPendingDelta: null,
        todayDispatched: null,         // new - today
        todayDispatchedDelta: null,
        todayPercentDispatched: null,
        todayReturned: null,
        todayReturnedDelta: null,
        todayPercentReturned: null,
        todayCancelled: null,
        todayCancelledDelta: null,
        todayPercentCancelled: null,
        monthlyDispatched: null,         // new - monthly
        monthlyDispatchedDelta: null,
        monthlyReturned: null,
        monthlyReturnedDelta: null,
        monthlyCancelled: null,
        monthlyCancelledDelta: null,
        isSapManualUpdate: null,
        // searchStringList: "",
        data: undefined
    };

    handleBorder = (index) => {
        this.setState({ activeIndex: index });
    };

    handleSelectFile = (file) => {
        this.setState({ fileList: [file] });
        // this.   (file);
    };

    handleUnselectFile = () => {
        this.setState({ fileList: [] });
    };

    setTooltipToggle = (type, active, orderId) => {
        this.setState({ tooltipToggle: { type, active, orderId } });
    };

    toggleTrackingModal = () => {
        this.setState({ trackingModal: !this.state.trackingModal });
    };

    checkTrackingModalOrders = () => {
        const firstOrder = this.state.trackingModalOrders[0];
        for (
            let order = 0;
            order < this.state.trackingModalOrders.length;
            order++
        ) {
            if (
                firstOrder.currentStatus !==
                this.state.trackingModalOrders[order].currentStatus
            ) {
                message.error('Select orders with same tracking status and try again.');
                return false;
            }
        }
        return true;
    };

    singleOrderTrackingModal = (order) => {
        this.setState({ trackingModalOrders: [...[], order] }, () => {
            if (this.checkTrackingModalOrders()) this.toggleTrackingModal();
        });
    };

    multipleOrderTrackingModal = () => {
        if (this.state.selectedOrders.length > 0) {
            this.setState(
                { trackingModalOrders: [...this.state.selectedOrders] },
                () => {
                    if (this.checkTrackingModalOrders()) this.toggleTrackingModal();
                }
            );
        } else message.error('You need to select order');
    };

    uploadFile = (file) => {
        if (file && !this.state.importUploadingInProgress) {
            this.setState({ importUploadingInProgress: true }, () => {
                //.log(`${serverOmnycommUrl}/upload-orders/`);
                let formData = new FormData();
                formData.append('import_file', file);
                this.setState({ loading: true });
                axios
                    .post(`${serverOmnycommUrl}/upload-orders/`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `JWT ${localStorage.getItem('token')} `,
                        },
                    })
                    .then((res) => {
                        // this.setState(prevState => ({
                        //     step_2_product_list: prevState.uploadedImageCounter + 1
                        // }));
                        if (res.data.status === 200) {
                            message.success('Success!');
                        } else {
                            message.error('Internal Server Error!');
                        }
                        this.setState({
                            loading: false,
                            fileList: [],
                            importUploadingInProgress: false,
                        });
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                        message.error('Internal Server Error!');
                    });
            });
        }
    };

    toggleInvoiceModal = () => {
        this.setState(
            {
                showInvoiceModal: !this.state.showInvoiceModal,
            },
            () => {
                if (this.state.showInvoiceModal === false) {
                    this.setState({ invoiceModalOrder: null });
                }
            }
        );
    };

    exportPDFWithMethod = (callBack1 = null, callBack2 = null) => {
        this.setState(
            {
                pdfGenerating: true,
            },
            () => {
                drawDOM(this._element, {
                    paperSize: 'A4',
                    multiple: 'true',
                    margin: '0.2cm',
                    scale: 0.6,
                    keepTogether: '.prevent-split',
                })
                    .then((group) => {
                        return exportPDF(group);
                    })
                    .then((dataUri) => {
                        let base64URI = dataUri;

                        if (callBack2) {
                            callBack2(base64URI);
                        } else {
                            saveAs(
                                dataUri,
                                `invoice-order-id-${this.state.invoiceModalOrder.bundleId}.pdf`
                            );
                        }
                        if (this.state.invoiceModalOrder.website_group_name === 'daycart') {
                            html2canvas(this._arelement).then((canvas) => {
                                const imageData = canvas.toDataURL('image/jpeg');
                                const pdf = new jsPDF('p', 'px', 'A4');
                                const width = pdf.internal.pageSize.getWidth();
                                const height = pdf.internal.pageSize.getHeight();
                                pdf.addImage(
                                    imageData,
                                    'JPEG',
                                    10,
                                    10,
                                    width - 10,
                                    height - 10
                                );
                                pdf.save(
                                    `invoice-order-id-${this.state.invoiceModalOrder.bundleId}-ar.pdf`
                                );
                            });
                        }

                        if (callBack1) callBack1();
                        else message.success('Invoice Generated successfully!');
                    });

                setTimeout(() => {
                    this.setState({
                        pdfGenerating: false,
                    });
                }, 1000);
            }
        );
    };

    zip = new JSZip();

    nextDownload = (i) => {
        let orders = this.state.selectedOrders;
        this.setState(
            {
                invoiceModalOrder: orders[i],
                exportAllProgressBar: i + 1,
            },
            () => {
                this.exportPDFWithMethod(() => {
                    if (i + 1 < orders.length) {
                        this.nextDownload(i + 1);
                    }
                });
            }
        );
    };

    // else
    // 			{
    // 				this.zip.generateAsync({type:"blob"})
    // 				.then(function(content) {
    // 					// see FileSaver.js
    // 					saveAs(content, "example.zip");
    // 				});
    // 			}

    // (base64URI) => {
    // 	this.zip.file("sas.png" , base64URI);
    // }

    downloadAllInvoices = async () => {
        if (this.state.selectedOrders.length === 0) {
            message.error(
                'You need to select the orders you want to generate an invoice!'
            );
            return;
        }
        this.setState({
            exportAllModal: true,
        });
        setTimeout(() => {
            this.nextDownload(
                0,
                localStorage.getItem('websiteGroupName') === 'daycart'
            );
        }, 500);
    };

    SAPFinalToggle = () => {
        this.setState({ SAPFinal: !this.state.SAPFinal });
    };

    setRadioValueChange = (e) => {
        this.setState({
            reportType: e.target.value,
        });
    };

    checkSelectedOrders = (value) => {
        return this.state.selectedOrders.findIndex(
            (item) => item.bundleId === value
        ) !== -1
            ? true
            : false;
    };

    setSelectedOrders = (order) => {
        const dup = this.state.selectedOrders.findIndex(
            (item) => item.bundleId === order.bundleId
        );
        if (dup === -1)
            this.setState({ selectedOrders: [...this.state.selectedOrders, order] });
        else {
            const tmp = this.state.selectedOrders.filter(
                (item) => item.bundleId !== order.bundleId
            );
            this.setState({ selectedOrders: tmp });
        }
    };

    setCallTag = (selectedTag, uuid) => {
        axios({
            method: 'POST',
            url: `${serverOmnycommUrl}/dealshub/set-call-status/`,
            data: {
                call_status: selectedTag,
                orderUuid: uuid,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
        }).then(() => {
        });
    };

    selectAllOrders = () => {
        this.setState({
            selectedOrders:
                this.state.selectedOrders.length ===
                    this.props.ordersManagerDetails.fetchedOrders.length
                    ? []
                    : this.props.ordersManagerDetails.fetchedOrders,
        });
    };

    fetchOrderAnalytics = () => {
        axios
            .post(
                `${serverOmnycommUrl}/dealshub/fetch-order-sales-analytics/`,
                {
                    // "filterStatus": this.state.orderStatusFilter,
                    locationGroupUuid: this.props.locationGroupUuid,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        data: response.data,
                        todaySalesTarget: response.data.targets.today_sales,
                        todayOrdersTarget: response.data.targets.today_orders,
                        monthlySalesTarget: response.data.targets.monthly_sales,
                        monthlyOrderTarget: response.data.targets.monthly_orders,
                        todayTotalSales: response.data.todays.sales,
                        todayTotalOrders: response.data.todays.orders,
                        todayAvgOrderValue: response.data.todays.avg_value,
                        todayDelivered: response.data.todays.delivered,
                        todayPendingDelivery: response.data.todays.pending,
                        todayPercentSales: response.data.todays.percent_sales,
                        todayPercentOrders: response.data.todays.percent_orders,
                        todayPercentAvg: response.data.todays.percent_avg,
                        todayPercentDelivered: response.data.todays.percent_delivered,
                        todayPercentPending: response.data.todays.percent_pending,
                        todaySalesDelta: response.data.todays.sales_delta,
                        todayOrdersDelta: response.data.todays.orders_delta,
                        todayAvgOrderDelta: response.data.todays.avg_value_delta,
                        todayDeliveredDelta: response.data.todays.delivered_delta,
                        todayPendingDelta: response.data.todays.pending_delta,
                        monthlyTotalSales: response.data.monthly.sales,
                        monthlyTotalOrders: response.data.monthly.orders,
                        monthlyAvgOrderValue: response.data.monthly.avg_value,
                        monthlyDelivered: response.data.monthly.delivered,
                        monthlyPendingDelivery: response.data.monthly.pending,
                        monthlySalesDelta: response.data.monthly.sales_delta,
                        monthlyOrderedDelta: response.data.monthly.orders_delta,
                        monthlyAvgOrderDelta: response.data.monthly.avg_value_delta,
                        monthlyDeliveredDelta: response.data.monthly.delivered_delta,
                        monthlyPendingDelta: response.data.monthly.pending_delta,
                        todayDispatched: response.data.todays.dispatched,                     // new - today
                        todayDispatchedDelta: response.data.todays.dispatched_delta,
                        todayPercentDispatched: response.data.todays.percent_dispatched,
                        todayReturned: response.data.todays.returned,
                        todayReturnedDelta: response.data.todays.returned_delta,
                        todayPercentReturned: response.data.todays.percent_returned,
                        todayCancelled: response.data.todays.cancelled,
                        todayCancelledDelta: response.data.todays.cancelled_delta,
                        todayPercentCancelled: response.data.todays.percent_cancelled,
                        monthlyDispatched: response.data.monthly.dispatched,                   // new - monthly
                        monthlyDispatchedDelta: response.data.monthly.dispatched_delta,
                        monthlyReturned: response.data.monthly.returned,
                        monthlyReturnedDelta: response.data.monthly.returned_delta,
                        monthlyCancelled: response.data.monthly.cancelled,
                        monthlyCancelledDelta: response.data.monthly.cancelled_delta,
                    });
                } else {
                    message.error('Something went wrong in fetching order analytics');
                }
            });
    };

    componentDidMount = () => {
        if (this.props.orderAnalyticsAccess || this.props.salesAnalyticsAccess) this.fetchOrderAnalytics();
    };

    componentDidUpdate(previousProps) {
        if (
            (previousProps.orderAnalyticsAccess !== this.props.orderAnalyticsAccess &&
                this.props.orderAnalyticsAccess) || (previousProps.salesAnalyticsAccess !== this.props.salesAnalyticsAccess &&
                    this.props.salesAnalyticsAccess) || (previousProps.locationGroupUuid !== this.props.locationGroupUuid)
        )
            this.fetchOrderAnalytics();
    }

    updateSalesPerson = (e) => {
        this.setState({
            searchString: '',
            salesPerson: this.state.salesPersonList[e],
        });
    };

    tabularHeader = (order, index, updateManualOrderToSuccess) => {
        let isChecked = this.checkSelectedOrders(order.bundleId);
        const textRef = React.createRef();
        return (
            <Row
                type='flex'
                gutter={[0, 0]}
                className='collapse-header'
                style={{ padding: '15px' }}
            >
                <Col span={1}>
                    <Checkbox
                        value={order.bundleId}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => {
                            this.setSelectedOrders(order);
                        }}
                        checked={isChecked}
                    ></Checkbox>
                </Col>
                <Col span={13}>
                    <Row gutter={[12, 12]}>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Text style={{ color: '#1abc9c', fontWeight: 'bold' }}>
                                {order.customerName + ' '}
                            </Text>
                            placed an order with {order.unitOrderList.length} items
                        </Col>
                        <Col span={24} className='noPad'>
                            <Text
                                style={{ color: 'white', zIndex: '1000' }}
                                ref={textRef}
                                onClick={() => {
                                }}
                            >
                                {order.bundleId.toUpperCase()}
                            </Text>
                        </Col>
                        <Col span={24} className='noPad'>
                            <Text style={{ color: 'white' }}>
                                {order.dateCreated}, {order.time}
                            </Text>
                        </Col>
                        {order.merchant_reference !== '' && (
                            <Col span={24} className='noPad'>
                                <Text style={{ color: 'white' }}>
                                    Ref: {order.merchant_reference}{' '}
                                </Text>
                            </Col>
                        )}

                        <Col span={24} className='noPad'>
                            <Text style={{ color: 'white' }}>
                                Item count: {order.unitOrderList.length}{' '}
                            </Text>
                        </Col>

                        <Col
                            span={24}
                            className='Tag'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                                marginTop: '10px',
                            }}
                        >
                            <Row gutter={[0, 4]} type='flex'>
                                <Col>
                                    <Tag
                                        color='green'
                                        style={{ textTransform: 'uppercase' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {order.paymentStatus}
                                    </Tag>
                                </Col>
                                <Col style={{ zIndex: '10' }}>
                                    {order.sapStatus === 'Manual' ? (
                                        <ManualTag
                                            uuid={order.uuid}
                                            updateManualOrderToSuccess={updateManualOrderToSuccess}
                                        />
                                    ) : (
                                        <Tag
                                            color={
                                                order.sapStatus === 'GRN Conflict'
                                                    ? 'red'
                                                    : order.sapStatus === 'Pending'
                                                        ? 'yellow'
                                                        : 'green'
                                            }
                                            style={
                                                order.sap_final_billing_info.doc_list ||
                                                    order.sap_final_billing_info.msg_list
                                                    ? {
                                                        width: 'fit-content',
                                                        cursor: 'pointer',
                                                        zIndex: '10',
                                                    }
                                                    : { width: 'fit-content', zIndex: '10000' }
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    order.sap_final_billing_info.doc_list ||
                                                    order.sap_final_billing_info.msg_list
                                                ) {
                                                    this.setState(
                                                        {
                                                            selectedOrder: order,
                                                        },
                                                        () => {
                                                            this.SAPFinalToggle();
                                                        }
                                                    );
                                                }
                                            }}
                                        >
                                            SAP Status -{' '}
                                            {order.sapStatus.charAt(0).toUpperCase() +
                                                order.sapStatus.substr(1)}
                                        </Tag>
                                    )}
                                </Col>

                                {order.isOrderOffline && (
                                    <Col>
                                        <Tag
                                            color='blue'
                                            style={{}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            Offline Order {order.referenceMedium && '/'}{' '}
                                            {order.referenceMedium}
                                        </Tag>
                                    </Col>
                                )}

                                {order.isOrderOffline && (
                                    <Col>
                                        <Tag
                                            color='blue'
                                            style={{}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            Sales Person - {order.salesPerson}
                                        </Tag>
                                    </Col>
                                )}

                                <Col style={{ zIndex: '10' }}>
                                    <CallStatusTag tag={order.call_status} uuid={order.uuid} />
                                </Col>

                                {order.showSapWarning && (
                                    <Col
                                        style={{ zIndex: '10' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Icon
                                            type={this.state.resendSapLoader ? 'loading' : 'reload'}
                                            style={{ color: '#c0c0f1', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (this.state.resendSapLoader) {
                                                    message.info(
                                                        'one process is still going on, please wait!'
                                                    );
                                                    return;
                                                }
                                                this.setState({
                                                    resendSapLoader: true,
                                                });
                                                this.props.resendSapOrder(order, () => {
                                                    this.setState({
                                                        resendSapLoader: false,
                                                    });
                                                });
                                            }}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>

                        {order.cancelStatus ? (
                            <React.Fragment></React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Col span={4}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'WIG Fleet',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='dispatched-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'WIG Fleet' &&
                                                'primary'
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            WIG
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={6}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'grand gaadi',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='delivered-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'grand gaadi' &&
                                                'primary'
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            Grand Gaadi
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={7}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'Sendex',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='delivered-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'Sendex' &&
                                                'primary'
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            SENDEX
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={7}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'Emirates Post',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='delivered-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'Emirates Post' &&
                                                'primary'
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                                Emirates Post
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={7}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'LOGIX',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='delivered-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'LOGIX' &&
                                                'primary'
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            LOGIX
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </Col>

                <Col span={10}>
                    <Col
                        span={24}
                        style={{
                            fontSize: '18px',
                            textAlign: 'right',
                            marginBottom: '10px',
                        }}
                    >
                        {order.toPay} {order.currency}
                    </Col>
                    <Col span={24}>
                        <Row
                            type='flex'
                            justify='right'
                            align='end'
                            gutter={[8, 8]}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Col span={24}>
                                <Row type='flex' justify='right' align='end'>
                                    <Button
                                        style={{ width: '30%' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState(
                                                {
                                                    invoiceModalOrder: order,
                                                },
                                                () => {
                                                    this.toggleInvoiceModal();
                                                    // this.exportPDFWithMethod();
                                                }
                                            );
                                        }}
                                        block
                                    >
                                        <Icon type='download' />
                                        Invoice
                                    </Button>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type='flex' justify='right' align='end'>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (order.cancelStatus) {
                                                this.setState({
                                                    cancellationNoteModal: true,
                                                    cancellationNote: order.cancelling_note,
                                                });
                                            } else {
                                                this.setState({
                                                    currentBundleId: order.bundleId,
                                                });
                                                this.props.toggleModalShow(order.bundleId);
                                            }
                                        }}
                                        loading={this.props.someProcessingActive}
                                        style={{
                                            width: '30%',
                                            color: 'red',
                                            background: 'white',
                                            textAlign: 'center',
                                        }}
                                        className='returned-btn'
                                        icon={order.cancelStatus ? 'info-circle' : 'cancel'}
                                        block
                                    >
                                        {order.cancelStatus ? 'Order Cancelled' : 'Cancel Order'}
                                    </Button>
                                </Row>
                            </Col>
                            <Col>
                                <Row type='flex' justify='right' align='end'>
                                    {/* <Link
							to={`/order-details/${this.props.locationGroupUuid}/${order.uuid}`}
							style={{ fontSize: '14px' }}
						> */}

                                    <Button
                                        style={{ width: '30%' }}
                                        block
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                viewOrderUuid: order.uuid,
                                                showDetailsModal: true,
                                            });
                                        }}
                                    >
                                        View Details
                                    </Button>

                                    {/* </Link> */}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        );
    };

    collapseHeader = (order) => {
        return (
            <Row
                type='flex'
                justify='space-between'
                className='collapse-header'
                gutter={[8, 8]}
            >
                {order.selected && (
                    <div stle={{ position: 'absolute', top: '10px', right: '10px' }}>
                        gsgs
                    </div>
                )}

                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <Text type='secondary'>ID : </Text>
                            <Text>{order.bundleId}</Text>
                        </Col>
                        <Col span={12}>
                            <Row type='flex' justify='end'>
                                <Col>
                                    <Text type='secondary'>Date: </Text>
                                    <Text>{order.dateCreated}</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <Text type='secondary'>Item count : </Text>
                            <Text>{order.unitOrderList.length}</Text>
                        </Col>
                        <Col span={12}>
                            <Row type='flex' justify='end'>
                                <Col>
                                    <Text type='secondary'>Time: </Text>
                                    <Text>{order.time}</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {/* adding Ref Id */}

                <Col span={24}>
                    <Row>
                        <Col span={12} style={{}}>
                            <Text type='secondary'>Ref: </Text>
                            {order.merchant_reference === '' ? (
                                <Text>{'NA'}</Text>
                            ) : (
                                <Tooltip title={order.merchant_reference}>
                                    <Text>
                                        {order.merchant_reference.length >= 10
                                            ? order.merchant_reference.substring(0, 10) + '...'
                                            : order.merchant_reference}
                                    </Text>
                                </Tooltip>
                            )}
                        </Col>

                        <Col span={12}>
                            <Row type='flex' justify='end'>
                                <Col>
                                    <Text type='secondary'>Total: </Text>
                                    <Text>
                                        {order.toPay} {order.currency}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Row>
                        <Col span={10}>
                            <Text>{order.customerName}</Text>
                        </Col>

                        <Col span={14}>
                            <Row type='flex' justify='end'>
                                <Col className='Tag'>
                                    <Tag color='green'>
                                        {order.paymentStatus.charAt(0).toUpperCase() +
                                            order.paymentStatus.substr(1)}
                                    </Tag>
                                    <Tag
                                        color={order.sapStatus === 'GRN Conflict' ? 'red' : 'green'}
                                        style={
                                            order.sap_final_billing_info.doc_list ||
                                                order.sap_final_billing_info.msg_list
                                                ? {
                                                    width: 'fit-content',
                                                    margin: '5px 0',
                                                    cursor: 'pointer',
                                                }
                                                : { width: 'fit-content', margin: '5px 0' }
                                        }
                                        onClick={() => {
                                            if (
                                                order.sap_final_billing_info.doc_list ||
                                                order.sap_final_billing_info.msg_list
                                            ) {
                                                this.setState(
                                                    {
                                                        selectedOrder: order,
                                                    },
                                                    () => {
                                                        this.SAPFinalToggle();
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        SAP Status -{' '}
                                        {order.sapStatus.charAt(0).toUpperCase() +
                                            order.sapStatus.substr(1)}
                                    </Tag>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        {order.cancelStatus ? (
                            <React.Fragment>
                                <Col span={11}>
                                    <Tag
                                        color='red'
                                        style={{
                                            width: '100%',
                                            fontSize: '14px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                cancellationNoteModal: true,
                                                cancellationNote: order.cancelling_note,
                                            });
                                        }}
                                    >
                                        <Icon type='info-circle' /> Order cancelled
                                    </Tag>
                                    {/* </Tooltip> */}
                                </Col>
                                <Col span={24} style={{ padding: '0px', margin: '0px' }}></Col>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Col span={4}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'WIG Fleet',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='dispatched-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'WIG Fleet' &&
                                                'primary'
                                            }
                                        >
                                            WIG
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={6}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'grand gaadi',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='delivered-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'grand gaadi' &&
                                                'primary'
                                            }
                                        >
                                            Grand Gaadi
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={6}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'Sendex',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='delivered-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'Sendex' &&
                                                'primary'
                                            }
                                        >
                                            SENDEX
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={8}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'Emirates Post',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='delivered-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'Emirates Post' &&
                                                'primary'
                                            }
                                        >
                                                Emirates Post
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={6}>
                                    <Popconfirm
                                        title='Are you sure?'
                                        oktext='Yes'
                                        cancelText='No'
                                        onConfirm={() => {
                                            this.props.setShippingMethod(
                                                'LOGIX',
                                                order.bundleId,
                                                order
                                            );
                                        }}
                                    >
                                        <Button
                                            loading={this.props.someProcessingActive}
                                            className='dispatched-btn'
                                            disabled={order.cancelStatus}
                                            block
                                            type={
                                                order.unitOrderList[0].shippingMethod === 'LOGIX' &&
                                                'primary'
                                            }
                                        >
                                            LOGIX
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </React.Fragment>
                        )}
                        <Col span={10}>
                            <Button
                                onClick={() => {
                                    this.setState(
                                        {
                                            invoiceModalOrder: order,
                                        },
                                        () => {
                                            this.toggleInvoiceModal();
                                        }
                                    );
                                }}
                                block
                            >
                                <Icon type='download' />
                                Invoice
                            </Button>
                        </Col>
                        <Col span={3}>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        currentBundleId: order.bundleId,
                                    });
                                    this.props.toggleModalShow(order.bundleId);
                                }}
                                loading={this.props.someProcessingActive}
                                disabled={order.cancelStatus}
                                type='danger'
                                className='returned-btn'
                                icon='close'
                                block
                            ></Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    updateSapManualUpdate = (isSapManualUpdate) => {
        this.setState({ isSapManualUpdate: isSapManualUpdate })
    }

    render() {

        return (
            <Row
                className='orders-account-manager-mobile-view-page-container'
                gutter={[16, 16]}
            >
                <Col span={24} className='title-container'>
                    <Row type='flex' justify='start'>
                        <Col>
                            <Title level={4} className='main-title'>
                                Orders Page
                            </Title>
                        </Col>
                    </Row>
                </Col>
                {(this.props.orderAnalyticsAccess || this.props.salesAnalyticsAccess) ? (
                    <Col span={24}>
                        <Row type='flex' justify='end'>
                            {this.props.orderAnalyticsAccess && <Col span={12} style={{ textAlign: 'right' }}>
                                <Link
                                    to={`/employee-analytics/${this.props.locationGroupUuid}`}
                                    style={{ width: '100%' }}
                                >
                                    <Button
                                        style={{ backgroundColor: '#3cc9a9', border: 'none', color: '#F1F1F1' }}
                                    >Employee Analytics</Button></Link>
                            </Col>}

                        </Row>
                        <SalesOrderAnalytics
                            data={this.state.data}
                            filteredData={{
                                avg_value: this.props.order_analytics?.avg_order_value,
                                cancelled: this.props.order_analytics?.cancelled,
                                delivered: this.props.order_analytics?.delivered,
                                dispatched: this.props.order_analytics?.dispatched,
                                filtered_cancelled_amount: this.props.order_analytics?.filtered_cancelled_amount,
                                filtered_dispatched_amount: this.props.order_analytics?.filtered_dispatched_amount,
                                filtered_done_delivery_amount: this.props.order_analytics?.filtered_done_delivery_amount,
                                filtered_pending_amount: this.props.order_analytics?.filtered_pending_amount,
                                filtered_returned_amount: this.props.order_analytics?.filtered_returned_amount,
                                orders: this.props.order_analytics?.orders,
                                pending: this.props.order_analytics?.pending,
                                returned: this.props.order_analytics?.returned,
                                sales: this.props.order_analytics?.sales,
                                net_sales: this.props.order_analytics?.net_sales,
                                net_sales_amount: this.props.order_analytics?.net_sales_amount
                            }}
                        />



                    </Col>
                ) : (
                    <Col></Col>
                )}

                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col lg={3} md={3} sm={6}>
                            <OrdersAccountsManagerFilterModal
                                style={{ width: '100%' }}
                                filterCount={this.props.filterCount}
                                applyFilters={this.props.applyFilters}
                                someProcessingActive={this.props.someProcessingActive}
                                locationGroupUuid={this.props.locationGroupUuid}
                                filterObj={this.props.filterObj}
                            />
                        </Col>
                        <Col
                            lg={18}
                            md={18}
                            sm={9}
                            className='warehouse-manager-search-bar-container'
                        >
                            <Select
                                dropdownClassName='search-chips'
                                dropdownMatchSelectWidth='false'
                                mode='tags'
                                style={{ width: '100%' }}
                                value={this.props.searchStringList}
                                onChange={this.props.handleSearchChange}
                                placeholder={[<Icon type='search' />, ' Search here...']}
                            />
                        </Col>
                        {/*<Col lg={3} md={3} sm={6}>*/}
                        {/*    <Row>*/}
                        {/*        <Col span={8}>*/}
                        {/*            <Tooltip title="Download template">*/}
                        {/*                <Button*/}
                        {/*                    onClick={() => {*/}
                        {/*                        window.open(fileTemplate);*/}
                        {/*                    }}*/}
                        {/*                    type="danger"*/}
                        {/*                    style={{*/}
                        {/*                        borderTopRightRadius: "0px",*/}
                        {/*                        borderBottomRightRadius: "0px",*/}
                        {/*                        width: "100%",*/}
                        {/*                    }}*/}
                        {/*                    ghost*/}
                        {/*                >*/}
                        {/*                    <Icon type="import"/>*/}
                        {/*                </Button>*/}
                        {/*            </Tooltip>*/}
                        {/*        </Col>*/}
                        {/*        <Col span={16}>*/}
                        {/*            <UploadBtn*/}
                        {/*                style={{*/}
                        {/*                    width: "100%",*/}
                        {/*                    borderTopLeftRadius: "0px",*/}
                        {/*                    borderBottomLeftRadius: "0px",*/}
                        {/*                }}*/}
                        {/*                beforeUpload={this.handleSelectFile}*/}
                        {/*                onRemove={this.handleUnselectFile}*/}
                        {/*                fileList={this.state.fileList}*/}
                        {/*            >*/}
                        {/*                <Button*/}
                        {/*                    loading={this.state.loading}*/}
                        {/*                    disabled={*/}
                        {/*                        this.state.fileList.length !== 0 || this.state.published*/}
                        {/*                    }*/}
                        {/*                    style={{*/}
                        {/*                        width: "100%",*/}
                        {/*                        borderTopLeftRadius: "0px",*/}
                        {/*                        borderBottomLeftRadius: "0px",*/}
                        {/*                    }}*/}
                        {/*                    type="danger"*/}
                        {/*                >*/}
                        {/*                    {" "}*/}
                        {/*                    Import{" "}*/}
                        {/*                </Button>*/}
                        {/*            </UploadBtn>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</Col>*/}
                        {/*<Col lg={3} md={3} sm={6}>*/}
                        {/*    <Button*/}
                        {/*        type="primary"*/}
                        {/*        style={{width: "100%"}}*/}
                        {/*        ghost*/}
                        {/*        icon="download"*/}
                        {/*        onClick={() => {*/}
                        {/*            this.setState({*/}
                        {/*                toggleReportDownloadModal: true,*/}
                        {/*            });*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        Download*/}
                        {/*    </Button>*/}
                        {/*    <Modal*/}
                        {/*        title="Select Report Type"*/}
                        {/*        visible={this.state.toggleReportDownloadModal}*/}
                        {/*        closable={false}*/}
                        {/*        footer={[*/}
                        {/*            <Button*/}
                        {/*                key="back"*/}
                        {/*                disabled={this.state.generatingReport}*/}
                        {/*                onClick={() => {*/}
                        {/*                    this.setState({*/}
                        {/*                        toggleReportDownloadModal: false,*/}
                        {/*                        generatingReport: false,*/}
                        {/*                    });*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                Close*/}
                        {/*            </Button>,*/}
                        {/*            <Button*/}
                        {/*                key="submit"*/}
                        {/*                type="primary"*/}
                        {/*                loading={this.state.generatingReport}*/}
                        {/*                onClick={() => {*/}
                        {/*                    this.props.downloadReport(this.state.reportType);*/}
                        {/*                    this.setState({*/}
                        {/*                        generatingReport: false,*/}
                        {/*                    });*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                Generate Report*/}
                        {/*            </Button>,*/}
                        {/*        ]}*/}
                        {/*        destroyOnClose={true}*/}
                        {/*        width="50vw"*/}
                        {/*    >*/}
                        {/*        <Radio.Group*/}
                        {/*            onChange={this.setRadioValueChange}*/}
                        {/*            value={this.state.reportType}*/}
                        {/*        >*/}
                        {/*            <Radio value={"sap"}>SAP</Radio>*/}
                        {/*            <Radio value={"regular"}>Regular</Radio>*/}
                        {/*        </Radio.Group>*/}
                        {/*    </Modal>*/}
                        {/*</Col>*/}
                        <Col lg={3} md={3} sm={6}>
                            <Button
                                className='add-order-button'

                                onClick={() => this.setState({ offlineOrderModal: true })}
                            >
                                Add Order
                            </Button>
                            <OfflineOrder
                                locationGroupUuid={this.props.locationGroupUuid}
                                visible={this.state.offlineOrderModal}
                                setVisible={() =>
                                    this.setState({
                                        offlineOrderModal: !this.state.offlineOrderModal,
                                    })
                                }
                                setOrdersListing={this.props.setOrdersListing}
                                isB2b={this.props.isB2b}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 8]} type='flex'>
                        <Col lg={3} md={3} sm={6}>
                            <Button
                                type='primary'
                                style={{ width: '100%' }}
                                loading={this.props.loading}
                                ghost
                            >
                                <React.Fragment>
                                    {this.props.loading ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            Results: {this.props.totalOrders}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Row type='flex' gutter={[8, 0]}>
                                <Col>
                                    <Button
                                        type='primary'
                                        style={{ width: '100%' }}
                                        loading={this.props.loading}
                                        ghost
                                    >
                                        <React.Fragment>
                                            {this.props.loading ? (
                                                <React.Fragment></React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    Total Sales: {this.props.totalRevenue}{' '}
                                                    {this.props.currency}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={this.props.isB2b ? 9 : 13}>
                            <Row gutter={[8, 0]} type='flex' justify='end'>
                                <Col
                                    span={8}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Button
                                        type='primary'
                                        style={{ width: '100%' }}
                                        loading={this.props.loading}
                                        ghost
                                    >
                                        <React.Fragment>
                                            {this.props.loading ? (
                                                <React.Fragment></React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {this.state.selectedOrders.length} selected Orders
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    </Button>
                                </Col>
                                {this.state.selectedOrders.length > 0 && (
                                    <Col lg={8} md={8} sm={6}>
                                        <Button
                                            type='primary'
                                            loading={this.props.loading}
                                            style={{ width: '100%' }}
                                            onClick={this.downloadAllInvoices}
                                        >
                                            Download Invoices
                                        </Button>
                                    </Col>
                                )}
                                {this.state.selectedOrders.length > 0 && (
                                    <Col lg={8} md={8} sm={6}>
                                        <Button
                                            type='primary'
                                            loading={this.props.loading}
                                            style={{ width: '100%' }}
                                            onClick={this.multipleOrderTrackingModal}
                                        >
                                            Tracking Status
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col
                            span={4}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Link
                                to={`/order-cancellation/${this.props.locationGroupUuid}`}
                                style={{ width: '100%' }}
                            >
                                <Button
                                    type='primary'
                                    style={{ width: '100%' }}
                                    loading={this.props.loading}
                                    ghost
                                >
                                    <React.Fragment>
                                        {this.props.loading ? (
                                            <React.Fragment></React.Fragment>
                                        ) : (
                                            <React.Fragment>Cancellation Requests</React.Fragment>
                                        )}
                                    </React.Fragment>
                                </Button>
                            </Link>
                        </Col>
                        {this.props.isB2b &&
                            <Col
                                span={4}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <Link to={`/order-requests/${this.props.locationGroupUuid}`}
                                    style={{ width: '100%' }}>
                                    <Button
                                        type='primary'
                                        style={{ width: '100%' }}
                                        loading={this.props.loading}
                                        ghost
                                    ><React.Fragment>
                                            {this.props.loading ? (
                                                <React.Fragment></React.Fragment>
                                            ) : (
                                                <React.Fragment>{`Order Requests (${this.props.pendingOrderRequestCount})`}</React.Fragment>
                                            )}
                                        </React.Fragment></Button>
                                </Link>

                            </Col>}
                    </Row>
                </Col>

                {this.props.fetchingOrdersList &&
                    this.props.accountsOrderListIndex === 1 ? (
                    <Row
                        type='flex'
                        align='middle'
                        justify='center'
                        style={{ width: '100%', height: '100%' }}
                    >
                        <Ellipsis color='#1ABC9C' size={100} />
                    </Row>
                ) : (
                    <React.Fragment>
                        {this.props.ordersManagerDetails.fetchedOrders.length > 0 ? (

                            <React.Fragment>
                                <Col span={24}>
                                    <Row
                                        gutter={[12, 0]}
                                        style={{
                                            padding: '0px 20px',
                                        }}
                                    >
                                        <Col span={1}>
                                            <Checkbox onChange={this.selectAllOrders} />
                                        </Col>
                                        <Col span={7}>
                                            <Text>ORDER DETAILS</Text>
                                        </Col>
                                        <Col span={6}>
                                            <Text>ORDER PLACED ON</Text>
                                        </Col>
                                        <Col span={5}>
                                            <Text>ITEMS</Text>
                                        </Col>
                                        <Col span={5}>
                                            <Text>PRICE</Text>
                                        </Col>
                                    </Row>
                                </Col>

                                {this.props.ordersManagerDetails.fetchedOrders.map(
                                    (order, index) => {
                                        return (
                                            <BaseProductContent1
                                                type='flex'
                                                align='middle'
                                                key={index}
                                                onClick={() => this.handleBorder(index)}
                                                className={
                                                    this.state.activeIndex === index && 'border-is-active'
                                                }
                                            >
                                                <OrderRow
                                                    isSapManualUpdate={this.state.isSapManualUpdate}
                                                    bundleId={order.bundleId}
                                                    isB2b={this.props.isB2b}
                                                    locationGroupUuid={this.props.locationGroupUuid}
                                                    order={order}
                                                    downloadInvoice={() =>
                                                        this.setState({ invoiceModalOrder: order }, () =>
                                                            this.toggleInvoiceModal()
                                                        )
                                                    }
                                                    cancelOrder={() => {
                                                        if (order.currentStatus === 'dispatched' || order.currentStatus === 'picked' || order.currentStatus === 'delivered') {
                                                            message.error(`Order with ${order.currentStatus} tracking status cannot be cancelled!`);
                                                            return;
                                                        }
                                                        if (order.cancelStatus) {
                                                            this.setState({
                                                                cancellationNoteModal: true,
                                                                cancellationNote: order.cancelling_note,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                currentBundleId: order.bundleId,
                                                            });
                                                            this.props.toggleModalShow(order.bundleId);
                                                        }
                                                    }}
                                                    viewDetails={() => {
                                                        this.setState({
                                                            viewOrderUuid: order.uuid,
                                                            showDetailsModal: true,
                                                        });
                                                    }}
                                                    shippingMethod={(method, isSapManualUpdate, weight) => {
                                                        this.props.setShippingMethod(
                                                            method,
                                                            order.bundleId,
                                                            order,
                                                            isSapManualUpdate,
                                                            weight
                                                        );
                                                    }}
                                                    callStatus={(item) => {
                                                        this.setCallTag(item, order.uuid);
                                                    }}
                                                    sapStatus={(saplist) => {
                                                        if (saplist.doc_list || saplist.msg_list) {
                                                            this.setState(
                                                                {
                                                                    selectedOrder: order,
                                                                    SAPList: saplist,
                                                                },
                                                                () => {
                                                                    this.SAPFinalToggle();
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    setSelectedOrders={this.setSelectedOrders}
                                                    checkSelectedOrders={this.checkSelectedOrders}
                                                    singleOrderTrackingModal={
                                                        this.singleOrderTrackingModal
                                                    }
                                                    resendSapOrder={() => {
                                                        if (this.state.resendSapLoader) {
                                                            message.info(
                                                                'one process is still going on, please wait!'
                                                            );
                                                            return;
                                                        }
                                                        this.setState({
                                                            resendSapLoader: true,
                                                        });
                                                        this.props.resendSapOrder(order, () => {
                                                            this.setState({
                                                                resendSapLoader: false,
                                                            });
                                                        });
                                                    }}
                                                />
                                            </BaseProductContent1>
                                        );
                                    }
                                )}
                                <TrackingModal
                                    updateSapManualUpdate={this.updateSapManualUpdate}
                                    trackingModalOrders={this.state.trackingModalOrders}
                                    onCancel={this.toggleTrackingModal}
                                    visible={this.state.trackingModal}
                                    setOrderStatus={
                                        this.state.trackingModalOrders?.length > 1
                                            ? this.props.setOrderStatusBulk
                                            : this.props.setOrderStatus
                                    }
                                />
                                <Modal
                                    title='Order Invoice'
                                    className='order-invoice-modal-wrapper'
                                    visible={this.state.showInvoiceModal}
                                    onOk={() =>
                                        this.exportPDFWithMethod(
                                            null,
                                            null,
                                            this.state.invoiceModalOrder.website_group_name ===
                                            'daycart'
                                        )
                                    }
                                    onCancel={() => {
                                        this.toggleInvoiceModal();
                                    }}
                                    style={{ display: "flex", justifyContent: "center" }}
                                    destroyOnClose={true}
                                    width="60%"
                                    footer={[
                                        <Button
                                            key='back'
                                            onClick={() => {
                                                this.toggleInvoiceModal();
                                            }}
                                        >
                                            Cancel
                                        </Button>,
                                        <Button
                                            key='submit'
                                            type='primary'
                                            onClick={() =>
                                                this.exportPDFWithMethod(
                                                    null,
                                                    null,
                                                    this.state.invoiceModalOrder.website_group_name ===
                                                    'daycart'
                                                )
                                            }
                                        >
                                            Download
                                        </Button>
                                    ]}
                                >
                                    {this.state.invoiceModalOrder ? (
                                        <PdfTemplate
                                            invoiceModalOrder={this.state.invoiceModalOrder}
                                            refFetch={(div) => {
                                                this._element = div;
                                            }}
                                            refFetch1={(div) => {
                                                this._arelement = div;
                                            }}
                                            isB2b={this.props.isB2b}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Modal>
                            </React.Fragment>
                        ) : (
                            <Empty
                                className='no-orders-wrapper'
                                description='No Orders found'
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        )}
                        {this.props.areOrdersAvailable ? (
                            <div
                                className='center-child'
                                style={{
                                    width: '100%',
                                    marginTop: '10px',
                                    marginBottom: '50px',
                                }}
                            >
                                <Button
                                    type='primary'
                                    loading={this.props.fetchingOrdersList}
                                    onClick={() => {
                                        this.props.loadMoreOrders();
                                    }}
                                >
                                    {this.props.fetchingOrdersList ? '' : 'Load More...'}
                                </Button>
                            </div>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </React.Fragment>
                )}

                <Modal
                    visible={this.state.SAPFinal}
                    footer={null}
                    onOk={this.SAPFinalToggle}
                    onCancel={this.SAPFinalToggle}
                >
                    <div
                        style={{
                            display: 'flex',
                            maxHeight: '500px',
                            overflowY: 'scroll',
                            padding: '10px',
                        }}
                    >
                        <SAPListView SAPList={this.state.SAPList} />
                    </div>
                </Modal>

                <Modal
                    title='Cancellation Reason'
                    visible={this.state.cancellationNoteModal}
                    footer={null}
                    onOk={() => {
                        this.setState({
                            cancellationNoteModal: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            cancellationNoteModal: false,
                        });
                    }}
                    destroyOnClose={true}
                    width='50vw'
                >
                    <Input.TextArea
                        value={this.state.cancellationNote || this.props.cancellationReason}
                        maxLength={160}
                        minLength={5}
                    />
                </Modal>

                <Modal
                    title='Order Details'
                    visible={this.state.showDetailsModal}
                    onOk={() => {
                        this.setState({
                            showDetailsModal: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            showDetailsModal: false,
                        });
                    }}
                    destroyOnClose={true}
                    width='70vw'
                    style={{ display: 'flex', justifyContent: "center" }}
                    footer={false}
                >
                    <OrderDetails
                        uuid={this.state.viewOrderUuid}
                        locationUuid={this.props.locationGroupUuid}
                        isB2b={this.props.isB2b}
                    />
                </Modal>

                <Modal
                    title='Type Cancellation Reason'
                    visible={this.props.toggleShowlModal}
                    onOk={() => {
                        this.props.handleCancelModalSubmit(this.state.currentBundleId);
                    }}
                    onCancel={() => {
                        this.props.handleCancelModalClose(this.state.currentBundleId);
                    }}
                    loading={!this.props.toggleShowlModal}
                    destroyOnClose={true}
                    width='50%'
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <Input.TextArea
                        defaultValue={this.props.cancellationReason}
                        placeholder='Describe cancellation reason using a minimum of 5 characters and maximum of 160 characters!'
                        maxLength={160}
                        minLength={5}
                        onChange={this.props.handleCancelTextChange}
                    />
                </Modal>

                <Modal
                    title='Generating Invoices'
                    className='order-invoice-modal-wrapper'
                    visible={this.state.exportAllModal}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({
                            exportAllModal: false,
                        });
                    }}
                    width='50%'
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                    footer={[]}
                >
                    {this.state.invoiceModalOrder ? (
                        <div>
                            <div
                                style={{
                                    marginBottom: '20px',
                                }}
                            >
                                {/* Progress Bar {this.state.exportAllProgressBar} */}
                                <Progress
                                    percent={Math.ceil(
                                        (100 * this.state.exportAllProgressBar) /
                                        this.state.selectedOrders.length
                                    )}
                                    status='active'
                                />
                            </div>
                            <PdfTemplate
                                invoiceModalOrder={this.state.invoiceModalOrder}
                                refFetch={(div) => {
                                    this._element = div;
                                }}
                                refFetch1={(div) => {
                                    this._arelement = div;
                                }}
                                isB2b={this.props.isB2b}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </Modal>
            </Row>
        );
    }
}
