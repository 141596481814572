/**
 * @description This is the initialState of channelwise reducer
 * @property {object} channelwiseState - All required product details and images fetched to export to db.
 */
const initialState = {
  channelwiseState: {
    products: [],
    exportList: [],
    listToExport: [],
    filters: {
      verified: false,
      start_date: "",
      end_date: "",
      brand_name: "",
      has_image: "",
    },
    tags: [],
    status: "",
    loading: false,
  },
};
/**
 * @description This is redux reducer for loading products and displaying after extracting them from different platforms (amazon,ebay)
 * changes/updates state  for different cases like loading on/off etc..
 * @typedef StateType - state type of channelwise reducer, default state is initial state
 * @param {object} state
 * @param {object} action
 * @returns {StateType} returns updated state
 */
export default function channelwise(state = initialState, action) {
  switch (action.type) {
    case "LOADING_ON":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          loading: true,
        },
      };

    case "LOADING_OFF":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          loading: false,
        },
      };

    case "FETCH_ALL_CHANNEL_PRODUCT":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          products: action.payload,
        },
      };
    case "SEND_FEEDBACK_FOR_EXPORT_ADD_ON_DB":
      return {
        ...state,
        channelwiseState: { ...state.channelwiseState, status: action.payload },
      };

    case "RESET_EXPORT_FLOW":
      return {
        ...state,
        channelwiseState: { ...state.channelwiseState, listToExport: [] },
      };

    case "FETCH_ALL_MORE_CHANNEL_PRODUCT":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          products: {
            ...state.channelwiseState.products,
            is_available: action.payload.is_available,
            products: [
              ...state.channelwiseState.products.products,
              ...action.payload.products,
            ],
          },
        },
      };
    case "FETCH_EXPORT_LIST":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          exportList: action.payload,
        },
      };
    case "ADD_TO_EXPORT_LIST":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          listToExport: [
            ...state.channelwiseState.listToExport,
            action.payload,
          ],
        },
      };
    case "REMOVE_FROM_EXPORT_LIST":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          listToExport: state.channelwiseState.listToExport.filter(
            (e) => e !== action.payload
          ),
        },
      };
    case "ADD_FILTERS_TO_CHANNEL_WISE_VIEW":
      return {
        ...state,
        channelwiseState: {
          ...state.channelwiseState,
          filters: action.payload,
        },
      };
    case "ADD_TAGS_TO_CHANNEL_WISE_VIEW":
      return {
        ...state,
        channelwiseState: { ...state.channelwiseState, tags: action.payload },
      };
    default:
      return state;
  }
}
