import React, { Component } from "react";
import { Row, Col, Avatar, Typography, Icon, Button, Modal, message } from "antd";
import "./edit-user-modal.scss";
import InputField from "../datainputs/InputField";
import axios from "axios";

import isDev from "../../../server";
const serverUrl = isDev();

export default class EditUserModal extends Component {
    state = { visible: false, userData: null };

    componentDidMount() {
        this.setState({
            userData: this.props.userData,
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
            userData: this.props.userData,
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
        axios
            .post(
                `${serverUrl}/edit-user-profile/`,
                {
                    first_name: this.state.userData.first_name ? this.state.userData.first_name : "",
                    last_name: this.state.userData.last_name ? this.state.userData.last_name : "",
                    contact_number: this.state.userData.contact_number ? this.state.userData.contact_number : "",
                    email: this.state.userData.email ? this.state.userData.email : "",
                    designation: this.state.userData.designation ? this.state.userData.designation : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                if(response.status === 200) {
                    message.success("Profile Updated Successfully!");
                    this.props.updateUser({...this.state.userData})
                } else {
                    message.error("Something went Wrong!");
                }
            });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
            userData: null,
        });
    };

    render() {
        return (
            <React.Fragment>
                <Button type="primary" onClick={this.showModal}>
                    Edit user
                </Button>
                <Modal
                    title="Edit user details"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="Save"
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <InputField
                                label={{ type: "secondary" }}
                                labelText="First Name"
                                input={{
                                    size: "large",
                                    name: "cover_material_type",
                                    value: this.state.userData?.first_name,
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        userData: {
                                            ...this.state.userData,
                                            first_name: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <InputField
                                label={{ type: "secondary" }}
                                labelText="Last Name"
                                input={{
                                    size: "large",
                                    name: "cover_material_type",
                                    value: this.state.userData?.last_name,
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        userData: {
                                            ...this.state.userData,
                                            last_name: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <InputField
                                label={{ type: "secondary" }}
                                labelText="Mobile no."
                                input={{
                                    size: "large",
                                    name: "cover_material_type",
                                    value: this.state.userData?.contact_number,
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        userData: {
                                            ...this.state.userData,
                                            contact_number: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <InputField
                                label={{ type: "secondary" }}
                                labelText="Email"
                                input={{
                                    size: "large",
                                    name: "cover_material_type",
                                    value: this.state.userData?.email,
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        userData: {
                                            ...this.state.userData,
                                            email: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Col>

                        <Col span={24}>
                            <InputField
                                label={{ type: "secondary" }}
                                labelText="Designation"
                                input={{
                                    size: "large",
                                    name: "cover_material_type",
                                    value: this.state.userData?.designation,
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        userData: {
                                            ...this.state.userData,
                                            designation: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </Modal>
            </React.Fragment>
        );
    }
}
