import React, { Component } from 'react'
import { Row, Col, Typography, Collapse, Button, Checkbox, Avatar, Icon, Tooltip, Tag, Input, Select, Spin } from 'antd';
import TitleWithBreadCrumb from '../components/titlewithbreadcrumb/TitleWithBreadCrumb';
import UserAvatar from "../../media/male_avatar.png"
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import PlaceholderImage from "../../media/placeholder3.png";
import "./orders.scss"
import isDev from "../../server";

import styled from "styled-components";

const serverDealsHubUrl = isDev(true);

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const TableRow = styled(Row)`
  width: 100%;
  padding: 12px;
  background-color: #313841;

  /* border-bottom: 1px solid red; */
`;

const TableRowText = styled(Text)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TableHeadText = styled(Text)`
  color: #dee2e6;
`;

const TableRowCollapse = styled(Collapse)`
  width: 100%;
  border: none;
  margin-bottom: 10px;
`;

const orderList = [{
    orderId: "12321", dateCreated: "23 march pta nhi", paymentStatus: "paid", unitOrderList: [
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" }
    ]
},


{
    orderId: "12321", dateCreated: "23 march pta nhi", paymentStatus: "paid", unitOrderList: [
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" }
    ]
},

{
    orderId: "12321", dateCreated: "23 march pta nhi", paymentStatus: "paid", unitOrderList: [
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" }
    ]
},


{
    orderId: "12321", dateCreated: "23 march pta nhi", paymentStatus: "paid", unitOrderList: [
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" }
    ]
},

{
    orderId: "12321", dateCreated: "23 march pta nhi", paymentStatus: "paid", unitOrderList: [
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" }
    ]
},

{
    orderId: "12321", dateCreated: "23 march pta nhi", paymentStatus: "paid", unitOrderList: [
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" },
        { quantity: 3, price: 120, currency: "AED", productImageUrl: "https://wig-wams-s3-bucket.s3.amazonaws.com/midsize/01_GJ4yojc_V3hPhgl.jpg" }
    ]
},

]


export default class Orders extends Component {

    state = {
        spinnerLoading: false,
        orderList: null,
        is_available: true,
        loadingInLoadMore: false,
        page: 1
    };

    componentDidMount = () => {
        this.setState({
            spinnerLoading: true
        });

        const data = {
            "page": 1
        }

        axios.post(`${serverDealsHubUrl}/dealshub/order/fetch-order-list-admin/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {

            this.setState({
                spinnerLoading: false,
            });
            if (response.data.status == 200) {
                this.setState({ is_available: response.data.is_available, totalproducts: response.data.total_products, orderList: response.data.orderList });
            }
        });
    }

    handleLoadMore = () => {
        this.setState({
            loadingInLoadMore: true
        });

        const data = {
            "page": this.state.page + 1
        }

        axios.post(`${serverDealsHubUrl}/dealshub/order/fetch-order-list-admin/`, data, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {

            this.setState({
                loadingInLoadMore: false,
            });
            if (response.data.status == 200) {
                this.setState({ page: this.state.page + 1, totalproducts: response.data.total_products, orderList: [...this.state.orderList, ...response.data.orderList], is_available: response.data.is_available });
            }
        });
    }

    getImages = (unitOrderList) =>
        unitOrderList.map((e, index) => {
            if (unitOrderList.length == 1 && index == 0) {
                return (
                    <Col key={index} span={24}>
                        <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={`${e.productImageUrl}`} />
                    </Col>
                )
            }
            if (unitOrderList.length == 4) {
                return (
                    <Col key={index} span={12}>
                        <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={`${e.productImageUrl}`} />
                    </Col>
                )
            }
            if (unitOrderList.length > 4 && index < Math.min(unitOrderList.length, 3)) {
                return (
                    <Col key={index} span={12}>
                        <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={`${e.productImageUrl}`} />
                    </Col>
                )
            }
            if (unitOrderList.length < 4) {
                return (
                    <Col key={index} span={12}>
                        <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={`${e.productImageUrl}`} />
                    </Col>
                )
            }
        });

    getTotalAmount = (unitOrderList) => {
        let amount = 0;
        for (let ind = 0; ind < unitOrderList.length; ind++) {
            amount += (unitOrderList[ind].quantity * unitOrderList[ind].price);
        }

        return amount;
    }

    channelList = ["Amazon", "Noon", "eBay", "Jasp", "Wigme", "DealsHub"]
    collapseHeader = (order, channelInd) => {

        let quantity = 0;
        for (let ind = 0; ind < order.unit_order_list.length; ind++) {
            quantity += (order.unit_order_list[ind].quantity * order.unit_order_list[ind].price);
        }



        return (
            <Row key={order.orderId} className="orders-row" type="flex" align="middle" >
                <Col span={1} className="padding-left">
                    <Checkbox />
                </Col>
                <Col span={2}>
                    <Text className="">
                        {order.orderId}
                    </Text>
                </Col>
                <Col span={2}>
                    <Row gutter={[8, 8]} style={{ width: "95%" }}>
                        <Col span={24}>
                            {this.getImages(order.unit_order_list)}
                            {/* {order.unit_order_list.length == 2 && <Col span={12}>
                                <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={PlaceholderImage} />
                            </Col>
                            }

                            {
                                order.unit_order_list.length == 3 &&
                                <Col span={12}>
                                    <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={PlaceholderImage} />
                                </Col>
                            } */}
                            {order.unit_order_list.length > 4 &&
                                <Col span={12} style={{ textAlign: "center" }}>

                                    <Text>
                                        +{order.unit_order_list.length - 3}
                                    </Text>

                                </Col>
                            }
                        </Col>

                        {/* <Col span={8}>
                                                            <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={`${order.productImageUrl}`} />
                                                        </Col> */}
                    </Row>
                </Col>
                <Col span={5} className="padding-right">
                    <Tooltip title={order.customerName}>
                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="text-color-2 ">
                            {order.unit_order_list.length}{" Products"}
                        </Typography.Paragraph>
                    </Tooltip>
                </Col>
                <Col span={2}>
                    <Text className="">
                        {this.channelList[channelInd]}
                    </Text>
                </Col>
                <Col span={2}>
                    <Text className="table-head-text">
                        {order.customerName}
                    </Text>
                </Col>
                <Col span={2}>
                    <Text className="">
                        {order.dateCreated}
                    </Text>
                </Col>
                <Col span={3} className="padding-left">
                    <Tag
                        color={order.paymentStatus == "pending" ? "yellow" : (order.paymentStatus == "failed" ? "red" : "green")}
                        style={{ marginBottom: "10px" }}
                    >
                        {order.paymentStatus}
                    </Tag>

                </Col>

                <Col span={3}>
                    <Tag
                        color="green"
                        style={{ marginBottom: "10px" }}
                    >
                        Shipped
                                            </Tag>
                </Col>
                <Col span={2}>
                    <Text className="">
                        {/* {this.getTotalAmount(order.unitOrderList)} AED */}
                        {
                            quantity
                        } AED
                                                </Text>

                </Col>

            </Row>
        )
    };


    render() {

        const channelList = ["Amazon", "Noon", "eBay", "Jasp", "Wigme", "DealsHub"]


        return (
            <Row className="orders-page-container" >
                <TitleWithBreadCrumb title="Orders" breadcrumb={["OmnyComm", "Orders"]} />
                <Col span={24} className="orders-table-container" style={{ marginBottom: "30px" }}  >
                    <Row className="top-nav" type="flex" align="middle">
                        <Col span={12}>
                            <Select
                                className="search-select"
                                dropdownClassName="search-chips"
                                dropdownMatchSelectWidth="false"
                                mode="tags"
                                style={{ width: 200 }}
                                placeholder={[<Icon type="search" />, " Search here..."]}
                            ></Select>
                            <Select
                                className="status-select"
                                style={{ width: 200 }}
                                placeholder="Status"
                            >
                                <Option value="paid">Paid</Option>
                                <Option value="awaiting-authorization">Awaiting Authorization</Option>
                                <Option value="payment-failed">Payment failed</Option>
                                <Option value="cod">Cash On Delivery</Option>
                                <Option value="fulfilled">Fulfilled</Option>
                                <Option value="unfulfilled">Unfulfilled</Option>
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Row type="flex" justify="end">
                                <Button type="danger" icon="shopping-cart" className="margin-right">
                                    Add New Order
                                 </Button>
                                <Button type="primary" >
                                    Export
                                 </Button>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="orders-table" style={{ paddingBottom: "30px" }}>
                        <Col span={24}>
                            <Row className="table-head-row" >
                                <Col span={1}>
                                    <Checkbox />
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Order ID
                                    </Text>
                                </Col>
                                <Col span={7}>
                                    <Row type="flex" justify="center">
                                        <Col>
                                            <Text className="table-head-text">
                                                Products
                                             </Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Channel
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Customer
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Date
                                    </Text>
                                </Col>
                                <Col span={3} className="padding-left">
                                    <Text className="table-head-text">
                                        Payment
                                </Text>
                                </Col>

                                <Col span={3}>
                                    <Text className="table-head-text">
                                        Order Status
                                    </Text>
                                </Col>
                                <Col span={2}>
                                    <Text className="table-head-text">
                                        Total
                                    </Text>
                                </Col>

                            </Row>


                            <div className="orders-table-content">

                                {this.state.spinnerLoading && <Row type="flex" justify="center" align="middle" style={{ height: "50vh" }} >
                                    <Col span={1}>
                                        <Spin tip="Loading" />
                                    </Col>
                                </Row>}

                                {this.state.orderList && this.state.orderList.map(order => {

                                    let channelInd = Math.floor((Math.random() * 10) % 6);



                                    if (order.unit_order_list.length > 1) {
                                        return (
                                            <TableRowCollapse style={{ marginBottom: "0px" }} className="base-product-container">
                                                <Panel extra={this.collapseHeader(order, channelInd)}
                                                    showArrow={false}>

                                                    {
                                                        order.unit_order_list.map((unit_order, index) => {
                                                            return (
                                                                <Link to={{
                                                                    pathname: `/order-details/${order.uuid}`,
                                                                }}>
                                                                    <Row key={unit_order.orderId} className="orders-row" type="flex" align="middle">
                                                                        <Col span={1} className="padding-left">
                                                                            <Checkbox />
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Text className="">
                                                                                {unit_order.orderId}
                                                                            </Text>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Row gutter={[8, 8]} style={{ width: "95%" }}>

                                                                                <Col span={24}>
                                                                                    <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={`${unit_order.productImageUrl}`} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={5} className="padding-right">
                                                                            <Tooltip title={unit_order.productName}>
                                                                                <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="text-color-2">
                                                                                    {unit_order.productName}
                                                                                </Typography.Paragraph>
                                                                            </Tooltip>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Text className="">
                                                                                {/* {channelList[channelInd]} */}
                                                                            </Text>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Text className="table-head-text">
                                                                                {/* Customer */}
                                                                            </Text>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Text className="">
                                                                                {/* {order.dateCreated} */}
                                                                            </Text>
                                                                        </Col>
                                                                        <Col span={3} className="padding-left">
                                                                            <Tag
                                                                                color={order.currentStatus == "pending" ? "yellow" : (order.currentStatus == "failed" ? "red" : "green")}
                                                                                style={{ marginBottom: "10px" }}
                                                                            >
                                                                                {order.currentStatus}
                                                                            </Tag>

                                                                        </Col>

                                                                        <Col span={3}>
                                                                            <Tag
                                                                                color="green"
                                                                                style={{ marginBottom: "10px" }}
                                                                            >
                                                                                Shipped
                                                                        </Tag>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Text className="">
                                                                                {/* {this.getTotalAmount(order.unitOrderList)} AED */}
                                                                                {unit_order.quantity * unit_order.price} AED
                                                                            </Text>

                                                                        </Col>

                                                                    </Row>
                                                                </Link>
                                                            )
                                                        })
                                                    }

                                                </Panel>
                                            </TableRowCollapse>
                                        )
                                    }

                                    return (
                                        <Link to={{
                                            pathname: `/order-details/${order.uuid}`,
                                        }}>
                                            <Row key={order.orderId} className="orders-row" type="flex" align="middle">
                                                <Col span={1} className="padding-left">
                                                    <Checkbox />
                                                </Col>
                                                <Col span={2}>
                                                    <Text className="">
                                                        {order.uuid}
                                                    </Text>
                                                </Col>
                                                <Col span={2}>
                                                    <Row gutter={[8, 8]} style={{ width: "95%" }}>
                                                        <Col span={24}>
                                                            {this.getImages(order.unit_order_list)}
                                                            {order.unit_order_list.length > 4 &&
                                                                <Col span={12} style={{ textAlign: "center" }}>

                                                                    <Text>
                                                                        +{order.unit_order_list.length - 3}
                                                                    </Text>

                                                                </Col>
                                                            }
                                                        </Col>

                                                        {/* <Col span={8}>
                                                            <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={`${order.productImageUrl}`} />
                                                        </Col> */}
                                                    </Row>
                                                </Col>
                                                <Col span={5} className="padding-right">
                                                    <Tooltip title={order.unit_order_list[0].productName}>
                                                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="text-color-2 ">
                                                            {order.unit_order_list[0].productName}
                                                        </Typography.Paragraph>
                                                    </Tooltip>
                                                </Col>
                                                <Col span={2}>
                                                    <Text className="">
                                                        {channelList[channelInd]}
                                                    </Text>
                                                </Col>
                                                <Col span={2}>
                                                    <Text className="table-head-text">
                                                        {order.customerName}
                                                    </Text>
                                                </Col>
                                                <Col span={2}>
                                                    <Text className="">
                                                        {order.dateCreated}
                                                    </Text>
                                                </Col>
                                                <Col span={3} className="padding-left">
                                                    <Tag
                                                        color={order.paymentStatus == "pending" ? "yellow" : (order.paymentStatus == "failed" ? "red" : "green")}
                                                        style={{ marginBottom: "10px" }}
                                                    >
                                                        {order.paymentStatus}
                                                    </Tag>

                                                </Col>

                                                <Col span={3}>
                                                    <Tag
                                                        color="green"
                                                        style={{ marginBottom: "10px" }}
                                                    >
                                                        Shipped
                                            </Tag>
                                                </Col>
                                                <Col span={2}>
                                                    <Text className="">
                                                        {/* {this.getTotalAmount(order.unitOrderList)} AED */}
                                                        {order.unit_order_list[0].quantity * order.unit_order_list[0].price} AED
                                                </Text>

                                                </Col>

                                            </Row>
                                        </Link>
                                    );
                                })}
                                {this.state.is_available && (
                                    <div
                                        className="center-child"
                                        style={{ width: "100%", marginTop: "10px" }}
                                    >
                                        <Button
                                            type="primary"
                                            loading={this.state.loadingInLoadMore}
                                            onClick={this.handleLoadMore}

                                        >
                                            {this.state.loadingInLoadMore ? "" : "Load More..."}

                                        </Button>
                                    </div>
                                )}



                            </div>
                        </Col>
                    </Row>

                </Col>


            </Row>
        )
    }
}
