import React, { Component } from "react";
import { Row, Col, Modal, Collapse, Switch, Tag, Upload, message, Icon, Input, Button, Spin, Typography } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { handleUpdate, handleDelete, deleteBanner, publishBanner, unpublishBanner, loadCategoryOrBanner } from "../../../actions/dealsHubAdmin";
import GenericBannerModalContent from './GenericBannerModalContent';
import "./generic-banner.scss";
import placeHolderImage from "media/placeholder3.png"
import isDev from "../../../server";
import { idText } from "typescript";
import { previewImage } from "antd/lib/upload/utils";
import { clearConfig } from "dompurify";

const { Text } = Typography;
const serverUrl = isDev();
const { Panel } = Collapse;
const bannerResolution = {
    "HomePageSchedular": "1392px * 290px",
    "FullBannerAd": "1392px * 377px",
    "CategoryGridBanner": "479px * 179px",
    "DealsBanner": "410px * 240px",
}
const fileSize = {
    "HomePageSchedular": "10KB - 100KB",
    "FullBannerAd": "10KB - 100KB",
    "CategoryGridBanner": "5KB - 15KB",
    "DealsBanner": "10KB - 30KB",
}

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

 function beforeUpload(file) {
//     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
//     if (!isJpgOrPng) {
//         message.error("You can only upload JPG/PNG/GIF file!");
//     }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isLt2M;
}

class GenericBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverUrl: serverUrl,
            previewVisible: false,
            previewImage: "",
            fileList: [],
            uploadingInProgress: false, //mutex to prevent duplicate requests during handleChange function of AntD Upload Component
            isDeleteOn: false,
            modalFileUid: "",
            modalFileHttpLink: "",
            spinnerLoading: false,
            saveNameLoading : false,
            innerSpinner: false,
        };
    }

    componentDidMount = () => {
        this.setState({
            toggleState: this.props.bannerData.isPublished,
        });
    };

    togglePublishBanner = (checked) => {
        if (!checked) {
            this.unpublishBannerBtn();
        } else {
            this.publishBannerBtn();
        }
    };

    genExtra = (str) => {
        return (
            <>
                <Switch
                    checked={this.state.toggleState}
                    onChange={(checked) => {
                        this.togglePublishBanner(checked);
                    }}
                    disabled={str[0] === "FiveStyleBanner" && 5 - this.state.fileList.length}
                    style={{ marginRight: '10px' }}
                />
                <Tag color={str[1]}>{str[0]}</Tag>
            </>
        );
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            modalFileUid: file.uid,
            modalFileHttpLink: file.httpLink
        } , () => {
            if(this.state.previewImage == null || this.state.previewImage == "")
            {
                this.setState({
                    previewImage : placeHolderImage
                })
            }
        });
    };

    changeModalFileUrl = event => {
        this.setState({ modalFileHttpLink: event.target.value });
    };

    updateModalData = () => {
        axios.post(`${this.state.serverUrl}/dealshub/update-link-banner/`, {
            uuid: this.state.modalFileUid,
            httpLink: this.state.modalFileHttpLink
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("token")}`
            }
        }).then(response => {
            let temp = this.state.fileList;

            for (let index = 0; index < temp.length; ++index) {
                if (temp[index]["uid"] == this.state.modalFileUid) {
                    temp[index]["httpLink"] = this.state.modalFileHttpLink;
                    break;
                }
            }
            this.setState({
                fileList: [...[], ...temp]
            });
        });
        this.handleCancel();
    };

    handleChange = ({ file, fileList }) => {
        if(file.size > 2097152) {
            let temp = [];
            for(let i=0; i<fileList.length-1; i++){
                temp.push(fileList[i]);
            }
            this.setState({ fileList: temp });
        } else {
            this.setState({ fileList });
        }
        //.log(this.props.bannerData);

        if (
            file.size <= 2097152 &&
            !this.state.uploadingInProgress &&
            !this.state.isDeleteOn
        ) {
            this.setState({ uploadingInProgress: true });

            let currentImage = fileList[fileList.length - 1];
            let formData = new FormData();
            formData.append("image", currentImage.originFileObj);
            formData.append("websiteGroupName", localStorage.getItem("websiteGroupName"));
            formData.append("uuid", this.props.bannerData.uuid);

            if (currentImage.originFileObj !== undefined) {
                axios.post(`${this.state.serverUrl}/dealshub/add-banner-image/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                }).then(response => {
                    this.props.bannerData.bannerImages.push({
                        httpLink: "",
                        products: [],
                        uid: response.data.uuid,
                        url: response.data.imageUrl,
                        mobileUrl: "",
                        is_promotional: false,
                        start_time: null,
                        end_time: null,
                        promotion_tag: null
                    });

                    let temp = this.state.fileList;

                    temp[temp.length - 1]["uid"] = response.data.uuid;

                    this.setState({
                        fileList: [...[], ...temp],
                        uploadingInProgress: false
                    });
                });
            }
        }
    };

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    removeFile = file => {
        this.setState({
            isDeleteOn: true
        }, () => {
            if(this.props.bannerData.isPublished && this.props.bannerData.bannerType === "FiveStyleBanner") {
                axios.post(`${serverUrl}/dealshub/unpublish-banner/`, {
                    uuid: this.props.bannerData.uuid
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        this.props.unpublishBanner(this.props.bannerData.uuid);
                        this.setState({
                            toggleState: false
                        });
                    } 
                    else if(response.data.status === 403)
                    {
                        message.info("You are not authorised!")
                        return;
                    }
                    else {
                        message.error('Something went wrong!')
                        return;
                    }
                });
            }
            if (!file.uid.includes('rc-')) {
                axios.post(`${this.state.serverUrl}/dealshub/delete-unit-banner/`, {
                    uuid: file.uid
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${localStorage.getItem("token")}`
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        let tempAdminBanner = this.props.dealsHubAdmin[this.props.bannerIndex].bannerImages,
                            tempAdminBannerLength = tempAdminBanner.length, index = null;

                        for (index = 0; index < tempAdminBannerLength; ++index) {
                            if (tempAdminBanner[index].uid === file.uid) {
                                tempAdminBanner.splice(index, 1);
                                break;
                            }
                        }

                        this.props.dealsHubAdmin[this.props.bannerIndex].bannerImages = tempAdminBanner;
                        this.props.handleDelete(this.props.dealsHubAdmin);
                        this.setState({ isDeleteOn: false });
                        message.success("Unit Banner Deleted Successfully!");
                    } 
                    else if(response.data.status === 405)
                    {
                      message.info("You are not authorised!")
                    }
                    else {
                        message.error("Something went Wrong!");
                    }
                });
            }
        });
    };

    deleteBannerBtn = event => {
        let tempUuid = event.target.getAttribute("value")
        this.props.deleteBanner(tempUuid.substr(0, tempUuid.indexOf("$")));
        this.props.spinnerOn(true);
    };

    publishBannerBtn = () => {
        this.setState({
            spinnerLoading: true
        }, () => {
            axios.post(`${serverUrl}/dealshub/publish-banner/`, {
                uuid: this.props.bannerData.uuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.data.status === 200) {
                    message.success('Published Successfully!');
                    this.props.publishBanner(this.props.bannerData.uuid);
                    this.setState({
                        spinnerLoading: false,
                        toggleState: true
                    });
                } 
                else if(response.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error('Something went wrong!')
                }
            });
        });
    };

    unpublishBannerBtn = () => {
        this.setState({
            spinnerLoading: true
        }, () => {
            axios.post(`${serverUrl}/dealshub/unpublish-banner/`, {
                uuid: this.props.bannerData.uuid
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.data.status === 200) {
                    message.success('Unpublished Successfully!');
                    this.props.unpublishBanner(this.props.bannerData.uuid);
                    this.setState({
                        spinnerLoading: false,
                        toggleState: false
                    });
                } 
                else if(response.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error('Something went wrong!')
                }
            });
        });
    };

    changeCategoryName = event => {
        this.props.handleUpdate({
            uuid: this.props.bannerData.uuid,
            value: event.target.value
        });
    };

    saveBannerName = (e) => {
        e.stopPropagation();
        this.setState({
            saveNameLoading: true
        }, () => {
            axios.post(`${this.state.serverUrl}/dealshub/update-banner-name/`, {
                uuid: this.props.bannerData.uuid,
                name: this.props.bannerData.name
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                }
            }).then(response => {
                if (response.data.status === 200) {
                    message.success("Banner name updated Successfully!");
                    this.setState({
                        saveNameLoading: false
                    });
                } 
                else if(response.data.status === 403)
                {
                  message.info("You are not authorised!")
                }
                else {
                    message.error("Something went wrong!");
                }
            });
        });

    };

    loadData = () => {
        if(this.props.bannerData.bannerImages === undefined){
            this.setState({innerSpinner: true});
            axios.post(`${serverUrl}/dealshub/fetch-${this.props.siteName === "WIGme - B2B" ? "b2b-" : ""}section-detail/`, {
                BannerUuid: this.props.bannerData.uuid,
                locationGroupUuid: this.props.locationUuid
            },{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("token")}`
                    },
            }).then((response) =>{
                if(response.data.status === 200){
                    this.props.bannerData.bannerImages = response.data.sections_list[0].bannerImages;
                    this.props.bannerData.is_nested = response.data.sections_list[0].is_nested;
                    this.props.bannerData.limit = response.data.sections_list[0].limit;
                    
                    let bannerImages_COPY = this.props.bannerData.bannerImages;
                    for(let i = 0;i<bannerImages_COPY.length;i++)
                    {
                        if(bannerImages_COPY[i].url === "")
                        bannerImages_COPY[i].url = placeHolderImage;
                    }
                    this.setState({
                        fileList: [
                            ...this.state.fileList,
                            ...bannerImages_COPY
                        ],
                        innerSpinner: false,
                    });
                }
                else {
                    message.error('Something Went Wrong!');
                }
            });
            if(this.props.bannerData.bannerImages){
                this.props.loadCategoryOrBanner({
                    itemUuid: this.props.bannerData.uuid,
                    itemValue: {
                        bannerImages: this.props.bannerData.bannerImages,
                        is_nested: this.props.bannerData.is_nested,
                        limit: this.props.bannerData.limit,
                    }
                });
            }
        }
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = (
            <div>
                <Icon type="camera" />
                <div className="ant-upload-text">
                    You can drag and drop images or upload images
                </div>
            </div>
        );
        let fiveStyleChecker =  this.props.bannerData.bannerType === "FiveStyleBanner" && (5 - fileList.length)

        if (!this.state.spinnerLoading) {
            return (
                <React.Fragment>
                    <Collapse expandIconPosition="left" onChange={(e)=>{if(e.length === 1) this.loadData()}}>
                        <Panel
                            header={this.props.bannerData.name}
                            extra={this.genExtra([this.props.bannerData.bannerType, "blue"])}
                            className="dealshub-admin-collapse-panel"
                        >
                        {
                            this.state.innerSpinner ? (
                                <Spin style={{display: 'flex', justifyContent: 'center'}}></Spin>
                            ):(
                                <div
                                    className="content-container"
                                    style={{ padding: "20px", background: "#36404A" }}
                                >
                                    <div className="btn-wrapper btn-container-generic-banner" 
                                        style={{ marginBottom: !fiveStyleChecker ? "18px" : 5 }}>
                                        {this.props.bannerData.isPublished ? (
                                            <Button
                                                type="danger"
                                                onClick={this.unpublishBannerBtn}
                                            >Unpublish</Button>
                                        ) : (
                                                <Button
                                                    type="primary"
                                                    onClick={this.publishBannerBtn}
                                                    disabled={fiveStyleChecker}
                                                >Publish</Button>
                                            )}
                                        <Button
                                            className="purple-btn"
                                            value={this.props.bannerData.uuid + "$delete"}
                                            onClick={this.deleteBannerBtn}
                                        >Delete</Button>
                                    </div>
                                    {fiveStyleChecker ? (
                                        <div style={{marginBottom: 18}}>
                                            <Text style={{color: 'red'}}>
                                                Please upload another {5 - fileList.length} Images to publish this banner
                                            </Text>
                                        </div>
                                    ) : (<></>)}
                                    <Row>
                                        <Col span={24} style={{ marginBottom: "25px" }}>
                                            <Text className="text-color3">Banner Display Name</Text>
                                            <Input
                                                defaultValue={this.props.bannerData.name}
                                                onChange={(e) => {
                                                    this.changeCategoryName(e);
                                                }}
                                                maxLength = {100}
                                                style={{ marginTop: "5px" }}
                                            />
                                            <Button
                                                style={{ "marginTop": "20px" }}
                                                type="primary"
                                                loading = {this.state.saveNameLoading}
                                                onClick={(e) => {this.saveBannerName(e);}}
                                            >Save Name</Button>
                                        </Col>
                                        <Col span={24}>
                                            <div style={{ marginBottom: "18px" }}>
                                                <Text>
                                                    Banner Type: {this.props.bannerData.bannerType}
                                                </Text>
                                                <br />
                                                <Text>
                                                    {
                                                        this.props.bannerData.bannerType === "FiveStyleBanner" ? (
                                                            `Exactly ${this.props.bannerData.limit} Images has to be uploaded`
                                                        ) : (
                                                            `Maximum ${this.props.bannerData.limit} Images Allowed`
                                                        )
                                                    }
                                                </Text>
                                                <br />
                                                <Text>
                                                    Resolution: {bannerResolution[this.props.bannerData.bannerType]}
                                                </Text>
                                                <br />
                                                <Text >
                                                    File Size Range: {fileSize[this.props.bannerData.bannerType]}
                                                </Text>
                                            </div>
                                            {this.state.uploadingInProgress ? (
                                                <div className="dealshub-admin-section-category-deletion-in-progress-container">
                                                    <Spin indicator={antIcon} tip="Uploading Unit Banner Data" />
                                                </div>
                                            ) : (
                                                    <Upload
                                                        accept=".jpg, .jpeg, .png, .gif"
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onPreview={this.handlePreview}
                                                        onChange={this.handleChange}
                                                        onRemove={this.removeFile}
                                                        customRequest={this.dummyRequest}
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        {fileList.length >= this.props.bannerData.limit ? null : uploadButton}
                                                    </Upload>
                                                )}
                                            <Modal
                                                visible={previewVisible}
                                                footer={null}
                                                onCancel={this.handleCancel}
                                                destroyOnClose={true}
                                                width = {"70vw"}
                                                style={{display: "flex", justifyContent: "center"}}
                                            >
                                                <GenericBannerModalContent
                                                    bannerData={this.props.bannerData}
                                                    bannerIndex={this.props.bannerIndex}
                                                    modalFileUid={this.state.modalFileUid}
                                                    modalFileHttpLink={this.state.modalFileHttpLink}
                                                    previewImage={previewImage}
                                                    locationUuid = {this.props.locationUuid}
                                                />
                                            </Modal>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Panel>
                    </Collapse>
                </React.Fragment>
            );
        } else {
            return (
                <Spin></Spin>
            );
        }
    }
}

const mapStateToProps = ({ dealsHubAdmin }) => {
    return {
        dealsHubAdmin
    };
};

export default connect(mapStateToProps, {
    handleUpdate,
    handleDelete,
    deleteBanner,
    publishBanner,
    unpublishBanner,
    loadCategoryOrBanner
})(GenericBanner);
