import React, { useState, useEffect } from "react";
import { Switch, message } from "antd";
import isDev from "../../server";
const serverUrl = isDev();

export default function ProductListToggle(props) {
  const [checked, isChecked] = useState(props.checked);

  //.log(props, "SDSDSD")

  useEffect(() => {
    isChecked(props.checked);
  }, [props.checked])

  const updateStatus = () => {
    let url = "dealshub/unpublish-dealshub-product/";
    if (!checked) url = "dealshub/publish-dealshub-product/";

    if(props.cod)
    {
      if(checked)
      {
        url = "dealshub/deactivate-cod-dealshub-product/"
      }
      else
      {
        url = "dealshub/activate-cod-dealshub-product/"
      }
    }
    
    let data = {
      product_uuid: props.uuid,
    };
    //.log(data);
    fetch(`${serverUrl}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 200) {

          if(props.cod)
          {
            if(!checked)
            message.success("COD Allowed");
            else
            message.success("COD Disallowed");
          }
          else
          {
            if(!checked)
            message.success("Product Activated");
            else
            message.success("Product Deactivated");
          }
          isChecked(!checked);
         
        }
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        } else if(data.status === 407) {
          message.error(data.message)
        }
        else message.error("Something went wrong during update");
      });
  };


  return (
    <React.Fragment>
      <Switch
        size="small"
        checked={checked}
        checkedChildren={props.checkedText} unCheckedChildren={props.uncheckedText}
        onChange={() => {
          updateStatus();
        }}
      />
    </React.Fragment>
  );
}
