export const locationUuidConst = {
    WIGMe_UAE: 'f17c7336-644c-44a3-bc92-4f0c0b11463b',
    KryptonWorld: 'ac634677-fb25-46eb-a14b-55075a4f8581',
    PARAJOHN_UAE: 'ed1bb009-46b8-47af-8c80-39c9971b8c77',
    Daycart_KSA: 'g92c7336-931c-42a3-ji92-4f8c0b11463b',
    WIGme_KWT: '335100bc-7b67-4e52-bb2c-88fc5eb9554d',
    WIGme_BAH: '39ff1329-da37-49fe-91f4-8ce4c8cd1410',
    WIGme_B2B: '501daeb3-2041-41d5-ac29-3179d5411b9b',
    Geepas_uganda: '40f8715f-aad9-49bb-9813-ebcf8ff0589b',
    QA_wigme_UAE: '4724a505-8b23-45fe-bf98-4d4b23817aa4',
    QA_wigme_B2B: '86bd83ab-0a99-493e-afa1-d621f3d46693',
    QA_wigme_KWT: 'ee98252a-a321-4b8c-938f-b04179158db0',
    QA_wigme_BAH: '15158dfe-b6ac-4fa5-8f95-d7c215693395',
    QA_geepas_uganda: '86689-513438asd-112ddcsd',
}

