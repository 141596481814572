import React, { Component } from "react";
import { Card, Row, Col, Typography, Icon, message, Avatar, Tooltip } from "antd";
import "./cards.scss";
import isDev from "../../../server";
const serverUrl = isDev();

const { Text, Paragraph } = Typography;

const { Meta } = Card;

class ProductCardExportpage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  removeProduct = () => {
    const bodyData = {
      product_pk: this.props.product_pk,
      export_pk: this.props.export_pk
    };
    fetch(`${serverUrl}/remove-product-from-export-list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(bodyData)
    })
      .then(res => res.json())
      .then(data => {
        if (data.status == 200) {
          message.success("Deleted Successfully!");
          this.props.reRender();
        } 
        else if(data.status === 403)
        {
          message.info("You are not authorised!")
        }else {
          message.error("Something Went Wrong!");
        }
      });
  };

  render() {

    return (
      <React.Fragment>
        <Card bordered className="categoryproductcard">
          <Row gutter={[16, 0]}>
            <Col sm={6} lg={6}>
              <Avatar shape="square" style={{ width: "100%", height: "100%" }} src={this.props.image} />
            </Col>
            <Col sm={18} lg={18}>
              <Tooltip title={this.props.name && this.props.name.product_name} >
                {/* <Text type="secondary" className="text-ellipsis">
                  {this.props.name && this.props.name.product_name}
                </Text> */}
                <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ marginBottom: "0px", paddingRight: "35px" }}>
                  {this.props.name && this.props.name.product_name} <br /><br />

                </Paragraph>
              </Tooltip>


              <Text className="text-color3">ID: </Text>
              <Text className="">{this.props.ID}</Text>
              <br />
              <Text className="text-color3">SKU: </Text>
              <Text className="">{this.props.product_sku}</Text>

            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              top: "2px",
              right: "5px",
              cursor: "pointer"
            }}
            onClick={this.removeProduct}
          >
            X
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default ProductCardExportpage;
