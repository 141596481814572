import React, { Component } from "react";
import { connect } from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
import FactoryUserRoute from "./routes/FactoryUserRoute";
import "./stylesheet/style.scss";
import isDev from "server";
import { message } from "antd";
import WaitingScreen from "views/waiting-screen/WaitingScreen";
const serverUrl = isDev();
/**
 * @description This is a class component named App which returns PrivateRoute component if user is logged in or PublicRoute component otherwise 
 */
class App extends Component {

  state = {
    loading : false
  }

  checkFactoryUser = () => {

    this.setState({
      loading : true
    })

    let data = {
     
    };
    fetch(`${serverUrl}/sourcing/check-factory-user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {
         this.setState({
           loading : false
         })
             if(data.status == 200)
              {
                this.setState({
                    factoryUser : data.factory_user,
                    factory_uuid : data.factory_uuid
                })
              }
              else if(data.status == 403)
              {
                message.info("You have not the permission to check the User");
              }
              else
              {
                message.error("Something went wrong!")
              }
      }).catch(e => {
        console.log(e);
        this.setState({
          loading: false
        })
      });   
  }

  componentDidMount = () => {
    this.setState({
      factoryUser : "Stop Pass",
    })

    if(localStorage.getItem("isLoggedIn"))
     this.checkFactoryUser();
    else
      this.setState({factoryUser : "Pass"})
      
  }

  

  render() {

    // debug by this  
    console.log(this.state.loading , " " ,localStorage.getItem("isLoggedIn"), " ", this.state.factoryUser , " yeyeyey");

    if(!localStorage.getItem("isLoggedIn"))
    {
      return  <PublicRoute />
    }

    if(this.state.factoryUser == "Stop Pass" || this.state.factoryUser == undefined)
    return <React.Fragment>
      <WaitingScreen/>
    </React.Fragment>;

    if(this.state.factoryUser == true)
    return <FactoryUserRoute factory_uuid = {this.state.factory_uuid} />

    // return <PublicRoute />

    return localStorage.getItem("isLoggedIn") ? (
      <ProtectedRoute />
    ) : (
        <PublicRoute />
      );
  }
}
/**
 * @description The function is mapping state to props
 * @param {*} store 
 * @returns updated store with new changes.
 */
const mapStateToProps = store => {
  return {
    ...store
  };
};
export default connect(mapStateToProps)(App);