import React from "react";

import { Row, Col } from "antd";

/**
 * @description - Just exporting Play ground as text to /play-ground route.
 * @returns - Component which displays "play gound" as text in a row on screen
 */

const PlayGround = () => {
  return (
    <React.Fragment>
      <Row>play ground</Row>
    </React.Fragment>
  );
};

export default PlayGround;
